import React from 'react'
import { bindActionCreators } from 'redux'
import { connect as reduxConnect } from 'react-redux'
import {
  getLoggableUsers,
  isLoading,
  isLoginInProgress,
  loginAs,
} from '../../store/app/login-as'
import { getCurrentAssistant } from '../../store/app/user-profile'

const connect = (Component) => {
  const LoginAsConnect = (props) => <Component<any, any> {...props} />

  const mapStateToProps = (state) => {
    return {
      users: getLoggableUsers(state),
      isFetching: isLoading(state),
      isLoginInProgress: isLoginInProgress(state),
      currentAssistant: getCurrentAssistant(state),
    }
  }

  const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
      {
        loginAs,
      },
      dispatch,
    )
  }

  const mergedProps = (selectors, actions, own) => {
    return {
      ...own,
      loginAs: {
        selectors,
        actions,
        ...own.loginAs,
      },
    }
  }

  return reduxConnect(mapStateToProps, mapDispatchToProps, mergedProps)(LoginAsConnect)
}

export default connect
export { connect }
