import React from 'react'
import {
  BOOKING_CANCELLED,
  BOOKING_EXPIRED,
  BOOKING_FAILED,
  BOOKING_OFFER_CHANGED,
  BOOKING_SUCCESS,
} from '../../../store/app/flights-booking'
import classNames from 'classnames'
import { trans } from '../../../trans'
import { get } from 'lodash'

const ReservationStatus = (props) => {
  const { offer } = props

  const status = get(offer, 'options.0.booking', null)

  const statusClass = classNames({
    'reservation-status': true,
    'reservation-status--warning': status === BOOKING_OFFER_CHANGED,
    'reservation-status--error':
      status === BOOKING_FAILED || status === BOOKING_EXPIRED || status === BOOKING_CANCELLED,
  })

  let label = null

  // if (status === BOOKING_OFFER_CHANGED) {
  //     label = trans('global.offer-changed');
  // }

  if (status === BOOKING_FAILED || status === BOOKING_CANCELLED) {
    label = trans('global.booking-failed')
  }

  if (status === BOOKING_EXPIRED) {
    label = trans('global.booking-expired')
  }

  if (status === BOOKING_SUCCESS) {
    label = trans('global.booking-success')
  }

  if (offer && offer.errorMessageSlug) {
    label = trans(offer.errorMessageSlug)
  }

  if (label) {
    return <span className={statusClass}>{label}</span>
  }

  return null
}

export default ReservationStatus
export { ReservationStatus }
