import React from 'react'
import { reduxForm, SubmissionError } from 'redux-form/immutable'
import { connect } from 'react-redux'
import { fromJS } from 'immutable'
import { PrivateAccommodation as Form } from '../Forms'
import { bindActionCreators } from 'redux'
import { processAPIerrorResponseToFormErrors } from '../../../../services/APIClient'
import { getFormValues } from '../../../../utils/forms'
import { Factory as ElementFactory } from '../../../../models/timeline/index'
import { prepareRequestDates } from '../../../../utils/prepareRequestDates'
import { getElements, getPrivateAccommodationCost } from '../../../../store/app/trip-timeline/index'
import { DateSuggester } from '../../../../store/app/trip-timeline/services/date-suggester'
import moment from 'moment'
import { config } from '../../../../config'
import { AccommodationLocationSuggester } from '../../../../store/app/trip-timeline/services/accommodation-location-suggester'
import { has } from 'lodash'
import store from '../../../../store'
import {
  TRAVEL_ACCOMMODATION_PROVIDED,
  TRAVEL_ACCOMODATION,
  TRAVEL_ACCOMODATION_SUGGESTION,
  TRAVEL_EMPTY,
  TRAVEL_PRIVATE_ACCOMODATION,
  TRAVEL_TARGET_POINT,
} from '../../../../constants/travel'

class PrivateAccommodation extends React.PureComponent<any, any> {
  componentDidMount() {
    const { getPrivateAccommodationCost, dispatch, change, initialValues } = this.props

    const values = initialValues.toJS()

    if (values.type !== TRAVEL_PRIVATE_ACCOMODATION) {
      return
    }

    if (has(values, 'location.country_code') && values.arrival_at && values.departure_at) {
      getPrivateAccommodationCost(values, this.props.request.slug)
        .then((response) => {
          dispatch(change('converted_amount', response.convertedAmount))
          dispatch(change('amount_currency', response.currency))
          dispatch(change('amount', response.amount))
        })
        .catch(() => {
          dispatch(change('converted_amount', null))
          dispatch(change('amount_currency', null))
          dispatch(change('amount', null))
        })
    }
  }

  render() {
    const { ...props } = this.props

    if (!this.props.initialized) {
      return null
    }

    return <Form {...props} />
  }
}

export const submit = (values, dispatch, props) => {
  const { onSave, request, change } = props
  values = prepareRequestDates(values)
  return onSave(request, values, props.element).then(
    () => {
      dispatch(change('isOpen', false))
    },
    (alerts) => {
      throw new SubmissionError(processAPIerrorResponseToFormErrors(alerts))
    },
  )
}

export const change = (values, dispatch, props, previousValues) => {
  const { change, accommodation } = props

  if (accommodation.getEndDate() < accommodation.getStartDate()) {
    dispatch(change('departure_at', accommodation.getStartDate()))
    return
  }

  if (values.get('type') !== TRAVEL_PRIVATE_ACCOMODATION) {
    return
  }

  if (
    has(accommodation, 'location.country_code') &&
    accommodation.getStartDate() &&
    accommodation.getEndDate()
  ) {
    if (
      values.get('location') !== previousValues.get('location') ||
      values.get('departure_at') !== previousValues.get('departure_at') ||
      values.get('arrival_at') !== previousValues.get('arrival_at')
    ) {
      props
        .getPrivateAccommodationCost(values.toJS(), props.request.slug)
        .then((response) => {
          dispatch(change('converted_amount', response.convertedAmount))
          dispatch(change('amount_currency', response.currency))
          dispatch(change('amount', response.amount))
        })
        .catch(() => {
          dispatch(change('converted_amount', null))
          dispatch(change('amount_currency', null))
          dispatch(change('amount', null))
        })
    }
  } else {
    dispatch(change('converted_amount', null))
  }
}

const calculateEndDate = (state, initialAccommodation, dateSuggester) => {
  let startDate = initialAccommodation.draft
    ? dateSuggester.suggestStartDate()
    : initialAccommodation.getStartDate()
  let endDate = initialAccommodation.draft
    ? dateSuggester.suggestEndDate()
    : initialAccommodation.getEndDate()

  let endDatePlusDay = moment(endDate, config.apiDateFormat).add(1, 'days')
  let elements = getElements(store.getState())
  let elementsWithoutTravelingItems = elements.filter((element) => {
    return [
      TRAVEL_ACCOMODATION_SUGGESTION,
      TRAVEL_ACCOMODATION,
      TRAVEL_PRIVATE_ACCOMODATION,
      TRAVEL_ACCOMMODATION_PROVIDED,
      TRAVEL_TARGET_POINT,
      TRAVEL_EMPTY,
    ].includes(element.type)
  })

  if (initialAccommodation.draft && elementsWithoutTravelingItems.length === elements.length) {
    endDate = moment(startDate, config.apiDateFormat).add(1, 'days')
  } else if (
    moment(endDate).diff(moment(startDate), 'days') === 0 &&
    (dateSuggester.suggestMaxDate() === undefined ||
      endDatePlusDay < moment(dateSuggester.suggestMaxDate()))
  ) {
    endDate = moment(endDatePlusDay).format(config.apiDateTimeFormat)
  }

  return endDate
}

const withForm = reduxForm({
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
  onSubmit: submit,
  onChange: change,
  destroyOnUnmount: false,
})(PrivateAccommodation)

const mapStateToProps = (state, props) => {
  const { request, element, currencies } = props

  const accommodation = ElementFactory.create(element)
  const dateSuggester = new DateSuggester(state, accommodation)
  const locationSuggester = new AccommodationLocationSuggester(state, accommodation)

  let startDate = accommodation.draft
    ? dateSuggester.suggestStartDate()
    : accommodation.getStartDate()
  let endDate = calculateEndDate(state, accommodation, dateSuggester)

  return {
    initialValues: fromJS({
      uuid: accommodation.uuid,
      location: accommodation.draft
        ? locationSuggester.suggestLocation()
        : accommodation.getStartLocation(),
      arrival_at: startDate,
      departure_at: endDate,
      id: accommodation.id,
      type: accommodation.type,
      converted_amount: accommodation.converted_amount,
      isOpen: accommodation.isOpen,
      draft: accommodation.draft,
      virtual: accommodation.virtual,
      amount_currency: accommodation.amount_currency,
      converted_amount_currency: accommodation.converted_amount_currency,
    }),
    form: accommodation.key,
    request,
    currencies,
    accommodation: ElementFactory.create(getFormValues(accommodation.key, state)),
    minDate: dateSuggester.suggestMinDate(),
    maxDate: dateSuggester.suggestMaxDate(),
    maxStartDate: dateSuggester.suggestMaxStartDate(),
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getPrivateAccommodationCost,
    },
    dispatch,
  )
}

const connected = connect(mapStateToProps, mapDispatchToProps)(withForm)

PrivateAccommodation = connected

export { PrivateAccommodation }
export default { PrivateAccommodation }
