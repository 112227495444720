import React, { Fragment } from 'react'
import { connect } from '../../containers/TripDidNotHavePlace'
import Icon from '../ui/IconComponent'
import trans from '../../trans'
import { ComponentOverlay } from '../ui/ComponentOverlay'

const TripDidNotHavePlaceOverlay = connect((props) => {
  const {
    tripDidNotHavePlace: {
      selectors: { isTripDidNotHavePlace },
    },
  } = props

  return (
    <div className='has-overlay'>
      <Fragment>
        {props.children}

        {isTripDidNotHavePlace && (
          <ComponentOverlay>
            <div className='component-overlay__message component-overlay__message--unsettled-lump-sums'>
              <Icon className='icon-info-grey component-overlay__message-icon' type='info' />
              <span>{trans('request.trip-did-not-have-place-message')}</span>
            </div>
          </ComponentOverlay>
        )}
      </Fragment>
    </div>
  )
})

export default TripDidNotHavePlaceOverlay
export { TripDidNotHavePlaceOverlay }
