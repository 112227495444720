import PropTypes from 'prop-types'
import React from 'react'
import Icon from '../ui/IconComponent'
import { Map } from 'immutable'

const getUrl = (location) => {
  if (
    location.additional_data &&
    location.additional_data.spot_lat &&
    location.additional_data.spot_long
  ) {
    return `https://www.google.pl/maps/?q=${location.additional_data.spot_lat},${location.additional_data.spot_long}&z=17&ll=${location.additional_data.spot_lat},${location.additional_data.spot_long}`
  }
  if (location.lat && location.long) {
    return `https://www.google.pl/maps/?q=${location.lat},${location.long}&z=17&ll=${location.lat},${location.long}`
  }

  return `https://www.google.com/maps/search/?api=1&query=${location.formatted_address}`
}

const renderDirections = (location) => {
  return (
    <a className='travel-point__link' target='_blank' href={getUrl(location)}>
      <Icon type='compas' className='is-google-red' lg />
    </a>
  )
}

const TravelPointLabel = ({ location, showDirections }) => {
  if (location instanceof Map) {
    location = location.toJS()
  }

  return (
    <span className={showDirections ? 'travel-point' : ''} title={location.formatted_address}>
      {location.city} {showDirections ? renderDirections(location) : ''}
    </span>
  )
}

TravelPointLabel.propTypes = {
  location: PropTypes.object.isRequired,
  showDirections: PropTypes.bool.isRequired,
}

TravelPointLabel.defaultProps = {
  showDirections: false,
}

export { TravelPointLabel }
export default { TravelPointLabel }
