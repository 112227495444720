import React from 'react'
import { SelectField } from '../../ui/Form'
import { trans } from '../../../trans'
import {
  SORT_BY_CHEAPEST_FIRST,
  SORT_BY_STARS_DESCENDING,
  SORT_BY_DISTANCE_ASCENDING,
} from '../../../store/app/hotels-booking/sort'

const Sort = (props) => {
  const { onChange, value } = props

  return (
    <div className='form-group form-group--label-top'>
      <span className='form-group__label'>{trans('hotels-booking.sort-by')}</span>
      <div className='form-group__input-wrapper'>
        <SelectField
          placeholder={trans('hotels-booking.sort-by-select')}
          options={[
            {
              value: SORT_BY_CHEAPEST_FIRST,
              label: trans('hotels-booking.sort-by-' + SORT_BY_CHEAPEST_FIRST),
            },
            {
              value: SORT_BY_STARS_DESCENDING,
              label: trans('hotels-booking.sort-by-' + SORT_BY_STARS_DESCENDING),
            },
            {
              value: SORT_BY_DISTANCE_ASCENDING,
              label: trans('hotels-booking.sort-by-' + SORT_BY_DISTANCE_ASCENDING),
            },
          ]}
          onChange={(value) => onChange(value)}
          value={value}
        />
      </div>
    </div>
  )
}

export default Sort
export { Sort }
