import React from 'react'
import PropTypes from 'prop-types'
import trans from '../../trans'

interface ComponentProps {
  name: string
  erpId: string
  registryNumber: string
  city: string
  address: string
}

function RenderProvider({ name, erpId, registryNumber, city, address }: ComponentProps) {
  const formattedAddress = [address, city].filter((v) => v).join(', ') || '-'

  return (
    <div className='provider-select-option'>
      <div className='provider-select-option__name'>{name}</div>
      <div className='provider-select-option__details'>
        <span className='provider-select-option__detail'>
          <span>{trans('document.erp-id')}:</span>
          &nbsp;
          <span>{erpId ? erpId : '-'}</span>
        </span>

        <span className='provider-select-option__detail'>
          <span>{trans('document.provider-nip')}:</span>
          &nbsp;
          <span>{registryNumber ? registryNumber : '-'}</span>
        </span>
      </div>

      <div className='provider-select-option__details'>
        <span className='provider-select-option__detail'>
          <span>{trans('document.address')}:</span>
          &nbsp;
          <span>{formattedAddress}</span>
        </span>
      </div>
    </div>
  )
}

RenderProvider.propTypes = {
  name: PropTypes.string.isRequired,
  erpId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  registryNumber: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
}

export default React.memo(RenderProvider)
