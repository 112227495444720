import React from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { Cell, Row } from '../../../ui/AccordionTable'
import trans from '../../../../trans'
import {
  fetchProjects,
  getPage,
  getPaginatedProjects,
  getProjectsTotalCount,
  isLoading,
  setPage,
} from '../../../../store/app/projects'
import { CompanyBaseList } from '../../CompanyBaseList'
import ProjectListFormItem from './ProjectListFormItem'
import ProjectListItem from './ProjectListItem'

class ProjectList extends CompanyBaseList {
  getIdentifierKey() {
    return 'id'
  }

  getFormComponent() {
    return ProjectListFormItem
  }

  getListItemComponent() {
    return ProjectListItem
  }

  renderNewForm() {
    if (!this.state.adding) {
      return
    }

    const item = {
      is_active: true,
    }

    return this.renderForm(item)
  }

  renderTableHeader() {
    return (
      <Row>
        <Cell fixedWidth={150}>{trans('accounting.project-code')}</Cell>
        <Cell fixedWidth='auto'>{trans('accounting.project-name')}</Cell>
        <Cell fixedWidth={120}>{trans('accounting.is_active')}</Cell>
        <Cell fixedWidth={180} alignRight />
      </Row>
    )
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      fetch: fetchProjects,
      setPage,
    },
    dispatch,
  )
}

const mapStateToProps = (state) => {
  return {
    page: getPage(state),
    isLoading: isLoading(state),
    items: getPaginatedProjects(state),
    total: getProjectsTotalCount(state),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProjectList)
