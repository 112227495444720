import React, { Component, Fragment } from 'react'
import { Loader } from '../../ui/LoadingOverlay/Loader'
import { connect as flightsConnect } from '../../../containers/FlightsBooking'
import SelectedOffer from './SelectedOffer'
import OffersDialog from '../List/OffersDialog'
import SearchForm from './SearchForm'

class BookingContentComponent extends Component<any, any> {
  renderLoader() {
    const {
      flights: {
        selectors: { isLoading, isBlocked },
      },
    } = this.props

    if (isLoading || isBlocked) {
      return <Loader />
    }
  }

  renderSelectedOffer() {
    return <SelectedOffer />
  }

  renderForm() {
    const {
      currencies,
      context: { element },
    } = this.props

    return <SearchForm currencies={currencies} plane={element} />
  }

  render() {
    const {
      flights,
      context: { element },
    } = this.props
    const {
      flights: {
        selectors: { selectedBack, selectedThere, query },
      },
    } = this.props

    if (!flights.selectors.initialized) {
      return <Loader />
    }

    const showSingleOffer =
      (!query.round_trip && selectedThere) || (query.round_trip && selectedBack)

    return (
      <Fragment>
        {this.renderLoader()}

        {showSingleOffer ? this.renderSelectedOffer() : this.renderForm()}

        <OffersDialog element={element} />
      </Fragment>
    )
  }
}

const BookingContent = flightsConnect(BookingContentComponent)

export { BookingContent }
export default BookingContent
