import React from 'react'
import { Field } from 'redux-form/immutable'
import { FormField, FormFieldRadioGroup } from '../../../ui/Form'
import { trans } from '../../../../trans'
import { Col, Row } from '../../../../ui/Grid'
import Button from '../../../ui/ButtonComponent'
import ExchangeSummaryContainer from '../../../../containers/ExchangeSummaryContainer'
import numeral from 'numeral'
import { Map } from 'immutable'
import {
  TRAVEL_ACCOMMODATION_PROVIDED,
  TRAVEL_ACCOMODATION,
  TRAVEL_PRIVATE_ACCOMODATION,
} from '../../../../constants/travel'
import HotelsBooking from '../../../HotelsBooking'
import {
  DEFAULT_COORDINATE_LAT,
  DEFAULT_COORDINATE_LNG,
  DEFAULT_SEARCH_RADIUS,
} from '../../../../store/app/hotels-booking'
import { getInstance } from '../../../../store/app/instance'
import store from '../../../../store'
import { getCurrentUser } from '../../../../store/app/user-profile/userProfileSlice'
import { tryToUnlockWaitingForWebSocketEvent } from '../../../../store/app/trip-request'

class Accommodation extends React.Component<any, any> {
  constructor(props) {
    super(props)

    this.state = {
      rangeValue: 0,
      isListOpen: false,
      submitting: false,
    }

    this.onSubmit = this.onSubmit.bind(this)
  }

  getExchangeSummaryPartials() {
    const { accommodation } = this.props

    const amount = accommodation.amount
    const amount_currency = accommodation.amount_currency

    return Map({
      [amount_currency]: numeral(amount),
    })
  }

  getAmountSuggestion() {
    const { accommodation } = this.props

    const location = accommodation.location
    const date = accommodation.getStartDate()

    if (!location.city || !date) {
      return null
    }

    if (location.country_code.indexOf('PL') === -1) {
      return 450
    }

    return 260
  }

  search = (e) => {
    e.preventDefault()
    const { search, searchQuery, radius, setSearch, data } = this.props
    setSearch(true)

    search({
      ...searchQuery,
      range: radius,
      rooms: data.rooms,
      request_travelers: data.request_travelers,
    }).then()
  }

  closeList = () => {
    const { setSearch } = this.props
    setSearch(false)
  }

  resignFromSearching = (e) => {
    e.preventDefault()
    const { resignFromSearch, change } = this.props
    change('searcher_disabled', true)
    resignFromSearch()
  }

  _getChangeTypeOptions() {
    const { accommodation } = this.props
    const currentUser = getCurrentUser(store.getState());

    let options = {
      [TRAVEL_ACCOMODATION]: {
        value: TRAVEL_ACCOMODATION,
        label: trans('accommodation.accommodation'),
      },
    }

    if (currentUser.abilities.showPrivateAccommodationType) {
      options[TRAVEL_PRIVATE_ACCOMODATION] = {
        value: TRAVEL_PRIVATE_ACCOMODATION,
        label: trans('accommodation.private-accommodation')
      };
    }

    if (currentUser.abilities.showProvidedAccommodationType) {
      options[TRAVEL_ACCOMMODATION_PROVIDED] = {
        value: TRAVEL_ACCOMMODATION_PROVIDED,
        label: trans('accommodation.provided')
      };
    }

    if (accommodation.draft) {
      return Object.values(options)
    }

    return [options[accommodation.type]]
  }

  onSubmit(e) {
    if (this.state.submitting) {
      e.preventDefault()
      return false
    }

    this.setState({ submitting: true })
    this.props.handleSubmit(e)
  }

  renderContent() {
    const {
      element,
      currencies,
      onChangeType,
      change,
      minDate,
      maxDate,
      accommodation,
      form,
      daysOfStay,
      maxStartDate,
      request,
      isSearchEnabled,
      isOptionSelected,
      travelers,
      dispatch,
      clearFields,
      setFacility,
    } = this.props

    return (
      <form onSubmit={this.onSubmit}>
        <Field name='draft' type='hidden' component={FormField} inputOnly labeltop />

        <Field name='id' type='hidden' component={FormField} inputOnly labeltop />

        <Field name='type' type='hidden' component={FormField} inputOnly labeltop />

        <Field name='round_trip' type='hidden' component={FormField} inputOnly labeltop />

        <ExchangeSummaryContainer
          key={`exchange-${form}`}
          onSumChange={(sum) => {
            change('converted_amount', sum)
          }}
          partials={this.getExchangeSummaryPartials()}
          showSummary={false}
          labeltop
          request={request}
        />

        {!isOptionSelected && (
          <Row>
            <Col xs={12}>
              <div className='accommodation__inputs'>
                <div className='accommodation__input-container accommodation__input-container--radios'>
                  <Field
                    name='type'
                    component={FormFieldRadioGroup}
                    items={this._getChangeTypeOptions()}
                    label={trans('accommodation.accommodation-type')}
                    onChange={(f, value) => onChangeType(element, value)}
                    inline
                    labeltop
                  />
                </div>
              </div>
            </Col>
          </Row>
        )}

        <div className='accommodation__form-content'>
          {!isOptionSelected && (
            <div className='accommodation__inputs-container accommodation__input-wrapper'>
              <div>
                {isSearchEnabled && (
                  <h2 className='h2 section__header-title accommodation__map-title'>
                    {trans('accommodation.offers-search-engine')}
                  </h2>
                )}

                <div className='accommodation__inputs'>
                  <div className='accommodation__input-container'>
                    <Field
                      name='location'
                      type='location'
                      component={FormField}
                      label={trans('accommodation.location')}
                      immutable
                      autoFocus
                      noAutoTrigger
                      labeltop
                    />
                  </div>
                </div>

                <div className='accommodation__inputs'>
                  <div className='accommodation__input-container'>
                    <Field
                      name='arrival_at'
                      type='datepicker'
                      component={FormField}
                      label={trans('accommodation.arrival-date')}
                      minDate={minDate}
                      maxDate={maxStartDate}
                      labeltop
                    />
                  </div>

                  <div className='accommodation__input-container'>
                    <Field
                      name='departure_at'
                      type='datepicker'
                      component={FormField}
                      label={trans('accommodation.departure-date')}
                      minDate={accommodation.getStartDate()}
                      maxDate={maxDate}
                      labeltop
                    />
                  </div>
                </div>

                {travelers.length > 1 && (
                  <div className='accommodation__inputs'>
                    <div className='accommodation__input-container'>
                      <Field
                        name='request_travelers'
                        type='traveler-select'
                        options={travelers}
                        component={FormField}
                        label={trans('hotels-booking.request-traveler-field-label')}
                        immutable
                        labeltop
                      />
                    </div>

                    <div className='accommodation__input-container'>
                      <Field
                        name='rooms'
                        type='rooms'
                        component={FormField}
                        label={trans('hotels-booking.rooms-field-label')}
                        onChange={() => {
                          dispatch(clearFields(true, false, ['request_travelers']))
                        }}
                        immutable
                        labeltop
                      />
                    </div>
                  </div>
                )}

                <div className='accommodation__inputs'>
                  <div className='accommodation__input-container'>
                    <Field
                      name='breakfast'
                      type='checkbox'
                      label={trans('hotels-booking.breakfast-included')}
                      onChange={(event, value) => {
                        setFacility('breakfast', value)
                      }}
                      component={FormField}
                      inputOnly={true}
                    />
                  </div>
                </div>
              </div>

              {isSearchEnabled && (
                <div className='accommodation__buttons-wrapper'>
                  <div className='accommodation__buttons'>
                    <Button
                      className='accommodation__button-resign is-margin-right-small'
                      secondary
                      outline
                      xs
                      onClick={this.resignFromSearching}
                    >
                      {trans('hotels-booking.resign-from-searching')}
                    </Button>

                    <Button
                      className='accommodation__button-search'
                      primary
                      type='submit'
                      onClick={this.search}
                    >
                      {trans('hotels-booking.search')}
                    </Button>
                  </div>
                </div>
              )}
            </div>
          )}

          <div className='accommodation__map-container'>
            <HotelsBooking
              rangeMap={{
                init: {
                  radius: DEFAULT_SEARCH_RADIUS,
                  coordinates: {
                    lat: this.getInitialLat(),
                    lng: this.getInitialLng(),
                  },
                  name: accommodation.key,
                },
              }}
              hotelsBooking={{
                request: request,
                init: {
                  offers: [],
                  socket: {
                    channel: null,
                    event: null,
                  },
                  name: accommodation.key,
                  facilities: {
                    breakfast: accommodation.breakfast,
                  },
                  query: {
                    location: accommodation.getStartLocation(),
                    arrival_at: accommodation.getStartDate(),
                    departure_at: accommodation.getEndDate(),
                  },
                },
                element: accommodation,
              }}
              isListOpen={this.state.isListOpen}
              closeList={this.closeList}
            />
          </div>
        </div>

        {!isSearchEnabled && (
          <div className='accommodation__inputs accommodation__inputs--save'>
            <div className='accommodation__requested-amount-inputs'>
              <div className='accommodation__input-container accommodation__input-container--requested-amount'>
                <Field
                  name='amount'
                  type='amount'
                  component={FormField}
                  label={trans('request-element.amount')}
                  suggestedAmount={this.getAmountSuggestion()}
                  suggestedAmountCurrency={accommodation.calculated_amount_currency}
                  suggestedAmountLabel={trans('request.accommodation-amount-suggestion-label')}
                  onBlur={(e) => {
                    e.preventDefault()
                  }}
                  placeholder='0,00'
                  multiplier={daysOfStay}
                  labeltop
                />
              </div>

              <div className='accommodation__input-container accommodation__input-container--currency'>
                <Field
                  name='amount_currency'
                  type='currency'
                  component={FormField}
                  currencies={currencies}
                  onChange={() => {}}
                  label=' '
                  labeltop
                />
              </div>
            </div>
            <div className=''>
              {/*<Button className="accommodation__button is-margin-right-small" secondary outline xs onClick={this.openSearch}>{trans('hotels-booking.search-offer')}</Button>*/}
              <Button className='accommodation__button' primary xs type='submit'>
                {trans('ui.save-trip-element')}
              </Button>
            </div>
          </div>
        )}
      </form>
    )
  }

  getInitialLng() {
    const workLocation = getCurrentUser(store.getState()).workLocation
    const instanceLocation = getInstance(store.getState()).location

    if (workLocation && workLocation.long) {
      return workLocation.long
    }

    if (instanceLocation && instanceLocation.long) {
      return instanceLocation.long
    }

    return DEFAULT_COORDINATE_LNG
  }

  getInitialLat() {
    const workLocation = getCurrentUser(store.getState()).workLocation
    const instanceLocation = getInstance(store.getState()).location

    if (workLocation && workLocation.lat) {
      return workLocation.lat
    }

    if (instanceLocation && instanceLocation.lat) {
      return instanceLocation.lat
    }

    return DEFAULT_COORDINATE_LAT
  }

  render() {
    return this.renderContent()
  }
}

export { Accommodation }
export default { Accommodation }
