import { BreadCrumbs } from '../../containers'
import { RouteManager } from '../../containers/RouteManager'
import { SettlementRequestsManager } from '../../containers/SettlementRequestsManager'
import { getRouteByName } from '../../routes'
import trans from '../../trans'
import { Section } from '../ui/Section'
import { SectionHeader } from '../ui/SectionHeader'
import React, { useCallback } from 'react'
import { Link } from 'react-router-dom'
import PaymentsPageFilters from '../PaymentsPageFilters'
import PaymentsPageTable from '../PaymentsPageTable'
import useTransactions from './hooks/useTransactions'
import { LoadingOverlay } from '../ui/LoadingOverlay'

function PaymentsPage() {
  const card = '4239 **** **** 7345'
  const { data, paginator, loading, setPage, load } = useTransactions()

  return (
    <div>
      <BreadCrumbs>
        <Link to={getRouteByName('main', 'dashboard')}>{trans('global.dashboard')}</Link>
        <Link to={getRouteByName('main', 'my-card')}>{trans('main-menu.my-card')}</Link>
        <Link to={getRouteByName('main', 'my-card-payments')}>
          {trans('main-menu.my-card-payments')}
        </Link>
      </BreadCrumbs>

      <Section className={'my-cards'} noBorder>
        <SectionHeader
          className={'my-cards__header'}
          caption={trans('my-card.payments_header', { card })}
        />

        <RouteManager>
          {({ getRouteByName, push }) => (
            <div>
              <PaymentsPageFilters />
              {loading && <LoadingOverlay />}
              {!loading && (
                <PaymentsPageTable
                  data={data}
                  paginator={paginator}
                  setPage={setPage}
                  refresh={load}
                />
              )}
            </div>
          )}
        </RouteManager>
      </Section>
    </div>
  )
}

export default PaymentsPage
