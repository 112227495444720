import React from 'react'
import { trans } from '../../trans'
import PropTypes from 'prop-types'
import { TYPE_PLANE, TYPE_TRAIN } from '../../constants/access-lum-sum'

const TRAVEL_POINTS_STARTS_STOP_HOURS_AVAILABLE_TYPES = [TYPE_TRAIN, TYPE_PLANE]

class TravelPointStartStopHours extends React.Component {
  render() {
    let memo = ''

    if (this.props.type.indexOf(TRAVEL_POINTS_STARTS_STOP_HOURS_AVAILABLE_TYPES)) {
      if (
        !this.props.start ||
        !this.props.end ||
        !this.props.start.isValid() ||
        !this.props.end.isValid()
      ) {
        return null
      }

      const start = this.props.start.format('HH:mm')
      const stop = this.props.end.format('HH:mm')

      switch (this.props.type) {
        case TYPE_TRAIN:
          memo = trans('train-trip.start-stop', { start, stop })
          break
        case TYPE_PLANE:
          memo = trans('flights-booking.start-stop', { start, stop })
          break
      }
    }

    return <span className={'travel-point travel-point-start-stop--label'}>{memo}</span>
  }
}

TravelPointStartStopHours.propTypes = {
  type: PropTypes.oneOf(TRAVEL_POINTS_STARTS_STOP_HOURS_AVAILABLE_TYPES),
  start: PropTypes.object.isRequired,
  end: PropTypes.object.isRequired,
}
export default TravelPointStartStopHours
