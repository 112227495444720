import APIClient from '../../../services/APIClient'
import { OFFER_STATUS } from '../../../constants/offer-status'

const getInitialState = () => {
  return {
    offer: null,
    isLoaded: false,
    isLoading: false,
    isCanceling: false,
    error: false,
  }
}

// constants
export const RESET_DOCUMENT_OFFER = 'document-offer::reset'
export const SET_DOCUMENT_OFFER = 'document-offer::set-offer'
export const START_LOADING = 'document-offer::start-loading'
export const LOADING_ERROR = 'document-offer::loading-error'

export const CANCEL_STARTED = 'document-offer::cancel-started'
export const CANCEL_SUBMITTED = 'document-offer::cancel-submitted'
export const CANCEL_REFUSED = 'document-offer::cancel-refused'

export const MOUNT_POINT = 'document-offer'

// reducer
export const reducer = (state = getInitialState(), action) => {
  switch (action.type) {
    case RESET_DOCUMENT_OFFER:
      return getInitialState()
    case SET_DOCUMENT_OFFER:
      return {
        ...state,
        offer: { ...action.offer },
        isLoading: false,
        isLoaded: true,
        error: false,
      }
    case START_LOADING:
      return {
        ...state,
        isLoading: true,
        error: false,
      }
    case LOADING_ERROR:
      return {
        ...state,
        isLoaded: false,
        isLoading: false,
        error: true,
      }
    case CANCEL_STARTED:
      return {
        ...state,
        isCanceling: true,
      }
    case CANCEL_SUBMITTED:
      return {
        ...state,
        offer: { ...action.offer },
        isCanceling: false,
      }
    case CANCEL_REFUSED:
      return {
        ...state,
        isCanceling: false,
      }
    default:
      return state
  }
}

export const reset = () => (dispatch) => {
  dispatch({
    type: RESET_DOCUMENT_OFFER,
  })
}

export const setDocumentOffer = (offer) => (dispatch) => {
  dispatch({
    type: SET_DOCUMENT_OFFER,
    offer: { ...offer },
  })
}

export const getState = (state) => {
  return state.get(MOUNT_POINT)
}

export const getDocumentOffer = (state) => {
  return getState(state).offer
}

export const fetchDocumentOffer = (requestSlug, offerUuid) => (dispatch) => {
  dispatch({
    type: START_LOADING,
  })

  return new Promise((resolve, reject) => {
    APIClient.getSingleBookedOffer(requestSlug, offerUuid)
      .then((response) => {
        dispatch({
          type: SET_DOCUMENT_OFFER,
          offer: { ...response.data },
        })

        resolve(response.data)
      })
      .catch((e) => {
        dispatch({
          type: LOADING_ERROR,
        })

        reject()
      })
  })
}

export const cancelDocumentOffer = (requestSlug, offerUuid) => (dispatch) => {
  dispatch({
    type: CANCEL_STARTED,
  })

  return new Promise((resolve, reject) => {
    APIClient.cancelSingleBookedOffer(requestSlug, offerUuid)
      .then((response) => {
        dispatch({
          type: CANCEL_SUBMITTED,
          offer: { ...response.data },
        })

        resolve(response.data)
      })
      .catch((e) => {
        dispatch({
          type: CANCEL_REFUSED,
        })

        reject()
      })
  })
}

export const getIsLoading = (state) => getState(state).isLoading
export const getIsLoaded = (state) => getState(state).isLoaded

export const isCancellationInProgress = (offerStatus) => {
  return (
    [
      OFFER_STATUS.PENDING_CANCELLATION,
      OFFER_STATUS.CANCELLATION_IN_PROGRESS,
      OFFER_STATUS.WAITING_FOR_CANCEL_DOCUMENTS,
    ].indexOf(offerStatus) !== -1
  )
}
