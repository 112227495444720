import trans from '../../../trans'

export const deductibleOptions = () => {
  return [
    {
      label: trans('document-element.deductible'),
      value: 'deductible',
    },
    {
      label: trans('document-element.non-deductible'),
      value: 'non-deductible',
    },
  ]
}
