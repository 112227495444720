import PropTypes from 'prop-types'
import React, { Fragment } from 'react'
import {
  TYPE_BACKGROUND_GRADIENT_ROW,
  TYPE_BACKGROUND_PRIMARY_ROW,
  TYPE_DATA_ROW,
} from '../ui/AccordionTable/Row'
import { TYPE_SUMMARY_CELL } from '../ui/AccordionTable/Cell'
import trans from '../../trans'
import { RequestMileageSummary } from '../RequestMileageSummary'
import { AmountFormatter } from '../AmountFormatter'
import { isArray, isPlainObject, isEmpty } from 'lodash'
import { getLumpSumFromSubSummary } from '../../utils/subsummary'
import { Panel } from '../ui/Panel'
import { ComponentOverlay } from '../ui/ComponentOverlay'
import Icon from '../ui/IconComponent'
import { borderCrossings } from '../../store/app/border-crossings'
import { connect as nonDelegation } from '../../containers/NonDelegation'
import { connect as tripDidNotHavePlace } from '../../containers/TripDidNotHavePlace'
import moment from 'moment'
import { Tooltip } from '../Tooltip'

class Summary extends React.Component<any, any> {
  renderHeader() {
    return (
      <h1 className='is-margin-bottom is-margin-top is-text-uppercase'>{trans('cover.summary')}</h1>
    )
  }

  render() {
    const {
      request,
      defaultCurrency,
      crossings: {
        selectors: { step },
      },
      isReadOnly,
    } = this.props
    const summary = request.subsummary

    const table = [
      {
        type: TYPE_BACKGROUND_GRADIENT_ROW,
        columns: [
          {
            content: trans('request-summary.country'),
          },
          {
            content: trans('request-summary.daily-rate'),
          },
          {
            content: trans('request-summary.duration-quantity'),
          },
          {
            content: trans('request-summary.value'),
          },
          {
            content: trans('request-summary.deductions'),
          },
          {
            content: trans('request-summary.exchange-rate'),
          },
          {
            content: `${trans('request-summary.amount')} ${defaultCurrency}`,
          },
        ],
      },
    ]

    if (isPlainObject(summary) && !isEmpty(summary)) {
      Object.keys(summary).forEach((key) => {
        const tmpRow = summary[key]
        const label = tmpRow['name']

        table.push({
          type: TYPE_BACKGROUND_PRIMARY_ROW,
          columns: [
            {
              content: trans(label),
            },
            {
              connectLeft: true,
            },
            {
              connectLeft: true,
            },
            {
              connectLeft: true,
            },
            {
              connectLeft: true,
            },
            {
              connectLeft: true,
            },
            {
              connectLeft: true,
            },
          ],
        })

        if (isArray(tmpRow['elements'])) {
          if (isEmpty(tmpRow['elements'])) {
            table.push({
              type: TYPE_DATA_ROW,
              columns: [
                {
                  content: <span>-</span>,
                },
                {
                  content: <span>-</span>,
                },
                {
                  content: <span>-</span>,
                },
                {
                  content: <span>-</span>,
                },
                {
                  content: <span>-</span>,
                },
                {
                  content: <span>-</span>,
                },
                {
                  content: <strong>-</strong>,
                  type: TYPE_SUMMARY_CELL,
                },
              ],
            })
          } else {
            tmpRow['elements'].forEach((row) => {
              table.push({
                type: TYPE_DATA_ROW,
                columns: [
                  {
                    content: (
                      <div className='request-mileage-allowance-summary__country'>
                        <span className='request-mileage-allowance-summary__country-name'>
                          {trans(row['countryName'])}
                        </span>
                        <span className='request-mileage-allowance-summary__country-currency'>
                          ({row['currency']})
                        </span>
                      </div>
                    ),
                  },
                  {
                    content: <AmountFormatter amount={row.base} />,
                  },
                  {
                    content: <AmountFormatter amount={row.duration} />,
                  },
                  {
                    content: <AmountFormatter amount={row.amount} />,
                  },
                  {
                    content: <AmountFormatter amount={row.deductions} />,
                  },
                  {
                    content:
                      parseFloat(row['exchangeRate']) === 1 || row['exchangeRate'] === null ? (
                        '-'
                      ) : (
                        <div>
                          <AmountFormatter amount={row['exchangeRate']} format='0.0000' />
                          <br />
                          <Tooltip
                            html={
                              <span className={'request-mileage-allowance-page__lump-sum-date'}>
                                {row.exchangeRateStrategy ===
                                  'foreign_travel_allowance_request_installment_rate_strategy' && (
                                  <span>
                                    {trans('request-summary.installment-rate')}{' '}
                                    {moment(row['exchangeRateDate']).format('DD-MM-YYYY')}
                                  </span>
                                )}
                                {row.exchangeRateStrategy !==
                                  'foreign_travel_allowance_request_installment_rate_strategy' && (
                                  <span>
                                    {trans('request-summary.rate')}{' '}
                                    {trans('request-summary.rate-day')}{' '}
                                    {moment(row['exchangeRateDate']).format('DD-MM-YYYY')}
                                  </span>
                                )}
                              </span>
                            }
                          >
                            <div className={'request-mileage-allowance-page__tooltip'}>
                              {trans('request-summary.fx')}{' '}
                              <Icon
                                style={{ marginRight: '0px', marginLeft: '5px' }}
                                grey
                                lg
                                type='icon-info-grey'
                              />
                            </div>
                          </Tooltip>
                        </div>
                      ),
                  },
                  {
                    content: <AmountFormatter amount={row['convertedAmount']} />,
                  },
                ],
              })
            })
          }
        }
      })
    }

    table.push({
      type: TYPE_BACKGROUND_PRIMARY_ROW,
      columns: [
        {
          content: trans('request-summary.table-summary'),
        },
        {
          connectLeft: true,
        },
        {
          connectLeft: true,
        },
        {
          connectLeft: true,
        },
        {
          connectLeft: true,
        },
        {
          connectLeft: true,
        },
        {
          connectLeft: true,
        },
      ],
    })

    table.push({
      type: TYPE_DATA_ROW,
      columns: [
        {
          content: `${trans('request-summary.sum')}:`,
        },
        {
          connectLeft: true,
        },
        {
          connectLeft: true,
        },
        {
          connectLeft: true,
        },
        {
          connectLeft: true,
        },
        {
          connectLeft: true,
        },
        {
          content: <AmountFormatter amount={getLumpSumFromSubSummary(summary)} />,
        },
      ],
    })

    const {
      nonDelegation: {
        selectors: { isDelegation },
      },
    } = this.props
    const {
      tripDidNotHavePlace: {
        selectors: { isTripDidNotHavePlace },
      },
    } = this.props

    return (
      <div className='has-overlay'>
        <Panel header={this.renderHeader()} className='panel--full-content'>
          {!isReadOnly && (
            <Fragment>
              {!isDelegation && !isTripDidNotHavePlace && (
                <ComponentOverlay>
                  <div className='component-overlay__message component-overlay__message--unsettled-lump-sums'>
                    <Icon className='icon-info-grey component-overlay__message-icon' type='info' />
                    <span>{trans('request.non-delegation-overlay-message')}</span>
                  </div>
                </ComponentOverlay>
              )}

              {isDelegation && isTripDidNotHavePlace && (
                <ComponentOverlay>
                  <div className='component-overlay__message component-overlay__message--unsettled-lump-sums'>
                    <Icon className='icon-info-grey component-overlay__message-icon' type='info' />
                    <span>{trans('request.trip-did-not-have-place-message')}</span>
                  </div>
                </ComponentOverlay>
              )}

              {!step && isDelegation && !isTripDidNotHavePlace && (
                <ComponentOverlay className='component-overlay--unsettled-lump-sums'>
                  <div className='component-overlay__message component-overlay__message--unsettled-lump-sums'>
                    <Icon className='icon-info-grey component-overlay__message-icon' type='info' />
                    <span>{trans('request-summary.unsettled-lump-sums-message')}</span>
                  </div>
                </ComponentOverlay>
              )}
            </Fragment>
          )}

          <RequestMileageSummary tableData={table} styleForTable={{ paddingBottom: '6.5rem' }} />
        </Panel>
      </div>
    )
  }
}

Summary.propTypes = {
  request: PropTypes.object.isRequired,
  defaultCurrency: PropTypes.string.isRequired,
}

Summary = borderCrossings()(Summary)
Summary = nonDelegation(Summary)
Summary = tripDidNotHavePlace(Summary)

export { Summary }
export default { Summary }
