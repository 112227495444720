import React from 'react'
import classnames from 'classnames'

class TooltipContent extends React.Component<
  {
    noArrow?: boolean
    children: React.ReactNode
    arrowPosition?: number
    className?: string
  },
  any
> {
  render() {
    const { noArrow, children, className, arrowPosition = 0, ...rest } = this.props
    const classes = classnames({
      'tooltip-content': true,
      'tooltip-content--no-arrow': noArrow,
      [className]: true,
    })

    const arrowStyle = {}
    if (arrowPosition > 0) {
      arrowStyle['left'] = `${Math.floor(arrowPosition)}px`
    }

    return (
      <div className={classes} {...rest}>
        {!noArrow && <span className='tooltip-content__arrow' style={arrowStyle} />}

        {children}
      </div>
    )
  }
}

export { TooltipContent }
export default { TooltipContent }
