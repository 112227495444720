import React from 'react'
import Icon from '../IconComponent'
import Button from '../ButtonComponent'
import { trans } from '../../../trans'

class FormFieldIconRadioGroup extends React.Component<any, any> {
  filterItems() {
    const {
      items,
      input: { value },
    } = this.props

    if (value) {
      return items.filter((item) => item.value === value)
    }

    return items
  }

  render() {
    const {
      label,
      meta: { error },
      input: { onChange, value },
    } = this.props
    const items = this.filterItems()

    return (
      <div className='form-group form-group--label-top icon-select'>
        <span className='form-group__label'>{label}</span>

        {items.map((item) => (
          <Icon
            className={value && 'expense-commute__icon-selected'}
            onClick={() => onChange(item.value)}
            type={item.icon}
            gradient='true'
          />
        ))}

        {value && (
          <Button outline xxs onClick={() => onChange(null)}>
            {trans('global.change')}
          </Button>
        )}

        {error && (
          <div className='form-group__error'>
            <p>{error}</p>
          </div>
        )}
      </div>
    )
  }
}

export { FormFieldIconRadioGroup }
export default { FormFieldIconRadioGroup }
