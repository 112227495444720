import { getElements } from '../index'

import { findIndex, includes } from 'lodash'
import {
  TRAVEL_ACCOMODATION_SUGGESTION,
  TRAVEL_COMPANY_CAR_TRIP,
  TRAVEL_EMPTY,
  TRAVEL_PLANE_TRIP,
  TRAVEL_PRIVATE_CAR_TRIP,
  TRAVEL_RENTED_CAR_TRIP,
  TRAVEL_TARGET_POINT,
  TRAVEL_TRAIN_TRIP,
  TRAVEL_BUS_TRIP,
  TRAVEL_PASSENGER_CAR_TRIP,
  TRAVEL_REPLACEMENT_CAR_TRIP,
} from '../../../../constants/travel'

class AccommodationLocationSuggester {
  constructor(state, current) {
    this.elements = getElements(state)
    this.state = state
    this.current = current

    this.currentIndex = findIndex(
      this.elements,
      (item) => item.id === current.id && item.type === current.type,
    )

    this.excludeTypes = [TRAVEL_RENTED_CAR_TRIP, TRAVEL_EMPTY, TRAVEL_ACCOMODATION_SUGGESTION]
  }

  isThereAnyTargetPoint() {
    return this.elements.find((element) => element.type === TRAVEL_TARGET_POINT)
  }

  isThereAnyLocation() {
    return this.elements.length > 0
  }

  findFirstTargetAfterCurrent() {
    if (
      this.elements[this.currentIndex + 1] !== undefined &&
      this.elements[this.currentIndex + 1]
    ) {
      return { ...this.elements[this.currentIndex + 1] }
    }

    return null
  }

  findFirstTargetBeforeCurrent() {
    if (
      this.elements[this.currentIndex - 1] !== undefined &&
      this.elements[this.currentIndex - 1]
    ) {
      return { ...this.elements[this.currentIndex - 1] }
    }

    return null
  }

  suggestLocation() {
    if (!this.isThereAnyLocation()) {
      return null
    }

    const firstTargetBeforeCurrent = this.findFirstTargetBeforeCurrent()

    if (firstTargetBeforeCurrent) {
      return this.resolveLocation(firstTargetBeforeCurrent, false)
    }

    const firstTargetAfterCurrent = this.findFirstTargetAfterCurrent()

    if (firstTargetAfterCurrent) {
      return this.resolveLocation(firstTargetAfterCurrent, true)
    }

    return null
  }

  resolveLocation(element, from = true) {
    let location = null

    if (!includes(this.excludeTypes, element.type)) {
      switch (element.type) {
        case TRAVEL_TRAIN_TRIP:
        case TRAVEL_PRIVATE_CAR_TRIP:
        case TRAVEL_BUS_TRIP:
        case TRAVEL_COMPANY_CAR_TRIP:
        case TRAVEL_PASSENGER_CAR_TRIP:
        case TRAVEL_REPLACEMENT_CAR_TRIP:
          location = from
            ? element.departure_location
            : element.virtual
            ? element.departure_location
            : element.destination_location
          break
        case TRAVEL_PLANE_TRIP:
          location = from
            ? element.flight_from
            : element.virtual
            ? element.flight_from
            : element.flight_to
          break
        default:
          location = element.location
          break
      }
    }

    if (!location || !location.lat || !location.long) {
      return null
    }

    location.name = location.city
    location.formatted_address = location.city

    return location
  }
}

export { AccommodationLocationSuggester }
export default AccommodationLocationSuggester
