import { useCallback, useEffect, useMemo, useState } from 'react'
import APIClient from '../../services/APIClient'
import { TRAVEL_TRAIN_TRIP } from '../../constants/travel'

export default function useMeansOfTransports(requestSlug: string) {
  const [isLoading, setIsLoading] = useState(false)
  const [availableOptions, setAvailableOptions] = useState<{ label: string; value: string }[]>([])
  const [requestOptions, setRequestOptions] = useState<string[]>([])
  const hasPrivateCar = useMemo(
    () => requestOptions.some((option) => option === 'private_car'),
    [requestOptions, requestSlug],
  )

  const saveRequestOptions = useCallback(
    (types) => {
      const previous = [...requestOptions]

      setRequestOptions(types)

      return APIClient.saveRequestMeansOfTransports(requestSlug, types).catch(() =>
        setRequestOptions(previous),
      )
    },
    [requestOptions, requestSlug],
  )

  useEffect(() => {
    setIsLoading(true)

    Promise.all([
      APIClient.getAllMeansOfTransports(requestSlug),
      APIClient.getRequestMeansOfTransports(requestSlug),
    ]).then(([all, current]) => {
      setAvailableOptions(
        Object.keys(all).map((value) => ({
          value,
          label: all[value],
          fixed: value === 'private_car', // do not allow to remove private car, but allow to add it
        })),
      )
      setRequestOptions(current)
      setIsLoading(false)
    })
  }, [requestSlug])

  return {
    availableOptions,
    requestOptions,
    hasPrivateCar,
    saveRequestOptions,
    isLoading,
  }
}
