import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import {
  reset,
  fetch,
  getItems,
  getIsLoading,
  getIsLoaded,
  uploadDocument,
} from '../../store/app/dashboard-current-trips'
import { getCurrency as getInstanceCurrency } from '../../store/app/instance/index'

class DashboardCurrentTripsManagerBase extends React.Component<any, any> {
  componentDidMount() {
    const { reset, fetch } = this.props

    reset()
    fetch()
  }

  render() {
    const { children, items, isLoading, isLoaded, instanceCurrency, uploadDocument, currentUser } =
      this.props

    const renderProps = {
      items,
      isLoading: isLoading || !isLoaded,
      isLoaded,
      instanceCurrency,
      uploadDocument,
      currentUser,
    }

    return children(renderProps)
  }
}

DashboardCurrentTripsManagerBase.propTypes = {
  children: PropTypes.func.isRequired,
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      reset,
      fetch,
      uploadDocument,
    },
    dispatch,
  )
}

const mapStateToProps = (state) => ({
  items: getItems(state),
  isLoading: getIsLoading(state),
  isLoaded: getIsLoaded(state),
  instanceCurrency: getInstanceCurrency(state),
  currentUser: state.get('global').get('currentUser'),
})

const DashboardCurrentTripsManager = connect(
  mapStateToProps,
  mapDispatchToProps,
)(DashboardCurrentTripsManagerBase)

export { DashboardCurrentTripsManager }
export default DashboardCurrentTripsManager
