import React, { Component } from 'react'
import { connect } from 'react-redux'
import {
  getSubstitutionsAction,
  getSubstitutions,
  deleteSubstitutionAction,
  reset,
} from '../../../../store/app/substitutions'
import moment from 'moment'
import { config } from '../../../../config'
import Button from '../../../ui/ButtonComponent'
import trans from '../../../../trans'
import PropTypes from 'prop-types'
import Avatar from '../../../Avatar/Avatar'

class SubstitutionsTable extends Component<any, any> {
  constructor(props) {
    super(props)

    this.state = {
      userAvatars: {},
    }
  }

  componentDidMount() {
    this.props.reset()

    if (
      this.props.subjectUser &&
      this.props.subjectUser.slug &&
      this.props.isFromExternal === true
    ) {
      this.props.getSubstitutionsAction(this.props.subjectUser.slug)
    }

    if (!this.props.isFromExternal) {
      this.props.getSubstitutionsAction()
    }
  }

  componentWillUnmount() {
    this.props.reset()
  }

  renderUserInfo = (user) => {
    if (!user) return null

    return (
      <div className='substitutions__user'>
        <Avatar className='substitutions__user-avatar' user={user} />

        <div>
          <span className='substitutions__user-detail substitutions__user-detail--bold'>
            {user.full_name}
          </span>
          <a href={`mailto:${user.email}`} className='substitutions__user-detail'>
            {user.email}
          </a>
        </div>
      </div>
    )
  }

  handleDelete = (substitution) => {
    this.props.deleteSubstitutionAction(
      substitution.id,
      this.props.subjectUser && this.props.subjectUser.slug ? this.props.subjectUser.slug : null,
    )
  }

  render() {
    if (this.props.data.length === 0) {
      return <strong>{trans('global.no-results')}</strong>
    }

    return (
      <div className='substitutions-table'>
        <div className='substitutions-table__header substitutions-table__row'>
          <div className='substitutions-table__cell'>{trans('global.user')}</div>
          <div className='substitutions-table__cell'>{trans('user.from')}</div>
          <div className='substitutions-table__cell'>{trans('user.to')}</div>
          <div className='substitutions-table__cell'>{trans('user.operations')}</div>
        </div>

        {this.props.data.map((substitution) => (
          <div className='substitutions-table__row' key={substitution.id}>
            <div className='substitutions-table__cell'>
              {this.renderUserInfo(substitution.deputy)}
            </div>
            <div className='substitutions-table__cell'>
              {moment(substitution.from).format(config.apiDateFormat)}
            </div>
            <div className='substitutions-table__cell'>
              {moment(substitution.to).format(config.apiDateFormat)}
            </div>
            <div className='substitutions-table__cell'>
              <Button xxs outline onClick={() => this.handleDelete(substitution)}>
                {trans('user.delete')}
              </Button>
            </div>
          </div>
        ))}
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  data: getSubstitutions(state),
  reset: reset,
})

const mapDispatchToProps = {
  getSubstitutionsAction,
  deleteSubstitutionAction,
  reset,
}

SubstitutionsTable.propTypes = {
  subjectUser: PropTypes.object,
}

export default connect(mapStateToProps, mapDispatchToProps)(SubstitutionsTable)
