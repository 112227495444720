import React, { Component } from 'react'
import { Loader } from '../ui/LoadingOverlay/Loader'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'

class PureBaseRequestPage extends Component<any, any> {
  componentDidMount() {
    const {
      action,
      history: { push },
    } = this.props

    if (action && push) {
      this.props
        .action()
        .then(() => push('/dashboard'))
        .catch(() => push('/dashboard'))
    }
  }

  render() {
    return <Loader />
  }
}

PureBaseRequestPage.propTypes = {
  action: PropTypes.func.isRequired,
}

export const BaseRequestPage = withRouter(PureBaseRequestPage)
