import React, { memo } from 'react'
import { Link, NavLink, withRouter } from 'react-router-dom'
import classNames from 'classnames'

import Icon from '../../components/ui/IconComponent'
import { trans } from '../../trans'

import MindentoLogo from '../../../../assets/images/mindento_logo_gradient.png'
import uuid from '../../utils/uuid'
import { history } from '../../store'

const isActive = (match, location, path) => {
  return location.pathname.indexOf(path) === 0
}

const addChildrensToParents = (items) => {
  const menuParents = items.filter((item, index) => {
    if (!item.parent && item.showInMenu) {
      item.submenu = []
      return item
    }
  })

  const menuChildrens = items.filter((item, index) => {
    if (item.parent && item.showInMenu) {
      return true
    }
  })

  menuChildrens.forEach((element) => {
    menuParents.forEach((parent) => {
      if (parent.name === element.parent) {
        parent.submenu.push(element)
      }
    })
  })

  return menuParents
}

const MainNavList = (props) => {
  const { items, expand, open, handleMainNavExpandClick, handleMainNavOpenClick } = props

  const reloadPage = (event, targetUrl) => {
    const active = targetUrl === props.location.pathname

    if (active) {
      event.preventDefault()

      history.push('/reload')

      setTimeout(() => history.replace(targetUrl))
    }
  }

  const menuClasses = classNames({
    'app__main-nav main-nav': true,
    small: !expand,
    open: open,
  })

  const menuWithChildrens = addChildrensToParents(items)

  const menuList = menuWithChildrens.map((element, element_index) => {
    let menu_element_jsx = (
      <li key={element_index} className='main-nav__list-element'>
        <NavLink
          exact
          to={element.path}
          onClick={(e) => reloadPage(e, element.path)}
          className='main-nav__list-element-name'
        >
          <span className='main-nav__icon'>
            <Icon type={element.icon} gradient='true' />
          </span>

          <span>{element.name}</span>
        </NavLink>
      </li>
    )

    if (element.submenu) {
      let submenu = element.submenu.map((subelement, subelement_index) => {
        return (
          <li key={subelement + '_' + subelement_index} className='main-nav__list-element'>
            <NavLink
              exact
              to={subelement.path}
              className='main-nav__list-element-name'
              onClick={(e) => reloadPage(e, subelement.path)}
              isActive={(match, location) => isActive(match, location, subelement.path)}
            >
              <span className='main-nav__icon'>
                <Icon type={subelement.icon} gradient='true' />
              </span>
              <span>{trans(subelement.label)}</span>
            </NavLink>
          </li>
        )
      })

      menu_element_jsx = (
        <li key={element_index} className='main-nav__list-element main-nav__list-element--has-menu'>
          <NavLink
            to={element.path}
            onClick={(e) => reloadPage(e, element.path)}
            className='main-nav__list-element-name main-nav__list-element-name--has-menu'
            isActive={(match, location) => isActive(match, location, element.path)}
          >
            <span className='main-nav__icon'>
              <Icon type={element.icon} gradient='true' />
            </span>
            <span>{trans(element.label)}</span>
          </NavLink>
          <ul className='main-nav__list main-nav__list--submenu'>{submenu}</ul>
        </li>
      )
    }

    return menu_element_jsx
  })

  // If logo is empty, add class .logo--empty

  return (
    <aside className={menuClasses}>
      <div className='main-nav__logo'>
        <Link key={uuid()} to='/dashboard'>
          <img className='logo' src={MindentoLogo} alt='Logo Mindento' />
        </Link>
      </div>
      <div className='main-nav__wrapper'>
        <ul className='main-nav__list main-nav__list--main-menu'>{menuList}</ul>

        <button className='main-nav__expand' onClick={handleMainNavExpandClick}>
          <Icon type='arrow' />
        </button>
      </div>

      <button className='main-nav__open' onClick={handleMainNavOpenClick}>
        <span></span>
      </button>
    </aside>
  )
}

export default memo(withRouter(MainNavList))
