import PropTypes from 'prop-types'
import React from 'react'
import trans from '../../../trans'
import { RouteManager } from '../../../containers/RouteManager/index'
import { TimelineAdd } from '../../Timeline/index'
import { OtherCostsManager } from '../../../containers/OtherCostsManager/index'
import { OtherCostForm } from '../../../containers/OtherCostForm/index'
import Button from '../../ui/ButtonComponent'
import { RequestHeader } from '../../RequestPageCommon/RequestHeader'
import { ExpenseRequestSummary } from '../ExpenseRequestSummary'
import { Installments } from '../../RequestPageCommon/Installments/Installments'
import { RequestComments } from '../../RequestPageCommon/RequestComments'
import { Ability } from '../../RequestPageCommon/Ability/Ability'
import { AddComment } from '../../RequestPageCommon/AddComment'
import * as constants from '../../../constants/request'
import { BasicInfoManager } from '../../../containers/BasicInfoManager'
import { LoadingOverlay } from '../../ui/LoadingOverlay'
import { Preview as OtherCostPreview } from '../../OtherCostForm'
import { BasicInfoForm } from '../../../containers/BasicInfoForm'
import { Preview as BasicInfoPreview } from '../../BasicInfoForm'
import { stopSubmit } from 'redux-form'
import store from '../../../store'
import { FORM_NAME as BASIC_INFO_FORM_NAME } from '../../../containers/BasicInfoForm/BasicInfoForm'
import { Loader } from '../../ui/LoadingOverlay/Loader'
import { get } from 'lodash'
import { processAPIerrorResponseToFormErrors } from '../../../services/APIClient'
import { AccountDimensionManager } from '../../../containers/AccountDimensionManager'
import BasicRequestInfoRenderer from '../../RequestBasicInfoRenderer/RequestBasicInfoRenderer'

class ExpenseRequestPageDraft extends React.Component<any, any> {
  sendToAcceptance(e, push, getRouteByName) {
    const { sendToAcceptance, request } = this.props
    e.preventDefault()

    sendToAcceptance()
      .then((response) => {
        const autoaccepted = get(response, 'data.autoaccepted', false)
        push(getRouteByName('main', `expenseRequestsList`))
        if (autoaccepted) {
          push(getRouteByName('main', 'expenseRequestShow', { id: request.slug }))
        }
      })
      .catch((response) => {
        if (response.alerts) {
          const errors = processAPIerrorResponseToFormErrors(response.alerts, true)
          store.dispatch(stopSubmit(BASIC_INFO_FORM_NAME, errors))
        } else {
          store.dispatch(stopSubmit(BASIC_INFO_FORM_NAME, response))
        }
      })
  }

  render() {
    const {
      request,
      currencies,
      currentUser,
      deleteRequest,
      changeRequestValue,
      isModuleActive,
      saveDecision,
    } = this.props

    return (
      <RouteManager>
        {({ push, getRouteByName }) => (
          <div>
            <RequestHeader request={request} />

            <BasicRequestInfoRenderer />

            <section className='section'>
              <header className='section__header'>
                <h2 className='h2 section__header-title'>{trans('request.items')}</h2>
              </header>
              <OtherCostsManager request={request}>
                {({ elements, types, onAdd, onEdit, onRemove, isElementOpen, isSaving }) => {
                  return (
                    <div className='timeline-container timeline-container--other-costs has-loader'>
                      {isSaving && <Loader />}

                      {!request['abilities']['edit'] &&
                        elements.map((element) => {
                          const key = `other-cost-form-${element['id']}`
                          return (
                            <OtherCostPreview
                              key={key}
                              form={key}
                              element={element}
                              request={request}
                              types={types}
                            />
                          )
                        })}
                      {request['abilities']['edit'] &&
                        elements.map((element) => {
                          const key = `other-cost-form-${element['id']}`
                          return (
                            <OtherCostForm
                              key={key}
                              form={key}
                              element={element}
                              onRemove={onRemove}
                              onEdit={onEdit}
                              request={request}
                              currencies={currencies}
                              label=' '
                              types={types}
                              isElementOpen={isElementOpen(element)}
                            />
                          )
                        })}

                      <Ability ability={['edit']}>
                        <TimelineAdd icon='plus' gradient='true' onClick={() => onAdd()} last>
                          {trans('global.add')}
                        </TimelineAdd>
                      </Ability>
                    </div>
                  )
                }}
              </OtherCostsManager>
            </section>

            <ExpenseRequestSummary request={request} />

            <Installments request={request} currentUser={currentUser} />

            <Ability ability={['sendToAcceptance']}>
              <section className='section section--no-border section--no-border-top'>
                <AddComment request={request} changeRequestValue={changeRequestValue} />
              </section>
            </Ability>

            <div className='button-group'>
              <Ability ability={['delete']}>
                <Button
                  onClick={(e) => {
                    e.preventDefault()
                    deleteRequest().then(() => {
                      push(getRouteByName('main', `expenseRequestsList`))
                    })
                  }}
                  link
                >
                  {trans('request.delete-request')}
                </Button>
              </Ability>

              <Ability ability={['edit']}>
                <Button
                  key='save-draft'
                  onClick={(e) => {
                    e.preventDefault()
                    saveDecision(
                      request.comment &&
                        request.comment.content &&
                        request.comment.content.length > 0
                        ? { comment: request.comment }
                        : undefined,
                    ).then(() => {
                      push(getRouteByName('main', `expenseRequestsList`))
                    })
                  }}
                  data-test='save-draft'
                  outline
                  pull_end
                >
                  {trans('request.save-draft')}
                </Button>
              </Ability>

              <Ability ability={['sendToAcceptance']}>
                <Button
                  key='save-send'
                  data-test='save-send'
                  onClick={(e) => this.sendToAcceptance(e, push, getRouteByName)}
                  primary
                  pull_end
                >
                  {trans('request.send')}
                </Button>
              </Ability>
            </div>

            <RequestComments comments={request['comments']} />
          </div>
        )}
      </RouteManager>
    )
  }
}

ExpenseRequestPageDraft.propTypes = {
  request: PropTypes.object.isRequired,
  sendToAcceptance: PropTypes.func.isRequired,
  currencies: PropTypes.array.isRequired,
  currentUser: PropTypes.object.isRequired,
  deleteRequest: PropTypes.func.isRequired,
  changeRequestValue: PropTypes.func.isRequired,
  updateRequest: PropTypes.func.isRequired,
  isModuleActive: PropTypes.func.isRequired,
  saveDecision: PropTypes.func.isRequired,
}

export { ExpenseRequestPageDraft }
export default { ExpenseRequestPageDraft }
