import React from 'react'
import { AmountFormatter } from '../AmountFormatter'
import { trans } from '../../trans'
import classNames from 'classnames'

const KPITimeline = ({ items }) => {
  const totalKPI = items.reduce((sum, item) => sum + item.kpi, 0)
  const sumAverageKPI = items
    .reduce((sum, item) => sum + (item.average ? parseFloat(item.average) : 0), 0)
    .toFixed(1)
  return (
    <React.Fragment>
      <div className='reports-timeline'>
        <div className='reports-timeline--item reports-timeline--header'>
          <div className='reports-timeline--item__name'>{trans('reports.new-application')}</div>
          <div>KPI ({trans('accounting.kpi-unit')})</div>
        </div>
        {items.map((item) => (
          <div key={item.slug} className='reports-timeline--item'>
            <div className='reports-timeline--item__name'>
              {trans(item.slug)}
              {item.average && (
                <div
                  className={classNames({
                    'reports-timeline--item__summary': true,
                    'is-color-danger': item.average > item.kpi,
                  })}
                >
                  {trans('reports.average-kpi', { value: item.average })}
                </div>
              )}
            </div>
            {item.kpi && (
              <div className='reports-timeline--item__days'>
                &le; <AmountFormatter amount={item.kpi} />
              </div>
            )}
          </div>
        ))}
      </div>

      <div className='reports-timeline--summary'>
        <div className='reports-timeline--summary__title'>
          {trans('reports.total-process')}:{' '}
          <span
            className={classNames({
              'reports-timeline--summary__count': true,
              'is-color-danger': sumAverageKPI > totalKPI,
            })}
          >
            {trans('reports.sum-average-kpi', { value: sumAverageKPI })}
          </span>
        </div>
        <div className='reports-timeline--item__days'>
          &le; <AmountFormatter amount={totalKPI} />
        </div>
      </div>
    </React.Fragment>
  )
}

export { KPITimeline }
export default KPITimeline
