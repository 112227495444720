import PropTypes from 'prop-types'
import React from 'react'
import _ from 'lodash'
import { Section } from '../ui/Section'
import { round } from '../../utils/number'
import trans from '../../trans'
import { AmountFormatter } from '../AmountFormatter'
import { renderKey } from '../../utils/react'

class RequestBalance extends React.Component<any, any> {
  renderBalance(balance) {
    if (_.isEmpty(balance) || !_.isArray(balance)) {
      return null
    }

    const result = [
      <div
        className='accordion table-accordion__accordion'
        key={renderKey('request-balance-balance')}
      >
        <div key='balance-row-title' className='table-accordion__row row'>
          <div className='table-accordion__col xs-9' />
          <div className='table-accordion__col xs-3 is-allign-end'>
            <strong>{trans('request-summary.amount')}</strong>
          </div>
        </div>
      </div>,
    ]

    balance.forEach(({ values, name }) => {
      if (!_.isEmpty(values) && _.isArray(values)) {
        result.push(
          <div
            key={renderKey('request-balance-row', name)}
            className='accordion table-accordion__accordion'
          >
            <div className='table-accordion__row row'>
              <div className='table-accordion__col xs-9'>{trans(name)}</div>
              <div className='table-accordion__col xs-3 is-allign-end'>
                {values.map(({ amount, currency }, i) => (
                  <div key={renderKey('request-balance-amount-row', i, currency)}>
                    <strong>
                      <AmountFormatter amount={round(amount, 2)} /> {currency}
                    </strong>
                  </div>
                ))}
              </div>
            </div>
          </div>,
        )
      }
    })

    return (
      <div className='table-accordion-container'>
        <div className='table-accordion'>
          <div className='table-accordion__body'>{result}</div>
        </div>
      </div>
    )
  }

  render() {
    const { balance, noBorder = false } = this.props

    return (
      <Section className='summary-balance' noMargin noPadding noBorder={noBorder}>
        <h2 className='h2 section__header-title'>{trans('request-summary.settlement-balance')}</h2>

        {balance && this.renderBalance(balance)}
      </Section>
    )
  }
}

RequestBalance.propTypes = {
  balance: PropTypes.array,
  noBorder: PropTypes.bool,
}

export { RequestBalance }
export default { RequestBalance }
