import React from 'react'
import { trans } from '../../trans'
import {
  TimelineElement,
  TimeLineElementContent,
  TimeLineElementHeader,
  TimelineElementLabel,
} from '../Timeline'
import { Col, Row } from '../../ui/Grid'
import { TypesMenu } from './TypesMenu'
import Icon from '../ui/IconComponent'

class SelectType extends React.Component<any, any> {
  constructor(props) {
    super(props)
  }

  shouldComponentUpdate() {
    return false
  }

  render() {
    const { onChangeType, element, onRemove } = this.props
    return (
      <TimelineElement type={element.type}>
        <TimeLineElementHeader icon='plus' gradient='true'>
          <Col xs={8}>{trans('request.select-type-of-travel-element')}</Col>
          <Col xs={4} className='is-allign-end'>
            <span className='timeline-icon' onClick={() => onRemove(element)}>
              <Icon type='trash' lg gradient />
            </span>
          </Col>
        </TimeLineElementHeader>
        <TimeLineElementContent isOpened='open'>
          <TypesMenu onSelect={(type) => onChangeType(element, type)} selected={null} />
        </TimeLineElementContent>
      </TimelineElement>
    )
  }
}

export { SelectType }
export default { SelectType }
