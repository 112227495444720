import React from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import {
  fetchEditableMpk,
  getMpksTotalCount,
  getPage,
  isLoading as isMpkLoading,
  getMpks,
} from '../../../../store/app/mpks'
import { Cell, Row } from '../../../ui/AccordionTable'
import trans from '../../../../trans'
import Button from '../../../ui/ButtonComponent'
import MpkListItem from './MpkListItem'
import MpkListFormItem from './MpkListFormItem'
import {
  fetchCompanies,
  getCompanies,
  getCompaniesWithAllForSelectOptionsSelector,
  isLoading as isCompaniesLoading,
} from '../../../../store/app/companies/slice'
import { SelectField } from '../../../ui/Form'
import { CompanyBaseList } from '../../CompanyBaseList'

class MpkList extends CompanyBaseList {
  constructor(props) {
    super(props)

    this.selectCompany = this.selectCompany.bind(this)
  }

  componentDidMount() {
    const { fetchEditableMpk, fetchCompanies } = this.props

    // fetchEditableMpk();
    fetchCompanies()
  }

  getIdentifierKey() {
    return 'id'
  }

  selectCompany(value) {
    this.setState({ company: value })
  }

  getFormComponent() {
    return MpkListFormItem
  }

  getListItemComponent() {
    return MpkListItem
  }

  renderNewForm() {
    if (!this.state.adding) {
      return
    }

    const item = {
      company_id: this.state.company,
      is_active: true,
    }

    return this.renderForm(item)
  }

  renderCompanySelect() {
    const { companies } = this.props
    const { adding } = this.state

    return (
      <div className='table__header-select'>
        <span>{trans('instance-users.company-filter')}</span>
        <SelectField
          options={companies}
          value={this.state.company}
          disabled={adding}
          onChange={this.selectCompany}
          placeholder={trans('global.all')}
        />
      </div>
    )
  }

  renderTableHeader() {
    return (
      <Row>
        <Cell fixedWidth={150}>{trans('accounting.mpk-code')}</Cell>
        <Cell fixedWidth='auto'>{trans('accounting.mpk-name')}</Cell>
        <Cell fixedWidth={250}>{trans('accounting.company-code')}</Cell>
        <Cell fixedWidth={120}>{trans('accounting.is_active')}</Cell>
        <Cell fixedWidth={180} alignRight />
      </Row>
    )
  }

  renderHeader() {
    const { adding } = this.state

    return (
      <div className='table__header'>
        <div className='table__header-actions'>
          {this.renderCompanySelect()}

          <Button primary xs className={'btn--tiny'} disabled={adding} onClick={this.addNew}>
            {trans('global.add')}
          </Button>
        </div>
      </div>
    )
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      setPage: fetchEditableMpk,
      fetchEditableMpk,
      fetchCompanies,
    },
    dispatch,
  )
}

const mapStateToProps = (state) => {
  return {
    companies: getCompaniesWithAllForSelectOptionsSelector(state, 'all'),
    page: getPage(state),
    total: getMpksTotalCount(state),
    items: getMpks(state),
    isLoading: isMpkLoading(state) || isCompaniesLoading(state),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(MpkList)
