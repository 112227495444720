import React, { Fragment } from 'react'
import Pagination from './CompanyPage/Pagination/Pagination'
import Button from '../ui/ButtonComponent'
import trans from '../../trans'
import { Table, TBody, THead } from '../ui/AccordionTable'
import { LoadingOverlay } from '../ui/LoadingOverlay'

export class CompanyBaseList<
  P,
  S extends { editable: any; adding: boolean },
> extends React.Component<P, S> {
  pagination = true
  canCreate = true

  constructor(props) {
    super(props)

    this.state = {
      editable: {},
      adding: false,
    }

    this.addNew = this.addNew.bind(this)
    this.save = this.save.bind(this)
    this.makeEditable = this.makeEditable.bind(this)
    this.renderRow = this.renderRow.bind(this)
    this.cancelEditing = this.cancelEditing.bind(this)
  }

  getIdentifierKey() {
    throw new Error('Not implemented yet. Please overwrite this method.')
  }

  renderTableHeader() {
    throw new Error('Not implemented yet. Please overwrite this method.')
  }

  getFormComponent() {
    throw new Error('Not implemented yet. Please overwrite this method.')
  }

  getListItemComponent() {
    throw new Error('Not implemented yet. Please overwrite this method.')
  }

  renderNewForm() {
    throw new Error('Not implemented yet. Please overwrite this method.')
  }

  renderRow(item) {
    if (this.isEditable(item[this.getIdentifierKey()])) {
      return this.renderForm(item)
    }

    return this.renderListItem(item)
  }

  componentDidMount() {
    const { fetch } = this.props

    fetch()
  }

  addNew() {
    if (!this.canCreate) {
      return
    }

    this.setState({ adding: true })
  }

  makeEditable(item) {
    this.setState({
      editable: {
        ...this.state.editable,
        [item[this.getIdentifierKey()]]: true,
      },
    })
  }

  cancelEditing(item) {
    if (!(item[this.getIdentifierKey()] in this.state.editable)) {
      this.setState({ adding: false })
    }

    this.setState({
      editable: {
        ...this.state.editable,
        [item[this.getIdentifierKey()]]: false,
      },
    })
  }

  save(item) {
    this.cancelEditing(item)
  }

  isEditable(identifier) {
    return this.state.editable[identifier]
  }

  isLoading() {
    return this.props.isLoading
  }

  renderNotFound() {
    const { items } = this.props
    const { adding } = this.state

    if (this.isLoading() || items.length || adding) {
      return
    }

    return <p>{trans('accounting.items-not-found')}</p>
  }

  getExtraParams() {
    return {}
  }

  renderForm(item) {
    const Component = this.getFormComponent()

    return (
      <Component
        key={item[this.getIdentifierKey()]}
        save={this.save}
        cancel={this.cancelEditing}
        item={item}
        {...this.getExtraParams()}
      />
    )
  }

  renderListItem(item) {
    const Component = this.getListItemComponent()

    return (
      <Component
        key={item[this.getIdentifierKey()]}
        edit={() => this.makeEditable(item)}
        {...item}
        {...this.getExtraParams()}
      />
    )
  }

  renderTableContent() {
    const { items } = this.props

    return (
      <Fragment>
        {this.canCreate ? this.renderNewForm() : null}

        {items.map(this.renderRow)}
      </Fragment>
    )
  }

  renderTable() {
    const { items } = this.props
    const { adding } = this.state

    if (this.isLoading() || (!items.length && !adding)) {
      return
    }

    return (
      <Table className={'table-stylesheet-theme table-with-form align-center'} striped>
        <THead>{this.renderTableHeader()}</THead>
        <TBody isLoading={this.isLoading()}>{this.renderTableContent()}</TBody>
      </Table>
    )
  }

  renderPagination() {
    const { page, total, setPage } = this.props

    if (!this.pagination || this.isLoading()) {
      return
    }

    return <Pagination page={page} total={total} changePage={setPage} />
  }

  renderHeader() {
    const { adding } = this.state

    if (!this.canCreate) {
      return
    }

    return (
      <div className='table__header'>
        <div className='table__header-actions'>
          <Button primary xs className={'btn--tiny'} disabled={adding} onClick={this.addNew}>
            {trans('global.add')}
          </Button>
        </div>
      </div>
    )
  }

  renderLoading() {
    if (!this.isLoading()) {
      return
    }

    return <LoadingOverlay />
  }

  renderFooter() {
    return null
  }

  render() {
    return (
      <Fragment>
        {this.renderHeader()}
        {this.renderLoading()}
        {this.renderTable()}
        {this.renderNotFound()}
        {this.renderPagination()}
        {this.renderFooter()}
      </Fragment>
    )
  }
}
