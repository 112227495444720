import PropTypes from 'prop-types'
import React, { Fragment } from 'react'
import trans from '../../trans'
import { STATUS_UPCOMING_TRIP, STATUS_TRIP } from '../../constants/request'

class TripRequestTitleRight extends React.Component<any, any> {
  render() {
    const { request } = this.props

    return (
      <div>
        {request['trip_agent'] && (
          <Fragment>
            <h2 className='h2 is-margin-bottom-small'>{trans('request.efficient-info')}</h2>

            <div>
              <span>{trans('request.travel-keeper')}</span>
              <p dangerouslySetInnerHTML={{ __html: request['trip_agent'] }} />
            </div>
          </Fragment>
        )}

        {!request['trip_agent'] && (
          <div>
            <p>{trans('request.no-efficient-info')}</p>
          </div>
        )}
      </div>
    )
  }
}

TripRequestTitleRight.propTypes = {
  request: PropTypes.object.isRequired,
}

export { TripRequestTitleRight }
export default { TripRequestTitleRight }
