import React, { Component, Fragment } from 'react'
import { connect as flightsConnect } from '../../../containers/FlightsBooking'
import { RequestDocuments } from '../../RequestPageCommon/RequestDocuments'
import { get } from 'lodash'
import EnableReservationEngine from '../../EnableReservationEngine'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { getDocuments } from '../../../store/app/document-list'
import { isFlightSearchEnabled } from '../../../store/app/trip-request'

class DocumentsAndBackToSearchingComponent extends Component<any, any> {
  render() {
    const {
      context: { element, request },
    } = this.props
    const {
      flights: {
        actions: { backToSearching },
      },
      isSearchFeatureEnabled,
    } = this.props

    const documents = get(element, 'documents', [])

    return (
      <Fragment>
        <RequestDocuments
          element={element}
          documents={documents}
          request={request}
          listName={`${element.type}-${element.id}`}
          fragment={false}
        />

        {isSearchFeatureEnabled && this.props.documents.length === 0 && (
          <EnableReservationEngine action={backToSearching} />
        )}
      </Fragment>
    )
  }
}

const withDocuments = connect((state, props) => {
  const {
    context: { element, request },
  } = props
  return {
    documents: getDocuments(`${element.type}-${element.id}`)(state),
    isSearchFeatureEnabled: isFlightSearchEnabled(state),
  }
})

const DocumentsAndBackToSearching = compose(
  flightsConnect,
  withDocuments,
)(DocumentsAndBackToSearchingComponent)

export { DocumentsAndBackToSearching }
export default DocumentsAndBackToSearching
