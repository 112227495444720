import PropTypes from 'prop-types'
import React from 'react'
import _ from 'lodash'
import classnames from 'classnames'
import Select from 'react-select'
import { components } from '../../../utils/react-select'

export class CurrencyField extends React.Component<any, any> {
  handleOnChange(item) {
    const { onChange } = this.props

    if (_.isFunction(onChange)) {
      onChange(_.get(item, 'value'))
    }
  }

  render() {
    const {
      name,
      className,
      currencies,
      value,
      placeholder,
      disabled,
      onFocus,
      noBorder,
      onChange = undefined,
      ...rest
    } = this.props
    const classes = classnames({
      'react-select': true,
      'react-select--no-border': noBorder,
      'react-select__currency': true,
      [className]: true,
    })

    const selectedValue = currencies.find(({ value: _value }) => {
      return _value === value
    })

    return (
      <div className={classes}>
        <Select
          {...rest}
          name={name}
          options={currencies}
          value={selectedValue}
          onChange={this.handleOnChange.bind(this)}
          placeholder={placeholder}
          isDisabled={disabled}
          onFocus={onFocus}
          isClearable={false}
          type='small'
          label=' '
          components={components}
        />
      </div>
    )
  }
}

CurrencyField.propTypes = {
  onChange: PropTypes.func,
  name: PropTypes.string,
  className: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  placeholder: PropTypes.string,
  currencies: PropTypes.array,
  disabled: PropTypes.bool,
  noBorder: PropTypes.bool,
}

CurrencyField.defaultProps = {}
