import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { Col } from '../../ui/Grid'
import { Link } from 'react-router-dom'
import { Tooltip } from '../Tooltip'
import Icon from '../ui/IconComponent'
import _ from 'lodash'
import { LoadingOverlay } from '../ui/LoadingOverlay'
import ReactDOM from 'react-dom'
import jQuery from 'jquery'

// it may be buggy when grid has no fixed width
class DocumentCellPresentation extends React.PureComponent<any, any> {
  constructor(props) {
    super(props)
    this.state = {
      deleteInProgress: false,
      removed: false,
      toTransformData: {},
    }
    this.toTransformRefs = {}
    this.toTransformData = {}
  }

  componentDidMount() {
    this.setState({ toTransformData: {} }, () => {
      this.transformDescriptions()
    })

    window.addEventListener('resize', this.transformDescriptionTimeout)
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.transformDescriptionTimeout)
  }

  transformDescriptionTimeout = () => {
    clearTimeout(this.timeout)
    this.timeout = setTimeout(() => {
      this.setState({ toTransformData: {} }, () => {
        this.transformDescriptions()
      })
    }, 3000)
  }

  transformDescriptions() {
    if (_.isEmpty(this.toTransformData)) return false

    let update = false
    const newData = {}

    Object.keys(this.toTransformData).forEach((key) => {
      let lineHeight
      try {
        lineHeight = Number(
          String(
            window.getComputedStyle(this.toTransformRefs[key]).getPropertyValue('line-height'),
          ).replace(/[^0-9.]/g, ''),
        )
      } catch (e) {
        lineHeight = 1
      }

      const height = jQuery(this.toTransformRefs[key]).height()
      const numberOfLines = Math.ceil(height / lineHeight)

      const enabled = numberOfLines > 1
      if (enabled) {
        update = true
      }

      newData[key] = {
        ...this.toTransformData[key],
        enabled,
      }
    })

    if (update) {
      this.setState({ toTransformData: {} }, () => {
        this.setState({ toTransformData: newData })
      })
    }
  }

  renderMultipleIcons(icons) {
    if (_.isEmpty(icons)) {
      return null
    }

    if (icons.length === 1) {
      return icons[0]
    }

    return (
      <span style={{ position: 'relative' }}>
        <Tooltip
          html={
            <div>
              {icons.map((Icon) => {
                return Icon
              })}
            </div>
          }
        >
          <Icon
            type='arrow_more'
            grey
            className='icon--inline icon--document-cover icon--document-more'
          />
        </Tooltip>
      </span>
    )
  }

  renderComponent(component, tooltip) {
    const Component = () => <Fragment>{component}</Fragment>

    if (tooltip) {
      const TooltipContent = () => {
        return <Fragment>{tooltip}</Fragment>
      }

      return (
        <Tooltip html={<TooltipContent />}>
          <Component />
        </Tooltip>
      )
    }

    return <Component />
  }

  smartTransform(key, component) {
    key = `key${key}`

    let disabled = true
    if (this.state.toTransformData[key] && this.state.toTransformData[key]['enabled']) {
      disabled = false
    }

    const Component = () => (
      <div
        className={classNames({
          'document-cell__content-data__truncate-css': !disabled,
        })}
        ref={(element) => {
          this.toTransformRefs[key] = ReactDOM.findDOMNode(element)

          const text = jQuery(this.toTransformRefs[key]).text()

          if (!text) {
            return
          }

          this.toTransformData[key] = {
            text,
            key,
          }
        }}
      >
        {component}
      </div>
    )

    const TooltipContent = () => {
      return <div>{component}</div>
    }

    return (
      <Tooltip html={<TooltipContent />} disabled={disabled}>
        <Component />
      </Tooltip>
    )
  }

  renderContentBottom() {
    const { contentBottom } = this.props
    const {
      text: { row1: { component: component1, smartTruncateCSS: smartTruncateCSS1 } = {} } = {},
    } = contentBottom || {}
    const { text: { row2: { component: component2, tooltip: tooltip2 } = {} } = {} } =
      contentBottom || {}
    const {
      text: { row3: { component: component3, smartTruncateCSS: smartTruncateCSS3 } = {} } = {},
    } = contentBottom || {}
    const {
      icons: {
        icon1: { component: icon1 } = {},
        icon2: { component: icon2 } = {},
        icon3: { components: icon3components = [] } = {},
      } = {},
    } = contentBottom || {}

    return [
      <div className='document-cell__content-data' key='content-data'>
        <div>
          {smartTruncateCSS1 && this.smartTransform(1, component1)}
          {!smartTruncateCSS1 && component1}
        </div>
        <div
          className={classNames({
            'document-cell__content-data__truncate-css': true,
          })}
        >
          {this.renderComponent(component2, tooltip2)}
        </div>
        {}
        <div>
          {smartTruncateCSS3 && this.smartTransform(3, component3)}
          {!smartTruncateCSS3 && component3}
        </div>
      </div>,
      <div className='document-cell__content-icons' key='content-icons'>
        <div className='document-cell__content-icons__bottom-icons'>
          {icon1}
          {icon2}
        </div>
        <div className='document-cell__content-icons__expense-icons'>
          {this.renderMultipleIcons(icon3components)}
        </div>
      </div>,
    ]
  }

  renderButton() {
    const { button } = this.props

    return <div className='document-cell__content__button'>{button}</div>
  }

  render() {
    const {
      className,
      xs,
      sm,
      md,
      width,
      link,
      contentTop,
      contentTopLink,
      deleteFn = () => null,
      onDelete,
    } = this.props

    const deleteFnPrepared = deleteFn()

    const { deleteInProgress, removed } = this.state

    if (removed) return null

    const Content = () => (
      <div
        className={classNames('document-cell-inner', {
          'document-cell-inner--is-deletable': !!deleteFn,
        })}
      >
        {deleteFnPrepared && (
          <div
            className='document-cell-inner__delete-icon'
            onClick={() => {
              this.setState({ deleteInProgress: true })
              deleteFnPrepared().then(() => {
                this.setState({ removed: true }, () => {
                  if (_.isFunction(onDelete)) {
                    onDelete()
                  }
                })
              })
            }}
          >
            <Icon type='trash' lg />
          </div>
        )}
        <div className='document-cell__content-top'>
          {contentTopLink && <Link to={contentTopLink}>{contentTop}</Link>}
          {!contentTopLink && contentTop}
        </div>
        <div className='document-cell__content--outer'>
          <div className='document-cell__content--inner'>
            {this.renderContentBottom()}
            {this.renderButton()}
          </div>
        </div>
      </div>
    )

    return (
      <Col xs={xs} sm={sm} md={md} style={{ display: 'flex', width }}>
        <div className={classNames('document-cell-outer', className)}>
          {link && (
            <Link to={link}>
              <Content />
            </Link>
          )}
          {!link && <Content />}
          {deleteInProgress && <LoadingOverlay />}
        </div>
      </Col>
    )
  }
}

DocumentCellPresentation.propTypes = {
  contentTop: PropTypes.element.isRequired,
  contentTopLink: PropTypes.string,
  contentBottom: PropTypes.object,
  button: PropTypes.element,
  className: PropTypes.string,
  xs: PropTypes.number,
  sm: PropTypes.number,
  md: PropTypes.number,
  width: PropTypes.string,
  link: PropTypes.string,
  deleteFn: PropTypes.func,
  onDelete: PropTypes.func,
}

export { DocumentCellPresentation }
export default { DocumentCellPresentation }
