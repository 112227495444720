import PropTypes from 'prop-types'
import React from 'react'
import { formValueSelector } from 'redux-form/immutable'
import { connect } from 'react-redux'
import { fromJS, Map } from 'immutable'
import { RequestMileageAllowanceElementForm as FormComponent } from '../../components/RequestMileageAllowanceElementForm'
import { bindActionCreators } from 'redux'
import {
  getRequest,
  reset,
  updateRequestMileageAllowance,
} from '../../store/app/request-mileage-allowance'
import { createAutoSaveForm, getFormValues } from '../../utils/forms'

export const FORM_NAME = 'request-mileage-allowance-form'

class RequestMileageAllowanceForm extends React.Component<any, any> {
  render() {
    return <FormComponent<any, any> {...this.props} />
  }
}

const RequestMileageAllowanceElementFormBase = createAutoSaveForm(RequestMileageAllowanceForm, {
  name: FORM_NAME,
  save: (name, value, dispatch, props) => {
    const { updateRequestMileageAllowance, request, index, element } = props

    return updateRequestMileageAllowance(request.slug, element.id, index, { [name]: value })
  },
  change: (values, dispatch, props) => {
    const { change } = props

    if (values.get('arrival_date') < values.get('departure_date')) {
      dispatch(change('arrival_date', values.get('departure_date')))
    }
  },
})

RequestMileageAllowanceElementFormBase.propTypes = {
  request: PropTypes.object.isRequired,
}

const mapStateToProps = (state, props) => {
  const selector = formValueSelector(props.formName)
  const { element, isTripDidNotHavePlace } = props

  const values = Map({
    id: element['id'],
    departure_date: element['departure_date'],
    arrival_date: element['arrival_date'],
    departure_from: element['departure_from'],
    arrival_to: element['arrival_to'],
    round_trip: element['round_trip'],
    distance: isTripDidNotHavePlace ? 0 : element['distance'],
    vehicle_type: element['vehicle_type'],
    license_plate: element['license_plate'],
    currency: element['currency'],
  }).toJS()

  return {
    initialValues: fromJS(values),
    data: getFormValues(props.formName, state),
    form: props.formName,
    request: getRequest(state),
    element,
    getValue: (field) => selector(state, field),
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ updateRequestMileageAllowance, reset, formValueSelector }, dispatch)
}

const RequestMileageAllowanceElementForm = connect(
  mapStateToProps,
  mapDispatchToProps,
)(RequestMileageAllowanceElementFormBase)

export { RequestMileageAllowanceElementForm }
export default { RequestMileageAllowanceElementForm }
