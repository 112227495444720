import APIClient from '../../../services/APIClient'
import { createModule } from '../../modules/list/index'

const fetchMethod = ({ filters }, source) => {
  return APIClient.getExpenseRequests(filters, source)
}

const module = createModule({
  namespace: 'expense-requests',
  mountPoint: 'expense-requests',
  paginationByAPI: true,
  fetchMethod,
})

export const { reducer, MOUNT_POINT, actions, selectors } = module
