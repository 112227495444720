import PropTypes from 'prop-types'
import React from 'react'
import { Section } from '../../ui/Section/index'
import { SectionHeader } from '../../ui/SectionHeader/index'
import Icon, { EXPENSES_TYPES_TO_ICONS } from '../../ui/IconComponent'
import { concat, isArray, uniq } from 'lodash'
import { trans } from '../../../trans'
import TruncateBreakable from '../../TruncateBreakable/TruncateBreakable'

class RequestInfo extends React.Component<any, any> {
  renderCostIcons() {
    const {
      request: { lumpSumsDeclaration, documents },
    } = this.props
    let types = []

    // add milleage allowance icons
    if (lumpSumsDeclaration.has_access_lump_sums) {
      types.push('rail')
    }

    if (lumpSumsDeclaration.has_accommodation_lump_sums) {
      types.push('accomodation')
    }

    if (lumpSumsDeclaration.has_drive_lump_sums) {
      types.push('mpk')
    }

    if (lumpSumsDeclaration.has_mileage_allowances) {
      types.push('car_flat')
    }

    // add documents icons
    documents.forEach((document) => {
      if (isArray(document['elements'])) {
        document['elements'].forEach((element) => {
          const type = element['type']['slug']
          types.push(EXPENSES_TYPES_TO_ICONS[type])
        })
      }
    })

    types = uniq(types)

    return (
      <div className='dashboard-current-trips__costs-types'>
        {types.length === 0 && <span className='empty'>{trans('dashboard-page.empty-cost-types')}</span>}
        {types.length > 0 && types.map((type) => <Icon type={type} key={type} />)}
      </div>
    )
  }

  render() {
    const { request } = this.props

    return (
      <Section noBorder noMargin>
        <SectionHeader
          caption={
            <strong>
              <TruncateBreakable text={request.name} lines={2} />
            </strong>
          }
        />
        <p>{trans('dashboard-page.expense-type')}</p>
        {this.renderCostIcons(request)}
      </Section>
    )
  }
}

RequestInfo.propTypes = {
  request: PropTypes.object.isRequired,
}

export { RequestInfo }
export default { RequestInfo }
