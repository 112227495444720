import React from 'react'
import { Field } from 'redux-form/immutable'
import { FormField } from '../../../ui/Form'
import { trans } from '../../../../trans'
import { TimelineElement, TimeLineElementContent, TimeLineElementHeader } from '../../../Timeline'
import { Col, Row } from '../../../../ui/Grid'
import { TripTypesMenu } from '../../../TripTypesMenu'
import Button from '../../../ui/ButtonComponent'
import Icon, { TRAVEL_TYPES_TO_FILLED_ICONS } from '../../../ui/IconComponent'
import moment from 'moment'
import { Map } from 'immutable'
import numeral from 'numeral'
import ExchangeSummaryContainer from '../../../../containers/ExchangeSummaryContainer'
import { AmountFormatter } from '../../../AmountFormatter'
import { diff } from 'deep-object-diff'
import { TravelPointLabel } from '../../../TravelPointLabel'
import { TypesMenu } from '../../index'
import { ifDeepDiff } from '../../../../utils/javascript'
import { TRAVEL_FERRY } from '../../../../constants/travel'
import { keepInViewport } from '../KeepInViewport'
import foreignCurrenciesTooltip, {
  ForeignCurrenciesTooltip,
} from '../../../ForeignCurrenciesTooltip'
import { TravelPointDate } from '../../../TravelPointDate'

const KeepingViewportHeader = keepInViewport()(TimeLineElementHeader)

class Ferry extends React.Component<any, any> {
  constructor(props) {
    super(props)
  }

  shouldComponentUpdate(nextProps, nextState) {
    return ifDeepDiff(nextProps.ferry, this.props.ferry)
  }

  renderLabel() {
    const { ferry } = this.props

    const from = ferry.virtual ? ferry.getEndLocation() : ferry.getStartLocation()
    const to = ferry.virtual ? ferry.getStartLocation() : ferry.getEndLocation()
    const date = ferry.virtual ? ferry.getEndDate() : ferry.getStartDate()

    if (!from.city || !to.city || !date) {
      return trans('request.fill-travel-data')
    } else {
      return (
        <div>
          <TravelPointLabel location={from} showDirections /> → <TravelPointLabel location={to} />
          <TravelPointDate start={date} />
        </div>
      )
    }
  }

  renderEdit() {
    const { ferry, change } = this.props
    if (ferry.virtual || ferry.isOpen) {
      return null
    }

    return (
      <span
        className='timeline-icon'
        onClick={() => {
          change('isOpen', true)
        }}
      >
        <Icon type='edit' lg gradient />
      </span>
    )
  }

  renderTrash() {
    const { ferry, onRemove } = this.props
    if (ferry.virtual || ferry.isOpen) {
      return null
    }

    return (
      <span className='timeline-icon' onClick={() => onRemove(ferry)}>
        <Icon type='trash' lg gradient />
      </span>
    )
  }

  getExchangeSummaryPartials() {
    const { ferry } = this.props

    const amount = ferry.amount
    const amount_currency = ferry.amount_currency

    return Map({
      [amount_currency]: numeral(amount),
    })
  }

  renderForeignCurrenciesTooltip() {
    const { ferry, instanceCurrency } = this.props
    return (
      <ForeignCurrenciesTooltip
        instanceCurrency={instanceCurrency}
        foreignCurrencies={ferry.getAllAmounts()}
      />
    )
  }

  renderCalculatedAmount() {
    const { ferry } = this.props

    if (ferry.virtual) {
      return trans('request.return-travel')
    } else {
      const amount = ferry.converted_amount
      const currency = ferry.calculated_amount_currency

      return (
        <div className='flex-end-center'>
          {this.renderForeignCurrenciesTooltip()}{' '}
          <span>
            <AmountFormatter amount={amount} /> {currency}
          </span>
        </div>
      )
    }
  }

  getAmountSuggestion() {
    return null

    // const {ferry} = this.props;

    // const formattedFrom = ferry.virtual ? ferry.destination_location : ferry.departure_location.city;
    // const formattedTo   = ferry.virtual ? ferry.departure_location : ferry.destination_location.city;
    //
    // if(!formattedFrom || !formattedTo) {
    //     return null;
    // }
    //
    // return ferry.suggested_amount;
  }

  render() {
    const {
      handleSubmit,
      submitting,
      ferry,
      currencies,
      onEdit,
      onRemove,
      onChangeType,
      change,
      minDate,
      maxDate,
      form,
      tripEnds,
      request,
      maxStartDate,
    } = this.props

    return (
      <TimelineElement className={ferry.className} type={ferry.type} activeSimilar>
        <KeepingViewportHeader
          icon={TRAVEL_TYPES_TO_FILLED_ICONS[TRAVEL_FERRY]}
          gradient='true'
          isOpen={ferry.isOpen}
        >
          <Col xs={9} className='timeline__header--title'>
            {this.renderLabel()}
          </Col>
          <Col style={{ paddingRight: '0' }} className='is-allign-end' xs={2}>
            {this.renderCalculatedAmount()}
          </Col>
          {request['abilities']['edit'] && (
            <Col xs={1} style={{ paddingLeft: '0' }} className='is-allign-end'>
              {this.renderEdit()}
              {this.renderTrash()}
            </Col>
          )}
        </KeepingViewportHeader>
        <TimeLineElementContent isOpened={ferry.isOpen}>
          <TypesMenu
            selected={ferry.type}
            onChange={() => onChangeType(ferry, null)}
            showChangeButton={ferry.draft}
          />

          <div className='tooltip-content'>
            <form onSubmit={handleSubmit}>
              <Field name='draft' type='hidden' component={FormField} inputOnly labeltop />

              <Field name='id' type='hidden' component={FormField} inputOnly labeltop />

              <Field name='type' type='hidden' component={FormField} inputOnly labeltop />

              <ExchangeSummaryContainer
                onSumChange={(sum) => {
                  change('converted_amount', sum)
                }}
                partials={this.getExchangeSummaryPartials()}
                showSummary={false}
                request={this.props.request}
              />

              <Row>
                <Col style={{ width: '500px' }}>
                  <Row>
                    <Col sm={12}>
                      <Field
                        name='round_trip'
                        type='toggle'
                        component={FormField}
                        label={trans('request-ferry-element.round-trip')}
                        inverse
                      />
                    </Col>
                  </Row>

                  <Row>
                    <Col sm={6}>
                      <Field
                        name='departure_location'
                        type='location'
                        component={FormField}
                        label={trans('request-ferry-element.departure')}
                        immutable
                        labeltop
                        autoFocus
                      />
                    </Col>
                    <Col sm={6}>
                      <Field
                        name='destination_location'
                        type='location'
                        component={FormField}
                        label={trans('request-ferry-element.destination')}
                        immutable
                        labeltop
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col sm={6}>
                      <Field
                        name='departure_at'
                        type='datepicker'
                        component={FormField}
                        label={trans('request-ferry-element.departure-at')}
                        minDate={minDate}
                        maxDate={maxStartDate}
                        labeltop
                      />
                    </Col>
                    <Col sm={6}>
                      <div style={{ display: ferry.round_trip ? 'block' : 'none' }}>
                        <Field
                          name='return_at'
                          type='datepicker'
                          component={FormField}
                          label={trans('request-ferry-element.return-date')}
                          minDate={ferry.departure_at}
                          maxDate={maxDate}
                          labeltop
                        />
                      </div>
                    </Col>
                  </Row>

                  <Row>
                    <Col sm={6}>
                      <Row>
                        <Col sm={7} style={{ paddingRight: '0' }}>
                          <Field
                            name='amount'
                            type='amount'
                            component={FormField}
                            label={trans('request-ferry-element.amount')}
                            suggestedAmount={this.getAmountSuggestion()}
                            suggestedAmountCurrency='PLN'
                            onBlur={(e) => {
                              e.preventDefault()
                            }}
                            placeholder='0,00'
                            labeltop
                          />
                        </Col>

                        <Col sm={5}>
                          <Field
                            name='amount_currency'
                            type='currency'
                            component={FormField}
                            currencies={currencies}
                            label=' '
                            labeltop
                          />
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Col>
                <Col fill />
              </Row>

              <Row className='is-margin-top'>
                <Col sm={12}>
                  <Button primary pull_end xs type='submit'>
                    {trans('ui.save-trip-element')}
                  </Button>
                </Col>
              </Row>
            </form>
          </div>
        </TimeLineElementContent>
      </TimelineElement>
    )
  }
}

export { Ferry }
export default { Ferry }
