import { createSelector, createSlice } from '@reduxjs/toolkit'
import APIClient from '../../../services/APIClient'
import { changeRequestValue } from '../trip-request'
import { getElements, removeElement, startRecomposition, stopRecomposition } from '../trip-timeline'
import { getFormValues } from '../../../utils/forms'
import { change } from 'redux-form/immutable'

export const REQUEST_TRAVELER_MOUNT_POINT = 'request-traveler'

/**
 * In @reduxjs/toolkit we can mutate state in reducers,
 * because behind the scene it uses ImmerJS for applying them as non-mutate
 *
 * https://github.com/immerjs/immer
 */
const slice = createSlice({
  name: REQUEST_TRAVELER_MOUNT_POINT,
  initialState: {
    items: [],
    error: null,
  },
  reducers: {
    setRequestTravelers(state, action) {
      state.items = action.payload
    },
    addRequestTraveler(state, action) {
      state.items = [...state.items, action.payload]
    },
  },
})

export const { setRequestTravelers } = slice.actions

export default slice.reducer

// selectors
const getState = (state) => state.get(REQUEST_TRAVELER_MOUNT_POINT)
export const getRequestTravelers = createSelector(getState, (state) => state.items)

export const addRequestTraveler = (request) => (slug) => async (dispatch) => {
  const { data } = await APIClient.addRequestTraveler(request, { slug: slug })

  dispatch(slice.actions.addRequestTraveler(data))

  return data
}

export const setPrivateTrip = (slug) => (isPrivate) => async (dispatch, getState) => {
  await APIClient.changePrivate(slug, isPrivate)

  dispatch(changeRequestValue(['private'], isPrivate))
}

export const removeRequestTraveler = (request) => (traveler) => async (dispatch, getState) => {
  const { data } = await APIClient.removeRequestTraveler(request, traveler)

  dispatch(setRequestTravelers(data.travelers))
  dispatch(changeRequestValue(['travelers'], data.travelers))

  const elements = getElements(getState())

  dispatch(startRecomposition())
  elements.forEach((element) => {
    if (data['affected-offers'].find((offer) => offer.search_uuid === element.search_uuid)) {
      dispatch(removeElement(element))
    }

    const form = getFormValues(element.key, getState())
    if (Array.isArray(form.request_travelers)) {
      const request_travelers = form.request_travelers.filter((slug) => slug !== traveler.slug)

      dispatch(change(element.key, 'request_travelers', request_travelers))

      if (Array.isArray(form.rooms)) {
        const rooms = [...form.rooms].slice(0, form.rooms.length - 1)

        dispatch(change(element.key, 'rooms', rooms))
      }
    }
  })
  dispatch(stopRecomposition())

  return data
}
