import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

const TripPeriodChange = React.memo(
  (props) => {
    const { localTime, timezone, diff, className } = props
    const classes = classnames({
      'declarations__crossing declarations__grid-row': true,
      ...className,
    })

    return (
      <div className={classes}>
        <div />

        <div className='declarations__period-change declarations__grid-column'>
          <span className='declarations__crossing-timezone declarations__crossing-timezone--no-flag'>
            {localTime} ({timezone})
          </span>
        </div>

        <div />
        <div />
        <div />

        <div className='declarations__grid-column'>
          <span className='declarations__crossing-duration'>{diff}h</span>
        </div>
      </div>
    )
  },
  () => true,
)

TripPeriodChange.propTypes = {
  localTime: PropTypes.string.isRequired,
  timezone: PropTypes.string.isRequired,
  diff: PropTypes.number.isRequired,
}

export default TripPeriodChange
export { TripPeriodChange }
