import PropTypes from 'prop-types'
import React from 'react'
import { getDocumentElements, getDocumentCurrency } from '../../store/app/document-account-page'
import { connect } from 'react-redux'
import { groupBy, isEmpty, get } from 'lodash'
import numeral from 'numeral'
import { trans } from '../../trans'

class VATSummaryManagerBase extends React.Component<any, any> {
  constructor(props) {
    super(props)
  }

  prepareItems() {
    const { elements, currency, getExchangeRate, instanceCurrency } = this.props
    const sum = {}

    const grouped = groupBy(elements, (element) =>
      element.vatNumber ? element.vatNumber.code : null,
    )

    Object.keys(grouped).map((key) => {
      let isNull = false

      grouped[key].forEach((vatNumber) => {
        let value =
          vatNumber && vatNumber.vatNumber && vatNumber.vatNumber.value
            ? vatNumber.vatNumber.value
            : null

        if (value === null) {
          isNull = true
        }
      })

      if (isNull) {
        sum[key] = null
      } else {
        sum[key] = numeral(
          grouped[key].reduce((value, item) => {
            let taxValue = numeral(parseFloat(item.gross_default_currency)).subtract(
              parseFloat(item.net_default_currency ? item.net_default_currency : 0),
            )
            return value.add(taxValue.value())
            // return value + parseFloat(item.net_default_currency ? item.net_default_currency : 0)
          }, numeral(0)),
        )
      }
    })

    const exchangeRate = getExchangeRate()

    return Object.keys(grouped).map((key) => {
      if (grouped[key][0].vatNumber.reverse_accounting_account) {
        const reverse_tax = numeral(
          grouped[key].reduce((value, item) => {
            let taxValue = item.reverse_tax ? numeral(parseFloat(item.reverse_tax)) : numeral(0)
            return value.add(taxValue.value())
          }, numeral(0)),
        )
        return {
          value: reverse_tax,
          reverseValue: reverse_tax,
          currency: instanceCurrency,
          account: `${get(
            grouped[key][0],
            'vatNumber.accountingAccount.account_number',
            '',
          )} - ${trans(get(grouped[key][0], 'vatNumber.accountingAccount.name', ''))}`,
          reverseAccount: grouped[key][0].vatNumber.reverse_accounting_account !== null ? `${get(
            grouped[key][0],
            'vatNumber.reverse_accounting_account.account_number',
            '',
          )} - ${trans(get(grouped[key][0], 'vatNumber.reverse_accounting_account.name', ''))}` : null,
        }
      }
      return {
        value: sum[key] !== null ? sum[key] : numeral(0),
        currency: instanceCurrency,
        account: `${get(
          grouped[key][0],
          'vatNumber.accountingAccount.account_number',
          '',
        )} - ${trans(get(grouped[key][0], 'vatNumber.accountingAccount.name', ''))}`,
      }
    })
  }

  prepareSum(items) {
    let sum = {}

    items.forEach(({ value, reverseValue, currency }) => {
      if (value !== null) {
        if (!sum[currency]) {
          sum[currency] = 0
        }
        sum[currency] += parseFloat(value.format('0.00'))
          if (reverseValue) {
              sum[currency] -= parseFloat(reverseValue.format('0.00'))
          }
      }
    })

    return sum
  }

  render() {
    const { children } = this.props
    const items = this.prepareItems()
    const sum = this.prepareSum(items)

    const renderProps = {
      items,
      sum,
    }

    return children(renderProps)
  }
}

VATSummaryManagerBase.propTypes = {
  getExchangeRate: PropTypes.func.isRequired,
  instanceCurrency: PropTypes.string.isRequired,
}

const mapStateToProps = (state) => ({
  currency: getDocumentCurrency(state),
  elements: getDocumentElements(state),
})

const VATSummaryManager = connect(mapStateToProps)(VATSummaryManagerBase)

export { VATSummaryManager }
export default VATSummaryManager
