import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import { actions, selectors } from '../../store/app/agent-trip-requests'
import { bindActionCreators } from 'redux'
import { getOwner, setOwner } from '../../store/app/agent-trip-requests-owner'

class AgentTripRequestsManagerBase extends React.Component<any, any> {
  constructor(props) {
    super(props)

    this.changeOwner = this.changeOwner.bind(this)
  }

  componentDidMount() {
    const { reset, fetchItems } = this.props

    reset()
    fetchItems()
  }

  changeOwner(owner) {
    this.props.setOwner(owner)
    this.props.changeFilter('users', owner ? owner.value : null)
  }

  render() {
    const {
      children,
      requests,
      isLoading,
      isLoaded,
      filters,
      changeFilter,
      sorter,
      changeSorter,
      pagination,
      changePage,
      owner,
    } = this.props

    const renderProps = {
      requests,
      requestsFilters: filters,
      changeRequestsFilter: changeFilter,
      requestsSorter: sorter,
      changeRequestsSorter: changeSorter,
      areRequestsLoading: isLoading,
      areRequestsLoaded: isLoaded,
      requestsPagination: pagination,
      changeRequestsPage: changePage,
      changeOwner: this.changeOwner,
      selectedUser: owner,
    }

    return children(renderProps)
  }
}

AgentTripRequestsManagerBase.propTypes = {
  children: PropTypes.func.isRequired,
  requests: PropTypes.array.isRequired,
  isLoaded: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
  filters: PropTypes.object.isRequired,
  changeFilter: PropTypes.func.isRequired,
  reset: PropTypes.func.isRequired,
  pagination: PropTypes.object.isRequired,
}

const { reset, fetchItems, changeFilter, changePage, changeSorter } = actions

const { getIsLoading, getIsLoaded, getFilters, getSorter, getPagination, getItemsPaginated } =
  selectors

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      reset,
      fetchItems,
      changeFilter,
      changeSorter,
      changePage,
      setOwner,
    },
    dispatch,
  )
}

const mapStateToProps = (state) => ({
  requests: getItemsPaginated(state),
  isLoaded: getIsLoaded(state),
  isLoading: getIsLoading(state),
  filters: getFilters(state),
  pagination: getPagination(state),
  sorter: getSorter(state),
  owner: getOwner(state),
})

export const AgentTripRequestsManager = connect(
  mapStateToProps,
  mapDispatchToProps,
)(AgentTripRequestsManagerBase)
