import { Map } from 'immutable'
import { get } from 'lodash'

// initial state
const getInitialState = () => {
  return {
    data: {
      summary: [],
      balance: [],
    },
  }
}

// constants
export const RESET_SUMMARY = 'trip-request-summary::reset'
export const SET_SUMMARY = 'trip-request-summary::set-summary'
export const SET_BALANCE = 'trip-request-summary::set-balance'

export const MOUNT_POINT = 'tripRequestSummary'

// reducer
export const reducer = (state = getInitialState(), action) => {
  switch (action.type) {
    case RESET_SUMMARY:
      return getInitialState()
    case SET_SUMMARY:
      return {
        ...state,
        summary: action.payload,
      }
    case SET_BALANCE:
      return {
        ...state,
        balance: action.payload,
      }
    default:
      return state
  }
}

// actions
export const reset = () => (dispatch) => {
  dispatch({
    type: RESET_SUMMARY,
  })
}

export const setSummary = (payload) => (dispatch) => {
  dispatch({
    type: SET_SUMMARY,
    payload,
  })
}

export const setBalance = (payload) => (dispatch) => {
  dispatch({
    type: SET_BALANCE,
    payload,
  })
}

// selectors
export const getState = (state) => {
  return state.get(MOUNT_POINT)
}

export const getSummary = (state) => {
  return getState(state).summary
}

export const getBalance = (state) => {
  return getState(state).balance
}

export const getRules = (state) => {
  return get(getSummary(state), 'rules', [])
}
