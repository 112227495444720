import { ValueFormatterParams } from 'ag-grid-community'
import { format } from 'date-fns'

export default function dateFormatter(params: ValueFormatterParams) {
  if (!params.value) {
    return '-'
  }

  const date = new Date(params.value)

  return format(date, 'yyyy-MM-dd')
}
