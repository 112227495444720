import {
  BOOKING_CANCELLED,
  BOOKING_EXPIRED,
  BOOKING_FAILED,
  BOOKING_OFFER_CHANGED,
  FLIGHT_TYPE_RETURN,
  FLIGHT_TYPE_TARGET,
  MODULE_MOUNT_POINT,
  SEARCH_STATUS_PENDING,
  SELECT_MODE_BACK,
  SELECT_MODE_THERE,
} from './index'
import { find, get } from 'lodash'
import { createSelector } from '@reduxjs/toolkit'

//selectors
export const getState = (state) => {
  return state.get(MODULE_MOUNT_POINT)
}

export const getSocket = (name) => (state) => {
  return get(getState(state), `${name}.socket`, {
    channel: null,
    event: null,
    subscribed: false,
  })
}

export const getOffers = (name) => (state) => {
  return get(getState(state), `${name}.offers`, [])
}

export const getTotalPaxes = (name) => (state) => {
  return get(getState(state), `${name}.totalPaxes`, 1)
}

export const getStatus = (name) => (state) => {
  return get(getState(state), `${name}.status`, SEARCH_STATUS_PENDING)
}

export const getSearchUuid = (name) => (state) => {
  return get(getState(state), `${name}.uuid`, null)
}

export const initialized = (name) => (state) => {
  return get(getState(state), `${name}.initialized`, false)
}

export const getQuery = (name) => (state) => {
  return get(getState(state), `${name}.query`, {})
}

export const isLoading = (name) => (state) => {
  return get(getState(state), `${name}.loading`, false)
}

export const isIntegrity = (name) => (state) => {
  return get(getState(state), `${name}.integrity`, false)
}

export const getFilters = (name) => (state) => {
  return get(getState(state), `${name}.filter`, null)
}

export const isSearchEnabled = (name) => (state) => {
  return get(getState(state), `${name}.enabled`, false)
}

export const searchFailed = (name) => (state) => {
  return get(getState(state), `${name}.searchFailed`, false)
}

export const selector =
  (name) =>
  (state) =>
  (field, defaultValue = null) => {
    return get(getState(state), `${name}.${field}`, defaultValue)
  }

export const getSelectMode = (name) => (state) => {
  const selectThere = get(getState(state), `${name}.selected.${SELECT_MODE_THERE}`, null)

  if (!selectThere) {
    return SELECT_MODE_THERE
  }

  return SELECT_MODE_BACK
}

export const getOfferOption = (offer) => {
  return get(offer, 'options.0')
}

export const getSelectedOffer = (name) =>
  createSelector(getSelectedThere(name), getSelectedBack(name), (selectedThere, selectedBack) =>
    selectedBack ? selectedBack : selectedThere,
  )

export const isBookingProcessWaiting = (name) =>
  createSelector(getSelectedOffer(name), (offer) => {
    const bookingStatus = get(offer, 'options.0.booking', null)

    return (
      bookingStatus !== null &&
      bookingStatus !== BOOKING_OFFER_CHANGED &&
      bookingStatus !== BOOKING_FAILED &&
      bookingStatus !== BOOKING_EXPIRED &&
      bookingStatus !== BOOKING_CANCELLED
    )
  })

const getSelected = (name, direction, state) => {
  if ([SELECT_MODE_THERE, SELECT_MODE_BACK].indexOf(direction) === -1) {
    throw Error('Invalid select offer direction param')
  }

  return get(getState(state), `${name}.selected.${direction}`, {
    [SELECT_MODE_THERE]: null,
    [SELECT_MODE_BACK]: null,
  })
}

export const getSelectedThere = (name) => (state) => {
  return getSelected(name, SELECT_MODE_THERE, state)
}

export const getSelectedBack = (name) => (state) => {
  return getSelected(name, SELECT_MODE_BACK, state)
}

export const getSelectedOffersAmount = (name) => (state) => {
  const there = getSelectedThere(name)(state)
  const back = getSelectedBack(name)(state)

  const amountThere = parseFloat(get(there, 'options.0.amount.amount', '0.00'))
  const amountBack = parseFloat(get(back, 'options.0.amount.amount', '0.00'))

  const fixedAmount = (amountThere + amountBack).toFixed(2)

  const currency = get(there, 'options.0.reservation_fee.currency', 'PLN')

  return {
    amount: fixedAmount,
    formatted: fixedAmount + ' ' + currency,
    currency,
  }
}

export const getSelectedOffersReservationFee = (name) => (state) => {
  const there = getSelectedThere(name)(state)
  const back = getSelectedBack(name)(state)

  const amountThere = parseFloat(get(there, 'options.0.reservation_fee.amount', '0.00'))
  const amountBack = parseFloat(get(back, 'options.0.reservation_fee.amount', '0.00'))

  const fixedAmount = (amountThere + amountBack).toFixed(2)

  const currency = get(there, 'options.0.reservation_fee.currency', 'PLN')

  return {
    amount: fixedAmount,
    formatted: fixedAmount + ' ' + currency,
    currency,
  }
}

export const getPaxes = (name) => (state) => {
  return get(getState(state), `${name}.paxes`, {})
}

export const getPax = (name) => (pax) => (state) => {
  return get(getPaxes(name)(state), pax, {})
}

export const getPaxAttributes = (name) => (pax, which) => (state) => {
  return get(getPax(name)(pax)(state), which, [])
}

export const getPaxesArray = (name) => (state) => {
  const paxes = getPaxes(name)(state)

  return Object.keys(paxes).map((slug) => paxes[slug])
}

export const getPaxRequestedAttribute = (name) => (pax, offer, type) => (state) => {
  const requestedAttributes = getPaxAttributes(name)(pax, 'requestedServices')(state)

  return get(requestedAttributes, `services.${offer.uuid}.${type}`, [])
}

export const getPaxBookedAttribute = (name) => (pax, offer, type) => (state) => {
  const bookedAttributes = getPaxAttributes(name)(pax, 'bookedServices')(state)
  const offerAttributes = get(bookedAttributes, offer.uuid, [])

  return find(offerAttributes, { type })
}

export const getPaxAvailableAttribute = (name) => (pax, offer, type) => (state) => {
  const availableAttributes = getPaxAttributes(name)(pax, 'additionalServices')(state)
  const offerAttributes = get(availableAttributes, `services.${offer.uuid}`, [])

  return find(offerAttributes, { type })
}

export const arePaxAvailableAttributesForBoth = (name) => (pax) => (state) => {
  const availableAttributes = getPaxAttributes(name)(pax, 'additionalServices')(state)

  return get(availableAttributes, 'both', false)
}

export const getPaxesRequestedServices = (name) => (state) => {
  return getPaxesArray(name)(state)
    .map((pax) => ({
      [pax.slug]: { requestedServices: pax.requestedServices },
    }))
    .reduce((a, b) => ({ ...a, ...b }), {})
}

export const getProcessedOffersSelector = (name) =>
  createSelector([getOffers(name)], (offers) => {
    return offers.filter((offer) => offer.attributes.flightType === FLIGHT_TYPE_TARGET)
  })

export const getSortBy = (name) => (state) => {
  return get(getState(state), `${name}.sortBy`, [])
}

export const getFeaturedOffers = (name) => (state) => {
  const offers = getOffers(name)(state)
  return offers.filter((offer) => get(offer, 'attributes.featured', false))
}

export const getReturnFlights = (name, offer) =>
  createSelector([getOffers(name)], (offers) => {
    const thereReturnFlights = get(offer, 'options.0.attributes.returnFlightsIds', [])

    return offers.filter(
      (offer) =>
        offer.attributes.flightType === FLIGHT_TYPE_RETURN &&
        thereReturnFlights.indexOf(get(offer, 'attributes.flightId', '')) !== -1,
    )
  })

export const isAgreement = (name) => {
  return get(getState(state), `${name}.isAgreement`, false)
}
