import PropTypes from 'prop-types'
import React from 'react'
import { getRouteByName } from '../../routes'
import trans from '../../trans'
import BreadCrumbs from '../../containers/BreadCrumbs/index'
import { Link } from 'react-router-dom'

class RequestBreadcrumb extends React.Component<any, any> {
  render() {
    const { request } = this.props
    const { state, uid, slug, type } = request

    return (
      <BreadCrumbs>
        <Link to={getRouteByName('main', 'dashboard')}>{trans('global.dashboard')}</Link>
        <Link to={getRouteByName('main', 'tripRequestsList')}>{trans('global.trips')}</Link>
        <Link to={getRouteByName('main', 'tripRequestShow', { id: slug })}>
          {trans(`request.${state}`)} {uid}
        </Link>
        <Link to={getRouteByName('main', 'tripRequestSummaryShow', { type, id: slug })}>
          {trans('request.summary-page')}
        </Link>
      </BreadCrumbs>
    )
  }
}

RequestBreadcrumb.propTypes = {
  request: PropTypes.object.isRequired,
}

export { RequestBreadcrumb }
export default { TripRequestBreadcrumb: RequestBreadcrumb }
