import React from 'react'
import PropTypes from 'prop-types'
import { get } from 'lodash'
import className from 'classnames'

const OPERATORS_IMAGES = {
  IC: '/images/IC.png',
  EIC: '/images/EIC.png',
  EIP: '/images/EIP.png',
  TLK: '/images/TLK.png',
}

const OperatorLogo = (props) => {
  const { offer } = props
  const logo = get(OPERATORS_IMAGES, offer.attributes.equipmentCode, null)

  if (!logo) {
    return <div className='train-trip__dialog-ticket-logo'>{offer.attributes.equipmentCode}</div>
  }

  const classNames = className(
    {
      'train-trip__dialog-ticket-logo': true,
    },
    props.className,
  )

  return <img src={logo} alt={offer.attributes.equipmentCode} className={classNames} />
}

OperatorLogo.propTypes = {
  offer: PropTypes.object.isRequired,
}

export default OperatorLogo
export { OperatorLogo }
