import { Tooltip } from '../Tooltip'
import trans from '../../trans'
import Icon from '../ui/IconComponent'
import React from 'react'
import PropTypes from 'prop-types'

class DocumentFormExchangeRateTooltip extends React.Component<any, any> {
  render() {
    const { type } = this.props

    return (
      <span
        style={{
          position: 'absolute',
          right: -45,
          bottom: 0,
          width: 30,
          height: 30,
          cursor: 'help',
        }}
      >
        <Tooltip
          html={
            type === 'trip' ? (
              <div>
                <p>{trans('document.exchange-rate-tooltip-trip')}</p>
                <br />
                <p>{trans('document.exchange-rate-tooltip-trip-2')}</p>
                <br />
                <p>{trans('document.exchange-rate-tooltip-trip-3')}</p>
              </div>
            ) : (
              <div>
                <p>{trans('document.exchange-rate-tooltip-expense')}</p>
                <br />
                <p>{trans('document.exchange-rate-tooltip-expense-2')}</p>
                <br />
                <p>{trans('document.exchange-rate-tooltip-expense-3')}</p>
              </div>
            )
          }
          style={{ width: '100%', height: '100%', display: 'block', position: 'absolute' }}
        >
          <span
            style={{ position: 'absolute', top: '50%', left: '50%', marginLeft: -8, marginTop: -8 }}
          >
            <Icon grey lg type='icon-info-grey' />
          </span>
        </Tooltip>
      </span>
    )
  }
}

DocumentFormExchangeRateTooltip.propTypes = {
  type: PropTypes.oneOf(['expense', 'trip']).isRequired,
}

export default DocumentFormExchangeRateTooltip
