import React, { Component } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { Checkbox, Col } from '../../../ui'
import { trans } from '../../../trans'
import Icon from '../../../components/ui/IconComponent'

export class TravelPart extends Component<any, any> {
  constructor(props) {
    super(props)
  }

  renderLabel({ type, location: { city } }) {
    type = trans(`request-expense-commute.travel-part__type-${type}`)
    return `${type} ${city}`
  }

  renderIcon({ type }, direction) {
    if (type === 'plane') {
      if (direction === 'from') {
        return 'flight2'
      } else {
        return 'flight'
      }
    } else if (type === 'train') {
      return 'train_flat'
    } else if (type === 'ferryboat') {
      return 'ferry'
    } else {
      return 'question_fill'
    }
  }

  render() {
    const {
      setAccessLumpSumPair,
      accessLumpSum = { location: {} },
      direction,
      toggle,
      secondAccessLumpSum,
      deleteAccessLumpSums,
      fromIndex,
      toIndex,
      isReadOnly,
    } = this.props

    const {
      checked,
      disable,
      date,
      isNew,
      location: { city },
      type,
    } = accessLumpSum

    const labelsWrapperClassNames = [
      'travel-part__labels-wrapper',
      'lump-sum-section__access-lump-sum-item',
      disable && 'travel-part__labels-wrapper--disable',
    ]
      .filter((className) => className)
      .join(' ')

    const iconClassNames = [
      'expense-commute__travel-list__travel__travel-part__icon',
      disable && 'travel-part__labels-wrapper--disable',
    ]
      .filter((className) => className)
      .join(' ')

    return (
      <Col xs={6} className='travel-list__column'>
        <Checkbox
          name={`access_${direction}`}
          checked={checked}
          disabled={disable || isNew}
          onChange={({ target: { checked } }) => {
            if (direction === 'from') {
              setAccessLumpSumPair({ ...accessLumpSum, checked }, secondAccessLumpSum, [
                fromIndex,
                toIndex,
              ])
            } else {
              setAccessLumpSumPair(secondAccessLumpSum, { ...accessLumpSum, checked }, [
                fromIndex,
                toIndex,
              ])
            }
          }}
        />
        <Icon className={iconClassNames} type={this.renderIcon(accessLumpSum, direction)} grey />
        <span className='expense-commute__travel-list__travel__travel-part__label'>
          {type && city && (
            <span className={labelsWrapperClassNames}>
              <span className='travel-part__label-detail'>{this.renderLabel(accessLumpSum)}</span>
              {moment(date).isValid() && (
                <span className='travel-part__label-detail travel-part__label-detail--x-small'>
                  {moment(date).format('YYYY-MM-DD HH:mm')}
                </span>
              )}
            </span>
          )}
          {(!type || !city) && (
            <span>
              <i>{trans('request-expense-commute.travel-part--is-new')}</i>
            </span>
          )}
        </span>
        {!isReadOnly && direction === 'from' && (
          <span className='expense-commute__travel-list__travel__travel-part__actions'>
            {!isNew && (
              <span>
                <Icon key='edit' type='edit' lg onClick={() => toggle()} />
              </span>
            )}
            <Icon
              key='delete'
              type='trash'
              onClick={() =>
                deleteAccessLumpSums([accessLumpSum, secondAccessLumpSum], [fromIndex, toIndex])
              }
              lg
            />
          </span>
        )}
      </Col>
    )
  }
}

TravelPart.propTypes = {
  accessLumpSum: PropTypes.object.isRequired,
  direction: PropTypes.string.isRequired,
  toggle: PropTypes.func,
  secondAccessLumpSum: PropTypes.object.isRequired,
  setAccessLumpSumPair: PropTypes.func.isRequired,
  deleteAccessLumpSums: PropTypes.func,
  fromIndex: PropTypes.number,
  toIndex: PropTypes.number,
  isReadOnly: PropTypes.bool,
}
