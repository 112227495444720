import PropTypes from 'prop-types'
import React from 'react'
import classNames from 'classnames'
import _ from 'lodash'
import AnimateHeight from 'react-animate-height'
import { config } from '../../../config'
import Icon from '../IconComponent'

class Accordion extends React.Component<any, any> {
  constructor(props) {
    super(props)

    this.state = {
      isOpen: false,
    }
  }

  handleIconClick = () => {
    const { isOpen } = this.state

    this.setState({ isOpen: !isOpen })
  }

  render() {
    const { children, bar, className } = this.props
    const { isOpen } = this.state

    const _className = classNames(
      {
        accordion: true,
        'table-accordion__accordion': true,
        'accordion--is-open': isOpen,
      },
      className,
    )

    return (
      <div className={_className}>
        <div className='accordion__bar'>
          {_.isFunction(bar) && bar({ isOpen })}
          {!_.isFunction(bar) && bar}
          {!_.isEmpty(children) && (
            <div className='table-accordion__row-icon' onClick={this.handleIconClick.bind(this)}>
              <Icon
                type='arrow'
                rotatable
                rotate={isOpen}
                onClick={this.handleIconClick.bind(this)}
              />
            </div>
          )}
        </div>
        {!_.isEmpty(children) && (
          <AnimateHeight duration={config.animations.heightDuration} height={isOpen ? 'auto' : 0}>
            <div className='accordion__children'>
              {_.isFunction(children) && children({ isOpen })}
              {!_.isFunction(children) && children}
            </div>
          </AnimateHeight>
        )}
      </div>
    )
  }
}

Accordion.propTypes = {
  bar: PropTypes.oneOfType([PropTypes.element, PropTypes.func]),
  isOpen: PropTypes.bool,
}

Accordion.defaultProps = {
  isOpen: false,
}

export { Accordion }
export default Accordion
