import React, { Component } from 'react'
import { FormGroup } from '../../../../ui'
import { DatePickerField, SelectField } from '../../../ui/Form'
import Button from '../../../ui/ButtonComponent'
import trans from '../../../../trans'
import { connect } from 'react-redux'
import { addSubstitutionAction } from '../../../../store/app/substitutions'
import userProfile from '../../../../store/app/user-profile/providers/userProfile'
import moment from 'moment'
import formatImageUrlSize from '../../../../utils/formatImageUrlSize'
import Avatar from '../../../Avatar/Avatar'
import { loadOptionsForUsers } from '../../../../store/app/users-for-select'
import { AsyncSelectField } from '../../../ui/Form/AsyncSelectField'
import UserField from '../../../../form/UserField/UserField'

class SubstitutionsForm extends Component<any, any> {
  state = {
    adding: false,
    currentUser: null,
    dateFrom: null,
    dateTo: null,
  }

  handleUserChange = (user) => {
    this.setState({ currentUser: user.value ? user : null })
  }

  isSaveDisabled = () => {
    const { currentUser, dateFrom, dateTo, adding } = this.state

    return !currentUser || !dateFrom || !dateTo || adding
  }

  addSubstitution = () => {
    const { currentUser, dateFrom, dateTo } = this.state

    this.setState({ adding: true })
    this.props
      .addSubstitution(
        currentUser.id,
        dateFrom,
        dateTo,
        this.props.subjectUser && this.props.subjectUser.slug ? this.props.subjectUser.slug : null,
      )
      .then(() => {
        this.setState({ adding: false, currentUser: null, dateFrom: null, dateTo: null })
      })
      .catch(() => {
        this.setState({ adding: false })
      })
  }

  render() {
    return (
      <div>
        <div className='substitutions__range-panel'>
          <div className='substitutions__range-field-wrapper'>
            <div>
              <FormGroup label={trans('user.from')} labeltop>
                <DatePickerField
                  type={'datepicker'}
                  value={this.state.dateFrom}
                  minDate={moment()}
                  maxDate={this.state.dateTo}
                  onChange={(dateFrom) => this.setState({ dateFrom })}
                />
              </FormGroup>
            </div>
          </div>

          <div className='substitutions__range-field-wrapper'>
            <div>
              <FormGroup label={trans('user.to')} labeltop>
                <DatePickerField
                  type={'datepicker'}
                  value={this.state.dateTo}
                  minDate={this.state.dateFrom || moment()}
                  onChange={(dateTo) => this.setState({ dateTo })}
                />
              </FormGroup>
            </div>
          </div>
        </div>

        <p className='substitutions__copy'>{trans('user.substitute')}</p>

        <div className='substitutions__user-panel'>
          <div className='substitutions__username-field-wrapper'>
            <div>
              <FormGroup>
                <UserField
                  value={this.state.currentUser}
                  onChange={this.handleUserChange}
                  loadItems={loadOptionsForUsers}
                />
              </FormGroup>
            </div>
          </div>

          {this.state.currentUser && (
            <div className='substitutions__user'>
              <Avatar
                className='substitutions__user-avatar'
                user={this.state.currentUser}
                asImage={true}
              />

              <div>
                <span className='substitutions__user-detail substitutions__user-detail--bold'>
                  {this.state.currentUser.full_name}
                </span>
                <a
                  href={`mailto:${this.state.currentUser.email}`}
                  className='substitutions__user-detail'
                >
                  {this.state.currentUser.email}
                </a>
              </div>
            </div>
          )}
        </div>

        <Button
          className='substitutions__set-button'
          primary
          disabled={this.isSaveDisabled()}
          onClick={this.addSubstitution}
        >
          {trans('user.set')}
        </Button>
      </div>
    )
  }
}

const mapDispatchToProps = {
  addSubstitution: addSubstitutionAction,
}

export default userProfile(false)(connect(null, mapDispatchToProps)(SubstitutionsForm))
