import React, { ReactElement } from 'react'
import {
  getFormValues as getFormValuesRedux,
  reduxForm,
  SubmissionError,
  getFormSubmitErrors as getFormErrorsRedux,
  change,
} from 'redux-form/immutable'
import { processAPIerrorResponseToFormErrors } from '../services/APIClient'
import _ from 'lodash'
import moment from 'moment'
import { config } from '../config'
import { Map } from 'immutable'
import { deepDiff, ifDeepDiff } from './javascript'
import { startSubmit, stopSubmit } from 'redux-form'
import { ConfirmationModal } from '../components/ConfirmationModal'
import type { Config as ReduxFormConfig } from 'redux-form/lib/createReduxForm'

interface AutoSaveFormProps extends ReduxFormConfig {
  name: string
  save?: (...args: any[]) => any
  change?: (...args: any[]) => any
  container?: ReactElement
  enableReinitialize?: boolean
  fieldsExcludedFromDebounce?: string[]
  persistentSubmitErrors?: boolean
  handleErrors?: (response: any) => any
  timeout?: number
}
export const isFullOfUndefined = (obj) => {
  const length1 = Object.keys(obj)
    .map((key) => obj[key])
    .filter((value) => value === undefined).length
  const length2 = Object.keys(obj).map((key) => obj[key]).length

  return length1 === length2 && length1 > 0
}

export class AutoSaveForm extends React.Component<any, any> {
  fieldsExcludedFromDebounce: string[] = []
  handlers: Record<number, any> = {}
  errors: Record<number, any> = {}
  timeouts: Record<number, any> = {}
  autoSaveDisabled: boolean

  constructor(props) {
    super(props)
    this.timeouts = {} // timeouts for fields
    this.errors = {} // save previous errors
    this.handlers = {}
    this.state = {
      pendingRequests: 0,
      requestsToPerform: 0,
      timeout: _.get(props, 'timeout', 1000),
      confirmation: {},
    }
    this.fieldsExcludedFromDebounce = _.get(props, 'fieldsExcludedFromDebounce', [])

    this.onConfirmationReject = this.onConfirmationReject.bind(this)
    this.onConfirmationAccept = this.onConfirmationAccept.bind(this)
  }

  enableAutoSave() {
    this.autoSaveDisabled = false
  }

  disableAutoSave() {
    this.autoSaveDisabled = true
  }

  componentDidMount() {
    const { componentDidMount, initialValues, initialize, initialized } = this.props
    const _self = this

    if (_.isFunction(componentDidMount)) {
      componentDidMount({
        subject: {
          onUnlock: (cb) => (_self.handlers['onUnlock'] = cb),
          onLock: (cb) => (_self.handlers['onLock'] = cb),
        },
        ...this.props,
      })
    }

    if (!initialized) {
      this.disableAutoSave()
      initialize(initialValues)
      this.enableAutoSave()
    }
  }

  componentWillUnmount() {
    const { componentWillUnmount } = this.props

    if (_.isFunction(componentWillUnmount)) {
      componentWillUnmount()
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    // will execute on change every field
    const { initialValues, initialize, initialized } = this.props

    if (!initialized) {
      initialize(initialValues)
      return
    }

    const { data: _prevData } = prevProps
    const { data: _currentData } = this.props

    if (!_.isEmpty(_prevData) && !_.isEmpty(_currentData)) {
      // data received... (prevProps['data'] can be undefined at initialization)
      const prevData = Map(_prevData).toJS()
      const currentData = Map(_currentData).toJS()

      if (ifDeepDiff(prevData, currentData) && !isFullOfUndefined(prevData)) {
        // form values changed
        const diff = deepDiff(prevData, currentData)

        const newValues = Object.keys(diff).map((key) => {
          const name = key
          let value = currentData[key]
          if (value instanceof moment) {
            value = value.format(config.apiDateTimeFormat)
          }

          delete this.errors[name] // delete validation error since field is updated and the error may not occure

          return {
            name,
            value,
          }
        })

        const oldValues = Object.keys(diff).map((key) => {
          const name = key
          let value = prevData[key]
          if (value instanceof moment) {
            value = value.format(config.apiDateTimeFormat)
          }

          delete this.errors[name] // delete validation error since field is updated and the error may not occure

          return {
            name,
            value,
          }
        })

        // save data
        this.handleSave(newValues, oldValues, {
          increaseGlobal: true,
        })
      }
    }

    if (prevState['requestsToPerform'] !== this.state['requestsToPerform']) {
      if (this.state['requestsToPerform'] === 0) {
        if (_.isFunction(this.handlers['onUnlock'])) {
          this.handlers['onUnlock']()
        }
      } else {
        if (_.isFunction(this.handlers['onLock'])) {
          this.handlers['onLock']()
        }
      }
    }
  }

  getTimeoutValue(field: string) {
    if (this.fieldsExcludedFromDebounce.includes(field)) {
      return 0
    }

    return this.state.timeout
  }

  handleSave(newValues, oldValues, { increaseGlobal = true, confirm = false }) {
    const { save, dispatch, handleSubmit, handleErrors } = this.props

    if (this.autoSaveDisabled) {
      this.enableAutoSave()
      return
    }

    newValues.forEach(({ name, value }) => {
      let timeout = null
      const newData = {} // one instance in memory (requestInProgress)

      const createTimeout = (increaseLocal) => {
        if (increaseGlobal === true && increaseLocal === true) {
          this.setState(({ requestsToPerform }) => {
            return { requestsToPerform: requestsToPerform + 1 }
          })
        }

        return new Promise((resolve) => {
          timeout = setTimeout(() => {
            dispatch(startSubmit(this.props.formName))
            newData['requestInProgress'] = true // boom

            this.setState(({ pendingRequests }) => {
              return { pendingRequests: pendingRequests + 1 }
            })

            save(name, value, dispatch, this.props, { confirm }).then(
              (response) => {
                // save from `createReduxForm` method
                dispatch(stopSubmit(this.props.formName))
                resolve(response)
              },
              (response) => {
                const { alerts, confirmation } = response

                if (alerts) {
                  // its a hack for triggering validation errors - handleSubmit is called when the errors occur
                  handleSubmit(() => {
                    const errors = _.isFunction(handleErrors)
                      ? handleErrors(alerts)
                      : processAPIerrorResponseToFormErrors(alerts)

                    // save errors for preventing removing errors when next error is triggered
                    Object.keys(errors).map((fieldName) => {
                      this.errors[fieldName] = errors[fieldName]
                    })

                    dispatch(stopSubmit(this.props.formName))

                    throw new SubmissionError(this.errors) // throw saved errors validation
                  })()
                }

                if (confirmation) {
                  const { value: oldValue } = oldValues.find((item) => item.name === name)

                  this.setState({
                    confirmation: {
                      accept: confirmation.buttons.accept,
                      decline: confirmation.buttons.decline,
                      title: confirmation.title,
                      fieldName: name,
                      prevValue: oldValue,
                      newValue: value,
                    },
                  })

                  dispatch(stopSubmit(this.props.formName))
                }

                resolve(response)
              },
            )
          }, this.getTimeoutValue(name))
        }).then(() => {
          this.setState(({ pendingRequests }) => {
            return { pendingRequests: pendingRequests - 1 }
          })

          let onEnd = null
          if (this.timeouts[name]['onEnd']) {
            onEnd = this.timeouts[name]['onEnd']
          }

          delete this.timeouts[name]

          if (onEnd) {
            onEnd() // again
          } else {
            this.setState(({ requestsToPerform }) => {
              return { requestsToPerform: requestsToPerform - 1 }
            })
          }
        })
      }

      if (this.timeouts[name]) {
        if (this.timeouts[name]['requestInProgress']) {
          this.timeouts[name]['onEnd'] = () => {
            // override onEnd (next) function with currentValues
            this.handleSave([{ name, value }], [{ name, value: oldValues[name] }], {
              increaseGlobal: false,
            })
          }
        } else {
          clearTimeout(this.timeouts[name]['timeout'])
          delete this.timeouts[name]

          newData['promise'] = createTimeout(false)
          newData['timeout'] = timeout

          this.timeouts[name] = newData
        }
      } else {
        newData['promise'] = createTimeout(true)
        newData['timeout'] = timeout

        this.timeouts[name] = newData
      }
    })
  }

  onConfirmationReject() {
    const { dispatch, form } = this.props
    const { fieldName, prevValue } = this.state.confirmation

    this.setState({ confirmation: {} })
    this.disableAutoSave()

    dispatch(change(form, fieldName, prevValue))
  }

  onConfirmationAccept() {
    const { fieldName, newValue, prevValue } = this.state.confirmation

    this.setState({ confirmation: {} })

    this.handleSave(
      [{ name: fieldName, value: newValue }],
      [{ field: fieldName, value: prevValue }],
      {
        increaseGlobal: false,
        confirm: true,
      },
    )
  }

  render() {
    const { component: FormComponent, container: FormComponentContainer } = this.props
    const {
      confirmation: { accept, decline, title },
    } = this.state
    const children = FormComponentContainer ? (
      <FormComponentContainer {...this.props} component={FormComponent} />
    ) : (
      <FormComponent<any, any> {...this.props} />
    )

    return (
      <>
        {children}

        <ConfirmationModal
          title={title}
          decline={decline}
          accept={accept}
          onReject={this.onConfirmationReject}
          onConfirm={this.onConfirmationAccept}
        />
      </>
    )
  }
}

/**
 *
 * @param component
 * @param name
 * @param save
 * @param change
 * @param container
 * @param rest
 * @deprecated
 * @returns {*}
 */
export const createAutoSaveForm = (
  component,
  { name, save, change, container, ...rest }: AutoSaveFormProps,
) => {
  let preventDefault = false
  return reduxForm({
    form: name,
    save: (...args) => {
      if (preventDefault) {
        return
      }

      if (typeof save === 'function') {
        return save(...args)
      }
    },
    onChange: (...args) => {
      preventDefault = false

      if (typeof change === 'function') {
        preventDefault = change(...args)
      }
    },
    enableReinitialize: false, // do not reload form when success save
    component,
    container,
    submit: () => null,
    ...rest,
  })(AutoSaveForm)
}

/**
 *
 * @param component
 * @param name
 * @param save
 * @param change
 * @param container
 * @param enableReinitialize
 * @returns {*}
 */
export const prepareAutoSaveForm = (
  component,
  { name, save, change, container, enableReinitialize = false } = {},
) => {
  const props = {
    save,
    onChange: change,
    enableReinitialize,
    component,
    container,
    submit: () => null,
  }

  if (name) {
    props['form'] = name
  }

  return reduxForm(props)
}

// helper to get form data
export const getFormValues = (formName, state) => {
  const data = getFormValuesRedux(formName)(state)
  if (data) {
    return data.toJS() // get JS JSON
  }
  return {} // no data available yet
}

export const getFormErrors = (formName, state) => {
  const data = getFormErrorsRedux(formName)(state)
  if (data) {
    return data.toJS() // get JS JSON
  }
  return {} // no data available yet
}
