import PropTypes from 'prop-types'
import React from 'react'
import * as constants from '../../constants/request'
import { Radio } from '../../ui/index'
import { Row, Col } from '../../ui/Grid/index'
import { Ability } from './Ability/Ability'
import trans from '../../trans'
import { FormGroup } from '../../ui/index'

class AddComment extends React.Component<any, any> {
  constructor(props) {
    super(props)
    this.isRejectParamPresent = location.href.endsWith('?reject')
  }

  componentDidMount() {
    if (this.isRejectParamPresent) {
      scrollTo(0, document.body.scrollHeight)
    }
  }

  onChange = (e) => {
    const { changeRequestValue } = this.props
    const target = e.target

    if (target.name === 'content') {
      changeRequestValue(['comment'], { content: target.value })
    }

    if (target.name === 'action') {
      changeRequestValue(['action'], target.value)
    }
  }

  render() {
    const {
      request: { status },
    } = this.props
    let _children = null

    if (status === constants.STATUS_WAITING_FOR_ACCEPTANCE) {
      _children = (
        <Col xs={12} is_pull_end>
          <Ability ability={['changeStatusToRejected']}>
            <Radio
              defaultChecked={this.isRejectParamPresent}
              name='action'
              value={0}
              label={trans('request.reject')}
              onClick={this.onChange}
            />
          </Ability>

          <Ability ability={['changeStatusToDraft']}>
            <Radio
              name='action'
              value={-1}
              label={trans('request.return-to-improvement')}
              onClick={this.onChange}
            />
          </Ability>

          <Ability ability={['changeStatusToReservation']}>
            <Radio
              name='action'
              value={1}
              label={trans('request.accept')}
              onClick={this.onChange}
            />
          </Ability>
        </Col>
      )
    }

    if (status === constants.STATUS_ACCEPTANCE_OF_SETTLEMENT) {
      _children = (
        <Col xs={12} is_pull_end>
          <Ability ability={['changeStatusToRejected']}>
            <Radio
              name='action'
              value={0}
              label={trans('request.reject')}
              onClick={this.onChange}
            />
          </Ability>

          <Ability ability={['changeStatusToSettlement']}>
            <Radio
              name='action'
              value={-1}
              label={trans('request.return-to-improvement')}
              onClick={this.onChange}
            />
          </Ability>

          <Ability ability={['changeStatusToAccounting']}>
            <Radio
              name='action'
              value={1}
              label={trans('request.accept')}
              onClick={this.onChange}
            />
          </Ability>
        </Col>
      )
    }

    return (
      <div>
        <Row>
          <Col sm={1} />
          <Col sm={11}>
            <span className='comments__label'>{trans('comment.comment')}</span>
          </Col>
        </Row>
        <Row>
          <Col sm={1} />
          <Col sm={11}>
            <FormGroup labeltop>
              <textarea
                className='input input--textarea'
                id='content'
                name='content'
                onKeyUp={this.onChange}
              />
            </FormGroup>
          </Col>
        </Row>
        {_children && <Row>{_children}</Row>}
      </div>
    )
  }
}

AddComment.propTypes = {
  request: PropTypes.object.isRequired,
  changeRequestValue: PropTypes.func.isRequired,
}

export { AddComment }
export default { AddComment }
