import { AbstractAbility } from './AbstractAbility'
import { connect } from 'react-redux'
import { MOUNT_POINT } from '../../../store/app/trip-request/index'
import { Map } from 'immutable'

class AbilityBase extends AbstractAbility {}

const mapStateToProps = (state, props) => {
  if (props['abilities']) {
    return {
      abilities: props['abilities'],
    }
  }

  const {
    request: {
      data: { abilities },
    },
  } = Map(state.get(MOUNT_POINT)).toJS()
  return {
    abilities,
  }
}

const Ability = connect(mapStateToProps)(AbilityBase)

export { Ability }
export default { Ability }
