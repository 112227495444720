import React, { Component, Fragment } from 'react'
import { FormField } from '../../ui/Form'
import { trans } from '../../../trans'
import PropTypes from 'prop-types'
import { Field } from 'redux-form/immutable'
import { TrainStationsAutocompleteField } from '../../ui/Form/TrainStationsAutocompleteField'
import alwaysOnTop from '../../../utils/alwaysOnTop'

class QueryFields extends Component<any, any> {
  onFocusDate = (e) => {
    alwaysOnTop('.timeline.accordion', e.target)
  }

  render() {
    const { travelers } = this.props

    return (
      <Fragment>
        <div className='train-trip__inputs'>
          <div className='train-trip__input train-trip__input-station'>
            <Field
              id='departure_location'
              name='departure_location'
              component={TrainStationsAutocompleteField}
              label={trans('request-train-element.departure-from')}
              labeltop
              smart
              onFocus={this.onFocusDate}
            />
          </div>

          <div className='train-trip__input train-trip__input-station'>
            <Field
              id='destination_location'
              name='destination_location'
              component={TrainStationsAutocompleteField}
              label={trans('request-train-element.arrival-to')}
              smart
              onFocus={this.onFocusDate}
            />
          </div>

          <div className='train-trip__input'>
            <Field
              id='departure_at'
              name='departure_at'
              type='datepicker'
              component={FormField}
              label={trans('request-train-element.arrival-date')}
              labeltop
              onFocus={(e) => this.onFocusDate(e)}
            />
          </div>
        </div>

        {travelers.length > 1 && (
          <div className='train-trip__inputs'>
            <div className='train-trip__input'>
              <Field
                name='request_travelers'
                type='traveler-select'
                options={travelers}
                component={FormField}
                placeholder={trans('trip-request.trip-traveler-passenger-label')}
                label={trans('flights-booking.request-traveler-field-label')}
                immutable
                labeltop
              />
            </div>
          </div>
        )}
      </Fragment>
    )
  }
}

QueryFields.propTypes = {
  trainsBooking: PropTypes.object.isRequired,
  travelers: PropTypes.object.isRequired,
}

export default QueryFields
export { QueryFields }
