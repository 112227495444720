import React from 'react'
import {
  ExpenseTypesExpenseManager,
  ExpenseTypesGroupsManager,
  ExpenseTypesManager,
} from '../../../containers/DocumentPage/ExpenseTypes'
import ExpenseTypesExpense from './ExpenseTypesExpense'
import ExpenseTypesGroup from './ExpenseTypesGroup'
import ExpenseTypesTab from './ExpenseTypesTab'
import ExpenseTypesGroupContent from './ExpenseTypesGroupContent'
import ExpenseTypesPrivateExpenseGroup from './ExpenseTypesPrivateExpenseGroup'
import { Loader } from '../../ui/LoadingOverlay/Loader'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { Row } from '../../../ui'

class ExpenseTypes extends React.Component<any, any> {
  renderGroup(group, key, onToggleGroup, activeGroup, item, groups) {
    const { document } = this.props
    const readOnly = this.props.readOnly || !document.abilities.edit

    let content = [
      <ExpenseTypesGroup
        key={key}
        group={group}
        onToggleGroup={onToggleGroup}
        groups={groups}
        document={document}
      />,
    ]
    let oneRowClass = groups.length < 4 ? 'last-toggle' : ''

    if (group.hasContent) {
      content.push(
        <ExpenseTypesGroupContent
          className={oneRowClass}
          key={'active-' + key}
          group={activeGroup}
          onToggleGroup={onToggleGroup}
        >
          <ExpenseTypesExpenseManager group={activeGroup}>
            {({ expenses, onEdit, sumOfExpenseTypes }) => (
              <Row className='expense-type__group'>
                {expenses.map((expense, key) => (
                  <ExpenseTypesExpense
                    key={key}
                    expense={expense}
                    onEdit={(expense, values) =>
                      readOnly ? () => {} : onEdit(item, expense, values)
                    }
                    sumOfExpenseTypes={sumOfExpenseTypes}
                    document={document}
                    readOnly={readOnly}
                  />
                ))}
              </Row>
            )}
          </ExpenseTypesExpenseManager>
        </ExpenseTypesGroupContent>,
      )
    }

    return content
  }

  render() {
    const { document } = this.props
    const headerClasses = classNames({
      'expense-type__header--uppercase':
        document.request.status === 'settlement' ||
        document.request.status === 'acceptance_of_settlement',
    })

    return (
      <ExpenseTypesManager document={document}>
        {({ items, onActivateTab, isRemainingInProgress, isWaitingForMessage }) => (
          <div className='has-loader'>
            {(isWaitingForMessage || isRemainingInProgress) && <Loader />}
            {items.map((item, key) => (
              <ExpenseTypesTab
                className={headerClasses}
                tab={item}
                onActivateTab={onActivateTab}
                key={key}
              >
                <ExpenseTypesGroupsManager item={item} groupWidth={'25%'}>
                  {({ groups, onToggleGroup, activeGroup }) => (
                    <div>
                      <Row className='expense-type__group'>
                        {groups.map((group, key) =>
                          this.renderGroup(group, key, onToggleGroup, activeGroup, item, groups),
                        )}
                        <ExpenseTypesPrivateExpenseGroup document={document} groups={groups} />
                      </Row>
                    </div>
                  )}
                </ExpenseTypesGroupsManager>
              </ExpenseTypesTab>
            ))}
          </div>
        )}
      </ExpenseTypesManager>
    )
  }
}

ExpenseTypes.propTypes = {
  readOnly: PropTypes.bool.isRequired,
}

export default ExpenseTypes
