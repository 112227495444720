import PropTypes from 'prop-types'
import React from 'react'
import Button from '../../components/ui/ButtonComponent'
import trans from '../../trans'
import Icon from '../ui/IconComponent'
import { AmountFormatter } from '../AmountFormatter'
import { STATUS_ACCOUNTING, STATUS_TRIP, STATUS_UPCOMING_TRIP } from '../../constants/request'
import { RouteManager } from '../../containers/RouteManager'
import { DocumentCellPresentation } from '../DocumentCellPresentation'
import { disableCell } from './DisableCell'

const DisabledCell = disableCell()(DocumentCellPresentation)

class MileageAllowanceCell extends React.Component<any, any> {
  renderLumpSumsIcons() {
    const {
      request: { lumpSumsDeclaration },
    } = this.props
    const icons = []

    if (lumpSumsDeclaration.has_access_lump_sums) {
      icons.push('rail')
    }

    if (lumpSumsDeclaration.has_accommodation_lump_sums) {
      icons.push('accomodation')
    }

    if (lumpSumsDeclaration.has_drive_lump_sums) {
      icons.push('mpk')
    }

    if (lumpSumsDeclaration.has_mileage_allowances) {
      icons.push('car_flat')
    }

    return icons.map((icon) => {
      return <Icon type={icon} grey className='icon--inline icon--document-cover' key={icon} />
    })
  }

  getContentBottom() {
    const {
      request: { uid, lumpSumsDeclaration, status, abilities, erp_accounting_number },
    } = this.props

    const lump_sum_sum =
      lumpSumsDeclaration.lump_sum_accounted || lumpSumsDeclaration.lump_sum_settled
        ? lumpSumsDeclaration.settled_lump_sums
        : 0
    const currency = lumpSumsDeclaration.settled_lump_sums_currency

    if (
      (lumpSumsDeclaration.lump_sum_accounted && status === STATUS_ACCOUNTING) ||
      (lumpSumsDeclaration.lump_sum_settled && (status !== STATUS_ACCOUNTING || !abilities.account))
    ) {
      return {
        text: {
          row1: {
            component: <strong>{uid}</strong>,
            tooltip: uid,
            truncateCSS: true,
          },
          row2: {
            component: (
              <span>
                <AmountFormatter amount={lump_sum_sum} /> {currency}
              </span>
            ),
          },
          row3: {
            component: (
              <span>
                {erp_accounting_number ? erp_accounting_number : trans('request.lump-sum-header')}
              </span>
            ),
            smartTruncateCSS: true,
          },
        },
        icons: {
          icon1: {
            component: (
              <Icon
                type='checked_fill-square'
                xlg
                className='icon--inline icon--document-cover is-color-success document-status-icon-ok'
              />
            ),
          },
          icon2: {
            component: (
              <Icon type='restaurant' grey className='icon--inline icon--document-cover' />
            ),
          },
          icon3: {
            components: this.renderLumpSumsIcons(),
          },
        },
      }
    }

    return null
  }

  _getLink(match) {
    const {
      absoluteLink,
      getRouteByName,
      request: { slug: id, type },
    } = this.props

    if (absoluteLink) {
      return getRouteByName('main', 'RequestMileageAllowance', { type, id })
    }

    return `${match.url}/cover`
  }

  getButton(match) {
    const {
      request: { status, abilities, lumpSumsDeclaration },
    } = this.props

    if (!abilities['settle'] && !abilities['account']) {
      return null
    }

    const show =
      status === STATUS_ACCOUNTING
        ? abilities['account'] && !lumpSumsDeclaration.lump_sum_accounted
        : abilities['settle'] && !lumpSumsDeclaration.lump_sum_settled

    if (!show) {
      return null
    }

    let label =
      status === STATUS_ACCOUNTING && abilities.account
        ? trans('document.post')
        : trans('global.decrees')

    return (
      <div>
        <Button primary xs>
          {label}
        </Button>
      </div>
    )
  }

  isCellDisabled = () => {
    const { request } = this.props
    return [STATUS_UPCOMING_TRIP, STATUS_TRIP].indexOf(request.status) !== -1
  }

  getTooltipMessage = () => {
    const {
      request: { unrealized },
    } = this.props
    return unrealized ? trans('trip-request.unrealized-tooltip') : null
  }

  render() {
    const { xs, sm, md, width, isDelegation } = this.props

    const disabled = this.isCellDisabled()

    return (
      <RouteManager>
        {({ match }) => (
          <DisabledCell
            contentTop={
              <div>
                <div className='dropzone__header'>
                  <div>{trans('request.mileage-allowance-header')}</div>
                  <div className='document-cell__non-delegation-header'>
                    {!isDelegation && trans('request.mileage-allowance-header-non-delegation')}
                    {this.props.request.unrealized && trans('request.trip-did-not-have-place')}
                  </div>
                </div>
                <div className='dropzone__icon dropzone__icon--diets'>
                  <Icon
                    type='diets'
                    className='is-gradient-success document-cell__img-wrapper__icon'
                  />
                </div>
              </div>
            }
            contentBottom={this.getContentBottom()}
            button={this.getButton(match)}
            xs={xs}
            sm={sm}
            md={md}
            width={width}
            link={this._getLink(match)}
            disabled={disabled}
            tooltipMessage={this.getTooltipMessage()}
          />
        )}
      </RouteManager>
    )
  }
}

MileageAllowanceCell.propTypes = {
  push: PropTypes.func.isRequired,
  getRouteByName: PropTypes.func.isRequired,
  xs: PropTypes.number,
  sm: PropTypes.number,
  md: PropTypes.number,
  request: PropTypes.object.isRequired,
  isDelegation: PropTypes.bool.isRequired,
}

MileageAllowanceCell.defaultProps = {
  isDelegation: true,
}

export { MileageAllowanceCell }
export default { MileageAllowanceCell }
