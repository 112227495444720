import React from 'react'
import { connect } from 'react-redux'
import { isRecomposition } from '../../store/app/trip-timeline/index'

class TimelineManagerOverlay extends React.Component<any, any> {
  render() {
    const { children, isRecomposition } = this.props
    const renderProps = {
      isRecomposition,
    }

    return children(renderProps)
  }
}

const mapStateToProps = (state) => ({
  isRecomposition: isRecomposition(state),
})

TimelineManagerOverlay = connect(mapStateToProps)(TimelineManagerOverlay)

export { TimelineManagerOverlay }
export default { TimelineManagerOverlay }
