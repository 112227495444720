// initial state
import APIClient from '../../../services/APIClient'
import { get } from 'lodash'
import { change } from 'redux-form'

const getInitialState = () => {
  return {
    items: [],
    isLoaded: false,
    isLoading: false,
    error: false,
    saving: false,
    waitingForMessage: false,
    remainingProgress: false,
  }
}

// constants
export const RESET_DOCUMENT_EXPENSE_TYPES = 'document-expense-types::reset'
export const SET_DOCUMENT_EXPENSE_TYPES = 'document-expense-types::set-documents'
export const START_LOADING = 'document-expense-types::start-loading'
export const LOADING_ERROR = 'document-expense-types::loading-error'
export const CHANGE_EXPENSE_TYPE = 'document-expense-types::change-expense-type'
export const ADD_EXPENSE_TYPE = 'document-expense-types::add-expense-type'
export const SET_REMAINING_PROGRESS = 'document-expense-types::set-remaining-progress'
export const DELETE_EXPENSE_TYPE = 'document-expense-types::delete-expense-type'
export const SET_SAVING = 'document-expense-types::set-saving'
export const SET_WAITING_FOR_MESSAGE = 'document-expense-types::set-waiting-for-message'

export const MOUNT_POINT = 'document-expense-types'

// reducer
export const reducer = (state = getInitialState(), action) => {
  switch (action.type) {
    case RESET_DOCUMENT_EXPENSE_TYPES:
      return getInitialState()
    case SET_DOCUMENT_EXPENSE_TYPES:
      return {
        ...state,
        items: action.payload,
        isLoading: false,
        isLoaded: true,
        error: false,
        waitingForMessage: false,
      }
    case START_LOADING:
      return {
        ...state,
        isLoading: true,
        error: false,
      }
    case LOADING_ERROR:
      return {
        ...state,
        isLoaded: false,
        isLoading: false,
        error: true,
      }

    case SET_REMAINING_PROGRESS:
      return {
        ...state,
        remainingProgress: action.payload.progress,
      }

    case DELETE_EXPENSE_TYPE:
    case ADD_EXPENSE_TYPE:
    case CHANGE_EXPENSE_TYPE:
      const {
        item: { id: item_id },
        data,
      } = action.payload
      let items = state.items.map((item) => {
        item.documentElements.map((group) => {
          group.types.map((expense) => {
            if (item.id === item_id && expense.id === data.type_id) {
              let element = expense.element
              expense.element = {
                ...element,
                ...data,
              }
            }
            return expense
          })
          return group
        })
        return item
      })

      return {
        ...state,
        items: items,
        remainingProgress: false,
      }

    case SET_SAVING: {
      const { state: saving } = action.payload
      return {
        ...state,
        saving,
      }
    }

    case SET_WAITING_FOR_MESSAGE: {
      const { state: waitingForMessage } = action.payload
      return {
        ...state,
        waitingForMessage,
      }
    }

    default:
      return state
  }
}

// helpers
const prepareElementData = (data) => {
  if (data['gross'].length === 0) {
    data['gross'] = 0
  }

  return data
}

// actions
export const reset = () => (dispatch) => {
  dispatch({
    type: RESET_DOCUMENT_EXPENSE_TYPES,
  })
}

export const setExpenseTypes = (items, document, gross) => (dispatch) => {
  dispatch({
    type: SET_DOCUMENT_EXPENSE_TYPES,
    payload: items,
  })

  if (get(document, 'abilities.edit', false)) {
    dispatch(createSuggestedExpenseTypes(items, document, gross))
  }
}

export const changeElementOfType =
  (item, document, element, type, data) => (dispatch, getState) => {
    dispatch(setSaving(true))
    data = prepareElementData(data)
    return new Promise((resolve, reject) => {
      APIClient.saveExpenseType(document, element, type, data)
        .then((response) => {
          dispatch({
            type: CHANGE_EXPENSE_TYPE,
            payload: {
              item,
              type,
              data: response.data,
            },
          })
          dispatch(setSaving(false))
          resolve(response)
        })
        .catch((e) => {
          dispatch(setSaving(false))
          reject(e)
        })
    })
  }

export const setRemainingSumProgress = (progress) => (dispatch) => {
  dispatch({
    type: SET_REMAINING_PROGRESS,
    payload: {
      progress,
    },
  })
}

export const addElementOfType = (item, document, data) => (dispatch, getState) => {
  dispatch(setSaving(true))
  data = prepareElementData(data)

  return new Promise((resolve, reject) => {
    APIClient.addExpenseType(document, data)
      .then((response) => {
        dispatch({
          type: ADD_EXPENSE_TYPE,
          payload: {
            item,
            data: response.data,
          },
        })
        dispatch(setSaving(false))
        resolve(response)
      })
      .catch((e) => {
        dispatch(setSaving(false))
        reject(e)
      })
  })
}

export const deleteElementOfType = (item, document, element) => (dispatch, getState) => {
  dispatch(setSaving(true))
  return new Promise((resolve, reject) => {
    APIClient.deleteExpenseType(document, element)
      .then((response) => {
        dispatch({
          type: DELETE_EXPENSE_TYPE,
          payload: {
            item,
            data: {
              ...element,
              id: null,
              gross: null,
              gross_default_currency: null,
            },
          },
        })

        dispatch(setSaving(false))

        resolve(response)
      })
      .catch((e) => {
        dispatch(setSaving(false))
        reject(e)
      })
  })
}

const createSuggestedExpenseTypes = (items, document, gross) => (dispatch) => {
  items.forEach((item) => {
    if (item.shouldSuggest) {
      item.documentElements.forEach((group) =>
        group.types.map((expense) => {
          if (expense.suggested) {
            dispatch(
              addElementOfType(item, document.id, {
                gross: gross,
                request_element: {
                  id: item.id,
                  type: item.type,
                },
                type_id: expense.id,
              }),
            )
            dispatch(change(`expense-type-${expense.id}`, 'gross', gross))
          }
        }),
      )
    }
  })
}

export const setSaving = (state) => (dispatch) => {
  dispatch({
    type: SET_SAVING,
    payload: {
      state,
    },
  })
}

export const setWaitingForMessage = (state) => (dispatch) => {
  dispatch({
    type: SET_WAITING_FOR_MESSAGE,
    payload: {
      state,
    },
  })
}

// selectors
export const getState = (state) => {
  return state.get(MOUNT_POINT)
}

export const getExpenseTypes = (state) => {
  return getState(state).items
}

export const isRemainingProgress = (state) => {
  return getState(state).remainingProgress
}

export const getSumOfExpenseTypes = (state) => {
  const items = getExpenseTypes(state)
  let sum = []

  items.forEach((item) =>
    item.documentElements.forEach((group) =>
      group.types.forEach((type) => {
        sum.push(Number(type.element.gross))
      }),
    ),
  )

  return sum.reduce((acc, current) => acc + current, 0)
}

export const getGroups = (state, item) => {
  const found = getExpenseTypes(state).find((i) => i.id === item.id && i.type === item.type)
  return found.documentElements
}

export const getIsLoading = (state) => getState(state).isLoading
export const getIsLoaded = (state) => getState(state).isLoaded

export const getForms = (state) => {
  return state
    .get('form')
    .filter((item, key) => key.indexOf('expense-type') !== -1)
    .toJS()
}

export const isSaving = (state) => {
  return getState(state).saving
}

export const isWaitingForMessage = (state) => {
  return getState(state).waitingForMessage
}

export const getSuggestedExpense = (state, item) => {
  const foundItem = getExpenseTypes(state).find((i) => i.id === item.id && i.type === item.type)
  const suggested = get(foundItem, 'suggestedElementType.id', null)

  if (suggested) {
    return {
      id: suggested,
      slug: get(foundItem, 'suggestedElementType.slug'),
    }
  }

  return null
}
