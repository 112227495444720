import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Stars from '../Stars'
import Icon from '../../ui/IconComponent'
import { trans } from '../../../trans'
import Facilities from '../Facilities'
import { get } from 'lodash'
import Button from '../../ui/ButtonComponent'
import Maintenance from '../Maintenance'
import { Tooltip } from '../../Tooltip'
import classNames from 'classnames'
import _ from 'lodash'
import { getOr } from '../../../utils/getOr'
import { CorporateRate } from '../CorporateRate'

class Offer extends Component<any, any> {
  renderShowButton = () => {
    const { selectOffer } = this.props

    let label = trans('hotels-booking.show-offer')
    return (
      <Button primary className='selected-hotel__show-offers-btn' onClick={selectOffer}>
        {label}
      </Button>
    )
  }

  renderPhoto = () => {
    const { offer } = this.props
    const photo = get(offer, 'attributes.photos.0', '/images/no-images.png')

    return <img src={photo} alt='' className='hotels-dialog__hotel-image' />
  }

  capitalizeAddress = (address, toLowerCaseWords) =>
    _.split(address, ' ')
      .map((word) =>
        new RegExp(`^${toLowerCaseWords.join('|')}`, 'i').test(word)
          ? word.toLowerCase()
          : _.capitalize(word),
      )
      .join(' ')

  renderDistance = () => {
    const { offer } = this.props
    const distance = get(offer, 'attributes.distance', 0)
    const rounded = Math.round(distance * 10) / 10
    const formatted = `${rounded}`.replace('.', ',')

    return (
      <div className='selected-hotel__distance'>
        {trans('hotels-booking.distance')} {formatted} km
      </div>
    )
  }

  render() {
    const { offer, className, style, getFilter } = this.props

    const hasBreakfast = getFilter('breakfast')

    const amountKey = hasBreakfast ? 'lowestPriceWithBreakfast' : 'lowestPrice'
    const amountPerNightKey = hasBreakfast
      ? 'lowestPriceWithBreakfastPerNight'
      : 'lowestPricePerNight'

    const name = get(offer, 'attributes.name', '')
    const address = get(offer, 'attributes.address')
    const amount = getOr(offer, amountKey, '0,00 zł').toLowerCase()
    const amountPerNight = getOr(offer, amountPerNightKey, '0,00 zł').toLowerCase()
    const stars = get(offer, 'attributes.stars', null)
    const facilities = getOr(offer, 'attributes.facilities.facilities', [])
    const mealTypes = getOr(offer, 'mealTypes', [])
    const corporateOption = getOr(offer, 'options', [])
      .filter((option) => option.corporateRate)
      .find((option) => option.corporateRateImage)

    let warning = null
    if (offer.rules && offer.rules.length) {
      warning = (
        <Tooltip
          html={<span>{trans(offer.rules[0].message.name, offer.rules[0].message.params)}</span>}
        >
          <Icon className='is-color-warning selected-hotel__price-icon' type='warning' />
        </Tooltip>
      )
    }

    const classes = classNames(
      {
        'hotels-dialog__hotels-item': true,
      },
      className,
    )

    return (
      <div style={style}>
        <div className={classes}>
          {this.renderPhoto()}

          <div className='hotels-dialog__hotel-content-wrapper'>
            <div className='hotels-dialog__hotel-content'>
              <div className='hotels-dialog__hotel-details'>
                <div>
                  <div className='hotels-dialog__hotel-name-container'>
                    <span className='selected-hotel__name'>{_.startCase(_.toLower(name))}</span>

                    <div className='hotels-dialog__hotel-name-icons'>
                      {!!stars && (
                        <div className='hotels-dialog__hotel-stars-container'>
                          <Stars stars={stars} />
                        </div>
                      )}

                      <div className='hotels-dialog__hotel-corporate-icon'>
                        <CorporateRate option={corporateOption} />
                      </div>
                    </div>
                  </div>

                  <a
                    target='_blank'
                    href={`https://www.google.com/maps/search/?api=1&query=${address}`}
                    className='selected-hotel__address'
                  >
                    {this.capitalizeAddress(address, ['ul.?', 'ulica'])}
                  </a>

                  {this.renderDistance()}
                </div>

                <div className='hotels-dialog__price-container'>
                  <div className='hotels-dialog__price selected-hotel__price'>
                    <div className='selected-hotel__price-amount-wrapper'>
                      {warning}
                      <span className='selected-hotel__price-amount'>{amount}</span>
                    </div>

                    <span className='selected-hotel__price-per-night'>
                      {trans('hotels-booking.amount-per-night', { amount: amountPerNight })}
                    </span>
                  </div>

                  {this.renderShowButton()}
                </div>
              </div>
            </div>

            <div className='hotels-dialog__hotel-footer'>
              <div className='hotels-dialog__facilities-container'>
                <span className='hotels-dialog__facilities-text'>
                  {trans('hotels-booking.available-facilities')}:
                </span>
                <div className='selected-hotel__offer-icons'>
                  <Maintenance mealTypes={mealTypes} />
                  <Facilities facilities={facilities} />
                </div>
              </div>

              {/*<span className="hotels-dialog__additional-info-text">{trans('hotels-booking.free-cancellation-message')}</span>*/}
            </div>
          </div>
        </div>
      </div>
    )
  }
}

Offer.propTypes = {
  offer: PropTypes.object.isRequired,
  getFilter: PropTypes.func.isRequired,
}

export default Offer
export { Offer }
