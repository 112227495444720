import {
  FILTERING_FILTERED,
  getInitialState,
  STEP_LISTING,
  STEP_MAP,
  STEP_SEARCHING,
} from './index'
import { cloneDeep, get, set } from 'lodash'

//actions
export const resetAction = (state, payload) => {
  return {
    ...state,
    [payload.name]: getInitialState(),
  }
}

export const setAction = (state, payload) => {
  return {
    ...state,
    [payload.name]: {
      ...state[payload.name],
      ...payload.data,
      initialized: true,
    },
  }
}

export const changeAction = (state, payload) => {
  return set(cloneDeep(state), `${payload.name}.${payload.path}`, payload.value)
}

export const setStepAction = (state, payload) => {
  return {
    ...state,
    [payload.name]: {
      ...state[payload.name],
      step: payload.step,
    },
  }
}

export const setOffersAction = (
  state,
  { name, uuid, offers, status, roomAllocations, totalPaxes },
) => {
  const currentOffers = cloneDeep(state[name].offers)
  const selectedOffer = currentOffers.find((currentOffer) => currentOffer.selected === true)

  let selectedOption = null
  if (selectedOffer) {
    selectedOption = selectedOffer.options.find((option) => option.chosen === true)
  }

  let newOffers = offers.map((offer) => {
    if (selectedOffer) {
      offer.selected = get(selectedOffer, 'uuid', null) === offer.uuid
    }

    offer.options = offer.options.map((option) => {
      if (selectedOption) {
        option.chosen = get(selectedOption, 'uuid', null) === option.uuid
      }

      return option
    })
    return offer
  })

  const step = state[name].step

  return {
    ...state,
    [name]: {
      ...state[name],
      status,
      uuid,
      roomAllocations,
      totalPaxes,
      offers: newOffers,
      step: step === STEP_SEARCHING && newOffers.length ? STEP_LISTING : step,
    },
  }
}

export const selectOfferAction = (state, payload) => {
  const offers = state[payload.name].offers.map((offer) => {
    offer.selected = offer.uuid === payload.uuid
    return offer
  })

  return {
    ...state,
    [payload.name]: {
      ...state[payload.name],
      offers,
    },
  }
}

export const changeStatusAction = (state, payload) => {
  const offers = state[payload.name].offers.map((offer) => {
    if (offer.uuid === payload.offerUuid) {
      offer.booking = payload.status
      offer.options = offer.options.map((option) => {
        if (option.uuid === payload.optionUuid) {
          option.booking = payload.status
        }

        return option
      })
    }

    return offer
  })

  return {
    ...state,
    [payload.name]: {
      ...state[payload.name],
      offers,
    },
  }
}

export const selectOfferOptionAction = (state, payload) => {
  const offers = state[payload.name].offers.map((offer) => {
    offer.options = offer.options.map((option) => {
      option.chosen = option.uuid === payload.option_uuid && offer.uuid === payload.offer_uuid
      return option
    })

    offer.chosen = offer.uuid === payload.offer_uuid

    return offer
  })

  return {
    ...state,
    [payload.name]: {
      ...state[payload.name],
      offers,
    },
  }
}

export const setFilterAction = (state, payload) => {
  return {
    ...state,
    [payload.name]: {
      ...state[payload.name],
      filters: {
        ...state[payload.name].filters,
        [payload.filter.name]: payload.filter.value,
      },
    },
  }
}

export const reduxFormChangeAction = (state, payload, meta) => {
  if (!get(state, meta.form, null)) {
    return state
  }

  if (meta.field === 'standard') {
    if (get(state[meta.form], 'step', null) !== STEP_MAP && meta.field === 'standard') {
      state = setStepAction(state, {
        name: meta.form,
        step: STEP_LISTING,
      })
    }

    return setFilterAction(state, {
      name: meta.form,
      filter: {
        name: meta.field,
        value: payload,
      },
    })
  }

  return state
}

export const reduxFormInitializeAction = (state, payload, meta) => {
  if (!get(state, meta.form, null)) {
    return state
  }

  const query = {
    ...state[meta.form].query,
    arrival_at: payload.get('arrival_at'),
    departure_at: payload.get('departure_at'),
  }

  // TODO check if we can skip that
  return setQueryAction(state, {
    name: meta.form,
    query,
  })
}

export const setQueryAction = (state, payload) => {
  return {
    ...state,
    [payload.name]: {
      ...state[payload.name],
      query: payload.query,
    },
  }
}

export const searchAnotherOneAction = (state, payload) => {
  const offers = cloneDeep(state[payload.name].offers)
  const newOffers = offers.map((offer) => {
    offer.options = offer.options.map((option) => {
      option.chosen = false
      return option
    })
    offer.selected = offer.chosen = false
    return offer
  })

  return {
    ...state,
    [payload.name]: {
      ...state[payload.name],
      offers: newOffers,
      hasAgreement: false,
    },
  }
}

export const bookOfferAction = (state, payload) => {
  const newOffers = state[payload.name].offers.map((offer) => {
    if (offer.chosen) {
      offer.options = offer.options.map((option) => {
        if (option.chosen) {
          option.booking = payload.status
        }
        return option
      })
    }
    return offer
  })

  return {
    ...state,
    [payload.name]: {
      ...state[payload.name],
      offers: newOffers,
    },
  }
}

export const valuateOfferAction = (state, payload) => {
  const offers = cloneDeep(state[payload.name].offers)
  const newOffers = offers.map((offer) => {
    if (offer.chosen) {
      offer.options = offer.options.map((option) => {
        if (option.chosen) {
          option.valuate = payload.status
        }
        return option
      })
    }
    return offer
  })

  return {
    ...state,
    [payload.name]: {
      ...state[payload.name],
      offers: newOffers,
    },
  }
}

export const toggleSearchAction = (state, payload) => {
  const current = get(state, `${payload.name}.enabled`, true)
  return {
    ...state,
    [payload.name]: {
      ...state[payload.name],
      enabled: !current,
    },
  }
}

export const applyFiltersAction = (state, payload) => {
  const currentState = state[payload.name]

  if (!currentState.filtersChanged) {
    return state
  }

  const newOffers = currentState.offers

  return {
    ...state,
    [payload.name]: {
      ...currentState,
      offers: newOffers,
      filtered: FILTERING_FILTERED,
      filtersChanged: false,
    },
  }
}

export const setAgreementAction = (state, payload) => {
  return {
    ...state,
    [payload.name]: {
      ...state[payload.name],
      hasAgreement: payload.value,
    },
  }
}

export const changeErrorMessageSlugAction = (state, payload) => {
  const offers = state[payload.name].offers.map((offer) => {
    if (offer.uuid === payload.offerUuid) {
      offer.errorMessageSlug = payload.errorMessageSlug
    }

    return offer
  })

  return {
    ...state,
    [payload.name]: {
      ...state[payload.name],
      offers,
    },
  }
}
