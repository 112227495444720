import PropTypes from 'prop-types'
import React from 'react'
import { config } from '../../config'
import { isNil } from 'lodash'
import { AmountFormatter } from '../AmountFormatter'

export function getExchangeFormat(amount) {
  const stringAmount = String(amount)
  const dotIndex = stringAmount.indexOf('.')
  if (dotIndex === -1) {
    return config.exchangeRateFormatNBP
  }

  const floatPart = stringAmount.substr(dotIndex + 1)
  if (floatPart.length <= 4) {
    return config.exchangeRateFormatNBP
  }

  return Number(floatPart.substr(4)) === 0
    ? config.exchangeRateFormatNBP
    : config.exchangeRateFormat
}

const ExchangeFormatter = ({ amount }) => {
  const correctedAmount = String(isNil(amount) ? 0 : amount)
  const format = getExchangeFormat(correctedAmount)

  return <AmountFormatter amount={amount} format={format} />
}

ExchangeFormatter.propTypes = {
  amount: PropTypes.any,
  format: PropTypes.string,
}

export { ExchangeFormatter }
export default { ExchangeFormatter }
