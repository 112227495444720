import React from 'react'
import DialogTitle from '@material-ui/core/DialogTitle'
import trans from '../../trans'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import Icon from '../ui/IconComponent'
import Dialog from '@material-ui/core/Dialog'
import PaymentAttachTable from './PaymentAttachTable'
import { ITransactionSuggestion } from '../../types/transaction-suggestion'

export default function PaymentAttachModal({
  open,
  items,
  onClose,
  onAttach,
}: {
  open: boolean
  items: ITransactionSuggestion[]
  onClose: () => void
  onAttach: (item: ITransactionSuggestion) => void
}) {
  return (
    <Dialog
      open={open}
      keepMounted
      onClose={onClose}
      maxWidth={'xl'}
      PaperProps={{
        square: true,
      }}
    >
      <DialogTitle id='alert-dialog-slide-title'>
        {trans('my-card.payment_attach_header')}
      </DialogTitle>

      <DialogContent className='last-requests__dialog-content'>
        <PaymentAttachTable items={items} onAttach={onAttach} />
      </DialogContent>

      <DialogActions className='no-margin'>
        <Icon className='last-requests__dialog-close' type='close' onClick={onClose} />
      </DialogActions>
    </Dialog>
  )
}
