import React from 'react'
import { bindActionCreators } from 'redux'
import { fetchLoggableUsers } from '../../store/app/login-as'
import { connect } from 'react-redux'

const provide = (Component) => {
  class LoginAsProvider extends React.Component<any, any> {
    componentDidMount() {
      const { fetchLoggableUsers } = this.props
      fetchLoggableUsers()
    }

    render() {
      return <Component<any, any> {...this.props} />
    }
  }

  const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
      {
        fetchLoggableUsers,
      },
      dispatch,
    )
  }

  return connect(null, mapDispatchToProps)(LoginAsProvider)
}

export default provide
export { provide }
