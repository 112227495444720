import React, { Fragment } from 'react'
import { Field } from 'redux-form/immutable'
import { FormField } from '../../../ui/Form'
import { trans } from '../../../../trans'
import { Map } from 'immutable'
import ExchangeSummaryContainer from '../../../../containers/ExchangeSummaryContainer'
import numeral from 'numeral'
import { ForeignCurrenciesTooltip } from '../../../ForeignCurrenciesTooltip'
import TrainsBooking from '../../../TrainsBooking'
import { TrainStationsAutocompleteField } from '../../../ui/Form/TrainStationsAutocompleteField'
import Button from '../../../ui/ButtonComponent'

class Train extends React.Component<any, any> {
  constructor(props) {
    super(props)
    this.state = {
      rangeInputValue: {
        min: 5,
        max: 10,
      },
      submitting: false,
    }

    this.onSubmit = this.onSubmit.bind(this)
  }

  getExchangeSummaryPartials() {
    const { train } = this.props

    const amount = train.amount
    const amount_currency = train.amount_currency

    return Map({
      [amount_currency]: numeral(amount),
    })
  }

  renderForeignCurrenciesTooltip() {
    const { train, instanceCurrency } = this.props
    return (
      <ForeignCurrenciesTooltip
        instanceCurrency={instanceCurrency}
        foreignCurrencies={train.getAllAmounts()}
      />
    )
  }

  onSubmit(e) {
    if (this.state.submitting) {
      e.preventDefault()
      return false
    }

    this.setState({ submitting: true })
    this.props.handleSubmit(e)
  }

  renderContent() {
    const {
      train,
      currencies,
      change,
      minDate,
      errors,
      request,
      isSearchEnabled,
      selectedOffer,
      travelers,
    } = this.props

    return (
      <form onSubmit={this.onSubmit}>
        {!selectedOffer && (
          <Fragment>
            <div className='train-trip__inputs'>
              <div className='train-trip__input train-trip__input-station'>
                <Field
                  id='departure_location'
                  name='departure_location'
                  type={isSearchEnabled ? undefined : 'location'}
                  component={isSearchEnabled ? TrainStationsAutocompleteField : FormField}
                  label={trans('request-train-element.departure-from')}
                  labeltop
                  smart
                />
              </div>

              <div className='train-trip__input train-trip__input-station'>
                <Field
                  id='destination_location'
                  name='destination_location'
                  type={isSearchEnabled ? undefined : 'location'}
                  component={isSearchEnabled ? TrainStationsAutocompleteField : FormField}
                  label={trans('request-train-element.arrival-to')}
                  labeltop
                  smart
                />
              </div>

              <div className='train-trip__input'>
                <Field
                  id='departure_at'
                  name='departure_at'
                  type='datepicker'
                  component={FormField}
                  label={trans('request-train-element.arrival-date')}
                  minDate={minDate}
                  // maxDate={maxStartDate}
                  labeltop
                />
              </div>
            </div>

            {travelers.length > 1 && (
              <div className='train-trip__inputs'>
                <div className='train-trip__input'>
                  <Field
                    name='request_travelers'
                    type='traveler-select'
                    options={travelers}
                    component={FormField}
                    label={trans('flights-booking.request-traveler-field-label')}
                    immutable
                    labeltop
                  />
                </div>
              </div>
            )}
          </Fragment>
        )}

        <Field name='draft' type='hidden' component={FormField} inputOnly labeltop />

        <Field name='id' type='hidden' component={FormField} inputOnly labeltop />

        <Field name='type' type='hidden' component={FormField} inputOnly labeltop />

        <ExchangeSummaryContainer
          onSumChange={(sum) => {
            change('converted_amount', sum)
          }}
          partials={this.getExchangeSummaryPartials()}
          showSummary={false}
          request={this.props.request}
        />

        <TrainsBooking
          trainsBooking={{
            request,
            errors,
            element: train,
            init: {
              name: train.key,
              offers: [],
              query: {
                departure_location: train.departure_location,
                destination_location: train.destination_location,
                departure_at: train.departure_at,
                request_travelers: train.request_travelers,
              },
            },
          }}
        />

        {!isSearchEnabled && (
          <div className='train-trip__inputs train-trip__inputs--requested-amount'>
            <div className='train-trip__requested-amount-wrapper'>
              <div className='train-trip__input train-trip__input--requested-amount'>
                <Field
                  name='amount'
                  type='amount'
                  component={FormField}
                  label={trans('train-trip.requested-amount')}
                  immutable
                  autoFocus
                  labeltop
                />
              </div>
              <div>
                <Field
                  name='amount_currency'
                  type='currency'
                  component={FormField}
                  currencies={currencies}
                  label=' '
                  labeltop
                />
              </div>
            </div>
            <div className=''>
              <Button className='accommodation__button' primary xs type='submit'>
                {trans('ui.save-trip-element')}
              </Button>
            </div>
          </div>
        )}
      </form>
    )
  }

  render() {
    return this.renderContent()
  }
}

export { Train }
export default { Train }
