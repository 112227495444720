import { TripRequestPageAccounting } from './TripRequestPageAccounting'
import PropTypes from 'prop-types'

class TripRequestPageTransferError extends TripRequestPageAccounting {}

TripRequestPageTransferError.propTypes = {
  request: PropTypes.object.isRequired,
  currentUser: PropTypes.object.isRequired,
  isModuleActive: PropTypes.func.isRequired,
}

export { TripRequestPageTransferError }
export default { TripRequestPageTransferError }
