import React from 'react'
import { bindActionCreators, compose } from 'redux'
import {
  reset,
  set,
  setInitialized,
  setOffers,
  setQuery,
  toggleSearch,
} from '../../store/app/flights-booking/creators'
import { initialized } from '../../store/app/flights-booking/selectors'
import PropTypes from 'prop-types'
import propsBridge from '../propsBridge'
import { connect } from 'react-redux'
import { ProviderContext } from './context'
import { isFlightSearchEnabled } from '../../store/app/trip-request'

export default (propsMapper, resetOnMount = false) =>
  (Component) => {
    class FlightsBookingProvider extends React.Component<any, any> {
      static propTypes = {
        request: PropTypes.object.isRequired,
        element: PropTypes.object.isRequired,
        name: PropTypes.string.isRequired,
        query: PropTypes.object,
      }

      static defaultProps = {
        query: {},
      }

      componentDidMount() {
        const { reset, element } = this.props

        if (resetOnMount) {
          reset()
          element.draft ? this.draftInitialize() : this.noDraftInitialize()
        }
      }

      componentWillUnmount() {
        const { setInitialized } = this.props
        setInitialized(false)
      }

      draftInitialize() {
        const { set, setInitialized, isSearchFeatureEnabled } = this.props
        set({ offers: [], loading: false, enabled: isSearchFeatureEnabled })
        setInitialized(true)
      }

      noDraftInitialize() {
        const {
          element: { searcher_disabled },
          toggleSearch,
          setInitialized,
          isSearchFeatureEnabled,
        } = this.props

        if (searcher_disabled || !isSearchFeatureEnabled) {
          toggleSearch()
        }

        setInitialized(true)
      }

      render() {
        const context = {
          name: this.props.name,
          request: this.props.request,
          element: this.props.element,
        }

        const { isInitialized } = this.props

        return (
          <ProviderContext.Provider value={context}>
            {isInitialized && <Component<any, any> {...this.props} />}
          </ProviderContext.Provider>
        )
      }
    }

    const mapStateToProps = (state, props) => ({
      isInitialized: initialized(props.name)(state),
      isSearchFeatureEnabled: isFlightSearchEnabled(state),
    })

    const mapDispatchToProps = (dispatch, props) => {
      return bindActionCreators(
        {
          reset: reset(props.name),
          set: set(props.name),
          setOffers: setOffers(props.name),
          setQuery: setQuery(props.name),
          toggleSearch: toggleSearch(props.name),
          setInitialized: setInitialized(props.name),
        },
        dispatch,
      )
    }

    const withConnect = connect(mapStateToProps, mapDispatchToProps)
    const withPropsBridge = propsBridge(propsMapper)

    return compose(withPropsBridge, withConnect)(FlightsBookingProvider)
  }
