import moment from 'moment'
import 'moment/locale/en-gb';
import 'moment/locale/pl';
import locale from 'browser-locale'
import numeral from 'numeral'
import { registerLocale, setDefaultLocale } from 'react-datepicker'
import dateFnsPL from 'date-fns/locale/pl'

export const config = {
  storage: localStorage,
  pusherKey: '93bd131641937e9ec166',
  sockets: {
    release: {
      port: '6001',
    },
    demo: {
      port: '6002',
    },
    testing: {
      port: '6003',
    },
    netkata: {
      port: '6004',
    },
    local: {
      port: '6001',
    },
  },
  titles: {
    login: 'login-page.title',
    dashboard: 'global.dashboard',
    tripRequest: 'main-menu.add-trip-request',
    tripRequestsList: 'page-title.trip-request-list',
    tripRequestEdit: 'page-title.trip-request-edit',
    tripRequestSummaryShow: 'request.summary-page',
    documentsList: 'page-title.documents-list',
    reports: 'main-menu.reports',
  },
  moment: moment,
  moneyFormat: '0,0.00',
  exchangeRateFormat: '0.00000000',
  exchangeRateFormatNBP: '0.0000',
  defaultCurrency: 'PLN',
  dateFormat: 'YYYY-MM-DD HH:mm:ss',
  apiDateTimeFormat: 'YYYY-MM-DD HH:mm:ss',
  apiDateFormat: 'YYYY-MM-DD',
  datePickerFieldFormat: 'dd.MM.yyyy HH:mm',
  googleMapsKey: 'AIzaSyCLV7XTb4dvfnIXhWc4uYSlVJlKWeULOE8',
  productionURL: 'http://demo.release.vtl.netkata.io',
  acceptedDocumentMimeTypes:
    'image/gif, image/x-xbitmap, image/gi_, image/png, application/png, application/x-png, image/jpeg, image/jpg, image/jp_, application/jpg, application/x-jpg, image/pjpeg, image/pipeg, image/vnd.swiftview-jpeg, image/x-xbitmap application/pdf, application/x-pdf, application/acrobat, applications/vnd.pdf, text/pdf, text/x-pdf',
  lumSumRates: {
    lowCapacityEngine: 0.89,
    upperCapacityEngine: 1.15,
    motorcycle: 0.69,
    moped: 0.42,
  },
  animations: {
    heightDuration: 800,
  },
}

setDefaultLocale('pl')
registerLocale('pl', dateFnsPL)
config.moment.locale('pl')
numeral.locale('pl')
numeral.register('locale', 'pl', {
  delimiters: {
    thousands: '&nbsp;',
    decimal: '.',
  },
  abbreviations: {
    thousand: 'tys.',
    million: 'mln',
    billion: 'mld',
    trillion: 'bln',
  },
  ordinal: function (number) {
    return '.'
  },
  currency: {
    symbol: 'PLN',
  },
})
