import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import { trans } from '../../trans'
import { get } from 'lodash'

const OfferTitle = (props) => {
  const { offer } = props

  const from = get(offer, 'attributes.originStation', '')
  const to = get(offer, 'attributes.destinationStation', '')

  return trans('trains-booking.offers-list-title', { from, to })
}

OfferTitle.propTypes = {}

export default OfferTitle
export { OfferTitle }
