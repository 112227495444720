import React, { Component } from 'react'
import PropTypes from 'prop-types'
import trans from '../../trans'
import APIClient from '../../services/APIClient'
import { get } from 'lodash'
import { Loader } from '../ui/LoadingOverlay/Loader'
import store from '../../store'
import Avatar from '../Avatar/Avatar'
import { loginAs } from '../../store/app/login-as'
import { updateAvatarInHierarchy } from '../../store/app/company-hierarchy/creators'
import { updateAvatarInHierarchy as updateAvatarInExternalHierarchy } from '../../store/app/external-company-hierarchy/creators'

class UserCard extends Component<any, any> {
  constructor(props) {
    super(props)

    this.state = {
      file: null,
      uploading: false,
      preview: false,
    }
  }

  handleChangeAvatar = (e) => {
    const file = get(e, 'target.files.0', null)

    //set preview
    const fileReader = new FileReader()
    fileReader.onload = (e) => {
      this.setState({
        preview: e.target.result,
      })
    }

    fileReader.readAsDataURL(file)

    this.setState(
      {
        file,
      },
      () => {
        this.saveAvatar()
      },
    )
  }

  saveAvatar = () => {
    const { user, currentUser, setUserProfile } = this.props

    this.setState({ uploading: true })

    APIClient.uploadAvatar(user, this.state.file)
      .then((response) => {
        this.setState({ uploading: false })

        if (currentUser) {
          store.dispatch(updateAvatarInHierarchy(response.data))
        } else {
          store.dispatch(updateAvatarInExternalHierarchy(response.data))
        }

        setUserProfile(response.data)
      })
      .catch(() => {
        this.setState({ uploading: false })
      })
  }

  renderAvatar = () => {
    const { user } = this.props

    return <Avatar className='settings-page__account-avatar' user={user} title={user.full_name} />
  }

  loginAsAdmin = () => {
    const {
      user: { slug },
    } = this.props

    store.dispatch(
      loginAs({
        slug,
        type: 'admin',
      }),
    )
  }

  render() {
    const { user, currentUser } = this.props
    return (
      <div className='settings-page__info'>
        <div className='has-loader'>
          {this.state.uploading && <Loader small center />}
          {this.renderAvatar()}
        </div>

        <div className='settings-page__account-dashboard'>
          <div className='settings-page__account-details'>
            <span className='settings-page__account-name'>{user.full_name}</span>
            <div className='settings-page__account-contact-links'>
              <a
                className='settings-page__account-contact-link is-text-lowercase'
                href={`mailto:${user.email}`}
              >
                {user.email}
              </a>
              <span> | </span>
              <a className='settings-page__account-contact-link'>{user.phone}</a>
            </div>
            <label>
              <a className='settings-page__change-avatar-link'>
                {trans('settings-page.change-avatar')}
              </a>
              <input
                type='file'
                name='file'
                onChange={this.handleChangeAvatar}
                style={{ display: 'none' }}
                accept='image/png, image/jpeg'
              />
            </label>
          </div>

          {!currentUser && (
            <button className='btn btn--primary' onClick={this.loginAsAdmin}>
              {trans('settings-page.sign-in-as')}
            </button>
          )}
        </div>
      </div>
    )
  }
}

UserCard.propTypes = {
  setUserProfile: PropTypes.func,
}

export default UserCard
export { UserCard }
