import React from 'react'

export interface DollarIconProps {
  height?: number
  width?: number
  color?: string
}

export default function DollarIcon({ color = '#4CAF50', width, height }: DollarIconProps) {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 406 406'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className='svg dollar-icon'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M405.333 202.667C405.333 314.596 314.596 405.333 202.667 405.333C90.737 405.333 0 314.596 0 202.667C0 90.737 90.737 0 202.667 0C314.596 0 405.333 90.737 405.333 202.667ZM199.869 221.896C218.4 229.918 234.368 236.83 234.368 257.387C234.368 282.859 209.77 284.054 204.928 284.054C204.839 284.054 204.739 284.054 204.628 284.055C199.453 284.076 170.976 284.196 170.976 245.921H126.176C126.176 312.292 182.67 318.319 192.45 319.362C192.587 319.377 192.714 319.39 192.832 319.403V350.102H217.482V319.254C226.784 318.326 279.178 311.062 279.178 257.089C279.178 213.92 247.659 200.477 220.068 188.709C198.593 179.55 179.498 171.406 179.498 151.051C179.498 147.232 179.946 123.926 206.165 123.926C222.144 123.926 234.538 134.347 234.378 160.971H279.178C278.176 135.062 270.816 97.5258 220.736 88.8858V55.2432H195.936V88.4165C139.36 95.7338 134.698 136.32 134.698 150.891C134.698 193.344 162.221 205.402 189.482 217.346C191.092 218.051 192.701 218.756 194.304 219.467C196.175 220.297 198.035 221.102 199.869 221.896Z'
        fill={color}
      />
    </svg>
  )
}
