import React from 'react'
import { Field } from 'redux-form/immutable'
import { FormField, FormFieldRadioGroup } from '../../../ui/Form'
import { trans } from '../../../../trans'
import { TimelineElement, TimeLineElementContent, TimeLineElementHeader } from '../../../Timeline'
import { Col, Row } from '../../../../ui/Grid'
import Icon from '../../../ui/IconComponent'
import Button from '../../../ui/ButtonComponent'
import { get } from 'lodash'
import { FormGroup } from '../../../../ui'
import numeral from 'numeral'
import ExchangeSummaryContainer from '../../../../containers/ExchangeSummaryContainer'
import { Map } from 'immutable'
import { AmountFormatter } from '../../../AmountFormatter'
import { TravelPointLabel } from '../../../TravelPointLabel'
import { TypesMenu } from '../../index'
import { ifDeepDiff } from '../../../../utils/javascript'
import {
  TRAVEL_COMPANY_CAR_TRIP,
  TRAVEL_PASSENGER_CAR_TRIP,
  TRAVEL_PRIVATE_CAR_TRIP,
  TRAVEL_RENTED_CAR_TRIP,
  TRAVEL_REPLACEMENT_CAR_TRIP,
} from '../../../../constants/travel'
import { config } from '../../../../config'
import { keepInViewport } from '../KeepInViewport'
import { ForeignCurrenciesTooltip } from '../../../ForeignCurrenciesTooltip'
import { TravelPointDate } from '../../../TravelPointDate'
import GoogleDirections from '../../../GoogleDirections'
import { LatLng } from '../../../../models/LatLng'
import { Route } from '../../../../models/Route'
import Car from './Car'

class PrivateCar extends Car {
  constructor(props) {
    super(props)

    this.state = {
      google_distance: null,
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    return (
      ifDeepDiff(nextProps.car, this.props.car) ||
      ifDeepDiff(nextProps.formErrors, this.props.formErrors) ||
      ifDeepDiff(nextState, this.state)
    )
  }

  renderMap() {
    const { car } = this.props

    const departure_location = car.getStartLocation()
    const destination_location = car.getEndLocation()

    const route = new Route(
      new LatLng(departure_location.lat, departure_location.long),
      new LatLng(destination_location.lat, destination_location.long),
    )

    return (
      <GoogleDirections
        routes={[route]}
        minHeight={'450px'}
        distanceCallback={(distance) => {
          this.setState({ google_distance: distance })
        }}
      />
    )
  }

  calculateLumpSum() {
    const { car } = this.props
    let distance = car.distance

    if (!distance) {
      return numeral(0)
    }

    return numeral(distance).multiply(config.lumSumRates[car.vehicle_type])
  }

  renderLumpSum() {
    return <AmountFormatter amount={this.calculateLumpSum()} />
  }

  getExchangeSummaryPartials() {
    const { car } = this.props

    let partials = Map()
    partials = partials.set(car.calculated_amount_currency, this.calculateLumpSum())

    return partials
  }

  calculateDistanceSuggestion() {
    const { google_distance } = this.state

    const { car } = this.props

    if (car.round_trip) {
      return google_distance * 2
    }
    return google_distance
  }

  renderForm() {
    const { handleSubmit, car, onChangeType, change, minDate, maxDate, formErrors, maxStartDate } =
      this.props

    const destination_error = get(formErrors, 'destination_location.formatted_address', null)
    const departure_error = get(formErrors, 'departure_location.formatted_address', null)
    const currency = car.calculated_amount_currency

    return (
      <form onSubmit={handleSubmit}>
        <Field name='draft' type='hidden' component={FormField} inputOnly labeltop />

        <Field name='id' type='hidden' component={FormField} inputOnly labeltop />

        <Field name='type' type='hidden' component={FormField} inputOnly labeltop />

        <Row>
          <Col style={{ width: '500px' }}>
            <Row>
              <Col sm={12}>{this.renderCarTypeField()}</Col>
            </Row>

            <Row>
              <Col sm={12}>
                <Field
                  name='round_trip'
                  type='toggle'
                  component={FormField}
                  label={trans('request-car-element.round-trip')}
                  inverse
                />
              </Col>
            </Row>

            <Row>
              <Col sm={6}>
                <FormGroup labeltop label={trans('request-car-element.departure-from')}>
                  <div className='form-group__input-wrapper'>
                    <Field
                      name='departure_location'
                      type='location'
                      component={FormField}
                      inputOnly
                      labeltop
                      immutable
                      autoFocus
                    />
                    {departure_error && (
                      <div className='form-group__error'>
                        <p>{departure_error}</p>
                      </div>
                    )}
                  </div>
                </FormGroup>
              </Col>
              <Col sm={6}>
                <FormGroup labeltop label={trans('request-car-element.arrival-to')}>
                  <div className='form-group__input-wrapper'>
                    <Field
                      name='destination_location'
                      type='location'
                      component={FormField}
                      inputOnly
                      labeltop
                      immutable
                    />
                    {destination_error && (
                      <div className='form-group__error'>
                        <p>{destination_error}</p>
                      </div>
                    )}
                  </div>
                </FormGroup>
              </Col>
            </Row>

            <Row>
              <Col sm={6}>
                <Field
                  name='departure_at'
                  type='datepicker'
                  component={FormField}
                  label={trans('request-car-element.departure-date')}
                  minDate={minDate}
                  maxDate={maxStartDate}
                  labeltop
                />
              </Col>
              <Col sm={6}>
                <div style={{ display: car.round_trip ? 'block' : 'none' }}>
                  <Field
                    name='return_at'
                    type='datepicker'
                    component={FormField}
                    label={trans('request-plane-element.return-date')}
                    minDate={car.getStartDate()}
                    maxDate={maxDate}
                    labeltop
                  />
                </div>
              </Col>
            </Row>

            <Row>
              <Col sm={6}>
                <Field
                  name='distance'
                  type='distance'
                  component={FormField}
                  label={trans('request-element.distance')}
                  distanceSuggestion={this.calculateDistanceSuggestion()}
                  onBlur={(e) => {
                    e.preventDefault()
                  }}
                  labeltop
                />
              </Col>
            </Row>

            <Row>
              <Col sm={12}>
                <Field
                  name='vehicle_type'
                  component={FormFieldRadioGroup}
                  items={{
                    rows: [
                      {
                        cols: [
                          {
                            xs: 6,
                            items: [
                              {
                                value: 'upperCapacityEngine',
                                label: trans('request-element.upper-engine-capacity', {
                                  capacity: '900cm<sup>3</sup>',
                                }),
                              },
                              {
                                value: 'lowCapacityEngine',
                                label: trans('request-element.nether-engine-capacity', {
                                  capacity: '900cm<sup>3</sup>',
                                }),
                              },
                            ],
                          },
                          {
                            xs: 6,
                            items: [
                              {
                                value: 'motorcycle',
                                label: trans('request-element.motorcycle'),
                              },
                              {
                                value: 'moped',
                                label: trans('request-element.moped'),
                              },
                            ],
                          },
                        ],
                      },
                    ],
                  }}
                  label={trans('request-car-element.vehicle-type')}
                  inline
                  labeltop
                />
              </Col>
            </Row>

            <Row>
              <Col sm={6}>
                <Field
                  name='license_plate'
                  type='text'
                  component={FormField}
                  label={trans('request-element.license-plate')}
                  labeltop
                />
              </Col>
            </Row>

            <Row>
              <Col sm={12}>
                <Row className='lump-sum'>
                  <Col sm={6}>{trans('request-car-element.lump-sum')}</Col>
                  <Col sm={6} className='is-pull-end'>
                    {this.renderLumpSum()} {currency}
                  </Col>
                </Row>
              </Col>
            </Row>

            <Row>
              <Col sm={12}>
                <ExchangeSummaryContainer
                  onSumChange={(sum) => {
                    change('converted_amount', sum)
                  }}
                  partials={this.getExchangeSummaryPartials()}
                  request={this.props.request}
                />
              </Col>
            </Row>
          </Col>
          <Col fill>{this.renderMap()}</Col>
        </Row>

        <Row className='is-margin-top'>
          <Col sm={12}>
            <Button primary pull_end xs type='submit'>
              {trans('ui.save-trip-element')}
            </Button>
          </Col>
        </Row>
      </form>
    )
  }
}

export { PrivateCar }
export default { PrivateCar }
