import React, { Component, Fragment } from 'react'
import DialogContent from '@material-ui/core/DialogContent/DialogContent'
import Dialog from '@material-ui/core/Dialog/Dialog'
import Paper from '@material-ui/core/Paper'
import SingleOffer from '../SingleOffer'
import Icon from '../../ui/IconComponent'

class OfferDialog extends Component<any, any> {
  closeOfferDialog = () => {
    const {
      hotelsBooking: {
        actions: { closeOfferDialog },
      },
    } = this.props
    closeOfferDialog()
  }

  render() {
    const {
      hotelsBooking: {
        selectors: { isOfferDialogOpen },
      },
    } = this.props

    return (
      <Dialog
        open={isOfferDialogOpen}
        onClose={this.closeOfferDialog}
        disableBackdropClick
        aria-labelledby='alert-dialog-slide-title'
        aria-describedby='alert-dialog-slide-description'
        maxWidth={false}
        fullWidth={true}
        PaperProps={{
          className: 'hotels-dialog',
          square: true,
        }}
        scroll='paper'
      >
        <DialogContent>
          <nav className='hotel-dialog__nav'>
            <div />
            <Icon type='close' className='hotel-dialog__close' onClick={this.closeOfferDialog} />
          </nav>
          <SingleOffer inDialog hotelsBooking={this.props.hotelsBooking} reservation={true} />
        </DialogContent>
      </Dialog>
    )
  }
}

OfferDialog.propTypes = {}

export default OfferDialog
export { OfferDialog }
