import PropTypes from 'prop-types'
import React from 'react'
import { StateTextButton } from '../ui/StateTextButton'
import { DatePickerField, SelectField } from '../ui/Form'
import {
  getStatusesForSelect,
  QUICK_FILTER_ASSIGNED_TO_ME,
  QUICK_FILTER_UNASSIGNED_TO_ACCOUNTANT,
} from '../../constants/request'
import trans from '../../trans'
import Icon from '../../components/ui/IconComponent'
import { getInstance } from '../../store/app/instance'
import { connect } from 'react-redux'
import { AsyncSelectField } from '../ui/Form/AsyncSelectField'
import { loadOptionsForUsers } from '../../store/app/users-for-select'
import { fetchMpkForAsyncSelect } from '../../store/app/mpks'
import ToggleableFilters from '../ToggleableFilters/ToggleableFilters'
import ToggleableQuickFilters from '../ToggleableFilters/ToggleableQuickFilters'
import ToggleableBottomFilters from '../ToggleableFilters/ToggleableBottomFilters'
import { components } from '../../utils/react-select'
import UserSelectOption from '../../form/UserField/UserFieldOption'
import UserField from '../../form/UserField/UserField'
import ToggleableQuickFiltersContainer from '../ToggleableFilters/ToggleableQuickFiltersContainer'
import ToggleableSearchField from '../ToggleableFilters/ToggleableSearchField'
import ToggleableInputWrapper from '../ToggleableFilters/ToggleableInputWrapper'

// For Page Accounting.
class SettlementRequestsListFilters extends React.Component<any, any> {
  constructor(props) {
    super(props)

    this.timer = null
    this.fetchMpkForAsyncSelect = fetchMpkForAsyncSelect(null, false)
  }
  getCompanyOptions() {
    const { companies } = this.props
    const optionAll = {
      label: trans('global.all'),
      value: null,
      onSelectResetsInput: true,
    }

    const options = companies.map((company) => {
      return {
        value: company.id,
        label: company.code,
      }
    })

    return [optionAll, ...options]
  }

  render() {
    const { filters, onChangeFilter } = this.props
    const filtersCount = Object.keys(filters).length

    const bottomFilters = (
      <>
        <ToggleableInputWrapper label={trans('global.filter-status')}>
          <SelectField
            options={getStatusesForSelect()}
            value={filters['statuses']}
            onChange={(value) => onChangeFilter('statuses', value)}
            placeholder={trans('global.filter-placeholder-all')}
            isMulti
            clearable={true}
          />
        </ToggleableInputWrapper>

        <ToggleableInputWrapper label={trans('global.employee')}>
          <UserField
            value={filters['users']}
            onChange={(value) => onChangeFilter('users', value)}
            loadItems={loadOptionsForUsers}
            isMulti
            clearable
          />
        </ToggleableInputWrapper>

        <ToggleableInputWrapper label={trans('global.filter-mpk')}>
          <AsyncSelectField
            loadOptions={this.fetchMpkForAsyncSelect}
            value={filters['mpks']}
            onChange={(value) => onChangeFilter('mpks', value)}
            placeholder={trans('global.filter-placeholder-all')}
            noOptionsMessage={() => {
              return trans('user.no-results-for-search-message')
            }}
            loadingMessage={() => {
              return trans('user.searching-message')
            }}
            delay={500}
            returnFullItemValueOnChange
            isMulti
            clearable
            allowCreateNewOption
          />
        </ToggleableInputWrapper>

        <ToggleableInputWrapper label={trans('global.company')}>
          <SelectField
            options={this.getCompanyOptions()}
            value={filters['companies']}
            onChange={(value) => onChangeFilter('companies', value)}
            placeholder={trans('global.filter-placeholder-all')}
            isMulti
            clearable={true}
          />
        </ToggleableInputWrapper>

        <ToggleableInputWrapper label={trans('global.filter-period')}>
          <div className='filters__period-container'>
            <DatePickerField
              placeholder={trans('global.datepicker-placeholder')}
              onChange={(value) => onChangeFilter('period_start', value)}
              isClearable={true}
              value={filters['period_start']}
            />
            <span className='filters__separator'>-</span>
            <DatePickerField
              placeholder={trans('global.datepicker-placeholder')}
              onChange={(value) => onChangeFilter('period_end', value)}
              isClearable={true}
              minDate={filters['period_start']}
              value={filters['period_end']}
            />
          </div>
        </ToggleableInputWrapper>

        <ToggleableInputWrapper label={trans('instance-users.supervisor')}>
          <AsyncSelectField
            loadOptions={loadOptionsForUsers}
            value={filters['supervisors']}
            onChange={(value) => onChangeFilter('supervisors', value)}
            placeholder={trans('global.filter-placeholder-everyone')}
            noOptionsMessage={() => {
              return trans('user.no-results-for-search-message')
            }}
            loadingMessage={() => {
              return trans('user.searching-message')
            }}
            delay={500}
            returnFullItemValueOnChange
            components={{ ...components, Option: UserSelectOption }}
            isMulti
            clearable={true}
          />
        </ToggleableInputWrapper>

        <ToggleableInputWrapper label={trans('global.filter-payment')}>
          <SelectField
            options={[
              {
                value: 'not_set',
                label: trans('document.not-set'),
              },
              {
                value: 'service_card',
                label: trans('document.service-card'),
              },
              {
                value: 'own',
                label: trans('document.own'),
              },
              {
                value: 'corporate_card',
                label: trans('document.corporate-card'),
              },
            ]}
            value={filters['payments']}
            onChange={(value) => onChangeFilter('payments', value)}
            placeholder={trans('global.filter-placeholder-all')}
            isMulti
            clearable={true}
          />
        </ToggleableInputWrapper>
      </>
    )

    return (
      <form>
        <ToggleableFilters filtersCount={filtersCount}>
          {({ isOpen }) => (
            <>
              <ToggleableQuickFilters>
                <ToggleableSearchField
                  value={filters['phrase']}
                  onChange={(value) => {
                    onChangeFilter('phrase', value)
                  }}
                />

                <ToggleableQuickFiltersContainer>
                  <StateTextButton
                    onClick={(activate) => {
                      onChangeFilter('quick_filters', activate ? QUICK_FILTER_ASSIGNED_TO_ME : null)
                    }}
                    active={filters['quick_filters'] === QUICK_FILTER_ASSIGNED_TO_ME}
                  >
                    {trans('global.assigned-to-me')}
                  </StateTextButton>

                  <StateTextButton
                    onClick={(activate) => {
                      onChangeFilter(
                        'quick_filters',
                        activate ? QUICK_FILTER_UNASSIGNED_TO_ACCOUNTANT : null,
                      )
                    }}
                    active={filters['quick_filters'] === QUICK_FILTER_UNASSIGNED_TO_ACCOUNTANT}
                  >
                    {trans('global.unassigned-settlements')}
                  </StateTextButton>
                </ToggleableQuickFiltersContainer>
              </ToggleableQuickFilters>

              <ToggleableBottomFilters isOpen={isOpen}>{bottomFilters}</ToggleableBottomFilters>
            </>
          )}
        </ToggleableFilters>
      </form>
    )
  }
}

SettlementRequestsListFilters.propTypes = {
  filters: PropTypes.object.isRequired,
  onChangeFilter: PropTypes.func.isRequired,
  currentUser: PropTypes.object.isRequired,
}

const mapStateToProps = (state) => ({
  instance: getInstance(state),
})

SettlementRequestsListFilters = connect(mapStateToProps)(SettlementRequestsListFilters)

export default { SettlementRequestsListFilters }
export { SettlementRequestsListFilters }
