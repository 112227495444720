import React, { Fragment } from 'react'
import { connect } from '../containers/NonDelegation'
import { Toggle } from '../ui'
import { Tooltip } from './Tooltip'
import Icon from './ui/IconComponent'
import { trans } from '../trans'
import classnames from 'classnames'

const DelegationSwitcher = connect((props) => {
  const {
    nonDelegation: {
      actions: { setNonDelegation },
      selectors: { isDelegation },
    },
  } = props
  const {
    tripDidNotHavePlace: {
      selectors: { isTripDidNotHavePlace },
    },
  } = props
  const {
    context: {
      request: { abilities },
    },
  } = props

  if (!(abilities.edit || abilities.settle) && isDelegation) {
    return null
  }

  const classes = classnames(
    {
      'delegation-switcher': true,
    },
    props.className,
  )

  return (
    <div className={classes}>
      <div className='delegation-switcher__header'>
        {trans('request.trip-without-per-diems-header')}

        <div className='delegation-switcher__tooltip'>
          <Tooltip
            className='hotel-dialog__offer-detail-text'
            html={trans('request.trip-without-per-diems')}
          >
            <Icon greyLight lg type='question_fill' />
          </Tooltip>
        </div>
      </div>

      <div className='delegation-switcher__toggle'>
        {abilities.edit || abilities.settle ? (
          <Toggle
            onChange={(event) => {
              setNonDelegation(!event.target.checked)
            }}
            checked={!isDelegation}
            disabled={isTripDidNotHavePlace}
          />
        ) : !isDelegation ? (
          <div className='delegation-switcher__toggle-read-only'>
            {trans('request.is-delegation-trip')}
          </div>
        ) : (
          ''
        )}
      </div>
    </div>
  )
})

export default DelegationSwitcher
export { DelegationSwitcher }
