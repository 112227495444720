import React from 'react'
import _ from 'lodash'

export const InputErrorComponent = ({ errors }) => {
  if (_.isEmpty(errors)) {
    return null
  }

  errors = errors.map((error, index) => {
    return <p key={index}>{error}</p>
  })

  return <div className='form-group__error'>{errors}</div>
}
