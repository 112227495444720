import { Cell, Row } from '../../../ui/AccordionTable'
import Button from '../../../ui/ButtonComponent'
import trans from '../../../../trans'
import React from 'react'
import { change, reduxForm, Field, SubmissionError } from 'redux-form/immutable'
import { connect } from 'react-redux'
import { bindActionCreators, compose } from 'redux'
import { getFormValues } from '../../../../utils/forms'
import { fromJS } from 'immutable'
import { FormField } from '../../../ui/Form'
import { getValidationErrors } from '../../../../utils'
import { createOrUpdateAccountingAccount } from '../../../../store/app/accounting-account'

class AccountingAccountListFormItem extends React.Component<any, any> {
  get typeOptions() {
    return [
      {
        value: 'debit',
        label: trans('accounting.accounting-account-type-normal'),
      },
      {
        value: 'credit',
        label: trans('accounting.accounting-account-type-settlement'),
      },
    ]
  }

  render() {
    const { handleSubmit, cancel, item } = this.props

    return (
      <Row>
        <form onSubmit={handleSubmit}>
          <Cell fixedWidth={150}>
            <Field
              name='account_number'
              type='text'
              component={FormField}
              inputOnly
              withErrorTooltip
            />
          </Cell>
          <Cell fixedWidth='auto'>
            <Field name='name' type='text' component={FormField} inputOnly withErrorTooltip />
          </Cell>
          <Cell fixedWidth={250}>
            <Field
              name='type'
              type='select'
              component={FormField}
              options={this.typeOptions}
              inputOnly
              withErrorTooltip
            />
          </Cell>
          <Cell fixedWidth={120}>
            <Field
              name='is_active'
              type='toggle'
              disabled={!item.id}
              component={FormField}
              inputOnly
              withErrorTooltip
            />
          </Cell>
          <Cell fixedWidth={180} alignRight>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <Button primary xs className={'btn--tiny'} onClick={handleSubmit}>
                {trans('ui.save-send')}
              </Button>
              <Button outline xs className={'btn--tiny'} type='button' onClick={() => cancel(item)}>
                {trans('ui.save-cancel')}
              </Button>
            </div>
          </Cell>
        </form>
      </Row>
    )
  }
}

const submit = (values, dispatch, props) => {
  const data = values.toJS()
  const { cancel, createOrUpdateAccountingAccount } = props

  return createOrUpdateAccountingAccount(data)
    .then((item) => cancel(item))
    .catch((res) => {
      throw new SubmissionError(getValidationErrors(res.alerts))
    })
}

const withForm = reduxForm({
  onSubmit: submit,
  enableReinitialize: true,
})

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      change,
      createOrUpdateAccountingAccount,
    },
    dispatch,
  )
}

const mapStateToProps = (state, props) => {
  const form = `accounting-account-form-${props.item.id}`
  const currentValues = getFormValues(form, state)
  const {
    item: { id, account_number, slug, name, type, is_active },
  } = props

  return {
    initialValues: fromJS({
      id,
      name,
      type,
      account_number,
      slug,
      is_active: Boolean(is_active),
    }),
    data: currentValues,
    form,
  }
}

const withConnect = connect(mapStateToProps, mapDispatchToProps)

export default compose(withConnect, withForm)(AccountingAccountListFormItem)
