import React from 'react'

const AvoidUpdate = React.memo(
  (props) => {
    return props.children
  },
  () => true,
)

export default AvoidUpdate
export { AvoidUpdate }
