import React from 'react'
import PropTypes from 'prop-types'
import { FormField, FormFieldIconRadioGroup } from '../../../components/ui/Form/index'
import { Field } from 'redux-form/immutable'
import { trans } from '../../../trans'
import Button from '../../../components/ui/ButtonComponent'
import { TYPE_FERRYBOAT, TYPE_PLANE, TYPE_TRAIN } from '../../../constants/access-lum-sum'
import { Row, Col } from '../../../ui/Grid'
import { EXPENSE_COMMUTE_TYPES_TO_ICONS } from '../../../components/ui/IconComponent'
import { config } from '../../../config'
import { getInstance } from '../../../store/app/instance'
import store from '../../../store'

class Form extends React.Component<any, any> {
  constructor(props) {
    super(props)
  }

  renderDirection(direction) {
    const disabled =
      this.props[direction].location.country_code ===
        getInstance(store.getState()).location.country_code &&
      [TYPE_PLANE, TYPE_TRAIN].includes(this.props[direction].type)

    return (
      <Row>
        <Col xs={12}>
          <Field
            name={`${direction}_location`}
            type='location'
            component={FormField}
            label={trans(`request-expense-commute.form__label-localization-${direction}`)}
            labeltop
            disabled={disabled}
            className={disabled ? 'timeline-change--disabled' : ''}
          />
        </Col>
        <Col xs={12}>
          <Field
            disabled={disabled}
            name={`${direction}_date`}
            type='datepicker'
            label={trans(`request-expense-commute.form__label-direction-${direction}`)}
            placeholder={
              this.props[direction].disable
                ? ' '
                : trans('request-expense-commute.form__label-date')
            }
            component={FormField}
            labeltop
            showTimeSelect
            timeFormat='HH:mm'
            dateFormat={config.datePickerFieldFormat}
            timeIntervals={15}
            className={disabled ? 'timeline-change--disabled' : ''}
            disabledCalendar={disabled}
            errorStyles={{
              'form-group__error-small': true,
              'form-group__error-left-align': true,
            }}
          />
        </Col>
      </Row>
    )
  }

  render() {
    const { handleSubmit, fromIndex, toIndex, from, to, deleteAccessLumpSums } = this.props

    return (
      <div className='expense-commute__travel-list__travel__form-wrapper__form'>
        <form onSubmit={handleSubmit}>
          <Row>
            <Col xs={6}>
              <Field name='from_is_new' type='hidden' component={FormField} inputOnly />
              <Field name='to_is_new' type='hidden' component={FormField} inputOnly />
              <Field name='from_update_count' type='hidden' component={FormField} inputOnly />
              <Field name='to_update_count' type='hidden' component={FormField} inputOnly />
              <Field
                name='type'
                component={FormFieldIconRadioGroup}
                label={trans('request-expense-commute.form__label-type')}
                items={[
                  {
                    value: TYPE_PLANE,
                    icon: EXPENSE_COMMUTE_TYPES_TO_ICONS[TYPE_PLANE],
                  },
                  {
                    value: TYPE_TRAIN,
                    icon: EXPENSE_COMMUTE_TYPES_TO_ICONS[TYPE_TRAIN],
                  },
                  {
                    value: TYPE_FERRYBOAT,
                    icon: EXPENSE_COMMUTE_TYPES_TO_ICONS[TYPE_FERRYBOAT],
                  },
                ]}
              />
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <p className='expense-commute__travel-list__travel__form-wrapper__form__header'>
                <strong>{trans('request-expense-commute.form__add-from')}</strong>
              </p>

              <Row>
                <Col xs={6}>{this.renderDirection('to')}</Col>
                <Col xs={6}>{this.renderDirection('from')}</Col>
              </Row>
            </Col>
          </Row>

          <div className='expense-commute__travel-list__travel__form-wrapper__form__buttons'>
            <Button
              outline
              className='expense-commute__travel-list__travel__form-wrapper__form__button'
              onClick={(e) => {
                e.preventDefault()
                deleteAccessLumpSums([from, to], [fromIndex, toIndex])
              }}
            >
              {trans('global.delete')}
            </Button>

            <Button
              primary
              className='expense-commute__travel-list__travel__form-wrapper__form__button'
            >
              {trans('request-expense-commute.form__button')}
            </Button>
          </div>
        </form>
      </div>
    )
  }
}

Form.propTypes = {
  from: PropTypes.object.isRequired,
  to: PropTypes.object.isRequired,
}

export { Form }
export default { Form }
