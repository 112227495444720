import PropTypes from 'prop-types'
import React from 'react'
import _ from 'lodash'
import classnames from 'classnames'

export class TextField extends React.Component<any, any> {
  handleOnChange(e) {
    const { onChange } = this.props

    if (_.isFunction(onChange)) {
      onChange(e, e.target.value)
    }
  }

  render() {
    const { type, className, ...rest } = this.props
    const classes = classnames({
      input: true,
      'input--text': true,
      [className]: !!className,
    })

    return (
      <input {...rest} type={type} onChange={this.handleOnChange.bind(this)} className={classes} />
    )
  }
}

TextField.propTypes = {
  type: PropTypes.string,
  onChange: PropTypes.func,
  className: PropTypes.string,
}

TextField.defaultProps = {
  type: 'text',
}
