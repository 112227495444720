import React from 'react'
import { connect } from '../../containers/ExternalCompanyHierarchy'
import { CompanyHierarchyComponent } from './CompanyHierarchy'

export class ExternalCompanyHierarchyComponent extends CompanyHierarchyComponent<any, any> {
  constructor(props) {
    super(props)
  }

  render() {
    return super.render()
  }
}

ExternalCompanyHierarchyComponent.propTypes = {}

const ExternalCompanyHierarchy = connect(ExternalCompanyHierarchyComponent)

export default ExternalCompanyHierarchy
export { ExternalCompanyHierarchy }
