import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { getDocuments } from '../../store/app/document-list'

class CostsDocumentsObserver extends Component<any, any> {
  constructor(props) {
    super(props)
    this.state = {
      elements: [],
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    return {
      elements: nextProps.elements,
    }
  }

  isThereAnyDocument = () => {
    const { getDocuments } = this.props
    return this.state.elements.find((element) => {
      return getDocuments(`${element.type}${element.id}`).length > 0
    })
  }

  isThereAnyUnassignedDocument = () => {
    const { getDocuments } = this.props
    return getDocuments('request-expense-unassigned').length > 0
  }

  render() {
    const { children } = this.props

    if (this.isThereAnyDocument() || this.isThereAnyUnassignedDocument()) {
      return null
    }

    return <Fragment>{children}</Fragment>
  }
}

CostsDocumentsObserver.propTypes = {
  elements: PropTypes.array.isRequired,
}

const mapStateToProps = (state) => ({
  getDocuments: (name) => getDocuments(name)(state),
})

CostsDocumentsObserver = connect(mapStateToProps)(CostsDocumentsObserver)

export default CostsDocumentsObserver
export { CostsDocumentsObserver }
