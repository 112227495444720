import React, { Component } from 'react'
import PropTypes from 'prop-types'
import APIClient from '../../services/APIClient'
import { Checkbox } from '../../ui'
import trans from '../../trans'
import { Loader } from '../ui/LoadingOverlay/Loader'
import moment, { isMoment } from 'moment'
import { DatePickerField } from '../ui/Form'
import { config } from '../../config'
import { SensitiveDataForm } from './SensitiveData/SensitiveDataForm'

export default class Roles extends Component<any, any> {
  state = {
    groups: [],
    isLoading: true,
    blockingDate: null,
  }

  componentDidMount() {
    APIClient.getGroups().then(({ data: groups }) => this.setState({ groups }))
    this.getBlockingDate(this.props.user.slug)
  }

  updateGroups = (groupId, checked) => {
    let userGroupIds = this.props.user.groups.map((group) => group.id)

    if (checked) {
      userGroupIds.push(groupId)
    } else {
      userGroupIds = userGroupIds.filter((group) => group !== groupId)
    }

    this.setState({ isLoading: true })
    this.props
      .update(this.props.user.slug, userGroupIds)
      .then(() => this.setState({ isLoading: false }))
  }

  getBlockingDate = (slug) => {
    APIClient.getUserProfile(slug).then((response) => {
      this.setState({ blockingDate: response.data.user.blocked_at })
      this.setState({ isLoading: false })
    })
  }

  setBlockingDate = (date) => {
    this.setState({ isLoading: true })
    this.setState({
      blockingDate: date,
    })
    APIClient.setBlockUser(this.props.user.slug, date).then((response) => {
      this.setState({ isLoading: false })
    })
  }

  render() {
    const { user, setUser } = this.props
    const userGroupIds = user.groups.map((group) => group.id)

    return (
      <div className='has-loader'>
        <SensitiveDataForm setUser={setUser} user={user} />

        <div className='sensitive-data__fields'>
          <h1>{trans('settings-page.access')}</h1>
          <div className='form-group form-group--label-top'>
            <span className='form-group__label'>{trans('settings-page.block')}:</span>

            <div className='form-group__input-wrapper'>
              <DatePickerField
                name={'blockingDate'}
                onChange={(date) => this.setBlockingDate(date)}
                type={'datepicker'}
                minDate={moment()}
                value={
                  !isMoment(this.state.blockingDate) && this.state.blockingDate !== null
                    ? moment(moment(this.state.blockingDate).format(config.apiDateTimeFormat))
                    : this.state.blockingDate
                }
                placeholder={trans('settings-page.date-format')}
              />
            </div>
          </div>
        </div>

        <h1>{trans('settings-page.roles')}</h1>

        {this.state.isLoading && <Loader />}

        {this.state.groups.map((group) => (
          <div className='user-groups--row' key={group.id}>
            <Checkbox
              className='user-groups--checkbox'
              checked={userGroupIds.indexOf(group.id) > -1}
              onChange={({ target: { checked } }) => this.updateGroups(group.id, checked)}
              disabled={!group.abilities.canBeAttachedToUser}
            />
            <div>{group.name}</div>
          </div>
        ))}
      </div>
    )
  }
}

Roles.propTypes = {
  user: PropTypes.object.isRequired,
  setUser: PropTypes.func.isRequired,
  update: PropTypes.func.isRequired,
}
