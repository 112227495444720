import { getElements } from '../index'
import { TRAVEL_ACCOMODATION_SUGGESTION, TRAVEL_EMPTY } from '../../../../constants/travel'
import { Factory as ElementFactory } from '../../../../models/timeline'

const EXCLUDED_TYPES = [TRAVEL_ACCOMODATION_SUGGESTION, TRAVEL_EMPTY]

class EdgePointsDateSuggester {
  constructor(state) {
    this.elements = getElements(state)
  }

  getStartPointMaxDate() {
    const element = this.elements.find((element) => EXCLUDED_TYPES.indexOf(element.type) === -1)

    if (element) {
      return ElementFactory.create(element).getStartDate()
    }

    return null
  }

  getEndPointMinDate() {
    const elements = this.elements.reverse()
    const element = elements.find((element) => EXCLUDED_TYPES.indexOf(element.type) === -1)

    if (element) {
      return ElementFactory.create(element).getEndDate()
    }

    return null
  }
}

export { EdgePointsDateSuggester }
export default EdgePointsDateSuggester
