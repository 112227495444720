import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Travel } from './Travel'
import _ from 'lodash'

export class TravelList extends Component<any, any> {
  constructor(props) {
    super(props)
  }

  render() {
    const {
      accessLumpSums,
      setAccessLumpSumPair,
      deleteAccessLumpSums,
      setStoreForAccessLumpSumsPair,
      isReadOnly,
    } = this.props

    return (
      <div>
        <ul className='expense-commute__travel-list'>
          {_.chunk(accessLumpSums, 2).map((accessLumpSumsPair) => {
            const to = accessLumpSumsPair.find(
              (accessLumpSum) => accessLumpSum.destination === 'to',
            )
            const from = accessLumpSumsPair.find(
              (accessLumpSum) => accessLumpSum.destination === 'from',
            )
            const toIndex = accessLumpSums.indexOf(to)
            const fromIndex = accessLumpSums.indexOf(from)

            if (to && from) {
              return (
                <Travel
                  to={to}
                  from={from}
                  toIndex={toIndex}
                  fromIndex={fromIndex}
                  key={from.id || from.isNew}
                  setAccessLumpSumPair={setAccessLumpSumPair}
                  deleteAccessLumpSums={deleteAccessLumpSums}
                  setStoreForAccessLumpSumsPair={setStoreForAccessLumpSumsPair}
                  isReadOnly={isReadOnly}
                  confirmed={to.confirmed && from.confirmed}
                />
              )
            }

            return null
          })}
        </ul>
      </div>
    )
  }
}

TravelList.propTypes = {
  accessLumpSums: PropTypes.array.isRequired,
  setAccessLumpSumPair: PropTypes.func.isRequired,
  deleteAccessLumpSums: PropTypes.func.isRequired,
  setStoreForAccessLumpSumsPair: PropTypes.func.isRequired,
  isReadOnly: PropTypes.bool,
}
