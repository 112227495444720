import React, { Component, Fragment } from 'react'
import Autosuggest from 'react-autosuggest'
import { get, has, isFunction, throttle } from 'lodash'
import { fromJS, Map } from 'immutable'
import Location from '../../../models/Location'
import axios from 'axios'
import { Loader } from '../LoadingOverlay/Loader'
import PropTypes from 'prop-types'

class CustomAutocompleteField extends Component<any, any> {
  constructor(props) {
    super(props)

    this.state = {
      suggestions: [],
      value: '',
      isFetching: false,
      smart: false,
      initialized: false,
      error: false,
    }

    this.inputRef = React.createRef()
    this.cancelToken = axios.CancelToken.source()
  }

  defaultInitValue = () => {
    let { input } = this.props

    input.onChange(input.value)

    this.setState({ value: input.value.name || '', initialized: true })
  }

  componentWillUnmount() {
    this.setState({
      suggestions: [],
      value: '',
      isFetching: false,
      smart: false,
      initialized: false,
      error: false,
    })

    if (this.cancelToken) {
      this.cancelToken.cancel()
      this.cancelToken = null
    }
  }

  componentDidMount() {
    let { input } = this.props

    if (input.value instanceof Map) {
      input.value = input.value.toJS()
    }

    if (this.state.initialized) {
      return
    }

    if (this.props.smart) {
      this.smartFindTarget(input.value)
        .then((response) => {
          const suggestion = get(response.data, '0.stations.0', null)
          if (suggestion) {
            input.onChange(suggestion)
            this.setState({ value: suggestion.name, initialized: true })
          }
        })
        .catch(() => {
          this.defaultInitValue()
        })
    } else {
      this.defaultInitValue()
    }
  }

  smartFindTarget(value) {
    const { fetchMethod } = this.props
    return new Promise((resolve, reject) => {
      if (has(value, 'additional_data.type')) {
        return reject()
      }

      const address = get(value, 'city') || get(value, 'formatted_address')
      if (!address) {
        return reject()
      }

      fetchMethod(address, true)
        .then((response) => {
          if (response.data.length) {
            resolve(response)
          } else {
            reject()
          }
        })
        .catch((reason) => {
          reject(reason)
        })
    })
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    return {
      ...prevState,
      smart: nextProps.smart,
    }
  }

  getSuggestions = (value) => {
    const { fetchMethod } = this.props

    if (this.state.isFetching) {
      this.cancelToken.cancel('message')
      this.cancelToken = axios.CancelToken.source()
    }

    this.setState({ isFetching: true })

    fetchMethod(value, false, this.cancelToken)
      .then((response) => {
        const groupedSuggestions = response.data.reduce((acc, item) => {
          const suggestion = {
            title: item.city,
            stations: item.stations,
          }
          acc.push(suggestion)
          return acc
        }, [])

        const error = response.alerts.find((alert) => alert.type === 'error')

        this.setState({ suggestions: groupedSuggestions, isFetching: false, error: !!error })
      })
      .catch(() => {
        this.setState({ error: true })
      })
  }

  onSuggestionsFetchRequested = ({ value, reason }) => {
    if (value.length > 2 && reason === 'input-changed') {
      throttle(this.getSuggestions, 5000)(value)
    } else {
      this.setState({ suggestions: [], isFetching: false })
    }
  }

  onChange = (event, { newValue, method }) => {
    const { input } = this.props
    this.setState({
      value: newValue,
    })

    if (!newValue.length) {
      input.onChange(new Location({}))
    }
  }

  onBlur = (event, { highlightedSuggestion }) => {
    let {
      input: { value, onChange },
    } = this.props

    if (value instanceof Map) {
      value = value.toJS()
    }

    if (event.target.value !== value.name) {
      this.setState({ value: value.name || '' })
    }

    if (onChange && highlightedSuggestion) {
      this.setState({ value: highlightedSuggestion.name }, () => onChange(highlightedSuggestion))
    }
  }

  onFocus = (event) => {
    const { input } = this.props
  }

  onSuggestionsClearRequested = () => {
    const { input } = this.props
    this.setState({ suggestions: [] })
  }

  getSuggestionValue = (suggestion) => suggestion.name

  renderSectionTitle = (section) => <div>{section.title}</div>

  renderSuggestion = (suggestion) => (
    <div className='autosuggest__suggestion'>
      <span className='autosuggest__suggestion-name'>{suggestion.name}</span>
    </div>
  )

  getSectionSuggestions = (section) => section.stations

  onSuggestionSelected = (
    event,
    { suggestion, suggestionValue, suggestionIndex, sectionIndex, method },
  ) => {
    const { input, immutable } = this.props

    if (immutable) {
      suggestion = fromJS(suggestion)
    }

    if (isFunction(input.onChange)) {
      input.onChange(suggestion)
    }
  }

  onKeyPress = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault()
    }
  }

  render() {
    const { value, suggestions, error } = this.state
    let { input, label, meta, errorMessage } = this.props
    const metaError = get(meta, 'error', '')

    return (
      <Fragment>
        {!this.state.initialized && <Loader />}

        <div className='form-group form-group--label-top'>
          <span className='form-group__label'>{label}</span>
          <div className='form-group__input-wrapper'>
            <div className='train-autosuggest-wrapper'>
              <Autosuggest
                id={input.id}
                multiSection={true}
                suggestions={suggestions}
                onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
                getSuggestionValue={this.getSuggestionValue}
                getSectionSuggestions={this.getSectionSuggestions}
                onSuggestionsClearRequested={this.onSuggestionsClearRequested}
                renderSectionTitle={this.renderSectionTitle}
                renderSuggestion={this.renderSuggestion}
                onSuggestionSelected={this.onSuggestionSelected}
                alwaysRenderSuggestions={true}
                inputProps={{
                  ...input,
                  value,
                  onChange: this.onChange,
                  onBlur: this.onBlur,
                  onFocus: this.onFocus,
                  className: 'input input--text autocomplete__input',
                  id: null,
                  onKeyPress: this.onKeyPress,
                }}
                ref={this.inputRef}
                disabled={false}
              />
            </div>
            {this.state.isFetching && <div className='input-loader' />}

            <div className='form-group__error'>{metaError}</div>

            {error && errorMessage && <div className='form-group__error'>{errorMessage}</div>}
          </div>
        </div>
      </Fragment>
    )
  }
}

CustomAutocompleteField.propTypes = {
  fetchMethod: PropTypes.func.isRequired,
  errorMessage: PropTypes.string,
}

export default CustomAutocompleteField
export { CustomAutocompleteField }
