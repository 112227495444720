import { createSlice } from '@reduxjs/toolkit'
import APIClient from '../../../services/APIClient'
import { config } from '../../../config'
import { isEmpty } from 'lodash'
import { createSelector } from 'reselect'
import {
  TRAVEL_ACCOMODATION,
  TRAVEL_PLANE_TRIP,
  TRAVEL_TRAIN_TRIP,
} from '../../../constants/travel'
import { hasRequestAbility } from '../trip-request'

export const INSTANCE_MOUNT_POINT = 'instance'

const getInitialState = () => ({
  instance: {},
  translations: {},
  error: null,
  isLoading: false,
})

/**
 * In @reduxjs/toolkit we can mutate state in reducers,
 * because behind the scene it uses ImmerJS for applying them as non-mutate
 *
 * https://github.com/immerjs/immer
 */
const instanceSlice = createSlice({
  name: INSTANCE_MOUNT_POINT,
  initialState: getInitialState(),
  reducers: {
    resetInstance() {
      return getInitialState()
    },
    setTranslations(state, action) {
      state.translations = action.payload
    },
    setInstance(state, action) {
      state.instance = action.payload
    },
    startLoading(state) {
      state.isLoading = true
      state.error = null
    },
    setLoadingSuccess(state) {
      state.isLoading = false
      state.error = null
    },
    setLoadingFailed(state, action) {
      state.isLoading = false
      state.error = action.payload
    },
  },
})

export const {
  resetInstance,
  setInstance,
  setTranslations,
  startLoading,
  setLoadingSuccess,
  setLoadingFailed,
} = instanceSlice.actions

export default instanceSlice.reducer

// selectors
const getState = (state) => state.get(INSTANCE_MOUNT_POINT)

export const getInstance = (state) => getState(state).instance
export const getCurrency = (state) => getInstance(state).currency
export const getLocation = (state) => getInstance(state).location
export const getModules = (state) => getInstance(state).modules
export const getTranslations = (state) => getState(state).translations
export const isDemoInstance = (state) => getInstance(state).demo_mode
export const isInstanceLoading = (state) => getState(state).isLoading
export const isModuleActive = () =>
  createSelector(
    [(state, _name) => getModules(state), (_state, name) => name],
    (modules, name) =>
      modules && name in modules && modules[name] && modules[name].enabled === true,
  )

// action thunks
export const fetchInstance = () => async (dispatch, state) => {
  if (isInstanceLoading(state()) || !isEmpty(getInstance(state()))) {
    return
  }

  dispatch(startLoading())

  try {
    const {
      data: { translations, instance },
    } = await APIClient.getInstance()

    config.storage.setItem('instanceData', JSON.stringify(instance))

    dispatch(setInstance(instance))
    dispatch(setTranslations(translations))
    dispatch(setLoadingSuccess())
  } catch (err) {
    dispatch(setLoadingFailed(err))
  }
}

export const fetchInstanceBeforeLogin =
  (lang = 'pl') =>
  async (dispatch) => {
    dispatch(startLoading())

    try {
      const {
        data: { instance, translations },
      } = await APIClient.getInstanceBeforeLogin(lang)

      dispatch(setInstance(instance))
      dispatch(setTranslations(translations))
      dispatch(setLoadingSuccess())
    } catch (err) {
      dispatch(setLoadingFailed(err))
    }
  }

export const isFeatureEnabled = (featureName) => (state) => {
  return getInstance(state).features[featureName]
}
