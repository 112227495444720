import { trans } from '../../../trans'
import React from 'react'
import { connect } from '../../../containers/FlightsBooking'
import TariffConditions from './TariffConditions'
import { get } from 'lodash'
import { BOOKING_OFFER_CHANGED } from '../../../store/app/flights-booking'
import { AgreementContainer } from '../../AgreementContainer'

const Agreement = connect((props) => {
  const {
    flights: {
      actions: { setAgreement },
      selectors: { isAgreement },
    },
    offer,
  } = props
  const bookingStatus = get(offer, 'options.0.booking', null)

  const allowed = (bookingStatus !== null && bookingStatus !== BOOKING_OFFER_CHANGED) || isAgreement

  return (
    <AgreementContainer setAgreement={setAgreement} allowed={allowed}>
      {trans('flights-booking.read-accepted')} <TariffConditions />{' '}
      {trans('flights-booking.selected-airline')}
    </AgreementContainer>
  )
})

export default Agreement
export { Agreement }
