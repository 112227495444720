// initial state
import APIClient from '../../../services/APIClient'
import { differenceBy } from 'lodash'

const getInitialState = () => {
  return {
    acceptors: [],
    settlementAcceptors: [],
  }
}

// constants
export const RESET = 'request-acceptors::reset'
export const SET_ACCEPTORS = 'request-acceptors::set-acceptors'
export const SET_SETTLEMENT_ACCEPTORS = 'request-acceptors::set-settlement-acceptors'
export const MOUNT_POINT = 'request-acceptors'

// reducer
export const reducer = (state = getInitialState(), { type, payload }) => {
  switch (type) {
    case RESET:
      return getInitialState()
    case SET_ACCEPTORS:
      return {
        ...state,
        acceptors: payload,
      }
    case SET_SETTLEMENT_ACCEPTORS:
      return {
        ...state,
        settlementAcceptors: payload,
      }
    default:
      return state
  }
}

// actions
export const reset = () => (dispatch) => {
  dispatch({
    type: RESET,
  })
}

export const setAcceptors = (acceptors) => (dispatch) => {
  dispatch({
    type: SET_ACCEPTORS,
    payload: acceptors,
  })
}

export const setSettlementAcceptors = (settlementAcceptors) => (dispatch) => {
  dispatch({
    type: SET_SETTLEMENT_ACCEPTORS,
    payload: settlementAcceptors,
  })
}

export const fetchAcceptors = () => (dispatch) => {
  return new Promise((resolve) => {
    APIClient.getAcceptors().then((response) => {
      const acceptors = response.data

      dispatch(setAcceptors(acceptors))
      resolve(acceptors)
    })
  })
}

export const fetchSettlementAcceptors = () => (dispatch) => {
  return new Promise((resolve) => {
    APIClient.getSettlementAcceptors().then((response) => {
      const settlementAcceptors = response.data

      dispatch(setSettlementAcceptors(settlementAcceptors))
      resolve(settlementAcceptors)
    })
  })
}

export const addAcceptor = (request, acceptor) => (dispatch) => {
  return new Promise((resolve) => {
    APIClient.addAcceptor(request['slug'], acceptor['slug']).then(() => {
      resolve()
    })
  })
}

export const addSettlementAcceptor = (request, acceptor) => (dispatch) => {
  return new Promise((resolve) => {
    APIClient.addSettlementAcceptor(request['slug'], acceptor['slug']).then(() => {
      resolve()
    })
  })
}

export const removeAcceptor = (request, acceptor) => (dispatch) => {
  return APIClient.removeAcceptor(request['slug'], acceptor['slug'])
}

export const removeSettlementAcceptor = (request, acceptor) => (dispatch) => {
  return APIClient.removeSettlementAcceptor(request['slug'], acceptor['slug'])
}

// selectors
export const getState = (state) => {
  return state.get(MOUNT_POINT)
}

export const getAcceptors = (state) => {
  return getState(state).acceptors
}

export const getSettlementAcceptors = (state) => {
  return getState(state).settlementAcceptors
}
