import { Cell, Row } from '../../../ui/AccordionTable'
import Button from '../../../ui/ButtonComponent'
import trans from '../../../../trans'
import React from 'react'
import { Field, reduxForm, SubmissionError } from 'redux-form/immutable'
import { connect } from 'react-redux'
import { bindActionCreators, compose } from 'redux'
import { getFormValues } from '../../../../utils/forms'
import { fromJS } from 'immutable'
import { FormField } from '../../../ui/Form'
import { getValidationErrors } from '../../../../utils'
import { createOrUpdateVatNumber } from '../../../../store/app/vat'
import {
    getActiveAccountingAccountSelectOptions,
    getActiveAccountingAccountSelectOptionsByType,
} from '../../../../store/app/accounting-account'

class VatListFormItem extends React.Component<any, any> {
  render() {
    const { handleSubmit, cancel, item, accountsOptions, reverseAccountingAccountOptions } = this.props

    return (
      <Row>
        <form onSubmit={handleSubmit}>
          <Cell fixedWidth={150}>
            <Field name='code' type='text' component={FormField} inputOnly withErrorTooltip />
          </Cell>
          <Cell fixedWidth='auto'>
            <Field name='name' type='text' component={FormField} inputOnly withErrorTooltip />
          </Cell>
          <Cell fixedWidth={150} alignRight>
            <Field
              name='value'
              type='formatted_number'
              component={FormField}
              inputOnly
              withErrorTooltip
            />
          </Cell>
          <Cell fixedWidth={150} alignRight>
            <Field
              name='deductibility'
              type='formatted_number'
              component={FormField}
              inputOnly
              withErrorTooltip
            />
          </Cell>
            <Cell fixedWidth={200}>
            <div>
                DR:
                <Field
                name="accounting_account_id"
                type="select"
                component={FormField}
                options={accountsOptions}
                inputOnly
                withErrorTooltip
            />
            </div>
            <div>
                CR:
                <Field
                    name="reverse_accounting_account_id"
                    type="select"
                    component={FormField}
                    clearable={true}
                    options={reverseAccountingAccountOptions}
                    inputOnly
                    withErrorTooltip
                />
            </div>
            </Cell>
            <Cell fixedWidth={120}>
            <Field
              name='is_active'
              type='toggle'
              component={FormField}
              disabled={!item.slug}
              inputOnly
              withErrorTooltip
            />
          </Cell>
          <Cell fixedWidth={180} alignRight>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <Button primary xs className={'btn--tiny'} onClick={handleSubmit}>
                {trans('ui.save-send')}
              </Button>
              <Button outline xs className={'btn--tiny'} type='button' onClick={() => cancel(item)}>
                {trans('ui.save-cancel')}
              </Button>
            </div>
          </Cell>
        </form>
      </Row>
    )
  }
}

const submit = (values, dispatch, props) => {
  const data = values.toJS()
  const { cancel, createOrUpdateVatNumber } = props

  return createOrUpdateVatNumber(data)
    .then((item) => cancel(item))
    .catch((res) => {
      throw new SubmissionError(getValidationErrors(res.alerts))
    })
}

const withForm = reduxForm({
  onSubmit: submit,
  enableReinitialize: true,
})

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      createOrUpdateVatNumber,
    },
    dispatch,
  )
}

const mapStateToProps = (state, props) => {
  const form = `vat-number-form-${props.item.slug}`
  const currentValues = getFormValues(form, state)
  const {
    item: { slug, code, name, value, deductibility, is_active, accountingAccount, reverse_accounting_account },
  } = props

  return {
    initialValues: fromJS({
      slug,
      name,
      code,
      value,
      deductibility,
      accounting_account_id: accountingAccount.id,
      reverse_accounting_account_id: reverse_accounting_account ? reverse_accounting_account.id : null,
      is_active: Boolean(is_active),
    }),
    data: currentValues,
    form,
    accountsOptions: getActiveAccountingAccountSelectOptionsByType(state, accountingAccount),
    reverseAccountingAccountOptions: getActiveAccountingAccountSelectOptions(state, reverse_accounting_account),
  }
}

const withConnect = connect(mapStateToProps, mapDispatchToProps)

export default compose(withConnect, withForm)(VatListFormItem)
