import React from 'react'
import { EXPENSES_TYPES_TO_ICONS } from '../../ui/IconComponent'
import { ExpenseTypeBox } from '../../ExpenseTypeBox'
import Icon from '../../ui/IconComponent'
import { AmountFormatter } from '../../AmountFormatter'
import { TooltipContent } from '../../ui/Tooltip'
import { Row } from '../../../ui/Grid'
import * as classnames from 'classnames'

class ExpenseTypesGroupContent extends React.Component<any, any> {
  render() {
    const { group, children, className } = this.props

    if (!group) {
      return null
    }

    return (
      <TooltipContent
        key={`tooltip-${group.id}`}
        className={classnames('expense-type__tooltip', className)}
        arrowPosition={group.arrowPosition}
      >
        {children}
      </TooltipContent>
    )
  }
}

export default ExpenseTypesGroupContent
