import { getRequestTravelers } from './index'

export function getSelectedTravelersOrDefault(queryList, state) {
  const allTravelers = getRequestTravelers(state)

  if (queryList && queryList.length) {
    return queryList.filter((slug) => allTravelers.find((item) => item.slug === slug))
  }

  // if more than one traveler has been set to request, then user have to select correct travelers
  if (allTravelers.length === 1) {
    // make sure that this is a lead
    const lead = allTravelers.find((x) => x.is_lead)

    if (lead) {
      return [lead.slug]
    }
  }

  return []
}
