import React from 'react'
import PropTypes from 'prop-types'
import TruncateMarkup from 'react-truncate-markup'
import Ellipsis from '../ui/Ellipsis/Ellipsis'
import classNames from 'classnames'

class TruncateBreakable extends React.Component {
  constructor(props) {
    super(props)

    this.onTruncate = this.onTruncate.bind(this)
    this.state = {
      isTruncated: false,
    }
  }

  onTruncate(wasTruncated) {
    this.setState({ isTruncated: wasTruncated })
  }

  render() {
    if (!this.props.text) {
      return null
    }

    const text = <span className={'breakable'}>{this.props.text}</span>
    const classes = classNames({
      'truncate-breakable--truncated': this.state.isTruncated,
    })

    return (
      <span className={classes}>
        <TruncateMarkup
          lines={this.props.lines}
          ellipsis={<Ellipsis text={text} />}
          onTruncate={this.onTruncate}
        >
          {text}
        </TruncateMarkup>
      </span>
    )
  }
}

TruncateBreakable.propTypes = {
  lines: PropTypes.number.isRequired,
  text: PropTypes.string,
}

export default TruncateBreakable
