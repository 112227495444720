import { createSlice } from '@reduxjs/toolkit'
import APIClient from '../../../services/APIClient'

export const USER_GROUPS_MOUNT_POINT = 'user-groups'

const getInitialState = () => ({
  items: [],
  error: null,
  isLoading: false,
})

/**
 * In @reduxjs/toolkit we can mutate state in reducers,
 * because behind the scene it uses ImmerJS for applying them as non-mutate
 *
 * https://github.com/immerjs/immer
 */
const userGroupsSlice = createSlice({
  name: USER_GROUPS_MOUNT_POINT,
  initialState: getInitialState(),
  reducers: {
    resetUserGroups(state) {
      state = getInitialState()
    },
    setUserGroups(state, action) {
      const { data } = action.payload

      state.items = data
    },
    startLoading(state) {
      state.isLoading = true
      state.error = null
    },
    setLoadingSuccess(state) {
      state.isLoading = false
      state.error = null
    },
    setLoadingFailed(state, action) {
      state.isLoading = false
      state.error = action.payload
    },
  },
})

export const { resetUserGroups, setUserGroups, startLoading, setLoadingSuccess, setLoadingFailed } =
  userGroupsSlice.actions

export default userGroupsSlice.reducer

// selectors
const getState = (state) => state.get(USER_GROUPS_MOUNT_POINT)

export const getUserGroups = (state) => getState(state).items

// action thunks
export const fetchUserGroups = () => async (dispatch) => {
  dispatch(startLoading())

  try {
    const { data } = await APIClient.getGroups()
    dispatch(setUserGroups({ data }))
    dispatch(setLoadingSuccess())
  } catch (err) {
    dispatch(setLoadingFailed(err))
  }
}

export const fetchUserGroupsIfNeeded = () => async (dispatch, state) => {
  const { items, isLoading } = getState(state())

  if (items.length || isLoading) {
    return
  }

  return dispatch(fetchUserGroups())
}
