import React from 'react'
import { bindActionCreators, compose } from 'redux'
import { reduxForm } from 'redux-form/immutable'
import { QueryFields } from './QueryFields'
import { fromJS } from 'immutable'
import { connect } from 'react-redux'
import { connect as connectFlights } from '../../../containers/FlightsBooking'
import ActionButtons from '../Booking/ActionButtons'
import trans from '../../../trans'
import OffersDialog from '../List/OffersDialog'
import { LocationSuggester } from '../../../store/app/trip-timeline/services/location-suggester'
import { DateSuggester } from '../../../store/app/trip-timeline/services/date-suggester'
import { updateElement } from '../../../store/app/trip-timeline'
import { getFormValues } from '../../../utils/forms'

const SearchFormComponent = (props) => {
  return (
    <form>
      {props.flights.selectors.searchFailed() && (
        <div className='plane-offer__reservation-status'>
          <span className='reservation-status reservation-status--error'>
            {trans('global.search-failed')}
          </span>
        </div>
      )}

      <div className='plane-trip__dashboard'>
        <QueryFields />
        <ActionButtons query={props.query} />
      </div>
      <OffersDialog element={props.element} />
    </form>
  )
}

const withForm = reduxForm({
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
  destroyOnUnmount: true,
  onChange: (values, dispatch, props) => {
    const data = values.toJS()

    const element = {
      ...props.element,
      flight_from: data.flight_from,
      flight_to: data.flight_to,
      arrival_at: data.arrival_at,
      return_at: data.return_at,
      round_trip: data.round_trip,
      flight_class: data.flight_class,
      direct_only: data.direct_only,
      restrict_to_fba: data.restrict_to_fba,
      request_travelers: data.request_travelers,
    }

    const prevElement = {
      ...props.element,
    }

    dispatch(props.updateElement({ prevValues: prevElement, nextValues: element, silent: true }))
  },
})

const mapStateToProps = (state, props) => {
  const {
    context: { name, element: plane },
  } = props
  const locationSuggester = new LocationSuggester(state, plane)
  const dateSuggester = new DateSuggester(state, plane)
  const hasLocation = (location) => location && location.city

  return {
    initialValues: fromJS({
      flight_from: hasLocation(plane.getStartLocation())
        ? plane.getStartLocation()
        : locationSuggester.suggestStartLocation(),
      flight_to: hasLocation(plane.getEndLocation())
        ? plane.getEndLocation()
        : locationSuggester.suggestEndLocation(),
      arrival_at: plane.getStartDate() ? plane.getStartDate() : dateSuggester.suggestStartDate(),
      return_at: plane.getEndDate() ? plane.getEndDate() : dateSuggester.suggestEndDate(),
      round_trip: plane.round_trip,
      flight_class: plane.flight_class,
      direct_only: plane.direct_only,
      restrict_to_fba: plane.restrict_to_fba,
      request_travelers: plane.request_travelers,
    }),
    element: plane,
    form: name,
    query: getFormValues(name, state),
  }
}

const mapDispatchToProps = (dispatch, props, ownProps) => {
  return bindActionCreators(
    {
      updateElement,
    },
    dispatch,
  )
}

const withConnect = connect(mapStateToProps, mapDispatchToProps)

const SearchForm = compose(connectFlights, withConnect, withForm)(SearchFormComponent)

export { SearchForm }
export default SearchForm
