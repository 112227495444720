import React from 'react'
import { createSelector, createSlice } from '@reduxjs/toolkit'
import { bindActionCreators, compose } from 'redux'
import { connect } from 'react-redux'
import uuid from '../../../utils/uuid'
import APIClient from '../../../services/APIClient'
import Element from '../../../models/timeline/Element'
import { Factory as ElementFactory } from '../../../models/timeline'
import moment from 'moment'
import { config } from '../../../config'

export const TARGET_POINTS_MOUNT_POINT = 'target-points'

const slice = createSlice({
  name: TARGET_POINTS_MOUNT_POINT,
  initialState: {
    targetPoints: [],
  },
  reducers: {
    setTargetPoints(state, action) {
      state.targetPoints = action.payload
    },
    removeDrafts(state, action) {
      state.targetPoints = state.targetPoints.filter((point) => point.draft !== true)
    },
    setTargetPointsByRequest(state, action) {
      const request = action.payload
      const targetPoints = [
        { ...request.start_location, id: 'trip-start' },
        ...(request.targetPoints || []),
        { ...request.end_location, id: 'trip-end' },
      ]

      state.targetPoints = targetPoints
        .map((point) => ({
          ...point,
          cid: point.id,
        }))
        .sort((a, b) => a.weight - b.weight)
    },
    removeTargetPoint(state, action) {
      const targetPoint = action.payload

      state.targetPoints = state.targetPoints.filter((point) => point.cid !== targetPoint.cid)
    },
    insertTargetPoint(state, action) {
      const index = action.payload
      const point = {
        id: null,
        cid: uuid(),
        draft: true,
      }

      state.targetPoints.splice(index, 0, point)
    },
    updateTargetPoint(state, action) {
      const point = action.payload
      const index = state.targetPoints.findIndex((p) => p.cid === point.cid)

      state.targetPoints[index] = point
    },
  },
})

export const {
  setTargetPointsByRequest,
  removeTargetPoint,
  insertTargetPoint,
  updateTargetPoint,
  removeDrafts,
} = slice.actions

export default slice.reducer

// selectors
const getState = (state) => state.get(TARGET_POINTS_MOUNT_POINT)
export const getTargetPoints = createSelector(getState, (state) => state.targetPoints)

const remove = (request) => (point) => (dispatch) => {
  dispatch(removeTargetPoint(point))

  if (point.draft) {
    return new Promise((resolve) => resolve())
  }

  return APIClient.removeTargetPoint(request.slug, point.id).catch(() => {})
}

const insert = (index) => (dispatch) => {
  dispatch(insertTargetPoint(index + 1))
}

const updateWeight = (request) => async (dispatch, getState) => {
  const toElement = (item) => (!(item instanceof Element) ? ElementFactory.create(item) : item)
  const dateOnly = (item) => toElement(item).getStartDate()
  const targetPointsOnly = (item) => item.type === 'target_point'
  const targetPointsExclude = (item) => item.type !== 'target_point'
  const toUnix = (item) => moment(toElement(item).getStartDate(), config.apiDateTimeFormat).unix()
  const sortByDate = (a, b) => toUnix(a) - toUnix(b)

  const combinedTravelElements = request.combinedTravelElements.filter(targetPointsExclude)
  const targetPoints = getTargetPoints(getState())
  const elements = [...combinedTravelElements, ...targetPoints.filter((item) => item.date)]
  const sorted = elements.filter(dateOnly).sort(sortByDate)

  const order = sorted.map((element, index) => {
    let weight = {
      type: element.type,
      id: element.id,
    }

    if (element.virtual) {
      weight['return_weight'] = index
    } else {
      weight['weight'] = index
    }

    return weight
  })

  return APIClient.updateTimelineWeight(request.slug, order).then(() => {
    dispatch(
      slice.actions.setTargetPoints([
        targetPoints.find((item) => item.id === 'trip-start'),
        ...sorted.filter(targetPointsOnly),
        targetPoints.find((item) => item.id === 'trip-end'),
      ]),
    )
  })
}

const save = (request) => (point, fields) => (dispatch) => {
  const promise = point.draft
    ? APIClient.createTargetPoint(request.slug, fields)
    : APIClient.updateTargetPoint(request.slug, point.id, fields)

  return promise.then((response) => {
    dispatch(
      updateTargetPoint({
        ...response.data,
        cid: point.cid,
      }),
    )

    dispatch(updateWeight(request))

    return response
  })
}

const targetPoints = () => (Component) => {
  class TargetPointsHOC extends React.PureComponent<any, any> {
    render() {
      return <Component<any, any> {...this.props} />
    }
  }

  const mapStateToProps = (state) => ({
    targetPoints: getTargetPoints(state),
  })

  const mapDispatchToProps = (dispatch, props) => {
    return bindActionCreators(
      {
        remove: remove(props.request),
        save: save(props.request),
        insert,
      },
      dispatch,
    )
  }

  const mergedProps = (selectors, actions, own) => {
    return {
      ...own,
      targetPoints: {
        selectors,
        actions,
      },
    }
  }

  const withConnect = connect(mapStateToProps, mapDispatchToProps, mergedProps)

  return compose(withConnect)(TargetPointsHOC)
}

export { targetPoints }
