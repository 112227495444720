import _ from 'lodash'
import * as requestConstants from './constants/request'

/**
 * Parse API output and return validation errors
 * @param errors
 */
export const getValidationErrors = (errors) => {
  if (errors) {
    return _.head(errors).errors
  }

  return []
}

export const getNextPossibleStatus = (currentStatus) => {
  let stack = requestConstants.STACK_OF_STATUSES
  if (currentStatus === stack[stack.length - 1]) {
    return requestConstants.STATUS_DRAFT
  }
  return stack[stack.indexOf(currentStatus) + 1]
}

export const getPreviousPossibleStatus = (currentStatus) => {
  let stack = requestConstants.STACK_OF_STATUSES
  if (currentStatus === stack[0]) {
    return requestConstants.STATUS_DRAFT
  }
  return stack[stack.indexOf(currentStatus) - 1]
}
