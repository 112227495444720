import React from 'react'
import { SmartCheckbox } from '../../ui/Input/SmartCheckbox'

export const AgreementContainer = (props) => (
  <div className='agreement-message d-flex'>
    <SmartCheckbox
      name='placeholder'
      onChange={(value) => props.setAgreement(value)}
      checked={props.allowed}
      disabled={props.disabled}
    />
    <p style={{ margin: '0 0 0 10px' }}>{props.children}</p>
  </div>
)
