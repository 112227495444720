import React, {
  forwardRef,
  useContext,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react'
import { SelectField } from '../ui/Form'
import { AgGridContext } from './AgGridContext'
import { Toggle } from '../../ui'

const ToggleEditor = forwardRef((props, ref) => {
  const [, { isRowInEditingState }] = useContext(AgGridContext)
  const [value, setValue] = useState(Boolean(props.value))
  const refInput = useRef(null)
  const isEditing = isRowInEditingState(props.node.id)

  useImperativeHandle(ref, () => {
    return {
      getValue() {
        return [props.colDef.field, value]
      },

      reset() {
        setValue(Boolean(props.value))
      },
    }
  })

  if (!isEditing) {
    return <span>{props.valueFormatted ? props.valueFormatted : props.value}</span>
  }

  return (
    <Toggle
      name={props.colDef.field}
      onChange={() => {
        setValue(!value)
      }}
      checked={value === true}
      ref={refInput}
    />
  )
})

export default ToggleEditor
