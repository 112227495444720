// initial state
import APIClient from '../../../services/APIClient'
import { toJS } from 'immutable'
import { isEmpty, isString, isUndefined } from 'lodash'
import uuid from '../../../utils/uuid'
import localStorage from '../../../services/localStorage'
import { trans } from '../../../trans'

const getInitialState = () => {
  return {
    elements: {
      isLoaded: false,
      isLoading: false,
      data: [],
    },
    currencies: {
      isLoaded: false,
      isLoading: false,
      data: [],
    },
    types: {
      isLoaded: false,
      isLoading: false,
      data: [],
    },
    saving: false,
  }
}

// constants
export const MOUNT_POINT = 'request-other-costs'

export const RESET = `${MOUNT_POINT}::reset`
export const START_LOADING = `${MOUNT_POINT}::start-loading`
export const SET = `${MOUNT_POINT}::set`
export const SET_CURRENCIES = `${MOUNT_POINT}::set-currencies`
export const SET_TYPES = `${MOUNT_POINT}::set-types`
export const UPDATE_ELEMENT = `${MOUNT_POINT}::update-element`
export const REMOVE_ELEMENT = `${MOUNT_POINT}::remove-element`
export const TOGGLE_ELEMENT = `${MOUNT_POINT}::toggle-element`
export const ADD_ELEMENT = `${MOUNT_POINT}::add-element`
export const SET_SAVING = `${MOUNT_POINT}::set-saving`

// reducer
export const reducer = (state = getInitialState(), action) => {
  switch (action.type) {
    case RESET:
      return getInitialState()
    case START_LOADING: {
      const key = action.payload

      return {
        ...state,
        [key]: {
          ...state[key],
          isLoading: true,
        },
      }
    }

    case SET:
      return {
        ...state,
        elements: {
          ...state.elements,
          data: action.payload,
        },
      }

    case SET_CURRENCIES:
      return {
        ...state,
        currencies: {
          ...state.currencies,
          data: action.payload,
          isLoading: false,
          isLoaded: true,
        },
      }

    case SET_TYPES:
      return {
        ...state,
        types: {
          ...state.types,
          data: action.payload,
          isLoading: false,
          isLoaded: true,
        },
      }

    case UPDATE_ELEMENT: {
      const { prevValues, nextValues } = action.payload
      const elements = state.elements.data.map((element) =>
        element.id === prevValues.id ? { ...element, ...nextValues, draft: false } : element,
      )
      return {
        ...state,
        elements: {
          ...state.elements,
          data: elements,
        },
      }
    }

    case REMOVE_ELEMENT: {
      const { element } = action.payload
      const elements = state.elements.data.filter((item) => item.id !== element.id)
      return {
        ...state,
        elements: {
          ...state.elements,
          data: elements,
        },
      }
    }

    case TOGGLE_ELEMENT: {
      const { element, state: isOpen } = action.payload
      const elements = state.elements.data.map((item) =>
        item.id === element.id ? { ...item, isOpen } : item,
      )

      return {
        ...state,
        elements: {
          ...state.elements,
          data: elements,
        },
      }
    }

    case ADD_ELEMENT: {
      let elements = state.elements.data
      elements.push({ id: uuid(), draft: true, isOpen: true })
      return {
        ...state,
        elements: {
          ...state.elements,
          data: elements,
        },
      }
    }

    case SET_SAVING: {
      const { state: saving } = action.payload
      return {
        ...state,
        saving,
      }
    }

    default:
      return state
  }
}

// actions
export const reset = () => (dispatch) => {
  dispatch({
    type: RESET,
  })
}

export const startLoading = (key) => (dispatch) => {
  dispatch({
    type: START_LOADING,
    payload: key,
  })
}

export const fetchElements = (requestSlug) => (dispatch) => {
  dispatch(startLoading('elements'))

  APIClient.getCosts(requestSlug).then((response) => {
    dispatch(setElements(response.data))
  })
}

export const setElements = (data) => (dispatch) => {
  dispatch({
    type: SET,
    payload: data,
  })
}

export const updateElement = (request, prevValues, nextValues) => (dispatch) => {
  dispatch(toggleElement(prevValues, false))
  dispatch({
    type: UPDATE_ELEMENT,
    payload: {
      prevValues,
      nextValues,
    },
  })
}

export const fetchTypes =
  ({ type }) =>
  (dispatch) => {
    dispatch(startLoading('types'))

    APIClient.getCostTypes(type).then((response) => {
      dispatch(setTypes(response.data))
    })
  }

export const setTypes = (data) => (dispatch) => {
  dispatch({
    type: SET_TYPES,
    payload: data,
  })
}

export const removeElement = (element) => (dispatch) => {
  dispatch({
    type: REMOVE_ELEMENT,
    payload: {
      element,
    },
  })
}

export const toggleElement = (element, state, storage) => (dispatch) => {
  if (storage) {
    saveValueToStorage(element, 'isOpen', state)
  }

  dispatch({
    type: TOGGLE_ELEMENT,
    payload: {
      element,
      state,
    },
  })
}

export const addElement = () => (dispatch) => {
  dispatch({
    type: ADD_ELEMENT,
  })
}
export const setSaving = (state) => (dispatch) => {
  dispatch({
    type: SET_SAVING,
    payload: { state },
  })
}

export const saveCost = (request, values, element) => (dispatch) => {
  dispatch(setSaving(true))

  if (values.draft) {
    return dispatch(createCost(request, values, element))
  }

  return dispatch(updateCost(request, values, element))
}

export const createCost = (request, values, element) => (dispatch) => {
  return APIClient.createCost(request.slug, values)
    .then((response) => {
      dispatch(updateElement(request, element, { ...response.data, scrollToElement: true }))
      dispatch(setSaving(false))
      return response
    })
    .catch((e) => {
      dispatch(setSaving(false))
      return e
    })
}

export const updateCost = (request, values, element) => (dispatch) => {
  return APIClient.updateCost(request.slug, values.id, values)
    .then((response) => {
      dispatch(updateElement(request, element, response.data))
      dispatch(setSaving(false))
      return response
    })
    .catch((e) => {
      dispatch(setSaving(false))
      return e
    })
}

export const removeCost = (request, element) => (dispatch) => {
  dispatch(setSaving(true))

  if (element.draft) {
    dispatch(removeElement(element))
    dispatch(setSaving(false))
    return
  }

  return APIClient.removeCost(request.slug, element.id)
    .then(() => {
      dispatch(removeElement(element))
      dispatch(setSaving(false))
    })
    .catch((e) => {
      dispatch(setSaving(false))
    })
}

export const saveValueToStorage = (element, key, value) => {
  const storageKey = `${element.type}-${element.id}${element.virtual ? '-virtual' : ''}`

  const data = localStorage.get(storageKey) || {}
  data[key] = value

  localStorage.set(storageKey, data)
}

export const getValueFromStorage = (element, key) => {
  const storageKey = `${element.type}-${element.id}${element.virtual ? '-virtual' : ''}`
  const data = localStorage.get(storageKey) || {}

  if (isUndefined(data[key])) {
    return null
  }

  return data[key]
}

// selectors
export const getState = (state) => {
  return state.get(MOUNT_POINT)
}

export const getElements = (state) => {
  const elements = getState(state).elements

  return (elements && elements.data) || []
}

export const getTypes = (state) => {
  const types = getState(state).types
  const data = (types && types.data) || []

  if (isEmpty(data)) {
    return []
  }

  return data.map((type) => ({
    value: `root-${type.id}`,
    label: trans(type.short_name),
    options: type.types.map((type) => ({
      value: type.id,
      label: trans(type.short_name),
      slug: type.slug,
    })),
  }))
}

export const getIsLoading = (state) => {
  return (
    getState(state).elements.isLoading ||
    getState(state).currencies.isLoading ||
    getState(state).types.isLoading
  )
}

export const isElementOpen = (element) => {
  return getValueFromStorage(element, 'isOpen')
}

export const isSaving = (state) => {
  return getState(state).saving
}
