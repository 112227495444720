import { get } from 'lodash'
import { getOr } from '../../../utils/getOr'

export const SORT_BY_CHEAPEST_FIRST = 'cheapest-first'
export const SORT_BY_CHEAPEST_LAST = 'cheapest-last'
export const SORT_BY_STARS_ASCENDING = 'stars-ascending'
export const SORT_BY_STARS_DESCENDING = 'stars-descending'
export const SORT_BY_DISTANCE_ASCENDING = 'distance-ascending'

const cheapest = (offers, direction, withBreakfast) => {
  direction = direction === 'ascending'
  const amountField = withBreakfast ? 'lowestPriceWithBreakfast' : 'lowestPrice'

  return offers.sort((a, b) => {
    const aAmount = parseInt(get(a, amountField, 0).replace(/\D/g, ''))
    const bAmount = parseInt(get(b, amountField, 0).replace(/\D/g, ''))

    if (aAmount < bAmount) {
      return direction ? -1 : 1
    }
    if (aAmount > bAmount) {
      return direction ? 1 : -1
    }

    return 0
  })
}

const distance = (offers, direction) => {
  direction = direction === 'ascending'

  return offers.sort((a, b) => {
    const aDistance = parseFloat(get(a, 'attributes.distance', 0))
    const bDistance = parseFloat(get(b, 'attributes.distance', 0))

    if (aDistance < bDistance) {
      return direction ? -1 : 1
    }
    if (aDistance > bDistance) {
      return direction ? 1 : -1
    }
    return 0
  })
}

const stars = (offers, direction) => {
  direction = direction === 'ascending'

  return offers.sort((a, b) => {
    const aStars = parseInt(get(a, 'attributes.stars', 0))
    const bStars = parseInt(get(b, 'attributes.stars', 0))

    if (aStars < bStars) {
      return direction ? -1 : 1
    }
    if (aStars > bStars) {
      return direction ? 1 : -1
    }

    return 0
  })
}

const corporateRate = (offers, direction) => {
  direction = direction === 'ascending'

  return offers.sort((a, b) => {
    const aCorporateRate = getOr(a, 'options', []).some((option) => option.corporateRate)
    const bCorporateRate = getOr(b, 'options', []).some((option) => option.corporateRate)

    if (!aCorporateRate && bCorporateRate) {
      return direction ? -1 : 1
    }
    if (aCorporateRate && !bCorporateRate) {
      return direction ? 1 : -1
    }

    return 0
  })
}

export const sort = (sortBy, offers, withBreakfast) => {
  switch (sortBy) {
    case SORT_BY_CHEAPEST_FIRST:
      return cheapest(offers, 'ascending', withBreakfast)
    case SORT_BY_CHEAPEST_LAST:
      return cheapest(offers, 'descending', withBreakfast)
    case SORT_BY_STARS_ASCENDING:
      return stars(offers, 'ascending')
    case SORT_BY_STARS_DESCENDING:
      return stars(offers, 'descending')
    case SORT_BY_DISTANCE_ASCENDING:
      return distance(offers, 'ascending')
    default:
      return corporateRate(offers, 'descending')
  }
}
