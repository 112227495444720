import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import { Col, Row } from '../../../ui/Grid'
import { trans } from '../../../trans'
import { get } from 'lodash'
import ReservationStatus from '../ReservationStatus'
import OfferDialog from './OfferDialog'

class ReservationBox extends Component<any, any> {
  constructor(props) {
    super(props)
  }

  renderStatus = () => {
    const {
      hotelsBooking: {
        selectors: { selectedOption },
      },
    } = this.props
    return <ReservationStatus selectedOption={selectedOption} />
  }

  openOfferDialog = () => {
    const {
      hotelsBooking: {
        actions: { openOfferDialog },
      },
    } = this.props
    openOfferDialog()
  }

  render() {
    const {
      hotelsBooking: {
        selectors: { selectedOffer, selectedOption },
      },
    } = this.props

    const {
      attributes: { name },
    } = selectedOffer

    const address = get(selectedOffer, 'attributes.address')
    const amount = get(selectedOption, 'amount.formatted')
    const amountFee = get(selectedOption, 'amountPerNight.formatted')

    return (
      <Fragment>
        <Row>
          <Col>
            <div className='reservation-box' onClick={this.openOfferDialog}>
              <div className='reservation-box__content'>
                <div>
                  <span className='reservation-box__hotel-name'>{name}</span>
                  <a
                    className='reservation-box__link selected-hotel__address'
                    target='_blank'
                    href={`https://www.google.com/maps/search/?api=1&query=${address}`}
                  >
                    {trans('hotels-booking.show-on-the-map')}
                  </a>
                </div>

                <div className='reservation-box__status'>{this.renderStatus()}</div>

                {/*<span>{trans('hotels-booking.reservation-fee')} {amountFee}</span>*/}
              </div>
            </div>
          </Col>
        </Row>
        <OfferDialog hotelsBooking={this.props.hotelsBooking} />
      </Fragment>
    )
  }
}

ReservationBox.propTypes = {
  hotelsBooking: PropTypes.object.isRequired,
}

export default ReservationBox
export { ReservationBox }
