import PropTypes from 'prop-types'
import React from 'react'
import _ from 'lodash'
import { Radio } from '../../../ui'

export class RadioField extends React.Component<any, any> {
  handleOnChange(event) {
    const { onChange, value } = this.props

    if (_.isFunction(onChange)) {
      onChange(value)
    }
  }

  render() {
    const { name, label, value, checked, onBlur, disabled } = this.props

    return (
      <Radio
        name={name}
        label={label}
        onChange={this.handleOnChange.bind(this)}
        onBlur={onBlur}
        value={value}
        checked={checked}
        disabled={disabled}
      />
    )
  }
}

RadioField.propTypes = {
  name: PropTypes.string,
  label: PropTypes.node,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.bool, PropTypes.number]),
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  checked: PropTypes.bool,
}

RadioField.defaultProps = {}
