import React from 'react'
import Button from '../ui/ButtonComponent'
import Icon from '../ui/IconComponent'
import { trans } from '../../trans'
import { menuTypes } from './index'

export const TypesMenu = ({
  selected,
  onChange,
  onSelect,
  showChangeButton = true,
  disabled = false,
  menuTypesInjected = null,
}) => {
  let menu = menuTypesInjected
    ? menuTypesInjected.filter((item) => item.render)
    : menuTypes.filter((item) => item.render)

  if (selected) {
    menu = menuTypes.filter((item) => item.type === selected)
  }

  const menuRender = menu.map((item) => {
    return (
      <span key={item.type} className='timeline-icon' onClick={() => onSelect(item.type)}>
        <Icon type={item.icon} gradient='true' />
      </span>
    )
  })

  const iconSelectClassNames = [
    'icon-select',
    selected && 'icon-select--open',
    disabled ? 'timeline-change--disabled' : '',
  ]
    .filter((className) => className)
    .join(' ')

  return (
    <div className={iconSelectClassNames}>
      {menuRender}
      {selected && showChangeButton ? (
        <Button disabled={disabled} outline xxs onClick={!disabled ? onChange : () => {}}>
          {trans('global.change')}
        </Button>
      ) : null}
    </div>
  )
}
