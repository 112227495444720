import { useCallback, useEffect, useMemo, useState } from 'react'
import { GridApi, GridOptions, GridReadyEvent } from 'ag-grid-community'
import trans from '../../../trans'
import ButtonRenderer from '../../AgGrid/CellRenderer/ButtonRenderer'
import dateFormatter from '../../AgGrid/ValueFormatter/dateFormatter'
import { amountFormatter } from '../../AgGrid/ValueFormatter/amountFormatter'
import defaultFormatter from '../../AgGrid/ValueFormatter/defaultFormatter'
import LinkRenderer from '../../AgGrid/CellRenderer/LinkRenderer'
import { ITransactionSuggestion } from '../../../types/transaction-suggestion'
import IconRenderer from '../../AgGrid/CellRenderer/IconRenderer'

export function usePaymentAttachGridOptions() {
  const [api, setApi] = useState<GridApi>(null)
  const [gridOptions, setGridOptions] = useState<GridOptions<ITransactionSuggestion>>({
    domLayout: 'autoHeight',
    defaultColDef: {
      suppressSizeToFit: false,
      suppressMenu: true,
      suppressMovable: true,
    },
    onGridReady(event: GridReadyEvent<ITransactionSuggestion>) {
      setApi(event.api)
    },
    columnDefs: [
      {
        headerName: trans('my-card.payment_attach_th_invoice_date'),
        valueFormatter: dateFormatter,
        field: 'document.issue_date',
        minWidth: 140,
        maxWidth: 140,
      },
      {
        headerName: trans('my-card.payment_attach_th_invoice_number'),
        field: 'document.number',
        valueFormatter: defaultFormatter,
        cellRenderer: LinkRenderer,
        cellRendererParams: (params) => ({
          link: params.data.document?.metadata?.document_url,
        }),
        minWidth: 150,
        maxWidth: 150,
      },
      {
        headerName: trans('my-card.payment_attach_th_transaction_amount'),
        field: 'transaction.transaction_value',
        valueFormatter: amountFormatter('transaction.transaction_currency'),
        minWidth: 150,
        maxWidth: 150,
        type: 'numericColumn',
      },
      {
        headerName: trans('my-card.payment_attach_th_settlement_amount'),
        field: 'transaction.billing_value',
        valueFormatter: amountFormatter('transaction.billing_currency'),
        minWidth: 150,
        maxWidth: 150,
        type: 'numericColumn',
      },
      {
        headerName: trans('my-card.payment_attach_th_request_id'),
        field: 'document.metadata.request_number',
        valueFormatter: defaultFormatter,
        cellRenderer: LinkRenderer,
        cellRendererParams: (params) => ({
          link: params.data.document?.metadata?.request_url,
        }),
        minWidth: 150,
        maxWidth: 150,
      },
      {
        type: 'attach',
        minWidth: 140,
        maxWidth: 140,
        cellRenderer: ButtonRenderer,
        cellRendererParams: {
          label: trans('my-card.payments_attach_button'),
          primary: true,
        },
      },
    ],
  })

  const onResize = useMemo(() => {
    return () => {
      if (api) {
        api.sizeColumnsToFit()
      }
    }
  }, [api])

  useEffect(() => {
    onResize()

    window.addEventListener('resize', onResize)

    return () => {
      window.removeEventListener('resize', onResize)
    }
  }, [api])

  return gridOptions
}
