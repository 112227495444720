import React, { Component } from 'react'
import { trans } from '../../../trans'
import Dialog from '@material-ui/core/Dialog/index'
import Paper from '@material-ui/core/Paper/index'
import DialogContent from '@material-ui/core/DialogContent/index'
import Icon from '../../ui/IconComponent'
import { get } from 'lodash'
import moment from 'moment/moment'
import OffersList from './OffersList'
import Filters from '../Filters'
import {
  FILTER_RETURN_DEPARTURE_TIME,
  FILTER_TARGET_DEPARTURE_TIME,
} from '../../../store/app/flights-booking'
import { connect as connectFlights } from '../../../containers/FlightsBooking'
import Button from '../../ui/ButtonComponent'
import { FormField } from '../../ui/Form'
import { Field } from 'redux-form/immutable'

class OffersDialogComponent extends Component<any, any> {
  search = () => {
    const { flights } = this.props
    flights.actions.searchFilters()
  }

  renderTitle = () => {
    const { element } = this.props

    let from = get(element, 'flight_from.formatted_address', '')
    let to = get(element, 'flight_to.formatted_address', '')

    return trans('trains-booking.offers-list-title', { from, to })
  }

  renderDate = () => {
    const { element } = this.props

    const round = get(element, 'round_trip', false)
    const arrival_at = moment(get(element, 'arrival_at', moment()))
    const return_at = moment(get(element, 'return_at', moment()))

    if (!round) {
      return arrival_at.format('D MMM')
    }

    if (!return_at.isSame(arrival_at, 'day')) {
      return arrival_at.format('D MMM–') + return_at.format('D MMM')
    }

    return arrival_at.format('D MMM')
  }

  renderDetails = () => {
    const { element } = this.props

    const round = get(element, 'round_trip', false)
    const direct = get(element, 'direct_only', false)

    if (round && direct) {
      return trans('flights-booking.round-trip-direct')
    }

    if (round && !direct) {
      return trans('flights-booking.round-trip')
    }

    if (!round && direct) {
      return trans('flights-booking.one-way-trip-direct')
    }

    if (!round && !direct) {
      return trans('flights-booking.one-way-trip')
    }

    return null
  }

  handleChange = () => {
    const { flights } = this.props
    flights.actions.isChanged(true)
  }

  render() {
    const {
      element,
      flights: {
        actions: { afterCloseResultsList, closeResultsList },
        selectors: { isLoading, isFiltering, isResultsListOpen, query, filters },
      },
    } = this.props

    const isRoundTrip = get(element, 'round_trip', false)
    const title = this.renderTitle()
    const date = this.renderDate()
    const details = this.renderDetails()

    const isDisabled = isLoading || isFiltering
    const arrival_at = element.arrival_at
      ? moment(element.arrival_at, ['YYYY-MM-DD HH:mm:ss'], true)
      : moment()
    const return_at = element.return_at
      ? moment(element.return_at, ['YYYY-MM-DD HH:mm:ss'], true)
      : moment()

    const startDate = arrival_at.format('YYYY MM DD')
    const endDate = return_at.format('YYYY MM DD')

    return (
      <Dialog
        open={isResultsListOpen}
        onExited={afterCloseResultsList}
        disableBackdropClick
        aria-labelledby='alert-dialog-slide-title'
        aria-describedby='alert-dialog-slide-description'
        maxWidth={false}
        fullWidth={true}
        PaperProps={{
          className: 'trip-dialog',
          square: true,
        }}
        scroll='paper'
      >
        <DialogContent>
          <div className='trip-dialog__main plane-trip-dialog__main'>
            <div className='trip-dialog__header-line'>
              <div className='trip-dialog__header-title'>
                <h2 className='trip-dialog__trip-title h2'>{title}</h2>
                <span className='trip-dialog__trip-details'>
                  {date} · {details}
                </span>
              </div>

              <Icon type='close' className='trip-dialog__close' onClick={closeResultsList} />
            </div>

            <nav className='plane-trip__dialog-dashboard'>
              <div className='plane-trip__filter-block'>
                <div className='plane-trip__filter-wrapper'>
                  <div className='plane-trip__filter-inner-wrapper'>
                    <div className='plane-trip__filter plane-trip__filter-large'>
                      <span className='plane-trip__label'>
                        {trans('flights-booking.arrival-time-flight-over-there')}
                      </span>
                      <Filters.Time
                        filterName={FILTER_TARGET_DEPARTURE_TIME}
                        disabled={isDisabled}
                      />
                    </div>

                    {isRoundTrip && (
                      <div className='plane-trip__filter plane-trip__filter-large'>
                        <span className='plane-trip__label'>
                          {trans('flights-booking.arrival-time-flight-back')}
                        </span>
                        <Filters.Time
                          sameDay={startDate === endDate}
                          filterName={FILTER_RETURN_DEPARTURE_TIME}
                          disabled={isDisabled}
                        />
                      </div>
                    )}

                    <div className='plane-trip__filter'>
                      <span className='plane-trip__label'>&nbsp;</span>
                      <Field
                        name='direct_only'
                        type='checkbox'
                        component={FormField}
                        label={trans('request-plane-element.direct-only')}
                        immutable
                        inputOnly
                        onChange={this.handleChange}
                      />
                    </div>

                    <div className='plane-trip__filter'>
                      <span className='plane-trip__label'>&nbsp;</span>
                      <Field
                        name='restrict_to_fba'
                        type='checkbox'
                        component={FormField}
                        label={trans('request-plane-element.restrict-to-fba')}
                        immutable
                        inputOnly
                        onChange={this.handleChange}
                      />
                    </div>

                    <div className='plane-trip__filter plane-trip__filter-large'>
                      <Field
                        name='flight_class'
                        type='select'
                        component={FormField}
                        label={trans('request-plane-element.class')}
                        options={[
                          {
                            value: 'class_economy',
                            label: trans('request-plane-element.economy-class'),
                          },
                          {
                            value: 'class_economy_premium',
                            label: trans('request-plane-element.economy-premium-class'),
                          },
                          {
                            value: 'class_business',
                            label: trans('request-plane-element.class-business-class'),
                          },
                          {
                            value: 'class_first',
                            label: trans('request-plane-element.class-first-class'),
                          },
                        ]}
                        labeltop
                        onChange={this.handleChange}
                      />
                    </div>

                    <div className='plane-trip__filter plane-trip__filter-large'>
                      <Filters.Sort />
                    </div>

                    <div className='plane-trip__filter plane-trip__filter-search'>
                      <Button primary xxs onClick={this.search} disabled={isDisabled}>
                        {trans('global.search')}
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </nav>

            <OffersList />
          </div>
        </DialogContent>
      </Dialog>
    )
  }
}

const OffersDialog = connectFlights(OffersDialogComponent)

export default OffersDialog
export { OffersDialog }
