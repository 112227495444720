import PropTypes from 'prop-types'
import React from 'react'
import { get } from 'lodash'
import { ifDeepDiff } from '../../../utils/javascript'
import ReactDOM from 'react-dom'
import jQuery from 'jquery'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { getGroups } from '../../../store/app/document-expense-types'
import { trans } from '../../../trans'

class ExpenseTypesGroupsManager extends React.Component<any, any> {
  constructor(props) {
    super(props)

    this.state = {
      groups: this._prepareGroups(this.props.groups),
      activeGroup: null,
    }

    this.rowSize = Math.floor(100 / Number(this.props.groupWidth.match(/(\d+)%/)[1]))
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (ifDeepDiff(this.props, prevProps)) {
      this.setState({
        groups: this._prepareGroups(this.props.groups),
      })
    }
  }

  _calculateGroupSum(group) {
    const expenses = group.types

    if (!expenses) {
      return 0
    }

    return expenses.reduce((acc, current) => {
      return acc + Number(get(current, 'element.gross', 0))
    }, 0)
  }

  _prepareGroups(groups) {
    const { groupWidth } = this.props
    groups.map((group) => {
      group.groupWidth = groupWidth
      group.sum = this._calculateGroupSum(group)
      return group
    })

    return groups
  }

  _getGroups() {
    const { item } = this.props

    return item.documentElements.map((group) => {
      group.isActive = group.id === get(this.state.activeGroup, 'id', null)
      return group
    })
  }

  _getGroupContentRow(activeGroup) {
    const groupInSequence = this.state.groups.findIndex((group) => group.id === activeGroup.id)
    return Math.floor(groupInSequence / this.rowSize)
  }

  _insertGroupContentRow(row) {
    let insertAfter = this.rowSize - 1
    if (row > 0) {
      insertAfter = this.rowSize + row * this.rowSize - 1
    }

    if (insertAfter >= this.state.groups.length) {
      insertAfter = this.state.groups.length - 1
    }

    let groups = this.state.groups.map((group, index) => {
      group.hasContent = index === insertAfter && this.state.activeGroup !== null
      return group
    })

    this.setState({ groups })
  }

  _calculateArrowPosition(reference) {
    const expenseNode = ReactDOM.findDOMNode(reference)
    const left = jQuery(expenseNode).position().left
    const width = jQuery(expenseNode).outerWidth()

    return left + width / 2
  }

  onToggleGroup = (group, reference) => {
    let state
    const groupContentRow = this._getGroupContentRow(group)

    if (get(this.state.activeGroup, 'id', null) === group.id) {
      state = { activeGroup: null }
    } else {
      group.arrowPosition = this._calculateArrowPosition(reference)
      state = { activeGroup: group, groupContentRow }
    }

    this.setState(state, () => {
      this._insertGroupContentRow(groupContentRow)
    })
  }

  render() {
    const { children, item } = this.props

    const renderProps = {
      groups: this._getGroups(),
      activeGroup: this.state.activeGroup,
      suggestedElement: item.suggestedElementType,
      onToggleGroup: this.onToggleGroup,
    }

    return children(renderProps)
  }
}

ExpenseTypesGroupsManager.propTypes = {
  children: PropTypes.func.isRequired,
  item: PropTypes.object.isRequired,
  groupWidth: PropTypes.string.isRequired,
}

const mapStateToProps = (state, props) => ({
  groups: getGroups(state, props.item),
})

ExpenseTypesGroupsManager = connect(mapStateToProps, null)(ExpenseTypesGroupsManager)

export { ExpenseTypesGroupsManager }
export default ExpenseTypesGroupsManager
