import { trans } from '../../trans'
import React, { Fragment } from 'react'
import { connect } from '../../containers/FlightsBooking'
import { FormField } from '../ui/Form'
import { Field } from 'redux-form/immutable'

const AmountFields = connect((props) => {
  const { flights, plane, currencies } = props

  if (flights.selectors.isSearchEnabled) {
    return null
  }

  const getAmountSuggestion = () => {
    const formattedFrom = plane.virtual ? plane.flight_to : plane.flight_from.city
    const formattedTo = plane.virtual ? plane.flight_from : plane.flight_to.city

    if (!formattedFrom || !formattedTo) {
      return null
    }

    return plane.suggested_amount
  }

  return (
    <Fragment>
      <div className='plane-trip__dashboard-field'>
        <Field
          name='amount'
          type='amount'
          component={FormField}
          label={trans('request-plane-element.amount')}
          suggestedAmount={getAmountSuggestion()}
          suggestedAmountCurrency={plane.calculated_amount_currency}
          onBlur={(e) => {
            e.preventDefault()
          }}
          placeholder='0,00'
          labeltop
        />
      </div>

      <div className='plane-trip__dashboard-field'>
        <Field
          name='amount_currency'
          type='currency'
          component={FormField}
          currencies={currencies}
          label=' '
          labeltop
        />
      </div>
    </Fragment>
  )
})

export default AmountFields
export { AmountFields }
