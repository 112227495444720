import React from 'react'
import Icon from '../../../ui/IconComponent'
import { TimelineElement, TimeLineElementContent, TimeLineElementHeader } from '../../../Timeline'
import { TypesMenu } from '../../TypesMenu'
import { Col } from '../../../../ui'
import { keepInViewport } from '../KeepInViewport'
import { TooltipContent } from '../../../ui/Tooltip'
import { Booking } from '../../../TripRequestPage/Booking'
import { STATUS_DRAFT } from '../../../../constants/request'

const KeepingViewportHeader = keepInViewport()(TimeLineElementHeader)

abstract class OfferTimeline extends React.Component<
  any,
  {
    isOpen: boolean
    isTooltipOpen: boolean
  }
> {
  tooltipRef: React.RefObject<HTMLElement> = React.createRef()

  abstract icon(): string
  abstract renderLabel(): React.ReactElement
  abstract renderElement(): React.ReactElement
  abstract renderCalculatedAmount(): React.ReactElement

  constructor(props) {
    super(props)

    this.state = {
      isOpen: props.element.isOpen,
      isTooltipOpen: false,
    }

    this.changeIsOpen = this.changeIsOpen.bind(this)
    this.onSave = this.onSave.bind(this)
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside)
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside)
  }

  handleClickOutside = (event) => {
    if (this.state.isTooltipOpen && !this.tooltipRef.current.contains(event.target)) {
      this.changeIsTooltipOpen(false)
    }
  }

  changeIsOpen(isOpen) {
    this.setState({ isOpen, isTooltipOpen: false })
  }

  changeIsTooltipOpen(isTooltipOpen) {
    const { element } = this.props

    if (element.draft) {
      return
    }

    this.setState({ isTooltipOpen, isOpen: false })
  }

  renderEdit() {
    const { element } = this.props
    const { isOpen } = this.state

    if (isOpen) {
      return null
    }

    if (element.virtual) {
      return null
    }

    return (
      <span
        className='timeline-icon'
        onClick={() => {
          this.changeIsOpen(true)
        }}
      >
        <Icon type='edit' lg gradient />
      </span>
    )
  }

  onSave(...args) {
    const { onSave } = this.props

    return onSave(...args).then((res) => {
      this.changeIsOpen(false)

      return res
    })
  }

  renderBooking = (element) => {
    const { request } = this.props
    const readOnlyRequest = {
      ...request,
      abilities: {
        ...request.abilities,
        edit: false,
        view: true,
      },
    }

    return <Booking element={element} request={readOnlyRequest} />
  }

  renderContent() {
    const { onChangeType, element } = this.props

    const { isOpen } = this.state

    return (
      <TimeLineElementContent isOpened={isOpen}>
        <TypesMenu
          selected={element.type}
          onChange={() => onChangeType(element, null)}
          showChangeButton={element.draft}
        />

        <div className='tooltip-content'>{this.renderElement()}</div>
      </TimeLineElementContent>
    )
  }

  renderTrash = () => {
    const { element, onRemove } = this.props

    if (element.virtual) {
      return null
    }

    return (
      <span className='timeline-icon' onClick={() => onRemove(element)}>
        <Icon type='trash' lg gradient />
      </span>
    )
  }

  render() {
    const { element, request } = this.props
    const { isOpen } = this.state

    return (
      <TimelineElement className={element.className} type={element.type} activeSimilar>
        <KeepingViewportHeader
          icon={this.icon()}
          gradient='true'
          isOpen={isOpen}
          onIconClick={() => this.changeIsTooltipOpen(true)}
        >
          <Col xs={9} className='timeline__header--title'>
            {this.renderLabel()}
          </Col>
          <Col style={{ paddingRight: '0' }} className='is-allign-end' xs={2}>
            {this.renderCalculatedAmount()}
          </Col>
          {request['abilities']['edit'] && (
            <Col xs={1} style={{ paddingLeft: '0' }} className='is-allign-end'>
              {this.renderEdit()}
              {this.renderTrash()}
            </Col>
          )}

          {request.status === STATUS_DRAFT && this.state.isTooltipOpen && (
            <div className={'timeline__header-tooltip'} ref={this.tooltipRef}>
              <TooltipContent arrowPosition={25}>{this.renderBooking(element)}</TooltipContent>
            </div>
          )}
        </KeepingViewportHeader>
        {isOpen && this.renderContent()}
      </TimelineElement>
    )
  }
}

export { OfferTimeline }
export default OfferTimeline
