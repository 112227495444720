import React from 'react'
import { AccordionPanel } from '../../ui/AccordionPanel/index'
import { Label, TYPE_WARNING } from '../../ui/Label/index'
import { Col, Row } from '../../../ui/Grid/index'
import { SectionHeader } from '../../ui/SectionHeader/index'
import { Section } from '../../ui/Section/index'
import { DashboardRequestsContent } from '../DashboardRequestsContent'
import trans from '../../../trans'
import { RequestSummary } from '../../RequestSummary'
import { UserInfo } from '../DashboardRequestsToAccept/UserInfo'
import { ProjectInfo } from '../DashboardRequestsToAccept/ProjectInfo'
import { RequestActions } from './RequestActions'
import { LoadingOverlay } from '../../ui/LoadingOverlay'
import { getRouteByName } from '../../../routes'
import { RouteManager } from '../../../containers/RouteManager'
import TruncateBreakable from '../../TruncateBreakable/TruncateBreakable'
import { Loader } from '../../ui/LoadingOverlay/Loader'
import DashboardSettlementsToAcceptManager from '../../../containers/DashboardSettlementsToAcceptManager/DashboardSettlementsToAcceptManager'

const DashboardSettlementsToAccept = (props) => {
  if (!props.counter) {
    return null
  }

  return (
    <div className='dashboard-requests-to-accept'>
      <AccordionPanel
        bar={
          <h1>
            <Label level={TYPE_WARNING}>{props.counter}</Label>
            {trans('dashboard-page.settlements-to-accept')}
          </h1>
        }
        defaultOpened={false}
        name='settlements-to-accept'
        expandOnBarClick
        barTheme='secondary'
        noShadow
      >
        {({ isOpen }) => {
          return (
            <DashboardSettlementsToAcceptManager
              user={props.user}
              resetOnMount={true}
              fetchOnMount={true}
              destroyOnUnmount={true}
            >
              {({ items, isFetching, isModuleActive, accept, reject, actionInProgress }) => {
                return isOpen === true && items.length > 0 ? (
                  <DashboardRequestsContent items={items} isLoading={isFetching}>
                    {items.map((request) => {
                      return (
                        <Section noBorder key={`request-${request['slug']}`}>
                          <Row className='dashboard-requests-to-accept__row' key={request['slug']}>
                            <Col xs={12} md={6} className='dashboard-requests-to-accept__summary'>
                              <SectionHeader
                                caption={<TruncateBreakable text={request.name} lines={2} />}
                                className='dashboard-requests-to-accept__header'
                              />

                              <RouteManager>
                                {({ push }) => (
                                  <RequestSummary
                                    push={push}
                                    getRouteByName={getRouteByName}
                                    request={request}
                                    className='with-warning'
                                    summary={request['basicSummary']}
                                    grid={{
                                      contentCell: 3,
                                      requestedCell: 3,
                                      iconsCell: 3,
                                      settledCell: 3,
                                    }}
                                    showLessIcons
                                  />
                                )}
                              </RouteManager>
                            </Col>
                            <Col xs={12} md={6} className='dashboard-requests-to-accept__right-col'>
                              <UserInfo user={request['user']} />
                              {request.project && (
                                <ProjectInfo
                                  project={request['project']}
                                  purpose={request['purpose']}
                                  mpk={request['mpk']}
                                  isModuleActive={isModuleActive}
                                />
                              )}

                              <RequestActions
                                request={request}
                                acceptRequest={accept}
                                rejectRequest={reject}
                                getRouteByName={getRouteByName}
                              />
                            </Col>
                            <div className='dashboard-requests-to-accept__hr' />
                          </Row>

                          {actionInProgress(request['slug']) && <LoadingOverlay />}
                        </Section>
                      )
                    })}
                  </DashboardRequestsContent>
                ) : (
                  <div className='has-loader pt-12'>
                    <Loader />
                  </div>
                )
              }}
            </DashboardSettlementsToAcceptManager>
          )
        }}
      </AccordionPanel>
    </div>
  )
}

export default DashboardSettlementsToAccept
