export const TRAVEL_COMPANY_CAR_TRIP = 'company_car_trip'
export const TRAVEL_PLANE_TRIP = 'plane_trip'
export const TRAVEL_PRIVATE_CAR_TRIP = 'private_car_trip'
export const TRAVEL_RENTED_CAR_TRIP = 'rented_car_trip'
export const TRAVEL_REPLACEMENT_CAR_TRIP = 'replacement_car_trip'
export const TRAVEL_PASSENGER_CAR_TRIP = 'passenger_car_trip'
export const TRAVEL_TRAIN_TRIP = 'train_trip'
export const TRAVEL_ACCOMODATION = 'accomodation'
export const TRAVEL_PRIVATE_ACCOMODATION = 'private_accomodation'
export const TRAVEL_ACCOMMODATION_PROVIDED = 'provided_accommodation'
export const TRAVEL_ACCOMODATION_SUGGESTION = 'accommodation_suggestion'
export const TRAVEL_TARGET_POINT = 'target_point'
export const TRAVEL_EMPTY = 'empty'
export const TRAVEL_FERRY = 'ferry_boat_trip'
export const TRAVEL_BUS_TRIP = 'bus_trip'

export const TRAVEL_TYPES = [
  TRAVEL_COMPANY_CAR_TRIP,
  TRAVEL_PLANE_TRIP,
  TRAVEL_PRIVATE_CAR_TRIP,
  TRAVEL_RENTED_CAR_TRIP,
  TRAVEL_TRAIN_TRIP,
  TRAVEL_ACCOMODATION_SUGGESTION,
  TRAVEL_PRIVATE_ACCOMODATION,
  TRAVEL_ACCOMMODATION_PROVIDED,
  TRAVEL_TARGET_POINT,
  TRAVEL_EMPTY,
  TRAVEL_FERRY,
  TRAVEL_BUS_TRIP,
  TRAVEL_REPLACEMENT_CAR_TRIP,
  TRAVEL_PASSENGER_CAR_TRIP,
]
