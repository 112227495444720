import trans from '../../trans'
import { AsyncSelectField } from '../../components/ui/Form/AsyncSelectField'
import React, { useCallback, useState } from 'react'
import { SelectLoadingOptions, SelectOption } from '../../types/form'
import UserSelectOption from './UserFieldOption'
import { components } from '../../utils/react-select'

interface UserFieldProps {
  value: any
  onChange: (user: any) => void
  loadItems: (search: string, prevOptions: SelectOption[]) => Promise<SelectLoadingOptions>
  isMulti?: boolean
  clearable?: boolean
  returnFullItemValueOnChange?: boolean
}

export default function UserField({ value, onChange, loadItems, ...rest }: UserFieldProps) {
  const [selected, setSelected] = useState(value)
  const onChangeCallback = useCallback(
    (user) => {
      setSelected(user)
      onChange(user)
    },
    [onChange],
  )

  return (
    <AsyncSelectField
      loadOptions={loadItems}
      value={selected}
      returnFullItemValueOnChange={true}
      onChange={onChangeCallback}
      placeholder={trans('global.filter-placeholder-everyone')}
      noOptionsMessage={() => trans('user.no-results-for-search-message')}
      loadingMessage={() => trans('user.searching-message')}
      components={{
        ...components,
        Option: UserSelectOption,
      }}
      delay={500}
      {...rest}
    />
  )
}
