// initial state
const getInitialState = () => {
  return {
    rules: {
      isLoaded: false,
      isLoading: false,
      data: [],
    },
  }
}

// constants
export const MOUNT_POINT = 'model-rules'
export const SET_RULES = MOUNT_POINT + '::set-rules'
export const RESET = MOUNT_POINT + '::reset'

// reducer
export const reducer = (state = getInitialState(), { type, payload }) => {
  switch (type) {
    case RESET:
      return getInitialState()
    case SET_RULES: {
      const { rules } = payload
      return {
        ...state,
        rules: {
          data: rules,
          isLoading: false,
          isLoaded: true,
        },
      }
    }
    default:
      return state
  }
}

// actions
export const reset = () => (dispatch) => {
  dispatch({
    type: RESET,
  })
}

export const startLoading = (key) => (dispatch) => {
  dispatch({
    type: START_LOADING,
    payload: key,
  })
}

export const setRules = (rules) => (dispatch) => {
  dispatch({
    type: SET_RULES,
    payload: { rules },
  })
}

// selectors
export const getState = (state) => state.get(MOUNT_POINT)
export const getRules = (state) => getState(state).rules.data
export const getIsLoading = (state) => getState(state).rules.isLoading
