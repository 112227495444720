import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { get } from 'lodash'
import APIClient from '../../../services/APIClient'
import uuid from '../../../utils/uuid'
import pdfIcon from '../../../../img/pdf.svg'

export const MODULE_MOUNT_LIST = 'documents'

const RESET = MODULE_MOUNT_LIST + '::reset'
const SET = MODULE_MOUNT_LIST + '::set'
const REMOVE = MODULE_MOUNT_LIST + '::remove'
const PUSH = MODULE_MOUNT_LIST + '::push'
const UPDATE = MODULE_MOUNT_LIST + '::update'
const DESTROY = MODULE_MOUNT_LIST + '::destroy'

// actions Creators
const reset = (listName) => (dispatch) => {
  dispatch({
    type: RESET,
    payload: {
      listName,
    },
  })
}

const destroy = (listName) => (dispatch) => {
  dispatch({
    type: DESTROY,
    payload: {
      listName,
    },
  })
}

const set = (listName, documents) => (dispatch) => {
  dispatch({
    type: SET,
    payload: {
      listName,
      documents,
    },
  })
}

const remove = (listName, document) => (dispatch) => {
  dispatch({
    type: REMOVE,
    payload: {
      listName,
      document,
    },
  })
}

const push = (listName, document) => (dispatch) => {
  dispatch({
    type: PUSH,
    payload: {
      listName,
      document,
    },
  })
}

const update = (listName, id, body) => (dispatch) => {
  dispatch({
    type: UPDATE,
    payload: {
      listName,
      id,
      body,
    },
  })
}

const getInitialState = () => {
  return {}
}

//actions
const resetAction = (state, payload) => {
  return {
    ...state,
    [payload.listName]: {
      documents: [],
    },
  }
}

const destroyAction = (state, payload) => {
  let newState = state
  delete newState[payload.listName]
  return newState
}

const setAction = (state, payload) => {
  return {
    ...state,
    [payload.listName]: {
      documents: payload.documents,
    },
  }
}

const removeAction = (state, payload) => {
  const documents = state[payload.listName].documents.filter((d) => d.id !== payload.document.id)
  return {
    ...state,
    [payload.listName]: {
      documents,
    },
  }
}

const pushAction = (state, payload) => {
  const documents = [...state[payload.listName].documents]
  documents.push(payload.document)

  return {
    ...state,
    [payload.listName]: {
      ...[payload.listName],
      documents,
    },
  }
}

const updateAction = (state, payload) => {
  let documents = [...state[payload.listName].documents]
  const index = documents.findIndex((d) => d.id === payload.id)
  documents[index] = payload.body

  return {
    ...state,
    [payload.listName]: {
      ...[payload.listName],
      documents,
    },
  }
}

const actions = {
  [RESET]: resetAction,
  [SET]: setAction,
  [REMOVE]: removeAction,
  [PUSH]: pushAction,
  [UPDATE]: updateAction,
  [DESTROY]: destroyAction,
}

//selectors
export const getDocuments = (listName) => (state) => {
  return get(state.get(MODULE_MOUNT_LIST), `${listName}.documents`, [])
}

export const reducer = (state = getInitialState(), action) => {
  if (actions.hasOwnProperty(action.type)) {
    return actions[action.type](state, action.payload)
  }
  return state
}

const documents =
  (listName = null) =>
  (Component) => {
    class DocumentsList extends React.Component<any, any> {
      componentDidMount() {
        const { reset, listName, documents, set } = this.props
        reset(listName)
        set(listName, documents)
      }

      componentDidUpdate(prevProps) {
        const { reset, listName, documents, set } = this.props

        if (documents.length !== prevProps.documents.length) {
          reset(listName)
          set(listName, documents)
        }
      }

      componentWillUnmount() {
        const { destroy, listName } = this.props
        destroy(listName)
      }

      _getPreviewUrl(document) {
        let preview = get(document, 'preview', null)

        if (document.type.indexOf('pdf') !== -1) {
          return pdfIcon
        }

        if (preview) {
          return preview
        }

        return null
      }

      upload = (document, promise) => {
        const { listName, push, update, remove } = this.props

        document.id = uuid()
        document.loader = true
        document.preview = this._getPreviewUrl(document)
        document.abilities = {
          delete: true,
        }

        push(listName, document)
        promise()
          .then((response) => {
            update(listName, document.id, response.data)
          })
          .catch((response) => {
            const errors = get(response, 'alerts.0.errors.file', [])
            update(listName, document.id, { ...document, failure: true, loader: false, errors })
          })
      }

      delete = (document) => {
        const { remove, listName } = this.props

        return new Promise((resolve, reject) => {
          if (document.failure) {
            remove(listName, document)
            resolve()
            return
          }

          APIClient.deleteFile(document.id)
            .then((response) => {
              remove(listName, document)
              resolve(response)
            })
            .catch((error) => {
              reject(error)
            })
        })
      }

      render() {
        let props = { ...this.props, upload: this.upload, delete: this.delete }

        if (listName) {
          props = { ...props, listName }
        }

        return <Component<any, any> {...props} />
      }
    }

    DocumentsList.propTypes = {
      documents: PropTypes.array.isRequired,
    }

    const mapStateToProps = (state, props) => ({
      documentsList: getDocuments(props.listName)(state),
    })

    const mapDispatchToProps = (dispatch) => {
      return bindActionCreators(
        {
          reset,
          set,
          remove,
          push,
          update,
          destroy,
        },
        dispatch,
      )
    }

    return connect(mapStateToProps, mapDispatchToProps)(DocumentsList)
  }

export { documents }
export default documents
