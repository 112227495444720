import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import { provide as nonDelegationProvider } from '../../containers/NonDelegation'

const NonDelegationWrapperComponent = (props) => props.children

const NonDelegationWrapper = nonDelegationProvider(NonDelegationWrapperComponent)

NonDelegationWrapper.propTypes = {
  request: PropTypes.object.isRequired,
}

export default NonDelegationWrapper
export { NonDelegationWrapper }
