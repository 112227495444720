import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import { get } from 'lodash'

import { trans } from '../../trans'

import Facility from './Facility'

const FACILITIES_TO_ICONS = {
  wifi: 'wifi',
  parking: 'parking',
  ac: 'air',
  tv: 'tv',
  'conference-center': 'conf',
  gym: 'gym',
  spa: 'spa',
  restaurant: 'restaurant2',
}

class Facilities extends Component<any, any> {
  render() {
    const { facilities } = this.props

    return (
      <Fragment>
        {facilities.map((facility) => {
          const icon = get(FACILITIES_TO_ICONS, facility, null)
          if (icon) {
            return <Facility key={icon} icon={icon} title={trans(`hotels-booking.${facility}`)} />
          }

          return null
        })}
      </Fragment>
    )
  }
}

Facilities.propTypes = {
  facilities: PropTypes.array.isRequired,
}

export default Facilities
export { Facilities }
