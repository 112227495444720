import React from 'react'
import { SEARCH_STATUS_PENDING, STEP_MAP } from '../index'
import {
  getAllOffersNumber,
  getAllOptionsNumber,
  getFilter,
  getFilteredOptions,
  getProcessedOffers,
  getRoomAllocations,
  getSelectedOffer,
  getSelectedOption,
  getSortBy,
  getTotalPaxes,
  isOptionSelected,
  isReservationSuccess,
  isReservationInCancellation,
  selector,
  getFacility,
  getFacilities,
  getFilters,
  isReservationCancellationFailed,
} from '../selectors'
import { bindActionCreators } from 'redux'
import {
  backToListing,
  backToMap,
  backToSearching,
  bookOffer,
  changeExtraServicesText,
  closeOfferDialog,
  closeResultsList,
  fetchSelectedForElement,
  isChanged,
  openOfferDialog,
  reset,
  resignFromSearch,
  search,
  searchAnotherOne,
  searchFilters,
  selectOffer,
  selectOfferOption,
  set,
  setAgreement,
  setFacility,
  setFilter,
  setFilters,
  setInitialFacilities,
  setInitialized,
  setQuery,
  setSearch,
  setSortBy,
  setStepListing,
  subscribeSearchResult,
  toggleExtraServices,
  toggleParking,
  toggleSearch,
  unsubscribeReservationStatus,
  unsubscribeSearchResult,
  valuateOffer,
} from '../creators'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { isHotelSearchEnabled } from '../../trip-request'

const hotelsBooking =
  (resetOnMount = false) =>
  (Component) => {
    class HotelsBooking extends React.Component<any, any> {
      componentDidMount() {
        const { hotelsBooking } = this.props

        if (resetOnMount) {
          hotelsBooking.actions.reset()

          if (hotelsBooking.element.draft) {
            hotelsBooking.actions.set({
              offers: hotelsBooking.init.offers,
              uuid: hotelsBooking.init.uuid,
              filters: hotelsBooking.init.filters,
              query: hotelsBooking.init.query,
              enabled: hotelsBooking.selectors.isSearchFeatureEnabled,
            })
          } else {
            hotelsBooking.actions.setFilters(hotelsBooking.init.filters)
            hotelsBooking.actions.setQuery(hotelsBooking.init.query)

            const element = hotelsBooking.element

            if (element.searcher_disabled || !hotelsBooking.selectors.isSearchFeatureEnabled) {
              hotelsBooking.actions.toggleSearch()
              hotelsBooking.actions.setInitialized(true)
            } else {
              hotelsBooking.actions
                .fetchSelectedForElement(hotelsBooking.request, element)
                .then(() => {
                  hotelsBooking.actions.setInitialized(true)
                })
            }
          }
        }
      }

      render() {
        return <Component<any, any> {...this.props} />
      }
    }

    const mapStateToProps = (state, props) => {
      const {
        hotelsBooking: {
          init: { name },
        },
      } = props

      const get = selector(name)(state)

      return {
        step: get('step', STEP_MAP),
        offers: getProcessedOffers(name)(state),
        allOffersNumber: getAllOffersNumber(name)(state),
        allOptionsNumber: getAllOptionsNumber(name)(state),
        options: getFilteredOptions(name)(state),
        status: get('status', SEARCH_STATUS_PENDING),
        selectedOffer: getSelectedOffer(name)(state),
        selectedOption: getSelectedOption(name)(state),
        uuid: get('uuid'),
        initialized: get('initialized'),
        getFilter: getFilter(name)(state),
        filters: getFilters(name)(state),
        facilities: getFacilities(name)(state),
        query: get('query', {}),
        isOptionSelected: isOptionSelected(name)(state),
        isValuating: get('valuate'),
        isSearchEnabled: get('enabled'),
        isSearching: get('searching'),
        isFiltering: get('isFiltering'),
        isFacilityChanged: get('isFacilityChanged'),
        isChanged: get('isChanged'),
        statusAfterFiltering: get('statusAfterFiltering'),
        hasOffers: get('hasOffers'),
        isResultsListOpen: get('isResultsListOpen'),
        isOfferDialogOpen: get('isOfferDialogOpen'),
        sortBy: getSortBy(name)(state),
        lastComputed: get('lastComputed'),
        hasAgreement: get('hasAgreement'),
        isReservationSuccess: isReservationSuccess(name)(state),
        isReservationInCancellation: isReservationInCancellation(name)(state),
        isReservationCancellationFailed: isReservationCancellationFailed(name)(state),
        roomAllocations: getRoomAllocations(name)(state),
        totalPaxes: getTotalPaxes(name)(state),
        isSearchFeatureEnabled: isHotelSearchEnabled(state),
      }
    }

    const mapDispatchToProps = (dispatch, props) => {
      const {
        hotelsBooking: {
          init: { name },
          request,
          element,
        },
      } = props
      return bindActionCreators(
        {
          reset: reset(name),
          set: set(name),
          search: search(name),
          backToMap: backToMap(name),
          backToListing: backToListing(name),
          setStepListing: setStepListing(name),
          selectOffer: selectOffer(name),
          selectOfferOption: selectOfferOption(name),
          toggleParking: toggleParking(name),
          toggleExtraServices: toggleExtraServices(name),
          changeExtraServicesText: changeExtraServicesText(name),
          fetchSelectedForElement: fetchSelectedForElement(name),
          setFilter: setFilter(name),
          setFilters: setFilters(name),
          setFacility: setFacility(name),
          setInitialFacilities: setInitialFacilities(name),
          getFacility: getFacility(name),
          setQuery: setQuery(name),
          searchAnotherOne: searchAnotherOne(name),
          setInitialized: setInitialized(name),
          bookOffer: bookOffer(name)(request, element),
          valuateOffer: valuateOffer(name)(request),
          toggleSearch: toggleSearch(name),
          closeResultsList: closeResultsList(name),
          openOfferDialog: openOfferDialog(name),
          closeOfferDialog: closeOfferDialog(name),
          resignFromSearch: resignFromSearch(name)(request, element),
          backToSearching: backToSearching(name)(request)(element),
          setSortBy: setSortBy(name),
          setAgreement: setAgreement(name),
          setSearch: setSearch(name),
          subscribeSearchResult: subscribeSearchResult(name),
          unsubscribeSearchResult: unsubscribeSearchResult(name),
          unsubscribeReservationStatus: unsubscribeReservationStatus(name),
          isChanged: isChanged(name),
          searchFilters: searchFilters(name),
        },
        dispatch,
      )
    }

    const mergedProps = (selectors, actions, own) => {
      return {
        ...own,
        hotelsBooking: {
          selectors,
          actions,
          ...own.hotelsBooking,
        },
      }
    }

    HotelsBooking.propTypes = {
      hotelsBooking: PropTypes.shape({
        request: PropTypes.object,
        element: PropTypes.object,
        init: PropTypes.shape({
          name: PropTypes.string.isRequired,
          offers: PropTypes.array,
        }).isRequired,
      }),
    }

    return connect(mapStateToProps, mapDispatchToProps, mergedProps)(HotelsBooking)
  }

export { hotelsBooking }
export default hotelsBooking
