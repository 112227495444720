import PropTypes from 'prop-types'
import React from 'react'
import { additionalTypes, timelineTypes } from '../../components/TripTimeline'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { changeElementType, deleteElement } from '../../store/app/trip-timeline'
import { saveElement } from '../../store/app/trip-timeline'
import { getSearchEnabledByType } from '../../store/app/trip-request'
import { getCurrency } from '../../store/app/instance'

class ElementManager extends React.Component<any, any> {
  _getTypeOfComponent() {
    const { element } = this.props

    if (element.acceptance_source === 'primary') {
      return timelineTypes[element.type]
    }

    return additionalTypes[element.type]
  }

  onSaveHandler = (...props) => {
    const { saveElement } = this.props
    return saveElement(...props)
  }

  onRemoveHandler = (element) => {
    const { deleteElement, request } = this.props
    return deleteElement(request, element)
  }

  onChangeTypeHandler = (element, type) => {
    const { changeElementType, getSearchEnabledByType } = this.props

    return changeElementType(
      {
        ...element,
        searcher_disabled: !getSearchEnabledByType(type),
      },
      type,
    )
  }

  render() {
    const { children, instanceCurrency } = this.props
    const Component = this._getTypeOfComponent()

    const renderProps = {
      Component,
      instanceCurrency,
      onSave: this.onSaveHandler,
      onRemove: this.onRemoveHandler,
      onChangeType: this.onChangeTypeHandler,
    }

    return children(renderProps)
  }
}

ElementManager.propTypes = {
  request: PropTypes.object.isRequired,
  element: PropTypes.object.isRequired,
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      changeElementType,
      deleteElement,
      saveElement,
    },
    dispatch,
  )
}

const mapStateToProps = (state) => ({
  instanceCurrency: getCurrency(state),
  getSearchEnabledByType: getSearchEnabledByType(state),
})

ElementManager = connect(mapStateToProps, mapDispatchToProps)(ElementManager)

export default ElementManager
export { ElementManager }
