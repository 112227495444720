import React, { Component } from 'react'
import Dialog from '@material-ui/core/Dialog'
import Paper from '@material-ui/core/Paper'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import { Transition } from 'react-transition-group'
import trans from '../../trans'
import Icon from '../ui/IconComponent'
import Button from '../ui/ButtonComponent'
import PropTypes from 'prop-types'

class ReservationCancelConfirmationDialog extends Component<any, any> {
  render() {
    return (
      <Dialog
        open={this.props.open}
        TransitionComponent={Transition}
        onClose={() => this.props.onClose(false)}
        aria-labelledby='alert-dialog-slide-title'
        aria-describedby='alert-dialog-slide-description'
        className='last-requests__dialog'
      >
        <Paper square>
          <DialogTitle id='alert-dialog-slide-title'>
            {trans('document.reservation-changes-confirmation-title')}
          </DialogTitle>

          <DialogContent className='last-requests__dialog-content'>
            {trans('document.reservation-changes-confirmation-message')}
          </DialogContent>

          <DialogActions className='accommodation-confirm__dialog-actions'>
            <Icon
              className='last-requests__dialog-close'
              type='close'
              onClick={() => this.props.onClose(false)}
            />
            <Button danger xxs onClick={() => this.props.onClose(true)} color='primary'>
              {trans('document.reservation-changes-confirmation-yes')}
            </Button>
            <Button outline xxs onClick={() => this.props.onClose(false)} color='primary'>
              {trans('document.reservation-changes-confirmation-no')}
            </Button>
          </DialogActions>
        </Paper>
      </Dialog>
    )
  }
}

ReservationCancelConfirmationDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
}

export default ReservationCancelConfirmationDialog
