import React, { useEffect } from 'react'
import { Helmet } from 'react-helmet'
import { config } from '../../config'
import { DashboardRequestsToAccept } from './DashboardRequestsToAccept'
import { DashboardCurrentTrips } from './DashboardCurrentTrips'
import { DashboardWaitingToSettlement } from './DashboardWaitingToSettlement'
import { ClickToAction } from './ClickToAction'
import { connect, useDispatch, useSelector } from 'react-redux'

import {
  fetch,
  fetch as fetchCounter,
  getCounter,
  getIsLoaded as getIsCounterLoaded,
  isAnyItemInLoadedCounter,
} from '../../store/app/dashboard-counter'
import DashboardSettlementsToAccept from './SettlementsToAccept'
import { userProfile } from '../../store/app/user-profile/providers/userProfile'
import { bindActionCreators, compose } from 'redux'
import { Loader } from '../ui/LoadingOverlay/Loader'
import { Redirect } from 'react-router-dom'
import trans from '../../trans'
import { getCurrentAssistant } from '../../store/app/user-profile'


class DashboardPage extends React.Component<any, any> {
  componentDidMount() {
    this.props.fetchCounter()
  }

  renderHelloMessage() {
    const {
      userProfile: {
        selectors: { data: user },
      },
      currentAssistant,
    } = this.props

    const username = currentAssistant ? currentAssistant.first_name : user.first_name

    let message = trans('dashboard-page.hello-message', {
      username,
    })

    if (!this.props.anyItems) {
      message = trans('dashboard-page.short-hello-message', {
        username,
      })
    }

    return <h1 className='hello-message'>{message}</h1>
  }

  render() {
    const {
      userProfile: {
        selectors: { data: user },
      },
      currentAssistant,
    } = this.props

    const isLoaded = this.props.isCounterLoaded

    if (user.abilities.isAgent) {
      return <Redirect to={'requests/agent'} />
    }

    return (
      <div>
        <div>
          <Helmet title={trans(config.titles.dashboard)} />

          {isLoaded && this.renderHelloMessage()}

          {!isLoaded && <Loader />}

          {!this.props.isAnyItemInLoadedCounter && isLoaded && (
            <ClickToAction abilities={user.abilities} />
          )}

          <DashboardCurrentTrips counter={this.props.counter.currentTrips} />
          <DashboardWaitingToSettlement counter={this.props.counter.waitingToSettlement} />
          <DashboardRequestsToAccept counter={this.props.counter.requestToAccept} />
          <DashboardSettlementsToAccept
            user={user}
            counter={this.props.counter.settlementToAccept}
          />
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  counter: getCounter(state),
  isCounterLoaded: getIsCounterLoaded(state),
  isAnyItemInLoadedCounter: isAnyItemInLoadedCounter(state),
  currentAssistant: getCurrentAssistant(state),
})

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      fetchCounter,
    },
    dispatch,
  )
}

const withProfile = userProfile(false, false)

DashboardPage = compose(connect(mapStateToProps, mapDispatchToProps), withProfile)(DashboardPage)

export { DashboardPage }
export default DashboardPage
