import { ASYNC_SELECT_DEFAULT_LIMIT } from '../../../components/ui/Form/AsyncSelectField'
import APIClient from '../../../services/APIClient'
import { trans } from '../../../trans'

export const getLabelForProvider = (provider) =>
  (provider.erp_id !== null ? provider.erp_id + ' ' : '') +
  provider.name +
  (provider.registry_number !== null ? ' (' + provider.registry_number + ')' : '')

export const loadOptionsForProviders = async (search, prevOptions, company_id = null) => {
  const hasPlaceholder = prevOptions.some(
    (item) => item.label === trans('global.filter-placeholder-everyone'),
  )
  const optionsCount = hasPlaceholder ? prevOptions.length - 1 : prevOptions.length
  const response = await APIClient.getProviders({
    phrase: search,
    company_id: company_id,
    limit: ASYNC_SELECT_DEFAULT_LIMIT,
    offset: optionsCount,
  })
  let data = response.data.map((provider) => {
    return {
      label: getLabelForProvider(provider),
      value: provider,
      data: provider,
    }
  })

  if (!hasPlaceholder) {
    data = [
      {
        label: trans('global.filter-placeholder-everyone'),
        value: null,
        onSelectResetsInput: true,
      },
      ...data,
    ]
  }

  return {
    options: data,
    hasMore: response.pagination.total > response.pagination.offset + response.data.length,
  }
}
