import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { getCurrency as getInstanceCurrency } from '../../store/app/instance'
import APIClient from '../../services/APIClient'
import { documents } from '../../store/app/document-list'
import { getAdditionalElements } from '../../store/app/trip-timeline'

class RequestUnassignedDocumentsManagerBase extends React.Component<any, any> {
  uploadDocument = (request, files) => {
    const { upload } = this.props
    for (let file of files) {
      upload(file, () => {
        return APIClient.uploadElementDocument(
          request['slug'],
          request['unrequestedElement']['type'],
          request['unrequestedElement']['id'],
          file,
          false,
          'accounting',
        )
      })
    }
  }

  getDocuments() {
    const { documentsList, elements } = this.props
    const getDocuments = (element) => element.documents
    const mergeArray = (a, b) => [...a, ...b]

    return [...documentsList, ...elements.map(getDocuments).reduce(mergeArray, [])]
  }

  hasForm() {
    const { elements, request } = this.props

    if (!request.abilities.addUnrequestedReservation) {
      return false
    }

    const onlyEmptyDocuments = (element) => !element.documents.length

    return elements.filter(onlyEmptyDocuments).length > 0
  }

  render() {
    const { children, instanceCurrency, request, currentUser } = this.props

    const renderProps = {
      instanceCurrency,
      request,
      uploadDocument: this.uploadDocument,
      deleteDocument: this.props.delete,
      hasForm: this.hasForm(),
      currentUser,
      documentsList: this.getDocuments(),
    }

    return <div className='request-unassigned-documents'>{children(renderProps)}</div>
  }
}

RequestUnassignedDocumentsManagerBase.propTypes = {
  request: PropTypes.object.isRequired,
}

const mapStateToProps = (state) => ({
  instanceCurrency: getInstanceCurrency(state),
  currentUser: state.get('global').get('currentUser'),
  elements: getAdditionalElements(state),
})

const withConnect = connect(mapStateToProps)
const withDocuments = documents()

const RequestUnassignedDocumentsManager = compose(
  withDocuments,
  withConnect,
)(RequestUnassignedDocumentsManagerBase)

export { RequestUnassignedDocumentsManager }
export default { RequestUnassignedDocumentsManager }
