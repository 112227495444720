import { createSelector, createSlice } from '@reduxjs/toolkit'
import APIClient from '../../../services/APIClient'
import { TableConfig } from './table-config.model'

export const TABLE_CONFIG_MOUNT_POINT = 'tableConfig'

interface InitialState {
  items: Record<string, TableConfig[]>
  isLoading: boolean
  error: any
}

const getInitialState = (): InitialState => ({
  items: {},
  isLoading: false,
  error: null,
})

/**
 * In @reduxjs/toolkit we can mutate state in reducers,
 * because behind the scene it uses ImmerJS for applying them as non-mutate
 *
 * https://github.com/immerjs/immer
 */
const slice = createSlice({
  name: TABLE_CONFIG_MOUNT_POINT,
  initialState: getInitialState(),
  reducers: {
    reset(state) {
      state = getInitialState()
    },
    setTableConfigs(state, action) {
      const { data } = action.payload

      state.items = data
    },
    startLoading(state) {
      state.isLoading = true
      state.error = null
    },
    setLoadingSuccess(state) {
      state.isLoading = false
      state.error = null
    },
    setLoadingFailed(state, action) {
      state.isLoading = false
      state.error = action.payload
    },
  },
})

export const { reset, setTableConfigs, startLoading, setLoadingSuccess, setLoadingFailed } =
  slice.actions

export default slice.reducer

// selectors
const getState = (state) => state.get(TABLE_CONFIG_MOUNT_POINT)

export const getTableConfigs = (state) => getState(state).items
export const isLoading = (state) => getState(state).isLoading

// memoized selectors, improve performance
export const getSettlementTableConfig = createSelector(
  [getTableConfigs],
  (items) => items.settlement,
)

// action thunks
export const fetchTableConfigs = () => async (dispatch) => {
  dispatch(startLoading())

  try {
    const data = await APIClient.getTableConfigs()
    dispatch(setTableConfigs({ data }))
    dispatch(setLoadingSuccess())
  } catch (err) {
    dispatch(setLoadingFailed(err))
  }
}
