import React from 'react'

const omittedPaths = ['/user/profile']

const checkOmittedPaths = (url) => {
  return !!omittedPaths.find((path) => !url.indexOf(path))
}

const scrollToTop = () => (Component) => {
  class ScrollToTop extends React.Component<any, any> {
    componentDidUpdate(prevProps, prevState) {
      if (
        prevProps.location.pathname !== this.props.location.pathname &&
        !checkOmittedPaths(this.props.location.pathname)
      ) {
        window.scrollTo(0, 0)
      }
    }

    render() {
      return <Component<any, any> {...this.props} />
    }
  }

  return ScrollToTop
}

export default scrollToTop
export { scrollToTop }
