import React, { Fragment } from 'react'
import { Col } from '../../ui'
import Icon from '../ui/IconComponent'
import trans from '../../trans'
import { TypesMenu } from '../TripTimeline'
import {
  TRAVEL_ACCOMODATION,
  TRAVEL_PLANE_TRIP,
  TRAVEL_PRIVATE_ACCOMODATION,
  TRAVEL_TRAIN_TRIP,
} from '../../constants/travel'
import { bindActionCreators, compose } from 'redux'
import { connect } from 'react-redux'
import { Ability } from '../RequestPageCommon/Ability/Ability'
import {
  addElement,
  changeElementTypeAtIndex,
  getAdditionalElements,
} from '../../store/app/trip-timeline'
import Booking from './Booking'
import { EdgePointManager } from '../../containers/EdgePointManager'
import classNames from 'classnames'
import { getCurrencies, isFlightSearchEnabled } from '../../store/app/trip-request'

class AddUnrequestedReservation extends React.Component<any, any> {
  constructor(props) {
    super(props)

    this.state = {
      isSelectingTypeOpen: false,
      isTypeSelected: false,
    }

    this.container = React.createRef()
  }

  renderFilteredElements(elements, request) {
    return (
      <Col xs={12}>
        <div className='tooltip-content'>
          {elements.map((element) => (
            <Booking element={element} request={request} key={element.uuid} />
          ))}
        </div>
      </Col>
    )
  }

  renderElements() {
    const { elements, request } = this.props

    if (elements.some((e) => !e.documents.length)) {
      return this.renderFilteredElements(
        elements.filter((e) => !e.documents.length),
        request,
      )
    }

    return this.renderFilteredElements(elements, request)
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevState.isSelectingTypeOpen && !this.state.isSelectingTypeOpen) {
      this.container.current.scrollIntoView()
    }
  }

  renderCta() {
    const { isSearchFeatureEnabled } = this.props

    if (!isSearchFeatureEnabled) {
      return null
    }

    return (
      <Col width='217px'>
        <div className='add-unrequested-reservation__container'>
          {!this.state.isSelectingTypeOpen && (
            <div
              className={'add-unrequested-reservation__init-section'}
              onClick={() => {
                this.setState({ isSelectingTypeOpen: true })
              }}
            >
              <div style={{ width: '100%' }}>
                <Icon xlg type='search' />
              </div>
              <div className={'add-unrequested-reservation__button-text'}>
                {trans('global.add-unrequested-reservation-button-text')}
              </div>
            </div>
          )}

          {this.state.isSelectingTypeOpen && (
            <div className={'add-unrequested-reservation__select'}>
              <div className={'add-unrequested-reservation__select-type-section'}>
                {trans('global.select-unrequested-reservation-type-text')}
              </div>
              <TypesMenu
                onSelect={(type) => {
                  this.setState({
                    isSelectingTypeOpen: false,
                  })

                  const count = this.props.elements.length
                  const index = count > 0 ? count - 1 : count

                  this.props.addElement(index, 'additional')
                  this.props.changeElementTypeAtIndex(index, type, 'additional')
                }}
                menuTypesInjected={[
                  {
                    type: TRAVEL_PLANE_TRIP,
                    icon: 'plane2',
                    render: true,
                  },
                  {
                    type: TRAVEL_TRAIN_TRIP,
                    icon: 'train_flat',
                    render: true,
                  },
                  {
                    type: TRAVEL_ACCOMODATION,
                    icon: 'hotel',
                    render: true,
                  },
                  {
                    type: TRAVEL_PRIVATE_ACCOMODATION,
                    icon: 'accomodation',
                    render: false,
                  },
                ]}
              />
            </div>
          )}
        </div>
      </Col>
    )
  }

  render() {
    const { request, hasForm } = this.props
    const classes = classNames({
      'add-unrequested-reservation__wrapper': true,
      'add-unrequested-reservation__wrapper--form': hasForm,
    })

    return (
      <Ability ability={['addUnrequestedReservation']} abilities={request.abilities}>
        <EdgePointManager request={request}>
          {() => (
            <div className={classes} ref={this.container}>
              {hasForm ? this.renderElements() : this.renderCta()}
            </div>
          )}
        </EdgePointManager>
      </Ability>
    )
  }
}

const mapStateToProps = (state, props) => ({
  elements: getAdditionalElements(state),
  currencies: getCurrencies(state),
  isSearchFeatureEnabled: isFlightSearchEnabled(state),
})

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      addElement,
      changeElementTypeAtIndex,
    },
    dispatch,
  )
}

const withConnect = connect(mapStateToProps, mapDispatchToProps)

AddUnrequestedReservation = compose(withConnect)(AddUnrequestedReservation)

export default AddUnrequestedReservation
