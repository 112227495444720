import React, { memo, useEffect, useState } from 'react'
import APIClient from '../../services/APIClient'
import SocketProviderService from '../../utils/SocketProvider'
import uuid from '../../utils/uuid'
import isTouchDevice from '../../utils/isTouchDevice'

const DocumentPreview = ({ id, name, type, requestSlug, callback }) => {
  const [blob, setBlob] = useState(null)
  const [viewId, setViewId] = useState()
  const [isTouch] = useState(isTouchDevice())

  const fetchDocument = (callback = null) => {
    APIClient.getDocumentAsBlob(`storage/documents/${id}/original`).then((response) => {
      if (isTouch) {
        setBlob(response.data)
      } else {
        setBlob(URL.createObjectURL(response.data))
      }

      if (callback !== null && typeof callback === 'function') {
        callback()
      }
    })
  }

  useEffect(() => {
    setViewId(uuid())
  }, [])

  useEffect(() => {
    if (!blob || !isTouch) {
      return
    }

    const embed = new AdobeDC.View({ clientId: process.env.ADOBE_EMBED_API_KEY, divId: viewId })
    embed.previewFile(
      {
        content: { promise: blob.arrayBuffer() },
        metaData: { fileName: name },
      },
      {
        embedMode: 'SIZED_CONTAINER',
      },
    )
  }, [blob])

  useEffect(() => {
    fetchDocument()

    if (type === 'travel') {
      const channelName = `App.Request.${requestSlug}`
      const eventName = '.App\\Events\\Document\\TicketInvalidatedEvent'
      SocketProviderService.subscribe(channelName)(eventName)((data) => {
        fetchDocument(callback)
      })

      return () => {
        SocketProviderService.unsubscribe(channelName)(eventName)
      }
    }
  }, [id])

  if (!isTouch) {
    return (
      <iframe
        src={blob}
        style={{
          maxWidth: '100%',
          width: '100%',
          border: '0 none',
        }}
        onLoad={(e) => {
          const height = e.target.contentWindow.outerHeight

          e.target.style.height = `${height}px`
        }}
      />
    )
  }

  return (
    <div className={'document-preview--container'}>
      <div id={viewId} />
    </div>
  )
}

export default memo(DocumentPreview)
