import React from 'react'
import { Helmet } from 'react-helmet'
import { BreadCrumbs } from '../../../containers'
import { Section } from '../../ui/Section'
import { SectionHeader } from '../../ui/SectionHeader'
import { RouteManager } from '../../../containers/RouteManager'
import InstanceUsersManager from '../../../containers/InstanceUsersManager'
import { getRouteByName } from '../../../routes'
import { Link } from 'react-router-dom'
import trans from '../../../trans'
import { Filters } from './Filters'
import { List } from './List'
import Button from '../../ui/ButtonComponent'

const Page = () => (
  <div>
    <BreadCrumbs>
      <Link to={getRouteByName('main', 'dashboard')}>{trans('global.dashboard')}</Link>
      <Link to={getRouteByName('main', 'instance-settings')}>
        {trans('instance-settings.instance-settings')}
      </Link>
      <Link to={getRouteByName('main', 'users')}>{trans('main-menu.users')}</Link>
    </BreadCrumbs>

    <Section noBorder>
      <Helmet title={trans('main-menu.users')} />

      <SectionHeader caption={trans('main-menu.users')}>
        <Link to={getRouteByName('main', 'createUser')} className='no-style-link pull-right'>
          <Button primary>{trans('instance-users.new')}</Button>
        </Link>
      </SectionHeader>

      <RouteManager>
        {({ getRouteByName }) => (
          <InstanceUsersManager>
            {({
              users,
              companies,
              groups,
              MPKs,
              grades,
              requestsFilters,
              changeRequestsFilter,
              sorter,
              changeSorter,
              requestsPagination,
              changeRequestsPage,
              areUsersLoaded,
              areUsersLoading,
              selectedItems,
              selectDeselectOne,
              selectDeselectAll,
              selectDeselectMany,
              isItemSelected,
              requestCardForSelectedUsers,
              currentUser,
            }) => (
              <div>
                <Filters
                  filters={requestsFilters}
                  onChangeFilter={changeRequestsFilter}
                  companies={companies}
                  groups={groups}
                  MPKs={MPKs}
                  grades={grades}
                />
                <List
                  users={users}
                  getRouteByName={getRouteByName}
                  pagination={requestsPagination}
                  changePage={changeRequestsPage}
                  isLoading={!areUsersLoaded || areUsersLoading}
                  sorter={sorter}
                  onChangeSorter={changeSorter}
                  selectedItems={selectedItems}
                  selectDeselectOne={selectDeselectOne}
                  selectDeselectAll={selectDeselectAll}
                  selectDeselectMany={selectDeselectMany}
                  isItemSelected={isItemSelected}
                  requestCardForUsers={requestCardForSelectedUsers}
                  currentUser={currentUser}
                />
              </div>
            )}
          </InstanceUsersManager>
        )}
      </RouteManager>
    </Section>
  </div>
)

export default Page
