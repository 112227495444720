import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import Button from '../../ui/ButtonComponent'
import { trans } from '../../../trans'
import classNames from 'classnames'
import Icon from '../../ui/IconComponent'
import PriceInfo from '../Offer/PriceInfo'
import { Loader } from '../../ui/LoadingOverlay/Loader'
import ItemDetails from './ItemDetails'
import ReturnItems from './ReturnItems'
import { connect } from '../../../containers/FlightsBooking/connect'
import { get } from 'lodash'

class ListItemComponent extends Component<any, any> {
  constructor(props) {
    super(props)

    this.state = {
      expanded: false,
      isFetchingReturns: false,
    }
  }

  expandToggle = () => {
    const { expanded } = this.state

    if (!expanded) {
      const {
        flights: {
          selectors: { returnFlights },
          actions: { fetchReturnFlights },
        },
        offer,
      } = this.props

      const returnOffers = returnFlights(offer)

      if (returnOffers.length === 0) {
        this.setState({ isFetchingReturns: true })

        fetchReturnFlights(offer).then(() => {
          this.setState({ isFetchingReturns: false })
        })
      }

      this.setState({ expanded: true })
    } else {
      this.setState({ expanded: false })
    }
  }

  render() {
    const { offer, isRoundTrip } = this.props
    const {
      flights: {
        selectors: { returnFlights },
      },
    } = this.props
    const returnOffers = returnFlights(offer)
    const returnFlightsCounter = offer.attributes.returnFlightsCounter
      ? offer.attributes.returnFlightsCounter
      : 0

    const classes = classNames({
      'plane-trip__offer': true,
      'plane-trip__offer--expanded': this.state.expanded,
      'has-loader': true,
    })

    const {
      flights: {
        actions: { selectOffer },
      },
    } = this.props

    // FIXME: TODO: remove on production
    let score = null
    let attributes = null

    if (offer && offer.attributes && offer.attributes.filteringAndSortingAttributesDTO) {
      score = offer.attributes.filteringAndSortingAttributesDTO.score.score
      attributes = JSON.stringify(offer.attributes.filteringAndSortingAttributesDTO)
    }

    return (
      <div
        className={classes}
        // FIXME: TODO: remove on production
        data-score={score}
        data-score-attributes={attributes}
      >
        <ItemDetails offer={offer}>
          {isRoundTrip ? (
            <div
              className='plane-trip__offer-detail plane-trip__offer-expander'
              onClick={this.expandToggle}
            >
              <Button arrow outline xxs>
                {this.state.expanded ? (
                  trans('flights-booking.collapse-options')
                ) : (
                  <Fragment>
                    {trans('flights-booking.return-options')} ({returnFlightsCounter})
                  </Fragment>
                )}
                <Icon type='arrow' rotatable rotate={this.state.expanded} />
              </Button>
            </div>
          ) : (
            <Button primary xxs onClick={() => selectOffer(offer)}>
              {trans('flights-booking.select-offer')}
            </Button>
          )}
        </ItemDetails>

        {this.state.isFetchingReturns && <Loader />}

        {isRoundTrip && this.state.expanded && (
          <ReturnItems
            offer={offer}
            visible={this.state.expanded && !this.state.isFetchingReturns}
          />
        )}

        <PriceInfo offer={offer} />
      </div>
    )
  }
}

ListItemComponent.propTypes = {
  offer: PropTypes.object.isRequired,
  selectOffer: PropTypes.func,
  removeSelected: PropTypes.func,
  featured: PropTypes.bool,
}

ListItemComponent.defaultProps = {
  featured: false,
}

const ListItem = connect(ListItemComponent)

export default ListItem
export { ListItem }
