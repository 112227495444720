import React from 'react'
import { get } from 'lodash'
import {
  bookOffer,
  change,
  changeErrorMessageSlugAction,
  changeStatus,
  reduxFormChange,
  reduxFormInitialize,
  reset,
  searchAnotherOne,
  set,
  setFilter,
  setOffers,
  setPaxes,
  toggleSearch,
} from './reducers'

export const MODULE_MOUNT_POINT = 'flights-booking'

export const RESET = MODULE_MOUNT_POINT + '::reset'
export const SET = MODULE_MOUNT_POINT + '::set'
export const CHANGE = MODULE_MOUNT_POINT + '::change'
export const SET_OFFERS = MODULE_MOUNT_POINT + '::set-offers'
export const SET_PAXES = MODULE_MOUNT_POINT + '::set-paxes'
export const SET_FILTER = MODULE_MOUNT_POINT + '::set-filter'
export const BOOK_OFFER = MODULE_MOUNT_POINT + '::book-offer'
export const SEARCH_ANOTHER_ONE = MODULE_MOUNT_POINT + '::search-another-one'
export const TOGGLE_SEARCH = MODULE_MOUNT_POINT + '::toggle-search'
export const CHANGE_STATUS = MODULE_MOUNT_POINT + '::change-status'
export const CHANGE_ERROR_MESSAGE_SLUG = MODULE_MOUNT_POINT + '::change-error-message-slug'

export const REDUX_FORM_CHANGE = '@@redux-form/CHANGE'
export const REDUX_FORM_INITIALIZE = '@@redux-form/INITIALIZE'

export const SEARCH_STATUS_PENDING = 'pending'
export const SEARCH_STATUS_COMPLETED = 'completed'

export const BOOKING_SUCCESS = 'booked'
export const BOOKING_EXPIRED = 'expired'
export const BOOKING_FAILED = 'failed'
export const BOOKING_OFFER_CHANGED = 'offer_changed'
export const BOOKING_WAITING_FOR_CONFIRM = 'waiting_for_confirm'
export const BOOKING_WAITING_FOR_TICKET = 'waiting_for_ticket'
export const BOOKING_NEW = 'new'
export const BOOKING_CANCELLED = 'canceled'
export const BOOKING_PENDING_CANCELLATION = 'pending_cancellation'
export const BOOKING_CANCELLATION_FAILED = 'cancellation_failed'
export const BOOKING_WITHOUT_TICKET = 'booked_without_ticket'

export const QUERY_FIELD_NAMES = [
  'round_trip',
  'flight_from',
  'flight_to',
  'flight_class',
  'arrival_at',
  'return_at',
  'direct_only',
  'restrict_to_fba',
  'request_travelers',
]

export const SELECT_MODE_THERE = 'there'
export const SELECT_MODE_BACK = 'back'

export const FLIGHT_TYPE_TARGET = 'target'
export const FLIGHT_TYPE_RETURN = 'return'

export const FILTER_TARGET_DEPARTURE_TIME = 'target_departure_time'
export const FILTER_RETURN_DEPARTURE_TIME = 'return_departure_time'
export const FILTER_TARGET_DEPARTURE_TIME_DEFAULT_FROM = 0
export const FILTER_TARGET_DEPARTURE_TIME_DEFAULT_TO = 720
export const FILTER_RETURN_DEPARTURE_TIME_DEFAULT_FROM = 900
export const FILTER_RETURN_DEPARTURE_TIME_DEFAULT_TO = 1440

export const SORT_BY_DEPARTURE_TIME = 'departure-time'
export const SORT_BY_CHEAPEST_FIRST = 'cheapest-first'
export const SORT_BY_TRAVEL_TIME = 'travel-time'
export const SORT_BY_BEST_OFFER = 'best-offer'

const actions = {
  [RESET]: reset,
  [SET]: set,
  [SET_OFFERS]: setOffers,
  [SET_PAXES]: setPaxes,
  [REDUX_FORM_CHANGE]: reduxFormChange,
  [REDUX_FORM_INITIALIZE]: reduxFormInitialize,
  [SET_FILTER]: setFilter,
  [SEARCH_ANOTHER_ONE]: searchAnotherOne,
  [BOOK_OFFER]: bookOffer,
  [TOGGLE_SEARCH]: toggleSearch,
  [CHANGE]: change,
  [CHANGE_STATUS]: changeStatus,
  [CHANGE_ERROR_MESSAGE_SLUG]: changeErrorMessageSlugAction,
}

export const getInitialState = () => {
  return {
    offers: [],
    round: false,
    status: SEARCH_STATUS_PENDING,
    initialized: false,
    uuid: null,
    query: {},
    integrity: true,
    loading: [],
    paxes: {},
    filter: {
      [FILTER_TARGET_DEPARTURE_TIME]: {
        min: FILTER_TARGET_DEPARTURE_TIME_DEFAULT_FROM,
        max: FILTER_TARGET_DEPARTURE_TIME_DEFAULT_TO,
      },
      [FILTER_RETURN_DEPARTURE_TIME]: {
        min: FILTER_RETURN_DEPARTURE_TIME_DEFAULT_FROM,
        max: FILTER_RETURN_DEPARTURE_TIME_DEFAULT_TO,
      },
    },
    sortBy: SORT_BY_DEPARTURE_TIME,
    enabled: true,
    isResultsListOpen: false,
    isOfferDialogOpen: false,
    hasOffers: false,
    selected: {
      [SELECT_MODE_THERE]: null,
      [SELECT_MODE_BACK]: null,
    },
    isFetchingReturns: false,
    isAgreement: false,
    isFiltering: false,
    isBlocked: false,
    rules: null,
    totalPaxes: 1,
    searchFailed: false,
    isChanged: false,
  }
}

export const reducer = (state = {}, action) => {
  if (actions.hasOwnProperty(action.type)) {
    return actions[action.type](state, action.payload, get(action, 'meta', {}))
  }
  return state
}
