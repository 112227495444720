import React from 'react'

class TFoot extends React.Component<any, any> {
  render() {
    const { children } = this.props

    return <div className='table-accordion__footer'>{children}</div>
  }
}

export { TFoot }
export default TFoot
