import { ITransactionSuggestion } from '../../types/transaction-suggestion'
import { AgGridReact } from 'ag-grid-react'
import React, { useCallback } from 'react'
import { usePaymentAttachGridOptions } from './hooks/usePaymentAttachGridOptions'
import { CellClickedEvent } from 'ag-grid-community'

export default function PaymentAttachTable({
  items,
  onAttach,
}: {
  items: ITransactionSuggestion[]
  onAttach?: (item: ITransactionSuggestion) => void
}) {
  const gridOptions = usePaymentAttachGridOptions()

  const onCellClicked = useCallback((params: CellClickedEvent<ITransactionSuggestion>) => {
    if (params.colDef.type === 'attach') {
      onAttach(params.data)
    }
  }, [])

  return (
    <div className={'ag-theme-alpine payment-attach-table'}>
      <AgGridReact
        gridOptions={gridOptions}
        rowData={items}
        rowHeight={58}
        headerHeight={40}
        onCellClicked={onCellClicked}
      />
    </div>
  )
}
