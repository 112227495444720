import React, { Component } from 'react'

import PropTypes from 'prop-types'
import trans from '../../../trans'
import InputRange from 'react-input-range'
import minutesToHours from '../../../utils/minutesToHours'

const ARRIVE = 'arrive'
const DEPARTURE = 'departure'

class Time extends Component<any, any> {
  constructor(props) {
    super(props)

    this.state = {
      direction: DEPARTURE,
      value: {
        [ARRIVE]: {
          min: 0,
          max: 1440,
        },
        [DEPARTURE]: {
          min: 0,
          max: 1440,
        },
      },
    }
  }

  setDirection = (e) => {
    this.setState({ direction: e.target.value })
  }

  setValue = (value) => {
    const { direction } = this.state
    this.setState({
      value: {
        ...this.state.value,
        [direction]: value,
      },
    })
  }

  getValue() {
    const { direction, value } = this.state
    return value[direction]
  }

  getMinValue = () => {
    const { direction, value } = this.state
    return value[direction].min
  }

  getMaxValue = () => {
    const { direction, value } = this.state
    return value[direction].max
  }

  onChange = (value) => {
    const {
      trainsBooking: {
        actions: { setFilter },
      },
    } = this.props
    const { direction } = this.state

    setFilter({ time: { [direction]: value } })
  }

  render() {
    const activeClass = 'border-crossings__toggler-label--active'

    const arriveActive = this.state.direction === ARRIVE ? activeClass : ''
    const departureActive = this.state.direction === DEPARTURE ? activeClass : ''

    return (
      <div className='train-trip__departure-inputs'>
        <div className='train-trip__input'>
          <div className='border-crossings__toggler'>
            <label
              htmlFor='trip-abroad'
              className={`border-crossings__toggler-label border-crossings__toggler-label--first ${departureActive}`}
            >
              <span>{trans('train-trip.departure')}</span>
            </label>
            <input
              id='trip-abroad'
              className='border-crossings__toggler-radio'
              type='radio'
              value={DEPARTURE}
              onClick={this.setDirection}
            />
            <label
              htmlFor='trip-national'
              className={`border-crossings__toggler-label border-crossings__toggler-label--last ${arriveActive}`}
            >
              <span>{trans('train-trip.arrival')}</span>
            </label>
            <input
              id='trip-national'
              className='border-crossings__toggler-radio'
              type='radio'
              value={ARRIVE}
              onClick={this.setDirection}
            />
          </div>
        </div>

        <div className='train-trip__input train-trip__input--range-slider'>
          <span className='train-trip__range-slider-data'>
            <span>{minutesToHours(this.getMinValue())}</span>
          </span>

          <InputRange
            minValue={0}
            maxValue={1440}
            step={15}
            value={this.getValue()}
            onChange={this.setValue}
            onChangeComplete={this.onChange}
            classNames={{
              activeTrack: 'train-trip__range-track--active',
              disabledInputRange: 'train-trip__range--disabled',
              inputRange: 'train-trip__range-input',
              labelContainer: 'train-trip__range-label-container',
              maxLabel: 'train-trip__range-label',
              minLabel: 'train-trip__range-label',
              slider: 'train-trip__range-slider',
              sliderContainer: 'train-trip__range-slider-container',
              track: 'train-trip__range-track',
              valueLabel: 'train-trip__range-label',
            }}
            formatLabel={(value) => `${value}cm`}
          />

          <span className='train-trip__range-slider-data'>
            <span>{minutesToHours(this.getMaxValue())}</span>
          </span>
        </div>
      </div>
    )
  }
}

Time.propTypes = {
  trainsBooking: PropTypes.object.isRequired,
}

export default Time
export { Time }
