import { createSelector, createSlice } from '@reduxjs/toolkit'
import APIClient from '../../../services/APIClient'
import { trans } from '../../../trans'
import { deburr, orderBy } from 'lodash'

export const COUNTRIES_MOUNT_POINT = 'countries2'

/**
 * In @reduxjs/toolkit we can mutate state in reducers,
 * because behind the scene it uses ImmerJS for applying them as non-mutate
 *
 * https://github.com/immerjs/immer
 */
const countriesSlice = createSlice({
  name: COUNTRIES_MOUNT_POINT,
  initialState: {
    items: [],
    error: null,
    isLoading: false,
  },
  reducers: {
    setCountries(state, action) {
      const { data } = action.payload

      state.items = data
    },
    startLoading(state) {
      state.isLoading = true
      state.error = null
    },
    setLoadingSuccess(state) {
      state.isLoading = false
      state.error = null
    },
    setLoadingFailed(state, action) {
      state.isLoading = false
      state.error = action.payload
    },
    reset(state) {
      state.items = []
      state.error = null
      state.isLoading = false
    },
  },
})

export const { reset, setCountries, startLoading, setLoadingSuccess, setLoadingFailed } =
  countriesSlice.actions

export default countriesSlice.reducer

// selectors
const getState = (state) => {
  return state.get(COUNTRIES_MOUNT_POINT)
}

export const getCountries = (state) => {
  return getState(state).items
}

export const isLoading = (state) => {
  return getState(state).isLoading
}

// memoized selectors, improve performance
export const getCountriesForSelectOptionsSelector = createSelector([getCountries], (items) =>
  items.map((item) => ({
    value: item.id,
    label: trans(item.name),
  })),
)

// action thunks
export const fetchCountries = () => async (dispatch) => {
  dispatch(startLoading())

  try {
    const { data } = await APIClient.getCountries()
    const countries = orderBy(
      data.map((country) => ({
        ...country,
        name: trans(country.name),
      })),
      (country) => deburr(country.name).toLowerCase(),
    )

    dispatch(setCountries({ data: countries }))
    dispatch(setLoadingSuccess())
  } catch (err) {
    dispatch(setLoadingFailed(err))
  }
}

export const fetchCountriesIfNeeded = () => async (dispatch, state) => {
  const { items, isLoading } = getState(state())

  if (items.length || isLoading) {
    return
  }

  return dispatch(fetchCountries())
}
