import PropTypes from 'prop-types'
import React from 'react'
import { Section } from '../ui/Section'
import { SectionHeader } from '../ui/SectionHeader'
import * as constants from '../../constants/request'
import trans from '../../trans'
import { Path } from './Path'
import { PathItem } from './PathItem'
import { AbstractRequestStatusPath } from './AbstractRequestStatusPath'
import { getInstance } from '../../store/app/instance'
import { connect } from 'react-redux'

class TripRequestStatusPath extends AbstractRequestStatusPath {
  render() {
    const { request, instance } = this.props

    return (
      <Section noBorder noMargin>
        <SectionHeader caption='Status' />

        <div>
          {this.renderCancelled()}
          {this.renderRejected()}
        </div>

        <Path>
          <PathItem isActive={request.status === constants.STATUS_DRAFT}>
            <span>{trans('trip-request.path-status-draft')}</span>
          </PathItem>
          <PathItem isActive={request.status === constants.STATUS_WAITING_FOR_ACCEPTANCE}>
            <span>{trans('trip-request.path-status-waiting-for-acceptance')}</span>
            {this.renderAcceptors()}
          </PathItem>
          <PathItem isActive={request.status === constants.STATUS_UPCOMING_TRIP}>
            <span>{trans('trip-request.path-status-upcoming-trip')}</span>
          </PathItem>
          <PathItem isActive={request.status === constants.STATUS_TRIP}>
            <span>{trans('trip-request.path-status-trip')}</span>
          </PathItem>
          <PathItem isActive={request.status === constants.STATUS_SETTLEMENT}>
            <span>{trans('trip-request.path-status-settlement')}</span>
          </PathItem>
          <PathItem isActive={request.status === constants.STATUS_ACCEPTANCE_OF_SETTLEMENT}>
            <span>{trans('trip-request.path-status-acceptance-of-settlement')}</span>
            {this.renderSettlementAcceptors()}
          </PathItem>
          <PathItem isActive={request.status === constants.STATUS_ACCOUNTING}>
            <span>{trans('trip-request.path-status-accounting')}</span>
          </PathItem>
          {((instance.modules.erp && instance.modules.erp.enabled) ||
            request.features.FEATURE_INTEGRATION_API_ERP_ACCOUNTING_ENABLED ||
            instance.features.FEATURE_INTEGRATION_MODE === 'FILE') &&
            request.status === constants.STATUS_TRANSFER_ERROR && (
              <PathItem isActive={request.status === constants.STATUS_TRANSFER_ERROR}>
                <span>{trans('trip-request.path-status-transfer-error')}</span>
              </PathItem>
            )}
          {((instance.modules.erp && instance.modules.erp.enabled) ||
            request.features.FEATURE_INTEGRATION_API_ERP_ACCOUNTING_ENABLED ||
            instance.features.FEATURE_INTEGRATION_MODE === 'FILE') && (
            <PathItem isActive={request.status === constants.STATUS_TRANSFERRED}>
              <span>{trans('trip-request.path-status-transferred')}</span>
            </PathItem>
          )}
          <PathItem isActive={request.status === constants.STATUS_FINISH}>
            <span>{trans('trip-request.path-status-finish')}</span>
            {this.renderERPSender()}
          </PathItem>
        </Path>
      </Section>
    )
  }
}

TripRequestStatusPath.propTypes = {
  request: PropTypes.object.isRequired,
  acceptors: PropTypes.array.isRequired,
  settlementAcceptors: PropTypes.array.isRequired,
  addAcceptor: PropTypes.func.isRequired,
  addSettlementAcceptor: PropTypes.func.isRequired,
  removeAcceptor: PropTypes.func.isRequired,
  removeSettlementAcceptor: PropTypes.func.isRequired,
}

const mapStateToProps = (state) => ({
  instance: getInstance(state),
})

TripRequestStatusPath = connect(mapStateToProps)(TripRequestStatusPath)

export { TripRequestStatusPath }
export default { RequestStatusPath: TripRequestStatusPath }
