import React, { Fragment } from 'react'
import { Link } from 'react-router-dom'
import { ICellRendererParams } from 'ag-grid-community'

export interface LinkRendererProps extends ICellRendererParams {
  link?: string
}

export default function LinkRenderer(props: LinkRendererProps) {
  const Component = props.link ? Link : Fragment
  const value = props.valueFormatted || props.value

  return <Component to={props.link}>{value}</Component>
}
