import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import { provide as nonDelegationProvider } from '../../containers/NonDelegation'

const NonDelegationWrapper = nonDelegationProvider((props) => props.children)

NonDelegationWrapper.propTypes = {}

export default NonDelegationWrapper
export { NonDelegationWrapper }
