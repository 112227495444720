import React, { useCallback } from 'react'
import AlertsController from '../../services/alerts-controller'
import trans from '../../trans'
import { formatIBAN, parseIBAN } from '../../hooks/useIBAN'

export const UserProfileHover = ({
  hover,
}: {
  hover: Array<{
    name: string
    value: string
    field: string
  }>
}) => {
  const copy = useCallback((item: { name: string; value: string, field: string }) => {
    AlertsController.displayAlert({
      type: 'info',
      message: trans('global.clipboard', { name: item.name }),
    })

    const value = item.field === 'iban' ? parseIBAN(item.value || '') : item.value

    return navigator.clipboard.writeText(value)
  }, [])

  return (
    <>
      {hover.map(item => {
        if (item.field === 'iban') {
          return {
            ...item,
            value: formatIBAN(item.value || ''),
          }
        }

        return item;
      }).map((item) => {
        return (
          <div className='user-profile__hover-line' onClick={() => copy(item)}>
            <strong>{item.name}:</strong> {item.value}
          </div>
        )
      })}
    </>
  )
}
