import React, { Component } from 'react'
import Icon from '../ui/IconComponent'

const SORT_NONE = null
const SORT_ASC = 'asc'
const SORT_DESC = 'desc'

class SortableCellWrapper extends Component<any, any> {
  render() {
    const extraProps = {
      icon: this.props.sorter[this.props.name] ? (
        <Icon
          style={{ display: 'flex', marginRight: 0 }}
          type='icon-arrow'
          rotate={this.props.sorter[this.props.name] === SORT_DESC}
        />
      ) : (
        ''
      ),
    }
    return (
      <div
        style={{ ...this.props.style, cursor: 'pointer' }}
        onClick={() => {
          if (!this.props.sorter[this.props.name]) {
            this.props.onChangeSorter(this.props.name, SORT_ASC)
          }

          if (
            this.props.sorter[this.props.name] &&
            this.props.sorter[this.props.name] === SORT_ASC
          ) {
            this.props.onChangeSorter(this.props.name, SORT_DESC)
          }

          if (
            this.props.sorter[this.props.name] &&
            this.props.sorter[this.props.name] === SORT_DESC
          ) {
            this.props.onChangeSorter(this.props.name, SORT_NONE)
          }
        }}
      >
        {typeof this.props.children === 'function'
          ? this.props.children(extraProps)
          : this.props.children}
      </div>
    )
  }
}

export default SortableCellWrapper
