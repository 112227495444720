import moment from 'moment'

export const sortByDate = (prev, next, desc = true) => {
  const prevDate = moment(prev)
  const nextDate = moment(next)

  if (prevDate.isBefore(nextDate)) {
    return desc ? 1 : -1
  } else if (prevDate.isAfter(nextDate)) {
    return desc ? -1 : 1
  }

  return 0
}
