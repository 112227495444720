import PropTypes from 'prop-types'
import React from 'react'
import { formValueSelector } from 'redux-form/immutable'
import { connect } from 'react-redux'
import { fromJS } from 'immutable'
import { DocumentExpenseTypeForm as FormComponent } from '../../components/DocumentExpensesTypes/DocumentExpenseTypeForm'
import { bindActionCreators } from 'redux'
import { createAutoSaveForm, getFormValues } from '../../utils/forms'
import { get, isUndefined, isEmpty } from 'lodash'

class FormComponentContainer extends React.Component<any, any> {
  render() {
    const { component: ChildComponent } = this.props // presentation component - form with FormFields

    return <ChildComponent {...this.props} />
  }
}

const DocumentExpenseTypeFormBase = createAutoSaveForm(FormComponent, {
  destroyOnUnmount: false,
  container: FormComponentContainer,
  save: (name, value, dispatch, props) => {
    const { expense, handleExpenseSave } = props
    return handleExpenseSave(expense, { [name]: value })
  },
})

DocumentExpenseTypeFormBase.propTypes = {
  expense: PropTypes.object.isRequired,
  handleExpenseSave: PropTypes.func.isRequired,
}

const mapStateToProps = (state, props) => {
  const { expense } = props
  return {
    initialValues: fromJS({
      gross: get(expense['element'], 'gross', null),
    }),
    form: props.formName,
    data: getFormValues(props.formName, state),
    expense,
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({}, dispatch)
}

const DocumentExpenseTypeForm = connect(
  mapStateToProps,
  mapDispatchToProps,
)(DocumentExpenseTypeFormBase)

export { DocumentExpenseTypeForm }
export default { DocumentExpenseTypeForm }
