import React from 'react'
import { Helmet } from 'react-helmet'
import { config } from '../../config'
import { LostPasswordForm } from '../../containers/LostPasswordForm'
import trans from '../../trans'

export const LostPasswordPage = () => (
  <div>
    <Helmet title={trans(config.titles.login)} />
    <LostPasswordForm />
  </div>
)
