import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Row, Col } from '../../../ui/Grid'
import { FormContainer } from '../forms/FormContainer'

export class FormWrapper extends Component<any, any> {
  constructor(props) {
    super(props)
  }

  render() {
    const {
      from,
      to,
      setAccessLumpSumPair,
      toggle,
      fromIndex,
      toIndex,
      setStoreForAccessLumpSumsPair,
      deleteAccessLumpSums,
    } = this.props
    const { id: fromId, isNew: isNewFrom } = from
    const { id: toId, isNew: isNewTo } = to

    return (
      <div className='expense-commute__travel-list__travel__form-wrapper--outer'>
        <Row>
          <Col xs={12}>
            <div className='expense-commute__travel-list__travel__form-wrapper--inner'>
              <FormContainer
                from={from}
                to={to}
                fromIndex={fromIndex}
                toIndex={toIndex}
                formName={`expense-commute-from-${fromId}-${isNewFrom}-to-${toId}-${isNewTo}`}
                setAccessLumpSumPair={setAccessLumpSumPair}
                toggle={toggle}
                setStoreForAccessLumpSumsPair={setStoreForAccessLumpSumsPair}
                deleteAccessLumpSums={deleteAccessLumpSums}
              />
            </div>
          </Col>
        </Row>
      </div>
    )
  }
}

FormWrapper.propTypes = {
  from: PropTypes.object.isRequired,
  to: PropTypes.object.isRequired,
  setAccessLumpSumPair: PropTypes.func.isRequired,
  toggle: PropTypes.func.isRequired,
  fromIndex: PropTypes.number.isRequired,
  toIndex: PropTypes.number.isRequired,
  setStoreForAccessLumpSumsPair: PropTypes.func.isRequired,
  deleteAccessLumpSums: PropTypes.func.isRequired,
}
