import React from 'react'
import { get } from 'lodash'
import {
  bookOfferAction,
  changeAction,
  changeErrorMessageSlugAction,
  resetAction,
  searchAnotherOneAction,
  selectOfferAction,
  setAction,
  setFilterAction,
  setOffersAction,
  toggleSearchAction,
  setAgreementAction,
  changeStatusAction,
  replaceOfferAction,
} from './actions'

export const MODULE_MOUNT_POINT = 'trains-booking'

export const RESET = MODULE_MOUNT_POINT + '::reset'
export const SET = MODULE_MOUNT_POINT + '::set'
export const CHANGE = MODULE_MOUNT_POINT + '::change'
export const CHANGE_STATUS = MODULE_MOUNT_POINT + '::change-status'
export const CHANGE_ERROR_MESSAGE_SLUG = MODULE_MOUNT_POINT + '::change-error-message-slug'
export const SET_OFFERS = MODULE_MOUNT_POINT + '::set-offers'
export const REPLACE_OFFER = MODULE_MOUNT_POINT + '::replace-offers'
export const SELECT_OFFER = MODULE_MOUNT_POINT + '::select-offer'
export const SET_FILTER = MODULE_MOUNT_POINT + '::set-filter'
export const BOOK_OFFER = MODULE_MOUNT_POINT + '::book-offer'
export const SEARCH_ANOTHER_ONE = MODULE_MOUNT_POINT + '::search-another-one'
export const TOGGLE_SEARCH = MODULE_MOUNT_POINT + '::toggle-search'
export const SET_AGREEMENT = MODULE_MOUNT_POINT + '::set-agreement'

export const SEARCH_STATUS_PENDING = 'pending'
export const SEARCH_STATUS_COMPLETED = 'completed'

export const BOOKING_NEW = 'new'
export const BOOKING_WAITING_FOR_VERIFY = 'waiting_for_verify'
export const BOOKING_CANCELED = 'canceled'
export const BOOKING_PENDING_CANCELLATION = 'pending_cancellation'
export const BOOKING_CANCELLATION_FAILED = 'cancellation_failed'
export const BOOKING_SUCCESS = 'booked'
export const BOOKING_EXPIRED = 'expired'
export const BOOKING_FAILED = 'failed'
export const BOOKING_WAITING_FOR_CONFIRM = 'waiting_for_confirm'
export const BOOKING_WAITING_FOR_TICKET = 'waiting_for_ticket'
export const BOOKING_WITHOUT_TICKET = 'booked_without_ticket'
export const BOOKING_YA_RE_CHECK = 'ya_re_check'
export const BOOKING_PROCESS_TOO_LONG = 'booking_process_too_long'

const actions = {
  [RESET]: resetAction,
  [SET]: setAction,
  [SET_OFFERS]: setOffersAction,
  [REPLACE_OFFER]: replaceOfferAction,
  [SELECT_OFFER]: selectOfferAction,
  [SET_FILTER]: setFilterAction,
  [SEARCH_ANOTHER_ONE]: searchAnotherOneAction,
  [BOOK_OFFER]: bookOfferAction,
  [TOGGLE_SEARCH]: toggleSearchAction,
  [CHANGE]: changeAction,
  [CHANGE_ERROR_MESSAGE_SLUG]: changeErrorMessageSlugAction,
  [SET_AGREEMENT]: setAgreementAction,
  [CHANGE_STATUS]: changeStatusAction,
}

export const OPERATORS = {
  EIP: 'EIP',
  EIC: 'EIC',
  IC: 'IC',
  TLK: 'TLK',
}

export const getInitialState = () => {
  return {
    offers: [],
    status: SEARCH_STATUS_PENDING,
    initialized: false,
    uuid: null,
    slug: null,
    query: {},
    integrity: true,
    loading: false,
    filter: {
      attributes: {
        equipmentCode: [OPERATORS['IC'], OPERATORS['EIC'], OPERATORS['EIP'], OPERATORS['TLK']],
      },
      time: {
        arrive: {
          min: 0,
          max: 1440,
        },
        departure: {
          min: 0,
          max: 1440,
        },
      },
      travelClass: '2',
    },
    request_travelers: [],
    enabled: true,
    isResultsListOpen: false,
    isOfferDialogOpen: false,
    hasOffers: false,
    isBlocked: false,
    waitingForAttributes: false,
  }
}

export const reducer = (state = {}, action) => {
  if (actions.hasOwnProperty(action.type)) {
    return actions[action.type](state, action.payload, get(action, 'meta', {}))
  }
  return state
}
