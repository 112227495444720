import { getElements } from '../index'

import { findIndex, get, head, includes, isUndefined, reverse, slice, takeRightWhile } from 'lodash'
import {
  TRAVEL_ACCOMODATION_SUGGESTION,
  TRAVEL_BUS_TRIP,
  TRAVEL_COMPANY_CAR_TRIP,
  TRAVEL_EMPTY,
  TRAVEL_PLANE_TRIP,
  TRAVEL_PRIVATE_CAR_TRIP,
  TRAVEL_RENTED_CAR_TRIP,
  TRAVEL_TARGET_POINT,
  TRAVEL_TRAIN_TRIP,
} from '../../../../constants/travel'
import { Factory as ElementFactory } from '../../../../models/timeline/index'
import { getLocation as getInstanceLocation } from '../../instance'
import { Location } from '../../../../models/Location'
import { getEndPoint, getStartPoint } from '../../edge-points'

class TrainLocationSuggester {
  constructor(state, element) {
    this.elements = getElements(state)
    this.state = state
    this.element = element
    this.elementIndex = findIndex(
      this.elements,
      (item) => item.id === element.id && item.type === element.type,
    )

    this.previousElement =
      this.elementIndex > 0 ? ElementFactory.create(this.elements[this.elementIndex - 1]) : null

    this.excludeTypes = [TRAVEL_RENTED_CAR_TRIP, TRAVEL_EMPTY, TRAVEL_ACCOMODATION_SUGGESTION]
  }

  suggestStartLocation() {
    let location = null

    if (this.elementIndex === -1 || !this.elementIndex) {
      location = get(getStartPoint(this.state), 'location.value', getInstanceLocation(this.state))
      location = {
        ...new Location(),
        formatted_address: `${location.city}`,
        country: get(location, 'country'),
        country_code: get(location, 'country_code'),
        city: get(location, 'city'),
        province: get(location, 'province'),
        address: get(location, 'address'),
        long: get(location, 'long'),
        lat: get(location, 'lat'),
        name: get(location, 'name'),
      }
      return location
    }

    if (includes(this.excludeTypes, this.previousElement.type) || this.previousElement.draft) {
      return new TrainLocationSuggester(this.state, this.previousElement).suggestStartLocation()
    } else {
      location = this.previousElement.getVirtualEndLocation()
    }

    location = {
      ...new Location(),
      formatted_address: `${location.city}`,
      country: get(location, 'country'),
      country_code: get(location, 'country_code'),
      city: get(location, 'city'),
      province: get(location, 'province'),
      address: get(location, 'address'),
      long: get(location, 'long'),
      lat: get(location, 'lat'),
      name: get(location, 'name'),
    }

    return location
  }

  findNearestTargetPoint(others = false) {
    const nearestPoints = this.elements

    if (others === false) {
      nearestPoints.filter((e) => e.type === TRAVEL_TARGET_POINT)
    }

    nearestPoints
      .map((item) => this.elements.indexOf(item))
      .sort((a, b) => Math.abs(this.elementIndex - a) - Math.abs(this.elementIndex - b))

    return { ...nearestPoints[this.elementIndex + 1] }
  }

  suggestEndLocation() {
    //if last element take end point date
    if (this.elementIndex === this.elements.length - 1) {
      const endLocation = this.getEndLocation()
      return {
        ...new Location(),
        formatted_address: get(endLocation, 'city'),
        country: get(endLocation, 'country'),
        country_code: get(endLocation, 'country_code'),
        city: get(endLocation, 'city'),
        province: get(endLocation, 'province'),
        address: get(endLocation, 'address'),
        long: get(endLocation, 'long'),
        lat: get(endLocation, 'lat'),
        name: get(endLocation, 'name'),
      }
    }

    const nearestTargetPoint = this.findNearestTargetPoint(true)

    if (nearestTargetPoint && !includes(this.excludeTypes, nearestTargetPoint.type)) {
      switch (nearestTargetPoint.type) {
        case TRAVEL_TRAIN_TRIP:
        case TRAVEL_PRIVATE_CAR_TRIP:
        case TRAVEL_COMPANY_CAR_TRIP:
        case TRAVEL_BUS_TRIP:
          nearestTargetPoint.location = nearestTargetPoint.departure_location
          break
        case TRAVEL_PLANE_TRIP:
          nearestTargetPoint.location = nearestTargetPoint.flight_from
          break
        default:
          break
      }
    }

    if (nearestTargetPoint) {
      return {
        ...new Location(),
        formatted_address: get(nearestTargetPoint, 'location.city'),
        country: get(nearestTargetPoint, 'location.country'),
        country_code: get(nearestTargetPoint, 'location.country_code'),
        city: get(nearestTargetPoint, 'location.city'),
        province: get(nearestTargetPoint, 'location.province'),
        address: get(nearestTargetPoint, 'location.address'),
        long: get(nearestTargetPoint, 'location.long'),
        lat: get(nearestTargetPoint, 'location.lat'),
        name: get(nearestTargetPoint, 'location.name'),
      }
    }

    const endLocation = this.getEndLocation()

    return {
      ...new Location(),
      formatted_address: get(endLocation, 'location.city'),
      country: get(endLocation, 'location.country'),
      country_code: get(endLocation, 'location.country_code'),
      city: get(endLocation, 'location.city'),
      province: get(endLocation, 'location.province'),
      address: get(endLocation, 'location.address'),
      long: get(endLocation, 'location.long'),
      lat: get(endLocation, 'location.lat'),
      name: get(endLocation, 'location.name'),
    }
  }

  getEndLocation = () => {
    const point = getEndPoint(this.state)
    return new Location(point.location.value)
  }
}

export { TrainLocationSuggester }
export default TrainLocationSuggester
