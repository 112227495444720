import React from 'react'
import { reduxForm, SubmissionError } from 'redux-form/immutable'
import { connect } from 'react-redux'
import { fromJS } from 'immutable'
import { Ferry as Form } from '../Forms'
import { bindActionCreators, compose } from 'redux'
import { processAPIerrorResponseToFormErrors } from '../../../../services/APIClient'
import { prepareRequestDates } from '../../../../utils/prepareRequestDates'
import { Factory as ElementFactory } from '../../../../models/timeline/index'
import { getFormValues } from '../../../../utils/forms'
import { DateSuggester } from '../../../../store/app/trip-timeline/services/date-suggester'
import { LocationSuggester } from '../../../../store/app/trip-timeline/services/location-suggester'
import { getCurrency } from '../../../../store/app/instance'
import { setEndOfDay } from '../../../../utils/setEndOfDay'

class Ferry extends React.Component<any, any> {
  render() {
    const { ...props } = this.props

    if (!this.props.initialized) {
      return null
    }

    return <Form {...props} />
  }
}

export const submit = (values, dispatch, props) => {
  const { onSave, request, change } = props

  values = prepareRequestDates(values)
  values.return_at = setEndOfDay(values.return_at, true)

  return onSave(request, values, props.element).then(
    () => {
      dispatch(change('isOpen', false))
    },
    (alerts) => {
      throw new SubmissionError(processAPIerrorResponseToFormErrors(alerts))
    },
  )
}

export const change = (values, dispatch, props) => {
  const { change } = props
  if (values.get('return_at') < values.get('departure_at')) {
    dispatch(change('return_at', values.get('departure_at')))
  }
}

const withForm = reduxForm({
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
  onSubmit: submit,
  onChange: change,
  destroyOnUnmount: false,
  touchOnBlur: false,
})

const mapStateToProps = (state, props) => {
  const { request, element, currencies } = props
  const ferry = ElementFactory.create(element)

  const dateSuggester = new DateSuggester(state, ferry)
  const locationSuggester = new LocationSuggester(state, ferry)

  const instanceCurrency = getCurrency(state)
  const currentValues = ElementFactory.create(getFormValues(ferry.key, state))

  return {
    initialValues: fromJS({
      uuid: ferry.uuid,
      departure_at: ferry.draft ? dateSuggester.suggestStartDate() : ferry.getStartDate(),
      return_at: ferry.draft ? dateSuggester.suggestEndDate() : ferry.getEndDate(),
      destination_location: ferry.draft
        ? locationSuggester.suggestEndLocation()
        : ferry.getEndLocation(),
      departure_location: ferry.draft
        ? locationSuggester.suggestStartLocation()
        : ferry.getStartLocation(),
      amount: ferry.amount,
      amount_currency: !ferry.draft ? ferry.amount_currency : instanceCurrency,
      converted_amount: ferry.converted_amount,
      calculated_amount_currency: ferry.calculated_amount_currency,
      round_trip: ferry.round_trip,
      id: ferry.id,
      type: ferry.type,
      isOpen: ferry.isOpen,
      draft: ferry.draft,
      virtual: ferry.virtual,
    }),
    form: ferry.key,
    request,
    ferry: currentValues,
    currencies,
    minDate: dateSuggester.suggestMinDate(),
    maxDate: dateSuggester.suggestMaxDate(),
    maxStartDate: dateSuggester.suggestMaxStartDate(),
    instanceCurrency,
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({}, dispatch)
}

const withConnect = connect(mapStateToProps, mapDispatchToProps)

Ferry = compose(withConnect, withForm)(Ferry)

export { Ferry }
export default { Ferry }
