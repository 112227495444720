import React from 'react'
import { Link } from 'react-router-dom'
import trans from '../trans'
import localStorage from '../services/localStorage'
import queryString from 'query-string'
import { fetchInstanceBeforeLogin, getInstance } from '../store/app/instance'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { bindActionCreators, compose } from 'redux'
import { getCurrentLang } from '../utils/lang'

class LoginLayout extends React.Component<any, any> {
  componentDidUpdate(prevProps, prevState) {
    const { location: prevLocation } = prevProps
    const { location } = this.props

    if (prevLocation.search !== location.search) {
      this.setLanguage(location)
    }
  }

  setLanguage(location) {
    const params = new URLSearchParams(location.search)
    const lang = params.get('lang')

    if (lang) {
      localStorage.set('login-page-lang', lang)
      this.props.fetchInstanceBeforeLogin(lang)
    }
  }

  render() {
    if (this.props.instance.sso_login && !this.props.noSso) {
      window.location.pathname = '/api/auth/sso'
      return null
    }

    const lang = getCurrentLang()

    const parsedQuery = queryString.parse(window.location.search)
    parsedQuery['lang'] = lang === 'pl' ? 'en' : 'pl'

    const newQuery = queryString.stringify(parsedQuery)
    const url = window.location.pathname + '?' + newQuery

    return (
      <div className='login'>
        <div className='login__background'>
          <a href={trans('login-page.privacy-policy-link')}>{trans('login-page.privacy-policy')}</a>
        </div>
        <div className='login-wrapper'>
          <div className='lang__selector'>
            {lang === 'pl' && (
              <Link to={url}>
                <span className='lang__selector-item lang__selector-item--active'>ENG</span>
              </Link>
            )}
            {lang === 'en' && (
              <Link to={url}>
                <span className='lang__selector-item lang__selector-item--active'>PL</span>
              </Link>
            )}
          </div>
          <div className='login__logo' />
          <div className='login__panel'>{this.props.children}</div>

          <div className='login-with'>
            <span className='login-with__title'>{trans('login-page.login-by')}</span>

            <div className='login-with__buttons'>
              <button className='login-button google'>
                <span className='icon google-icon' />
                <span className='text'>Google</span>
              </button>
              <button className='login-button azure'>
                <span className='icon azure-icon' />
                <span className='text'>Microsoft Azure</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      fetchInstanceBeforeLogin,
    },
    dispatch,
  )

const connected = connect(
  (state) => ({
    instance: getInstance(state),
  }),
  mapDispatchToProps,
)

LoginLayout = compose(connected, withRouter)(LoginLayout)

export default LoginLayout
export { LoginLayout }
