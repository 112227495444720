import React from 'react'
import Paper from '@material-ui/core/Paper'
import { trans } from '../../trans'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import Icon from '../ui/IconComponent'
import Button from '../ui/ButtonComponent'
import Dialog from '@material-ui/core/Dialog'
import Slide from '@material-ui/core/Slide'

function Transition(props) {
  return <Slide direction='up' {...props} />
}

const ConfirmAccommodationDialog = (props) => {
  return (
    <Dialog
      open={props.isOpen}
      TransitionComponent={Transition}
      onClose={props.onClose}
      aria-labelledby='alert-dialog-slide-title'
      aria-describedby='alert-dialog-slide-description'
      className='last-requests__dialog'
      PaperProps={{
        className: 'last-requests__dialog-paper',
      }}
    >
      <Paper square>
        <DialogContent className='accommodation-confirm__dialog-content'>
          {trans('deductions-widget.accommodation-confirmation-message')}
        </DialogContent>

        <DialogActions className='accommodation-confirm__dialog-actions'>
          <Icon className='last-requests__dialog-close' type='close' onClick={props.onClose} />
          <Button primary xxs onClick={props.onClose} color='primary'>
            {trans('deductions-widget.accept-accommodation')}
          </Button>
        </DialogActions>
      </Paper>
    </Dialog>
  )
}

export default ConfirmAccommodationDialog
