import { trans } from '../../../trans'
import React, { Fragment } from 'react'
import { connect } from '../../../containers/FlightsBooking'
import { get } from 'lodash'
import Dialog from '@material-ui/core/Dialog'
import Paper from '@material-ui/core/Paper'
import DialogContent from '@material-ui/core/DialogContent'
import { AutoSizer } from 'react-virtualized'
import DialogTitle from '@material-ui/core/DialogTitle/index'
import { Scrollbars } from 'react-custom-scrollbars-2'
import Icon from '../../ui/IconComponent'

class TariffConditionsComponent extends React.Component<any, any> {
  constructor(props) {
    super(props)
    this.state = {
      isModalOpen: false,
    }
  }

  openModal = (event) => {
    event.preventDefault()
    this.setState({ isModalOpen: true })
  }

  closeModal = () => {
    this.setState({ isModalOpen: false })
  }

  render() {
    const {
      flights: {
        selectors: { rules },
      },
      linkText,
    } = this.props

    const rulesType = get(rules, 'type', null)
    const content = get(rules, 'content', '')

    if (rulesType === 'link') {
      return (
        <a href={content} target='_blank'>
          {linkText ? linkText : trans('flights-booking.tariff-conditions')}
        </a>
      )
    }

    if (rulesType === 'text') {
      return (
        <Fragment>
          <a onClick={this.openModal} href='#' target='_blank'>
            {linkText ? linkText : trans('flights-booking.tariff-conditions')}
          </a>
          <Dialog
            open={this.state.isModalOpen}
            onClose={this.closeModal}
            aria-labelledby='alert-dialog-slide-title'
            aria-describedby='alert-dialog-slide-description'
            PaperProps={{
              className: 'trip-dialog tariff-conditions',
            }}
            scroll='paper'
          >
            <Paper square>
              <DialogTitle className='tariff-conditions__title'>
                <div className='trip-dialog__title'>
                  <div>{trans('flights-booking.tariff-conditions-popup-title')}</div>
                  <div>
                    <Icon className='trip-dialog__close' type='close' onClick={this.closeModal} />
                  </div>
                </div>
              </DialogTitle>
              <DialogContent>
                <AutoSizer>
                  {({ height, width }) => (
                    <Scrollbars style={{ width, height }}>
                      <div
                        className='tariff-conditions-content'
                        dangerouslySetInnerHTML={{ __html: content }}
                      />
                    </Scrollbars>
                  )}
                </AutoSizer>
              </DialogContent>
            </Paper>
          </Dialog>
        </Fragment>
      )
    }

    return null
  }
}

const TariffConditions = connect(TariffConditionsComponent)

export default TariffConditions
export { TariffConditions }
