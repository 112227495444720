import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import { MileageAllowanceCell } from '../../components/MileageAllowanceCell'
import { connect } from '../../containers/NonDelegation'

const MileageAllowanceCellNonDelegation = connect((props) => {
  const {
    nonDelegation: {
      selectors: { isDelegation },
    },
  } = props
  return (
    <MileageAllowanceCell
      width={props.width}
      request={props.request}
      push={props.push}
      getRouteByName={props.getRouteByName}
      isDelegation={isDelegation}
    />
  )
})

MileageAllowanceCellNonDelegation.propTypes = {}

export default MileageAllowanceCellNonDelegation
export { MileageAllowanceCellNonDelegation }
