import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { getSelectedOption } from '../../store/app/hotels-booking/selectors'
import { getSelectedOffer } from '../../store/app/trains-booking/selectors'
import { getDocuments } from '../../store/app/document-list'
import { Factory as ElementFactory } from '../../models/timeline/index'
import { Accommodation, Train } from '../../models/timeline'
import {
  BOOKING_SUCCESS,
  BOOKING_WITHOUT_TICKET,
  BOOKING_WAITING_FOR_CONFIRM,
  BOOKING_WAITING_FOR_TICKET,
  BOOKING_WAITING_FOR_VERIFY,
  BOOKING_NEW,
  BOOKING_YA_RE_CHECK,
} from '../../store/app/trains-booking'
import { get } from 'lodash'

class ReservationSettlementObserver extends Component<any, any> {
  constructor(props) {
    super(props)
    this.state = {
      elements: [],
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    return {
      elements: nextProps.elements.map((element) => ElementFactory.create(element)),
    }
  }

  isReservationPending = (status) => {
    return (
      [
        BOOKING_SUCCESS,
        BOOKING_WITHOUT_TICKET,
        BOOKING_WAITING_FOR_CONFIRM,
        BOOKING_WAITING_FOR_TICKET,
        BOOKING_NEW,
        BOOKING_WAITING_FOR_VERIFY,
        BOOKING_YA_RE_CHECK,
      ].indexOf(status) !== -1
    )
  }

  isThereAnyDocument = () => {
    const { getDocuments } = this.props
    return this.state.elements.find((element) => {
      return getDocuments(`${element.type}-${element.id}`).length > 0
    })
  }

  isThereAnyReservation = () => {
    const { getHotelOffer, getTrainOffer } = this.props

    return this.state.elements.find((element) => {
      const key = element.key
      let status = null

      if (element instanceof Train) {
        status = get(getTrainOffer(key), 'option.booking', null)
      }

      if (element instanceof Accommodation) {
        status = get(getHotelOffer(key), 'booking', null)
      }

      return this.isReservationPending(status)
    })
  }

  render() {
    const { children } = this.props

    if (this.isThereAnyDocument() || this.isThereAnyReservation()) {
      return null
    }

    return <Fragment>{children}</Fragment>
  }
}

ReservationSettlementObserver.propTypes = {
  elements: PropTypes.array.isRequired,
}

const mapStateToProps = (state) => ({
  getHotelOffer: (name) => getSelectedOption(name)(state),
  getTrainOffer: (name) => getSelectedOffer(name)(state),
  getDocuments: (name) => getDocuments(name)(state),
})

ReservationSettlementObserver = connect(mapStateToProps)(ReservationSettlementObserver)

export default ReservationSettlementObserver
export { ReservationSettlementObserver }
