import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import { values } from 'lodash'
import Rows from './Rows'

class Periods extends Component<any, any> {
  render() {
    const { periods, form, change, validate } = this.props
    return (
      <div className='declarations__body'>
        <div className='declarations__days'>
          {periods.map((rows, key) => (
            <div
              key={key}
              className={`declarations__day ${key % 2 !== 0 ? 'declarations__day--gray' : ''}`}
            >
              <Rows rows={rows} form={form} change={change} validate={validate} />
            </div>
          ))}
        </div>
      </div>
    )
  }
}

Periods.propTypes = {
  periods: PropTypes.array.isRequired,
  form: PropTypes.func.isRequired,
}

export default Periods
export { Periods }
