import React from 'react'
import { noop } from 'lodash/util'
import ToggleableInputWrapper from '../ToggleableFilters/ToggleableInputWrapper'
import trans from '../../trans'
import { DatePickerField } from '../ui/Form'
import {
  QUICK_FILTER_ASSIGNED,
  QUICK_FILTER_UNASSIGNED_TO_ACCOUNTANT,
} from '../../constants/request'
import ToggleableFilters from '../ToggleableFilters/ToggleableFilters'
import ToggleableQuickFilters from '../ToggleableFilters/ToggleableQuickFilters'
import ToggleableSearchField from '../ToggleableFilters/ToggleableSearchField'
import ToggleableQuickFiltersContainer from '../ToggleableFilters/ToggleableQuickFiltersContainer'
import { StateTextButton } from '../ui/StateTextButton'
import ToggleableBottomFilters from '../ToggleableFilters/ToggleableBottomFilters'

function StatementsPageFilters() {
  const onChangeFilter = noop
  const filters = {}
  const filtersCount = Object.keys(filters).length

  const bottomFilters = (
    <>
      <ToggleableInputWrapper label={trans('global.filter-period')}>
        <div className='filters__period-container'>
          <DatePickerField
            placeholder={trans('global.datepicker-placeholder')}
            onChange={(value) => onChangeFilter('period_start', value)}
            isClearable={true}
            value={filters['period_start']}
          />
          <span className='filters__separator'>-</span>
          <DatePickerField
            placeholder={trans('global.datepicker-placeholder')}
            onChange={(value) => onChangeFilter('period_end', value)}
            isClearable={true}
            minDate={filters['period_start']}
            value={filters['period_end']}
          />
        </div>
      </ToggleableInputWrapper>
    </>
  )

  return (
    <form>
      <ToggleableFilters filtersCount={filtersCount}>
        {({ isOpen }) => (
          <>
            <ToggleableQuickFilters>
              <ToggleableSearchField
                value={filters['phrase']}
                onChange={(value) => {
                  onChangeFilter('phrase', value)
                }}
              />

              <ToggleableQuickFiltersContainer>
                <StateTextButton
                  onClick={(activate) => {
                    onChangeFilter('quick_filters', activate ? 'mine' : null)
                  }}
                  active={filters['quick_filters'] === 'mine'}
                >
                  {trans('my-card.statement_quick_filter_mine')}
                </StateTextButton>

                <StateTextButton
                  onClick={(activate) => {
                    onChangeFilter('quick_filters', activate ? 'opened' : null)
                  }}
                  active={filters['quick_filters'] === 'opened'}
                >
                  {trans('my-card.statement_quick_filter_opened')}
                </StateTextButton>

                <StateTextButton
                  onClick={(activate) => {
                    onChangeFilter('quick_filters', activate ? 'closed' : null)
                  }}
                  active={filters['quick_filters'] === 'closed'}
                >
                  {trans('my-card.statement_quick_filter_closed')}
                </StateTextButton>
              </ToggleableQuickFiltersContainer>
            </ToggleableQuickFilters>

            <ToggleableBottomFilters isOpen={isOpen}>{bottomFilters}</ToggleableBottomFilters>
          </>
        )}
      </ToggleableFilters>
    </form>
  )
}

export default StatementsPageFilters
