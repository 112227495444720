import React from 'react'
import { Col, Row } from '../../ui/Grid'
import { trans } from '../../trans'
import Icon from '../ui/IconComponent'
import { summaryRules } from '../../containers/RequestSummaryManager/Rules'

class TimelineElementsCompliance extends React.Component<any, any> {
  render() {
    const {
      summaryRules: {
        selectors: { rules },
      },
    } = this.props

    if (!rules.length) {
      return null
    }

    return (
      <Row data-test='timeline-elements-compliance'>
        <Col xs={1} />
        <Col xs={11}>
          <div>
            <div className='request-summary-warnings'>
              <p className='request-summary-warnings__warning-header'>
                <Icon
                  type='warning'
                  className='request-summary-warnings__warning-icon is-gradient-warning'
                />
                {trans('request.compliance-message')}:
              </p>
              <ul>
                {rules.map((rule, key) => {
                  return (
                    <div key={`${rule.ruleName}-${key}`}>
                      - {trans(rule.message.name, rule.message.params)}
                    </div>
                  )
                })}
              </ul>
            </div>
          </div>
        </Col>
      </Row>
    )
  }
}

TimelineElementsCompliance = summaryRules()(TimelineElementsCompliance)

export default TimelineElementsCompliance
export { TimelineElementsCompliance }
