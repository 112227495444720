import React from 'react'
import PropTypes from 'prop-types'
import { get } from 'lodash'
import className from 'classnames'

class OperatorLogo extends React.Component<any, any> {
  render() {
    const { offer } = this.props

    let logo = get(offer, 'attributes.airlineLogoUrl', null)
    const code = get(offer, 'attributes.airlineCode', null)
    const flightType = get(offer, 'attributes.flightType', null)
    const multiAirlinesFlight = get(offer, 'attributes.multiAirlinesFlight', null)

    if (
      this.props.canRenderMultiLogo === true &&
      flightType === 'target' &&
      multiAirlinesFlight === true
    ) {
      logo = '/images/MULTI.png'
    }

    if (!logo) {
      return <div />
    }

    const classNames = className(
      {
        'plane-trip__dialog-ticket-logo': true,
      },
      this.props.className,
    )

    return <img src={logo} alt={code} className={classNames} />
  }
}

OperatorLogo.propTypes = {
  offer: PropTypes.object.isRequired,
  canRenderMultiLogo: PropTypes.bool,
}

export default OperatorLogo
export { OperatorLogo }
