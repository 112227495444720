import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Slider from 'react-slick'
import classNames from 'classnames'
import { get, toArray, includes } from 'lodash'

class Gallery extends Component<any, any> {
  constructor(props) {
    super(props)

    this.state = {
      photoIndex: 0,
    }

    this.list = React.createRef()
    this.slider = React.createRef()
    this.slidesToShow = 4
    this.blockSlideChange = false
    this.slides = null

    this.slickSettings = {
      dots: false,
      infinite: false,
      arrows: false,
      speed: 500,
      slidesToShow: this.slidesToShow,
      slidesToScroll: 1,
      vertical: true,
      verticalSwiping: true,
      beforeChange: () => {
        this.blockSlideChange = true
      },
      afterChange: () => {
        this.blockSlideChange = false
      },
    }
  }

  componentDidMount() {
    this.slides = toArray(this.list.current.querySelectorAll('.slick-slide'))
  }

  hasCurrentSlideClassActive() {
    const slideClassNames = toArray(this.slides[this.state.photoIndex].classList)

    return includes(slideClassNames, 'slick-active')
  }

  nextPhoto = (e) => {
    e.stopPropagation()

    if (this.blockSlideChange) return

    this.setState(
      {
        photoIndex: Math.min(this.state.photoIndex + 1, this.props.photos.length - 1),
      },
      () => {
        if (!this.hasCurrentSlideClassActive()) {
          this.slider.current.slickNext()
        }
      },
    )
  }

  prevPhoto = (e) => {
    e.stopPropagation()

    if (this.blockSlideChange) return

    this.setState(
      {
        photoIndex: Math.max(this.state.photoIndex - 1, 0),
      },
      () => {
        if (!this.hasCurrentSlideClassActive()) {
          this.slider.current.slickPrev()
        }
      },
    )
  }

  render() {
    return (
      <div className='selected-hotel__gallery'>
        <aside className='selected-hotel__aside-gallery' ref={this.list}>
          <Slider {...this.slickSettings} ref={this.slider}>
            {this.props.photos.map((photo, key) => {
              const classes = classNames({
                'selected-hotel__aside-gallery-image-wrapper': true,
                'selected-hotel__aside-gallery-image-wrapper--active':
                  this.state.photoIndex === key,
              })

              return (
                <div className={classes} key={key}>
                  <img
                    src={photo || '/images/no-images.png'}
                    className='selected-hotel__aside-gallery-image'
                    onClick={() => this.setState({ photoIndex: key })}
                    alt=''
                  />
                </div>
              )
            })}
          </Slider>
        </aside>

        <div className='selected-hotel__main-gallery'>
          {this.state.photoIndex > 0 && (
            <button
              className='selected-hotel__gallery-btn selected-hotel__gallery-btn--prev'
              type='button'
              onClick={this.prevPhoto}
            />
          )}

          <img
            className='selected-hotel__main-gallery-image'
            src={get(this.props.photos, this.state.photoIndex, '/images/no-images.png')}
            alt=''
          />

          {this.state.photoIndex < this.props.photos.length - 1 && (
            <button
              className='selected-hotel__gallery-btn selected-hotel__gallery-btn--next'
              type='button'
              onClick={this.nextPhoto}
            />
          )}
        </div>
      </div>
    )
  }
}

Gallery.propTypes = {
  photos: PropTypes.array.isRequired,
  showPhotosBelt: PropTypes.bool,
}

Gallery.defaultProps = {
  showPhotosBelt: true,
}

export default Gallery
export { Gallery }
