import { createSelector } from 'reselect'

const selectGlobal = (state) => state.get('global')
const selectRoute = (state) => state.get('route')

const makeSelectSession = () =>
  createSelector(selectGlobal, (selectGlobal) => selectGlobal.get('session'))
const makeSelectCurrentUser = () =>
  createSelector(selectGlobal, (globalState) => globalState.get('currentUser'))

const selectUserAbilities = () =>
  createSelector(selectGlobal, (globalState) => globalState.get('currentUser').abilities)

// const makeSelectCurrentInstance = () => createSelector(
//     selectGlobal,
//     (globalState) => globalState.get('currentInstance')
// );
//
const makeSelectLoading = () =>
  createSelector(selectGlobal, (globalState) => globalState.get('loading'))

const selectCurrentLocation = () =>
  createSelector(selectRoute, (routeState) => routeState.get('location'))

const selectInstanceDefaultCurrency = createSelector(
  selectGlobal,
  (globalState) => globalState.get('currentUser')['instance']['default_currency'],
)

export {
  selectGlobal,
  selectRoute,
  makeSelectSession,
  makeSelectCurrentUser,
  makeSelectLoading,
  selectCurrentLocation,
  selectUserAbilities,
  selectInstanceDefaultCurrency,
}
