import React from 'react'
import { SelectField } from '../../ui/Form'
import { trans } from '../../../trans'
import { connect } from '../../../containers/FlightsBooking'
import {
  SORT_BY_BEST_OFFER,
  SORT_BY_CHEAPEST_FIRST,
  SORT_BY_DEPARTURE_TIME,
  SORT_BY_TRAVEL_TIME,
} from '../../../store/app/flights-booking'

const Sort = connect((props) => {
  const {
    flights: {
      actions: { setSortBy },
      selectors: { sortBy },
    },
  } = props

  return (
    <div className='form-group form-group--label-top'>
      <span className='form-group__label'>{trans('flights-booking.sort-by')}</span>
      <div className='form-group__input-wrapper'>
        <SelectField
          options={[
            {
              value: SORT_BY_DEPARTURE_TIME,
              label: trans('flights-booking.sort-by-' + SORT_BY_DEPARTURE_TIME),
            },
            {
              value: SORT_BY_CHEAPEST_FIRST,
              label: trans('flights-booking.sort-by-' + SORT_BY_CHEAPEST_FIRST),
            },
            {
              value: SORT_BY_TRAVEL_TIME,
              label: trans('flights-booking.sort-by-' + SORT_BY_TRAVEL_TIME),
            },
            {
              value: SORT_BY_BEST_OFFER,
              label: trans('flights-booking.sort-by-' + SORT_BY_BEST_OFFER),
            },
          ]}
          onChange={(value) => setSortBy(value)}
          value={sortBy}
        />
      </div>
    </div>
  )
})

export default Sort
export { Sort }
