import PropTypes from 'prop-types'
import React from 'react'
import Icon from './ui/IconComponent'
import { Tooltip } from './Tooltip'
import { AmountFormatter } from './AmountFormatter'
import { get, groupBy, keys, has } from 'lodash'
import { Col } from '../ui/Grid'

class ForeignCurrenciesTooltip extends React.Component<any, any> {
  prepareCurrencies() {
    const { foreignCurrencies, instanceCurrency } = this.props
    const result = []

    if (!foreignCurrencies.length) {
      return result
    }

    const grouped = groupBy(foreignCurrencies, 'currency')

    if (keys(grouped).length === 1 && has(grouped, instanceCurrency)) {
      return result
    }

    for (let currency of Object.keys(grouped)) {
      let group = grouped[currency]

      let value = group.reduce((acc, next) => {
        return acc + parseFloat(next.value)
      }, 0)

      if (value > 0) {
        result.push({
          currency,
          value,
        })
      }
    }

    return result
  }

  renderTooltipContent(currencies) {
    return (
      <span style={{ textAlign: 'right', display: 'block' }}>
        {currencies.map((item, key) => (
          <span key={key}>
            <AmountFormatter amount={item.value} /> {item.currency}
            <br />
          </span>
        ))}
      </span>
    )
  }

  render() {
    const { instanceCurrency } = this.props
    const currencies = this.prepareCurrencies()

    if (!currencies.length) {
      return null
    }

    if (currencies.length === 1 && get(currencies[0], 'currency', null) === instanceCurrency) {
      return null
    }

    return (
      <Tooltip html={this.renderTooltipContent(currencies)}>
        <span className='foreign-currencies__icon--small'>
          <Icon type='fx_2' className='icon--type-fx' />
        </span>
      </Tooltip>
    )
  }
}

ForeignCurrenciesTooltip.propTypes = {
  foreignCurrencies: PropTypes.arrayOf(
    PropTypes.shape({
      currency: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    }),
  ),
  instanceCurrency: PropTypes.string,
}

export default ForeignCurrenciesTooltip
export { ForeignCurrenciesTooltip }
