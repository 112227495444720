import localStorage from '../services/localStorage'
import { get } from 'lodash'
import queryString from 'query-string'

export const getCurrentLang = () => {
  const { query } = queryString.parseUrl(window.location.href)
  return get(
    query,
    'lang',
    localStorage.get('login-page-lang') || document.documentElement.lang || 'pl',
  )
}
