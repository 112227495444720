// initial state
const getInitialState = () => {
  return {
    comments: {
      data: [],
    },
  }
}

// constants
export const RESET = 'comments::reset'
export const SET_COMMENTS = 'comments::set-comments'
export const MOUNT_POINT = 'comments'

// reducer
export const reducer = (state = getInitialState(), action) => {
  switch (action.type) {
    case RESET:
      return getInitialState()
    case SET_COMMENTS:
      return {
        ...state,
        comments: {
          ...state.comments,
          data: action.payload,
        },
      }
    default:
      return state
  }
}

// actions
export const reset = () => (dispatch) => {
  dispatch({
    type: RESET,
  })
}

export const setComments = (data) => (dispatch) => {
  dispatch({
    type: SET_COMMENTS,
    payload: data,
  })
}

// selectors
export const getState = (state) => {
  return state.get(MOUNT_POINT)
}

export const getCommentsData = (state) => {
  return getState(state).comments
}

export const getComments = (state) => {
  return getCommentsData(state).data
}
