import React from 'react'
import { Col } from '../../ui/Grid'
import { Loader } from '../ui/LoadingOverlay/Loader'
import { isFunction } from 'lodash'
import classNames from 'classnames'

class DocumentCellLoader extends React.Component<any, any> {
  componentDidMount() {
    const { appear } = this.props

    if (isFunction(appear)) {
      appear()
    }
  }

  render() {
    const { width, document, xs, sm, md, className } = this.props

    const documentPreviewUrl = document.name.split('.').pop() === 'pdf' ? '' : document.preview

    return (
      <Col xs={xs} sm={sm} md={md} style={{ display: 'flex', width }}>
        <div className={classNames('document-cell-outer', className)}>
          <div className='document-cell-inner has-loader'>
            <div
              className='document-cell__img-wrapper'
              style={{ backgroundImage: `url(${documentPreviewUrl})` }}
            />
            <Loader className='component-loader__loader--document' />
          </div>
        </div>
      </Col>
    )
  }
}

export { DocumentCellLoader }
export default { DocumentCellLoader }
