import { getRouteByName } from '../routes'

class Document {
  constructor(init, { type, slug } = {}) {
    const keys = Object.keys(init)
    keys.forEach((key) => {
      Object.defineProperty(this, key, {
        value: init[key],
      })
    })

    this.type = type
    this.slug = slug
  }

  isSettled() {
    return this.settled_at !== null
  }

  isAccounted() {
    return this.accounted_at !== null
  }

  getDocumentLink() {
    if (this.type && this.slug) {
      return getRouteByName('main', 'requestDocument', {
        type: this.type,
        request: this.slug,
        id: this.id,
      })
    }

    let link = window.location.href + '/documents/' + this.id
    link = link.replace(window.location.origin, '')

    if (!this.id) {
      return null
    }

    return link
  }

  getAccountDocumentLink() {
    if (this.type && this.slug && this.id) {
      return getRouteByName('main', 'requestAccountDocument', {
        type: this.type,
        request: this.slug,
        id: this.id,
      })
    }

    let link = window.location.href + '/documents/' + this.id + '/account'
    link = link.replace(window.location.origin, '')

    if (!this.id) {
      return null
    }

    return link
  }
}

export { Document }
export default Document
