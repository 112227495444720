import { getElements } from '../index'
import { findIndex, includes } from 'lodash'
import {
  TRAVEL_ACCOMODATION_SUGGESTION,
  TRAVEL_EMPTY,
  TRAVEL_ACCOMODATION,
  TRAVEL_PRIVATE_ACCOMODATION,
  TRAVEL_ACCOMMODATION_PROVIDED,
} from '../../../../constants/travel'
import {
  getEndPoint,
  getHighestElementDate,
  getLowestElementDate,
  getStartPoint,
} from '../../edge-points'
import { Factory as ElementFactory } from '../../../../models/timeline/index'

const EXCLUDED_TYPES = [TRAVEL_ACCOMODATION_SUGGESTION, TRAVEL_EMPTY]

class DateSuggester {
  constructor(state, element) {
    this.elements = getElements(state)

    this.state = state
    this.element = element

    if (this.element.virtual) {
      const real = this.findRealElement()
      if (real) {
        this.element = real
      }
    }

    this.elementIndex = findIndex(this.elements, (item) => {
      item = ElementFactory.create(item)
      return (
        item.id === this.element.id &&
        item.type === this.element.type &&
        item.virtual === this.element.virtual
      )
    })
    this.previousElement =
      this.elementIndex > 0 ? ElementFactory.create(this.elements[this.elementIndex - 1]) : null
    this.nextElement =
      this.elementIndex < this.elements.length - 1
        ? ElementFactory.create(this.elements[this.elementIndex + 1])
        : null
  }

  findRealElement() {
    return this.elements.find((e) => e.id === this.element.id && e.type === this.element.type)
  }

  suggestStartDate() {
    if (this.elementIndex === -1) {
      return undefined
    }

    if (this.elementIndex === 0) {
      return getLowestElementDate(this.state)
    }

    if (includes(EXCLUDED_TYPES, this.previousElement.type)) {
      return new DateSuggester(this.state, this.previousElement).suggestStartDate()
    }

    // VTL-2308: if previous element is hotel, return check-out (end) date
    if (
      this.previousElement.type === TRAVEL_ACCOMODATION ||
      this.previousElement.type === TRAVEL_PRIVATE_ACCOMODATION ||
      this.previousElement.type === TRAVEL_ACCOMMODATION_PROVIDED
    ) {
      return this.previousElement.getEndDate()
    }

    return this.previousElement.getDate()
  }

  suggestEndDate() {
    if (this.elementIndex === -1) {
      return undefined
    }

    if (this.elementIndex === this.elements.length - 1) {
      return getHighestElementDate(this.state)
    }

    if (includes(EXCLUDED_TYPES, this.nextElement.type)) {
      return new DateSuggester(this.state, this.nextElement).suggestEndDate()
    }

    return this.nextElement.getDate()
  }

  suggestMinDate() {
    if (this.elementIndex === -1) {
      return undefined
    }

    if (this.elementIndex === 0) {
      const point = getStartPoint(this.state)
      return point.date.saved ? point.date.value : undefined
    }

    if (includes(EXCLUDED_TYPES, this.previousElement.type)) {
      return new DateSuggester(this.state, this.previousElement).suggestMinDate()
    }

    return this.previousElement.getDate()
  }

  suggestMaxDate() {
    if (this.elementIndex === -1) {
      return undefined
    }

    const point = getEndPoint(this.state)
    return point.date.saved ? point.date.value : undefined
  }

  suggestMaxStartDate() {
    if (this.elementIndex === -1) {
      return undefined
    }

    if (this.elementIndex === this.elements.length - 1) {
      const point = getEndPoint(this.state)

      if (!point.date.saved) {
        return undefined
      }

      return getHighestElementDate(this.state)
    }

    if (includes(EXCLUDED_TYPES, this.nextElement.type)) {
      return new DateSuggester(this.state, this.nextElement).suggestMaxStartDate()
    }

    return this.nextElement.getDate()
  }
}

export { DateSuggester }
export default DateSuggester
