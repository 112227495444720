import React from 'react'
import { Field } from 'redux-form/immutable'
import { get } from 'lodash'
import { trans } from '../../../trans'
import { FormField } from '../../ui/Form/index'
import Icon from '../../ui/IconComponent'
import { TimeLineElementHeader } from '../../Timeline/index'
import { Tooltip } from '../../Tooltip'
import { countries } from '../../../store/app/countries'
import { config } from '../../../config'

class CrossingFormComponent extends React.Component<any, any> {
  constructor(props) {
    super(props)
    this.state = {
      isOpen: false,
      country_code: null,
    }
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside)
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside)
  }

  handleClickOutside = (event) => {
    if (this.state.isOpen && this.ref && !this.ref.contains(event.target)) {
      this.setState({ isOpen: false })
    }
  }

  getCountryCode = () => {
    const { data, countries } = this.props
    return get(
      countries.selectors.countries.find((country) => country.id === data.country_id),
      'country_code',
      null,
    )
  }

  getCountries = () => {
    const { countries } = this.props

    return countries.selectors.countries.map((country) => ({
      label: country.name,
      value: country.id,
    }))
  }

  shouldBeDisabled = () => {
    const { crossing, crossings } = this.props

    const filteredCrossings = crossings.selectors.crossings.filter(
      (c) => c.id !== 'trip-start' && c.id !== 'trip-end',
    )
    const isLast = filteredCrossings[filteredCrossings.length - 1].id === crossing.id
    const isPoland = this.getCountryCode() === 'PL'

    return isLast && isPoland
  }

  render() {
    const { crossing, crossings, dirty, countries } = this.props
    const style = { zIndex: this.state.isOpen ? 999 : 1 }

    return (
      <div
        ref={(ref) => {
          this.ref = ref
        }}
        className='timeline accordion'
        style={style}
        onMouseDown={() => {
          this.setState({ isOpen: true })
        }}
      >
        <TimeLineElementHeader
          /* isFlag */ countryCode={this.getCountryCode()}
          icon='pin_1'
          gradient='true'
        >
          <div className='border-crossings__timieline-item'>
            <div className='border-crossings__timieline-label-container'>
              <span className='border-crossings__timieline-item-label'>
                {trans('deductions-widget.border-crossing')}
              </span>

              {!dirty && crossing.suggested && (
                <Tooltip
                  html={
                    <span>{trans('deductions-widget.date-auto-suggested-tooltip-content')}</span>
                  }
                >
                  <Icon type='question_fill' className='border-crossings__timieline-item-icon' />
                </Tooltip>
              )}
            </div>

            <div className='border-crossings__timieline-inputs'>
              <div className='border-crossings__timieline-item-input-wrapper'>
                <Field
                  name='country_id'
                  type='select'
                  component={FormField}
                  options={this.getCountries()}
                  labeltop
                  onFocus={() => {
                    this.setState({ isOpen: true })
                  }}
                  placeholder={trans('border-crossing.select-country')}
                />
              </div>

              <div className='border-crossings__timieline-item-input-wrapper'>
                <Field
                  name='date'
                  type='datepicker'
                  component={FormField}
                  labeltop
                  showTimeSelect
                  timeFormat='HH:mm'
                  dateFormat={config.datePickerFieldFormat}
                  timeIntervals={15}
                  placeholder={trans('border-crossing.select-date')}
                />
              </div>

              <div className='border-crossings__timieline-item-checkbox-wrapper'>
                <Field
                  name='target'
                  type='checkbox'
                  component={FormField}
                  inputOnly
                  labeltop
                  disabled={this.shouldBeDisabled()}
                />
                <label className='border-crossings__timieline-item-checkbox-label'>
                  {trans('deductions-widget.destination-country')}
                </label>
                <Icon type='trash' lg onClick={() => crossings.actions.remove(crossing)} />
              </div>
            </div>
          </div>
        </TimeLineElementHeader>
      </div>
    )
  }
}

const CrossingForm = countries(false, false)(CrossingFormComponent)

export { CrossingForm }
export default CrossingForm
