import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import { fromJS } from 'immutable'
import { UserAnnotationForm as FormComponent } from '../../components/UserAnnotationForm'
import APIClient from '../../services/APIClient'
import { createAutoSaveForm, getFormValues } from '../../utils/forms'

export const FORM_NAME = 'request-user-annotation'

const UserAnnotationFormBase = createAutoSaveForm(FormComponent, {
  name: FORM_NAME,
  save: (name, value, dispatch, props) => {
    const { request } = props

    return new Promise((resolve, reject) => {
      APIClient.updateRequest(request.slug, { [name]: value })
        .then(() => {
          resolve()
        })
        .catch((response) => {
          reject(response['alerts'])
        })
    })
  },
})

UserAnnotationFormBase.propTypes = {
  request: PropTypes.object.isRequired,
}

const mapStateToProps = (state, props) => {
  const { request } = props

  return {
    initialValues: fromJS({
      annotation: request['annotation'],
    }),
    data: getFormValues(FORM_NAME, state),
  }
}

const UserAnnotationForm = connect(mapStateToProps)(UserAnnotationFormBase)

export { UserAnnotationForm }
export default { UserAnnotationForm }
