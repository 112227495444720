import React, { Component } from 'react'
import { DocumentCell } from '../../DocumentCell/index'
import documentAssign from '../../DocumentAssign'
import APIClient from '../../../services/APIClient'

class AssignableCell extends Component<any, any> {
  render() {
    return <DocumentCell {...this.props} />
  }
}

AssignableCell = documentAssign({
  onSelect: (document, selected) =>
    APIClient.saveDocument(document.id, { request_element: { ...selected.value } }),
})(AssignableCell)

export default AssignableCell
export { AssignableCell }
