import React from 'react'
import ReactDOM from 'react-dom/client'
import { Provider } from 'react-redux'
import { ConnectedRouter } from 'connected-react-router/immutable'
import initializeSentry from './sentry'
import App from './containers/App'
import store, { history } from './store'
import 'react-datepicker/dist/react-datepicker.css'
import 'react-tippy/dist/tippy.css'
import 'react-toastify/dist/ReactToastify.css'
import '../sass/app.scss'

initializeSentry()

window.__MUI_USE_NEXT_TYPOGRAPHY_VARIANTS__ = true

const root = ReactDOM.createRoot(document.getElementById('app'))
root.render(
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <App />
    </ConnectedRouter>
  </Provider>,
)
