import React from 'react'
import Dialog from '@material-ui/core/Dialog'
import Paper from '@material-ui/core/Paper'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import { Transition } from 'react-transition-group'
import trans from '../../trans'
import Icon from '../ui/IconComponent'
import Button from '../ui/ButtonComponent'
import PropTypes from 'prop-types'

export const ChronologyConfirmationDialog = ({ open, onClose }) => (
  <Dialog
    open={open}
    TransitionComponent={Transition}
    onClose={() => onClose(false)}
    aria-labelledby='alert-dialog-slide-title'
    aria-describedby='alert-dialog-slide-description'
    className='last-requests__dialog'
  >
    <Paper square>
      <DialogTitle id='alert-dialog-slide-title'>{trans('global.crossings-confirm')}</DialogTitle>

      <DialogContent className='last-requests__dialog-content'>
        {trans('global.crossings-confirm-text')}
      </DialogContent>

      <DialogActions className='accommodation-confirm__dialog-actions'>
        <Icon
          className='last-requests__dialog-close'
          type='close'
          onClick={(e) => onClose(e, false)}
        />
        <Button primary xxs onClick={(e) => onClose(e, true)} color='primary'>
          {trans('global.crossings-confirm-yes')}
        </Button>
        <Button outline xxs onClick={(e) => onClose(e, false)} color='primary'>
          {trans('global.crossings-confirm-no')}
        </Button>
      </DialogActions>
    </Paper>
  </Dialog>
)

ChronologyConfirmationDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
}

export default ChronologyConfirmationDialog
