import PropTypes from 'prop-types'
import React from 'react'
import classNames from 'classnames'

class PathItem extends React.Component<any, any> {
  render() {
    const { children, isActive } = this.props

    const classes = classNames({
      path__element: true,
      active: isActive,
    })

    return (
      <div className={classes}>
        <div className='path__element-content'>{children}</div>
      </div>
    )
  }
}

PathItem.propTypes = {
  isActive: PropTypes.bool.isRequired,
}

export { PathItem }
export default { PathItem }
