import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators, compose } from 'redux'
import store from '../../store'
import { getIsLoading, getRules, reset, setRules } from '../../store/app/model-rules'
import socketProvider from '../SocketProvider'

class ModelRulesManger extends React.Component<any, any> {
  constructor(props) {
    super(props)
  }

  componentDidMount() {
    const { reset, modelRules, setRules, socket, socketChannel, socketEvent } = this.props

    reset()
    setRules(modelRules)

    socket.subscribe(socketChannel)(socketEvent)((response) => {
      setRules(response.rules)
    })
  }

  componentWillUnmount() {
    const { socketChannel, socketEvent, socket } = this.props
    socket.unsubscribe(socketChannel)(socketEvent)
  }

  render() {
    const { children, rules, isLoading } = this.props
    const renderProps = {
      rules,
      isLoading,
    }

    return children(renderProps)
  }
}

const mapStateToProps = (state) => ({
  rules: getRules(state),
  isLoading: getIsLoading(state),
})

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      reset,
      setRules,
    },
    dispatch,
    store.getState,
  )
}

const withConnect = connect(mapStateToProps, mapDispatchToProps)
const withSocket = socketProvider()

ModelRulesManger = compose(withSocket, withConnect)(ModelRulesManger)

ModelRulesManger.propTypes = {
  modelRules: PropTypes.array.isRequired,
  socketChannel: PropTypes.string.isRequired,
  socketEvent: PropTypes.string.isRequired,
}

export { ModelRulesManger }
export default { ModelRulesManger }
