import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { reset, setComments, getComments } from '../../store/app/comments'
import { Map, List } from 'immutable'

class CommentsManager extends React.Component<any, any> {
  componentDidMount() {
    const { reset, comments, setComments } = this.props
    reset()

    let commentsData = comments

    if (commentsData instanceof Map || commentsData instanceof List) {
      commentsData = commentsData.toJS()
    }

    setComments(commentsData)
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.comments.length !== this.props.comments.length) {
      let commentsData = this.props.comments

      if (commentsData instanceof Map || commentsData instanceof List) {
        commentsData = commentsData.toJS()
      }

      this.props.setComments(commentsData)
    }
  }

  render() {
    const { children, commentsData } = this.props

    const renderProps = {
      comments: commentsData,
    }

    return children(renderProps)
  }
}

CommentsManager.propTypes = {
  comments: PropTypes.array.isRequired,
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      reset,
      setComments,
      getComments,
    },
    dispatch,
  )
}

const mapStateToProps = (state) => ({
  commentsData: getComments(state),
})

CommentsManager = connect(mapStateToProps, mapDispatchToProps)(CommentsManager)

export { CommentsManager }
export default CommentsManager
