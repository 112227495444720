import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import memoize from 'memoize-one'

const DEFAULT_COORDINATE = { lat: 52.237049, lng: 21.017532 } // Warsaw
const MAP_STROKE_COLOR = '#2dcee3'
const MAP_FILL_COLOR = '#2dcee3'
const MIN_RADIUS_KM = 1
const MIN_RADIUS = MIN_RADIUS_KM * 1000
const MAX_RADIUS_KM = 20
const MAX_RADIUS = MAX_RADIUS_KM * 1000

class Map extends Component<any, any> {
  constructor(props) {
    super(props)

    this.mapDomRef = React.createRef()
    this.map = null
    this.circle = null
    this.pin = null
  }

  shouldComponentUpdate(nextProps, nextState, context) {
    if (this.props.radius !== nextProps.radius) {
      return true
    }

    if (this.props.coordinates.lat !== nextProps.coordinates.lat) {
      return true
    }

    return this.props.coordinates.lng !== nextProps.coordinates.lng
  }

  componentDidMount() {
    const { coordinates } = this.props
    const center = coordinates.lat && coordinates.lng ? coordinates : DEFAULT_COORDINATE

    this.map = new google.maps.Map(this.mapDomRef.current, {
      zoom: 12,
      center: center,
      mapTypeId: 'terrain',
      fullscreenControl: false,
      zoomControlOptions: {
        style: google.maps.ZoomControlStyle.SMALL,
      },
    })

    this.drawCircle(this.props.radius, coordinates)
    this.drawPin(coordinates)
  }

  componentDidUpdate(prevProps, prevState, prevContext) {
    try {
      this.drawCircle(this.props.radius, this.props.coordinates)
      this.drawPin(this.props.coordinates)
    } catch (e) {
      this.resetMap()
    }
  }

  drawCircle = memoize((radius, coordinates) => {
    if (this.circle) {
      this.circle.setMap(null)
    }

    if (!coordinates || !coordinates.lat) {
      return
    }

    if (this.map) {
      this.map.setCenter(coordinates)

      this.circle = new google.maps.Circle({
        strokeColor: MAP_STROKE_COLOR,
        strokeOpacity: 1,
        strokeWeight: 2,
        fillColor: MAP_FILL_COLOR,
        fillOpacity: 0.25,
        map: this.map,
        center: { lat: coordinates.lat, lng: coordinates.lng },
        radius: radius * 1000,
        editable: true,
      })

      this.circle.addListener('radius_changed', this.resizeHandler)
      this.map.fitBounds(this.circle.getBounds())
    }
  })

  drawPin = memoize((coordinates) => {
    if (!coordinates || !coordinates.lat) {
      return
    }

    if (this.pin) {
      this.pin.setMap(null)
    }

    this.pin = new google.maps.Marker({
      position: { lat: coordinates.lat, lng: coordinates.lng },
      map: this.map,
    })
  })

  setRadiusManual = (radius) => {
    this.circle.setRadius(radius * 1000)
    this.props.onChange(radius)
  }

  resizeHandler = () => {
    const radius = this.circle.getRadius()
    const radiusKm = Math.round(this.circle.getRadius() / 1000)
    if (radius < MIN_RADIUS) {
      this.setRadiusManual(MIN_RADIUS_KM)
      return
    }
    if (radius > MAX_RADIUS) {
      this.setRadiusManual(MAX_RADIUS_KM)
      return
    }
    this.props.onChange(radiusKm)
  }

  resetMap = () => {
    this.pin.setMap(null)
    this.circle.setMap(null)
  }

  render() {
    return (
      <Fragment>
        <div ref={this.mapDomRef} className='accommodation__map' />
      </Fragment>
    )
  }
}

Map.propTypes = {
  coordinates: PropTypes.shape({
    lat: PropTypes.number,
    lng: PropTypes.number,
  }).isRequired,
  radius: PropTypes.number.isRequired,
}

export default Map
