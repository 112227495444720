import React from 'react'
import { bindActionCreators } from 'redux'
import { createDraft, reset } from '../../store/app/trip-request'
import { connect } from 'react-redux'
import { getRouteByName } from '../../routes'
import { withRouter } from 'react-router-dom'

export class TripRequestCreatePage extends React.Component<any, any> {
  async componentDidMount() {
    const {
      reset,
      createDraft,
      history: { replace },
    } = this.props

    reset()

    const request = await createDraft({ type: 'trip' })

    replace(getRouteByName('main', `tripRequestShow`, { id: request['slug'] }))
  }

  render() {
    return null
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      reset,
      createDraft,
    },
    dispatch,
  )
}

const route = withRouter(TripRequestCreatePage)

export default connect(null, mapDispatchToProps)(route)
