import React from 'react'
import { Tooltip } from '../Tooltip'
import { trans } from '../../trans'
import { get } from 'lodash'

const disableCell = () => (Component) => {
  return (props) => {
    if (!get(props, 'disabled', false)) {
      return <Component<any, any> {...props} />
    }

    return (
      <div className='mileage-allowance--disabled'>
        <Tooltip html={props.tooltipMessage || trans('global.disabled-mileage-allowance-message')}>
          <Component<any, any> {...props}> </Component>
        </Tooltip>
      </div>
    )
  }
}

export default disableCell
export { disableCell }
