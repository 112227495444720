import APIClient, { processAPIerrorResponseToFormErrors } from '../../../services/APIClient'
import { apiErrorAction } from '../../../containers/App/actions'
import {
  getNextPossibleStatus,
  getPreviousPossibleStatus,
  getValidationErrors,
} from '../../../utils'
import _ from 'lodash'
import * as constants from '../../../constants/request'
import trans from '../../../trans'
import AlertsController from '../../../services/alerts-controller'
import { setRequestTravelers } from '../request-traveler'
import uuid from '../../../utils/uuid'
import store from '../../../store'
import { stopSubmit } from 'redux-form'
import { FORM_NAME as BASIC_INFO_FORM_NAME } from '../../../containers/BasicInfoForm/BasicInfoForm'
import moment from 'moment'
import { sortByDate } from '../../../utils/sortByDate'
import { createSelector } from '@reduxjs/toolkit'
import {
  TRAVEL_ACCOMODATION,
  TRAVEL_PLANE_TRIP,
  TRAVEL_TRAIN_TRIP,
} from '../../../constants/travel'
import { getInstance } from '../instance'

// initial state
const getInitialState = () => {
  return {
    request: {
      isLoaded: false,
      isLoading: false,
      isUnrealizeInProgress: false,
      ws: {
        isWaitingForWebSocketEvent: false,
        lockUuid: null,
      },
      data: {},
    },
    currencies: {
      isLoaded: false,
      isLoading: false,
      data: [],
    },
  }
}

// constants
export const SET_REQUEST_DATA = 'trip-request::set-request-data'
export const SET_CURRENCIES_DATA = 'trip-request::set-currencies-data'
export const SET_UNREALIZED_IN_PROGRESS = 'trip-request::set-unrealiz-in-progress'
export const SET_ELEMENT_SEARCH_UUID = 'trip-request::set-element-search-uuid'
export const SET_IS_LOADING_IS_LOADED = 'trip-request::set-is-loading-is-loaded'
export const SET_WAITING_FOR_WEB_SOCKET_EVENT_START =
  'trip-request::set-waiting-for-web-socket-event-start'
export const SET_WAITING_FOR_WEB_SOCKET_EVENT_STOP =
  'trip-request::set-waiting-for-web-socket-event-stop'
export const SET_COMMENTS = 'trip-request::set-comments'
export const SET_LUMP_SUMS_DECLARATION = 'trip-request::set-lump-sums-declaration'
export const RESET = 'trip-request::reset'

export const MOUNT_POINT = 'trip-request'

// reducer
export const reducer = (state = getInitialState(), { type, payload }) => {
  switch (type) {
    case SET_LUMP_SUMS_DECLARATION: {
      return {
        ...state,
        request: {
          ...state.request,
          data: {
            ...state.request.data,
            lumpSumsDeclaration: payload,
          },
        },
      }
    }
    case SET_WAITING_FOR_WEB_SOCKET_EVENT_START: {
      return {
        ...state,
        request: {
          ...state.request,
          ws: {
            isWaitingForWebSocketEvent: true,
            lockUuid: payload.lockUuid,
          },
        },
      }
    }
    case SET_WAITING_FOR_WEB_SOCKET_EVENT_STOP: {
      return {
        ...state,
        request: {
          ...state.request,
          ws: {
            isWaitingForWebSocketEvent: false,
            lockUuid: null,
          },
        },
      }
    }
    case SET_UNREALIZED_IN_PROGRESS: {
      return {
        ...state,
        request: {
          ...state.request,
          isUnrealizeInProgress: payload,
        },
      }
    }
    case SET_ELEMENT_SEARCH_UUID: {
      const { id, search_uuid } = payload

      const elements = state.request.data.combinedTravelElements.map((e) => {
        if (e.id === id) {
          return {
            ...e,
            search_uuid,
          }
        }

        return e
      })

      return {
        ...state,
        request: {
          ...state.request,
          data: {
            ...state.request.data,
            combinedTravelElements: elements,
          },
        },
      }
    }
    case SET_REQUEST_DATA: {
      return {
        ...state,
        request: {
          ...state['request'],
          data: {
            ...payload,
            comments: (payload.comments || []).sort((prev, next) =>
              sortByDate(prev.created_at, next.created_at),
            ),
          },
        },
      }
    }
    case SET_CURRENCIES_DATA: {
      return {
        ...state,
        currencies: {
          ...state['currencies'],
          data: payload,
        },
      }
    }
    case SET_IS_LOADING_IS_LOADED: {
      const { key, isLoading, isLoaded } = payload

      return {
        ...state,
        [key]: {
          ...state[key],
          isLoaded,
          isLoading,
          data: state[key]['data'],
        },
      }
    }
    case SET_COMMENTS: {
      const { key, data } = payload

      return {
        ...state,
        [key]: {
          ...state[key],
          data: {
            ...state[key]['data'],
            comments: data,
          },
        },
      }
    }
    case RESET:
      return getInitialState()
    default:
      return state
  }
}

export const setIsWaitingForWebSocketEventStart = (lockUuid) => (dispatch) => {
  dispatch({
    type: SET_WAITING_FOR_WEB_SOCKET_EVENT_START,
    payload: { lockUuid },
  })
}

export const setIsWaitingForWebSocketEventStop = () => (dispatch) => {
  dispatch({
    type: SET_WAITING_FOR_WEB_SOCKET_EVENT_STOP,
    payload: {},
  })
}

export const setIsLoadingIsLoaded = (key, isLoading, isLoaded) => (dispatch) => {
  dispatch({
    type: SET_IS_LOADING_IS_LOADED,
    payload: {
      key,
      isLoading,
      isLoaded,
    },
  })
}

export const setUnrealizeInProgress = (data) => (dispatch) => {
  dispatch({
    type: SET_UNREALIZED_IN_PROGRESS,
    payload: data,
  })
}

export const setSearchUuidForElement = (element_id, search_uuid) => (dispatch) => {
  dispatch({
    type: SET_ELEMENT_SEARCH_UUID,
    payload: {
      id: element_id,
      search_uuid,
    },
  })
}

export const setRequestData = (data) => (dispatch) => {
  dispatch({
    type: SET_REQUEST_DATA,
    payload: data,
  })
}

export const setCurrenciesData = (data) => (dispatch) => {
  data = data.map((currency) => ({ value: currency.code, label: currency.code }))

  dispatch({
    type: SET_CURRENCIES_DATA,
    payload: data,
  })
}

export const setComments = (data) => (dispatch) => {
  dispatch({
    type: SET_COMMENTS,
    payload: {
      key: 'request',
      data,
    },
  })
}

export const lockWaitingForWebSocketEvent = (uuid) => (dispatch, getState) => {
  if (uuid) {
    dispatch(setIsWaitingForWebSocketEventStart(uuid))
    // Unlock anyway timeout if websocket failed on queue.
    setTimeout(() => {
      dispatch(tryToUnlockWaitingForWebSocketEvent(uuid))
    }, 15000)
  }
}

export const tryToUnlockWaitingForWebSocketEvent = (unlockUuid) => (dispatch, getState) => {
  const requestData = getRequestData(getState())

  if (
    requestData.ws.isWaitingForWebSocketEvent === true &&
    requestData.ws.lockUuid === unlockUuid
  ) {
    dispatch(setIsWaitingForWebSocketEventStop())
  }
}

export const setLumpSumsDeclaration = (lump) => (dispatch) => {
  dispatch({ type: SET_LUMP_SUMS_DECLARATION, payload: lump })
}

export const changeRequestValue = (path, value) => (dispatch, getState) => {
  const requestData = _.cloneDeep(getRequestData(getState()))
  _.set(requestData.data, path.join('.'), value)

  dispatch(setRequestData(requestData.data))
}

export const reset = () => (dispatch) => {
  dispatch({
    type: RESET,
  })
}

export const fetchComments = (slug) => (dispatch) => {
  return APIClient.getComments(slug).then((response) => {
    dispatch(setComments(response.data))
  })
}

export const fetchRequest =
  (slug, inBackground = false) =>
  (dispatch) => {
    return new Promise((resolve, reject) => {
      if (!inBackground) {
        dispatch(setIsLoadingIsLoaded('request', true, false))
      }

      APIClient.getTripRequest(slug)
        .then((response) => {
          dispatch(setRequestData(response.data))
          dispatch(setRequestTravelers(response.data.travelers || []))

          if (!inBackground) {
            dispatch(setIsLoadingIsLoaded('request', false, true))
          }
          resolve()
        })
        .catch((error, status) => {
          dispatch(apiErrorAction(status))
          if (!inBackground) {
            dispatch(setIsLoadingIsLoaded('request', false, false))
          }

          // Do not throw `reject(error)`
          // if there is no logic in the app for handling that
          resolve(error)
        })
    })
  }

export const fetchCurrencies = () => (dispatch) => {
  return new Promise((resolve) => {
    dispatch(setIsLoadingIsLoaded('currencies', true, false))

    APIClient.getCurrencies()
      .then((response) => {
        dispatch(setCurrenciesData(response.data))
        dispatch(setIsLoadingIsLoaded('currencies', false, true))
        resolve()
      })
      .catch((error, status) => {
        dispatch(apiErrorAction(status))
        dispatch(setIsLoadingIsLoaded('currencies', false, false))
      })
  })
}

export const sendToAcceptance =
  (request, fields = {}, showAlerts = true) =>
  (dispatch, getState) => {
    const requestData = getRequestData(getState())
    const comment = _.get(requestData, 'data.comment', {})
    const data = { comment, ...fields }

    return new Promise((resolve, reject) => {
      APIClient.sendToAcceptance(request, data, showAlerts)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }

export const sendToSettlement =
  (request, fields = {}) =>
  (dispatch, getState) => {
    const requestData = getRequestData(getState())
    const comment = _.get(requestData, 'data.comment', {})
    const data = { comment }

    return new Promise((resolve, reject) => {
      APIClient.requestSendToSettlement(request, data)
        .then(() => {
          resolve()
        })
        .catch((error) => {
          const errors = getValidationErrors(error.alerts)
          //this.props.setErrors(errors);
          reject(errors)
        })
    })
  }

export const sendToAcceptanceOfSettlement =
  (request, fields = {}) =>
  (dispatch, getState) => {
    const requestData = getRequestData(getState())
    const comment = _.get(requestData, 'data.comment', {})
    const data = { comment }

    return new Promise((resolve, reject) => {
      APIClient.requestSendToSettlementAcceptance(request, data)
        .then(() => {
          resolve()
        })
        .catch((response) => {
          const errors = processAPIerrorResponseToFormErrors(response.alerts, true)
          store.dispatch(stopSubmit(BASIC_INFO_FORM_NAME, errors))
          let rules = _.get(response, 'data.rules', [])

          if (rules) {
            rules = rules.map((rule) => ({
              type: rule.level,
              message: rule.message,
            }))
            AlertsController.displayAlerts(rules)
          }

          reject(response)
        })
        .catch((error) => {
          const errors = getValidationErrors(error.alerts, true)

          // Do not throw `reject(errors)` if there is no logic in app that will handle that
          resolve(errors)
        })
    })
  }

export const deleteRequest = (request) => (dispatch) => {
  return new Promise((resolve) => {
    APIClient.deleteRequest(request).then(() => {
      resolve()
    })
  })
}

export const cancelRequest =
  (request, fields = {}) =>
  (dispatch) => {
    return new Promise((resolve) => {
      APIClient.requestCancel(request, fields).then(() => {
        resolve()
      })
    })
  }

export const updateRequest =
  (request, fields = {}) =>
  (dispatch) => {
    return new Promise((resolve, reject) => {
      APIClient.updateRequest(request['slug'], fields)
        .then((response) => {
          dispatch(setRequestData(response.data))
          resolve()
        })
        .catch((error, status) => {
          dispatch(apiErrorAction(status))
          reject(error)
        })
    })
  }

export const saveDecision =
  (request, fields = {}) =>
  (dispatch) => {
    const prepareStatus = () => {
      switch (request['action']) {
        case '-1':
          return getPreviousPossibleStatus(this.props.data.status)
        case '0':
          return constants.STATUS_REJECTED
        case '1':
          return getNextPossibleStatus(this.props.data.status)
      }
    }
    const displayAlert = () => {
      AlertsController.displayAlerts([
        {
          type: 'success',
          message: trans('success.request-saved-as-draft'),
        },
      ])
    }
    const status = prepareStatus()

    if (_.isEmpty(status) && _.isEmpty(fields)) {
      return new Promise((resolve) => {
        displayAlert()
        resolve()
      })
    }

    return new Promise((resolve, reject) => {
      APIClient.updateRequest(request['slug'], {
        ...fields,
        status,
      })
        .then(() => {
          displayAlert()
          resolve()
        })
        .catch((error, status) => {
          dispatch(apiErrorAction(status))
          reject()
        })
    })
  }

export const createDraft = (data) => () => {
  return new Promise((resolve, reject) => {
    APIClient.createRequest({ type: data['type'], periodic: data['periodic'] })
      .then((response) => {
        resolve(response['data'])
      })
      .catch(() => {
        reject()
      })
  })
}

export const unrealizeTrip = (slug) => (dispatch) => {
  dispatch(setUnrealizeInProgress(true))

  return new Promise((resolve, reject) => {
    APIClient.unrealizeTrip(slug)
      .then(async (response) => {
        await dispatch(fetchRequest(slug, false))

        dispatch(setUnrealizeInProgress(false))
        resolve()
      })
      .catch(() => {
        dispatch(setUnrealizeInProgress(false))

        reject()
      })
  })
}

// selectors
export const getState = (state) => state.get(MOUNT_POINT)
export const getRequestData = (state) => getState(state).request
export const getRequest = (state) => getRequestData(state).data
export const getCurrenciesData = (state) => getState(state).currencies
export const getCurrencies = (state) => getState(state).currencies.data
export const areCurrenciesLoading = (state) => getState(state).currencies.isLoading
export const getRequestAbilities = createSelector(getRequest, (request) => request.abilities)

export const hasRequestAbility = (ability) => (state) => {
  const abilities = getRequestAbilities(state)

  return abilities[ability]
}

export const isFlightSearchEnabled = (state) => {
  return hasRequestAbility('searchEnginePlane')(state)
}

export const isHotelSearchEnabled = (state) => {
  return hasRequestAbility('searchEngineAccommodation')(state)
}

export const isTrainSearchEnabled = (state) => {
  return hasRequestAbility('searchEngineTrain')(state)
}

export const getSearchEnabledByType = (state) => (type) => {
  if (type === TRAVEL_PLANE_TRIP) {
    return isFlightSearchEnabled(state)
  }

  if (type === TRAVEL_ACCOMODATION) {
    return isHotelSearchEnabled(state)
  }

  if (type === TRAVEL_TRAIN_TRIP) {
    return isTrainSearchEnabled(state)
  }

  return true
}
