import PropTypes from 'prop-types'
import React, { Fragment } from 'react'
import { RouteManager } from '../../../containers/RouteManager/index'
import { ExpenseRequestManager } from '../../../containers/ExpenseRequestManager/index'
import { ExpenseRequestAccountingPage } from '../../ExpenseRequestAccountingPage/index'

class TripRequestPageAccounting extends React.Component<any, any> {
  render() {
    const { request, currentUser, openPopup, closePopup } = this.props

    return (
      <RouteManager>
        {({ push, getRouteByName }) => (
          <Fragment>
            <ExpenseRequestManager expenseRequest={request} currentUser={currentUser}>
              {({
                expenseRequest,
                isExpenseRequestLoaded,
                instanceCurrency,
                currentUser,
                unrealizeTrip,
              }) => (
                <ExpenseRequestAccountingPage
                  expenseRequest={expenseRequest}
                  isLoaded={isExpenseRequestLoaded}
                  push={push}
                  getRouteByName={getRouteByName}
                  instanceCurrency={instanceCurrency}
                  currentUser={currentUser}
                  isTrip={true}
                  unrealizeTrip={() =>
                    unrealizeTrip(request.slug).then(() => {
                      push(getRouteByName('main', 'tripRequestsList'))
                    })
                  }
                  isModuleActive={this.props.isModuleActive}
                  openPopup={openPopup}
                  closePopup={closePopup}
                  changeRequestValue={this.props.changeRequestValue}
                />
              )}
            </ExpenseRequestManager>
          </Fragment>
        )}
      </RouteManager>
    )
  }
}

TripRequestPageAccounting.propTypes = {
  request: PropTypes.object.isRequired,
  currentUser: PropTypes.object.isRequired,
  isModuleActive: PropTypes.func.isRequired,
}

export { TripRequestPageAccounting }
export default { TripRequestPageAccounting }
