import React from 'react'
import { AgGridReact } from 'ag-grid-react'
import { useGridOptions } from './hooks/useGridOptions'
import Pagination from '../SettingsPage/CompanyPage/Pagination/Pagination'

function AccountsPaymentsPageTable({ data, paginator, setPage }) {
  const gridOptions = useGridOptions()

  return (
    <>
      <div className={'ag-theme-alpine'}>
        <AgGridReact gridOptions={gridOptions} rowData={data} rowHeight={58} headerHeight={40} />
      </div>

      <Pagination
        page={paginator.page}
        total={paginator.total}
        perPage={paginator.per_page}
        changePage={(page) => setPage(page)}
      />
    </>
  )
}

export default AccountsPaymentsPageTable
