import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { connect } from '../../../containers/FlightsBooking/connect'
import ItemDetails from './ItemDetails'
import Button from '../../ui/ButtonComponent'
import { trans } from '../../../trans'

class ReturnItemsComponent extends Component<any, any> {
  render() {
    const classes = classNames({
      'plane-trip__return-offers': true,
      'plane-trip__return-offers--visible': this.props.visible,
    })

    const {
      flights: {
        selectors: { returnFlights, isRoundTrip },
        actions: { selectOffer },
      },
      offer,
    } = this.props

    const returnOffers = returnFlights(offer)

    return (
      <div className={classes}>
        <div className='plane-trip__return-offers-heading'>
          <div className='plane-trip__return-offers-heading-text'>
            {trans('flights-booking.suggest')}
          </div>
        </div>
        {returnOffers.map((offer) => {
          return (
            <ItemDetails key={offer.uuid} offer={offer} isRoundTrip={isRoundTrip} isReturn={true}>
              <Button primary xxs onClick={() => selectOffer(offer)}>
                {trans('flights-booking.select-offer')}
              </Button>
            </ItemDetails>
          )
        })}
      </div>
    )
  }
}

ReturnItemsComponent.propTypes = {
  offer: PropTypes.object.isRequired,
  visible: PropTypes.bool.isRequired,
}

const ReturnItems = connect(ReturnItemsComponent)

export default ReturnItems
export { ReturnItems }
