import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import { get } from 'lodash'
import { trans } from '../../../trans'

const PriceInfo = (props) => {
  const { offer } = props

  const tariffSlug = get(offer, 'attributes.tariffSlug', null)

  if (tariffSlug !== 'LOW') {
    return null
  }

  return (
    <div className='plane-trip__offer-row-description'>
      <div className='plane-trip__offer-price-info is-font-xsmall'>
        {trans('flights-booking.price-may-change')}
      </div>
    </div>
  )
}

PriceInfo.propTypes = {
  offer: PropTypes.object.isRequired,
}

export default PriceInfo
export { PriceInfo }
