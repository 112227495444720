import React from 'react'
import { noop } from 'lodash/util'
import ToggleableInputWrapper from '../ToggleableFilters/ToggleableInputWrapper'
import trans from '../../trans'
import { DatePickerField, SelectField } from '../ui/Form'
import {
  getStatusesForSelect,
  QUICK_FILTER_ASSIGNED,
  QUICK_FILTER_ASSIGNED_TO_ME,
  QUICK_FILTER_UNASSIGNED_TO_ACCOUNTANT,
} from '../../constants/request'
import UserField from '../../form/UserField/UserField'
import { loadOptionsForUsers } from '../../store/app/users-for-select'
import { AsyncSelectField } from '../ui/Form/AsyncSelectField'
import { components } from '../../utils/react-select'
import UserSelectOption from '../../form/UserField/UserFieldOption'
import ToggleableFilters from '../ToggleableFilters/ToggleableFilters'
import ToggleableQuickFilters from '../ToggleableFilters/ToggleableQuickFilters'
import ToggleableSearchField from '../ToggleableFilters/ToggleableSearchField'
import ToggleableQuickFiltersContainer from '../ToggleableFilters/ToggleableQuickFiltersContainer'
import { StateTextButton } from '../ui/StateTextButton'
import ToggleableBottomFilters from '../ToggleableFilters/ToggleableBottomFilters'

function PaymentsPageFilters() {
  const onChangeFilter = noop
  const filters = {}
  const filtersCount = Object.keys(filters).length

  const bottomFilters = (
    <>
      <ToggleableInputWrapper label={trans('global.filter-period')}>
        <div className='filters__period-container'>
          <DatePickerField
            placeholder={trans('global.datepicker-placeholder')}
            onChange={(value) => onChangeFilter('period_start', value)}
            isClearable={true}
            value={filters['period_start']}
          />
          <span className='filters__separator'>-</span>
          <DatePickerField
            placeholder={trans('global.datepicker-placeholder')}
            onChange={(value) => onChangeFilter('period_end', value)}
            isClearable={true}
            minDate={filters['period_start']}
            value={filters['period_end']}
          />
        </div>
      </ToggleableInputWrapper>
    </>
  )

  return (
    <form>
      <ToggleableFilters filtersCount={filtersCount}>
        {({ isOpen }) => (
          <>
            <ToggleableQuickFilters>
              <ToggleableSearchField
                value={filters['phrase']}
                onChange={(value) => {
                  onChangeFilter('phrase', value)
                }}
              />

              <ToggleableQuickFiltersContainer>
                <StateTextButton
                  onClick={(activate) => {
                    onChangeFilter('quick_filters', activate ? QUICK_FILTER_ASSIGNED : null)
                  }}
                  active={filters['quick_filters'] === QUICK_FILTER_ASSIGNED}
                >
                  {trans('global.assigned')}
                </StateTextButton>

                <StateTextButton
                  onClick={(activate) => {
                    onChangeFilter(
                      'quick_filters',
                      activate ? QUICK_FILTER_UNASSIGNED_TO_ACCOUNTANT : null,
                    )
                  }}
                  active={filters['quick_filters'] === QUICK_FILTER_UNASSIGNED_TO_ACCOUNTANT}
                >
                  {trans('global.unassigned-settlements')}
                </StateTextButton>
              </ToggleableQuickFiltersContainer>
            </ToggleableQuickFilters>

            <ToggleableBottomFilters isOpen={isOpen}>{bottomFilters}</ToggleableBottomFilters>
          </>
        )}
      </ToggleableFilters>
    </form>
  )
}

export default PaymentsPageFilters
