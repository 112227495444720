import { BreadCrumbs } from '../../containers'
import { RouteManager } from '../../containers/RouteManager'
import { getRouteByName } from '../../routes'
import trans from '../../trans'
import { Section } from '../ui/Section'
import { SectionHeader } from '../ui/SectionHeader'
import React from 'react'
import { Link, useParams } from 'react-router-dom'
import useAccountStatements from './hooks/useAccountStatements'
import { LoadingOverlay } from '../ui/LoadingOverlay'
import AccountsStatementsPageTable from '../AccountStatementsPageTable'
import AccountStatementsPageFilters from '../AccountStatementsPageFilters'

function AccountStatementsPage() {
  const { data, paginator, loading, setPage } = useAccountStatements()
  const params = useParams()

  return (
    <div>
      <BreadCrumbs>
        <Link to={getRouteByName('main', 'dashboard')}>{trans('global.dashboard')}</Link>
        <Link to={getRouteByName('main', 'my-card-accounts')}>
          {trans('main-menu.my-card-accounts')}
        </Link>
        <Link to={getRouteByName('main', 'my-card-accounts-statements', params)}>
          {trans('statements.statements_page_title')}
        </Link>
      </BreadCrumbs>

      <Section className={'my-cards account-page'} noBorder>
        <SectionHeader
          className={'my-cards__header'}
          caption={`${trans('statements.statements_page_title')}`}
        ></SectionHeader>

        <RouteManager>
          {({ getRouteByName, push }) => (
            <div>
              <AccountStatementsPageFilters />
              {loading && <LoadingOverlay />}
              {!loading && (
                <AccountsStatementsPageTable data={data} paginator={paginator} setPage={setPage} />
              )}
            </div>
          )}
        </RouteManager>
      </Section>
    </div>
  )
}

export default AccountStatementsPage
