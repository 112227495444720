import moment from 'moment'
import { Request } from './Request'

class FlightsRequest extends Request {
  static minutesToHour(minutes) {
    if (minutes > 1439) {
      minutes = 1439
    }

    return moment().hour(0).minute(0).add(minutes, 'm').format('HH:mm')
  }

  static stringBoolToNumber(bool) {
    return bool ? 1 : 0
  }

  static arrayToString(array) {
    return Array.isArray(array) ? array.join(',') : array
  }
}

export default FlightsRequest
export { FlightsRequest }
