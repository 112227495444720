import React from 'react'
import StarsRating from '../../StarsRating'

const StarsRatingField = (props) => {
  const { input, stars } = props
  return <StarsRating stars={stars} rate={parseInt(input.value)} onChange={input.onChange} />
}

export default StarsRatingField
export { StarsRatingField }
