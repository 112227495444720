import React from 'react'
import PropTypes from 'prop-types'
import Icon from '../ui/IconComponent'
import Avatar from '../Avatar/Avatar'
import trans from '../../trans'
import { FormGroup } from '../../ui'
import Paper from '@material-ui/core/Paper'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import Button from '../ui/ButtonComponent'
import Transition from 'react-transition-group/Transition'

export class RequestTravelerList extends React.Component<any, any> {
  state = {
    confirmModalOpen: false,
    traveler: null,
  }

  openModal(traveler) {
    this.setState({
      confirmModalOpen: true,
      traveler,
    })
  }

  closeModal() {
    this.setState({
      confirmModalOpen: false,
      traveler: null,
    })
  }

  async removeTraveler() {
    const { onRemove } = this.props
    const { traveler } = this.state

    await onRemove(traveler)

    this.closeModal()
  }

  renderTraveler(traveler, disabled, onRemove) {
    return (
      <div key={traveler.slug} className='request-traveler-item'>
        <div className='request-traveler-avatar'>
          <Avatar user={traveler.user} />
        </div>

        <div className='request-traveler-name'>
          <strong>{traveler.user.full_name}</strong>
          <br />
          <span className='request-traveler-email'>{traveler.user.email.toLowerCase()}</span>
        </div>

        {!traveler.is_lead && !disabled && (
          <div className='request-traveler-remove'>
            <Icon type='trash' gradient onClick={() => this.openModal(traveler)} />
          </div>
        )}
      </div>
    )
  }

  render() {
    const { travelers, disabled, onRemove } = this.props

    return (
      <FormGroup labeltop label={trans('trip-request.trip-traveler-group-label')}>
        <div className='request-traveler-list'>
          {travelers.map((traveler) => this.renderTraveler(traveler, disabled, onRemove))}
        </div>

        <Dialog
          open={this.state.confirmModalOpen}
          TransitionComponent={Transition}
          onClose={() => this.closeModal()}
          aria-labelledby='alert-dialog-slide-title'
          aria-describedby='alert-dialog-slide-description'
          className='last-requests__dialog'
        >
          <Paper square>
            <DialogTitle id='alert-dialog-slide-title'>
              {trans('global.finish-trip-confirm')}
            </DialogTitle>

            <DialogContent className='last-requests__dialog-content'>
              {trans('trip-request.trip-traveler-remove-text')}
            </DialogContent>

            <DialogActions className='accommodation-confirm__dialog-actions'>
              <Icon
                className='last-requests__dialog-close'
                type='close'
                onClick={() => this.closeModal()}
              />
              <Button primary xxs color='primary' onClick={() => this.removeTraveler()}>
                {trans('trip-request.trip-traveler-remove-btn-ok')}
              </Button>
              <Button outline xxs onClick={() => this.closeModal()} color='primary'>
                {trans('trip-request.trip-traveler-remove-btn-cancel')}
              </Button>
            </DialogActions>
          </Paper>
        </Dialog>
      </FormGroup>
    )
  }
}

RequestTravelerList.propTypes = {
  travelers: PropTypes.arrayOf(
    PropTypes.shape({
      is_lead: PropTypes.bool.isRequired,
      slug: PropTypes.string.isRequired,
    }),
  ),
  disabled: PropTypes.bool,
}
