import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import { trans } from '../../trans'
import { has } from 'lodash'

class Availability extends Component<any, any> {
  getAvailability = () => {
    const { offer } = this.props
    const option = offer.option

    const availability = {
      no_information_from_provider: {
        type: 'no-info',
        message: trans('trains-booking.no_information_from_provider'),
      },
      high_availability: {
        type: 'high',
        message: trans('trains-booking.high_availability'),
      },
      limited_availability: {
        type: 'limited',
        message: trans('trains-booking.limited_availability'),
      },
      no_guarantee_of_a_place_to_sit: {
        type: 'no-guarantee',
        message: trans('trains-booking.no_guarantee_of_a_place_to_sit'),
      },
      no_place_to_sit: {
        type: 'no-place',
        message: trans('trains-booking.no_place_to_sit'),
      },
      unknown_availability: {
        type: 'no-info',
        message: trans('trains-booking.unknown_availability'),
      },
    }

    if (has(availability, option.attributes.availability)) {
      return availability[option.attributes.availability]
    }

    if (option.attributes.salable !== 'Y') {
      return {
        type: 'no-place',
        message: trans('trains-booking.no-seats'),
      }
    }

    return null
  }

  render() {
    const availability = this.getAvailability()

    if (!availability) return null

    const badgeClassName = [
      'train-trip__dialog-ticket-availability-badge',
      `train-trip__dialog-ticket-availability-badge--${availability.type}`,
    ].join(' ')

    return (
      <div className='train-trip__dialog-ticket-availability'>
        <span className='train-trip__dialog-ticket-availability-heading'>
          {trans('trains-booking.seats-availability')}
        </span>
        <span className={badgeClassName}>{availability.message}</span>
      </div>
    )
  }
}

Availability.propTypes = {}

export default Availability
export { Availability }
