import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { get } from 'lodash'
import Icon from '../../ui/IconComponent'

const Relation = (props) => {
  const { offer, flight_from, flight_to, isReturn } = props

  const arrivalAirport = get(offer, 'attributes.arrivalAirport', null)
  const departureAirport = get(offer, 'attributes.departureAirport', null)

  const getCity = (obj) => (obj ? obj.city : null)
  const flightToCity = isReturn ? getCity(flight_to) : getCity(flight_from)
  const flightFromCity = isReturn ? getCity(flight_from) : getCity(flight_to)

  return (
    <Fragment>
      <div className='plane-offer__flight-trip-cities'>
        <div className='plane-offer__flight-trip-cities-left'>
          <div className='plane-offer__flight-trip-airport'>{departureAirport}</div>
          <div className='plane-offer__flight-trip-city'>{flightToCity}</div>
        </div>
        <div className='plane-offer__flight-trip-cities-center'>
          <span className='plane-offer__flight-trip-icon'>
            <Icon type='plane' />
          </span>
        </div>
        <div className='plane-offer__flight-trip-cities-right'>
          <div className='plane-offer__flight-trip-airport'>{arrivalAirport}</div>
          <div className='plane-offer__flight-trip-city'>{flightFromCity}</div>
        </div>
      </div>
    </Fragment>
  )
}

Relation.propTypes = {
  offer: PropTypes.object.isRequired,
}

export default Relation
export { Relation }
