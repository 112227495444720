import PropTypes from 'prop-types'
import React from 'react'
import { SelectField, DatePickerField } from '../ui/Form'
import trans from '../../trans'
import Icon from '../../components/ui/IconComponent'
import { loadOptionsForUsers } from '../../store/app/users-for-select'
import { AsyncSelectField } from '../ui/Form/AsyncSelectField'
import { components } from '../../utils/react-select'
import UserSelectOption from '../../form/UserField/UserFieldOption'
import UserField from '../../form/UserField/UserField'

class AgentTripRequestsListFilters extends React.Component<any, any> {
  constructor(props) {
    super(props)

    this.timer = null

    this.state = {
      window: null,
      users: (props.filters || {}).users,
    }

    this.changeUsers = this.changeUsers.bind(this)
  }

  changeUsers(value) {
    this.setState({ users: value })
    this.props.onChangeOwner(value)
  }

  render() {
    const { filters, onChangeFilter } = this.props
    const { users } = this.state

    return (
      <form>
        <div className='agent-filters filters__top'>
          <div className='input-group'>
            <Icon type='search' lg />
            <input
              type='text'
              placeholder={trans('ui.search')}
              className='input-group__input'
              ref={(e) => (this.searchInput = e)}
              defaultValue={filters['phrase']}
              onChange={() => {
                clearTimeout(this.timer)
                this.timer = setTimeout(() => {
                  onChangeFilter('phrase', this.searchInput.value)
                }, 250)
              }}
            />
          </div>

          <div className='filters__input-wrapper'>
            <span className='filters__input-title'>{trans('global.filter-owner')}</span>

            <UserField value={users} onChange={this.changeUsers} loadItems={loadOptionsForUsers} />
          </div>

          <div className='filters__input-wrapper'>
            <span className='filters__input-title'>{trans('global.filter-period')}</span>
            <div className='filters__period-container'>
              <DatePickerField
                placeholder={trans('global.datepicker-placeholder')}
                onChange={(value) => onChangeFilter('period_start', value)}
                isClearable={true}
                value={filters['period_start']}
              />
              <span className='filters__separator'>-</span>
              <DatePickerField
                placeholder={trans('global.datepicker-placeholder')}
                onChange={(value) => onChangeFilter('period_end', value)}
                isClearable={true}
                minDate={filters['period_start']}
                value={filters['period_end']}
              />
            </div>
          </div>
        </div>
      </form>
    )
  }
}

AgentTripRequestsListFilters.propTypes = {
  filters: PropTypes.object.isRequired,
  onChangeFilter: PropTypes.func.isRequired,
  onChangeOwner: PropTypes.func.isRequired,
}

export default AgentTripRequestsListFilters
export { AgentTripRequestsListFilters }
