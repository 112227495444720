import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import { fromJS } from 'immutable'
import { bindActionCreators } from 'redux'
import { createAutoSaveForm, getFormValues } from '../../utils/forms'
import APIClient from '../../services/APIClient'
import { AccountAllowanceForm as FormComponent } from '../../components/AccountAllowanceForm'
import { getCache, setCache } from '../../store/app/account-dimensions'
import {
  generateAccountDimensionFormValues,
  submitAccountDimension,
} from '../../store/app/account-dimensions/form'
import {
  getRequest,
  removeAccountingMileageAllowance,
  saveAccountingMileageAllowance,
} from '../../store/app/request-mileage-allowance'
import useMeansOfTransports from '../../components/MeansOfTransports/MeansOfTransports'

const AccountAllowanceFormPure = createAutoSaveForm(FormComponent, {
  save: (name, value, dispatch, props) => {
    const {
      slug,
      accountDimensionItems,
      accountDimensions,
      setCache,
      saveRequestMeansOfTransportsOptions,
    } = props

    if (name === 'means_of_transports') {
      return saveRequestMeansOfTransportsOptions(value)
    }

    return submitAccountDimension({
      name,
      value,
      setCache,
      dimensions: accountDimensions,
      items: accountDimensionItems,
      updateMethod: (dim_id) => APIClient.updateAllowanceAccountDimension(slug, dim_id, value.id),
      deleteMethod: (dim_id) => APIClient.deleteAllowanceAccountDimension(slug, dim_id),
    })
  },
  componentDidMount: (props) => {
    const { accountingAccountDimensionItems, setCache } = props

    setCache(accountingAccountDimensionItems)
  },
})

const getFormName = (id) => {
  return `account-allowance-${id}`
}

const mapStateToProps = (state, props) => {
  const request = getRequest(state)

  const {
    accountingAccountDimensionItems = [],
    status_settlement_at,
    uid,
    slug,
    abilities,
    user: { full_name },
  } = request
  const { accountDimensions = [], requestMeansOfTransportsOptions } = props

  const FORM_NAME = getFormName(props.slug)

  return {
    initialValues: fromJS({
      uid,
      status_settlement_at,
      means_of_transports: requestMeansOfTransportsOptions,
      ...generateAccountDimensionFormValues(accountDimensions, accountingAccountDimensionItems),
    }),
    form: FORM_NAME,
    data: getFormValues(FORM_NAME, state),
    accountDimensionItems: getCache(state)(FORM_NAME),
    isMeansOfTransportsEditable: abilities.editMeansOfTransports,
    accountingAccountDimensionItems,
    accountDimensions,
    username: full_name,
    selectedAccommodations: request.with_selected_accommodations,
    slug,
  }
}

const mapDispatchToProps = (dispatch, props) => {
  return bindActionCreators(
    {
      setCache: (data) => setCache({ name: getFormName(props.slug), data }),
      saveAccountingMileageAllowance,
      removeAccountingMileageAllowance: () =>
        removeAccountingMileageAllowance(props.request.slug, props.mileageAllowance.id),
    },
    dispatch,
  )
}

const AccountAllowanceForm = connect(mapStateToProps, mapDispatchToProps)(AccountAllowanceFormPure)

AccountAllowanceForm.propTypes = {
  slug: PropTypes.string.isRequired,
  accountDimensions: PropTypes.array.isRequired,
}

export { AccountAllowanceForm }
export default { AccountAllowanceForm }
