import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Icon from '../ui/IconComponent'
import { CurrencyField } from '../ui/Form'
import { InputErrorComponent } from '../InputErrorComponent'

class EditableCurrency extends Component<any, any> {
  constructor(props) {
    super(props)

    this.ref = React.createRef()

    this.state = {
      isEditing: false,
      currencyError: null,
    }
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside)
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside)
  }

  handleClickOutside = (event) => {
    if (this.state.isEditing && this.ref && !this.ref.current.contains(event.target)) {
      this.setState({ isEditing: false })
    }
  }

  setEditing = (e) => {
    e.preventDefault()

    if (this.props.canEdit) {
      this.setState({ isEditing: true })
    }
  }

  renderAmount = () => {
    const { canEdit } = this.props

    return (
      <div className='table-accordion__installment-currency' onClick={this.setEditing}>
        <span>{this.props.currency}</span>
        {canEdit && (
          <span className='timeline-icon' onClick={this.setEditing}>
            <Icon type='edit' lg gradient />
          </span>
        )}
      </div>
    )
  }

  onChange = (currency) => {
    this.setState({ currency, currencyError: null })
    this.submitChange(currency)
  }

  submitChange = (currency) => {
    this.props
      .onChange(this.props.amount, currency)
      .then(() => this.setState({ isEditing: false }))
      .catch((error) => this.setState({ currencyError: this.props.onError(error) }))
  }

  renderInput = () => {
    return (
      <div className='table-accordion__row-edit' onClick={(e) => e.preventDefault()} ref={this.ref}>
        <CurrencyField
          value={this.props.currency}
          onChange={(value) => this.onChange(value)}
          currencies={this.props.currencies}
        />
        <InputErrorComponent errors={this.state.currencyError} />
      </div>
    )
  }

  render() {
    return this.state.isEditing ? this.renderInput() : this.renderAmount()
  }
}

EditableCurrency.propTypes = {
  amount: PropTypes.string,
  currency: PropTypes.string,
  currencies: PropTypes.array,
  canEdit: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  onError: PropTypes.func.isRequired,
}

export default EditableCurrency
export { EditableCurrency }
