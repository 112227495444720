import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { logoutUserAction } from '../containers/App/actions'
import { getInstance } from '../store/app/instance'

class PrivateRouteComponent extends Component<any, any> {
  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.instance.isBlocked) {
      this.props.logoutUserAction()
    }
  }

  render() {
    const {
      isAuthenticated,
      push,
      children,
      location: { pathname, search },
    } = this.props

    if (isAuthenticated !== true) {
      push(`/login?redirect=${encodeURIComponent(`${pathname}${search}`)}`)
      return null
    }

    return children
  }
}

const mapStateToProps = (state) => ({
  instance: getInstance(state),
})
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      logoutUserAction,
    },
    dispatch,
  )

export default connect(mapStateToProps, mapDispatchToProps)(PrivateRouteComponent)

PrivateRouteComponent.propTypes = {
  push: PropTypes.func.isRequired,
  isAuthenticated: PropTypes.any,
  children: PropTypes.node.isRequired,
  location: PropTypes.object.isRequired,
}
