import { CHANGE, RESET, SET, SET_CURRENT, UPDATE_AVATAR } from './index'
import APIClient from '../../../services/APIClient'

// actions Creators
export const reset = () => (dispatch) => {
  dispatch({
    type: RESET,
  })
}

export const set = (data) => (dispatch) => {
  dispatch({
    type: SET,
    payload: data,
  })
}

export const change = (path, value) => (dispatch) => {
  dispatch({
    type: CHANGE,
    payload: {
      path,
      value,
    },
  })
}

export const updateAvatarInHierarchy = (payload) => (dispatch) => {
  dispatch({
    type: UPDATE_AVATAR,
    payload: payload,
  })
}

export const fetch =
  (slug = null) =>
  (dispatch) => {
    dispatch(change('isFetching', true))

    APIClient.getOrganizationalStructure(slug).then((response) => {
      dispatch(
        set({
          isFetching: false,
          employees: response.data,
          isInitialized: true,
        }),
      )
    })
  }

export const setCurrent = (user) => (dispatch) => {
  dispatch({
    type: SET_CURRENT,
    payload: {
      user,
    },
  })
}
