import { trans } from '../trans'

export const TYPE_TRIP = 'trip'
export const TYPE_EXPENSE = 'expense'

export const STATUS_DRAFT = 'draft'
export const STATUS_NEW = 'new'
export const STATUS_WAITING_FOR_ACCEPTANCE = 'waiting_for_acceptance'
export const STATUS_UPCOMING_TRIP = 'upcoming_trip'
export const STATUS_TRIP = 'trip'
export const STATUS_SETTLEMENT = 'settlement'
export const STATUS_ACCEPTANCE_OF_SETTLEMENT = 'acceptance_of_settlement'
export const STATUS_ACCOUNTING = 'accounting'
export const STATUS_FINISH = 'finish'
export const STATUS_REJECTED = 'rejected'
export const STATUS_CANCELED = 'canceled'
export const STATUS_ACCOUNTED = 'accounted'
export const STATUS_TRANSFERRED = 'transferred_to_erp'
export const STATUS_TRANSFER_ERROR = 'transfer_error'

export const STATE_SETTLEMENT = 'state_settlement'
export const STATE_REQUEST = 'state_request'

export const STACK_OF_STATUSES = [
  STATUS_DRAFT,
  STATUS_WAITING_FOR_ACCEPTANCE,
  STATUS_UPCOMING_TRIP,
  STATUS_TRIP,
  STATUS_SETTLEMENT,
  STATUS_ACCEPTANCE_OF_SETTLEMENT,
  STATUS_ACCOUNTING,
  STATUS_FINISH,
]

export const SET_DATA = 'TRIP_REQUEST_SET_DATA'
export const SET_INITIAL_DATA = 'TRIP_REQUEST_SET_INITIAL_DATA'
export const CHANGE_VALUE = 'TRIP_REQUEST_CHANGE_VALUE'
export const SET_DOCUMENTS = 'TRIP_REQUEST_SET_DOCUMENTS'
export const APPEND_DOCUMENT = 'TRIP_REQUEST_APPEND_DOCUMENT'
export const DELETE_DOCUMENT = 'TRIP_REQUEST_DELETE_DOCUMENT'
export const SAVE_ELEMENT_ITEM = 'TRIP_REQUEST_SAVE_ELEMENT_ITEM'
export const ADD_ELEMENT_ITEM = 'TRIP_REQUEST_ADD_ELEMENT_ITEM'
export const UPDATE_ELEMENT_ACTION = 'TRIP_REQUEST_UPDATE_ELEMENT_ITEM'
export const REMOVE_ELEMENT_ITEM = 'TRIP_REQUEST_REMOVE_ELEMENT_ITEM'
export const CHANGE_ELEMENT = 'TRIP_REQUEST_CHANGE_ELEMENT'
export const CLEAR_DELETED_ELEMENT_ITEMS = 'TRIP_REQUEST_CLEAR_DELETED_ELEMENT_ITEMS'
export const ADD_ACCEPTOR = 'ADD_ACCEPTOR'
export const UPDATE_ACCEPTOR = 'UPDATE_ACCEPTOR'
export const DELETE_ACCEPTOR = 'DELETE_ACCEPTOR'
export const ADD_SETTLEMENT_ACCEPTOR = 'ADD_SETTLEMENT_ACCEPTOR'
export const UPDATE_SETTLEMENT_ACCEPTOR = 'UPDATE_SETTLEMENT_ACCEPTOR'
export const DELETE_SETTLEMENT_ACCEPTOR = 'DELETE_SETTLEMENT_ACCEPTOR'
export const DELETE_ELEMENT_DOCUMENT = 'DELETE_ELEMENT_DOCUMENT'

export const getStatusesForSelect = () => [
  {
    label: trans('global.all'),
    value: null,
    onSelectResetsInput: true,
  },
  {
    label: trans(`global.request-status-${STATUS_DRAFT}`),
    value: STATUS_DRAFT,
  },
  {
    label: trans(`global.request-status-${STATUS_WAITING_FOR_ACCEPTANCE}`),
    value: STATUS_WAITING_FOR_ACCEPTANCE,
  },
  {
    label: trans(`global.request-status-${STATUS_UPCOMING_TRIP}`),
    value: STATUS_UPCOMING_TRIP,
  },
  {
    label: trans(`global.request-status-${STATUS_TRIP}`),
    value: STATUS_TRIP,
  },
  {
    label: trans(`global.request-status-${STATUS_SETTLEMENT}`),
    value: STATUS_SETTLEMENT,
  },
  {
    label: trans(`global.request-status-${STATUS_ACCEPTANCE_OF_SETTLEMENT}`),
    value: STATUS_ACCEPTANCE_OF_SETTLEMENT,
  },
  {
    label: trans(`global.request-status-${STATUS_ACCOUNTING}`),
    value: STATUS_ACCOUNTING,
  },
  {
    label: trans(`global.request-status-${STATUS_FINISH}`),
    value: STATUS_FINISH,
  },
  {
    label: trans(`global.request-status-${STATUS_REJECTED}`),
    value: STATUS_REJECTED,
  },
  {
    label: trans(`global.request-status-${STATUS_TRANSFER_ERROR}`),
    value: STATUS_TRANSFER_ERROR,
  },
  {
    label: trans(`global.request-status-${STATUS_TRANSFERRED}`),
    value: STATUS_TRANSFERRED,
  },
  {
    label: trans(`global.request-status-${STATUS_CANCELED}`),
    value: STATUS_CANCELED,
  },
]

export const getStatusesForSettlementSelect = () => [
  {
    label: trans('global.all'),
    value: null,
    onSelectResetsInput: true,
  },
  {
    label: trans(`global.request-status-${STATUS_SETTLEMENT}`),
    value: STATUS_SETTLEMENT,
  },
  {
    label: trans(`global.request-status-${STATUS_ACCEPTANCE_OF_SETTLEMENT}`),
    value: STATUS_ACCEPTANCE_OF_SETTLEMENT,
  },
  {
    label: trans(`global.request-status-${STATUS_ACCOUNTING}`),
    value: STATUS_ACCOUNTING,
  },
  {
    label: trans(`global.request-status-${STATUS_FINISH}`),
    value: STATUS_FINISH,
  },
]

export const getStatusesForTransactionSelect = () => [
  {
    label: trans('global.all'),
    value: null,
    onSelectResetsInput: true,
  },
  {
    label: trans(`global.filter-status-document-to-settle`),
    value: STATUS_ACCEPTANCE_OF_SETTLEMENT,
  },
  {
    label: trans(`global.filter-status-document-to-account`),
    value: STATUS_ACCOUNTING,
  },
  {
    label: trans(`global.filter-status-document-accounted`),
    value: STATUS_FINISH,
  },
]

export const QUICK_FILTER_UNACCOUNTED_SETTLEMENTS = 'unaccounted_settlements'
export const QUICK_FILTER_ASSIGNED_TO_ME = 'assigned_to_me'

export const QUICK_FILTER_ASSIGNED = 'assigned'
export const QUICK_FILTER_UNASSIGNED_TO_ACCOUNTANT = 'unassigned_to_accountant'
