import { components as Components } from 'react-select'
import classnames from 'classnames'
import React from 'react'

const ClearIndicator = ({ ...props }) => (
  <Components.ClearIndicator {...props} className={'react-select__clear-indicator'} />
)

const Control = ({ ...props }) => (
  <Components.Control {...props} className={'react-select__control'} />
)

const CrossIcon = ({ ...props }) => (
  <Components.CrossIcon {...props} className={'react-select__cross-icon'} />
)

const DownChevron = ({ ...props }) => (
  <Components.DownChevron {...props} className={'react-select__down-chevron'} />
)

const DropdownIndicator = ({ ...props }) => (
  <Components.DropdownIndicator {...props} className={'react-select__dropdown-indicator'} />
)

const Group = ({ ...props }) => <Components.Group {...props} className={'react-select__group'} />

const GroupHeader = ({ ...props }) => (
  <Components.GroupHeader {...props} className={'react-select__group-header'} />
)

const IndicatorSeparator = ({ ...props }) => (
  <Components.IndicatorSeparator {...props} className={'react-select__indicator-separator'} />
)

const IndicatorsContainer = ({ ...props }) => (
  <Components.IndicatorsContainer {...props} className={'react-select__indicators-container'} />
)

const Input = ({ ...props }) => <Components.Input {...props} className={'react-select__input'} />

const LoadingIndicator = ({ ...props }) => (
  <Components.LoadingIndicator {...props} className={'react-select__loading-indicator'} />
)

const LoadingMessage = ({ ...props }) => (
  <Components.LoadingMessage {...props} className={'react-select__loading-message'} />
)

const Menu = ({ ...props }) => <Components.Menu {...props} className={'react-select__menu'} />

const MenuList = ({ ...props }) => (
  <Components.MenuList {...props} className={'react-select__menu-list'} />
)

const MenuPortal = ({ ...props }) => (
  <Components.MenuPortal {...props} className={'react-select__menu-portal'} />
)

const MultiValue = ({ ...props }) => (
  <Components.MultiValue {...props} className={'react-select__multi-value'} />
)

const MultiValueContainer = ({ ...props }) => (
  <Components.MultiValueContainer {...props} className={'react-select__multi-value-container'} />
)

const MultiValueLabel = ({ ...props }) => (
  <Components.MultiValueLabel {...props} className={'react-select__multi-value-label'} />
)

const MultiValueRemove = ({ ...props }) => {
  if (props.data.fixed) {
    return null
  }

  return <Components.MultiValueRemove {...props} className={'react-select__multi-value-remove'} />
}

const NoOptionsMessage = ({ ...props }) => (
  <Components.NoOptionsMessage {...props} className={'react-select__no-options-message'} />
)

const Option = ({ ...props }) => (
  <Components.Option
    {...props}
    className={classnames({
      'react-select__option': true,
      'react-select__option--is-selected': props['isSelected'],
      'react-select__option--is-focused': props['isFocused'],
    })}
  />
)

const Placeholder = ({ ...props }) => (
  <Components.Placeholder {...props} className={'react-select__placeholder'} />
)

const SelectContainer = ({ ...props }) => (
  <Components.SelectContainer
    {...props}
    className={classnames({
      'react-select__select-container': true,
      'react-select__select-container__is-disabled': props['isDisabled'],
    })}
  />
)

const SingleValue = ({ ...props }) => (
  <Components.SingleValue {...props} className={'react-select__single-value'} />
)

const ValueContainer = ({ ...props }) => (
  <Components.ValueContainer {...props} className={'react-select__value-container'} />
)

export const components = {
  ClearIndicator,
  Control,
  CrossIcon,
  DownChevron,
  DropdownIndicator,
  Group,
  GroupHeader,
  IndicatorSeparator,
  IndicatorsContainer,
  Input,
  LoadingIndicator,
  LoadingMessage,
  Menu,
  MenuList,
  MenuPortal,
  MultiValue,
  MultiValueContainer,
  MultiValueLabel,
  MultiValueRemove,
  NoOptionsMessage,
  Option,
  Placeholder,
  SelectContainer,
  SingleValue,
  ValueContainer,
}
