import { getInitialState } from './index'
import { cloneDeep, set as _set, get } from 'lodash'

//actions
export const reset = () => {
  return getInitialState()
}

export const set = (state, payload) => {
  return {
    ...state,
    delegation: get(payload, 'request.delegation', false),
  }
}

export const change = (state, payload) => {
  return _set(cloneDeep(state), payload.path, payload.value)
}
