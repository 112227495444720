import React from 'react'
import D3Base from './D3Base'
import { select, arc, format, interpolate, pie, easeLinear } from 'd3'

const SIZE = 146
const DISTANCE = 2

const pieFunction = pie()
  .sort(null)
  .startAngle(Math.PI)
  .endAngle(4 * Math.PI)
  .value((d) => d.value)

const radius = SIZE / 2 - DISTANCE
const arcFunction = arc()
  .outerRadius(radius)
  .innerRadius(radius / 1.35)

class CircleGraph extends D3Base {
  state = {
    hovered: null,
  }

  renderGraph = () => {
    const { data, percentage } = this.props
    const d3Container = select(this.el)
    const countElement = d3Container.select('.half-donut-count')
    const currentValue = parseInt(countElement.text()) || 0
    const value = percentage ? data[0].value : data.reduce((prev, i) => prev + i.value || 0, 0)
    const formatter = percentage ? format('.0%') : parseInt

    d3Container
      .selectAll('.d3-arc')
      .data(pieFunction(data))
      .select('path')
      .transition()
      .delay((_d, i) => i * 500)
      .duration(500)
      .attrTween('d', (d) => {
        const interpolateFunction = interpolate(d.startAngle, d.endAngle)
        return (t) => {
          d.endAngle = interpolateFunction(t)
          return arcFunction(d)
        }
      })

    countElement
      .transition()
      .duration(1500)
      .ease(easeLinear)
      .tween('text', () => {
        const i = interpolate(currentValue, value)
        return (t) => countElement.text(formatter(i(percentage ? t / 100 : t)))
      })
  }

  onMouseOver = (event, index) => {
    select(event.target).attr('transform', (d) => {
      d.midAngle = (d.endAngle - d.startAngle) / 2 + d.startAngle
      const x = Math.sin(d.midAngle) * DISTANCE
      const y = -Math.cos(d.midAngle) * DISTANCE
      return `translate(${x}, ${y})`
    })
    this.setState({ hovered: index })
  }

  onMouseOut = (event) => {
    select(event.target).attr('transform', null)
    this.setState({ hovered: null })
  }

  renderChildren = () => {
    const { data } = this.props
    const { hovered } = this.state
    const total = data.reduce((prev, curr) => prev + curr.value, 0)

    return (
      <React.Fragment>
        <svg width={SIZE} height={SIZE}>
          <g className='pie' transform={`translate(${SIZE / 2}, ${SIZE / 2})`}>
            {data.map((item, index) => (
              <g
                key={item.id}
                className='d3-arc'
                style={{ stroke: '#fff', strokeWidth: item.value > 0 ? 2 : 0, cursor: 'pointer' }}
              >
                <path
                  style={{ fill: item.color, transition: '.3s ease all' }}
                  onMouseOver={(e) => this.onMouseOver(e, index)}
                  onMouseOut={(e) => this.onMouseOut(e)}
                />
              </g>
            ))}
            <text
              className='half-donut-count'
              textAnchor='middle'
              style={{
                fontSize: '21px',
                fontWeight: 500,
                transform: 'translateY(9px)',
              }}
            >
              0%
            </text>
          </g>
        </svg>
        <ul className='chart-widget-legend'>
          {data.map((item, index) => (
            <li
              key={item.id}
              data-slice={index}
              style={{
                opacity: hovered !== null && hovered !== index ? 0.3 : 1,
                borderBottom: `solid 2px ${item.color}`,
              }}
            >
              {item.status}: <span>{total ? ((item.value * 100) / total).toFixed() : 0}%</span>
            </li>
          ))}
        </ul>
      </React.Fragment>
    )
  }
}

export default CircleGraph
export { CircleGraph }
