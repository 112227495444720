import React, { Fragment, useState } from 'react'
import { Map } from 'immutable'
import classnames from 'classnames'
import Avatar from '../Avatar/Avatar'
import Ellipsis from '../ui/Ellipsis/Ellipsis'
import TruncateMarkup from 'react-truncate-markup'
import { Tooltip } from '../Tooltip'
import { UserProfileHover } from './UserProfileHover'

interface User {
  first_name: string
  last_name: string
  email: string
  phone?: string
  user_name_suffix?: string
  hover: Array<{
    name: string
    value: string
  }>
  company: {
    name: string
  }
}

interface Company {
  name: string
}

interface UserProfileProps {
  user: User | Map<keyof User, any>
  company: Company | Map<keyof Company, any>
}

export const UserProfile = (props: UserProfileProps) => {
  const userData: User = Map.isMap(props.user) ? (props.user.toJS() as User) : props.user
  const hover = userData.hover || []
  const Wrapper = hover.length ? Tooltip : Fragment

  return (
    <Wrapper className='user-profile__hover-tooltip' html={<UserProfileHover hover={hover} />}>
      <div className='user-profile-card'>
        <Avatar user={userData} className='user-profile-card__avatar' />

        <div className='user-profile-card__content'>
          <h4 className='user-profile-card__header'>
            {userData.first_name} {userData.last_name} {userData.user_name_suffix && `- ${userData.user_name_suffix}`}
          </h4>
          <span className={'user-profile-card__info'}>
            <a className='user-profile-card__email' href={`mailto:${userData.email}`}>
              {userData.email}
            </a>
          </span>
        </div>
      </div>
    </Wrapper>
  )
}
