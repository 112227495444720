import PropTypes from 'prop-types'
import React from 'react'
import { Field } from 'redux-form/immutable'
import { FormField, FormFieldRadioGroup } from '../ui/Form'
import { Panel } from '../ui/Panel'
import trans from '../../trans'
import { AmountFormatter } from '../AmountFormatter'
import { config } from '../../config'
import numeral from 'numeral'
import TripDidNotHavePlaceOverlay from '../BorderCrossings/TripDidNotHavePlaceOverlay'

class RequestMileageAllowanceElementForm extends React.Component<any, any> {
  calculateLumpSum() {
    const { data } = this.props
    let distance = data.distance

    if (!distance) {
      return numeral(0)
    }

    return numeral(distance).multiply(config.lumSumRates[data.vehicle_type])
  }

  renderLumpSum() {
    return <AmountFormatter amount={this.calculateLumpSum()} />
  }

  render() {
    const { submitting, getValue, data, change } = this.props

    return (
      <TripDidNotHavePlaceOverlay>
        <Panel header={<h1>{trans('request-element.ride-detail')}</h1>}>
          <form>
            <div>
              <Field
                name='departure_date'
                type='datepicker'
                component={FormField}
                label={trans('request-element.departure-at')}
                disabled={submitting}
                labeltop
              />

              {data.round_trip && (
                <Field
                  name='arrival_date'
                  type='datepicker'
                  component={FormField}
                  label={trans('request-element.return-at')}
                  disabled={submitting}
                  minDate={getValue('departure_date')}
                  labeltop
                />
              )}

              <Field
                name='departure_from'
                type='location'
                component={FormField}
                label={trans('request-element.departure-from')}
                disabled={submitting}
                labeltop
              />

              <Field
                name='arrival_to'
                type='location'
                component={FormField}
                label={trans('request-element.arrival-to')}
                disabled={submitting}
                labeltop
              />

              <Field
                name='round_trip'
                type='toggle'
                component={FormField}
                label={trans('request-element.round-trip')}
                disabled={submitting}
                inverse
                onChange={(event) => {
                  const isRoundTrip = event.target.checked

                  if (isRoundTrip) {
                    change('distance', parseFloat(data.distance) * 2)
                  } else {
                    change('distance', parseFloat(data.distance) / 2)
                  }
                }}
              />

              <Field
                name='distance'
                type='distance'
                component={FormField}
                label={trans('request-element.distance')}
                disabled={submitting}
                labeltop
              />

              <Field
                name='vehicle_type'
                component={FormFieldRadioGroup}
                label={trans('request-car-element.vehicle-type')}
                inline
                items={{
                  rows: [
                    {
                      cols: [
                        {
                          xs: 6,
                          items: [
                            {
                              value: 'upperCapacityEngine',
                              label: trans('request-element.upper-engine-capacity', {
                                capacity: '900cm<sup>3</sup>',
                              }),
                            },
                            {
                              value: 'lowCapacityEngine',
                              label: trans('request-element.nether-engine-capacity', {
                                capacity: '900cm<sup>3</sup>',
                              }),
                            },
                          ],
                        },
                        {
                          xs: 6,
                          items: [
                            {
                              value: 'motorcycle',
                              label: trans('request-element.motorcycle'),
                            },
                            {
                              value: 'moped',
                              label: trans('request-element.moped'),
                            },
                          ],
                        },
                      ],
                    },
                  ],
                }}
                disabled={submitting}
                labeltop
              />
            </div>

            <Field
              name='license_plate'
              type='text'
              component={FormField}
              label={trans('request-element.license-plate')}
              labeltop
            />

            <div className='request-mileage-allowance-page__lump-sum is-margin-top-small'>
              {trans('request-car-element.lump-sum')}: {this.renderLumpSum()} {data.currency}
            </div>
          </form>
        </Panel>
      </TripDidNotHavePlaceOverlay>
    )
  }
}

RequestMileageAllowanceElementForm.propTypes = {
  request: PropTypes.object.isRequired,
}

export { RequestMileageAllowanceElementForm }
export default { RequestMileageAllowanceElementForm }
