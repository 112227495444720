import { useCallback, useEffect, useMemo, useState } from 'react'
import { GridApi, GridOptions, GridReadyEvent, ICellRendererParams } from 'ag-grid-community'
import trans from '../../../trans'
import { Transaction } from '../../../types/transaction'
import ButtonRenderer from '../../AgGrid/CellRenderer/ButtonRenderer'
import dateFormatter from '../../AgGrid/ValueFormatter/dateFormatter'
import { amountFormatter } from '../../AgGrid/ValueFormatter/amountFormatter'
import defaultFormatter from '../../AgGrid/ValueFormatter/defaultFormatter'
import LinkRenderer from '../../AgGrid/CellRenderer/LinkRenderer'
import { ITransactionSuggestion } from '../../../types/transaction-suggestion'
import IconRenderer from '../../AgGrid/CellRenderer/IconRenderer'

export function useGridOptions() {
  const [api, setApi] = useState<GridApi>(null)
  const [gridOptions, setGridOptions] = useState<GridOptions<ITransactionSuggestion>>({
    domLayout: 'autoHeight',
    defaultColDef: {
      suppressSizeToFit: false,
      suppressMenu: true,
      suppressMovable: true,
    },
    enableBrowserTooltips: true,
    onGridReady(event: GridReadyEvent<ITransactionSuggestion>) {
      setApi(event.api)
    },
    columnDefs: [
      {
        headerName: trans('my-card.payments_th_payment_date'),
        field: 'transaction.transaction_date',
        valueFormatter: dateFormatter,
        minWidth: 140,
        maxWidth: 140,
      },
      {
        headerName: '',
        field: 'transaction.category_css_class',
        minWidth: 80,
        maxWidth: 80,
        valueFormatter: defaultFormatter,
        cellRenderer: IconRenderer,
      },
      {
        headerName: trans('my-card.payments_th_details'),
        field: 'transaction.description',
        minWidth: 150,
        tooltipField: 'transaction.description',
        valueFormatter: defaultFormatter,
      },
      {
        headerName: trans('my-card.payments_th_transaction_amount'),
        field: 'transaction.transaction_value',
        valueFormatter: amountFormatter('transaction.transaction_currency'),
        minWidth: 150,
        maxWidth: 150,
        type: 'numericColumn',
      },
      {
        headerName: trans('my-card.payments_th_settlement_amount'),
        field: 'transaction.billing_value',
        valueFormatter: amountFormatter('transaction.billing_currency'),
        minWidth: 150,
        maxWidth: 150,
        type: 'numericColumn',
      },
      {
        headerName: trans('my-card.payments_th_document_number'),
        field: 'document.number',
        valueFormatter: defaultFormatter,
        cellRenderer: LinkRenderer,
        cellRendererParams: (params) => ({
          link: params.data.document?.metadata?.document_url,
        }),
        minWidth: 150,
        maxWidth: 150,
      },
      {
        headerName: trans('my-card.payments_th_status'),
        valueGetter: (params) =>
          params.data.reconciled
            ? trans('my-card.payments_status_reconciled')
            : trans('my-card.payments_status_not_reconciled'),
        minWidth: 120,
        maxWidth: 120,
      },
      {
        headerName: trans('my-card.payments_th_request_id'),
        field: 'document.metadata.request_number',
        valueFormatter: defaultFormatter,
        cellRenderer: LinkRenderer,
        cellRendererParams: (params) => ({
          link: params.data.document?.metadata?.request_url,
        }),
        minWidth: 150,
        maxWidth: 150,
      },
      {
        type: 'attach',
        minWidth: 140,
        maxWidth: 140,
        cellRenderer: ButtonRenderer,
        cellRendererParams: (params: ICellRendererParams<ITransactionSuggestion>) => {
          if (params.data.reconciled) {
            return {
              label: trans('my-card.payments_cancel_button'),
              danger: true,
            }
          }

          return {
            label: trans('my-card.payments_attach_button'),
            primary: true,
          }
        },
      },
    ],
  })

  const onResize = useMemo(() => {
    return () => {
      if (api) {
        api.sizeColumnsToFit()
      }
    }
  }, [api])

  useEffect(() => {
    onResize()

    window.addEventListener('resize', onResize)

    return () => {
      window.removeEventListener('resize', onResize)
    }
  }, [api])

  return gridOptions
}
