import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import Icon from '../ui/IconComponent'
import React from 'react'
import Button from '../ui/ButtonComponent'
import trans from '../../trans'

export default function NoInvoicesModal({ open, onClose }: { open: boolean; onClose: () => void }) {
  return (
    <Dialog
      open={open}
      keepMounted
      onClose={onClose}
      maxWidth={'lg'}
      className={'no-invoice-modal'}
      PaperProps={{
        square: true,
      }}
    >
      <DialogContent className='no-invoice-modal__content'>
        <div className='no-invoice-modal__icon'>
          <Icon type={'warning'} />
        </div>

        <div className='no-invoice-modal__description'>
          <h4>{trans('my-card.no-invoice-title')}</h4>
          <p>{trans('my-card.no-invoice-description')}</p>
        </div>

        <div className='no-invoice-modal__button'>
          <Button sm primary type='close' onClick={onClose}>
            {trans('my-card.no-invoice-back')}
          </Button>
        </div>
      </DialogContent>

      <DialogActions className='no-margin'>
        <Icon className='last-requests__dialog-close' type='close' onClick={onClose} />
      </DialogActions>
    </Dialog>
  )
}
