import { MODULE_MOUNT_POINT } from './index'
import { get } from 'lodash'
import { createSelector } from '@reduxjs/toolkit'
import {
  BOOKING_CANCELED,
  BOOKING_CANCELLATION_FAILED,
  BOOKING_PENDING_CANCELLATION,
  BOOKING_SUCCESS,
  BOOKING_WITHOUT_TICKET,
} from '../trains-booking'

//selectors
export const getState = (state) => {
  return state.get(MODULE_MOUNT_POINT)
}

export const getFacilities = (name) => (state) => {
  return get(getState(state), `${name}.facilities`)
}

export const getInitialFacilities = (name) => (state) => {
  return get(getState(state), `${name}.initialFacilities`)
}

export const getFacility = (name) => (state) => (facility) => {
  return get(getState(state), `${name}.facilities.${facility}`, false)
}

export const getFilters = (name) => (state) => {
  return get(getState(state), `${name}.filters`, false)
}

export const getFilter = (name) => (state) => (filter) => {
  return get(getState(state), `${name}.filters.${filter}`, false)
}

export const getSortBy = (name) => (state) => {
  return get(getState(state), `${name}.sortBy`, false)
}

export const isReservationSuccess = (name) =>
  createSelector(getSelectedOption(name), (selectedOption) => {
    const status = get(selectedOption, 'booking', null)

    return status === BOOKING_WITHOUT_TICKET || status === BOOKING_SUCCESS
  })

export const isReservationInCancellation = (name) =>
  createSelector(getSelectedOption(name), (selectedOption) => {
    const status = get(selectedOption, 'booking', null)

    return (
      status === BOOKING_CANCELED ||
      status === BOOKING_PENDING_CANCELLATION ||
      status === BOOKING_CANCELLATION_FAILED
    )
  })

export const isReservationCancellationFailed = (name) =>
  createSelector(getSelectedOption(name), (selectedOption) => {
    const status = get(selectedOption, 'booking', null)

    return status === BOOKING_CANCELLATION_FAILED
  })

export const getOffers = (name) => (state) => {
  return get(getState(state), `${name}.offers`, [])
}

export const getRoomAllocations = (name) => (state) => {
  return get(getState(state), `${name}.roomAllocations`, [])
}

export const getTotalPaxes = (name) => (state) => {
  return get(getState(state), `${name}.totalPaxes`, 1)
}

export const getStatus = (name) => (state) => {
  return get(getState(state), `${name}.status`, [])
}

export const getSearchUuid = (name) => (state) => {
  return get(getState(state), `${name}.uuid`, null)
}

export const getSelectedOffer = (name) =>
  createSelector([getOffers(name)], (offers) => offers.find((offer) => offer.selected === true))

export const getSelectedOption = (name) =>
  createSelector([getSelectedOffer(name)], (selected) => {
    if (selected) {
      return selected.options.find((option) => option.chosen === true)
    }
    return null
  })
export const isOptionSelected = (name) => (state) => {
  const selected = getSelectedOffer(name)(state)
  if (selected) {
    return selected.options.find((option) => option.chosen === true) || false
  }
  return false
}

export const selector =
  (name) =>
  (state) =>
  (field, defaultValue = null) => {
    return get(getState(state), `${name}.${field}`, defaultValue)
  }

const filterAndSortOptions = (filters, options) => {
  const breakfast = get(filters, 'breakfast')

  return (
    options
      // filter based on breakfast
      .filter((option) => !(breakfast && get(option, 'hasBreakfast', false) !== breakfast))
      // sort based on option amount, ascending
      .sort((a, b) => parseFloat(a.amount.amount) - parseFloat(b.amount.amount))
  )
}

export const getFilteredOptions = (name) =>
  createSelector([getFilters(name), getSelectedOffer(name)], (filters, selected) => {
    if (selected) {
      return filterAndSortOptions(filters, selected.options)
    }

    return []
  })

export const getProcessedOffers = (name) => (state) => {
  return get(getState(state), `${name}.processedOffers`, [])
}

export const getAllOffersNumber = (name) =>
  createSelector([getOffers(name)], (offers) => offers.length)

export const getAllOptionsNumber = (name) =>
  createSelector([getSelectedOffer(name)], (selected) => (selected ? selected.options.length : 0))

export const getQuery = (name) => (state) => {
  return get(getState(state), `${name}.query`, {})
}
