import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { ToggleValue } from '../../../ui/Input/ToggleValue'
import { get } from 'lodash'
import store from '../../../store'
import { change as changeFormValue } from 'redux-form'
import { trans } from '../../../trans'

class TravelClass extends Component<any, any> {
  onChange = (value) => {
    const {
      trainsBooking: {
        actions: { setFilter },
      },
    } = this.props
    setFilter({ travelClass: value ? '2' : '1' })
  }

  render() {
    const {
      trainsBooking: {
        selectors: { filters },
      },
    } = this.props

    return (
      <div className='departure__toggler-container'>
        <span>{trans('trains-booking.travel-class-first')}</span>
        <ToggleValue
          offValue='first-class'
          onValue='second-class'
          className='departure__toggler-input'
          value={get(filters, 'travelClass', '1') === '2'}
          onChange={this.onChange}
          disabled={false}
        />
        <span>{trans('trains-booking.travel-class-second')}</span>
      </div>
    )
  }
}

TravelClass.propTypes = {
  trainsBooking: PropTypes.object.isRequired,
}

export default TravelClass
export { TravelClass }
