import React, { useCallback } from 'react'
import { AgGridReact } from 'ag-grid-react'
import { useGridOptions } from './hooks/useGridOptions'
import { useHistory } from 'react-router-dom'
import Pagination from '../SettingsPage/CompanyPage/Pagination/Pagination'
import { CellClickedEvent } from 'ag-grid-community'
import { IAccount } from '../../types/account'

function AccountsPageTable({ data, paginator, setPage }) {
  const gridOptions = useGridOptions()
  const history = useHistory()

  const onCellClicked = useCallback((event: CellClickedEvent<IAccount>) => {
    history.push(`/bank-account/${event.data.id}/statements`)
  }, [])

  return (
    <>
      <div className={'ag-theme-alpine'}>
        <AgGridReact
          gridOptions={gridOptions}
          rowData={data}
          rowHeight={58}
          headerHeight={40}
          onCellClicked={onCellClicked}
        />
      </div>

      <Pagination
        page={paginator.page}
        total={paginator.total}
        perPage={paginator.per_page}
        changePage={(page) => setPage(page)}
      />
    </>
  )
}

export default AccountsPageTable
