class Location {
  constructor(init, formattedAddress = null) {
    const defaults = {
      country: null,
      country_code: null,
      city: null,
      province: null,
      address: null,
      long: null,
      lat: null,
      name: null,
      formatted_address: '',
      additional_data: {},
    }

    const opts = Object.assign({}, defaults, init)

    this.country = opts.country
    this.country_code = opts.country_code
    this.city = opts.city
    this.province = opts.province
    this.address = opts.address
    this.long = opts.long
    this.lat = opts.lat
    this.name = opts.name
    this.formatted_address = formattedAddress ? formattedAddress : opts.formatted_address
    this.additional_data = opts.additional_data
  }

  isSame(location) {
    return location !== null && this.city == location.city
  }
}

export { Location }
export default Location
