import React from 'react'

class ToggleableQuickFilters extends React.PureComponent<any, any> {
  render() {
    const { children } = this.props

    return <div className='filters__top'>{children}</div>
  }
}

export default ToggleableQuickFilters
export { ToggleableQuickFilters }
