import React from 'react'
import PropTypes from 'prop-types'
import { get } from 'lodash'

const ChangeDetails = (props) => {
  const { offer } = props

  const connectingFlightsAirports = get(offer, 'attributes.connectingFlightsAirports', [])

  if (!connectingFlightsAirports || connectingFlightsAirports.length === 0) {
    return '—'
  }

  const changes = connectingFlightsAirports.map(
    (change) => `${change.travelTime} ${change.airport}`,
  )

  return changes.join(', ')
}

ChangeDetails.propTypes = {
  offer: PropTypes.object.isRequired,
}

export default ChangeDetails
export { ChangeDetails }
