import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import {
  BOOKING_CANCELED,
  BOOKING_CANCELLATION_FAILED,
  BOOKING_EXPIRED,
  BOOKING_FAILED,
  BOOKING_PENDING_CANCELLATION,
  BOOKING_SUCCESS,
} from '../../../store/app/trains-booking'
import { trans } from '../../../trans'

class Status extends React.Component<any, any> {
  render() {
    const status = this.props.offer.option.booking

    const statusClass = classNames({
      'train-trip__selected-ticket-reservation-status': true,
      'train-trip__selected-ticket-reservation-status--success': status === BOOKING_SUCCESS,
      'train-trip__selected-ticket-reservation-status--error':
        status === BOOKING_FAILED ||
        status === BOOKING_EXPIRED ||
        status === BOOKING_CANCELLATION_FAILED,
    })

    let label = null

    if (status === BOOKING_FAILED) {
      label =
        this.props.offer.errorMessageSlug && this.props.offer.errorMessageSlug.length > 1
          ? trans(this.props.offer.errorMessageSlug)
          : trans('trains-booking.booking-failed')
    }

    if (status === BOOKING_EXPIRED) {
      label = trans('trains-booking.booking-expired')
    }

    if (status === BOOKING_SUCCESS) {
      label = trans('trains-booking.booking-success')
    }

    if (this.props.offer.errorMessageSlug) {
      label = trans(this.props.offer.errorMessageSlug)
    }

    if (label) {
      return <span className={statusClass}>{label}</span>
    }

    return null
  }
}

Status.propTypes = {
  offer: PropTypes.object.isRequired,
}

export default Status
export { Status }
