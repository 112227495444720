import React from 'react'
import { trans } from '../../../../trans'
import moment from 'moment/moment'
import { AmountFormatter } from '../../../AmountFormatter'
import { TravelPointLabel } from '../../../TravelPointLabel'
import { ForeignCurrenciesTooltip } from '../../../ForeignCurrenciesTooltip'
import { TravelPointDate } from '../../../TravelPointDate'
import TravelPointStartStopHours from '../../../TravelPointStartStopHours/TravelPointStartStopHours'
import { TYPE_TRAIN } from '../../../../constants/access-lum-sum'
import { Train as TrainForm } from '../Containers/Train'
import PropTypes from 'prop-types'
import OfferTimeline from './OfferTimeline'

class TrainTimeline extends OfferTimeline {
  icon() {
    return 'train_fill'
  }

  renderLabel() {
    const { element } = this.props
    const arrivalDate = moment(element.getStartDate())
    const from = element.getStartLocation()
    const to = element.getEndLocation()

    if (!element.getStartLocation().city || !element.getStartDate()) {
      return trans('request.fill-travel-data')
    } else {
      return (
        <div>
          <TravelPointLabel location={from} showDirections /> → <TravelPointLabel location={to} />
          <TravelPointDate start={arrivalDate} />
          {this.getTravelPointStartStop()}
        </div>
      )
    }
  }

  getTravelPointStartStop() {
    const { element } = this.props

    if (
      element &&
      element.offer_uuid &&
      element.target_real_departure_at &&
      element.target_real_arrival_at
    ) {
      return (
        <TravelPointStartStopHours
          type={TYPE_TRAIN}
          start={moment(element.target_real_departure_at)}
          end={moment(element.target_real_arrival_at)}
        />
      )
    }
  }

  renderForeignCurrenciesTooltip() {
    const { element } = this.props

    const instanceCurrency =
      this.props.instanceCurrency && this.props.instanceCurrency.length > 0
        ? this.props.instanceCurrency
        : this.props.request.user.instance.default_currency

    return (
      <ForeignCurrenciesTooltip
        instanceCurrency={instanceCurrency}
        foreignCurrencies={element.getAllAmounts()}
      />
    )
  }

  renderCalculatedAmount() {
    const { element } = this.props

    if (element.virtual) {
      return trans('request.return-travel')
    } else {
      const amount = element.converted_amount
      const currency = element.calculated_amount_currency

      return (
        <div className='flex-end-center'>
          {this.renderForeignCurrenciesTooltip()}{' '}
          <span>
            <AmountFormatter amount={amount} /> {currency}
          </span>
        </div>
      )
    }
  }

  renderElement() {
    const { element, request, currencies, onRemove } = this.props

    const { isOpen } = this.state

    return (
      <TrainForm
        request={request}
        element={element}
        currencies={currencies}
        onSave={this.onSave}
        onRemove={onRemove}
        isOpen={isOpen}
      />
    )
  }
}

TrainTimeline.propTypes = {
  request: PropTypes.object.isRequired,
  element: PropTypes.object.isRequired,
  currencies: PropTypes.array.isRequired,
  onSave: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
  onChangeType: PropTypes.func.isRequired,
}

export { TrainTimeline }
export default { TrainTimeline }
