import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import { actions, selectors } from '../../store/app/documents-list'
import { bindActionCreators } from 'redux'
import APIClient from '../../services/APIClient'

class DocumentsManagerBase extends React.Component<any, any> {
  constructor(props) {
    super(props)

    this.state = {
      MPKs: [],
      users: [],
      providers: [],
      companies: [],
    }
  }

  componentDidMount() {
    const { reset, fetchItems, listenQueryChange } = this.props

    reset()
    fetchItems()
    listenQueryChange()

    const dependencies = [
      APIClient.getMPKs(),
      APIClient.getUsersForSelect(),
      APIClient.getProviders(),
      APIClient.getCompanies(),
    ]

    Promise.all(dependencies).then((response) => {
      this.setState({
        MPKs: response[0].data,
        users: response[1].data,
        providers: response[2].data,
        companies: response[3].data,
      })
    })
  }

  componentWillUnmount() {
    const { unlistenQueryChange } = this.props

    unlistenQueryChange()
  }

  render() {
    const {
      children,
      documents,
      isLoading,
      isLoaded,
      filters,
      changeFilter,
      currentUser,
      pagination,
      changePage,
      sorter,
      changeSorter,
    } = this.props
    const { MPKs, users, providers, companies } = this.state

    const renderProps = {
      documents,
      documentsFilters: filters,
      changeDocumentFilter: changeFilter,
      requestsSorter: sorter,
      changeRequestsSorter: changeSorter,
      areDocumentsLoading: isLoading,
      areDocumentsLoaded: isLoaded,
      documentsPagination: pagination,
      changeDocumentsPage: changePage,
      MPKs,
      users,
      providers,
      companies,
      currentUser,
    }

    return children(renderProps)
  }
}

DocumentsManagerBase.propTypes = {
  children: PropTypes.func.isRequired,
  documents: PropTypes.array.isRequired,
  isLoaded: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
  filters: PropTypes.object.isRequired,
  changeFilter: PropTypes.func.isRequired,
  sorter: PropTypes.object.isRequired,
  changeSorter: PropTypes.func.isRequired,
  reset: PropTypes.func.isRequired,
  currentUser: PropTypes.object.isRequired,
  pagination: PropTypes.object.isRequired,
  changePage: PropTypes.func.isRequired,
}

const {
  reset,
  fetchItems,
  changeFilter,
  changeSorter,
  changePage,
  listenQueryChange,
  unlistenQueryChange,
} = actions
const { getIsLoaded, getIsLoading, getFilters, getSorter, getItemsPaginated, getPagination } =
  selectors

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      reset,
      fetchItems,
      changeFilter,
      changeSorter,
      changePage,
      listenQueryChange,
      unlistenQueryChange,
    },
    dispatch,
  )
}

const mapStateToProps = (state) => ({
  documents: getItemsPaginated(state),
  isLoaded: getIsLoaded(state),
  isLoading: getIsLoading(state),
  filters: getFilters(state),
  sorter: getSorter(state),
  currentUser: state.get('global').get('currentUser'),
  pagination: getPagination(state),
})

export const DocumentsManager = connect(mapStateToProps, mapDispatchToProps)(DocumentsManagerBase)
