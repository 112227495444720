import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { isFunction } from 'lodash'

class ToggleValue extends Component<any, any> {
  state = {
    isOn: Boolean(this.props.isOn),
  }

  componentDidMount() {
    this.setState({ isOn: this.props.value })
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    return {
      ...prevState,
      isOn: nextProps.value,
    }
  }

  handleToggle = (event) => {
    const { disabled } = this.props

    event.preventDefault()

    if (disabled) {
      return
    }

    let { isOn } = { ...this.state }
    isOn = !isOn
    this.setState({ isOn })
    if (isFunction(this.props.onChange)) {
      this.props.onChange(isOn)
    }
  }

  render() {
    const { isOn } = this.state
    const { onValue = '', offValue = '', className = null, disabled } = this.props

    const classNames = [
      'input-toggle-value',
      isOn && 'input-toggle-value--on',
      className,
      disabled && 'input-toggle-value--disabled',
    ]
      .filter((className) => className)
      .join(' ')

    const value = isOn ? onValue : offValue

    return (
      <label className={classNames} onClick={this.handleToggle}>
        <input type='text' className='input-toggle-value__input' value={value} readOnly={true} />
      </label>
    )
  }
}

ToggleValue.propTypes = {
  onValue: PropTypes.any.isRequired,
  offValue: PropTypes.any.isRequired,
  isOn: PropTypes.bool,
  classNames: PropTypes.string,
  disabled: PropTypes.bool.isRequired,
}

export { ToggleValue }
