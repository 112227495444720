import { createSelector, createSlice } from '@reduxjs/toolkit'
import APIClient from '../../../services/APIClient'
import { getAccountDimensionKey } from './form'
import trans from '../../../trans'

export const ACCOUNT_DIMENSION_MOUNT_POINT = 'account-dimension'

const getInitialState = () => ({
  cache: {},
})

/**
 * In @reduxjs/toolkit we can mutate state in reducers,
 * because behind the scene it uses ImmerJS for applying them as non-mutate
 *
 * https://github.com/immerjs/immer
 */
const slice = createSlice({
  name: ACCOUNT_DIMENSION_MOUNT_POINT,
  initialState: getInitialState(),
  reducers: {
    reset() {
      return getInitialState()
    },
    setCache(state, action) {
      const { name, data } = action.payload
      state.cache[name] = data
    },
  },
})

export const { reset, setCache } = slice.actions

export default slice.reducer

// selectors
const getState = (state) => state.get(ACCOUNT_DIMENSION_MOUNT_POINT)
export const getCache = (state) => (name) => getState(state).cache[name] || []
