import PropTypes from 'prop-types'
import React from 'react'
import trans from '../../../trans'
import { RouteManager } from '../../../containers/RouteManager/index'
import { ExpenseRequestSummary } from '../ExpenseRequestSummary'
import { RequestComments } from '../../RequestPageCommon/RequestComments'
import { Ability } from '../../RequestPageCommon/Ability/Ability'
import { RequestHeader } from '../../RequestPageCommon/RequestHeader'
import { Link, withRouter } from 'react-router-dom'
import { ExpenseRequestCostsDocuments } from '../ExpenseRequestCostsDocuments'
import Button from '../../ui/ButtonComponent'
import { get } from 'lodash'
import CostsDocumentsObserver from '../../RequestPageCommon/CostsDocumentsObserver'
import { TripRequestUnassignedDocuments } from '../../TripRequestPage/TripRequestUnassignedDocuments'
import BasicRequestInfoRenderer from '../../RequestBasicInfoRenderer/RequestBasicInfoRenderer'

class ExpenseRequestPageSettlementComponent extends React.Component<any, any> {
  render() {
    const {
      request,
      changeRequestValue,
      fetchRequest,
      instanceCurrency,
      cancelRequest,
      isModuleActive,
    } = this.props

    return (
      <RouteManager>
        {({ push, getRouteByName }) => (
          <div>
            <RequestHeader request={request} />

            <BasicRequestInfoRenderer />

            <ExpenseRequestCostsDocuments
              request={request}
              instanceCurrency={instanceCurrency}
              fetchRequest={fetchRequest}
            />

            <Ability ability={['canShowUnrequestedDocuments']}>
              <TripRequestUnassignedDocuments request={request} />
            </Ability>

            <ExpenseRequestSummary request={request} />

            <div className='button-group'>
              <Ability ability={['viewSettlementSummary']}>
                <Link
                  className='btn btn--primary btn--pull-end'
                  to={`/requests/${request.type}/${request.slug}/summary`}
                >
                  {trans('global.go-to-settlement-summary')}
                </Link>
              </Ability>

              <Ability ability={['cancel']}>
                <CostsDocumentsObserver elements={get(request, 'costs', [])}>
                  <Button
                    onClick={(e) => {
                      e.preventDefault()
                      cancelRequest().then(() => {
                        this.props.history.goBack()
                      })
                    }}
                    link
                  >
                    {trans('ui.cancel-request')}
                  </Button>
                </CostsDocumentsObserver>
              </Ability>
            </div>

            <RequestComments comments={request['comments']} />
          </div>
        )}
      </RouteManager>
    )
  }
}

ExpenseRequestPageSettlementComponent.propTypes = {
  request: PropTypes.object.isRequired,
  sendToAcceptanceOfSettlement: PropTypes.func.isRequired,
  changeRequestValue: PropTypes.func.isRequired,
  instanceCurrency: PropTypes.string.isRequired,
  isModuleActive: PropTypes.func.isRequired,
}

export const ExpenseRequestPageSettlement = withRouter(ExpenseRequestPageSettlementComponent)
export default { TripRequestPageSettlement: ExpenseRequestPageSettlementComponent }
