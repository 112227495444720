import { Element } from './Element'
import { Location } from './index'
import { TRAVEL_PRIVATE_CAR_TRIP } from '../../constants/travel'

class PrivateCar extends Element {
  constructor(init) {
    super({ ...init, type: TRAVEL_PRIVATE_CAR_TRIP })

    const defaults = {
      departure_at: null,
      departure_location: {},
      destination_location: {},
      round_trip: true,
      return_at: null,
      distance: null,
      other_costs_amount: null,
      other_costs_currency: null,
      google_distance: null,
      calculated_amount: null,
      calculated_amount_currency: null,
      vehicle_type: 'upperCapacityEngine',
      license_plate: '',
    }

    const opts = Object.assign({}, defaults, init)

    this.departure_at = opts.departure_at
    this.departure_location = new Location(opts.departure_location)
    this.destination_location = new Location(opts.destination_location)
    this.round_trip = opts.round_trip
    this.return_at = opts.return_at
    this.distance = opts.distance
    this.other_costs_amount = opts.other_costs_amount
    this.other_costs_currency = opts.other_costs_currency
    this.google_distance = opts.google_distance
    this.calculated_amount = opts.calculated_amount
    this.calculated_amount_currency = opts.calculated_amount_currency
    this.vehicle_type = opts.vehicle_type
    this.license_plate = opts.license_plate
  }

  getStartLocation() {
    return this.departure_location
  }

  getEndLocation() {
    return this.destination_location
  }

  getStartDate() {
    return this.departure_at
  }

  getEndDate() {
    return this.return_at
  }

  getAllAmounts() {
    return []
  }
}

export { PrivateCar }
export default PrivateCar
