import PropTypes from 'prop-types'
import React from 'react'
import { setElements, reset, getElements } from '../../store/app/trip-timeline'

import { bindActionCreators, compose } from 'redux'
import { connect } from 'react-redux'
import { isRecomposition, getRecomposed, addElement } from '../../store/app/trip-timeline/index'
import { isUndefined } from 'lodash'
import socketProvider from '../SocketProvider'
import { isNaN } from 'lodash'

class TimelineManager extends React.Component<any, any> {
  componentDidMount() {
    const {
      setElements,
      reset,
      request: { slug },
    } = this.props

    if (!isUndefined(this.props.request)) {
      reset()
      setElements(this.props.request.combinedTravelElements)
    }

    this.subscribe(slug)
  }

  subscribe(slug) {
    const { setElements, socket, reset } = this.props
    this.channelName = `App.Request.${slug}`
    this.eventName = '.App\\Events\\DocumentOptoked'

    socket.subscribe(this.channelName)(this.eventName)((data) => {
      // cast object to array and remove "socket" prop by checking if key is a number
      const elements = Object.keys(data)
        .filter((a) => !isNaN(parseInt(a)))
        .map((a) => data[a])

      reset()
      setElements(elements)
    })
  }

  shouldComponentUpdate(nextProps, nextState) {
    return !nextProps.isRecomposition && nextProps.recomposed !== this.props.recomposed
  }

  componentWillUnmount() {
    const { reset, socket } = this.props
    if (!isUndefined(this.props.request)) {
      reset()
    }

    socket.unsubscribe(this.channelName)(this.eventName)
  }

  onAddHandler = (index) => {
    this.props.addElement(index)
  }

  render() {
    const { children, elements, isRecomposition } = this.props

    const renderProps = {
      elements,
      onAdd: this.onAddHandler,
      isRecomposition,
    }

    return children(renderProps)
  }
}

TimelineManager.propTypes = {
  request: PropTypes.object,
  excludeTypes: PropTypes.array,
}

TimelineManager.defaultProps = {
  excludeTypes: [],
}

const mapStateToProps = (state, props) => ({
  elements: getElements(state, props.excludedTypes),
  isRecomposition: isRecomposition(state),
  recomposed: getRecomposed(state),
})

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      reset,
      setElements,
      addElement,
    },
    dispatch,
  )
}

const withConnect = connect(mapStateToProps, mapDispatchToProps)
const withSocketProvider = socketProvider()

TimelineManager = compose(withConnect, withSocketProvider)(TimelineManager)

export { TimelineManager }
export default { TimelineManager }
