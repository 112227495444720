import React, { forwardRef, useContext, useImperativeHandle, useRef, useState } from 'react'
import { AgGridContext } from './AgGridContext'
import AccountDimensionField from '../ui/Form/AccountDimensionField'
import TruncateMarkup from 'react-truncate-markup'
import Ellipsis from '../ui/Ellipsis/Ellipsis'

const getValue = (item) => {
  if (!item || !item.accountDimensionItem) {
    return null
  }

  return {
    label: `${item.accountDimensionItem.code} - ${item.accountDimensionItem.name}`,
    value: item.accountDimensionItem.id,
  }
}

const AccountDimensionEditor = forwardRef((props, ref) => {
  const [, { isRowInEditingState }] = useContext(AgGridContext)
  const [value, setValue] = useState(getValue(props.value))
  const refInput = useRef(null)
  const isEditing = isRowInEditingState(props.node.id)

  useImperativeHandle(ref, () => {
    return {
      getValue() {
        return [props.colDef.field, value]
      },
      reset() {
        setValue(getValue(props.value))
      },
    }
  })

  if (!isEditing) {
    const formatted = props.valueFormatted ? props.valueFormatted : props.value

    return (
      <TruncateMarkup lines={1} ellipsis={<Ellipsis text={`${formatted}`} />}>
        <span>{formatted}</span>
      </TruncateMarkup>
    )
  }

  return (
    <div className='ag-select-field'>
      <AccountDimensionField
        dimensionItem={value}
        accountDimension={props.accountDimension}
        value={value}
        onChange={(item) => {
          setValue(item)
        }}
        asField
        ref={refInput}
        placeholder={props.placeholder}
      />
    </div>
  )
})

export default AccountDimensionEditor
