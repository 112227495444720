import React from 'react'
import D3Base from './D3Base'
import { select, scaleLinear, arc, format, interpolate } from 'd3'

class SemicircleGraph extends D3Base {
  color = scaleLinear().domain([0, 100]).range(['#F44336', '#F44336'])
  radius = 78
  thickness = 20

  arcFunction = arc()
    .innerRadius(this.radius - this.thickness)
    .outerRadius(this.radius)
    .startAngle(-Math.PI / 2)

  textTween(transition, v) {
    transition.tween('text', function () {
      const interpolateFunction = interpolate(this.innerHTML, v)
      return (t) => (this.innerHTML = `${format('.0f')(interpolateFunction(t))}<tspan>%</tspan>`)
    })
  }

  arcTween(transition, v) {
    const newAngle = Math.PI * (v / 100 - 0.5)
    transition.attrTween('d', (d) => {
      const interpolateFunction = interpolate(d.endAngle, newAngle)
      return (t) => {
        d.endAngle = interpolateFunction(t)
        return this.arcFunction(d)
      }
    })
  }

  update(v) {
    v = format('.0f')(v)
    this.foreground
      .transition()
      .duration(750)
      .style('fill', this.color(v))
      .call((transition) => this.arcTween(transition, v))

    this.value.transition().call(this.textTween, v)
  }

  renderGraph() {
    const d3Container = select(this.el)

    d3Container
      .select('.background')
      .datum({ endAngle: Math.PI / 2 })
      .attr('d', this.arcFunction)

    this.foreground = d3Container
      .select('.foreground')
      .datum({ endAngle: -Math.PI / 2 })
      .attr('d', this.arcFunction)

    this.value = d3Container.select('.value')
    this.updateGraph()
  }

  updateGraph() {
    this.update(this.props.value)
  }

  renderChildren() {
    return (
      <svg width={this.radius * 2} height={this.radius + 20}>
        <g transform={`translate(${this.radius}, ${this.radius})`}>
          <path className='background' fill='#eee' />
          <path className='foreground' style={{ fill: '#F44336' }} />
        </g>
        <g transform={`translate(${this.radius}, ${this.radius * 0.9})`}>
          <text
            textAnchor='middle'
            fill='#555'
            style={{ fontSize: '19px', fontWeight: 400 }}
            className='value'
          ></text>
        </g>
        <g
          transform={`translate(${this.radius}, ${this.radius + 15})`}
          style={{ fontSize: '11px', fill: '#999' }}
        >
          <text textAnchor='middle' x={this.radius - this.thickness / 2}>
            100
          </text>
          <text textAnchor='middle' x={-(this.radius - this.thickness / 2)}>
            0
          </text>
        </g>
      </svg>
    )
  }
}

export default SemicircleGraph
export { SemicircleGraph }
