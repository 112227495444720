import React from 'react'
import { connect } from 'formik'
import { FormGroup } from '../../../ui'
import Error from '../../../ui/Input/Error'
import { trans } from '../../../trans'
import { SelectField } from '../../ui/Form'

export default connect((props) => {
  const { label, name, disabled = false } = props

  return (
    <div>
      <FormGroup label={label} labeltop>
        <SelectField
          name={name}
          options={[
            { value: 'm', label: trans('user.male') },
            { value: 'f', label: trans('user.female') },
          ]}
          value={props.formik.values[name]}
          onChange={(value) => props.formik.setFieldValue(name, value)}
          disabled={disabled}
          placeholder={trans('user.select-sex')}
        />

        <Error errors={props.formik.errors[name]} />
      </FormGroup>
    </div>
  )
})
