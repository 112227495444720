import React from 'react'
import { isModuleActive } from '../../store/app/instance'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import {
  reset,
  fetch,
  set,
  destroy,
  accept,
  getItems,
  isActionInProgress,
  reject,
} from '../../store/app/dashboard-settlements-to-accept'
import { isFetching } from '../../store/app/company-hierarchy/selectors'

class SettlementsToAccept extends React.Component<any, any> {
  componentDidMount() {
    const { user, resetOnMount, fetchOnMount } = this.props
    if (resetOnMount) {
      this.props.reset()
    }

    if (fetchOnMount) {
      this.props.fetch(user)
    }
  }

  componentWillUnmount() {
    const { destroyOnUnmount } = this.props
    if (destroyOnUnmount) {
      this.props.destroy()
    }
  }

  render() {
    const propsToRender = {
      items: this.props.items,
      isFetching: this.props.isFetching,
      isModuleActive: this.props.isModuleActive,
      accept: this.props.accept,
      reject: this.props.reject,
      actionInProgress: this.props.actionInProgress,
    }

    return this.props.children(propsToRender)
  }
}

const mapStateToProps = (state) => ({
  items: getItems(state),
  isFetching: isFetching(state),
  actionInProgress: isActionInProgress(state),
  isModuleActive: (name) => isModuleActive()(state, name),
})

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      reset,
      set,
      destroy,
      fetch,
      accept,
      reject,
    },
    dispatch,
  )
}

const SettlementsToAcceptManager = connect(mapStateToProps, mapDispatchToProps)(SettlementsToAccept)

export default SettlementsToAcceptManager
