import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { fetchCountries, getCountries, isLoading, reset } from './slice'

const countries =
  (resetOnMount = false, fetchOnMount = false) =>
  (Component) => {
    class Countries extends React.Component<any, any> {
      componentDidMount() {
        const { countries } = this.props

        if (resetOnMount) {
          countries.actions.reset()
        }

        if (fetchOnMount) {
          countries.actions.fetch()
        }
      }

      render() {
        return <Component<any, any> {...this.props} />
      }
    }

    const mapStateToProps = (state) => ({
      countries: getCountries(state),
      isLoading: isLoading(state),
    })

    const mapDispatchToProps = (dispatch) => {
      return bindActionCreators(
        {
          reset,
          fetch: fetchCountries,
        },
        dispatch,
      )
    }

    const mergedProps = (selectors, actions, own) => {
      return {
        ...own,
        countries: {
          selectors,
          actions,
          ...own.countries,
        },
      }
    }

    return connect(mapStateToProps, mapDispatchToProps, mergedProps)(Countries)
  }

export { countries }
export default countries
