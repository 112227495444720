import { getElements } from '../index'
import { findIndex } from 'lodash'
import { TRAVEL_TARGET_POINT } from '../../../../constants/travel'
import moment from 'moment'
import { config } from '../../../../config'

class AccomodationDateSuggester {
  constructor(state) {
    this.elements = getElements(state)

    this.state = state
  }

  predictEndDate(element, location) {
    let currentElementIndex = this.getElementIndex(element)

    for (let i = currentElementIndex + 1; i < this.elements.length; i++) {
      let iterationElement = this.elements[i]
      if (
        !(
          iterationElement.isOfType(TRAVEL_TARGET_POINT) &&
          iterationElement.location.isSame(location)
        )
      ) {
        return moment(iterationElement.getStartDate())
          .startOf('day')
          .format(config.apiDateTimeFormat)
      }
    }
  }

  getElementIndex(element) {
    let elementIndex = findIndex(this.elements, (item) => {
      return item.compareTo(element)
    })

    return elementIndex
  }
}

export { AccomodationDateSuggester }
export default AccomodationDateSuggester
