import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { selectUserAbilities } from '../containers/App/selectors'
import { getRouteByName } from '../routes'

export default function MyCardPage() {
  const abilities = useSelector(selectUserAbilities())
  const history = useHistory()

  if (abilities.mycardPayments) {
    history.replace(getRouteByName('main', 'my-card-payments'))
  } else {
    history.replace(getRouteByName('main', 'my-card-statements'))
  }

  return null
}
