import PropTypes from 'prop-types'
import React from 'react'
import { formValueSelector, change as changeForm } from 'redux-form/immutable'
import { bindActionCreators, compose } from 'redux'
import { connect } from 'react-redux'
import { fromJS, Map } from 'immutable'
import { DocumentForm as FormComponent } from '../../components/DocumentForm'
import { createAutoSaveForm, getFormValues } from '../../utils/forms'
import APIClient, { processAPIerrorResponseToFormErrors } from '../../services/APIClient'
import moment from 'moment'
import { config } from '../../config'
import { isUndefined, isEmpty, isNumber } from 'lodash'
import { withExpensesActions as connectToExpenseActions } from '../../containers/DocumentPage/ExpenseTypes/withExpensesActions'
import { get } from 'lodash'
import { withAmountLoader } from './withAmountLoader'
import { stopSubmit } from 'redux-form'
import {
  generateAccountDimensionFormValues,
  isAccountDimensionField,
  submitAccountDimension,
} from '../../store/app/account-dimensions/form'
import { getCache, setCache } from '../../store/app/account-dimensions'
import { getCurrency as getInstanceCurrency, isFeatureEnabled } from '../../store/app/instance'
import {
  fetchProjects,
  getActiveProjectsSelectOptions,
  getProjects,
} from '../../store/app/projects'
import {
  applyTransactionSuggestion,
  detachTransactionSuggestion,
  fetchTransactionSuggestions,
  getSelectedTransaction,
  getTransactionSuggestions,
  isTransactionSuggestionsLoading,
  startLoading as setLoadingTransactionSuggestion,
} from '../../store/app/transaction-suggestion'
import { HttpLink } from '../../types/http-link'
import { setDocument } from '../../store/app/document-account-page'

export const FORM_NAME = 'document-form'

// here is Form container, it has redux-form props from `reduxForm` factory and it separates API class from
// FormComponent presentation
class FormComponentContainer extends React.Component<any, any> {
  acceptHint = (hint) => {
    const { document, change, dispatch } = this.props

    return new Promise((resolve) => {
      APIClient.updateHint(document['id'], hint['id'], { accepted: true }).then(() => {
        let value = hint['value']

        if (hint['column'] === 'issue_date') {
          value = moment(value)
        }

        if (hint['column'] === 'provider_id') {
          value = parseInt(value)
        }

        dispatch(change(hint['column'], value))
        resolve(hint)
      })
    })
  }

  rejectHint = (hint) => {
    const { document } = this.props

    return new Promise((resolve) => {
      APIClient.updateHint(document['id'], hint['id'], { accepted: false }).then(() => {
        resolve(hint)
      })
    })
  }

  getExchangeRate = (date, currency) => {
    const { defaultCurrency } = this.props

    return new Promise((resolve, reject) => {
      if (defaultCurrency === currency) {
        resolve(1)
        return
      }

      APIClient.getExchangeRate(this.props.request.slug, currency).then((response) => {
        if (!isUndefined(response.data[0])) {
          resolve(response.data[0].rate)
        } else {
          reject()
        }
      })
    })
  }

  render() {
    const { component: ChildComponent } = this.props // presentation component - form with FormFields

    return (
      <ChildComponent
        {...this.props}
        acceptHint={this.acceptHint}
        rejectHint={this.rejectHint}
        getExchangeRate={this.getExchangeRate}
      />
    )
  }
}

// creates auto save form
const AutoSaveForm = createAutoSaveForm(FormComponent, {
  name: FORM_NAME,
  fieldsExcludedFromDebounce: ['transaction'],

  handleErrors: (alerts) => {
    const errors = processAPIerrorResponseToFormErrors(alerts)

    if (errors.mpks) {
      errors.mpks = {
        _error: errors.mpks.join(', '),
      }
    }

    return errors
  },

  /**
   *
   * @param name Input name
   * @param value Input value
   * @param dispatch ...
   * @param props Props from `reduxForm` factory and passed to form
   * @returns {Promise<any>}
   */
  save: (name, value, dispatch, props) => {
    const {
      document,
      setWaitingForMessage,
      amountLoader: { setLoading },
      accountDimensionItems,
      accountDimensions,
      setCache,
      setDocument,
      applyTransactionSuggestion,
      detachTransactionSuggestion,
      setLoadingTransactionSuggestion,
    } = props

    if (['gross', 'currency', 'issue_date'].includes(name)) {
      setLoadingTransactionSuggestion()
    }

    if (name === 'default_currency_gross') {
      Promise.resolve(document)
    }

    if ((name === 'gross' && value) || name === 'request_element') {
      setWaitingForMessage(true)
    }

    if (name === 'issue_date' && value) {
      value = moment(value).format(config.apiDateFormat)
    }

    if (name === 'mpks') {
      if (value.length === 1) {
        value[0].percentage = 100
      }

      const hasMain = value.some((item) => item.main)

      value = value.map((item, index) => {
        const main = hasMain ? item.main : !index ? 1 : 0

        return {
          id: item.id,
          percentage: item.percentage,
          main: item.main || main,
        }
      })
    }

    if (
      [
        'gross',
        'currency',
        'payment',
        'exchange_rate',
        'issue_date',
        'default_exchange_rate_accepted',
      ].indexOf(name) !== -1
    ) {
      setLoading(true)
    }

    if (name === 'provider') {
      name = 'provider_id'
    }

    if (name === 'transaction') {
      if (value) {
        return applyTransactionSuggestion(value)
      }

      return detachTransactionSuggestion()
    }

    if (isAccountDimensionField(name)) {
      return submitAccountDimension({
        name,
        value,
        setCache,
        dimensions: accountDimensions,
        items: accountDimensionItems,
        updateMethod: (dim_id) =>
          APIClient.updateDocumentAccountDimension(document.id, dim_id, value.id),
        deleteMethod: (dim_id) => APIClient.deleteDocumentAccountDimension(document.id, dim_id),
      })
    }

    // resolved with success and pass data
    // rejected with failure and reject data
    return new Promise((resolve, reject) => {
      APIClient.saveDocument(document.id, { [name]: value })
        .then((response) => {
          const { data } = response
          dispatch(
            changeForm(
              FORM_NAME,
              'default_exchange_rate_accepted',
              data.default_exchange_rate_accepted,
            ),
          )

          resolve(data)
          setLoading(false)
          setDocument({ ...document, ...data })
        })
        .catch((response) => {
          const errors = processAPIerrorResponseToFormErrors(response.alerts, true)
          dispatch(stopSubmit(FORM_NAME, errors))

          reject(response.alerts)
        })
    })
  },
  container: FormComponentContainer, // container with API logic
})

AutoSaveForm.propTypes = {
  document: PropTypes.object.isRequired,
  travelElements: PropTypes.array.isRequired,
  defaultCurrency: PropTypes.string.isRequired,
  accountDimensions: PropTypes.array.isRequired,
}

const mapStateToProps = (state, props) => {
  const selector = formValueSelector(FORM_NAME)
  const {
    document,
    travelElements,
    currencies,
    defaultCurrency,
    accountDimensions = [],
    amountLoader,
  } = props
  const { accountDimensionItems = [] } = document
  const projects = getActiveProjectsSelectOptions(state, [document.request.project_id])
  const transaction = getSelectedTransaction(state)
  const data = getFormValues(FORM_NAME, state)

  return {
    initialValues: fromJS({
      type: document['type'],
      document_number: document['document_number'],
      issue_date: document['issue_date'],
      annotation: document['annotation'],
      currency: document['currency'] ? document['currency']['code'] : defaultCurrency,
      gross: document['gross'],
      exchange_rate: document['exchange_rate'],
      default_exchange_rate: document['default_exchange_rate'],
      payment: document['payment'],
      provider_id: get(document, 'provider.id', null),
      provider: get(document, 'provider', null),
      request_element: document['request_elements'][0],
      can_edit_exchange_rate: document['can_edit_exchange_rate'],
      default_exchange_rate_accepted: document['default_exchange_rate_accepted'],
      mpks: document['mpks'],
      transaction: transaction?.transaction.id,
      project_id: document['project_id'],
      ...generateAccountDimensionFormValues(accountDimensions, accountDimensionItems),
    }),
    data, // required for autosave
    getValue: (field) => selector(state, field),
    document,
    travelElements,
    currencies,
    accountDimensions,
    // providers,
    defaultCurrency,
    transactionSuggestions: getTransactionSuggestions(state),
    accountDimensionItems: getCache(state)(FORM_NAME),
    transactionSuggestionsLoading: isTransactionSuggestionsLoading(state),
    suggested_document_element_type: document['suggested_document_element_type'],
    amountLoader,
    hasMpkFeatureEnabled: isFeatureEnabled('FEATURE_MANAGE_DOCUMENT_MPK_ENABLED')(state),
    hasProjectFeatureEnabled: isFeatureEnabled('FEATURE_MANAGE_DOCUMENT_PROJECT_ENABLED')(state),
    projects,
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      setCache: (data) => setCache({ name: FORM_NAME, data }),
      fetchProjects,
      setDocument,
      fetchTransactionSuggestions,
      applyTransactionSuggestion,
      detachTransactionSuggestion,
      setLoadingTransactionSuggestion,
    },
    dispatch,
  )
}

const withConnect = connect(mapStateToProps, mapDispatchToProps)
const withExpensesActions = connectToExpenseActions()

const DocumentForm = compose(withConnect, withExpensesActions, withAmountLoader)(AutoSaveForm)

export { DocumentForm }
export default { DocumentForm }
