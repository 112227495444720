import React from 'react'
import { bindActionCreators } from 'redux'
import { connect as reduxConnect } from 'react-redux'
import { isTripDidNotHavePlace } from '../../store/app/trip-did-not-have-place/selectors'
import { isDelegation } from '../../store/app/non-delegation/selectors'
import {
  reset,
  set,
  setTripDidNotHavePlace,
} from '../../store/app/trip-did-not-have-place/creators'
import { ProviderContext } from './context'

const enhanced = (Component) => {
  const TripDidNotHavePlaceConnect = (props) => <Component<any, any> {...props} />

  const mapStateToProps = (state) => {
    return {
      isTripDidNotHavePlace: isTripDidNotHavePlace(state),
      isDelegation: isDelegation(state),
    }
  }

  const mapDispatchToProps = (dispatch, props) => {
    const { context } = props

    return bindActionCreators(
      {
        reset,
        set,
        setTripDidNotHavePlace: setTripDidNotHavePlace(context.request),
      },
      dispatch,
    )
  }

  const mergedProps = (selectors, actions, own) => {
    return {
      ...own,
      tripDidNotHavePlace: {
        selectors,
        actions,
        ...own.tripDidNotHavePlace,
      },
      delegation: {
        selectors,
        actions,
        ...own.delegation,
      },
    }
  }

  return reduxConnect(mapStateToProps, mapDispatchToProps, mergedProps)(TripDidNotHavePlaceConnect)
}

const connect = (Component) => {
  const Enhanced = enhanced(Component)
  return (props) => (
    <ProviderContext.Consumer>
      {(context) => <Enhanced context={context} {...props} />}
    </ProviderContext.Consumer>
  )
}

export default connect
export { connect }
