import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import {
  getExpenseRequestData,
  getExpenseRequestIsLoaded,
  reset,
  setExpenseRequest,
  accountDocument,
} from '../../store/app/expense-request'
import { getCurrency as getInstanceCurrency } from '../../store/app/instance/index'

class ExpenseRequestManagerBase extends React.Component<any, any> {
  componentDidMount() {
    const { reset, setExpenseRequest, expenseRequest } = this.props

    reset()
    setExpenseRequest(expenseRequest)
  }

  render() {
    const { children, expenseRequestInternal, isLoaded, instanceCurrency, currentUser } = this.props

    const renderProps = {
      expenseRequest: expenseRequestInternal,
      isExpenseRequestLoaded: isLoaded,
      instanceCurrency,
      currentUser,
    }

    return children(renderProps)
  }
}

ExpenseRequestManagerBase.propTypes = {
  expenseRequest: PropTypes.object,
  children: PropTypes.func,
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ reset, setExpenseRequest }, dispatch)
}

const mapStateToProps = (state) => ({
  expenseRequestInternal: getExpenseRequestData(state),
  isLoaded: getExpenseRequestIsLoaded(state),
  instanceCurrency: getInstanceCurrency(state),
  currentUser: state.get('global').get('currentUser'),
})

const ExpenseRequestManager = connect(
  mapStateToProps,
  mapDispatchToProps,
)(ExpenseRequestManagerBase)

export { ExpenseRequestManager }
export default { ExpenseRequestManager }
