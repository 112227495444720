import React, { memo } from 'react'
import numeral from 'numeral'
import { config } from '../../config'
import { isNil } from 'lodash'

interface AmountFormatterProps {
  amount: number
  format?: string
}

const AmountFormatter = memo(({ amount, format }: AmountFormatterProps) => {
  const amountObject = numeral(isNil(amount) ? '0.00' : amount)
  const formatted = amountObject.format(format ? format : config.moneyFormat).replace('.', ',')

  return <span dangerouslySetInnerHTML={{ __html: formatted }} />
})

export { AmountFormatter }
export default { AmountFormatter }
