import React from 'react'

class Path extends React.Component<any, any> {
  render() {
    const { children } = this.props

    return <div className='path'>{children}</div>
  }
}

export { Path }
export default { Path }
