import PropTypes from 'prop-types'
import React from 'react'
import trans from '../../../trans'
import Button from '../../ui/ButtonComponent'
import { REQUEST_DECISIONS } from '../../../store/app/request-action/index'
import { Col, Row } from '../../../ui/Grid/index'
import { TripRequestSummary } from '../TripRequestSummary'
import { RequestActionManager } from '../../../containers/RequestActionManager/index'
import { RequestAction } from '../../RequestAction/index'
import { Radio } from '../../../ui/index'
import { RouteManager } from '../../../containers/RouteManager/index'
import { RequestComments } from '../../RequestPageCommon/RequestComments'
import { Ability } from '../../RequestPageCommon/Ability/Ability'
import { RequestHeader } from '../../RequestPageCommon/RequestHeader'
import { TimelineElementsCompliance } from '../TimelineElementsCompliance'
import { withRouter } from 'react-router-dom'
import { TripTimeline } from '../../TripTimeline/TripTimeline'
import RequestCommentManager from '../../../containers/RequestCommentManager/RequestCommentManager'
import BasicRequestInfoRenderer from '../../RequestBasicInfoRenderer/RequestBasicInfoRenderer'
import TripPlanDocuments from '../TripPlanDocuments/TripPlanDocuments'

class TripRequestPageWaitingForAcceptanceComponent extends React.Component<any, any> {
  render() {
    const { request, cancelRequest, currencies, instanceCurrency } = this.props

    const cancelAbilityClasses = ['button-group', request.abilities.accept && 'button-group--fake']
      .filter((className) => className)
      .join(' ')

    const view = request.abilities.view && !request.abilities.edit
    const edit = request.abilities.edit

    return (
      <RouteManager>
        {({ push, getRouteByName }) => (
          <div>
            <RequestHeader request={request} />

            <BasicRequestInfoRenderer />
            <section className='section'>
              {view && <TripPlanDocuments request={request} instanceCurrency={instanceCurrency} />}

              {edit && (
                <>
                  <header className='section__header'>
                    <h2 className='h2 section__header-title'>{trans('request.trip-plan')}</h2>
                  </header>
                  <TripTimeline request={request} currencies={currencies} />
                </>
              )}
            </section>
            <TripRequestSummary request={request} />
            <TimelineElementsCompliance />
            <Ability ability={['reject', 'returnToImprovement', 'accept']} comparator='or'>
              <RequestActionManager request={request}>
                {({ comment, decision, onChange, onAction }) => (
                  <RequestAction
                    comment={comment}
                    decision={decision}
                    onChange={onChange}
                    onAction={onAction}
                  >
                    <Row>
                      <Col xs={12} is_pull_end>
                        <Ability ability={['reject']}>
                          <Radio
                            name='decision'
                            label={trans('request.reject')}
                            onClick={() => onChange('decision', REQUEST_DECISIONS.REJECT)}
                            checked={decision === REQUEST_DECISIONS.REJECT}
                          />
                        </Ability>

                        <Ability ability={['returnToImprovement']}>
                          <Radio
                            name='decision'
                            label={trans('request.return-to-improvement')}
                            onClick={() =>
                              onChange('decision', REQUEST_DECISIONS.RETURN_TO_IMPROVEMENT)
                            }
                            checked={decision === REQUEST_DECISIONS.RETURN_TO_IMPROVEMENT}
                          />
                        </Ability>

                        <Radio
                          name='decision'
                          label={trans('global.print-accounting-documents')}
                          onClick={() => {
                            onChange('decision', REQUEST_DECISIONS.PRINT)
                          }}
                        />

                        <Ability ability={['accept']}>
                          <Radio
                            name='decision'
                            label={trans('request.accept')}
                            onClick={() => onChange('decision', REQUEST_DECISIONS.ACCEPT)}
                            checked={decision === REQUEST_DECISIONS.ACCEPT}
                          />
                        </Ability>
                      </Col>
                    </Row>
                  </RequestAction>
                )}
              </RequestActionManager>
            </Ability>
            <RequestCommentManager request={request}>
              {({ comments }) => {
                return <RequestComments comments={comments} />
              }}
            </RequestCommentManager>
            <div className={cancelAbilityClasses}>
              <Ability ability={['cancel']}>
                <Button
                  onClick={(e) => {
                    e.preventDefault()
                    cancelRequest().then(() => {
                      this.props.history.goBack()
                    })
                  }}
                  link
                >
                  {trans('ui.cancel-request')}
                </Button>
              </Ability>
            </div>
          </div>
        )}
      </RouteManager>
    )
  }
}

TripRequestPageWaitingForAcceptanceComponent.propTypes = {
  request: PropTypes.object.isRequired,
  currentUser: PropTypes.object.isRequired,
  cancelRequest: PropTypes.func.isRequired,
  isModuleActive: PropTypes.func.isRequired,
}

export const TripRequestPageWaitingForAcceptance = withRouter(
  TripRequestPageWaitingForAcceptanceComponent,
)
export default { TripRequestPageWaitingForAcceptance }
