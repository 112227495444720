import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import { actions, selectors } from '../../store/app/edge-points'
import { bindActionCreators } from 'redux'
import { getLocation } from '../../store/app/instance'
import EdgePointsDateSuggester from '../../store/app/trip-timeline/services/edge-points-date-suggester'
import moment from 'moment'
import { getElements } from '../../store/app/trip-timeline'
import { TRAVEL_TARGET_POINT } from '../../constants/travel'

class EdgePointManagerBase extends React.Component<any, any> {
  componentDidMount() {
    const { request, initData, reset } = this.props
    reset()
    initData(request)
  }

  onLocationChange(point, location) {
    const { request, setLocation } = this.props
    return setLocation(request, point, location)
  }

  onDateChange(point, date) {
    const { request, setDateAndSave } = this.props
    setDateAndSave(request, point, date)
  }

  render() {
    const { children, start, end, startMaxDate, endMinDate, instanceLocation, disabled } =
      this.props

    const renderProps = {
      start,
      end,
      startMaxDate,
      endMinDate,
      disabled,
      onLocationChange: this.onLocationChange.bind(this),
      onDateChange: this.onDateChange.bind(this),
      instanceLocation: instanceLocation,
    }

    return children(renderProps)
  }
}

EdgePointManagerBase.propTypes = {
  children: PropTypes.func.isRequired,
  request: PropTypes.object.isRequired,
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ ...actions }, dispatch)
}

const mapStateToProps = (state) => {
  const dateSuggester = new EdgePointsDateSuggester(state)
  const elements = getElements(state)
    .filter((element) => element.type === TRAVEL_TARGET_POINT)
    .filter((element) => !element.draft)
  const startPoint = selectors.getStartPoint(state)
  const endPoint = selectors.getEndPoint(state)
  const startMaxDate = dateSuggester.getStartPointMaxDate()
  let endMinDate = dateSuggester.getEndPointMinDate()

  if (!startPoint.date.saved && startMaxDate) {
    startPoint.date.value = startMaxDate
  }

  if (!endPoint.date.saved && endMinDate) {
    endPoint.date.value = endMinDate
  }

  if (!endPoint.date.saved && moment(endPoint.date.value) < moment(startPoint.date.value)) {
    endPoint.date.value = startPoint.date.value
  }

  if (!endMinDate) {
    endMinDate = startPoint.date.value
  }

  return {
    start: startPoint,
    end: endPoint,
    startMaxDate,
    endMinDate,
    disabled: !elements.length,
    instanceLocation: getLocation(state),
  }
}

export const EdgePointManager = connect(mapStateToProps, mapDispatchToProps)(EdgePointManagerBase)
