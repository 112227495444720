import React from 'react'
import { Helmet } from 'react-helmet'
import { BreadCrumbs } from '../../../containers'
import { Section } from '../../ui/Section'
import { SectionHeader } from '../../ui/SectionHeader'
import { RouteManager } from '../../../containers/RouteManager'
import { getRouteByName } from '../../../routes'
import { Link } from 'react-router-dom'
import trans from '../../../trans'
import UserCreateForm from '../../../containers/UserCreateForm'

const UserCreatePage = () => (
  <div>
    <BreadCrumbs>
      <Link to={getRouteByName('main', 'dashboard')}>{trans('global.dashboard')}</Link>
      <Link to={getRouteByName('main', 'instance-settings')}>
        {trans('instance-settings.instance-settings')}
      </Link>
      <Link to={getRouteByName('main', 'users')}>{trans('main-menu.users')}</Link>
      <Link to={getRouteByName('main', 'createUser')}>{trans('instance-users.new')}</Link>
    </BreadCrumbs>

    <Section noBorder>
      <Helmet title={trans('instance-users.new')} />

      <SectionHeader caption={trans('instance-users.new')} />

      <RouteManager>
        {({ getRouteByName, push }) => (
          <UserCreateForm getRouteByName={getRouteByName} push={push} />
        )}
      </RouteManager>
    </Section>
  </div>
)

export default UserCreatePage
