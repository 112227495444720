import React from 'react'
import { connect } from 'formik'
import { AutoCompleteGeocoding, FormGroup } from '../../../ui'
import Error from '../../../ui/Input/Error'
import { get } from 'lodash'

export default connect((props) => {
  const { label, name } = props

  return (
    <div>
      <FormGroup label={label} labeltop>
        <AutoCompleteGeocoding
          name={name}
          inputProps={{
            value: props.formik.values[name],
            onChange: (address) => {
              props.formik.setFieldValue(name, address)
            },
          }}
        />
        <Error errors={get(props, `formik.errors.${name}.formatted_address`, [])} />
      </FormGroup>
    </div>
  )
})
