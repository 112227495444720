import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import { setDate } from '../../store/app/edge-points'
import { bindActionCreators, compose } from 'redux'
import {
  getBalance,
  getSummary,
  reset,
  setBalance,
  setSummary,
} from '../../store/app/trip-request-summary'
import {
  changeRequestValue,
  setLumpSumsDeclaration,
  tryToUnlockWaitingForWebSocketEvent,
} from '../../store/app/trip-request'
import socketProvider from '../SocketProvider'
import { getCurrentAssistant, getCurrentUser } from '../../store/app/user-profile/userProfileSlice'
import store from '../../store'
import { setElements } from '../../store/app/request-other-costs'

class RequestSummaryManagerBase extends React.Component<any, any> {
  componentDidMount() {
    const {
      reset,
      setSummary,
      setBalance,
      request: { slug, balance, basicSummary },
    } = this.props

    reset()
    setSummary(basicSummary)
    setBalance(balance)

    this.subscribe(slug)
  }

  componentWillUnmount() {
    const { socket, reset } = this.props

    socket.unsubscribe(this.summaryChannelName)(this.summaryChangedEventName)
    socket.unsubscribe(this.lumpChannelName)(this.lumpChangedEventName)

    reset()
  }

  subscribe(slug) {
    const {
      setSummary,
      setBalance,
      socket,
      changeRequestValue,
      setDate,
      setElements,
      tryToUnlockWaitingForWebSocketEvent,
      setLumpSumsDeclaration,
    } = this.props
    const proxyUser = getCurrentAssistant(store.getState())

    const userSlug = proxyUser && proxyUser.slug ? proxyUser.slug : this.props.currentUser.slug

    this.summaryChannelName = `App.Request.${slug}.User.${userSlug}`
    this.summaryChangedEventName = `.App\\Events\\RequestSummaryChanged`

    this.lumpChannelName = `App.Request.${slug}`
    this.lumpChangedEventName = `.App\\Events\\Request\\RequestLumpSumDeclarationChangedEvent`

    socket.subscribe(this.summaryChannelName)(this.summaryChangedEventName)(
      ({ basicSummary, balance, trip_starts, trip_ends, abilities, costs, lock_uuid }) => {
        changeRequestValue(['abilities'], abilities)
        setSummary(basicSummary)
        setBalance(balance)
        changeRequestValue(['trip_starts'], trip_starts)
        changeRequestValue(['trip_ends'], trip_ends)

        setDate('start', trip_starts)
        setDate('end', trip_ends)
        setElements(costs)
        tryToUnlockWaitingForWebSocketEvent(lock_uuid)
      },
    )

    socket.subscribe(this.lumpChannelName)(this.lumpChangedEventName)(({ socket, ...lump }) => {
      setLumpSumsDeclaration(lump)
    })
  }

  render() {
    const { children, internalSummary, internalBalance, request } = this.props

    const renderProps = {
      summary: internalSummary,
      balance: internalBalance,
      request,
    }

    return children(renderProps)
  }
}

RequestSummaryManagerBase.propTypes = {
  children: PropTypes.func,
  request: PropTypes.object.isRequired,
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      reset,
      setSummary,
      setBalance,
      changeRequestValue,
      setLumpSumsDeclaration,
      tryToUnlockWaitingForWebSocketEvent,
      setDate,
      setElements,
    },
    dispatch,
  )
}

const mapStateToProps = (state) => ({
  internalSummary: getSummary(state),
  internalBalance: getBalance(state),
  currentUser: getCurrentUser(state),
})

const withConnect = connect(mapStateToProps, mapDispatchToProps)
const withSocketProvider = socketProvider()

const TripRequestSummaryManager = compose(
  withSocketProvider,
  withConnect,
)(RequestSummaryManagerBase)

export default TripRequestSummaryManager
export { TripRequestSummaryManager }
