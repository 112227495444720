import React from 'react'

class ToggleableBottomFilters extends React.PureComponent<any, any> {
  render() {
    const { children, isOpen } = this.props

    if (!isOpen) {
      return null
    }

    return (
      <div className='filters__tooltip'>
        <div className='filters__bottom'>{children}</div>
      </div>
    )
  }
}

export default ToggleableBottomFilters
export { ToggleableBottomFilters }
