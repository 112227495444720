import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { TimelineManager } from '../../../containers/TripTimeline/index'
import { trans } from '../../../trans'
import { TimelineManagerOverlay } from '../../../containers/TripTimeline/TimelineManagerOverlay'
import {
  TRAVEL_ACCOMODATION,
  TRAVEL_PLANE_TRIP,
  TRAVEL_TARGET_POINT,
  TRAVEL_TRAIN_TRIP,
} from '../../../constants/travel'
import { TimelineElement, TimeLineElementContent } from '../../Timeline'
import { RequestDocuments } from '../../RequestPageCommon/RequestDocuments'
import { StorableTimelineElement } from '../../Timeline/StorableTimelineElement'
import { Loader } from '../../ui/LoadingOverlay/Loader'
import { SectionHeader } from '../../ui/SectionHeader'
import Header from './Header'
import Booking from '../Booking'
import { includes } from 'lodash'

class TripPlanDocuments extends Component<any, any> {
  constructor(props) {
    super(props)
    this.ref = React.createRef()
  }

  renderDocuments(element) {
    const { request } = this.props
    return (
      <RequestDocuments
        element={element}
        documents={element['documents']}
        request={request}
        listName={`${element.type}-${element.id}`}
      />
    )
  }

  renderContent(element) {
    if (element.isLumpSum()) {
      return null
    }

    if (includes([TRAVEL_ACCOMODATION, TRAVEL_TRAIN_TRIP, TRAVEL_PLANE_TRIP], element.type)) {
      return this.renderBooking(element)
    }

    return this.renderDocuments(element)
  }

  renderBooking = (element) => {
    const { request } = this.props
    return <Booking element={element} request={request} />
  }

  isElementOpen = (isOpen, element) => {
    if (element.type === TRAVEL_PLANE_TRIP && element.virtual) {
      return false
    }

    return isOpen && !element.isLumpSum()
  }

  renderElements(elements) {
    const { request, instanceCurrency } = this.props

    return elements.map((element, i) => {
      return (
        <TimelineElement
          key={element.key}
          last={i + 1 === elements.length}
          ref={this.ref}
          type={element.type}
        >
          <StorableTimelineElement storageKey={element.key} defaultState={'open'}>
            {({ isOpen, toggle }) => {
              return (
                <div>
                  <Header
                    request={request}
                    element={element}
                    isOpen={isOpen}
                    toggle={toggle}
                    instanceCurrency={instanceCurrency}
                  />

                  <TimeLineElementContent isOpened={this.isElementOpen(isOpen, element)}>
                    <div className='tooltip-content'>{this.renderContent(element)}</div>
                  </TimeLineElementContent>
                </div>
              )
            }}
          </StorableTimelineElement>
        </TimelineElement>
      )
    })
  }

  render() {
    const { request } = this.props

    return (
      <TimelineManagerOverlay>
        {({ isRecomposition }) => {
          return (
            <TimelineManager request={request} excludedTypes={[TRAVEL_TARGET_POINT]}>
              {({ elements }) => {
                if (!elements.length) {
                  return null
                }

                return (
                  <div>
                    <SectionHeader caption={trans('request.trip-plan')} />
                    <div className='has-loader'>
                      {isRecomposition && <Loader />}
                      <div className='accordion__content'>
                        <div className='timeline-container timeline-container--trip-plan trip-plan--documents'>
                          <div>{this.renderElements(elements)}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                )
              }}
            </TimelineManager>
          )
        }}
      </TimelineManagerOverlay>
    )
  }
}

TripPlanDocuments.propTypes = {
  request: PropTypes.object.isRequired,
  instanceCurrency: PropTypes.string.isRequired,
}

export { TripPlanDocuments }
export default TripPlanDocuments
