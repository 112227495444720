import { ReactElement } from 'react'
import useMeansOfTransports from './useMeansOfTransports'

export default function MeansOfTransportsManager({
  slug,
  children,
}: {
  slug: string
  children: (props: {
    availableOptions: { label: string; value: string }[]
    requestOptions: string[]
    hasPrivateCar: boolean
    saveRequestOptions: (types: string[]) => Promise<any>
    isLoading: boolean
  }) => ReactElement
}) {
  const { availableOptions, requestOptions, hasPrivateCar, saveRequestOptions, isLoading } =
    useMeansOfTransports(slug)

  if (isLoading) {
    return null
  }

  return children({
    availableOptions,
    requestOptions,
    hasPrivateCar,
    saveRequestOptions,
  })
}
