import React, { Component } from 'react'
import trans from '../../../trans'
import AssistantsTable from './partials/AssistantsTable'
import AssistantsForm from './partials/AssistantsForm'
import PropTypes from 'prop-types'

class Assistants extends Component<any, any> {
  render() {
    return (
      <div className='substitutions'>
        <div className='substitutions__header'>
          <h2 className='tabs__content-heading substitutions__title'>
            {trans('user.set-assistant')}
          </h2>
        </div>

        <AssistantsForm
          subjectUser={this.props.subjectUser}
          isFromExternal={this.props.isFromExternal}
        />

        <div className='substitutions__header'>
          <h2 className='tabs__content-heading substitutions__title'>
            {trans('user.assistants-list')}
          </h2>
        </div>

        <AssistantsTable
          subjectUser={this.props.subjectUser}
          isFromExternal={this.props.isFromExternal}
        />
      </div>
    )
  }
}

Assistants.propTypes = {
  subjectUser: PropTypes.object,
}

export default Assistants
