import PropTypes from 'prop-types'
import React from 'react'
import {
  fetchRequest,
  fetchCurrencies,
  getRequestData,
  getCurrenciesData,
  sendToAcceptance,
  sendToSettlement,
  sendToAcceptanceOfSettlement,
  reset,
  deleteRequest,
  cancelRequest,
  changeRequestValue,
  updateRequest,
  saveDecision,
  createDraft,
  unrealizeTrip,
} from '../../store/app/trip-request'
import { getCurrency, isModuleActive } from '../../store/app/instance'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { get } from 'lodash'
import { Map } from 'immutable'
import { getRouteByName } from '../../routes'
import { withRouter } from 'react-router-dom'

class TripRequestManagerBase extends React.Component<any, any> {
  constructor(props) {
    super(props)

    this.sendToAcceptance = this.sendToAcceptance.bind(this)
    this.sendToSettlement = this.sendToSettlement.bind(this)
    this.sendToAcceptanceOfSettlement = this.sendToAcceptanceOfSettlement.bind(this)
    this.deleteRequest = this.deleteRequest.bind(this)
    this.cancelRequest = this.cancelRequest.bind(this)
    this.changeRequestValue = this.changeRequestValue.bind(this)
    this.updateRequest = this.updateRequest.bind(this)
    this.saveDecision = this.saveDecision.bind(this)
    this.fetchRequest = this.fetchRequest.bind(this)
  }

  componentDidMount() {
    const {
      fetchInitial,
      fetchRequest,
      fetchCurrencies,
      requestType,
      createPeriodic,
      createDraft,
      history: { replace },
    } = this.props

    if (fetchInitial) {
      fetchRequest(fetchInitial)
      fetchCurrencies()
    } else {
      createDraft({ type: requestType, periodic: createPeriodic }).then((request) => {
        replace(getRouteByName('main', `${requestType}RequestShow`, { id: request['slug'] }))
      })
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { fetchInitial: prevFetchInitial } = prevProps
    const { fetchInitial, fetchRequest, fetchCurrencies, reset } = this.props

    if (fetchInitial !== prevFetchInitial) {
      reset()
      fetchRequest(fetchInitial)
      fetchCurrencies()
    }
  }

  componentWillUnmount() {
    const { reset } = this.props

    reset()
  }

  sendToAcceptance(chronologyConfirmed = false, showAlerts = true) {
    const {
      sendToAcceptance,
      requestData: { data: request },
    } = this.props

    return sendToAcceptance(request, { chronologyConfirmed: chronologyConfirmed }, showAlerts)
  }

  sendToSettlement() {
    const {
      sendToSettlement,
      requestData: { data: request },
    } = this.props

    return sendToSettlement(request, {})
  }

  sendToAcceptanceOfSettlement() {
    const {
      sendToAcceptanceOfSettlement,
      requestData: { data: request },
    } = this.props

    return sendToAcceptanceOfSettlement(request, {})
  }

  deleteRequest() {
    const {
      deleteRequest,
      requestData: { data: request },
    } = this.props

    return deleteRequest(request)
  }

  cancelRequest() {
    const {
      cancelRequest,
      requestData: { data: request },
    } = this.props

    return cancelRequest(request)
  }

  changeRequestValue(path, value) {
    const { changeRequestValue } = this.props

    changeRequestValue(path, value)
  }

  updateRequest(fields = {}) {
    const {
      updateRequest,
      requestData: { data: request },
    } = this.props

    return updateRequest(request, fields)
  }

  saveDecision(fields = {}) {
    const {
      saveDecision,
      requestData: { data: request },
    } = this.props

    return saveDecision(request, fields)
  }

  fetchRequest(slug) {
    const { fetchRequest } = this.props

    return fetchRequest(slug, true)
  }

  render() {
    const {
      children,
      requestData,
      currenciesData,
      instanceCurrency,
      currentUser,
      isModuleActive,
      unrealizeTrip,
    } = this.props

    const {
      data: requestJSON,
      isLoading: isRequestLoading,
      isLoaded: isRequestLoaded,
      isUnrealizeInProgress,
      ws,
    } = requestData
    const {
      data: currenciesJSON,
      isLoading: areCurrenciesLoading,
      isLoaded: areCurrenciesLoaded,
    } = currenciesData

    const renderProps = {
      request: requestJSON,
      isRequestLoaded,
      isRequestLoading,
      isUnrealizeInProgress,
      ws,
      currencies: currenciesJSON,
      areCurrenciesLoaded,
      areCurrenciesLoading,
      sendToAcceptance: this.sendToAcceptance,
      sendToSettlement: this.sendToSettlement,
      sendToAcceptanceOfSettlement: this.sendToAcceptanceOfSettlement,
      deleteRequest: this.deleteRequest,
      cancelRequest: this.cancelRequest,
      changeRequestValue: this.changeRequestValue,
      updateRequest: this.updateRequest,
      saveDecision: this.saveDecision,
      fetchRequest: this.fetchRequest,
      instanceCurrency,
      isModuleActive,
      currentUser,
      unrealizeTrip,
    }

    return children(renderProps)
  }
}

TripRequestManagerBase.propTypes = {
  children: PropTypes.func.isRequired,
  fetchInitial: PropTypes.string,
  requestType: PropTypes.string,
  createPeriodic: PropTypes.bool,
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      fetchRequest,
      fetchCurrencies,
      reset,
      sendToAcceptance,
      sendToSettlement,
      sendToAcceptanceOfSettlement,
      deleteRequest,
      cancelRequest,
      changeRequestValue,
      updateRequest,
      saveDecision,
      createDraft,
      unrealizeTrip,
    },
    dispatch,
  )
}

const mapStateToProps = (state) => {
  const instanceCurrency = getCurrency(state)
  const currentUser = get(Map(state.get('global')).toJS(), 'currentUser', {})

  return {
    requestData: getRequestData(state),
    currenciesData: getCurrenciesData(state),
    instanceCurrency,
    currentUser,
    isModuleActive: (name) => isModuleActive()(state, name),
  }
}
const withConnectedRouter = withRouter(TripRequestManagerBase)
const TripRequestManager = connect(mapStateToProps, mapDispatchToProps)(withConnectedRouter)

export { TripRequestManager }
export default { TripRequestManager }
