import React from 'react'
import PropTypes from 'prop-types'
import { trans } from '../trans'
import Icon from './ui/IconComponent'

const EnableReservationEngine = React.memo(
  (props) => {
    return (
      <div className='reservation-engine-button' onClick={() => props.action()}>
        <Icon type='search' />
        <br />
        <span>{trans('global.back-to-search-engine')}</span>
      </div>
    )
  },
  () => true,
)

EnableReservationEngine.propTypes = {
  action: PropTypes.func.isRequired,
}

export { EnableReservationEngine }
export default EnableReservationEngine
