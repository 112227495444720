import React from 'react'
import { reduxForm, SubmissionError } from 'redux-form/immutable'
import { connect } from 'react-redux'
import { fromJS } from 'immutable'
import { LostPasswordForm as Form } from '../../components/LostPasswordForm'
import { bindActionCreators } from 'redux'
import { processAPIerrorResponseToFormErrors } from '../../services/APIClient'
import { withRouter } from 'react-router-dom'
import ApiClient from '../../services/APIClient'
import localStorage from '../../services/localStorage'
import { getCurrentLang } from '../../utils/lang'

export const FORM_NAME = 'lost-password-form'

class LostPasswordForm extends React.Component<any, any> {
  render() {
    const { ...props } = this.props

    return <Form {...props} />
  }
}

LostPasswordForm.propTypes = {}

const submit = (values, dispatch, props) => {
  const { email } = values.toJS()

  const lang = getCurrentLang()

  return new Promise((_resolve, _reject) => {
    return new Promise((resolve, reject) => {
      ApiClient.resetPassword(email, lang)
        .then((response) => {
          resolve()
        })
        .catch((response) => {
          reject(response['alerts'])
        })
    }).then(
      () => {
        _resolve(true)
      },
      (alerts) => {
        // rejected alerts
        _resolve(alerts)
      },
    )
  }).then((result) => {
    if (result !== true) {
      throw new SubmissionError(processAPIerrorResponseToFormErrors(result))
    }
    return result
  })
}

const withForm = reduxForm({
  form: FORM_NAME,
  onSubmit: submit,
  enableReinitialize: true,
})(LostPasswordForm)

const mapStateToProps = (state) => {
  return {
    initialValues: fromJS({
      email: '',
    }),
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({}, dispatch)
}

LostPasswordForm = connect(mapStateToProps, mapDispatchToProps)(withForm)
LostPasswordForm = withRouter(LostPasswordForm)

export { LostPasswordForm }
export default { LostPasswordForm }
