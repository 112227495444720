import React, { Fragment } from 'react'
import { Helmet } from 'react-helmet'
import { RequestMileageAllowancePageManager } from '../../containers/RequestMileageAllowancePageManager'
import { Col, Row } from '../../ui/Grid'
import { RequestMileageAllowanceElementForm } from '../../containers/RequestMileageAllowanceElementForm/RequestMileageAllowanceElementForm'
import { Panel } from '../ui/Panel'
import { LoadingOverlay } from '../ui/LoadingOverlay'
import trans from '../../trans'
import Button from '../ui/ButtonComponent'
import { RouteManager } from '../../containers/RouteManager'
import { Summary } from './Summary'
import { AccountMileageAllowanceForm } from '../../containers/AccountMileageAllowanceForm'
import _ from 'lodash'
import { AccountTravelExpenseForm } from '../../containers/AccountTravelExpenseForm'
import {
  STATUS_ACCOUNTING,
  STATUS_FINISH,
  STATUS_SETTLEMENT,
  STATUS_TRANSFER_ERROR,
  STATUS_TRANSFERRED,
  STATUS_TRIP,
  STATUS_UPCOMING_TRIP,
} from '../../constants/request'
import { Ability } from '../RequestPageCommon/Ability/Ability'
import { Link } from 'react-router-dom'
import { RequestExpenseCommute } from '../../widgets/RequestExpenseCommute'
import { BorderCrossings } from '../BorderCrossings/BorderCrossings'
import Compliance from './Compliance'
import BreadCrumbs from '../../containers/BreadCrumbs'
import { getMileageAllowancePageBreadcrumbs } from '../../breadcrumbs'
import AvoidUpdate from '../../containers/AvoidUpdate'
import GoogleDirections from '../GoogleDirections'
import NonDelegationWrapper from './NonDelegationWrapper'
import DelegationSwitcher from '../DelegationSwitcher'
import APIClient from '../../services/APIClient'
import { fetchRequest } from '../../store/app/request-mileage-allowance'
import store from '../../store'
import TripDidNotHavePlaceSwitcher from '../TripDidNotTakePlaceSwitcher'
import TripDidNotHavePlaceWrapper from './TripDidNotHavePlaceWrapper'
import {
  connect,
  provide as tripDidNotHavePlaceProvider,
} from '../../containers/TripDidNotHavePlace'
import TripDidNotHavePlaceOverlay from '../BorderCrossings/TripDidNotHavePlaceOverlay'
import { AccountDimensionManager } from '../../containers/AccountDimensionManager'
import { Loader } from '../ui/LoadingOverlay/Loader'
import { AccountAllowanceForm } from '../../containers/AccountAllowanceForm'
import MeansOfTransportsManager from '../MeansOfTransports/MeansOfTransportsManager'

class RequestMileageAllowancePage extends React.Component<any, any> {
  isAccountant(currentUser) {
    const {
      abilities: { isAccountant },
    } = currentUser
    return isAccountant
  }

  canAccount(request) {
    return request.abilities.account
  }

  getHeightForGoogleMapPanel(request) {
    const isRoundTrip = _.get(request, 'mileageAllowances', []).some(
      (mileage) => mileage.round_trip,
    )

    return isRoundTrip ? 609 : 546
  }

  renderMileageAllowance(request, routes, currentUser, accountDimensions, createMileageAllowance) {
    const {
      tripDidNotHavePlace: {
        selectors: { isTripDidNotHavePlace },
      },
    } = this.props
    const height = this.getHeightForGoogleMapPanel(request)

    return (
      <div>
        <Row>
          <Col xs={8}>
            <h1 className='is-margin-bottom-small is-text-uppercase'>
              {trans('cover.private-car-routes-header')}
            </h1>
          </Col>
        </Row>
        <Row nowrap>
          <Col xs={6}>
            <TripDidNotHavePlaceOverlay>
              <div style={{ height: `${height}px` }}>
                <Panel
                  header={<h1>{trans('map.trip-map')}</h1>}
                  className='panel--google_map panel--full-content'
                >
                  <GoogleDirections routes={routes} />
                </Panel>
              </div>
            </TripDidNotHavePlaceOverlay>
          </Col>
          <Col xs={6}>
            {request.mileageAllowances &&
              request.mileageAllowances.map((element, index) => {
                const key = `request-mileage-allowance-element-form-${element['id']}`

                return (
                  <RequestMileageAllowanceElementForm
                    key={key}
                    element={element}
                    formName={key}
                    request={request}
                    index={index}
                    isTripDidNotHavePlace={isTripDidNotHavePlace}
                  />
                )
              })}

            {(request['status'] === STATUS_ACCOUNTING ||
              request['status'] === STATUS_TRANSFERRED ||
              request['status'] === STATUS_TRANSFER_ERROR ||
              request['status'] === STATUS_FINISH) &&
              this.isAccountant(currentUser) && (
                <>
                  {request.accountingMileageAllowances.map((allowance) => (
                    <AccountMileageAllowanceForm
                      request={request}
                      mileageAllowance={allowance}
                      accountDimensions={accountDimensions}
                    />
                  ))}

                  {this.canAccount(request) && (
                    <div className='request-mileage-allowance-page__add-button-wrapper'>
                      <Button
                        onClick={() => createMileageAllowance(request.slug)}
                        className='request-mileage-allowance-page__add-button'
                        primary
                      >
                        {trans('document.mileage-allowance-add-button')}
                      </Button>
                    </div>
                  )}
                </>
              )}
          </Col>
        </Row>
      </div>
    )
  }

  renderFinishButtons(request, setRequestAsCompleted, push) {
    const { match } = this.props

    let statusField = 'lump_sum_settled'
    if (request.status === STATUS_ACCOUNTING || request.status === STATUS_TRANSFER_ERROR) {
      statusField = 'lump_sum_accounted'
    }

    const back = () => push(this.getBackUrl(match))
    return (
      <div className='button-group'>
        {!request.lumpSumsDeclaration[statusField] ? (
          <Button secondary outline onClick={() => back()}>
            {trans('document.mileage-allowance-finish-button')}
          </Button>
        ) : null}
        <Button
          locked={!request.border_crossing_state && request.delegation && !request.unrealized}
          primary
          onClick={() => setRequestAsCompleted(request).then(() => back())}
        >
          {trans('document.mileage-allowance-finish-and-mark-as-completed')}
        </Button>
      </div>
    )
  }

  getBackUrl(match) {
    return match.url.replace('/cover', '')
  }

  isReadOnly({ status, abilities }) {
    if ((status === STATUS_ACCOUNTING || status === STATUS_TRANSFER_ERROR) && abilities.account) {
      return false
    }

    if (
      (status === STATUS_TRIP || status === STATUS_UPCOMING_TRIP || status === STATUS_SETTLEMENT) &&
      abilities.settle
    ) {
      return false
    }

    return true
  }

  renderHeader(request) {
    let header = trans('cover.settlement-header')

    if (request.status === STATUS_ACCOUNTING || request.status === STATUS_TRANSFER_ERROR) {
      header = trans('cover.accounting-header')
    }

    return (
      <div className='mileage-allowance-page--top is-margin-bottom is-margin-top'>
        <div className='mileage-allowance-page--header'>
          <h1 className='is-text-uppercase'>{header}</h1>
        </div>

        <div className='mileage-allowance-page--buttons'>
          <Ability abilities={request.abilities} ability={['delegationTripSwitch']}>
            <DelegationSwitcher className='delegation-switcher--inline' />
          </Ability>
          <TripDidNotHavePlaceSwitcher className='delegation-switcher--inline-with-top-margin' />
        </div>
      </div>
    )
  }

  renderBasicInfoForm(
    availableMeansOfTransportsOptions,
    requestMeansOfTransportsOptions,
    saveRequestMeansOfTransportsOptions,
  ) {
    const {
      match: {
        params: { id },
      },
    } = this.props

    return (
      <AccountDimensionManager fetchMethod={'allowanceHeader'} fetchArgs={[id]}>
        {({ accountDimensions, isLoaded }) => {
          if (!isLoaded) {
            return <Loader />
          }

          return (
            <AccountAllowanceForm
              slug={id}
              accountDimensions={accountDimensions}
              availableMeansOfTransportsOptions={availableMeansOfTransportsOptions}
              requestMeansOfTransportsOptions={requestMeansOfTransportsOptions}
              saveRequestMeansOfTransportsOptions={saveRequestMeansOfTransportsOptions}
            />
          )
        }}
      </AccountDimensionManager>
    )
  }

  render() {
    const {
      match: {
        params: { id },
      },
    } = this.props

    return (
      <div className={'mileage-allowance-page'}>
        <Helmet title={trans('request.lump-sum-statement-title')} />
        <RouteManager>
          {({ goBack, push, match, getRouteByName }) => (
            <RequestMileageAllowancePageManager requestId={id} push={push}>
              {({
                request,
                isLoading,
                routes,
                saveAccountingTravelExpense,
                removeAccountingTravelExpense,
                currentUser,
                currencies,
                createAccountingTravelExpense,
                setRequestAsCompleted,
                createMileageAllowance,
              }) => (
                <AccountDimensionManager fetchMethod={'requestAccounting'} fetchArgs={[id]}>
                  {({ accountDimensions, isLoaded: isLoadedAccountDimensions }) => {
                    if (isLoading || !isLoadedAccountDimensions) {
                      return <LoadingOverlay />
                    }

                    const isReadOnly = this.isReadOnly(request)

                    return (
                      <NonDelegationWrapper request={request}>
                        <TripDidNotHavePlaceWrapper request={request}>
                          <div className={isReadOnly ? 'read-only' : ''}>
                            <BreadCrumbs>
                              {getMileageAllowancePageBreadcrumbs(
                                match,
                                getRouteByName,
                                request,
                              ).map((item) => (
                                <Link key={item.url} to={item.url}>
                                  {item.label}
                                </Link>
                              ))}
                            </BreadCrumbs>

                            <Row>
                              <Col xs={12}>{this.renderHeader(request)}</Col>
                            </Row>

                            <MeansOfTransportsManager slug={request.slug}>
                              {({
                                availableOptions,
                                requestOptions,
                                hasPrivateCar,
                                saveRequestOptions,
                              }) => (
                                <>
                                  <Row>
                                    <Col xs={12}>
                                      {this.renderBasicInfoForm(
                                        availableOptions,
                                        requestOptions,
                                        saveRequestOptions,
                                      )}
                                    </Col>
                                  </Row>

                                  {hasPrivateCar && !_.isEmpty(request.mileageAllowances)
                                    ? this.renderMileageAllowance(
                                        request,
                                        routes,
                                        currentUser,
                                        accountDimensions,
                                        createMileageAllowance,
                                      )
                                    : null}
                                </>
                              )}
                            </MeansOfTransportsManager>

                            <Row>
                              <Col xs={8}>
                                <h1 className='is-margin-bottom-small is-text-uppercase'>
                                  {trans('cover.lump-sums-header')}
                                </h1>
                              </Col>
                            </Row>

                            <Row nowrap>
                              <Col xs={6}>
                                <AvoidUpdate>
                                  <BorderCrossings request={request} isReadOnly={isReadOnly} />
                                </AvoidUpdate>
                              </Col>
                              <Col xs={6}>
                                <RequestExpenseCommute request={request} isReadOnly={isReadOnly} />

                                <Summary
                                  isReadOnly={isReadOnly}
                                  request={request}
                                  defaultCurrency={currentUser['instance']['default_currency']}
                                />

                                {(request['status'] === STATUS_ACCOUNTING ||
                                  request['status'] === STATUS_TRANSFERRED ||
                                  request['status'] === STATUS_TRANSFER_ERROR ||
                                  request['status'] === STATUS_FINISH) &&
                                  this.isAccountant(currentUser) && (
                                    <Fragment>
                                      <Ability
                                        abilities={request.abilities}
                                        ability={['account']}
                                        comparator='or'
                                      >
                                        {this.renderRegenerateButton(request)}
                                      </Ability>
                                      <div>
                                        {_.isArray(request['accountingTravelExpenses']) &&
                                          request['accountingTravelExpenses'].map(
                                            (travelExpense) => {
                                              return (
                                                <AccountTravelExpenseForm
                                                  key={travelExpense['id']}
                                                  travelExpense={travelExpense}
                                                  request={request}
                                                  currencies={currencies}
                                                  formName={`account-travel-expense-form-${travelExpense['id']}`}
                                                  saveAccountingTravelExpense={
                                                    saveAccountingTravelExpense
                                                  }
                                                  removeAccountingTravelExpense={
                                                    removeAccountingTravelExpense
                                                  }
                                                  currentUser={currentUser}
                                                  accountDimensions={accountDimensions}
                                                />
                                              )
                                            },
                                          )}
                                        {_.isEmpty(request['accountingTravelExpenses']) && (
                                          <AccountTravelExpenseForm
                                            travelExpense={{}}
                                            request={request}
                                            currencies={currencies}
                                            formName={`account-travel-expense-form-new`}
                                            saveAccountingTravelExpense={
                                              saveAccountingTravelExpense
                                            }
                                            removeAccountingTravelExpense={
                                              removeAccountingTravelExpense
                                            }
                                            currentUser={currentUser}
                                          />
                                        )}

                                        {this.canAccount(request) && (
                                          <div className='request-mileage-allowance-page__add-button-wrapper'>
                                            <Button
                                              onClick={() => createAccountingTravelExpense(request)}
                                              className='request-mileage-allowance-page__add-button'
                                              primary
                                            >
                                              {trans('document.mileage-allowance-add-button')}
                                            </Button>
                                          </div>
                                        )}
                                      </div>
                                    </Fragment>
                                  )}

                                {(request['status'] === STATUS_ACCOUNTING ||
                                  request['status'] === STATUS_TRANSFER_ERROR) &&
                                  this.isAccountant(currentUser) && (
                                    <Compliance
                                      compliance={{
                                        init: {
                                          name: 'accounting-cover',
                                          rules: request.rules,
                                          socketChannel: `App.Request.Accounting.Cover.${request.slug}`,
                                          socketEvent: `.App\\Events\\AccountingTravelExpensesRulesChanged`,
                                        },
                                      }}
                                    />
                                  )}
                              </Col>
                            </Row>

                            <Row>
                              <Col xs='12' is_pull_end>
                                <Ability
                                  abilities={request.abilities}
                                  ability={['settle', 'account']}
                                  comparator='or'
                                >
                                  {this.renderFinishButtons(request, setRequestAsCompleted, push)}
                                </Ability>
                              </Col>
                            </Row>
                          </div>
                        </TripDidNotHavePlaceWrapper>
                      </NonDelegationWrapper>
                    )
                  }}
                </AccountDimensionManager>
              )}
            </RequestMileageAllowancePageManager>
          )}
        </RouteManager>
      </div>
    )
  }

  renderRegenerateButton(request) {
    return (
      <Button
        secondary
        xs
        outline
        style={{ margin: '30px auto', padding: '10px 15px' }}
        onClick={() => this.reGenerateAllowanceEntries(request)}
      >
        {trans('cover.re-generate-button')}
      </Button>
    )
  }

  reGenerateAllowanceEntries = (request) => {
    return APIClient.reGenerateAllowanceEntries(request.slug).then((response) => {
      store.dispatch(fetchRequest(request.slug))
    })
  }
}

RequestMileageAllowancePage = tripDidNotHavePlaceProvider(connect(RequestMileageAllowancePage))
export { RequestMileageAllowancePage }
export default { RequestMileageAllowancePage }
