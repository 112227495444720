import { Loader } from '../ui/LoadingOverlay/Loader'
import { useParams } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { useEffect } from 'react'
import { relogin } from '../../store/app/auth'

function Relogin() {
  const { slug } = useParams()
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(relogin(slug))
  }, [])

  return <Loader />
}

export default Relogin
export { Relogin }
