import {
  ADD_FORM_ERRORS,
  API_ERROR,
  CLEAR_FORM_ERRORS,
  CLEAR_FORM_INPUT_ERRORS,
  SET_CURRENT_USER,
  SET_LOADING,
  USER_AUTHORIZE,
  USER_LOGOUT,
} from './constants'
import { fromJS } from 'immutable'
import { LOCATION_CHANGE } from 'connected-react-router'

const initialState = fromJS({
  session: {
    isAuthenticated: null,
    token: '',
  },
  currentUser: {},
  loading: false,
  errors: {
    app: {},
    forms: {},
  },
})

const appReducer = (state = initialState, action) => {
  switch (action.type) {
    case USER_AUTHORIZE:
    case USER_LOGOUT:
      return state
        .set('session', {
          isAuthenticated: action.isAuthenticated,
          token: action.token,
        })
        .set('currentUser', action.user)
    case SET_CURRENT_USER:
      return state.set('currentUser', action.userData)
    case ADD_FORM_ERRORS:
      return state.setIn(['errors', 'forms', action.formName], action.errors)
    case CLEAR_FORM_ERRORS:
      return state.setIn(['errors', 'forms', action.formName], action.errors)
    case CLEAR_FORM_INPUT_ERRORS:
      try {
        return state.updateIn(['errors', 'forms', action.formName], (fields) => {
          let result = {}
          Object.keys(fields).forEach((key) => {
            if (key !== action.fieldKey) {
              result[key] = fields[key]
            }
          })
          return result
        })
      } catch (e) {
        return state
      }
    case LOCATION_CHANGE:
      return state.setIn(['errors', 'forms'], initialState.getIn(['errors', 'forms']))
    case SET_LOADING:
      return state.set('loading', action.value)
    case API_ERROR:
      if (action.status === 401) {
        return state.set('session', {
          isAuthenticated: false,
          token: null,
        })
      }
      return state
    default:
      return state
  }
}

export default appReducer
