import React from 'react'
import PropTypes from 'prop-types'
import TruncateMarkup from 'react-truncate-markup'
import Ellipsis from '../ui/Ellipsis/Ellipsis'

class UserName extends React.Component<any, any> {
  render() {
    let { firstName, lastName, truncateLines } = this.props

    return (
      <TruncateMarkup
        lines={truncateLines || 2}
        ellipsis={<Ellipsis text={firstName + ' ' + lastName} />}
      >
        <span>
          {firstName}
          <br />
          {lastName}
        </span>
      </TruncateMarkup>
    )
  }
}

UserName.propTypes = {
  firstName: PropTypes.string.isRequired,
  lastName: PropTypes.string.isRequired,
  truncateLines: PropTypes.number,
}

export default UserName
export { UserName }
