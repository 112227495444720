import PropTypes from 'prop-types'
import React from 'react'
import { Col, Row } from '../../ui/Grid'
import classNames from 'classnames'
import trans from '../../trans'
import { AmountFormatter } from '../AmountFormatter'

class ExchangeSummary extends React.Component<any, any> {
  render() {
    const items = this.props.partials.map((item, key) => {
      return (
        <Row key={key}>
          <Col xs={6}>
            <span>w tym:</span>
          </Col>
          <Col xs={6} is_pull_end>
            <div>
              <AmountFormatter amount={item} />
              <span> {key}</span>
            </div>
          </Col>
        </Row>
      )
    })

    const summaryClasses = classNames({
      'exchange-summary': true,
      'exchange-summary--loading': this.props.isLoading,
    })

    return (
      <div className={summaryClasses}>
        <div className='exchange-summary__content'>
          <Row>
            <Col xs={6}>
              <span>{trans('request-summary.requested-amount')}</span>
            </Col>
            <Col xs={6} is_pull_end>
              <span>
                <AmountFormatter amount={this.props.calculatedValue} /> {this.props.currency}
              </span>
            </Col>
          </Row>
          <div className='exchange-summary__partials'>{items}</div>
        </div>
      </div>
    )
  }
}

ExchangeSummary.propTypes = {
  partials: PropTypes.object.isRequired,
  calculatedValue: PropTypes.object,
  isLoading: PropTypes.bool,
}

export default ExchangeSummary
