import React from 'react'
import { getFormSubmitErrors, reduxForm, SubmissionError } from 'redux-form/immutable'
import { connect } from 'react-redux'
import { fromJS } from 'immutable'
import { ReplacementCar as Form } from '../Forms'
import { bindActionCreators, compose } from 'redux'
import { processAPIerrorResponseToFormErrors } from '../../../../services/APIClient'
import { prepareRequestDates } from '../../../../utils/prepareRequestDates'
import { Factory as ElementFactory } from '../../../../models/timeline/index'
import { getFormValues } from '../../../../utils/forms'
import { DateSuggester } from '../../../../store/app/trip-timeline/services/date-suggester'
import { LocationSuggester } from '../../../../store/app/trip-timeline/services/location-suggester'
import { getCurrency } from '../../../../store/app/instance'
import { setEndOfDay } from '../../../../utils/setEndOfDay'

class ReplacementCar extends React.Component<any, any> {
  render() {
    const { ...props } = this.props

    if (!this.props.initialized) {
      return null
    }

    return <Form {...props} />
  }
}
export const submit = (values, dispatch, props) => {
  const { onSave, request, change } = props

  values = prepareRequestDates(values)
  values.return_at = setEndOfDay(values.return_at, true)

  return onSave(request, values, props.element).then(
    () => {
      dispatch(change('isOpen', false))
    },
    (alerts) => {
      throw new SubmissionError(processAPIerrorResponseToFormErrors(alerts))
    },
  )
}

export const change = (values, dispatch, props) => {
  const { change } = props

  if (values.get('return_at') < values.get('departure_at')) {
    dispatch(change('return_at', values.get('departure_at')))
  }
}

const withForm = reduxForm({
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
  onSubmit: submit,
  onChange: change,
  destroyOnUnmount: false,
})

const mapStateToProps = (state, props) => {
  const { request, element, currencies } = props

  const car = ElementFactory.create(element)
  const formErrors = getFormSubmitErrors(car.key)

  const dateSuggester = new DateSuggester(state, car)
  const locationSuggester = new LocationSuggester(state, car)

  const currentValues = ElementFactory.create(getFormValues(car.key, state))
  const instanceCurrency = getCurrency(state)

  return {
    initialValues: fromJS({
      uuid: car.uuid,
      departure_at: car.draft ? dateSuggester.suggestStartDate() : car.getStartDate(),
      return_at: car.draft ? dateSuggester.suggestEndDate() : car.getEndDate(),
      departure_location: car.draft
        ? locationSuggester.suggestStartLocation()
        : car.getStartLocation(),
      destination_location: car.draft
        ? locationSuggester.suggestEndLocation()
        : car.getEndLocation(),
      round_trip: car.round_trip,
      fuel_cost: car.fuel_cost,
      fuel_cost_currency: !car.draft ? car.fuel_cost_currency : instanceCurrency,
      other_costs_amount: car.other_costs_amount,
      other_costs_currency: !car.draft ? car.other_costs_currency : instanceCurrency,
      amount: car.amount,
      amount_currency: !car.draft ? car.amount_currency : instanceCurrency,
      id: car.id,
      type: car.type,
      converted_amount: car.converted_amount,
      calculated_amount_currency: car.calculated_amount_currency
        ? car.calculated_amount_currency
        : instanceCurrency,
      isOpen: car.isOpen,
      draft: car.draft,
      virtual: car.virtual,
      license_plate: car.license_plate ? car.license_plate : request.user.company_license_plate,
    }),
    formErrors: formErrors(state).toJS(),
    form: car.key,
    request,
    car: currentValues,
    currencies,
    minDate: dateSuggester.suggestMinDate(),
    maxDate: dateSuggester.suggestMaxDate(),
    maxStartDate: dateSuggester.suggestMaxStartDate(),
    instanceCurrency,
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({}, dispatch)
}

const withConnect = connect(mapStateToProps, mapDispatchToProps)

ReplacementCar = compose(withConnect, withForm)(ReplacementCar)

export { ReplacementCar }
export default { ReplacementCar }
