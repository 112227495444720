import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { get } from 'lodash'
import APIClient from '../../../services/APIClient'
import uuid from '../../../utils/uuid'
import { BorderCrossing as BorderCrossingModel } from '../../../models/BorderCrossing'
import store from '../../../store'
import arrayMove from 'array-move'
import {
  reset as resetRequestMileageAllowance,
  fetchRequest,
  updateBorderCrossingsState,
} from '../request-mileage-allowance'
import { changeRequestValue } from '../trip-request'

export const MODULE_MOUNT_POINT = 'destination'

const RESET = MODULE_MOUNT_POINT + '::reset'
const SET = MODULE_MOUNT_POINT + '::set'
const REMOVE = MODULE_MOUNT_POINT + '::remove-crossing'
const INSERT = MODULE_MOUNT_POINT + '::insert-crossing'
const UPDATE = MODULE_MOUNT_POINT + '::update-crossing'
const DESTROY = MODULE_MOUNT_POINT + '::destroy'
const SET_LOADING = MODULE_MOUNT_POINT + '::set-loading'
const SET_STEP = MODULE_MOUNT_POINT + '::set-step'
const SET_STEP_READ = MODULE_MOUNT_POINT + '::set-step-read'
const SET_NATIONAL_TRIP = MODULE_MOUNT_POINT + '::set-national-trip'
const REORDER = MODULE_MOUNT_POINT + '::reorder'

const getInitialState = () => {
  return {
    targetPoints: [],
    step: false,
    countries: [],
    nationalTrip: null,
    loading: true,
    initialized: false,
  }
}

// actions Creators
const reset = () => (dispatch) => {
  dispatch({
    type: RESET,
  })
}

const destroy = () => (dispatch) => {
  dispatch({
    type: DESTROY,
  })
}

const set = (data) => (dispatch) => {
  dispatch({
    type: SET,
    payload: {
      targetPoints: data.targetPoints,
      countries: data.countries,
      step: data.step,
      nationalTrip: data.nationalTrip,
    },
  })
}

const remove = (request) => (crossing) => (dispatch) => {
  dispatch(setLoading(true))

  dispatch({
    type: REMOVE,
    payload: {
      crossing,
    },
  })

  if (crossing.draft) {
    dispatch(setLoading(false))
    return
  }

  return APIClient.removeBorderCrossing(request.slug, crossing.id).then((response) => {
    dispatch(setLoading(false))
    dispatch(updateWeight(request))
  })
}

const insert = (index) => (dispatch) => {
  dispatch({
    type: INSERT,
    payload: {
      index: index + 1, //always after trip-start
    },
  })
}

const update = (cid, crossing) => (dispatch) => {
  dispatch({
    type: UPDATE,
    payload: {
      cid,
      crossing,
    },
  })
}

const setLoading = (state) => (dispatch) => {
  dispatch({
    type: SET_LOADING,
    payload: { state },
  })
}

export const save = (request) => (point, fields) => (dispatch) => {
  let promise

  if (point.draft) {
    promise = APIClient.createTargetPoint(request.slug, fields)
  } else {
    promise = APIClient.updateTargetPoint(request.slug, point.id, fields)
  }

  return new Promise((resolve, reject) => {
    promise
      .then((response) => {
        if (point.draft) {
          dispatch(update(point.cid, response.data))
        }

        resolve(response)
      })
      .catch((response) => {
        reject(response)
      })
  })
}

const fetch = (request) => () => (dispatch) => {
  APIClient.getBorderCrossings(request.slug).then((response) => {
    dispatch(
      set({
        targetPoints: response.data[0].targetPoints,
        step: response.data[0].border_crossing_state,
        nationalTrip: response.data[0].national_trip,
        countries: response.data,
      }),
    )

    dispatch(changeRequestValue(['border_crossing_state'], response.data[0].border_crossing_state))
  })
}

const setStep = (request) => (step) => (dispatch) => {
  dispatch(setLoading(true))

  APIClient.changeDeductionsWidgetState(request.slug, { state: step })
    .then((response) => {
      dispatch({
        type: SET_STEP,
        payload: { step },
      })

      dispatch(updateBorderCrossingsState(response.data[0].state))

      if (!step) {
        dispatch(reset())
        dispatch(fetch(request)())
      } else {
        dispatch(setLoading(false))
      }
    })
    .catch(() => {
      dispatch(setLoading(false))
    })
}

const setStepReadOnly = (request) => (step) => (dispatch) => {
  dispatch({
    type: SET_STEP_READ,
    payload: { step },
  })
}

const setNationalTrip = (request) => (value) => (dispatch) => {
  dispatch(setLoading(true))

  return APIClient.updateRequestWithoutDependency(request.slug, {
    national_trip: value,
    national_trip_value_confirmed: true,
  })
    .then((response) => {
      dispatch({
        type: SET_NATIONAL_TRIP,
        payload: { value },
      })

      dispatch(setLoading(false))

      dispatch(resetRequestMileageAllowance())
      dispatch(fetchRequest(request.slug))
    })
    .catch(() => {
      dispatch(setLoading(false))
    })
}

const updateWeight = (request) => (dispatch) => {
  const targetPoints = getCrossings(store.getState())
  //filter(c => !isString(c.id))
  const order = targetPoints.map((crossing, index) => {
    return { id: crossing.id, weight: index }
  })

  APIClient.updateBorderCrossingsWeights(request, order)
}

const reorder = (request) => (source, destination) => (dispatch) => {
  dispatch({
    type: REORDER,
    payload: {
      source,
      destination,
    },
  })

  const targetPoints = getCrossings(store.getState())
  const order = targetPoints.map((crossing, index) => {
    return { id: crossing.id, weight: index }
  })

  APIClient.updateBorderCrossingsWeights(request, order)
}

//actions
const resetAction = () => {
  return getInitialState()
}

const destroyAction = (state) => {
  delete state[MODULE_MOUNT_POINT]
  return state
}

const setAction = (state, payload) => {
  return {
    ...state,
    targetPoints: payload.targetPoints,
    countries: payload.countries,
    loading: false,
    step: payload.step,
    nationalTrip: payload.nationalTrip,
    initialized: true,
  }
}

const removeAction = (state, payload) => {
  const targetPoints = state.targetPoints.filter((c) => c.id !== payload.crossing.id)
  return {
    ...state,
    targetPoints,
  }
}

const insertAction = (state, payload) => {
  let targetPoints = [...state.targetPoints]
  const crossing = new BorderCrossingModel({ id: null, draft: true, cid: uuid() })
  targetPoints.splice(payload.index, 0, crossing)

  return {
    ...state,
    targetPoints,
  }
}

const updateAction = (state, payload) => {
  let targetPoints = [...state.targetPoints]
  const index = targetPoints.findIndex((c) => c.cid === payload.cid)
  targetPoints[index] = {
    cid: payload.cid,
    ...payload.crossing,
  }

  return {
    ...state,
    targetPoints,
  }
}

const setLoadingAction = (state, payload) => {
  return {
    ...state,
    loading: payload.state,
  }
}

const setStepAction = (state, payload) => {
  return {
    ...state,
    step: payload.step,
  }
}

const setNationalTripAction = (state, payload) => {
  return {
    ...state,
    nationalTrip: payload.value,
  }
}

const reorderAction = (state, payload) => {
  let targetPoints = [...state.targetPoints]

  //take out trip start and trip end
  const tripStart = targetPoints[0]
  const tripEnd = targetPoints[targetPoints.length - 1]

  targetPoints = targetPoints.filter((c) => c.id !== 'trip-start' && c.id !== 'trip-end')
  targetPoints = arrayMove(targetPoints, payload.source, payload.destination)

  //put back trip start and trip end
  targetPoints.splice(0, 0, tripStart)
  targetPoints.push(tripEnd)

  return {
    ...state,
    targetPoints,
  }
}

const actions = {
  [RESET]: resetAction,
  [SET]: setAction,
  [REMOVE]: removeAction,
  [INSERT]: insertAction,
  [UPDATE]: updateAction,
  [DESTROY]: destroyAction,
  [SET_LOADING]: setLoadingAction,
  [SET_STEP]: setStepAction,
  [SET_STEP_READ]: setStepAction,
  [SET_NATIONAL_TRIP]: setNationalTripAction,
  [REORDER]: reorderAction,
}

//selectors
const getState = (state) => {
  return state.get(MODULE_MOUNT_POINT)
}

const getCrossings = (state) => {
  return get(getState(state), 'targetPoints', []).map((crossing) => {
    if (!get(crossing, 'cid', null)) {
      crossing.cid = crossing.id
    }
    return new BorderCrossingModel(crossing)
  })
}

const isLoading = (state) => {
  return get(getState(state), 'loading', true)
}

const isInitialized = (state) => {
  return get(getState(state), 'initialized', true)
}

const getStep = (state) => {
  return get(getState(state), 'step', false)
}

const getNationalTrip = (state) => {
  return get(getState(state), 'nationalTrip', true)
}

export const reducer = (state = getInitialState(), action) => {
  if (actions.hasOwnProperty(action.type)) {
    return actions[action.type](state, action.payload)
  }
  return state
}

const destinations =
  (
    resetOnMount = false,
    fetchOnMount = false,
    destroyOnUnmount = false,
    listenNationalTrip = false,
  ) =>
  (Component) => {
    class BorderCrossingsHOC extends React.PureComponent<any, any> {
      componentDidMount() {
        const { targetPoints } = this.props

        if (resetOnMount) {
          targetPoints.actions.reset()
        }

        if (fetchOnMount) {
          targetPoints.actions.fetch()
        }
      }

      componentDidUpdate(prevProps, prevState, prevContext) {
        const { targetPoints } = this.props

        if (
          fetchOnMount &&
          listenNationalTrip &&
          targetPoints.selectors.nationalTrip !== prevProps.targetPoints.selectors.nationalTrip &&
          prevProps.targetPoints.selectors.nationalTrip !== null
        ) {
          targetPoints.actions.fetch()
        }
      }

      componentWillUnmount() {
        const { targetPoints } = this.props
        if (destroyOnUnmount) {
          targetPoints.actions.destroy()
        }
      }

      render() {
        return <Component<any, any> {...this.props} />
      }
    }

    const mapStateToProps = (state) => ({
      targetPoints: getCrossings(state),
      isLoading: isLoading(state),
      step: getStep(state),
      nationalTrip: getNationalTrip(state),
      isInitialized: isInitialized(state),
    })

    const mapDispatchToProps = (dispatch, props) => {
      return bindActionCreators(
        {
          reset,
          set,
          insert,
          destroy,
          remove: remove(props.request),
          save: save(props.request),
          fetch: fetch(props.request),
          setStep: setStep(props.request),
          setStepReadOnly: setStepReadOnly(props.request),
          setNationalTrip: setNationalTrip(props.request),
          reorder: reorder(props.request),
        },
        dispatch,
      )
    }

    const mergedProps = (selectors, actions, own) => {
      return {
        ...own,
        targetPoints: {
          selectors,
          actions,
        },
      }
    }

    return connect(mapStateToProps, mapDispatchToProps, mergedProps)(BorderCrossingsHOC)
  }

export { destinations }
export default destinations
