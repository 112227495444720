import PropTypes from 'prop-types'
import React from 'react'
import { SelectField } from '../../ui/Form'
import trans from '../../../trans'
import Icon from '../../../components/ui/IconComponent'
import { StateTextButton } from '../../ui/StateTextButton'
import { fetchMpkForAsyncSelect } from '../../../store/app/mpks'
import { AsyncSelectField } from '../../ui/Form/AsyncSelectField'
import classnames from 'classnames'
import { isEmpty } from 'lodash'
import ToggleableFilters from '../../ToggleableFilters/ToggleableFilters'
import ToggleableQuickFilters from '../../ToggleableFilters/ToggleableQuickFilters'
import { STATUS_ACCEPTANCE_OF_SETTLEMENT, STATUS_ACCOUNTING } from '../../../constants/request'
import ToggleableBottomFilters from '../../ToggleableFilters/ToggleableBottomFilters'
import { Grade } from '../../../types/grade'
import { SelectOption } from '../../../types/form'

interface FiltersProps {
  grades: Grade[]
  [key: string]: any
}

class Filters extends React.Component<FiltersProps, any> {
  constructor(props) {
    super(props)

    this.timer = null

    this.fetchMpkForAsyncSelect = fetchMpkForAsyncSelect()
  }

  getGradeOptions(): SelectOption[] {
    const { grades } = this.props
    const options = grades.map((grade) => {
      return {
        value: grade.id,
        label: grade.name,
      }
    })

    const optionAll = {
      label: trans('global.all'),
      value: null,
      onSelectResetsInput: true,
    }

    return [optionAll, ...options]
  }

  getCompaniesOptions(): SelectOption[] {
    const { companies } = this.props
    const options = companies.map((company) => ({
      label: company.code,
      value: company.id,
    }))

    const optionAll = {
      label: trans('instance-users.filter-placeholder-all'),
      value: null,
      onSelectResetsInput: true,
    }

    return [optionAll, ...options]
  }

  getGroupsOptions(): SelectOption[] {
    const { groups } = this.props
    const options = groups.map((group) => ({
      label: group.name,
      value: group.id,
    }))

    const optionAll = {
      label: trans('instance-users.filter-placeholder-all'),
      value: null,
      onSelectResetsInput: true,
    }

    return [optionAll, ...options]
  }

  render() {
    const { filters, onChangeFilter } = this.props
    const filtersCount = Object.keys(filters).length

    const bottomFilters = (
      <>
        <div className='filters__input-wrapper'>
          <span className='filters__input-title'>{trans('instance-users.company-filter')}</span>
          <SelectField
            options={this.getCompaniesOptions()}
            value={filters['companies']}
            onChange={(value) => onChangeFilter('companies', value)}
            placeholder={trans('global.filter-placeholder-all')}
            isMulti
            clearable={true}
          />
        </div>

        <div className='filters__input-wrapper'>
          <span className='filters__input-title'>{trans('instance-users.role-filter')}</span>
          <SelectField
            options={this.getGroupsOptions()}
            value={filters['groups']}
            onChange={(value) => onChangeFilter('groups', value)}
            placeholder={trans('global.filter-placeholder-all')}
            isMulti
            clearable={true}
          />
        </div>

        <div className='filters__input-wrapper'>
          <span className='filters__input-title'>{trans('global.filter-mpk')}</span>

          <AsyncSelectField
            loadOptions={this.fetchMpkForAsyncSelect}
            value={filters['mpks']}
            onChange={(value) => onChangeFilter('mpks', value)}
            placeholder={trans('global.filter-placeholder-all')}
            noOptionsMessage={() => {
              return trans('user.no-results-for-search-message')
            }}
            loadingMessage={() => {
              return trans('user.searching-message')
            }}
            delay={500}
            returnFullItemValueOnChange
            isMulti
            clearable={true}
          />
        </div>

        <div className='filters__input-wrapper'>
          <span className='filters__input-title'>{trans('instance-users.user-level')}</span>
          <SelectField
            options={this.getGradeOptions()}
            value={filters['grades']}
            onChange={(value) => onChangeFilter('grades', value)}
            placeholder={trans('global.filter-placeholder-all')}
            isMulti
            clearable={true}
          />
        </div>
      </>
    )

    return (
      <form>
        <ToggleableFilters filtersCount={filtersCount}>
          {({ isOpen }) => (
            <>
              <ToggleableQuickFilters>
                <div className='input-group'>
                  <Icon type='search' lg />
                  <input
                    type='text'
                    placeholder={trans('ui.search')}
                    className='input-group__input'
                    ref={(e) => (this.searchInput = e)}
                    defaultValue={filters['phrase']}
                    onChange={() => {
                      clearTimeout(this.timer)
                      this.timer = setTimeout(() => {
                        onChangeFilter('phrase', this.searchInput.value)
                      }, 250)
                    }}
                  />
                </div>

                <div className='filters__quick'>
                  <span className='filters__quick-title'>{trans('global.quick-filters')}:</span>

                  <StateTextButton
                    onClick={(status) => {
                      onChangeFilter('status', status ? 'active' : null)
                    }}
                    active={filters['status'] === 'active'}
                  >
                    {trans('instance-users.status-active-filter')}
                  </StateTextButton>

                  <StateTextButton
                    onClick={(activate) => {
                      onChangeFilter('status', activate ? 'deactivate' : null)
                    }}
                    active={filters['status'] === 'deactivate'}
                  >
                    {trans('instance-users.status-deactivate-filter')}
                  </StateTextButton>
                </div>
              </ToggleableQuickFilters>

              <ToggleableBottomFilters isOpen={isOpen}>{bottomFilters}</ToggleableBottomFilters>
            </>
          )}
        </ToggleableFilters>
      </form>
    )
  }
}

Filters.propTypes = {
  filters: PropTypes.object.isRequired,
  onChangeFilter: PropTypes.func.isRequired,
  companies: PropTypes.array.isRequired,
  groups: PropTypes.array.isRequired,
}

export default Filters
export { Filters }
