import React from 'react'
import { reduxForm, SubmissionError } from 'redux-form/immutable'
import { connect } from 'react-redux'
import { fromJS } from 'immutable'
import { isEmpty } from 'lodash'
import { Plane as Form } from '../Forms/Plane'
import { bindActionCreators, compose } from 'redux'
import { processAPIerrorResponseToFormErrors } from '../../../../services/APIClient'
import { prepareRequestDates } from '../../../../utils/prepareRequestDates'
import { Factory as ElementFactory } from '../../../../models/timeline/index'
import { getFormValues } from '../../../../utils/forms'
import { DateSuggester } from '../../../../store/app/trip-timeline/services/date-suggester'
import { LocationSuggester } from '../../../../store/app/trip-timeline/services/location-suggester'
import { getCurrency } from '../../../../store/app/instance'
import { provide as flightsProvider } from '../../../../containers/FlightsBooking'
import { saveSelectedOffer, setInitialized } from '../../../../store/app/flights-booking/creators'
import { getRequestTravelers } from '../../../../store/app/request-traveler'
import { setEndOfDay } from '../../../../utils/setEndOfDay'

class Plane extends React.Component<any, any> {
  render() {
    if (!this.props.initialized) {
      return null
    }

    return <Form {...this.props} />
  }
}

export const saveFlightOffer = (props, element, dispatch) => {
  dispatch(saveSelectedOffer(props.plane.key, props.request, element))
}

export const submit = (values, dispatch, props) => {
  const { onSave, request, change } = props

  values = prepareRequestDates(values, [
    'target_real_arrival_at',
    'target_real_departure_at',
    'return_real_arrival_at',
    'return_real_departure_at',
  ])
  values.return_at = setEndOfDay(values.return_at, true)

  return onSave(request, values, props.element).then(
    (response) => {
      dispatch(change('isOpen', false))
      saveFlightOffer(props, response, dispatch)
      dispatch(setInitialized(props.plane.key)(true))
    },
    (alerts) => {
      throw new SubmissionError(processAPIerrorResponseToFormErrors(alerts))
    },
  )
}

export const change = (values, dispatch, props) => {
  const { change } = props
  if (values.get('return_at') < values.get('arrival_at')) {
    dispatch(change('return_at', values.get('arrival_at')))
  }
}

const withForm = reduxForm({
  onSubmit: submit,
  onChange: change,
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
  destroyOnUnmount: false,
  touchOnBlur: false,
})

const mapStateToProps = (state, props) => {
  const { request, element, currencies } = props

  const plane = ElementFactory.create(element)
  const { draft, key } = plane

  const currentValues = getFormValues(key, state)
  const currentPlane = !isEmpty(currentValues) ? ElementFactory.create(currentValues) : plane
  const instanceCurrency = getCurrency(state)

  const travelers = getRequestTravelers(state)
  const defaultTravelers = travelers.map((traveler) => traveler.slug)

  const dateSuggester = new DateSuggester(state, currentPlane)
  const locationSuggester = new LocationSuggester(state, currentPlane)

  return {
    initialValues: fromJS({
      uuid: plane.uuid,
      flight_from: draft ? locationSuggester.suggestStartLocation() : plane.getStartLocation(),
      flight_to: draft ? locationSuggester.suggestEndLocation() : plane.getEndLocation(),
      flight_class: plane.flight_class,
      arrival_at: draft ? dateSuggester.suggestStartDate() : plane.getStartDate(),
      return_at: draft ? dateSuggester.suggestEndDate() : plane.getEndDate(),
      acceptance_source: plane.acceptance_source,
      round_trip: plane.round_trip,
      amount: plane.amount,
      amount_currency: !draft ? plane.amount_currency : instanceCurrency,
      id: plane.id,
      type: plane.type,
      converted_amount: plane.converted_amount,
      calculated_amount_currency: plane.calculated_amount_currency
        ? plane.calculated_amount_currency
        : instanceCurrency,
      suggested_amount: plane.suggested_amount,
      searcher_disabled: plane.searcher_disabled,
      draft: draft,
      virtual: plane.virtual,
      direct_only: plane.direct_only,
      restrict_to_fba: plane.restrict_to_fba,
      request_travelers: plane.request_travelers.length
        ? plane.request_travelers
        : defaultTravelers,
      offer_uuid: plane.offer_uuid,
      target_real_departure_at: plane.target_real_departure_at,
      target_real_arrival_at: plane.target_real_arrival_at,
      return_real_departure_at: plane.return_real_departure_at,
      return_real_arrival_at: plane.return_real_arrival_at,
    }),
    form: key,
    request,
    plane: currentPlane,
    currencies,
    minDate: dateSuggester.suggestMinDate(),
    maxDate: dateSuggester.suggestMaxDate(),
    maxStartDate: dateSuggester.suggestMaxStartDate(),
    isOpen: props.isOpen,
    travelers,
    instanceCurrency,
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({}, dispatch)
}

const withConnect = connect(mapStateToProps, mapDispatchToProps)

const withFlights = flightsProvider((props) => {
  return {
    name: props.form,
    request: props.request,
    element: props.element,
  }
}, true)

Plane = compose(withConnect, withForm, withFlights)(Plane)

export { Plane }
export default Plane
