import APIClient from '../../services/APIClient'
import Session from '../../services/Session'
import { getValidationErrors } from '../../utils'
import { addFormErrorsAction, authorizeUserAction, setUserToken } from '../App/actions'
import { fetchInstance, resetInstance } from '../../store/app/instance'
import GoogleMapsProvider from '../../services/GoogleMapsProvider'
import { config } from '../../config'
import { setUserProfile } from '../../store/app/user-profile/userProfileSlice'

export const userLoginAction = (email, password, lang) => {
  return (dispatch) => {
    APIClient.loginUser(email, password, lang)
      .then((response) => {
        Session.saveToStorage(
          JSON.stringify({
            isAuthenticated: true,
            token: response.data.token,
          }),
        )

        dispatch(setUserToken(response.data.token, response.data.user))

        const { domain } = response.data.user.instance

        if (location.host !== domain) {
          location.href = `${location.protocol}//${domain}?token=${response.data.token}`
          return
        }
        Session.isAuthenticated()

        dispatch(setUserProfile(response.data.user))

        config.moment.locale(response.data.user.lang)
        GoogleMapsProvider.setLang(response.data.user.lang)

        GoogleMapsProvider.setCallback(() => {
          dispatch(resetInstance())
          dispatch(fetchInstance()).then(() => {
            // save session data to store
            dispatch(authorizeUserAction(response.data.token, response.data.user))
          })
        })

        GoogleMapsProvider.enqueue()
      })
      .catch((error) => {
        // save errors data to store
        const errors = getValidationErrors(error.alerts)
        dispatch(addFormErrorsAction('login', errors))
      })
  }
}
