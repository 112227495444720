import PropTypes from 'prop-types'
import React from 'react'
import {
  fetchNotifications,
  getNotifications,
  pushNotification,
} from '../../store/app/notifications'
import { connect } from 'react-redux'
import { bindActionCreators, compose } from 'redux'
import _ from 'lodash'
import { Map } from 'immutable'
import socketProvider from '../SocketProvider'

class NotificationsManagerBase extends React.Component<any, any> {
  componentDidMount() {
    this.mountSocket()
  }

  componentWillUnmount() {
    this.unMountSocket()
  }

  unMountSocket() {
    if (this._mounted) {
      const { currentUser, socket } = this.props
      socket.stopNotifications(`App.User.${currentUser.id}`)
      this._mounted = false
    }
  }

  mountSocket() {
    if (this._mounted) return

    const { currentUser, socket } = this.props
    const {
      currentUser: { id },
    } = this.props

    if (!_.isEmpty(currentUser)) {
      this._mounted = true
      const { pushNotification, fetchNotifications } = this.props

      socket.notification(`App.User.${id}`)((notification) => {
        pushNotification(notification)
      })

      fetchNotifications()
    }
  }

  render() {
    const { children, notifications, messages } = this.props

    const renderProps = {
      notifications,
      messages,
    }

    return children(renderProps)
  }
}

NotificationsManagerBase.propTypes = {
  children: PropTypes.func.isRequired,
  pushNotification: PropTypes.func.isRequired,
  fetchNotifications: PropTypes.func.isRequired,
  currentUser: PropTypes.object.isRequired,
  notifications: PropTypes.array.isRequired,
}

NotificationsManagerBase.defaultProps = {
  children: () => null,
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ pushNotification, fetchNotifications }, dispatch)
}

const mapStateToProps = (state) => ({
  notifications: getNotifications(state),
  currentUser: Map(state.get('global').get('currentUser')).toJS(), // TODO: use selector
})

const withConnect = connect(mapStateToProps, mapDispatchToProps)
const withSocket = socketProvider()

const NotificationsManager = compose(withConnect, withSocket)(NotificationsManagerBase)

export { NotificationsManager }
export default NotificationsManager
