import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import { actions, selectors } from '../../store/app/expense-requests'
import { bindActionCreators } from 'redux'
import APIClient from '../../services/APIClient'

class ExpenseRequestsManagerBase extends React.Component<any, any> {
  constructor(props) {
    super(props)

    this.state = {
      MPKs: [],
      users: [],
    }
  }

  componentDidMount() {
    const { reset, fetchItems, listenQueryChange } = this.props

    reset()
    fetchItems()
    listenQueryChange()

    APIClient.getMPKs().then((response) => {
      this.setState({ MPKs: response.data })
    })
    APIClient.getUsersForSelect().then((response) => {
      this.setState({ users: response.data })
    })
  }

  componentWillUnmount() {
    const { unlistenQueryChange } = this.props

    unlistenQueryChange()
  }

  render() {
    const {
      children,
      requests,
      isLoading,
      isLoaded,
      filters,
      changeFilter,
      currentUser,
      pagination,
      changePage,
      sorter,
      changeSorter,
    } = this.props

    const { MPKs, users } = this.state

    const renderProps = {
      requests,
      requestsPagination: pagination,
      requestsFilters: filters,
      changeRequestsFilter: changeFilter,
      requestsSorter: sorter,
      changeRequestsSorter: changeSorter,
      areRequestsLoading: isLoading,
      areRequestsLoaded: isLoaded,
      changeRequestsPage: changePage,
      MPKs,
      currentUser,
      users,
    }

    return children(renderProps)
  }
}

ExpenseRequestsManagerBase.propTypes = {
  children: PropTypes.func.isRequired,
  requests: PropTypes.array.isRequired,
  isLoaded: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
  filters: PropTypes.object.isRequired,
  changeFilter: PropTypes.func.isRequired,
  sorter: PropTypes.object.isRequired,
  changeSorter: PropTypes.func.isRequired,
  reset: PropTypes.func.isRequired,
  currentUser: PropTypes.object.isRequired,
  pagination: PropTypes.object.isRequired,
  changePage: PropTypes.func.isRequired,
}

const {
  reset,
  fetchItems,
  changeFilter,
  changeSorter,
  changePage,
  listenQueryChange,
  unlistenQueryChange,
} = actions

const { getIsLoaded, getIsLoading, getFilters, getSorter, getItemsPaginated, getPagination } =
  selectors

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      reset,
      fetchItems,
      changeFilter,
      changeSorter,
      changePage,
      listenQueryChange,
      unlistenQueryChange,
    },
    dispatch,
  )
}

const mapStateToProps = (state) => ({
  requests: getItemsPaginated(state),
  pagination: getPagination(state),
  isLoaded: getIsLoaded(state),
  isLoading: getIsLoading(state),
  filters: getFilters(state),
  sorter: getSorter(state),
  currentUser: state.get('global').get('currentUser'),
})

export const ExpenseRequestsManager = connect(
  mapStateToProps,
  mapDispatchToProps,
)(ExpenseRequestsManagerBase)
