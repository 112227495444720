import React from 'react'
import classNames from 'classnames'
import Icon from '../../components/ui/IconComponent'
import trans from '../../trans'
import { debounce, get, isEmpty } from 'lodash'
import { TYPE_EXPENSE, TYPE_TRIP } from '../../constants/request'
import { getRouteByName } from '../../routes'
import { Scrollbars } from 'react-custom-scrollbars-2'
import Button from '../../components/ui/ButtonComponent'

class Dropdown extends React.PureComponent<any, any> {
  constructor(props) {
    super(props)

    this.debouncedLoadMore = debounce(props.handleLoadMore, 300)
    this.onScroll = this.onScroll.bind(this)
  }

  onScroll(e) {
    const bottom = e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight

    if (bottom) {
      this.debouncedLoadMore()
    }
  }

  renderDescription(notification) {
    if (notification.data.request_type === TYPE_EXPENSE) {
      return notification.data.purpose
    }

    if (!isEmpty(notification.data.translationParams)) {
      if (notification.data.translationParams.route) {
        return trans('trip-request.trip-name-with-date', notification.data.translationParams)
      } else {
        return trans(
          'trip-request.trip-name-with-date-no-route',
          notification.data.translationParams,
        )
      }
    }
    return ''
  }

  handleNotificationClick(notification) {
    const { handleNotificationClick, historyPush, location } = this.props

    return handleNotificationClick(notification.id).then(() => {
      let redirectUrl

      if (notification.data.request_type === TYPE_TRIP) {
        redirectUrl = getRouteByName('main', 'tripRequestShow', {
          id: notification.data.request_id,
        })
      } else {
        redirectUrl = getRouteByName('main', 'expenseRequestShow', {
          id: notification.data.request_id,
        })
      }
      //hack, change location to fake then change to wanted
      if (get(location, 'pathname', null) === redirectUrl) {
        historyPush('/dashboard')
      }

      historyPush(redirectUrl)
    })
  }

  renderNotification(notification, index) {
    const { handleNotificationClick, historyPush, location } = this.props

    const classes = classNames({
      'notification-dropdown__list-element': true,
      'notification-element': true,
      'notification-element--read': notification['read_at'],
      [`notification-element--${notification['data']['level']}`]: true,
    })

    return (
      <li
        key={index}
        onClick={() => {
          handleNotificationClick(notification.id).then((res) => {
            let redirectUrl

            if (notification.data.request_type === TYPE_TRIP) {
              redirectUrl = getRouteByName('main', 'tripRequestShow', {
                id: notification.data.request_id,
              })
            } else {
              redirectUrl = getRouteByName('main', 'expenseRequestShow', {
                id: notification.data.request_id,
              })
            }
            //hack, change location to fake then change to wanted
            if (get(location, 'pathname', null) === redirectUrl) {
              historyPush('/dashboard')
            }

            historyPush(redirectUrl)
          })
        }}
      >
        <a className={classes}>
          <div className='notification-element__col-icon'>
            {notification['data']['level'] === 'warning' && (
              <Icon className='is-gradient-warning' type='warning' />
            )}
            {notification['data']['level'] === 'success' && (
              <Icon className='is-gradient-success' type='checked_fill' />
            )}
            {notification['data']['level'] === 'info' && <Icon type='info' />}
          </div>
          <div>
            <h3 className='h3 notification-element__title'>
              {trans(notification.data.name, notification.data.translationParams)}
            </h3>
            <span className='notification-element__desc'>
              {this.renderDescription(notification)}
            </span>
          </div>
        </a>
      </li>
    )
  }

  getScrollableElements() {
    const { notifications } = this.props

    if (notifications.length > 5) {
      return (
        <Scrollbars style={{ height: 495 }} onScroll={this.onScroll}>
          {notifications.map((notification, i) => this.renderNotification(notification, i))}
        </Scrollbars>
      )
    }

    return notifications.map((notification, i) => this.renderNotification(notification, i))
  }

  render() {
    const { unreadCounter, open, handleDropdownIconClick, passRef } = this.props

    const classes = classNames({
      'notification-dropdown': true,
      active: open,
    })

    return (
      <div className={classes} ref={passRef}>
        <div className='notification-dropdown__icon' onClick={handleDropdownIconClick}>
          <Icon type='bell' xlg />
          {unreadCounter !== 0 && (
            <span className='notification-dropdown__icon-label'>{unreadCounter}</span>
          )}
        </div>

        <ul className='notification-dropdown__list'>{this.getScrollableElements()}</ul>
      </div>
    )
  }
}

export { Dropdown }
export default { Dropdown }
