// initial state
import APIClient from '../../../services/APIClient'
import _ from 'lodash'
import { fetch as fetchCounter } from '../../../store/app/dashboard-counter'
import { getCurrentAssistant } from '../user-profile'

const getInitialState = () => {
  return {
    items: [],
    isLoading: false,
    isLoaded: false,
    actionsInProgress: {},
  }
}

// constants
export const RESET = 'dashboard-requests-to-accept::reset'
export const SET_ITEMS = 'dashboard-requests-to-accept::set-comments'
export const START_LOADING = 'dashboard-requests-to-accept::start-loading'
export const SET_ACTION_IN_PROGRESS = 'dashboard-requests-to-accept::set-action-in-progress'
export const MOUNT_POINT = 'dashboard-requests-to-accept'

// reducer
export const reducer = (state = getInitialState(), action) => {
  switch (action.type) {
    case RESET:
      return getInitialState()
    case SET_ITEMS:
      return {
        ...state,
        items: [...action.payload],
        isLoading: false,
        isLoaded: true,
      }
    case START_LOADING:
      return {
        ...state,
        isLoading: true,
        isLoaded: false,
      }
    case SET_ACTION_IN_PROGRESS: {
      const { requestID, type } = action.payload
      const newState = {
        ...state,
        actionsInProgress: {
          ...state.actionsInProgress,
          [requestID]: {
            type,
          },
        },
      }

      if (type === false) {
        delete newState['actionsInProgress'][requestID]
        newState['items'] = newState['items'].filter((request) => request['slug'] !== requestID)
      }

      return newState
    }
    default:
      return state
  }
}

// actions
export const reset = () => (dispatch) => {
  dispatch({
    type: RESET,
  })
}

export const setItems = (data) => (dispatch) => {
  dispatch({
    type: SET_ITEMS,
    payload: data,
  })
}

export const setActionInProgress = (requestID, type) => (dispatch) => {
  dispatch({
    type: SET_ACTION_IN_PROGRESS,
    payload: {
      requestID,
      type,
    },
  })
}

export const fetch = () => (dispatch, getState) => {
  dispatch({ type: START_LOADING })

  const currentAssistant = getCurrentAssistant(getState())
  let user = null

  if (currentAssistant) {
    user = currentAssistant
  } else {
    user = getState().get('global').get('currentUser')
  }

  APIClient.getRequestsToAcceptanceDashboard({
    acceptors: user.slug,
  }).then((response) => {
    dispatch(setItems(response.data))
  })
}

export const rejectRequest = (request) => (dispatch) => {
  dispatch(setActionInProgress(request['slug'], 'rejecting'))

  APIClient.requestReject(request).then(() => {
    dispatch(setActionInProgress(request['slug'], false))
    dispatch(fetchCounter())
  })
}

export const acceptRequest = (request) => (dispatch) => {
  dispatch(setActionInProgress(request['slug'], 'accepting'))

  APIClient.requestAccept(request).then(() => {
    dispatch(setActionInProgress(request['slug'], false))
    dispatch(fetchCounter())
  })
}

// selectors
export const getState = (state) => {
  return state.get(MOUNT_POINT)
}

export const getItems = (state) => {
  return getState(state).items
}

export const getIsLoading = (state) => {
  return getState(state).isLoading
}

export const getIsLoaded = (state) => {
  return getState(state).isLoaded
}

export const getIsActionInProgress = (state) => (requestID) => {
  return !_.isEmpty(getState(state)['actionsInProgress'][requestID])
}
