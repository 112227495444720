import React from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import TextareaAutosize from 'react-autosize-textarea'

export class Textarea extends React.Component<any, any> {
  renderAutosize(classes) {
    const { autosize, ...props } = this.props
    return <TextareaAutosize className={classes} {...props} />
  }

  renderSimple(classes) {
    const { className, autosize, ...props } = this.props
    return <textarea className={classes} {...props} />
  }

  render() {
    const { className, autosize } = this.props

    const classes = classNames(className, {
      input: true,
      'input--textarea': true,
      'input--autosize': autosize,
    })

    return autosize ? this.renderAutosize(classes) : this.renderSimple(classes)
  }
}

Textarea.propTypes = {
  className: PropTypes.string,
  autosize: PropTypes.bool,
}
