import React from 'react'
import { reduxForm, SubmissionError } from 'redux-form/immutable'
import { connect } from 'react-redux'
import { fromJS } from 'immutable'
import { OtherCostForm as Form } from '../../components/OtherCostForm'
import { bindActionCreators, compose } from 'redux'
import { processAPIerrorResponseToFormErrors } from '../../services/APIClient'
import { saveCost } from '../../store/app/request-other-costs'
import { formValueSelector } from 'redux-form/immutable'
import { get } from 'lodash'
import { getCurrency } from '../../store/app/instance'
import foreignCurrenciesTooltip from '../../components/ForeignCurrenciesTooltip'

class OtherCostForm extends React.Component<any, any> {
  render() {
    const { ...props } = this.props

    if (!this.props.initialized) {
      return null
    }

    return <Form {...props} />
  }
}

export const submit = (values, dispatch, props) => {
  const { request, saveCost, element } = props
  values = values.toJS()
  return saveCost(request, values, element).then((r) => {
    if (r.success === false) {
      throw new SubmissionError(processAPIerrorResponseToFormErrors(r.alerts))
    }
  })
}

const withForm = reduxForm({
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
  onSubmit: submit,
  destroyOnUnmount: false,
})

const mapStateToProps = (state, props) => {
  const { request, element, currencies, types, form } = props
  const selector = formValueSelector(form)
  const type_id = selector(state, 'type_id')
  const selected_type = types.find((type) =>
    type.options.find((option) => option.value === type_id),
  )
  const const_type_slug = selected_type
    ? selected_type.options.find((option) => option.value === type_id)
    : null

  const instanceCurrency = getCurrency(state)

  return {
    initialValues: fromJS({
      type_id: get(element, 'cost_type.id', null),
      description: element['description'],
      amount: element['amount'],
      amount_currency: element['amount_currency'] || instanceCurrency,
      draft: element['draft'],
      id: element['id'],
      calculated_amount_currency: element['calculated_amount_currency'] || instanceCurrency,
    }),
    instanceCurrency: instanceCurrency,
    form,
    request,
    element,
    currencies,
    types,
    cost_type_slug: get(const_type_slug, 'slug', null),
    getValue: (key) => {
      return selector(state, key)
    },
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ saveCost }, dispatch)
}

const withConnect = connect(mapStateToProps, mapDispatchToProps)

OtherCostForm = compose(withConnect, withForm)(OtherCostForm)

export { OtherCostForm }
export default { OtherCostForm }
