import React from 'react'
import { Field } from 'redux-form/immutable'
import { trans } from '../../../trans'
import NationalTripConfirmDialog from './NationalTripConfirmDialog'
import { FormFieldRadioGroup } from '../../ui/Form'

class NationalTripForm extends React.Component<any, any> {
  constructor(props) {
    super(props)

    this.state = {
      confirmationDialogIsOpen: false,
    }

    this.handleConfirm = this.handleConfirm.bind(this)
  }

  handleConfirm(confirmed = false) {
    if (confirmed === true) {
      this.props.handleSubmit()
    } else {
      const {
        form,
        crossings: {
          selectors: { nationalTrip },
        },
      } = this.props
      this.props.change('national_trip', nationalTrip)
    }

    this.setState({ confirmationDialogIsOpen: false })
  }

  render() {
    const { isReadOnly } = this.props

    return (
      <form onSubmit={this.props.handleSubmit.bind(this)}>
        <label htmlFor='#' className='border-crossings__heading'>
          {trans('deductions-widget.trip')}
        </label>
        <Field
          name='national_trip'
          type='national_trip_toggle'
          component={FormFieldRadioGroup}
          disabled={false}
          onChange={() => {
            this.setState({ confirmationDialogIsOpen: true })
          }}
          items={[
            {
              label: trans('deductions-widget.national-trip'),
              value: true,
            },
            {
              label: trans('deductions-widget.abroad-trip'),
              value: false,
            },
          ]}
          thin
          inline
        />
        <NationalTripConfirmDialog
          open={this.state.confirmationDialogIsOpen}
          onClose={this.handleConfirm}
        />
      </form>
    )
  }
}

export { NationalTripForm }
export default NationalTripForm
