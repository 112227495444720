import React, { Component } from 'react'
import trans from '../../../trans'
import SubstitutionsTable from './partials/SubstitutionsTable'
import SubstitutionsForm from './partials/SubstitutionsForm'
import PropTypes from 'prop-types'

class Substitutions extends Component<any, any> {
  render() {
    return (
      <div className='substitutions'>
        <div className='substitutions__header'>
          <h2 className='tabs__content-heading substitutions__title'>
            {trans('user.set-substitution')}
          </h2>
        </div>

        <SubstitutionsForm
          subjectUser={this.props.subjectUser}
          isFromExternal={this.props.isFromExternal}
        />

        <div className='substitutions__header'>
          <h2 className='tabs__content-heading substitutions__title'>
            {trans('user.substitutions')}
          </h2>
        </div>

        <SubstitutionsTable
          subjectUser={this.props.subjectUser}
          isFromExternal={this.props.isFromExternal}
        />
      </div>
    )
  }
}

Substitutions.propTypes = {
  subjectUser: PropTypes.object,
}

export default Substitutions
