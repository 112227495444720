import React, { ReactNode } from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import _ from 'lodash'

type RowProps = {
  className?: string
  nowrap?: boolean
  children: ReactNode
}

export const Row: React.FC<RowProps> = ({ className, nowrap, ...props }) => {
  const RowClasses = classNames(className, {
    row: true,
    'row--no-wrap': nowrap,
  })

  return (
    <div className={RowClasses} {...props}>
      {props.children}
    </div>
  )
}

type ColProps = {
  className?: string
  children: ReactNode
  xs?: number
  sm?: number
  md?: number
  lg?: number
  is_pull_end?: boolean
  percentWidth?: number
  fill?: boolean
  style?: Record<string, any>
  noGutters?: boolean
  align_center?: boolean
}

export const Col: React.FC<ColProps> = ({
  className,
  children,
  is_pull_end,
  fill,
  xs,
  sm,
  md,
  lg,
  percentWidth,
  style,
  noGutters,
  align_center,
  ...props
}) => {
  const ColClasses = classNames(className, {
    ['xs-' + xs]: xs,
    ['sm-' + sm]: sm,
    ['md-' + md]: md,
    ['lg-' + lg]: lg,
    'is-pull-end': is_pull_end,
    col: !xs && !sm && !md && !lg,
    'col--grow': fill,
    'col--no-gutters': noGutters,
    'col--flex-align-center': align_center,
  })

  const _style = style || {}
  if (!_.isUndefined(percentWidth)) {
    _style['width'] = `${percentWidth}%`
  }

  return (
    <div className={ColClasses} {...props} style={_style}>
      {children}
    </div>
  )
}
