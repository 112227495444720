import React from 'react'
import moment from 'moment'
import { config } from '../../../config'
import {
  AirportField,
  AmountField,
  CurrencyField,
  DatePickerField,
  FormattedNumberField,
  SelectField,
  TextField,
} from './index'
import { CheckboxField } from './CheckboxField'
import { AutoCompleteGeocoding, FormGroup, Textarea, Toggle } from '../../../ui'
import { fromJS, List, Map } from 'immutable'
import { DistanceField } from './DistanceField'
import classnames from 'classnames'
import Icon from '../IconComponent'
import { Tooltip } from '../../Tooltip'
import { TravelerSelectField } from './TravelerSelectField'
import { CounterField } from './CounterField'
import { RoomField } from './RoomField'
import { AsyncSelectField } from './AsyncSelectField'
import trans from '../../../trans'
import { AccountDimensionField } from './AccountDimensionField'
import { FieldArray } from 'redux-form/immutable'
import { MpkSplitField } from '../../UserProfilePage/SensitiveData/MpkSplitField'

const FormField = (props) => {
  const {
    input,
    label,
    placeholder,
    type,
    meta: { touched, error },
    options,
    after,
    html,
    inputOnly,
    withError,
    emptyValue,
    withErrorTooltip,
    isSaving,
    labelInner = false,
    clearable,
    labeltop,
    inverse,
    errorStyles,
    groupWrapper = true,
    ...custom
  } = props

  const classes = classnames({
    'form-group': groupWrapper,
    'form-group--label-top': labeltop,
    'form-group--inverse': inverse,
    'form-group--hidden': type === 'hidden',
  })

  let inputComponent = null
  switch (type) {
    case 'hidden':
      inputComponent = <div />
      break
    case 'text':
    case 'number':
      inputComponent = <TextField {...input} {...custom} placeholder={label} type={type} />
      break

    case 'counter':
      inputComponent = <CounterField {...input} {...custom} />
      break

    case 'datepicker':
      const { minDate, maxDate } = custom

      inputComponent = (
        <DatePickerField
          name={input.name}
          placeholder={placeholder ? placeholder : label}
          onChange={(value) => {
            input.onChange(value ? value.format(config.apiDateTimeFormat) : null)
          }}
          onFocus={input.onFocus}
          value={input.value ? moment(moment(input.value).format(config.apiDateTimeFormat)) : null}
          minDate={minDate}
          maxDate={maxDate}
          disabled={custom.disabled}
          disabledCalendar={props.disabledCalendar}
          {...custom}
        />
      )
      break

    case 'account-dimensions':
      inputComponent = (
        <AccountDimensionField
          name={input.name}
          value={input.value}
          onChange={input.onChange}
          options={options}
          error={error}
          placeholder={placeholder}
          {...custom}
        />
      )
      break

    case 'rooms':
      inputComponent = (
        <div>
          <div>
            <RoomField
              name={input.name}
              value={input.value}
              onChange={input.onChange}
              disabled={custom.disabled}
            />
          </div>
          <div>{after}</div>
        </div>
      )
      break

    case 'traveler-select':
      const peopleSelectValue = (value) => {
        if (value instanceof Map) {
          return value.toJS()
        }

        return value
      }

      inputComponent = (
        <div>
          <div>
            <TravelerSelectField
              name={input.name}
              options={options}
              value={peopleSelectValue(input.value)}
              onChange={input.onChange}
              disabled={custom.disabled}
            />
          </div>
          <div>{after}</div>
        </div>
      )
      break

    case 'select':
      const value = (value) => {
        if (value instanceof Map) {
          return value.toJS()
        }

        if (value instanceof List) {
          return value.toJS()
        }

        return value
      }

      inputComponent = (
        <div>
          <div>
            <SelectField
              name={input.name}
              options={options}
              value={value(input.value)}
              placeholder={placeholder ? placeholder : label}
              onChange={input.onChange}
              disabled={custom.disabled}
              onFocus={input.onFocus}
              clearable={clearable}
              emptyValue={emptyValue}
              {...custom}
              onBlur={
                custom.handleBlur
                  ? (...args) => {
                      custom.onBlur && custom.onBlur(...args)
                      custom.handleBlur(...args)
                    }
                  : custom.onBlur
              }
            />
          </div>
          <div>{after}</div>
        </div>
      )
      break

    case 'async-select':
      const selectedOptionParsed = props.selectedOption
        ? {
            id: props.selectedOption.id,
            label: props.selectedOption.name,
            data: props.selectedOption,
          }
        : null

      inputComponent = (
        <div>
          <div>
            <AsyncSelectField
              name={input.name}
              value={selectedOptionParsed}
              placeholder={placeholder ? placeholder : label}
              onChange={input.onChange}
              disabled={custom.disabled}
              onFocus={input.onFocus}
              clearable={clearable}
              {...custom}
              onBlur={
                custom.handleBlur
                  ? (...args) => {
                      custom.onBlur && custom.onBlur(...args)
                      custom.handleBlur(...args)
                    }
                  : custom.onBlur
              }
              loadOptions={props.loadOptions}
              noOptionsMessage={props.noOptionsMessage}
              loadingMessage={props.loadingMessage}
              delay={500}
            />
          </div>
          <div>{after}</div>
        </div>
      )
      break

    case 'grouped-select':
      inputComponent = (
        <div>
          <div>
            <SelectField
              name={input.name}
              options={options}
              value={input.value}
              placeholder={label}
              onChange={(value) => {
                input.onChange(value)
              }}
              disabled={custom.disabled}
              onFocus={input.onFocus}
              clearable={clearable}
              nested
            />
          </div>
          <div>{after}</div>
        </div>
      )
      break

    case 'select-object':
      inputComponent = (
        <div>
          <div>
            <SelectField
              name={input.name}
              options={options}
              value={input.value}
              placeholder={label}
              onChange={(value) => {
                input.onChange(value)
              }}
              disabled={custom.disabled}
              onFocus={input.onFocus}
              clearable={clearable}
            />
          </div>
          <div>{after}</div>
        </div>
      )
      break

    case 'html':
      inputComponent = <div>{html()}</div>
      break

    case 'grouped-checkbox':
      inputComponent = options.map((option, index) => {
        return (
          <FormGroup key={index}>
            <CheckboxField
              name={`${input.name}[${index}]`}
              value={option.value}
              label={option.name}
              checked={input.value.includes(option.value)}
              disabled={option.disabled}
              onChange={(checked) => {
                const newValue = [...input.value]
                if (checked) {
                  newValue.push(option.value)
                } else {
                  newValue.splice(newValue.indexOf(option.value), 1)
                }

                return input.onChange(newValue)
              }}
            />
          </FormGroup>
        )
      })

      break

    case 'checkbox':
      inputComponent = (
        <CheckboxField
          name={input.name}
          label={label}
          onChange={(value) => {
            input.onChange(value)
          }}
          checked={input.value === true}
          disabled={custom.disabled}
        />
      )
      break
    case 'location':
      const { inputProps, noAutoTrigger } = custom
      inputComponent = (
        <AutoCompleteGeocoding
          disabled={custom.disabled}
          name={input.name}
          inputProps={{
            value: input.value,
            onChange: (address) => {
              const formatted_address =
                address && address.city && address.country
                  ? `${address.city}, ${address.country}`
                  : address
                  ? address.country
                  : null
              let event = {
                ...address,
                additional_data: {
                  ...address.additional_data,
                  formatted: address.formatted_address,
                },
                formatted_address,
              }

              if (custom.immutable) {
                event = fromJS(event)
              }
              input.onChange(event)
            },
            ...inputProps,
            ...(noAutoTrigger ? { noAutoTrigger } : {}),
          }}
          {...custom}
        />
      )
      break
    case 'toggle':
      inputComponent = (
        <Toggle
          name={input.name}
          onChange={(value) => {
            input.onChange(value)
          }}
          checked={input.value === true}
          {...custom}
        />
      )
      break

    case 'textarea':
      inputComponent = <Textarea {...input} {...custom} />
      break

    case 'distance':
      const { distanceSuggestion } = custom
      inputComponent = (
        <DistanceField
          {...input}
          {...custom}
          distanceSuggestion={distanceSuggestion}
          placeholder={label}
          type={type}
        />
      )
      break

    case 'formatted_number':
      inputComponent = <FormattedNumberField {...input} {...custom} options={options} />
      break
    case 'amount': {
      const {
        suggestedAmount,
        suggestedAmountCurrency,
        suggestedAmountLabel = null,
        multiplier = 1,
        disabled = false,
      } = custom
      inputComponent = (
        <AmountField
          suggestedAmountLabel={suggestedAmountLabel}
          suggestedAmount={suggestedAmount}
          suggestedAmountCurrency={suggestedAmountCurrency}
          multiplier={multiplier}
          disabled={disabled}
          {...input}
        />
      )
      break
    }
    case 'airport': {
      inputComponent = <AirportField {...input} {...custom} />
      break
    }
    case 'currency':
      const { currencies } = custom
      inputComponent = (
        <div>
          <div>
            <CurrencyField
              name={input.name}
              currencies={currencies}
              value={input.value}
              placeholder={label}
              onChange={(value) => {
                input.onChange(value)
              }}
              disabled={custom.disabled}
              onFocus={input.onFocus}
              label=' '
            />
          </div>
          <div>{after}</div>
        </div>
      )
      break
  }

  let errorComponent = null

  if (error) {
    if (!inputOnly || withError) {
      const classes = classnames({
        'form-group__error': true,
        ...errorStyles,
      })

      errorComponent = (
        <div className={classes}>
          <p>{error}</p>
        </div>
      )
    } else if (withErrorTooltip) {
      const errorStr = error.join('<br/>')

      errorComponent = (
        <Tooltip
          className='field-error'
          theme={'error'}
          html={
            <div className={'tooltip-with-icon'}>
              <Icon type='info' className={'icon-info-error'} />{' '}
              <span dangerouslySetInnerHTML={{ __html: errorStr }} />
            </div>
          }
        >
          <Icon type='info' className={'icon-info-error'} />
        </Tooltip>
      )
    }
  }

  if (type === 'hidden') {
    return <div className={classes}>{errorComponent}</div>
  }

  if (inputOnly) {
    return (
      <div className={errorComponent ? 'has-error' : ''}>
        <div className='field-input'>{inputComponent}</div>

        {errorComponent}
      </div>
    )
  }

  if (inverse) {
    return (
      <div className={classes}>
        <div className='form-group__input-wrapper'>
          {inputComponent}
          {errorComponent}
          {isSaving && 'Loading...'}
        </div>
        {label ? <span className='form-group__label'>{label}</span> : null}
      </div>
    )
  }

  return (
    <div className={classes}>
      {label ? <span className='form-group__label'>{label}</span> : null}

      <div className='form-group__input-wrapper'>
        {inputComponent}
        {errorComponent}
        {isSaving && 'Loading...'}
      </div>
    </div>
  )
}

export { FormField }
export default { FormField }
