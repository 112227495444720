import React from 'react'
import { trans } from '../../../../trans'
import { TravelPointLabel } from '../../../TravelPointLabel'
import { TravelPointDate } from '../../../TravelPointDate'
import TravelPointStartStopHours from '../../../TravelPointStartStopHours/TravelPointStartStopHours'
import { TYPE_PLANE } from '../../../../constants/access-lum-sum'
import moment from 'moment'
import { ForeignCurrenciesTooltip } from '../../../ForeignCurrenciesTooltip'
import { AmountFormatter } from '../../../AmountFormatter'
import { Plane as PlaneForm } from '../Containers/Plane'
import { OfferTimeline } from './OfferTimeline'

class PlaneTimeline extends OfferTimeline {
  icon() {
    return 'plane2_fill'
  }

  renderElement() {
    const { currencies, element, request, onRemove } = this.props
    const { isOpen } = this.state

    return (
      <PlaneForm
        request={request}
        element={element}
        currencies={currencies}
        onSave={this.onSave}
        onRemove={onRemove}
        isOpen={isOpen}
      />
    )
  }

  renderLabel() {
    const { element } = this.props

    const from = element.virtual ? element.getEndLocation() : element.getStartLocation()
    const to = element.virtual ? element.getStartLocation() : element.getEndLocation()
    const date = element.virtual ? element.return_at : element.arrival_at

    if (!from.city || !to.city || !date) {
      return trans('request.fill-travel-data')
    } else {
      return (
        <div>
          <TravelPointLabel location={from} showDirections /> → <TravelPointLabel location={to} />
          <TravelPointDate start={date} />
          {this.getTravelPointStartStop()}
        </div>
      )
    }
  }

  getTravelPointStartStop() {
    const { element } = this.props

    if (
      element &&
      element.offer_uuid &&
      element.target_real_departure_at &&
      element.target_real_arrival_at &&
      !element.virtual
    ) {
      return (
        <TravelPointStartStopHours
          type={TYPE_PLANE}
          start={moment(element.target_real_departure_at)}
          end={moment(element.target_real_arrival_at)}
        />
      )
    } else if (
      element &&
      element.offer_uuid &&
      element.return_real_departure_at &&
      element.return_real_arrival_at &&
      element.virtual
    ) {
      return (
        <TravelPointStartStopHours
          type={TYPE_PLANE}
          start={moment(element.return_real_departure_at)}
          end={moment(element.return_real_arrival_at)}
        />
      )
    }
  }

  renderForeignCurrenciesTooltip() {
    const { element } = this.props

    const instanceCurrency =
      this.props.instanceCurrency && this.props.instanceCurrency.length > 0
        ? this.props.instanceCurrency
        : this.props.request.user.instance.default_currency

    return (
      <ForeignCurrenciesTooltip
        instanceCurrency={instanceCurrency}
        foreignCurrencies={element.getAllAmounts()}
      />
    )
  }

  renderCalculatedAmount() {
    const { element } = this.props

    if (element.virtual) {
      return trans('request.return-travel')
    } else {
      const amount = element.converted_amount
      const currency = element.calculated_amount_currency

      return (
        <div className='flex-end-center'>
          {this.renderForeignCurrenciesTooltip()}{' '}
          <span>
            <AmountFormatter amount={amount} /> {currency}
          </span>
        </div>
      )
    }
  }
}

export { PlaneTimeline }
export default PlaneTimeline
