import React from 'react'
import classnames from 'classnames'

export const TYPE_WARNING = 'warning'

export class Label extends React.Component<any, any> {
  render() {
    const { level, children } = this.props
    const classes = classnames({
      label: true,
      'label--is-warning': level === TYPE_WARNING,
    })

    return <span className={classes}>{children}</span>
  }
}

export default { Label, TYPE_WARNING }
