import React, { Component, Fragment } from 'react'
import { components } from '../../../utils/react-select'
import { SmartCheckbox } from '../../../ui/Input/SmartCheckbox'
import classnames from 'classnames'
import { trans } from '../../../trans'
import { get } from 'lodash'
import SelectWithOutsideClosing from './SelectWithOutsideClosing'

const Input = () => null

const Option = (props) => {
  const classes = classnames({
    'react-select__option': true,
    'react-select__option--is-selected': props.isSelected,
  })

  return (
    <div className={classes} onClick={() => props.selectOption(props)}>
      <div className='react-select__option-row'>
        <div className='react-select__multi-row-wrapper'>
          <div>
            <div className='react-select__option-row-label'>{props.label}</div>
          </div>

          <SmartCheckbox name={props.value.name} checked={props.isSelected} />
        </div>
      </div>
    </div>
  )
}

const ValueContainer = (props) => {
  const values = props.getValue()
  const quantity = values.length

  let label = props.selectProps.placeholder

  if (quantity === 1) {
    label = get(values, '0.label')
  }

  if (quantity > 1) {
    label = trans('request-car-element.order-car-type-selected-types', { quantity })
  }

  const toggleMenu = () => {
    if (props.selectProps.menuIsOpen) {
      props.selectProps.onMenuClose()
    } else {
      props.selectProps.onMenuOpen()
    }
  }

  return (
    <div
      className='react-select__value-container react-select__multi-value-label'
      onClick={() => toggleMenu()}
    >
      {label}
    </div>
  )
}

const newComponents = {
  ...components,
  Input,
  Option,
  ValueContainer,
}

class RentCarTypeMultiSelect extends Component<any, any> {
  onChange = (values) => {
    const { onChange } = this.props
    const value = values.map((value) => value.value)
    onChange(value)
  }

  getValue = () => {
    const { options, value } = this.props
    return options.filter((option) => value.indexOf(option.value) !== -1)
  }

  render() {
    const { name, options, placeholder, label, labeltop } = this.props
    const classes = classnames({
      'react-select': true,
      'react-select--no-border': false,
    })

    const wrapperClasses = classnames({
      'react-select__container--outer': true,
      'react-select__container--nowrap': true,
      'form-group--label-top': labeltop,
    })
    return (
      <Fragment>
        <div className={wrapperClasses}>
          <span className='form-group__label'>{label}</span>
          <SelectWithOutsideClosing
            classes={classes}
            name={name}
            options={options}
            components={newComponents}
            isMulti={true}
            isClearable={false}
            isSearchable={false}
            hideSelectedOptions={false}
            closeMenuOnSelect={false}
            backspaceRemovesValue={false}
            openMenuOnClick={true}
            onChange={this.onChange}
            value={this.getValue()}
            placeholder={placeholder}
          />
        </div>
      </Fragment>
    )
  }
}

RentCarTypeMultiSelect.propTypes = {}

export default RentCarTypeMultiSelect
export { RentCarTypeMultiSelect }
