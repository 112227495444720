import React from 'react'
import _ from 'lodash'
import trans from '../trans'
import { Link } from 'react-router-dom'
import { InputErrorComponent } from './InputErrorComponent'
import { Checkbox } from '../ui'

export const LoginFormComponent = ({
  onLogin,
  onChangeInput,
  email,
  password,
  errors,
  error_email = '',
  error_password = '',
}) => {
  if (!_.isEmpty(errors)) {
    if (errors['email']) error_email = 'input--error'
    if (errors['password']) error_password = 'input--error'
  }

  return (
    <form className='form-group form-group--label-top form-group--vertical' onSubmit={onLogin}>
      <label className='form-group__wrapper'>
        <input
          type='text'
          name='email'
          value={email}
          className={'form-group__wrapper-input input input--text ' + error_email}
          placeholder={trans('login-page.e-mail')}
          onChange={onChangeInput}
        />
        <InputErrorComponent errors={_.get(errors, 'email', [])} />
      </label>
      <label className='form-group__wrapper'>
        <input
          type='password'
          name='password'
          value={password}
          className={'form-group__wrapper-input input input--text ' + error_password}
          placeholder={trans('login-page.password')}
          onChange={onChangeInput}
        />
        <InputErrorComponent errors={_.get(errors, 'password', [])} />
      </label>
      <div className='remember'>
        <Checkbox label={trans('login-page.remember-me')} />
        <Link to='/login/lost-password'>{trans('login-page.password-reminder')}</Link>
      </div>
      <input
        type='submit'
        value={trans('login-page.login')}
        className='btn--primary btn input--submit'
      />
    </form>
  )
}
