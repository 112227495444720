import PropTypes from 'prop-types'
import React from 'react'
import {
  TRAVEL_ACCOMODATION,
  TRAVEL_COMPANY_CAR_TRIP,
  TRAVEL_FERRY,
  TRAVEL_PLANE_TRIP,
  TRAVEL_PRIVATE_ACCOMODATION,
  TRAVEL_PRIVATE_CAR_TRIP,
  TRAVEL_RENTED_CAR_TRIP,
  TRAVEL_TRAIN_TRIP,
  TRAVEL_BUS_TRIP,
  TRAVEL_REPLACEMENT_CAR_TRIP,
  TRAVEL_PASSENGER_CAR_TRIP,
  TRAVEL_ACCOMMODATION_PROVIDED,
} from '../../constants/travel'
import moment from 'moment'
import trans from '../../trans'
import { TravelPointLabel } from '../TravelPointLabel'
import { TravelPointDate } from '../TravelPointDate'
import TravelPointStartStopHours from '../TravelPointStartStopHours/TravelPointStartStopHours'
import { TYPE_PLANE, TYPE_TRAIN } from '../../constants/access-lum-sum'

class TripRequestElementLabel extends React.Component<any, any> {
  render() {
    const { element } = this.props

    const date = moment(element.virtual ? element.getEndDate() : element.getStartDate())
    const startLocation = element.virtual ? element.getEndLocation() : element.getStartLocation()
    const endLocation = element.virtual ? element.getStartLocation() : element.getEndLocation()

    const arrivalDate = element.getStartDate()
    const departureDate = element.getEndDate()

    let label = null
    switch (element.type) {
      case TRAVEL_ACCOMODATION:
      case TRAVEL_PRIVATE_ACCOMODATION:
      case TRAVEL_ACCOMMODATION_PROVIDED:
        label = (
          <div>
            <TravelPointLabel location={startLocation} showDirections />
            <TravelPointDate start={date} />
          </div>
        )
        break
      case TRAVEL_RENTED_CAR_TRIP:
        label = (
          <div>
            {trans('request-car-element.rent-car-label')}
            <TravelPointDate start={date} />
          </div>
        )
        break
      case TRAVEL_PASSENGER_CAR_TRIP:
        label = (
          <div>
            {trans('request-car-element.passenger-car-label')}
            <TravelPointDate start={date} />
          </div>
        )
        break
      case TRAVEL_PRIVATE_CAR_TRIP:
      case TRAVEL_COMPANY_CAR_TRIP:
      case TRAVEL_REPLACEMENT_CAR_TRIP:
        label = (
          <div>
            <TravelPointLabel location={startLocation} /> →{' '}
            <TravelPointLabel
              className='travel-point__label'
              location={endLocation}
              showDirections
            />
            &nbsp;
            <TravelPointDate start={arrivalDate} end={departureDate} />
          </div>
        )
        break
      case TRAVEL_PLANE_TRIP:
      case TRAVEL_TRAIN_TRIP:
        let typeInternal = element.type === TRAVEL_PLANE_TRIP ? TYPE_PLANE : TYPE_TRAIN
        label = (
          <div className=''>
            <TravelPointLabel location={startLocation} showDirections /> →{' '}
            <TravelPointLabel className='travel-point__label' location={endLocation} />
            <TravelPointDate start={arrivalDate} />
            {element &&
            element.offer_uuid &&
            element.target_real_departure_at &&
            element.target_real_arrival_at &&
            !element.virtual ? (
              <TravelPointStartStopHours
                type={typeInternal}
                start={moment(element.target_real_departure_at)}
                end={moment(element.target_real_arrival_at)}
              />
            ) : (
              <TravelPointStartStopHours
                type={typeInternal}
                start={moment(element.return_real_departure_at)}
                end={moment(element.return_real_arrival_at)}
              />
            )}
          </div>
        )
        break
      case TRAVEL_FERRY:
        label = (
          <div>
            <TravelPointLabel location={startLocation} showDirections /> →{' '}
            <TravelPointLabel className='travel-point__label' location={endLocation} />
            <TravelPointDate start={arrivalDate} end={departureDate} />
          </div>
        )
        break
      case TRAVEL_BUS_TRIP:
        label = (
          <div>
            <TravelPointLabel location={startLocation} showDirections /> →{' '}
            <TravelPointLabel className='travel-point__label' location={endLocation} />
            <TravelPointDate
              start={arrivalDate}
              end={element.isRoundTrip() ? departureDate : null}
            />
          </div>
        )
        break
      default:
        label = null
    }

    return <div>{label}</div>
  }
}

TripRequestElementLabel.propTypes = {
  element: PropTypes.object.isRequired,
}

export { TripRequestElementLabel }
export default { TripRequestTravelElementLabel: TripRequestElementLabel }
