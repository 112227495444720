import React, { useCallback } from 'react'
import { Field, formValueSelector, reduxForm } from 'redux-form/immutable'
import trans from '../../../../trans'
import { FormField } from '../../../ui/Form'
import Button from '../../../ui/ButtonComponent'
import { connect } from 'react-redux'

let RequestCardForm = (props) => {
  const { handleSubmit, save, limit, validity_period } = props

  const onSave = useCallback(
    (event) => {
      event.preventDefault()

      save({ limit, validity_period })
    },
    [save, handleSubmit],
  )

  return (
    <form className='request-card-form'>
      <Field
        name='limit'
        type='formatted_number'
        label={trans('instance-users.limit')}
        component={FormField}
        options={{
          isAllowed: (values) => {
            const { floatValue } = values

            return floatValue >= 0 && floatValue <= 6000
          },
        }}
        labeltop
      />

      <Field
        name='validity_period'
        type='select'
        label={trans('instance-users.validity')}
        component={FormField}
        options={[
          { label: 3, value: 3 },
          { label: 6, value: 6 },
          { label: 9, value: 9 },
          { label: 12, value: 12 },
          { label: 18, value: 18 },
          { label: 24, value: 24 },
          { label: 30, value: 30 },
          { label: 36, value: 36 },
        ]}
        menuPortalTarget={document.body}
        labeltop
      />

      <div className='is-margin-top'>
        <Button outline color='primary' pull_end type='submit' onClick={onSave}>
          {trans('instance-users.save')}
        </Button>
      </div>
    </form>
  )
}

const formKey = 'request-card-form'
const selector = formValueSelector(formKey)

RequestCardForm = reduxForm({
  form: formKey,
  destroyOnUnmount: true,
  initialValues: {
    validity_period: 36,
    limit: 1000,
  },
})(RequestCardForm)

RequestCardForm = connect((state) => ({
  validity_period: selector(state, 'validity_period'),
  limit: selector(state, 'limit'),
}))(RequestCardForm)

export { RequestCardForm }
export default RequestCardForm
