import React, { Component } from 'react'
import { borderCrossings } from '../../../../store/app/border-crossings'
import { compose } from 'redux'
import Periods from '../Common/Periods'
import lumpSumsDeductions from '../../../../store/app/deductions/lump-sums-deductions'
import { LumpSumExpenseForm } from './LumpSumExpenseForm'
import { Tooltip } from '../../../Tooltip'
import { trans } from '../../../../trans'
import Icon from '../../../ui/IconComponent'

class LumpSum extends Component<any, any> {
  render() {
    const {
      deductions: {
        selectors: { deductions },
        actions: { change, validate },
      },
    } = this.props
    return (
      <div className='declarations'>
        <div className='declarations__tooltip'>
          <Tooltip
            html={
              <div>
                <p>{trans('deductions-widget.header__top-tooltip-content-2-1')}</p>
                <p>{trans('deductions-widget.header__top-tooltip-content-2-2')}</p>
              </div>
            }
          >
            <span className='declarations__tooltip-content'>
              <strong>{trans('deductions-widget.header__top-cell-2')}</strong>
              <Icon greyLight lg type='question_fill' />
            </span>
          </Tooltip>
        </div>

        <div className='declarations__header declarations__grid-row'>
          <div className='declarations__grid-column'>
            <span className='declarations__heading declarations__heading--text-right'>
              {trans('deductions-widget.time')}
            </span>
          </div>

          <div className='declarations__grid-column'>
            <span className='declarations__heading declarations__heading--text-left'>
              {trans('deductions-widget.local-time')}
            </span>
          </div>

          <div className='declarations__grid-column'>
            <span className='declarations__heading'>
              {trans('deductions-widget.accommodation')}
            </span>
          </div>

          <div />

          <div className='declarations__grid-column'>
            <span className='declarations__heading'>{trans('deductions-widget.local-trips')}</span>
          </div>

          <div />
        </div>
        <Periods
          periods={deductions}
          form={LumpSumExpenseForm}
          change={change}
          validate={validate}
        />
      </div>
    )
  }
}

LumpSum.propTypes = {}

const withBorderCrossings = borderCrossings()
const withDeductions = lumpSumsDeductions(true, true, true)

LumpSum = compose(withBorderCrossings, withDeductions)(LumpSum)

export default LumpSum
export { LumpSum }
