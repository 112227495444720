import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import {
  fetchProjects,
  getActiveProjectsSelectOptions,
  isLoaded as isProjectLoaded,
} from '../../store/app/projects'
import { isModuleActive } from '../../store/app/instance'
import { changeRequestValue, getRequest } from '../../store/app/trip-request'

class BasicInfoManager extends React.Component<any, any> {
  componentDidMount() {
    const { fetchProjects } = this.props

    fetchProjects()
  }

  render() {
    const { children, projects, isLoaded, isModuleActive, request, changeRequestValue } = this.props

    const renderProps = {
      projects,
      isLoaded: isLoaded,
      isModuleActive,
      request,
      changeRequestValue,
    }

    return children(renderProps)
  }
}

BasicInfoManager.propTypes = {
  children: PropTypes.func.isRequired,
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      fetchProjects,
      isModuleActive,
      changeRequestValue,
    },
    dispatch,
  )
}

const mapStateToProps = (state) => {
  const request = getRequest(state)

  return {
    projects: getActiveProjectsSelectOptions(state),
    isLoaded: isProjectLoaded(state),
    requestSlug: request.slug,
    request,
  }
}

BasicInfoManager = connect(mapStateToProps, mapDispatchToProps)(BasicInfoManager)

export { BasicInfoManager }
export default BasicInfoManager
