import PropTypes from 'prop-types'
import React, { useCallback } from 'react'
import Button from '../../ui/ButtonComponent'
import { Link } from 'react-router-dom'
import { TYPE_EXPENSE, TYPE_TRIP } from '../../../constants/request'
import trans from '../../../trans'

export const RequestActions = ({ request, acceptRequest, rejectRequest, getRouteByName }) => {
  let url = ''

  if (request['type'] === TYPE_TRIP) {
    url = getRouteByName('main', 'tripRequestShow', { id: request['slug'] })
  } else if (request['type'] === TYPE_EXPENSE) {
    url = getRouteByName('main', 'expenseRequestShow', { id: request['slug'] })
  }

  return (
    <div className='dashboard-requests-to-accept__actions'>
      <Link to={url}>
        <Button outline xs>
          {trans('request.see-details')}
        </Button>
      </Link>
      <Link to={`${url}?reject`}>
        <Button danger xs>
          {trans('request.reject')}
        </Button>
      </Link>
      <Button primary xs onClick={() => acceptRequest(request)}>
        {trans('request.accept')}
      </Button>
    </div>
  )
}

RequestActions.propTypes = {
  request: PropTypes.object.isRequired,
  acceptRequest: PropTypes.func.isRequired,
  rejectRequest: PropTypes.func.isRequired,
  getRouteByName: PropTypes.func.isRequired,
}
