import { Tooltip } from '../Tooltip'
import React, { PropsWithChildren, ReactNode } from 'react'
import trans from '../../trans'

interface InstallmentAlreadyAccountedTooltipProps {
  ability: boolean
}

export default function InstallmentAlreadyAccountedTooltip(
  props: PropsWithChildren<InstallmentAlreadyAccountedTooltipProps>,
): ReactNode {
  if (props.ability !== true) {
    return (
      <Tooltip hide={false} html={trans('installments.installment-already-accounted-tooltip')}>
        {props.children}
      </Tooltip>
    )
  }

  return props.children
}
