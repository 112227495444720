import React, { PureComponent } from 'react'
import InputRange from 'react-input-range'
import Icon from '../ui/IconComponent'
import { trans } from '../../trans'

const MIN = 1
const MAX = 20

class Slider extends PureComponent<any, any> {
  constructor(props) {
    super(props)
    this.state = { value: this.props.value || MIN }
  }

  static getDerivedStateFromProps(props, state) {
    if (props.value !== state.prevPropsValue) {
      return {
        prevPropsValue: props.value,
        value: props.value,
      }
    }
    return null
  }

  handleChange = (value) => {
    this.setState({ value })
  }

  render() {
    const { value } = this.state
    const { max, min, onChange } = this.props
    return (
      <div className='accommodation__range'>
        <label className='accommodation__range-title'>{trans('accommodation.search-range')}</label>
        <div className='accommodation__range-container'>
          <InputRange
            maxValue={max || MAX}
            minValue={min || MIN}
            value={value}
            onChange={this.handleChange}
            onChangeComplete={onChange}
            classNames={{
              activeTrack: 'accommodation__range-track--active',
              inputRange: 'accommodation__range-input',
              labelContainer: 'accommodation__range-label-container',
              maxLabel: 'accommodation__range-label',
              minLabel: 'accommodation__range-label',
              slider: 'accommodation__range-slider',
              sliderContainer: 'accommodation__range-slider-container',
              track: 'accommodation__range-track',
              valueLabel: 'accommodation__range-value',
            }}
          />
        </div>
        <span className='accommodation__range-distance'>{value} km</span>
      </div>
    )
  }
}

export default Slider
