import { useEffect, useState } from 'react'
import APIClient from '../../../services/APIClient'
import { Paginator } from '../../../types/response'
import { Transaction } from '../../../types/transaction'
import { StatementSummary } from '../../../types/statements'
import { ITransactionSuggestion } from '../../../types/transaction-suggestion'
import useTransactions from '../../PaymentsPage/hooks/useTransactions'

export default function useStatement(id: string) {
  const [summaries, setSummaries] = useState<StatementSummary[]>([])

  useEffect(() => {
    setSummaries([
      {
        name: 'my-card.statement-summary-label-balance',
        currency: 'PLN',
        value: 512.3,
      },
      {
        name: 'my-card.statement-summary-label-settled',
        currency: 'PLN',
        value: 1235.3,
      },
      {
        name: 'my-card.statement-summary-label-remind-settled',
        currency: 'PLN',
        value: 614.3,
      },
    ])
  }, [])

  return { summaries }
}
