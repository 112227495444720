import React, { Component, useEffect, useState } from 'react'
import { Redirect, Route, Switch, withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { routes } from '../../routes'
import { Helmet } from 'react-helmet'
import PrivateRouteComponent from '../../components/PrivateRouteComponent'
import Session from '../../services/Session'
import LogoutContainer from '../../containers/LogoutContainer'
import { LoginLayout } from '../../layouts/LoginLayout'
import { makeSelectSession, selectUserAbilities } from './selectors'
import LoginPage from '../LoginPage/index'
import { authorizeUserAction } from './actions'
import { bindActionCreators, compose } from 'redux'
import { NotificationsManager } from '../NotificationsManager'
import { ToastContainer } from 'react-toastify'
import { fetchInstance, resetInstance } from '../../store/app/instance'
import {
  fetchInstanceBeforeLogin,
  getTranslations,
  isInstanceLoading,
} from '../../store/app/instance'
import { LostPasswordPage } from '../../components/LostPasswordPage'
import { RouteManager } from '../RouteManager'
import { Map } from 'immutable'
import { scrollToTop } from '../../components/ScrollToTop'
import localStorage from '../../services/localStorage'
import { getCurrentLang } from '../../utils/lang'
import { userProfile } from '../../store/app/user-profile/providers/userProfile'
import DependencyProvider from '../DependencyProvider'
import { config } from '../../config'
import GoogleMapsProvider from '../../services/GoogleMapsProvider'
import { ToastTransition } from '../../components/Toast'
import Relogin from '../../components/Relogin/Relogin'

class App extends Component<any, any> {
  constructor(props) {
    super(props)

    this.state = {
      loading: true,
    }

    Session.getTokenFromUrl()
  }

  componentDidMount() {
    const { resetInstance, fetchInstance, fetchInstanceBeforeLogin, authorizeUserAction } =
      this.props

    resetInstance()

    Session.isAuthenticated()
      .then((response) => {
        fetchInstance().then(() => {
          authorizeUserAction(response.token, response.data.user)

          config.moment.locale(response.data.user.lang)

          GoogleMapsProvider.setLang(response.data.user.lang)

          GoogleMapsProvider.setCallback(() => {
            this.setState({ loading: false })
          })

          GoogleMapsProvider.enqueue()
        })
      })
      .catch(() => {
        const lang = getCurrentLang()

        localStorage.set('login-page-lang', lang)
        fetchInstanceBeforeLogin(lang).then(() => {
          this.setState({ loading: false })
        })
      })

    if ('ontouchstart' in document.documentElement) {
      document.documentElement.className += ' touch'
    }
  }

  render() {
    if (this.state.loading || this.props.isInstanceLoading) {
      return (
        <div className='main-page-loader main-page-loader--infinite'>
          <div className='main-page-loader__loader' />
        </div>
      )
    }

    const { session, location } = this.props
    const _session = Map(session).toJS()
    return (
      <div>
        <NotificationsManager />
        <Helmet titleTemplate='%s' defaultTitle='mindento.com' />

        <Switch>
          <Route path='/logout'>
            <LogoutContainer />
          </Route>
          <Route path='/login/lost-password' exact>
            <LoginLayout>
              <LostPasswordPage />
            </LoginLayout>
          </Route>
          <Route path='/login/without-sso' exact>
            <LoginLayout noSso={true}>
              <LoginPage />
            </LoginLayout>
          </Route>
          <Route path='/login' exact>
            <LoginLayout>
              <LoginPage />
            </LoginLayout>
          </Route>
          <Route path='/relogin/:slug'>
            <Relogin />
          </Route>
          <RouteManager>
            {({ push }) => (
              <PrivateRouteComponent
                isAuthenticated={_session['isAuthenticated']}
                push={push}
                location={location}
              >
                <DependencyProvider>
                  <Switch>
                    {routes.main.map((route) => {
                      return (
                        <route.layout
                          path={route.path}
                          key={route.path}
                          exact={route.exact}
                          component={route.component}
                          name={route.name}
                        />
                      )
                    })}
                    <Redirect to='/dashboard' />
                  </Switch>
                </DependencyProvider>
              </PrivateRouteComponent>
            )}
          </RouteManager>
        </Switch>

        <ToastContainer
          draggable={false}
          hideProgressBar={true}
          newestOnTop={true}
          closeButton={false}
          position='bottom-right'
          transition={ToastTransition}
        />
      </div>
    )
  }
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      authorizeUserAction,
      resetInstance,
      fetchInstance,
      fetchInstanceBeforeLogin,
    },
    dispatch,
  )

const mapStateToProps = (state) => ({
  session: makeSelectSession()(state),
  userAbilities: selectUserAbilities()(state),
  isInstanceLoading: isInstanceLoading(state),
  translations: getTranslations(state),
})

const withConnect = connect(mapStateToProps, mapDispatchToProps)
const withScrollToTop = scrollToTop()
const withUserProfile = userProfile(false)

export default compose(withRouter, withScrollToTop, withConnect, withUserProfile)(App)
