import React, {
  forwardRef,
  useContext,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react'
import { SelectField } from '../ui/Form'
import { AgGridContext } from './AgGridContext'
import Ellipsis from '../ui/Ellipsis/Ellipsis'
import TruncateMarkup from 'react-truncate-markup'

const SelectEditor = forwardRef((props, ref) => {
  const [, { isRowInEditingState }] = useContext(AgGridContext)
  const [value, setValue] = useState(props.value)
  const refInput = useRef(null)
  const isEditing = isRowInEditingState(props.node.id)

  useImperativeHandle(ref, () => {
    return {
      getValue() {
        return [props.colDef.field, value]
      },
      reset() {
        setValue(props.value)
      },
    }
  })

  if (!isEditing) {
    const formatted = props.valueFormatted ? props.valueFormatted : props.value

    return (
      <TruncateMarkup lines={1} ellipsis={<Ellipsis text={`${formatted}`} />}>
        <span>{formatted}</span>
      </TruncateMarkup>
    )
  }

  return (
    <div className='ag-select-field'>
      <SelectField
        name={props.colDef.field}
        options={props.options}
        value={value}
        onChange={(value) => {
          setValue(value)
        }}
        emptyValue={props.emptyValue}
        menuPortalTarget={document.body}
        clearable={props.clearable}
        ref={refInput}
      />
    </div>
  )
})

export default SelectEditor
