import React, { Component } from 'react'
import classNames from 'classnames'
import Icon from '../ui/IconComponent'
import moment from 'moment'
import { Tooltip } from '../Tooltip'

class Person extends Component<any, any> {
  constructor(props) {
    super(props)

    this.state = {
      active: false,
      removed: false,
    }
  }

  renderTooltipContent() {
    const { comment } = this.props
    return (
      <div>
        <div dangerouslySetInnerHTML={{ __html: comment }} />
      </div>
    )
  }

  renderTooltip(trigger) {
    const { comment } = this.props

    if (!comment) return trigger

    return <Tooltip html={this.renderTooltipContent()}>{trigger}</Tooltip>
  }

  render() {
    const PersonClasses = classNames({
      'person-add__select-container form-group__input-wrapper react-select-container': true,
      active: this.state.active,
    })

    const { date, handleRemove } = this.props
    const { removed } = this.state

    if (removed) return null

    return (
      <li className='person-add'>
        <div className={PersonClasses}>
          {/*@todo icons*/}
          {/*<Icon type="dot_fill"/>*/}
          {this.props.accepted === 'accepted' &&
            this.renderTooltip(<Icon type='checked_fill' lg gradient />)}

          {(this.props.accepted === 'pending' || this.props.accepted === 'waiting_for_step') &&
            this.renderTooltip(<Icon type='question_fill' lg gradient />)}

          {this.props.accepted === 'rejected' &&
            this.renderTooltip(
              <Icon className='is-gradient-danger cursor-help' type='close_fill' lg />,
            )}

          {this.props.accepted === 'need_correct' &&
            this.renderTooltip(
              <Icon className='is-gradient-warning cursor-help' type='warning' lg />,
            )}

          <div className='react-select-container__placeholder person-add__label person-add__label--path'>
            {this.props.name}
          </div>
          {this.props.withRemoveAction && (
            <div className='person-add__edit'>
              {/*<Icon type="edit" onClick={this.handlePersonEditClick} />*/}
              <Icon
                type='trash'
                lg
                onClick={() => {
                  handleRemove().then(() => {
                    this.setState({ removed: true })
                  })
                }}
              />
            </div>
          )}
          {date && <div className='person-add__data'>{moment(date).format('D.MM.YYYY, H:mm')}</div>}
        </div>
      </li>
    )
  }
}

export { Person }
export default { Person }
