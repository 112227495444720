import React from 'react'
import { trans } from '../../../trans'
import SecurityForm from './SecurityForm'
import SecurityChangePinForm from './SecurityChangePinForm'
import PropTypes from 'prop-types'
import { SecurityWelcomeEmail } from './SecurityWelcomeEmail'

class SecurityTab extends React.Component<any, any> {
  constructor(props) {
    super(props)
  }

  render() {
    const { subjectUser } = this.props

    return (
      <div>
        <h1 className='tabs__content-heading'>{trans('user.security')}</h1>

        <SecurityForm subjectUser={subjectUser} />

        <SecurityChangePinForm subjectUser={subjectUser} />

        <SecurityWelcomeEmail subjectUser={subjectUser} />
      </div>
    )
  }
}

SecurityTab.propTypes = {
  subjectUser: PropTypes.object,
}

export default SecurityTab
export { SecurityTab }
