export const USER_AUTHORIZE = 'USER_AUTHORIZE'
export const USER_LOGOUT = 'USER_LOGOUT'
export const SET_CURRENT_USER = 'SET_CURRENT_USER'

export const ADD_FORM_ERRORS = 'ADD_FORM_ERRORS'
export const CLEAR_FORM_ERRORS = 'CLEAR_FORM_ERRORS'
export const CLEAR_FORM_INPUT_ERRORS = 'CLEAR_FORM_INPUT_ERRORS'
export const SET_LOADING = 'SET_LOADING'
export const AFTER_SAVE = 'AFTER_SAVE'
export const API_ERROR = 'API_ERROR'
export const CREATE_RETURN_ELEMENT = 'CREATE_RETURN_ELEMENT'
