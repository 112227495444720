import PropTypes from 'prop-types'
import React from 'react'
import { SmartCheckbox } from '../../../ui/Input/SmartCheckbox'

export class CheckboxField extends React.Component<any, any> {
  render() {
    return <SmartCheckbox {...this.props} />
  }
}

CheckboxField.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  checked: PropTypes.bool,
}

CheckboxField.defaultProps = {}
