import { countries } from '../store/app/countries'
import { compose } from 'redux'
import { languages } from '../store/app/languages'
import { provide } from './LoginAs'
import tableConfigs from '../store/app/table-config/provider'
import { Loader } from '../components/ui/LoadingOverlay/Loader'

const DependencyProvider = (props) => {
  if (
    !props.languages.selectors.isLoading &&
    !props.tableConfigs.selectors.isLoading &&
    !props.countries.selectors.isLoading
  ) {
    return props.children
  }

  return <Loader />
}

const withCountries = countries(true, true)
const withLanguages = languages(true, true)
const withTableConfigs = tableConfigs(true, true)

export default compose(withCountries, withLanguages, withTableConfigs, provide)(DependencyProvider)
