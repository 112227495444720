import PropTypes from 'prop-types'
import React from 'react'
import moment from 'moment'
import { Cell, Row, Table, TBody, THead } from '../../components/ui/AccordionTable'
import { Col, Radio } from '../../ui'
import Button from '../ui/ButtonComponent'
import { CheckboxField } from '../ui/Form'
import trans from '../../trans'
import { AmountFormatter } from '../AmountFormatter'
import { capitalize, get } from 'lodash'
import { Tooltip } from '../Tooltip'
import APIClient from '../../services/APIClient'
import UserName from '../UserName/UserName'
import SortableCellWrapper from '../Sortable/SortableCellWrapper'
import {
  STATUS_ACCEPTANCE_OF_SETTLEMENT,
  STATUS_FINISH,
  STATUS_SETTLEMENT,
  STATUS_TRANSFERRED,
} from '../../constants/request'
import TruncateBreakable from '../TruncateBreakable/TruncateBreakable'
import { ExpenseRequestActions } from '../ExpenseRequestAccountingPage/ExpenseRequestActions'
import { TableConfig, TableConfigFieldType } from '../../store/app/table-config/table-config.model'
import tableConfig from '../../store/app/table-config'

enum SettlementActions {
  REMINDER = 'reminder',
  PRINT = 'print',
  EXPORT = 'export',
}

interface SettlementRequestsListTableState {
  action: SettlementActions
}

interface SettlementRequestsListTableProps {
  [key: string]: unknown
  tableConfig: TableConfig[]
}

const ColumnWidthConfig = {
  settled_at: 150,
  uid: 130,
  'user.full_name': 130,
  company_code: 125,
  name: null,
  requestElementsAccountedSumAmount: 130,
  status_translated: 135,
}

export class SettlementRequestsListTable extends React.Component<
  SettlementRequestsListTableProps,
  SettlementRequestsListTableState
> {
  state = {
    action: null,
  }

  getRequestUrl = (request) => {
    const { getRouteByName } = this.props
    return getRouteByName('main', `show${capitalize(request.type)}Settlement`, { id: request.slug })
  }

  formatNameTooltip = (request) => {
    if (request.trip_starts && request.trip_ends) {
      const startDate = moment(request.trip_starts).format('YYYY-MM-DD')
      const endDate = moment(request.trip_ends).format('YYYY-MM-DD')

      if (startDate === endDate) {
        return `${request.name_short} (${startDate})`
      }

      return `${request.name_short} (${startDate} - ${endDate})`
    }

    return request.name_short
  }

  sendReminder = (selectedIds) => {
    const { requests, selectDeselectMany } = this.props
    return APIClient.sendUnaccountedReminder({ ids: selectedIds }).then((response) => {
      selectDeselectMany(requests, false)
      this.setAction(null)
    })
  }

  exportClaims = (selectedIdsToExport) => {
    const { requests, selectDeselectMany } = this.props
    return APIClient.exportClaims({ slugs: selectedIdsToExport.join(',') }).then((response) => {
      selectDeselectMany(requests, false)
      this.setAction(null)
    })
  }

  printClaims = (selectedIdsToPrint) => {
    const { requests, selectDeselectMany } = this.props
    return APIClient.printClaims({ slugs: selectedIdsToPrint.join(',') }).then((response) => {
      selectDeselectMany(requests, false)
      this.setAction(null)
    })
  }

  setAction(action: SettlementActions) {
    this.setState({ action })
  }

  isSelectedAll = (requests) => {
    const { isItemSelected } = this.props

    if (requests.length === 0) {
      return false
    }

    return requests.filter((request) => isItemSelected(request)).length === requests.length
  }

  applyAction() {
    const { action } = this.state

    switch (action) {
      case SettlementActions.EXPORT:
        return this.exportClaims(this.getExportableRequests())
      case SettlementActions.PRINT:
        return this.printClaims(this.getPrintableRequests())
      case SettlementActions.REMINDER:
        return this.sendReminder(this.getReminderRequests())
    }
  }

  getReminderRequests(): string[] {
    const { selectedRequests } = this.props

    return selectedRequests
      ? selectedRequests
          .filter((request) => {
            return [STATUS_SETTLEMENT, STATUS_ACCEPTANCE_OF_SETTLEMENT].includes(request.status)
          })
          .map((request) => request.slug)
      : []
  }

  getExportableRequests(): string[] {
    const { selectedRequests } = this.props

    return selectedRequests
      ? selectedRequests
          .filter((request) => {
            return [STATUS_TRANSFERRED, STATUS_FINISH].includes(request.status)
          })
          .map((request) => request.slug)
      : []
  }

  getPrintableRequests(): string[] {
    const { selectedRequests } = this.props

    return selectedRequests ? selectedRequests.map((request) => request.slug) : []
  }

  renderHeaders() {
    const { tableConfig, sorter, onChangeSorter } = this.props

    if (!tableConfig) {
      return null
    }

    return tableConfig.map((item: TableConfig) => {
      return (
        <SortableCellWrapper
          sorter={sorter}
          onChangeSorter={onChangeSorter}
          name={item.sortField}
          style={{ width: ColumnWidthConfig[item.field] ? undefined : '100%' }}
        >
          {({ icon }) => (
            <Cell
              fixedWidth={ColumnWidthConfig[item.field] ?? 'auto'}
              alignRight={item.fieldType === TableConfigFieldType.CURRENCY}
              className={'table-accordion__col__sortable'}
            >
              {trans(item.header)}&nbsp;{icon}
            </Cell>
          )}
        </SortableCellWrapper>
      )
    })
  }

  renderColumnContent(config: TableConfig, entity) {
    const value = get(entity, config.field)

    switch (config.fieldType) {
      case TableConfigFieldType.CURRENCY:
        return <AmountFormatter amount={value as number} />

      default:
        return <TruncateBreakable text={value} lines={2} />
    }
  }

  renderColumns(entity) {
    const { tableConfig } = this.props

    if (!tableConfig) {
      return null
    }

    return tableConfig.map((item: TableConfig) => {
      return (
        <Cell
          fixedWidth={ColumnWidthConfig[item.field] ?? 'auto'}
          alignRight={item.fieldType === TableConfigFieldType.CURRENCY}
        >
          {this.renderColumnContent(item, entity)}
        </Cell>
      )
    })
  }

  render() {
    const {
      requests,
      isLoading,
      selectDeselectMany,
      selectDeselectOne,
      isItemSelected,
      assignToMe,
      selectedRequests,
      pagination,
      changePage,
      push,
      sorter,
      onChangeSorter,
      currentUser,
    } = this.props

    const selectedIdsForMonit = this.getReminderRequests()
    const selectedIdsForPrint = this.getPrintableRequests()
    const selectedIdsForExport = this.getExportableRequests()

    return (
      <div>
        <Table
          className={'table-stylesheet-theme'}
          striped
          pagination={{
            ...pagination,
            changePage,
            className: selectedIdsForMonit.length > 0 ? 'has-notification-bar' : '',
            align: 'center',
          }}
        >
          <THead>
            <Row>
              <Cell fixedWidth={50} cellClassName='has-checkbox'>
                <CheckboxField
                  onChange={(value) => selectDeselectMany(requests, value)}
                  name='select-deleselect-all'
                  checked={this.isSelectedAll(requests)}
                />
              </Cell>

              {this.renderHeaders()}

              <SortableCellWrapper
                sorter={sorter}
                onChangeSorter={onChangeSorter}
                name={'request_accounting_user_last_name'}
              >
                {({ icon }) => (
                  <Cell fixedWidth={160} className={'table-accordion__col__sortable'}>
                    {trans('request.decretation')}&nbsp;{icon}
                  </Cell>
                )}
              </SortableCellWrapper>
            </Row>
          </THead>
          <TBody isLoading={isLoading}>
            {requests.map((request, i) => {
              const enableAssignButton = request['abilities']['assignAccountant']

              return (
                <Row className='request-list__table' key={i} href={this.getRequestUrl(request)}>
                  <Cell fixedWidth={50} cellClassName='has-checkbox'>
                    <CheckboxField
                      name={`item-${request['slug']}`}
                      onChange={() => selectDeselectOne(request)}
                      checked={isItemSelected(request)}
                    />
                  </Cell>

                  {this.renderColumns(request)}

                  <Cell fixedWidth={160}>
                    {request['accountingUser'] && (
                      <UserName
                        firstName={request['accountingUser'].first_name}
                        lastName={request['accountingUser'].last_name}
                      />
                    )}
                    {!request['accountingUser'] && enableAssignButton && (
                      <div>
                        <Button
                          primary
                          xs
                          className='charge-card__operation-button charge-card__operation-button--delete'
                          onClick={(e) => {
                            e.preventDefault()
                            assignToMe(request).then(() => {
                              push(this.getRequestUrl(request))
                            })
                          }}
                        >
                          {trans('request.assign-to-me')}
                        </Button>
                      </div>
                    )}
                  </Cell>
                </Row>
              )
            })}
          </TBody>
          {selectedRequests.length > 0 && (
            <Row className='notification-bar'>
              <Col sm={12} is_pull_end>
                <div className='notification-bar__row'>
                  <ExpenseRequestActions hideNotApplicable>
                    <div className='react-select__option'>
                      <Radio
                        name='status'
                        disabled={!selectedIdsForMonit.length}
                        label={trans('request.send-reminder') + ` (${selectedIdsForMonit.length})`}
                        onClick={() => {
                          this.setAction(SettlementActions.REMINDER)
                        }}
                      />
                    </div>

                    {currentUser.instance.features.FEATURE_INTEGRATION_MODE === 'FILE' && (
                      <div className='react-select__option'>
                        <Radio
                          name='status'
                          disabled={!selectedIdsForExport.length}
                          label={trans('request.export') + ` (${selectedIdsForExport.length})`}
                          onClick={() => {
                            this.setAction(SettlementActions.EXPORT)
                          }}
                        />
                      </div>
                    )}

                    <div className='react-select__option'>
                      <Radio
                        name='status'
                        disabled={!selectedIdsForPrint.length}
                        label={
                          trans('global.print-accounting-documents') +
                          ` (${selectedIdsForPrint.length})`
                        }
                        onClick={() => {
                          this.setAction(SettlementActions.PRINT)
                        }}
                      />
                    </div>
                  </ExpenseRequestActions>

                  <Button
                    primary
                    xs
                    disabled={!this.state.action}
                    onClick={() => this.applyAction()}
                  >
                    {trans('global.perform')}
                  </Button>
                </div>
              </Col>
            </Row>
          )}
        </Table>
      </div>
    )
  }
}

SettlementRequestsListTable.propTypes = {
  requests: PropTypes.array.isRequired,
  isLoading: PropTypes.bool.isRequired,
  getRouteByName: PropTypes.func.isRequired,
  currentUser: PropTypes.object.isRequired,
  selectDeselectMany: PropTypes.func.isRequired,
  selectDeselectOne: PropTypes.func.isRequired,
  isItemSelected: PropTypes.func.isRequired,
  assignToMe: PropTypes.func.isRequired,
  selectedRequests: PropTypes.array.isRequired,
  changePage: PropTypes.func.isRequired,
}
