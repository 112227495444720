import PropTypes from 'prop-types'
import React from 'react'
import { Cell, Row, Table, TBody } from '../../components/ui/AccordionTable'
import { trans } from '../../trans'
import { AmountFormatter } from '../AmountFormatter'
import { renderKey } from '../../utils/react'
import { Col as GridCol, Row as GridRow } from '../../ui/Grid'

class VATSummary extends React.Component<any, any> {
  render() {
    const { items, sum } = this.props

    return (
      <div className='vat-summary'>
        <GridRow>
          <GridCol xs={12}>
            <Table>
              <TBody>
                {items.map((item, key) => (
                  <Row key={key} thin>
                    <Cell xs={12}>
                      <Row thin>
                        <Cell xs={8}>
                          <div className='form-group form-group--label-top'>
                            <span className='form-group__label'>
                              {trans('document.vat-account')}
                            </span>
                          </div>
                        </Cell>
                        <Cell xs={4}>
                          <div className='form-group form-group--label-top'>
                            <span className='form-group__label vat-summary__amount-label'>
                              {trans('document.vat-amount')}
                            </span>
                          </div>
                        </Cell>
                      </Row>
                      <Row thin>
                        <Cell xs={8}>
                          <div>{item['account']}</div>
                        </Cell>
                        <Cell xs={4} style={{ textAlign: 'right' }}>
                          {item['value'] === null && <span>{trans('document.no-vat')}</span>}
                          {item['value'] !== null && (
                            <span>
                              <AmountFormatter amount={item['value']} /> {item['currency']}
                            </span>
                          )}
                        </Cell>
                      </Row>
                        {item['reverseAccount'] && (
                            <Row thin>
                        <Cell xs={8}>
                          <div>{item['reverseAccount']}</div>
                        </Cell>
                        <Cell xs={4} style={{ textAlign: 'right' }}>
                          {item['value'] !== null && (
                            <span>
                              -<AmountFormatter amount={item['value']} /> {item['currency']}
                            </span>
                          )}
                        </Cell>
                      </Row>
                    )}
                    </Cell>
                  </Row>
                ))}
                <Row>
                  <Cell xs={12}>
                    {Object.keys(sum).map((currency, i) => {
                      return (
                        <Row thin key={renderKey(sum[currency], currency)}>
                          <Cell xs={8}>
                            <span>
                              {i === 0 && <strong>{trans('document.vat-sum')}</strong>}
                              {i > 0 && <span>&nbsp;</span>}
                            </span>
                          </Cell>
                          <Cell xs={4} alignRight>
                            <strong>
                              <AmountFormatter amount={sum[currency]} /> {currency}
                            </strong>
                          </Cell>
                        </Row>
                      )
                    })}
                  </Cell>
                </Row>
              </TBody>
            </Table>
          </GridCol>
        </GridRow>
      </div>
    )
  }
}

VATSummary.propTypes = {
  items: PropTypes.array.isRequired,
}

export { VATSummary }
