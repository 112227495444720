import { includes, isEmpty } from 'lodash'
import moment from 'moment'
import { config } from '../config'
import { Map } from 'immutable'

export const prepareRequestDates = (values, excludes = []) => {
  if (values instanceof Map) {
    values = values.toJS()
  }

  Object.keys(values).forEach((key) => {
    if ((key.indexOf('_at') !== -1 || key.indexOf('date') !== -1) && !includes(excludes, key)) {
      if (!isEmpty(values[key])) {
        if (moment.isMoment(values[key])) {
          values[key] = values[key]
        } else {
          values[key] = moment(values[key])
        }

        values[key] = values[key].hours(0).minutes(0).seconds(0).format(config.apiDateTimeFormat)
      }
    }
  })

  return values
}
