import React, { Fragment } from 'react'
import { trans } from '../../trans'
import TravelTime from './Offer/TravelTime'
import Relation from './Offer/Relation'
import { get } from 'lodash'
import OperatorLogo from './Offer/OperatorLogo'
import Changes from './Offer/Changes'
import ChangeDetails from './Offer/ChangeDetails'
import Tariff from './Offer/Tariff'
import Luggage from './Offer/Luggage'
import Additional from './Reservation/Additional'
import ServiceSelect from './ServiceSelect'
import { BOOKING_OFFER_CHANGED } from '../../store/app/flights-booking'
import SelectedServices from './SelectedServices'
import TariffConditions from './Reservation/TariffConditions'
import classNames from 'classnames'
import Avatar from '../Avatar/Avatar'

const checkIsBookingPending = (offer) => {
  const bookingStatus = get(offer, 'options.0.booking', null)
  return bookingStatus !== null && bookingStatus !== BOOKING_OFFER_CHANGED
}

const SingleOffer = (props) => {
  const { offer, flightClass, query, isReturn, totalPaxes, travelers, abilities } = props

  const airlines = get(offer, 'attributes.airlineName', null)
  const duration = get(offer, 'attributes.travelTime', null)

  const isBookingPending = checkIsBookingPending(offer)
  const canEdit = abilities.edit || abilities.bookOffers

  const tariffDescription = get(offer, 'attributes.tariffDescription', null)
  const tariffSlug = get(offer, 'attributes.tariffSlug', null)
  const linkText = (
    <Fragment>
      {tariffDescription} ({tariffSlug})
    </Fragment>
  )

  const baggagePlaceholder =
    totalPaxes === 1
      ? trans('flights-booking.select-luggage')
      : trans('flights-booking.select-luggage-per-person')

  const servicePlaceholder =
    totalPaxes === 1
      ? trans('flights-booking.additional-service-select-placeholder')
      : trans('flights-booking.additional-service-select-placeholder-per-person')

  const Wrapper = ({ children }) => (
    <div className='plane-offer__detail plane-offer__paxes'>{children}</div>
  )
  const PaxesWrapper = travelers.length > 1 ? Wrapper : Fragment

  const gridClasses = classNames({
    'plane-offer__details-grid': true,
    'plane-offer__details-grid--reservation': true,
    'plane-offer__details-grid--paxes': totalPaxes > 1,
    'plane-offer__details-grid--single-pax': totalPaxes === 1,
  })

  return (
    <div className='plane-offer__grid plane-offer__grid--reservation'>
      <div className='plane-offer__airlines'>
        <div className='plane-offer__flight-details'>
          <OperatorLogo offer={offer} />
        </div>

        <div className='plane-offer__flight-details'>
          <span className='plane-offer__hour'>
            <TravelTime offer={offer} />
          </span>
          <span className='plane-offer__flight-info'>
            <span className='plane-offer__flight-airline'>{airlines}</span>
            {flightClass && (
              <span className='plane-offer__flight-class'>
                {trans('flights-booking.' + flightClass)}
              </span>
            )}
          </span>
        </div>
      </div>

      <div className={gridClasses}>
        <div className='plane-offer__detail'>
          <span className='plane-offer__detail-heading'>{duration}</span>
          <span className='plane-offer__detail-content'>
            <Relation
              offer={offer}
              flight_from={query.flight_from}
              flight_to={query.flight_to}
              isReturn={isReturn}
            />
          </span>
        </div>

        <div className='plane-offer__detail'>
          <span className='plane-offer__detail-heading'>
            <Changes offer={offer} />
          </span>
          <span className='plane-offer__detail-content'>
            <ChangeDetails offer={offer} />
          </span>
        </div>

        <PaxesWrapper>
          {travelers.map((pax) => (
            <div key={pax.slug} className='plane-offer__pax'>
              {travelers.length > 1 && (
                <div className='plane-offer__pax-user request-traveler-item'>
                  <div className='request-traveler-avatar'>
                    <Avatar user={pax} />
                  </div>

                  <div className='request-traveler-name'>
                    <strong>{pax.full_name}</strong>
                    <br />
                    <span className='request-traveler-email'>{pax.email}</span>
                  </div>
                </div>
              )}

              <div className='plane-offer__detail plane-offer__detail--luggage'>
                <Luggage offer={offer} pax={pax}>
                  {!canEdit || isBookingPending ? (
                    <SelectedServices name='baggage' offer={offer} pax={pax} />
                  ) : (
                    <ServiceSelect
                      offer={offer}
                      name='baggage'
                      placeholder={baggagePlaceholder}
                      pax={pax}
                    />
                  )}
                </Luggage>
              </div>

              <div className='plane-offer__detail plane-offer__detail--service'>
                <Additional>
                  {!canEdit || isBookingPending ? (
                    <SelectedServices name='priority-boarding' offer={offer} pax={pax} />
                  ) : (
                    <ServiceSelect
                      offer={offer}
                      name='priority-boarding'
                      placeholder={servicePlaceholder}
                      pax={pax}
                    />
                  )}
                </Additional>
              </div>
            </div>
          ))}
        </PaxesWrapper>

        <div className='plane-offer__detail plane-offer__detail--tariff'>
          <Tariff offer={offer} noText={true} />
          <TariffConditions linkText={linkText} />
        </div>
      </div>
    </div>
  )
}

export default SingleOffer
export { SingleOffer }
