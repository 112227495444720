import PropTypes from 'prop-types'
import React from 'react'
import classNames from 'classnames'
import Icon from '../ui/IconComponent'
import trans from '../../trans'
import { AsyncSelectField } from '../ui/Form/AsyncSelectField'
import { loadOptionsForUsers } from '../../store/app/users-for-select'

class AddPerson extends React.Component<any, any> {
  constructor(props) {
    super(props)

    this.state = {
      showSelect: false,
      showIcon: true,
    }
  }

  addHandler = () => {
    this.setState({
      showSelect: true,
      showIcon: false,
    })
  }

  changeHandler = (acceptor) => {
    const { handleAddPeople } = this.props

    handleAddPeople(acceptor)

    this.setState({
      showSelect: false,
      showIcon: true,
    })
  }

  render() {
    if (!this.props.active) {
      return null
    }

    const classes = classNames({
      'person-add person-add--select': true,
      active: this.props.active,
    })

    return (
      <li key='add-select' className={classes}>
        {this.state.showSelect && (
          <div
            className='person-add__select-container form-group__input-wrapper react-select-container active'
            style={{ display: 'block' }}
          >
            <AsyncSelectField
              className='input--react-select react-select'
              loadOptions={this.props.loadOptions}
              returnFullItemValueOnChange={true}
              onChange={(value) => this.changeHandler(value)}
              placeholder={trans('dashboard-page.assign-element')}
              noOptionsMessage={() => {
                return trans('user.no-results-for-search-message')
              }}
              loadingMessage={() => {
                return trans('user.searching-message')
              }}
              delay={500}
            />
          </div>
        )}

        {this.state.showIcon && (
          <div className='person-add__add' onClick={this.addHandler}>
            <Icon type='plus' lg gradient />
            <div className='person-add__label'>{trans('trip-request.path-add-reviewer')}</div>
          </div>
        )}
      </li>
    )
  }
}

AddPerson.propTypes = {
  active: PropTypes.bool.isRequired,
  options: PropTypes.array.isRequired,
  handleAddPeople: PropTypes.func.isRequired,
  loadOptions: PropTypes.func.isRequired,
}

AddPerson.defaultProps = {
  active: true,
}

export { AddPerson }
export default { AddPerson }
