import React, { Fragment } from 'react'
import { Helmet } from 'react-helmet'
import { BreadCrumbs } from '../../containers'
import { Link } from 'react-router-dom'
import { AccountDocumentManager } from '../../containers/AccountDocumentManager'
import { Col, Row } from '../../ui/Grid'
import { AccountDocumentForm } from '../../containers/AccountDocumentForm'
import { AccountDocumentElementForm } from '../../containers/AccountDocumentElementForm'
import Button from '../ui/ButtonComponent'
import { RouteManager } from '../../containers/RouteManager'
import trans from '../../trans'
import { VATSummaryManager } from '../../containers/VATSummaryManager'
import { VATSummary } from '../VATSummary'
import Icon from '../ui/IconComponent'
import { TYPE_TRIP } from '../../constants/request'
import { SectionHeader } from '../ui/SectionHeader'
import { renderKey } from '../../utils/react'
import { DocumentAccountRules } from '../DocumentAccountRules'
import { getDocumentPageBreadcrumbs } from '../../breadcrumbs'
import { Ability } from '../RequestPageCommon/Ability/Ability'
import { Loader } from '../ui/LoadingOverlay/Loader'
import DocumentPreview from '../../containers/DocumentPage/DocumentPreview'
import ImagePreview from '../../containers/DocumentPage/ImagePreview'
import Sticky from '../Sticky/Sticky'
import { AccountDimensionManager } from '../../containers/AccountDimensionManager'
import DocumentAccountDimensionsSummary from '../DocumentAccountDimensionsSummary/DocumentAccountDimensionsSummary'

class AccountDocumentPage extends React.Component<any, any> {
  constructor(props) {
    super(props)

    this.state = {
      forms: {},
      closeButtonClicked: false,
    }
  }

  getCloseRedirectUrl() {
    const pos = window.location.pathname.indexOf('/documents/')
    return window.location.pathname.slice(0, pos)
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.state['closeButtonClicked']) {
      const { forms } = this.state
      const {
        history: { push },
      } = this.props
      if (Object.keys(forms).length === 0) {
        push(this.getCloseRedirectUrl())
      } else {
        const numLockedForms = Object.keys(forms)
          .map((key) => {
            return forms[key]
          })
          .filter((value) => value === true).length

        if (numLockedForms === 0) {
          push(this.getCloseRedirectUrl())
        }
      }
    }
  }

  renderLastBreadcrumb(request) {
    if (!request) {
      return trans('global.document')
    }

    if (request['type'] === TYPE_TRIP) {
      return trans('global.request-type-trip')
    } else {
      return trans('global.request-type-expense')
    }
  }

  handleClickClose = (e) => {
    e.preventDefault()
    this.setState({ closeButtonClicked: true })
  }

  handleForm(formName) {
    return ({ subject }) => {
      subject.onUnlock(() => {
        const { forms } = this.state
        forms[formName] = false
        this.setState({ forms })
      })
      subject.onLock(() => {
        const { forms } = this.state
        forms[formName] = true
        this.setState({ forms })
      })
    }
  }

  unHandleForm(formName) {
    return () => {
      const { forms } = this.state
      delete forms[formName]
      this.setState({ forms })
    }
  }

  renderBreadcrumbs(document) {
    return (
      <RouteManager>
        {({ match, getRouteByName }) => (
          <BreadCrumbs>
            {getDocumentPageBreadcrumbs(match, getRouteByName, document).map((item) => (
              <Link to={item.url}>{item.label}</Link>
            ))}
          </BreadCrumbs>
        )}
      </RouteManager>
    )
  }

  isPDF(fileType) {
    return fileType === 'pdf'
  }

  shouldDisplaySettlements(document) {
    return document.payment !== 'corporate_card'
  }

  renderPreview(document) {
    if (this.isPDF(document.file_type)) {
      return (
        <DocumentPreview
          id={document.id}
          name={document.name}
          style={{
            maxWidth: '100%',
            width: '100%',
            border: '0 none',
          }}
        />
      )
    } else {
      return (
        <ImagePreview
          id={document.id}
          style={{
            maxWidth: '100%',
            border: '1px solid #c1c1c1',
            width: '100%',
          }}
        />
      )
    }
  }

  renderHeader(document) {
    const header =
      document.request.status === 'finish'
        ? trans('request.state_settlement')
        : trans('request.decretation')

    return (
      <section className='section section--no-padding section--no-border'>
        <header className='section__header'>
          <h1 className='h2 section__header-title'>{header}</h1>
        </header>
      </section>
    )
  }

  renderDocumentForm(document, isDocumentLoaded, accountDimensions) {
    return (
      <div className='account-document-page__section-shadow'>
        <header className='section__header'>
          <h2 className='h2 section__header-title'>{trans('document.basic-info')}</h2>
        </header>

        {isDocumentLoaded && (
          <div>
            <AccountDocumentForm
              componentDidMount={this.handleForm('AccountDocumentForm')}
              exchange={{
                date: document.issue_date,
                currency: document.currency.code,
              }}
              accountDimensions={accountDimensions}
            />
          </div>
        )}
      </div>
    )
  }

  renderDocumentElements(
    document,
    isDocumentLoaded,
    instanceCurrency,
    getExchangeRate,
    accountDimensions,
  ) {
    return (
      isDocumentLoaded &&
      document['elements'] &&
      document['elements'].map((element, index) => {
        return (
          <div className='account-document-page__section-shadow' key={renderKey(element['id'])}>
            <AccountDocumentElementForm
              key={`document-element-form-${element['id']}`}
              element={element}
              document={document}
              allowRemove={index !== 0}
              componentDidMount={this.handleForm(`AccountDocumentElementForm-${element['id']}`)}
              instanceCurrency={instanceCurrency}
              getExchangeRate={getExchangeRate}
              accountDimensions={accountDimensions}
            />
          </div>
        )
      })
    )
  }

  render() {
    const {
      match: {
        params: { id },
      },
      location: { pathname },
    } = this.props

    return (
      <div className='account-document-page'>
        <Helmet title={trans('global.document')} />
        <RouteManager>
          {({ getRouteByName }) => (
            <AccountDocumentManager documentId={id}>
              {({
                document,
                isAddFormVisible,
                updateAddFormVisible,
                isDocumentLoaded,
                instanceCurrency,
                getExchangeRate,
              }) => (
                <AccountDimensionManager fetchMethod={'document'} fetchArgs={[id]}>
                  {({
                    accountDimensions: documentAccountDimensions,
                    isLoaded: documentAccountDimensionIsLoaded,
                  }) => (
                    <AccountDimensionManager fetchMethod={'documentElement'} fetchArgs={[id]}>
                      {({ accountDimensions, isLoaded: accountDimensionsIsLoaded }) => {
                        if (
                          !isDocumentLoaded ||
                          !document.abilities ||
                          !accountDimensionsIsLoaded ||
                          !documentAccountDimensionIsLoaded
                        ) {
                          return <Loader />
                        }

                        let readOnly = !document.abilities.account
                        return (
                          <div className={readOnly ? 'read-only' : ''}>
                            {this.renderBreadcrumbs(document)}
                            {this.renderHeader(document)}

                            <Fragment>
                              <Row nowrap>
                                <Col fill className='col col--grow col--img' key='thumb'>
                                  <Sticky offset={10}>{this.renderPreview(document)}</Sticky>
                                </Col>
                                <Col
                                  className='col--form'
                                  key='form'
                                  style={{ maxWidth: 500, minWidth: 500, width: '100%' }}
                                >
                                  <div id='form'>
                                    {this.renderDocumentForm(
                                      document,
                                      isDocumentLoaded,
                                      documentAccountDimensions,
                                    )}

                                    {this.renderDocumentElements(
                                      document,
                                      isDocumentLoaded,
                                      instanceCurrency,
                                      getExchangeRate,
                                      accountDimensions,
                                    )}

                                    {document.abilities.account && (
                                      <div className='account-document-page__add-button-wrapper'>
                                        <Ability
                                          abilities={document.abilities}
                                          ability={['account']}
                                        >
                                          <Button
                                            onClick={() => updateAddFormVisible(true)}
                                            className='account-document-page__add-button'
                                            primary
                                          >
                                            {trans('document-element.add-decretation')}
                                          </Button>
                                        </Ability>
                                      </div>
                                    )}
                                    {isAddFormVisible && (
                                      <div className='account-document-page__section-shadow'>
                                        <AccountDocumentElementForm
                                          element={{}}
                                          document={document}
                                          allowRemove
                                          componentDidMount={this.handleForm(
                                            'AccountDocumentFormNew',
                                          )}
                                          componentWillUnmount={this.unHandleForm(
                                            'AccountDocumentFormNew',
                                          )}
                                          getExchangeRate={getExchangeRate}
                                          instanceCurrency={instanceCurrency}
                                          accountDimensions={accountDimensions}
                                        />
                                      </div>
                                    )}

                                    {document['elements'] && (
                                      <>
                                        <div className='account-document-page__section-shadow'>
                                          <SectionHeader
                                            headerTag='h1'
                                            caption={
                                              <Fragment>
                                                <Icon
                                                  type={'calculator_2'}
                                                  lg
                                                  grey
                                                  className='account-document-page__section-icon'
                                                />
                                                VAT
                                              </Fragment>
                                            }
                                          />
                                          <VATSummaryManager
                                            getExchangeRate={getExchangeRate}
                                            instanceCurrency={instanceCurrency}
                                          >
                                            {({ items, sum }) => (
                                              <VATSummary items={items} sum={sum} />
                                            )}
                                          </VATSummaryManager>
                                        </div>

                                        <div className='account-document-page__section-shadow'>
                                          <SectionHeader
                                            headerTag='h1'
                                            caption={
                                              <Fragment>
                                                <Icon
                                                  type={'calculator_2'}
                                                  lg
                                                  grey
                                                  className='account-document-page__section-icon'
                                                />
                                                {trans('document.account-dimension-summary')}
                                              </Fragment>
                                            }
                                          />

                                          <DocumentAccountDimensionsSummary
                                              gross={document.converted_gross_sum_of_elements}
                                              net={document.converted_net_sum_of_elements}
                                              vat={document.converted_tax_sum_of_elements}
                                          />
                                        </div>
                                      </>
                                    )}
                                    <Row>
                                      <Col xs={12} is_pull_end>
                                        <Ability
                                          abilities={document.abilities}
                                          ability={['account']}
                                        >
                                          <DocumentAccountRules document={document} />
                                        </Ability>
                                      </Col>
                                    </Row>
                                    <Row>
                                      <Col xs={12} is_pull_end>
                                        <Ability
                                          abilities={document.abilities}
                                          ability={['account']}
                                        >
                                          <Button primary onClick={this.handleClickClose}>
                                            {trans('document.finish-document-edition')}
                                          </Button>
                                        </Ability>
                                      </Col>
                                    </Row>
                                  </div>
                                </Col>
                              </Row>
                            </Fragment>
                          </div>
                        )
                      }}
                    </AccountDimensionManager>
                  )}
                </AccountDimensionManager>
              )}
            </AccountDocumentManager>
          )}
        </RouteManager>
      </div>
    )
  }
}

export { AccountDocumentPage }
export default { AccountDocumentPage }
