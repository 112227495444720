import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { getCurrency as getInstanceCurrency } from '../../store/app/instance'
import APIClient from '../../services/APIClient'
import { documents } from '../../store/app/document-list'

class AccountingDocumentsManagerBase extends React.Component<any, any> {
  uploadDocument = (request, files) => {
    const { upload } = this.props
    for (let file of files) {
      upload(file, () => {
        return APIClient.uploadAccountingDocument(request['slug'], file)
      })
    }
  }

  render() {
    const { children, instanceCurrency, request, currentUser, documentsList } = this.props
    const renderProps = {
      instanceCurrency,
      request,
      currentUser,
      documentsList,
      uploadDocument: this.uploadDocument,
      deleteDocument: this.props.delete,
    }

    return children(renderProps)
  }
}

AccountingDocumentsManagerBase.propTypes = {
  request: PropTypes.object.isRequired,
}

const mapStateToProps = (state) => ({
  instanceCurrency: getInstanceCurrency(state),
  currentUser: state.get('global').get('currentUser'),
})

const withConnect = connect(mapStateToProps)
const withDocuments = documents()

const AccountingDocumentsManager = compose(
  withDocuments,
  withConnect,
)(AccountingDocumentsManagerBase)

export { AccountingDocumentsManager }
export default { AccountingDocumentsManager }
