import { getInitialState } from './index'
import { get, mergeWith, isArray, cloneDeep, set } from 'lodash'

//actions
export const resetAction = (state, payload) => {
  return {
    ...state,
    [payload.name]: getInitialState(),
  }
}

export const setAction = (state, payload) => {
  return {
    ...state,
    [payload.name]: {
      ...state[payload.name],
      ...payload.data,
      initialized: true,
    },
  }
}

export const replaceOfferAction = (state, payload) => {
  return {
    ...state,
    [payload.name]: {
      ...state[payload.name],
      offers: state[payload.name].offers.map((offer) => {
        const offerToReplace = payload.offers.find((o) => o.uuid === offer.uuid)

        return offerToReplace || offer
      }),
    },
  }
}

export const setOffersAction = (state, payload) => {
  const offers = payload.offers.map((offer) => {
    offer.option = offer.options.find((option) => option.chosen === true)
    return offer
  })

  return {
    ...state,
    [payload.name]: {
      ...state[payload.name],
      offers,
      status: payload.status,
      uuid: payload.uuid,
      totalPaxes: payload.totalPaxes,
      initialized: true,
      loading: false,
    },
  }
}

export const selectOfferAction = (state, payload) => {
  const offers = state[payload.name].offers.map((offer) => {
    offer.selected = offer.chosen = offer.uuid === payload.offer_uuid
    offer.option.chosen = true
    return offer
  })

  const newOffers = [...offers]

  return {
    ...state,
    [payload.name]: {
      ...state[payload.name],
      offers: newOffers,
    },
  }
}

export const setQueryFieldAction = (state, payload) => {
  return {
    ...state,
    [payload.name]: {
      ...state[payload.name],
      query: {
        ...state[payload.name].query,
        [payload.query.name]: payload.query.value,
      },
      integrity: false,
    },
  }
}

export const setLoadingAction = (state, payload) => {
  return {
    ...state,
    [payload.name]: {
      ...state[payload.name],
      loading: payload.loading,
    },
  }
}

export const setIntegrityAction = (state, payload) => {
  return {
    ...state,
    [payload.name]: {
      ...state[payload.name],
      integrity: payload.integrity,
    },
  }
}

export const setFilterAction = (state, payload) => {
  const newFilter = mergeWith(
    state[payload.name].filter,
    payload.filter,
    (currentValue, newValue) => {
      if (isArray(newValue)) {
        return newValue
      }
    },
  )

  return {
    ...state,
    [payload.name]: {
      ...state[payload.name],
      filter: newFilter,
    },
  }
}

export const setFiltersAction = (state, payload) => {
  return {
    ...state,
    [payload.name]: {
      ...state[payload.name],
      filters: payload.filters,
    },
  }
}

export const setQueryAction = (state, payload) => {
  return {
    ...state,
    [payload.name]: {
      ...state[payload.name],
      query: payload.query,
    },
  }
}

export const searchAnotherOneAction = (state, payload) => {
  return {
    ...state,
    [payload.name]: {
      ...state[payload.name],
      offers: [],
      uuid: null,
      hasAgreement: false,
    },
  }
}

export const bookOfferAction = (state, payload) => {
  const newOffers = state[payload.name].offers.map((offer) => {
    if (offer.chosen) {
      offer.options = offer.options.map((option) => {
        if (option.chosen) {
          option.booking = payload.status
        }
        return option
      })
    }
    return offer
  })

  return {
    ...state,
    [payload.name]: {
      ...state[payload.name],
      offers: newOffers,
    },
  }
}

export const toggleSearchAction = (state, payload) => {
  const current = get(state, `${payload.name}.enabled`, true)
  return {
    ...state,
    [payload.name]: {
      ...state[payload.name],
      enabled: !current,
    },
  }
}

export const setEnableAction = (state, payload) => {
  return {
    ...state,
    [payload.name]: {
      ...state[payload.name],
      enabled: payload.state,
    },
  }
}

export const setAgreementAction = (state, payload) => {
  return {
    ...state,
    [payload.name]: {
      ...state[payload.name],
      hasAgreement: payload.value,
    },
  }
}

export const changeAction = (state, payload) => {
  return set(cloneDeep(state), `${payload.name}.${payload.path}`, payload.value)
}

export const changeStatusAction = (state, payload) => {
  const offers = state[payload.name].offers.map((offer) => {
    if (offer.uuid === payload.offerUuid) {
      offer.booking = payload.status
      offer.options = offer.options.map((option) => {
        if (option.uuid === payload.optionUuid) {
          option.booking = payload.status
        }

        return option
      })
    }

    return offer
  })

  return {
    ...state,
    [payload.name]: {
      ...state[payload.name],
      offers,
    },
  }
}

export const changeErrorMessageSlugAction = (state, payload) => {
  const offers = state[payload.name].offers.map((offer) => {
    if (offer.uuid === payload.offerUuid) {
      offer.errorMessageSlug = payload.errorMessageSlug
    }

    return offer
  })

  return {
    ...state,
    [payload.name]: {
      ...state[payload.name],
      offers,
    },
  }
}
