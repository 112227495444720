import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import Icon from '../ui/IconComponent'
import { trans } from '../../trans'
import { FormGroup } from '../../ui'
import { loadOptionsForRequestTravelers } from '../../store/app/users-for-select'
import { AsyncSelectField } from '../ui/Form/AsyncSelectField'
import { SelectField } from '../ui/Form'
import { setNonDelegation } from '../../store/app/non-delegation/creators'

const STEP_OPTIONS = 'STEP_OPTIONS'
const STEP_TRAVELERS = 'STEP_TRAVELERS'

interface RequestTravelerSelectProps {
  delegationTripSwitch: boolean
  onSelect: (value) => void
  onSetPrivateTrip: (value) => void
  setNonDelegation: (value) => void
  isPrivate: boolean
  featurePrivateTripRequestsEnabled: boolean
  companyId: number
  delegation: boolean
  showDelegationOptions: boolean
}

export class RequestTravelerSelect extends React.Component<RequestTravelerSelectProps, any> {
  state = {
    isOpen: false,
    step: STEP_OPTIONS,
  }

  ref = null

  componentDidMount() {
    this.onSelect = this.onSelect.bind(this)
    this.setPrivateTrip = this.setPrivateTrip.bind(this)
    this.showOptions = this.showOptions.bind(this)

    document.addEventListener('mousedown', this.handleClickOutside)
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside)
  }

  handleClickOutside = (event) => {
    if (this.state.isOpen && this.ref && !this.ref.contains(event.target)) {
      this.setState({ isOpen: false })
    }
  }

  onSelect(option) {
    const { onSelect } = this.props

    this.setState({ isOpen: false }, () => {
      onSelect(option.slug)
    })
  }

  hide() {
    this.setState({ isOpen: false })
  }

  showOptions() {
    this.setState({
      isOpen: true,
      step: STEP_OPTIONS,
    })
  }

  setPrivateTrip = () => {
    const { onSetPrivateTrip } = this.props

    onSetPrivateTrip(true)

    this.setState({ isOpen: false })
  }

  showTravelers = () => {
    this.setState({
      isOpen: true,
      step: STEP_TRAVELERS,
    })
  }

  setDelegation(delegation: boolean) {
    const { setNonDelegation } = this.props

    setNonDelegation(delegation)

    this.hide()
  }

  renderOptions() {
    const {
      isPrivate,
      featurePrivateTripRequestsEnabled,
      delegationTripSwitch,
      showDelegationOptions,
      delegation,
    } = this.props

    return (
        <div className='react-select__menu-list'>
            <div onClick={() => this.hide()} className='react-select__option'>
                {trans('trip-request.trip-option-not-applicable')}
            </div>

            <div onClick={() => this.showTravelers()} className='react-select__option'>
                {trans('trip-request.trip-option-add-traveler')}
            </div>

            {delegationTripSwitch && showDelegationOptions && (
                <div onClick={() => this.setDelegation(!delegation)} className='react-select__option'>
                    {trans(
                        delegation
                            ? 'trip-request.trip-type-non-delegation'
                            : 'trip-request.trip-type-delegation',
                    )}
                </div>
            )}

            {featurePrivateTripRequestsEnabled && !isPrivate && (
                <div onClick={() => this.setPrivateTrip()} className='react-select__option'>
                    {trans('trip-request.trip-option-private')}
                </div>
            )}

        </div>
    )
  }

  renderTravelers() {
    const { companyId } = this.props

    return (
      <AsyncSelectField
        className='input--react-select react-select'
        loadOptions={(search, prevOptions) =>
          loadOptionsForRequestTravelers(search, prevOptions, companyId)
        }
        returnFullItemValueOnChange={true}
        onChange={(value) => this.onSelect(value)}
        placeholder={trans('dashboard-page.assign-element')}
        noOptionsMessage={() => {
          return trans('user.no-results-for-search-message')
        }}
        loadingMessage={() => {
          return trans('user.searching-message')
        }}
        delay={500}
        menuIsOpen={true}
      />
    )
  }

  renderMenu() {
    const { step } = this.state

    if (step === STEP_OPTIONS) {
      return this.renderOptions()
    }

    if (step === STEP_TRAVELERS) {
      return this.renderTravelers()
    }

    return null
  }

  render() {
    const { isOpen, step } = this.state

    return (
      <div className='request-traveler-select'>
        <FormGroup labeltop label={trans('trip-request.trip-options')}>
          <Icon type='other' onClick={() => this.showOptions()} />

          {isOpen && (
            <div
              className='react-select__select-container react-select-custom'
              ref={(ref) => {
                this.ref = ref
              }}
            >
              <div className='react-select__menu'>{this.renderMenu()}</div>
            </div>
          )}
        </FormGroup>
      </div>
    )
  }
}

RequestTravelerSelect.propTypes = {
  onSelect: PropTypes.func.isRequired,
  featurePrivateTripRequestsEnabled: PropTypes.bool,
  companyId: PropTypes.number.isRequired,
}
