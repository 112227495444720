import React from 'react'
import { cssTransition } from 'react-toastify'

const ToastTransition = cssTransition({
  enter: 'toast-custom__transition--in',
  exit: 'toast-custom__transition--out',
})

export { ToastTransition }
export default ToastTransition
