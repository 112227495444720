import React, { Component } from 'react'
import { Loader } from '../ui/LoadingOverlay/Loader'
import { trans } from '../../trans'
import Button from '../ui/ButtonComponent'
import Regular from '../Formik/Fields/Regular'
import SelectCountry from '../Formik/Fields/SelectCountry'
import Icon from '../ui/IconComponent'
import APIClient from '../../services/APIClient'

const AddProvider = (props) => {
  const stripToNumber = (registryNumber = '') => {
    if (registryNumber.length > 0) {
      return registryNumber.replace(/\D/g, '')
    }

    return registryNumber
  }

  const { handleSubmit, isSubmitting, countries } = props

  const isSearchEnabled =
    props.values.registry_number && stripToNumber(props.values.registry_number).length === 10

  const search = (e, props) => {
    e.preventDefault()

    if (isSearchEnabled !== true) {
      return
    }

    props.setSubmitting(true)

    APIClient.searchProvider(stripToNumber(props.values.registry_number))
      .then((response) => {
        if (response.data) {
          props.setValues({
            registry_number: response.data.registry_number,
            name: response.data.name,
            address: response.data.address,
            city: response.data.city,
            postcode: response.data.postcode,
            country_id: countries.find((country) => {
              return country.country_code === response.data.country_code
            }).id,
            instance_id: props.values.instance_id,
            source: response.data.source,
            erp_id: props.values.erp_id,
            company_slug: props.companySlug,
          })
        }
        props.setSubmitting(false)
      })
      .catch((response) => {
        props.setSubmitting(false)
      })
  }

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      search(event, props)
    }
  }

  return (
    <form onSubmit={handleSubmit}>
      {isSubmitting && <Loader />}

      <div className='user-profile__fields-wrapper'>
        <div className='user-profile__fields'>
          <div style={{ display: 'flex', width: '100%' }}>
            <Regular
              type='text'
              label={trans('document.provider-nip')}
              name='registry_number'
              onKeyPress={handleKeyPress}
            />
            <span
              className='btn-add'
              onClick={(e) => {
                search(e, props)
              }}
            >
              <Icon
                className={isSearchEnabled ? 'is-gradient-success' : ''}
                grey={!isSearchEnabled}
                type='search'
              />
            </span>
          </div>

          <Regular type='text' label={trans('document.provider-name')} name='name' />

          <SelectCountry
            label={trans('document.country')}
            name='country_id'
            countries={countries}
            maxMenuHeight={80}
          />

          <Regular type='text' label={trans('document.address')} name='address' />

          <Regular type='text' label={trans('document.city')} name='city' />

          <Regular type='text' label={trans('document.postcode')} name='postcode' />

          <Regular
            type='text'
            label={trans('document.erp-id')}
            name='erp_id'
            disabled={props.suggestedProvider.disposable}
          />
        </div>
        <div />
      </div>
      <div className='is-margin-top'>
        <Button primary pull_end type='submit' onClick={handleSubmit}>
          {trans('document.add-provider')}
        </Button>
      </div>
    </form>
  )
}

AddProvider.propTypes = {}

export default AddProvider
export { AddProvider }
