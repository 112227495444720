import PropTypes from 'prop-types'
import React from 'react'
import { AccountDimensionManager } from '../../containers/AccountDimensionManager'
import { BasicInfoManager } from '../../containers/BasicInfoManager'
import { LoadingOverlay } from '../ui/LoadingOverlay'
import trans from '../../trans'
import { BasicInfoForm } from '../../containers/BasicInfoForm'

const BasicRequestInfoRenderer = () => {
  return (
    <BasicInfoManager>
      {({ mpks, projects, isLoaded, isModuleActive, request, changeRequestValue }) => {
        return (
          <AccountDimensionManager fetchMethod={'requestHeader'} fetchArgs={[request.slug]}>
            {({ accountDimensions, isLoaded: accountDimensionsIsLoaded }) => {
              if (!isLoaded || !accountDimensionsIsLoaded) {
                return (
                  <div>
                    <LoadingOverlay />
                  </div>
                )
              }
              return (
                <section className='section'>
                  <header className='section__header'>
                    <h1 className='h2 section__header-title'>
                      {trans('trip-request.basic-info-header')}
                    </h1>
                  </header>

                  <BasicInfoForm
                    changeRequestValue={changeRequestValue}
                    disabled={!request.abilities.canEditBasicSection}
                    request={request}
                    userMpk={request.user_mpk}
                    mpks={mpks}
                    projects={projects}
                    isModuleActive={isModuleActive}
                    accountDimensions={accountDimensions}
                  />
                </section>
              )
            }}
          </AccountDimensionManager>
        )
      }}
    </BasicInfoManager>
  )
}

export { BasicRequestInfoRenderer }
export default BasicRequestInfoRenderer
