import React from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import _ from 'lodash'
import Icon from '../../components/ui/IconComponent'

export class SmartCheckbox extends React.Component<any, any> {
  constructor(props) {
    super(props)

    this.state = {
      checked: props.checked || false,
    }
  }

  componentDidUpdate(prevProps, prevState, prevContext) {
    if (prevProps.checked !== this.props.checked) {
      this.setState({ checked: this.props.checked || false })
    }
  }

  handleClick = (e) => {
    e.preventDefault()

    const { checked } = this.state
    const { onChange } = this.props

    if (this.props.disabled) {
      return
    }

    if (typeof this.props.dummyClick === 'function') {
      return onChange('dummy')
    }

    this.setState({ checked: !checked })

    if (_.isFunction(onChange)) {
      onChange(!checked)
    }
  }

  render() {
    const { className, label, checked: propsChecked, onChange, onClick, ...props } = this.props
    const { checked } = this.state
    const { disabled } = this.props

    const CheckboxClasses = classNames(className, {
      'checkbox-wrapper__input-wrapper': true,
      'checkbox-wrapper__input-wrapper--is-disabled': disabled,
    })

    return (
      <label className='checkbox-wrapper' onClick={this.handleClick}>
        <div className={CheckboxClasses}>
          <input
            type='checkbox'
            className='checkbox-wrapper__input'
            {...props}
            onChange={this.handleClick}
            checked={checked}
          />
          <Icon type='checked' className='checkbox-wrapper__icon' />
        </div>
        {label && <span className='checkbox-wrapper__label'>{label}</span>}
      </label>
    )
  }
}

SmartCheckbox.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string,
  label: PropTypes.string,
}

SmartCheckbox.defaults = {
  disabled: false,
}
