import React from 'react'
import { Panel } from '../ui/Panel'
import trans from '../../trans'
import { Crossings } from './BorderCrossings/Crossings'
import { borderCrossings } from '../../store/app/border-crossings'
import AnimateHeight from 'react-animate-height'
import Button from '../ui/ButtonComponent'
import Steps from './Steps'
import { compose } from 'redux'
import { connect } from 'react-redux'
import Meals from './Declarations/Meals/Meals'
import LumpSum from './Declarations/LumpSum/LumpSum'
import { isLoading as isMealDeductionsLoading } from '../../store/app/deductions/meal-deductions'
import { isLoading as isLumpSumsDeductionsLoading } from '../../store/app/deductions/lump-sums-deductions'
import { Loader } from '../ui/LoadingOverlay/Loader'
import classNames from 'classnames'
import NonDelegationOverlay from './NonDelegationOverlay'
import TripDidNotHavePlaceOverlay from './TripDidNotHavePlaceOverlay'

class BorderCrossings extends React.Component<any, any> {
  renderMealDeductions() {
    const { request } = this.props
    return <Meals request={request} />
  }

  renderLumpSumsDeductions() {
    const { request } = this.props
    return <LumpSum request={request} />
  }

  renderDeductions() {
    const {
      isReadOnly,
      crossings: {
        actions: { setStep, setStepReadOnly },
      },
      isDeductionsLoading,
    } = this.props

    const classes = classNames({
      'border-crossing__deductions': true,
      'read-only': isReadOnly,
    })

    return (
      <div className={classes}>
        <Steps step={2} />

        {isDeductionsLoading && <Loader />}

        {this.renderMealDeductions()}
        {this.renderLumpSumsDeductions()}

        <div className='border-crossings__pagination-wrapper'>
          <span className='border-crossings__pagination'>2 z 2</span>

          <Button
            secondary
            xs
            outline
            onClick={() => (isReadOnly ? setStepReadOnly(false) : setStep(false))}
          >
            {trans('deductions-widget.back-to-border-crossings')}
          </Button>
        </div>
      </div>
    )
  }

  renderBorderCrossings() {
    const { request, isReadOnly } = this.props
    return <Crossings request={request} isReadOnly={isReadOnly} />
  }

  render() {
    const {
      crossings: {
        selectors: { step },
      },
    } = this.props

    return (
      <NonDelegationOverlay>
        <TripDidNotHavePlaceOverlay>
          <Panel header={<h1>{trans('request.lump-sum-header')}</h1>}>
            <AnimateHeight height={'auto'}>
              {!step ? this.renderBorderCrossings() : this.renderDeductions()}
            </AnimateHeight>
          </Panel>
        </TripDidNotHavePlaceOverlay>
      </NonDelegationOverlay>
    )
  }
}

const mapStateToProps = (state) => ({
  isDeductionsLoading: isMealDeductionsLoading(state) || isLumpSumsDeductionsLoading(state),
})

const withConnect = connect(mapStateToProps)
const withBorderCrossings = borderCrossings(true, true, true, true)

BorderCrossings = compose(withConnect, withBorderCrossings)(BorderCrossings)

export default BorderCrossings
export { BorderCrossings }
