import React from 'react'
import { withRouter } from 'react-router-dom'

import { TabsContent, TabsHeader } from './partials'
import PropTypes from 'prop-types'

function RouteTabs(props) {
  const { match, location, defaultTab, items } = props
  const activeTabUrl = location.pathname.replace(match.url, '')
  const activeTab = activeTabUrl ? activeTabUrl.slice(1) : defaultTab
  const defaultTabContent = items.find((item) => item.id === defaultTab)

  return (
    <div className='tabs'>
      <TabsHeader items={items} activeTab={activeTab} match={match} />
      <TabsContent defaultTabContent={defaultTabContent} items={items} match={match} />
    </div>
  )
}

RouteTabs.propTypes = {
  defaultTab: PropTypes.string,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      path: PropTypes.string,
      title: PropTypes.string,
      enabled: PropTypes.bool,
      component: PropTypes.element,
    }),
  ),
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }),
  match: PropTypes.shape({
    url: PropTypes.string,
  }).isRequired,
}

export default withRouter(RouteTabs)
