import uuid from '../utils/uuid'
import { Location } from './Location'

class BorderCrossing {
  constructor(init) {
    const defaults = {
      id: null,
      country_id: null,
      country_code: null,
      date: null,
      target: false,
      location: null,
      draft: false,
      cid: null,
      suggested: false,
    }

    const opts = Object.assign({}, defaults, init)

    this.id = opts.id
    this.cid = opts.cid
    this.country_id = opts.country_id
    this.country_code = opts.country_code
    this.date = opts.date
    this.target = opts.target
    this.city = opts.city
    this.draft = opts.draft
    this.suggested = opts.suggested
    this.location = new Location(opts.location)
  }
}

export { BorderCrossing }
export default BorderCrossing
