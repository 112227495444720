import React from 'react'
import { Field } from 'redux-form/immutable'
import { FormField, FormFieldRadioGroup } from '../../../ui/Form'
import { trans } from '../../../../trans'
import {
  TimelineElement,
  TimeLineElementContent,
  TimeLineTargetPointHeader,
} from '../../../Timeline'
import { Col, Row } from '../../../../ui/Grid'
import Button from '../../../ui/ButtonComponent'
import Icon from '../../../ui/IconComponent'
import { TypesMenu } from '../../index'
import { isEmpty, isFunction, isUndefined } from 'lodash'
import { ifDeepDiff } from '../../../../utils/javascript'
import { TravelPointLabel } from '../../../TravelPointLabel'
import moment from 'moment'
import { keepInViewport } from '../KeepInViewport'
import { TravelPointDate } from '../../../TravelPointDate'
import { config } from '../../../../config'

const KeepingViewportHeader = keepInViewport()(TimeLineTargetPointHeader)

class TargetPoint extends React.Component<any, any> {
  shouldComponentUpdate(nextProps, nextState) {
    return ifDeepDiff(nextProps.point, this.props.point)
  }

  renderLabel() {
    const { point } = this.props
    const arrivalDate = point.getStartDate()

    if (!point.getStartLocation().city || !point.getStartDate()) {
      return trans('request.choose-destination')
    } else {
      return (
        <div>
          <strong>{trans('request.travel-point')}</strong>
          <span> - </span>
          <TravelPointLabel location={point.getStartLocation()} />
          <TravelPointDate start={arrivalDate} withArrival />
        </div>
      )
    }
  }

  renderEdit() {
    const { point, change, alreadyHasTargetPoint } = this.props

    if (point.isOpen) {
      return null
    }

    return (
      <span
        className='timeline-icon'
        onClick={() => {
          change('isOpen', true)
        }}
      >
        <Icon type='edit' lg gradient />
      </span>
    )
  }

  submitAndCreateNew = (e) => {
    const { addTargetPoint, handleSubmit } = this.props

    return handleSubmit(e).then((response) => {
      // if the response is undefined means there are no form errors
      if (isUndefined(response)) {
        addTargetPoint()
      }
    })
  }

  submit = (e) => {
    const { addFirstElement, handleSubmit } = this.props

    return handleSubmit(e).then((response) => {
      // if the response is undefined means there are no form errors
      if (isUndefined(response)) {
        addFirstElement()
      }
    })
  }

  renderContent() {
    const { onChangeType, minDate, maxDate, point, maxStartDate, alreadyHasTargetPoint } =
      this.props

    return (
      <TimeLineElementContent isOpened={point.isOpen}>
        <TypesMenu
          selected={point.type}
          onChange={() => onChangeType(point, null)}
          showChangeButton={point.draft}
          disabled={!alreadyHasTargetPoint}
        />

        <div className='tooltip-content tooltip-content--target-point'>
          <form>
            <Field name='draft' type='hidden' component={FormField} inputOnly labeltop />

            <Field name='id' type='hidden' component={FormField} inputOnly labeltop />

            <Field name='type' type='hidden' component={FormField} inputOnly labeltop />

            <Row>
              <Col className='target-point__wrapper'>
                <Row className='target-point__inputs'>
                  <Col sm={6}>
                    <Field
                      name='location'
                      type='location'
                      component={FormField}
                      label={trans('target-point.location')}
                      immutable
                      labeltop
                    />
                  </Col>
                  <Col sm={6}>
                    <Field
                      name='date'
                      type='datepicker'
                      component={FormField}
                      label={trans('target-point.arrival-date')}
                      minDate={minDate}
                      maxDate={maxDate}
                      labeltop
                      showTimeSelect
                      timeFormat='HH:mm'
                      dateFormat={config.datePickerFieldFormat}
                      timeIntervals={15}
                    />
                  </Col>
                </Row>
                <Row className='is-margin-top'>
                  <Col fill={6} sm={6}>
                    <div className={'button-group'}>
                      <Button outline pull_end xs onClick={this.submitAndCreateNew}>
                        {trans('target-point.add-next-target-point')}
                      </Button>
                      <Button primary pull_end xs onClick={this.submit}>
                        {trans('ui.save-trip-element')}
                      </Button>
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
          </form>
        </div>
      </TimeLineElementContent>
    )
  }

  render() {
    const { element, onRemove, point, request, alreadyHasTargetPoint } = this.props

    return (
      <TimelineElement className={point.className} type={point.type} activeSimilar>
        <KeepingViewportHeader icon='pin_1' gradient='true' isOpen={point.isOpen}>
          <Col xs={9} className='timeline__header--title'>
            {this.renderLabel()}
          </Col>
          <Col style={{ paddingRight: '0' }} className='is-allign-end' xs={2} />
          {request['abilities']['edit'] && (
            <Col xs={1} style={{ paddingLeft: '0' }} className='is-allign-end'>
              {this.renderEdit()}

              {alreadyHasTargetPoint && (
                <span className='timeline-icon' onClick={() => onRemove(point)}>
                  <Icon type='trash' lg gradient />
                </span>
              )}

              {!alreadyHasTargetPoint && (
                <span className='timeline-icon timeline-icon--disabled'>
                  <Icon type='trash' lg gradient />
                </span>
              )}
            </Col>
          )}
        </KeepingViewportHeader>
        {point.isOpen && this.renderContent()}
      </TimelineElement>
    )
  }
}

export { TargetPoint }
export default { TargetPoint }
