import React from 'react'
import { Field } from 'redux-form/immutable'
import { FormField } from '../../../ui/Form'
import { trans } from '../../../../trans'
import { Col, Row } from '../../../../ui/Grid'
import Button from '../../../ui/ButtonComponent'
import numeral from 'numeral'
import { Map } from 'immutable'
import { FormGroup } from '../../../../ui'
import { get } from 'lodash'
import { ifDeepDiff } from '../../../../utils/javascript'
import { Route } from '../../../../models/Route'
import { LatLng } from '../../../../models/LatLng'
import GoogleDirections from '../../../GoogleDirections'
import Car from './Car'

class PassengerCar extends Car {
  constructor(props) {
    super(props)
  }

  shouldComponentUpdate(nextProps, nextState) {
    return (
      ifDeepDiff(nextProps.car, this.props.car) ||
      ifDeepDiff(nextProps.formErrors, this.props.formErrors) ||
      ifDeepDiff(nextState, this.state)
    )
  }

  renderMap() {
    const { car } = this.props

    const departure_location = car.getStartLocation()
    const destination_location = car.getEndLocation()

    const route = new Route(
      new LatLng(departure_location.lat, departure_location.long),
      new LatLng(destination_location.lat, destination_location.long),
    )

    return <GoogleDirections routes={[route]} minHeight={'450px'} />
  }

  getExchangeSummaryPartials() {
    const { car } = this.props

    const other_costs_amount = car.other_costs_amount ? car.other_costs_amount : 0
    const other_costs_currency = car.other_costs_currency
    const fuel_cost = car.fuel_cost ? car.fuel_cost : 0
    const fuel_cost_currency = car.fuel_cost_currency

    let partials = Map()
    partials = partials.set(other_costs_currency, numeral(other_costs_amount))

    if (partials.has(fuel_cost_currency)) {
      partials = partials.set(fuel_cost_currency, partials.get(fuel_cost_currency).add(fuel_cost))
    } else {
      partials = partials.set(fuel_cost_currency, numeral(fuel_cost))
    }

    return partials
  }

  renderForm() {
    const { handleSubmit, car, minDate, maxDate, formErrors, maxStartDate } = this.props

    const destination_error = get(formErrors, 'destination_location.formatted_address', null)
    const departure_error = get(formErrors, 'departure_location.formatted_address', null)

    return (
      <form onSubmit={handleSubmit}>
        <Field name='draft' type='hidden' component={FormField} inputOnly labeltop />

        <Field name='id' type='hidden' component={FormField} inputOnly labeltop />

        <Field name='type' type='hidden' component={FormField} inputOnly labeltop />

        <Row>
          <Col style={{ width: '500px' }}>
            <Row>
              <Col sm={12}>{this.renderCarTypeField()}</Col>
            </Row>

            <Row>
              <Col sm={12}>
                <Field
                  name='round_trip'
                  type='toggle'
                  component={FormField}
                  label={trans('request-car-element.round-trip')}
                  inverse
                />
              </Col>
            </Row>

            <Row>
              <Col sm={6}>
                <FormGroup labeltop label={trans('request-car-element.departure-from')}>
                  <div className='form-group__input-wrapper'>
                    <Field
                      name='departure_location'
                      type='location'
                      component={FormField}
                      inputOnly
                      immutable
                      autoFocus
                      labeltop
                    />

                    {destination_error && (
                      <div className='form-group__error'>
                        <p>{destination_error}</p>
                      </div>
                    )}
                  </div>
                </FormGroup>
              </Col>
              <Col sm={6}>
                <FormGroup labeltop label={trans('request-car-element.arrival-to')}>
                  <div className='form-group__input-wrapper'>
                    <Field
                      name='destination_location'
                      type='location'
                      component={FormField}
                      inputOnly
                      immutable
                      labeltop
                    />
                    {departure_error && (
                      <div className='form-group__error'>
                        <p>{departure_error}</p>
                      </div>
                    )}
                  </div>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col sm={6}>
                <Field
                  name='departure_at'
                  type='datepicker'
                  component={FormField}
                  label={trans('request-car-element.departure-date')}
                  minDate={minDate}
                  maxDate={maxStartDate}
                  labeltop
                />
              </Col>
              <Col sm={6}>
                <div style={{ display: car.round_trip ? 'block' : 'none' }}>
                  <Field
                    name='return_at'
                    type='datepicker'
                    component={FormField}
                    label={trans('request-plane-element.return-date')}
                    minDate={car.departure_at}
                    maxDate={maxDate}
                    labeltop
                  />
                </div>
              </Col>
            </Row>
          </Col>
          <Col fill>{this.renderMap()}</Col>
        </Row>

        <Row className='is-margin-top'>
          <Col sm={12}>
            <Button primary pull_end xs type='submit'>
              {trans('ui.save-trip-element')}
            </Button>
          </Col>
        </Row>
      </form>
    )
  }
}

export { PassengerCar }
export default { PassengerCar }
