import React from 'react'
import { reset, set, change } from './reducers'

export const MODULE_MOUNT_POINT = 'non-delegation'

export const RESET = MODULE_MOUNT_POINT + '::reset'
export const SET = MODULE_MOUNT_POINT + '::set'
export const CHANGE = MODULE_MOUNT_POINT + '::change'

const actions = {
  [RESET]: reset,
  [SET]: set,
  [CHANGE]: change,
}

export const getInitialState = () => {
  return {
    delegation: false,
  }
}

export const reducer = (state = {}, action) => {
  if (actions.hasOwnProperty(action.type)) {
    return actions[action.type](state, action.payload)
  }
  return state
}
