import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import {
  reset,
  getAcceptors,
  getSettlementAcceptors,
  addAcceptor,
  addSettlementAcceptor,
  removeAcceptor,
  removeSettlementAcceptor,
} from '../../store/app/request-acceptors'
import { differenceBy } from 'lodash'

class RequestAcceptorsManagerBase extends React.Component<any, any> {
  componentDidMount() {
    const { reset } = this.props
    reset()
  }

  handleAddAcceptor(acceptor) {
    const { request, addAcceptor, fetchRequest } = this.props

    addAcceptor(request, acceptor).then(() => fetchRequest(request['slug']))
  }

  handleAddSettlementAcceptor(acceptor) {
    const { request, addSettlementAcceptor, fetchRequest } = this.props

    addSettlementAcceptor(request, acceptor).then(() => fetchRequest(request['slug']))
  }

  handleRemoveAcceptor(acceptor) {
    const { request, removeAcceptor, fetchRequest } = this.props

    return removeAcceptor(request, acceptor).then(() => fetchRequest(request['slug']))
  }

  handleRemoveSettlementAcceptor(acceptor) {
    const { request, removeSettlementAcceptor, fetchRequest } = this.props

    return removeSettlementAcceptor(request, acceptor).then(() => fetchRequest(request['slug']))
  }

  render() {
    const { children, request, acceptors, settlementAcceptors } = this.props

    const renderProps = {
      request,
      acceptors: differenceBy(acceptors, request.acceptors, 'id'),
      settlementAcceptors: differenceBy(settlementAcceptors, request.settlementAcceptors, 'id'),
      addAcceptor: this.handleAddAcceptor.bind(this),
      addSettlementAcceptor: this.handleAddSettlementAcceptor.bind(this),
      removeAcceptor: this.handleRemoveAcceptor.bind(this),
      removeSettlementAcceptor: this.handleRemoveSettlementAcceptor.bind(this),
    }

    return children(renderProps)
  }
}

RequestAcceptorsManagerBase.propTypes = {
  children: PropTypes.func.isRequired,
  request: PropTypes.object.isRequired,
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      reset,
      addAcceptor,
      addSettlementAcceptor,
      removeAcceptor,
      removeSettlementAcceptor,
    },
    dispatch,
  )
}

const mapStateToProps = (state) => ({
  acceptors: getAcceptors(state),
  settlementAcceptors: getSettlementAcceptors(state),
})

const RequestAcceptorsManager = connect(
  mapStateToProps,
  mapDispatchToProps,
)(RequestAcceptorsManagerBase)

export { RequestAcceptorsManager }
export default RequestAcceptorsManager
