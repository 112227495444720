import { MODULE_MOUNT_POINT } from './index'
import { get } from 'lodash'

//selectors
export const getState = (state) => {
  return state.get(MODULE_MOUNT_POINT)
}

export const getUsers = (state) => {
  return get(getState(state), 'users', [])
}

export const isDelegation = (state) => {
  return get(getState(state), 'delegation', false)
}
