import PropTypes from 'prop-types'
import React from 'react'
import { TableFlatArrayRenderer } from '../ui/AccordionTable'

class RequestMileageSummary extends React.Component<any, any> {
  render() {
    const { tableData } = this.props

    return (
      <div className={'no-uppercase'}>
        <TableFlatArrayRenderer data={tableData} styleForTable={this.props.styleForTable} />
      </div>
    )
  }
}

RequestMileageSummary.propTypes = {
  tableData: PropTypes.array.isRequired,
}

export default { RequestMileageSummary }
export { RequestMileageSummary }
