import { TYPE_EXPENSE } from '../../../components/BorderCrossings/constants'
import moment from 'moment'
import { get, keys, mapValues, times, zipObject } from 'lodash'

export const buildMapOfCheckedExpense = (expenses) => {
  let checkedValues = {}
  //generate map of checked expense values
  for (let expense of expenses) {
    if (expense.type === TYPE_EXPENSE) {
      for (let valueKey in expense.values) {
        if (expense.values.hasOwnProperty(valueKey) && expense.values[valueKey]) {
          checkedValues[`${expense.period}.${valueKey}`] = expense.id
        }
      }
    }
  }
  return checkedValues
}

export const buildListOfCheckedAccommodations = (expenses) => {
  let checkedValues = []
  //generate map of checked expense values
  for (let expense of expenses) {
    if (expense.type === TYPE_EXPENSE) {
      for (let valueKey in expense.values) {
        if (
          expense.values.hasOwnProperty(valueKey) &&
          expense.values[valueKey] &&
          valueKey == 'accommodation'
        ) {
          checkedValues.push(expense.id)
        }
      }
    }
  }
  return checkedValues
}

export const setDisabledExpensesInPeriod = (expenses) => {
  const checkedValues = buildMapOfCheckedExpense(expenses)
  const firstExpense = expenses.find((expense) => expense.type === TYPE_EXPENSE)

  if (firstExpense === -1) {
    return expenses
  }

  let sumOfCheckedAccommodations = buildListOfCheckedAccommodations(expenses).length

  const expenseValueKeys = keys(firstExpense.values)
  const defaultDisabledValues = zipObject(
    expenseValueKeys,
    times(expenseValueKeys.length, () => false),
  )

  let tripStart = expenses[0]
  let tripEnd = expenses[expenses.length - 1]

  let dateTripStart = moment(tripStart.date + ' ' + tripStart.localTime, 'YYYY-MM-DD HH::mm:ss')
  let dateTripEnd = moment(tripEnd.date + ' ' + tripEnd.localTime, 'YYYY-MM-DD HH::mm:ss')

  let blockAllAccomodations =
    moment.duration(dateTripEnd.diff(dateTripStart)).days() <= sumOfCheckedAccommodations

  return expenses.map((expense) => {
    expense.disabled = defaultDisabledValues
    expense.disabled = mapValues(expense.disabled, (value, key) => {
      const periodExpenseValueChecked = get(checkedValues, `${expense.period}.${key}`, null)

      let disabled =
        (periodExpenseValueChecked !== null || (blockAllAccomodations && key == 'accommodation')) &&
        periodExpenseValueChecked !== expense.id

      if (
        expense.tripDay === 0 &&
        expense.userCanObtainAccommodation === true &&
        expense.values.accommodation === true
      ) {
        disabled = false
      }

      return disabled
    })

    return expense
  })
}
