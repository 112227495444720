import React, { Component, Fragment } from 'react'

import { FormField } from '../../ui/Form'
import { trans } from '../../../trans'
import { Field } from 'redux-form/immutable'
import { connect as flightConnect } from '../../../containers/FlightsBooking'
import { AirportsAutocompleteField } from '../../ui/Form/AirportsAutocompleteField'
import { Row } from '../../../ui/Grid'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { getRequestTravelers } from '../../../store/app/request-traveler'

class QueryFieldsComponent extends Component<any, any> {
  render() {
    const {
      minDate,
      maxDate,
      maxStartDate,
      travelers,
      context: { element },
    } = this.props

    return (
      <Fragment>
        <Row>
          <div className='plane-trip__dashboard-row'>
            <Field
              name='round_trip'
              type='toggle'
              component={FormField}
              label={trans('request-plane-element.round-trip')}
              inverse
            />
          </div>

          <div className='plane-trip__filter-block'>
            <div className='plane-trip__filter-wrapper'>
              <div className='plane-trip__filter-inner-wrapper'>
                <div className='plane-trip__filter plane-trip__filter-large'>
                  <Field
                    name='flight_from'
                    component={AirportsAutocompleteField}
                    label={trans('request-plane-element.flight-from')}
                    immutable
                    labeltop
                    autoFocus
                    smart
                  />
                </div>

                <div className='plane-trip__filter plane-trip__filter-large'>
                  <Field
                    name='flight_to'
                    component={AirportsAutocompleteField}
                    label={trans('request-plane-element.flight-to')}
                    immutable
                    labeltop
                    smart
                  />
                </div>

                <div className='plane-trip__filter plane-trip__filter-large'>
                  <Field
                    name='arrival_at'
                    type='datepicker'
                    component={FormField}
                    label={trans('request-plane-element.arrival-at')}
                    minDate={minDate}
                    maxDate={maxStartDate}
                    labeltop
                  />
                </div>

                <div
                  className='plane-trip__filter plane-trip__filter-large'
                  style={{ display: element.round_trip ? 'block' : 'none' }}
                >
                  <Field
                    name='return_at'
                    type='datepicker'
                    component={FormField}
                    label={trans('request-plane-element.return-date')}
                    minDate={element.arrival_at}
                    maxDate={maxDate}
                    labeltop
                  />
                </div>

                <div className='plane-trip__filter'>
                  <div className='form-group form-group--label-top'>
                    <span className='form-group__label form_group__label--empty' />
                    <div className='form-group__input-wrapper form-field__checkbox'>
                      <Field
                        name='direct_only'
                        type='checkbox'
                        component={FormField}
                        label={trans('request-plane-element.direct-only')}
                        immutable
                        inputOnly
                      />
                    </div>
                  </div>
                </div>

                <div className='plane-trip__filter'>
                  <div className='form-group form-group--label-top'>
                    <span className='form-group__label form_group__label--empty' />
                    <div className='form-group__input-wrapper form-field__checkbox'>
                      <Field
                        name='restrict_to_fba'
                        type='checkbox'
                        component={FormField}
                        label={trans('request-plane-element.restrict-to-fba')}
                        immutable
                        inputOnly
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Row>

        <Row>
          <div className='plane-trip__dashboard-field'>
            <Field
              name='flight_class'
              type='select'
              component={FormField}
              label={trans('request-plane-element.class')}
              options={[
                {
                  value: 'class_economy',
                  label: trans('request-plane-element.economy-class'),
                },
                {
                  value: 'class_economy_premium',
                  label: trans('request-plane-element.economy-premium-class'),
                },
                {
                  value: 'class_business',
                  label: trans('request-plane-element.class-business-class'),
                },
                {
                  value: 'class_first',
                  label: trans('request-plane-element.class-first-class'),
                },
              ]}
              labeltop
            />
          </div>
        </Row>

        {travelers.length > 1 && (
          <Row>
            <div className='plane-trip__dashboard-field'>
              <Field
                name='request_travelers'
                type='traveler-select'
                options={travelers}
                component={FormField}
                placeholder={trans('trip-request.trip-traveler-passenger-label')}
                label={trans('flights-booking.request-traveler-field-label')}
                immutable
                labeltop
              />
            </div>
          </Row>
        )}
      </Fragment>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    travelers: getRequestTravelers(state),
  }
}

const withConnect = connect(mapStateToProps)

const QueryFields = compose(withConnect, flightConnect)(QueryFieldsComponent)

export default QueryFields
export { QueryFields }
