import { createSelector, createSlice } from '@reduxjs/toolkit'
import APIClient from '../../../services/APIClient'
import { getExpenseGroupById } from '../expense-group'
import { getAccountingAccountById } from '../accounting-account'
import { getVatNumberById } from '../vat'

export const EXPENSE_TYPE_MOUNT_POINT = 'expense-type'

/**
 * In @reduxjs/toolkit we can mutate state in reducers,
 * because behind the scene it uses ImmerJS for applying them as non-mutate
 *
 * https://github.com/immerjs/immer
 */
const slice = createSlice({
  name: EXPENSE_TYPE_MOUNT_POINT,
  initialState: {
    items: [],
    error: null,
    isLoading: false,
  },
  reducers: {
    setExpenseTypes(state, action) {
      const { data } = action.payload

      state.items = data
    },
    setExpenseType(state, action) {
      const { data } = action.payload
      const index = state.items.findIndex((item) => item.slug === data.slug)

      if (index === -1) {
        state.items.unshift(data)
      } else {
        state.items[index] = data
      }
    },
    startLoading(state) {
      state.isLoading = true
      state.error = null
    },
    setLoadingSuccess(state) {
      state.isLoading = false
      state.error = null
    },
    setLoadingFailed(state, action) {
      state.isLoading = false
      state.error = action.payload
    },
  },
})

export const {
  setExpenseTypes,
  setExpenseType,
  startLoading,
  setLoadingSuccess,
  setLoadingFailed,
} = slice.actions

export default slice.reducer

// selectors
const getState = (state) => state.get(EXPENSE_TYPE_MOUNT_POINT)
export const getExpenseTypes = (state) => getState(state).items
export const isLoading = (state) => getState(state).isLoading
export const getExpenseTypesWithRelations = createSelector(
  [(state) => state, (state) => getExpenseTypes(state)],
  (state, types) => types.map((type) => getExpenseTypeWithRelations(state, type)),
)

export const getExpenseTypeWithRelations = (state, item) => {
  return {
    ...item,
    documentElementGroup: getExpenseGroupById(state, item.document_element_group_id),
    accountingAccount: getAccountingAccountById(state, item.accounting_account_id),
    vatNumber: getVatNumberById(state, item.vat_number_id),
  }
}

// action thunks
export const fetchExpenseTypes = () => async (dispatch) => {
  dispatch(startLoading())

  try {
    const { data } = await APIClient.getExpenseTypes()

    dispatch(setExpenseTypes({ data }))
    dispatch(setLoadingSuccess())
  } catch (err) {
    dispatch(setLoadingFailed(err))
  }
}

export const updateExpenseType = (item) => async (dispatch, getState) => {
  const { data } = await APIClient.updateExpenseType(item.slug, item)

  await dispatch(fetchExpenseTypes())

  return getExpenseTypeWithRelations(getState(), data)
}

export const changeExpenseTypeOrder = (event) => async (dispatch, state) => {
  if (!event) {
    return
  }

  const {
    overIndex,
    node: {
      data: { id },
    },
  } = event
  const cloned = [...getExpenseTypes(state())]
  const items = [...getExpenseTypes(state())]
  const sourceIndex = cloned.findIndex((item) => item.id === id)

  if (sourceIndex === overIndex) {
    return
  }

  const item = items.splice(sourceIndex, 1)

  items.splice(overIndex, 0, item[0])

  // temporary set order to store
  dispatch(setExpenseTypes({ data: items }))
  // dispatch(startLoading());

  try {
    const { data } = await APIClient.changeOrderOfExpenseTypes({
      types: items.map((item) => item.slug),
    })

    dispatch(setExpenseTypes({ data }))
    // dispatch(setLoadingSuccess());
  } catch (err) {
    dispatch(setLoadingFailed(err))
    dispatch(setExpenseTypes({ data: cloned }))
  }
}
