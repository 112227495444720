import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { fetchGradesIfNeeded, getGrades, isLoading, resetGrades } from './slice'

const grades =
  (resetOnMount = false, fetchOnMount = false) =>
  (Component) => {
    class Entities extends React.Component<any, any> {
      componentDidMount() {
        const { grades } = this.props

        if (resetOnMount) {
          grades.actions.reset()
        }

        if (fetchOnMount) {
          grades.actions.fetch()
        }
      }

      render() {
        return <Component<any, any> {...this.props} />
      }
    }

    const mapStateToProps = (state) => ({
      grades: getGrades(state),
      isLoading: isLoading(state),
    })

    const mapDispatchToProps = (dispatch) => {
      return bindActionCreators(
        {
          reset: resetGrades,
          fetch: fetchGradesIfNeeded,
        },
        dispatch,
      )
    }

    const mergedProps = (selectors, actions, own) => {
      return {
        ...own,
        grades: {
          selectors,
          actions,
          ...own.grades,
        },
      }
    }

    return connect(mapStateToProps, mapDispatchToProps, mergedProps)(Entities)
  }

export { grades }
export default grades
