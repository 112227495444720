// initial state
import APIClient from '../../../services/APIClient'
import isNil from 'lodash/isNil'
import { isEmpty } from 'lodash'
import { STATUS_ACCOUNTING, STATUS_TRANSFER_ERROR } from '../../../constants/request'
import { LatLng } from '../../../models/LatLng'
import { Route } from '../../../models/Route'
import { setTargetPointsByRequest } from '../target-points'

const getInitialState = () => {
  return {
    request: {
      isLoaded: false,
      isLoading: false,
      data: {},
    },
    addFormVisible: false,
    deletedElementsInProgress: [],
  }
}

// constants
export const RESET = 'request-mileage-allowance::reset'
export const START_LOADING = 'request-mileage-allowance::start-loading-request'
export const START_SAVING = 'request-mileage-allowance::start-saving'
export const SET_REQUEST = 'request-mileage-allowance::set-request'
export const SET_MILEAGE_ALLOWANCE_ELEMENT =
  'request-mileage-allowance::set-mileage-allowance-element'
export const SET_SUBSUMMARY = 'request-mileage-allowance::set-subsummary'
export const UPDATE_ADD_FORM_VISIBLE = 'request-mileage-allowance::update-add-form-visible'
export const ADD_ACCOUNTING_TRAVEL_EXPENSE =
  'request-mileage-allowance::add-accounting-travel-expense'
export const UPDATE_ACCOUNTING_TRAVEL_EXPENSE =
  'request-mileage-allowance::update-accounting-travel-expense'
export const REMOVE_ACCOUNTING_TRAVEL_EXPENSE =
  'request-mileage-allowance::remove-accounting-travel-expense'
export const ADD_ACCOUNTING_MILEAGE_ALLOWANCE =
  'request-mileage-allowance::add-accounting-mileage-allowance'
export const UPDATE_ACCOUNTING_MILEAGE_ALLOWANCE =
  'request-mileage-allowance::update-accounting-mileage-allowance'
export const REMOVE_ACCOUNTING_MILEAGE_ALLOWANCE =
  'request-mileage-allowance::remove-accounting-mileage-allowance'
export const UPDATE_DELETED_ELEMENT_STATUS =
  'request-mileage-allowance::update-deleted-element-status'
export const UPDATE_BORDER_CROSSINGS_STATE =
  'request-mileage-allowance::update-border-crossings-state'

export const MOUNT_POINT = 'request-mileage-allowance'

// reducer
export const reducer = (state = getInitialState(), action) => {
  switch (action.type) {
    case RESET:
      return getInitialState()
    case START_LOADING: {
      const key = action.payload

      return {
        ...state,
        [key]: {
          ...state[key],
          isLoading: true,
        },
      }
    }

    case START_SAVING: {
      const { key, field } = action.payload

      const mileageAllowances = state.request.data.mileageAllowances.map((item, index) => {
        if (index !== key) {
          return item
        }

        return {
          ...item,
          [`${field}IsSaving`]: true,
        }
      })

      return {
        ...state,
        request: {
          ...state.request,
          data: {
            ...state.request.data,
            mileageAllowances,
          },
        },
      }
    }

    case SET_REQUEST:
      return {
        ...state,
        request: {
          ...state.request,
          data: action.payload,
          isLoading: false,
          isLoaded: true,
        },
      }

    case UPDATE_BORDER_CROSSINGS_STATE:
      return {
        ...state,
        request: {
          ...state.request,
          data: {
            ...state.request.data,
            border_crossing_state: action.payload.borderCrossingState,
          },
        },
      }
    case SET_MILEAGE_ALLOWANCE_ELEMENT:
      const { key, field, data } = action.payload

      const mileageAllowances = state.request.data.mileageAllowances.map((item, index) => {
        if (index !== key) {
          return item
        }

        return {
          ...data,
          ...item,
          [`${field}IsSaving`]: false,
        }
      })

      return {
        ...state,
        request: {
          ...state.request,
          data: {
            ...state.request.data,
            mileageAllowances,
          },
        },
      }

    case SET_SUBSUMMARY:
      return {
        ...state,
        request: {
          ...state.request,
          data: {
            ...state.request.data,
            subsummary: action.payload,
          },
        },
      }
    case UPDATE_ADD_FORM_VISIBLE:
      return {
        ...state,
        addFormVisible: action.payload,
      }
    case ADD_ACCOUNTING_TRAVEL_EXPENSE: {
      const newElement = action.payload

      return {
        ...state,
        request: {
          ...state.request,
          data: {
            ...state.request.data,
            accountingTravelExpenses: [...state.request.data.accountingTravelExpenses, newElement],
          },
        },
      }
    }
    case UPDATE_ACCOUNTING_TRAVEL_EXPENSE:
      return {
        ...state,
        request: {
          ...state.request,
          data: {
            ...state.request.data,
            accountingTravelExpenses: state.request.data.accountingTravelExpenses.map((element) => {
              if (element['id'] === action.payload['id']) {
                return action.payload
              }
              return element
            }),
          },
        },
      }
    case REMOVE_ACCOUNTING_TRAVEL_EXPENSE:
      return {
        ...state,
        request: {
          ...state.request,
          data: {
            ...state.request.data,
            accountingTravelExpenses: state.request.data.accountingTravelExpenses.filter(
              (element) => {
                return element['id'] !== action.payload['id']
              },
            ),
          },
        },
      }
    case ADD_ACCOUNTING_MILEAGE_ALLOWANCE: {
      const newElement = action.payload

      return {
        ...state,
        request: {
          ...state.request,
          data: {
            ...state.request.data,
            accountingMileageAllowances: [
              ...state.request.data.accountingMileageAllowances,
              newElement,
            ],
          },
        },
      }
    }
    case UPDATE_ACCOUNTING_MILEAGE_ALLOWANCE:
      return {
        ...state,
        request: {
          ...state.request,
          data: {
            ...state.request.data,
            accountingMileageAllowances: state.request.data.accountingMileageAllowances.map(
              (element) => {
                if (element['id'] === action.payload['id']) {
                  return action.payload
                }
                return element
              },
            ),
          },
        },
      }
    case REMOVE_ACCOUNTING_MILEAGE_ALLOWANCE:
      return {
        ...state,
        request: {
          ...state.request,
          data: {
            ...state.request.data,
            accountingMileageAllowances: state.request.data.accountingMileageAllowances.filter(
              (element) => {
                return element['id'] !== action.payload
              },
            ),
          },
        },
      }
    case UPDATE_DELETED_ELEMENT_STATUS: {
      let { deletedElementsInProgress } = state

      if (action.payload.action === 'add') {
        deletedElementsInProgress.push(action.payload.element)
      } else if (action.payload.action === 'remove') {
        deletedElementsInProgress = deletedElementsInProgress.filter((id) => {
          return id !== action.payload.element
        })
      }

      return {
        ...state,
        deletedElementsInProgress,
      }
    }
    default:
      return state
  }
}

// actions
export const reset = () => (dispatch) => {
  dispatch({
    type: RESET,
  })
}

export const startLoading = (key) => (dispatch) => {
  dispatch({
    type: START_LOADING,
    payload: key,
  })
}

export const startSaving = (key, field) => (dispatch) => {
  dispatch({
    type: START_SAVING,
    payload: { key, field },
  })
}

export const setRequest = (data) => (dispatch) => {
  dispatch({
    type: SET_REQUEST,
    payload: data,
  })

  dispatch(setTargetPointsByRequest(data))
}

export const updateBorderCrossingsState = (borderCrossingState) => (dispatch) => {
  dispatch({
    type: UPDATE_BORDER_CROSSINGS_STATE,
    payload: { borderCrossingState },
  })
}

export const setRequestMileageAllowanceElement = (key, field, data) => (dispatch) => {
  dispatch({
    type: SET_MILEAGE_ALLOWANCE_ELEMENT,
    payload: { key, field, data },
  })
}

export const setSubsummary = (data) => (dispatch) => {
  dispatch({
    type: SET_SUBSUMMARY,
    payload: data,
  })
}

export const addAccountingTravelExpense = (accountingTravelExpense) => (dispatch) => {
  dispatch({
    type: ADD_ACCOUNTING_TRAVEL_EXPENSE,
    payload: accountingTravelExpense,
  })
}

export const updateAccountingTravelExpense = (data) => (dispatch) => {
  dispatch({
    type: UPDATE_ACCOUNTING_TRAVEL_EXPENSE,
    payload: data,
  })
}

export const addAccountingMileageAllowance = (data) => (dispatch) => {
  dispatch({
    type: ADD_ACCOUNTING_MILEAGE_ALLOWANCE,
    payload: data,
  })
}

export const updateAccountingMileageAllowance = (data) => (dispatch) => {
  dispatch({
    type: UPDATE_ACCOUNTING_MILEAGE_ALLOWANCE,
    payload: data,
  })
}

export const removeAccountingMileageAllowance = (request, mileage_id) => (dispatch) => {
  dispatch(updateDeletedElementsInProgress('add', `mileage_${mileage_id}`))
  APIClient.deleteMileageAllowance(request, mileage_id).then(() => {
    dispatch(updateDeletedElementsInProgress('remove', `mileage_${mileage_id}`))
    dispatch({
      type: REMOVE_ACCOUNTING_MILEAGE_ALLOWANCE,
      payload: mileage_id,
    })
  })
}

export const createMileageAllowance = (slug) => (dispatch) => {
  return new Promise((resolve, reject) => {
    APIClient.createMileageAllowance(slug)
      .then((response) => {
        dispatch(addAccountingMileageAllowance(response.data))
        resolve()
      })
      .catch((response) => {
        reject(response['alerts'])
      })
  })
}

export const saveAccountingMileageAllowance = (request, mileage_id, data) => (dispatch) => {
  return new Promise((resolve, reject) => {
    APIClient.updateMileageAllowance(request, mileage_id, data)
      .then((response) => {
        dispatch(updateAccountingMileageAllowance(response.data))
        resolve()
      })
      .catch((response) => {
        reject(response['alerts'])
      })
  })
}

export const fetchRequest = (slug) => (dispatch) => {
  dispatch(startLoading('request'))

  return new Promise((resolve) => {
    APIClient.getRequestForMileageAllowance(slug).then((response) => {
      dispatch(setRequest(response.data))
      resolve(response.data)
    })
  })
}

export const updateRequestMileageAllowanceField =
  (requestSlug, id, key, field, value) => (dispatch) => {
    // dispatch(startSaving(key, field));

    return APIClient.updateRequestMileageAllowance(requestSlug, id, { [field]: value }).then(
      (response) => {
        dispatch(setRequestMileageAllowanceElement(key, field, response.data))
      },
    )
  }

export const updateRequestMileageAllowance = (requestSlug, id, key, values) => (dispatch) => {
  return new Promise((resolve, reject) => {
    APIClient.updateRequestMileageAllowance(requestSlug, id, values)
      .then((response) => {
        dispatch(setRequestMileageAllowanceElement(key, '', response.data))
        resolve()
      })
      .catch((response) => {
        reject(response['alerts'])
      })
  })
}

export const saveAccountingTravelExpense = (request, travelExpense, data) => (dispatch) => {
  return new Promise((resolve, reject) => {
    if (travelExpense['id']) {
      APIClient.editAccountingTravelExpense(request, travelExpense, data)
        .then((response) => {
          dispatch(updateAccountingTravelExpense(response.data))
          resolve(response.data)
        })
        .catch((response) => {
          reject(response['alerts'])
        })
    } else {
      APIClient.addAccountingTravelExpense(request, {
        amount: 0,
        cost_of_earning: false,
        ...data,
      })
        .then((response) => {
          dispatch(addAccountingTravelExpense(response.data))
          resolve(response.data)
        })
        .catch((response) => {
          reject(response['alerts'])
        })
    }
  })
}

export const createAccountingTravelExpense = (request) => (dispatch) => {
  return new Promise((resolve, reject) => {
    APIClient.addAccountingTravelExpense(request, {
      amount: 0,
      cost_of_earning: false,
    })
      .then((response) => {
        dispatch(addAccountingTravelExpense(response.data))
      })
      .then(() => resolve())
      .catch((response) => {
        reject(response['alerts'])
      })
  })
}

export const updateDeletedElementsInProgress = (action, element) => (dispatch) => {
  dispatch({
    type: UPDATE_DELETED_ELEMENT_STATUS,
    payload: {
      element,
      action,
    },
  })
}

export const removeAccountingTravelExpense = (request, travelExpense) => (dispatch) => {
  dispatch(updateDeletedElementsInProgress('add', `travel_${travelExpense.id}`))
  APIClient.removeAccountingTravelExpense(request, travelExpense).then(() => {
    dispatch(updateDeletedElementsInProgress('remove', `travel_${travelExpense.id}`))
    dispatch({
      type: REMOVE_ACCOUNTING_TRAVEL_EXPENSE,
      payload: travelExpense,
    })
  })
}

export const setRequestAsCompleted = (request) => () => {
  let field = 'lump_sum_settled'
  if (request.status === STATUS_ACCOUNTING || request.status === STATUS_TRANSFER_ERROR) {
    field = 'lump_sum_accounted'
  }

  return APIClient.updateRequest(request.slug, {
    [field]: true,
  })
}

// selectors
export const getState = (state) => {
  return state.get(MOUNT_POINT)
}

export const getRequestData = (state) => {
  return getState(state).request
}

export const getRequest = (state) => {
  return getRequestData(state).data
}

export const getMileageAllowances = (state) => {
  if (!isNil(getRequest(state).mileageAllowances)) {
    return getRequest(state).mileageAllowances
  }
  return []
}

export const getForms = (state) => {
  return state
    .get('form')
    .filter((item, key) => key.indexOf('request-mileage-allowance-element-form') !== -1)
}

export const getRoutes = (state) => {
  const forms = getForms(state).toJS()

  if (isEmpty(forms)) {
    return []
  }

  return Object.keys(forms).map((key) => {
    const item = forms[key].values

    const origin = new LatLng(item.departure_from.lat, item.departure_from.long)
    const destination = new LatLng(item.arrival_to.lat, item.arrival_to.long)

    return new Route(origin, destination)
  })
}

export const updateAddFormVisible = (visible) => (dispatch) => {
  dispatch({
    type: UPDATE_ADD_FORM_VISIBLE,
    payload: visible,
  })
}

export const getSummary = (state) => {
  return getRequest(state).basicSummary
}

export const isAddFormVisible = (state) => {
  return getState(state).addFormVisible
}

export const isTravelExpenseDeleteInProgress = (state, element) => {
  const statuses = getState(state).deletedElementsInProgress
  return statuses.indexOf(`travel_${element['id']}`) !== -1
}

export const isMileageAllowanceDeleteInProgress = (state, element) => {
  const statuses = getState(state).deletedElementsInProgress
  return statuses.indexOf(`mileage_${element['id']}`) !== -1
}
