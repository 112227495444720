import React from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import {
  fetchEditableVatNumbers,
  fetchVatNumbers,
  getPage,
  getPaginatedVatNumbers,
  getVatNumbersTotalCount,
  isLoading as isVatNumbersLoading,
  setPage,
} from '../../../../store/app/vat'
import { Cell, Row } from '../../../ui/AccordionTable'
import trans from '../../../../trans'
import VatListItem from './VatListItem'
import VatListFormItem from './VatListFormItem'
import {
  fetchAccountingAccounts,
  isLoading as isAccountingAccountsLoading,
} from '../../../../store/app/accounting-account'
import { CompanyBaseList } from '../../CompanyBaseList'

class VatList extends CompanyBaseList {
  constructor(props) {
    super(props)

    this.selectCompany = this.selectCompany.bind(this)
  }

  componentDidMount() {
    const { fetchEditableVatNumbers, fetchAccountingAccounts } = this.props

    fetchEditableVatNumbers()
    fetchAccountingAccounts()
  }

  getIdentifierKey() {
    return 'slug'
  }

  selectCompany(value) {
    this.setState({ company: value })
  }

  renderTableHeader() {
    return (
      <Row>
        <Cell fixedWidth={150}>{trans('accounting.vat-number-code')}</Cell>
        <Cell fixedWidth='auto'>{trans('accounting.vat-number-name')}</Cell>
        <Cell fixedWidth={150} alignRight>
          {trans('accounting.vat-number-value')}
        </Cell>
        <Cell fixedWidth={150} alignRight>
          {trans('accounting.vat-number-deductibility')}
        </Cell>
        <Cell fixedWidth={150}>{trans('document-element.accounting-accounts')}</Cell>
        <Cell fixedWidth={120}>{trans('accounting.is_active')}</Cell>
        <Cell fixedWidth={180} alignRight />
      </Row>
    )
  }

  getFormComponent() {
    return VatListFormItem
  }

  getListItemComponent() {
    return VatListItem
  }

  renderNewForm() {
    if (!this.state.adding) {
      return
    }

    const item = {
      accountingAccount: {},
      reverse_accounting_account: {},
      is_active: true,
    }

    return this.renderForm(item)
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      setPage,
      fetchEditableVatNumbers,
      fetchAccountingAccounts,
    },
    dispatch,
  )
}

const mapStateToProps = (state) => {
  return {
    page: getPage(state),
    items: getPaginatedVatNumbers(state),
    total: getVatNumbersTotalCount(state),
    isLoading: isVatNumbersLoading(state) || isAccountingAccountsLoading(state),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(VatList)
