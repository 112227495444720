import React from 'react'
import trans from '../../trans'
import { connect as withHierarchy } from '../../containers/CompanyHierarchy'
import Employee from './Employee'
import { get } from 'lodash'
import { Link } from 'react-router-dom'
import { getRouteByName } from '../../routes'
import { compose } from 'redux'

export class MeInCompanyComponent extends React.Component<any, any> {
  render() {
    let {
      hierarchy: {
        selectors: { me, superior, isInitialized },
      },
    } = this.props
    let { userProfile } = this.props
    if (!isInitialized) {
      return null
    }

    let link = getRouteByName('main', 'settings') + '/company'

    if (!userProfile && this.props.user) {
      userProfile = this.props.user
      me = _.find(this.props.hierarchy.selectors.employees, (o) => o.id === userProfile.id)
    }

    if (this.props.link) {
      link = this.props.link
    }

    const mySubordinates = get(me, 'subordinates', [])

    return (
      <div>
        <header className='section__header settings-page__aside-header'>
          <h2 className='h2 section__header-title settings-page__aside-heading'>
            {trans('settings-page.my-role')}
          </h2>
          <span>
            Mpk {get(userProfile, 'mpk.code', '-')} ({get(userProfile, 'mpk.name', '-')})
          </span>
        </header>

        <div className='settings-page__hierarchy'>
          {superior && (
            <div className='settings-page__hierarchy-row'>
              <Employee employee={superior} />
            </div>
          )}

          {me && (
            <div className='settings-page__hierarchy-row'>
              <Employee employee={me} large />
            </div>
          )}

          {!!mySubordinates.length && (
            <div className='settings-page__hierarchy-row'>
              <ul className='settings-page__hierarchy-list'>
                {mySubordinates.map((employee) => {
                  return (
                    <li key={employee.name}>
                      <Employee employee={employee} />
                    </li>
                  )
                })}
              </ul>
            </div>
          )}
        </div>

        <div className='settings-page__hierarchy-more'>
          <Link to={link}>{trans('settings-page.my-role-more')}</Link>
        </div>
      </div>
    )
  }
}

MeInCompanyComponent.propTypes = {}

const MeInCompany = compose(withHierarchy)(MeInCompanyComponent)

export default MeInCompany
export { MeInCompany }
