import React from 'react'
import MainPageLoader from '../../containers/MainPageLoader/index'

export const Wrapper = ({ children }) => {
  return (
    <div className='app__main-page main-page'>
      {/*<MainPageLoader/>*/}
      {children}
    </div>
  )
}

export const Main = ({ children }) => {
  return <main className='app__main-page-content column-padding'>{children}</main>
}

export const Aside = ({ children }) => {
  return <aside className='app__main-page-aside main-page-aside'>{children}</aside>
}
