import React from 'react'
import classNames from 'classnames'
import PlacesAutocomplete, { geocodeByAddress } from 'react-places-autocomplete'
import _ from 'lodash'
import { parseAddress } from '../../utils/addressParser'
import { Map } from 'immutable'
import { Location } from '../../models/Location'
import reactTriggerChange from 'react-trigger-change'
import memoize from 'memoize-one'

export class AutoCompleteGeocoding extends React.Component<any, any> {
  constructor(props) {
    super(props)

    let { value } = this.props.inputProps

    if (value instanceof Map) {
      value = value.toJS()
    }

    this.state = {
      address: _.get(value, 'formatted_address', ''),
      location: !_.isEmpty(value) && value.city ? value : null,
      isGeocoding: false,
    }
  }

  componentDidMount() {
    const {
      inputProps: { noAutoTrigger },
      autoFocus,
    } = this.props
    const { location } = this.state

    if (location === null && !noAutoTrigger && autoFocus) {
      this.selectFirst = true
      reactTriggerChange(this.ref)
    }
  }

  onKeyPressed(e) {
    this.showSuggestions = true

    if (e.key === 'Tab') {
      this.ref2.handleEnterKey()
    }
  }

  updateValue = memoize((value) => {
    if (value instanceof Map) {
      value = value.toJS()
    }

    if (value.formatted_address !== this.state.address) {
      this.setState({
        address: value.formatted_address,
        location: value,
      })
    }
  })

  componentDidUpdate(prevProps, prevState, prevContext) {
    let { value } = this.props.inputProps

    if (value !== prevProps.inputProps.value) {
      this.updateValue(value)
    }
  }

  handleSelect(address) {
    const {
      inputProps: { onChange },
    } = this.props
    this.setState({ address, isGeocoding: true })

    return geocodeByAddress(address).then((results) => {
      if (results.length) {
        const result = results[0]
        const location = {
          ...parseAddress(result, results[0].formatted_address),
          lat: result.geometry.location.lat(),
          long: result.geometry.location.lng(),
        }
        onChange(location)
        this.setState({ location })
      }
      this.setState({ isGeocoding: false })
    })
  }

  handleChange(address) {
    const {
      inputProps: { onChange },
    } = this.props

    this.setState({ address })

    if (_.isEmpty(address)) {
      onChange({
        ...new Location(),
      })
    }
  }

  handleInputBlur = (e) => {
    let { value } = this.props.inputProps

    if (value instanceof Map) {
      value = value.toJS()
    }

    if (e.target.value !== value.formatted_address) {
      this.setState({ address: value.formatted_address })
    }
  }

  onPaste = (e) => {
    const address = e.clipboardData.getData('Text')

    e.preventDefault()

    this.showSuggestions = false
    this.handleSelect(address)
  }

  render() {
    const AutoCompleteWrapperClasses = classNames(this.props.className, {
      'form-group__input-wrapper': true,
    })

    const inputClasses = ['input', 'input--text', 'autocomplete__input']
    const {
      inputProps: { noAutoTrigger, value, onChange, onKeyDown = undefined, ...inputProps },
      autoFocus = false,
      ...props
    } = this.props
    const _inputProps = {
      onPaste: this.onPaste.bind(this),
      onKeyDown: this.onKeyPressed.bind(this),
      ref: (ref) => (this.ref = ref),
      autoFocus: autoFocus,
      className: inputClasses.join(' '),
      onBlur: this.handleInputBlur,
      ...inputProps,
    }

    return (
      <div className={AutoCompleteWrapperClasses}>
        <PlacesAutocomplete
          highlightFirstSuggestion
          onSelect={this.handleSelect.bind(this)}
          ref={(ref) => (this.ref2 = ref)}
          value={this.state.address || ''}
          shouldFetchSuggestions={this.showSuggestions}
          onChange={this.handleChange.bind(this)}
          {...props}
        >
          {({ getInputProps, getSuggestionItemProps, suggestions }) => {
            if (this.selectFirst === true && !_.isEmpty(suggestions[0])) {
              this.selectFirst = false
              this.handleChange(suggestions[0]['description'])
              this.handleSelect(suggestions[0]['description'])
            }

            return (
              <div className='autocomplete'>
                <input {...getInputProps(_inputProps)} disabled={this.props.disabled} />
                {!_.isEmpty(suggestions) && (
                  <div className='autocomplete__list'>
                    {suggestions.map((suggestion) => {
                      return (
                        <div
                          {...getSuggestionItemProps(suggestion, {
                            className: suggestion.active
                              ? 'autocomplete-places-suggestion autocomplete-places-suggestion--active'
                              : 'autocomplete-places-suggestion',
                          })}
                          key={suggestion.placeId}
                        >
                          <div>{suggestion.description}</div>
                        </div>
                      )
                    })}
                  </div>
                )}
              </div>
            )
          }}
        </PlacesAutocomplete>
        {(this.props.isLoading || this.state.isGeocoding) && <div className='input-loader' />}
        {this.props.children}
      </div>
    )
  }
}
