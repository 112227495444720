import React from 'react'
import PropTypes from 'prop-types'
import CardsTableRow from './CardsTableRow'
import trans from '../../../../trans'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'
import { CARD_STATUS_ADDING } from '../../../../store/app/charge-cards'
import { Tooltip } from '../../../Tooltip'
import Icon from '../../../ui/IconComponent'
import * as classnames from 'classnames'

class CardsTable extends React.Component<any, any> {
  static propTypes = {
    withPriority: PropTypes.bool,
    subjectUser: PropTypes.object,
  }

  onDragEnd = (result) => {
    if (!result.destination) {
      return
    }

    this.props.reorderCards(
      result.source.index,
      result.destination.index,
      this.props.subjectUser && this.props.subjectUser.slug ? this.props.subjectUser.slug : null,
    )
  }

  renderUserContent = () => (
    <DragDropContext onDragEnd={this.onDragEnd}>
      <Droppable droppableId='droppable'>
        {(provided) => (
          <div ref={provided.innerRef}>
            {this.props.cards.map((card, index) => (
              <Draggable
                key={card.slug}
                draggableId={card.slug}
                index={index}
                isDragDisabled={card.status === CARD_STATUS_ADDING}
              >
                {(provided) => (
                  <CardsTableRow
                    subjectUser={this.props.subjectUser ? this.props.subjectUser : null}
                    data={card}
                    allowDeletingCorporate={false}
                    withPriority={!!this.props.withPriority}
                    withCompany={this.props.withCompany}
                    draggableProps={{
                      ...provided.draggableProps,
                      ...provided.dragHandleProps,
                      ref: provided.innerRef,
                    }}
                  />
                )}
              </Draggable>
            ))}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  )

  renderAdminContent = () =>
    this.props.cards.map((card) => (
      <CardsTableRow
        key={card.slug}
        data={card}
        allowDeletingCorporate={true}
        withPriority={!!this.props.withPriority}
        withCompany={this.props.withCompany}
      />
    ))

  render() {
    const tableClass = {
      'charge-cards__table': true,
      'charge-cards__table--withCompany': this.props.withCompany,
    }

    return (
      <div className={classnames(tableClass)}>
        <div className='charge-cards__table-header'>
          <div className='charge-card__column charge-cards__table-heading'>
            {trans('user.card-number')}
          </div>
          <div className='charge-card__column charge-cards__table-heading'>
            {trans('user.card-type')}
          </div>
          {this.props.withCompany && (
            <div className='charge-card__column charge-cards__table-heading'>
              {trans('user.card-company')}
            </div>
          )}
          {this.props.withPriority && (
            <div className='charge-card__column charge-cards__table-heading'>
              {trans('user.priority')}
              <Tooltip html={trans('user.priority-tooltip')}>
                <Icon type='question_fill' gradient style={{ marginLeft: 7 }} />
              </Tooltip>
            </div>
          )}
          <div className='charge-card__column charge-cards__table-heading'>
            {trans('user.operations')}
          </div>
        </div>
        {!this.props.cards.length && (
          <div className='charge-cards__charge-card charge-card'>
            <div className='charge-card__column charge-card__column--no-results' align='center'>
              {trans('global.no-results')}
            </div>
          </div>
        )}

        {this.props.reorderCards ? this.renderUserContent() : this.renderAdminContent()}
      </div>
    )
  }
}

export default CardsTable
