import { Element } from './Element'
import { Location } from './index'
import { TRAVEL_EMPTY } from '../../constants/travel'

class Empty extends Element {
  constructor(init) {
    super({ ...init, type: TRAVEL_EMPTY })

    const defaults = {}
    const opts = Object.assign({}, defaults, init)
  }

  getStartLocation() {
    return null
  }

  getEndLocation() {
    return null
  }

  getStartDate() {
    return null
  }

  getEndDate() {
    return null
  }
}

export { Empty }
export default Empty
