import React, { Fragment } from 'react'
import PropTypes from 'prop-types'

class SimpleLoader extends React.Component<any, any> {
  render() {
    const size = !!this.props.szie ? this.props.size + 'px' : '25px'

    return (
      <Fragment>
        <div className='simple-loader' style={{ width: size, height: size }} />
      </Fragment>
    )
  }
}

SimpleLoader.propTypes = {
  size: PropTypes.number,
}

export { SimpleLoader }
export default SimpleLoader
