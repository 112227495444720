import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import { actions, selectors } from '../../store/app/settlement-requests'
import { bindActionCreators } from 'redux'
import APIClient from '../../services/APIClient'
import { getSettlementTableConfig } from '../../store/app/table-config'

class SettlementRequestsManagerBase extends React.Component<any, any> {
  constructor(props) {
    super(props)

    this.state = {
      MPKs: [],
      companies: [],
    }
  }

  componentDidMount() {
    const { reset, fetchItems, listenQueryChange } = this.props

    reset()
    fetchItems()
    listenQueryChange()

    const dependencies = []

    dependencies.push(APIClient.getMPKs())
    dependencies.push(APIClient.getCompanies())

    Promise.all(dependencies).then((responses) => {
      this.setState({
        MPKs: responses[0].data,
        companies: responses[1].data,
      })
    })
  }

  componentWillUnmount() {
    const { unlistenQueryChange } = this.props

    unlistenQueryChange()
  }

  assignToMe(request) {
    const { assignToUser, currentUser } = this.props

    return assignToUser(request, currentUser)
  }

  render() {
    const {
      children,
      requests,
      isLoading,
      isLoaded,
      filters,
      changeFilter,
      sorter,
      changeSorter,
      currentUser,
      selectDeselectOne,
      selectDeselectAll,
      isItemSelected,
      selectedRequests,
      selectDeselectMany,
      pagination,
      changePage,
      tableConfig,
    } = this.props
    const { MPKs, companies } = this.state

    const renderProps = {
      requests,
      requestsFilters: filters,
      changeRequestsFilter: changeFilter,
      requestsSorter: sorter,
      changeRequestsSorter: changeSorter,
      areRequestsLoading: isLoading,
      areRequestsLoaded: isLoaded,
      requestsPagination: pagination,
      changeRequestsPage: changePage,
      currentUser,
      MPKs,
      companies,
      selectDeselectAll,
      selectDeselectOne,
      isItemSelected,
      selectedRequests,
      selectDeselectMany,
      tableConfig,
      assignToMe: this.assignToMe.bind(this),
    }

    return children(renderProps)
  }
}

SettlementRequestsManagerBase.propTypes = {
  children: PropTypes.func.isRequired,
  requests: PropTypes.array.isRequired,
  isLoaded: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
  filters: PropTypes.object.isRequired,
  changeFilter: PropTypes.func.isRequired,
  changeSorter: PropTypes.func.isRequired,
  sorter: PropTypes.object.isRequired,
  reset: PropTypes.func.isRequired,
  currentUser: PropTypes.object.isRequired,
  selectDeselectAll: PropTypes.func.isRequired,
  selectDeselectOne: PropTypes.func.isRequired,
  isItemSelected: PropTypes.func.isRequired,
  assignToUser: PropTypes.func.isRequired,
  pagination: PropTypes.object.isRequired,
}

const {
  reset,
  fetchItems,
  changeFilter,
  changeSorter,
  selectDeselectOne,
  selectDeselectAll,
  assignToUser,
  selectDeselectMany,
  changePage,
  listenQueryChange,
  unlistenQueryChange,
} = actions

const {
  getIsLoading,
  getIsLoaded,
  getFilters,
  getSorter,
  isItemSelected,
  getSelectedItems,
  getPagination,
  getItemsPaginated,
} = selectors

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      reset,
      fetchItems,
      changeFilter,
      changeSorter,
      selectDeselectOne,
      selectDeselectAll,
      assignToUser,
      selectDeselectMany,
      changePage,
      listenQueryChange,
      unlistenQueryChange,
    },
    dispatch,
  )
}

const mapStateToProps = (state) => ({
  requests: getItemsPaginated(state),
  isLoaded: getIsLoaded(state),
  isLoading: getIsLoading(state),
  filters: getFilters(state),
  sorter: getSorter(state),
  currentUser: state.get('global').get('currentUser'),
  isItemSelected: isItemSelected(state),
  selectedRequests: getSelectedItems(state),
  pagination: getPagination(state),
  tableConfig: getSettlementTableConfig(state),
})

export const SettlementRequestsManager = connect(
  mapStateToProps,
  mapDispatchToProps,
)(SettlementRequestsManagerBase)
