class LatLng {
  constructor(lat, lng) {
    this._lat = lat
    this._lng = lng
  }

  set lat(value) {
    this._lat = value
  }

  set lng(value) {
    this._lng = value
  }

  get lat() {
    return this._lat
  }

  get lng() {
    return this._lng
  }

  get long() {
    return this._lng
  }
}

export default LatLng
export { LatLng }
