import PropTypes from 'prop-types'
import React from 'react'
import classnames from 'classnames'
import Icon from '../IconComponent'
import { CounterField } from './CounterField'
import { FormGroup } from '../../../ui'
import { trans } from '../../../trans'
import { List } from 'immutable'

export class RoomField extends React.Component<any, any> {
  constructor(props) {
    super(props)
    this.divRef = null
    this.state = {
      isOpen: false,
    }
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside)
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside)
  }

  handleClickOutside = (event) => {
    if (this.state.isOpen && this.divRef && !this.divRef.contains(event.target)) {
      this.close()
    }
  }

  open() {
    if (this.props.onOpen) this.props.onOpen()
    this.setState({ isOpen: true })
  }

  close() {
    if (this.props.onClose) this.props.onClose()
    this.setState({ isOpen: false })
  }

  toggleOpen(e) {
    const { isOpen } = this.state
    const { disabled } = this.props

    e.preventDefault()

    if (disabled) {
      return
    }

    if (isOpen) {
      this.close()
    } else {
      this.open()
    }
  }

  onTouchStart(e) {
    const { disabled } = this.props

    if (disabled) {
      return
    }

    if (e.target.classList.contains('react-select__dropdown-indicator')) {
      this.toggleOpen(e)
    }
  }

  onRoomCapacityChange(roomCapacity, index) {
    const { onChange } = this.props
    const value = this.value

    value[index] = roomCapacity

    onChange(value)
  }

  onRoomCountChange(roomCount) {
    const { onChange } = this.props
    const value = this.value

    if (value.length < roomCount) {
      onChange([...value, 1])
    } else {
      value.pop()

      onChange(value)
    }
  }

  get value() {
    const { value } = this.props

    if (value instanceof List) {
      return [...value]
    }

    if (!Array.isArray(value)) {
      return [value]
    }

    return [...value]
  }

  renderRoom(room, index) {
    return (
      <div key={index} className='room-item'>
        <div className='room-name'>
          {trans('hotels-booking.rooms-list-value')} {index + 1}
        </div>

        <div className='room-field'>
          <CounterField
            min={1}
            value={room}
            onChange={(value) => this.onRoomCapacityChange(value, index)}
          />
        </div>
      </div>
    )
  }

  renderRooms(rooms) {
    return (
      <FormGroup labeltop label={trans('hotels-booking.rooms-list-label')}>
        {rooms.map((room, index) => this.renderRoom(room, index))}
      </FormGroup>
    )
  }

  renderCounter(rooms) {
    return (
      <FormGroup labeltop label={trans('hotels-booking.rooms-field-label')}>
        <CounterField value={rooms.length} onChange={(value) => this.onRoomCountChange(value)} />
      </FormGroup>
    )
  }

  render() {
    const { isOpen } = this.state
    const { className } = this.props
    const classes = classnames({
      'dropdown-field': true,
      'room-field': true,
      'is-open': isOpen,
      [className]: true,
    })

    return (
      <div ref={(ref) => (this.divRef = ref)} className={classes}>
        <div
          className='dropdown-field__value'
          onClick={(e) => this.toggleOpen(e)}
          onTouchStart={(e) => this.onTouchStart(e)}
          onTouchEnd={(e) => this.toggleOpen(e)}
        >
          <div className='dropdown-field__value-label'>{this.value.length}</div>

          <div className='dropdown-field__value-change'>
            <Icon type='edit' gradient />
          </div>
        </div>

        <div className='dropdown-field__dropdown'>
          <div className='tooltip-content'>
            <div className='dropdown-field__options room-row'>
              <div className='room-col'>{this.renderCounter(this.value)}</div>
              <div className='room-col'>{this.renderRooms(this.value)}</div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

RoomField.propTypes = {
  onChange: PropTypes.func,
  name: PropTypes.string,
  className: PropTypes.string,
  value: PropTypes.any,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
}
