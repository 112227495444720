export type TableConfigResponse = Record<string, TableConfig[]>

export interface TableConfig {
  header: string
  field: string
  sortField: string
  fieldType: TableConfigFieldType
}

export enum TableConfigFieldType {
  STRING = 'string',
  CURRENCY = 'currency',
}
