import React from 'react'
import classNames from 'classnames'
import Icon from '../../components/ui/IconComponent'

export const Toggle = ({ className, ...props }) => {
  const ToggleClasses = classNames(className, {
    'input-toggle': true,
  })

  return (
    <label className={ToggleClasses}>
      <input type='checkbox' className='input-toggle__input' {...props} />
      <div className='input-toggle__toggle-container-outer'>
        <div className='input-toggle__toggle-container-inner'>
          <span className='input-toggle__checked-background' />
          <Icon type='close' className='input-toggle__toggle-close-icon' />
          <Icon type='checked' className='input-toggle__toggle-checked-icon' />
        </div>
      </div>
    </label>
  )
}
