import { Request } from '../../types/request'

export interface AccountingDocumentsManageProviderProps {
  types: Array<'travel' | 'accounting' | 'confirmation'>
  request: Request
  children: (params: any) => any
}

function AccountingDocumentsManageProvider(props: AccountingDocumentsManageProviderProps) {
  const {
    children,
    request: { documents },
    types = [],
  } = props
  const filtered = documents.filter((document) => types.includes(document.type))
  const renderProps = { ...props, documents: filtered }

  return children(renderProps)
}

export default AccountingDocumentsManageProvider
