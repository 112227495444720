import React from 'react'
import { ReportsFilters } from './Filters'
import Widgets from './Widgets'
import ActionBar from './ActionBar'
import { Helmet } from 'react-helmet'
import { BreadCrumbs } from '../../containers'
import { Link } from 'react-router-dom'
import { getRouteByName } from '../../routes'
import trans from '../../trans'
import { SectionHeader } from '../ui/SectionHeader'
import { Section } from '../ui/Section'

class ReportsPage extends React.Component<any, any> {
  render() {
    return (
      <div className="reports">
        <BreadCrumbs>
          <Link to={getRouteByName('main', 'dashboard')}>{trans('reports.reports')}</Link>
          <Link to={getRouteByName('main', 'cockpit')}>{trans('reports.cocpit')}</Link>
        </BreadCrumbs>
        <Section noBorder>
          <Helmet title={trans('reports.cocpit')} />

          <SectionHeader caption={trans('reports.cocpit')} />

          <ReportsFilters />
          <Widgets />
          <ActionBar />

        </Section>
      </div>
    )
  }
}

export { ReportsPage }
export default { ReportsPage }
