import React from 'react'
import classNames from 'classnames'
import PlacesAutocomplete from 'react-places-autocomplete'
import { isMap, isObject } from 'lodash'

export class AutoComplete extends React.Component<any, any> {
  prepareValue(value) {
    let parsed = ''

    if (isMap(value)) {
      value = value.toJS()
    }

    if (isObject(value)) {
      if (value.get('address')) {
        parsed += value.get('address')
      }

      if (value.get('city')) {
        parsed += ', ' + value.get('city')
      }

      if (value.get('province')) {
        parsed += ', ' + value.get('province')
      }

      if (value.get('country')) {
        parsed += ', ' + value.get('country')
      }
    }

    return parsed
  }

  render() {
    const AutoCompleteWrapperClasses = classNames(this.props.className, {
      'form-group__input-wrapper': true,
    })

    let inputClasses = ['input', 'input--text', 'autocomplete__input']
    const autocompleteClasses = {
      root: 'autocomplete',
      input: inputClasses.join(' '),
      autocompleteContainer: 'autocomplete__list',
    }

    const {
      inputProps: { value, ...inputProps },
      ...props
    } = this.props

    return (
      <div className={AutoCompleteWrapperClasses}>
        <PlacesAutocomplete
          classNames={autocompleteClasses}
          inputProps={{
            ...inputProps,
            value: this.prepareValue(value),
          }}
          {...props}
        />
        {this.props.isLoading && <div className='input-loader' />}
        {this.props.children}
      </div>
    )
  }
}
