import React, { Fragment } from 'react'
import { Field } from 'redux-form/immutable'
import { Map } from 'immutable'
import { FormField } from '../../ui/Form'
import classNames from 'classnames'
import Icon from '../../ui/IconComponent'
import trans from '../../../trans'

export const MpkSplitField = ({
  fields,
  disabled,
  label,
  placeholder,
  noOptionsMessage,
  loadingMessage,
  loadOptions,
  meta: { error },
  ...rest
}) => {
  const fieldsClasses = classNames({
    'mpk-split-field__container': fields.length > 1,
  })

  const splitMpk = () => {
    if (fields.length === 1) {
      const items = fields.getAll()

      const newItems = [
        ...items.map((item) => {
          const entry = item instanceof Map ? item.toJS() : item

          return {
            ...entry,
            percentage: 50,
          }
        }),
        {
          id: null,
          percentage: 50,
          main: 0,
        },
      ]

      fields.splice(0, 1, newItems[0])
      fields.splice(1, 0, newItems[1])
    } else {
      fields.push({
        id: null,
        percentage: 0,
        main: 0,
      })
    }
  }

  const remove = (index) => {
    fields.remove(index)
  }

  const renderField = (field, index, data, ...rest) => {
    const item = data.get(index)
    const selectedOption = item instanceof Map ? item.toJS() : item

    if (data.length === 1) {
      return (
        <Field
          key={field}
          name={`${field}.id`}
          disabled={disabled}
          type={'async-select'}
          component={FormField}
          loadOptions={loadOptions}
          placeholder={placeholder}
          noOptionsMessage={noOptionsMessage}
          loadingMessage={loadingMessage}
          selectedOption={selectedOption}
          groupWrapper={false}
        />
      )
    }

    return (
      <Fragment key={field}>
        <Field
          name={`${field}.id`}
          disabled={disabled}
          type={'async-select'}
          component={FormField}
          loadOptions={loadOptions}
          placeholder={placeholder}
          noOptionsMessage={noOptionsMessage}
          loadingMessage={loadingMessage}
          selectedOption={selectedOption}
          groupWrapper={false}
        />

        <div className={'mpk-split-field__percentage'}>
          <Field
            name={`${field}.percentage`}
            disabled={disabled}
            type={'text'}
            component={FormField}
            placeholder={'0'}
            groupWrapper={false}
          />

          <div className={'mpk-split-field__sign'}>%</div>

          <div className={'mpk-split-field__remove'} onClick={() => remove(index)}>
            <Icon type={'minus'} />
          </div>
        </div>
      </Fragment>
    )
  }

  return (
    <div className='form-group form-group--label-top'>
      <div className='mpk-split-field'>
        <div className='mpk-split-field__label'>
          <span className='form-group__label'>{label}</span>

          <span className={'mpk-split-field--split'} onClick={() => splitMpk()}>
            {trans('mpk.split')}
          </span>
        </div>

        <div className={fieldsClasses}>{fields.map(renderField)}</div>

        {error && (
          <div className='form-group__error'>
            <p>{error}</p>
          </div>
        )}
      </div>
    </div>
  )
}
