import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { SmartCheckbox } from '../../../../ui/Input/SmartCheckbox'
import AccommodationConfirmableCheckbox from '../AccommodationConfirmableCheckbox'
import { trans } from '../../../../trans'
import Icon from '../../../ui/IconComponent'
import { Tooltip } from '../../../Tooltip'

const LumpSumExpenseForm = (props) => (
  <Fragment>
    <div className='declarations__checkbox declarations__grid-column'>
      <div className='accommodation-checkbox__wrapper'>
        <AccommodationConfirmableCheckbox
          checked={props.values.accommodation}
          disabled={props.disabled.accommodation || !props.userCanObtainAccommodation}
          change={props.change}
          validate={props.validate}
        />
        {!props.userCanObtainAccommodation && (
          <Tooltip html={trans('deductions-widget.accommodation-cant-be-obtained')}>
            <Icon type='question_fill' className='accommodation-cant-be-obtained__info-icon' />
          </Tooltip>
        )}
      </div>
    </div>
    <div />

    <div className='declarations__checkbox declarations__grid-column'>
      <SmartCheckbox
        name='drive'
        checked={props.values.drive}
        disabled={props.disabled.drive}
        onChange={(value) => props.change('drive', value)}
      />
    </div>
  </Fragment>
)

LumpSumExpenseForm.propTypes = {
  values: PropTypes.object.isRequired,
  disabled: PropTypes.object.isRequired,
  change: PropTypes.func.isRequired,
  validate: PropTypes.func.isRequired,
}

export default LumpSumExpenseForm
export { LumpSumExpenseForm }
