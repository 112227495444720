import React, { PureComponent } from 'react'
import Icon from '../ui/IconComponent'
import { times } from 'lodash'

class Stars extends PureComponent<any, any> {
  render() {
    const { stars } = this.props
    return times(5, (index) => (
      <Icon
        className='icon-star_fill--yellow'
        key={`star-${index}`}
        type={index < stars ? 'star_fill' : 'star'}
      />
    ))
  }
}

export { Stars }
export default Stars
