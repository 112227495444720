import PropTypes from 'prop-types'
import React from 'react'
import { StateTextButton } from '../ui/StateTextButton'
import { SelectField, DatePickerField } from '../ui/Form'
import {
  getStatusesForTransactionSelect,
  STATUS_ACCEPTANCE_OF_SETTLEMENT,
  STATUS_ACCOUNTING,
  STATUS_TRANSFER_ERROR,
  STATUS_TRANSFERRED,
} from '../../constants/request'
import trans from '../../trans'
import Icon from '../../components/ui/IconComponent'
import { getInstance } from '../../store/app/instance'
import { connect } from 'react-redux'
import { AsyncSelectField } from '../ui/Form/AsyncSelectField'
import { loadOptionsForUsers } from '../../store/app/users-for-select'
import { loadOptionsForProviders } from '../../store/app/providers-for-select'
import { fetchMpkForAsyncSelect } from '../../store/app/mpks'
import ToggleableFilters from '../ToggleableFilters/ToggleableFilters'
import ToggleableQuickFilters from '../ToggleableFilters/ToggleableQuickFilters'
import ToggleableBottomFilters from '../ToggleableFilters/ToggleableBottomFilters'
import { components } from '../../utils/react-select'
import UserSelectOption from '../../form/UserField/UserFieldOption'
import UserField from '../../form/UserField/UserField'

const STATUS_TO_SETTLE = 'to_settle'
const STATUS_TO_ACCOUNT = 'to_account'
const STATUS_ACCOUNTED = 'accounted'
const DOCUMENT_TYPE_ACCOUNTING = 'accounting'
const STATUS_TRANSFERRED_TO_ERP = 'transferred_to_erp'

// For Page Transactions
class DocumentsListFilters extends React.Component<any, any> {
  constructor(props) {
    super(props)

    this.timer = null

    this.state = {
      statusOptions: [],
    }

    this.fetchMpkForAsyncSelect = fetchMpkForAsyncSelect()
  }

  componentDidMount() {
    const options = getStatusesForTransactionSelect()
    const { instance } = this.props

    if (instance.modules.erp && instance.modules.erp.enabled) {
      options.splice(-1, 0, {
        label: trans(`global.request-status-${STATUS_TRANSFER_ERROR}`),
        value: STATUS_TRANSFER_ERROR,
      })

      options.splice(-1, 0, {
        label: trans(`global.request-status-${STATUS_TRANSFERRED}`),
        value: STATUS_TRANSFERRED,
      })
    }

    this.setState({
      statusOptions: options,
    })
  }

  getCompanyOptions() {
    const { companies } = this.props
    const options = companies.map((company) => ({
      value: company.id,
      label: company.code,
    }))

    const optionAll = {
      label: trans('global.all'),
      value: null,
      onSelectResetsInput: true,
    }

    return [optionAll, ...options]
  }

  render() {
    const {
      filters,
      onChangeFilter,
      currentUser: { slug },
    } = this.props
    const filtersCount = Object.keys(filters).length

    const bottomFilters = (
      <>
        <div className='filters__input-wrapper'>
          <span className='filters__input-title'>{trans('global.filter-doc-type')}</span>
          <SelectField
            options={[
              {
                label: trans('global.all'),
                value: null,
                onSelectResetsInput: true,
              },
              {
                label: trans('global.filter-document-type'),
                value: 'accounting',
              },
              {
                label: trans('global.filter-document-cover-type'),
                value: 'travel',
              },
            ]}
            value={filters['transaction_document_type']}
            onChange={(value) => {
              onChangeFilter('transaction_document_type', value)
            }}
            placeholder={trans('global.filter-placeholder-all')}
            clearable={true}
          />
        </div>

        <div className='filters__input-wrapper'>
          <span className='filters__input-title'>{trans('global.employee')}</span>

          <UserField
            value={filters['users']}
            onChange={(value) => onChangeFilter('users', value)}
            loadItems={loadOptionsForUsers}
            isMulti
            clearable
          />
        </div>

        <div className='filters__input-wrapper'>
          <span className='filters__input-title'>{trans('global.filter-mpk')}</span>

          <AsyncSelectField
            loadOptions={this.fetchMpkForAsyncSelect}
            value={filters['mpks']}
            onChange={(value) => onChangeFilter('mpks', value)}
            placeholder={trans('global.filter-placeholder-all')}
            noOptionsMessage={() => {
              return trans('user.no-results-for-search-message')
            }}
            loadingMessage={() => {
              return trans('user.searching-message')
            }}
            delay={500}
            returnFullItemValueOnChange
            isMulti
            clearable={true}
          />
        </div>

        <div className='filters__input-wrapper'>
          <span className='filters__input-title'>
            {trans('global.filter-period-transferred-to-erp')}
          </span>
          <div className='filters__period-container'>
            <DatePickerField
              placeholder={trans('global.datepicker-placeholder')}
              onChange={(value) => onChangeFilter('period_start', value)}
              value={filters['period_start']}
              isClearable={true}
            />
            <span className='filters__separator'>-</span>
            <DatePickerField
              isClearable={true}
              minDate={filters['period_start']}
              placeholder={trans('global.datepicker-placeholder')}
              onChange={(value) => onChangeFilter('period_end', value)}
              value={filters['period_end']}
            />
          </div>
        </div>

        <div className='filters__input-wrapper'>
          <span className='filters__input-title'>{trans('global.company')}</span>
          <SelectField
            options={this.getCompanyOptions()}
            value={filters['companies']}
            onChange={(value) => onChangeFilter('companies', value)}
            placeholder={trans('global.filter-placeholder-all')}
            isMulti
            clearable={true}
          />
        </div>

        <div className='filters__input-wrapper'>
          <span className='filters__input-title'>{trans('global.provider')}</span>
          <AsyncSelectField
            loadOptions={loadOptionsForProviders}
            value={filters['providers']}
            onChange={(value) => onChangeFilter('providers', value)}
            placeholder={trans('global.filter-placeholder-everyone')}
            noOptionsMessage={() => {
              return trans('provider.no-results-for-search-message')
            }}
            loadingMessage={() => {
              return trans('provider.searching-message')
            }}
            delay={500}
            isMulti
            returnFullItemValueOnChange
            clearable={true}
          />
        </div>

        {this.props.instance.modules.erp && this.props.instance.modules.erp.enabled ? (
          <div className='filters__input-wrapper'>
            <span className='filters__input-title'>{trans('global.filter-document-vat-date')}</span>
            <div className='filters__period-container'>
              <DatePickerField
                placeholder={trans('global.datepicker-placeholder')}
                onChange={(value) => onChangeFilter('vat_date_start', value)}
                value={filters['vat_date_start']}
                isClearable={true}
              />
              <span className='filters__separator'>-</span>
              <DatePickerField
                isClearable={true}
                minDate={filters['vat_date_start']}
                placeholder={trans('global.datepicker-placeholder')}
                onChange={(value) => onChangeFilter('vat_date_end', value)}
                value={filters['vat_date_end']}
              />
            </div>
          </div>
        ) : (
          ''
        )}

        <div className='filters__input-wrapper'>
          <span className='filters__input-title'>{trans('global.filter-status')}</span>
          <SelectField
            options={this.state.statusOptions}
            value={filters['statuses']}
            onChange={(value) => onChangeFilter('statuses', value)}
            placeholder={trans('global.filter-placeholder-all')}
            isMulti
            clearable={true}
          />
        </div>

        <div className='filters__input-wrapper'>
          <span className='filters__input-title'>
            {trans('global.filter-document-issued-date')}
          </span>
          <div className='filters__period-container'>
            <DatePickerField
              placeholder={trans('global.datepicker-placeholder')}
              onChange={(value) => onChangeFilter('document_issued_date_start', value)}
              value={filters['document_issued_date_start']}
              isClearable={true}
            />
            <span className='filters__separator'>-</span>
            <DatePickerField
              isClearable={true}
              minDate={filters['document_issued_date_start']}
              placeholder={trans('global.datepicker-placeholder')}
              onChange={(value) => onChangeFilter('document_issued_date_end', value)}
              value={filters['document_issued_date_end']}
            />
          </div>
        </div>
      </>
    )

    return (
      <form>
        <ToggleableFilters filtersCount={filtersCount}>
          {({ isOpen }) => (
            <>
              <ToggleableQuickFilters>
                <div className='input-group'>
                  <Icon type='search' lg />
                  <input
                    type='text'
                    placeholder={trans('ui.search')}
                    className='input-group__input'
                    ref={(e) => (this.searchInput = e)}
                    defaultValue={filters['phrase']}
                    onChange={() => {
                      clearTimeout(this.timer)
                      this.timer = setTimeout(() => {
                        onChangeFilter('phrase', this.searchInput.value)
                      }, 250)
                    }}
                  />
                </div>

                <div className='filters__quick'>
                  <span className='filters__quick-title'>{trans('global.quick-filters')}:</span>

                  <StateTextButton
                    onClick={(activate) => {
                      onChangeFilter(
                        'transaction_document_type',
                        activate ? DOCUMENT_TYPE_ACCOUNTING : null,
                      )
                    }}
                    active={filters['transaction_document_type'] === DOCUMENT_TYPE_ACCOUNTING}
                  >
                    {trans('global.filter-accounting-documents')}
                  </StateTextButton>

                  <StateTextButton
                    onClick={(activate) => {
                      onChangeFilter('quick_filters', activate ? STATUS_TO_SETTLE : null)
                    }}
                    active={filters['quick_filters'] === STATUS_TO_SETTLE}
                  >
                    {trans('global.filter-document-to-settle')}
                  </StateTextButton>

                  <StateTextButton
                    onClick={(activate) => {
                      onChangeFilter('quick_filters', activate ? STATUS_TO_ACCOUNT : null)
                    }}
                    active={filters['quick_filters'] === STATUS_TO_ACCOUNT}
                  >
                    {trans('global.filter-document-to-account')}
                  </StateTextButton>

                  {this.props.instance.modules.erp && this.props.instance.modules.erp.enabled ? (
                    <StateTextButton
                      onClick={(activate) => {
                        onChangeFilter('quick_filters', activate ? STATUS_TRANSFERRED_TO_ERP : null)
                      }}
                      active={filters['quick_filters'] === STATUS_TRANSFERRED_TO_ERP}
                    >
                      {trans('global.filter-document-transferred-to-erp')}
                    </StateTextButton>
                  ) : (
                    ''
                  )}

                  <StateTextButton
                    onClick={(activate) => {
                      onChangeFilter('quick_filters', activate ? STATUS_ACCOUNTED : null)
                    }}
                    active={filters['quick_filters'] === STATUS_ACCOUNTED}
                  >
                    {trans('global.filter-document-accounted')}
                  </StateTextButton>
                </div>
              </ToggleableQuickFilters>

              <ToggleableBottomFilters isOpen={isOpen}>{bottomFilters}</ToggleableBottomFilters>
            </>
          )}
        </ToggleableFilters>
      </form>
    )
  }
}

DocumentsListFilters.propTypes = {
  filters: PropTypes.object.isRequired,
  onChangeFilter: PropTypes.func.isRequired,
  MPKs: PropTypes.array.isRequired,
  companies: PropTypes.array.isRequired,
  currentUser: PropTypes.object.isRequired,
}

const mapStateToProps = (state) => ({
  instance: getInstance(state),
})

DocumentsListFilters = connect(mapStateToProps)(DocumentsListFilters)

export default { DocumentsListFilters }
export { DocumentsListFilters }
