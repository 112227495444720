import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { fetchAvatar } from '../../store/app/avatar'

export function useAvatar(user) {
  const [avatar, setAvatar] = useState()
  const dispatch = useDispatch()

  useEffect(() => {
    let mounted = true

    if (user) {
      dispatch(fetchAvatar(user.avatar, user.slug)).then((avatar) => {
        if (mounted) {
          setAvatar(avatar)
        }
      })
    }

    return () => {
      mounted = false
    }
  }, [user ? user.avatar : user])

  return avatar
}
