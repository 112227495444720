import React, { DetailedHTMLProps, InputHTMLAttributes } from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'

interface RadioProps
  extends DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {
  label: string
}

export const Radio = ({ className, label, defaultChecked, ...props }: RadioProps) => {
  const CheckboxClasses = classNames(className, {
    'radio-wrapper__input-wrapper': true,
  })

  return (
    <label className='radio-wrapper'>
      <div className={CheckboxClasses}>
        <input
          type='radio'
          className='radio-wrapper__input'
          {...props}
          defaultChecked={defaultChecked}
        />
        <span className='radio-wrapper__icon'></span>
      </div>

      <span className='radio-wrapper__label'>{label}</span>
    </label>
  )
}

Radio.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  defaultChecked: PropTypes.bool,
}
