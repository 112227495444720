import { Cell, Row } from '../../../ui/AccordionTable'
import Button from '../../../ui/ButtonComponent'
import trans from '../../../../trans'
import React from 'react'
import { change, reduxForm, Field, SubmissionError } from 'redux-form/immutable'
import { connect } from 'react-redux'
import { bindActionCreators, compose } from 'redux'
import { getFormValues } from '../../../../utils/forms'
import { fromJS } from 'immutable'
import { FormField } from '../../../ui/Form'
import { getValidationErrors } from '../../../../utils'
import { updateKpi } from '../../../../store/app/kpis'

class KpiListFormItem extends React.Component<any, any> {
  render() {
    const { handleSubmit, cancel, item } = this.props

    return (
      <Row>
        <form onSubmit={handleSubmit}>
          <Cell fixedWidth='auto'>{item.name}</Cell>
          <Cell fixedWidth={150} alignRight>
            <Field
              name='value'
              type='formatted_number'
              component={FormField}
              inputOnly
              withErrorTooltip
            />
          </Cell>
          <Cell fixedWidth={100}>{trans('accounting.kpi-unit')}</Cell>
          <Cell fixedWidth={180} alignRight>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <Button primary xs className={'btn--tiny'} onClick={handleSubmit}>
                {trans('ui.save-send')}
              </Button>
              <Button outline xs className={'btn--tiny'} type='button' onClick={() => cancel(item)}>
                {trans('ui.save-cancel')}
              </Button>
            </div>
          </Cell>
        </form>
      </Row>
    )
  }
}

const submit = (values, dispatch, props) => {
  const data = values.toJS()
  const { cancel, updateKpi } = props

  return updateKpi(data)
    .then((item) => cancel(item))
    .catch((res) => {
      throw new SubmissionError(getValidationErrors(res.alerts))
    })
}

const withForm = reduxForm({
  onSubmit: submit,
  enableReinitialize: true,
})

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      change,
      updateKpi,
    },
    dispatch,
  )
}

const mapStateToProps = (state, props) => {
  const form = `kpi-form-${props.item.slug}`
  const currentValues = getFormValues(form, state)
  const {
    item: { value, slug },
  } = props

  return {
    initialValues: fromJS({
      slug,
      value,
    }),
    data: currentValues,
    form,
  }
}

const withConnect = connect(mapStateToProps, mapDispatchToProps)

export default compose(withConnect, withForm)(KpiListFormItem)
