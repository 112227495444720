import React, { Fragment } from 'react'
import { trans } from '../../../trans'
import { Tooltip } from '../../Tooltip'
import Icon from '../../ui/IconComponent'
import { get } from 'lodash'
import { connect } from '../../../containers/FlightsBooking'
import PropTypes from 'prop-types'

const Luggage = connect((props) => {
  const {
    pax,
    flights: {
      selectors: { arePaxAvailableAttributesForBoth },
    },
    offer,
    children,
    showLowCostMessage,
  } = props

  const extFlightInfo = get(offer, 'attributes.extFlightInfo', '')
  const airlineCode = get(offer, 'attributes.airlineCode', '')
  const areAvailableAttributesForBoth = arePaxAvailableAttributesForBoth(pax)
  const checkedBaggage = pax ? get(pax, 'fba', []) : get(offer, 'attributes.checkedBaggage', [])

  const luggageList = checkedBaggage.map((item, index) => (
    <div key={index} className='plane-trip__offer-tooltip__content'>
      <p>
        <strong>
          {item.departureCity} ({item.departureAirport}) - {item.arrivalCity} ({item.arrivalAirport}
          )
        </strong>
      </p>
      <p>
        <span>{item.luggageDetails}</span>
      </p>
    </div>
  ))

  const lowCostMessage = trans('flights-booking.free-luggage-low-cost')

  let extFlightInfoTranslated = lowCostMessage
  if (extFlightInfo) {
    const transKey = ('flights-booking.baggage-' + airlineCode + '-' + extFlightInfo).toLowerCase()
    extFlightInfoTranslated =
      trans(transKey) !== transKey ? trans(transKey) : extFlightInfoTranslated
  }

  return (
    <Fragment>
      <div className='plane-trip__offer-detail-tooltip-wrapper'>
        <span className='plane-offer__detail-heading'>
          {areAvailableAttributesForBoth
            ? trans('flights-booking.luggage-for-both')
            : trans('flights-booking.luggage')}
        </span>

        {(!!checkedBaggage.length || showLowCostMessage) && (
          <Tooltip
            html={
              <div className='plane-trip__offer-tooltip plane-trip__offer-tooltip--luggage'>
                {showLowCostMessage ? extFlightInfoTranslated : luggageList}
              </div>
            }
          >
            <Icon type='info' className='icon-info-grey plane-trip__icon-info' />
          </Tooltip>
        )}
      </div>
      <div className='plane-offer__detail-content'>{children}</div>
    </Fragment>
  )
})

Luggage.propTypes = {
  offer: PropTypes.object.isRequired,
  showLowCostMessage: PropTypes.bool,
}

Luggage.defaultProps = {
  showLowCostMessage: false,
}

export default Luggage
export { Luggage }
