import * as Sentry from '@sentry/react'

export default function initializeSentry() {
  if (!APP_CONFIG.sentry || !process.env.SENTRY_DSN) {
    return
  }

  Sentry.init({
    dsn: process.env.SENTRY_DSN,
    integrations: [
      new Sentry.BrowserTracing(),
      new Sentry.Replay({
        maskAllText: false,
        blockAllMedia: false,
        maskAllInputs: false,
        networkDetailAllowUrls: [`${window.location.origin}/api`],
      }),
    ],
    replaysSessionSampleRate: 0,
    replaysOnErrorSampleRate: 1.0,
    tracesSampleRate: 1.0,
    release: process.env.SENTRY_DIST,
    environment: APP_CONFIG.environment,
  })

  Sentry.setTag('instance', window.location.origin)
}
