import React, { Component, Fragment } from 'react'
import classnames from 'classnames'
import { components } from '../../../utils/react-select'
import SelectWithOutsideClosing from './SelectWithOutsideClosing'
import { trans } from '../../../trans'

const Option = (props) => {
  const classes = classnames({
    'react-select__option': true,
    'react-select__option--is-selected': props.isSelected,
  })

  return (
    <div className={classes} onClick={() => props.selectOption(props)}>
      <div className='react-select__option-row'>
        <div className='react-select__row-wrapper'>
          <div className='react-select__option-row-label'>{props.label}</div>
          <div className='react-select__option-row-amount'>
            <span>{trans('flights-booking.service-amount-about')} </span>
            {props.data.amount}
          </div>
        </div>
        <div />
      </div>
    </div>
  )
}

const newComponents = {
  ...components,
  Option,
}

class FlightServiceSelect extends Component<any, any> {
  onChange = (e) => {
    const { onChange } = this.props
    onChange(e.value)
  }

  getValue = () => {
    const { options, value } = this.props
    return options.find((option) => option.value === value)
  }

  render() {
    const { name, options, placeholder } = this.props

    const classes = classnames({
      'react-select': true,
      'react-select--no-border': false,
    })

    return (
      <Fragment>
        <div className='react-select__container--outer react-select__container--flight-services'>
          <SelectWithOutsideClosing
            classes={classes}
            name={name}
            options={options}
            openMenuOnClick={true}
            onChange={this.onChange}
            value={this.getValue()}
            components={newComponents}
            placeholder={placeholder}
          />
        </div>
      </Fragment>
    )
  }
}

FlightServiceSelect.propTypes = {}

export default FlightServiceSelect
export { FlightServiceSelect }
