import APIClient from '../../../services/APIClient'
import { createModule } from '../../modules/list/index'

const fetchMethod = ({ filters }, source) => {
  return APIClient.getAgentTripRequests(filters, source)
}

const module = createModule({
  namespace: 'agent-trip-requests',
  mountPoint: 'agent-trip-requests',
  fetchMethod,
  paginationByAPI: true,
})

const { reducer, MOUNT_POINT, actions, selectors } = module

export { reducer, MOUNT_POINT, actions, selectors }
