import { getInitialState } from './index'
import { cloneDeep, set as _set } from 'lodash'

//actions
export const reset = () => {
  return getInitialState()
}

export const set = (state, payload) => {
  return {
    ...state,
    ...payload,
  }
}

export const setCurrent = (state, payload) => {
  const employees = payload.employees

  return {
    ...state,
    employees,
  }
}

export const change = (state, payload) => {
  return _set(cloneDeep(state), payload.path, payload.value)
}

export const updateAvatarInHierarchy = (state, payload) => {
  const employees = state.employees || []

  function recursive(employee) {
    if (employee.id === payload.id) {
      return {
        ...employee,
        avatar: payload.avatar,
      }
    }

    return {
      ...employee,
      subordinates: employee.subordinates.map(recursive),
    }
  }

  return {
    ...state,
    employees: employees.map(recursive),
  }
}
