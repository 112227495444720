import ApiClient from './APIClient'
import { config } from '../config'
import store from '../store'
import {
  fetchLoggedUser,
  setCurrentUser,
  setLoggedAs,
  setUserProfile,
} from '../store/app/user-profile/userProfileSlice'

export default class Session {
  static isAuthenticated() {
    return new Promise((resolve, reject) => {
      let sessionData = config.storage.getItem('sessionData')
      if (sessionData) {

        store.dispatch(fetchLoggedUser())
          .then((response) => {
            if (response.success) {
              resolve(response)
            } else {
              reject()
            }
          })
          .catch(() => {
            config.storage.removeItem('sessionData')
            reject()
          })

      } else {
        reject()
      }
    })
  }

  static saveToStorage(sessionData) {
    config.storage.setItem('sessionData', sessionData)
  }

  static clearSessionData() {
    config.storage.removeItem('sessionData')
    config.storage.removeItem('instanceData')
  }

  static getTokenFromUrl() {
    if (location.href.match(/\?token=(.*)$/)) {
      const token = location.href.match(/\?token=(.*)$/)[1]
      this.saveToStorage(
        JSON.stringify({
          token,
          isAuthenticated: true,
        }),
      )
      history.pushState({}, document.title, location.origin)
    }
  }
}
