import React, { memo, useEffect, useState } from 'react'
import APIClient from '../../services/APIClient'

const ImagePreview = ({ id, style }) => {
  const [blob, setBlob] = useState(null)

  useEffect(() => {
    APIClient.getDocumentAsBlob(`storage/documents/${id}/original`).then((response) => {
      setBlob(URL.createObjectURL(response.data))
    })
  }, [id])

  return (
    <img
      style={{
        maxWidth: '100%',
        border: '1px solid #c1c1c1',
        ...style,
      }}
      src={blob}
      alt=''
    />
  )
}

export default memo(ImagePreview)
