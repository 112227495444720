import React, { Component, Fragment } from 'react'
import { connect } from '../../containers/CompanyHierarchy'
import { CompanyHierarchy as CompanyHierarchyTree } from '../CompanyHierarchy'

export class CompanyHierarchyComponent extends Component<any, any> {
  render() {
    const {
      hierarchy: {
        selectors: { employees },
      },
    } = this.props

    return <CompanyHierarchyTree employees={employees} />

    return (
      <Fragment>
        <ul className='company-hierarchy__list'>
          <li>
            <div>
              <img
                src='//placehold.it/300'
                alt='Username'
                className='settings-page__hierarchy-avatar'
              />
              <span className='settings-page__hierarchy-name'>Jan Bąk 1</span>
            </div>
          </li>
          <li>
            <div>
              <img
                src='//placehold.it/300'
                alt='Username'
                className='settings-page__hierarchy-avatar'
              />
              <span className='settings-page__hierarchy-name'>Jan Bąk 1</span>
            </div>
            <ul>
              <li>
                <div>
                  <img
                    src='//placehold.it/300'
                    alt='Username'
                    className='settings-page__hierarchy-avatar'
                  />
                  <span className='settings-page__hierarchy-name'>Jan Bąk2</span>
                </div>
                <ul>
                  <li>
                    <div>
                      <img
                        src='//placehold.it/300'
                        alt='Username'
                        className='settings-page__hierarchy-avatar'
                      />
                      <span className='settings-page__hierarchy-name'>Jan Bąk2</span>
                    </div>
                  </li>
                  <li>
                    <div>
                      <img
                        src='//placehold.it/300'
                        alt='Username'
                        className='settings-page__hierarchy-avatar'
                      />
                      <span className='settings-page__hierarchy-name'>Jan Bąk2</span>
                    </div>
                  </li>
                  <li>
                    <div>
                      <img
                        src='//placehold.it/300'
                        alt='Username'
                        className='settings-page__hierarchy-avatar'
                      />
                      <span className='settings-page__hierarchy-name'>Jan Bąk2</span>
                    </div>
                    <ul>
                      <li>
                        <div>
                          <img
                            src='//placehold.it/300'
                            alt='Username'
                            className='settings-page__hierarchy-avatar'
                          />
                          <span className='settings-page__hierarchy-name'>Jan Bąk2</span>
                        </div>
                        <ul>
                          <li>
                            <div>
                              <img
                                src='//placehold.it/300'
                                alt='Username'
                                className='settings-page__hierarchy-avatar'
                              />
                              <span className='settings-page__hierarchy-name'>Jan Bąk2</span>
                            </div>
                          </li>
                          <li>
                            <div>
                              <img
                                src='//placehold.it/300'
                                alt='Username'
                                className='settings-page__hierarchy-avatar'
                              />
                              <span className='settings-page__hierarchy-name'>Jan Bąk2</span>
                            </div>
                          </li>
                          <li>
                            <div>
                              <img
                                src='//placehold.it/300'
                                alt='Username'
                                className='settings-page__hierarchy-avatar'
                              />
                              <span className='settings-page__hierarchy-name'>Jan Bąk2</span>
                            </div>
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </li>
                </ul>
              </li>
            </ul>
          </li>
          <li>
            <div>
              <img
                src='//placehold.it/300'
                alt='Username'
                className='settings-page__hierarchy-avatar'
              />
              <span className='settings-page__hierarchy-name'>Jan Bąk 1</span>
            </div>
            <ul>
              <li>
                <div>
                  <img
                    src='//placehold.it/300'
                    alt='Username'
                    className='settings-page__hierarchy-avatar'
                  />
                  <span className='settings-page__hierarchy-name'>Jan Bąk2</span>
                </div>
              </li>
            </ul>
          </li>
          <li>
            <div>
              <img
                src='//placehold.it/300'
                alt='Username'
                className='settings-page__hierarchy-avatar'
              />
              <span className='settings-page__hierarchy-name'>Jan Bąk 1</span>
            </div>
          </li>
          <li>
            <div>
              <img
                src='//placehold.it/300'
                alt='Username'
                className='settings-page__hierarchy-avatar'
              />
              <span className='settings-page__hierarchy-name'>Jan Bąk 1</span>
            </div>
            <ul>
              <li>
                <div>
                  <img
                    src='//placehold.it/300'
                    alt='Username'
                    className='settings-page__hierarchy-avatar'
                  />
                  <span className='settings-page__hierarchy-name'>Jan Bąk2</span>
                </div>
                <ul>
                  <li>
                    <div>
                      <img
                        src='//placehold.it/300'
                        alt='Username'
                        className='settings-page__hierarchy-avatar'
                      />
                      <span className='settings-page__hierarchy-name'>Jan Bąk3</span>
                    </div>
                    <ul>
                      <li>
                        <div>
                          <img
                            src='//placehold.it/300'
                            alt='Username'
                            className='settings-page__hierarchy-avatar'
                          />
                          <span className='settings-page__hierarchy-name'>Jan Bąk2</span>
                        </div>
                        <ul>
                          <li>
                            <div>
                              <img
                                src='//placehold.it/300'
                                alt='Username'
                                className='settings-page__hierarchy-avatar'
                              />
                              <span className='settings-page__hierarchy-name'>Jan Bąk3</span>
                            </div>
                          </li>
                          <li>
                            <div>
                              <img
                                src='//placehold.it/300'
                                alt='Username'
                                className='settings-page__hierarchy-avatar'
                              />
                              <span className='settings-page__hierarchy-name'>Jan Bąk3</span>
                            </div>
                          </li>
                          <li>
                            <div>
                              <img
                                src='//placehold.it/300'
                                alt='Username'
                                className='settings-page__hierarchy-avatar'
                              />
                              <span className='settings-page__hierarchy-name'>Jan Bąk3</span>
                            </div>
                          </li>
                          <li>
                            <div>
                              <img
                                src='//placehold.it/300'
                                alt='Username'
                                className='settings-page__hierarchy-avatar'
                              />
                              <span className='settings-page__hierarchy-name'>Jan Bąk3</span>
                            </div>
                          </li>
                        </ul>
                      </li>
                      <li>
                        <div>
                          <img
                            src='//placehold.it/300'
                            alt='Username'
                            className='settings-page__hierarchy-avatar'
                          />
                          <span className='settings-page__hierarchy-name'>Jan Bąk2</span>
                        </div>
                      </li>
                      <li>
                        <div>
                          <img
                            src='//placehold.it/300'
                            alt='Username'
                            className='settings-page__hierarchy-avatar'
                          />
                          <span className='settings-page__hierarchy-name'>Jan Bąk2</span>
                        </div>
                      </li>
                      <li>
                        <div>
                          <img
                            src='//placehold.it/300'
                            alt='Username'
                            className='settings-page__hierarchy-avatar'
                          />
                          <span className='settings-page__hierarchy-name'>Jan Bąk2</span>
                        </div>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <div>
                      <img
                        src='//placehold.it/300'
                        alt='Username'
                        className='settings-page__hierarchy-avatar'
                      />
                      <span className='settings-page__hierarchy-name'>Jan Bąk3</span>
                    </div>
                  </li>
                  <li>
                    <div>
                      <img
                        src='//placehold.it/300'
                        alt='Username'
                        className='settings-page__hierarchy-avatar'
                      />
                      <span className='settings-page__hierarchy-name'>Jan Bąk3</span>
                    </div>
                  </li>
                  <li>
                    <div>
                      <img
                        src='//placehold.it/300'
                        alt='Username'
                        className='settings-page__hierarchy-avatar'
                      />
                      <span className='settings-page__hierarchy-name'>Jan Bąk3</span>
                    </div>
                  </li>
                </ul>
              </li>
              <li>
                <div>
                  <img
                    src='//placehold.it/300'
                    alt='Username'
                    className='settings-page__hierarchy-avatar'
                  />
                  <span className='settings-page__hierarchy-name'>Jan Bąk2</span>
                </div>
              </li>
              <li>
                <div>
                  <img
                    src='//placehold.it/300'
                    alt='Username'
                    className='settings-page__hierarchy-avatar'
                  />
                  <span className='settings-page__hierarchy-name'>Jan Bąk2</span>
                </div>
              </li>
              <li>
                <div>
                  <img
                    src='//placehold.it/300'
                    alt='Username'
                    className='settings-page__hierarchy-avatar'
                  />
                  <span className='settings-page__hierarchy-name'>Jan Bąk2</span>
                </div>
              </li>
            </ul>
          </li>
        </ul>
      </Fragment>
    )
  }
}

CompanyHierarchyComponent.propTypes = {}

const CompanyHierarchy = connect(CompanyHierarchyComponent)

export default CompanyHierarchy
export { CompanyHierarchy }
