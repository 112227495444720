import PropTypes from 'prop-types'
import React from 'react'
import classnames from 'classnames'

class StateTextButton extends React.Component<any, any> {
  render() {
    const { onClick, children, active } = this.props
    const classes = classnames({
      'state-text-button': true,
      'state-text-button--is-active': active,
    })

    return (
      <div
        className={classes}
        onClick={() => {
          onClick(!active)
        }}
      >
        {children}
      </div>
    )
  }
}

StateTextButton.propTypes = {
  onClick: PropTypes.func,
  active: PropTypes.bool,
  children: PropTypes.node,
}

StateTextButton.defaultProps = {
  onClick: () => null,
  active: false,
}

export { StateTextButton }
export default { CheckboxButton: StateTextButton }
