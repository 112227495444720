import React from 'react'
import { compliance } from '../../store/app/compliance'
import { Rules } from '../Rules'
import { trans } from '../../trans'

class Compliance extends React.Component<any, any> {
  render() {
    const {
      compliance: {
        selectors: { rules },
      },
    } = this.props
    return (
      <Rules
        rules={rules}
        types={[
          'accounting_travel_expenses_valid',
          'accounting_travel_expenses_has_accounting_dimensions',
          'accounting_milleage_allowance_summary_amount',
          'acounting_milleage_allowance_summary_has_accounting_dimensions',
          'request_accounting_allowances_has_account_dimension',
        ]}
        message={trans('rules.accounting-cover-message')}
      />
    )
  }
}

Compliance = compliance()(Compliance)

export default Compliance
export { Compliance }
