import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { fromJS } from 'immutable'
import { Form as FormComponent } from './Form'
import { processAPIerrorResponseToFormErrors } from '../../../services/APIClient'
import { reduxForm, SubmissionError } from 'redux-form/immutable'
import { get, transform } from 'lodash'

export const submit = (values, dispatch, props) => {
  const {
    from: { isNew: isNewFrom },
    to: { isNew: isNewTo },
  } = props

  const {
    setAccessLumpSumPair,
    from: { id: from_id },
    to: { id: to_id },
    toggle,
  } = props

  values = values.toJS()

  const from = {
    id: from_id,
    destination: 'from',
    location: values['from_location'],
    date: values['from_date'],
    type: values['type'],
    isNew: isNewFrom,
    confirmed: true,
  }

  const to = {
    id: to_id,
    destination: 'to',
    location: values['to_location'],
    date: values['to_date'],
    type: values['type'],
    isNew: isNewTo,
    confirmed: true,
  }

  const { fromIndex, toIndex } = props

  return setAccessLumpSumPair(from, to, [fromIndex, toIndex]).then(
    () => {
      toggle()
    },
    (alerts) => {
      //custom error processing
      let sourceErrors = get(alerts, '0.errors', [])

      //sourceErrors is always two elements array, and each group errors appear on [0][0]
      let fieldsFromErrors = get(sourceErrors, '0.1', {})
      let fieldsToErrors = get(sourceErrors, '0.0', {})

      //then map errors field to real form field
      fieldsFromErrors = transform(fieldsFromErrors, (result, value, key) => {
        let splittedKey = key.split('.')
        result['from_' + splittedKey[0]] = value
      })

      fieldsToErrors = transform(fieldsToErrors, (result, value, key) => {
        let splittedKey = key.split('.')
        result['to_' + splittedKey[0]] = value
      })

      throw new SubmissionError({ ...fieldsFromErrors, ...fieldsToErrors })
    },
  )
}

const change = (values, dispatch, props) => {
  const {
    type,
    from_location,
    from_date,
    to_location,
    to_date,
    from_is_new,
    to_is_new,
    from_update_count,
    to_update_count,
  } = values.toJS()
  const { setStoreForAccessLumpSumsPair, fromIndex, toIndex, from: prevFrom, to: prevTo } = props

  const from = {
    ...prevFrom,
    type,
    location: from_location,
    date: from_date,
    isNew: from_is_new,
  }

  const to = {
    ...prevTo,
    type,
    location: to_location,
    date: to_date,
    isNew: to_is_new,
  }

  if (from['update_count'] === from_update_count && to['update_count'] === to_update_count) {
    setStoreForAccessLumpSumsPair(from, to, [fromIndex, toIndex])
  }
}

const FormContainer = compose(
  connect((state, { formName }) => ({
    form: formName,
  })),
  connect((state, props) => {
    const { from, to } = props

    const {
      type: from_type,
      location: from_location,
      date: from_date,
      isNew: from_is_new,
      update_count: from_update_count,
    } = from
    const {
      type: to_type,
      location: to_location,
      date: to_date,
      isNew: to_is_new,
      update_count: to_update_count,
    } = to

    let type = from_type
    if (from_type !== to_type) {
      type = ''
    }

    return {
      initialValues: fromJS({
        type,
        from_location,
        from_date,
        to_location,
        to_date,
        from_is_new,
        to_is_new,
        from_update_count,
        to_update_count,
      }),
    }
  }),
  reduxForm({
    onSubmit: submit,
    onChange: change,
    enableReinitialize: true,
    destroyOnUnmount: false,
  }),
)(FormComponent)

FormContainer.propTypes = {
  from: PropTypes.object.isRequired,
  to: PropTypes.object.isRequired,
  formName: PropTypes.string.isRequired,
  toggle: PropTypes.func.isRequired,
  fromIndex: PropTypes.number.isRequired,
  toIndex: PropTypes.number.isRequired,
}

export { FormContainer }
export default { FormContainer }
