import PropTypes from 'prop-types'
import React from 'react'
import classnames from 'classnames'
import Avatar from '../../Avatar/Avatar'
import Icon from '../IconComponent'
import { CheckboxField } from './CheckboxField'

export class TravelerSelectField extends React.Component<any, any> {
  constructor(props) {
    super(props)
    this.divRef = null
    this.state = {
      isOpen: false,
    }
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside)
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside)
  }

  handleClickOutside = (event) => {
    if (this.state.isOpen && this.divRef && !this.divRef.contains(event.target)) {
      this.close()
    }
  }

  open() {
    if (this.props.onOpen) this.props.onOpen()
    this.setState({ isOpen: true })
  }

  close() {
    if (this.props.onClose) this.props.onClose()
    this.setState({ isOpen: false })
  }

  toggleOpen(e) {
    const { isOpen } = this.state
    const { disabled } = this.props

    e.preventDefault()

    if (disabled) {
      return
    }

    if (isOpen) {
      this.close()
    } else {
      this.open()
    }
  }

  onTouchStart(e) {
    const { disabled } = this.props

    if (disabled) {
      return
    }

    if (e.target.classList.contains('react-select__dropdown-indicator')) {
      this.toggleOpen(e)
    }
  }

  onChange = (item) => {
    const { onChange } = this.props
    const newValues = this.value.includes(item)
      ? this.value.filter((v) => v !== item)
      : [...this.value, item]

    onChange(newValues)
  }

  get value() {
    const { value } = this.props

    return [...value]
  }

  renderOption(option) {
    return (
      <div key={option.slug} className='request-traveler-item'>
        <div className='request-traveler-avatar'>
          <Avatar user={option.user} />
        </div>

        <div className='request-traveler-name'>
          <strong>{option.user.full_name}</strong>
          <br />
          <span className='request-traveler-email'>{option.user.email.toLowerCase()}</span>
        </div>

        <div className='request-traveler-remove'>
          <CheckboxField
            value={option.slug}
            onChange={() => this.onChange(option.slug)}
            checked={this.value.includes(option.slug)}
          />
        </div>
      </div>
    )
  }

  render() {
    const { isOpen } = this.state
    const {
      name,
      className,
      options,
      placeholder,
      disabled,
      onFocus,
      labeltop,
      onBlur,
      onChange,
      nested,
      maxMenuHeight,
      ...rest
    } = this.props
    const classes = classnames({
      'dropdown-field': true,
      'traveler-select': true,
      'is-open': isOpen,
      [className]: true,
    })

    return (
      <div ref={(ref) => (this.divRef = ref)} className={classes}>
        <div
          className='dropdown-field__value'
          onClick={(e) => this.toggleOpen(e)}
          onTouchStart={(e) => this.onTouchStart(e)}
          onTouchEnd={(e) => this.toggleOpen(e)}
        >
          <div className='dropdown-field__value-label'>{this.value.length}</div>

          <div className='dropdown-field__value-change'>
            <Icon type='edit' gradient />
          </div>
        </div>

        <div className='dropdown-field__dropdown'>
          <div className='tooltip-content'>
            <div className='dropdown-field__options traveler-select__options'>
              {options.map((option) => this.renderOption(option))}
            </div>
          </div>
        </div>
      </div>
    )
  }
}

TravelerSelectField.propTypes = {
  onChange: PropTypes.func,
  name: PropTypes.string,
  className: PropTypes.string,
  options: PropTypes.array,
  disabled: PropTypes.bool,
  value: PropTypes.any,
}

TravelerSelectField.defaultProps = {}
