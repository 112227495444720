import { FORM_SET_ERRORS } from './constants'
import { fromJS } from 'immutable'
import _ from 'lodash'

export const MOUNT_POINT = 'login'

const initialState = fromJS({
  errors: {
    email: [],
    password: [],
  },
})

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case FORM_SET_ERRORS:
      let errors = _.merge({}, initialState.errors, action.errors)
      return state.set('errors', errors)
    default:
      return state
  }
}
