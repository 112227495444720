import React, { Fragment } from 'react'
import trans from '../../trans'
import { Col, Row } from '../../ui/Grid'
import { Ability } from '../RequestPageCommon/Ability/Ability'
import { DocumentCell, UploadCell } from '../DocumentCell'
import { RequestUnassignedDocumentsManager } from '../../containers/RequestUnassignedDocumentsManager'
import { RouteManager } from '../../containers/RouteManager'
import { Section } from '../ui/Section'
import { SectionHeader } from '../ui/SectionHeader'
import TripRequestHeaderSummary from './TripRequestHeaderSummary'
import RequestCollapsableSectionManager from '../RequestCollapsableSection/RequestCollapsableSectionManager'
import RequestCollapsableSectionHeader from '../RequestCollapsableSection/RequestCollapsableSectionHeader'
import RequestCollapsableSectionContent from '../RequestCollapsableSection/RequestCollapsableSectionContent'
import AddUnrequestedReservation from './AddUnrequestedReservation'
import { Request } from '../../types/request'

export interface TripRequestUnassignedDocumentsProps {
  request: Request
}

function TripRequestUnassignedDocuments({ request }: TripRequestUnassignedDocumentsProps) {
  const { abilities } = request
  const element = request.unrequestedElement

  return (
    <Section noBorder>
      <RequestCollapsableSectionManager>
        {({ isOpen, toggle }) => (
          <Fragment>
            <RequestCollapsableSectionHeader isOpen={isOpen} toggle={toggle}>
              <SectionHeader caption={trans('request.header-unassigned-documents')} />

              {element && (
                <TripRequestHeaderSummary
                  currency={element.amount_currency}
                  accounted_amount={element.accounted_amount}
                  requested_amount={element.amount}
                />
              )}
            </RequestCollapsableSectionHeader>

            <RequestCollapsableSectionContent isOpen={isOpen}>
              <RouteManager>
                {({ push, getRouteByName }) => (
                  <RequestUnassignedDocumentsManager
                    request={request}
                    elements={request['combinedTravelElements']}
                    documents={request['unassignedDocuments']}
                    listName={'request-travel-unassigned'}
                  >
                    {({
                      documentsList,
                      uploadDocument,
                      instanceCurrency,
                      deleteDocument,
                      currentUser,
                      hasForm,
                    }) => {
                      return (
                        <Row style={{ marginBottom: '-15px' }}>
                          <Ability ability={['addAndDeleteDocuments']} comparator='nor'>
                            {!documentsList.length && trans('document.there-are-no-documents')}
                          </Ability>

                          {!hasForm && (
                            <>
                              {documentsList.map((document) => {
                                return (
                                  <DocumentCell
                                    width='217px'
                                    document={document}
                                    key={`document-cell-${document['id']}`}
                                    push={push}
                                    getRouteByName={getRouteByName}
                                    instanceCurrency={instanceCurrency}
                                    deleteDocument={
                                      abilities['addAndDeleteDocuments'] ? deleteDocument : null
                                    }
                                    request={request}
                                    currentUser={currentUser}
                                  />
                                )
                              })}

                              <Ability ability={['addAndDeleteDocuments']}>
                                <Col
                                  width='217px'
                                  style={{
                                    display: 'flex',
                                    paddingTop: '15px',
                                    paddingBottom: '15px',
                                  }}
                                >
                                  <UploadCell uploadDocument={uploadDocument} request={request} />
                                </Col>
                              </Ability>
                            </>
                          )}

                          <Ability ability={['addUnrequestedReservation']}>
                            <AddUnrequestedReservation hasForm={hasForm} request={request} />
                          </Ability>
                        </Row>
                      )
                    }}
                  </RequestUnassignedDocumentsManager>
                )}
              </RouteManager>
            </RequestCollapsableSectionContent>
          </Fragment>
        )}
      </RequestCollapsableSectionManager>
    </Section>
  )
}

export { TripRequestUnassignedDocuments }
export default { TripRequestUnassignedDocuments }
