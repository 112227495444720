import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import { getCurrency as getInstanceCurrency } from '../../store/app/instance'
import APIClient from '../../services/APIClient'
import { compose } from 'redux'
import { documents } from '../../store/app/document-list'

class RequestDocumentsManagerBase extends React.Component<any, any> {
  uploadDocument = (request, files) => {
    const { upload } = this.props

    const promises = []

    for (let file of files) {
      let u = upload(file, () => {
        return APIClient.uploadDocument(request.slug, file, false, null)
      })
      promises.push(u)
    }

    return new Promise((resolve) => {
      Promise.all(promises)
        .then(() => {
          resolve()
        })
        .finally()
    })
  }

  render() {
    const { children, instanceCurrency, request, currentUser, documentsList } = this.props

    const renderProps = {
      instanceCurrency,
      request,
      uploadDocument: this.uploadDocument,
      deleteDocument: this.props.delete,
      currentUser,
      documentsList,
    }

    return children(renderProps)
  }
}

RequestDocumentsManagerBase.propTypes = {
  request: PropTypes.object.isRequired,
  documents: PropTypes.array.isRequired,
}

const mapStateToProps = (state) => ({
  instanceCurrency: getInstanceCurrency(state),
  currentUser: state.get('global').get('currentUser'),
})

const withConnect = connect(mapStateToProps)
const withDocuments = documents()

const RequestDocumentsManager = compose(withDocuments, withConnect)(RequestDocumentsManagerBase)

export { RequestDocumentsManager }
export default { RequestDocumentsManager }
