import { createSlice } from '@reduxjs/toolkit'
import { createSelector } from 'reselect'
import APIClient from '../../../services/APIClient'

export const DOCUMENT_MOUNT_POINT = 'document'

interface InitialState {
  document: Document
  isLoading: boolean
  error: any
}

const getInitialState = (): InitialState => ({
  document: null,
  isLoading: false,
  error: null,
})

const slice = createSlice({
  name: DOCUMENT_MOUNT_POINT,
  initialState: getInitialState(),
  reducers: {
    reset(state) {
      state = getInitialState()
    },
    setDocument(state, action) {
      const { data } = action.payload

      state.document = data
    },
    startLoading(state) {
      state.isLoading = true
      state.error = null
    },
    setLoadingSuccess(state) {
      state.isLoading = false
      state.error = null
    },
    setLoadingFailed(state, action) {
      state.isLoading = false
      state.error = action.payload
    },
  },
})

const { reset, setDocument, startLoading, setLoadingFailed, setLoadingSuccess } = slice.actions

const getState = (state) => state.get(DOCUMENT_MOUNT_POINT)

export const getDocument = createSelector(getState, (state) => state.document)

export const fetchDocument = (id: number) => async (dispatch) => {
  dispatch(startLoading())

  try {
    const { data } = await APIClient.getDocumentForSettlement(id)

    dispatch(setDocument({ data }))
    dispatch(setLoadingSuccess())
  } catch (err) {
    dispatch(setLoadingFailed(err))
  }
}

export default slice.reducer
