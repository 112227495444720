import React, { Fragment } from 'react'
import { trans } from '../../trans'
import { SectionHeader } from '../ui/SectionHeader'
import { Section } from '../ui/Section'
import { RouteManager } from '../../containers/RouteManager'
import { MileageAllowanceCellNonDelegation } from '../../components/RequestPageCommon/MileageAllowanceCellNonDelegation'
import { Row, Col } from '../../ui/Grid'
import TripRequestHeaderSummary from './TripRequestHeaderSummary'
import RequestCollapsableSectionManager from '../RequestCollapsableSection/RequestCollapsableSectionManager'
import RequestCollapsableSectionContent from '../RequestCollapsableSection/RequestCollapsableSectionContent'
import RequestCollapsableSectionHeader from '../RequestCollapsableSection/RequestCollapsableSectionHeader'

class TripRequestLumpSum extends React.Component<any, any> {
  render() {
    const caption = trans('request.lump-sum-header')
    const { request, instanceCurrency } = this.props

    return (
      <Section>
        <RequestCollapsableSectionManager>
          {({ isOpen, toggle }) => (
            <Fragment>
              <RequestCollapsableSectionHeader isOpen={isOpen} toggle={toggle}>
                <SectionHeader caption={caption} />

                {request.lumpSumsDeclaration && (
                  <TripRequestHeaderSummary
                    currency={instanceCurrency}
                    accounted_amount={request.lumpSumsDeclaration.settled_lump_sums}
                    requested_amount={request.lumpSumsDeclaration.requested_lump_sums}
                  />
                )}
              </RequestCollapsableSectionHeader>

              <RequestCollapsableSectionContent isOpen={isOpen}>
                <div className='lump-sum-section__summary-row'>
                  <Row>
                    <RouteManager>
                      {({ push, getRouteByName }) => (
                        <MileageAllowanceCellNonDelegation
                          width='217px'
                          request={request}
                          push={push}
                          getRouteByName={getRouteByName}
                        />
                      )}
                    </RouteManager>
                  </Row>
                </div>
              </RequestCollapsableSectionContent>
            </Fragment>
          )}
        </RequestCollapsableSectionManager>
      </Section>
    )
  }
}

export { TripRequestLumpSum }
export default { TripRequestLumpSum }
