import React from 'react'
import { bindActionCreators } from 'redux'
import { connect as reduxConnect } from 'react-redux'
import { isDelegation } from '../../store/app/non-delegation/selectors'
import { reset, set, setNonDelegation } from '../../store/app/non-delegation/creators'
import { ProviderContext } from './context'
import { isTripDidNotHavePlace } from '../../store/app/trip-did-not-have-place/selectors'

const enhanced = (Component) => {
  const NonDelegationConnect = (props) => <Component<any, any> {...props} />

  const mapStateToProps = (state) => {
    return {
      isDelegation: isDelegation(state),
      isTripDidNotHavePlace: isTripDidNotHavePlace(state),
    }
  }

  const mapDispatchToProps = (dispatch, props) => {
    const { context } = props

    return bindActionCreators(
      {
        reset,
        set,
        setNonDelegation: setNonDelegation(context.request),
      },
      dispatch,
    )
  }

  const mergedProps = (selectors, actions, own) => {
    return {
      ...own,
      nonDelegation: {
        selectors,
        actions,
        ...own.nonDelegation,
      },
      tripDidNotHavePlace: {
        selectors,
        actions,
        ...own.tripDidNotHavePlace,
      },
    }
  }

  return reduxConnect(mapStateToProps, mapDispatchToProps, mergedProps)(NonDelegationConnect)
}

const connect = (Component) => {
  const Enhanced = enhanced(Component)
  return (props) => (
    <ProviderContext.Consumer>
      {(context) => <Enhanced context={context} {...props} />}
    </ProviderContext.Consumer>
  )
}

export default connect
export { connect }
