import PropTypes from 'prop-types'
import React from 'react'
import { STATE_SETTLEMENT } from '../../constants/request'
import { SectionHeader } from '../ui/SectionHeader'
import { Section } from '../ui/Section'
import trans from '../../trans'
import { AccordionTableRenderer } from './AccordionTableRenderer'
import { isArray } from 'lodash'
import createTableDataTrip from './create-table-data-trip'
import classNames from 'classnames'

class RequestSummary extends React.PureComponent<any, any> {
  constructor(props) {
    super(props)
    this.state = {
      windowWidth: null,
    }
  }

  componentDidMount() {
    this.updateWidth()
    window.addEventListener('resize', this.updateWidth)
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWidth)
  }

  updateWidth = () => {
    if (this.state.windowWidth !== window.innerWidth) {
      this.setState({ windowWidth: window.innerWidth })
    }
  }

  getDataPrepareMethod() {
    const { request } = this.props
    return createTableDataTrip(request)
  }

  prepareData() {
    const { request, summary, showLessIcons = false } = this.props
    const {
      grid = {
        contentCell: 5,
        requestedCell: request.type === 'expense' ? 3 : 2,
        iconsCell: 2,
        settledCell: request.type === 'expense' ? 2 : 3,
      },
    } = this.props
    const method = this.getDataPrepareMethod()
    const windowWidth = this.state.windowWidth
    return method({ ...summary, costs: request.costs, request, grid, showLessIcons, windowWidth })
  }

  render() {
    const data = this.prepareData()
    const { noExpand, hideNoResultsLabel = false, request, className } = this.props
    const classes = classNames(className, 'basic-summary')

    let caption = trans('request-summary.request-summary')
    if (request.state === STATE_SETTLEMENT) {
      caption = trans('request-summary.settlement-summary')
    }

    return (
      <Section className={classes} noBorder>
        <SectionHeader caption={caption} />
        <AccordionTableRenderer
          data={data}
          noExpand={noExpand}
          hideNoResultsLabel={hideNoResultsLabel}
        />
      </Section>
    )
  }
}

RequestSummary.propTypes = {
  summary: PropTypes.object,
  request: PropTypes.object.isRequired,
  noExpand: PropTypes.bool,
  grid: PropTypes.object,
  className: PropTypes.string,
  showLessIcons: PropTypes.bool,
  hideNoResultsLabel: PropTypes.bool,
}

export { RequestSummary }
export default { RequestSummary }
