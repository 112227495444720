import PropTypes from 'prop-types'
import React from 'react'
import APIClient from '../../services/APIClient'
import { isObject, isUndefined, isNil } from 'lodash'
import moment from 'moment'
import { createStructuredSelector } from 'reselect'
import numeral from 'numeral'
import ExchangeSummary from '../../components/ExchangeSummary'
import { toJS } from 'immutable'
import { connect } from 'react-redux'
import { bindActionCreators, compose } from 'redux'
import { connect as withHierarchy } from '../ExternalCompanyHierarchy'
import { getCurrency as getInstanceCurrency } from '../../store/app/instance'

class ExchangeSummaryContainerComponent extends React.PureComponent<any, any> {
  constructor(props) {
    super(props)

    this.state = {
      rates: [],
      currenciesWithoutNominal: this.preparePartials(this.props.partials),
      exchangeErrors: [],
      fetching: false,
      lastCurrencies: null,
      sum: numeral(0),
    }
  }

  componentDidMount() {
    this.setState({ fetching: true })
    const currencies = this.state.currenciesWithoutNominal.map((item, key) => key).join(',')
    this.updateRates(currencies)
  }

  componentDidUpdate(prevProps) {
    const equal = JSON.stringify(prevProps.partials) === JSON.stringify(this.props.partials)
    if (!equal) {
      this.setState({ fetching: true })
      const currencies = this.preparePartials(this.props.partials)
        .map((item, key) => key)
        .join(',')
      this.updateRates(currencies)
    }
  }

  updateRates(currencies) {
    if (currencies === this.state.lastCurrencies) {
      this.calculateNominalSum(this.state.rates)
      this.setState({ fetching: false })
      return
    }

    if (currencies === '') {
      this.setState({ rates: [], exchangeErrors: [], fetching: false, lastCurrencies: currencies })
      this.calculateNominalSum([])
      return
    }

    // sort currencies to ensure that they are cached regardless of order
    currencies = currencies.split(',').sort().join(',')

    APIClient.getExchangeRate(this.props.request.slug, currencies).then((response) => {
      this.setState({
        rates: response.data,
        exchangeErrors: [],
        fetching: false,
        lastCurrencies: currencies,
      })
      this.calculateNominalSum(response.data)
    })
  }

  preparePartials(currencies) {
    const { instanceCurrency } = this.props

    if (!currencies || this.props.fetching) {
      return []
    }
    return currencies.filter((item, key) => !isNil(item.value()) && key !== instanceCurrency)
  }

  calculateNominalSum(rates) {
    const { instanceCurrency } = this.props

    if (!this.props.partials) {
      return null
    }
    const values = this.props.partials
      .filter((item) => !isUndefined(item))
      .map((item) => numeral(item))
    let sum = numeral(0)

    values.forEach((item, key) => {
      if (item.value() !== null) {
        if (key === instanceCurrency) {
          sum = sum.add(item.value())
          return
        }

        sum = sum.add(this.exchange(item.value(), key, rates))
      }
    })

    this.props.onSumChange(sum)

    this.setState({ sum })
  }

  exchange(input, currencyCode, rates) {
    const rate = rates.find((item) => item.currency.code === currencyCode)

    if (!rate) {
      return input
    }

    return numeral(input).multiply(rate.rate).value()
  }

  render() {
    const { instanceCurrency } = this.props

    if (!this.props.showSummary) {
      return null
    }

    const renderProps = {
      partials: this.preparePartials(this.props.partials),
      calculatedValue: this.state.sum,
      isLoading: this.state.fetching,
      currency: instanceCurrency,
    }

    return <ExchangeSummary {...renderProps} />
  }
}

ExchangeSummaryContainerComponent.propTypes = {
  partials: PropTypes.object.isRequired,
  onSumChange: PropTypes.func,
  showSummary: PropTypes.bool,
}

ExchangeSummaryContainerComponent.defaultProps = {
  showSummary: true,
  onSumChange: (sum) => {},
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({}, dispatch)
}

const mapStateToProps = (state, ownProps) => {
  return {
    instanceCurrency: getInstanceCurrency(state),
  }
}

const ExchangeSummaryContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(ExchangeSummaryContainerComponent)

export default ExchangeSummaryContainer
