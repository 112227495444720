import { MODULE_MOUNT_POINT } from './index'
import { get } from 'lodash'

//selectors
export const getState = (state) => {
  return state.get(MODULE_MOUNT_POINT)
}

export const getEmployees = (state) => {
  return get(getState(state), 'employees', [])
}

export const isFetching = (state) => {
  return get(getState(state), 'isFetching', false)
}

export const getMe = (state) => {
  const findMe = (employees) => {
    let me = null

    for (let employee of employees) {
      if (employee.me) {
        me = employee
        break
      }

      if (employee.subordinates.length) {
        me = findMe(employee.subordinates)
      }

      if (me) {
        break
      }
    }

    return me
  }

  const employees = getEmployees(state)
  return findMe(employees)
}

export const getSuperior = (state) => {
  const findSuperior = (parent = null, employees) => {
    let superior = null

    for (let employee of employees) {
      if (employee.me) {
        superior = parent
        break
      }

      if (employee.subordinates.length) {
        superior = findSuperior(employee, employee.subordinates)
      }

      if (superior) {
        return superior
      }
    }

    return superior
  }

  const employees = getEmployees(state)
  return findSuperior(null, employees)
}

export const isInitialized = (state) => {
  return get(getState(state), 'isInitialized', false)
}
