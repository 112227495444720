import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { get } from 'lodash'
import APIClient from '../../../services/APIClient'

export const MODULE_MOUNT_POINT = 'last-requests'

const RESET = MODULE_MOUNT_POINT + '::reset'
const SET = MODULE_MOUNT_POINT + '::set'
const DESTROY = MODULE_MOUNT_POINT + '::destroy'

const getInitialState = () => {
  return {
    items: [],
    loading: true,
  }
}

// actions Creators
const reset = () => (dispatch) => {
  dispatch({
    type: RESET,
  })
}

const destroy = () => (dispatch) => {
  dispatch({
    type: DESTROY,
  })
}

const set = (items) => (dispatch) => {
  dispatch({
    type: SET,
    payload: items,
  })
}

const fetch = () => (dispatch) => {
  APIClient.getLastRequests().then((response) => {
    dispatch(set(response.data))
  })
}

const createSimilar = (similar) => (dispatch) => {
  const { request, date } = similar
  return APIClient.createSimilar(request, date)
}

//actions
const resetAction = () => {
  return getInitialState()
}

const destroyAction = (state) => {
  delete state[MODULE_MOUNT_POINT]
  return state
}

const setAction = (state, payload) => {
  return {
    ...state,
    items: payload,
    loading: false,
  }
}

const actions = {
  [RESET]: resetAction,
  [SET]: setAction,
  [DESTROY]: destroyAction,
}

//selectors
const getState = (state) => {
  return state.get(MODULE_MOUNT_POINT)
}

const getItems = (state) => {
  return get(getState(state), 'items', [])
}

const isLoading = (state) => {
  return get(getState(state), 'loading', true)
}

const getSimilarDate = (state) => {
  return get(getState(state), 'similarDate')
}

export const reducer = (state = getInitialState(), action) => {
  if (actions.hasOwnProperty(action.type)) {
    return actions[action.type](state, action.payload)
  }
  return state
}

const lastRequests =
  (resetOnMount = false, fetchOnMount = false, destroyOnUnmount = false) =>
  (Component) => {
    class LastRequests extends React.Component<any, any> {
      constructor(props) {
        super(props)
      }

      componentDidMount() {
        const { lastRequests } = this.props
        if (resetOnMount) {
          lastRequests.actions.reset()
        }

        if (fetchOnMount) {
          lastRequests.actions.fetch()
        }
      }

      componentWillUnmount() {
        const { lastRequests } = this.props
        if (destroyOnUnmount) {
          lastRequests.actions.destroy()
        }
      }

      render() {
        return <Component<any, any> {...this.props} />
      }
    }

    const mapStateToProps = (state) => ({
      items: getItems(state),
      isLoading: isLoading(state),
    })

    const mapDispatchToProps = (dispatch) => {
      return bindActionCreators(
        {
          reset,
          set,
          destroy,
          fetch,
          createSimilar,
        },
        dispatch,
      )
    }

    const mergedProps = (selectors, actions, own) => {
      return {
        ...own,
        lastRequests: {
          selectors,
          actions,
        },
      }
    }

    return connect(mapStateToProps, mapDispatchToProps, mergedProps)(LastRequests)
  }

export { lastRequests }
export default lastRequests
