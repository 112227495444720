import React, { Fragment } from 'react'
import { connect } from '../../containers/NonDelegation'
import Icon from '../ui/IconComponent'
import trans from '../../trans'
import { ComponentOverlay } from '../ui/ComponentOverlay'

const NonDelegationOverlay = connect((props) => {
  const {
    nonDelegation: {
      selectors: { isDelegation },
    },
  } = props

  return (
    <div className='has-overlay'>
      <Fragment>
        {props.children}

        {!isDelegation && (
          <ComponentOverlay>
            <div className='component-overlay__message component-overlay__message--unsettled-lump-sums'>
              <Icon className='icon-info-grey component-overlay__message-icon' type='info' />
              <span>{trans('request.non-delegation-overlay-message')}</span>
            </div>
          </ComponentOverlay>
        )}
      </Fragment>
    </div>
  )
})

export default NonDelegationOverlay
export { NonDelegationOverlay }
