import React from 'react'
import { reduxForm, SubmissionError } from 'redux-form/immutable'
import { connect } from 'react-redux'
import { fromJS } from 'immutable'
import { Accommodation as Form } from '../Forms'
import { bindActionCreators, compose } from 'redux'
import { processAPIerrorResponseToFormErrors } from '../../../../services/APIClient'
import { prepareRequestDates } from '../../../../utils/prepareRequestDates'
import { getFormValues } from '../../../../utils/forms'
import { Factory as ElementFactory } from '../../../../models/timeline/index'
import { DateSuggester } from '../../../../store/app/trip-timeline/services/date-suggester'
import { AccomodationDateSuggester } from '../../../../store/app/trip-timeline/services/accomodation-date-suggester'
import { getCurrency } from '../../../../store/app/instance'
import { isEmpty } from 'lodash'
import moment from 'moment'
import { config } from '../../../../config'
import { getRadius, setCoordinates } from '../../../../store/app/range-map'
import {
  getSelectedOffer,
  isOptionSelected,
  selector as hotelsBookingSelector,
} from '../../../../store/app/hotels-booking/selectors'
import {
  resignFromSearch,
  search,
  selectOfferOption,
  setFacility,
  setSearch,
} from '../../../../store/app/hotels-booking/creators'
import { AccommodationLocationSuggester } from '../../../../store/app/trip-timeline/services/accommodation-location-suggester'
import { getRequestTravelers } from '../../../../store/app/request-traveler'
import { changeElementType, getElements } from '../../../../store/app/trip-timeline'
import store from '../../../../store'
import {
  TRAVEL_ACCOMMODATION_PROVIDED,
  TRAVEL_ACCOMODATION,
  TRAVEL_ACCOMODATION_SUGGESTION,
  TRAVEL_BUS_TRIP,
  TRAVEL_COMPANY_CAR_TRIP,
  TRAVEL_EMPTY,
  TRAVEL_FERRY,
  TRAVEL_PLANE_TRIP,
  TRAVEL_PRIVATE_ACCOMODATION,
  TRAVEL_PRIVATE_CAR_TRIP,
  TRAVEL_RENTED_CAR_TRIP,
  TRAVEL_TARGET_POINT,
  TRAVEL_TRAIN_TRIP,
} from '../../../../constants/travel'

class Accommodation extends React.Component<any, any> {
  render() {
    const { ...props } = this.props

    if (!this.props.initialized) {
      return null
    }

    return <Form {...props} />
  }
}

const saveHotelOfferOption = (props, element, dispatch) => {
  if (props.searchUuid && props.selectedOffer) {
    const option = props.selectedOffer.options.find((option) => option.chosen === true)
    if (option) {
      dispatch(
        selectOfferOption(props.accommodation.key)(props.request, {
          search_uuid: props.searchUuid,
          offer: props.selectedOffer,
          option: option,
          request_element: element,
        }),
      )
    }
  }
}

export const submit = (values, dispatch, props) => {
  const { onSave, request, change } = props

  values = prepareRequestDates(values)

  if (
    values.extraServicesChecked === true &&
    values.extraServicesText &&
    values.extraServicesText.length > 0
  ) {
    values.extra_services = values.extraServicesText
  }

  return onSave(request, values, props.element).then(
    (response) => {
      dispatch(change('isOpen', false))
      saveHotelOfferOption(props, response, dispatch)
    },
    (alerts) => {
      throw new SubmissionError(processAPIerrorResponseToFormErrors(alerts))
    },
  )
}

export const change = (values, dispatch, props) => {
  const { change } = props

  if (values.get('departure_at') < values.get('arrival_at')) {
    dispatch(change('departure_at', values.get('arrival_at')))
  }

  const location = values.toJS().location
  if (location) {
    dispatch(
      setCoordinates(props.accommodation.key)({
        lat: location.lat,
        lng: location.long,
      }),
    )
  }
}

export const getDaysOfStay = (arrival, departure) => {
  let daysOfStay = 0

  if (arrival.isValid() && departure.isValid()) {
    daysOfStay += departure.endOf('day').diff(arrival.startOf('day'), 'days')
  }

  return daysOfStay
}

export const calculateEndDate = (state, initialAccommodation, dateSuggester, location) => {
  let startDate = initialAccommodation.draft
    ? dateSuggester.suggestStartDate()
    : initialAccommodation.getStartDate()
  let endDate = initialAccommodation.draft
    ? dateSuggester.suggestEndDate()
    : initialAccommodation.getEndDate()

  let endDatePlusDay = moment(endDate, config.apiDateTimeFormat).add(1, 'days')
  let elements = getElements(store.getState())
  let elementsWithoutTravelingItems = elements.filter((element) => {
    return [
      TRAVEL_ACCOMODATION_SUGGESTION,
      TRAVEL_ACCOMODATION,
      TRAVEL_ACCOMMODATION_PROVIDED,
      TRAVEL_PRIVATE_ACCOMODATION,
      TRAVEL_TARGET_POINT,
      TRAVEL_EMPTY,
    ].includes(element.type)
  })

  if (initialAccommodation.draft && elementsWithoutTravelingItems.length === elements.length) {
    endDate = moment(startDate, config.apiDateTimeFormat).add(1, 'days')
  } else if (
    moment(endDate).diff(moment(startDate), 'days') === 0 &&
    (dateSuggester.suggestMaxDate() === undefined ||
      endDatePlusDay < moment(dateSuggester.suggestMaxDate()))
  ) {
    endDate = moment(endDatePlusDay).format(config.apiDateTimeFormat)
  } else if (initialAccommodation.draft && !endDate) {
    let accomodationDateSuggester = new AccomodationDateSuggester(state)

    endDate = accomodationDateSuggester.predictEndDate(initialAccommodation, location)
  }

  return endDate
}

const withForm = reduxForm({
  onSubmit: submit,
  onChange: change,
  destroyOnUnmount: false,
})

const mapStateToProps = (state, props) => {
  const { request, element, currencies } = props

  const initialAccommodation = ElementFactory.create(element)
  const { key, draft } = initialAccommodation

  const currentValues = getFormValues(key, state)
  const currentAccommodation = !isEmpty(currentValues)
    ? ElementFactory.create(currentValues)
    : initialAccommodation

  const travelers = getRequestTravelers(state)
  const requestTravelers = travelers.map((traveler) => traveler.slug)
  const rooms = travelers.map(() => 1)

  const dateSuggester = new DateSuggester(state, currentAccommodation)
  const locationSuggester = new AccommodationLocationSuggester(state, currentAccommodation)

  const location = draft
    ? locationSuggester.suggestLocation()
    : initialAccommodation.getStartLocation()

  const startDate = draft ? dateSuggester.suggestStartDate() : currentAccommodation.getStartDate()
  const endDate = calculateEndDate(state, currentAccommodation, dateSuggester, location)
  const daysOfStay = getDaysOfStay(
    moment(currentValues.arrival_at),
    moment(currentValues.departure_at),
  )
  const instanceCurrency = getCurrency(state)
  const getHotelsBookingField = hotelsBookingSelector(key)(state)

  return {
    initialValues: fromJS({
      location: location,
      uuid: initialAccommodation.uuid,
      breakfast: initialAccommodation.breakfast,
      arrival_at: startDate,
      departure_at: endDate,
      wifi: initialAccommodation.wifi,
      amount: initialAccommodation.amount,
      amount_currency: !draft ? initialAccommodation.amount_currency : getCurrency(state),
      id: initialAccommodation.id,
      type: initialAccommodation.type,
      converted_amount: initialAccommodation.converted_amount,
      calculated_amount_currency: initialAccommodation.calculated_amount_currency
        ? initialAccommodation.calculated_amount_currency
        : instanceCurrency,
      acceptance_source: initialAccommodation.acceptance_source,
      draft: initialAccommodation.draft,
      virtual: initialAccommodation.virtual,
      standard: initialAccommodation.standard,
      searcher_disabled: initialAccommodation.searcher_disabled,
      request_travelers: initialAccommodation.request_travelers.length
        ? initialAccommodation.request_travelers
        : requestTravelers,
      rooms: initialAccommodation.rooms.length ? initialAccommodation.rooms.length : rooms,
      extraServicesChecked: initialAccommodation.extraServicesChecked,
      extraServicesText: initialAccommodation.extraServicesText,
      parking: initialAccommodation.parking,
    }),
    form: key,
    request,
    currencies,
    daysOfStay,
    data: currentValues,
    accommodation: currentAccommodation,
    minDate: dateSuggester.suggestMinDate(),
    maxDate: dateSuggester.suggestMaxDate(),
    maxStartDate: dateSuggester.suggestMaxStartDate(),
    instanceCurrency,
    searchUuid: getHotelsBookingField('uuid'),
    selectedOffer: getSelectedOffer(key)(state),
    isSearchEnabled: getHotelsBookingField('enabled'),
    searchQuery: currentValues,
    isOptionSelected: isOptionSelected(key)(state),
    radius: getRadius(key)(state),
    isOpen: props.isOpen,
    travelers,
  }
}

const mapDispatchToProps = (dispatch, props) => {
  const { element, request } = props
  const initialAccommodation = ElementFactory.create(element)
  return bindActionCreators(
    {
      resignFromSearch: resignFromSearch(initialAccommodation.key)(request, initialAccommodation),
      search: (searchQuery) => search(initialAccommodation.key)(request, searchQuery),
      setSearch: (status) => setSearch(initialAccommodation.key)(status),
      onChangeType: (element, type) => changeElementType(element, type),
      setFacility: setFacility(initialAccommodation.key),
    },
    dispatch,
  )
}

const withConnect = connect(mapStateToProps, mapDispatchToProps)

Accommodation = compose(withConnect, withForm)(Accommodation)

export { Accommodation }
export default { Accommodation }
