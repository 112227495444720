import React from 'react'
import { isArray } from 'lodash'

const Error = ({ errors }) => {
  if (isArray(errors)) {
    return (
      <div className='form-group__error' style={{ textAlign: 'left' }}>
        {errors.map((error) => (
          <p>{error}</p>
        ))}
      </div>
    )
  }

  // TODO: check if <p> tag is necesarry here
  return (
    <div className='form-group__error' style={{ textAlign: 'left' }}>
      <p>{errors}</p>
    </div>
  )
}

Error.defaultProps = {
  errors: [],
}

export default Error
export { Error }
