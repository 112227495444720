import React, { Fragment } from 'react'
import Steps from '../Steps'
import classNames from 'classnames'
import { Loader } from '../../ui/LoadingOverlay/Loader'
import { NationalTripForm } from '../../../containers/BorderCrossings/NationalTripForm'
import EdgePointForm from '../../../containers/BorderCrossings/EdgePointForm'
import trans from '../../../trans'
import { TimelineAdd } from '../../Timeline'
import Button from '../../ui/ButtonComponent'
import { borderCrossings } from '../../../store/app/border-crossings'
import { targetPoints } from '../../../store/app/target-points'
import { CrossingForm } from '../../../containers/BorderCrossings/CrossingForm'
import TargetPointsArea from '../TargetPointsArea'
import DestinationForm from '../../../containers/BorderCrossings/DestinationForm'
import { destinations } from '../../../store/app/destinations'
import { compose } from 'redux'
import { isDirty } from 'redux-form'
import { connect } from 'react-redux'
import APIClient from '../../../services/APIClient'
import { ConfirmationDialog } from './ConfirmationDialog'

class Crossings extends React.Component<any, any> {
  state = {
    dialogOpen: false,
  }

  isNationalTrip() {
    const {
      crossings: {
        selectors: { nationalTrip },
      },
    } = this.props
    return nationalTrip
  }

  onDragEnd = (result) => {
    // dropped outside the list
    if (!result.destination) {
      return
    }
    const {
      crossings: {
        actions: { reorder },
      },
    } = this.props
    reorder(result.source.index, result.destination.index)
  }

  renderTargetPoints = () => {
    const { request, isReadOnly, targetPoints } = this.props

    if (!targetPoints.selectors.targetPoints.length) {
      return
    }

    return (
      <Fragment>
        {targetPoints.selectors.targetPoints
          .filter((c) => c.id !== 'trip-start' && c.id !== 'trip-end')
          .map((targetPoint, index) => {
            const key = `request-${request.slug}-destination-${targetPoint.cid}`
            return [
              !isReadOnly && (
                <TimelineAdd
                  key={`crossing-add-${index}`}
                  className='timeline--add'
                  icon='plus'
                  gradient='true'
                  onClick={() => targetPoints.actions.insert(index)}
                >
                  {trans('global.add')}
                </TimelineAdd>
              ),

              <DestinationForm
                request={request}
                form={key}
                key={key}
                isReadOnly={isReadOnly}
                point={targetPoint}
                targetPoints={targetPoints}
              />,
            ]
          })}
      </Fragment>
    )
  }

  renderCrossings() {
    const { crossings, request, isReadOnly } = this.props
    return (
      <Fragment>
        {crossings.selectors.crossings
          .filter((c) => c.id !== 'trip-start' && c.id !== 'trip-end')
          .map((crossing, index) => {
            const key = `request-${request.slug}-crossing-${crossing.cid}`
            return [
              !isReadOnly && (
                <TimelineAdd
                  key={`crossing-add-${index}`}
                  className='timeline--add'
                  icon='plus'
                  gradient='true'
                  onClick={() => crossings.actions.insert(index)}
                >
                  {trans('global.add')}
                </TimelineAdd>
              ),
              <CrossingForm request={request} form={key} key={key} crossing={crossing} />,
            ]
          })}
      </Fragment>
    )
  }

  renderNationalTrip() {
    const {
      request,
      isReadOnly,
      targetPoints: {
        actions: { insert },
        selectors: { targetPoints },
      },
    } = this.props

    return (
      <Fragment>
        {this.renderTargetPoints()}

        {!isReadOnly && request['abilities']['settle'] ? (
          <TimelineAdd
            icon='plus'
            gradient='true'
            className='border-crossings__timeline-add-button'
            onClick={() => insert(targetPoints.length - 2)}
          >
            <div className='timeline__header timeline__header--title timeline__header--padding'>
              {trans('deductions-widget.add-target')}
            </div>
          </TimelineAdd>
        ) : null}
      </Fragment>
    )
  }

  renderBorderCrossingTrip() {
    const {
      request,
      isReadOnly,
      crossings: {
        actions: { insert },
        selectors: { crossings },
      },
    } = this.props

    return (
      <Fragment>
        {this.renderCrossings()}

        {!isReadOnly && request['abilities']['settle'] ? (
          <TimelineAdd
            icon='plus'
            gradient='true'
            className='border-crossings__timeline-add-button'
            onClick={() => insert(crossings.length - 2)}
          >
            <div className='timeline__header timeline__header--title timeline__header--padding'>
              {trans('deductions-widget.add-crossing')}
            </div>
          </TimelineAdd>
        ) : null}
      </Fragment>
    )
  }

  renderTimeline() {
    const {
      request,
      crossings: {
        selectors: { crossings },
      },
    } = this.props

    if (!crossings.length) {
      return null
    }

    const classnames = classNames({
      'timeline-container': true,
      'timeline-container--border-crossings': true,
      'timeline-container--national': this.isNationalTrip(),
      'timeline-container--aboard': !this.isNationalTrip(),
    })

    const begin = crossings.findIndex((crossing) => crossing.cid === 'trip-start')
    const end = crossings.findIndex((crossing) => crossing.cid === 'trip-end')

    return (
      <div className={classnames}>
        <EdgePointForm
          request={request}
          label={trans('deductions-widget.trip-start')}
          form={'crossing-trip-start'}
          crossing={crossings[begin]}
          index={begin}
          showTarget={!this.isNationalTrip()}
          disabled={request.start_location.country_code === 'PL'}
        />

        {this.isNationalTrip() ? this.renderNationalTrip() : this.renderBorderCrossingTrip()}

        <EdgePointForm
          request={request}
          label={trans('deductions-widget.trip-end')}
          form={'crossing-trip-end'}
          index={end}
          crossing={crossings[end]}
        />
      </div>
    )
  }

  // HACK: for every form which is defined in this component, check if it has not changed since initialization
  isDirty = () => {
    const {
      dispatch,
      request,
      crossings: {
        selectors: { crossings, isInitialized },
      },
    } = this.props

    if (!isInitialized) return true

    const formNames = ['border-crossings-national-trip', 'crossing-trip-start', 'crossing-trip-end']

    if (this.isNationalTrip()) {
      request.targetPoints.forEach((_point, index) => {
        const key = `request-${request.slug}-destination-${index}`
        formNames.push(key)
      })
    } else {
      crossings
        .filter((c) => c.id !== 'trip-start' && c.id !== 'trip-end')
        .map((crossing) => {
          const key = `request-${request.slug}-crossing-${crossing.cid}`
          formNames.push(key)
        })
    }

    return formNames.some((form) =>
      dispatch((_dispatch, getState) => isDirty(form)(getState().toJS())),
    )
  }

  preValidate = () => {
    const { request } = this.props

    return APIClient.preValidateBorderCrossings(request.slug).then(({ data }) => {
      if (data.should_be_confirmed) {
        this.setState({ dialogOpen: true })
      } else {
        this.goToDeductions()
      }
    })
  }

  goToDeductions = () => {
    const {
      isReadOnly,
      crossings: {
        actions: { setStep, setStepReadOnly },
      },
    } = this.props

    if (isReadOnly) {
      setStepReadOnly(true)
    } else {
      setStep(true, this.isDirty())
    }
  }

  handleDialogClose = (confirmed) => {
    this.setState({ dialogOpen: false })

    if (confirmed) {
      this.goToDeductions()
    }
  }

  render() {
    const {
      request,
      isReadOnly,
      crossings: {
        selectors: { isLoading, isInitialized },
      },
    } = this.props

    return (
      <div className='border-crossings has-loader'>
        {isLoading && <Loader />}

        <Steps step={1} />

        <TargetPointsArea isReadOnly={isReadOnly} request={request} />

        {isInitialized && (
          <>
            <NationalTripForm
              form='border-crossings-national-trip'
              request={request}
              isReadOnly={isReadOnly}
            />

            <label htmlFor='#' className='border-crossings__heading'>
              {trans('deductions-widget.trip-process')}
            </label>

            {this.renderTimeline()}

            <div className='border-crossings__pagination-wrapper'>
              <span className='border-crossings__pagination'>1 z 2</span>

              <ConfirmationDialog open={this.state.dialogOpen} onClose={this.handleDialogClose} />

              <Button primary xs onClick={this.preValidate}>
                {isReadOnly
                  ? trans('deductions-widget.go-to-deductions-read')
                  : trans('deductions-widget.go-to-deductions')}
              </Button>
            </div>
          </>
        )}
      </div>
    )
  }
}

const withConnect = connect() // empty connect just to add dispatch... for getting current state object :/
const withBorderCrossings = borderCrossings()
const withTargetPoints = targetPoints()
const withDestination = destinations()

Crossings = compose(withConnect, withDestination, withBorderCrossings, withTargetPoints)(Crossings)

export default Crossings
export { Crossings }
