import React from 'react'
import { trans } from '../../trans'
import Icon from '../ui/IconComponent'

const TravelTimeWarning = (props) => {
  return (
    <div className='train-trip__travel-time-warning'>
      <div>
        <Icon type='warning' className='is-gradient-warning' />
      </div>
      <div className='train-trip__travel-time-warning__message'>
        {trans('trains-booking.too-far-away-message')}
      </div>
    </div>
  )
}

TravelTimeWarning.propTypes = {}

export default TravelTimeWarning
export { TravelTimeWarning }
