import PropTypes from 'prop-types'
import React from 'react'
import { isNumber } from 'lodash'
import { trans } from '../../../trans'
import { FormattedNumberField } from './FormattedNumberField'

export class DistanceField extends React.Component<any, any> {
  renderSuggestion() {
    const { distanceSuggestion, onChange } = this.props
    const distance = Math.ceil(distanceSuggestion)
    return (
      <div className='input-suggestion'>
        <span>
          {trans('request.distance-suggestion')}: {distance} km
        </span>
        <a
          onClick={() => {
            onChange(distance)
          }}
        >
          {trans('global.accept')}
        </a>
      </div>
    )
  }

  render() {
    const { value, distanceSuggestion, ...rest } = this.props
    return (
      <div>
        <FormattedNumberField value={value} {...rest} />
        {distanceSuggestion && Math.ceil(value) !== Math.ceil(distanceSuggestion)
          ? this.renderSuggestion()
          : null}
      </div>
    )
  }
}

DistanceField.propTypes = {
  distanceSuggestion: PropTypes.number,
}
