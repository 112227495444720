import React from 'react'
import { bindActionCreators } from 'redux'
import { connect as reduxConnect } from 'react-redux'
import { fetchAvatar, getAvatars } from '../../store/app/avatar'

const connect = (Component) => {
  const Avatar = (props) => <Component<any, any> {...props} />

  const mapStateToProps = (state) => {
    return {
      avatars: getAvatars(state),
    }
  }

  const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
      {
        fetchAvatar,
      },
      dispatch,
    )
  }

  const mergedProps = (selectors, actions, own) => {
    return {
      ...own,
      selectors,
      actions,
    }
  }

  return reduxConnect(mapStateToProps, mapDispatchToProps, mergedProps)(Avatar)
}

export default connect
export { connect }
