import React from 'react'
import { isEqual } from 'lodash'

class D3Base extends React.Component<any, any> {
  el = null

  componentDidMount() {
    this.renderGraph()
  }

  componentDidUpdate(prevProps) {
    if (isEqual(prevProps, this.props)) {
      this.updateGraph()
    } else {
      this.renderGraph()
    }
  }

  // methods to override

  renderGraph() {}
  updateGraph() {}

  renderChildren() {
    return null
  }

  render() {
    return (
      <div className='is-align-center' ref={(el) => (this.el = el)}>
        {this.renderChildren()}
      </div>
    )
  }
}

export { D3Base }
export default D3Base
