import PropTypes from 'prop-types'
import React from 'react'
import { StateTextButton } from '../ui/StateTextButton'
import { DatePickerField, SelectField } from '../ui/Form'
import trans from '../../trans'
import Icon from '../../components/ui/IconComponent'
import APIClient from '../../services/APIClient'
import { AsyncSelectField } from '../ui/Form/AsyncSelectField'
import { loadOptionsForUsers } from '../../store/app/users-for-select'
import classnames from 'classnames'
import { isEmpty } from 'lodash'
import ToggleableFilters from '../ToggleableFilters/ToggleableFilters'
import ToggleableQuickFilters from '../ToggleableFilters/ToggleableQuickFilters'
import { STATUS_ACCEPTANCE_OF_SETTLEMENT, STATUS_ACCOUNTING } from '../../constants/request'
import ToggleableBottomFilters from '../ToggleableFilters/ToggleableBottomFilters'
import UserField from '../../form/UserField/UserField'

const INSTALLMENTS_FILTERS = {
  EMPLOYEE: 'employee',
  STATUS: 'status',
  ACCOUNTING_STATUS: 'accounting_status',
  COMPANIES: 'companies',
  ACCOUNTING_DATE: {
    START: 'accounting_date_start',
    END: 'accounting_date_end',
  },
  ACCOUNTED: 'accounted',
  PAID: 'paid',
}

// For Page Advances.
class InstallmentsListFilters extends React.Component<any, any> {
  constructor(props) {
    super(props)

    this.timer = null
  }

  getStatusesOptions() {
    return [
      {
        label: trans('installments.status-all'),
        value: null,
        onSelectResetsInput: true,
      },
      {
        value: 'paid',
        label: trans('installments.status-paid'),
      },
      {
        value: 'unpaid',
        label: trans('installments.status-unpaid'),
      },
    ]
  }

  getAccountingStatusesOptions() {
    return [
      {
        label: trans('installments.status-all'),
        value: null,
        onSelectResetsInput: true,
      },
      {
        value: 'accounted',
        label: trans('installments.filter-quick-accounted'),
      },
      {
        value: 'not-accounted',
        label: trans('installments.filter-quick-not-accounted'),
      },
    ]
  }

  getCompaniesOptions() {
    const companiesMapped = this.props.companies.map((company) => {
      return {
        value: company.id,
        label: company.code,
      }
    })

    const optionAll = {
      label: trans('installments.filter-select-company-all'),
      value: null,
      onSelectResetsInput: true,
    }

    return [optionAll, ...companiesMapped]
  }

  render() {
    const { filters, onChangeFilter } = this.props
    const filtersCount = Object.keys(filters).length

    const bottomFilters = (
      <>
        <div className='filters__input-wrapper'>
          <span className='filters__input-title'>
            {trans('installments.filter-select-employee')}
          </span>

          <UserField
            value={filters[INSTALLMENTS_FILTERS.EMPLOYEE]}
            onChange={(value) => onChangeFilter(INSTALLMENTS_FILTERS.EMPLOYEE, value)}
            loadItems={loadOptionsForUsers}
            isMulti
            clearable
          />
        </div>

        <div className='filters__input-wrapper'>
          <span className='filters__input-title'>
            {trans('installments.filter-select-paid-status')}
          </span>
          <SelectField
            options={this.getStatusesOptions()}
            value={filters[INSTALLMENTS_FILTERS.STATUS]}
            onChange={(value) => onChangeFilter(INSTALLMENTS_FILTERS.STATUS, value)}
            placeholder={trans('installments.status-all')}
            isMulti
            clearable={true}
          />
        </div>

        <div className='filters__input-wrapper'>
          <span className='filters__input-title'>
            {trans('installments.filter-select-company')}
          </span>
          <SelectField
            options={this.getCompaniesOptions()}
            value={filters[INSTALLMENTS_FILTERS.COMPANIES]}
            onChange={(value) => {
              onChangeFilter(INSTALLMENTS_FILTERS.COMPANIES, value)
            }}
            placeholder={trans('installments.filter-select-company-all')}
            isMulti
            clearable={true}
          />
        </div>

        <div className='filters__input-wrapper'>
          <span className='filters__input-title'>{trans('installments.accounting-date')}</span>
          <div className='filters__period-container'>
            <DatePickerField
              placeholder={trans('global.datepicker-placeholder')}
              onChange={(value) =>
                onChangeFilter(INSTALLMENTS_FILTERS.ACCOUNTING_DATE.START, value)
              }
              value={filters[INSTALLMENTS_FILTERS.ACCOUNTING_DATE.START]}
              isClearable={true}
            />
            <span className='filters__separator'>-</span>
            <DatePickerField
              isClearable={true}
              minDate={filters[INSTALLMENTS_FILTERS.ACCOUNTING_DATE.START]}
              placeholder={trans('global.datepicker-placeholder')}
              onChange={(value) => onChangeFilter(INSTALLMENTS_FILTERS.ACCOUNTING_DATE.END, value)}
              value={filters[INSTALLMENTS_FILTERS.ACCOUNTING_DATE.END]}
            />
          </div>
        </div>

        <div className='filters__input-wrapper'>
          <span className='filters__input-title'>
            {trans('installments.filter-select-accounting-status')}
          </span>
          <SelectField
            options={this.getAccountingStatusesOptions()}
            value={filters[INSTALLMENTS_FILTERS.ACCOUNTING_STATUS]}
            onChange={(value) => onChangeFilter(INSTALLMENTS_FILTERS.ACCOUNTING_STATUS, value)}
            placeholder={trans('installments.status-all')}
            isMulti
            clearable={true}
          />
        </div>
      </>
    )

    return (
      <form>
        <ToggleableFilters filtersCount={filtersCount}>
          {({ isOpen }) => (
            <>
              <ToggleableQuickFilters>
                <div className='input-group'>
                  <Icon type='search' lg />
                  <input
                    type='text'
                    placeholder={trans('ui.search')}
                    className='input-group__input'
                    ref={(e) => (this.searchInput = e)}
                    defaultValue={filters['phrase']}
                    onChange={() => {
                      clearTimeout(this.timer)
                      this.timer = setTimeout(() => {
                        onChangeFilter('phrase', this.searchInput.value)
                      }, 250)
                    }}
                  />
                </div>

                <div className='filters__quick'>
                  <span className='filters__quick-title'>{trans('global.quick-filters')}:</span>

                  <StateTextButton
                    onClick={(activate) => {
                      onChangeFilter('quick_filters', activate ? INSTALLMENTS_FILTERS.PAID : null)
                    }}
                    active={filters['quick_filters'] === INSTALLMENTS_FILTERS.PAID}
                  >
                    {trans('installments.filter-quick-paid')}
                  </StateTextButton>

                  <StateTextButton
                    onClick={(activate) => {
                      onChangeFilter(
                        'quick_filters',
                        activate ? INSTALLMENTS_FILTERS.ACCOUNTED : null,
                      )
                    }}
                    active={filters['quick_filters'] === INSTALLMENTS_FILTERS.ACCOUNTED}
                  >
                    {trans('installments.filter-quick-accounted')}
                  </StateTextButton>
                </div>
              </ToggleableQuickFilters>

              <ToggleableBottomFilters isOpen={isOpen}>{bottomFilters}</ToggleableBottomFilters>
            </>
          )}
        </ToggleableFilters>
      </form>
    )
  }
}

InstallmentsListFilters.propTypes = {
  filters: PropTypes.object.isRequired,
  onChangeFilter: PropTypes.func.isRequired,
  companies: PropTypes.array.isRequired,
}

export default { InstallmentsListFilters }
export { InstallmentsListFilters }
