import React, { memo } from 'react'
import AnimateHeight from 'react-animate-height'
import { config } from '../../config'
import PropTypes from 'prop-types'

function RequestCollapsableSectionContent({ children, isOpen }) {
  return (
    <div className='request-collapsable-section-content'>
      <AnimateHeight duration={config.animations.heightDuration} height={isOpen ? 'auto' : 0}>
        <div className='request-collapsable-section-content__inner'>{children}</div>
      </AnimateHeight>
    </div>
  )
}

RequestCollapsableSectionContent.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.element.isRequired,
    PropTypes.arrayOf(PropTypes.element),
  ]),
  isOpen: PropTypes.bool.isRequired,
}

export default memo(RequestCollapsableSectionContent)
