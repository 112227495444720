import React from 'react'
import { SmartCheckbox } from '../../../ui/Input/SmartCheckbox'
import PropTypes from 'prop-types'
import ConfirmAccommodationDialog from '../ConfirmAccommodationDialog'

class AccommodationConfirmableCheckbox extends React.Component<any, any> {
  constructor(props) {
    super(props)

    this.state = {
      isDialogOpen: false,
    }
  }

  openDialog = () => {
    this.setState({ isDialogOpen: true })
  }

  closeDialog = () => {
    this.setState({ isDialogOpen: false })
  }

  change = (value) => {
    const { change } = this.props
    return change('accommodation', value)
  }

  onChange = (value) => {
    const { validate } = this.props

    if (value === 'dummy') {
      validate().then((response) => {
        if (response.data.should_be_confirmed) {
          this.openDialog()
        } else {
          this.change(true)
        }
      })
    }

    if (value === false) {
      this.change(false)
    }
  }

  render() {
    return (
      <React.Fragment>
        <SmartCheckbox
          name='accommodation'
          checked={this.props.checked}
          disabled={this.props.disabled}
          onChange={this.onChange}
          dummyClick={!this.props.checked ? () => {} : null}
        />

        <ConfirmAccommodationDialog isOpen={this.state.isDialogOpen} onClose={this.closeDialog} />
      </React.Fragment>
    )
  }
}

AccommodationConfirmableCheckbox.propTypes = {
  checked: PropTypes.bool.isRequired,
  disabled: PropTypes.bool.isRequired,
  change: PropTypes.func.isRequired,
  validate: PropTypes.func.isRequired,
}

export default AccommodationConfirmableCheckbox
