import React, { Component } from 'react'
import PropTypes from 'prop-types'
import QueryFields from './QueryFields'
import trans from '../../../trans'
import Button from '../../ui/ButtonComponent'
import OffersDialog from '../OffersDialog'
import { Loader } from '../../ui/LoadingOverlay/Loader'
import { reduxForm } from 'redux-form/immutable'
import { Factory as ElementFactory } from '../../../models/timeline'
import { fromJS } from 'immutable'
import { bindActionCreators, compose } from 'redux'
import { toggleSearch } from '../../../store/app/trains-booking/creators'
import { connect } from 'react-redux'
import { getRequestTravelers } from '../../../store/app/request-traveler'
import { TrainLocationSuggester } from '../../../store/app/trip-timeline/services/train-location-suggester'
import { getFormValues } from '../../../utils/forms'

class Search extends Component<any, any> {
  resignFromSearching = (e) => {
    e.preventDefault()
    const { trainsBooking } = this.props
    trainsBooking.actions.resignFromSearching()
  }

  search = (e) => {
    const { trainsBooking, data } = this.props
    e.preventDefault()

    trainsBooking.actions.search(trainsBooking.request, data)
  }

  render() {
    const { trainsBooking, travelers } = this.props

    return (
      <form>
        {trainsBooking.selectors.isLoading && <Loader />}
        <QueryFields trainsBooking={trainsBooking} travelers={travelers} />
        <div className='train-trip__inputs train-trip__inputs--filters train-trip__inputs--buttons'>
          <Button
            secondary
            outline
            className='train-trip__resign-button'
            onClick={this.resignFromSearching}
          >
            {trans('hotels-booking.resign-from-searching')}
          </Button>
          <Button primary className='train-trip__search-button' onClick={this.search}>
            {trans('trains-booking.search')}
          </Button>
        </div>
        <OffersDialog trainsBooking={trainsBooking} />
      </form>
    )
  }
}

Search.propTypes = {
  trainsBooking: PropTypes.object.isRequired,
}

const withForm = reduxForm({
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
  destroyOnUnmount: false,
})

const mapStateToProps = (state, props) => {
  const {
    trainsBooking: { element },
  } = props
  const train = ElementFactory.create(element)

  const locationSuggester = new TrainLocationSuggester(state, train)
  const travelers = getRequestTravelers(state)
  const defaultTravelers = travelers.map((traveler) => traveler.slug)

  return {
    initialValues: fromJS({
      departure_at: train.departure_at,
      destination_location: train.draft
        ? locationSuggester.suggestEndLocation()
        : train.getEndLocation(),
      departure_location: train.draft
        ? locationSuggester.suggestStartLocation()
        : train.getStartLocation(),
      request_travelers: defaultTravelers,
    }),
    form: train.key,
    data: getFormValues(train.key, state),
    travelers: getRequestTravelers(state),
  }
}

const mapDispatchToProps = (dispatch, props) => {
  const {
    trainsBooking: { element },
  } = props
  const train = ElementFactory.create(element)
  return bindActionCreators(
    {
      toggleSearch: toggleSearch(train.key),
    },
    dispatch,
  )
}

const withConnect = connect(mapStateToProps, mapDispatchToProps)

Search = compose(withConnect, withForm)(Search)

export { Search }
export default Search
