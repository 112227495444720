import PropTypes from 'prop-types'
import React from 'react'
import classnames from 'classnames'
import { Link } from 'react-router-dom'

export const TYPE_DATA_ROW = 'data_row'
export const TYPE_BACKGROUND_PRIMARY_ROW = 'background_primary_row'
export const TYPE_BACKGROUND_GRADIENT_ROW = 'type_background_primary_row'

class Row extends React.Component<any, any> {
  renderLinkedRow() {
    const { children, className, href } = this.props
    const classes = classnames({
      'table-accordion__row': true,
      row: true,
      [className]: !!className,
      'table-accordion__row--is-link': !!href,
    })

    return (
      <Link to={href} className='table-accordion__row-link' draggable={false}>
        <div className={classes}>{children}</div>
      </Link>
    )
  }

  renderCommonRow() {
    const { children, className, href, renderer, thin } = this.props
    const classes = classnames({
      'table-accordion__row': true,
      row: true,
      [className]: !!className,
      'table-accordion__row--is-link': !!href,
      'table-accordion__row--is-data-row': renderer && renderer.type === TYPE_DATA_ROW,
      'table-accordion__row--is-background-primary-row':
        renderer && renderer.type === TYPE_BACKGROUND_PRIMARY_ROW,
      'table-accordion__row--is-background-gradient-row':
        renderer && renderer.type === TYPE_BACKGROUND_GRADIENT_ROW,
      'table-accordion__row--is-thin': thin,
    })

    return <div className={classes}>{children}</div>
  }

  render() {
    const { href } = this.props

    return href ? this.renderLinkedRow() : this.renderCommonRow()
  }
}

Row.propTypes = {
  href: PropTypes.string,
  renderer: PropTypes.object,
}

Row.defaultProps = {
  type: TYPE_DATA_ROW,
}

export { Row }
export default Row
