import React from 'react'
import { bindActionCreators } from 'redux'
import { connect as reduxConnect } from 'react-redux'
import {
  getEmployees,
  getMe,
  getSuperior,
  isFetching,
  isInitialized,
} from '../../store/app/company-hierarchy/selectors'
import { reset, set, setCurrent } from '../../store/app/company-hierarchy/creators'

const connect = (Component) => {
  const CompanyHierarchyConnect = (props) => <Component<any, any> {...props} />

  const mapStateToProps = (state) => {
    return {
      employees: getEmployees(state),
      isFetching: isFetching(state),
      me: getMe(state),
      superior: getSuperior(state),
      isInitialized: isInitialized(state),
    }
  }

  const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
      {
        reset,
        set,
        setCurrent,
      },
      dispatch,
    )
  }

  const mergedProps = (selectors, actions, own) => {
    return {
      ...own,
      hierarchy: {
        selectors,
        actions,
        ...own.hierarchy,
      },
    }
  }

  return reduxConnect(mapStateToProps, mapDispatchToProps, mergedProps)(CompanyHierarchyConnect)
}

export default connect
export { connect }
