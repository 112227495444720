import PropTypes from 'prop-types'
import React from 'react'
import { RouteManager } from '../../../containers/RouteManager/index'
import { ExpenseRequestManager } from '../../../containers/ExpenseRequestManager/index'
import { ExpenseRequestPageAccounting } from './ExpenseRequestPageAccounting'

class ExpenseRequestPageTransferError extends ExpenseRequestPageAccounting {}

ExpenseRequestPageTransferError.propTypes = {
  request: PropTypes.object.isRequired,
}

export { ExpenseRequestPageTransferError }
export default { ExpenseRequestPageTransferError }
