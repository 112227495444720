import PropTypes from 'prop-types'
import React, { Component, Fragment } from 'react'
import trans from '../../trans'
import { RouteManager } from '../../containers/RouteManager/index'
import { RequestElementDocumentsManager } from '../../containers/RequestDocumentsManager/index'
import { Row, Col } from '../../ui/Grid/index'
import { DocumentCell } from '../DocumentCell/index'
import { UploadCell } from '../DocumentCell/index'
import { Ability } from './Ability/Ability'
import { get } from 'lodash'

class RequestDocuments extends Component<any, any> {
  // Sorts documents based on self_generated property, so self generated
  // invoices are first on the list, other than that default order is
  // preserved
  sortDocuments = (documents) => {
    return [...documents].sort((a, b) => {
      if (a.self_generated && !b.self_generated) return -1
      if (!a.self_generated && b.self_generated) return 1
      return 0
    })
  }

  render() {
    const { request, element, documents, listName } = this.props

    let Container = Row

    if (get(this.props, 'fragment', false)) {
      // noinspection JSUnusedAssignment
      Container = Fragment
    }

    return (
      <RouteManager>
        {({ push, getRouteByName }) => (
          <RequestElementDocumentsManager
            element={element}
            documents={documents}
            request={request}
            listName={listName}
          >
            {({
              documentsList,
              instanceCurrency,
              request,
              uploadDocument,
              deleteDocument,
              currentUser,
            }) => (
              <Container>
                <Ability ability={['addAndDeleteDocuments']} comparator='nor'>
                  {!documentsList.length && trans('document.there-are-no-documents')}
                </Ability>

                {this.sortDocuments(documentsList).map((document) => {
                  return (
                    <DocumentCell
                      width='217px'
                      document={document}
                      key={`document-cell-${document['id']}`}
                      push={push}
                      getRouteByName={getRouteByName}
                      instanceCurrency={instanceCurrency}
                      deleteDocument={document.abilities.delete ? deleteDocument : null}
                      request={request}
                      currentUser={currentUser}
                    />
                  )
                })}

                <Ability ability={['addAndDeleteDocuments']}>
                  <Col
                    width='210px'
                    style={{ display: 'flex', paddingTop: '15px', paddingBottom: '15px' }}
                  >
                    <UploadCell uploadDocument={uploadDocument} request={request} />
                  </Col>
                </Ability>
              </Container>
            )}
          </RequestElementDocumentsManager>
        )}
      </RouteManager>
    )
  }
}

RequestDocuments.propTypes = {
  element: PropTypes.object.isRequired,
  documents: PropTypes.array.isRequired,
  request: PropTypes.object.isRequired,
  listName: PropTypes.string.isRequired,
}

export { RequestDocuments }
export default { TripRequestDocuments: RequestDocuments }
