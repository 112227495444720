import PropTypes from 'prop-types'
import React from 'react'
import { transformDimensions } from '../../store/app/account-dimensions/form'
import APIClient from '../../services/APIClient'

const FetchMethods = {
  requestHeader: APIClient.getAccountDimensionsForRequestHeader.bind(APIClient),
  requestAccounting: APIClient.getAccountDimensionsForRequestAccounting.bind(APIClient),
  documentElement: APIClient.getAccountDimensionsForDocumentElement.bind(APIClient),
  document: APIClient.getAccountDimensionsForDocument.bind(APIClient),
  allowanceHeader: APIClient.getAccountDimensionsForAllowanceHeader.bind(APIClient),
}

class AccountDimensionManager extends React.Component<any, any> {
  constructor(props) {
    super(props)

    this.state = {
      accountDimensionsLoaded: false,
      accountDimensions: [],
    }
  }

  componentDidMount() {
    const { fetchMethod, fetchArgs } = this.props

    if (!(fetchMethod in FetchMethods)) {
      console.error(
        `Invalid fetch method ${fetchMethod}. Available methods: ${Object.keys(FetchMethods).join(
          ', ',
        )}`,
      )
      return null
    }

    FetchMethods[fetchMethod](...fetchArgs)
      .then((res) => {
        this.setState({
          accountDimensionsLoaded: true,
          accountDimensions: res.data.map(transformDimensions),
        })
      })
      .catch((e) => {
        this.setState({
          accountDimensionsLoaded: true,
          accountDimensions: [],
        })
      })
  }

  render() {
    const { children } = this.props
    const { accountDimensions, accountDimensionsLoaded } = this.state

    const renderProps = {
      accountDimensions,
      isLoaded: accountDimensionsLoaded,
    }

    return children(renderProps)
  }
}

AccountDimensionManager.propTypes = {
  fetchMethod: PropTypes.oneOf(Object.keys(FetchMethods)).isRequired,
  fetchArgs: PropTypes.array,
  children: PropTypes.func.isRequired,
}

export { AccountDimensionManager }
export default AccountDimensionManager
