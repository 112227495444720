import Icon from '../../components/ui/IconComponent'
import trans from '../../trans'
import React, { useRef, useState } from 'react'
import { Timeout } from 'react-number-format/types/types'

export const TOGGLEABLE_SEARCH_TEST_ID = 'toggleable-search-field'

export interface SearchFilterFieldProps {
  value: string
  onChange: (value: string) => void
  debounce?: number
}

export function ToggleableSearchField({ value, onChange, debounce }: SearchFilterFieldProps) {
  const inputRef = useRef<HTMLInputElement>()
  const [timer, setTimer] = useState<Timeout>()

  return (
    <div className='input-group'>
      <Icon type='search' lg />
      <input
        type='text'
        placeholder={trans('ui.search')}
        className='input-group__input'
        data-testid={TOGGLEABLE_SEARCH_TEST_ID}
        ref={inputRef}
        defaultValue={value}
        onChange={() => {
          clearTimeout(timer)

          setTimer(
            setTimeout(() => {
              onChange(inputRef.current.value)
            }, debounce || 250),
          )
        }}
      />
    </div>
  )
}

export default ToggleableSearchField
