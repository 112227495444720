import React from 'react'
import classNames from 'classnames'
import connect from '../../containers/Avatar/connect'
import { useAvatar } from './useAvatar'
import PropTypes from 'prop-types'

function AvatarComponent(props) {
  const { user, className, asImage, selectors, actions, ...rest } = props
  const avatar = useAvatar(user)
  const isDefault = `${avatar}`.includes('images/avatar.png')
  const classes = classNames('user-avatar', className, {
    'user-avatar--default': isDefault,
  })

  if (!user) {
    return <div className={classes} />
  }

  if (!avatar) {
    return <div className={classes} />
  }

  if (asImage || isDefault) {
    return <img src={avatar} className={classes} {...rest} />
  }

  return (
    <div
      className={classes}
      style={{
        backgroundImage: `url(${avatar})`,
      }}
      {...rest}
    />
  )
}

AvatarComponent.propTypes = {
  asImage: PropTypes.bool,
  user: PropTypes.shape({
    slug: PropTypes.string,
    avatar: PropTypes.string,
  }),
  className: PropTypes.string,
}

const Avatar = connect(AvatarComponent)

export default Avatar
export { Avatar }
