import React from 'react'
import { connect } from 'formik'
import { FormGroup } from '../../../ui'
import { DatePickerField } from '../../ui/Form'
import moment, { isMoment } from 'moment'
import Error from '../../../ui/Input/Error'

export default connect((props) => {
  const { label, name, disabled } = props
  const value = props.formik.values[name]

  return (
    <div>
      <FormGroup label={label} labeltop>
        <DatePickerField
          name={name}
          disabled={disabled}
          onChange={(date) =>
            props.formik.setFieldValue(name, isMoment(date) ? date.format('DD.MM.YYYY') : null)
          }
          type={'datepicker'}
          value={!isMoment(value) && value !== null ? moment(value, 'DD.MM.YYYY') : value}
        />
        <Error errors={props.formik.errors[name]} />
      </FormGroup>
    </div>
  )
})
