import PropTypes from 'prop-types'
import React from 'react'
import { RequestHeader } from '../../RequestPageCommon/RequestHeader'
import { ExpenseRequestSummary } from '../ExpenseRequestSummary'
import { RequestComments } from '../../RequestPageCommon/RequestComments'
import BasicRequestInfoRenderer from '../../RequestBasicInfoRenderer/RequestBasicInfoRenderer'

class ExpenseRequestPageCancelled extends React.Component<any, any> {
  render() {
    const { request, currentUser, isModuleActive } = this.props

    return (
      <div>
        <RequestHeader request={request} />

        <BasicRequestInfoRenderer />

        <ExpenseRequestSummary request={request} />

        <RequestComments comments={request['comments']} />
      </div>
    )
  }
}

ExpenseRequestPageCancelled.propTypes = {
  request: PropTypes.object.isRequired,
  currentUser: PropTypes.object.isRequired,
}

export { ExpenseRequestPageCancelled }
export default { TripRequestPageCancelled: ExpenseRequestPageCancelled }
