import $ from 'jquery'

const onItemHover = function () {
  const $self = $(this)
  addClasses($self)
}

const onItemOut = function () {
  const $self = $(this)
  removeClasses($self)
}

const addClasses = ($self) => {
  $self.addClass('active')
  const $submenu = $self.find('ul')
  $submenu.addClass('active')
  // $submenu.parent().addClass('is-sub-open');
}

const removeClasses = ($self) => {
  $self.removeClass('active')
  const $submenu = $self.find('ul')
  $submenu.removeClass('active')
  //     $submenu.parent().removeClass('is-sub-open');
}

$(document).ready(function () {
  $(document).on('mouseenter', '.profile-dropdown__list li', onItemHover)
  $(document).on('mouseleave', '.profile-dropdown__list li', onItemOut)
})
