import { createSelector, createSlice } from '@reduxjs/toolkit'
import APIClient from '../../../services/APIClient'
import { trans } from '../../../trans'

export const GRADE_MOUNT_POINT = 'grade'

const getInitialState = () => ({
  items: [],
  error: null,
  isLoading: false,
})

/**
 * In @reduxjs/toolkit we can mutate state in reducers,
 * because behind the scene it uses ImmerJS for applying them as non-mutate
 *
 * https://github.com/immerjs/immer
 */
const gradeSlice = createSlice({
  name: GRADE_MOUNT_POINT,
  initialState: getInitialState(),
  reducers: {
    resetGrades(state) {
      state = getInitialState()
    },
    setGrades(state, action) {
      const { data } = action.payload

      state.items = data
    },
    startLoading(state) {
      state.isLoading = true
      state.error = null
    },
    setLoadingSuccess(state) {
      state.isLoading = false
      state.error = null
    },
    setLoadingFailed(state, action) {
      state.isLoading = false
      state.error = action.payload
    },
  },
})

export const { resetGrades, setGrades, startLoading, setLoadingSuccess, setLoadingFailed } =
  gradeSlice.actions

export default gradeSlice.reducer

// selectors
const getState = (state) => state.get(GRADE_MOUNT_POINT)

export const getGrades = (state) => getState(state).items
export const isLoading = (state) => getState(state).isLoading

// memoized selectors, improve performance
export const getGradesForSelectOptionsSelector = createSelector([getGrades], (items) =>
  items.map((item) => ({
    value: `${item.id}`,
    label: `${item.name}`,
  })),
)

// action thunks
export const fetchGrades = () => async (dispatch) => {
  dispatch(startLoading())

  try {
    const { data } = await APIClient.getGrades()
    dispatch(setGrades({ data }))
    dispatch(setLoadingSuccess())
  } catch (err) {
    dispatch(setLoadingFailed(err))
  }
}

export const fetchGradesIfNeeded = () => async (dispatch, state) => {
  const { items, isLoading } = getState(state())

  if (items.length || isLoading) {
    return
  }

  return dispatch(fetchGrades())
}
