import React from 'react'
import PropTypes from 'prop-types'
import { Tooltip } from '../Tooltip'

import Icon from '../ui/IconComponent'

function _Facility({ icon, title }) {
  return (
    <Tooltip className='selected-hotel__offer-icon' title={title} stickyDuration={0}>
      <Icon className='selected-hotel__offer-icon' type={icon} key={icon} />
    </Tooltip>
  )
}

_Facility.propTypes = {
  icon: PropTypes.string.isRequired,
  title: PropTypes.string,
}

const Facility = React.memo(_Facility)

export default Facility
export { Facility }
