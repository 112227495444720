import React from 'react'
import PropTypes from 'prop-types'

const NoticeLoader = (props) => {
  const { loaderText, description, descriptionBreak } = props

  return (
    <div className='notice-loader'>
      <p className='notice-loader__loader-text'>{loaderText}</p>
      <p className='notice-loader__description'>{description}</p>
      <p className='notice-loader__description--break'>{descriptionBreak}</p>
    </div>
  )
}

NoticeLoader.propTypes = {
  text: PropTypes.string,
}

export { NoticeLoader }
export default NoticeLoader
