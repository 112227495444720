import React, { Component } from 'react'
import MainNavList from './MainNavList'
import { bindActionCreators, compose } from 'redux'
import { connect } from 'react-redux'
import { fill, get, has, intersection, isEmpty, isMatch, keys, zipObject } from 'lodash'
import { connect as connectLoginAs } from '../../containers/LoginAs'
import { fetchInstance, getTranslations, isDemoInstance } from '../../store/app/instance'
import {
  getUserAssistantOrCurrentUserAbilities,
  getUserAssistantOrCurrentUserLinks,
} from '../../store/app/user-profile/userProfileSlice'

class MainNav extends Component<any, any> {
  constructor(props) {
    super(props)

    this.state = {
      expand: true,
      open: false,
      isLoaded: false,
    }
  }

  handleMainNavExpandClick = () => {
    this.setState({ expand: !this.state.expand })
  }

  handleMainNavOpenClick = () => {
    this.setState({ open: !this.state.open })
  }

  swipeMenuDependOnScreen = () => {
    if (window.innerWidth >= 768 && window.innerWidth < 992) {
      if (this.state.expand) {
        this.setState({ expand: false })
      }
    }
  }

  componentDidMount() {
    const { fetchInstance } = this.props
    this.swipeMenuDependOnScreen()
    window.addEventListener('resize', this.swipeMenuDependOnScreen)

    fetchInstance()
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.swipeMenuDependOnScreen)
  }

  preFilterDemoInstance() {
    const { items, isDemoInstance } = this.props
    let filteredItems = items

    if (!isDemoInstance) {
      filteredItems = items.filter((item) => {
        return item.showInInstance !== 'demo'
      })
    }
    return filteredItems
  }

  checkUserAbility(item) {
    const { userAbilities } = this.props

    if (!has(item, 'abilities.ability') || isEmpty(item.abilities.ability)) {
      return true
    }

    const itemAbilities = zipObject(
      item.abilities.ability,
      fill(Array(item.abilities.ability.length), true),
    )
    const operator = get(item, 'abilities.operator', 'and')
    const abilities = []
    Object.keys(userAbilities).forEach((key) => {
      if (userAbilities[key]) {
        abilities.push(key)
      }
    })

    if (operator.toLowerCase() === 'or') {
      return intersection(abilities, keys(itemAbilities)).length
    }

    if (operator.toLowerCase() === 'not') {
      let can = true

      Object.keys(itemAbilities).forEach((key) => {
        if (userAbilities.hasOwnProperty(key) && userAbilities[key] === true) {
          can = false
        }
      })

      return can
    }

    return isMatch(userAbilities, itemAbilities)
  }

  checkUserLink(item) {
    const { userLinks } = this.props

    if (!item.hasLink) {
      return true
    }

    return !!userLinks[item.hasLink]
  }

  prepareItems() {
    const items = this.preFilterDemoInstance()
    const { userAbilities, userLinks } = this.props

    if (isEmpty(userAbilities) && isEmpty(userLinks)) {
      return items
    }

    return items.filter((item) => {
      const hasAbility = this.checkUserAbility(item)
      const hasLink = this.checkUserLink(item)

      return hasAbility && hasLink
    })
  }

  render() {
    return (
      <MainNavList
        open={this.state.open}
        expand={this.state.expand}
        items={this.prepareItems()}
        handleMainNavExpandClick={this.handleMainNavExpandClick}
        handleMainNavOpenClick={this.handleMainNavOpenClick}
      />
    )
  }
}

const mapStateToProps = (state) => ({
  userAbilities: getUserAssistantOrCurrentUserAbilities(state),
  userLinks: getUserAssistantOrCurrentUserLinks(state),
  translations: getTranslations(state),
  isDemoInstance: isDemoInstance(state),
})

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      fetchInstance,
    },
    dispatch,
  )

const withConnect = connect(mapStateToProps, mapDispatchToProps)

export default compose(connectLoginAs, withConnect)(MainNav)
