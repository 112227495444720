import { useEffect, useMemo, useState } from 'react'
import { GridApi, GridOptions, GridReadyEvent } from 'ag-grid-community'
import trans from '../../../trans'
import dateFormatter from '../../AgGrid/ValueFormatter/dateFormatter'
import defaultFormatter from '../../AgGrid/ValueFormatter/defaultFormatter'
import StatementStatusRenderer from '../../AgGrid/CellRenderer/StatementStatusRenderer'
import { Statement } from '../../../types/statements'

export function useGridOptions() {
  const [api, setApi] = useState<GridApi>(null)
  const [gridOptions, setGridOptions] = useState<GridOptions<Statement>>({
    domLayout: 'autoHeight',
    defaultColDef: {
      suppressSizeToFit: false,
      suppressMenu: true,
      suppressMovable: true,
    },
    onGridReady(event: GridReadyEvent<Statement>) {
      setApi(event.api)
    },
    columnDefs: [
      {
        headerName: trans('statements.statements_th_from'),
        field: 'from',
        valueFormatter: dateFormatter,
        minWidth: 120,
        maxWidth: 120,
      },
      {
        headerName: trans('statements.statements_th_to'),
        field: 'to',
        valueFormatter: defaultFormatter,
        minWidth: 120,
        maxWidth: 120,
      },
      {
        headerName: trans('statements.statements_th_card_number'),
        field: 'card_number',
        valueFormatter: defaultFormatter,
        minWidth: 180,
        maxWidth: 180,
      },
      {
        headerName: trans('statements.statements_th_owner'),
        field: 'owner',
        valueFormatter: defaultFormatter,
        minWidth: 150,
      },
      {
        headerName: trans('statements.statements_th_balance'),
        field: 'balance',
        valueFormatter: defaultFormatter,
        minWidth: 150,
        maxWidth: 150,
        type: 'numericColumn',
      },
      {
        headerName: trans('statements.statements_th_amount_settled'),
        field: 'amount_settled',
        valueFormatter: defaultFormatter,
        minWidth: 150,
        maxWidth: 150,
        type: 'numericColumn',
      },
      {
        headerName: trans('statements.statements_th_amount_to_settle'),
        field: 'amount_to_settle',
        valueFormatter: defaultFormatter,
        minWidth: 150,
        maxWidth: 150,
        type: 'numericColumn',
      },
      {
        headerName: trans('statements.statements_th_status'),
        field: 'closed',
        cellRenderer: StatementStatusRenderer,
        minWidth: 120,
        maxWidth: 120,
      },
    ],
  })

  const onResize = useMemo(() => {
    return () => {
      if (api) {
        api.sizeColumnsToFit()
      }
    }
  }, [api])

  useEffect(() => {
    onResize()

    window.addEventListener('resize', onResize)

    return () => {
      window.removeEventListener('resize', onResize)
    }
  }, [api])

  return gridOptions
}
