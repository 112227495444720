import PropTypes from 'prop-types'
import React from 'react'
import { RequestAcceptorsManager } from '../../containers/RequestAcceptorsManager'
import { ExpenseRequestStatusPath, TripRequestStatusPath } from '../RequestStatusPath'
import * as constants from '../../constants/request'

class TripRequestPath extends React.Component<any, any> {
  renderExpensePath(
    request,
    acceptors,
    settlementAcceptors,
    addAcceptor,
    addSettlementAcceptor,
    removeAcceptor,
    removeSettlementAcceptor,
  ) {
    return (
      <ExpenseRequestStatusPath
        request={request}
        acceptors={acceptors}
        settlementAcceptors={settlementAcceptors}
        addAcceptor={addAcceptor}
        addSettlementAcceptor={addSettlementAcceptor}
        removeAcceptor={removeAcceptor}
        removeSettlementAcceptor={removeSettlementAcceptor}
      />
    )
  }

  renderTripPath(
    request,
    acceptors,
    settlementAcceptors,
    addAcceptor,
    addSettlementAcceptor,
    removeAcceptor,
    removeSettlementAcceptor,
  ) {
    return (
      <TripRequestStatusPath
        request={request}
        acceptors={acceptors}
        settlementAcceptors={settlementAcceptors}
        addAcceptor={addAcceptor}
        addSettlementAcceptor={addSettlementAcceptor}
        removeAcceptor={removeAcceptor}
        removeSettlementAcceptor={removeSettlementAcceptor}
      />
    )
  }

  render() {
    const { request, fetchRequest } = this.props

    return (
      <div>
        <RequestAcceptorsManager request={request} fetchRequest={fetchRequest}>
          {({
            request,
            acceptors,
            settlementAcceptors,
            addAcceptor,
            addSettlementAcceptor,
            removeAcceptor,
            removeSettlementAcceptor,
          }) => (
            <div>
              {request.type === constants.TYPE_TRIP
                ? this.renderTripPath(
                    request,
                    acceptors,
                    settlementAcceptors,
                    addAcceptor,
                    addSettlementAcceptor,
                    removeAcceptor,
                    removeSettlementAcceptor,
                  )
                : this.renderExpensePath(
                    request,
                    acceptors,
                    settlementAcceptors,
                    addAcceptor,
                    addSettlementAcceptor,
                    removeAcceptor,
                    removeSettlementAcceptor,
                  )}
            </div>
          )}
        </RequestAcceptorsManager>
      </div>
    )
  }
}

TripRequestPath.propTypes = {
  request: PropTypes.object.isRequired,
  fetchRequest: PropTypes.func.isRequired,
}

export { TripRequestPath }
export default { TripRequestPath }
