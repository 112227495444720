import { get } from 'lodash'
import React from 'react'
import { connect } from 'react-redux'
import { fromJS } from 'immutable'
import { bindActionCreators, compose } from 'redux'
import { createAutoSaveForm, getFormValues } from '../../../utils/forms'
import { changeFieldValue, setSensitiveData } from '../../../store/app/sensitive-data'
import SensitiveData from './SensitiveData'
import companies from '../../../store/app/companies'
import { fetchEditableMpk } from '../../../store/app/mpks'
import { grades } from '../../../store/app/grades'
import { getInstance } from '../../../store/app/instance'
import userProfile from '../../../store/app/user-profile/providers/userProfile'
import {
  fetchCompanies,
  getCompaniesForSelectOptionsWithCodeSelector,
} from '../../../store/app/companies/slice'
import { fetch as fetchExternalCompanyHierarchy } from '../../../store/app/external-company-hierarchy/creators'
import { getGradesForSelectOptionsSelector } from '../../../store/app/grades/slice'
import { toArray } from '../../../utils/array'
import { processAPIerrorResponseToFormErrors } from '../../../services/APIClient'
import { SubmissionError } from 'redux-form/immutable'

export const FORM_NAME = 'sensitive-data'

const save = (name, value, dispatch, props) => {
  const { user, data, setSensitiveData, setUser, fetchExternalCompanyHierarchy } = props
  const values = {...data, [name]: value };

  if (name === 'mpk') {
    if (value.length === 1) {
      value[0].percentage = 100
    }
  }

  return setSensitiveData(user.slug, values).then(({ data }) => {
    setUser({...user, ...data, ...values})
    fetchExternalCompanyHierarchy(data.slug)
  })
}

const AutoSaveForm = createAutoSaveForm(SensitiveData, {
  name: FORM_NAME,
  save,
  timeout: 500,
  enableReinitialize: true,
  handleErrors: (alerts) => {
    const errors = processAPIerrorResponseToFormErrors(alerts)

    if (errors.mpk) {
      errors.mpk = {
        _error: errors.mpk.join(', '),
      }
    }

    return errors
  },
})

const getMpks = (user) => {
  if (!user.hasMpks || !user.hasMpks.length) {
    return [
      {
        ...user.mpk,
        percentage: 100,
        main: 1,
      },
    ]
  }

  return user.hasMpks || []
}

const mapStateToProps = (state, props) => {
  const { form = FORM_NAME, user, userProfile } = props

  const data = getFormValues(FORM_NAME, state)
  const instance = getInstance(state)
  const loggedUser = userProfile.selectors.data
  const isEditable =
    (!instance.modules.erp || !instance.modules.erp.enabled) &&
    loggedUser.abilities.userChangeSensitiveData

  return {
    initialValues: fromJS({
      company_id: get(user, 'company_id', 0),
      mpk: getMpks(user),
      mpk_id: get(user, 'mpk.id'),
      supervisor: get(user, 'supervisor', null),
      grade_id: String(get(user, 'grade', 0)),
      erp_id: get(user, 'erp_id', ''),
      hr_id: get(user, 'hr_id', ''),
    }),
    form,
    data,
    isEditable,
    companies: getCompaniesForSelectOptionsWithCodeSelector(state),
    grades: getGradesForSelectOptionsSelector(state),
    instance: getInstance(state),
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      changeFieldValue,
      setSensitiveData,
      fetchEditableMpk,
      fetchCompanies,
      fetchExternalCompanyHierarchy,
    },
    dispatch,
  )
}

const withCompanies = companies(false, true)
const withGrades = grades(false, true)
const withUserProfile = userProfile(false, false)
const withConnect = connect(mapStateToProps, mapDispatchToProps)

const SensitiveDataForm = compose(
  withCompanies,
  withGrades,
  withUserProfile,
  withConnect,
)(AutoSaveForm)

export { SensitiveDataForm }
export default SensitiveDataForm
