import React, { Fragment } from 'react'
import classNames from 'classnames'
import '../../third-party/dropdown'
import SuperiorsList from './SuperiorsList'
import CurrentSuperior from './CurrentSuperior'

class Dropdown extends React.Component<any, any> {
  render() {
    const { currentUser, open, handleDropdownHeaderClick } = this.props

    const DropdownClasses = classNames({
      'main-header__profile-dropdown  profile-dropdown profile-dropdown--assistant': true,
      active: open,
    })

    return (
      <div className={DropdownClasses}>
        <div className='profile-dropdown__header' onClick={handleDropdownHeaderClick}>
          <CurrentSuperior />
        </div>
        <SuperiorsList currentUser={currentUser} isOpen={open} />
      </div>
    )
  }
}

export default Dropdown
export { Dropdown }
