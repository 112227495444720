import { concat, filter, find, get, groupBy } from 'lodash'

export const getOffersUuidConcatenation = (offerA, offerB) => {
  const offerAUuid = get(offerA, 'uuid', null)
  const offerBUuid = get(offerB, 'uuid', null)

  if (!offerAUuid) {
    return null
  }

  if (!offerBUuid) {
    return offerAUuid
  }

  return offerAUuid + '_' + offerBUuid
}

export const getOptionsUuidConcatenation = (offerA, offerB) => {
  const optionAUuid = get(offerA, 'options.0.uuid', null)
  const optionBUuid = get(offerB, 'options.0.uuid', null)

  if (!optionAUuid) {
    return null
  }

  if (!optionBUuid) {
    return optionAUuid
  }

  return optionAUuid + '_' + optionBUuid
}

export const calculateAmountWithAdditionalServices = (
  selectedThere,
  selectedBack,
  availableAttributes,
  requestedAttributes,
) => {
  const both = get(availableAttributes, 'both', false)
  const thereUuid = get(selectedThere, 'uuid', '')
  const backUuid = get(selectedBack, 'uuid', '')

  const availableServices = get(availableAttributes, 'services', {})
  const requestedServices = get(requestedAttributes, 'services', {})

  const requestedBaggageThere = get(requestedServices, `${thereUuid}.baggage`, [])
  const requestedBoardingThere = get(requestedServices, `${thereUuid}.priority-boarding`, [])

  const requestedBaggageBack = get(requestedServices, `${backUuid}.baggage`, [])
  const requestedBoardingBack = get(requestedServices, `${backUuid}.priority-boarding`, [])

  const availableBaggageItemsThere = get(
    find(get(availableServices, `${thereUuid}`, []), { type: 'baggage' }),
    'items',
    [],
  )
  const availableBoardingItemsThere = get(
    find(get(availableServices, `${thereUuid}`, []), { type: 'priority-boarding' }),
    'items',
    [],
  )

  const availableBaggageItemsBack = get(
    find(get(availableServices, `${backUuid}`, []), { type: 'baggage' }),
    'items',
    [],
  )
  const availableBoardingItemsBack = get(
    find(get(availableServices, `${backUuid}`, []), { type: 'priority-boarding' }),
    'items',
    [],
  )

  const matchedBaggageThere = filter(
    availableBaggageItemsThere,
    (item) => requestedBaggageThere.indexOf(item.id) !== -1,
  )
  const matchedBaggageBack = filter(
    availableBaggageItemsBack,
    (item) => requestedBaggageBack.indexOf(item.id) !== -1,
  )

  const matchedBoardingThere = filter(
    availableBoardingItemsThere,
    (item) => requestedBoardingThere.indexOf(item.id) !== -1,
  )
  const matchedBoardingBack = filter(
    availableBoardingItemsBack,
    (item) => requestedBoardingBack.indexOf(item.id) !== -1,
  )

  const matchedBaggage = !both
    ? concat(matchedBaggageThere, matchedBaggageBack)
    : matchedBaggageBack
  const matchedBoarding = !both
    ? concat(matchedBoardingThere, matchedBoardingBack)
    : matchedBoardingBack

  const matched = concat(matchedBaggage, matchedBoarding)

  const servicesAmount = matched.reduce((acc, current) => {
    return acc + parseInt(get(current, 'amount.amount', 0))
  }, 0)

  let offerAmount = parseInt(get(selectedThere, 'options.0.amount.amount', 0))

  if (selectedBack) {
    offerAmount = parseInt(get(selectedBack, 'options.0.amount.amount', 0))
  }

  return servicesAmount + offerAmount
}

export const normalizeAttributes = (attributes, offerThere, offerBack) => {
  const normalize = (input) => {
    const services = concat(get(input, '0.services', []), get(input, '1.services', []))

    const both = get(input, '0.both', false)

    if (both) {
      return {
        both,
        services: {
          [offerThere.uuid]: services,
          [offerBack.uuid]: services,
        },
      }
    }

    const servicesByOffers = groupBy(services, 'offer_id')
    return {
      both,
      services: {
        ...servicesByOffers,
      },
    }
  }

  const available = normalize(get(attributes, 'additionalServices', {}))
  const booked = normalize(get(attributes, 'bookedServices', {}))
  const requested = get(attributes, 'requestedServices', {})

  return {
    ...attributes,
    additionalServices: available,
    bookedServices: booked,
    requestedServices: requested,
  }
}
