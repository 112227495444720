import React from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import Button from '../../ui/ButtonComponent'
import CardsTable from './partials/CardsTable'
import trans from '../../../trans'
import {
  addCard,
  getCards,
  isLoading,
  reset,
  setData,
  reorderCards,
} from '../../../store/app/charge-cards'
import SocketProviderService from '../../../utils/SocketProvider'
import { userProfile } from '../../../store/app/user-profile/providers/userProfile'
import APIClient from '../../../services/APIClient'
import { Loader } from '../../ui/LoadingOverlay/Loader'
import PropTypes from 'prop-types'
import { SelectField, TextField } from '../../ui/Form'
import { SectionHeader } from '../../ui/SectionHeader'

const SOCKET_CHANNEL = 'App.User.'
const SOCKET_EVENT = '.App\\CardManager\\Events\\CardUpdatedEvent'

class ChargeCards extends React.Component<any, any> {
  state = {
    cardholderName: '',
  }

  componentDidMount() {
    const {
      userProfile: {
        selectors: { data: user },
      },
    } = this.props

    this.fetchCards()

    SocketProviderService.subscribe(SOCKET_CHANNEL + user.id)(SOCKET_EVENT)(() => {
      this.fetchCards()
    })
  }

  componentWillUnmount() {
    const {
      userProfile: {
        selectors: { data: user },
      },
      reset,
    } = this.props
    SocketProviderService.unsubscribe(SOCKET_CHANNEL + user.id)(SOCKET_EVENT)
    reset()
  }

  fetchCards() {
    const { setData } = this.props

    return APIClient.getPaymentCards(
      this.props.subjectUser ? this.props.subjectUser.slug : null,
    ).then((response) => {
      setData(response.data)
    })
  }

  setCardholder = (cardholderName: string) => {
    this.setState({ cardholderName })
  }

  render() {
    const { cards, addCard, isLoading, reorderCards } = this.props
    const { cardholderName } = this.state
    const subjectUser = this.props.subjectUser ? this.props.subjectUser : null

    return (
      <div className='charge-cards has-loader'>
        <SectionHeader className={'charge-cards__header'} caption={trans('user.charge-cards')}>
          <div className='charge-cards__header_form'>
            <div className='charge-cards__form_wrapper'>
              <div className={'charge-cards__horizontal'}>
                <div className='charge-cards__inline_form'>
                  <span className='filters__input-title'>
                    {trans('instance-users.card-holder-label')}
                  </span>

                  <TextField
                    placeholder={trans('instance-users.card-holder-label')}
                    value={cardholderName}
                    onChange={(e, val) => {
                      e.preventDefault()

                      this.setCardholder(val)
                    }}
                  />
                </div>
              </div>
            </div>

            <Button
              primary
              className='charge-cards__add-button'
              disabled={!cardholderName}
              onClick={() =>
                addCard(
                  'individual',
                  null,
                  cardholderName,
                  this.props.subjectUser && this.props.subjectUser.slug
                    ? this.props.subjectUser.slug
                    : null,
                )
              }
            >
              {trans('user.add-card')}
            </Button>
          </div>
        </SectionHeader>

        {!isLoading ? (
          <CardsTable
            subjectUser={subjectUser}
            cards={cards}
            reorderCards={reorderCards}
            withPriority
            withCompany
          />
        ) : (
          <Loader />
        )}

        <div className='charge-cards__footer'>
          <img
            src='/images/fdp.png'
            alt='Logo First Data Polcard'
            className='charge-cards__certificate-img'
          />
          <img src='/images/pci.png' alt='Logo PCI' className='charge-cards__certificate-img' />
          <p className='charge-cards__clause'>
            Certyfikat zaświadcza, że dane transakcyjne – takie jak dane karty oraz numery PIN –
            przetwarzane we wszystkich punktach oznaczonych logo FirstData Polcard są szyfrowane,
            przesyłane i przechowywane z zachowaniem restrykcyjnych procedur i standardów
            bezpieczeństwa. Minimalizuje to ryzyko oszustw, co jest ważne zarówno dla właściciela
            karty, jak i punktu przyjmującego płatności bezgotówkowe. Nadzór nad procesem wdrażania
            PCI DSS sprawuje pozarządowa organizacja PCI SSC (Payment Card Industry Security
            Standards Council). Certyfikat wydawany jest na okres 12 miesięcy.
          </p>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  cards: getCards(state),
  isLoading: isLoading(state),
})

const withConnect = connect(mapStateToProps, { addCard, setData, reset, reorderCards })
const withProfile = userProfile(false, false)

const composed = compose(withConnect, withProfile)(ChargeCards)

ChargeCards.propTypes = {
  subjectUser: PropTypes.object,
}

export default composed
