import React, { Component } from 'react'
import GoogleMapsProvider from '../services/GoogleMapsProvider'
import { trans } from '../trans'
import { differenceWith, get, isEqual, isFunction } from 'lodash'
import PropTypes from 'prop-types'

class GoogleDirections extends Component<any, any> {
  constructor(props) {
    super(props)

    this.state = {
      results: false,
    }

    this.bounds = null
    this.currentMapObjects = []

    this.googleMapsService = GoogleMapsProvider
    this.mapContainer = React.createRef()
  }

  componentDidMount() {
    this.initMap()
    this.getRoutesByCoordinates()
  }

  componentDidUpdate = (prevProps, prevState) => {
    if (
      !this.areRoutesSame(this.props.routes, prevProps.routes) ||
      prevState.results !== this.state.results
    ) {
      this.clearMap()
      this.getRoutesByCoordinates()
    }
  }

  initMap() {
    if (!this.map) {
      this.map = new google.maps.Map(this.mapContainer.current, {
        zoom: this.props.zoom,
        center: this.props.center,
      })
      this.distanceWindow = new google.maps.InfoWindow()
      this.bounds = new google.maps.LatLngBounds()
    }
  }

  clearMap = () => {
    this.currentMapObjects.forEach((object) => object.setMap(null))
    this.currentMapObjects = []
    this.distanceWindow.close()
  }

  areRoutesSame = (prevRoutes, currentRoutes) => {
    return !differenceWith(currentRoutes, prevRoutes, isEqual).length
  }

  getRoutesByCoordinates() {
    const { routes } = this.props

    this.bounds = new google.maps.LatLngBounds()
    const promises = []

    routes.forEach((item) => {
      if (!item.isValid()) {
        return
      }

      let { origin, destination } = item

      const routePromise = new Promise((resolve) => {
        this.googleMapsService
          .getRoute(origin, destination)
          .then((response) => {
            resolve(response)
          })
          .catch(() => {
            resolve(null)
          })
      })

      promises.push(routePromise)
    })

    Promise.all(promises).then(this.drawRoutes)
  }

  drawRoutes = (routes) => {
    let distance = 0

    routes = routes.filter((route) => route)

    routes.forEach((route) => {
      let renderer = new google.maps.DirectionsRenderer({ preserveViewport: true })
      renderer.setDirections(route.response)

      renderer.setMap(this.map)

      this.bounds.extend(route.origin)
      this.bounds.extend(route.destination)
      this.currentMapObjects.push(renderer)

      distance += get(route, 'response.routes.0.legs.0.distance.value', 0) / 1000

      let center_point = Math.floor(get(route, 'response.routes.0.overview_path.length', 0) / 2)
      let position = get(route, 'response.routes.0.overview_path.' + center_point, 0)

      if (position !== 0) {
        this.distanceWindow.setContent(
          (Math.round(distance * 100, 2) / 100 + ' km').replace('.', ','),
        )
        this.distanceWindow.setPosition(position)
        this.distanceWindow.open(this.map)
      }
    })

    this.map.fitBounds(this.bounds)
    this.setState({ results: true })

    if (isFunction(this.props.distanceCallback)) {
      this.props.distanceCallback(distance)
    }
  }

  failedFetchingRoutes = (responses) => {
    this.setState({ results: false })
  }

  render() {
    const { results } = this.state
    const { minHeight } = this.props

    return (
      <div style={{ height: '100%', position: 'relative', minHeight: minHeight }}>
        <div style={{ height: '100%' }} ref={this.mapContainer} />
        <span className='google_map-desc'>{trans('map.suggested-google-map')}</span>
        {!results && (
          <div
            style={{
              position: 'absolute',
              left: 0,
              top: 0,
              right: 0,
              bottom: 0,
              height: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              background: '#fff',
            }}
          >
            <span>{trans('map.no-data')}</span>
          </div>
        )}
      </div>
    )
  }
}

GoogleDirections.propTypes = {
  distanceCallback: PropTypes.func,
  routes: PropTypes.array.isRequired,
  zoom: PropTypes.number.isRequired,
  center: PropTypes.object.isRequired,
}

GoogleDirections.defaultProps = {
  origin: { lat: 37.77, lng: -122.447 },
  destination: { lat: 37.768, lng: -122.511 },
  center: { lat: 52.06, lng: 19.48 },
  zoom: 5.5,
}

export default GoogleDirections
export { GoogleDirections }
