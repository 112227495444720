import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import { fromJS } from 'immutable'
import { BasicInfoForm as FormComponent } from '../../components/BasicInfoForm'
import { createAutoSaveForm, getFormValues } from '../../utils/forms'
import APIClient from '../../services/APIClient'
import { getProjectById } from '../../store/app/projects'
import { getCache, setCache } from '../../store/app/account-dimensions'
import { generateAccountDimensionFormValues } from '../../store/app/account-dimensions/form'
import { bindActionCreators } from 'redux'

export const FORM_NAME = 'basic-info-form'

const updateRequest = (id, data, changeRequestValue, meta) => {
  return new Promise((resolve, reject) => {
    APIClient.updateRequest(id, data, meta)
      .then((response) => {
        changeRequestValue(['purpose_edited'], response.data.purpose_edited)
        changeRequestValue(['comments'], response.data.comments)
        resolve(response['data'])
      })
      .catch((response) => {
        reject(response)
      })
  })
}

const BasicInfoFormBase = createAutoSaveForm(FormComponent, {
  name: FORM_NAME,
  save: (name, value, dispatch, props, { confirm = false }) => {
    const { request } = props

    if (name === 'mpk') {
      name = 'mpk_id'
      value = value.id
    }

    return updateRequest(request['slug'], { [name]: value }, props.changeRequestValue, { confirm })
  },
  componentDidMount: (props) => {
    const {
      request: { accountDimensionItems },
      setCache,
    } = props

    setCache(accountDimensionItems)
  },
})

BasicInfoFormBase.propTypes = {
  request: PropTypes.object.isRequired,
  mpks: PropTypes.array.isRequired,
  projects: PropTypes.array.isRequired,
}

const mapStateToProps = (state, props) => {
  const { request, accountDimensions = [] } = props
  const { accountDimensionItems = [] } = request

  return {
    initialValues: fromJS({
      mpk: request['mpk'],
      project_id: request['project_id'],
      purpose: request['purpose'],
      ...generateAccountDimensionFormValues(accountDimensions, accountDimensionItems),
    }),
    project: getProjectById(state, request['project_id']),
    data: getFormValues(FORM_NAME, state),
    currentUser: state.get('global').get('currentUser'),
    accountDimensionItems: getCache(state)(FORM_NAME),
    accountDimensions,
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      setCache: (data) => setCache({ name: FORM_NAME, data }),
    },
    dispatch,
  )
}

const BasicInfoForm = connect(mapStateToProps, mapDispatchToProps)(BasicInfoFormBase)

export { BasicInfoForm }
export default { BasicInfoForm }
