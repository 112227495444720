import APIClient from '../../../services/APIClient'
import { createModule } from '../../modules/list/index'

// TODO probably not used anywhere
const fetchMethod = ({ filters }, source) => {
  return APIClient.getTripRequests(filters, source)
}

const module = createModule({
  namespace: 'trip-requests',
  mountPoint: 'trip-requests',
  paginationByAPI: true,
  fetchMethod,
})

export const { reducer, MOUNT_POINT, actions, selectors } = module
