import React, { Component } from 'react'
import SocketProviderService from '../utils/SocketProvider'

const socketProvider = () => (Component) => {
  return (props) => {
    return (
      <Component
        socket={{
          subscribe: SocketProviderService.subscribe,
          unsubscribe: SocketProviderService.unsubscribe,
          notification: SocketProviderService.notification,
          stopNotifications: SocketProviderService.stopNotifications,
        }}
        {...props}
      />
    )
  }
}

export { socketProvider }
export default socketProvider
