import { stringify } from 'query-string'
import { Request } from './Request'

class HotelsFilterRequest extends Request {
  constructor(searchUUID, with_breakfasts) {
    super()
    this.searchUUID = searchUUID
    this.with_breakfasts = with_breakfasts
  }

  toString() {
    return stringify(this.toJSON())
  }

  toJSON() {
    return {
      searchUUID: this.searchUUID,
      with_breakfasts: this.with_breakfasts,
      sort: this.sort,
    }
  }
}

export default HotelsFilterRequest
export { HotelsFilterRequest }
