import React from 'react'
import { AccordionPanel } from '../../ui/AccordionPanel/index'
import { Label, TYPE_WARNING } from '../../ui/Label/index'
import { Col, Row } from '../../../ui/Grid/index'
import { RouteManager } from '../../../containers/RouteManager/index'
import { DashboardCurrentTripsManager } from '../../../containers/DashboardCurrentTripsManager/index'
import { RequestInfo } from './RequestInfo'
import { DashboardRequestsDocuments } from '../DashboardRequestDocuments/index'
import { Link } from 'react-router-dom'
import Button from '../../ui/ButtonComponent'
import { DashboardRequestsContent } from '../DashboardRequestsContent'
import trans from '../../../trans'
import { Loader } from '../../ui/LoadingOverlay/Loader'

export const DashboardCurrentTrips = (props) => {
  if (!props.counter) {
    return null
  }

  return (
    <div className='dashboard-current-trips'>
      <RouteManager>
        {({ push, getRouteByName }) => {
          return (
            <AccordionPanel
              bar={
                <h1>
                  <Label level={TYPE_WARNING}>{props.counter}</Label>
                  {trans('dashboard-page.current-trips')}
                </h1>
              }
              defaultOpened={false}
              name='current-trips'
              expandOnBarClick
              barTheme='primary'
              noShadow
            >
              {({ isOpen }) => {
                return (
                  <DashboardCurrentTripsManager>
                    {({
                      items,
                      isLoading,
                      isLoaded,
                      instanceCurrency,
                      uploadDocument,
                      currentUser,
                    }) => {
                      const _isLoading = isLoading || !isLoaded

                      return isOpen === true && items.length > 0 ? (
                        <DashboardRequestsContent items={items} isLoading={_isLoading}>
                          {items.map((request) => {
                            return (
                              <Row className='dashboard-current-trips__row' key={request['slug']}>
                                <Col xs={12} sm={3} md={3}>
                                  <RequestInfo request={request} />
                                </Col>
                                <Col
                                  xs={12}
                                  sm={9}
                                  md={9}
                                  style={{ position: 'relative', paddingRight: '165px' }}
                                >
                                  <DashboardRequestsDocuments
                                    getRouteByName={getRouteByName}
                                    push={push}
                                    documents={request['documents']}
                                    instanceCurrency={instanceCurrency}
                                    uploadDocument={uploadDocument}
                                    request={request}
                                    currentUser={currentUser}
                                  />
                                  <Link
                                    to={getRouteByName('main', 'tripRequestShow', {
                                      id: request['slug'],
                                    })}
                                    className='dashboard-current-trips__show-button'
                                  >
                                    <Button outline xs>
                                      {trans('dashboard-page.see-details')}
                                    </Button>
                                  </Link>
                                </Col>
                                <div className='dashboard-current-trips__hr' />
                              </Row>
                            )
                          })}
                        </DashboardRequestsContent>
                      ) : (
                        <div className='has-loader pt-12'>
                          <Loader />
                        </div>
                      )
                    }}
                  </DashboardCurrentTripsManager>
                )
              }}
            </AccordionPanel>
          )
        }}
      </RouteManager>
    </div>
  )
}
