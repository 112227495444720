import appReducer from './containers/App/reducer'
import {
  MOUNT_POINT as notificationsMountPoint,
  reducer as notificationsReducer,
} from './store/app/notifications'
import {
  MOUNT_POINT as tripSummaryMountPoint,
  reducer as tripRequestSummaryReducer,
} from './store/app/trip-request-summary'
import {
  MOUNT_POINT as documentsMountPoint,
  reducer as documentsReducer,
} from './store/app/documents-list'
import {
  MOUNT_POINT as requestsMountPoint,
  reducer as requestsReducer,
} from './store/app/expense-requests'
import {
  MOUNT_POINT as documentExpensesTypesMountPoint,
  reducer as documentExpensesTypesReducer,
} from './store/app/document-element-groups'
import {
  MOUNT_POINT as expenseRequestMountPoint,
  reducer as expenseRequestReducer,
} from './store/app/expense-request'
import {
  MOUNT_POINT as documentAccountPageMountPoint,
  reducer as documentAccountPageReducer,
} from './store/app/document-account-page'
import {
  MOUNT_POINT as settlementRequestsMountPoint,
  reducer as settlementRequestsReducer,
} from './store/app/settlement-requests'
import {
  MOUNT_POINT as tripRequestsMountPoint,
  reducer as tripRequestsReducer,
} from './store/app/trip-requests'
import {
  MOUNT_POINT as agentTripRequestsMountPoint,
  reducer as agentTripRequestsReducer,
} from './store/app/agent-trip-requests'
import {
  MOUNT_POINT as dashboardCounter,
  reducer as dashboardCounterReducer,
} from './store/app/dashboard-counter'
import {
  MOUNT_POINT as dashboardRequestsToAcceptMountPoint,
  reducer as dashboardRequestsToAcceptReducer,
} from './store/app/dashboard-requests-to-accept'
import {
  MOUNT_POINT as dashboardCurrentTripsMountPoint,
  reducer as dashboardCurrentTripsReducer,
} from './store/app/dashboard-current-trips'
import {
  MOUNT_POINT as dashboardWaitingToSettlementMountPoint,
  reducer as dashboardWaitingToSettlementReducer,
} from './store/app/dashboard-waiting-to-settlement'
import {
  MOUNT_POINT as requestMileageAllowanceReducerMountPoint,
  reducer as requestMileageAllowanceReducer,
} from './store/app/request-mileage-allowance'
import {
  MOUNT_POINT as tripRequestMountPoint,
  reducer as tripRequestReducer,
} from './store/app/trip-request'
import {
  MOUNT_POINT as documentExpenseTypesMountPoint,
  reducer as documentExpenseTypesReducer,
} from './store/app/document-expense-types'
import {
  MOUNT_POINT as tripTimelineMountPoint,
  reducer as tripTimelineReducer,
} from './store/app/trip-timeline'
import {
  MOUNT_POINT as allRequestsMountPoint,
  reducer as allRequestsReducer,
} from './store/app/all-requests'
import {
  MOUNT_POINT as instanceUsersMountPoint,
  reducer as instanceUsersReducer,
} from './store/app/instance-users'
import {
  MODULE_MOUNT_POINT as flightsBookingMountPoint,
  reducer as flightsBookingReducer,
} from './store/app/flights-booking'
import {
  MOUNT_POINT as documentOfferMountPoint,
  reducer as documentOfferReducer,
} from './store/app/document-offer'

import { combineReducers } from 'redux-immutable'
import { reducer as formReducer } from 'redux-form/immutable'
import userProfileSlice, {
  USER_PROFILE_MOUNT_POINT,
} from './store/app/user-profile/userProfileSlice'
import accountDimensionsSlice, {
  ACCOUNT_DIMENSION_MOUNT_POINT,
} from './store/app/account-dimensions/index'
import mpkSlice, { MPK_MOUNT_POINT } from './store/app/mpks'
import countriesSlice, { COUNTRIES_MOUNT_POINT } from './store/app/countries/slice'
import companiesSlice, { COMPANY_MOUNT_POINT } from './store/app/companies/slice'
import gradeSlice, { GRADE_MOUNT_POINT } from './store/app/grades/slice'
import transactionSuggestionSlice, {
  TRANSACTION_SUGGESTION_MOUNT_POINT,
} from './store/app/transaction-suggestion'
import usersForSelectSlice, { USERS_FOR_SELECT_MOUNT_POINT } from './store/app/users-for-select'
import rentCarOrderEnumValuesSlice, {
  RENT_CAR_ORDER_ENUM_VALUES,
} from './store/app/rent-car-order-car-types'
import userGroupsSlice, { USER_GROUPS_MOUNT_POINT } from './store/app/user-groups'
import instanceSlice, { INSTANCE_MOUNT_POINT } from './store/app/instance'
import projectSlice, { PROJECT_MOUNT_POINT } from './store/app/projects'
import kpiSlice, { KPI_MOUNT_POINT } from './store/app/kpis'
import accountingAccountSlice, {
  ACCOUNTING_ACCOUNT_MOUNT_POINT,
} from './store/app/accounting-account'
import agentTripRequestOwnerSlice, {
  AGENT_TRIP_REQUEST_OWNER_MOUNT_POINT,
} from './store/app/agent-trip-requests-owner'
import expenseGroupSlice, { EXPENSE_GROUP_MOUNT_POINT } from './store/app/expense-group'
import expenseTypeSlice, { EXPENSE_TYPE_MOUNT_POINT } from './store/app/expense-type'
import vatSlice, { VAT_MOUNT_POINT } from './store/app/vat'
import loggableSlice, { LOGGABLE_MOUNT_POINT } from './store/app/login-as'
import targetPointsSlice, { TARGET_POINTS_MOUNT_POINT } from './store/app/target-points'
import requestTravelerSlice, { REQUEST_TRAVELER_MOUNT_POINT } from './store/app/request-traveler'
import importsSlice, { IMPORTS_MOUNT_POINT } from './store/app/imports'
import tableConfigSlice, { TABLE_CONFIG_MOUNT_POINT } from './store/app/table-config'
import {
  reducer as hotelsReducer,
  MODULE_MOUNT_POINT as HOTELS_MOUNT_POINT,
} from './store/app/hotels-booking'
import { connectRouter } from 'connected-react-router/immutable'

import {
  reducer as externalCompanyHierarchyReducer,
  MODULE_MOUNT_POINT as EXTERNAL_COMPANY_HIERARCHY_MODULE_MOUNT_POINT,
} from './store/app/external-company-hierarchy'
import {
  reducer as companyHierarchyReducer,
  MODULE_MOUNT_POINT as COMPANY_HIERARCHY_MODULE_MOUNT_POINT,
} from './store/app/company-hierarchy'
import {
  reducer as chargeCardsReducer,
  MOUNT_POINT as CHARGE_CARDS_MODULE_MOUNT_POINT,
} from './store/app/charge-cards'
import {
  reducer as assistantsReducer,
  MOUNT_POINT as ASSISTANTS_MODULE_MOUNT_POINT,
} from './store/app/assistants'
import {
  reducer as otherCostsReducer,
  MOUNT_POINT as OTHER_COSTS_MODULE_MOUNT_POINT,
} from './store/app/request-other-costs'
import {
  reducer as substitutionsReducer,
  MOUNT_POINT as SUBSTITUTIONS_MODULE_MOUNT_POINT,
} from './store/app/substitutions'
import {
  reducer as avatarReducer,
  MOUNT_POINT as AVATAR_MODULE_MOUNT_POINT,
} from './store/app/avatar'
import {
  reducer as borderCrossingReducer,
  MODULE_MOUNT_POINT as BORDER_CROSSING_MODULE_MOUNT_POINT,
} from './store/app/border-crossings'
import {
  reducer as commentsReducer,
  MOUNT_POINT as COMMENTS_MODULE_MOUNT_POINT,
} from './store/app/comments'
import {
  reducer as dashboardSettlementToAcceptReducer,
  MODULE_MOUNT as DASHBOARD_SETTLEMENT_TO_ACCEPT_MODULE_MOUNT_POINT,
} from './store/app/dashboard-settlements-to-accept'
import {
  reducer as edgePointsReducer,
  MOUNT_POINT as EDGE_POINTS_MODULE_MOUNT_POINT,
} from './store/app/edge-points'
import {
  reducer as LoginReducer,
  MOUNT_POINT as LOGIN_MODULE_MOUNT_POINT,
} from './containers/LoginPage/reducer'
import {
  reducer as modelRulesReducer,
  MOUNT_POINT as MODEL_RULES_MODULE_MOUNT_POINT,
} from './store/app/model-rules'
import {
  reducer as nonDelegationReducer,
  MODULE_MOUNT_POINT as NON_DELEGATION_MODULE_MOUNT_POINT,
} from './store/app/non-delegation'
import {
  reducer as requestAcceptorReducer,
  MOUNT_POINT as REQUEST_ACCEPTOR_MODULE_MOUNT_POINT,
} from './store/app/request-acceptors'
import {
  reducer as requestActionReducer,
  MOUNT_POINT as REQUEST_ACTION_MODULE_MOUNT_POINT,
} from './store/app/request-action'
import {
  reducer as tripDidNotHavePlaceReducer,
  MODULE_MOUNT_POINT as TRIP_DID_NOT_HAVE_PLACE_MODULE_MOUNT_POINT,
} from './store/app/trip-did-not-have-place'
import {
  reducer as complianceReducer,
  MODULE_MOUNT_POINT as COMPLIANCE_MODULE_MOUNT_POINT,
} from './store/app/compliance'
import {
  reducer as currenciesReducer,
  MODULE_MOUNT_POINT as CURRENCIES_MODULE_MOUNT_POINT,
} from './store/app/currencies'
import {
  reducer as lumpSumDeductionsReducer,
  MODULE_MOUNT_POINT as LUMP_SUM_DEDUCTIONS_MODULE_MOUNT_POINT,
} from './store/app/deductions/lump-sums-deductions'
import {
  reducer as mealSumDeductionsReducer,
  MODULE_MOUNT_POINT as MEAL_DEDUCTIONS_MODULE_MOUNT_POINT,
} from './store/app/deductions/meal-deductions'
import {
  reducer as destinationsReducer,
  MODULE_MOUNT_POINT as DESTINATIONS_MODULE_MOUNT_POINT,
} from './store/app/destinations'
import {
  reducer as documentListReducer,
  MODULE_MOUNT_LIST as DOCUMENT_LIST_MODULE_MOUNT_POINT,
} from './store/app/document-list'
import {
  reducer as exchangeRateReducer,
  MODULE_MOUNT_POINT as EXCHANGE_RATE_MODULE_MOUNT_POINT,
} from './store/app/exchange-rates'
import {
  reducer as installmentsReducer,
  MOUNT_POINT as INSTALLMENTS_MODULE_MOUNT_POINT,
} from './store/app/installments'
import {
  reducer as languageReducer,
  MODULE_MOUNT_POINT as LANGUAGE_MODULE_MOUNT_POINT,
} from './store/app/languages'
import {
  reducer as lastRequestsReducer,
  MODULE_MOUNT_POINT as LAST_REQUESTS_MODULE_MOUNT_POINT,
} from './store/app/last-requests'
import {
  reducer as rangeMapReducer,
  MODULE_MOUNT_POINT as RANGE_MAP_MODULE_MOUNT_POINT,
} from './store/app/range-map'
import {
  reducer as reportsReducer,
  MOUNT_POINT as REPORTS_MODULE_MOUNT_POINT,
} from './store/app/reports'
import {
  reducer as trainStationsReducer,
  MODULE_MOUNT_POINT as TRAIN_STATIONS_MODULE_MOUNT_POINT,
} from './store/app/train-stations'
import {
  reducer as trainBookingReducer,
  MODULE_MOUNT_POINT as TRAIN_BOOKING_MODULE_MOUNT_POINT,
} from './store/app/trains-booking'
import documentReducer, { DOCUMENT_MOUNT_POINT } from './store/app/document'

/**
 * Creates the main reducer with the dynamically injected ones
 */
export default function createReducer(history, injectedReducers) {
  return combineReducers({
    router: connectRouter(history),
    global: appReducer,
    [tripSummaryMountPoint]: tripRequestSummaryReducer,
    [documentsMountPoint]: documentsReducer,
    [requestsMountPoint]: requestsReducer,
    [documentExpensesTypesMountPoint]: documentExpensesTypesReducer,
    [expenseRequestMountPoint]: expenseRequestReducer,
    [documentAccountPageMountPoint]: documentAccountPageReducer,
    [settlementRequestsMountPoint]: settlementRequestsReducer,
    [tripRequestsMountPoint]: tripRequestsReducer,
    [agentTripRequestsMountPoint]: agentTripRequestsReducer,
    [notificationsMountPoint]: notificationsReducer,
    [dashboardCounter]: dashboardCounterReducer,
    [dashboardRequestsToAcceptMountPoint]: dashboardRequestsToAcceptReducer,
    [dashboardCurrentTripsMountPoint]: dashboardCurrentTripsReducer,
    [dashboardWaitingToSettlementMountPoint]: dashboardWaitingToSettlementReducer,
    [requestMileageAllowanceReducerMountPoint]: requestMileageAllowanceReducer,
    [tripRequestMountPoint]: tripRequestReducer,
    form: formReducer,
    [documentExpenseTypesMountPoint]: documentExpenseTypesReducer,
    [tripTimelineMountPoint]: tripTimelineReducer,
    [allRequestsMountPoint]: allRequestsReducer,
    [instanceUsersMountPoint]: instanceUsersReducer,
    [flightsBookingMountPoint]: flightsBookingReducer,
    [documentOfferMountPoint]: documentOfferReducer,
    [HOTELS_MOUNT_POINT]: hotelsReducer,
    [USER_PROFILE_MOUNT_POINT]: userProfileSlice,
    [MPK_MOUNT_POINT]: mpkSlice,
    [COUNTRIES_MOUNT_POINT]: countriesSlice,
    [COMPANY_MOUNT_POINT]: companiesSlice,
    [GRADE_MOUNT_POINT]: gradeSlice,
    [USERS_FOR_SELECT_MOUNT_POINT]: usersForSelectSlice,
    [RENT_CAR_ORDER_ENUM_VALUES]: rentCarOrderEnumValuesSlice,
    [USER_GROUPS_MOUNT_POINT]: userGroupsSlice,
    [INSTANCE_MOUNT_POINT]: instanceSlice,
    [PROJECT_MOUNT_POINT]: projectSlice,
    [KPI_MOUNT_POINT]: kpiSlice,
    [ACCOUNTING_ACCOUNT_MOUNT_POINT]: accountingAccountSlice,
    [IMPORTS_MOUNT_POINT]: importsSlice,
    [TABLE_CONFIG_MOUNT_POINT]: tableConfigSlice,
    [EXPENSE_GROUP_MOUNT_POINT]: expenseGroupSlice,
    [EXPENSE_TYPE_MOUNT_POINT]: expenseTypeSlice,
    [VAT_MOUNT_POINT]: vatSlice,
    [LOGGABLE_MOUNT_POINT]: loggableSlice,
    [REQUEST_TRAVELER_MOUNT_POINT]: requestTravelerSlice,
    [TARGET_POINTS_MOUNT_POINT]: targetPointsSlice,
    [ACCOUNT_DIMENSION_MOUNT_POINT]: accountDimensionsSlice,
    [AGENT_TRIP_REQUEST_OWNER_MOUNT_POINT]: agentTripRequestOwnerSlice,

    [EXTERNAL_COMPANY_HIERARCHY_MODULE_MOUNT_POINT]: externalCompanyHierarchyReducer,
    [COMPANY_HIERARCHY_MODULE_MOUNT_POINT]: companyHierarchyReducer,
    [CHARGE_CARDS_MODULE_MOUNT_POINT]: chargeCardsReducer,
    [ASSISTANTS_MODULE_MOUNT_POINT]: assistantsReducer,
    [OTHER_COSTS_MODULE_MOUNT_POINT]: otherCostsReducer,
    [SUBSTITUTIONS_MODULE_MOUNT_POINT]: substitutionsReducer,
    [AVATAR_MODULE_MOUNT_POINT]: avatarReducer,
    [BORDER_CROSSING_MODULE_MOUNT_POINT]: borderCrossingReducer,
    [COMMENTS_MODULE_MOUNT_POINT]: commentsReducer,
    [DASHBOARD_SETTLEMENT_TO_ACCEPT_MODULE_MOUNT_POINT]: dashboardSettlementToAcceptReducer,
    [EDGE_POINTS_MODULE_MOUNT_POINT]: edgePointsReducer,
    [LOGIN_MODULE_MOUNT_POINT]: LoginReducer,
    [MODEL_RULES_MODULE_MOUNT_POINT]: modelRulesReducer,
    [NON_DELEGATION_MODULE_MOUNT_POINT]: nonDelegationReducer,
    [REQUEST_ACCEPTOR_MODULE_MOUNT_POINT]: requestAcceptorReducer,
    [REQUEST_ACTION_MODULE_MOUNT_POINT]: requestActionReducer,
    [TRIP_DID_NOT_HAVE_PLACE_MODULE_MOUNT_POINT]: tripDidNotHavePlaceReducer,
    [COMPLIANCE_MODULE_MOUNT_POINT]: complianceReducer,
    [CURRENCIES_MODULE_MOUNT_POINT]: currenciesReducer,
    [LUMP_SUM_DEDUCTIONS_MODULE_MOUNT_POINT]: lumpSumDeductionsReducer,
    [MEAL_DEDUCTIONS_MODULE_MOUNT_POINT]: mealSumDeductionsReducer,
    [DESTINATIONS_MODULE_MOUNT_POINT]: destinationsReducer,
    [DOCUMENT_LIST_MODULE_MOUNT_POINT]: documentListReducer,
    [EXCHANGE_RATE_MODULE_MOUNT_POINT]: exchangeRateReducer,
    [INSTALLMENTS_MODULE_MOUNT_POINT]: installmentsReducer,
    [LANGUAGE_MODULE_MOUNT_POINT]: languageReducer,
    [LAST_REQUESTS_MODULE_MOUNT_POINT]: lastRequestsReducer,
    [RANGE_MAP_MODULE_MOUNT_POINT]: rangeMapReducer,
    [REPORTS_MODULE_MOUNT_POINT]: reportsReducer,
    [TRAIN_STATIONS_MODULE_MOUNT_POINT]: trainStationsReducer,
    [TRAIN_BOOKING_MODULE_MOUNT_POINT]: trainBookingReducer,
    [TRANSACTION_SUGGESTION_MOUNT_POINT]: transactionSuggestionSlice,
    [DOCUMENT_MOUNT_POINT]: documentReducer,
    ...injectedReducers,
  })
}
