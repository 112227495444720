import React from 'react'
import { Route, Switch } from 'react-router-dom'

export function TabsContent({ match, items = [], defaultTabContent = {} }) {
  return (
    <div className='tabs__content has-loader'>
      <Switch>
        <Route exact path={match.path}>
          {defaultTabContent.component}
        </Route>

        {items.map((item) => {
          return (
            <Route key={item.id} path={item.path}>
              {item.component}
            </Route>
          )
        })}
      </Switch>
    </div>
  )
}
