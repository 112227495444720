import PropTypes from 'prop-types'
import React from 'react'
import Autosuggest from 'react-autosuggest'
import APIClient from '../../../services/APIClient'
import { throttle } from 'lodash'
import axios from 'axios'
import { fromJS, Map } from 'immutable'

export class AirportField extends React.Component<any, any> {
  constructor(props) {
    super(props)

    let { value } = this.props

    if (value instanceof Map) {
      value = value.toJS()
    }

    this.state = {
      airports: [],
      value: value.formatted_address ? value.formatted_address : '',
      isProcessing: false,
    }

    this.source = axios.CancelToken.source()
  }

  onSuggestionsFetchRequested() {
    if (this.state.value.length < 2) {
      return
    }

    if (this.state.isProcessing) {
      this.source.cancel()
      this.source = axios.CancelToken.source()

      this.setState({ isProcessing: false })
    }

    this.setState({ isProcessing: true })

    APIClient.searchAirport(this.state.value, this.source).then((response) => {
      this.setState({ isProcessing: false, airports: response.data })
    })
  }

  onSuggestionsClearRequested() {
    this.setState({
      airports: [],
    })
  }

  getSuggestionValue(suggestion) {
    return `${suggestion.name}, ${suggestion.city}, ${suggestion.country} (${suggestion.code})`
  }

  renderSuggestion(suggestion, { query, isHighlighted }) {
    return (
      <span>
        {suggestion.name}, {suggestion.city}, {suggestion.country} ({suggestion.code})
      </span>
    )
  }

  onChangeHandler(event, { newValue, method }) {
    this.setState({ value: newValue })
  }

  onSuggestionSelected(event, { suggestion }) {
    const { onChange, immutable = false } = this.props

    const fields = {
      country: suggestion.country,
      country_code: suggestion.country_code,
      city: suggestion.city,
      province: suggestion.state,
      address: null,
      long: suggestion.long,
      lat: suggestion.lat,
      name: suggestion.name,
      formatted_address: `${suggestion.name}, ${suggestion.city}, ${suggestion.country} (${suggestion.code})`,
      additional_data: suggestion.code,
    }

    if (immutable) {
      onChange(fromJS(fields))
      return
    }

    onChange(fields)
  }

  render() {
    const { name, placeholder, disabled } = this.props

    return (
      <Autosuggest
        suggestions={this.state.airports}
        onSuggestionsFetchRequested={this.onSuggestionsFetchRequested.bind(this)}
        onSuggestionsClearRequested={this.onSuggestionsClearRequested.bind(this)}
        getSuggestionValue={this.getSuggestionValue.bind(this)}
        renderSuggestion={this.renderSuggestion.bind(this)}
        onSuggestionSelected={this.onSuggestionSelected.bind(this)}
        inputProps={{
          name,
          value: this.state.value,
          placeholder,
          onChange: this.onChangeHandler.bind(this),
          className: 'input input--text autocomplete__input',
        }}
        disabled={disabled}
      />
    )
  }
}

AirportField.propTypes = {
  onChange: PropTypes.func,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  value: PropTypes.any,
  disabled: PropTypes.bool,
}

AirportField.defaultProps = {
  options: {},
  disabled: false,
}
