import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import { SmartCheckbox } from '../../../../ui/Input/SmartCheckbox'
import { Dialog } from '../../../Dialog'
import Button from '../../../ui/ButtonComponent'
import trans from '../../../../trans'

class MealExpenseForm extends Component<any, any> {
  state = {
    breakfastPopupOpen: false,
  }

  changeBreakfast = (value) => {
    if (value === 'dummy') {
      this.setState({ breakfastPopupOpen: true })
    } else {
      this.props.change('breakfast', value)
    }
  }

  confirmChangeBreakfast = () => {
    this.setState({ breakfastPopupOpen: false })
    this.props.change('breakfast', false)
  }

  render() {
    return (
      <Fragment>
        <div className='declarations__checkbox declarations__grid-column'>
          <SmartCheckbox
            name='breakfast'
            checked={this.props.values.breakfast}
            disabled={this.props.disabled.breakfast || this.props.values.locked_breakfast}
            dummyClick={
              this.props.values.default_breakfast && this.props.values.breakfast ? () => {} : null
            }
            onChange={this.changeBreakfast}
          />

          <Dialog
            open={this.state.breakfastPopupOpen}
            onClose={() => this.setState({ breakfastPopupOpen: false })}
            actionButtons={[
              {
                title: trans('deductions-widget.confirm-accommodation'),
                onClick: this.confirmChangeBreakfast,
              },
            ]}
          >
            {trans('deductions-widget.price-included-breakfast')}
          </Dialog>
        </div>

        <div className='declarations__checkbox declarations__grid-column'>
          <SmartCheckbox
            name='lunch'
            checked={this.props.values.lunch}
            disabled={this.props.disabled.lunch}
            onChange={(value) => this.props.change('lunch', value)}
          />
        </div>

        <div className='declarations__checkbox declarations__grid-column'>
          <SmartCheckbox
            name='dinner'
            checked={this.props.values.dinner}
            disabled={this.props.disabled.dinner}
            onChange={(value) => this.props.change('dinner', value)}
          />
        </div>
      </Fragment>
    )
  }
}

MealExpenseForm.propTypes = {
  values: PropTypes.object.isRequired,
  disabled: PropTypes.object.isRequired,
}

export default MealExpenseForm
export { MealExpenseForm }
