import React from 'react'
import { bindActionCreators } from 'redux'
import { fetch, reset, set } from '../../store/app/company-hierarchy/creators'
import { connect } from 'react-redux'

const provide = (Component) => {
  class CompanyHierarchyProvider extends React.Component<any, any> {
    componentDidMount() {
      const { reset, fetch } = this.props
      reset()
      fetch()
    }

    componentWillUnmount() {
      const { reset } = this.props
      reset()
    }

    render() {
      return <Component<any, any> {...this.props} />
    }
  }

  const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
      {
        reset,
        set,
        fetch,
      },
      dispatch,
    )
  }

  return connect(null, mapDispatchToProps)(CompanyHierarchyProvider)
}

export default provide
export { provide }
