import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import Button from '../ui/ButtonComponent'
import OperatorLogo from './OperatorLogo'
import { get } from 'lodash'
import moment from 'moment'
import { trans } from '../../trans'
import Availability from './Availability'
import { Loader } from '../ui/LoadingOverlay/Loader'
import Icon from '../ui/IconComponent'
import { Tooltip } from '../Tooltip'

class ListItem extends Component<any, any> {
  renderAvailabilityButton = () => {
    const { offer, selectOffer } = this.props
    const salable = get(offer, 'option.attributes.salable', false) === 'Y'
    return salable ? (
      <Button primary className='train-trip__dialog-select-button' onClick={selectOffer}>
        {trans('trains-booking.choose')}
      </Button>
    ) : (
      <Tooltip
        className='selected-hotel__offer-icon'
        title={trans('trains-booking.no-availability-tooltip')}
        sticky
        stickyDuration={0}
        arrow
      >
        <Button
          primary
          className='train-trip__dialog-select-button train-trip__dialog-select-button--disabled'
          onClick={selectOffer}
          disabled
        >
          {trans('trains-booking.choose')}
        </Button>
      </Tooltip>
    )
  }

  render() {
    const { offer } = this.props
    const option = offer.option

    const arrival = moment(get(offer, 'attributes.arrivalDate', null))
    const departure = moment(get(offer, 'attributes.departureDate', null))
    const operator = get(offer, 'attributes.equipmentCode', '')
    const trainNumber = get(offer, 'attributes.trainNumber', '')
    const travelTime = get(offer, 'attributes.travelTime', '')
    const amount = get(offer, 'option.amount.formatted', '0,00 zł').toLowerCase()
    const travelClass =
      get(option, 'attributes.service_class', null) === '1'
        ? trans('trains-booking.travel-class-item-first')
        : trans('trains-booking.travel-class-item-second')

    let warning = null

    if (option.rules && option.rules.length > 0) {
      warning = (
        <Tooltip
          useContext
          interactive
          position='top'
          popperOptions={{
            modifiers: {
              preventOverflow: {
                enabled: false,
              },
            },
            data: {
              placement: 'top',
              originalPlacement: 'top',
            },
          }}
          html={<span>{trans(option.rules[0].message.name, option.rules[0].message.params)}</span>}
        >
          <Icon className='is-color-warning selected-hotel__price-icon' type='warning' />
        </Tooltip>
      )
    }

    return (
      <li className='train-trip__dialog-ticket'>
        {/*<Loader />*/}
        <OperatorLogo offer={offer} />

        <div className='train-trip__dialog-ticket-details'>
          <span className='train-trip__dialog-ticket-departure-hours'>
            {departure.format('HH:mm')} - {arrival.format('HH:mm')}
          </span>
          <div className='train-trip__dialog-ticket-provider'>
            <span>{operator}&nbsp;</span>
            <span>{trainNumber}</span>
          </div>
        </div>

        <div className='train-trip__dialog-ticket-travel-details'>
          <div className='train-trip__dialog-ticket-travel-detail'>
            <span className='train-trip__dialog-ticket-travel-details-title'>
              {trans('trains-booking.travel-time')}
            </span>
            <span className='train-trip__dialog-ticket-travel-details-value'>{travelTime}</span>
          </div>

          <div className='train-trip__dialog-ticket-travel-detail'>
            <Availability offer={offer} />
          </div>

          <div className='train-trip__dialog-ticket-travel-detail'>
            <span className='train-trip__dialog-ticket-travel-details-title'>
              {trans('trains-booking.travel-class')}
            </span>
            <span className='train-trip__dialog-ticket-travel-details-value'>{travelClass}</span>
          </div>
        </div>

        <div className='train-trip__dialog-ticket-select'>
          {warning}
          <span className='train-trip__dialog-ticket-price'>{amount}</span>
          {this.renderAvailabilityButton()}
        </div>
      </li>
    )
  }
}

ListItem.propTypes = {
  offer: PropTypes.object.isRequired,
  selectOffer: PropTypes.func.isRequired,
}

export default ListItem
export { ListItem }
