import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import Icon, { EXPENSES_TYPES_TO_ICONS } from '../../ui/IconComponent'
import { ExpenseTypeBox } from '../../ExpenseTypeBox'
import { AmountFormatter } from '../../AmountFormatter'
import trans from '../../../trans'
import { getSumOfExpenseTypes } from '../../../store/app/document-expense-types'

class ExpenseTypesPrivateExpenseGroup extends React.Component<any, any> {
  anyGroupsHasValueOrIsActive() {
    const { groups } = this.props
    return groups.filter((g) => g.sum > 0 || g.isActive).length
  }

  render() {
    const { document, sum } = this.props

    let remaining = document.gross - sum

    return (
      <ExpenseTypeBox
        icon={<Icon type={EXPENSES_TYPES_TO_ICONS['expense-private']} className='is-orange' />}
        title={trans('cost-type.private-expense')}
        key={`group-private-expense`}
        isActive={!this.anyGroupsHasValueOrIsActive() || !!remaining}
        itemWidth={'25%'}
        onClick={() => {}}
        ref={(ref) => (this.ref = ref)}
      >
        <AmountFormatter amount={remaining} />
      </ExpenseTypeBox>
    )
  }
}

ExpenseTypesPrivateExpenseGroup.propTypes = {
  document: PropTypes.object.isRequired,
}

const mapStateToProps = (state) => ({
  sum: getSumOfExpenseTypes(state),
})

ExpenseTypesPrivateExpenseGroup = connect(mapStateToProps)(ExpenseTypesPrivateExpenseGroup)

export { ExpenseTypesPrivateExpenseGroup }
export default ExpenseTypesPrivateExpenseGroup
