import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { get } from 'lodash'

export const MODULE_MOUNT_POINT = 'exchange'

const RESET = MODULE_MOUNT_POINT + '::reset'
const SET = MODULE_MOUNT_POINT + '::set'
const DESTROY = MODULE_MOUNT_POINT + '::destroy'

// actions Creators
const reset = (exchangeName) => () => (dispatch) => {
  dispatch({
    type: RESET,
    payload: {
      exchangeName,
    },
  })
}

const destroy = (exchangeName) => () => (dispatch) => {
  dispatch({
    type: DESTROY,
    payload: {
      exchangeName,
    },
  })
}

const set = (exchangeName) => (rate) => (dispatch) => {
  dispatch({
    type: SET,
    payload: {
      exchangeName,
      rate,
    },
  })
}

const fetch = (exchangeName) => (date, currency) => (dispatch) => {
  // date = moment(date).format(config.apiDateFormat);
  // APIClient.getExchangeRate(date, currency).then(response => {
  //     dispatch(set(exchangeName)(response.data[0].rate));
  // });
}

const getInitialState = () => {
  return {}
}

//actions
const resetAction = (state, payload) => {
  return {
    ...state,
    [payload.exchangeName]: {
      rate: null,
    },
  }
}

const destroyAction = (state, payload) => {
  let newState = state
  delete newState[payload.exchangeName]
  return newState
}

const setAction = (state, payload) => {
  return {
    ...state,
    [payload.exchangeName]: {
      rate: payload.rate,
    },
  }
}

const actions = {
  [RESET]: resetAction,
  [SET]: setAction,
  [DESTROY]: destroyAction,
}

//selectors
const getRate = (exchangeName) => (state) => {
  return get(state.get(MODULE_MOUNT_POINT), `${exchangeName}.rate`, null)
}

export const reducer = (state = getInitialState(), action) => {
  if (actions.hasOwnProperty(action.type)) {
    return actions[action.type](state, action.payload)
  }
  return state
}

const exchange =
  ({ exchangeName = null, resetOnMount = false, fetchOnMount = false, destroyOnUnmount = false }) =>
  (Component) => {
    class Exchange extends React.Component<any, any> {
      constructor(props) {
        super(props)
      }

      componentDidMount() {
        const { exchange } = this.props

        if (exchangeName === null) {
          exchangeName = exchange.exchangeName
        }

        if (resetOnMount) {
          const {
            actions: { reset },
          } = exchange
          reset()
        }

        if (fetchOnMount) {
          const {
            actions: { fetch },
            date,
            currency,
          } = exchange
          fetch(date, currency)
        }
      }

      componentWillUnmount() {
        const { exchange } = this.props

        if (exchangeName === null) {
          exchangeName = exchange.exchangeName
        }

        if (destroyOnUnmount) {
          exchange.actions.destroy()
        }
      }

      render() {
        return <Component<any, any> {...this.props} />
      }
    }

    const mapStateToProps = (state, props) => {
      const { exchange } = props

      if (exchangeName === null) {
        exchangeName = props.exchange.exchangeName
      }

      return {
        rate: getRate(exchangeName)(state),
      }
    }

    const mapDispatchToProps = (dispatch, props) => {
      if (exchangeName === null) {
        exchangeName = props.exchange.exchangeName
      }

      return bindActionCreators(
        {
          reset: reset(exchangeName),
          set: set(exchangeName),
          destroy: destroy(exchangeName),
          fetch: fetch(exchangeName),
        },
        dispatch,
      )
    }

    const mergedProps = (selectors, actions, own) => {
      return {
        ...own,
        exchange: {
          selectors,
          actions,
          ...own.exchange,
        },
      }
    }

    return connect(mapStateToProps, mapDispatchToProps, mergedProps)(Exchange)
  }

export { exchange }
export default exchange
