import React from 'react'
import trans from '../../trans'

const Steps = ({ step }) => {
  return (
    <div>
      <span className='border-crossings__heading'>
        {trans('deductions-widget.current-deductions-status')}
      </span>

      <div
        className={`border-crossings__steps ${step === 2 ? 'border-crossings__steps--done' : ''}`}
      >
        <div className='border-crossings__step border-crossings__step--first border-crossings__step--active'>
          <span className='border-crossings__step-number'>1</span>
          <span className='border-crossings__step-label'>
            {trans('deductions-widget.trip-type')}
          </span>
        </div>

        <div
          className={`border-crossings__step border-crossings__step--last ${
            step === 2 ? 'border-crossings__step--active' : ''
          }`}
        >
          <span className='border-crossings__step-number'>2</span>
          <span className='border-crossings__step-label'>
            {trans('deductions-widget.declarations')}
          </span>
        </div>
      </div>
    </div>
  )
}

export default Steps
export { Steps }
