import {
  FLIGHT_TYPE_RETURN,
  FLIGHT_TYPE_TARGET,
  getInitialState,
  QUERY_FIELD_NAMES,
  SELECT_MODE_BACK,
  SELECT_MODE_THERE,
} from './index'
import { cloneDeep, find, get, isArray, mergeWith, unset, set as _set } from 'lodash'
import { Map } from 'immutable'

//actions
export const reset = (state, payload) => {
  return {
    ...state,
    [payload.name]: getInitialState(),
  }
}

export const set = (state, payload) => {
  return {
    ...state,
    [payload.name]: {
      ...state[payload.name],
      ...payload.data,
    },
  }
}

const getSelectedFromOffers = (offers) => {
  let newSelected = {}

  const selectedThere = find(
    offers,
    (offer) =>
      get(offer, 'attributes.flightType', null) === FLIGHT_TYPE_TARGET &&
      get(offer, 'options.0.chosen', false),
  )

  if (selectedThere) {
    newSelected = {
      [SELECT_MODE_THERE]: selectedThere,
    }
  }

  const selectedBack = find(
    offers,
    (offer) =>
      get(offer, 'attributes.flightType', null) === FLIGHT_TYPE_RETURN &&
      get(offer, 'options.0.chosen', false),
  )

  if (selectedBack) {
    newSelected = {
      ...newSelected,
      [SELECT_MODE_BACK]: selectedBack,
    }
  }

  return newSelected
}

export const setOffers = (state, payload) => {
  return {
    ...state,
    [payload.name]: {
      ...state[payload.name],
      offers: payload.offers,
      status: payload.status,
      uuid: payload.uuid,
      loading: false,
      rules: payload.rules,
      totalPaxes: payload.totalPaxes,
      selected: {
        ...state[payload.name].selected,
        ...getSelectedFromOffers(payload.offers),
      },
    },
  }
}

export const setPaxes = (state, payload) => {
  return {
    ...state,
    [payload.name]: {
      ...state[payload.name],
      paxes: payload.paxes,
    },
  }
}

export const selectOffer = (state, payload) => {
  const offers = state[payload.name].offers.map((offer) => {
    offer.selected = offer.chosen = offer.uuid === payload.offer_uuid
    offer.option.chosen = true
    return offer
  })

  const newOffers = [...offers]

  return {
    ...state,
    [payload.name]: {
      ...state[payload.name],
      offers: newOffers,
    },
  }
}

export const setQueryField = (state, payload) => {
  return {
    ...state,
    [payload.name]: {
      ...state[payload.name],
      query: {
        ...state[payload.name].query,
        [payload.query.name]: payload.query.value,
      },
      integrity: false,
    },
  }
}

export const reduxFormChange = (state, payload, meta) => {
  if (!get(state, meta.form, null)) {
    return state
  }

  if (QUERY_FIELD_NAMES.indexOf(meta.field) !== -1) {
    return setQueryField(state, {
      name: meta.form,
      query: {
        name: meta.field,
        value: payload instanceof Map ? payload.toJS() : payload,
      },
    })
  }

  return state
}

export const setIntegrity = (state, payload) => {
  return {
    ...state,
    [payload.name]: {
      ...state[payload.name],
      integrity: payload.integrity,
    },
  }
}

export const setFilter = (state, payload) => {
  const newFilter = mergeWith(
    state[payload.name].filter,
    payload.filter,
    (currentValue, newValue) => {
      if (isArray(newValue)) {
        return newValue
      }
    },
  )

  return {
    ...state,
    [payload.name]: {
      ...state[payload.name],
      filter: newFilter,
      isChanged: true,
    },
  }
}

export const setFilters = (state, payload) => {
  return {
    ...state,
    [payload.name]: {
      ...state[payload.name],
      filters: payload.filters,
    },
  }
}

export const setQuery = (state, payload) => {
  return {
    ...state,
    [payload.name]: {
      ...state[payload.name],
      query: payload.query,
    },
  }
}

export const searchAnotherOne = (state, payload) => {
  return {
    ...state,
    [payload.name]: {
      ...state[payload.name],
      offers: [],
      uuid: null,
    },
  }
}

export const bookOffer = (state, payload) => {
  const newOffers = state[payload.name].offers.map((offer) => {
    if (offer.chosen) {
      offer.options = offer.options.map((option) => {
        if (option.chosen) {
          option.booking = payload.status
        }
        return option
      })
    }
    return offer
  })

  return {
    ...state,
    [payload.name]: {
      ...state[payload.name],
      offers: newOffers,
    },
  }
}

export const toggleSearch = (state, payload) => {
  const current = get(state, `${payload.name}.enabled`, true)
  return {
    ...state,
    [payload.name]: {
      ...state[payload.name],
      enabled: !current,
    },
  }
}

export const setEnable = (state, payload) => {
  return {
    ...state,
    [payload.name]: {
      ...state[payload.name],
      enabled: payload.state,
    },
  }
}

export const change = (state, payload) => {
  return _set(cloneDeep(state), `${payload.name}.${payload.path}`, payload.value)
}

export const reduxFormInitialize = (state, payload, meta) => {
  if (!get(state, meta.form, null)) {
    return state
  }

  const query = {
    ...state[meta.form].query,
    arrival_at: payload.get('arrival_at'),
    return_at: payload.get('return_at'),
  }

  return setQuery(state, {
    name: meta.form,
    query,
  })
}

export const changeStatus = (state, payload) => {
  const offersUuid = payload.offerUuid.split('_')
  const optionsUuid = payload.optionUuid.split('_')

  const offers = state[payload.name].offers.map((offer) => {
    if (offersUuid.includes(offer.uuid)) {
      offer.booking = payload.status

      offer.options = offer.options.map((option) => {
        if (optionsUuid.includes(option.uuid)) {
          option.booking = payload.status
        }

        return option
      })
    }

    return offer
  })

  return {
    ...state,
    [payload.name]: {
      ...state[payload.name],
      offers,
      selected: {
        ...state[payload.name].selected,
        ...getSelectedFromOffers(offers),
      },
    },
  }
}

export const changeErrorMessageSlugAction = (state, payload) => {
  const offersUuid = payload.offerUuid.split('_')

  const offers = state[payload.name].offers.map((offer) => {
    if (offersUuid.includes(offer.uuid)) {
      offer.errorMessageSlug = payload.errorMessageSlug
    }

    return offer
  })

  return {
    ...state,
    [payload.name]: {
      ...state[payload.name],
      offers,
      selected: {
        ...state[payload.name].selected,
        ...getSelectedFromOffers(offers),
      },
    },
  }
}
