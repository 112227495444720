import React, { useEffect, useRef, useState } from 'react'
import { AgGridProvider } from './AgGridContext'

export function AgGridWrapper({ children }) {
  const [height, setHeight] = useState(400)
  const containerRef = useRef()

  const setGridHeight = () => {
    if (!containerRef.current) {
      return
    }

    const offsetTop = containerRef.current.getBoundingClientRect().top
    const freeSpace = window.innerHeight - offsetTop - 60

    if (freeSpace > 400) {
      setHeight(freeSpace)
    } else if (freeSpace !== height) {
      setHeight(400)
    }
  }

  useEffect(() => {
    setGridHeight()

    window.addEventListener('resize', setGridHeight)

    return () => {
      window.removeEventListener('resize', setGridHeight)
    }
  }, [])

  return (
    <div className='ag-theme-alpine' style={{ height }} ref={containerRef}>
      <AgGridProvider>{children}</AgGridProvider>
    </div>
  )
}
