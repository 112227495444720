import { OptionProps } from 'react-select/dist/declarations/src/components/Option'
import React, { PropsWithChildren, ReactElement, ReactNode } from 'react'
import RenderProvider from '../../components/AccountDocumentForm/RenderProvider'
import { components } from 'react-select'
import { Company } from '../../types/company'

export interface UserSelectOptionProps extends PropsWithChildren<OptionProps> {
  data: {
    first_name: string
    last_name: string
    full_name: string
    company: Company
    value: string
  }
}

export default function UserSelectOption({ children, ...props }: UserSelectOptionProps): ReactNode {
  const { data } = props

  const content = data.value ? (
    <div className={'user-field__option'}>
      <div>{data.full_name}</div>
      <div>{data.company.code}</div>
    </div>
  ) : (
    children
  )

  return <components.Option {...props}>{content}</components.Option>
}
