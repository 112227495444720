import React from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { Cell, Row } from '../../../ui/AccordionTable'
import trans from '../../../../trans'
import AccountingAccountListItem from './AccountingAccountListItem'
import AccountingAccountListFormItem from './AccountingAccountListFormItem'
import {
  fetchAccountingAccounts,
  getAccountingAccountsTotalCount,
  getPage,
  getPaginatedAccountingAccounts,
  isLoading,
  setPage,
} from '../../../../store/app/accounting-account'
import { CompanyBaseList } from '../../CompanyBaseList'

class AccountingAccountList extends CompanyBaseList {
  getIdentifierKey() {
    return 'id'
  }

  getFormComponent() {
    return AccountingAccountListFormItem
  }

  getListItemComponent() {
    return AccountingAccountListItem
  }

  renderNewForm() {
    if (!this.state.adding) {
      return
    }

    const item = {
      is_active: true,
    }

    return this.renderForm(item)
  }

  renderTableHeader() {
    return (
      <Row>
        <Cell fixedWidth={150}>{trans('accounting.accounting-account-accounting_number')}</Cell>
        <Cell fixedWidth='auto'>{trans('accounting.accounting-account-name')}</Cell>
        <Cell fixedWidth={250}>{trans('accounting.accounting-account-type')}</Cell>
        <Cell fixedWidth={120}>{trans('accounting.is_active')}</Cell>
        <Cell fixedWidth={180} alignRight />
      </Row>
    )
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      setPage,
      fetch: fetchAccountingAccounts,
    },
    dispatch,
  )
}

const mapStateToProps = (state) => {
  return {
    isLoading: isLoading(state),
    items: getPaginatedAccountingAccounts(state),
    total: getAccountingAccountsTotalCount(state),
    page: getPage(state),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AccountingAccountList)
