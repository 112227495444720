import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { get } from 'lodash'
import moment from 'moment/moment'

const TravelTime = (props) => {
  const { offer } = props

  const arrivalDateTime = moment(get(offer, 'attributes.arrivalDateTime', null))
  const departureDateTime = moment(get(offer, 'attributes.departureDateTime', null))
  const departureDayDelay = get(offer, 'attributes.departureDayDelay', null)

  const time = (
    <Fragment>
      {departureDateTime.format('HH:mm')}&thinsp;–&thinsp;{arrivalDateTime.format('HH:mm')}
    </Fragment>
  )

  return (
    <span>
      {time}
      {departureDayDelay && <sup>{departureDayDelay}</sup>}
    </span>
  )
}

TravelTime.propTypes = {
  offer: PropTypes.object.isRequired,
}

export default TravelTime
export { TravelTime }
