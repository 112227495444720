import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { createDeductions } from '../createDeductions'
import APIClient from '../../../../services/APIClient'

export const MODULE_MOUNT_POINT = 'meal-deductions'

const fetchMethod = (...args) => {
  return APIClient.getMealDeductionsWidget(...args)
}

const changeMethod = (...args) => {
  return APIClient.updateMealDeduction(...args)
}

const deductions = createDeductions(MODULE_MOUNT_POINT, fetchMethod, changeMethod)

const {
  selectors: { getDeductions, isInitialized },
  creators: { reset, set, destroy, fetch, change },
  reducer,
} = deductions

export const isLoading = deductions.selectors.isLoading

const mealDeductions =
  (resetOnMount = false, fetchOnMount = false, destroyOnUnmount = false) =>
  (Component) => {
    class MealDeductionsHOC extends React.Component<any, any> {
      constructor(props) {
        super(props)
      }

      componentDidMount() {
        const { deductions } = this.props
        if (resetOnMount) {
          deductions.actions.reset()
        }

        if (fetchOnMount) {
          deductions.actions.fetch()
        }
      }

      componentWillUnmount() {
        const { deductions } = this.props
        if (destroyOnUnmount) {
          deductions.actions.destroy()
        }
      }

      render() {
        return <Component<any, any> {...this.props} />
      }
    }

    const mapStateToProps = (state) => ({
      deductions: getDeductions(state),
      isLoading: isLoading(state),
      isInitialized: isInitialized(state),
    })

    const mapDispatchToProps = (dispatch, props) => {
      return bindActionCreators(
        {
          reset,
          set,
          destroy,
          fetch: fetch(props.request),
          change: change(props.request),
        },
        dispatch,
      )
    }

    const mergedProps = (selectors, actions, own) => {
      return {
        ...own,
        deductions: {
          selectors,
          actions,
        },
      }
    }

    return connect(mapStateToProps, mapDispatchToProps, mergedProps)(MealDeductionsHOC)
  }

export { mealDeductions, reducer }
export default mealDeductions
