import PropTypes from 'prop-types'
import React from 'react'
import { Section } from '../../ui/Section/index'
import { AmountFormatter } from '../../AmountFormatter/index'
import { round } from '../../../utils/number'
import { trans } from '../../../trans'

const Amount = ({ label, amount, currency }) => (
  <div>
    <p style={{ marginTop: 0 }}>{label}</p>
    <p className='is-allign-end dashboard-current-trips__amount'>
      <AmountFormatter amount={round(amount, 2)} /> {currency}
    </p>
  </div>
)

class RequestInfo extends React.Component<any, any> {
  render() {
    const {
      request: {
        basicSummary: {
          requestedConvertedAmount: { amount: requestedAmount, currency: requestedCurrency },
          settledConvertedAmount: { amount: settledAmount, currency: settledCurrency },
          showSettled,
        },
      },
    } = this.props

    return (
      <Section noBorder noMargin>
        <Amount
          label={trans('dashboard-page.requested-amount')}
          amount={requestedAmount}
          currency={settledCurrency}
        />
        {showSettled && (
          <Amount
            label={trans('dashboard-page.settled-amount')}
            amount={settledAmount}
            currency={settledCurrency}
          />
        )}
      </Section>
    )
  }
}

RequestInfo.propTypes = {
  request: PropTypes.object.isRequired,
}

export { RequestInfo }
export default { RequestInfo }
