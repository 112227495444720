import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import trans from '../../trans'
import Button from '../ui/ButtonComponent'
import { Loader } from '../ui/LoadingOverlay/Loader'

class MarkAsPaid extends Component<any, any> {
  constructor(props) {
    super(props)

    this.state = {
      isPending: false,
      status: props.status,
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    return {
      ...prevState,
      status: nextProps.status,
    }
  }

  markAsPaid = (e) => {
    e.preventDefault()

    this.setState({ isPending: true }, () => {
      this.props.markAsPaid().then((request) => {
        this.setState({ isPending: false })

        if (this.props.callback) {
          this.props.callback(request)
        }
      })
    })
  }

  markAsUnPaid = (e) => {
    e.preventDefault()

    this.setState({ isPending: true }, () => {
      this.props.markAsUnPaid().then(() => {
        this.setState({ isPending: false })
      })
    })
  }

  render() {
    if (this.state.isPending) {
      return (
        <div className='table-accordion__installment-loader'>
          <Loader small center />
        </div>
      )
    }

    if (this.state.status === 'paid') {
      return (
        <Fragment>
          <Button
            outline
            xs
            onClick={this.markAsUnPaid}
            style={{
              display: 'flex',
              fontSize: '1.5rem',
              padding: '0.3rem 1.5rem',
            }}
          >
            &#9747;
          </Button>
        </Fragment>
      )
    }

    return (
      <Fragment>
        <Button
          primary
          xs
          onClick={this.markAsPaid}
          style={{
            display: 'flex',
            fontSize: '1.5rem',
            padding: '0.3rem 1.5rem',
          }}
        >
          &#10003;
        </Button>
      </Fragment>
    )
  }
}

MarkAsPaid.propTypes = {
  installment: PropTypes.object.isRequired,
  markAsPaid: PropTypes.func.isRequired,
  canMark: PropTypes.bool.isRequired,
}

export default MarkAsPaid
export { MarkAsPaid }
