import { createSlice } from '@reduxjs/toolkit'

export const AGENT_TRIP_REQUEST_OWNER_MOUNT_POINT = 'agent-trip-request-owner'

/**
 * In @reduxjs/toolkit we can mutate state in reducers,
 * because behind the scene it uses ImmerJS for applying them as non-mutate
 *
 * https://github.com/immerjs/immer
 */
const slice = createSlice({
  name: AGENT_TRIP_REQUEST_OWNER_MOUNT_POINT,
  initialState: {
    owner: {},
  },
  reducers: {
    setOwner(state, action) {
      state.owner = action.payload
    },
  },
})

export const { setOwner } = slice.actions

export default slice.reducer

// selectors
const getState = (state) => state.get(AGENT_TRIP_REQUEST_OWNER_MOUNT_POINT)
export const getOwner = (state) => getState(state).owner
