import React, { Fragment } from 'react'
import { trans } from '../../../trans'

const Additional = (props) => {
  const { children } = props

  return (
    <Fragment>
      <div className='plane-trip__offer-detail-tooltip-wrapper'>
        <span className='plane-offer__detail-heading'>
          {trans('flights-booking.additional-service')}
        </span>

        {/*<Tooltip*/}
        {/*    useContext*/}
        {/*    interactive*/}
        {/*    position="top"*/}
        {/*    popperOptions={{*/}
        {/*        modifiers: {*/}
        {/*            preventOverflow: {*/}
        {/*                enabled: false*/}
        {/*            },*/}
        {/*        },*/}
        {/*        data: {*/}
        {/*            placement: "top",*/}
        {/*            originalPlacement: "top",*/}
        {/*        },*/}
        {/*    }}*/}
        {/*    html={(*/}
        {/*        <div className="plane-trip__offer-tooltip plane-trip__offer-tooltip--luggage">*/}
        {/*            <div className="plane-trip__offer-tooltip__content">*/}
        {/*                /!*<p>*!/*/}
        {/*                /!*    <strong>*!/*/}
        {/*                /!*        Warszawa (WAW) - Helsinki (HEL)*!/*/}
        {/*                /!*    </strong>*!/*/}
        {/*                /!*</p>*!/*/}
        {/*                <p>*/}
        {/*                    <span>Darmowy bagaż nadawany</span>*/}
        {/*                    <span className="plane-trip__offer-tooltip__info plane-trip__offer-tooltip__info--luggage">*/}
        {/*                        <strong>*/}
        {/*                            {quantity}szt.*/}
        {/*                        </strong>*/}
        {/*                    </span>*/}
        {/*                </p>*/}
        {/*            </div>*/}
        {/*            /!*<div className="plane-trip__offer-tooltip__content">*!/*/}
        {/*            /!*    <p>*!/*/}
        {/*            /!*        <strong>*!/*/}
        {/*            /!*            Warszawa (WAW) - Helsinki (HEL)*!/*/}
        {/*            /!*        </strong>*!/*/}
        {/*            /!*    </p>*!/*/}
        {/*            /!*    <p>*!/*/}
        {/*            /!*        <span>Lorem ipsum dolor sit amet consectetur adipiscing elit sed do eiusmod tempor incididunt ut labore</span>*!/*/}
        {/*            /!*        <span className="plane-trip__offer-tooltip__info plane-trip__offer-tooltip__info--luggage">*!/*/}
        {/*            /!*            <strong>*!/*/}
        {/*            /!*                10szt.*!/*/}
        {/*            /!*            </strong>*!/*/}
        {/*            /!*        </span>*!/*/}
        {/*            /!*    </p>*!/*/}
        {/*            /!*</div>*!/*/}
        {/*        </div>*/}

        {/*    )}>*/}
        {/*    <Icon type="info" className="icon-info-grey plane-trip__icon-info"/>*/}
        {/*</Tooltip>*/}
      </div>
      <div className='plane-offer__detail-content'>{children}</div>
    </Fragment>
  )
}

export default Additional
export { Additional }
