import React, { Component } from 'react'
import { borderCrossings } from '../../../../store/app/border-crossings'
import { mealDeductions } from '../../../../store/app/deductions/meal-deductions'
import { compose } from 'redux'
import { MealExpenseForm } from './MealExpenseForm'
import Periods from '../Common/Periods'
import { Tooltip } from '../../../Tooltip'
import { trans } from '../../../../trans'
import Icon from '../../../ui/IconComponent'

class Meals extends Component<any, any> {
  render() {
    const {
      deductions: {
        selectors: { deductions },
        actions: { change },
      },
    } = this.props
    return (
      <div className='declarations'>
        <div className='declarations__tooltip'>
          <Tooltip
            html={
              <p className='tooltip__content'>
                {trans('deductions-widget.header__top-tooltip-content-1')}
              </p>
            }
          >
            <span className='declarations__tooltip-content'>
              <strong>{trans('deductions-widget.header__top-cell-1')}</strong>
              <Icon greyLight lg type='question_fill' />
            </span>
          </Tooltip>
        </div>

        <div className='declarations__header declarations__grid-row'>
          <div className='declarations__grid-column'>
            <span className='declarations__heading declarations__heading--text-right'>
              {trans('deductions-widget.time')}
            </span>
          </div>

          <div className='declarations__grid-column'>
            <span className='declarations__heading declarations__heading--text-left'>
              {trans('deductions-widget.local-time')}
            </span>
          </div>

          <div className='declarations__grid-column'>
            <span className='declarations__heading'>{trans('deductions-widget.breakfast')}</span>
          </div>

          <div className='declarations__grid-column'>
            <span className='declarations__heading'>{trans('deductions-widget.lunch')}</span>
          </div>

          <div className='declarations__grid-column'>
            <span className='declarations__heading'>{trans('deductions-widget.dinner')}</span>
          </div>
        </div>
        <Periods periods={deductions} form={MealExpenseForm} change={change} />
      </div>
    )
  }
}

Meals.propTypes = {}

const withBorderCrossings = borderCrossings()
const withDeductions = mealDeductions(true, true, true)

Meals = compose(withBorderCrossings, withDeductions)(Meals)

export default Meals
export { Meals }
