// initial state
import APIClient from '../../../services/APIClient'
import { createModule as createListModule } from '../../modules/list/index'

const fetchMethod = ({ filters }, source) => {
  return APIClient.getSettlementRequests(filters, source)
}

export const MOUNT_POINT = 'settlement-requests'
const NAMESPACE = MOUNT_POINT

const module = createListModule({
  namespace: NAMESPACE,
  mountPoint: MOUNT_POINT,
  paginationByAPI: true,
  fetchMethod,
})

const { reducer, actions, selectors } = module

actions.assignToUser = (request, user) => (dispatch) => {
  return APIClient.assignToUser(request).then(() => {
    dispatch(
      actions.fetchItems({
        resetPage: false,
      }),
    )
  })
}

export { reducer, actions, selectors }
