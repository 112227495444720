import PropTypes from 'prop-types'
import React from 'react'
import { formValueSelector } from 'redux-form/immutable'
import { connect } from 'react-redux'
import { fromJS } from 'immutable'
import { AccountTravelExpenseForm as FormComponent } from '../../components/AccountTravelExpenseForm'
import { bindActionCreators, compose } from 'redux'
import { createAutoSaveForm, getFormValues } from '../../utils/forms'
import { isTravelExpenseDeleteInProgress } from '../../store/app/request-mileage-allowance'
import { getActiveAccountingAccountSelectOptions } from '../../store/app/accounting-account'
import { getActiveMpkSelectOptions } from '../../store/app/mpks'
import { getActiveProjectsSelectOptions } from '../../store/app/projects'
import { getCache, setCache } from '../../store/app/account-dimensions'
import {
  generateAccountDimensionFormValues,
  getAccountDimensionIdFromKey,
  isAccountDimensionField,
  submitAccountDimension,
} from '../../store/app/account-dimensions/form'
import APIClient from '../../services/APIClient'
import { isFeatureEnabled } from '../../store/app/instance'
import { FEATURE_DEDUCTIBILITY_ENABLED } from '../../constants/features'
import { selectInstanceDefaultCurrency } from '../App/selectors'
import { getCurrencies } from '../../store/app/document-account-page'
import withAmountLoader from '../DocumentForm/withAmountLoader'
import moment from 'moment'
import { config } from '../../config'

const AccountTravelExpenseFormBase = createAutoSaveForm(FormComponent, {
  save: (name, value, dispatch, props) => {
    const {
      request,
      travelExpense,
      saveAccountingTravelExpense,
      amountLoader,
      accountDimensionItems,
      accountDimensions,
      setCache,
    } = props

    if (['amount', 'currency_id'].indexOf(name) !== -1) {
      amountLoader.setLoading(true)
    }

    if (isAccountDimensionField(name)) {
      return submitAccountDimension({
        name,
        value,
        setCache,
        items: accountDimensionItems,
        dimensions: accountDimensions,
        updateMethod: (dim_id) =>
          APIClient.updateTravelExpenseAccountDimension(
            request.slug,
            travelExpense.id,
            dim_id,
            value.id,
          ),
        deleteMethod: (dim_id) =>
          APIClient.deleteTravelExpenseAccountDimension(request.slug, travelExpense.id, dim_id),
      })
    }

    if (name === 'mpk') {
      name = 'mpk_id'

      if (value) {
        value = value.id
      }
    }

    return saveAccountingTravelExpense(request, travelExpense, { [name]: value }).then(() => {
      amountLoader.setLoading(false)
    })
  },
  componentDidMount: (props) => {
    const {
      travelExpense: { accountDimensionItems },
      setCache,
    } = props

    setCache(accountDimensionItems)
  },
})

AccountTravelExpenseFormBase.propTypes = {
  request: PropTypes.object.isRequired,
  mpks: PropTypes.array.isRequired,
  accountingAccounts: PropTypes.array.isRequired,
  travelExpense: PropTypes.object.isRequired,
  saveAccountingTravelExpense: PropTypes.func.isRequired,
  currentUser: PropTypes.object.isRequired,
  isDeductibilityEnabled: PropTypes.bool.isRequired,
}

const mapStateToProps = (state, props) => {
  const {
    request,
    accountDimensions = [],
    travelExpense,
    travelExpense: { accountDimensionItems = [] },
  } = props

  const selector = formValueSelector(props.formName, (state) => {
    return state.get('form').toJS()
  })

  const isDeletingInProgress = isTravelExpenseDeleteInProgress(state, travelExpense)

  let amount = parseFloat(travelExpense['amount'])
  if (isNaN(amount) || amount === 0) {
    amount = ''
  }

  const currentAccountingAccount = { id: travelExpense['accounting_account_id'] }
  const accountingAccounts = getActiveAccountingAccountSelectOptions(
    state,
    currentAccountingAccount || [],
  )
  const mpks = getActiveMpkSelectOptions(state, [travelExpense.mpk_id, request.mpk_id])
  const projects = getActiveProjectsSelectOptions(state, [
    travelExpense.project_id,
    request.project_id,
  ])

  return {
    initialValues: fromJS({
      accounting_account_id: travelExpense['accounting_account_id'],
      amount: amount,
      cost_of_earning: travelExpense['cost_of_earning'] || false,
      mpk: travelExpense['mpk'],
      project_id: travelExpense['project_id'],
      deductibility: travelExpense['deductibility'],
      currency_id: travelExpense.currency_id,
      ...generateAccountDimensionFormValues(accountDimensions, accountDimensionItems),
    }),
    form: props.formName,
    mpks,
    projects,
    accountingAccounts,
    isDeletingInProgress,
    request,
    travelExpense,
    data: getFormValues(props.formName, state),
    deductibility: selector(state, 'deductibility'),
    accountDimensions,
    currencies: getCurrencies(state),
    accountDimensionItems: getCache(state)(props.formName),
    isDeductibilityEnabled: isFeatureEnabled(FEATURE_DEDUCTIBILITY_ENABLED)(state),
    defaultCurrency: selectInstanceDefaultCurrency(state),
  }
}

const mapDispatchToProps = (dispatch, props) => {
  return bindActionCreators(
    {
      setCache: (data) => setCache({ name: props.formName, data }),
    },
    dispatch,
  )
}

const withConnect = connect(mapStateToProps, mapDispatchToProps)

const AccountTravelExpenseForm = compose(
  withConnect,
  withAmountLoader,
)(AccountTravelExpenseFormBase)

export { AccountTravelExpenseForm }
export default { AccountTravelExpenseForm }
