import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators, compose } from 'redux'
import {
  getExpenseTypes,
  getForms,
  getIsLoaded,
  getIsLoading,
  isRemainingProgress,
  reset,
  setExpenseTypes,
} from '../../../store/app/document-expense-types'
import { destroy } from 'redux-form'
import store from '../../../store'
import { isSaving, isWaitingForMessage } from '../../../store/app/document-expense-types/index'
import { isFunction } from 'lodash'
import socketProvider from '../../SocketProvider'

class ExpenseTypesManager extends React.Component<any, any> {
  componentDidMount() {
    const { reset, document, socket, setExpenseTypes } = this.props

    reset()

    setExpenseTypes(document.expenseTypes, document, document.gross)

    if (isFunction(socket.subscribe)) {
      socket.subscribe(`App.Document.${document.id}`)('.App\\Events\\DocumentElementChanged')(
        (response) => {
          setExpenseTypes(response.expenseTypes, document, response.documentGross)
        },
      )
    }
  }

  _destroyForms() {
    const { forms } = this.props
    Object.keys(forms).forEach((form) => {
      store.dispatch(destroy(form))
    })
  }

  componentWillUnmount() {
    const { socket, document } = this.props

    if (isFunction(socket.unsubscribe)) {
      socket.unsubscribe(`App.Document.${document.id}`)('.App\\Events\\DocumentElementChanged')
    }

    this._destroyForms()
  }

  onActivateTab() {}

  render() {
    const {
      children,
      items,
      isLoading,
      isLoaded,
      isSaving,
      isWaitingForMessage,
      isRemainingInProgress,
    } = this.props

    const renderProps = {
      items: items,
      isLoading: isLoading,
      isLoaded: isLoaded,
      isSaving: isSaving,
      isWaitingForMessage,
      isRemainingInProgress,
      onActiveTab: this.onActivateTab,
    }

    return children(renderProps)
  }
}

ExpenseTypesManager.propTypes = {
  children: PropTypes.func.isRequired,
  document: PropTypes.object.isRequired,
  socketSubscribe: PropTypes.func,
  socketLeave: PropTypes.func,
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      reset,
      setExpenseTypes,
    },
    dispatch,
    store.getState,
  )
}

const mapStateToProps = (state) => ({
  items: getExpenseTypes(state),
  isLoading: getIsLoading(state),
  isLoaded: getIsLoaded(state),
  forms: getForms(state),
  isSaving: isSaving(state),
  isWaitingForMessage: isWaitingForMessage(state),
  isRemainingInProgress: isRemainingProgress(state),
})

const withConnect = connect(mapStateToProps, mapDispatchToProps)
const withSocket = socketProvider()

ExpenseTypesManager = compose(withConnect, withSocket)(ExpenseTypesManager)

export { ExpenseTypesManager }
export default ExpenseTypesManager
