import React from 'react'
import trans from '../../../trans'
import { AccordionPanel } from '../../ui/AccordionPanel/index'
import { Label, TYPE_WARNING } from '../../ui/Label/index'
import { DashboardRequestsToAcceptManager } from '../../../containers/DashboardRequestsToAcceptManager/index'
import { LoadingOverlay } from '../../ui/LoadingOverlay/index'
import { Col, Row } from '../../../ui/Grid/index'
import { RouteManager } from '../../../containers/RouteManager/index'
import { UserInfo } from './UserInfo'
import { ProjectInfo } from './ProjectInfo'
import { RequestActions } from './RequestActions'
import { Section } from '../../ui/Section/index'
import { SectionHeader } from '../../ui/SectionHeader/index'
import { DashboardRequestsContent } from '../DashboardRequestsContent'
import { RequestSummary } from '../../RequestSummary/index'
import TruncateBreakable from '../../TruncateBreakable/TruncateBreakable'
import { Loader } from '../../ui/LoadingOverlay/Loader'

export const DashboardRequestsToAccept = (props) => {
  if (!props.counter) {
    return null
  }

  return (
    <div className='dashboard-requests-to-accept'>
      <RouteManager>
        {({ push, getRouteByName }) => {
          return (
            <AccordionPanel
              bar={
                <h1>
                  <Label level={TYPE_WARNING}>{props.counter}</Label>
                  {trans('dashboard-page.requests-to-accept')}
                </h1>
              }
              defaultOpened={false}
              name='requests-to-accept'
              expandOnBarClick
              barTheme='secondary'
              noShadow
            >
              {({ isOpen }) => {
                return (
                  <DashboardRequestsToAcceptManager>
                    {({
                      items,
                      isLoading,
                      isLoaded,
                      isActionInProgress,
                      rejectRequest,
                      acceptRequest,
                      isModuleActive,
                    }) => {
                      const _isLoading = isLoading || !isLoaded

                      return isOpen === true && items.length > 0 ? (
                        <DashboardRequestsContent items={items} isLoading={_isLoading}>
                          {items.map((request) => {
                            return (
                              <Section noBorder key={`request-${request['slug']}`}>
                                <Row
                                  className='dashboard-requests-to-accept__row'
                                  key={request['slug']}
                                >
                                  <Col
                                    xs={12}
                                    md={6}
                                    className='dashboard-requests-to-accept__summary'
                                  >
                                    <SectionHeader
                                      caption={<TruncateBreakable text={request.name} lines={2} />}
                                      className='dashboard-requests-to-accept__header'
                                    />
                                    <RequestSummary
                                      push={push}
                                      getRouteByName={getRouteByName}
                                      request={request}
                                      summary={request['basicSummary']}
                                      grid={{
                                        contentCell: 5,
                                        requestedCell: 4,
                                        iconsCell: 3,
                                        settledCell: 0,
                                      }}
                                      showLessIcons
                                    />
                                  </Col>
                                  <Col
                                    xs={12}
                                    md={6}
                                    className='dashboard-requests-to-accept__right-col'
                                  >
                                    <UserInfo user={request['user']} />
                                    {request['project'] && (
                                      <ProjectInfo
                                        project={request['project']}
                                        purpose={request['purpose']}
                                        mpk={request['mpk']}
                                        isModuleActive={isModuleActive}
                                      />
                                    )}
                                    <RequestActions
                                      request={request}
                                      acceptRequest={acceptRequest}
                                      rejectRequest={rejectRequest}
                                      getRouteByName={getRouteByName}
                                    />
                                  </Col>
                                  <div className='dashboard-requests-to-accept__hr' />
                                </Row>

                                {isActionInProgress(request['slug']) && <LoadingOverlay />}
                              </Section>
                            )
                          })}
                        </DashboardRequestsContent>
                      ) : (
                        <div className='has-loader pt-12'>
                          <Loader />
                        </div>
                      )
                    }}
                  </DashboardRequestsToAcceptManager>
                )
              }}
            </AccordionPanel>
          )
        }}
      </RouteManager>
    </div>
  )
}
