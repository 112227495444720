import PropTypes from 'prop-types'
import React from 'react'
import classnames from 'classnames'
import { trans } from '../../trans'

class ExpenseTypeBox extends React.Component<any, any> {
  render() {
    const { icon, title, children, isActive, itemWidth, hasChildren, ...rest } = this.props
    const classes = classnames({
      'expense-type-box': true,
      'expense-type-box--is-active': isActive,
      'expense-type-box--has-content': hasChildren,
      'has-loader': true,
    })
    const styles = { width: itemWidth }

    return (
      <label className={classes} style={styles} {...rest}>
        <span className='expense-type-box__icon'>{icon}</span>
        <span className='expense-type-box__title'>{trans(title)}</span>
        <span className='expense-type-box__content'>{children}</span>
      </label>
    )
  }
}

ExpenseTypeBox.propTypes = {
  children: PropTypes.node,
  title: PropTypes.string,
  icon: PropTypes.node,
  isActive: PropTypes.bool,
}

export { ExpenseTypeBox }
export default { ExpenseTypeBox }
