import { createSelector, createSlice } from '@reduxjs/toolkit'
import { isEmpty } from 'lodash'
import APIClient from '../../../services/APIClient'
import { PER_PAGE } from '../../../constants/pagination'

export const KPI_MOUNT_POINT = 'kpis'

/**
 * In @reduxjs/toolkit we can mutate state in reducers,
 * because behind the scene it uses ImmerJS for applying them as non-mutate
 *
 * https://github.com/immerjs/immer
 */
const kpiSlice = createSlice({
  name: KPI_MOUNT_POINT,
  initialState: {
    items: [],
    error: null,
    isLoading: false,
    page: 1,
  },
  reducers: {
    setKpiList(state, action) {
      const { data } = action.payload

      state.items = data
    },
    setKpi(state, action) {
      const { data } = action.payload
      const index = state.items.findIndex((item) => item.slug === data.slug)

      if (index === -1) {
        state.items.unshift(data)
      } else {
        state.items[index] = data
      }
    },
    setPage(state, action) {
      state.page = action.payload
    },
    startLoading(state) {
      state.isLoading = true
      state.error = null
    },
    setLoadingSuccess(state) {
      state.isLoading = false
      state.error = null
    },
    setLoadingFailed(state, action) {
      state.isLoading = false
      state.error = action.payload
    },
  },
})

export const { setKpi, setKpiList, setPage, startLoading, setLoadingSuccess, setLoadingFailed } =
  kpiSlice.actions

export default kpiSlice.reducer

// selectors
const getState = (state) => state.get(KPI_MOUNT_POINT)
export const getPage = (state) => getState(state).page
export const getKpiList = (state) => getState(state).items
export const isLoading = (state) => getState(state).isLoading
export const getPaginatedKpi = createSelector(getKpiList, getPage, (items, page) =>
  items.slice(page * PER_PAGE - PER_PAGE, page * PER_PAGE),
)
export const getKpiTotalCount = createSelector(getKpiList, (items) => items.length)

// action thunks
export const fetchKpiList = () => async (dispatch) => {
  dispatch(startLoading())

  try {
    const { data } = await APIClient.getKpiList()

    dispatch(setKpiList({ data }))
    dispatch(setLoadingSuccess())
  } catch (err) {
    dispatch(setLoadingFailed(err))
  }
}

export const fetchKpiListIfNeeded = () => async (dispatch, state) => {
  const { items, isLoading } = getState(state())

  if (!isEmpty(items) || isLoading) {
    return
  }

  return dispatch(fetchKpiList())
}

export const updateKpi = (item) => async (dispatch) => {
  const { data } = await APIClient.updateKpi(item.slug, item)

  await dispatch(fetchKpiList())

  return data
}
