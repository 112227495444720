import PropTypes from 'prop-types'
import React from 'react'
import localStorage from '../../services/localStorage'

const STORE_KEY = 'stored-timeline-elements'

class StorableTimelineElement extends React.Component<any, any> {
  constructor(props) {
    super(props)

    this.state = {
      change: null,
    }
  }

  _getStorageData() {
    return localStorage.get(STORE_KEY) || {}
  }

  _getState() {
    const { storageKey, defaultState } = this.props
    const data = this._getStorageData()

    let state = defaultState

    if (data.hasOwnProperty(storageKey)) {
      state = data[storageKey]
    }

    return state
  }

  toggle = () => {
    const { storageKey } = this.props

    let data = this._getStorageData()
    let state = this._getState()

    if (state === 'open') {
      state = 'close'
    } else {
      state = 'open'
    }

    localStorage.set(STORE_KEY, { ...data, [storageKey]: state })

    this.setState({ state })
  }

  render() {
    const { children } = this.props

    const renderProps = {
      toggle: this.toggle,
      isOpen: this._getState() === 'open',
    }

    return children(renderProps)
  }
}

StorableTimelineElement.propTypes = {
  storageKey: PropTypes.string.isRequired,
  defaultState: PropTypes.oneOf(['open', 'close']),
}

StorableTimelineElement.defaultProps = {
  defaultState: 'open',
}

export default StorableTimelineElement
export { StorableTimelineElement }
