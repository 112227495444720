import React, { Component } from 'react'
import PropTypes from 'prop-types'
import numeral from 'numeral'
import { Field } from 'redux-form/lib/immutable'
import { FormField } from './FormField'
import { get, isFinite } from 'lodash'
import classNames from 'classnames'
import Icon from '../IconComponent'
import { ExchangeFormatter } from '../../ExchangeFormatter'
import { getExchangeFormat } from '../../ExchangeFormatter/ExchangeFormatter'

class ExchangeRateField extends Component<any, any> {
  constructor(props) {
    super(props)

    this.state = {
      defaultRate: this.props.defaultRate,
      isDirty: false,
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    return {
      ...prevState,
      defaultRate: nextProps.defaultRate,
    }
  }

  onBlur = (event) => {
    const { change, input } = this.props
    const target = get(event, 'target.value', 0)
    const value = target !== '' ? parseFloat(target) : 0.0
    if (isFinite(value)) {
      const amount = numeral(value).format(getExchangeFormat(value))

      change(input.name, amount)

      this.setState({ isDirty: false })
    }
  }

  edit = () => {
    const { input, change } = this.props
    change(input.name, this.state.defaultRate)
    this.setState({ isDirty: true })
  }

  render() {
    const { input, change, canEdit = true } = this.props
    const classes = classNames('editable-label', {
      'editable-label--is-open': this.state.isDirty,
    })

    return (
      <div className={classes}>
        {this.state.isDirty ? (
          <Field
            name={input.name}
            type='formatted_number'
            component={FormField}
            inputOnly
            options={{ decimalScale: 8 }}
            withError
            normalize={(value) => (value === '' ? null : value)}
            onBlur={this.onBlur}
            autoFocus
            value={2}
            onChange={change}
          />
        ) : (
          <div className='editable-label__label'>
            <div className='editable-label__value'>
              <ExchangeFormatter amount={this.state.defaultRate} />
            </div>

            {canEdit && (
              <div className='editable-label__icon' onClick={this.edit}>
                <Icon type='edit' />
              </div>
            )}
          </div>
        )}
      </div>
    )
  }
}

ExchangeRateField.propTypes = {
  defaultRate: PropTypes.string.required,
}

export default ExchangeRateField
export { ExchangeRateField }
