import PropTypes from 'prop-types'
import React from 'react'
import { Field } from 'redux-form/immutable'
import { FormField } from '../ui/Form'
import Icon from '../../components/ui/IconComponent'
import trans from '../../trans'

class UserAnnotationForm extends React.Component<any, any> {
  constructor(props) {
    super(props)
    this.state = {
      isEditing: false,
    }
  }

  renderPreview() {
    const { initialValues } = this.props
    return (
      <div>
        <span>{initialValues.get('annotation')}&nbsp;</span>
        <Icon
          type='edit'
          gradient
          onClick={() => {
            this.setState({ isEditing: true })
          }}
        />
      </div>
    )
  }

  renderEdit() {
    return <Field name='annotation' type='textarea' component={FormField} inputOnly autosize />
  }

  render() {
    return (
      <div className='is-margin-bottom-small'>
        <form>
          <span>{trans('request.notes')}</span>
          {this.state.isEditing ? this.renderEdit() : this.renderPreview()}
        </form>
      </div>
    )
  }
}

UserAnnotationForm.propTypes = {
  request: PropTypes.object.isRequired,
}

export { UserAnnotationForm }
export default { UserAnnotationForm }
