import PropTypes from 'prop-types'
import React, { PropsWithChildren } from 'react'
import classNames from 'classnames'

interface SectionProps extends PropsWithChildren {
  noMargin?: boolean
  noBorder?: boolean
  noPadding?: boolean
  className?: string
}

export const Section = ({ children, noMargin, noBorder, noPadding, className }: SectionProps) => {
  const classes = classNames({
    section: true,
    'section--no-margin': noMargin,
    'section--no-border': noBorder,
    'section--no-padding': noPadding,
    [className]: !!className,
  })

  return (
    <section className={classes} style={{ width: '100%' }}>
      {children}
    </section>
  )
}
