import React from 'react'
import { change, reset, set, setCurrent, updateAvatarInHierarchy } from './reducers'

export const MODULE_MOUNT_POINT = 'company-hierarchy'

export const RESET = MODULE_MOUNT_POINT + '::reset'
export const SET = MODULE_MOUNT_POINT + '::set'
export const SET_CURRENT = MODULE_MOUNT_POINT + '::set-current'
export const CHANGE = MODULE_MOUNT_POINT + '::change'
export const UPDATE_AVATAR = MODULE_MOUNT_POINT + '::update-avatar'

const actions = {
  [RESET]: reset,
  [SET]: set,
  [SET_CURRENT]: setCurrent,
  [CHANGE]: change,
  [UPDATE_AVATAR]: updateAvatarInHierarchy,
}

export const getInitialState = () => {
  return {
    employees: [],
    isFetching: false,
    isInitialized: false,
  }
}

export const reducer = (state = {}, action) => {
  if (actions.hasOwnProperty(action.type)) {
    return actions[action.type](state, action.payload)
  }
  return state
}
