import React from 'react'
import PropTypes from 'prop-types'
import { AmountFormatter } from '../AmountFormatter/AmountFormatter'
import Icon from '../ui/IconComponent'
import trans from '../../trans'

function ReportProgressBar({
  countryValue,
  abroadValue,
  unit,
  format = null,
  expenseLabel,
  iconType,
}) {
  const countryVal = parseInt(countryValue)
  const abroadVal = parseInt(abroadValue)
  const sumVal = countryVal + abroadVal
  const percentage = sumVal ? (countryVal / sumVal) * 100 : 0

  return (
    <div className='report-bar'>
      <div className='report-bar__progress' style={{ width: `${percentage}%` }} />

      <div className='report-bar__expense'>
        <span className='report-bar__expense-label'>{expenseLabel}</span>
        <Icon className='report-bar__expense-icon' type={iconType} />
      </div>

      <div className='report-bar__content'>
        <div className='report-bar__content-left'>
          <span className='report-bar__label'>{trans('ui.country')}</span>
          <div className='report-bar__value'>
            <AmountFormatter amount={countryValue} format={format} />
            <span>{trans(unit)}</span>
          </div>
        </div>

        <div className='report-bar__content-right'>
          <span className='report-bar__label'>{trans('ui.abroad')}</span>
          <div className='report-bar__value'>
            <AmountFormatter amount={parseFloat(abroadValue)} format={format} />
            <span>{trans(unit)}</span>
          </div>
        </div>
      </div>
    </div>
  )
}

ReportProgressBar.propTypes = {
  countryValue: PropTypes.string.isRequired,
  abroadValue: PropTypes.string.isRequired,
  unit: PropTypes.string.isRequired,
  format: PropTypes.string,
  expenseLabel: PropTypes.string.isRequired,
  iconType: PropTypes.string.isRequired,
}

export default ReportProgressBar
