import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Icon from '../ui/IconComponent'
import { InputValid } from '../../ui'
import { InputErrorComponent } from '../InputErrorComponent'
import isTouchDevice from '../../utils/isTouchDevice'

class EditableErpId extends Component<any, any> {
  constructor(props) {
    super(props)

    this.state = {
      isEditing: false,
      idError: null,
      id: this.props.id,
    }
  }

  setEditing = (e) => {
    e.preventDefault()

    if (this.props.canEdit) {
      this.setState({ isEditing: true })
    }
  }

  renderId = () => {
    const { canEdit } = this.props

    return (
      <div
        className='table-accordion__installment-date table-accordion__installment-date--flex-end'
        onClick={this.setEditing}
      >
        <span>{this.props.id ? this.props.id : '---'}</span>
        {canEdit && (
          <span className='timeline-icon' onClick={this.setEditing}>
            <Icon type='edit' lg gradient />
          </span>
        )}
      </div>
    )
  }

  onChange = (id) => {
    this.setState({
      id,
      idError: null,
    })
  }

  onBlur = (event) => {
    this.submitChange(event)
  }

  submitChange = (event) => {
    event.preventDefault()

    this.props
      .onChange(this.state.id)
      .then(() => this.setState({ isEditing: false }))
      .catch((error) => {
        this.setState({ error: 'Niepoprawna kwota' })
      })
  }

  handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      this.submitChange(event)
    }
  }

  renderInput = () => {
    return (
      <div className='table-accordion__row-edit' onClick={(e) => e.preventDefault()}>
        <InputValid
          name='id'
          placeholder={' '}
          value={this.props.id}
          autoFocus
          onChange={(value) => this.onChange(value)}
          onBlur={this.submitChange}
          onKeyDown={this.handleKeyDown}
        >
          <InputErrorComponent errors={this.state.idError} />
        </InputValid>

        {isTouchDevice() && (
          <span className='timeline-icon' onClick={this.submitChange}>
            <Icon type='checked' lg gradient />
          </span>
        )}
      </div>
    )
  }

  render() {
    return this.state.isEditing ? this.renderInput() : this.renderId()
  }
}

EditableErpId.propTypes = {
  id: PropTypes.string,
  canEdit: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
}

export default EditableErpId
export { EditableErpId }
