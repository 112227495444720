import { get } from 'lodash'
import { trans } from '../../../trans'
import Button from '../../ui/ButtonComponent'
import { connect } from '../../../containers/FlightsBooking'
import React from 'react'
import { SingleOffer } from '../SingleOffer'
import moment from 'moment/moment'
import Warning from '../Offer/Warning'

const SelectedOfferComponent = (props) => {
  const {
    context: {
      element,
      request: { abilities },
    },
    flights: {
      selectors: { selectedThere, selectedBack, query, isBlocked, totalPaxes, travelers },
      actions: { search },
    },
  } = props

  const amountOfferReference = selectedBack ? selectedBack : selectedThere

  const thereDate = moment(get(selectedThere, 'attributes.departureDateTime', null)).format(
    'ddd. D MMM.',
  )
  const backDate = moment(get(selectedBack, 'attributes.departureDateTime', null)).format(
    'ddd. D MMM.',
  )
  const flightClass = get(query, 'flight_class', null)
  const amount = get(
    amountOfferReference,
    'options.0.amount.amount_rounded',
    get(amountOfferReference, 'amount.formatted', '0,00 PLN'),
  ).toLowerCase()
  const fee = get(
    amountOfferReference,
    'options.0.reservation_fee.formatted',
    '0,00 PLN',
  ).toLowerCase()
  const hasBrokenRules = get(amountOfferReference, 'hasBrokenRules', false)
  const rules = get(amountOfferReference, 'rules', false)
  const searchPlane = () => {
    const query = {
      flight_from: element.flight_from,
      flight_to: element.flight_to,
      arrival_at: element.arrival_at,
      return_at: element.return_at,
      round_trip: element.round_trip,
      flight_class: element.flight_class,
      direct_only: element.direct_only,
      restrict_to_fba: element.restrict_to_fba,
      request_travelers: element.request_travelers,
    }

    search(query)
  }

  return (
    <div className='plane-offer'>
      <div className='plane-offer__step'>
        <div className='plane-offer__heading'>
          <strong>{trans('flights-booking.flight-there-single')}</strong>
          <span> - {thereDate}</span>
        </div>

        <SingleOffer
          offer={selectedThere}
          flightClass={flightClass}
          query={query}
          totalPaxes={totalPaxes}
          isReturn={false}
          abilities={abilities}
          travelers={travelers}
        />
      </div>

      {selectedBack && (
        <div className='plane-offer__step'>
          <div className='plane-offer__heading'>
            <strong>{trans('flights-booking.flight-back-single')}</strong>
            <span> - {backDate}</span>
          </div>

          <SingleOffer
            offer={selectedBack}
            flightClass={flightClass}
            query={query}
            totalPaxes={totalPaxes}
            isReturn={true}
            abilities={abilities}
            travelers={travelers}
          />
        </div>
      )}

      <div className='plane-offer__footer'>
        <div className='plane-offer__price-wrapper'>
          {hasBrokenRules && <Warning rules={rules} />}

          <span className='plane-offer__price'>
            {isBlocked ? trans('flights-booking.pricing') : amount}
          </span>
        </div>
        <div className='plane-offer__commission-message'>
          {totalPaxes > 1 && (
            <p className='is-allign-end'>
              {trans('flights-booking.reservation-short-info', { paxes: totalPaxes })}
            </p>
          )}

          <p>
            {trans('flights-booking.commission-message')} {fee}
          </p>
        </div>

        <div className='plane-offer__buttons'>
          <Button
            outline
            type='button'
            className='plane-offer__button plane-offer__button--another'
            onClick={searchPlane}
          >
            {trans('flights-booking.select-another-one')}
          </Button>
          <Button type='submit' primary className='plane-offer__button plane-offer__button--save'>
            {trans('global.save')}
          </Button>
        </div>
      </div>
    </div>
  )
}

const SelectedOffer = connect(SelectedOfferComponent)

export default SelectedOffer
export { SelectedOffer }
