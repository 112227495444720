import { includes, cloneDeep } from 'lodash'
import {
  TRAVEL_ACCOMMODATION_PROVIDED,
  TRAVEL_ACCOMODATION,
  TRAVEL_ACCOMODATION_SUGGESTION,
  TRAVEL_EMPTY,
  TRAVEL_PRIVATE_ACCOMODATION,
} from '../../../../constants/travel'
import { Factory as ElementFactory } from '../../../../models/timeline/index'
import moment from 'moment'

class AccommodationSuggester {
  constructor(elements, current, past) {
    this.newElements = cloneDeep(elements).map((element) => ElementFactory.create(element))

    this.current = ElementFactory.create(current)
    this.past = ElementFactory.create(past)

    if (
      this.current.type === TRAVEL_PRIVATE_ACCOMODATION ||
      this.current.type === TRAVEL_ACCOMODATION ||
      this.current.type === TRAVEL_ACCOMMODATION_PROVIDED ||
      this.current.type === TRAVEL_EMPTY
    ) {
      return this
    }

    this.startDateChenged = false
    this.endDateChanged = false

    if (this.current.getStartDate() !== this.past.getStartDate()) {
      this.startDateChenged = true
    }

    if (this.current.getEndDate() !== this.past.getEndDate()) {
      this.endDateChanged = true
    }

    if (!this.startDateChenged && !this.endDateChanged) {
      return this
    }

    this.excludedTypes = [
      TRAVEL_ACCOMODATION,
      TRAVEL_ACCOMODATION_SUGGESTION,
      TRAVEL_EMPTY,
      TRAVEL_PRIVATE_ACCOMODATION,
      TRAVEL_ACCOMMODATION_PROVIDED,
    ]

    this._suggestionsForCurrent()
    this._suggestionsForVirtualCurrent()
  }

  _suggestionsForCurrent() {
    if (this.startDateChenged) {
      this._findCurrentIndex()
      this._setNextPrevElements()
      this._addSuggestionBefore()
      this._addSuggestionAfter()
    }
  }

  _suggestionsForVirtualCurrent() {
    if (this.endDateChanged) {
      this._findVirtualCurrent()

      if (this.virtualCurrent) {
        this._setVirtualNextPrevElements()
        this._addSuggestionBeforeVirtual()
        this._addSuggestionAfterVirtual()
      }
    }
  }

  _findCurrentIndex() {
    this.currentIndex = this.newElements.findIndex((e) => {
      e = ElementFactory.create(e)
      return e.id === this.current.id && e.type === this.current.type && !e.virtual
    })
  }

  _setNextPrevElements() {
    this.nextElement =
      this.currentIndex < this.newElements.length - 1
        ? ElementFactory.create(this.newElements[this.currentIndex + 1])
        : null
    this.prevElement =
      this.currentIndex > 0 ? ElementFactory.create(this.newElements[this.currentIndex - 1]) : null
  }

  _setVirtualNextPrevElements() {
    this.virtualNextElement =
      this.virtualCurrentIndex < this.newElements.length - 1
        ? ElementFactory.create(this.newElements[this.virtualCurrentIndex + 1])
        : null
    this.virtualPrevElement =
      this.virtualCurrentIndex > 0
        ? ElementFactory.create(this.newElements[this.virtualCurrentIndex - 1])
        : null
  }

  _findVirtualCurrent() {
    this.virtualCurrentIndex = this.newElements.findIndex((e) => {
      return e.id === this.current.id && e.type === this.current.type && e.virtual
    })

    if (this.virtualCurrentIndex) {
      this.virtualCurrent = this.newElements[this.virtualCurrentIndex]
    } else {
      this.virtualCurrent = null
    }
  }

  _addSuggestionBefore() {
    this._findCurrentIndex()

    if (this.prevElement && !includes(this.excludedTypes, this.prevElement.type)) {
      const currentDate = moment(this.current.getDate())
      const prevDate = moment(this.prevElement.getDate())

      if (prevDate.diff(currentDate, 'days') < 0) {
        const suggestion = ElementFactory.create({
          type: TRAVEL_ACCOMODATION_SUGGESTION,
          date: currentDate,
        })
        this.newElements.splice(this.currentIndex, 0, suggestion)
      }
    }
  }

  _addSuggestionAfter() {
    this._findCurrentIndex()

    if (this.nextElement && !includes(this.excludedTypes, this.nextElement.type)) {
      const currentDate = moment(this.current.getDate())
      const nextDate = moment(this.nextElement.getDate())

      if (nextDate.diff(currentDate, 'days') > 0) {
        const suggestion = ElementFactory.create({
          type: TRAVEL_ACCOMODATION_SUGGESTION,
          date: currentDate,
        })
        this.newElements.splice(this.currentIndex + 1, 0, suggestion)
      }
    }
  }

  _addSuggestionBeforeVirtual() {
    this._findVirtualCurrent()
    if (this.virtualPrevElement && !includes(this.excludedTypes, this.virtualPrevElement.type)) {
      const currentDate = moment(this.virtualCurrent.getDate())
      const prevDate = moment(this.virtualPrevElement.getDate())

      if (prevDate.diff(currentDate, 'days') < 0) {
        const suggestion = ElementFactory.create({
          type: TRAVEL_ACCOMODATION_SUGGESTION,
          date: prevDate,
          endDate: currentDate,
        })
        this.newElements.splice(this.virtualCurrentIndex, 0, suggestion)
      }
    }
  }

  _addSuggestionAfterVirtual() {
    this._findVirtualCurrent()
    if (this.virtualNextElement && !includes(this.excludedTypes, this.virtualNextElement.type)) {
      const currentDate = moment(this.virtualCurrent.getDate())
      const nextDate = moment(this.virtualNextElement.getDate())

      if (nextDate.diff(currentDate, 'days') > 0) {
        const suggestion = ElementFactory.create({
          type: TRAVEL_ACCOMODATION_SUGGESTION,
          date: currentDate,
          endDate: nextDate,
        })
        this.newElements.splice(this.virtualCurrentIndex + 1, 0, suggestion)
      }
    }
  }

  getStateWithSuggestions() {
    return this.newElements
  }
}

export { AccommodationSuggester }
export default AccommodationSuggester
