import React from 'react'
import PropTypes from 'prop-types'
import { Field } from 'redux-form/immutable'
import { ExchangeRateField } from '../ui/Form/ExchangeRateField'
import trans from '../../trans'
import { ExchangeFormatter } from '../ExchangeFormatter'
import moment from 'moment'

export class DocumentFormRate extends React.Component<any, any> {
  render() {
    const { document, isAccounting, onRateChange, onRateSuggestionAccept } = this.props
    const rate_day = document.exchange_rate_date
      ? moment(document.exchange_rate_date).format('DD.MM.YYYY')
      : document.default_exchange_rate_date
      ? moment(document.default_exchange_rate_date).format('DD.MM.YYYY')
      : null

    return (
      <div className='account-document-page__rate'>
        <Field
          component={ExchangeRateField}
          name='exchange_rate'
          defaultRate={document.exchange_rate || document.default_exchange_rate}
          change={onRateChange}
          canEdit={document.can_edit_exchange_rate}
        />
        <div className='input-suggestion'>
          {(document.default_exchange_rate_accepted === false || isAccounting) &&
            document.exchange_rate_source === 'individual' && (
              <span>
                {document.exchange_rate_source === 'individual'
                  ? trans('document.individual-rate')
                  : trans('document.rate')}
                &nbsp;
                <ExchangeFormatter amount={document.exchange_rate} />
              </span>
            )}

          {(document.default_exchange_rate_accepted === true || isAccounting) &&
            document.exchange_rate_source === 'strategy' &&
            document.exchange_rate_strategy !==
              'document_request_installment_rate_date_strategy' && (
              <span>
                {trans('document.default-rate')}&nbsp;
                {trans('document.rate-day')} {rate_day}
              </span>
            )}

          {(document.default_exchange_rate_accepted === true || isAccounting) &&
            document.exchange_rate_source === 'strategy' &&
            document.exchange_rate_strategy ===
              'document_request_installment_rate_date_strategy' && (
              <span>
                {trans('document.installment-rate')}&nbsp;
                {trans('document.rate-day')} {rate_day}
              </span>
            )}

          {document.default_exchange_rate_date &&
            !document.exchange_rate &&
            document.default_exchange_rate_strategy !==
              'document_request_installment_rate_date_strategy' && (
              <span>
                {trans('document.suggested-rate')} {trans('document.rate-day')} {rate_day}
              </span>
            )}

          {document.default_exchange_rate_date &&
            !document.exchange_rate &&
            document.default_exchange_rate_strategy ===
              'document_request_installment_rate_date_strategy' && (
              <span>
                {trans('document.suggested-installment-rate')} {rate_day}
              </span>
            )}

          {document.exchange_rate_source === 'accountant' && (
            <span>
              {trans('document.finance-rate')}&nbsp;
              {trans('document.rate-day')} {rate_day}
            </span>
          )}

          {onRateSuggestionAccept &&
            document.default_exchange_rate &&
            document.can_edit_exchange_rate &&
            !document.exchange_rate &&
            !document.default_exchange_rate_accepted && (
              <a
                className=''
                onClick={() => onRateSuggestionAccept(null, document.default_exchange_rate)}
              >
                {trans('ui.accept')}
              </a>
            )}
        </div>
      </div>
    )
  }
}

DocumentFormRate.propTypes = {
  document: PropTypes.object.isRequired,
  onRateChange: PropTypes.func.isRequired,
  isAccounting: PropTypes.bool.isRequired,
  onRateSuggestionAccept: PropTypes.func.isRequired,
}

export default DocumentFormRate
