import axios from 'axios'

class CancellableRequest {
  static instance = null
  request = null
  cancelToken = null

  constructor() {
    if (CancellableRequest.instance !== null) {
      throw new Error('Cannot construct other instance of CancellableRequest')
    }
  }

  static getInstance() {
    if (!CancellableRequest.instance) {
      CancellableRequest.instance = new CancellableRequest()
    }

    return CancellableRequest.instance
  }

  setRequest(request) {
    this.request = request

    if (this.cancelToken) {
      this.cancelToken.cancel()
    }

    this.cancelToken = axios.CancelToken.source()

    return this
  }
}

const instance = CancellableRequest.getInstance()

export default instance
export { instance }
