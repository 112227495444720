import {
  ITransactionSuggestion,
  ITransactionSuggestionResponse,
} from '../../../types/transaction-suggestion'
import { createSlice } from '@reduxjs/toolkit'
import { createSelector } from 'reselect'
import APIClient from '../../../services/APIClient'
import { setTableConfigs } from '../table-config'
import { HttpLink } from '../../../types/http-link'
import { getDocument } from '../document-account-page'

export const TRANSACTION_SUGGESTION_MOUNT_POINT = 'trasnactionSuggestion'

interface InitialState {
  items: ITransactionSuggestion[]
  fetchLink: HttpLink<void>
  isLoading: boolean
  reconciliationInProgress: boolean
  error: any
}

const getInitialState = (): InitialState => ({
  items: [],
  isLoading: false,
  error: null,
  fetchLink: null,
  reconciliationInProgress: false,
})

const slice = createSlice({
  name: TRANSACTION_SUGGESTION_MOUNT_POINT,
  initialState: getInitialState(),
  reducers: {
    reset(state) {
      state = getInitialState()
    },
    setSuggestions(state, action) {
      const { data } = action.payload

      state.items = data
    },
    setFetchLink(state, action) {
      const { link } = action.payload

      state.fetchLink = link
    },
    startLoading(state) {
      state.isLoading = true
      state.error = null
    },
    setLoadingSuccess(state) {
      state.isLoading = false
      state.error = null
    },
    setLoadingFailed(state, action) {
      state.isLoading = false
      state.error = action.payload
    },
    setReconciledTransactionSuggestion(state, action) {
      const transactionId = action.payload

      state.items.forEach((item) => {
        item.reconciled = item.transaction.id === transactionId
      })
    },
    setReconciliationInProgress(state, action) {
      state.reconciliationInProgress = action.payload
    },
  },
})

const {
  reset,
  setReconciledTransactionSuggestion,
  setFetchLink,
  setSuggestions,
  startLoading,
  setLoadingFailed,
  setLoadingSuccess,
} = slice.actions

export { startLoading }

const getState = (state) => state.get(TRANSACTION_SUGGESTION_MOUNT_POINT)

export const getTransactionSuggestions = createSelector(getState, (state) => state.items)
export const getTransactionSuggestionById = (transactionId: string) =>
  createSelector(getTransactionSuggestions, (suggestions) => {
    return suggestions.find((suggestion) => suggestion.transaction.id === transactionId)
  })

export const isTransactionSuggestionsLoading = createSelector(getState, (state) => state.isLoading)

export const getSelectedTransaction = createSelector(getTransactionSuggestions, (suggestions) => {
  return suggestions.find((suggestion) => suggestion.reconciled)
})

export const fetchTransactionSuggestions = (link: HttpLink<void>) => async (dispatch) => {
  if (!link) {
    return
  }

  dispatch(startLoading())

  try {
    const { data } = await APIClient.makeRequestFromHttpLink<ITransactionSuggestionResponse, void>(
      link,
    )
    dispatch(setSuggestions({ data }))
    dispatch(setLoadingSuccess())
    dispatch(setFetchLink(link))
  } catch (err) {
    dispatch(setLoadingFailed(err))
  }
}

export const applyTransactionSuggestion = (transactionId: string) => async (dispatch, getState) => {
  const suggestion = getTransactionSuggestionById(transactionId)(getState())
  const document = getDocument(getState())

  dispatch(startLoading())
  dispatch(setReconciledTransactionSuggestion(transactionId))

  try {
    await APIClient.makeRequestFromHttpLink(suggestion._links.attach)
    await APIClient.makeRequestFromHttpLink(document._links.transaction_suggestions)
    dispatch(setLoadingSuccess())
  } catch (err) {
    dispatch(setLoadingFailed(err))
  }
}
export const detachTransactionSuggestion = () => async (dispatch, getState) => {
  const suggestion = getSelectedTransaction(getState())
  const document = getDocument(getState())

  dispatch(startLoading())
  dispatch(setReconciledTransactionSuggestion(null))

  try {
    await APIClient.makeRequestFromHttpLink(suggestion._links.detach)
    await APIClient.makeRequestFromHttpLink(document._links.transaction_suggestions)
    dispatch(setLoadingSuccess())
  } catch (err) {
    dispatch(setLoadingFailed(err))
  }
}

export default slice.reducer
