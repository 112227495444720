import React from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import { FormattedNumberField } from '../../components/ui/Form'

export const InputValid = ({ className, children, align_right, ...props }) => {
  const InputClasses = classNames(className, {
    'form-group__input-wrapper': true,
    'form-group__input-wrapper--align-right': align_right,
  })

  return (
    <div className={InputClasses}>
      <FormattedNumberField className='input input--text' {...props} />
      {children}
    </div>
  )
}

InputValid.propTypes = {
  className: PropTypes.string,
  max_char: PropTypes.number,
  align_right: PropTypes.bool,
}
