import { LatLng } from './LatLng'
import { isNil } from 'lodash'

class Route {
  constructor(origin, destination) {
    if (!origin instanceof LatLng) {
      throw new Error('Origin must be an instance of LatLng')
    }

    if (!destination instanceof LatLng) {
      throw new Error('Destination must be an instance of LatLng')
    }

    this._origin = origin
    this._destination = destination
  }

  get origin() {
    return this._origin
  }

  set origin(value) {
    if (!value instanceof LatLng) {
      throw new Error('Origin must be an instance of LatLng')
    }

    this._origin = value
  }

  get destination() {
    return this._destination
  }

  set destination(value) {
    if (!value instanceof LatLng) {
      throw new Error('Destination must be an instance of LatLng')
    }

    this._destination = value
  }

  isValid() {
    return (
      !isNil(this.origin.lat) &&
      !isNil(this.origin.lng) &&
      !isNil(this.destination.lat) &&
      !isNil(this.destination.lng)
    )
  }

  isSame(route) {}
}

export default Route
export { Route }
