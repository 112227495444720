import React from 'react'
import { Link } from 'react-router-dom'
import { trans } from '../../trans'
import Button from '../ui/ButtonComponent'
import { Ability } from '../RequestPageCommon/Ability/Ability'
import { getRouteByName } from '../../routes'
import { SectionHeader } from '../ui/SectionHeader'

function ClickToAction({ abilities }) {
  const className =
    abilities.showRegularExpense && abilities.showPeriodicExpense
      ? 'click-to-action_half__box'
      : 'click-to-action__box'

  return (
    <div className='click-to-action'>
      <SectionHeader caption={trans('dashboard-page.there-is-no-tasks')} />

      <div className='click-to-action__boxes'>
        <div className='click-to-action__box-wrapper'>
          <Ability abilities={abilities} ability={['showRegularTrip']}>
            <Link to={getRouteByName('main', 'tripRequestAdd')} className='click-to-action__box'>
              <Button primary icon='plus2' className='advance-icon click-to-action__box-button ' />
              <span className='click-to-action__box-label'>
                {trans('dashboard-page.new-trip-request-action')}
              </span>
            </Link>
          </Ability>
        </div>

        <div className='click-to-action__box-wrapper'>
          <Ability abilities={abilities} ability={['showRegularExpense']}>
            <Link to={getRouteByName('main', 'expenseRequestAdd')} className={className}>
              <Button primary icon='plus2' className='advance-icon click-to-action__box-button ' />
              <span className='click-to-action__box-label'>
                {trans('dashboard-page.new-expense-request-action')}
              </span>
            </Link>
          </Ability>

          <Ability abilities={abilities} ability={['showPeriodicExpense']}>
            <Link to={getRouteByName('main', 'periodicExpenseRequestAdd')} className={className}>
              <Button primary icon='plus2' className='advance-icon click-to-action__box-button ' />
              <span className='click-to-action__box-label'>
                {trans('dashboard-page.new-expense-periodic-request-action')}
              </span>
            </Link>
          </Ability>
        </div>
      </div>
    </div>
  )
}

export { ClickToAction }
export default { ClickToAction }
