import React, { MouseEventHandler } from 'react'
import classNames from 'classnames'
import {
  TYPE_TAXI,
  TYPE_PARKING,
  TYPE_OTHER_BUSINESS_TRIPS,
  TYPE_INTERNAL_MEETING,
  TYPE_MEETING_WITH_CONTRACTOR,
  TYPE_REPRESENTATION,
  TYPE_SERVICE_MATERIALS,
  TYPE_SHOPPING_FOR_THE_OFFICE,
  TYPE_SMALL_EQUIPMENT,
  TYPE_OTHER_MATERIALS,
  TYPE_OTHER_SERVICES,
  TYPE_FUEL,
  TYPE_CAR_MAINTENANCE,
  TYPE_SUBSCRIPTIONS,
  TYPE_OTHERS,
  TYPE_PERIODIC_EXPENSE,
} from '../../constants/cost'
import {
  TRAVEL_COMPANY_CAR_TRIP,
  TRAVEL_PLANE_TRIP,
  TRAVEL_PRIVATE_CAR_TRIP,
  TRAVEL_RENTED_CAR_TRIP,
  TRAVEL_REPLACEMENT_CAR_TRIP,
  TRAVEL_PASSENGER_CAR_TRIP,
  TRAVEL_TRAIN_TRIP,
  TRAVEL_ACCOMODATION,
  TRAVEL_PRIVATE_ACCOMODATION,
  TRAVEL_FERRY,
  TRAVEL_BUS_TRIP,
} from '../../constants/travel'
import { TYPE_FERRYBOAT, TYPE_PLANE, TYPE_TRAIN } from '../../constants/access-lum-sum'
import _ from 'lodash'

export const ICON_TYPES = [
  'company-settings',
  'accomodation',
  'document',
  'add',
  'air',
  'arrow',
  'arrow_down',
  'arrow_up',
  'conf_fill',
  'arrow_more',
  'bell',
  'border_fill',
  'breifcase',
  'calculator',
  'calculator_2',
  'calendar',
  'calendar_cancel',
  'car',
  'car_fill',
  'car_flat',
  'car_fleet',
  'car_rent',
  'car_service',
  'check',
  'checked',
  'checked_fill',
  'checked_fill-square',
  'clock',
  'clock_pkp',
  'close',
  'close_fill',
  'compas',
  'conf',
  'consumption',
  'contractor_meeting',
  'contractor_meeting_new',
  'credit_card',
  'dashboard',
  'diets',
  'document_add',
  'document_add3',
  'document_ok',
  'documents',
  'dot_fill',
  'edit',
  'eurozl',
  'fares',
  'ferry',
  'ferry_fill',
  'filter',
  'flight',
  'flight2',
  'fx_2',
  'gift',
  'globe',
  'gym',
  'home',
  'hotel',
  'hotel_fill',
  'info',
  'lock',
  'lump_sum',
  'mpk',
  'newspaper_subscriptions',
  'office_maintenance',
  'office_shopping',
  'other',
  'other_expenses_car_related',
  'other_materials_service_support',
  'other_services_service_support',
  'parking',
  'plane',
  'plane2',
  'plane2_fill',
  'plane3_fill',
  'plane_fill',
  'minus',
  'minus2',
  'plus',
  'plus2',
  'plus_outline',
  'pin_1',
  'pin_2',
  'point',
  'point2',
  'profile',
  'question_fill',
  'rail',
  'reports',
  'restaurant',
  'restaurant2',
  'search',
  'seat',
  'service_materials',
  'service_support',
  'settings',
  'small_equipment',
  'snow',
  'spa',
  'star',
  'star_fill',
  'suitcase',
  'suitcase-small',
  'suitcase2',
  'taxi_fill',
  'taxi_flat',
  'tv',
  'fuel',
  'train',
  'train_fill',
  'train_flat',
  'trash',
  'user',
  'users',
  'vat',
  'wallet',
  'warning',
  'wash',
  'internal_meeting',
  'point_fill',
  'pool',
  'wydatek_prywatny',
  'wydatek_prywatny_fill',
  'wash_fill',
  'small_equipment_fill',
  'service_support_fill',
  'service_materials_fill',
  'restaurant_fill',
  'parking_fill',
  'other_services_service_support_fill',
  'other_materials_service_support_fill',
  'other_fill',
  'other_expenses_car_related_fill',
  'office_car',
  'office_car_fill',
  'office_shopping_fill',
  'office_maintenance_fill',
  'newspaper_subscriptions_fill',
  'internal_meeting_fill',
  'gift_fill',
  'fuel_fill',
  'fares_fill',
  'contractor_meeting_fill',
  'car_service_fill',
  'car_rent_fill',
  'car_fleet_fill',
  'accomodation_fill',
  'walk',
  'wifi',
  'heart-rate-search',
  'transactions',
  'bus',
  'bus_fill',
  'percent',
  'percent_fill',
  'rails_fill',
  'calendar',
  'administration_fees',
  'administration_fees_fill',
  'call',
  'production_costs',
  'contract_expenses',
  'energy_utility',
  'energy_utility_fill',
  'ohs',
  'ohs_fill',
  'other_costs',
  'other_costs_fill',
  'post',
  'post_fill',
  'renovation_maintenance',
  'renovation_maintenance_fill',
  'technical_advisory',
  'technical_advisory_fill',
  'hotel_marker',
  'hotel_marker_fill',
  'shopping_carts',
  'shopping_carts_fill',
  'house',
  'house_fill',
  'building',
  'building_fill',
  'wine',
  'wine_fill',
  'shield',
  'shield_fill',
  'wedding-dinner',
  'wedding-dinner_fill',
  'dinner',
  'dinner_fill',
  'repairing-service',
  'repairing-service-fill',
  'man',
  'trailer',
  'store',
  'machine',
  'pallet',
  'plastic-wrap',
  'repair',
  'warehouse',
  'mental-health',
  'heart-rate',
  'monitoring',
  'coverall',
  'coverall1',
  'coverall2',
  'health-insurance',
  'partnership',
  'heart',
  'relations',
  'badge',
  'megaphone',
  'target',
  'invoice',
  'www',
  'certificate',
  'system',
  'licence',
  'setting',
  'weight',
  'employee',
  'reverse-logistic',
  'security-guard',
  'van',
  'card',
  'fees',
  'invoice1',
  'bill',
  'book',
  'phone',
  'repair1',
  'stationery',
  'archives',
  'instructor',
  'presentation',
  'presentation1',
  'training',
  'dj',
  'candidate',
  'team',
  'teamwork',
  'confetti',
  'conference-room',
  'business-meeting',
  'business-meeting1',
  'rental',
  'tram',
  'representative-business-meeting',
  'budget',
  'eye',
  'glasses',
]
export const EXPENSES_TYPES_TO_ICONS = {
  'expense-trip-group-meal-domestic-type': 'restaurant',
  'expense-trip-group-meal-abroad-type': 'restaurant',
  'expense-representation-group-representation-own-type': 'dinner',
  'expense-office-group-hygiene-items-type': 'shield',
  'expense-trip-group-meals-meetings-type': 'dinner',
  'expense-trip-group-city-tax-type': 'bill',
  'expense-trip-group-train-fee-type': 'train_flat',
  'expense-meetings-team-integration-type': 'team',
  'expense-meetings-meeting-with-the-consultant-type': 'business-meeting',
  'expense-meetings-meeting-with-the-contractor-type': 'internal_meeting',
  'expense-other-group-refund-for-glasses-type': 'health-insurance',
  'expense-other-group-marketing-materials-type': 'conf',

  'expense-trip-group': 'suitcase2',
  'expense-trip-group-flights-type': 'plane2',
  'expense-trip-group-flights-fee-type': 'plane2',
  'expense-trip-group-plane-type': 'plane2',
  'expense-trip-group-train-type': 'train_flat',
  'expense-trip-group-taxi-type': 'taxi_flat',
  'expense-trip-group-bus-type': 'bus',
  'expense-trip-group-car-rental-type': 'car_rent',
  'expense-trip-group-company-car-type': 'office_car',
  'expense-trip-group-fuel-type': 'fuel',
  'expense-trip-group-parking-type': 'parking',
  'expense-trip-group-buisnes-trips-type': 'fares',
  'expense-trip-group-others-type': 'service_support',
  'expense-trip-group-ferry-type': 'ferry',
  'expense-trip-group-others-traveling': 'rail',
  'expense-accommodatiom-group': 'accomodation',
  'expense-accommodation-group-hotel-type': 'hotel',
  'expense-accommodation-hotel-type': 'hotel',
  'expense-accommodation-group-other-type': 'other',
  'expense-representation-group': 'gift',
  'expense-representation-group-meeting-with-contractor-type': 'wedding-dinner',
  'expense-representation-group-internal-meeting-type': 'internal_meeting',
  'expense-representation-group-gifts-type': 'gift',
  'expense-cars-group': 'car_fleet',
  'expense-cars-group-fuel-type': 'fuel',
  'expense-cars-group-parking-type': 'parking',
  'expense-cars-group-car-wash-type': 'wash',
  'expense-cars-group-car-washing-type': 'wash',
  'expense-cars-group-service-type': 'car_service',
  'expense-office-group': 'office_maintenance',
  'expense-office-group-shopping-for-office-type': 'office_shopping',
  'expense-office-group-small-equipment-type': 'office_maintenance',
  'expense-office-group-subscriptions-type': 'newspaper_subscriptions',
  'expense-office-group-taxi-type': 'taxi_flat',
  'expense-service-group': 'repairing-service',
  'expense-service-group-service-materials-type': 'service_materials',
  'expense-service-group-other-materials-type': 'other_materials_service_support',
  'expense-service-group-other-services-type': 'other_services_service_support',
  'expense-private': 'wydatek_prywatny',
  'expense-consumption-group': 'consumption',
  'expense-consumption-group-consumption-type': 'consumption',
  'expense-representation-group-consumption-type': 'restaurant',
  'expense-other-group': 'other',
  'expense-other-group-commission-type': 'percent',
  'expense-other-group-representation-type': 'gift',
  'expense-other-group-internal-training-type': 'internal_meeting',
  'expense-other-group-post-type': 'post',
  'expense-other-group-subscriptions-type': 'newspaper_subscriptions',
  'expense-other-group-tools-type': 'renovation_maintenance',
  'expense-other-group-trainings-type': 'internal_meeting',
  'expense-other-group-tasting-type': 'wine',
  'expense-other-group-groceries-type': 'shopping_carts',
  'expense-other-group-administration-fees-type': 'administration_fees',
  'expense-other-group-taxi-type': 'taxi_flat',
  'expense-other-group-qhs-type': 'ohs',
  'expense-other-group-other-costs-type': 'other_costs',
  'expense-other-group-fixed-assets-type': 'building',
  'expense-other-group-appliances-type': 'small_equipment',
  'expense-accommodatiom-group-hotel-non-statutory-type': 'hotel_marker',
  'expense-periodic-group-periodic-type': 'calendar',
  'expense-general-contract-expenses-group-fairs-conferences-type': 'internal_meeting',
  'expense-cars-group-car-rental-type': 'car_rent',

  'expense-periodic': 'calendar',
  'expense-other-costs-to-distribute-group': 'other_costs',
  'expense-general-contract-expenses-group': 'budget',
  'expense-production-costs-group': 'production_costs',
  'expense-trip-group-meals-type': 'restaurant',
  'expense-trip-group-other-costs-type': 'other_costs',
  'expense-cars-group-highway-type': 'fares',
  'expense-cars-group-other-costs-type': 'other_costs',
  'expense-office-group-it-equipment-type': 'small_equipment',
  'office-facilities-group-it-equipment-type': 'small_equipment',
  'expense-office-group-ohs-type': 'ohs',
  'expense-office-group-energy-utility-type': 'energy_utility',
  'expense-office-group-post-type': 'post',
  'expense-office-group-transportation-type': 'car',
  'expense-general-contract-expenses-group-transportation-type': 'taxi_flat',
  'expense-office-group-renovation-maintenance-type': 'renovation_maintenance',
  'expense-general-contract-expenses-group-renovations-type': 'renovation_maintenance',
  'expense-office-group-other-services-type': 'other_materials_service_support',
  'expense-office-group-other-costs-type': 'other_costs',
  'expense-other-costs-to-distribute-group-other-costs-type': 'other_costs',
  'expense-office-group-other-staff-costs-type': 'contractor_meeting',
  'expense-general-contract-expenses-group-other-staff-costs-type': 'contractor_meeting',
  'expense-general-contract-expenses-group-building-materials-type': 'service_materials',
  'expense-general-contract-expenses-group-fuel-type': 'fuel',
  'expense-general-contract-expenses-group-energy-utility-type': 'energy_utility',
  'expense-general-contract-expenses-group-ohs-type': 'ohs',
  'expense-general-contract-expenses-group-trainings-type': 'internal_meeting',
  'expense-general-contract-expenses-group-technical-advisory-type': 'technical_advisory',
  'expense-general-contract-expenses-group-other-services-type': 'service_support',
  'expense-general-contract-expenses-group-administration-fees-type': 'administration_fees',
  'expense-general-contract-expenses-group-accommodation-type': 'hotel',
  'expense-other-costs-to-distribute-group-deposits-donations-type': 'percent',
  'expense-production-costs-group-renovations-type': 'renovation_maintenance',
  'expense-production-costs-group-transportation-type': 'taxi_flat',
  'expense-production-costs-group-other-staff-costs-type': 'contractor_meeting',
  'expense-production-costs-group-building-materials-type': 'service_materials',
  'expense-production-costs-group-fuel-type': 'fuel',
  'expense-production-costs-group-energy-utility-type': 'energy_utility',
  'expense-production-costs-group-ohs-type': 'ohs',
  'expense-production-costs-group-trainings-type': 'internal_meeting',
  'expense-production-costs-group-technical-advisory-type': 'technical_advisory',
  'expense-production-costs-group-other-services-type': 'service_support',
  'expense-production-costs-group-administration-fees-type': 'administration_fees',
  'expense-production-costs-group-accommodation-type': 'hotel',

  'expense-representation-group-marketing-materials-type': 'office_shopping',
  'expense-representation-group-marketing-services-type': 'conf',
  'expense-general-contract-expenses-group-tools-type': 'renovation_maintenance',
  'expense-general-contract-expenses-group-other-materials-type': 'production_costs',
  'expense-production-costs-group-tools-type': 'renovation_maintenance',
  'expense-production-costs-group-other-materials-type': 'production_costs',

  // tu start
  'expense-warehouse-maintenance-repair-and-service-others-type': 'man',
  'expense-warehouse-maintenance-repair-and-service-storage-type': 'store',
  'expense-warehouse-maintenance-repair-and-service-semi-trailers-type': 'trailer',
  'expense-warehouse-maintenance-repair-and-service-forklifts-type': 'machine',
  'expense-warehouse-maintenance-pallets-type': 'pallet',
  'expense-warehouse-maintenance-films-packaging-materials-type': 'plastic-wrap',
  'expense-warehouse-maintenance-appliances-type': 'repair',
  'expense-warehouse-maintenance-group': 'warehouse',
  'expense-warehouse-maintenance': 'warehouse',

  'expense-qhs-healthcare-healthcare-other-type': 'other_costs',
  'expense-qhs-healthcare-healthcare-administration-type': 'mental-health',
  'expense-qhs-healthcare-healthcare-operations-type': 'monitoring',
  'expense-qhs-healthcare-healthcare-sales-type': 'heart-rate',
  'expense-qhs-healthcare-coveralls-administration-type': 'coverall',
  'expense-qhs-healthcare-coveralls-operations-type': 'coverall1',
  'expense-qhs-healthcare-coveralls-sales-type': 'coverall2',
  'expense-qhs-healthcare-group': 'health-insurance',
  'expense-qhs-healthcare': 'health-insurance',

  'expense-marketing-representation-type': 'partnership',
  'expense-marketing-donations-type': 'heart',
  'expense-marketing-public-relations-type': 'relations',
  'expense-marketing-hr-employer-branding-type': 'badge',
  'expense-marketing-advertisments-type': 'megaphone',
  'expense-marketing-group': 'target',
  'expense-marketing': 'target',

  'expense-general-contract-expenses-group-reinvoicing-type': 'invoice',
  'expense-general-contract-expenses-group-non-it-licences-type': 'certificate',
  'expense-general-contract-expenses-group-software-type': 'system',
  'expense-general-contract-expenses-group-www-services-type': 'www',
  'expense-general-contract-expenses-group-licences-type': 'licence',
  'expense-general-contract-expenses-group-proffesional-services-type': 'setting',
  'expense-general-contract-expenses-group-debt-collection-type': 'weight',
  'expense-general-contract-expenses-group-hr-services-type': 'employee',
  'expense-general-contract-expenses-group-clearence-costs-non-bps-speed-type': 'security-guard',
  'expense-general-contract-expenses-group-transportation-costs-non-bps-speed-type':
    'reverse-logistic',
  'expense-general-contract-expenses-group-forwarding-costs-non-bps-speed-type': 'van',
  'expense-general-contract-expenses-group-contractual-penalty-type': 'card',
  'expense-general-contract-expenses-group-other-fees-type': 'fees',
  'expense-general-contract-expenses-group-notarial-fee-type': 'invoice1',
  'expense-general-contract-expenses-group-stamp-duty-type': 'bill',

  'expense-general-contract-expenses-group-tasting-type': 'wine',

  'expense-office-group-subscriptions-proffesional-literature-type': 'book',
  'expense-office-group-phone-accessories-type': 'phone',
  'expense-office-group-office-maintenance-type': 'repair1',
  'expense-office-group-stationery-type': 'stationery',
  'expense-office-group-other-office-supplies-type': 'archives',

  'expense-representation-external-meeting-type': 'representative-business-meeting',

  'expense-representation': 'gift',
  // powinna byc nowa ikona shield
  'expense-office-group-cleaning-materials-type': 'shield',

  'expense-training-courses-trainings-administration-type': 'presentation',
  'expense-training-courses-trainings-operations-type': 'instructor',
  'expense-training-courses-trainings-sales-type': 'presentation1',
  'expense-training-courses-group': 'training',
  'expense-training-courses': 'training',

  'expense-meetings-taxi-type': 'taxi_flat',
  'expense-meetings-team-integration-zfss-type': 'dj',
  'expense-meetings-team-integration-administration-type': 'candidate',
  'expense-meetings-team-integration-operations-type': 'team',
  'expense-meetings-team-integration-sales-type': 'teamwork',
  'expense-meetings-representation-type': 'confetti',
  'expense-meetings-internal-meeting-type': 'conference-room',
  'expense-meetings-external-meeting-type': 'business-meeting',
  'expense-meetings-group': 'business-meeting1',
  'expense-meetings': 'business-meeting1',

  'expense-trip-group-other-cost-type': 'other_costs',
  'expense-trip-group-public-transportation-type': 'tram',
  'expense-other-group-public-transportation-type': 'tram',
  'expense-cars-group-auto-servicing-type': 'car_service',

  'expense-fleet-car-rental-type': 'rental',
  'expense-fleet-auto-servicing-type': 'car_service',

  'expense-travel-group-other-cost-type': 'tram',
  'expense-travel-group-hotel-type': 'hotel',
  'expense-accommodatiom-group-hotel-type': 'hotel',
  'expense-other-group-hotel-type': 'hotel',

  'expense-qhs-healthcare-healthcare-contact-lenses-type': 'eye',
  'expense-qhs-healthcare-healthcare-glasses-type': 'glasses',
  'expense-general-contract-expenses-group-refund-for-glasses-type': 'glasses',
  'expense-general-contract-expenses-group-marketing-materials-type': 'office_shopping_fill',
  'expense-general-contract-expenses-group-qhs-type': 'coverall1',

  'expense-office-group-office-groceries-type': 'shopping_carts',
  'expense-office-group-office-supplies-v2-type': 'office_shopping',
  'expense-general-contract-expenses-group-groceries-type': 'shopping_carts',
  'expense-general-contract-expenses-group-taxi-type': 'taxi_flat',
  'expense-general-contract-expenses-group-other-costs-type': 'other_costs'
}

export const EXPENSES_TYPES_FILLED_TO_ICONS = {
  'expense-trip-group-meal-domestic-type': 'restaurant_fill',
  'expense-trip-group-meal-abroad-type': 'restaurant_fill',
  'expense-representation-group-representation-own-type': 'dinner_fill',
  'expense-office-group-hygiene-items-type': 'shield_fill',

  'expense-trip-group': 'suitcase2_fill',
  'expense-trip-group-flights-type': 'plane2_fill',
  'expense-trip-group-plane-type': 'plane2_fill',
  'expense-trip-group-train-type': 'train_fill',
  'expense-trip-group-taxi-type': 'taxi_fill',
  'expense-trip-group-car-rental-type': 'car_rent_fill',
  'expense-trip-group-company-car-type': 'office_car_fill',
  'expense-trip-group-fuel-type': 'fuel_fill',
  'expense-trip-group-parking-type': 'parking_fill',
  'expense-trip-group-buisnes-trips-type': 'fares_fill',
  'expense-trip-group-others-type': 'service_support_fill',
  'expense-trip-group-ferry-type': 'ferry_fill',
  'expense-trip-group-others-traveling': 'rails_fill',
  'expense-accommodatiom-group': 'accomodation_fill',
  'expense-accommodation-group-hotel-type': 'hotel_fill',
  'expense-accommodation-hotel-type': 'hotel_fill',
  'expense-other-group-hotel-type': 'hotel_fill',
  'expense-accommodation-group-other-type': 'other_fill',
  'expense-representation-group': 'gift_fill',
  'expense-representation-group-meeting-with-contractor-type': 'wedding-dinner_fill',
  'expense-representation-group-internal-meeting-type': 'internal_meeting_fill',
  'expense-representation-group-gifts-type': 'gift_fill',
  'expense-cars-group': 'car_fleet_fill',
  'expense-cars-group-fuel-type': 'fuel_fill',
  'expense-cars-group-parking-type': 'parking_fill',
  'expense-cars-group-car-wash-type': 'wash_fill',
  'expense-cars-group-car-washing-type': 'wash_fill',
  'expense-cars-group-service-type': 'car_service_fill',
  'expense-office-group': 'office_maintenance_fill',
  'expense-office-group-shopping-for-office-type': 'office_shopping_fill',
  'expense-office-group-small-equipment-type': 'small_equipment_fill',
  'expense-office-group-subscriptions-type': 'newspaper_subscriptions_fill',
  'expense-office-group-taxi-type': 'taxi_fill',
  'expense-service-group': 'repairing-service-fill',
  'expense-service-group-service-materials-type': 'service_materials_fill',
  'expense-service-group-other-materials-type': 'other_materials_service_support_fill',
  'expense-service-group-other-services-type': 'other_services_service_support_fill',
  'expense-private': 'wydatek_prywatny_fill',
  'expense-consumption-group-consumption-type': 'consumption',
  'expense-other-group': 'other_fill',
  'expense-other-group-commission-type': 'percent_fill',
  'expense-other-group-representation-type': 'gift_fill',
  'expense-other-group-internal-training-type': 'internal_meeting_fill',
  'expense-other-group-post-type': 'post_fill',
  'expense-other-group-subscriptions-type': 'newspaper_subscriptions_fill',
  'expense-other-group-tools-type': 'renovation_maintenance_fill',
  'expense-other-group-trainings-type': 'internal_meeting_fill',
  'expense-other-group-tasting-type': 'wine_fill',
  'expense-other-group-groceries-type': 'shopping_carts_fill',
  'expense-other-group-administration-fees-type': 'administration_fees_fill',
  'expense-other-group-taxi-type': 'taxi_fill',
  'expense-other-group-qhs-type': 'ohs_fill',
  'expense-other-group-other-costs-type': 'other_costs_fill',
  'expense-other-group-fixed-assets-type': 'building_fill',
  'expense-other-group-appliances-type': 'small_equipment_fill',
  'expense-accommodatiom-group-hotel-non-statutory-type': 'hotel_marker_fill',
  'expense-trip-group-bus-type': 'bus_fill',
  'expense-periodic-group-periodic-type': 'calendar',

  'expense-trip-group-meals-type': 'restaurant_fill',
  'expense-trip-group-other-costs-type': 'other_costs_fill',
  'expense-cars-group-highway-type': 'fares_fill',
  'expense-cars-group-other-costs-type': 'other_costs_fill',
  'expense-office-group-it-equipment-type': 'small_equipment_fill',
  'office-facilities-group-it-equipment-type': 'small_equipment_fill',
  'expense-office-group-ohs-type': 'ohs_fill',
  'expense-office-group-energy-utility-type': 'energy_utility_fill',
  'expense-office-group-post-type': 'post_fill',
  'expense-office-group-transportation-type': 'car_fill',
  'expense-general-contract-expenses-group-transportation-type': 'taxi_fill',
  'expense-office-group-renovation-maintenance-type': 'renovation_maintenance_fill',
  'expense-general-contract-expenses-group-renovations-type': 'renovation_maintenance_fill',
  'expense-office-group-other-services-type': 'other_materials_service_support_fill',
  'expense-office-group-other-costs-type': 'other_costs_fill',
  'expense-other-costs-to-distribute-group-other-costs-type': 'other_costs_fill',
  'expense-office-group-other-staff-costs-type': 'contractor_meeting_fill',
  'expense-general-contract-expenses-group-other-staff-costs-type': 'contractor_meeting_fill',
  'expense-general-contract-expenses-group-building-materials-type': 'service_materials_fill',
  'expense-general-contract-expenses-group-fuel-type': 'fuel_fill',
  'expense-general-contract-expenses-group-energy-utility-type': 'energy_utility_fill',
  'expense-general-contract-expenses-group-ohs-type': 'ohs_fill',
  'expense-general-contract-expenses-group-trainings-type': 'internal_meeting_fill',
  'expense-general-contract-expenses-group-technical-advisory-type': 'technical_advisory_fill',
  'expense-general-contract-expenses-group-other-services-type': 'service_support_fill',
  'expense-general-contract-expenses-group-administration-fees-type': 'administration_fees_fill',
  'expense-general-contract-expenses-group-accommodation-type': 'hotel_fill',
  'expense-other-costs-to-distribute-group-deposits-donations-type': 'percent_fill',
  'expense-production-costs-group-renovations-type': 'renovation_maintenance_fill',
  'expense-production-costs-group-transportation-type': 'taxi_fill',
  'expense-production-costs-group-other-staff-costs-type': 'contractor_meeting_fill',
  'expense-production-costs-group-building-materials-type': 'service_materials_fill',
  'expense-production-costs-group-fuel-type': 'fuel_fill',
  'expense-production-costs-group-energy-utility-type': 'energy_utility_fill',
  'expense-production-costs-group-ohs-type': 'ohs_fill',
  'expense-production-costs-group-trainings-type': 'internal_meeting_fill',
  'expense-production-costs-group-technical-advisory-type': 'technical_advisory_fill',
  'expense-production-costs-group-other-services-type': 'service_support_fill',
  'expense-production-costs-group-administration-fees-type': 'administration_fees_fill',
  'expense-production-costs-group-accommodation-type': 'hotel_fill',

  'expense-representation-group-marketing-materials-type': 'office_shopping_fill',
  'expense-representation-group-marketing-services-type': 'conf_fill',
  'expense-representation-external-meeting-type': 'wedding-dinner',
  'expense-general-contract-expenses-group-tools-type': 'renovation_maintenance_fill',
  'expense-general-contract-expenses-group-other-materials-type':
    'other_materials_service_support_fill',
  'expense-production-costs-group-tools-type': 'renovation_maintenance_fill',
  'expense-production-costs-group-other-materials-type': 'other_materials_service_support_fill',
  'expense-general-contract-expenses-group-fairs-conferences-type': 'internal_meeting_fill',

  'expense-office-group-office-groceries-type': 'shopping_carts_fill',
  'expense-office-group-office-supplies-v2-type': 'office_shopping_fill',
  'expense-general-contract-expenses-group-groceries-type': 'shopping_carts_fill',
  'expense-general-contract-expenses-group-taxi-type': 'taxi_flat_fill',
  'expense-general-contract-expenses-group-other-costs-type': 'other_costs_fill'
}

export const COST_TYPES_TO_ICONS = {
  [TYPE_TAXI]: 'taxi_fill',
  [TYPE_PARKING]: 'parking_fill',
  [TYPE_OTHER_BUSINESS_TRIPS]: 'fares_fill',
  [TYPE_INTERNAL_MEETING]: 'internal_meeting_fill',
  [TYPE_MEETING_WITH_CONTRACTOR]: 'contractor_meeting_fill',
  [TYPE_REPRESENTATION]: 'gift_fill',
  [TYPE_SERVICE_MATERIALS]: 'service_materials_fill',
  [TYPE_SHOPPING_FOR_THE_OFFICE]: 'office_shopping_fill',
  [TYPE_SMALL_EQUIPMENT]: 'small_equipment_fill',
  [TYPE_OTHER_MATERIALS]: 'other_materials_service_support_fill',
  [TYPE_OTHER_SERVICES]: 'other_services_service_support_fill',
  [TYPE_FUEL]: 'fuel_fill',
  [TYPE_CAR_MAINTENANCE]: 'car_service_fill',
  [TYPE_SUBSCRIPTIONS]: 'newspaper_subscriptions_fill',
  [TYPE_OTHERS]: 'other_fill',
  [TYPE_PERIODIC_EXPENSE]: 'calendar',
}
export const TRAVEL_TYPES_TO_ICONS = {
  [TRAVEL_PLANE_TRIP]: 'plane2',
  [TRAVEL_PRIVATE_CAR_TRIP]: 'car_flat',
  [TRAVEL_COMPANY_CAR_TRIP]: 'car_flat',
  [TRAVEL_RENTED_CAR_TRIP]: 'car_flat',
  [TRAVEL_REPLACEMENT_CAR_TRIP]: 'car_flat',
  [TRAVEL_PASSENGER_CAR_TRIP]: 'car_flat',
  [TRAVEL_TRAIN_TRIP]: 'train_flat',
  [TRAVEL_FERRY]: 'ferry',
  [TRAVEL_BUS_TRIP]: 'bus',
}
export const TRAVEL_TYPES_TO_ICONS_FLAT = {
  [TRAVEL_PLANE_TRIP]: 'plane2',
  [TRAVEL_PRIVATE_CAR_TRIP]: 'car_flat',
  [TRAVEL_COMPANY_CAR_TRIP]: 'car_flat',
  [TRAVEL_RENTED_CAR_TRIP]: 'car_flat',
  [TRAVEL_REPLACEMENT_CAR_TRIP]: 'car_flat',
  [TRAVEL_PASSENGER_CAR_TRIP]: 'car_flat',
  [TRAVEL_TRAIN_TRIP]: 'train_flat',
  [TRAVEL_FERRY]: 'ferry',
  [TRAVEL_BUS_TRIP]: 'bus',
}

export const TRAVEL_TYPES_TO_FILLED_ICONS = {
  [TRAVEL_PLANE_TRIP]: 'plane2_fill',
  [TRAVEL_PRIVATE_CAR_TRIP]: 'car_fill',
  [TRAVEL_COMPANY_CAR_TRIP]: 'car_fill',
  [TRAVEL_RENTED_CAR_TRIP]: 'car_fill',
  [TRAVEL_REPLACEMENT_CAR_TRIP]: 'car_fill',
  [TRAVEL_PASSENGER_CAR_TRIP]: 'car_fill',
  [TRAVEL_TRAIN_TRIP]: 'train_fill',
  [TRAVEL_ACCOMODATION]: 'hotel_fill',
  [TRAVEL_PRIVATE_ACCOMODATION]: 'accomodation_fill',
  [TRAVEL_FERRY]: 'ferry_fill',
  [TRAVEL_BUS_TRIP]: 'bus_fill',
}

export const ACCOMMODATION_TO_ICONS = {
  [TRAVEL_ACCOMODATION]: 'hotel',
}

export const EXPENSE_COMMUTE_TYPES_TO_ICONS = {
  [TYPE_PLANE]: 'plane2',
  [TYPE_TRAIN]: 'train_flat',
  [TYPE_FERRYBOAT]: 'ferry',
  [TRAVEL_BUS_TRIP]: 'bus',
}

export const ICON_TYPE_MAP_TO_CLASS = {}
ICON_TYPES.forEach((type) => {
  ICON_TYPE_MAP_TO_CLASS[type] = `icon-${type}`
})

export function getIconBasedOnType(type) {
  let iconType = null
  if (
    [
      TRAVEL_COMPANY_CAR_TRIP,
      TRAVEL_PLANE_TRIP,
      TRAVEL_PRIVATE_CAR_TRIP,
      TRAVEL_RENTED_CAR_TRIP,
      TRAVEL_TRAIN_TRIP,
      TRAVEL_FERRY,
      TRAVEL_BUS_TRIP,
      TRAVEL_REPLACEMENT_CAR_TRIP,
      TRAVEL_PASSENGER_CAR_TRIP,
    ].indexOf(type) !== -1
  ) {
    iconType = TRAVEL_TYPES_TO_ICONS_FLAT[type]
  } else if (type === 'accomodation') {
    iconType = 'hotel'
  } else if (type === 'accommodation_lump_sum') {
    iconType = 'accomodation'
  } else if (type === 'travel-expenses') {
    iconType = 'diets'
  } else if (type === 'drive-lump-sums') {
    iconType = 'mpk'
  } else if (type === 'access_lump_sum') {
    iconType = 'rail'
  } else if (!_.isUndefined(EXPENSES_TYPES_TO_ICONS[type])) {
    iconType = EXPENSES_TYPES_TO_ICONS[type]
  } else {
    iconType = type
  }

  return iconType
}

interface IconProps {
  className?: string
  lg?: boolean
  xlg?: boolean
  grey?: boolean
  greyLight?: boolean
  gradient?: boolean
  type: string
  rotate?: boolean
  rotatable?: boolean
  iconFill?: boolean
  onClick?: MouseEventHandler
}

const Icon = (props: IconProps) => {
  const {
    className,
    lg,
    xlg,
    grey,
    greyLight,
    gradient,
    type,
    rotate = false,
    rotatable = false,
    iconFill = false,
    ...rest
  } = props

  const iconClasses = classNames(
    'icon',
    {
      'icon--lg': lg,
      'icon--xlg': xlg,
      'is-icon-grey': grey,
      'is-icon-grey-light': greyLight,
      'is-gradient-success': gradient,
      'icon--rotate': rotate,
      'icon--rotatable': rotatable,
      'icon--filled': iconFill,

      [ICON_TYPE_MAP_TO_CLASS[type] || type]: true,
    },
    className,
  )

  return <i className={iconClasses} {...rest} />
}

export default Icon
