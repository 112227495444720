import PropTypes from 'prop-types'
import React from 'react'
import trans from '../../trans'
import { Col, Row } from '../../ui/Grid'
import { REQUEST_DECISIONS } from '../../store/app/request-action'
import { ExpenseRequestSummary } from '../ExpenseRequestPage/ExpenseRequestSummary'
import { RequestActionManager } from '../../containers/RequestActionManager'
import { RequestAction } from '../RequestAction'
import { Radio } from '../../ui'
import { RequestComments } from '../RequestPageCommon/RequestComments'
import { Ability } from '../RequestPageCommon/Ability/Ability'
import { RequestHeader } from '../RequestPageCommon/RequestHeader'
import ExpenseAccountingDocuments from '../ExpenseAccountingDocuments/ExpenseAccountingDocuments'
import BasicRequestInfoRenderer from '../RequestBasicInfoRenderer/RequestBasicInfoRenderer'
import { AccountingDocuments } from '../AccountingDocuments'
import { noop } from 'lodash/util'
import PrintRequestDocuments from '../PrintRequestDocuments'

class ExpenseRequestAcceptanceSettlement extends React.Component<any, any> {
  isSummaryPage(location) {
    return location.pathname.indexOf('/summary/') !== -1
  }

  render() {
    const { request } = this.props

    return (
      <div>
        <RequestHeader request={request} />

        <BasicRequestInfoRenderer />

        <ExpenseAccountingDocuments
          expenseRequest={this.props.expenseRequest}
          push={this.props.push}
          getRouteByName={this.props.getRouteByName}
          instanceCurrency={this.props.instanceCurrency}
          currentUser={this.props.currentUser}
        />

        <AccountingDocuments
          request={request}
          fetchRequest={noop}
          disableUpload={true}
          disableDelete={true}
          showMileageAllowance={false}
          readOnly={true}
          types={['travel', 'confirmation']}
        />

        {this.isSummaryPage(location) && (
          <div className='summary-settlement'>
            <ExpenseRequestSummary request={request} />
          </div>
        )}
        {!this.isSummaryPage(location) && <ExpenseRequestSummary request={request} />}

        <Ability ability={['acceptSettlement', 'returnToSettlementImprovement']} comparator='nor'>
          <PrintRequestDocuments request={request} />
        </Ability>

        <Ability ability={['acceptSettlement', 'returnToSettlementImprovement']} comparator='or'>
          <RequestActionManager request={request}>
            {({ comment, decision, onChange, onAction }) => (
              <RequestAction
                comment={comment}
                decision={decision}
                onChange={onChange}
                onAction={onAction}
              >
                <Row>
                  <Col xs={12} is_pull_end>
                    <Ability ability={['returnToSettlementImprovement']}>
                      <Radio
                        name='decision'
                        label={trans('request.return-to-improvement')}
                        onClick={() =>
                          onChange('decision', REQUEST_DECISIONS.SETTLEMENT_RETURN_TO_IMPROVEMENT)
                        }
                        checked={decision === REQUEST_DECISIONS.SETTLEMENT_RETURN_TO_IMPROVEMENT}
                      />
                    </Ability>

                    <Radio
                      name='decision'
                      label={trans('global.print-accounting-documents')}
                      onClick={() => {
                        onChange('decision', REQUEST_DECISIONS.PRINT)
                      }}
                      checked={decision === REQUEST_DECISIONS.PRINT}
                    />

                    <Ability ability={['acceptSettlement']}>
                      <Radio
                        name='decision'
                        label={trans('request.confirm')}
                        onClick={() => onChange('decision', REQUEST_DECISIONS.SETTLEMENT_ACCEPT)}
                        checked={decision === REQUEST_DECISIONS.SETTLEMENT_ACCEPT}
                      />
                    </Ability>
                  </Col>
                </Row>
              </RequestAction>
            )}
          </RequestActionManager>
        </Ability>

        <RequestComments comments={request['comments']} />
      </div>
    )
  }
}

ExpenseRequestAcceptanceSettlement.propTypes = {
  request: PropTypes.object.isRequired,
}

export { ExpenseRequestAcceptanceSettlement }
export default { ExpenseRequestAcceptanceSettlement: ExpenseRequestAcceptanceSettlement }
