import PropTypes from 'prop-types'
import React from 'react'
import { FormField } from '../ui/Form'
import { Field } from 'redux-form/immutable'
import { Panel } from '../ui/Panel'
import { LoadingOverlay } from '../ui/LoadingOverlay'
import trans from '../../trans'
import Icon from '../ui/IconComponent'
import NonDelegationOverlay from '../BorderCrossings/NonDelegationOverlay'
import { connect as nonDelegation } from '../../containers/NonDelegation'
import TripDidNotHavePlaceOverlay from '../BorderCrossings/TripDidNotHavePlaceOverlay'
import { deductibleOptions } from '../../store/app/deductibilities'
import { fetchMpkForAsyncSelect } from '../../store/app/mpks'
import { AmountFormatter } from '../AmountFormatter'
import { Loader } from '../ui/LoadingOverlay/Loader'
import DocumentFormExchangeRateTooltip from '../DocumentForm/DocumentFormExchangeRateTooltip'
import { Col, Row } from '../../ui'
import { ExchangeFormatter } from '../ExchangeFormatter'

class AccountTravelExpenseForm extends React.Component<any, any> {
  constructor(props) {
    super(props)
  }

  isAccountant(currentUser) {
    const {
      abilities: { isAccountant },
    } = currentUser
    return isAccountant
  }

  getCurrenciesOptions() {
    const { currencies } = this.props

    return currencies.map((currency) => {
      return {
        label: currency['code'],
        value: currency['id'],
      }
    })
  }

  render() {
    const {
      handleSubmit,
      removeAccountingTravelExpense,
      travelExpense,
      isDeletingInProgress,
      request,
      nonDelegation,
      accountingAccounts,
      data,
      projects,
      accountDimensions,
      isDeductibilityEnabled,
      defaultCurrency,
      currencies,
      amountLoader,
      request: {
        abilities: { account },
      },
    } = this.props

    const currency = currencies.find((c) => c['id'] === data.currency_id)
    const readOnly = !account

    return (
      <NonDelegationOverlay>
        <TripDidNotHavePlaceOverlay>
          <Panel
            closable={!!travelExpense['id']}
            onClose={() => {
              removeAccountingTravelExpense(request, travelExpense)
            }}
            header={
              <h1>
                <Icon type={'restaurant'} lg grey />
                {trans('request.lump-sum-header')}
              </h1>
            }
            className='panel--header-icon'
          >
            <form onSubmit={handleSubmit}>
              <div>
                <Field
                  name='accounting_account_id'
                  type='select'
                  label={trans('document-element.accounting-account')}
                  component={FormField}
                  options={accountingAccounts}
                  labeltop
                />

                <Field
                  name='mpk'
                  type='async-select'
                  label={trans('document-element.mpk')}
                  component={FormField}
                  loadOptions={fetchMpkForAsyncSelect()}
                  labeltop
                  selectedOption={data.mpk}
                  placeholder={trans('instance-users.none')}
                  noOptionsMessage={() => {
                    return trans('user.no-results-for-search-message')
                  }}
                  loadingMessage={() => {
                    return trans('user.searching-message')
                  }}
                  returnFullItemValueOnChange
                />

                {projects.length > 0 && (
                  <Field
                    name='project_id'
                    type='select'
                    label={trans('document-element.project')}
                    component={FormField}
                    options={projects}
                    emptyValue={{
                      label: trans('trip-request.empty-project-option-label'),
                      value: null,
                    }}
                    labeltop
                  />
                )}

                {isDeductibilityEnabled && (
                  <Field
                    name='deductibility'
                    type='select'
                    label={trans('document-element.deductibility')}
                    component={FormField}
                    options={deductibleOptions()}
                    clearable={false}
                    labeltop
                  />
                )}

                <div className='form-group--inline-checkbox form-group--inline-checkbox-disabled'>
                  <Field
                    name='cost_of_earning'
                    type='checkbox'
                    label={trans('document-element.cost-of-earning')}
                    component={FormField}
                    inputOnly={true}
                    labeltop
                    disabled={true}
                  />
                </div>

                <Row>
                  <Col xs={6}>
                    <Row>
                      <Col xs={7} style={{ paddingRight: '0' }}>
                        <div className='form-group form-group--label-top'>
                          <span className='form-group__label'>{trans('document.gross')}</span>
                          <div className='form-group__input-wrapper'>
                            {readOnly ? (
                              <div className='input input--text'>
                                <AmountFormatter amount={data.amount} />{' '}
                                {currency ? currency.code : ''}
                              </div>
                            ) : (
                              <Field
                                name='amount'
                                type='formatted_number'
                                inputOnly
                                component={FormField}
                                withError
                                labeltop
                              />
                            )}
                          </div>
                        </div>
                      </Col>
                      {!readOnly && (
                        <Col xs={5} style={{ paddingLeft: '10px' }}>
                          <div className='form-group form-group--label-top'>
                            <span className='form-group__label'>{trans('document.currency')}</span>
                            <div className='form-group__input-wrapper'>
                              <Field
                                name='currency_id'
                                type='currency'
                                component={FormField}
                                inputOnly
                                currencies={this.getCurrenciesOptions()}
                                label=' '
                                withError
                                labeltop
                              />
                            </div>
                          </div>
                        </Col>
                      )}
                    </Row>
                  </Col>
                  <Col xs={6}>
                    <Field
                      component={FormField}
                      label={
                        <strong>
                          {trans('document.exchanged-amount') + ' ' + defaultCurrency}
                        </strong>
                      }
                      type='html'
                      html={() => {
                        return (
                          <div style={{ marginTop: '7px' }}>
                            <strong className='document__amount document__amount--accounting has-loader'>
                              {amountLoader.isLoading ? (
                                <Loader small />
                              ) : (
                                <AmountFormatter amount={travelExpense.instance_currency_amount} />
                              )}
                            </strong>
                          </div>
                        )
                      }}
                      labeltop
                    />
                  </Col>
                </Row>

                {currency && currency['code'] !== defaultCurrency && (
                  <Row>
                    <Col sm={6}>
                      <div style={{ position: 'relative' }}>
                        <div className='account-document-page__rate'>
                          <ExchangeFormatter amount={travelExpense.exchange_rate} />
                          <div className='input-suggestion'>
                            <span>
                              {trans('document.default-rate')}&nbsp;{trans('document.rate-day')}{' '}
                              {travelExpense.exchange_rate_date}
                            </span>
                          </div>
                        </div>

                        <DocumentFormExchangeRateTooltip type={'expense'} />
                      </div>
                    </Col>
                  </Row>
                )}

                {!nonDelegation.selectors.isDelegation && (
                  <div className='zero-placeholder'>0,00</div>
                )}
              </div>

              {accountDimensions.map((dimension) => (
                <Field
                  name={dimension.fieldName}
                  type='account-dimensions'
                  component={FormField}
                  accountDimension={dimension}
                  selectedDimensions={travelExpense.accountDimensionItems}
                  placeholder={trans('account-dimensions.placeholder')}
                  label={dimension.label}
                  labeltop
                  asField
                  key={dimension.id}
                />
              ))}
            </form>
            {isDeletingInProgress && <LoadingOverlay />}
          </Panel>
        </TripDidNotHavePlaceOverlay>
      </NonDelegationOverlay>
    )
  }
}

AccountTravelExpenseForm.propTypes = {
  accountingAccounts: PropTypes.array.isRequired,
  mpks: PropTypes.array.isRequired,
  removeAccountingTravelExpense: PropTypes.func.isRequired,
  travelExpense: PropTypes.object.isRequired,
  isDeletingInProgress: PropTypes.bool.isRequired,
  request: PropTypes.object.isRequired,
}

AccountTravelExpenseForm = nonDelegation(AccountTravelExpenseForm)

export { AccountTravelExpenseForm }
export default { AccountTravelExpenseForm }
