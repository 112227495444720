import PropTypes from 'prop-types'
import React from 'react'
import { NumericFormat } from 'react-number-format'
import { isFunction, isUndefined } from 'lodash'
import { NumberFormatValues } from 'react-number-format/types/types'

export class FormattedNumberField extends React.Component<any, any> {
  constructor(props) {
    super(props)
  }

  prepareValue(value) {
    if (isUndefined(value)) {
      return ''
    }

    value = '' + value
    return value.replace('.', ',')
  }

  onChangeHandler = (value: NumberFormatValues) => {
    const { onChange } = this.props

    if (!isFunction(onChange)) {
      return false
    }

    onChange(value.floatValue)
  }

  onBlurHandler = (event) => {
    const { onBlur } = this.props

    if (!isFunction(onBlur)) {
      return false
    }

    event.target.value = event.target.value.replace(',', '.')

    onBlur(event)
  }

  onFocusHandler = (event) => {
    const { onFocus } = this.props

    if (!isFunction(onFocus)) {
      return false
    }

    event.target.value = event.target.value.replace(',', '.')

    onFocus(event)
  }

  render() {
    const { name, value, placeholder, disabled, options } = this.props

    const numeralOptions = {
      decimalSeparator: ',',
      decimalScale: 2,
      ...options,
    }

    return (
      <NumericFormat
        name={name}
        className='input input--text'
        value={this.prepareValue(value)}
        placeholder={placeholder ? placeholder : '0,00'}
        disabled={disabled}
        onValueChange={this.onChangeHandler}
        onBlur={this.onBlurHandler}
        onFocus={this.onFocusHandler}
        {...numeralOptions}
      />
    )
  }
}

FormattedNumberField.propTypes = {
  onChange: PropTypes.func,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  value: PropTypes.any,
  disabled: PropTypes.bool,
}

FormattedNumberField.defaultProps = {
  options: {},
  disabled: false,
}
