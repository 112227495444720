import APIClient from '../../../services/APIClient'
import { config } from '../../../config'
import moment from 'moment'

const initialState = {
  data: [],
  isLoading: true,
}

export const MOUNT_POINT = 'assistants'

export const RESET = MOUNT_POINT + '::reset'
export const SET = MOUNT_POINT + '::set'
export const ADD = MOUNT_POINT + '::add'
export const DELETE = MOUNT_POINT + '::delete'

export const reset = () => (dispatch) => {
  dispatch({
    type: RESET,
  })
}

export const setData = (data) => (dispatch) => {
  dispatch({
    type: SET,
    payload: data,
  })
}

export const deleteAssistant = (id) => (dispatch) => {
  dispatch({
    type: DELETE,
    payload: id,
  })
}

export const getAssistantsAction =
  (slug = null) =>
  (dispatch) => {
    return APIClient.getUserAssistants(slug).then((response) => {
      dispatch(setData(response.data))
    })
  }

export const addAssistantAction =
  (userId, slug = null) =>
  (dispatch) => {
    return APIClient.addUserAssistant(userId, slug).then(() => {
      dispatch(getAssistantsAction(slug))
    })
  }

export const deleteAssistantAction =
  (assistantId, slug = null) =>
  (dispatch) => {
    return APIClient.deleteUserAssistant(assistantId, slug).then(() => {
      dispatch(deleteAssistant(assistantId))
      dispatch(getAssistantsAction(slug))
    })
  }

export const reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case RESET:
      return initialState
    case SET:
      return {
        ...state,
        data: payload,
        isLoading: false,
        isLoaded: true,
      }
    case DELETE:
      return {
        ...state,
        data: state.data.filter((sub) => sub.id !== payload),
      }
    default:
      return state
  }
}

export const getState = (state) => state.get(MOUNT_POINT)
export const getAssistants = (state) => getState(state).data
export const isLoading = (state) => getState(state).isLoading
