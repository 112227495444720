import React from 'react'
import CustomAutocompleteField from './CustomAutocompleteField'
import APIClient from '../../../services/APIClient'
import trans from '../../../trans'

const fetch = (...args) => {
  return APIClient.getTrainStations(...args)
}

const TrainStationsAutocompleteField = (props) => {
  return (
    <CustomAutocompleteField
      fetchMethod={fetch}
      {...props}
      errorMessage={trans('train-trip.station-not-found')}
    />
  )
}

export default TrainStationsAutocompleteField
export { TrainStationsAutocompleteField }
