import _, { get } from 'lodash'
import APIClient from '../../../services/APIClient'
import { fetch as fetchCounter } from '../dashboard-counter'
import { getCurrentAssistant } from '../user-profile'

export const MODULE_MOUNT = 'dashboard-settlement-to-accept'

const RESET = MODULE_MOUNT + '::reset'
const SET = MODULE_MOUNT + '::set'
const START_FETCHING = MODULE_MOUNT + '::start-fetching'
const DESTROY = MODULE_MOUNT + '::destroy'
const SET_ACTION_IN_PROGRESS = MODULE_MOUNT + '::set-action-in-progress'

// actions Creators
export const reset = () => (dispatch) => {
  dispatch({
    type: RESET,
  })
}

export const destroy = () => (dispatch) => {
  dispatch({
    type: DESTROY,
  })
}

export const set = (items) => (dispatch) => {
  dispatch({
    type: SET,
    payload: items,
  })
}

export const startFetching = () => (dispatch) => {
  dispatch({
    type: START_FETCHING,
  })
}

export const startAction = () => (dispatch) => {
  dispatch({
    type: START_ACTION,
  })
}

export const setActionInProgress = (requestID, type) => (dispatch) => {
  dispatch({
    type: SET_ACTION_IN_PROGRESS,
    payload: {
      requestID,
      type,
    },
  })
}

export const fetch = (user) => (dispatch, getState) => {
  dispatch(startFetching())

  const currentAssistant = getCurrentAssistant(getState())
  let user = null

  if (currentAssistant) {
    user = currentAssistant
  } else {
    user = getState().get('global').get('currentUser')
  }

  APIClient.settlementsToAccept(user).then((response) => {
    dispatch(set(response.data))
  })
}

export const accept = (request) => (dispatch) => {
  dispatch(setActionInProgress(request['slug'], 'accepting'))

  APIClient.requestSettlementAccept(request).then(() => {
    dispatch(setActionInProgress(request['slug'], false))
    dispatch(fetchCounter())
  })
}

export const reject = (request) => (dispatch) => {
  dispatch(setActionInProgress(request['slug'], 'accepting'))

  APIClient.requestSettlementReturnToImprovement(request).then(() => {
    dispatch(setActionInProgress(request['slug'], false))
    dispatch(fetchCounter())
  })
}

const getInitialState = () => {
  return {
    items: [],
    fetching: true,
    actionInProgress: false,
    isLoaded: false,
    actionsInProgress: {},
  }
}

//actions
export const resetAction = () => {
  return getInitialState()
}

export const destroyAction = () => {
  return getInitialState()
}

export const setAction = (state, payload) => {
  return {
    ...state,
    items: payload,
    fetching: false,
    isLoaded: true,
  }
}

export const startFetchingAction = (state) => {
  return {
    ...state,
    fetching: true,
  }
}

const setActionInProgressAction = (state, payload) => {
  const { requestID, type } = payload
  const newState = {
    ...state,
    actionsInProgress: {
      ...state.actionsInProgress,
      [requestID]: {
        type,
      },
    },
  }

  if (type === false) {
    delete newState['actionsInProgress'][requestID]
    newState['items'] = newState['items'].filter((request) => request['slug'] !== requestID)
  }

  return newState
}

const actions = {
  [RESET]: resetAction,
  [SET]: setAction,
  [START_FETCHING]: startFetchingAction,
  [DESTROY]: destroyAction,
  [SET_ACTION_IN_PROGRESS]: setActionInProgressAction,
}

//selectors
export const getItems = (state) => {
  return get(state.get(MODULE_MOUNT), 'items', {})
}

export const getIsLoaded = (state) => {
  return get(state.get(MODULE_MOUNT), 'isLoaded', false)
}

export const isFetching = (state) => {
  return get(state.get(MODULE_MOUNT), 'fetching', false)
}

export const isActionInProgress = (state) => (requestID) => {
  return !_.isEmpty(state.get(MODULE_MOUNT)['actionsInProgress'][requestID])
}

export const reducer = (state = getInitialState(), action) => {
  if (actions.hasOwnProperty(action.type)) {
    return actions[action.type](state, action.payload)
  }
  return state
}
