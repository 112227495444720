import React from 'react'
import Button from '../../ui/ButtonComponent'
import { ICellRendererParams } from 'ag-grid-community'
import Icon from '../../ui/IconComponent'

export default function IconRenderer(props: ICellRendererParams) {
  if (props.value) {
    return <Icon grey lg type={props.value} />
  }

  return props.valueFormatted || props.value
}
