import React, { Component } from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import Icon from '../../components/ui/IconComponent'
import AnimateHeight from 'react-animate-height'
import { config } from '../../config'
import { isFunction } from 'lodash'
import { toKebabCase } from '../../utils/kebabCase'
import { noop } from 'lodash/util'

export class TimelineAdd extends Component<any, any> {
  render() {
    const classes = classNames(
      {
        timeline: true,
        'timeline--last': this.props.last,
        'timeline__btn-add': true,
      },
      this.props.className,
    )

    return (
      <div className={classes}>
        <div className='timeline__header' onClick={this.props.onClick}>
          <span className='timeline__header-icon'>
            <Icon type={this.props.icon} gradient={this.props.gradient} />
          </span>
          <div className='timeline__header-row'>{this.props.children}</div>
        </div>
      </div>
    )
  }
}

export class TimelineElement extends React.Component<any, any> {
  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside)
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside)
  }

  handleClickOutside = (event) => {
    if (this.state.isOpen && this.ref && !this.ref.contains(event.target)) {
      this.setState({ isOpen: false })
    }
  }

  constructor(props) {
    super(props)
    this.state = {
      isOpen: false,
    }
    this.wrapper = document.getElementsByClassName('timeline-container')[0]
  }

  addActiveToSimilar() {
    const similar = this.wrapper.getElementsByClassName(this.props.className)
    for (let item of similar) {
      item.classList.add('trip-timeline-element--active')
    }
  }

  removeActiveFromSimilar() {
    const similar = this.wrapper.getElementsByClassName(this.props.className)
    for (let item of similar) {
      item.classList.remove('trip-timeline-element--active')
    }
  }

  hasTripElementClass() {
    if (this.ref) {
      const classes = this.ref.classList
      return classes.value.indexOf('trip-timeline-element-') !== -1
    }
  }

  onMouseOverHandler() {
    if (this.props.activeSimilar) {
      if (this.hasTripElementClass()) {
        this.addActiveToSimilar()
      }
    }
  }

  onMouseOutHandler() {
    if (this.props.activeSimilar) {
      if (this.hasTripElementClass()) {
        this.removeActiveFromSimilar()
      }
    }
  }

  render() {
    const timelineClasses = classNames(this.props.className, {
      // 'is-close': props.status === 'close',
      // 'is-open': props.status === 'open',
      // 'is-active': props.status === 'active',
      'timeline accordion': true,
      'timeline--last': this.props.last,
      [`timeline-${toKebabCase(this.props.type)}`]: true,
    })

    const style = { ...this.props.itemStyle, zIndex: this.state.isOpen ? 999 : null }

    return (
      <div
        ref={(ref) => (this.ref = ref)}
        className={timelineClasses}
        onMouseOver={this.onMouseOverHandler.bind(this)}
        onMouseOut={this.onMouseOutHandler.bind(this)}
        style={{ ...this.props.style, ...style }}
        onMouseDown={() => this.setState({ isOpen: true })}
      >
        {this.props.children}
      </div>
    )
  }
}

TimelineElement.propTypes = {
  status: PropTypes.string,
  type: PropTypes.string,
  activeSimilar: PropTypes.bool,
  wrapperRef: PropTypes.object,
}

TimelineElement.defaultProps = {
  activeSimilar: false,
}

export class TimeLineElementHeader extends React.Component<any, any> {
  setRef = (ref) => {
    const { innerRef } = this.props
    if (isFunction(innerRef)) {
      innerRef(ref)
    }
  }

  render() {
    const props = this.props

    // render flag
    if (props.isFlag && props.countryCode && props.countryCode.length) {
      return (
        <div className='timeline__header accordion__header' ref={this.setRef}>
          <span
            className={`timeline__header-flag flag flag-${
              props.countryCode.toLowerCase() || 'other'
            }`}
          />
          <div className='row timeline__header-row'>{props.children}</div>
        </div>
      )
    }

    const timelineHeaderIconClasses = classNames(props.className, {
      'timeline__header-icon--inline': props.inline,
      'timeline__header-icon': true,
    })

    const iconClasses = classNames('', {
      'is-google-red': props.icon === 'pin_1',
      'is-clickable': !!props.onIconClick,
    })

    const gradient = props.icon === 'pin_1' ? false : props.gradient
    const onIconClick = props.onIconClick || noop

    return (
      <div
        className='timeline__header timeline__header--padding accordion__header'
        ref={this.setRef}
      >
        <span className={timelineHeaderIconClasses}>
          <Icon
            className={iconClasses}
            type={props.icon}
            iconFill={props.iconFill}
            gradient={gradient}
            onClick={onIconClick}
          />
        </span>
        <div className='row timeline__header-row'>{props.children}</div>
      </div>
    )
  }
}

export class TimeLineTargetPointHeader extends React.Component<any, any> {
  constructor(props) {
    super(props)
  }

  setRef = (ref) => {
    const { innerRef } = this.props
    if (isFunction(innerRef)) {
      innerRef(ref)
    }
  }

  render() {
    const props = this.props
    const timelineHeaderIconClasses = classNames(props.className, {
      'timeline__header-icon--inline': props.inline,
      'timeline__header-icon': true,
    })

    const iconClasses = classNames('', {
      'is-google-red': props.icon === 'pin_1',
    })

    const gradient = props.icon === 'pin_1' ? false : props.gradient

    return (
      <div
        className='timeline__header timeline__header--target-point accordion__header'
        ref={this.setRef}
      >
        <span className={timelineHeaderIconClasses}>
          <Icon className={iconClasses} type={props.icon} gradient={gradient} />
        </span>
        <div className='row timeline__header-row'>{props.children}</div>
      </div>
    )
  }
}

TimeLineElementHeader.propTypes = {
  inline: PropTypes.bool,
  isFlag: PropTypes.bool,
  countryCode: PropTypes.string,
}

export const TimelineElementLabel = (props) => {
  return <div className='xs-8'>{props.label}</div>
}

TimelineElementLabel.propTypes = {
  label: PropTypes.string.isRequired,
}

export class TimeLineElementContent extends React.Component<any, any> {
  parentRef = React.createRef()
  state = {
    focused: false,
  }

  handleClickOutside = (event) => {
    const { current } = this.parentRef
    if (current && !current.contains(event.target)) {
      this.setState({ focused: false })
    }
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside)
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside)
  }

  render() {
    const { isOpened, ...props } = this.props

    const className = classNames({
      timeline__content: true,
      accordion__content: true,
      focused: this.state.focused,
    })

    return (
      <div
        {...props}
        ref={this.parentRef}
        onClick={() => this.setState({ focused: true })}
        className='accordion-animate'
      >
        <AnimateHeight duration={config.animations.heightDuration} height={isOpened ? 'auto' : 0}>
          <div className={className}>{props.children}</div>
        </AnimateHeight>
      </div>
    )
  }
}
