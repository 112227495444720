import { applyMiddleware, compose, createStore } from 'redux'
import { fromJS } from 'immutable'
import { routerMiddleware } from 'connected-react-router/immutable'
import createReducer from './reducers'
import thunk from 'redux-thunk'
import { createBrowserHistory } from 'history'
import SocketProvider from './utils/SocketProvider'
import { createLogger } from 'redux-logger'

export function configureStore(initialState = {}, history) {
  const middlewares = [thunk, routerMiddleware(history)]

  // if (process.env.NODE_ENV === `development` && process.env.APP_DEBUG === 'true') {
  //     const logger = createLogger({
  //         stateTransformer: (object) => {
  //             return object.toJS();
  //         }
  //     });
  //
  //     middlewares.push(logger);
  // }

  const enhancers = [applyMiddleware(...middlewares)]

  // If Redux DevTools Extension is installed use it, otherwise use Redux compose
  /* eslint-disable no-underscore-dangle */
  const composeEnhancers =
    process.env.NODE_ENV !== 'production' &&
    typeof window === 'object' &&
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
      ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
          // Prevent recomputing reducers for `replaceReducer`
          shouldHotReload: false,
          latency: 0,
          maxAge: 100,
          // actionsBlacklist: ["@@redux-form/REGISTER_FIELD", "@@redux-form/INITIALIZE", "@@redux-form/UNREGISTER_FIELD"]
        })
      : compose
  /* eslint-enable */

  const store = createStore(
    createReducer(history),
    fromJS(initialState),
    composeEnhancers(...enhancers),
  )

  // Extensions
  store.injectedReducers = {} // Reducer registry

  // Make reducers hot reloadable, see http://mxs.is/googmo
  /* istanbul ignore next */
  if (module.hot) {
    module.hot.accept('./reducers', () => {
      store.replaceReducer(createReducer(history, store.injectedReducers))
    })
  }

  SocketProvider.initialize(store)

  return store
}

const initialState = {}

export const history = createBrowserHistory()
export default configureStore(initialState, history)
