import { Cell, Row } from '../../../ui/AccordionTable'
import Button from '../../../ui/ButtonComponent'
import trans from '../../../../trans'
import React from 'react'

export default function MpkListItem({ name, code, company, is_active, edit }) {
  return (
    <Row>
      <Cell fixedWidth={150}>{code}</Cell>
      <Cell fixedWidth='auto'>{name}</Cell>
      <Cell fixedWidth={250}>{company ? company.code : trans('global.all')}</Cell>
      <Cell fixedWidth={120}>
        {trans(is_active ? 'accounting.is_active_active' : 'accounting.is_active_not_active')}
      </Cell>
      <Cell fixedWidth={180} alignRight>
        <Button primary xs className={'btn--tiny'} onClick={edit}>
          {trans('instance-users.edit')}
        </Button>
      </Cell>
    </Row>
  )
}
