import React, { useCallback } from 'react'
import APIClient from '../../../services/APIClient'
import Button from '../../ui/ButtonComponent'
import trans from '../../../trans'
import { Ability } from '../../RequestPageCommon/Ability/Ability'
import { getCurrentUser } from '../../../store/app/user-profile/userProfileSlice'
import { useSelector } from 'react-redux'

export function SecurityWelcomeEmail({ subjectUser = {} }) {
  const currentUser = useSelector(getCurrentUser)

  const sendEmail = useCallback(() => {
    return APIClient.sendWelcomeEmailToUser(subjectUser.slug)
  }, [subjectUser.slug])

  return (
    <Ability ability={['instanceSettingsManageUsers']} abilities={currentUser.abilities}>
      <Button primary onClick={sendEmail}>
        {trans('user.send-welcome-email')}
      </Button>
    </Ability>
  )
}
