import { TripTimeline } from './TripTimeline'
import { TypesMenu } from './TypesMenu'

import {
  CompanyCar,
  Plane,
  PrivateCar,
  RentedCar,
  ReplacementCar,
  PassengerCar,
  Train,
  Accommodation,
  PrivateAccommodation,
  TargetPoint,
  Ferry,
  Bus,
} from './Elements/Containers'

import {
  TRAVEL_PLANE_TRIP,
  TRAVEL_ACCOMODATION,
  TRAVEL_TRAIN_TRIP,
  TRAVEL_PRIVATE_ACCOMODATION,
  TRAVEL_COMPANY_CAR_TRIP,
  TRAVEL_PRIVATE_CAR_TRIP,
  TRAVEL_RENTED_CAR_TRIP,
  TRAVEL_TARGET_POINT,
  TRAVEL_EMPTY,
  TRAVEL_ACCOMODATION_SUGGESTION,
  TRAVEL_FERRY,
  TRAVEL_BUS_TRIP,
  TRAVEL_REPLACEMENT_CAR_TRIP,
  TRAVEL_PASSENGER_CAR_TRIP,
  TRAVEL_ACCOMMODATION_PROVIDED,
} from '../../constants/travel'

import { SelectType } from './SelectType'
import { AccomodationSuggestion } from './Elements/Forms'
import { TrainTimeline } from './Elements/Timeline/TrainTimeline'
import { PlaneTimeline } from './Elements/Timeline/PlaneTimeline'
import { AccommodationTimeline } from './Elements/Timeline/AccommodationTimeline'

const timelineTypes = {
  [TRAVEL_COMPANY_CAR_TRIP]: CompanyCar,
  [TRAVEL_PLANE_TRIP]: PlaneTimeline,
  [TRAVEL_PRIVATE_CAR_TRIP]: PrivateCar,
  [TRAVEL_RENTED_CAR_TRIP]: RentedCar,
  [TRAVEL_REPLACEMENT_CAR_TRIP]: ReplacementCar,
  [TRAVEL_PASSENGER_CAR_TRIP]: PassengerCar,
  [TRAVEL_TRAIN_TRIP]: TrainTimeline,
  [TRAVEL_ACCOMODATION]: AccommodationTimeline,
  [TRAVEL_PRIVATE_ACCOMODATION]: PrivateAccommodation,
  [TRAVEL_ACCOMMODATION_PROVIDED]: PrivateAccommodation,
  [TRAVEL_TARGET_POINT]: TargetPoint,
  [TRAVEL_EMPTY]: SelectType,
  [TRAVEL_ACCOMODATION_SUGGESTION]: AccomodationSuggestion,
  [TRAVEL_FERRY]: Ferry,
  [TRAVEL_BUS_TRIP]: Bus,
}

const additionalTypes = {
  [TRAVEL_TRAIN_TRIP]: Train,
  [TRAVEL_PLANE_TRIP]: Plane,
  [TRAVEL_ACCOMODATION]: Accommodation,
}

const menuTypes = [
  {
    type: TRAVEL_PLANE_TRIP,
    icon: 'plane2',
    render: true,
  },
  {
    type: TRAVEL_PRIVATE_CAR_TRIP,
    icon: 'car_flat',
    render: false,
  },
  {
    type: TRAVEL_RENTED_CAR_TRIP,
    icon: 'car_flat',
    render: false,
  },
  {
    type: TRAVEL_COMPANY_CAR_TRIP,
    icon: 'car_flat',
    render: true,
  },
  {
    type: TRAVEL_REPLACEMENT_CAR_TRIP,
    icon: 'car_flat',
    render: false,
  },
  {
    type: TRAVEL_PASSENGER_CAR_TRIP,
    icon: 'car_flat',
    render: false,
  },
  {
    type: TRAVEL_BUS_TRIP,
    icon: 'bus',
    render: true,
  },
  {
    type: TRAVEL_TRAIN_TRIP,
    icon: 'train_flat',
    render: true,
  },
  // {
  //     type: TRAVEL_FERRY,
  //     icon: 'ferry',
  //     render: true,
  // },
  {
    type: TRAVEL_ACCOMODATION,
    icon: 'hotel',
    render: true,
  },
  {
    type: TRAVEL_PRIVATE_ACCOMODATION,
    icon: 'accomodation',
    render: false,
  },
  {
    type: TRAVEL_ACCOMMODATION_PROVIDED,
    icon: 'accommodation_provided',
    render: false,
  },
  {
    type: TRAVEL_TARGET_POINT,
    icon: 'pin_1',
    render: true,
  },
]

export { timelineTypes, additionalTypes, TripTimeline, menuTypes, TypesMenu }
