import React from 'react'
import { Field } from 'redux-form/immutable'
import { FormField } from '../../../ui/Form'
import { Map } from 'immutable'
import numeral from 'numeral'
import ExchangeSummaryContainer from '../../../../containers/ExchangeSummaryContainer'
import { Booking as FlightsBooking } from '../../../FlightsBooking'
import { connect as flightConnect } from '../../../../containers/FlightsBooking'

class PlaneComponent extends React.Component<any, any> {
  constructor(props) {
    super(props)

    this.state = {
      submitting: false,
    }

    this.onSubmit = this.onSubmit.bind(this)
  }

  componentDidMount = () => {
    const { plane } = this.props

    this.props.flights.actions.setQuery({
      flight_from: plane.flight_from,
      flight_to: plane.flight_to,
      flight_class: plane.flight_class,
      arrival_at: plane.arrival_at,
      return_at: plane.return_at,
      round_trip: plane.round_trip,
      direct_only: plane.direct_only,
      restrict_to_fba: plane.restrict_to_fba,
      request_travelers: plane.request_travelers,
    })

    if (this.props.isOpen) {
      this.props.flights.actions.fetch()
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.isOpen !== this.props.isOpen && this.props.isOpen) {
      this.props.flights.actions.fetch()
    }
  }

  getExchangeSummaryPartials() {
    const { plane } = this.props

    const amount = plane.amount
    const amount_currency = plane.amount_currency

    return Map({
      [amount_currency]: numeral(amount),
    })
  }

  onSubmit(e) {
    if (this.state.submitting) {
      e.preventDefault()
      return false
    }

    this.setState({ submitting: true })
    this.props.handleSubmit(e)
  }

  renderContent() {
    const { plane, change, currencies, travelers } = this.props

    const { element, request } = this.props
    const {
      flights: {
        selectors: { selectedThere, selectedBack, query },
      },
    } = this.props

    const showSingleOffer =
      (!query.round_trip && selectedThere) || (query.round_trip && selectedBack)

    return (
      <form onSubmit={this.onSubmit}>
        <div style={{ display: showSingleOffer ? 'none' : 'block' }}>
          <Field name='draft' type='hidden' component={FormField} inputOnly labeltop />

          <Field name='id' type='hidden' component={FormField} inputOnly labeltop />

          <Field name='type' type='hidden' component={FormField} inputOnly labeltop />

          <ExchangeSummaryContainer
            onSumChange={(sum) => {
              change('converted_amount', sum)
            }}
            partials={this.getExchangeSummaryPartials()}
            showSummary={false}
            request={this.props.request}
          />
        </div>

        <FlightsBooking
          plane={plane}
          currencies={currencies}
          request={request}
          element={element}
          travelers={travelers}
        />
      </form>
    )
  }

  render() {
    return this.renderContent()
  }
}

const Plane = flightConnect(PlaneComponent)

export { Plane }
export default Plane
