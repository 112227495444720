import PropTypes from 'prop-types'
import React from 'react'
import { STATE_SETTLEMENT } from '../../constants/request'
import Icon, { EXPENSES_TYPES_FILLED_TO_ICONS, EXPENSES_TYPES_TO_ICONS } from '../ui/IconComponent'
import trans from '../../trans'
import { TimelineElement, TimeLineElementContent, TimeLineElementHeader } from '../Timeline'
import { Col, Row } from '../../ui/Grid'
import { AmountFormatter } from '../AmountFormatter'
import { RequestDocuments } from '../RequestPageCommon/RequestDocuments'
import { OtherCostsManager } from '../../containers/OtherCostsManager'
import { isUndefined } from 'lodash'
import { StorableTimelineElement } from '../Timeline/StorableTimelineElement'
import { ForeignCurrenciesTooltip } from '../ForeignCurrenciesTooltip'

class TripRequestCostsDocuments extends React.Component<any, any> {
  constructor(props) {
    super(props)
    this.state = {}
  }

  renderForeignCurrenciesTooltip(element, instanceCurrency, accounted = false) {
    let currencies = [
      {
        currency: element.amount_currency,
        value: element.amount,
      },
    ]

    if (accounted) {
      currencies = Object.values(element.accounted_component_amounts).map((e) => ({
        currency: e.currency,
        value: e.amount,
      }))
    }

    return (
      <ForeignCurrenciesTooltip
        instanceCurrency={instanceCurrency}
        foreignCurrencies={currencies}
      />
    )
  }

  render() {
    const { request, instanceCurrency } = this.props
    const {
      request: { state },
    } = this.props

    return (
      <OtherCostsManager request={request}>
        {({ elements, toggle }) => {
          if (elements.length === 0) {
            return null
          }

          const costs = elements.map((element, index) => {
            let uid = 'cost' + element.type + element.id
            const icon = EXPENSES_TYPES_TO_ICONS[element['cost_type']['slug']]

            element['label'] = `${trans(element['cost_type']['short_name'])} ${
              element['description'] ? ` - ${element['description']}` : ''
            }`

            return (
              <TimelineElement key={uid} last={index + 1 === elements.length}>
                <StorableTimelineElement storageKey={uid}>
                  {({ isOpen, toggle }) => {
                    return (
                      <div>
                        <Row className='trip-timeline-element-head'>
                          {state === STATE_SETTLEMENT && (
                            <Col sm={9} is_pull_end style={{ paddingRight: '34px' }}>
                              {trans('request-summary.settled-amount')}
                            </Col>
                          )}
                          <Col sm={3} is_pull_end style={{ paddingRight: '42px' }}>
                            {trans('request-summary.requested-amount')}
                          </Col>
                        </Row>
                        <TimeLineElementHeader icon={icon} iconFill>
                          <Col xs={6}>{element['label']}</Col>

                          {state === STATE_SETTLEMENT && (
                            <Col xs={3} is_pull_end align_center style={{ paddingRight: '29px' }}>
                              {this.renderForeignCurrenciesTooltip(element, instanceCurrency, true)}
                              <AmountFormatter amount={element['accounted_amount']} />
                              &nbsp;
                              {instanceCurrency}
                            </Col>
                          )}

                          <Col xs={3} is_pull_end align_center style={{ paddingRight: '32px' }}>
                            {this.renderForeignCurrenciesTooltip(element, instanceCurrency)}
                            <AmountFormatter amount={element['converted_amount']} />
                            &nbsp;
                            {instanceCurrency}
                          </Col>
                          <div style={{ position: 'absolute', display: 'flex', right: '0' }}>
                            <Icon
                              type='arrow'
                              onClick={() => {
                                toggle(element, true)
                              }}
                              rotate={isOpen}
                              rotatable
                            />
                          </div>
                        </TimeLineElementHeader>

                        <TimeLineElementContent isOpened={isOpen}>
                          <div className='tooltip-content'>
                            <RequestDocuments
                              element={element}
                              documents={element['documents']}
                              request={request}
                              listName={`cost-${element.id}`}
                            />
                          </div>
                        </TimeLineElementContent>
                      </div>
                    )
                  }}
                </StorableTimelineElement>
              </TimelineElement>
            )
          })

          return (
            <section className='section section--no-border'>
              <div className='accordion'>
                <header className='section__header accordion__bar'>
                  <h1 className='h2 section__header-title'>{trans('request.other-costs')}</h1>
                </header>

                <div className='accordion__content'>
                  <div className='timeline-container timeline-container--other-costs'>{costs}</div>
                </div>
              </div>
            </section>
          )
        }}
      </OtherCostsManager>
    )
  }
}

TripRequestCostsDocuments.propTypes = {
  request: PropTypes.object.isRequired,
  instanceCurrency: PropTypes.string.isRequired,
  fetchRequest: PropTypes.func.isRequired,
}

export { TripRequestCostsDocuments }
export default { TripRequestCostsDocuments }
