import React, { Component } from 'react'
import { Helmet } from 'react-helmet'
import { BreadCrumbs } from '../../containers'
import { Section } from '../ui/Section'
import { SectionHeader } from '../ui/SectionHeader'
import { TripRequestsListTable } from '../TripRequestsListTable'
import { getRouteByName } from '../../routes'
import { Link } from 'react-router-dom'
import trans from '../../trans'
import AgentTripRequestsListFilters from '../TripRequestsListFilters/AgentTripRequestsListFilters'
import { AgentTripRequestsManager } from '../../containers/AgentTripRequestsManager'
import NewRequestButton from './NewRequestButton'

export class AgentTripRequestsListPage extends Component<any, any> {
  render() {
    return (
      <div>
        <BreadCrumbs>
          <Link to={getRouteByName('main', 'dashboard')}>{trans('global.dashboard')}</Link>
          <Link to={getRouteByName('main', 'agent')}>{trans('main-menu.agent')}</Link>
        </BreadCrumbs>
        <AgentTripRequestsManager>
          {({
            requests,
            requestsFilters,
            changeRequestsFilter,
            areRequestsLoaded,
            areRequestsLoading,
            requestsPagination,
            changeRequestsPage,
            changeOwner,
            requestsSorter,
            changeRequestsSorter,
            selectedUser,
          }) => (
            <Section noBorder>
              <Helmet title={trans('main-menu.agent')} />

              <SectionHeader caption={trans('main-menu.agent')}>
                <NewRequestButton owner={selectedUser} />
              </SectionHeader>

              <AgentTripRequestsListFilters
                filters={requestsFilters}
                onChangeFilter={changeRequestsFilter}
                onChangeOwner={changeOwner}
              />

              <TripRequestsListTable
                requests={requests}
                isLoading={!areRequestsLoaded || areRequestsLoading}
                getRouteByName={getRouteByName}
                pagination={requestsPagination}
                changePage={changeRequestsPage}
                sorter={requestsSorter}
                onChangeSorter={changeRequestsSorter}
              />
            </Section>
          )}
        </AgentTripRequestsManager>
      </div>
    )
  }
}
