import { Cell, Row } from '../../../ui/AccordionTable'
import Button from '../../../ui/ButtonComponent'
import trans from '../../../../trans'
import React from 'react'

export default function KpiListItem({ name, value, edit }) {
  const formattedValue = value ? String(value).replace('.', ',') : ''

  return (
    <Row>
      <Cell fixedWidth='auto'>{name}</Cell>
      <Cell fixedWidth={150} alignRight>
        {formattedValue}
      </Cell>
      <Cell fixedWidth={100}>{trans('accounting.kpi-unit')}</Cell>
      <Cell fixedWidth={180} alignRight>
        <Button primary xs className={'btn--tiny'} onClick={edit}>
          {trans('instance-users.edit')}
        </Button>
      </Cell>
    </Row>
  )
}
