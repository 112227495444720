import React from 'react'

const withAmountLoader = (Component) => {
  class AmountLoaderHOC extends React.Component<any, any> {
    state = {
      isLoading: false,
    }

    getProps() {
      return {
        isLoading: this.state.isLoading,
        setLoading: (isLoading) => this.setState({ isLoading }),
      }
    }

    render() {
      return <Component<any, any> {...this.props} amountLoader={this.getProps()} />
    }
  }

  return AmountLoaderHOC
}

export default withAmountLoader
export { withAmountLoader }
