import PropTypes from 'prop-types'
import React, { Component } from 'react'
import Avatar from '../../Avatar/Avatar'

export class UserInfo extends Component<any, any> {
  constructor(props) {
    super(props)
  }

  render() {
    const { user } = this.props

    return (
      <div className='dashboard-requests-to-accept__user-info'>
        <div className='dashboard-requests-to-accept__user-info__avatar'>
          <Avatar user={user} className='avatar' />
        </div>
        <div className='dashboard-requests-to-accept__user-info__name'>
          <span>
            {user['first_name']} {user['last_name']}
          </span>
        </div>
      </div>
    )
  }
}

UserInfo.propTypes = {
  user: PropTypes.object.isRequired,
}
