import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Employee from './Employee'

class CompanyHierarchy extends Component<any, any> {
  handleCenterEmployee(e) {
    e.target.scrollIntoView({ behavior: 'smooth', inline: 'center' })
  }

  renderEmployees(employees) {
    return employees.map((employee) => {
      if (!employee.subordinates.length) {
        return (
          <li key={employee.name}>
            <Employee employee={employee} />
          </li>
        )
      }

      return (
        <li key={employee.name} className='has-child-node'>
          <Employee employee={employee} />
          <ul>{this.renderEmployees(employee.subordinates)}</ul>
        </li>
      )
    })
  }

  render() {
    const { employees } = this.props

    return (
      <ul onClick={this.handleCenterEmployee} className='company-hierarchy__list'>
        {this.renderEmployees(employees)}
      </ul>
    )
  }
}

CompanyHierarchy.propTypes = {
  employees: PropTypes.array.isRequired,
}

export default CompanyHierarchy
export { CompanyHierarchy }
