import APIClient from '../../../services/APIClient'

import {
  BOOKING_NEW,
  CHANGE,
  CHANGE_STATUS,
  FILTER_RETURN_DEPARTURE_TIME,
  FILTER_RETURN_DEPARTURE_TIME_DEFAULT_FROM,
  FILTER_RETURN_DEPARTURE_TIME_DEFAULT_TO,
  FILTER_TARGET_DEPARTURE_TIME,
  FILTER_TARGET_DEPARTURE_TIME_DEFAULT_FROM,
  FILTER_TARGET_DEPARTURE_TIME_DEFAULT_TO,
  RESET,
  SEARCH_ANOTHER_ONE,
  SEARCH_STATUS_PENDING,
  SELECT_MODE_BACK,
  SELECT_MODE_THERE,
  SET,
  SET_FILTER,
  SET_OFFERS,
  SET_PAXES,
  TOGGLE_SEARCH,
  CHANGE_ERROR_MESSAGE_SLUG,
} from './index'
import { get, mapValues } from 'lodash'
import { change as changeForm, stopSubmit } from 'redux-form'
import { STATUS_DRAFT } from '../../../constants/request'
import {
  arePaxAvailableAttributesForBoth,
  getFilters,
  getOfferOption,
  getOffers,
  getPaxesRequestedServices,
  getPaxRequestedAttribute,
  getQuery,
  getSearchUuid,
  getSelectedBack,
  getSelectedOffer,
  getSelectedThere,
  getSortBy,
  isSearchEnabled,
} from './selectors'
import {
  getOffersUuidConcatenation,
  getOptionsUuidConcatenation,
  normalizeAttributes,
} from './helpers'
import { FlightsSearchRequest } from '../../../requests/FlightsSearchRequest'
import { FlightsFilterRequest } from '../../../requests/FlightsFilterRequest'
import CancellableRequest from '../../../requests/CancellableRequest'
import { FlightsSortRequest } from '../../../requests/FlightsSortRequest'
import SocketProviderService from '../../../utils/SocketProvider'
import { getSelectedTravelersOrDefault } from '../request-traveler/helper'
import { getRequestTravelers } from '../request-traveler'
import {
  deleteElement,
  removeElement,
  saveElement,
  updateElementSearchUuid,
} from '../trip-timeline'

// actions Creators
export const reset = (name) => () => (dispatch) => {
  dispatch(change(name)('isChanged', false))
  dispatch({
    type: RESET,
    payload: {
      name,
    },
  })
}

export const set = (name) => (data) => (dispatch) => {
  dispatch({
    type: SET,
    payload: {
      name,
      data,
    },
  })
}

export const change = (name) => (path, value) => (dispatch) => {
  dispatch({
    type: CHANGE,
    payload: {
      name,
      path,
      value,
    },
  })
}

export const search = (name) => (request) => (query) => (dispatch, getState) => {
  dispatch(startLoading(name)())
  dispatch(setIntegrity(name)())
  dispatch(clearPaxes(name)())
  dispatch(change(name)('hasOffers', false))
  dispatch(change(name)('searchFailed', false))
  dispatch(change(name)('isChanged', false))

  const state = getState()
  const sort = getSortBy(name)(state)
  const filters = getFilters(name)(state)
  const request_travelers = getSelectedTravelersOrDefault(query.request_travelers, state)

  dispatch(
    setQuery(name)({
      ...query,
      request_travelers,
    }),
  )

  const flightsSearchRequest = new FlightsSearchRequest(
    query.arrival_at,
    query.return_at,
    query.flight_from,
    query.flight_to,
    query.round_trip,
    query.direct_only,
    query.flight_class,
    filters.target_departure_time,
    filters.return_departure_time,
    sort,
    query.restrict_to_fba,
    request_travelers,
  )

  APIClient.searchFlights(request, flightsSearchRequest)
    .then((response) => {
      dispatch(change(name)('uuid', response.data.uuid))
      dispatch(change(name)('isResultsListOpen', true))
      dispatch(subscribeSearchResult(name))

      if (response.data.status === 'complete') {
        return APIClient.getOffersByUuid(response.data.uuid).then((response) => {
          dispatch(setOffers(name)(response.data))
          dispatch(setInitialized(name)(true))

          dispatch(change(name)('searching', false))

          return response
        })
      }
    })
    .catch((error) => {
      dispatch(stopLoading(name)())

      const errors = mapValues(get(error, 'alerts.0.errors', {}), (error) => get(error, '0'))

      dispatch(change(name)('searchFailed', true))
      dispatch(stopSubmit(name, errors))

      return error
    })
}

export const setPaxes = (name) => (paxes) => (dispatch, getState) => {
  const state = getState()
  const offerThere = getSelectedThere(name)(state)
  const offerBack = getSelectedBack(name)(state)
  const travelers = getRequestTravelers(getState())

  if (!paxes) {
    return
  }

  const flightTravelers = Object.keys(paxes)
    .map((slug) => {
      const traveler = travelers.find((traveler) => traveler.slug === slug)

      if (!traveler) {
        return null
      }

      const pax = paxes[slug]
      const attributes = {
        full_name: traveler.user.full_name,
        avatar: traveler.user.avatar,
        email: traveler.user.email,
        slug,
        fba: pax.fba,
        additionalServices: pax.additionalServices,
        bookedServices: pax.bookedServices,
        requestedServices: pax.requestedServices,
      }

      const normalizedAttributes = normalizeAttributes(attributes, offerThere, offerBack)
      const newAttributes = {
        ...normalizedAttributes,
        requestedServices: {
          ...normalizedAttributes.requestedServices,
          ...attributes.requestedServices,
        },
      }

      return {
        [slug]: newAttributes,
      }
    })
    .filter(Boolean)
    .reduce((a, b) => ({ ...a, ...b }), {})

  dispatch({
    type: SET_PAXES,
    payload: {
      name,
      paxes: flightTravelers,
    },
  })
}

export const setOffers =
  (name) =>
  ({ uuid, status, offers, rules, totalPaxes }) =>
  (dispatch, getState) => {
    dispatch({
      type: SET_OFFERS,
      payload: {
        name,
        uuid,
        offers,
        status,
        rules,
        totalPaxes,
      },
    })

    if (offers.length) {
      dispatch(change(name)('hasOffers', true))
      dispatch(change(name)('isFiltering', false))
    } else {
      dispatch(change(name)('hasOffers', false))
      if (status !== SEARCH_STATUS_PENDING) {
        dispatch(change(name)('isFiltering', false))
      }
    }

    dispatch(change(name)('isChanged', false))
  }

export const startLoading = (name) => () => (dispatch) => {
  dispatch(change(name)('loading', true))
}

export const stopLoading = (name) => () => (dispatch) => {
  dispatch(change(name)('loading', false))
}

export const changeStatus = (name) => (offerUuid, optionUuid, status) => (dispatch) => {
  dispatch({
    type: CHANGE_STATUS,
    payload: {
      name,
      offerUuid,
      optionUuid,
      status,
    },
  })
}

export const changeErrorMessageSlug = (name) => (offerUuid, errorMessageSlug) => (dispatch) => {
  dispatch({
    type: CHANGE_ERROR_MESSAGE_SLUG,
    payload: {
      name,
      offerUuid,
      errorMessageSlug,
    },
  })
}

export const subscribeReservationStatus = (name) => () => (dispatch, getState) => {
  const searchUuid = getSearchUuid(name)(getState())
  const channelName = `App.Offer.${searchUuid}`
  const eventName = `.App\\Events\\ReservationStatusChangedEvent`

  SocketProviderService.subscribe(channelName)(eventName)((response) => {
    dispatch(
      changeErrorMessageSlug(name)(response.offer.offer_uuid, response.offer.error_message_slug),
    )
    dispatch(
      changeStatus(name)(
        response.offer.offer_uuid,
        response.offer.option_uuid,
        response.offer.status,
      ),
    )
  })
}

export const unsubscribeReservationStatus = (name) => () => (dispatch, getState) => {
  const searchUuid = getSearchUuid(name)(getState())
  const channelName = `App.Offer.${searchUuid}`
  const eventName = `.App\\Events\\ReservationStatusChangedEvent`

  SocketProviderService.unsubscribe(channelName)(eventName)
}

export const subscribeSearchResult = (name) => (dispatch, getState) => {
  const searchUuid = getSearchUuid(name)(getState())
  const channelName = `App.Offer.${searchUuid}`
  const eventName = `.App\\Events\\SearchOffersResultsChanged`

  SocketProviderService.subscribe(channelName)(eventName)((response) => {
    dispatch(setOffers(name)(response))
  })
}

export const unsubscribeSearchResult = (name) => (dispatch, getState) => {
  const searchUuid = getSearchUuid(name)(getState())
  const channelName = `App.Offer.${searchUuid}`
  const eventName = `.App\\Events\\SearchOffersResultsChanged`

  SocketProviderService.unsubscribe(channelName)(eventName)
}

export const fetchSelectedForElement = (name) => (request, element) => () => (dispatch) => {
  return APIClient.getOffers(request, { element_id: element.id, element_type: element.type }).then(
    (response) => {
      const data = response.data

      dispatch(setOffers(name)(data))
      dispatch(setPaxes(name)(data.paxes))

      if (
        get(request, 'status', null) === STATUS_DRAFT &&
        !response.data.uuid &&
        Number(element.amount)
      ) {
        dispatch(setEnable(name)(false))
      }
    },
  )
}

export const setIntegrity = (name) => () => (dispatch) => {
  dispatch(change(name)('integrity', true))
}

export const setFilter = (name) => (filter) => (dispatch, getState) => {
  dispatch({
    type: SET_FILTER,
    payload: {
      name,
      filter,
    },
  })
}

export const searchFilters = (name) => () => (dispatch, getState) => {
  dispatch(change(name)('isFiltering', true))
  dispatch(researchFlights(name)())
  dispatch(change(name)('isChanged', false))
}

export const setFilters = (name) => (filters) => (dispatch) => {
  dispatch(change(name)('filter', filters))
}

export const setQuery = (name) => (query) => (dispatch) => {
  dispatch(change(name)('query', query))
}

export const bookOffer = (name) => (request, element) => () => async (dispatch, getState) => {
  const state = getState()
  const uuid = getSearchUuid(name)(state)
  const selectedThere = getSelectedThere(name)(state)
  const selectedBack = getSelectedBack(name)(state)
  const selectedOffersUuid = getOffersUuidConcatenation(selectedThere, selectedBack)

  dispatch(unsubscribeSearchResult(name))

  if (element.draft) {
    const thereOption = getOfferOption(selectedThere)
    const thereAttributes = selectedThere.attributes
    const backAttributes = selectedBack ? selectedBack.attributes : null
    const query = getQuery(name)(state)

    const savedElement = await dispatch(
      saveElement(
        request,
        {
          ...element,
          ...query,
          offer_uuid: selectedOffersUuid,
          search_uuid: uuid,
          amount: thereOption.amount.amount,
          amount_currency: thereOption.amount.currency,
          target_real_arrival_at: thereAttributes.arrivalDateTime,
          target_real_departure_at: thereAttributes.departureDateTime,
          return_real_arrival_at: backAttributes ? backAttributes.arrivalDateTime : null,
          return_real_departure_at: backAttributes ? backAttributes.departureDateTime : null,
        },
        {
          ...element,
          offer_uuid: selectedOffersUuid,
          search_uuid: uuid,
        },
      ),
    )

    const selectedOffer = getSelectedOffer(name)(state)
    await dispatch(selectOffer(name, request, savedElement)(selectedOffer))
  }

  dispatch(change(name)(`selected.${SELECT_MODE_THERE}.options.0.booking`, BOOKING_NEW))

  if (selectedBack) {
    dispatch(change(name)(`selected.${SELECT_MODE_BACK}.options.0.booking`, BOOKING_NEW))
  }

  const paxes = getPaxesRequestedServices(name)(state)

  await APIClient.bookOffer(request, uuid, { paxes }).catch((error) => {
    dispatch(unsubscribeReservationStatus(name)())
    dispatch(change(name)(`selected.${SELECT_MODE_THERE}.options.0.booking`, null))
    if (selectedBack) {
      dispatch(change(name)(`selected.${SELECT_MODE_BACK}.options.0.booking`, null))
    }
  })
}

export const searchAnotherOne = (name) => (request) => () => (dispatch, getState) => {
  const uuid = getSearchUuid(name)(getState())

  dispatch({
    type: SEARCH_ANOTHER_ONE,
    payload: {
      name,
    },
  })

  dispatch(removeSelected(name)())
  dispatch(setAgreement(name, null, null)(false))
  dispatch(clearTimeFilters(name)())
  dispatch(clearPaxes(name)())

  APIClient.chooseFlightOffer(request, {
    search_uuid: uuid,
    attributes: {},
    paxes: {},
    request_element: {
      type: null,
      id: null,
    },
  })
}

export const isChanged = (name) => (value) => (dispatch) => {
  dispatch(change(name)('isChanged', value))
}

export const toggleSearch = (name) => () => (dispatch) => {
  dispatch({
    type: TOGGLE_SEARCH,
    payload: {
      name,
    },
  })
}

export const setEnable = (name) => (state) => (dispatch) => {
  dispatch(change(name)('enabled', state))
}

export const resignFromSearching = (name) => (request, element) => () => (dispatch) => {
  dispatch(change(name)('enabled', false))
  dispatch(changeForm(name, 'searcher_disabled', true))
  dispatch(changeForm(name, 'amount', null))

  if (element.acceptance_source === 'additional') {
    if (!element.draft) {
      dispatch(deleteElement(request, element))
    } else {
      dispatch(removeElement(element))
    }

    dispatch(reset(name)())
  } else if (!element.draft) {
    APIClient.disableSearcher(request, element)
  }
}

export const backToSearching = (name) => (request) => (element) => () => (dispatch) => {
  dispatch(change(name)('enabled', true))
  dispatch(changeForm(name, 'searcher_disabled', false))

  if (!element.draft) {
    APIClient.updatePlaneTrip(request.slug, element.id, { ...element, searcher_disabled: false })
  }
}

export const afterCloseResultsList = (name) => () => (dispatch) => {
  dispatch(change(name)('loading', false))
}

export const closeResultsList = (name) => () => (dispatch) => {
  dispatch(clearTimeFilters(name)())
  dispatch(clearPaxes(name)())
  dispatch(removeSelected(name, null, null)())
  dispatch(change(name)('isResultsListOpen', false))
  dispatch(unsubscribeSearchResult(name))
}

export const setInitialized = (name) => (value) => (dispatch) => {
  dispatch(change(name)('initialized', value))
}

export const removeSelected = (name, request, element) => () => (dispatch) => {
  dispatch(
    change(name)('selected', {
      [SELECT_MODE_THERE]: null,
      [SELECT_MODE_BACK]: null,
    }),
  )
}

export const selectOffer = (name, request, element) => (offer) => (dispatch, getState) => {
  const state = getState()
  const isRound = get(getQuery(name)(state), 'round_trip', false)

  if (isRound) {
    return dispatch(selectRoundTripOffer(name, request, element)(offer))
  } else {
    return dispatch(selectOneWayOffer(name, request, element)(offer))
  }
}

export const selectRoundTripOffer = (name, request, element) => (offer) => (dispatch, getState) => {
  const state = getState()
  const selectedThere = getSelectedThere(name)(state)

  //set amount in redux form
  const amount = Number.parseFloat(get(offer, 'options.0.amount.amount', 0))
  const fee = Number.parseFloat(get(offer, 'options.0.reservation_fee.amount', 0))
  dispatch(changeForm(name, 'amount', amount + fee))

  if (!selectedThere) {
    dispatch(change(name)('isFetchingReturns', true))

    return dispatch(fetchReturnFlights(name)(offer)).then(() => {
      //select offer
      dispatch(change(name)(`selected.${SELECT_MODE_THERE}`, offer))
      dispatch(change(name)('isFetchingReturns', false))
    })
  } else {
    dispatch(change(name)(`selected.${SELECT_MODE_BACK}`, offer))
    dispatch(change(name)('isResultsListOpen', false))
    dispatch(unsubscribeSearchResult(name))

    return dispatch(saveOffer(name, request, element)(offer))
  }
}

export const selectOneWayOffer = (name, request, element) => (offer) => (dispatch) => {
  //set amount in redux form
  const amount = Number.parseFloat(get(offer, 'options.0.amount.amount', 0))
  const fee = Number.parseFloat(get(offer, 'options.0.reservation_fee.amount', 0))
  dispatch(changeForm(name, 'amount', amount + fee))

  dispatch(change(name)(`selected.${SELECT_MODE_THERE}`, offer))
  dispatch(change(name)('isResultsListOpen', false))
  dispatch(unsubscribeSearchResult(name))

  return dispatch(saveOffer(name, request, element)(offer))
}

export const saveOffer = (name, request, element) => (offer) => (dispatch, getState) => {
  let state = getState()
  const searchUuid = getSearchUuid(name)(state)
  const searchEnabled = isSearchEnabled(name)(state)

  if (!searchEnabled) {
    return
  }

  dispatch(change(name)('isBlocked', true))

  const query = getQuery(name)(state)
  const roundTrip = get(query, 'round_trip')

  // if is round trip, find target flight mark as selected
  if (roundTrip) {
    const targetFlightId = get(offer, 'options.0.attributes.targetFlightsIds.0', null)
    const offers = getOffers(name)(state)
    const targetFlight = offers.find((offer) => offer.attributes.flightId === targetFlightId)

    dispatch(change(name)(`selected.${SELECT_MODE_THERE}`, targetFlight))
    dispatch(change(name)(`selected.${SELECT_MODE_BACK}`, offer))
  } else {
    dispatch(change(name)(`selected.${SELECT_MODE_THERE}`, offer))
  }

  state = getState()

  let selectedThere = getSelectedThere(name)(state)
  let selectedBack = getSelectedBack(name)(state)

  const selectedOffersUuid = getOffersUuidConcatenation(selectedThere, selectedBack)
  const selectedOptionsUuid = getOptionsUuidConcatenation(selectedThere, selectedBack)
  const requestedAttributes = getPaxesRequestedServices(name)(state)

  return APIClient.chooseFlightOffer(request, {
    search_uuid: searchUuid,
    offer_uuid: selectedOffersUuid,
    option_uuid: selectedOptionsUuid,
    paxes: requestedAttributes,
    ...(!element.draft ? { request_element: element } : {}),
  })
    .then((response) => {
      const data = response.data

      dispatch(setOffers(name)(data))
      dispatch(setPaxes(name)(data.paxes))

      //set amount in form
      state = getState()

      selectedThere = getSelectedThere(name)(state)
      selectedBack = getSelectedBack(name)(state)

      let amount = Number.parseFloat(get(selectedThere, 'options.0.amount.amount', 0))
      let fee = Number.parseFloat(get(selectedThere, 'options.0.reservation_fee.amount', 0))
      if (selectedBack) {
        amount = Number.parseFloat(get(selectedBack, 'options.0.amount.amount', 0))
        fee = Number.parseFloat(get(selectedBack, 'options.0.reservation_fee.amount', 0))
      }
      dispatch(changeForm(name, 'amount', amount + fee))
      dispatch(changeForm(name, 'offer_uuid', get(offer, 'uuid', null)))
      dispatch(
        changeForm(
          name,
          'target_real_departure_at',
          get(response.data.offers, '0.attributes.departureDateTime', null),
        ),
      )
      dispatch(
        changeForm(
          name,
          'target_real_arrival_at',
          get(response.data.offers, '0.attributes.arrivalDateTime', null),
        ),
      )

      if (element.round_trip) {
        dispatch(
          changeForm(
            name,
            'return_real_departure_at',
            get(response.data.offers, '1.attributes.departureDateTime', null),
          ),
        )
        dispatch(
          changeForm(
            name,
            'return_real_arrival_at',
            get(response.data.offers, '1.attributes.arrivalDateTime', null),
          ),
        )
      }

      dispatch(change(name)('isResultsListOpen', false))
      dispatch(change(name)('isBlocked', false))
      dispatch(unsubscribeSearchResult(name))
      dispatch(updateElementSearchUuid(element.key, searchUuid))
    })
    .catch(() => {
      dispatch(change(name)('isBlocked', false))
    })
}

export const setAgreement = (name, request, element) => (value) => (dispatch) => {
  dispatch(change(name)('isAgreement', value))
}

export const checkCompliance = (name, request, element) => () => (dispatch, getState) => {
  const state = getState()

  const query = getQuery(name)(state)
  const aggregatedAmount = getAggregatedAmount(name)(state)
  const selectedBack = getSelectedBack(name)(state)

  const flightFrom = get(query, 'flight_from.additional_data.code')
  const flightTo = get(query, 'flight_to.additional_data.code')
  const roundTrip = get(query, 'round_trip')

  APIClient.checkFlightCompliance({
    flightFrom,
    flightTo,
    roundTrip,
    amount: aggregatedAmount,
    currency: 'PLN',
    slug: request.slug,
  }).then((response) => {
    let mode = SELECT_MODE_THERE
    if (selectedBack) {
      mode = SELECT_MODE_BACK
    }

    dispatch(change(name)('isCheckingCompliance', false))
    dispatch(setCompliance(name)(mode, response.data))
  })
}

export const setPaxRequestedAttribute =
  (name, request, element) => (pax, offer, attribute, value) => (dispatch, getState) => {
    let state = getState()

    const selectedThere = getSelectedThere(name)(state)
    const selectedBack = getSelectedBack(name)(state)
    const areAttributesForBoth = arePaxAvailableAttributesForBoth(name)(pax)(state)

    if (areAttributesForBoth) {
      dispatch(
        change(name)(
          `paxes.${pax}.requestedServices.services.${selectedThere.uuid}.${attribute}`,
          value,
        ),
      )
      dispatch(
        change(name)(
          `paxes.${pax}.requestedServices.services.${selectedBack.uuid}.${attribute}`,
          value,
        ),
      )
    } else {
      dispatch(
        change(name)(`paxes.${pax}.requestedServices.services.${offer.uuid}.${attribute}`, value),
      )
    }

    dispatch(change(name)(`paxes.${pax}.requestedServices.both`, areAttributesForBoth))

    const query = getQuery(name)(state)

    if (query.round_trip) {
      dispatch(saveOffer(name, request, element)(selectedBack))
    } else {
      dispatch(saveOffer(name, request, element)(offer))
    }
  }

export const setSortBy = (name) => (value) => (dispatch) => {
  dispatch(change(name)('isFiltering', true))
  dispatch(change(name)('sortBy', value))
  dispatch(sortFlights(name)())
}

export const clearTimeFilters = (name) => () => (dispatch) => {
  dispatch(
    change(name)('filter', {
      [FILTER_TARGET_DEPARTURE_TIME]: {
        min: FILTER_TARGET_DEPARTURE_TIME_DEFAULT_FROM,
        max: FILTER_TARGET_DEPARTURE_TIME_DEFAULT_TO,
      },
      [FILTER_RETURN_DEPARTURE_TIME]: {
        min: FILTER_RETURN_DEPARTURE_TIME_DEFAULT_FROM,
        max: FILTER_RETURN_DEPARTURE_TIME_DEFAULT_TO,
      },
    }),
  )
}

export const clearPaxes = (name) => () => (dispatch) => {
  dispatch(change(name)('paxes', {}))
}

export const sortFlights = (name) => () => (dispatch, getState) => {
  const state = getState()

  const searchUUID = getSearchUuid(name)(state)
  const sort = getSortBy(name)(state)

  const flightsSortRequest = new FlightsSortRequest(searchUUID, sort)

  const cancellableRequest = CancellableRequest.setRequest(flightsSortRequest)

  return APIClient.getFlights(cancellableRequest).then((response) => {
    const data = response.data

    dispatch(setOffers(name)(data))
  })
}

export const researchFlights = (name) => () => (dispatch, getState) => {
  const state = getState()

  const searchUUID = getSearchUuid(name)(state)
  const sort = getSortBy(name)(state)
  const query = getQuery(name)(state)
  const filters = getFilters(name)(state)

  const flightsFilterRequest = new FlightsFilterRequest(
    searchUUID,
    null,
    filters.target_departure_time,
    filters.return_departure_time,
    query.flight_class,
    query.direct_only,
    query.restrict_to_fba,
    sort,
  )

  const cancellableRequest = CancellableRequest.setRequest(flightsFilterRequest)

  return APIClient.researchFlights(cancellableRequest).then((response) => {
    const data = response.data

    dispatch(setOffers(name)(data))
  })
}

export const fetchReturnFlights = (name) => (targetOffer) => (dispatch, getState) => {
  const state = getState()

  const searchUUID = getSearchUuid(name)(state)
  const sort = getSortBy(name)(state)
  const query = getQuery(name)(state)
  const filters = getFilters(name)(state)

  const flightsFilterRequest = new FlightsFilterRequest(
    searchUUID,
    targetOffer,
    filters.target_departure_time,
    filters.return_departure_time,
    query.flight_class,
    query.direct_only,
    query.restrict_to_fba,
    sort,
  )

  return APIClient.getReturnFlights(flightsFilterRequest).then((response) => {
    const data = response.data

    dispatch(setOffers(name)(data))
  })
}

export const saveSelectedOffer = (name, request, element) => (dispatch, getState) => {
  let state = getState()
  const searchEnabled = isSearchEnabled(name)(state)

  if (!searchEnabled) {
    return
  }

  const searchUuid = getSearchUuid(name)(state)
  let selectedThere = getSelectedThere(name)(state)
  let selectedBack = getSelectedBack(name)(state)

  const selectedOffersUuid = getOffersUuidConcatenation(selectedThere, selectedBack)
  const selectedOptionsUuid = getOptionsUuidConcatenation(selectedThere, selectedBack)
  const requestedAttributes = getPaxesRequestedServices(name)(state)

  dispatch(setInitialized(name)(true))

  return APIClient.chooseFlightOffer(request, {
    search_uuid: searchUuid,
    offer_uuid: selectedOffersUuid,
    option_uuid: selectedOptionsUuid,
    paxes: requestedAttributes,
    ...(!element.draft ? { request_element: element } : {}),
  }).then((response) => {
    const data = response.data

    dispatch(setOffers(name)(data))
    dispatch(setPaxes(name)(data.paxes))
  })
}
