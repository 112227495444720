import { useParams } from 'react-router-dom'
import useMyCardDataSource from '../../../hooks/useMyCardDataSource'
import { IAccountStatement } from '../../../types/account'

export default function useAccountStatements() {
  const params = useParams<{ id: string }>()
  const url = `/mycard/accounts/${params.id}/statements`

  return useMyCardDataSource<IAccountStatement>(url)
}
