import React from 'react'
import Icon from '../ui/IconComponent'
import PropTypes from 'prop-types'
import classnames from 'classnames'

class ToggleableFilters extends React.Component<any, any> {
  state = {
    isOpen: false,
  }

  render() {
    const { children, filtersCount } = this.props
    const renderProps = {
      isOpen: this.state.isOpen,
    }

    const classes = classnames({
      filters__toggler: true,
      'filters__toggler--has-filters': filtersCount > 0,
    })

    return (
      <div className={'filters__main'}>
        {children(renderProps)}

        <button className={classes} type='button' data-count={filtersCount} onClick={this.toggle}>
          <Icon type='filter' className='filters__toggler-icon' />
        </button>
      </div>
    )
  }

  toggle = () => {
    this.setState({ isOpen: !this.state.isOpen })
  }
}

ToggleableFilters.propTypes = {
  children: PropTypes.func.isRequired,
  filtersCount: PropTypes.number.isRequired,
}

export { ToggleableFilters }
export default ToggleableFilters
