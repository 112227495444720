import React, { Component, useState } from 'react'
import classNames from 'classnames'
import Icon from '../ui/IconComponent'
import { Link } from 'react-router-dom'
import '../../third-party/dropdown'
import { trans } from '../../trans'
import Avatar from '../Avatar/Avatar'

function Dropdown({ currentUser, open, handleDropdownHeaderClick }) {
  const [isCompanyOpen, setCompanyOpen] = useState(false)

  if (!currentUser) {
    return null
  }

  const {
    availableIdentities = [],
    company: { name: companyName },
  } = currentUser
  const filteredIdentities = availableIdentities.filter(
    (identity) => currentUser.slug !== identity.slug,
  )

  const DropdownClasses = classNames({
    'main-header__profile-dropdown profile-dropdown': true,
    active: open,
  })

  const companyClasses = classNames({
    'profile-dropdown__list-element': true,
    'profile-dropdown__company-name': true,
    open: isCompanyOpen,
  })

  return (
    <div className={DropdownClasses}>
      <div className='profile-dropdown__header' onClick={handleDropdownHeaderClick}>
        <Avatar className='profile-dropdown__header-img' user={currentUser} />

        <div className='profile-dropdown__header-content'>
          <span
            className='profile-dropdown__header-name'
            data-test={currentUser.email.toLowerCase()}
          >
            {currentUser.first_name} {currentUser.last_name}
          </span>
          {availableIdentities.length > 1 ? (
            <span className='profile-dropdown__header-company'>{currentUser.company.name}</span>
          ) : (
            <span className='profile-dropdown__header-company'>&nbsp;</span>
          )}
        </div>

        <Icon type='arrow' />
      </div>
      <ul className='profile-dropdown__list'>
        <li>
          <Link to={'/user/profile'} className='profile-dropdown__list-element'>
            {trans('profile.profile')}
          </Link>
        </li>

        {filteredIdentities.length > 0 && (
          <li>
            <div className='profile-dropdown__companies'>
              <div className={companyClasses} onClick={() => setCompanyOpen(!isCompanyOpen)}>
                {companyName}
                <Icon type='arrow' />
              </div>

              {isCompanyOpen && (
                <div className='profile-dropdown__company'>
                  {filteredIdentities.map((identity) => (
                    <Link
                      to={`/relogin/${identity.slug}`}
                      className='profile-dropdown__list-element'
                    >
                      {identity.company.name}
                    </Link>
                  ))}
                </div>
              )}
            </div>
          </li>
        )}
        <li>
          <Link to='/logout' className='profile-dropdown__list-element'>
            {trans('profile.logout')}
          </Link>
        </li>
      </ul>
    </div>
  )
}

export default Dropdown
export { Dropdown }
