import PropTypes from 'prop-types'
import React, { PropsWithChildren } from 'react'
import _ from 'lodash'
import { ifDeepDiff } from '../../utils/javascript'
import socketProvider from '../SocketProvider'
import { HttpLink } from '../../types/http-link'
import { connect } from 'react-redux'
import { bindActionCreators, compose } from 'redux'
import { fetchTransactionSuggestions } from '../../store/app/transaction-suggestion'
import {
  fetchCurrencies,
  fetchDocument,
  fetchRequestElements,
  getCurrenciesData,
  getDocumentData,
  getRequestElements,
  setOcrData,
} from '../../store/app/document-account-page'
import { getCurrency as getInstanceCurrency } from '../../store/app/instance'
import { IDocument } from '../../types/document'
import { Loader } from '../../components/ui/LoadingOverlay/Loader'

interface DocumentManagerProps {
  documentId: any
  socket?: any
  fetchRequestElements?: typeof fetchRequestElements
  fetchCurrencies?: typeof fetchCurrencies
  fetchDocument?: (documentId: number) => Promise<IDocument>
  fetchTransactionSuggestions?: typeof fetchTransactionSuggestions
  setOcrData?: typeof setOcrData
}

interface DocumentManagerReduxProps {}

class DocumentManager extends React.Component<
  DocumentManagerProps & DocumentManagerReduxProps,
  any
> {
  state = {
    loaded: false,
  }

  constructor(props) {
    super(props)
  }

  componentDidMount() {
    const {
      socket,
      documentId,

      fetchRequestElements,
      fetchCurrencies,
      fetchDocument,
      fetchTransactionSuggestions,
    } = this.props

    Promise.all([fetchDocument(documentId), fetchCurrencies()])
      .then(([document]: [IDocument, any]) => {
        return Promise.all([
          fetchRequestElements(document.request_slug),
          fetchTransactionSuggestions(document._links.transaction_suggestions),
        ])
      })
      .then(() => {
        this.setState({ loaded: true })
      })

    socket.subscribe(`App.Document.${documentId}`)('.App\\Events\\Ocr\\OcrProcessFinished')(
      this.updateDocumentFromSocket,
    )

    socket.subscribe(`App.Document.${documentId}`)(
      '.Modules\\MyCard\\Priv\\Event\\DocumentWasSync',
    )(this.updateTransactionSuggestions)
  }

  updateTransactionSuggestions = ({
    transaction_suggestions,
  }: {
    transaction_suggestions: HttpLink<void>
  }) => {
    const { fetchTransactionSuggestions } = this.props

    fetchTransactionSuggestions(transaction_suggestions)
  }

  updateDocumentFromSocket = (event) => {
    const { setOcrData } = this.props

    setOcrData(event)
  }

  componentWillUnmount() {
    const { socket, documentId } = this.props

    socket.unsubscribe(`App.Document.${documentId}`)('.App\\Events\\Ocr\\OcrProcessFinished')
    socket.unsubscribe(`App.Document.${documentId}`)(
      '.Modules\\MyCard\\Priv\\Event\\DocumentWasSync',
    )
  }

  render(): React.ReactElement<{ document: IDocument }> {
    const { documentData, currenciesData, children, requestElements, instanceCurrency } = this.props
    const { loaded } = this.state

    const renderProps = {
      document: documentData.data,
      currencies: currenciesData.data,
      travelElements: requestElements,
      defaultCurrency: instanceCurrency,
    }

    if (!loaded) {
      return <Loader />
    }

    return children(renderProps)
  }
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      fetchTransactionSuggestions,
      fetchDocument,
      fetchCurrencies,
      fetchRequestElements,
      setOcrData,
    },
    dispatch,
  )

const mapStateToProps = (state) => ({
  documentData: getDocumentData(state),
  currenciesData: getCurrenciesData(state),
  instanceCurrency: getInstanceCurrency(state),
  requestElements: getRequestElements(state),
})

const withConnect = connect(mapStateToProps, mapDispatchToProps)
const withSocketProvider = socketProvider()

DocumentManager = compose(withConnect, withSocketProvider)(DocumentManager)

export { DocumentManager }
export default { DocumentManager }
