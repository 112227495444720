import React from 'react'
import Icon from '../../ui/IconComponent'
import { Tooltip } from '../../Tooltip'
import trans from '../../../trans'

const Warning = (props) => {
  const message = props.rules.map(rule => (
    <div>{trans(rule.message.name, rule.message.params)}</div>
  ))

  return (
    <Tooltip html={message}>
      <Icon className='is-gradient-warning plane-trip__offer-detail-price-warning' type='warning' />
    </Tooltip>
  )
}

export default Warning
export { Warning }
