import PropTypes from 'prop-types'
import React from 'react'
import APIClient from '../../services/APIClient'
import { TYPE_PLANE } from '../../constants/access-lum-sum'
import uuid from '../../utils/uuid'
import { ifDeepDiff } from '../../utils/javascript'
import memoize from 'memoize-one'
import moment from 'moment'
import { flatten } from 'lodash'

class Manager extends React.Component<any, any> {
  constructor(props) {
    super(props)
    this.state = {
      accessLumpSums: [],
      isLoading: false,
      initial: true,
    }
  }

  updateItems = memoize((currentItems, nextItems) => {
    if (this.state.initial && Object.keys(nextItems).length && !currentItems.length) {
      this.setState({
        accessLumpSums: nextItems.map((accessLumpSum) => {
          return {
            ...accessLumpSum,
            update_count: 0,
          }
        }),
        initial: false,
      })
    }
  })

  componentDidUpdate(prevProps, prevState, prevContext) {
    const {
      request: { accessLumpSums: nextItems },
    } = this.props
    const { accessLumpSums: currentItems } = this.state
    this.updateItems(currentItems, nextItems)
  }

  setAccessLumpSumPair(from, to) {
    const { request } = this.props

    this.setState({ isLoading: true })

    if (from['isNew'] && to['isNew']) {
      return new Promise((resolve, reject) => {
        APIClient.addRequestAccessLumpSump(request, from, to)
          .then(({ data: [savedTo, savedFrom] }) => {
            const { accessLumpSums } = this.state

            const newAccessLumpSums = accessLumpSums.map((accessLumpSum) => {
              if (accessLumpSum.isNew === to.isNew) return savedTo
              if (accessLumpSum.isNew === from.isNew) return savedFrom

              return accessLumpSum
            })

            let sortedAccessLumpSums = _.chunk(newAccessLumpSums, 2).sort((a, b) => {
              const first = moment(a[0].date)
              const second = moment(b[0].date)

              return first.isBefore(second) ? -1 : first.isAfter(second) ? 1 : 0
            })

            sortedAccessLumpSums = flatten(sortedAccessLumpSums)

            this.setState({ accessLumpSums: sortedAccessLumpSums, isLoading: false }, () =>
              resolve(),
            )
          })
          .catch((response) => {
            this.setState({ isLoading: false })
            reject(response['alerts'])
          })
      })
    }

    return new Promise((resolve, reject) => {
      APIClient.updateRequestAccessLumpSump(request, from, to)
        .then(({ data }) => {
          const { accessLumpSums } = this.state

          data.forEach((accessLumpSum) => {
            const index = accessLumpSums.findIndex(({ id }) => accessLumpSum['id'] === id)
            const update_count = accessLumpSums[index]['update_count'] + 1
            accessLumpSums[index] = {
              ...accessLumpSum,
              update_count: update_count,
            }
          })

          let sortedAccessLumpSums = _.chunk(accessLumpSums, 2).sort((a, b) => {
            const first = moment(a[0].date)
            const second = moment(b[0].date)

            return first.isBefore(second) ? -1 : first.isAfter(second) ? 1 : 0
          })

          sortedAccessLumpSums = flatten(sortedAccessLumpSums)

          this.setState({ accessLumpSums: sortedAccessLumpSums, isLoading: false }, () => resolve())
        })
        .catch((response) => {
          this.setState({ isLoading: false })
          reject(response['alerts'])
        })
    })
  }

  addAccessLumpSum() {
    return new Promise((resolve) => {
      let { accessLumpSums } = this.state

      const newModel = {
        type: TYPE_PLANE,
        location: {},
        id: null,
        date: '',
        disable: true,
        update_count: 0,
      }

      accessLumpSums = accessLumpSums.concat([
        {
          ...newModel,
          destination: 'to',
          isNew: uuid(),
        },
        {
          ...newModel,
          destination: 'from',
          isNew: uuid(),
        },
      ])

      this.setState({ accessLumpSums }, () => resolve())
    })
  }

  setStoreForAccessLumpSumsPair(from, to, indexes = []) {
    return new Promise((resolve) => {
      const { accessLumpSums } = this.state
      let change = false

      const newAccessLumpSums = accessLumpSums.map((accessLumpSum, i) => {
        if (i === indexes[0]) {
          if (from && ifDeepDiff(accessLumpSum, from)) {
            change = true
            return {
              ...from,
              update_count: from['update_count'] + 1,
            }
          }
        }
        if (i === indexes[1]) {
          if (to && ifDeepDiff(accessLumpSum, to)) {
            change = true
            return {
              ...to,
              update_count: to['update_count'] + 1,
            }
          }
        }
        return accessLumpSum
      })

      if (change) {
        this.setState({ accessLumpSums: newAccessLumpSums }, () => resolve())
      }
    })
  }

  deleteAccessLumpSums(accessLumpSums, indexes = []) {
    const { request } = this.props

    if (accessLumpSums[0]['isNew']) {
      // wtf
      return new Promise((resolve) => {
        const { accessLumpSums } = this.state
        const newAccessLumpSums = accessLumpSums.filter((accessLumpSum, i) => {
          return indexes.indexOf(i) === -1
        })
        this.setState({ accessLumpSums: newAccessLumpSums }, () => resolve())
      })
    }

    this.setState({ isLoading: true })

    return new Promise((resolve) => {
      const ids = accessLumpSums.map(({ id }) => id)

      APIClient.deleteRequestAccessLumpSump(request, ids).then(() => {
        const { accessLumpSums: stateAccessLumpSums } = this.state
        const newAccessLumpSums = stateAccessLumpSums.filter(({ id }) => {
          return ids.indexOf(id) === -1
        })
        this.setState({ accessLumpSums: newAccessLumpSums, isLoading: false }, () => resolve())
      })
    })
  }

  render() {
    const { children, request } = this.props
    const { accessLumpSums, isLoading } = this.state

    const renderProps = {
      request,
      accessLumpSums,
      isLoading,
      setAccessLumpSumPair: this.setAccessLumpSumPair.bind(this),
      deleteAccessLumpSums: this.deleteAccessLumpSums.bind(this),
      addAccessLumpSum: this.addAccessLumpSum.bind(this),
      setStoreForAccessLumpSumsPair: this.setStoreForAccessLumpSumsPair.bind(this),
    }

    return children(renderProps)
  }
}

Manager.propTypes = {
  request: PropTypes.object.isRequired,
}

export { Manager }
