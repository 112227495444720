import React from 'react'
import { Link } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import { BreadCrumbs } from '../../containers'
import { Section } from '../ui/Section'
import { RouteManager } from '../../containers/RouteManager'
import { SettlementRequestsListFilters } from '../SettlementRequestsListFilters/SettlementRequestsListFilters'
import { SettlementRequestsManager } from '../../containers/SettlementRequestsManager'
import { SettlementRequestsListTable } from '../SettlementRequestsListTable'
import trans from '../../trans'
import { getRouteByName } from '../../routes'
import { connect } from 'react-redux'

const SettlementRequestsListPageBase = () => {
  return (
    <div>
      <BreadCrumbs>
        <Link to={getRouteByName('main', 'dashboard')}>{trans('global.dashboard')}</Link>
        <Link to={getRouteByName('main', 'settlementRequestsList')}>
          {trans('request.settlements')}
        </Link>
      </BreadCrumbs>

      <Section noBorder>
        <Helmet title={trans('request.settlement-request-list')} />

        {/* Hide button due to MIN-2497 */}
        {/*<Ability ability={["claimsPageReport"]} abilities={currentUser.abilities}>*/}
        {/*    <SectionHeader*/}
        {/*        caption={trans('request.settlements')}>*/}
        {/*        <a href="#" onClick={() => APIClient.getRequestsReport()} className="btn--primary btn--xs btn is-allign-end">{trans('global.download-report')}</a>*/}
        {/*    </SectionHeader>*/}
        {/*</Ability>*/}

        <RouteManager>
          {({ getRouteByName, push }) => (
            <SettlementRequestsManager>
              {({
                requests,
                requestsFilters,
                changeRequestsFilter,
                requestsSorter,
                changeRequestsSorter,
                areRequestsLoaded,
                areRequestsLoading,
                currentUser,
                MPKs,
                companies,
                selectDeselectOne,
                selectDeselectMany,
                isItemSelected,
                assignToMe,
                selectedRequests,
                requestsPagination,
                changeRequestsPage,
                tableConfig,
              }) => (
                <div>
                  <SettlementRequestsListFilters
                    filters={requestsFilters}
                    onChangeFilter={changeRequestsFilter}
                    currentUser={currentUser}
                    MPKs={MPKs}
                    companies={companies}
                  />
                  <SettlementRequestsListTable
                    requests={requests}
                    isLoading={!areRequestsLoaded || areRequestsLoading}
                    getRouteByName={getRouteByName}
                    currentUser={currentUser}
                    selectDeselectMany={selectDeselectMany}
                    selectDeselectOne={selectDeselectOne}
                    isItemSelected={isItemSelected}
                    assignToMe={assignToMe}
                    selectedRequests={selectedRequests}
                    pagination={requestsPagination}
                    changePage={changeRequestsPage}
                    push={push}
                    sorter={requestsSorter}
                    onChangeSorter={changeRequestsSorter}
                    tableConfig={tableConfig}
                  />
                </div>
              )}
            </SettlementRequestsManager>
          )}
        </RouteManager>
      </Section>
    </div>
  )
}

const mapStateToProps = (state) => ({
  currentUser: state.get('global').get('currentUser'),
})

export const SettlementRequestsListPage = connect(mapStateToProps)(SettlementRequestsListPageBase)
