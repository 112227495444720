import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import {
  action,
  change,
  getComment,
  getCompletionDate,
  getDecision,
  reset,
} from '../../store/app/request-action'
import { REQUEST_DECISIONS } from '../../store/app/request-action/index'

class RequestActionManager extends React.Component<any, any> {
  constructor(props) {
    super(props)

    this.onChangeHandler = this.onChangeHandler.bind(this)
    this.onActionHandler = this.onActionHandler.bind(this)
  }

  componentDidMount() {
    const { reset } = this.props
    reset()

    if (location.search.substr(1) === 'reject') {
      this.onChangeHandler('decision', REQUEST_DECISIONS.REJECT)
    }

    if (location.search.substr(1) === 'return') {
      this.onChangeHandler('decision', REQUEST_DECISIONS.SETTLEMENT_RETURN_TO_IMPROVEMENT)
    }
  }

  onChangeHandler(field, value) {
    const { change } = this.props
    change(field, value)
  }

  onActionHandler() {
    const { action, request, comment, decision, completion_date } = this.props

    return action(request, { comment, decision, completion_date })
  }

  render() {
    const { children, comment, decision, completion_date } = this.props

    const renderProps = {
      comment,
      decision,
      completion_date,
      onChange: this.onChangeHandler,
      onAction: this.onActionHandler,
    }

    return children(renderProps)
  }
}

RequestActionManager.propTypes = {
  children: PropTypes.func.isRequired,
  request: PropTypes.object.isRequired,
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      reset,
      change,
      action,
    },
    dispatch,
  )
}

const mapStateToProps = (state) => ({
  comment: getComment(state),
  decision: getDecision(state),
  completion_date: getCompletionDate(state),
})

RequestActionManager = connect(mapStateToProps, mapDispatchToProps)(RequestActionManager)

export { RequestActionManager }
export default RequestActionManager
