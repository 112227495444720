import React from 'react'
import { Link } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import { BreadCrumbs } from '../../containers'
import { Section } from '../ui/Section'
import { SectionHeader } from '../ui/SectionHeader'
import { getRouteByName } from '../../routes'
import trans from '../../trans'
import InstallmentsList from './InstallmentsList'
import InstallmentsManager from '../../store/app/installments/InstallmentsManager'
import { InstallmentsListFilters } from './InstallmentsListFilters'
import Button from '../ui/ButtonComponent'

class InstallmentsListPage extends React.Component<any, any> {
  render() {
    return (
      <div>
        <BreadCrumbs>
          <Link to={getRouteByName('main', 'dashboard')}>{trans('global.dashboard')}</Link>
          <Link to={getRouteByName('main', 'expenseRequestsList')}>
            {trans('global.installments')}
          </Link>
        </BreadCrumbs>

        <Section noBorder>
          <Helmet title={trans('global.installments-list')} />

          <SectionHeader caption={trans('global.installments-list')}>
            <Button hidden />
          </SectionHeader>

          <InstallmentsListFilters
            filters={this.props.filters}
            onChangeFilter={this.props.changeFilter}
            companies={this.props.companies}
          />

          <InstallmentsList
            installments={this.props.installments}
            markAsPaid={this.props.markAsPaid}
            markAsAccounted={this.props.markAsAccounted}
            isLoading={this.props.isLoading}
            pagination={this.props.pagination}
            changeDate={this.props.changeDate}
            changeAccountingDate={this.props.changeAccountingDate}
            changeExchangeRate={this.props.changeExchangeRate}
            changeAmount={this.props.changeAmount}
            updateItem={this.props.updateItem}
            changePage={this.props.changePage}
            changeErpId={this.props.changeErpId}
            sorter={this.props.sorter}
            onChangeSorter={this.props.changeSorter}
          />
        </Section>
      </div>
    )
  }
}

InstallmentsListPage = InstallmentsManager(true)(InstallmentsListPage)

export { InstallmentsListPage }
export default InstallmentsListPage
