import { useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { getMyCardTenantId } from '../store/app/user-profile/userProfileSlice'
import { MyCardResponse, Paginator } from '../types/response'
import APIClient from '../services/APIClient'
import queryString from 'query-string'

export default function useMyCardDataSource<T>(url: string) {
  const [data, setData] = useState<T[]>([])
  const [paginator, setPaginator] = useState<Partial<Paginator>>({})
  const [loading, setLoading] = useState<boolean>(true)
  const tenant = useSelector(getMyCardTenantId)

  const setPage = useCallback(
    (page: number) => {
      if (paginator.page !== page) {
        loadItems(page)
      }
    },
    [paginator.page, loading],
  )

  const loadItems = (page: number = paginator.page) => {
    setLoading(true)

    const headers = {
      Tenant: tenant,
      Pagination: queryString.stringify({
        page,
        per_page: 20,
      }),
    }

    return APIClient.makeRequestFromHttpLink<MyCardResponse<T[]>, []>({
      body: [],
      headers,
      href: url,
      method: 'GET',
    })
      .then((response) => {
        setData(response.data)
        setPaginator(response.paginator)
        setLoading(false)
      })
      .catch(() => {
        setLoading(false)
        setPaginator({ page: 1 })
      })
  }

  useEffect(() => {
    loadItems(1)
  }, [])

  return { data, paginator, loading, setPage, load: loadItems }
}
