import React from 'react'
import { connect } from 'formik'
import { FormGroup, Input } from '../../../ui'
import Error from '../../../ui/Input/Error'

export default connect((props) => {
  const { label, name, type, disabled = false, ...rest } = props

  const input = (
    <Input
      name={name}
      type={type}
      onChange={props.formik.handleChange}
      onBlur={props.formik.handleBlur}
      value={props.formik.values[name]}
      disabled={disabled}
      {...rest}
    />
  )

  if (type === 'hidden') {
    return input
  }

  return (
    <FormGroup label={label} labeltop>
      {input}
      <Error errors={props.formik.errors[name]} />
    </FormGroup>
  )
})
