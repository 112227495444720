import moment from 'moment'
import { config } from '../config'
import _ from 'lodash'
import queryString from 'query-string'

export const parseFilterDates = (filters) => {
  const parsed = { ...filters }

  Object.keys(parsed).forEach((key) => {
    const value = parsed[key]
    if (value instanceof moment) {
      parsed[key] = moment(value).format(config.apiDateTimeFormat)
    }
  })

  return parsed
}

export const parseQueryToFilters = (queryFilters) => {
  const normalize = (str) => {
    if (typeof str !== 'string') {
      return str
    }

    if (!isNaN(str)) {
      return Number(str)
    }

    if (!str.includes(';')) {
      return str
    }

    const [value, label] = str.split(';')

    if (Array.isArray(value)) {
      return {
        value: value.map((value) => (isNaN(value) ? value : Number(value))),
        label,
      }
    }

    return {
      value: isNaN(value) ? value : Number(value),
      label,
    }
  }

  return Object.keys(queryFilters)
    .map((key) => {
      const value = queryFilters[key]

      if (Array.isArray(value)) {
        return { [key]: value.map((v) => normalize(v)) }
      }

      return { [key]: normalize(value) }
    })
    .reduce((a, b) => ({ ...a, ...b }), {})
}

export const parseFiltersForAPI = (filters) => {
  const parsed = { ...filters }

  Object.keys(parsed).forEach((key) => {
    const value = parsed[key]

    if (
      value &&
      Array.isArray(value) &&
      value.filter((item) => item instanceof Object).length === 0
    ) {
      parsed[key] = value.join(',')
    } else if (
      value &&
      Array.isArray(value) &&
      value instanceof Object &&
      value.filter((item) => item instanceof Object).length > 0
    ) {
      let values = value.map((item) => {
        return item.value
      })

      parsed[key] = values.join(',')
    }
  })

  return parsed
}

export const createFiltersQuery = (filters) => {
  Object.keys(filters)
    .filter((key) => filters[key] !== null)
    .forEach((key) => {
      const item = filters[key]
      const items = Array.isArray(item) ? item : [item]

      filters[key] = items.map((item) =>
        typeof item === 'object' && 'value' in item ? `${item.value};${item.label}` : item,
      )
    })

  if (_.isEmpty(filters)) {
    return ''
  }

  return queryString.stringify(filters, { arrayFormat: 'comma' })
}

export const createSorterQuery = (sorter) => {
  return queryString.stringify(sorter, { arrayFormat: 'comma' })
}
