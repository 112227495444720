import React from 'react'
import { connect } from 'react-redux'
import { fromJS } from 'immutable'
import { EdgePointForm as Form } from '../../components/BorderCrossings/BorderCrossings/EdgePointForm'
import { createAutoSaveForm, getFormValues } from '../../utils/forms'
import { save as saveEdgePoint } from '../../store/app/border-crossings/index'

export const save = (name, value, dispatch, props) => {
  const { crossing, request } = props
  return dispatch(saveEdgePoint(request)(crossing, { [name]: value }))
}

const AutoSaveForm = createAutoSaveForm(Form, {
  save,
  timeout: 0,
})

const mapStateToProps = (state, props) => {
  const { crossing, form } = props
  return {
    initialValues: fromJS({
      location: crossing.location,
      date: crossing.date,
      target: crossing.target,
    }),
    form,
    data: getFormValues(form, state),
    crossing,
  }
}

const EdgePointForm = connect(mapStateToProps)(AutoSaveForm)

export { EdgePointForm }
export default EdgePointForm
