import PropTypes from 'prop-types'
import React, { PropsWithChildren } from 'react'
import classnames from 'classnames'

interface SectionHeaderProps extends PropsWithChildren {
  caption: string
  headerTag?: string
  noMargin?: boolean
  className?: string
}

export const SectionHeader = ({
  children,
  caption,
  headerTag: HeaderTag,
  noMargin,
  className,
}: SectionHeaderProps) => {
  const classes = classnames({
    section__header: true,
    'section__header--no-margin': noMargin,
    [className]: className,
  })

  const headerClasses = classnames({
    [HeaderTag]: true,
    'section__header-title': true,
  })

  const isHeaderTag = ['h1', 'h2', 'h3', 'h4'].includes(HeaderTag)
  const header = isHeaderTag ? <HeaderTag className={headerClasses}>{caption}</HeaderTag> : null

  return (
    <header className={classes}>
      {header}
      {children}
    </header>
  )
}

SectionHeader.propTypes = {
  caption: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  headerTag: PropTypes.oneOf(['h1', 'h2', 'h3', 'h4']),
  noMargin: PropTypes.bool,
  className: PropTypes.string,
}

SectionHeader.defaultProps = {
  headerTag: 'h2',
}
