import { createSelector, createSlice } from '@reduxjs/toolkit'
import APIClient from '../../../services/APIClient'
import trans from '../../../trans'

export const COMPANY_MOUNT_POINT = 'companySlice'

const getInitialState = () => ({
  items: [],
  error: null,
  isLoading: false,
})

/**
 * In @reduxjs/toolkit we can mutate state in reducers,
 * because behind the scene it uses ImmerJS for applying them as non-mutate
 *
 * https://github.com/immerjs/immer
 */
const companiesSlice = createSlice({
  name: COMPANY_MOUNT_POINT,
  initialState: getInitialState(),
  reducers: {
    resetCompanies(state) {
      state = getInitialState()
    },
    setCompanies(state, action) {
      const { data } = action.payload

      state.items = data
    },
    startLoading(state) {
      state.isLoading = true
      state.error = null
    },
    setLoadingSuccess(state) {
      state.isLoading = false
      state.error = null
    },
    setLoadingFailed(state, action) {
      state.isLoading = false
      state.error = action.payload
    },
  },
})

export const { resetCompanies, setCompanies, startLoading, setLoadingSuccess, setLoadingFailed } =
  companiesSlice.actions

export default companiesSlice.reducer

// selectors
const getState = (state) => state.get(COMPANY_MOUNT_POINT)

export const getCompanies = (state) => getState(state).items
export const isLoading = (state) => getState(state).isLoading

// memoized selectors, improve performance
export const getCompaniesForSelectOptionsSelector = createSelector([getCompanies], (items) =>
  items.map((item) => ({
    value: item.id,
    label: item.name,
  })),
)
export const getCompaniesWithAllForSelectOptionsSelector = createSelector(
  [getCompanies],
  (items) => [
    {
      label: trans('global.all'),
      value: null,
    },
    ...items.map((item) => ({
      value: item.id,
      label: item.name,
    })),
  ],
)

// memoized selectors, improve performance
export const getCompaniesForSelectOptionsWithCodeSelector = createSelector(
  [getCompanies],
  (items) =>
    items.map((item) => ({
      value: item.id,
      label: item.code,
    })),
)

// action thunks
export const fetchCompanies = () => async (dispatch) => {
  dispatch(startLoading())

  try {
    const { data } = await APIClient.getCompanies()
    dispatch(setCompanies({ data }))
    dispatch(setLoadingSuccess())
  } catch (err) {
    dispatch(setLoadingFailed(err))
  }
}

export const fetchCompaniesIfNeeded = () => async (dispatch, state) => {
  const { items, isLoading } = getState(state())

  if (items.length || isLoading) {
    return
  }

  return dispatch(fetchCompanies())
}
