import { useEffect, useMemo, useState } from 'react'
import { GridApi, GridOptions, ValueFormatterParams } from 'ag-grid-community'
import trans from '../../../trans'
import defaultFormatter from '../../AgGrid/ValueFormatter/defaultFormatter'
import { IAccountStatement } from '../../../types/account'
import ibanFormatter from '../../AgGrid/ValueFormatter/ibanFormatter'
import StatementStatusRenderer from '../../AgGrid/CellRenderer/StatementStatusRenderer'

export function useGridOptions() {
  const [api, setApi] = useState<GridApi>(null)
  const [gridOptions, setGridOptions] = useState<GridOptions<IAccountStatement>>({
    domLayout: 'autoHeight',
    defaultColDef: {
      suppressSizeToFit: false,
      suppressMenu: true,
      suppressMovable: true,
    },
    enableBrowserTooltips: true,
    onGridReady(event) {
      setApi(event.api)
    },
    rowSelection: 'multiple',
    suppressRowClickSelection: true,
    columnDefs: [
      {
        headerName: '',
        field: 'id',
        headerCheckboxSelection: true,
        checkboxSelection: true,
        valueFormatter: () => '',
        minWidth: 50,
        maxWidth: 50,
      },
      {
        headerName: trans('statements.statements_th_from'),
        field: 'from',
        valueFormatter: defaultFormatter,
        minWidth: 120,
        maxWidth: 120,
      },
      {
        headerName: trans('statements.statements_th_to'),
        field: 'to',
        valueFormatter: defaultFormatter,
        minWidth: 120,
        maxWidth: 120,
      },
      {
        headerName: trans('statements.statements_th_number'),
        field: 'number',
        valueFormatter: defaultFormatter,
        minWidth: 120,
        maxWidth: 120,
      },
      {
        headerName: trans('statements.statements_th_iban'),
        field: 'iban',
        valueFormatter: ibanFormatter,
        minWidth: 250,
      },
      {
        headerName: trans('statements.statements_th_status'),
        field: 'closed',
        cellRenderer: StatementStatusRenderer,
        minWidth: 150,
        maxWidth: 150,
      },
    ],
  })

  const onResize = useMemo(() => {
    return () => {
      if (api) {
        api.sizeColumnsToFit()
      }
    }
  }, [api])

  useEffect(() => {
    onResize()

    window.addEventListener('resize', onResize)

    return () => {
      window.removeEventListener('resize', onResize)
    }
  }, [api])

  return gridOptions
}
