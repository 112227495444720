import React, { Component, RefObject } from 'react'
import PropTypes from 'prop-types'
import Icon from '../ui/IconComponent'
import { DatePickerField } from '../ui/Form'
import { config } from '../../config'
import moment from 'moment'
import isTouchDevice from '../../utils/isTouchDevice'
import _, { debounce } from 'lodash'

const DATE_FORMAT = 'DD.MM.YYYY'

interface EditableDateProps {
  date: string | Date
  canEdit?: boolean
  onChange?: (date: string | Date) => void
  placeholder?: string
  triggerOnBlur?: boolean
}

class EditableDate extends Component<EditableDateProps, any> {
  ref: RefObject<any>
  debouncedSubmitChange: (date) => void

  constructor(props) {
    super(props)

    this.ref = React.createRef()

    this.state = {
      isEditing: false,
      error: null,
      date: EditableDate.prepareDate(this.props.date),
    }

    this.debouncedSubmitChange = debounce(this.submitChange, 2000)
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.date !== prevProps.date &&
      !(this.props.date === null && prevProps.date === null)
    ) {
      this.setState({ date: EditableDate.prepareDate(this.props.date) })
    }
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside)
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside)
  }

  handleClickOutside = (event) => {
    if (this.state.isEditing && this.ref && !this.ref.current.contains(event.target)) {
      this.setState({ isEditing: false })
    }
  }

  static prepareDate = (date) => {
    return moment(date).isValid() ? moment(date).format(DATE_FORMAT) : '---'
  }

  setEditing = (e) => {
    e.preventDefault()

    if (this.props.canEdit) {
      this.setState({ isEditing: true })
    }
  }

  renderDate = () => {
    const { canEdit } = this.props

    return (
      <div
        className='table-accordion__installment-date'
        style={{ justifyContent: 'flex-end' }}
        onClick={this.setEditing}
      >
        <span>{this.state.date !== null ? this.state.date : '---'}</span>
        {canEdit && (
          <span className='timeline-icon' onClick={this.setEditing}>
            <Icon type='edit' lg gradient />
          </span>
        )}
      </div>
    )
  }

  onChangeDate = (newDate) => {
    if (newDate) {
      newDate = newDate.format(DATE_FORMAT)
    }

    this.setState({ date: newDate, error: null })

    if (!this.props.triggerOnBlur) {
      this.debouncedSubmitChange(newDate)
    }
  }

  submitChange = (date) => {
    let convertedDate = null
    if (date) {
      convertedDate = moment(date, DATE_FORMAT).format(config.apiDateFormat)
    }

    this.props
      .onChange(convertedDate)
      .then(() => this.setState({ isEditing: false }))
      .catch((error) => {
        this.setState({ date: null })
      })
  }

  handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      this.submitChange(this.state.date)
    }
  }

  renderInput = () => {
    return (
      <div
        className='table-accordion__row-edit'
        onClick={(e) => {
          e.preventDefault()
        }}
        ref={this.ref}
      >
        <DatePickerField
          placeholder={this.props.placeholder}
          onChange={this.onChangeDate}
          value={moment(this.state.date, DATE_FORMAT)}
          onBlur={(value) => {
            this.onChangeDate(value)
            this.submitChange(this.state.date)
          }}
          onKeyDown={this.handleKeyDown}
        />

        {isTouchDevice() && (
          <span className='timeline-icon' onClick={this.submitChange}>
            <Icon type='checked' lg gradient />
          </span>
        )}

        {!!this.state.error && <span>{this.state.error}</span>}
      </div>
    )
  }

  render() {
    return this.state.isEditing ? this.renderInput() : this.renderDate()
  }
}

EditableDate.defaultProps = {
  triggerOnBlur: false,
}

EditableDate.propTypes = {
  date: PropTypes.string,
  canEdit: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string.isRequired,
  triggerOnBlur: PropTypes.bool,
}

export default EditableDate
export { EditableDate }
