import PropTypes from 'prop-types'
import React from 'react'
import _ from 'lodash'
import classnames from 'classnames'
import Select, { createFilter } from 'react-select'
import { components } from '../../../utils/react-select'
import { ifDeepDiff } from '../../../utils/javascript'
import { Tooltip } from '../../Tooltip'

export class SelectField extends React.Component<any, any> {
  constructor(props) {
    super(props)
    this.divRef = null
    this.state = {
      isOpen: props.isOpen || false,
    }
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside)
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside)
  }

  handleClickOutside = (event) => {
    if (this.state.isOpen && this.divRef && !this.divRef.contains(event.target)) {
      this.close()
    }
  }

  open() {
    if (this.props.onOpen) this.props.onOpen()
    this.setState({ isOpen: true })
  }

  close() {
    if (this.props.onClose) this.props.onClose()
    this.setState({ isOpen: false })
  }

  findValue(options) {
    const { value } = this.props

    if (this.props.isMulti) {
      return options.filter(({ value: _value }) => {
        if (!Array.isArray(value)) {
          return false
        }

        const found = value.find((singleInputValue) => {
          return singleInputValue === _value
        })

        return found !== null && found !== undefined
      })
    }

    return options.find(({ value: _value }) => {
      if (_.isObject(value)) {
        return !ifDeepDiff(value, _value)
      } else {
        return _value === value
      }
    })
  }

  findGroupedValue() {
    let selectedOption = null

    this.props.options.some((group) => {
      selectedOption = this.findValue(group.options)
      if (selectedOption) return true
    })

    return selectedOption
  }

  toggleOpen(e) {
    const { isOpen } = this.state
    const { disabled } = this.props

    e.preventDefault()

    if (disabled) {
      return
    }

    if (isOpen) {
      this.close()
    } else {
      this.open()
    }
  }

  onTouchStart(e) {
    const { disabled } = this.props

    if (disabled) {
      return
    }

    if (e.target.classList.contains('react-select__dropdown-indicator')) {
      this.toggleOpen(e)
    }
  }

  emitChange(item) {
    const { onChange } = this.props

    if (_.isFunction(onChange)) {
      if (this.props.isMulti) {
        onChange(
          item.map((item) => {
            return item.value
          }),
        )
      } else {
        onChange(item ? item.value : null)
      }
    }
  }

  onChange = (item, event) => {
    if (event.action === 'clear' && this.props.isMulti) {
      this.emitChange(event.removedValues.filter((option) => option.fixed))
    } else if (['remove-value', 'pop-value'].includes(event.action)) {
      if (!event.removedValue.fixed) {
        this.emitChange(item)
      }
    } else {
      this.emitChange(item)
    }

    this.close()
  }

  render() {
    const {
      name,
      className,
      options,
      value,
      placeholder,
      disabled,
      onFocus,
      noBorder,
      emptyValue,
      clearable,
      isSearchable = true,
      labeltop,
      onBlur,
      onChange,
      nested,
      maxMenuHeight,
      optionsWithTooltip,
      ...rest
    } = this.props
    const classes = classnames({
      'react-select': true,
      'react-select--no-border': noBorder,
      [className]: true,
    })
    const { isOpen } = this.state
    const inputValue = nested ? this.findGroupedValue() : this.findValue(options)
    const selectedValue = inputValue === undefined || inputValue === null ? null : inputValue
    const optionsWithEmpty = emptyValue ? [emptyValue, ...options] : options

    if (!options.length && emptyValue) {
      return <div className='react-select__container--outer'>{emptyValue.label}</div>
    }

    const Option = (props) => {
      return (
        <Tooltip html={<p>{props.label}</p>}>
          <components.Option {...props} />
        </Tooltip>
      )
    }

    const componentsExtended = { ...components, Option }

    return (
      <div className='react-select__container--outer' ref={(ref) => (this.divRef = ref)}>
        <Select
          name={name}
          options={optionsWithEmpty}
          className={classes}
          value={selectedValue}
          isOptionDisabled={(option) => option.disabled}
          onChange={this.onChange}
          placeholder={emptyValue ? emptyValue.label : placeholder}
          isDisabled={disabled}
          onFocus={onFocus}
          isClearable={clearable && !disabled}
          isSearchable={isSearchable}
          filterOption={createFilter({
            matchFrom: 'any',
            stringify: (option) => `${option.label}`,
          })}
          components={optionsWithTooltip ? componentsExtended : components}
          onBlur={onBlur}
          maxMenuHeight={maxMenuHeight ? maxMenuHeight : 300}
          {...rest}
        />
      </div>
    )
  }
}

SelectField.propTypes = {
  onChange: PropTypes.func,
  name: PropTypes.string,
  className: PropTypes.string,
  value: PropTypes.any,
  emptyValue: PropTypes.object,
  placeholder: PropTypes.string,
  options: PropTypes.array,
  disabled: PropTypes.bool,
  noBorder: PropTypes.bool,
  nested: PropTypes.bool,
}

SelectField.defaultProps = {}
