import PropTypes from 'prop-types'
import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { fromJS } from 'immutable'
import { bindActionCreators } from 'redux'
import { createAutoSaveForm, getFormValues } from '../../utils/forms'
import APIClient from '../../services/APIClient'
import { AccountMileageAllowanceForm as FormComponent } from '../../components/AccountMileageAllowanceForm'
import { getActiveAccountingAccountSelectOptions } from '../../store/app/accounting-account'
import { getActiveProjectsSelectOptions } from '../../store/app/projects'
import { setCache, getCache } from '../../store/app/account-dimensions'
import {
  generateAccountDimensionFormValues,
  isAccountDimensionField,
  submitAccountDimension,
} from '../../store/app/account-dimensions/form'
import { isFeatureEnabled } from '../../store/app/instance'
import { FEATURE_DEDUCTIBILITY_ENABLED } from '../../constants/features'
import {
  createMileageAllowance,
  isMileageAllowanceDeleteInProgress,
  isTravelExpenseDeleteInProgress,
  removeAccountingMileageAllowance,
  saveAccountingMileageAllowance,
  updateRequestMileageAllowance,
} from '../../store/app/request-mileage-allowance'

const AccountMileageAllowanceFormPure = createAutoSaveForm(FormComponent, {
  save: (name, value, dispatch, props) => {
    const {
      request,
      accountDimensionItems,
      accountDimensions,
      setCache,
      saveAccountingMileageAllowance,
      mileageAllowance,
    } = props

    if (isAccountDimensionField(name)) {
      return submitAccountDimension({
        name,
        value,
        setCache,
        dimensions: accountDimensions,
        items: accountDimensionItems,
        updateMethod: (dim_id) =>
          APIClient.updateMileageAllowanceAccountDimension(
            request.slug,
            mileageAllowance.id,
            dim_id,
            value.id,
          ),
        deleteMethod: (dim_id) =>
          APIClient.deleteMileageAllowanceAccountDimension(
            request.slug,
            mileageAllowance.id,
            dim_id,
          ),
      })
    }

    if (name === 'mpk') {
      name = 'mpk_id'
      value = value.id
    }

    return saveAccountingMileageAllowance(request['slug'], mileageAllowance.id, { [name]: value })
  },
  componentDidMount: (props) => {
    const {
      mileageAllowance: { accountDimensionItems },
      setCache,
    } = props

    setCache(accountDimensionItems)
  },
})

const getFormName = (id) => {
  return `account-mileage-allowance-${id}`
}

const mapStateToProps = (state, props) => {
  const {
    accountDimensions = [],
    request,
    mileageAllowance,
    mileageAllowance: { accountDimensionItems = [] },
  } = props

  const FORM_NAME = getFormName(mileageAllowance.id)
  const currentAccountingAccount = mileageAllowance && mileageAllowance['accounting_account']
  const accountingAccounts = getActiveAccountingAccountSelectOptions(
    state,
    currentAccountingAccount || [],
  )
  const mileageMpk = (mileageAllowance && mileageAllowance['mpk']) || {}
  const mileageProject = (mileageAllowance && mileageAllowance['project']) || {}
  const mileageDeductibility = (mileageAllowance && mileageAllowance['deductibility']) || {}
  const projects = getActiveProjectsSelectOptions(state, [request.project_id, mileageProject.id])
  const isDeletingInProgress = isMileageAllowanceDeleteInProgress(state, mileageAllowance)

  return {
    initialValues: fromJS({
      accounting_account_id: mileageAllowance && mileageAllowance.accounting_account_id,
      mpk: mileageMpk,
      cost_of_earning: mileageAllowance && mileageAllowance.cost_of_earning,
      amount: mileageAllowance && mileageAllowance.amount,
      project_id: mileageAllowance && mileageAllowance.project_id,
      deductibility: mileageAllowance && mileageAllowance.deductibility,
      ...generateAccountDimensionFormValues(accountDimensions, accountDimensionItems),
    }),
    form: FORM_NAME,
    data: getFormValues(FORM_NAME, state),
    projects,
    accountingAccounts,
    accountDimensions,
    mileageAllowance,
    mileageDeductibility,
    isDeletingInProgress,
    accountDimensionItems: getCache(state)(FORM_NAME),
    isDeductibilityEnabled: isFeatureEnabled(FEATURE_DEDUCTIBILITY_ENABLED)(state),
  }
}

const mapDispatchToProps = (dispatch, props) => {
  return bindActionCreators(
    {
      setCache: (data) => setCache({ name: getFormName(props.mileageAllowance.id), data }),
      saveAccountingMileageAllowance,
      removeAccountingMileageAllowance: () =>
        removeAccountingMileageAllowance(props.request.slug, props.mileageAllowance.id),
    },
    dispatch,
  )
}

const AccountMileageAllowanceForm = connect(
  mapStateToProps,
  mapDispatchToProps,
)(AccountMileageAllowanceFormPure)

AccountMileageAllowanceForm.propTypes = {
  request: PropTypes.object.isRequired,
  mileageAllowance: PropTypes.object.isRequired,
  accountDimensions: PropTypes.array.isRequired,
}

export { AccountMileageAllowanceForm }
export default { AccountMileageAllowanceForm }
