import React, { Fragment } from 'react'
import trans from '../../trans'
import { round } from '../../utils/number'
import { AmountFormatter } from '../AmountFormatter'
import Icon, { EXPENSES_TYPES_TO_ICONS, getIconBasedOnType } from '../ui/IconComponent'
import { renderKey } from '../../utils/react'
import _, { flatten, get } from 'lodash'
import classNames from 'classnames'
import { Tooltip } from '../Tooltip'
import { getRouteByName } from '../../routes'
import { Link } from 'react-router-dom'
import BrokenRules from './BrokenRules'
import { Document } from '../../models/Document'
import store from '../../store'
import { STATUS_FINISH } from '../../constants/request'
import { getInstance } from '../../store/app/instance'
import { getCurrentUser } from '../../store/app/user-profile/userProfileSlice'

const groupBrokenRules = {
  message: {
    name: 'request-summary.group-broken-rules',
    params: {},
  },
}

const createTooltip = (amounts = []) => {
  return (
    <span>
      {(!_.isEmpty(amounts) && _.isArray(amounts)) > 0 && (
        <Tooltip
          html={
            <span style={{ textAlign: 'right', display: 'block' }}>
              {amounts.map(({ currency, amount }, key) => (
                <span key={key}>
                  <AmountFormatter amount={amount} /> {currency}
                  <br />
                </span>
              ))}
            </span>
          }
        >
          <Icon type='fx_2' className='icon--type-fx' />
        </Tooltip>
      )}
      {(!_.isEmpty(amounts) && _.isPlainObject(amounts)) > 0 && (
        <Tooltip
          html={
            <span>
              <span>
                <AmountFormatter amount={amounts.amount} /> {amounts['currency']}
                <br />
              </span>
            </span>
          }
        >
          <Icon type='fx_2' className='icon--type-fx' />
        </Tooltip>
      )}
    </span>
  )
}

const createIcon = (type) => {
  return type ? <Icon grey type={type} /> : null
}

const createHeader = ({ requestedConvertedAmount, settledConvertedAmount }, params = {}) => {
  const requestedCell = {
    content: <div className='requested-cell'>{trans('request.requested-amount')}</div>,
    xs: params.grid.requestedCell,
    alignRight: true,
    key: 'head1__head2',
  }
  const settledCell = {
    content: <div className='settled-cell'>{trans('request-summary.settled-amount')}</div>,
    xs: params.grid.settledCell,
    alignRight: true,
    key: 'head1__head4',
  }

  return {
    row: {
      key: 'head1',
      cells: [
        {
          content: trans('request-summary.name'),
          xs: params.grid.contentCell,
          key: 'head1__head1',
        },

        { xs: params.grid.iconsCell, key: 'head1__head3' },
        params['showSettled'] ? settledCell : requestedCell,
        params['showSettled'] ? requestedCell : { key: 'head1__empty' },
      ],
    },
  }
}

const createFooter = (
  {
    requestedConvertedAmount = {},
    settledConvertedAmount = {},
    installments = {},
    originalInstallments = {},
  },
  params = {},
) => {
  const { amount: requestedAmount, currency: requestedCurrency } = requestedConvertedAmount
  const { amount: settledAmount, currency: settledCurrency } = settledConvertedAmount

  let _installmentsTitle = null
  if (!_.isEmpty(installments)) {
    _installmentsTitle = (
      <span className='table-accordion__total table-accordion__total--installment'>
        {trans('request-summary.with-installments')}
      </span>
    )
  }

  const _installments = Object.values(installments).map((installment, index) => (
    <span
      key={`installment-${installment.currency}-${index}`}
      className='table-accordion__total table-accordion__total--installment'
    >
      <AmountFormatter amount={round(installment.amount, 2)} /> {installment.currency}
    </span>
  ))

  const requestedCell = {
    content: (
      <div className='requested-cell'>
        <span className='table-accordion__total'>
          <AmountFormatter amount={round(requestedAmount, 2)} /> {requestedCurrency}
        </span>
        {_installments}
      </div>
    ),
    xs: params.grid.requestedCell,
    alignRight: true,
    key: renderKey('requested', requestedConvertedAmount),
  }
  const settledCell = {
    content: (
      <div className='settled-cell'>
        <span className='table-accordion__total'>
          <AmountFormatter amount={round(settledAmount, 2)} /> {settledCurrency}
        </span>
      </div>
    ),
    xs: params.grid.settledCell,
    alignRight: true,
    key: renderKey('settled', settledConvertedAmount),
  }

  return {
    row: {
      key: renderKey('summary'),
      thin: true,
      cells: [
        {
          xs: params.grid.contentCell,
          content: (
            <div>
              <span className='table-accordion__total'>{trans('request-summary.in-total')}</span>
              {_installmentsTitle}
            </div>
          ),
          key: 'summary1',
        },
        { xs: params.grid.iconsCell, key: renderKey('sumamry-icon', 'empty') },
        params['showSettled'] ? settledCell : requestedCell,
        params['showSettled'] ? requestedCell : { key: renderKey('summary', 'empty') },
      ],
    },
  }
}

const createAmount = ({ amount, currency }) => {
  return (
    <span>
      {/*<Icon type="fx_2" className="icon--type-fx" />*/}
      <AmountFormatter amount={round(amount, 2)} /> {currency}
    </span>
  )
}

const createDocument = (document, params = {}) => {
  const { name, convertedAmount, foreignAmount, id, annotation } = document

  document = new Document(document, params.request)
  let documentLink = document.getDocumentLink()

  const currentUser = getCurrentUser(store.getState())
  const isCurrentUserAccountant = get(currentUser, 'abilities.isAccountant', false)

  if (isCurrentUserAccountant || params.request.status === STATUS_FINISH) {
    documentLink = document.getAccountDocumentLink()
  }

  if (!documentLink) {
    documentLink = getRouteByName('main', 'RequestMileageAllowance', {
      type: params.request.type,
      id: params.request.slug,
    })
  }

  return {
    bar: {
      key: renderKey('bar', name.name, id),
      className: 'basic-summary__documents',
      rows: [
        {
          key: renderKey('bar', 'row', name.name, id),
          cells: [
            {
              content: () => (
                <div>
                  <Link to={documentLink}>{trans(name.name, name.params)}</Link>
                  {annotation && <span> - {annotation}</span>}
                </div>
              ),
              xs: params.grid.contentCell,
              key: renderKey(name.name),
            },
            {
              xs: params.grid.iconsCell,
              key: renderKey('document_empty_1'),
            },
            {
              content: () => (
                <span className='settled-cell'>
                  {createTooltip(foreignAmount)}
                  {createAmount(convertedAmount)}
                </span>
              ),
              xs: params.grid.settledCell,
              alignRight: true,
              key: renderKey('document_amounts', convertedAmount, foreignAmount),
            },
            {
              xs: params.grid.requestedCell,
              key: renderKey('document_empty_2'),
            },
          ],
        },
      ],
      content: null,
    },
  }
}

const createExpense = (element, params = {}, countryCode) => {
  const {
    name,
    documents,
    convertedAmount,
    foreignAmounts,
    requestedConvertedAmount,
    type,
    iconSource,
    id,
  } = element
  const amount = requestedConvertedAmount || convertedAmount

  const exchangeIcon =
    !_.isEmpty(foreignAmounts) && _.isArray(foreignAmounts) > 0 ? (
      <Tooltip
        html={
          <span style={{ textAlign: 'right', display: 'block' }}>
            {foreignAmounts.map(({ currency, amount }, key) => (
              <span key={key}>
                <AmountFormatter amount={amount} /> {currency}
                <br />
              </span>
            ))}
          </span>
        }
      >
        <Icon type='fx_2' className='icon--type-fx' />
      </Tooltip>
    ) : (
      ''
    )

  const settledClassNames = classNames({
    'settled-cell': true,
  })

  let requestedCell = {
    content:
      iconSource === 'country' && type !== countryCode ? (
        <span className={settledClassNames}>
          {exchangeIcon}
          {createAmount(amount)}
        </span>
      ) : (
        <span className={settledClassNames}>{createAmount(amount)}</span>
      ),
    xs: params.grid.settledCell,
    alignRight: true,
    key: renderKey(amount),
  }

  let iconType = EXPENSES_TYPES_TO_ICONS[type] || params['iconType']

  let href = getRouteByName('main', 'RequestMileageAllowance', {
    type: params.request.type,
    id: params.request.slug,
  })

  const settledCell = {}
  return {
    bar: {
      key: renderKey('bar', name.name, type, id),
      className: 'basic-summary__expense',
      rows: [
        {
          key: renderKey('bar', 'row', name.name, type, id),
          cells: [
            {
              content: () => {
                return (
                  <span>
                    {iconSource === 'country' && ( //hack, need to add param like: is_ryczałt
                      <Fragment>
                        <Link to={href} className='cover-id'>
                          {params.request.uid}
                        </Link>
                        <span
                          className={classNames('flag', {
                            [`flag-${(type ?? 'other').toLowerCase()}`]: true,
                          })}
                        />
                      </Fragment>
                    )}
                    {iconSource !== 'country' && createIcon(iconType)}
                    {type === 'mileage-allowance' && createIcon('car_flat')}{' '}
                    {trans(name.name, name.params)}
                  </span>
                )
              },
              xs: params.grid.contentCell,
              key: renderKey(name.name),
            },
            { xs: params.grid.iconsCell, key: renderKey('bar', 'icons', type, id) },
            params['showSettled'] && !_.isEmpty(settledCell) ? settledCell : requestedCell,
            params['showSettled'] && !_.isEmpty(settledCell)
              ? requestedCell
              : {
                  xs: params.grid.requestedCell,
                  key: 'expense_empty',
                },
          ],
        },
      ],
      content: () => {
        if (!_.isEmpty(documents)) {
          return documents.map((documents) => {
            return createDocument(documents, params)
          })
        }
        return null
      },
    },
  }
}

const createElement = (element, params = {}) => {
  const {
    name,
    elements,
    expenseTypes,
    requestedConvertedAmount,
    settledConvertedAmount,
    type,
    slug,
    requestedForeignAmounts,
    settledForeignAmounts,
    id,
  } = element

  const requestedCell = {
    content: (
      <span className='requested-cell'>
        {createTooltip(requestedForeignAmounts)}
        <span>{createAmount(requestedConvertedAmount)}</span>

        {params.depth === 0 && element.hasBrokenRules && !params.showSettled && (
          <BrokenRules messages={[groupBrokenRules]} />
        )}

        {!!_.get(element.rules, 'length', 0) && !params.showSettled && (
          <BrokenRules messages={element.rules} />
        )}
      </span>
    ),
    xs: params.grid.requestedCell,
    alignRight: true,
    key: renderKey('requested', requestedConvertedAmount),
  }
  const iconsCell = {
    content: () => {
      let icons = null
      if (params['depth'] === 0 && !_.isEmpty(elements)) {
        // first depth elements icons
        let elementsToDisplay = elements.map((element) => ({ type: element.type }))

        //expense types icons
        let expenseTypes = elements.map((element) =>
          element.expenseTypes
            .filter((e) => e.iconSource !== 'country')
            .map((expense) => ({ type: expense.type })),
        )

        expenseTypes = flatten(expenseTypes)

        elementsToDisplay = elementsToDisplay
          .concat(expenseTypes)
          .filter((e) => e.type !== 'unrequested_element')

        let restOfElements = null
        let iconsToDisplay = params.showLessIcons ? 3 : 4

        icons = elementsToDisplay.map((element) => ({
          type: getIconBasedOnType(element.type),
        }))
        icons = _.uniqBy(icons, 'type')

        if (icons.length > iconsToDisplay) {
          restOfElements = icons.slice(iconsToDisplay - 1)
          icons = icons.slice(0, iconsToDisplay - 1).concat({ type: 'arrow_more' })
        }

        icons = icons.map((icon) => {
          if (icon.type === 'arrow_more') {
            const tooltipIcons = restOfElements.map((restIcon) => {
              return <Icon key={`tooltip-icon-${restIcon.type}`} type={restIcon.type} />
            })

            return (
              <Tooltip html={<div>{tooltipIcons}</div>}>
                <Icon type='arrow_more' />
              </Tooltip>
            )
          }

          return <Icon key={`icon-${icon.type}`} type={icon.type} />
        })
      }

      return <div className='table-accordion__row-icons'>{icons}</div>
    },
    key: 'icons-list',
    xs: params.grid.iconsCell,
    noGutters: true,
  }

  const settleHasGroupBrokenRule =
    params.depth === 0 && element.hasBrokenRules && params.showSettled
  const settleHasElementBrokenRule = !!_.get(element.rules, 'length', 0) && params.showSettled
  const settledClassNames = classNames({
    'settled-cell': true,
    'has-warning': settleHasGroupBrokenRule || settleHasElementBrokenRule,
  })

  const settledCell = {
    content: (
      <span className={settledClassNames}>
        {createTooltip(settledForeignAmounts)}
        <span>{createAmount(settledConvertedAmount)}</span>

        {settleHasGroupBrokenRule && <BrokenRules messages={[groupBrokenRules]} />}

        {settleHasElementBrokenRule && <BrokenRules messages={element.rules} />}
      </span>
    ),
    xs: params.grid.settledCell,
    alignRight: true,
    key: renderKey('settled', settledConvertedAmount),
  }

  let iconType = getIconBasedOnType(type)

  let key = slug ? slug : type

  return {
    bar: {
      key: `bar-${name.name}-${key}-${id}`,
      className: params['depth'] === 0 ? 'basic-summary--depth-0' : undefined,
      rows: [
        {
          key: renderKey('bar', 'row', name.name, slug, id),
          cells: [
            {
              content: () => {
                return (
                  <span
                    className={
                      params['depth'] !== 0 && typeof iconType !== 'undefined'
                        ? 'basic-summary__text-indent'
                        : undefined
                    }
                  >
                    {createIcon(iconType)}
                    <span>{trans(name.name, name.params)}</span>
                  </span>
                )
              },
              xs: params.grid.contentCell,
              key: renderKey(name.name),
            },
            iconsCell,
            params['showSettled'] ? settledCell : requestedCell,
            params['showSettled'] ? requestedCell : { key: renderKey('bar', 'row', 'empty') },
          ],
        },
      ],
      content: () => {
        if (!_.isEmpty(elements)) {
          return elements.map((element) => {
            return createElement(element, { ...params, depth: params['depth'] + 1 })
          })
        } else if (_.isArray(expenseTypes)) {
          const instance = getInstance(store.getState())
          return expenseTypes.map((element) => {
            return createExpense(
              element,
              { ...params, depth: params['depth'] + 1 },
              instance.location.country_code,
            )
          })
        }
        return null
      },
    },
  }
}

const createTableDataTrip =
  (request) =>
  ({
    elements = [],
    requestedConvertedAmount,
    settledConvertedAmount,
    installments,
    showSettled,
    grid,
    showLessIcons,
    windowWidth,
  }) => {
    const data = {
      head: [],
      body: [],
      foot: [],
    }

    // header
    data['head'].push(
      createHeader({ requestedConvertedAmount, settledConvertedAmount }, { showSettled, grid }),
    )

    elements.forEach((element) => {
      data['body'].push(
        createElement(element, {
          showSettled,
          grid,
          showLessIcons,
          windowWidth,
          depth: 0,
          request,
        }),
      )
    })

    data['foot'].push(
      createFooter(
        {
          requestedConvertedAmount,
          settledConvertedAmount,
          installments,
          originalInstallments: request.installments,
        },
        { showSettled, grid },
      ),
    )

    return data
  }

export default createTableDataTrip
