import React from 'react'
import {
  arePaxAvailableAttributesForBoth,
  getPaxAvailableAttribute,
  getPaxBookedAttribute,
  getFeaturedOffers,
  getFilters,
  getProcessedOffersSelector,
  getQuery,
  getPaxRequestedAttribute,
  getReturnFlights,
  getSearchUuid,
  getSelectedBack,
  getSelectedOffersAmount,
  getSelectedOffersReservationFee,
  getSelectedThere,
  getSelectMode,
  getSortBy,
  getStatus,
  initialized,
  isIntegrity,
  isLoading,
  selector,
  searchFailed,
  isBookingProcessWaiting,
  getTotalPaxes,
  getPaxesArray,
} from '../../store/app/flights-booking/selectors'
import { bindActionCreators } from 'redux'
import {
  afterCloseResultsList,
  backToSearching,
  bookOffer,
  closeResultsList,
  fetchReturnFlights,
  fetchSelectedForElement,
  removeSelected,
  reset,
  resignFromSearching,
  saveOffer,
  search,
  searchAnotherOne,
  searchFilters,
  set,
  setAgreement,
  setFilter,
  setFilters,
  setInitialized,
  setOffers,
  setQuery,
  setPaxRequestedAttribute,
  setSortBy,
  toggleSearch,
  isChanged,
  sortFlights,
  subscribeReservationStatus,
  unsubscribeReservationStatus,
} from '../../store/app/flights-booking/creators'
import { connect as reduxConnect } from 'react-redux'
import { ProviderContext } from './context'

const enhanced = (Component) => {
  const FlightsBookingConnect = (props) => <Component<any, any> {...props} />

  const mapStateToProps = (state, props) => {
    const {
      context: { name },
    } = props
    const get = selector(name)(state)
    return {
      offers: getProcessedOffersSelector(name)(state),
      status: getStatus(name)(state),
      uuid: getSearchUuid(name)(state),
      totalPaxes: getTotalPaxes(name)(state),
      initialized: initialized(name)(state),
      query: getQuery(name)(state),
      isLoading: isLoading(name)(state),
      isIntegrity: isIntegrity(name)(state),
      filters: getFilters(name)(state),
      isSearchEnabled: get('enabled'),
      isResultsListOpen: get('isResultsListOpen'),
      isOfferDialogOpen: get('isOfferDialogOpen'),
      hasOffers: get('hasOffers'),
      isBlocked: get('isBlocked'),
      isBookingProcessWaiting: isBookingProcessWaiting(name)(state),
      selectMode: getSelectMode(name)(state),
      selectedThere: getSelectedThere(name)(state),
      selectedBack: getSelectedBack(name)(state),
      selectedOffersAmount: getSelectedOffersAmount(name)(state),
      selectedOffersReservationFee: getSelectedOffersReservationFee(name)(state),
      isFetchingReturns: get('isFetchingReturns'),
      isAgreement: get('isAgreement'),
      getPaxRequestedAttribute: (pax, offer, attribute) =>
        getPaxRequestedAttribute(name)(pax, offer, attribute)(state),
      getPaxAvailableAttribute: (pax, offer, attribute) =>
        getPaxAvailableAttribute(name)(pax, offer, attribute)(state),
      getPaxBookedAttribute: (pax, offer, attribute) =>
        getPaxBookedAttribute(name)(pax, offer, attribute)(state),
      arePaxAvailableAttributesForBoth: (pax) => arePaxAvailableAttributesForBoth(name)(pax)(state),
      sortBy: getSortBy(name)(state),
      isFiltering: get('isFiltering'),
      rules: get('rules', {}),
      featuredOffers: getFeaturedOffers(name)(state),
      returnFlights: (offer) => getReturnFlights(name, offer)(state),
      searchFailed: () => searchFailed(name)(state),
      isChanged: get('isChanged'),
      travelers: getPaxesArray(name)(state),
    }
  }

  const mapDispatchToProps = (dispatch, props) => {
    const {
      context: { name, request, element },
    } = props

    return bindActionCreators(
      {
        reset: reset(name),
        set: set(name),
        search: search(name)(request),
        setOffers: setOffers(name),
        setFilter: setFilter(name),
        searchFilters: searchFilters(name),
        sortFlights: sortFlights(name),
        setFilters: setFilters(name),
        setQuery: setQuery(name),
        bookOffer: bookOffer(name)(request, element),
        searchAnotherOne: searchAnotherOne(name)(request),
        toggleSearch: toggleSearch(name),
        resignFromSearching: resignFromSearching(name)(request, element),
        closeResultsList: closeResultsList(name),
        afterCloseResultsList: afterCloseResultsList(name),
        setInitialized: setInitialized(name),
        backToSearching: backToSearching(name)(request)(element),
        removeSelected: removeSelected(name, request, element),
        fetch: fetchSelectedForElement(name)(request, element),
        setAgreement: setAgreement(name, request, element),
        setPaxRequestedAttribute: setPaxRequestedAttribute(name, request, element),
        setSortBy: setSortBy(name),
        fetchReturnFlights: fetchReturnFlights(name),
        selectOffer: saveOffer(name, request, element),
        subscribeReservationStatus: subscribeReservationStatus(name),
        unsubscribeReservationStatus: unsubscribeReservationStatus(name),
        isChanged: isChanged(name),
      },
      dispatch,
    )
  }

  const mergedProps = (selectors, actions, own) => {
    return {
      ...own,
      flights: {
        selectors,
        actions,
        ...own.flights,
      },
    }
  }

  return reduxConnect(mapStateToProps, mapDispatchToProps, mergedProps)(FlightsBookingConnect)
}

const connect = (Component) => {
  const Enhanced = enhanced(Component)
  return (props) => (
    <ProviderContext.Consumer>
      {(context) => <Enhanced context={context} {...props} />}
    </ProviderContext.Consumer>
  )
}

export default connect
export { connect }
