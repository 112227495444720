import React from 'react'
import ReactDOM from 'react-dom'
import handleViewport from 'react-in-viewport'

const keepInViewport = () => (Component) => {
  class KeepInViewport extends React.Component<any, any> {
    constructor(props) {
      super(props)

      this.state = {
        initScrolled: false,
      }
    }

    componentDidMount() {
      const { initScroll } = this.props

      if (initScroll && !this.state.initScrolled) {
        const element = ReactDOM.findDOMNode(this.ref)
        element.scrollIntoView()
        this.setState({ initScrolled: true })
      }
    }

    componentDidUpdate(prevProps, prevState) {
      const { inViewport, isOpen, isSearchEnabled } = this.props

      const difference =
        prevProps.isOpen !== isOpen || prevProps.isSearchEnabled !== isSearchEnabled

      if (!inViewport && difference && this.ref) {
        const element = ReactDOM.findDOMNode(this.ref)
        element.scrollIntoView()
      }
    }

    innerRef = (ref) => {
      this.ref = ref
    }

    render() {
      return <Component<any, any> {...this.props} innerRef={this.innerRef} />
    }
  }

  return handleViewport(KeepInViewport)
}

export default keepInViewport
export { keepInViewport }
