import PropTypes from 'prop-types'
import React from 'react'
import classNames from 'classnames'

class LoadingOverlay extends React.Component<any, any> {
  render() {
    const { center, xs } = this.props
    const loaderClasses = classNames('loading-overlay__loader', {
      'loading-overlay__loader--center': center,
      'loading-overlay__loader--xs': xs,
    })

    return (
      <div className='loading-overlay'>
        <div className={loaderClasses} />
      </div>
    )
  }
}

LoadingOverlay.propTypes = {
  center: PropTypes.bool,
  xs: PropTypes.bool,
}

export { LoadingOverlay }
export default { LoadingOverlay }
