import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import { get } from 'lodash'
import { trans } from '../../trans'

class CommissionMessage extends Component<any, any> {
  render() {
    const { offer } = this.props
    const fee = get(offer, 'option.reservation_fee.amount', '0.00').toLowerCase()

    if (fee === '0.00') {
      return null
    }

    const amount = get(offer, 'option.reservation_fee.formatted', '0.00').toLowerCase()

    return (
      <Fragment>
        <span className='train-trip__selected-ticket-message'>
          {trans('trains-booking.commission-message', { commission: amount })}
        </span>
      </Fragment>
    )
  }
}

CommissionMessage.propTypes = {
  offer: PropTypes.object.isRequired,
}

export default CommissionMessage
export { CommissionMessage }
