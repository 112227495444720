import React from 'react'
import PropTypes from 'prop-types'
import PaginatorDefault from '../../../ui/AccordionTable/PaginatorDefault'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { push } from 'connected-react-router'
import { PER_PAGE } from '../../../../constants/pagination'

class Pagination extends React.Component<any, any> {
  constructor(props) {
    super(props)

    this.changePage = this.changePage.bind(this)
  }

  componentDidMount() {
    // call it immediately to initialize table
    this.changePage(this.getPageFromQuery())
  }

  getPageFromQuery() {
    const urlParams = new URLSearchParams(window.location.search)

    return Number(urlParams.get('page') || 1)
  }

  changePage(page) {
    const { changePage, push } = this.props

    if (page > 1) {
      push({ search: `page=${page}` })
    } else {
      push({ search: '' })
    }

    changePage(page)
  }

  get pagination() {
    const { total, perPage, page } = this.props

    return {
      pageCount: Math.ceil(total / perPage),
      totalCount: total,
      perPage,
      page,
    }
  }

  render() {
    return <PaginatorDefault align={'center'} changePage={this.changePage} {...this.pagination} />
  }
}

Pagination.defaultProps = {
  perPage: PER_PAGE,
}

Pagination.propTypes = {
  changePage: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  total: PropTypes.number.isRequired,
  perPage: PropTypes.number,
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      push,
    },
    dispatch,
  )

export default connect(null, mapDispatchToProps)(Pagination)
