import React, { memo } from 'react'
import Icon from '../ui/IconComponent'
import PropTypes from 'prop-types'

function RequestCollapsableSectionHeader({ children, isOpen, toggle }) {
  return (
    <div className='request-collapsable-section-header'>
      {children}

      <div className='request-collapsable-section-header__icon'>
        <Icon type='arrow' rotatable rotate={isOpen} onClick={() => toggle()} />
      </div>
    </div>
  )
}

RequestCollapsableSectionHeader.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.element.isRequired,
    PropTypes.arrayOf(PropTypes.element),
  ]),
  toggle: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
}

export default memo(RequestCollapsableSectionHeader)
