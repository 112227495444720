import {
  parseFilterDates,
  parseFiltersForAPI,
  parseQueryToFilters,
  createFiltersQuery,
  createSorterQuery,
} from '../../helpers'
import { replace, push } from 'connected-react-router'
import queryString from 'query-string'
import _ from 'lodash'
import moment from 'moment'
import axios from 'axios'
import { history } from '../../../store'
import { toArray } from '../../../utils/array'
import APIClient from '../../../services/APIClient'

export const createModule = ({
  namespace,
  mountPoint,
  fetchMethod,
  paginationByAPI = false,
  pagination: { perPage } = { perPage: 25 },
}) => {
  const MOUNT_POINT = mountPoint || undefined

  // constants
  const RESET = `${namespace}::reset`
  const SET_ITEMS = `${namespace}::set-items`
  const START_LOADING = `${namespace}::start-loading`
  const LOADING_ERROR = `${namespace}::loading-error`
  const CHANGE_FILTER = `${namespace}::change-filter`
  const CHANGE_SORTER = `${namespace}::change-sorter`
  const RESET_SORTER = `${namespace}::reset-sorter`
  const SET_ITEM_SELECTED = `${namespace}::set-item-selected`
  const CLEAR_SELECTED_ITEMS = `${namespace}::clear-selected-items`
  const CHANGE_PAGE = `${namespace}::change-page`
  const UPDATE_ITEM = `${namespace}::update-item`
  const SET_FRONTEND_PAGINATION = `${namespace}::pagination::api::set`
  const SET_API_PAGINATION = `${namespace}::pagination::frontend::set`
  const SET_QUERY_LISTENER = `${namespace}::query-listener::set`

  // actions
  const reset = () => (dispatch, getState) => {
    // called after component mount
    // parse URL
    const parsedURL = queryString.parseUrl(window.location.href)
    const filtersQuery = parsedURL.query.filters
    const URLfilters = queryString.parse(filtersQuery, { arrayFormat: 'comma' })
    const savedFilters = getFilters(getState(), true)
    const filters = _.isEmpty(URLfilters) ? savedFilters : parseQueryToFilters(URLfilters)
    const preview = !_.isUndefined(parsedURL.query.preview)
    const page = parsedURL.query.page || 1
    const dates = [
      'accounting_date_start',
      'accounting_date_end',
      'period_start',
      'period_end',
      'document_issued_date_start',
      'document_issued_date_end',
    ]
    const arrays = [
      'companies',
      'users',
      'employee',
      'statuses',
      'users',
      'mpks',
      'providers',
      'status',
      'accounting_status',
      'levels',
      'groups',
    ]

    for (const property in filters) {
      const filterValue = _.get(filters, property, null)

      if (filterValue === null || filterValue === undefined) {
        continue
      }

      if (dates.includes(property)) {
        filters[property] = moment(filterValue)
      } else if (arrays.includes(property)) {
        filters[property] = toArray(filterValue)
      }
    }

    if (!preview) {
      dispatch({
        type: RESET,
        payload: {
          filters,
        },
      })
    }

    dispatch(changePage(page, false))

    if (_.isEmpty(URLfilters) && !_.isEmpty(savedFilters)) {
      const search = prepareQueryUrl(getState)
      dispatch(
        replace({
          search,
        }),
      )
    }
  }
  const setItems = (data) => (dispatch) => {
    dispatch({
      type: SET_ITEMS,
      payload: {
        data,
      },
    })
  }
  const loadingError = () => (dispatch) => dispatch({ type: LOADING_ERROR })

  const changeFilter =
    (name, value, resetPage = true) =>
    (dispatch, getState) => {
      const adjustMinDate = (filters, propStart, propEnd) => {
        const isEndBeforeStart = !moment(filters[propEnd]).isAfter(filters[propStart])

        if (name === propStart && filters[propStart] !== null && isEndBeforeStart) {
          dispatch({
            type: CHANGE_FILTER,
            payload: {
              name: propEnd,
              value: moment(filters[propStart]).add(1, 'day') || null,
            },
          })
        }
      }

      dispatch({
        type: CHANGE_FILTER,
        payload: {
          name,
          value: value || null,
        },
      })

      if (resetPage) {
        dispatch(changePage(1, false))
      }

      let filters = getFilters(getState(), true)

      if ('period_start' in filters) {
        adjustMinDate(filters, 'period_start', 'period_end')
      } else {
        adjustMinDate(filters, 'accounting_date_start', 'accounting_date_end')
      }

      const search = prepareQueryUrl(getState)

      dispatch(
        replace({
          search,
        }),
      )

      dispatch(fetchItems())
    }

  const changeSorter =
    (name, value, resetPage = true) =>
    (dispatch, getState) => {
      if (!value) {
        dispatch({
          type: RESET_SORTER,
          payload: {},
        })
      } else {
        dispatch({
          type: CHANGE_SORTER,
          payload: {
            name,
            value: value || null,
          },
        })
      }

      if (resetPage) {
        dispatch(changePage(1, false))
      }

      const search = prepareQueryUrl(getState)

      dispatch(
        replace({
          search,
        }),
      )

      dispatch(fetchItems())
    }

  const prepareQueryUrl = (state) => {
    const filters = getFilters(state(), true)
    const sorter = getSorter(state())
    const pagination = getPagination(state())
    const search = {}

    if (!_.isEmpty(filters)) {
      search.filters = createFiltersQuery(filters)
      search.sorter = createSorterQuery(sorter)
    }

    if (paginationByAPI && pagination.page > 1) {
      search.page = pagination.page
    }

    return queryString.stringify(search, { arrayFormat: 'comma' })
  }

  let cancelToken = null
  const fetchItems =
    ({ resetPage } = { resetPage: true }) =>
    (dispatch, getState) => {
      dispatch({ type: START_LOADING })

      const parsedURL = queryString.parseUrl(window.location.href)
      const preview = !_.isUndefined(parsedURL.query.preview)

      const filters = parseFiltersForAPI(getFilters(getState(), true, preview))

      const sorter = getSorter(getState(), preview)
      const pagination = getPaginationForAPI(getState())

      if (cancelToken !== null) {
        cancelToken.cancel()
      }
      cancelToken = axios.CancelToken.source()

      const params = pagination ? { ...filters, ...pagination, sorter } : { ...filters, sorter }

      fetchMethod({ filters: { ...params } }, cancelToken)
        .then((response) => {
          dispatch(setItems(response.data))
          dispatch(setPagination(response.pagination, response.data, resetPage))
        })
        .catch(() => {
          dispatch(loadingError())
        })
    }

  const setPagination = (pagination, data, resetPage) => (dispatch) => {
    if (paginationByAPI) {
      dispatch({
        type: SET_API_PAGINATION,
        payload: {
          ...pagination,
        },
      })
    } else {
      dispatch({
        type: SET_FRONTEND_PAGINATION,
        payload: {
          data,
          resetPage,
        },
      })
    }
  }

  const setItemSelected = (item, select) => (dispatch) => {
    dispatch({
      type: SET_ITEM_SELECTED,
      payload: {
        select,
        item,
      },
    })
  }
  const clearSelectedItems = () => (dispatch) => {
    dispatch({
      type: CLEAR_SELECTED_ITEMS,
    })
  }

  const selectDeselectMany = (items, select) => (dispatch, getState) => {
    items.forEach((item) => {
      if (select) {
        if (!isItemSelected(getState())(item)) {
          dispatch(setItemSelected(item, true))
        }
      } else {
        if (isItemSelected(getState())(item)) {
          dispatch(setItemSelected(item, false))
        }
      }
    })
  }
  const selectDeselectAll = (select) => (dispatch, getState) => {
    const requests = getItems(getState())

    requests.forEach((request) => {
      if (select) {
        if (!isItemSelected(getState())(request)) {
          dispatch(setItemSelected(request, true))
        }
      } else {
        if (isItemSelected(getState())(request)) {
          dispatch(setItemSelected(request, false))
        }
      }
    })
  }
  const selectDeselectOne = (item) => (dispatch, getState) => {
    const isSelected = isItemSelected(getState())(item)
    dispatch(setItemSelected(item, !isSelected))
  }
  const changePage =
    (nextPage, updateURL = true) =>
    (dispatch, getState) => {
      const { page } = getPagination(getState())

      dispatch({ type: CHANGE_PAGE, payload: nextPage })

      if (updateURL && page !== nextPage) {
        const search = prepareQueryUrl(getState)
        dispatch(
          push({
            search,
          }),
        )

        dispatch(fetchItems())
      }
    }

  const listenQueryChange = () => (dispatch, getState) => {
    if (paginationByAPI) {
      const listener = history.listen((location) => {
        const { search } = location
        const parsed = queryString.parse(search, { arrayFormat: 'comma' })
        const page = Number((parsed && parsed.page) || 1)
        const pagination = getPagination(getState())

        if (pagination.page !== page) {
          dispatch(changePage(page, false))
          dispatch(fetchItems({ resetPage: false }))
        }
      })

      dispatch({
        type: SET_QUERY_LISTENER,
        payload: listener,
      })
    }
  }

  const requestCardForSelectedUsers = (data) => (dispatch, getState) => {
    const users = getSelectedItems(getState())

    return APIClient.requestCardForSelectedUsers(
      users.map((user) => user.id),
      data,
    ).then((res) => {
      const items = getItems(getState())
      const data = res.data
      const updatedItems = items.map((item) => {
        if (data[item.id]) {
          return {
            ...item,
            my_card: data[item.id],
          }
        }

        return item
      })

      dispatch(clearSelectedItems())
      dispatch(setItems(updatedItems))

      return res
    })
  }

  const unlistenQueryChange = () => (dispatch, state) => {
    const listener = getQueryListener(state())

    if (listener) {
      listener()

      dispatch({
        type: SET_QUERY_LISTENER,
        payload: null,
      })
    }
  }

  const updateItem = (item) => (dispatch) => {
    dispatch({ type: UPDATE_ITEM, payload: item })
  }

  // selectors
  const getState = (state) => state.get(MOUNT_POINT)
  const getIsLoading = (state) => getState(state).isLoading
  const getIsLoaded = (state) => getState(state).isLoaded
  const getPagination = (state) => getState(state).pagination
  const getPaginationForAPI = (state) => {
    const { perPage, page } = getState(state).pagination

    if (!paginationByAPI) {
      return null
    }

    return {
      limit: perPage,
      offset: perPage * page - perPage,
      pagination: true,
    }
  }
  const getQueryListener = (state) => getState(state).queryListener
  const getItems = (state) => getState(state).items
  const getItemsPaginated = (state) => {
    const items = getItems(state)
    const pagination = getPagination(state)

    if (paginationByAPI) {
      return items
    }

    return items.slice(
      pagination['perPage'] * (pagination['page'] - 1),
      pagination['perPage'] * pagination['page'],
    )
  }

  const getFilters = (state, parse = false, onlyQueryFilters = false) => {
    if (onlyQueryFilters) {
      const parsedURL = queryString.parseUrl(window.location.href)
      const filtersQuery = parsedURL.query.filters
      return queryString.parse(filtersQuery, { arrayFormat: 'comma' })
    }

    const { filters } = getState(state)

    if (!parse) {
      return { ...filters }
    }

    return parseFilterDates({ ...filters })
  }

  const getSorter = (state, onlyQueryFilters = false) => {
    const { sorter } = getState(state)

    if (onlyQueryFilters) {
      const parsedURL = queryString.parseUrl(window.location.href)
      const filtersQuery = parsedURL.query.filters
      return queryString.parse(filtersQuery, { arrayFormat: 'comma' })
    }

    return sorter
  }

  const getSelectedItems = (state) => {
    return getState(state).selectedItems
  }
  const isItemSelected = (state) => {
    return (item) => {
      const selected = getSelectedItems(state)
      const result = selected.find((_item) => {
        return item['slug'] === _item['slug']
      })
      return !!result
    }
  }

  // initial state
  const getInitialState = () => {
    return {
      isLoaded: false,
      isLoading: false,
      error: false,
      items: [],
      filters: {},
      sorter: {},
      selectedItems: [],
      queryListener: null,
      pagination: {
        perPage,
        pageCount: 0,
        page: 1,
        totalCount: 0,
      },
    }
  }

  const actionHandlers = {
    [RESET]: (state, action) => {
      const {
        payload: { filters },
      } = action

      return {
        ...state,
        ...getInitialState(),
        filters: {
          ...filters,
        },
      }
    },
    [SET_QUERY_LISTENER]: (state, action) => {
      return {
        ...state,
        queryListener: action.payload,
      }
    },
    [SET_API_PAGINATION]: (state, action) => {
      const { limit, offset, total } = action.payload

      return {
        ...state,
        pagination: {
          ...state.pagination,
          page: offset / limit + 1,
          pageCount: Math.ceil(total / limit),
          totalCount: total,
        },
      }
    },
    [CHANGE_PAGE]: (state, action) => {
      return {
        ...state,
        pagination: {
          ...state.pagination,
          page: action.payload,
        },
      }
    },
    [SET_FRONTEND_PAGINATION]: (state, action) => {
      const { data, resetPage } = action.payload
      return {
        ...state,
        pagination: {
          ...state.pagination,
          page: resetPage ? 1 : state.pagination.page,
          pageCount: Math.ceil(data.length / state.pagination.perPage),
          totalCount: data.length,
        },
      }
    },
    [SET_ITEMS]: (state, action) => {
      const { data } = action.payload

      return {
        ...state,
        items: data,
        isLoading: false,
        isLoaded: true,
        error: false,
      }
    },
    [START_LOADING]: (state) => {
      return {
        ...state,
        isLoading: true,
        error: false,
      }
    },
    [LOADING_ERROR]: (state) => {
      return {
        ...state,
        isLoaded: false,
        isLoading: false,
        error: true,
      }
    },
    [CHANGE_FILTER]: (state, action) => {
      let value = action.payload.value

      if (value instanceof Array) {
        value = value.filter((value, index, self) => {
          return self.indexOf(value) === index
        })
      }

      if (!value || value === '' || value.length === 0) {
        let filters = { ...state.filters }

        delete filters[action.payload.name]

        return {
          ...state,
          filters: filters,
          selectedItems: [],
        }
      } else {
        return {
          ...state,
          filters: {
            ...state.filters,
            [action.payload.name]: value,
          },
          selectedItems: [],
        }
      }
    },
    [CHANGE_SORTER]: (state, action) => {
      return {
        ...state,
        sorter: {
          [action.payload.name]: action.payload.value,
        },
        selectedItems: [],
      }
    },
    [RESET_SORTER]: (state, action) => {
      return {
        ...state,
        sorter: {},
        selectedItems: [],
      }
    },
    [CLEAR_SELECTED_ITEMS]: (state, action) => {
      return {
        ...state,
        selectedItems: [],
      }
    },
    [SET_ITEM_SELECTED]: (state, action) => {
      const { select, item } = action.payload

      if (select) {
        return {
          ...state,
          selectedItems: [...state.selectedItems, item],
        }
      } else {
        return {
          ...state,
          selectedItems: state.selectedItems.filter((_item) => {
            return _item['slug'] !== item['slug']
          }),
        }
      }
    },
    [CHANGE_PAGE]: (state, action) => {
      return {
        ...state,
        pagination: {
          ...state.pagination,
          page: action.payload,
        },
      }
    },
    [UPDATE_ITEM]: (state, action) => {
      const item = action.payload
      const index = _.findIndex(state.items, { id: item.id })

      if (index === -1) {
        return state
      }

      const newItems = [...state.items]

      newItems[index] = item

      return {
        ...state,
        items: newItems,
      }
    },
  }

  const reducer = (state = getInitialState(), action) => {
    if (actionHandlers[action.type]) {
      return actionHandlers[action.type](state, action)
    }
    return state
  }

  return {
    MOUNT_POINT,
    constants: {
      RESET,
      SET_ITEMS,
      START_LOADING,
      LOADING_ERROR,
      CHANGE_FILTER,
      SET_ITEM_SELECTED,
    },
    actions: {
      reset,
      setItems,
      loadingError,
      changeFilter,
      changeSorter,
      fetchItems,
      setItemSelected,
      selectDeselectMany,
      selectDeselectAll,
      selectDeselectOne,
      changePage,
      updateItem,
      listenQueryChange,
      unlistenQueryChange,
      requestCardForSelectedUsers,
    },
    selectors: {
      getState,
      getItems,
      getIsLoading,
      getIsLoaded,
      getFilters,
      getSorter,
      getSelectedItems,
      isItemSelected,
      getPagination,
      getItemsPaginated,
    },
    reducer,
    getInitialState,
    actionHandlers,
  }
}
