import React from 'react'
import { connect } from 'react-redux'
import {
  fetchUsersForSelect,
  isLoaded,
  makeGetUsersForSelectByCompanyIdOptionsSelector,
} from '../../store/app/users-for-select'
import { bindActionCreators } from 'redux'
import {
  addRequestTraveler,
  getRequestTravelers,
  removeRequestTraveler,
  setPrivateTrip,
} from '../../store/app/request-traveler'
import PropTypes from 'prop-types'
import { setNonDelegation } from '../../store/app/non-delegation/creators'

class RequestTravelerManager extends React.Component<any, any> {
  componentDidMount() {
    const { fetchUsersForSelect } = this.props

    fetchUsersForSelect()
  }

  render() {
    const {
      children,
      users,
      isLoaded,
      travelers,
      addRequestTraveler,
      setPrivateTrip,
      removeRequestTraveler,
      setNonDelegation,
      request,
    } = this.props

    if (request.type !== 'trip') {
      return null
    }

    const renderProps = {
      users,
      isLoaded,
      travelers,
      addRequestTraveler,
      setPrivateTrip,
      removeRequestTraveler,
      setNonDelegation: setNonDelegation,
      isPrivate: request.private,
      disabled: request.status !== 'draft',
      companyId: request.company.id,
      featurePrivateTripRequestsEnabled: request.features.FEATURE_PRIVATE_TRIP_REQUESTS_ENABLED,
      delegationTripSwitch: request.abilities.delegationTripSwitch,
    }

    return children(renderProps)
  }
}

RequestTravelerManager.propTypes = {
  children: PropTypes.func,
  request: PropTypes.object.isRequired,
}

const mapDispatchToProps = (dispatch, props) => {
  const { request } = props

  return bindActionCreators(
    {
      fetchUsersForSelect: () => fetchUsersForSelect(request.company.id),
      addRequestTraveler: (slug) => addRequestTraveler(request)(slug),
      setPrivateTrip: (isPrivate) => setPrivateTrip(request.slug)(isPrivate),
      removeRequestTraveler: (traveler) => removeRequestTraveler(request)(traveler),
      setNonDelegation: (value) => setNonDelegation(request)(value),
    },
    dispatch,
  )
}

const mapStateToProps = (state, props) => {
  const { request } = props

  const travelers = getRequestTravelers(state)
  const travelersIds = travelers.map((traveler) => traveler.user.id)

  return {
    users: makeGetUsersForSelectByCompanyIdOptionsSelector()(
      state,
      request.company.id,
      travelersIds,
    ),
    isLoaded: isLoaded(state),
    travelers,
  }
}

RequestTravelerManager = connect(mapStateToProps, mapDispatchToProps)(RequestTravelerManager)

export { RequestTravelerManager }
export default RequestTravelerManager
