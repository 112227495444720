import React, { Component } from 'react'
import { isObject } from 'lodash'
import { withRouter } from 'react-router-dom'

export default withRouter((props) => {
  if (props.children) {
    const children = props.children.filter((item) => isObject(item))
    let links = React.Children.map(children, (child) => React.cloneElement(child, {}))

    links = links.map((item, key) => {
      return (
        <li className='breadcrumb-level' key={key}>
          {item}
          <div className='breadcrumb-level breadcrumb-level--separator' />
        </li>
      )
    })

    return (
      <div className=''>
        <ol className='breadcrumb'>{links}</ol>
      </div>
    )
  }

  return null
})
