import React, { Component } from 'react'
import { TimeLineElementHeader } from '../Timeline'
import { trans } from '../../trans'
import { FormField } from '../ui/Form'
import { config } from '../../config'
import { Tooltip } from './BorderCrossings/EdgePointForm'
import { Field } from 'redux-form/immutable'
import Icon from '../ui/IconComponent'

class TargetPoint extends Component<any, any> {
  state = {
    isOpen: false,
  }

  handleClickOutside = (event) => {
    if (this.state.isOpen && this.ref && !this.ref.contains(event.target)) {
      this.setState({ isOpen: false })
    }
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside)
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside)
  }

  canShowRemoveIcon() {
    const { targetPoints } = this.props

    // check if lengths is greater than 3, because first and last elements are 'edges'
    return targetPoints.selectors.targetPoints.length > 3
  }

  renderLabel = () => {
    const { targetPoints, point } = this.props

    return (
      <div className='border-crossings__timieline-target-point'>
        <div className='border-crossings__timieline-label'>
          <strong>{trans('request.travel-point')}</strong>
        </div>

        <div className='border-crossings__timieline-inputs'>
          <div className='border-crossings__timieline-item-input-wrapper'>
            <Field
              name='target'
              type='location'
              component={FormField}
              labeltop
              placeholder={trans('border-crossing.select-country')}
            />
          </div>

          <div className='border-crossings__timieline-item-input-wrapper'>
            <Field
              name='date'
              type='datepicker'
              component={FormField}
              labeltop
              showTimeSelect
              timeFormat='HH:mm'
              dateFormat={config.datePickerFieldFormat}
              timeIntervals={15}
              placeholder={trans('border-crossing.select-date')}
            />
          </div>

          {this.canShowRemoveIcon() && (
            <div className='border-crossings__timieline-item-checkbox-wrapper'>
              <Icon
                type='trash'
                lg
                onClick={() => {
                  targetPoints.actions.remove(point)
                }}
              />
            </div>
          )}
        </div>
      </div>
    )
  }

  render() {
    const style = { ...this.props.itemStyle, zIndex: this.state.isOpen ? 999 : 1 }

    return (
      <div
        ref={(ref) => {
          this.ref = ref
        }}
        className='timeline accordion'
        style={style}
        onMouseDown={() => {
          this.setState({ isOpen: true })
        }}
      >
        <TimeLineElementHeader icon='pin_1' gradient='true'>
          <div className='border-crossings__timieline-item'>{this.renderLabel()}</div>
        </TimeLineElementHeader>
      </div>
    )
  }
}

export { TargetPoint }
export default TargetPoint
