import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { get } from 'lodash'
import APIClient from '../../../services/APIClient'

export const MODULE_MOUNT_POINT = 'languages'
const SET = MODULE_MOUNT_POINT + '::set'
const RESET = MODULE_MOUNT_POINT + '::reset'

const getInitialState = () => {
  return {
    data: [],
    loading: true,
  }
}

// actions Creators
const set = (data) => (dispatch) => {
  dispatch({
    type: SET,
    payload: data,
  })
}

const reset = () => (dispatch) => {
  dispatch({
    type: RESET,
  })
}

const fetch = () => (dispatch) => {
  return APIClient.getLanguages().then((response) => {
    dispatch(set({ data: response.data, loading: false }))
  })
}

//actions
const resetAction = () => {
  return getInitialState()
}

const setAction = (state, payload) => {
  return {
    ...state,
    ...payload,
  }
}

const actions = {
  [RESET]: resetAction,
  [SET]: setAction,
}

//selectors
const getState = (state) => {
  return state.get(MODULE_MOUNT_POINT)
}

const getLanguages = (state) => {
  return get(getState(state), 'data', [])
}

const isLoading = (state) => {
  return get(getState(state), 'loading', false)
}

export const reducer = (state = getInitialState(), action) => {
  if (actions.hasOwnProperty(action.type)) {
    return actions[action.type](state, action.payload)
  }
  return state
}

const languages =
  (resetOnMount = false, fetchOnMount = false) =>
  (Component) => {
    class Languages extends React.Component<any, any> {
      componentDidMount() {
        const { languages } = this.props

        if (resetOnMount) {
          languages.actions.reset()
        }

        if (fetchOnMount) {
          languages.actions.fetch()
        }
      }

      render() {
        return <Component<any, any> {...this.props} />
      }
    }

    const mapStateToProps = (state) => ({
      languages: getLanguages(state),
      isLoading: isLoading(state),
    })

    const mapDispatchToProps = (dispatch) => {
      return bindActionCreators(
        {
          reset,
          set,
          fetch,
        },
        dispatch,
      )
    }

    const mergedProps = (selectors, actions, own) => {
      return {
        ...own,
        languages: {
          selectors,
          actions,
          ...own.languages,
        },
      }
    }

    return connect(mapStateToProps, mapDispatchToProps, mergedProps)(Languages)
  }

export { languages, getLanguages }
export default languages
