import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import classnames from 'classnames'

import trans from '../../../../trans'
import { Loader } from '../../../ui/LoadingOverlay/Loader'
import Button from '../../../ui/ButtonComponent'
import {
  CARD_STATUS_ADDING,
  CARD_TYPE_INDIVIDUAL,
  CARD_TYPE_CORPO,
  deleteCard,
} from '../../../../store/app/charge-cards'
import Ellipsis from '../../../ui/Ellipsis/Ellipsis'
import TruncateMarkup from 'react-truncate-markup'

class CardsTableRow extends PureComponent<any, any> {
  static propTypes = {
    data: PropTypes.any,
    allowDeletingCorporate: PropTypes.bool.isRequired,
    withPriority: PropTypes.bool.isRequired,
    subjectUser: PropTypes.object,
  }

  state = {
    deleteRequestLoading: false,
  }

  handleDeleteCard = () => {
    const userSlug =
      this.props.subjectUser && this.props.subjectUser.slug ? this.props.subjectUser.slug : null
    this.setState({
      deleteRequestLoading: true,
    })
    this.props
      .deleteCard(this.props.data.slug, userSlug)
      .then(() => {
        this.setState({ deleteRequestLoading: false })
      })
      .catch(() => {
        this.setState({ deleteRequestLoading: false })
      })
  }

  renderDeleteButton = () => {
    const { data, allowDeletingCorporate } = this.props
    const { deleteRequestLoading } = this.state
    const deleteBtnWrapperClass = classnames('has-loader', {
      'charge-card__operation-button charge-card__operation-button--delete': deleteRequestLoading,
    })
    return (
      <div className={deleteBtnWrapperClass}>
        {deleteRequestLoading ? (
          <Loader small center />
        ) : (
          <Button
            outline
            className='charge-card__operation-button charge-card__operation-button--delete'
            onClick={this.handleDeleteCard}
            disabled={
              (data.type === CARD_TYPE_CORPO && !allowDeletingCorporate) ||
              data.status === CARD_STATUS_ADDING
            }
          >
            {trans('user.delete')}
          </Button>
        )}
      </div>
    )
  }

  render() {
    const { data, draggableProps, withPriority, withCompany } = this.props

    const classes = classnames({
      'charge-card': true,
      'charge-cards__charge-card': true,
      'charge-cards__charge-card--pending': data.status === CARD_STATUS_ADDING,
    })

    const companiesArray = []
    _.forEach(data.companies, (company) => {
      companiesArray.push(company.name)
    })

    return (
      <div className={classes} style={this.props.style} {...draggableProps}>
        <div className='charge-card__column'>
          {data.status === CARD_STATUS_ADDING ? trans('user.payment-cards-adding') : data.number}
        </div>
        <div className='charge-card__column'>
          {data.type === CARD_TYPE_INDIVIDUAL
            ? trans('user.card-type-individual')
            : trans('user.card-type-corpo')}
        </div>
        {withCompany && (
          <div className='charge-card__column'>
            <TruncateMarkup ellipsis={<Ellipsis text={companiesArray.join(', ')} />}>
              <span>{companiesArray.join(', ')}</span>
            </TruncateMarkup>
          </div>
        )}
        {withPriority && <div className='charge-card__column'>{data.priority + 1}</div>}
        <div className='charge-card__column charge-card__column--operations'>
          {this.renderDeleteButton()}
        </div>
      </div>
    )
  }
}

export default connect(null, { deleteCard })(CardsTableRow)
