import PropTypes from 'prop-types'
import React from 'react'
import Row from './Row'
import Cell from './Cell'
import Table from './Table'
import TBody from './TBody'
import jQuery from 'jquery'
import ReactDOM from 'react-dom'
import { Tooltip } from '../../Tooltip'

class TableFlatArrayRenderer extends React.Component<any, any> {
  constructor(...props) {
    super(...props)
    this.cellsToResize = []
    this.rows = []
    this.state = {
      cellStyle: {},
    }
    this.timeout = null
  }

  componentDidMount() {
    this.resize()
    window.addEventListener('resize', this.resize)
  }

  componentWillUnmount() {
    clearTimeout(this.timeout)
    window.removeEventListener('resize', this.resize)
  }

  resize = () => {
    clearTimeout(this.timeout)
    this.timeout = setTimeout(() => {
      this.cellsToResize.forEach(({ el, nextRows, columnIndex, rowIndex }) => {
        let top = 0
        nextRows.forEach((rowIndex) => {
          const $dom = ReactDOM.findDOMNode(this.rows[rowIndex])
          top += jQuery($dom).height() / 2
        })

        const state = this.state
        state['cellStyle'][`${rowIndex}-${columnIndex}`] = {
          position: 'relative',
          top: `${top}px`,
        }
        this.setState(state)
      })
    }, 500)
  }

  render() {
    const { data } = this.props
    const { cellStyle } = this.state

    return (
      <Table stripedVertically styleForTable={this.props.styleForTable}>
        <TBody>
          {data.map((row, rowIndex) => {
            const cellsCount = row['columns'].length

            return (
              <Row
                key={`column-${rowIndex}`}
                renderer={{
                  type: row['type'],
                }}
                ref={(el) => (this.rows[rowIndex] = el)}
                className='green-table'
              >
                {row['columns'].map((column, columnIndex) => {
                  const singleWidth = 100 / cellsCount
                  let cellWidth = singleWidth
                  let _break = false

                  row['columns'].forEach((nextColumn, nextColumnIndex) => {
                    if (nextColumnIndex > columnIndex) {
                      if (_break === false && nextColumn['connectLeft']) {
                        cellWidth += singleWidth
                      } else {
                        _break = true
                      }
                    }
                  })

                  let _break2 = false
                  let saveRef = false
                  const nextRows = []

                  data.forEach((row2, rowIndex2) => {
                    if (rowIndex2 > rowIndex) {
                      if (!_break2) {
                        if (row2['columns'][columnIndex]['connectUpper']) {
                          saveRef = true
                          _break2 = true
                          nextRows.push(rowIndex2)
                        }
                      } else {
                        if (row2['columns'][columnIndex]['connectUpper']) {
                          nextRows.push(rowIndex2)
                        }
                      }
                    }
                  })

                  return (
                    <Cell
                      percentWidth={cellWidth}
                      key={`cell-${columnIndex}`}
                      renderer={{
                        connectUpper: column['connectUpper'],
                        connectLeft: column['connectLeft'],
                        type: column['type'],
                      }}
                      noGutters={column.smallMargin}
                    >
                      <div
                        style={cellStyle[`${rowIndex}-${columnIndex}`]}
                        ref={(el) => {
                          if (saveRef) {
                            this.cellsToResize[columnIndex] = {
                              el,
                              nextRows,
                              columnIndex,
                              rowIndex,
                            }
                          }
                        }}
                      >
                        <Tooltip html={column.tooltip}>{column.content}</Tooltip>
                      </div>
                    </Cell>
                  )
                })}
              </Row>
            )
          })}
        </TBody>
      </Table>
    )
  }
}

TableFlatArrayRenderer.propTypes = {
  data: PropTypes.array.isRequired,
}

TableFlatArrayRenderer.defaultProps = {
  data: [],
}

export { TableFlatArrayRenderer }
export default { TableFlatArrayRenderer }
