import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import { trans } from '../../trans'
import { debounce } from 'lodash'
import APIClient from '../../services/APIClient'
import { get } from 'lodash'

class TargetPointsArea extends React.PureComponent<any, any> {
  constructor(props) {
    super(props)
    this.state = {
      value: get(this.props, 'request.target_points_text', ''),
    }
  }

  onChange = (event) => {
    const value = event.target.value
    this.setState({ value }, () => {
      this.save(value)
    })
  }

  save = debounce((value) => {
    APIClient.updateRequestWithoutDependency(this.props.request.slug, { target_points_text: value })
  }, 250)

  render() {
    return (
      <div className='is-margin-bottom-small'>
        <label>
          <span className='border-crossings__heading'>
            {trans('deductions-widget.target-points')}
          </span>
          <textarea
            className='input input--textarea'
            onChange={this.onChange}
            value={this.state.value}
            disabled={this.props.isReadOnly}
          />
        </label>
      </div>
    )
  }
}

TargetPointsArea.propTypes = {
  request: PropTypes.object.isRequired,
}

export default TargetPointsArea
export { TargetPointsArea }
