import React, { Component } from 'react'
import { FormGroup } from '../../../../ui'
import Button from '../../../ui/ButtonComponent'
import trans from '../../../../trans'
import { connect } from 'react-redux'
import { addAssistantAction } from '../../../../store/app/assistants'
import userProfile from '../../../../store/app/user-profile/providers/userProfile'
import PropTypes from 'prop-types'
import Avatar from '../../../Avatar/Avatar'
import { loadOptionsForUsers } from '../../../../store/app/users-for-select'
import UserField from '../../../../form/UserField/UserField'

class AssistantsForm extends Component<any, any> {
  state = {
    adding: false,
    currentUser: null,
  }

  handleUserChange = (user) => {
    this.setState({ currentUser: user })
  }

  isSaveDisabled = () => {
    const { currentUser, adding } = this.state

    return !currentUser || adding
  }

  addAssistant = () => {
    const { currentUser } = this.state

    this.setState({ adding: true })
    this.props
      .addAssistant(
        currentUser.id,
        this.props.subjectUser && this.props.subjectUser.slug ? this.props.subjectUser.slug : null,
      )
      .then(() => {
        this.setState({ adding: false, currentUser: null })
      })
      .catch(() => {
        this.setState({ adding: false })
      })
  }

  render() {
    return (
      <div>
        <div className='substitutions__user-panel'>
          <div className='substitutions__username-field-wrapper'>
            <div style={{ zIndex: 10000 }}>
              <FormGroup>
                <UserField
                  value={this.state.currentUser}
                  onChange={this.handleUserChange}
                  loadItems={loadOptionsForUsers}
                />
              </FormGroup>
            </div>
          </div>

          {this.state.currentUser && (
            <div className='substitutions__user'>
              <Avatar
                className='substitutions__user-avatar'
                user={this.state.currentUser}
                asImage={true}
              />

              <div>
                <span className='substitutions__user-detail substitutions__user-detail--bold'>
                  {this.state.currentUser.full_name}
                </span>
                <a
                  href={`mailto:${this.state.currentUser.email}`}
                  className='substitutions__user-detail'
                >
                  {this.state.currentUser.email}
                </a>
              </div>
            </div>
          )}
        </div>

        <Button
          className='substitutions__set-button'
          primary
          disabled={this.isSaveDisabled()}
          onClick={this.addAssistant}
        >
          {trans('user.set')}
        </Button>
      </div>
    )
  }
}

const mapDispatchToProps = {
  addAssistant: addAssistantAction,
}

AssistantsForm.propTypes = {
  subjectUser: PropTypes.object,
}

export default userProfile(false)(connect(null, mapDispatchToProps)(AssistantsForm))
