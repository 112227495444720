import PropTypes from 'prop-types'
import React from 'react'
import moment from 'moment'
import { Table, TBody, THead, Row, Cell } from '../../components/ui/AccordionTable'
import { config } from '../../config'
import trans from '../../trans'
import { AmountFormatter } from '../AmountFormatter'
import { STATUS_ACCOUNTING, STATUS_FINISH } from '../../constants/request'
import { getRouteByName } from '../../routes'
import store from '../../store'
import { get } from 'lodash'
import Ellipsis from '../ui/Ellipsis/Ellipsis'
import TruncateMarkup from 'react-truncate-markup'
import { CellGroup } from '../ui/AccordionTable/CellGroup'
import SortableCellWrapper from '../Sortable/SortableCellWrapper'
import { getCurrentUser } from '../../store/app/user-profile/userProfileSlice'

export class DocumentsListTable extends React.Component<any, any> {
  renderDocumentNumber(document) {
    if (document['annotation'] && document['document_number']) {
      return `${document['document_number']} - ${document['annotation']}`
    }

    if (document['annotation']) {
      return document['annotation']
    }

    if (document['document_number']) {
      return document['document_number']
    }

    return null
  }

  getDocumentLink(document) {
    const {
      request: { abilities, status },
    } = document

    const currentUser = getCurrentUser(store.getState())
    const isCurrentUserAccountant = get(currentUser, 'abilities.isAccountant', false)

    if (
      (status === STATUS_ACCOUNTING && (abilities.account || isCurrentUserAccountant)) ||
      status === STATUS_FINISH
    ) {
      return getRouteByName('main', 'AccountDocument', { id: document.id })
    }

    return getRouteByName('main', 'document', { id: document.id })
  }

  getCoverLink(document) {
    return getRouteByName('main', 'RequestMileageAllowance', {
      type: document.request.type,
      id: document.request.slug,
    })
  }

  getLink(document) {
    const { transaction_type } = document

    if (transaction_type === 'document') {
      return this.getDocumentLink(document)
    } else if (transaction_type === 'cover') {
      return this.getCoverLink(document)
    } else {
      return ''
    }
  }

  render() {
    const { documents, isLoading, changePage, pagination, instance, sorter, onChangeSorter } =
      this.props

    return (
      <Table
        className={['table-stylesheet-theme', 'transactions-table']}
        striped
        pagination={{
          ...pagination,
          changePage,
          align: 'center',
        }}
      >
        <THead>
          <Row>
            <SortableCellWrapper
              sorter={sorter}
              onChangeSorter={onChangeSorter}
              name={'real_accounted_at'}
            >
              {({ icon }) => (
                <Cell
                  fixedWidth={155}
                  className={'table-accordion__col__sortable'}
                  style={{ alignSelf: 'center' }}
                >
                  {trans('document.accounting-date')}&nbsp;{icon}
                </Cell>
              )}
            </SortableCellWrapper>
            <SortableCellWrapper
              sorter={sorter}
              onChangeSorter={onChangeSorter}
              name={'document_number'}
            >
              {({ icon }) => (
                <Cell
                  fixedWidth={190}
                  className={'table-accordion__col__sortable'}
                  style={{ alignSelf: 'center' }}
                >
                  {trans('document.document-number')}&nbsp;{icon}
                </Cell>
              )}
            </SortableCellWrapper>
            <SortableCellWrapper
              sorter={sorter}
              onChangeSorter={onChangeSorter}
              name={'issue_date'}
            >
              {({ icon }) => (
                <Cell
                  fixedWidth={150}
                  className={'table-accordion__col__sortable'}
                  style={{ alignSelf: 'center' }}
                >
                  {trans('document.document-date')}&nbsp;{icon}
                </Cell>
              )}
            </SortableCellWrapper>
            <SortableCellWrapper
              sorter={sorter}
              onChangeSorter={onChangeSorter}
              name={'erp_accounted_at'}
            >
              {({ icon }) => (
                <Cell
                  fixedWidth={130}
                  className={'table-accordion__col__sortable'}
                  style={{ alignSelf: 'center' }}
                >
                  {trans('document.date-vat')}&nbsp;{icon}
                </Cell>
              )}
            </SortableCellWrapper>
            <SortableCellWrapper
              style={{ width: '100%' }}
              sorter={sorter}
              onChangeSorter={onChangeSorter}
              name={'erp_id'}
            >
              {({ icon }) => (
                <Cell
                  fixedWidth={'auto'}
                  className={'table-accordion__col__sortable'}
                  style={{ alignSelf: 'center' }}
                >
                  {trans('document.document-accounting-number')}&nbsp;{icon}
                </Cell>
              )}
            </SortableCellWrapper>
            <SortableCellWrapper sorter={sorter} onChangeSorter={onChangeSorter} name={'gross'}>
              {({ icon }) => (
                <Cell
                  fixedWidth={120}
                  alignRight
                  className={'table-accordion__col__sortable'}
                  style={{ alignSelf: 'center', display: 'flex', justifyContent: 'flex-end' }}
                >
                  {trans('document.list-header-gross')}&nbsp;{icon}
                </Cell>
              )}
            </SortableCellWrapper>
            <SortableCellWrapper
              sorter={sorter}
              onChangeSorter={onChangeSorter}
              name={'request_number'}
            >
              {({ icon }) => (
                <Cell
                  fixedWidth={115}
                  className={'table-accordion__col__sortable'}
                  style={{ alignSelf: 'center' }}
                >
                  {trans('request.request-id')}&nbsp;{icon}
                </Cell>
              )}
            </SortableCellWrapper>
          </Row>
        </THead>
        <TBody isLoading={isLoading}>
          {documents.map((document, i) => {
            return (
              <Row className='request-list__table' key={i}>
                <CellGroup href={this.getLink(document)}>
                  <Cell fixedWidth={155}>
                    {document['real_accounted_at'] ? (
                      moment(document['real_accounted_at']).format(config.apiDateFormat)
                    ) : (
                      <TruncateMarkup
                        lines={2}
                        ellipsis={<Ellipsis text={trans('document.not-accounted')} />}
                      >
                        <span>
                          {document.type === 'travel' || document.type === 'confirmation'
                            ? ''
                            : trans('document.not-accounted')}
                        </span>
                      </TruncateMarkup>
                    )}
                  </Cell>
                  <Cell fixedWidth={190} className='request-list__column-name'>
                    <TruncateMarkup
                      lines={2}
                      ellipsis={
                        <Ellipsis text={<span>{this.renderDocumentNumber(document)}</span>} />
                      }
                    >
                      <span>{this.renderDocumentNumber(document)}</span>
                    </TruncateMarkup>
                  </Cell>
                  <Cell fixedWidth={150}>{document['issue_date']}</Cell>
                  <Cell fixedWidth={130}>{this.getVatDate(document)}</Cell>
                  <Cell fixedWidth={'auto'}>{document['erp_accounting_number']}</Cell>
                  <Cell fixedWidth={120} alignRight>
                    {this.getGrossAmount(document)}
                  </Cell>
                </CellGroup>

                <CellGroup fixedWidth={115} href={`/requests/trip/${document.request.slug}`}>
                  <Cell fixedWidth={115} style={{ whiteSpace: 'nowrap' }}>
                    {document.request && (
                      <TruncateMarkup
                        lines={2}
                        ellipsis={<Ellipsis text={<span>{document.request.uid}</span>} />}
                      >
                        <span>{document.request.uid}</span>
                      </TruncateMarkup>
                    )}
                  </Cell>
                </CellGroup>
              </Row>
            )
          })}
        </TBody>
      </Table>
    )
  }

  getGrossAmount = (document) => {
    if (document.type === 'travel') {
      return ''
    }

    return <AmountFormatter amount={document['gross']} />
  }

  getVatDate = (document) => {
    if (document.type === 'travel' || document.type === 'confirmation') {
      return ''
    }

    return document.vat_date ? document.vat_date : '-'
  }
}

DocumentsListTable.propTypes = {
  documents: PropTypes.array.isRequired,
  isLoading: PropTypes.bool.isRequired,
  getRouteByName: PropTypes.func.isRequired,
  changePage: PropTypes.func.isRequired,
  pagination: PropTypes.object.isRequired,
  instance: PropTypes.object.isRequired,
}
