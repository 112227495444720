import React, { useCallback, useState } from 'react'
import { AgGridReact } from 'ag-grid-react'
import { useGridOptions } from './hooks/useGridOptions'
import Pagination from '../SettingsPage/CompanyPage/Pagination/Pagination'
import { CellClickedEvent } from 'ag-grid-community'
import { ITransactionSuggestion } from '../../types/transaction-suggestion'
import PaymentAttachModal from '../PaymentAttachModal'
import APIClient from '../../services/APIClient'
import { HttpResponse, Paginator } from '../../types/response'
import NoInvoicesModal from '../NoInvoicesModal'

function PaymentsPageTable({
  data,
  paginator,
  setPage,
  refresh,
}: {
  data: ITransactionSuggestion[]
  paginator: Partial<Paginator>
  setPage: (page: number) => void
  refresh: () => Promise<void>
}) {
  const [showModal, setShowModal] = useState(false)
  const [documents, setDocuments] = useState<ITransactionSuggestion[]>([])
  const gridOptions = useGridOptions()
  const onAttach = useCallback((item: ITransactionSuggestion) => {
    return APIClient.makeRequestFromHttpLink(item._links.attach).then((res) => {
      setShowModal(false)
      setDocuments([])

      return refresh()
    })
  }, [])

  const onCellClicked = useCallback((params: CellClickedEvent<ITransactionSuggestion>) => {
    if (params.colDef.type === 'attach') {
      if (params.data.reconciled) {
        return APIClient.makeRequestFromHttpLink(params.data._links.detach).then((res) => refresh())
      }

      return APIClient.getReconciliations(params.data.transaction._links.reconciliations).then(
        (res) => {
          setDocuments(res.data)
          setShowModal(true)
        },
      )
    }
  }, [])

  return (
    <div className={'payment-table'}>
      <div className={'ag-theme-alpine'}>
        <AgGridReact
          gridOptions={gridOptions}
          rowData={data}
          rowHeight={58}
          headerHeight={40}
          onCellClicked={onCellClicked}
        />
      </div>

      <Pagination
        page={paginator.page}
        total={paginator.total}
        perPage={paginator.per_page}
        changePage={(page) => setPage(page)}
      />

      {documents.length ? (
        <PaymentAttachModal
          open={showModal}
          items={documents}
          onAttach={onAttach}
          onClose={() => setShowModal(false)}
        />
      ) : (
        <NoInvoicesModal open={showModal} onClose={() => setShowModal(false)} />
      )}
    </div>
  )
}

export default PaymentsPageTable
