import PropTypes from 'prop-types'
import React from 'react'
import classNames from 'classnames'
import _ from 'lodash'
import { Link } from 'react-router-dom'

export const TYPE_SUMMARY_CELL = 'summary'
export const TYPE_SUMMARY2_CELL = 'summary2'

class Cell extends React.Component<any, any> {
  render() {
    const {
      children,
      xs,
      alignRight,
      percentWidth,
      fixedWidth,
      renderer = {},
      style = {},
      className,
      cellClassName,
      alignCenter,
      noGutters,
      href,
    } = this.props

    if (_.isNumber(fixedWidth) && fixedWidth > 0) {
      style['width'] = `${fixedWidth}px`
      style['paddingLeft'] = !noGutters ? '15px' : 0
      style['paddingRight'] = !noGutters ? '15px' : 0
    } else if (fixedWidth == 'auto') {
      style['flex'] = `1`
      style['paddingLeft'] = !noGutters ? '15px' : 0
      style['paddingRight'] = !noGutters ? '15px' : 0
    } else if (_.isNumber(percentWidth) && percentWidth > 0) {
      style['width'] = `${percentWidth}%`
      style['paddingLeft'] = !noGutters ? '15px' : 0
      style['paddingRight'] = !noGutters ? '15px' : 0
    }

    if (alignRight) {
      style['display'] = 'flex'
      style['justifyContent'] = 'flex-end'
    }

    let classes = classNames({
      'table-accordion__col': true,
      [`xs-${xs}`]: !!xs,
      'is-allign-end': alignRight,
      'is-align-center': alignCenter,
      'table-accordion__col--is-connected-to-upper': renderer['connectUpper'],
      'table-accordion__col--is-connected-to-left': renderer['connectLeft'],
      'table-accordion__col--is-summary': renderer['type'] === TYPE_SUMMARY_CELL,
      'table-accordion__col--is-summary-2': renderer['type'] === TYPE_SUMMARY2_CELL,
      'table-accordion__col--is-link': !!href,
    })

    const content = (
      <div className={classNames('table-accordion__col__content', className)}>{children}</div>
    )

    return href ? (
      <Link to={href} style={style} className={classNames(classes, cellClassName)}>
        {content}
      </Link>
    ) : (
      <div style={style} className={classNames(classes, cellClassName)}>
        {content}
      </div>
    )
  }
}

Cell.propTypes = {
  xs: PropTypes.number,
  alignRight: PropTypes.bool,
  alignCenter: PropTypes.bool,
  percentWidth: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]),
  renderer: PropTypes.object,
  style: PropTypes.object,
  noGutters: PropTypes.bool,
}

Cell.defaultProps = {
  alignRight: false,
  percentWidth: 0,
  fixedWidth: 0,
}

export { Cell }
export default Cell
