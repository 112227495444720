// initial state
import APIClient from '../../../services/APIClient'
import numeral from 'numeral'
import { isUndefined, isNumber, isFunction } from 'lodash'
import { CANCEL_REFUSED, CANCEL_SUBMITTED, SET_DOCUMENT_OFFER } from '../document-offer'
import { OFFER_STATUS } from '../../../constants/offer-status'

const getInitialState = () => {
  return {
    document: {
      isLoaded: false,
      isLoading: false,
      data: {},
    },
    providers: {
      isLoaded: false,
      isLoading: false,
      data: [],
    },
    currencies: {
      isLoaded: false,
      isLoading: false,
      data: [],
    },
    documentElementTypes: {
      isLoaded: false,
      isLoading: false,
      data: [],
    },
    accountingAccounts: {
      isLoaded: false,
      isLoading: false,
      data: [],
    },
    exchangeRate: {
      isLoaded: false,
      isLoading: false,
      data: [],
    },
    requestElements: {
      isLoaded: false,
      isLoading: false,
      data: [],
    },
    provider: {},
    deletedElementsInProgress: [],
    addFormVisible: false,
  }
}

// constants
export const RESET_DOCUMENT_ACCOUNT_PAGE = 'document-account-page::reset'
export const START_LOADING = 'document-account-page::start-loading-document'
export const STOP_LOADING = 'document-account-page::stop-loading'
export const SET_DOCUMENT = 'document-account-page::set-document'
export const SET_PROVIDERS = 'document-account-page::set-providers'
export const CHANGE_PROVIDER = 'document-account-page::change-provider'
export const SET_CURRENCIES = 'document-account-page::set-currencies'
export const SET_DOCUMENT_ELEMENT_TYPES = 'document-account-page::set-document-element-types'
export const SET_ACCOUNTING_ACCOUNTS = 'document-account-page::set-accounting-accounts'
export const SET_EXCHANGE_RATE = 'document-account-page::set-exchange-rate'
export const SET_REQUEST_ELEMENTS = 'document-account-page::set-request-elements'
export const UPDATE_DOCUMENT_ELEMENT = 'document-account-page::update-document-element'
export const REMOVE_DOCUMENT_ELEMENT = 'document-account-page::remove-document-element'
export const UPDATE_DELETED_ELEMENT_STATUS = 'document-account-page::update-deleted-element-status'
export const UPDATE_ADD_FORM_VISIBLE = 'document-account-page::update-add-form-visible'
export const ADD_DOCUMENT_ELEMENT = 'document-account-page::add-document-element'
export const SET_OCR_DATA = 'document-account-page::set-ocr-data'
export const SET_DOCUMENT_ELEMENTS = 'document-account-page::set-document-elements'
export const UPDATE_DOCUMENT_AMOUNT = 'document-account-page::update-document-amount'

export const MOUNT_POINT = 'document-account-page'

// reducer
export const reducer = (state = getInitialState(), action) => {
  switch (action.type) {
    case RESET_DOCUMENT_ACCOUNT_PAGE:
      return getInitialState()
    case START_LOADING: {
      const { key, useLoadingStack } = action.payload
      let { loadingStack } = state[key]

      if (useLoadingStack) {
        if (isUndefined(loadingStack)) {
          loadingStack = 1
        } else {
          loadingStack += 1
        }
      }

      return {
        ...state,
        [key]: {
          ...state[key],
          isLoading: true,
          loadingStack,
        },
      }
    }
    case STOP_LOADING: {
      const { key, resolve } = action.payload
      const { loadingStack } = state[key]

      if (!isUndefined(loadingStack)) {
        const currentLoadingStack = loadingStack === 0 ? 0 : loadingStack - 1

        if (currentLoadingStack === 0) {
          resolve()
        }

        return {
          ...state,
          [key]: {
            ...state[key],
            loadingStack: currentLoadingStack,
          },
        }
      }

      break
    }

    case CANCEL_REFUSED: {
      return {
        ...state,
        document: {
          ...state.document,
          data: {
            ...state.document.data,
            offer: { ...state.document.data.offer, status: OFFER_STATUS.CANCELLATION_FAILED },
          },
        },
      }
    }

    case SET_DOCUMENT_OFFER:
    case CANCEL_SUBMITTED: {
      const offer = action.offer

      return {
        ...state,
        document: {
          ...state.document,
          data: {
            ...state.document.data,
            status:
              offer.status === OFFER_STATUS.PENDING_CANCELLATION
                ? 'processing'
                : state.document.data.status,

            offer: { ...offer },
          },
        },
      }
    }

    case SET_DOCUMENT: {
      return {
        ...state,
        document: {
          ...state.document,
          data: action.payload,
          isLoading: false,
          isLoaded: true,
        },
        provider: action.payload.provider,
      }
    }

    case UPDATE_DOCUMENT_AMOUNT: {
      return {
        ...state,
        document: {
          ...state.document,
          data: {
            ...state.document.data,
            ...action.payload,
          },
        },
      }
    }
    case SET_PROVIDERS:
      return {
        ...state,
        providers: {
          ...state.providers,
          data: action.payload,
          isLoading: false,
          isLoaded: true,
        },
      }
    case CHANGE_PROVIDER:
      return {
        ...state,
        provider:
          state.providers.data.find((provider) => {
            return provider['id'] === action.payload
          }) || {},
      }
    case SET_CURRENCIES:
      return {
        ...state,
        currencies: {
          ...state.currencies,
          data: action.payload,
          isLoading: false,
          isLoaded: true,
        },
      }
    case SET_DOCUMENT_ELEMENT_TYPES:
      return {
        ...state,
        documentElementTypes: {
          ...state.documentElementTypes,
          data: action.payload,
          isLoading: false,
          isLoaded: true,
        },
      }
    case SET_ACCOUNTING_ACCOUNTS:
      return {
        ...state,
        accountingAccounts: {
          ...state.accountingAccounts,
          data: action.payload,
          isLoading: false,
          isLoaded: true,
        },
      }
    case SET_EXCHANGE_RATE:
      return {
        ...state,
        exchangeRate: {
          data: action.payload,
          isLoading: false,
          isLoaded: true,
        },
      }
    case SET_REQUEST_ELEMENTS:
      return {
        ...state,
        requestElements: {
          data: action.payload,
          isLoading: false,
          isLoaded: true,
        },
      }
    case UPDATE_DOCUMENT_ELEMENT:
      return {
        ...state,
        document: {
          ...state.document,
          data: {
            ...state.document.data,
            elements: state.document.data.elements.map((element) => {
              if (element['id'] === action.payload['id']) {
                return action.payload
              }
              return element
            }),
          },
        },
      }
    case REMOVE_DOCUMENT_ELEMENT:
      return {
        ...state,
        document: {
          ...state.document,
          data: {
            ...state.document.data,
            elements: state.document.data.elements.filter((element) => {
              return element['id'] !== action.payload['id']
            }),
          },
        },
      }
    case UPDATE_DELETED_ELEMENT_STATUS: {
      let { deletedElementsInProgress } = state

      if (action.payload.action === 'add') {
        deletedElementsInProgress.push(action.payload.element['id'])
      } else if (action.payload.action === 'remove') {
        deletedElementsInProgress = deletedElementsInProgress.filter((id) => {
          return id !== action.payload.element['id']
        })
      }

      return {
        ...state,
        deletedElementsInProgress,
      }
    }
    case UPDATE_ADD_FORM_VISIBLE:
      return {
        ...state,
        addFormVisible: action.payload,
      }
    case ADD_DOCUMENT_ELEMENT: {
      const newElement = action.payload

      return {
        ...state,
        document: {
          ...state.document,
          data: {
            ...state.document.data,
            elements: [...state.document.data.elements, newElement],
          },
        },
      }
    }

    case SET_OCR_DATA: {
      const { hints, suggestedProvider, status } = action.payload

      return {
        ...state,
        document: {
          ...state.document,
          data: {
            ...state.document.data,
            ocrHints: hints,
            ocrSuggestedProvider: suggestedProvider,
            ocr_status: status,
          },
        },
      }
    }

    case SET_DOCUMENT_ELEMENTS: {
      const newElements = action.payload.data

      return {
        ...state,
        document: {
          ...state.document,
          elements: newElements,
        },
      }
    }
    default:
      return state
  }
}

// actions
export const reset = () => (dispatch) => {
  dispatch({
    type: RESET_DOCUMENT_ACCOUNT_PAGE,
  })
}

export const startLoading =
  (key, useLoadingStack = false) =>
  (dispatch) => {
    dispatch({
      type: START_LOADING,
      payload: {
        key,
        useLoadingStack,
      },
    })
  }

export const stopLoading = (key) => (dispatch) => {
  return new Promise((resolve) => {
    dispatch({
      type: STOP_LOADING,
      payload: {
        key,
        resolve,
      },
    })
  })
}

export const setDocument = (data) => (dispatch) => {
  dispatch({
    type: SET_DOCUMENT,
    payload: data,
  })
}

export const setDocumentElements = (data) => (dispatch) => {
  dispatch({
    type: SET_DOCUMENT_ELEMENTS,
    payload: data,
  })
}

export const setProviders = (data) => (dispatch) => {
  dispatch({
    type: SET_PROVIDERS,
    payload: data,
  })
}

export const setCurrencies = (data) => (dispatch) => {
  dispatch({
    type: SET_CURRENCIES,
    payload: data,
  })
}

export const setDocumentElementTypes = (data) => (dispatch) => {
  dispatch({
    type: SET_DOCUMENT_ELEMENT_TYPES,
    payload: data,
  })
}

export const setAccountingAccounts = (data) => (dispatch) => {
  dispatch({
    type: SET_ACCOUNTING_ACCOUNTS,
    payload: data,
  })
}

export const setExchangeRate = (data) => (dispatch) => {
  dispatch({
    type: SET_EXCHANGE_RATE,
    payload: data,
  })
}

export const setRequestElements = (data) => (dispatch) => {
  dispatch({
    type: SET_REQUEST_ELEMENTS,
    payload: data,
  })
}

export const fetchDocument = (id) => (dispatch) => {
  dispatch(startLoading('document'))

  return new Promise((resolve) => {
    APIClient.getDocument(id).then((response) => {
      response.data.elements.map((documentElement) => {
        if (
          documentElement.vatNumber === null ||
          typeof documentElement.vatNumber === 'undefined'
        ) {
          documentElement.vatNumber = {
            id: 0,
            name: 'Wybierz',
            code: 0,
            value: 0,
          }
        }
      })

      dispatch(setDocument(response.data))
      resolve(response.data)
    })
  })
}

export const updateDocument = (id, data) => (dispatch) => {
  dispatch(startLoading('document'))

  return new Promise((resolve, reject) => {
    APIClient.saveDocumentAccount(id, data)
      .then((response) => {
        dispatch(setDocument(response['data']))
        resolve(response['data'])
      })
      .catch((response) => {
        reject(response['alerts'])
      })
  })
}

export const saveDocumentElement = (document, element, type, data) => (dispatch) => {
  return new Promise((resolve, reject) => {
    if (element['id']) {
      APIClient.saveDocumentElementTypeAccount(document, element, type, data)
        .then((response) => {
          dispatch(updateElement(response.data))
          dispatch(updateDocumentAmount(response.data))
          resolve(response.data)
        })
        .catch((response) => {
          reject(response['alerts'])
        })
    } else {
      APIClient.addDocumentElementType(document, element, null, data)
        .then((response) => {
          dispatch(updateAddFormVisible(false))
          dispatch(addElement(response.data))
          dispatch(updateDocumentAmount(response.data))
          resolve(response.data)
        })
        .catch((response) => {
          reject(response['alerts'])
        })
    }
  })
}

export const addDocumentElement = (document) => (dispatch, getState) => {
  return new Promise((resolve, reject) => {
    const type = getDocumentElementsTypes(getState())[0]
    APIClient.addDocumentElementType(document, null, type, { type_id: type['id'] })
      .then((response) => {
        dispatch(addElement(response.data))
        resolve()
      })
      .catch((response) => {
        reject(response['alerts'])
      })
  })
}

export const updateElement = (data) => (dispatch) => {
  dispatch({
    type: UPDATE_DOCUMENT_ELEMENT,
    payload: data,
  })
}

export const fetchProviders = () => (dispatch) => {
  dispatch(startLoading('providers'))

  APIClient.getProviders().then((response) => {
    dispatch(setProviders(response.data))
  })
}

export const changeProvider = (id) => (dispatch) => {
  dispatch({
    type: CHANGE_PROVIDER,
    payload: id,
  })
}

export const updateDocumentAmount =
  ({ document }) =>
  (dispatch) => {
    dispatch({
      type: UPDATE_DOCUMENT_AMOUNT,
      payload: document,
    })
  }

export const fetchCurrencies = () => (dispatch) => {
  dispatch(startLoading('currencies'))

  return new Promise((resolve) => {
    APIClient.getCurrencies().then((response) => {
      dispatch(setCurrencies(response.data))
      resolve(response.data)
    })
  })
}

export const fetchDocumentElementTypes = (documentId) => (dispatch) => {
  dispatch(startLoading('documentElementTypes'))

  APIClient.getDocumentElementTypes(documentId).then((response) => {
    dispatch(setDocumentElementTypes(response.data))
  })
}

export const fetchExchangeRates = (requestSlug, currencies) => (dispatch) => {
  dispatch(startLoading('exchangeRate'))

  APIClient.getExchangeRate(requestSlug, currencies).then((response) => {
    dispatch(setExchangeRate(response.data))
  })
}

export const fetchRequestElements = (requestSlug: string) => (dispatch) => {
  dispatch(startLoading('requestElements'))

  APIClient.getRequestElementsForDocument(requestSlug).then((response) => {
    dispatch(setRequestElements(response.data))
  })
}

export const fetchAccountingAccounts = () => (dispatch) => {
  dispatch(startLoading('accountingAccounts'))

  APIClient.getAccountingAccounts().then((response) => {
    dispatch(setAccountingAccounts(response.data))
  })
}

export const removeDocumentElement = (document, element) => (dispatch) => {
  dispatch(updateDeletedElementsInProgress('add', element))
  APIClient.deleteDocumentElementType(document, element).then(() => {
    dispatch(updateDeletedElementsInProgress('remove', element))
    dispatch({
      type: REMOVE_DOCUMENT_ELEMENT,
      payload: element,
    })
  })
}

export const updateAccountingSettlements = (document, values) => (dispatch) => {
  return new Promise((resolve, reject) => {
    APIClient.saveDocumentAccount(document.id, values)
      .then(() => {
        resolve()
      })
      .catch((response) => {
        reject(response['alerts'])
      })
  })
}

export const updateDeletedElementsInProgress = (action, element) => (dispatch) => {
  dispatch({
    type: UPDATE_DELETED_ELEMENT_STATUS,
    payload: {
      element,
      action,
    },
  })
}

export const updateAddFormVisible = (visible) => (dispatch) => {
  dispatch({
    type: UPDATE_ADD_FORM_VISIBLE,
    payload: visible,
  })
}

export const addElement = (element) => (dispatch) => {
  dispatch({
    type: ADD_DOCUMENT_ELEMENT,
    payload: element,
  })
}

export const setOcrData = (data) => (dispatch) => {
  dispatch({
    type: SET_OCR_DATA,
    payload: data,
  })
}

// selectors
export const getState = (state) => {
  return state.get(MOUNT_POINT)
}

export const getDocumentData = (state) => {
  return getState(state).document
}

export const getDocument = (state) => {
  return getDocumentData(state).data
}

export const getProvider = (state) => {
  return getState(state).provider
}

export const getProvidersData = (state) => {
  return getState(state).providers
}

export const getProviders = (state) => {
  return getProvidersData(state).data
}

export const getCurrenciesData = (state) => {
  return getState(state).currencies
}

export const getCurrencies = (state) => {
  return getCurrenciesData(state).data
}

export const getDocumentElementsTypesData = (state) => {
  return getState(state).documentElementTypes
}

export const getDocumentElementsTypes = (state) => {
  return getDocumentElementsTypesData(state).data
}

export const getAccountingAccountsData = (state) => {
  return getState(state).accountingAccounts
}

export const getAccountingAccounts = (state) => {
  return getAccountingAccountsData(state).data
}

export const getRequestElementsData = (state) => {
  return getState(state).requestElements
}

export const getRequestElements = (state) => {
  return getRequestElementsData(state).data
}

export const getAccountingAccountsTypeSettlement = (state) => {
  return getAccountingAccounts(state).filter(({ type }) => type === 'credit')
}

export const isDocumentElementDeletingInProgress = (state, element) => {
  const statuses = getState(state).deletedElementsInProgress
  return statuses.indexOf(element['id']) !== -1
}

export const isAddFormVisible = (state) => {
  return getState(state).addFormVisible
}

export const getDocumentElements = (state) => {
  return getDocument(state).elements
}

export const getDocumentCurrency = (state) => {
  return getDocument(state).currency.code
}

export const getElementsGross = (state) => {
  const elements = getDocumentElements(state)
  if (!elements) {
    return numeral(0)
  }
  return numeral(elements.reduce((value, item) => value + parseFloat(item.gross), 0))
}

export const getExchangeRateData = (state) => {
  return getState(state).exchangeRate
}

export const getExchangeRates = (state) => {
  return getExchangeRateData(state).data
}

export const areElementsLoading = (state) => {
  return getDocumentElementsTypesData(state).isLoading
}
