import PropTypes from 'prop-types'
import React from 'react'
import { Table, THead, TBody, TFoot, Row, Cell, Accordion } from '../ui/AccordionTable'
import _ from 'lodash'
import ReactDOM from 'react-dom'
import jQuery from 'jquery'
import Icon from '../ui/IconComponent'
import { Tooltip } from '../Tooltip'

class AccordionTableRenderer extends React.Component<any, any> {
  constructor(props) {
    super(props)

    this.registeredFitElements = {}
    this.registeredFitContainers = {}

    this.state = {
      fitLimits: {},
    }
  }

  renderCell(
    { content, xs, alignRight, key },
    {
      isOpen,
      registeredFitContainers,
      registerFitContainer,
      registerFitElement,
      initFit,
      fitLimits,
    } = {},
  ) {
    if (!key) console.warn('no cell key', content, xs, alignRight, key)

    const renderContent = (content) => {
      if (_.isFunction(content)) {
        const _content = content({
          isOpen,
          registeredFitContainers,
          registerFitContainer,
          registerFitElement,
        })

        if (_.isPlainObject(_content) && _content['custom']) {
          return {
            content: _content['content'],
            registerFitContainer: _content['registerFitContainer'],
            registerFitElement: _content['registerFitElement'],
            fitElements: _content['fitElements'],
            className: _content['className'],
            fitName: _content['fitName'],
          }
        }

        return {
          content: _content,
        }
      } else {
        return {
          content,
        }
      }
    }

    const {
      registerFitContainer: __registerFitContainer,
      registerFitElement: __registerFitElement,
      fitElements: __fitElements,
      className: __className,
      content: __content,
      fitName: __fitName,
    } = renderContent(content)

    if (_.isFunction(initFit) && __fitName) {
      initFit(__fitName)
    }

    const limit = !_.isEmpty(fitLimits)
      ? fitLimits.find(({ name }) => name === __fitName)
      : undefined
    return (
      <Cell
        key={key}
        xs={xs}
        alignRight={alignRight}
        className={__className}
        ref={(e) => {
          if (_.isFunction(__registerFitContainer)) {
            __registerFitContainer(e)
          }
        }}
      >
        {!_.isEmpty(__content) && __content}
        {!_.isEmpty(__fitElements) &&
          __fitElements.map((component, i) => {
            const displayNone = _.isPlainObject(limit) && limit['limit'] <= i

            return (
              <span
                style={{ display: displayNone ? 'none' : 'inline-block' }}
                ref={(e) => {
                  if (_.isFunction(__registerFitElement)) {
                    __registerFitElement(e)
                  }
                }}
                key={component['key']}
              >
                {component}
              </span>
            )
          })}
        {!_.isEmpty(limit) &&
          !_.isEmpty(__fitElements) &&
          limit['limit'] < __fitElements.length && (
            <Tooltip
              html={
                <span className={__className}>
                  {__fitElements.slice(limit['limit']).map(({ style, key, ..._component }) => (
                    <span style={style} key={key}>
                      {_component}
                    </span>
                  ))}
                </span>
              }
            >
              <Icon type='arrow_more' />
            </Tooltip>
          )}
      </Cell>
    )
  }

  renderRow(
    { cells, thin, className, key },
    {
      isOpen,
      registeredFitContainers,
      registerFitContainer,
      registerFitElement,
      initFit,
      fitLimits,
    } = {},
  ) {
    if (!key) console.warn('no row key', cells, thin, className, key)
    return (
      <Row key={key} thin={thin} className={className}>
        {cells.map((cell) => {
          return this.renderCell(cell, {
            isOpen,
            registeredFitContainers,
            registerFitContainer,
            registerFitElement,
            initFit,
            fitLimits,
          })
        })}
      </Row>
    )
  }

  renderBar(
    { rows, content, key, className },
    {
      noExpand,
      registeredFitContainers,
      registerFitContainer,
      registerFitElement,
      initFit,
      fitLimits,
    } = {},
  ) {
    if (!key) console.warn('no bar key', rows, content, key)

    const renderContent = () => {
      const result = _.isFunction(content) ? content() : content

      if (_.isArray(result)) {
        return result.map((item) => {
          if (item['bar'])
            return this.renderBar(item['bar'], {
              noExpand,
              registeredFitContainers,
              registerFitContainer,
              registerFitElement,
              initFit,
              fitLimits,
            })
          if (item['row'])
            return this.renderBar(item['row'], {
              noExpand,
              registeredFitContainers,
              registerFitContainer,
              registerFitElement,
              initFit,
              fitLimits,
            })
        })
      } else if (_.isPlainObject(result)) {
        if (result['bar'])
          return this.renderBar(result['bar'], {
            noExpand,
            registeredFitContainers,
            registerFitContainer,
            registerFitElement,
            initFit,
            fitLimits,
          })
        if (result['row'])
          return this.renderBar(result['row'], {
            noExpand,
            registeredFitContainers,
            registerFitContainer,
            registerFitElement,
            initFit,
            fitLimits,
          })
      }

      return result
    }

    return (
      <Accordion
        key={key}
        className={!!className ? className : undefined}
        bar={({ isOpen }) => {
          if (_.isArray(rows)) {
            return rows.map((row) =>
              this.renderRow(row, {
                isOpen,
                registeredFitContainers,
                registerFitContainer,
                registerFitElement,
                initFit,
                fitLimits,
              }),
            )
          }
          return null
        }}
      >
        {!noExpand && renderContent()}
      </Accordion>
    )
  }

  renderSection(
    obj,
    {
      noExpand,
      registeredFitContainers,
      registerFitContainer,
      registerFitElement,
      initFit,
      fitLimits,
    } = {},
  ) {
    return obj.map(({ row, bar }) => {
      if (!_.isEmpty(row)) {
        return this.renderRow(row)
      } else if (!_.isEmpty(bar)) {
        return this.renderBar(bar, {
          noExpand,
          registeredFitContainers,
          registerFitContainer,
          registerFitElement,
          initFit,
          fitLimits,
        })
      }
      return null
    })
  }

  componentDidMount() {
    window.addEventListener('resize', this.fitElementsTimeout)

    this.fitElements()
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.fitElementsTimeout)
  }

  fitElements() {
    this.setState(
      {
        fitLimits: {},
      },
      () => {
        const fitLimits = Object.keys(this.registeredFitContainers)
          .map((key) => {
            const container = this.registeredFitContainers[key]
            const elements = this.getRegisteredFitElements(key)

            const containerElement = ReactDOM.findDOMNode(container)
            const containerWidth = Math.floor(jQuery(containerElement).width())

            let elementsWidth = 10
            let elementsNb = 0

            elements.forEach((el) => {
              const elDOM = ReactDOM.findDOMNode(el)
              const elWidth = jQuery(elDOM).outerWidth()

              if (elementsWidth + elWidth < containerWidth) {
                elementsNb++
                elementsWidth += elWidth
              }
            })

            if (elementsNb < elements.length) {
              return {
                limit: elementsNb - 1,
                name: key,
              }
            }

            return null
          })
          .filter((val) => val !== null)

        this.setState({
          fitLimits,
        })
      },
    )
  }

  fitElementsTimeout = () => {
    clearTimeout(this.timeout)
    this.timeout = setTimeout(() => {
      this.fitElements()
    }, 500)
  }

  initFit = (groupName) => {
    delete this.registeredFitContainers[groupName]
    delete this.registeredFitElements[groupName]

    this.registeredFitElements[groupName] = []
  }

  getRegisteredFitElements(groupName) {
    this.registeredFitElements[groupName] = this.registeredFitElements[groupName].filter(
      (v) => v !== null,
    )
    return this.registeredFitElements[groupName]
  }

  registerFitContainer = (groupName) => {
    return (element) => {
      this.registeredFitContainers[groupName] = element
    }
  }

  registerFitElement = (groupName) => {
    return (element) => {
      const el = this.registeredFitElements[groupName].find((added) => added === element)
      if (!el) {
        this.registeredFitElements[groupName].push(element)
      }
    }
  }

  render() {
    const { data, className, noExpand, hideNoResultsLabel } = this.props

    const registerFitElement = this.registerFitElement
    const registerFitContainer = this.registerFitContainer
    const initFit = this.initFit
    const registeredFitContainers = this.registeredFitContainers
    const { fitLimits } = this.state
    return (
      <Table className={className}>
        <THead>{this.renderSection(data['head'])}</THead>
        <TBody hideNoResultsLabel={hideNoResultsLabel}>
          {this.renderSection(data['body'], {
            noExpand,
            registeredFitContainers,
            registerFitContainer,
            registerFitElement,
            initFit,
            fitLimits,
          })}
        </TBody>
        <TFoot>{this.renderSection(data['foot'])}</TFoot>
      </Table>
    )
  }
}

AccordionTableRenderer.propTypes = {
  data: PropTypes.object.isRequired,
  className: PropTypes.string,
  noExpand: PropTypes.bool,
}

export { AccordionTableRenderer }
export default { AccordionTableRenderer }
