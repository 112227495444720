import {
  TRAVEL_PLANE_TRIP,
  TRAVEL_ACCOMODATION,
  TRAVEL_COMPANY_CAR_TRIP,
  TRAVEL_TRAIN_TRIP,
  TRAVEL_RENTED_CAR_TRIP,
  TRAVEL_PRIVATE_CAR_TRIP,
  TRAVEL_PRIVATE_ACCOMODATION,
  TRAVEL_TARGET_POINT,
  TRAVEL_ACCOMODATION_SUGGESTION,
  TRAVEL_EMPTY,
  TRAVEL_FERRY,
  TRAVEL_BUS_TRIP,
  TRAVEL_REPLACEMENT_CAR_TRIP,
  TRAVEL_PASSENGER_CAR_TRIP,
  TRAVEL_ACCOMMODATION_PROVIDED,
} from '../../constants/travel'

import Plane from './Plane'
import PrivateAccommodation from './PrivateAccommodation'
import AccommodationProvided from './AccommodationProvided'
import PrivateCar from './PrivateCar'
import CompanyCar from './CompanyCar'
import RentedCar from './RentedCar'
import Accommodation from './Accommodation'
import Train from './Train'
import TargetPoint from './TargetPoint'
import AccommodationSuggestion from './AccommodationSuggestion'
import Empty from './Empty'
import Ferry from './Ferry'
import Bus from './Bus'
import ReplacementCar from './ReplacementCar'
import PassengerCar from './PassengerCar'

class Factory {
  static create(element) {
    if (!element.type) {
      return null
    }

    switch (element.type) {
      case TRAVEL_PLANE_TRIP:
        return new Plane(element)
      case TRAVEL_ACCOMODATION:
        return new Accommodation(element)
      case TRAVEL_TRAIN_TRIP:
        return new Train(element)
      case TRAVEL_COMPANY_CAR_TRIP:
        return new CompanyCar(element)
      case TRAVEL_RENTED_CAR_TRIP:
        return new RentedCar(element)
      case TRAVEL_PRIVATE_CAR_TRIP:
        return new PrivateCar(element)
      case TRAVEL_REPLACEMENT_CAR_TRIP:
        return new ReplacementCar(element)
      case TRAVEL_PASSENGER_CAR_TRIP:
        return new PassengerCar(element)
      case TRAVEL_PRIVATE_ACCOMODATION:
        return new PrivateAccommodation(element)
      case TRAVEL_ACCOMMODATION_PROVIDED:
        return new AccommodationProvided(element)
      case TRAVEL_TARGET_POINT:
        return new TargetPoint(element)
      case TRAVEL_ACCOMODATION_SUGGESTION:
        return new AccommodationSuggestion(element)
      case TRAVEL_EMPTY:
        return new Empty(element)
      case TRAVEL_FERRY:
        return new Ferry(element)
      case TRAVEL_BUS_TRIP:
        return new Bus(element)
      default:
        throw Error('Invalid model type:' + element.type)
    }
  }
}

export { Factory }
export default Factory
