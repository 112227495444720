import React from 'react'
import _ from 'lodash'
import { RadioField } from './index'
import { Row, Col } from '../../../ui/Grid'
import { renderKey } from '../../../utils/react'

class FormFieldRadioGroup extends React.Component<any, any> {
  _renderItemsBasic() {
    const { input, items } = this.props

    return items.map((item, i) => {
      return (
        <RadioField
          key={`radio-${input.name}-${i}`}
          name={input.name}
          onChange={(value) => {
            input.onChange(value)
          }}
          onBlur={input.onBlur}
          label={item.label}
          value={item.value}
          checked={input.value === item.value}
          disabled={item.disabled}
        />
      )
    })
  }

  _renderItemsGrid() {
    const { input, items } = this.props

    return (
      <div style={{ width: '100%' }}>
        {items['rows'].map((row, i) => (
          <Row key={renderKey('radio-group-row', i)}>
            {row['cols'].map((col, j) => (
              <Col xs={col['xs']} key={renderKey('radio-group-col', i, j)}>
                {col['items'].map((item, k) => (
                  <RadioField
                    key={renderKey('radio-group-radio', input.name, i, j, k)}
                    name={input.name}
                    onChange={(value) => {
                      input.onChange(value)
                    }}
                    onBlur={input.onBlur}
                    label={item.label}
                    value={item.value}
                    checked={input.value === item.value}
                    disabled={item.disabled}
                  />
                ))}
              </Col>
            ))}
          </Row>
        ))}
      </div>
    )
  }

  renderItems() {
    const { items } = this.props

    if (_.isArray(items)) {
      return this._renderItemsBasic()
    }

    return this._renderItemsGrid()
  }

  render() {
    const {
      label,
      meta: { error },
      inline,
      thin,
    } = this.props

    return (
      <div className='form-group form-group--label-top'>
        {label && <span className='form-group__label'>{label}</span>}
        <div
          className={`form-group__input-wrapper ${
            inline ? 'form-group__input-wrapper--inline' : ''
          } ${thin ? 'form-group__input-wrapper--thin' : ''}`}
        >
          {this.renderItems()}
          {error && (
            <div className='form-group__error'>
              <p>{error}</p>
            </div>
          )}
        </div>
      </div>
    )
  }
}

FormFieldRadioGroup.defaultProps = {
  inline: false,
  thin: false,
}

export { FormFieldRadioGroup }
export default { FormFieldRadioGroup }
