// initial state
import APIClient from '../../../services/APIClient'

const getInitialState = () => {
  return {
    avatars: {},
  }
}

export const MOUNT_POINT = 'avatar'

// constants
export const RESET = MOUNT_POINT + '::reset'
export const SET_AVATAR = MOUNT_POINT + '::set-avatar'

// reducer
export const reducer = (state = getInitialState(), action) => {
  switch (action.type) {
    case RESET:
      return getInitialState()
    case SET_AVATAR: {
      const { path, avatar } = action.payload

      return {
        ...state,
        avatars: {
          ...state.avatars,
          [path]: avatar,
        },
      }
    }
    default:
      return state
  }
}

// actions
export const reset = () => (dispatch) => {
  dispatch({
    type: RESET,
  })
}

export const fetchAvatar = (avatarPath, slug) => (dispatch, state) => {
  if (!avatarPath) {
    return Promise.reject()
  }

  if (avatarPath === 'avatar.png') {
    return Promise.resolve('/images/avatar.png')
  }

  const avatar = getAvatarByPath(state(), avatarPath)

  // no need to fetch avatar once again
  if (avatar) {
    return Promise.resolve(avatar)
  }

  return APIClient.getAvatarAsBlob(avatarPath, slug, 'original')
    .then((res) => {
      const avatar = URL.createObjectURL(res.data)

      dispatch({
        type: SET_AVATAR,
        payload: {
          avatar,
          path: avatarPath,
        },
      })

      return avatar
    })
    .catch(() => {
      //console.log('ignored')
    })
}

// selectors
export const getState = (state) => {
  return state.get(MOUNT_POINT)
}

export const getAvatars = (state) => {
  return getState(state).avatars
}

export const getAvatarByPath = (state, path) => {
  const { avatars } = getState(state)

  if (avatars.hasOwnProperty(path)) {
    return avatars[path]
  }

  return null
}
