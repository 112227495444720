import React, { Component, Fragment } from 'react'
import { compose } from 'redux'
import { trainsBooking } from '../../store/app/trains-booking/providers/trainsBooking'
import { Loader } from '../ui/LoadingOverlay/Loader'
import Button from '../ui/ButtonComponent'
import trans from '../../trans'
import OffersDialog from './OffersDialog'
import SelectedOffer from './SelectedOffer'
import TravelTimeWarning from './TravelTimeWarning'
import moment from 'moment'
import { get } from 'lodash'

class TrainsBooking extends Component<any, any> {
  search = (e) => {
    const {
      trainsBooking: {
        request,
        element,
        actions: { search },
      },
    } = this.props
    e.preventDefault()

    const query = {
      departure_at: element.departure_at,
      destination_location: element.destination_location,
      departure_location: element.departure_location,
      request_travelers: element.request_travelers,
    }

    search(request, query)
  }

  selectOffer = (offer) => {
    const {
      trainsBooking: {
        actions: { selectOffer },
        selectors: { uuid },
        request,
        element,
      },
    } = this.props
    selectOffer(request, {
      search_uuid: uuid,
      offer,
      option: offer.option,
      request_element: element,
    })
  }

  resignFromSearching = (e) => {
    e.preventDefault()
    const { trainsBooking } = this.props
    trainsBooking.actions.resignFromSearching()
  }

  searchAnotherOne = () => {
    const {
      trainsBooking: {
        selectors: { uuid },
        actions: { searchAnotherOne },
      },
    } = this.props
    searchAnotherOne({ search_uuid: uuid })
  }

  render() {
    const { trainsBooking } = this.props
    const { element, errors } = trainsBooking

    if (!trainsBooking.selectors.isSearchEnabled) {
      return null
    }

    if (!trainsBooking.selectors.initialized) {
      return <Loader />
    }

    const travelTime = get(trainsBooking, 'selectors.query.departure_at', null)
    const isTravelTimeWarning = moment(travelTime).diff(moment(), 'days') >= 30

    return (
      <Fragment>
        {trainsBooking.selectors.isLoading && <Loader />}

        <div className='train-trip__departures-list-wrapper has-loader'>
          {trainsBooking.selectors.selectedOffer ? (
            <SelectedOffer
              offer={trainsBooking.selectors.selectedOffer}
              searchAnotherOne={this.searchAnotherOne}
              query={trainsBooking.selectors.query}
              element={element}
              trainsBooking={trainsBooking}
              errors={errors}
            />
          ) : (
            <Fragment>
              <div className='train-trip__inputs train-trip__inputs--filters train-trip__inputs--buttons'>
                <Button
                  secondary
                  outline
                  className='train-trip__resign-button'
                  onClick={this.resignFromSearching}
                >
                  {trans('hotels-booking.resign-from-searching')}
                </Button>
                <Button
                  primary
                  className='train-trip__search-button'
                  onClick={this.search}
                  disabled={isTravelTimeWarning}
                >
                  {trans('trains-booking.search')}
                </Button>
              </div>

              {isTravelTimeWarning && <TravelTimeWarning />}
            </Fragment>
          )}
        </div>

        <OffersDialog trainsBooking={trainsBooking} />

        {/*<SelectedOfferReservation/>*/}
      </Fragment>
    )
  }
}

const withTrainsBooking = trainsBooking(true)

TrainsBooking = compose(withTrainsBooking)(TrainsBooking)

export { TrainsBooking }
export default TrainsBooking
