import { createSelector, createSlice } from '@reduxjs/toolkit'
import { isEmpty } from 'lodash'
import APIClient from '../../../services/APIClient'
import { PER_PAGE } from '../../../constants/pagination'

export const IMPORTS_MOUNT_POINT = 'imports'

/**
 * In @reduxjs/toolkit we can mutate state in reducers,
 * because behind the scene it uses ImmerJS for applying them as non-mutate
 *
 * https://github.com/immerjs/immer
 */
const slice = createSlice({
  name: IMPORTS_MOUNT_POINT,
  initialState: {
    items: [],
    error: null,
    isLoading: false,
    isLoaded: false,
    page: 1,
    adding: false,
  },
  reducers: {
    setImports(state, action) {
      const { data } = action.payload

      state.items = data
    },
    setImport(state, action) {
      const { data } = action.payload
      const index = state.items.findIndex((item) => item.slug === data.slug)

      if (index === -1) {
        state.items.unshift(data)
      } else {
        state.items[index] = data
      }
    },
    setImportStatus(state, action) {
      const { data } = action.payload
      const index = state.items.findIndex((item) => item.slug === data.slug)

      if (index !== -1) {
        const newState = state.items[index]
        newState.status = data.status
        state.items[index] = newState
      }
    },
    setPage(state, action) {
      state.page = action.payload
    },
    startLoading(state) {
      state.isLoading = true
      state.isLoaded = false
      state.error = null
    },
    setLoadingSuccess(state) {
      state.isLoading = false
      state.isLoaded = true
      state.error = null
    },
    setLoadingFailed(state, action) {
      state.isLoading = false
      state.isLoaded = false
      state.error = action.payload
    },
    setAdding(state, action) {
      state.adding = action.payload
    },
  },
})

export const {
  setImport,
  setImportStatus,
  setImports,
  setPage,
  startLoading,
  setLoadingSuccess,
  setLoadingFailed,
  setAdding,
} = slice.actions

export default slice.reducer

// selectors
const getState = (state) => state.get(IMPORTS_MOUNT_POINT)
export const getPage = (state) => getState(state).page
export const getImports = (state) => getState(state).items
export const isLoading = (state) => getState(state).isLoading
export const isAdding = (state) => getState(state).adding
export const isLoaded = (state) => getState(state).isLoaded
export const getPaginatedImports = createSelector(getImports, getPage, (items, page) =>
  items.slice(page * PER_PAGE - PER_PAGE, page * PER_PAGE),
)
export const getImportsTotalCount = createSelector(getImports, (items) => items.length)
export const getImportById = (state, id) => {
  const items = getImports(state)

  return items.find((item) => item.id === id)
}

// action thunks
export const fetchImports = () => async (dispatch) => {
  dispatch(startLoading())

  try {
    const { data } = await APIClient.getImports()

    dispatch(setImports({ data }))
    dispatch(setLoadingSuccess())
  } catch (err) {
    dispatch(setLoadingFailed(err))
  }
}

export const fetchImportsIfNeeded = () => async (dispatch, state) => {
  const { items, isLoading } = getState(state())

  if (!isEmpty(items) || isLoading) {
    return
  }

  return dispatch(fetchImports())
}

export const createImport = (item) => (dispatch) => {
  return APIClient.createImport(item).then((response) => {
    const { data } = response
    dispatch(setImport({ data }))
  })
}
