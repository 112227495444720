import React from 'react'
import { isFunction } from 'lodash'

export default (propsMapper) => (Component) => {
  class PropsBridge extends React.Component<any, any> {
    mapIncomePropsToValues = () => {
      if (!isFunction(propsMapper)) {
        return {}
      }

      if (typeof propsMapper(this.props) !== 'object') {
        return {}
      }

      return propsMapper(this.props)
    }

    render() {
      const mappedProps = this.mapIncomePropsToValues()
      return <Component<any, any> {...this.props} {...mappedProps} />
    }
  }

  return PropsBridge
}
