import { useCallback, useEffect, useState } from 'react'
import APIClient from '../../../services/APIClient'
import { Paginator } from '../../../types/response'
import { Statement } from '../../../types/statements'
import { useSelector } from 'react-redux'
import { getUserAssistantOrCurrentUserLink } from '../../../store/app/user-profile/userProfileSlice'

export default function useStatements() {
  const [data, setData] = useState<Statement[]>([])
  const [paginator, setPaginator] = useState<Partial<Paginator>>({})
  const [loading, setLoading] = useState<boolean>(true)
  const link = useSelector(getUserAssistantOrCurrentUserLink('statements'))

  const setPage = useCallback(
    (page: number) => {
      if (paginator.page !== page) {
        loadItems(page)
      }
    },
    [paginator.page, loading],
  )

  const loadItems = async (page: number = 1) => {
    setLoading(true)

    return APIClient.getMyCardStatements(link, { page, per_page: 20 })
      .then((response) => {
        setData(response.data)
        setPaginator(response.paginator)
        setLoading(false)
      })
      .catch(() => {
        setLoading(false)
        setPaginator({ page: 1 })
      })
  }

  useEffect(() => {
    loadItems()
  }, [])

  return { data, paginator, loading, setPage }
}
