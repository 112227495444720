import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { AutoSizer, List as VirtualizedList } from 'react-virtualized'
import Offer from './Offer'
import { get } from 'lodash'
import { Scrollbars } from 'react-custom-scrollbars-2'

class List extends Component<any, any> {
  constructor(props) {
    super(props)

    this.state = {
      listHeight: 500,
      listRowHeight: 50,
      overscanRowCount: 10,
      rowCount: get(this.props, 'hotelsBooking.selectors.offers.length', 0),
      showScrollingPlaceholder: false,
      useDynamicRowHeight: false,
    }

    this.listRef = React.createRef()
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    return {
      ...prevState,
      rowCount: get(nextProps, 'hotelsBooking.selectors.offers.length', 0),
    }
  }

  componentDidUpdate = (prevProps, prevState) => {
    const {
      hotelsBooking: {
        selectors: { lastComputed: currentComputed },
      },
    } = this.props
    const {
      hotelsBooking: {
        selectors: { lastComputed },
      },
    } = prevProps

    if (currentComputed !== lastComputed && lastComputed !== null) {
      this.listRef.current.forceUpdateGrid()
    }
  }

  noRowsRenderer() {
    return <div>No rows</div>
  }

  getOffer = (index) => {
    const {
      hotelsBooking: {
        selectors: { offers },
      },
    } = this.props
    return offers[index]
  }

  renderOffer = ({ index, isScrolling, key, style }) => {
    const {
      hotelsBooking: {
        actions: { selectOffer },
        selectors: { getFilter },
      },
    } = this.props

    const offer = this.getOffer(index)

    return (
      <Offer
        key={key}
        style={style}
        offer={offer}
        selectOffer={() => {
          selectOffer(offer.uuid)
        }}
        getFilter={getFilter}
      />
    )
  }

  handleScroll = ({ target }) => {
    const { scrollTop, scrollLeft } = target
    const {
      current: { Grid: grid },
    } = this.listRef
    grid.handleScrollEvent({ scrollTop, scrollLeft })
  }

  render() {
    const { rowCount, scrollToIndex } = this.state

    return (
      <AutoSizer>
        {({ height, width }) => (
          <Scrollbars style={{ width, height }} onScroll={this.handleScroll}>
            <VirtualizedList
              ref={this.listRef}
              className={'hotels-dialog__hotels-list'}
              height={height}
              noRowsRenderer={this.noRowsRenderer}
              rowCount={rowCount}
              rowHeight={180}
              rowRenderer={this.renderOffer}
              scrollToIndex={scrollToIndex}
              width={width}
              style={{
                overflowX: false,
                overflowY: false,
              }}
            />
          </Scrollbars>
        )}
      </AutoSizer>
    )
  }
}

List.propTypes = {
  hotelsBooking: PropTypes.object.isRequired,
}

export default List
export { List }
