import React from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'

export const Input = ({
  className,
  children,
  max_char,
  type,
  align_right,
  onChange,
  inputRef = React.createRef(),
  ...props
}) => {
  const validateMaxChar = (value) => {
    if (max_char && value.length > max_char) {
      return false
    }

    return true
  }

  const handleChange = (e) => {
    if (!validateMaxChar(e.currentTarget.value)) {
      return false
    }
    if (onChange) {
      onChange(e)
    }
  }

  const InputClasses = classNames(className, {
    'form-group__input-wrapper': true,
    'form-group__input-wrapper--align-right': align_right,
  })

  return (
    <div className={InputClasses}>
      <input
        type={type}
        value=''
        onChange={handleChange}
        className='input input--text'
        ref={inputRef}
        {...props}
      />
      {children}
    </div>
  )
}

Input.propTypes = {
  className: PropTypes.string,
  type: PropTypes.string.isRequired,
  max_char: PropTypes.number,
  align_right: PropTypes.bool,
}
