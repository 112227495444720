import PropTypes from 'prop-types'
import React from 'react'
import { Scrollbars } from 'react-custom-scrollbars-2'
import { Row } from '../../../ui/Grid/index'
import { UploadCell } from '../../DocumentCell/index'
import trans from '../../../trans'
import { RequestDocumentsManager } from '../../../containers/RequestDocumentsManager'
import { MileageAllowanceCell } from '../../MileageAllowanceCell/index'
import { AssignableCell } from './AssignableCell'
import {
  TRAVEL_ACCOMMODATION_PROVIDED,
  TRAVEL_ACCOMODATION,
  TRAVEL_PRIVATE_ACCOMODATION,
  TRAVEL_PRIVATE_CAR_TRIP,
  TRAVEL_TARGET_POINT,
} from '../../../constants/travel'
import { TYPE_TRIP } from '../../../constants/request'

export const DOCUMENT_CELL_WIDTH = 220

class DashboardRequestsDocuments extends React.Component<any, any> {
  getDocumentAssignOptions() {
    const { request } = this.props
    const elements = request.combinedTravelElements.filter(
      (element) =>
        element.type !== TRAVEL_TARGET_POINT &&
        element.type !== TRAVEL_PRIVATE_CAR_TRIP &&
        element.type !== TRAVEL_PRIVATE_ACCOMODATION &&
        element.type !== TRAVEL_ACCOMODATION,
    )

    const elementsAndCosts = elements.concat(request.costs, request.unrequestedElement)

    return elementsAndCosts.map((item) => {
      return {
        label: item.name,
        value: {
          id: item.id,
          type: item.type,
        },
      }
    })
  }

  render() {
    const {
      documents = [],
      push,
      getRouteByName,
      instanceCurrency,
      request,
      currentUser,
    } = this.props
    const width = `${Math.min((documents.length + 1) * DOCUMENT_CELL_WIDTH, 650)}px`

    return (
      <div className='dashboard-requests-documents__container-outer'>
        <div>{trans('global.documents')}</div>
        <RequestDocumentsManager
          documents={documents}
          request={request}
          listName={'request-' + request.slug + '-documents'}
        >
          {({ documentsList, request, uploadDocument, deleteDocument }) => {
            return (
              <div>
                <div className='dashboard-requests-documents__container'>
                  <Scrollbars style={{ height: '291px' }} ref={(e) => (this.scrollbars = e)}>
                    <Row style={{ flexWrap: 'nowrap' }}>
                      {request.type === TYPE_TRIP && request.abilities.settle && (
                        <MileageAllowanceCell
                          width='220px'
                          request={request}
                          push={push}
                          getRouteByName={getRouteByName}
                          absoluteLink
                          isDelegation={request.delegation}
                        />
                      )}

                      {documentsList.map((document) => {
                        return (
                          <AssignableCell
                            key={`document-${document['id']}`}
                            document={document}
                            push={push}
                            getRouteByName={getRouteByName}
                            instanceCurrency={instanceCurrency}
                            width='220px'
                            request={request}
                            currentUser={currentUser}
                            absoluteLink
                            deleteDocument={deleteDocument}
                            options={this.getDocumentAssignOptions()}
                          />
                        )
                      })}
                    </Row>
                  </Scrollbars>
                </div>
                <div className='dashboard-requests-documents__upload-container'>
                  <div style={{ width: '190px', height: '255px', position: 'relative' }}>
                    <UploadCell
                      className='upload-cell--dashboard'
                      uploadDocument={(...args) => {
                        this.scrollbars.scrollToRight()
                        uploadDocument(...args).then(() => {
                          this.scrollbars.scrollToRight()
                        })
                      }}
                      request={request}
                    />
                  </div>
                </div>
              </div>
            )
          }}
        </RequestDocumentsManager>
      </div>
    )
  }
}

DashboardRequestsDocuments.propTypes = {
  documents: PropTypes.array.isRequired,
  push: PropTypes.func.isRequired,
  getRouteByName: PropTypes.func.isRequired,
  instanceCurrency: PropTypes.string.isRequired,
  uploadDocument: PropTypes.func.isRequired,
  request: PropTypes.object.isRequired,
  currentUser: PropTypes.object.isRequired,
}

export { DashboardRequestsDocuments }
export default { DashboardRequestsDocuments }
