import Button from '../../ui/ButtonComponent'
import { trans } from '../../../trans'
import React from 'react'
import { connect } from '../../../containers/FlightsBooking'

const ActionButtons = connect((props) => {
  const { flights } = props

  const resignFromSearching = (event) => {
    event.preventDefault()
    flights.actions.resignFromSearching()
  }

  const searchPlane = (event) => {
    event.preventDefault()

    flights.actions.search(props.query)
  }

  if (!flights.selectors.isSearchEnabled) {
    return (
      <Button primary pull_end xs type='submit'>
        {trans('ui.save-trip-element')}
      </Button>
    )
  }

  return (
    <div className='train-trip__inputs train-trip__inputs--filters train-trip__inputs--buttons'>
      <Button secondary outline className='train-trip__resign-button' onClick={resignFromSearching}>
        {trans('flights-booking.resign-from-searching')}
      </Button>
      <Button primary className='train-trip__search-button' onClick={searchPlane}>
        {trans('flights-booking.search')}
      </Button>
    </div>
  )
})

export default ActionButtons
export { ActionButtons }
