import { Cell, Row } from '../../../ui/AccordionTable'
import Button from '../../../ui/ButtonComponent'
import trans from '../../../../trans'
import React from 'react'
import { Loader } from '../../../ui/LoadingOverlay/Loader'
import Icon from '../../../ui/IconComponent'
import SocketProviderService from '../../../../utils/SocketProvider'
import { bindActionCreators } from 'redux'
import { setImport } from '../../../../store/app/imports'
import { connect } from 'react-redux'
import APIClient from '../../../../services/APIClient'
import { round } from '../../../../utils/number'
import { fetchMpk } from '../../../../store/app/mpks'

class ImportListItem extends React.Component<any, any> {
  constructor(props) {
    super(props)

    this.state = {
      channelName: `App.Import.${this.props.slug}`,
      eventName: '.Modules\\IntegrationFILE\\Priv\\Events\\ImportUpdatedEvent',
    }
  }

  componentDidMount() {
    SocketProviderService.subscribeOutOfSequence(this.state.channelName)(this.state.eventName)(
      (data) => {
        this.props.setImport({ data })

        if (data.status === 'status' && data.type === 'mpks') {
          this.props.fetchMpk()
        }
      },
    )
  }

  componentWillUnmount() {
    SocketProviderService.unsubscribe(this.state.channelName)
  }

  render() {
    const { startDate, endDate, status, type, company, user, slug, progress } = this.props

    return (
      <Row>
        <Cell fixedWidth={165}>{startDate ? startDate : '---'}</Cell>
        <Cell fixedWidth={165}>{endDate ? endDate : '---'}</Cell>
        <Cell fixedWidth='auto'>{trans(`import.type-${type}`)}</Cell>
        <Cell fixedWidth={150}>{company ? company.name : trans('global.all')}</Cell>
        <Cell fixedWidth={150}>{user.fullName}</Cell>
        <Cell fixedWidth={150}>{trans(`import.status-${status}`)}</Cell>
        <Cell fixedWidth={160} alignCenter>
          {status === 'error' && (
            <Button
              primary
              xs
              className={'btn--tiny btn--inline'}
              onClick={() => {
                APIClient.getImportErrorsReport(slug)
              }}
            >
              {trans('import.error-report-label')}
            </Button>
          )}
          {status === 'pending' && '---'}
          {status === 'processing' && (
            <div className='has-loader'>
              {round(progress, 0)}%<Loader />
            </div>
          )}
          {status === 'success' && <Icon type='checked_fill' lg gradient />}
        </Cell>
      </Row>
    )
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      setImport,
      fetchMpk,
    },
    dispatch,
  )
}

export default connect(null, mapDispatchToProps)(ImportListItem)
