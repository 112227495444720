import PropTypes from 'prop-types'
import React, { Fragment } from 'react'
import { Col, Row } from '../../ui/Grid'
import { Section } from '../ui/Section'
import { SectionHeader } from '../ui/SectionHeader'
import { DocumentCell } from '../DocumentCell'
import { MileageAllowanceCell } from '../MileageAllowanceCell'
import _ from 'lodash'
import { UserProfile } from '../UserProfile'
import { RequestActionManager } from '../../containers/RequestActionManager'
import { RequestAction } from '../RequestAction'
import { Radio } from '../../ui'
import { STATE_SETTLEMENT, TYPE_TRIP } from '../../constants/request'
import { Ability } from '../RequestPageCommon/Ability/Ability'
import { CommentsManager } from '../../containers/CommentsManager'
import Comments from '../Comments'
import trans from '../../trans'
import { REQUEST_DECISIONS } from '../../store/app/request-action'
import { BasicInfoManager } from '../../containers/BasicInfoManager'
import { LoadingOverlay } from '../ui/LoadingOverlay'
import { BasicInfoForm } from '../../containers/BasicInfoForm'
import { ExpenseRequestSummary } from '../ExpenseRequestPage/ExpenseRequestSummary'
import { RequestAccountingSummary } from '../RequestAccountingSummary'
import TruncateBreakable from '../TruncateBreakable/TruncateBreakable'
import { ExpenseRequestActions } from './ExpenseRequestActions'
import { DatePickerField } from '../ui/Form'
import PrintRequestDocuments from '../PrintRequestDocuments'
import { AccountDimensionManager } from '../../containers/AccountDimensionManager'
import BasicRequestInfoRenderer from '../RequestBasicInfoRenderer/RequestBasicInfoRenderer'
import { noop } from 'lodash/util'

class ExpenseRequestAccountingPage extends React.Component<any, any> {
  renderDocuments(documentTypes: string[], readOnly = false) {
    const { expenseRequest, push, getRouteByName, instanceCurrency, currentUser } = this.props
    const { documents } = expenseRequest

    if (_.isEmpty(documents)) {
      return null
    }

    return documents
      .filter(({ type }) => documentTypes.includes(type))
      .map((document) => {
        return (
          <DocumentCell
            width='217px'
            document={document}
            key={`document-cell-${document['id']}`}
            push={push}
            getRouteByName={getRouteByName}
            instanceCurrency={instanceCurrency}
            request={expenseRequest}
            currentUser={currentUser}
            readOnly={readOnly}
          />
        )
      })
  }

  renderAccountingDocuments() {
    return this.renderDocuments(['accounting'])
  }

  renderOtherDocuments() {
    return this.renderDocuments(['travel', 'confirmation'], true)
  }

  renderMileageAllowance() {
    const { expenseRequest, push, getRouteByName } = this.props

    if (expenseRequest.type !== TYPE_TRIP) {
      return null
    }

    if (!expenseRequest.abilities.showTripRequestLumpSum) {
      return null
    }

    return (
      <MileageAllowanceCell
        width='217px'
        request={expenseRequest}
        push={push}
        getRouteByName={getRouteByName}
      />
    )
  }

  renderComments() {
    const {
      expenseRequest: { comments },
    } = this.props

    if (_.isEmpty(comments)) {
      return null
    }

    return (
      <CommentsManager comments={comments}>
        {({ comments }) => <Comments comments={comments} noPadding />}
      </CommentsManager>
    )
  }

  renderRequestAction() {
    const { expenseRequest } = this.props

    return (
      <RequestActionManager request={expenseRequest}>
        {({ comment, status, onChange, onAction, decision, completion_date }) => (
          <RequestAction
            comment={comment}
            status={status}
            onChange={onChange}
            onAction={onAction}
            decision={decision}
            full
          >
            <Row>
              <Col xs={12} is_pull_end>
                <ExpenseRequestActions>
                  <Ability ability={['cancelAssignmentToAccountant']}>
                    <div className='react-select__option'>
                      <Radio
                        name='status'
                        label={trans('request.cancel-assignment-to-accountant')}
                        onClick={() => {
                          onChange('decision', REQUEST_DECISIONS.CANCEL_ASSIGNMENT_TO_ACCOUNTANT)
                        }}
                        checked={decision === REQUEST_DECISIONS.CANCEL_ASSIGNMENT_TO_ACCOUNTANT}
                      />
                    </div>
                  </Ability>

                  <div className='react-select__option'>
                    <Radio
                      name='status'
                      label={trans('global.print-accounting-documents')}
                      onClick={() => {
                        onChange('decision', REQUEST_DECISIONS.PRINT)
                      }}
                      checked={decision === REQUEST_DECISIONS.PRINT}
                    />
                  </div>

                  <Ability ability={['sendToERP']}>
                    <div className='react-select__option'>
                      <Radio
                        name='status'
                        label={trans('global.send-to-erp')}
                        checked={decision === REQUEST_DECISIONS.SEND_TO_ERP_WITH_DATE}
                        onClick={() => {
                          onChange('decision', REQUEST_DECISIONS.SEND_TO_ERP_WITH_DATE)
                        }}
                      />

                      <DatePickerField
                        usePortal
                        maxDate={new Date()}
                        onChange={(value) => {
                          onChange('completion_date', value)
                          onChange('decision', REQUEST_DECISIONS.SEND_TO_ERP_WITH_DATE)
                        }}
                        value={completion_date}
                      />
                    </div>
                  </Ability>
                </ExpenseRequestActions>

                <Ability ability={['sendToERP']}>
                  <Radio
                    name='status'
                    label={trans('global.send-to-erp')}
                    onClick={() => {
                      onChange('decision', REQUEST_DECISIONS.SEND_TO_ERP)
                    }}
                    checked={decision === REQUEST_DECISIONS.SEND_TO_ERP}
                  />
                </Ability>

                <Ability ability={['returnToSettlementImprovement']}>
                  <Radio
                    name='status'
                    label={trans('request.return-to-improvement')}
                    onClick={() =>
                      onChange('decision', REQUEST_DECISIONS.ACCOUNTANT_RETURN_TO_IMPROVEMENT)
                    }
                    checked={decision === REQUEST_DECISIONS.ACCOUNTANT_RETURN_TO_IMPROVEMENT}
                  />
                </Ability>
                <Ability ability={['returnToDecreeWhenTransferError']}>
                  <Radio
                    name='status'
                    label={trans('request.return-to-decree')}
                    onClick={() =>
                      onChange('decision', REQUEST_DECISIONS.ACCOUNTANT_RETURN_TO_DECREE)
                    }
                    checked={decision === REQUEST_DECISIONS.ACCOUNTANT_RETURN_TO_DECREE}
                  />
                </Ability>
              </Col>
            </Row>
          </RequestAction>
        )}
      </RequestActionManager>
    )
  }

  renderRequestSummary() {
    const { expenseRequest: request } = this.props
    const { accountingBalance = {}, documents = [] } = request

    return (
      <Fragment>
        <ExpenseRequestSummary request={request} />
        <section className='section section--no-border section--expense'>
          <RequestAccountingSummary
            changeRequestValue={this.props.changeRequestValue}
            request={request}
            accountingBalance={accountingBalance}
            documents={documents}
          />
        </section>
      </Fragment>
    )
  }

  render() {
    const {
      expenseRequest: { name, user, state, uid, company },
      isLoaded,
      isModuleActive,
      currentUser,
    } = this.props

    if (!isLoaded) return null

    let caption = trans('request-summary.request-summary')
    if (state === STATE_SETTLEMENT) {
      caption = trans('request-summary.settlement-summary')
    }

    return (
      <div className='expense-accounting'>
        <Section>
          <Row>
            <Col xs={8} lg={9}>
              <SectionHeader caption={caption} noMargin>
                {name && (
                  <span className='section__header-subtitle'>
                    <TruncateBreakable text={name} lines={2} />
                  </span>
                )}
                <span className='section__header-subtitle'>
                  {trans('request.id-label')}: {uid}
                </span>
              </SectionHeader>
            </Col>
            <Col xs={4} lg={3}>
              <SectionHeader
                caption={trans('global.employee-info')}
                noMargin
                className='user-header__title'
              />
              <UserProfile user={user} company={company} />
            </Col>
          </Row>
        </Section>

        <BasicRequestInfoRenderer />

        <Row>
          <Col xs={12}>
            <Section>
              <SectionHeader caption={trans('document.accounting-documents')} />

              <Row>
                {this.renderMileageAllowance()}
                {this.renderAccountingDocuments()}
              </Row>
            </Section>
          </Col>
        </Row>

        <Row>
          <Col xs={12}>
            <Section>
              <SectionHeader caption={trans('document.other-documents')} />

              <Row>{this.renderOtherDocuments()}</Row>
            </Section>
          </Col>
        </Row>

        <Row>
          <Col xs={12}>{this.renderRequestSummary()}</Col>
        </Row>
        <Row>
          <Col sm={12}>
            {currentUser.abilities.isAccountant ? (
              <div className='is-margin-top-small'>{this.renderRequestAction()}</div>
            ) : (
              <div className='request-button-group is-margin-top-small'>
                <PrintRequestDocuments request={this.props.expenseRequest} />
              </div>
            )}
          </Col>
        </Row>
        <Row>
          <Col sm={1} />
          <Col sm={11}>
            <Section noBorder>{this.renderComments()}</Section>
          </Col>
        </Row>
      </div>
    )
  }
}

ExpenseRequestAccountingPage.propTypes = {
  expenseRequest: PropTypes.object.isRequired,
  isLoaded: PropTypes.bool.isRequired,
  push: PropTypes.func.isRequired,
  getRouteByName: PropTypes.func.isRequired,
  currentUser: PropTypes.object.isRequired,
  isTrip: PropTypes.bool.isRequired,
  isModuleActive: PropTypes.func,
}

export { ExpenseRequestAccountingPage }
export default { ExpenseRequestAccountingPage }
