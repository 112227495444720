import { bindActionCreators, compose } from 'redux'
import { connect as withHierarchy } from '../../containers/ExternalCompanyHierarchy'
import { fetch, reset, set } from '../../store/app/external-company-hierarchy/creators'
import { connect } from 'react-redux'
import {
  fetchUserProfile,
  makeGetUserProfileBySlugSelector,
} from '../../store/app/user-profile/userProfileSlice'
import { getRouteByName } from '../../routes'
import { get } from 'lodash'
import trans from '../../trans'
import Employee from './Employee'
import { Link } from 'react-router-dom'
import React from 'react'
import { LoadingOverlay } from '../ui/LoadingOverlay'

class ExternalMeInCompanyComponent extends React.Component<any, any> {
  componentDidMount() {
    let slug =
      this.props.subjectUser && this.props.subjectUser.slug ? this.props.subjectUser.slug : null
    const { reset, fetch, fetchUserProfile } = this.props

    reset()
    fetch(slug)
    fetchUserProfile(slug)
  }

  componentWillUnmount() {
    const { reset } = this.props
    reset()
  }
  render() {
    let {
      hierarchy: {
        selectors: { me, superior, isInitialized, isFetching },
      },
    } = this.props
    let { userProfile } = this.props
    if (!isInitialized) {
      return null
    }

    let link = getRouteByName('main', 'settings') + '/company'

    if (!userProfile && this.props.user) {
      userProfile = this.props.user
      me = _.find(this.props.hierarchy.selectors.employees, (o) => o.id === userProfile.id)
    }

    if (this.props.link) {
      link = this.props.link
    }

    const mySubordinates = get(me, 'subordinates', [])

    return (
      <div>
        <header className='section__header settings-page__aside-header'>
          <h2 className='h2 section__header-title settings-page__aside-heading'>
            {trans('settings-page.my-role')}
          </h2>
          <span>
            Mpk {get(userProfile, 'mpk.code', '-')} ({get(userProfile, 'mpk.name', '-')})
          </span>
        </header>

        <div className='settings-page__hierarchy'>
          <div style={{ position: 'relative' }}>
            {isFetching && <LoadingOverlay />}

            {superior && (
              <div className='settings-page__hierarchy-row'>
                <Employee employee={superior} />
              </div>
            )}

            {me && (
              <div className='settings-page__hierarchy-row'>
                <Employee employee={me} large />
              </div>
            )}

            {!!mySubordinates.length && (
              <div className='settings-page__hierarchy-row'>
                <ul className='settings-page__hierarchy-list'>
                  {mySubordinates.map((employee) => {
                    return (
                      <li key={employee.name}>
                        <Employee employee={employee} />
                      </li>
                    )
                  })}
                </ul>
              </div>
            )}
          </div>
        </div>

        <div className='settings-page__hierarchy-more'>
          <Link to={link}>{trans('settings-page.my-role-more')}</Link>
        </div>
      </div>
    )
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      reset,
      set,
      fetch,
      fetchUserProfile,
    },
    dispatch,
  )
}

const mapStateToProps = (state, ownProps) => {
  return {
    userProfile: makeGetUserProfileBySlugSelector()(state, ownProps.subjectUser.slug),
  }
}

const withConnect = connect(mapStateToProps, mapDispatchToProps)

const ExternalMeInCompany = compose(withConnect, withHierarchy)(ExternalMeInCompanyComponent)

export default ExternalMeInCompany
export { ExternalMeInCompany }
