import React from 'react'
import PropTypes from 'prop-types'
import { connect } from '../../containers/FlightsBooking'
import { get } from 'lodash'
import FlightServiceMultiSelect from '../ui/Form/FlightServiceMultiSelect'
import FlightServiceSelect from '../ui/Form/FlightServiceSelect'

const ServiceSelect = connect((props) => {
  const { offer, name, placeholder, pax } = props
  const {
    flights: {
      actions: { setPaxRequestedAttribute },
      selectors: { getPaxAvailableAttribute, getPaxRequestedAttribute },
    },
  } = props

  const available = getPaxAvailableAttribute(pax.slug, offer, name)
  const requested = getPaxRequestedAttribute(pax.slug, offer, name)
  const selectionType = get(available, 'selectionType', 'radio')
  const items = get(available, 'items', []).map((item) => ({
    label: item.description,
    value: item.id,
    amount: item.amount.formatted,
  }))

  if (!items.length) {
    return '-'
  }

  if (selectionType === 'radio') {
    return (
      <FlightServiceSelect
        options={items}
        value={get(requested, '0', null)}
        placeholder={placeholder}
        onChange={(value) => setPaxRequestedAttribute(pax.slug, offer, name, [value])}
      />
    )
  } else {
    return (
      <FlightServiceMultiSelect
        name={name}
        options={items}
        value={requested}
        placeholder={placeholder}
        onChange={(value) => setPaxRequestedAttribute(pax.slug, offer, name, value)}
      />
    )
  }
})

ServiceSelect.propTypes = {
  offer: PropTypes.object.isRequired,
  placeholder: PropTypes.string.isRequired,
}

export default ServiceSelect
export { ServiceSelect }
