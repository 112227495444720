import { createSelector, createSlice } from '@reduxjs/toolkit'
import { isEmpty } from 'lodash'
import { toArray } from '../../../utils/array'
import APIClient from '../../../services/APIClient'
import trans from '../../../trans'
import { PER_PAGE } from '../../../constants/pagination'

export const ACCOUNTING_ACCOUNT_MOUNT_POINT = 'accounting-account'

/**
 * In @reduxjs/toolkit we can mutate state in reducers,
 * because behind the scene it uses ImmerJS for applying them as non-mutate
 *
 * https://github.com/immerjs/immer
 */
const slice = createSlice({
  name: ACCOUNTING_ACCOUNT_MOUNT_POINT,
  initialState: {
    items: [],
    error: null,
    isLoading: false,
    isLoaded: false,
    page: 1,
  },
  reducers: {
    setAccountingAccounts(state, action) {
      const { data } = action.payload

      state.items = data
    },
    setAccountingAccount(state, action) {
      const { data } = action.payload
      const index = state.items.findIndex((item) => item.id === data.id)

      if (index === -1) {
        state.items.unshift(data)
      } else {
        state.items[index] = data
      }
    },
    setPage(state, action) {
      state.page = action.payload
    },
    startLoading(state) {
      state.isLoading = true
      state.isLoaded = false
      state.error = null
    },
    setLoadingSuccess(state) {
      state.isLoading = false
      state.isLoaded = true
      state.error = null
    },
    setLoadingFailed(state, action) {
      state.isLoading = false
      state.isLoaded = false
      state.error = action.payload
    },
  },
})

export const {
  setAccountingAccount,
  setAccountingAccounts,
  setPage,
  startLoading,
  setLoadingSuccess,
  setLoadingFailed,
} = slice.actions

export default slice.reducer

// selectors
const getState = (state) => state.get(ACCOUNTING_ACCOUNT_MOUNT_POINT)
export const getPage = (state) => getState(state).page
export const getAccountingAccounts = (state) => getState(state).items
export const isLoading = (state) => getState(state).isLoading
export const isLoaded = (state) => getState(state).isLoaded
export const getPaginatedAccountingAccounts = createSelector(
  getAccountingAccounts,
  getPage,
  (items, page) => items.slice(page * PER_PAGE - PER_PAGE, page * PER_PAGE),
)
export const getAccountingAccountsTotalCount = createSelector(
  getAccountingAccounts,
  (items) => items.length,
)
export const getAccountingAccountById = (state, id) => {
  const items = getAccountingAccounts(state)

  return items.find((item) => item.id === id)
}

export const getActiveAccountingAccountSelectOptionsByType = createSelector(
  [
    (state, _type, _include) => getAccountingAccounts(state),
    (_state, _type, include) => toArray(include).map((item) => item.id),
  ],

  (items, type, includeIds) =>
    items
      .filter(
        (item) =>
          (item.is_active && item.type === 'debit') || (includeIds || []).includes(item.id),
      )
      .map((item) => ({
        label: `${item.account_number} - ${trans(item.name)}`,
        value: item.id,
      })),
)

export const getActiveAccountingAccountSelectOptions = createSelector(
  [
    (state, _type, _include) => getAccountingAccounts(state),
    (_state, _type, include) => toArray(include).map((item) => item.id),
  ],

  (items, type, includeIds) =>
    items
      .filter((item) => item.is_active || (includeIds || []).includes(item.id))
      .map((item) => ({
        label: `${item.account_number} - ${trans(item.name)}`,
        value: item.id,
      })),
)

// action thunks
export const fetchAccountingAccounts = () => async (dispatch) => {
  dispatch(startLoading())

  try {
    const { data } = await APIClient.getAccountingAccounts()

    dispatch(setAccountingAccounts({ data }))
    dispatch(setLoadingSuccess())
  } catch (err) {
    dispatch(setLoadingFailed(err))
  }
}

export const fetchAccountingAccountsIfNeeded = () => async (dispatch, state) => {
  const { items, isLoading } = getState(state())

  if (!isEmpty(items) || isLoading) {
    return
  }

  return dispatch(fetchAccountingAccounts())
}

export const createOrUpdateAccountingAccount = (item) => async (dispatch) => {
  const { data } = item.slug
    ? await APIClient.updateAccountingAccount(item.slug, item)
    : await APIClient.createAccountingAccount(item)

  await dispatch(fetchAccountingAccounts())

  return data
}
