import { ValueFormatterParams } from 'ag-grid-community'
import { isNil, get } from 'lodash'
import numeral from 'numeral'
import { config } from '../../../config'

export const amountFormatter = (currencyProperty: string) => (params: ValueFormatterParams) => {
  const amount = params.value
  const currency = get(params.data, currencyProperty)
  const amountObject = numeral(
    isNil(amount) ? '0.00' : String(amount).replace(',', '.').replace(' ', ''),
  )
  const formatted = amountObject.format(config.moneyFormat).replace('.', ',').replace('&nbsp;', ' ')

  if (currency) {
    return `${formatted} ${currency}`
  }

  return formatted
}
