import APIClient from '../../../services/APIClient'
import { apiErrorAction } from '../../../containers/App/actions'
import { createSelector } from '@reduxjs/toolkit'

// initial state
const getInitialState = () => {
  return {
    notifications: [],
    unread: 0,
    pagination: {
      offset: 0,
      total: 0,
      last: false,
    },
  }
}

// constants
const PAGE_LIMIT = 25
const PUSH_NOTIFICATION = 'notifications::push-notification'
const APPEND_NOTIFICATIONS = 'notifications::append-notifications'
const SET_UNREAD_COUNT = 'notifications::set-unread-count'
const SET_PAGINATION = 'notifications::set-pagination'
const READ_NOTIFICATIONS = 'notifications::read-notifications'

export const MOUNT_POINT = 'notifications'

// reducer
export const reducer = (state = getInitialState(), action) => {
  switch (action.type) {
    case PUSH_NOTIFICATION:
      return {
        ...state,
        notifications: [action.payload, ...state.notifications],
      }
    case APPEND_NOTIFICATIONS:
      return {
        ...state,
        notifications: [...state.notifications, ...action.payload],
      }
    case SET_UNREAD_COUNT:
      return {
        ...state,
        unread: action.payload,
      }
    case SET_PAGINATION:
      return {
        ...state,
        pagination: {
          total: action.payload.total,
          offset: action.payload.offset,
          last: action.payload.offset >= action.payload.total,
        },
      }
    case READ_NOTIFICATIONS: {
      return {
        ...state,
        notifications: state.notifications.map((notification) => {
          if (action.payload.indexOf(notification['id']) > -1) {
            notification['read_at'] = true
          }
          return notification
        }),
        unread: state.unread - action.payload.length,
      }
    }
    default:
      return state
  }
}

// actions
export const pushNotification = (notification) => (dispatch) => {
  dispatch({
    type: PUSH_NOTIFICATION,
    payload: notification,
  })
}

export const appendNotifications = (data) => (dispatch) => {
  dispatch({
    type: APPEND_NOTIFICATIONS,
    payload: data,
  })
}

export const setPagination = (offset, total) => (dispatch) => {
  dispatch({
    type: SET_PAGINATION,
    payload: { offset, total },
  })
}

export const setNotificationsUnreadCount = (count) => (dispatch) => {
  dispatch({
    type: SET_UNREAD_COUNT,
    payload: count,
  })
}

export const fetchNotifications = () => (dispatch, state) => {
  const offset = getState(state()).pagination.offset

  return APIClient.getNotifications(offset, PAGE_LIMIT)
    .then((response) => {
      const notifications = response.data

      dispatch(appendNotifications(notifications))
      dispatch(setNotificationsUnreadCount(response.meta.unread))
      dispatch(setPagination(response.pagination.offset + PAGE_LIMIT, response.pagination.total))
    })
    .catch((error, status) => {
      dispatch(apiErrorAction(status))
    })
}

export const readNotifications = (ids) => (dispatch) => {
  return new Promise((resolve) => {
    APIClient.readNotifications(ids)
      .then(() => {
        dispatch({
          type: READ_NOTIFICATIONS,
          payload: ids,
        })
        resolve()
      })
      .catch((error, status) => {
        dispatch(apiErrorAction(status))
      })
  })
}

// selectors
export const getState = (state) => {
  return state.get(MOUNT_POINT)
}

export const getNotifications = createSelector(getState, (state) => state.notifications)

export const getNotificationsUnreadCount = (state) => getState(state).unread

export const isNotificationLastPage = (state) => getState(state).pagination.last
