import React from 'react'
import trans from '../../../trans'
import { AgreementContainer } from '../../AgreementContainer'
import hotelsBooking from '../../../store/app/hotels-booking/providers/hotelsBooking'

export const Agreement = hotelsBooking(false)((props) => (
  <AgreementContainer
    setAgreement={props.hotelsBooking.actions.setAgreement}
    checked={props.hotelsBooking.selectors.hasAgreement}
    disabled={false}
  >
    {trans('hotels-booking.agreement-text')}
  </AgreementContainer>
))
