import Button from '../ui/ButtonComponent'
import trans from '../../trans'
import { useContext } from 'react'
import { AgGridContext } from './AgGridContext'

function EditButtonRenderer(props) {
  const [, { markRowAsEditing, markRowAsView, isRowInEditingState }] = useContext(AgGridContext)

  const startEditing = () => {
    markRowAsEditing(props.node.id)
    redraw()
  }
  const stopEditing = () => {
    const editors = props.api.getCellRendererInstances({ rowNodes: [props.node] })

    editors.forEach((editor) => {
      editor.reset()

      markRowAsView(props.node.id)
      redraw()
    })
  }

  const saveEditing = () => {
    const node = props.api.getRowNode(props.node.id)
    const data = { ...node.data }
    const editors = props.api.getCellRendererInstances({ rowNodes: [props.node] })

    editors.forEach((editor) => {
      const [field, value] = editor.getValue()

      data[field] = value
    })

    return props.onSave(data).then((item) => {
      props.api.applyTransaction({ update: [item] })

      markRowAsView(props.node.id)
      redraw()
    })
  }

  const redraw = () => {
    props.api.refreshCells({
      rowNodes: [props.node],
      force: true,
    })
  }

  return (
    <div className='ag-edit-button-renderer'>
      {isRowInEditingState(props.node.id) ? (
        <>
          <Button primary xs className={'btn--tiny'} onClick={saveEditing}>
            {trans('ui.save-send')}
          </Button>
          <Button outline xs className={'btn--tiny'} type='button' onClick={stopEditing}>
            {trans('ui.save-cancel')}
          </Button>
        </>
      ) : (
        <Button primary xs className={'btn--tiny'} onClick={startEditing}>
          {trans('instance-users.edit')}
        </Button>
      )}
    </div>
  )
}

export default EditButtonRenderer
