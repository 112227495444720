import React, { Component } from 'react'
import { BaseRequestPage } from './BaseRequestPage'
import APIClient from '../../services/APIClient'

export class ReturnToImprovementRequestPage extends Component<any, any> {
  action = () => {
    const {
      match: {
        params: { slug },
      },
    } = this.props

    return APIClient.requestReturnToImprovement({ slug })
  }

  render() {
    return <BaseRequestPage action={this.action} />
  }
}
