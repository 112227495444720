import React from 'react'
import { Helmet } from 'react-helmet'
import { BreadCrumbs } from '../../../containers'
import { Section } from '../../ui/Section'
import { SectionHeader } from '../../ui/SectionHeader'
import { getRouteByName } from '../../../routes'
import { Link } from 'react-router-dom'
import trans from '../../../trans'
import Button from '../../ui/ButtonComponent'
import RouteTabs from '../../RouteTabs'
import MpkList from './MPK/MpkList'
import ProjectList from './Project/ProjectList'
import KpiList from './KPI/KpiList'
import AccountingAccountList from './AccountingAccount/AccountingAccountList'
import ExpenseGroupList from './ExpenseGroup/ExpenseGroupList'
import VatList from './VAT/VatList'
import ExpenseTypeList from './ExpenseType/ExpenseTypeList'
import ImportList from './Import/ImportList'
import { connect } from 'react-redux'
import { getInstance } from '../../../store/app/instance'

const Page = ({ match, instance }) => {
  const tabs = [
    {
      id: 'mpk',
      path: `${match.path}/mpk`,
      title: trans('accounting.mpk-page-title'),
      component: <MpkList />,
    },
    {
      id: 'projects',
      path: `${match.path}/projects`,
      title: trans('accounting.project-page-title'),
      component: <ProjectList />,
    },
    {
      id: 'kpi',
      path: `${match.path}/kpi`,
      title: trans('accounting.kpi-page-title'),
      component: <KpiList />,
    },
    {
      id: 'accounting',
      path: `${match.path}/accounting`,
      title: trans('accounting.accounting-account-page-title'),
      component: <AccountingAccountList />,
    },
    {
      id: 'vat',
      path: `${match.path}/vat`,
      title: trans('accounting.vat-number-page-title'),
      component: <VatList />,
    },
    {
      id: 'expense-groups',
      path: `${match.path}/expense-groups`,
      title: trans('accounting.document-element-group-page-title'),
      component: <ExpenseGroupList />,
    },
    {
      id: 'expense-type',
      path: `${match.path}/expense-type`,
      title: trans('accounting.document-element-type-page-title'),
      component: <ExpenseTypeList />,
    },
    {
      id: 'import',
      path: `${match.path}/import`,
      title: 'Import',
      component: <ImportList />,
      enabled: instance.features.FEATURE_IMPORT_FILE_INTEGRATION_ENABLED === true,
    },
  ]

  return (
    <div>
      <BreadCrumbs>
        <Link to={getRouteByName('main', 'dashboard')}>{trans('global.dashboard')}</Link>
        <Link to={getRouteByName('main', 'instance-settings')}>
          {trans('instance-settings.instance-settings')}
        </Link>
        <Link to={getRouteByName('main', 'company')}>{trans('global.company')}</Link>
      </BreadCrumbs>

      <Section noBorder className='company-settings'>
        <Helmet title={trans('global.company')} />

        <SectionHeader caption={trans('global.company')}>
          <Button hidden />
        </SectionHeader>

        <RouteTabs match={match} items={tabs} defaultTab={tabs[0].id} />
      </Section>
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    instance: getInstance(state),
  }
}

export default connect(mapStateToProps)(Page)
