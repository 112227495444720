import { createSelector, createSlice } from '@reduxjs/toolkit'
import { isEmpty } from 'lodash'
import APIClient from '../../../services/APIClient'
import { toArray } from '../../../utils/array'
import { PER_PAGE } from '../../../constants/pagination'

export const PROJECT_MOUNT_POINT = 'projects'

/**
 * In @reduxjs/toolkit we can mutate state in reducers,
 * because behind the scene it uses ImmerJS for applying them as non-mutate
 *
 * https://github.com/immerjs/immer
 */
const slice = createSlice({
  name: PROJECT_MOUNT_POINT,
  initialState: {
    items: [],
    error: null,
    isLoading: false,
    isLoaded: false,
    page: 1,
  },
  reducers: {
    setProjects(state, action) {
      const { data } = action.payload

      state.items = data
    },
    setProject(state, action) {
      const { data } = action.payload
      const index = state.items.findIndex((item) => item.id === data.id)

      if (index === -1) {
        state.items.unshift(data)
      } else {
        state.items[index] = data
      }
    },
    setPage(state, action) {
      state.page = action.payload
    },
    startLoading(state) {
      state.isLoading = true
      state.isLoaded = false
      state.error = null
    },
    setLoadingSuccess(state) {
      state.isLoading = false
      state.isLoaded = true
      state.error = null
    },
    setLoadingFailed(state, action) {
      state.isLoading = false
      state.isLoaded = false
      state.error = action.payload
    },
  },
})

export const {
  setPage,
  setProject,
  setProjects,
  startLoading,
  setLoadingSuccess,
  setLoadingFailed,
} = slice.actions

export default slice.reducer

// selectors
const getState = (state) => state.get(PROJECT_MOUNT_POINT)
export const getPage = (state) => getState(state).page
export const getProjects = (state) => getState(state).items
export const isLoaded = (state) => getState(state).isLoaded || !isLoading(state)
export const isLoading = (state) => getState(state).isLoading
export const getPaginatedProjects = createSelector(getProjects, getPage, (items, page) =>
  items.slice(page * PER_PAGE - PER_PAGE, page * PER_PAGE),
)
export const getProjectsTotalCount = createSelector(getProjects, (items) => items.length)
export const getActiveProjectsSelectOptions = createSelector(
  [(state, _include) => getProjects(state), (_state, include) => toArray(include)],
  (items, includeIds) => {
    return items
      .filter((item) => item.is_active || includeIds.includes(item.id))
      .map((item) => ({
        label: `${item.code} - ${item.name}`,
        value: item.id,
      }))
  },
)

export const getProjectById = createSelector(
  [(state, _id) => getProjects(state), (_state, id) => id],
  (items, id) => items && items.find((item) => item.id === id),
)

// action thunks
export const fetchProjects = () => async (dispatch) => {
  dispatch(startLoading())

  try {
    const { data } = await APIClient.getProjects()

    dispatch(setProjects({ data }))
    dispatch(setLoadingSuccess())
  } catch (err) {
    dispatch(setLoadingFailed(err))
  }
}

export const fetchProjectsIfNeeded = () => async (dispatch, state) => {
  const { items, isLoading } = getState(state())

  if (!isEmpty(items) || isLoading) {
    return
  }

  return dispatch(fetchProjects())
}

export const createOrUpdateProject = (item) => async (dispatch) => {
  const { data } = item.slug
    ? await APIClient.updateProject(item.slug, item)
    : await APIClient.createProject(item)

  await dispatch(fetchProjects())

  return data
}
