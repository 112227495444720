import { createSelector, createSlice } from '@reduxjs/toolkit'
import APIClient from '../../../services/APIClient'
import { toArray } from '../../../utils/array'
import { PER_PAGE } from '../../../constants/pagination'

export const VAT_MOUNT_POINT = 'vat'

/**
 * In @reduxjs/toolkit we can mutate state in reducers,
 * because behind the scene it uses ImmerJS for applying them as non-mutate
 *
 * https://github.com/immerjs/immer
 */
const slice = createSlice({
  name: VAT_MOUNT_POINT,
  initialState: {
    items: [],
    error: null,
    isLoading: false,
    isLoaded: false,
    page: 1,
  },
  reducers: {
    setVatNumber(state, action) {
      const { data } = action.payload
      const index = state.items.findIndex((item) => item.id === data.id)

      if (index === -1) {
        state.items.unshift(data)
      } else {
        state.items[index] = data
      }
    },
    setVatNumbers(state, action) {
      const { data } = action.payload

      state.items = data
    },
    setPage(state, action) {
      state.page = action.payload
    },
    startLoading(state) {
      state.isLoading = true
      state.isLoaded = false
      state.error = null
    },
    setLoadingSuccess(state) {
      state.isLoading = false
      state.isLoaded = true
      state.error = null
    },
    setLoadingFailed(state, action) {
      state.isLoading = false
      state.isLoaded = false
      state.error = action.payload
    },
  },
})

export const {
  setVatNumber,
  setVatNumbers,
  setPage,
  startLoading,
  setLoadingSuccess,
  setLoadingFailed,
} = slice.actions

export default slice.reducer

// selectors
const getState = (state) => state.get(VAT_MOUNT_POINT)
export const getPage = (state) => getState(state).page
export const getVatNumbers = (state) => getState(state).items
export const isLoading = (state) => getState(state).isLoading
export const isLoaded = (state) => getState(state).isLoaded
export const getPaginatedVatNumbers = createSelector(getVatNumbers, getPage, (items, page) => {
  return items.slice(page * PER_PAGE - PER_PAGE, page * PER_PAGE)
})
export const getVatNumbersTotalCount = createSelector(getVatNumbers, (items) => items.length)

export const getVatNumbersSelector = createSelector(
  [(state, _include) => getVatNumbers(state), (_state, include) => toArray(include)],
  (items, includeIds) => items.filter((item) => item.is_active || includeIds.includes(item.id)),
)

export const getActiveVatCodeSelectOptions = createSelector(getVatNumbersSelector, (items) =>
  items.map((item) => ({
    label: item.code,
    value: item.id,
  })),
)

export const getActiveVatNumberSelectOptions = createSelector(getVatNumbersSelector, (items) =>
  items.map((item) => ({
    label: item.name,
    value: item.id,
  })),
)

export const getVatNumberById = (state, id) => {
  const items = getVatNumbers(state)

  return items.find((v) => v.id === id)
}

export const fetchEditableVatNumbers = () => async (dispatch) => {
  dispatch(startLoading())

  try {
    const { data } = await APIClient.getEditableVatNumbers()

    dispatch(setVatNumbers({ data }))
    dispatch(setLoadingSuccess())
  } catch (err) {
    dispatch(setLoadingFailed(err))
  }
}

// action thunks
export const fetchVatNumbers = () => async (dispatch) => {
  dispatch(startLoading())

  try {
    const { data } = await APIClient.getVatNumbers()

    dispatch(setVatNumbers({ data }))
    dispatch(setLoadingSuccess())
  } catch (err) {
    dispatch(setLoadingFailed(err))
  }
}

export const createOrUpdateVatNumber = (item) => async (dispatch) => {
  const { data } = item.slug
    ? await APIClient.updateVatNumber(item.slug, item)
    : await APIClient.createVatNumber(item)

  await dispatch(fetchVatNumbers())

  return data
}
