import React from 'react'
import { Helmet } from 'react-helmet'
import { BreadCrumbs } from '../../containers'
import { Section } from '../ui/Section'
import { SectionHeader } from '../ui/SectionHeader'
import { RouteManager } from '../../containers/RouteManager'
import { TripRequestsListFilters } from '../TripRequestsListFilters'
import { TripRequestsManager } from '../../containers/TripRequestsManager'
import { TripRequestsListTable } from '../TripRequestsListTable'
import { getRouteByName } from '../../routes'
import { Link, Redirect } from 'react-router-dom'
import trans from '../../trans'

export const TripRequestsListPage = () => (
  <div>
    <BreadCrumbs>
      <Link to={getRouteByName('main', 'dashboard')}>{trans('global.dashboard')}</Link>
      <Link to={getRouteByName('main', 'tripRequestsList')}>{trans('global.trips')}</Link>
    </BreadCrumbs>
    <Section noBorder>
      <Helmet title={trans('request.request-trip-list')} />

      <SectionHeader caption={trans('global.trips')} />

      <RouteManager>
        {({ getRouteByName }) => (
          <TripRequestsManager>
            {({
              requests,
              requestsFilters,
              changeRequestsFilter,
              requestsSorter,
              changeRequestsSorter,
              areRequestsLoaded,
              areRequestsLoading,
              MPKs,
              currentUser,
              requestsPagination,
              changeRequestsPage,
            }) =>
              currentUser.abilities.isAgent ? (
                <Redirect to='/requests/agent' />
              ) : (
                <div>
                  <TripRequestsListFilters
                    filters={requestsFilters}
                    onChangeFilter={changeRequestsFilter}
                    currentUser={currentUser}
                    MPKs={MPKs}
                  />
                  <TripRequestsListTable
                    requests={requests}
                    isLoading={!areRequestsLoaded || areRequestsLoading}
                    getRouteByName={getRouteByName}
                    currentUser={currentUser}
                    pagination={requestsPagination}
                    changePage={changeRequestsPage}
                    sorter={requestsSorter}
                    onChangeSorter={changeRequestsSorter}
                  />
                </div>
              )
            }
          </TripRequestsManager>
        )}
      </RouteManager>
    </Section>
  </div>
)
