import _ from 'lodash'

export const renderKey = (...args) => {
  let undefinedKeyCounter = 0
  const _args = args.map((arg, i) => {
    if (_.isUndefined(arg) || arg === 'undefined') {
      return ++undefinedKeyCounter
    } else if (_.isPlainObject(arg)) {
      if (!_.isEmpty(arg)) {
        return Object.keys(arg)
          .map((key) => {
            if (_.isPlainObject(arg[key])) {
              return `${key}==${renderKey(arg[key])}`
            } else if (!_.isArray(arg[key])) {
              return `${key}=${arg[key]}`
            }
            return null
          })
          .filter((value) => value !== null)
          .join(',')
      }
      return ++undefinedKeyCounter
    } else if (!_.isArray(arg)) {
      return `${arg}`
    }
    return null
  })
  return _args.length > 0
    ? _args.filter((value) => value !== null).join('-')
    : ++undefinedKeyCounter
}
