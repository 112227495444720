import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { trans } from '../../../trans'
import { Field } from 'redux-form/immutable'
import { FormField } from '../../ui/Form'
import Button from '../../ui/ButtonComponent'
import { FormGroup } from '../../../ui'
import { loadOptionsForGuSupervisorForNewUser } from '../../../store/app/users-for-select'
import { fetchMpkForAsyncSelect } from '../../../store/app/mpks'
import { components } from '../../../utils/react-select'
import UserSelectOption from '../../../form/UserField/UserFieldOption'

function UserCreateForm({
  countries,
  grades,
  companies,
  genders,
  groups,
  handleSubmit,
  onCompanyChange,
  languages,
  data: { mpk, company_id, supervisor, locale },
  data,
}) {
  return (
    <form onSubmit={handleSubmit}>
      <div className='sensitive-data__fields'>
        <Field
          name='email'
          type='text'
          label={trans('instance-users.email')}
          component={FormField}
          labeltop
        />
        <Field
          name='first_name'
          type='text'
          label={trans('user.first-name')}
          component={FormField}
          labeltop
        />
        <Field
          name='last_name'
          type='text'
          label={trans('user.last-name')}
          component={FormField}
          labeltop
        />
        <Field
          name='birth_date'
          type='datepicker'
          label={trans('user.birth-date')}
          component={FormField}
          labeltop
        />
        <Field
          name='phone_number'
          type='text'
          label={trans('user.phone')}
          component={FormField}
          labeltop
        />
        <Field
          name='citizenship_id'
          type='select'
          label={trans('user.citizenship')}
          component={FormField}
          options={countries}
          labeltop
        />
        <Field
          name='sex'
          type='select'
          label={trans('user.sex')}
          options={genders}
          component={FormField}
          labeltop
        />
        <Field
          name='company_id'
          type='select'
          label={trans('instance-users.company')}
          component={FormField}
          options={companies}
          onChange={onCompanyChange}
          labeltop
        />
        <Field
          name='mpk'
          type='async-select'
          disabled={!company_id}
          selectedOption={mpk}
          label={trans('instance-users.mpk')}
          component={FormField}
          loadOptions={fetchMpkForAsyncSelect(company_id)}
          labeltop
          placeholder={trans('instance-users.none')}
          noOptionsMessage={() => {
            return trans('user.no-results-for-search-message')
          }}
          loadingMessage={() => {
            return trans('user.searching-message')
          }}
          delay={500}
          returnFullItemValueOnChange={true}
        />
        <Field
          name='supervisor'
          type='async-select'
          disabled={!company_id}
          selectedOption={supervisor}
          component={FormField}
          label={trans('instance-users.supervisor')}
          loadOptions={loadOptionsForGuSupervisorForNewUser}
          labeltop
          placeholder={trans('instance-users.none')}
          noOptionsMessage={() => {
            return trans('user.no-results-for-search-message')
          }}
          loadingMessage={() => {
            return trans('user.searching-message')
          }}
          returnFullItemValueOnChange={true}
          components={{ ...components, Option: UserSelectOption }}
        />
        <Field
          name='grade_id'
          type='select'
          label={trans('instance-users.user-level')}
          component={FormField}
          options={grades}
          labeltop
        />
        <Field
          name='locale'
          type='select'
          label={trans('user.language')}
          options={languages}
          component={FormField}
          labeltop
        />
        <FormGroup className='input-wrapper is-margin-top'>
          <div>
            <h1>{trans('settings-page.roles')}</h1>

            <div className='checkbox-list'>
              <Field
                name='group_ids'
                type='grouped-checkbox'
                options={groups}
                component={FormField}
                withError
                inputOnly
              />
            </div>
          </div>
        </FormGroup>
        <FormGroup>
          <Button primary>{trans('global.save')}</Button>
        </FormGroup>
      </div>
    </form>
  )
}

UserCreateForm.propTypes = {
  grades: PropTypes.array.isRequired,
  countries: PropTypes.array.isRequired,
  companies: PropTypes.array.isRequired,
  groups: PropTypes.array.isRequired,
  onCompanyChange: PropTypes.func.isRequired,
  languages: PropTypes.array.isRequired,
}

export { UserCreateForm }
