import { PersonalData } from './PersonalData'
import { withFormik } from 'formik'
import { get } from 'lodash'
import APIClient from '../../../services/APIClient'
import moment from 'moment'
import { config } from '../../../config'
import store from '../../../store'
import { fetchInstance, isModuleActive, resetInstance } from '../../../store/app/instance'
import GoogleMapsProvider from '../../../services/GoogleMapsProvider'
import { Location } from '../../../models/Location'
import { bindActionCreators, compose } from 'redux'
import { connect } from 'react-redux'
import { setUserProfile } from '../../../store/app/user-profile/userProfileSlice'
import { fetchMpk } from '../../../store/app/mpks'
import { fetchCountries } from '../../../store/app/countries/slice'
import { formatIBAN, parseIBAN } from '../../../hooks/useIBAN'

const prepareDateValue = (value) => {
  if (value !== null && value.length !== 0 && value !== 'Invalid date') {
    value = moment(value, config.apiDateFormat)
  }
  return value
}

const parseDateValue = (value) => {
  if (value === null || value.length === 0 || value === 'Invalid date') {
    return ''
  }
  return moment(value, 'DD.MM.YYYY').format(config.apiDateFormat)
}

const save = (values, { props, setSubmitting, setErrors }) => {
  const { user } = props

  let work_location = values.work_location
  if (values.work_location.formatted_address === '') {
    work_location = null
  }

  values = {
    ...values,
    work_location,
    iban: parseIBAN(values.iban),
    birth_date: parseDateValue(values.birth_date),
  }

  APIClient.updateUserProfile({ slug: user.slug, ...values })
    .then((response) => {
      const languageChanged = values.lang !== user.lang

      store.dispatch(setUserProfile(response.data))

      if (languageChanged && props.currentUser) {
        const dependencies = []

        store.dispatch(resetInstance())
        dependencies.push(store.dispatch(fetchInstance()))
        dependencies.push(store.dispatch(fetchCountries()))

        Promise.all(dependencies).then(() => {
          config.moment.locale(values.lang)

          GoogleMapsProvider.dequeue()
          GoogleMapsProvider.setLang(values.lang)
          GoogleMapsProvider.enqueue()
          GoogleMapsProvider.removeScripts()
          GoogleMapsProvider.injectScripts()

          setSubmitting(false)
        })
      } else {
        setSubmitting(false)
      }
    })
    .catch((error) => {
      setErrors(get(error, 'alerts.0.errors', {}))
      setSubmitting(false)
    })
}

const formik = withFormik({
  mapPropsToValues: (props) => {
    const { user } = props

    if (!user) {
      return {}
    }

    let work_location = get(user, 'workLocation', null)

    if (!work_location) {
      work_location = { ...new Location() }
    }

    return {
      first_name: get(user, 'first_name', ''),
      last_name: get(user, 'last_name', ''),
      email: get(user, 'email', ''),
      phone: get(user, 'phone', ''),
      birth_date: prepareDateValue(get(user, 'birth_date', '')),
      citizenship_id: get(user, 'citizenship.id', ''),
      lang: get(user, 'lang', ''),
      work_location,
      license_plate: get(user, 'license_plate', ''),
      company_license_plate: get(user, 'company_license_plate', ''),
      sex: get(user, 'sex', ''),
      company_id: get(user, 'company_id', ''),
      iban: formatIBAN(get(user, 'iban', '')),
    }
  },
  handleSubmit: save,
  enableReinitialize: true,
  displayName: 'PersonalData',
})

const mapStateToProps = (state) => {
  return {
    isErp: isModuleActive()(state, 'erp'),
  }
}

const mapDispatchToProps = (dispatch, props) => {
  return bindActionCreators(
    {
      fetchMpk,
    },
    dispatch,
  )
}

const withConnect = connect(mapStateToProps, mapDispatchToProps)

const PersonalDataForm = compose(formik, withConnect)(PersonalData)

export { PersonalDataForm }
export default PersonalDataForm
