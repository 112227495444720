import { trans } from '../../trans'
import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { Loader } from '../../components/ui/LoadingOverlay/Loader'
import { bindActionCreators, compose } from 'redux'
import {
  fetchCountriesIfNeeded,
  getCountriesForSelectOptionsSelector,
} from '../../store/app/countries/slice'
import {
  fetchCompaniesIfNeeded,
  getCompaniesForSelectOptionsWithCodeSelector,
} from '../../store/app/companies/slice'
import {
  fetchGradesIfNeeded,
  getGradesForSelectOptionsSelector,
} from '../../store/app/grades/slice'
import {
  fetchEditableMpk,
  makeGetMpkByCompanyIdForSelectOptionsSelector,
} from '../../store/app/mpks'
import { fetchUserGroupsIfNeeded, getUserGroups } from '../../store/app/user-groups'
import { getLanguages } from '../../store/app/languages'
import { getInstance } from '../../store/app/instance'
import { getFormValues } from '../../utils/forms'
import { fromJS } from 'immutable'
import APIClient from '../../services/APIClient'
import { getValidationErrors } from '../../utils'
import { reduxForm, change, SubmissionError } from 'redux-form/immutable'
import { UserCreateForm as FormComponent } from '../../components/SettingsPage/UsersCreatePage/UserCreateForm'
import { fetchLoggableUsers } from '../../store/app/login-as'
import { config } from '../../config'
import * as moment from 'moment'

const changeFieldValue = (form, name, value) => (dispatch) => {
  dispatch(change(form, name, value))
}

export const FORM_NAME = 'user-create-form'

class UserCreateForm extends React.Component<any, any> {
  constructor(props) {
    super(props)

    this.state = {
      isLoading: true,
    }
  }

  componentDidMount() {
    const {
      fetchCountriesIfNeeded,
      fetchCompaniesIfNeeded,
      fetchGradesIfNeeded,
      fetchUserGroupsIfNeeded,
    } = this.props

    const dependencies = [
      fetchCountriesIfNeeded(),
      fetchCompaniesIfNeeded(),
      fetchGradesIfNeeded(),
      fetchUserGroupsIfNeeded(),
    ]

    Promise.all(dependencies).then((res) => {
      this.setState({ isLoading: false })
    })
  }

  getGenderOptions() {
    return [
      { value: 'm', label: trans('user.male') },
      { value: 'f', label: trans('user.female') },
    ]
  }

  onCompanyChange = (event, value) => {
    const { changeFieldValue, form } = this.props

    changeFieldValue(form, 'mpk', null)
    changeFieldValue(form, 'supervisor', null)
  }

  render() {
    const { handleSubmit, countries, companies, grades, groups, data, languages } = this.props
    const { isLoading } = this.state
    const genders = this.getGenderOptions()

    const groupOptions = groups.map((group) => ({
      value: `${group.id}`,
      name: group.name,
      disabled: !group.abilities.canBeAttachedToUser,
    }))
    const langOptions = languages.map((language) => ({
      value: language,
      label: trans('languages.' + language),
    }))

    if (isLoading) {
      return <Loader />
    }

    return (
      <FormComponent
        handleSubmit={handleSubmit}
        countries={countries}
        companies={companies}
        grades={grades}
        genders={genders}
        // users={users}
        groups={groupOptions}
        data={data}
        onCompanyChange={this.onCompanyChange}
        languages={langOptions}
      />
    )
  }
}

UserCreateForm.propTypes = {
  push: PropTypes.func.isRequired,
  getRouteByName: PropTypes.func.isRequired,
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      fetchCountriesIfNeeded,
      fetchCompaniesIfNeeded,
      fetchGradesIfNeeded,
      fetchUserGroupsIfNeeded,
      fetchLoggableUsers,
      change,
      changeFieldValue,
    },
    dispatch,
  )
}

const mapStateToProps = (state) => {
  const currentValues = getFormValues(FORM_NAME, state)

  return {
    initialValues: fromJS({
      first_name: null,
      last_name: null,
      email: '',
      group_ids: [],
      company_id: null,
      mpk: null,
      citizenship_id: null,
      birth_date: null,
      sex: null,
      phone_number: null,
      supervisor: null,
      locale: getInstance(state).default_lang,
    }),
    data: currentValues,
    form: FORM_NAME,
    grades: getGradesForSelectOptionsSelector(state),
    countries: getCountriesForSelectOptionsSelector(state),
    companies: getCompaniesForSelectOptionsWithCodeSelector(state),
    groups: getUserGroups(state),
    languages: getLanguages(state),
  }
}

const submit = (values, dispatch, props) => {
  const data = values.toJS()

  if (data.birth_date) {
    data.birth_date = moment(data.birth_date, config.apiDateTimeFormat).format(config.apiDateFormat)
  }

  if (data.supervisor !== null) {
    data.supervisor_id = data.supervisor.id
  }
  delete data.supervisor

  if (data.mpk !== null) {
    data.mpk_id = data.mpk.value
  }
  delete data.mpk

  const { push, getRouteByName, fetchLoggableUsers } = props

  return APIClient.createUser(data)
    .then((res) => {
      const {
        data: { slug },
      } = res

      fetchLoggableUsers()

      push(getRouteByName('main', 'external-user', { slug }))
    })
    .catch((res) => {
      const errors = getValidationErrors(res.alerts)

      if (errors.mpk_id) {
        errors.mpk = errors.mpk_id
      }

      throw new SubmissionError({
        ...errors,
      })
    })
}

const withForm = reduxForm({
  form: FORM_NAME,
  onSubmit: submit,
  enableReinitialize: true,
})

const withConnect = connect(mapStateToProps, mapDispatchToProps)

export default compose(withConnect, withForm)(UserCreateForm)
