import { createModule } from './elements'

const module = createModule({
  mountPoint: 'trip-timeline',
})

const { reducer, MOUNT_POINT, actions, selectors } = module

export const {
  setElements,
  saveElement,
  deleteElement,
  addTargetPoint,
  addFirstElement,
  getPrivateAccommodationCost,
  reset,
  startRecomposition,
  stopRecomposition,
  updateElementSearchUuid,
  updateElement,
  clearOffer,
  removeElement,
  addElement,
  changeElementType,
  sortElements,
  addReturnElement,
  removeReturnElement,
  changeElementTypeAtIndex,
  setDirty,
} = actions

export const { getElements, getAdditionalElements, isRecomposition, getRecomposed } = selectors

export { reducer, MOUNT_POINT }
