import React, { Component, Fragment, memo } from 'react'
import PropTypes from 'prop-types'
import Icon from '../ui/IconComponent'
import Button from '../ui/ButtonComponent'
import { Scrollbars } from 'react-custom-scrollbars-2'
import _, { times, get } from 'lodash'
import { trans } from '../../trans'
import Facilities from './Facilities'
import Maintenance from './Maintenance'
import Stars from './Stars'
import Gallery from './Gallery'
import Options from './Options'
import { CheckboxField, FormField } from '../ui/Form'
import { Textarea } from '../../ui'
import { connect } from 'react-redux'
import { getCurrentUser } from '../../store/app/user-profile/userProfileSlice'
import { CorporateRate } from './CorporateRate'
import { getOr } from '../../utils/getOr'
import memoize from 'memoize-one'

class SingleHotel extends Component<any, any> {
  constructor(props) {
    super(props)

    this.state = {
      isOpen: false,
      valuated: false,
      booked: false,
      windowInnerWidth: window.innerWidth,
    }

    this.slider = React.createRef()
  }

  componentDidMount = () => {
    window.addEventListener('resize', this.setWindowWidth)
  }

  componentWillUnmount = () => {
    window.removeEventListener('resize', this.setWindowWidth)
  }

  setWindowWidth = () => {
    this.setState({ innerWidth: window.innerWidth })
  }

  backToListing = (e) => {
    e.preventDefault()
    const { hotelsBooking } = this.props
    hotelsBooking.actions.selectOffer(null)
  }

  renderNav = () => {
    return (
      <nav className='hotel-dialog__nav'>
        <a href='#' className='hotel-dialog__return-btn' onClick={this.backToListing}>
          <Icon type='arrow' className='hotel-dialog__return-icon' />
          <span className='hotel-dialog__return-text'>
            {trans('hotels-booking.back-to-hotels')}
          </span>
        </a>

        <Icon type='close' className='hotel-dialog__close' onClick={this.props.onClose} />
      </nav>
    )
  }

  selectOfferOption = (option) => {
    const {
      hotelsBooking: {
        selectors: { uuid, selectedOffer },
        actions: { selectOfferOption },
        element,
        request,
      },
    } = this.props

    selectOfferOption(request, {
      search_uuid: uuid,
      offer: selectedOffer,
      option,
      request_element: element,
    })
  }

  getFilteredOptions = memoize((options, parking) => {
    if (parking) {
      return options.filter((option) => option.parking !== false)
    }

    return options
  })

  render() {
    const {
      hotelsBooking: {
        selectors: {
          selectedOffer,
          options,
          allOptionsNumber,
          facilities: { breakfast },
        },
        actions: {
          selectOfferOption,
          setFacility,
          toggleParking,
          toggleExtraServices,
          changeExtraServicesText,
          setFilter,
        },
      },
    } = this.props

    const name = get(selectedOffer, 'attributes.name', '')
    const address = get(selectedOffer, 'attributes.address')
    const amount = get(selectedOffer, 'lowestPrice', '0,00 zł')
    const amountPerNight = get(selectedOffer, 'lowestPricePerNight', '0,00 zł')
    const stars = get(selectedOffer, 'attributes.stars', null)
    const facilities = get(selectedOffer, 'attributes.facilities.facilities', [])
    const facilitiesDesc = get(selectedOffer, 'attributes.facilities.hotel', null)
    const mealTypes = get(selectedOffer, 'mealTypes', [])
    const description = get(selectedOffer, 'attributes.description', null)
    const photos = get(selectedOffer, 'attributes.photos', [])
    const extraServicesChecked = get(
      this.props.hotelsBooking.element,
      'extraServicesChecked',
      false,
    )
    const extraServicesText = get(this.props.hotelsBooking.element, 'extraServicesText', null)
    const parking = get(this.props.hotelsBooking.element, 'parking', false)
    const corporateOption = getOr(selectedOffer, 'options', [])
      .filter((option) => option.corporateRate)
      .find((option) => option.corporateRateImage)

    const companyFeatures = this.props.company.features
    const hasParkingFacility =
      facilities.length > 0 && facilities.filter((value) => value === 'parking').length > 0

    const filteredOptions = this.getFilteredOptions(options, parking)

    return (
      <Fragment>
        {this.renderNav()}

        <div className='selected-hotel'>
          <div className='selected-hotel__row'>
            <div className='selected-hotel__column'>
              <div className='selected-hotel__name'>
                <span>{_.startCase(_.toLower(name))}</span>

                <div className='selected-hotel__name-icons'>
                  <div className='selected-hotel__name-stars'>
                    <Stars stars={stars} />
                  </div>

                  <CorporateRate option={corporateOption} />
                </div>
              </div>

              <a
                target='_blank'
                href={`https://www.google.com/maps/search/?api=1&query=${address}`}
                className='selected-hotel__address'
              >
                {address}
              </a>

              <div className='selected-hotel__offer-wrapper selected-hotel__offer-wrapper--single-hotel'>
                <div className='selected-hotel__offer-icons'>
                  <Maintenance mealTypes={mealTypes} />
                  <Facilities facilities={facilities} />
                </div>

                <span className='selected-hotel__offer-details'>
                  {trans('hotels-booking.free-cancellation-message')}
                </span>
              </div>

              <div className='hotel-dialog__details selected-hotel__details'>
                <span className='selected-hotel__details-title h2'>
                  {trans('hotels-booking.description')}
                </span>

                <Scrollbars style={{ height: 230 }}>
                  {!!description && (
                    <div className='selected-hotel__detail'>
                      <div
                        className='selected-hotel__details-copy'
                        dangerouslySetInnerHTML={{ __html: description }}
                      />
                    </div>
                  )}

                  {!!facilitiesDesc && (
                    <div className='selected-hotel__detail'>
                      <span className='selected-hotel__details-title h2'>
                        {trans('hotels-booking.facilities-desc')}
                      </span>
                      <p
                        className='selected-hotel__details-copy'
                        dangerouslySetInnerHTML={{ __html: facilitiesDesc }}
                      />
                    </div>
                  )}
                </Scrollbars>
              </div>
            </div>

            <div className='selected-hotel__column'>
              <Gallery photos={photos} showPhotosBelt={true} />
            </div>
          </div>
        </div>

        <div className='selected-hotel__breakfast-filter'>
          <div className={'selected-hotel__additional-options'}>
            <CheckboxField
              label={trans('hotels-booking.options-with-breakfast')}
              checked={breakfast}
              disabled={true}
            />
            {companyFeatures['FEATURE_HOTEL_RESERVATION_ADDITIONAL_SERVICES_PARKING'] === true &&
              hasParkingFacility && (
                <CheckboxField
                  label={trans('hotels-booking.parking-request')}
                  onChange={(e) => {
                    toggleParking(this.props.hotelsBooking.element)
                  }}
                  checked={parking}
                  disabled={false}
                />
              )}
            {companyFeatures['FEATURE_HOTEL_RESERVATION_ADDITIONAL_SERVICES_EXTRA_REQUEST'] ===
              true && (
              <CheckboxField
                label={trans('hotels-booking.additional-requests')}
                onChange={(e) => {
                  toggleExtraServices(this.props.hotelsBooking.element)
                }}
                checked={extraServicesChecked}
                disabled={false}
              />
            )}

            <div className='selected-hotel__legend hotel-dialog__offer-detail-icon--cancellable'>
              <Icon type='calendar_cancel' />
              <span className='selected-hotel__legend-text'>
                {trans('hotels-booking.legend-offer-cancellable')}
              </span>
            </div>

            <div className='selected-hotel__legend hotel-dialog__offer-detail-icon--non-cancellable'>
              <Icon type='calendar_cancel' />
              <span className='selected-hotel__legend-text'>
                {trans('hotels-booking.legend-offer-non-cancellable')}
              </span>
            </div>
          </div>

          <div className='selected-hotel__breakfast-filter-counter'>
            {!!allOptionsNumber &&
              trans('hotels-booking.you-see-n-from-x', {
                filteredOffersNumber: filteredOptions.length,
                allOffersNumber: allOptionsNumber,
              })}
          </div>
        </div>
        {companyFeatures['FEATURE_HOTEL_RESERVATION_ADDITIONAL_SERVICES_EXTRA_REQUEST'] === true &&
          extraServicesChecked && (
            <div className='selected-hotel__breakfast-filter'>
              <div className={'selected-hotel__additional-options-remarks'}>
                <Textarea
                  autosize
                  label={trans('hotels-booking.options-with-breakfast')}
                  placeholder={trans('hotels-booking.additional-requests-textarea-placeholder')}
                  value={extraServicesText}
                  onChange={(e) => {
                    changeExtraServicesText(e.target.value, this.props.hotelsBooking.element)
                  }}
                />
              </div>
            </div>
          )}
        <Options
          options={filteredOptions}
          select={this.selectOfferOption}
          onClose={this.props.onClose}
        />
      </Fragment>
    )
  }
}

SingleHotel.propTypes = {
  onClose: PropTypes.func.isRequired,
  hotelsBooking: PropTypes.object.isRequired,
}

const mapStateToProps = (state) => ({
  company: getCurrentUser(state).company,
})

export default connect(mapStateToProps, null)(SingleHotel)
