import React, { Component } from 'react'
import PropTypes from 'prop-types'
import InputRange from 'react-input-range'
import minutesToHours from '../../../utils/minutesToHours'
import { connect } from '../../../containers/FlightsBooking'
import {
  FILTER_RETURN_DEPARTURE_TIME_DEFAULT_FROM,
  FILTER_RETURN_DEPARTURE_TIME_DEFAULT_TO,
  FILTER_TARGET_DEPARTURE_TIME,
  FILTER_TARGET_DEPARTURE_TIME_DEFAULT_FROM,
  FILTER_TARGET_DEPARTURE_TIME_DEFAULT_TO,
  FILTER_RETURN_DEPARTURE_TIME,
} from '../../../store/app/flights-booking'

const getMinDefault = (props) => {
  const {
    flights: {
      selectors: { filters },
    },
  } = props

  if (props.sameDay) {
    if (props.filterName === FILTER_TARGET_DEPARTURE_TIME) {
      return !isNaN(filters.target_departure_time.min)
        ? filters.target_departure_time.min
        : FILTER_TARGET_DEPARTURE_TIME_DEFAULT_FROM
    }

    return filters.target_departure_time.max || FILTER_TARGET_DEPARTURE_TIME_DEFAULT_TO
  } else {
    if (props.filterName === FILTER_TARGET_DEPARTURE_TIME) {
      return !isNaN(filters.target_departure_time.min)
        ? filters.target_departure_time.min
        : FILTER_TARGET_DEPARTURE_TIME_DEFAULT_FROM
    }

    return !isNaN(filters.return_departure_time.min)
      ? filters.return_departure_time.min
      : FILTER_RETURN_DEPARTURE_TIME_DEFAULT_FROM
  }
}

const getMaxDefault = (props) => {
  const {
    flights: {
      selectors: { filters },
    },
  } = props

  if (props.filterName === FILTER_TARGET_DEPARTURE_TIME) {
    return !isNaN(filters.target_departure_time.max)
      ? filters.target_departure_time.max
      : FILTER_TARGET_DEPARTURE_TIME_DEFAULT_TO
  }

  return !isNaN(filters.return_departure_time.max)
    ? filters.return_departure_time.max
    : FILTER_RETURN_DEPARTURE_TIME_DEFAULT_TO
}

class TimeComponent extends Component<any, any> {
  constructor(props) {
    super(props)

    this.state = {
      value: {
        min: getMinDefault(props),
        max: getMaxDefault(props),
      },
    }
  }

  onChangeComplete = (value) => {
    const { flights, filterName } = this.props
    flights.actions.setFilter({
      [filterName]: value,
    })
  }

  render() {
    const {
      sameDay,
      disabled,
      filterName,
      flights: { selectors },
      flights,
    } = this.props
    let minValue = 0
    let isDisabled = disabled

    if (
      filterName === FILTER_RETURN_DEPARTURE_TIME &&
      sameDay &&
      selectors.filters.return_departure_time.min !== selectors.filters.target_departure_time.max
    ) {
      flights.actions.setFilter({
        [FILTER_RETURN_DEPARTURE_TIME]: {
          min: selectors.filters.target_departure_time.max,
          max: selectors.filters.return_departure_time.max,
        },
      })

      this.setState({
        value: {
          min: selectors.filters.target_departure_time.max,
          max: selectors.filters.return_departure_time.max,
        },
      })
    }

    if (filterName === FILTER_RETURN_DEPARTURE_TIME && sameDay) {
      isDisabled = true
    }

    return (
      <div className='train-trip__input train-trip__input--range-slider'>
        <span className='train-trip__range-slider-data'>
          <span>{minutesToHours(this.state.value.min)}</span>
        </span>

        <InputRange
          minValue={minValue}
          maxValue={1440}
          step={15}
          value={this.state.value}
          onChange={(value) => this.setState({ value })}
          onChangeComplete={this.onChangeComplete}
          classNames={{
            activeTrack: 'train-trip__range-track--active',
            disabledInputRange: 'train-trip__range-input train-trip__range--disabled',
            inputRange: 'train-trip__range-input',
            labelContainer: 'train-trip__range-label-container',
            maxLabel: 'train-trip__range-label',
            minLabel: 'train-trip__range-label',
            slider: 'train-trip__range-slider',
            sliderContainer: 'train-trip__range-slider-container',
            track: 'train-trip__range-track',
            valueLabel: 'train-trip__range-label',
          }}
          formatLabel={(value) => `${value}cm`}
          disabled={isDisabled}
        />

        <span className='train-trip__range-slider-data'>
          <span>{minutesToHours(this.state.value.max)}</span>
        </span>
      </div>
    )
  }
}

TimeComponent.propTypes = {
  filterName: PropTypes.string.isRequired,
  disabled: PropTypes.bool.isRequired,
}

TimeComponent.defaultProps = {
  disabled: false,
}

const Time = connect(TimeComponent)

export default Time
export { Time }
