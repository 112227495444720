import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import { TYPE_BORDER_CROSSING, TYPE_TRIP_END, TYPE_TRIP_START } from '../../constants'
import { trans } from '../../../../trans'

class TripStateRow extends Component<any, any> {
  renderLabel = () => {
    const { type, countryName, target } = this.props
    const country = ': ' + trans(countryName)
    switch (type) {
      case TYPE_TRIP_START:
        return trans('deductions-widget.trip-start') + country
      case TYPE_BORDER_CROSSING:
        return (
          trans('deductions-widget.border-crossing') +
          country +
          (target ? ' - ' + trans('deductions-widget.target-point') : '')
        )
      case TYPE_TRIP_END:
        return trans('deductions-widget.trip-end') + country
    }
  }

  render() {
    const { time, localTime, timezone, diff, countryCode, period, className } = this.props

    const classes = classnames({
      'declarations__crossing declarations__grid-row': true,
      ...className,
    })

    return (
      <div className={classes}>
        <div className='declarations__grid-column'>
          <span className='declarations__crossing-time'>{time}</span>
        </div>

        <div className='declarations__country-column declarations__grid-column'>
          <div className='declarations__flag-wrapper'>
            <span
              className={`declarations__flag flag flag-${countryCode.toLowerCase() || 'other'}`}
              aria-hidden='true'
            />
          </div>

          <span className='declarations__crossing-timezone'>
            {localTime} ({timezone})
          </span>
        </div>

        <div className='declarations__crossing-label declarations__grid-column'>
          <span className='declarations__crossing-label-text'>{this.renderLabel()}</span>
        </div>

        <div className='declarations__grid-column'>
          <span className='declarations__crossing-duration'>{diff}h</span>
        </div>
      </div>
    )
  }
}

TripStateRow.propTypes = {
  type: PropTypes.string.isRequired,
  time: PropTypes.string.isRequired,
  localTime: PropTypes.string.isRequired,
  timezone: PropTypes.string.isRequired,
  diff: PropTypes.number.isRequired,
  countryName: PropTypes.string.isRequired,
  countryCode: PropTypes.string.isRequired,
  period: PropTypes.number.isRequired,
}

export default TripStateRow
export { TripStateRow }
