import PropTypes from 'prop-types'
import React from 'react'
import uuid from '../../../utils/uuid'
import APIClient from '../../../services/APIClient'
import isObject from 'lodash/isObject'
import { InstallmentTimelineElement } from './InstallmentTimelineElement'
import { trans } from '../../../trans'
import { get } from 'lodash'
import { Toggle, FormGroup } from '../../../ui'
import Button from '../../ui/ButtonComponent'
import { Ability } from '../Ability/Ability'
import * as classnames from 'classnames'

class Installments extends React.Component<any, any> {
  constructor(props) {
    super(props)

    const {
      request: { installments },
    } = this.props

    this.state = {
      currencies: [],
      installments,
      errors: {},
    }
  }

  componentDidMount() {
    APIClient.getCurrencies().then((response) => {
      this.setState({ currencies: response.data })
    })
  }

  onAdd = () => {
    const { currentUser } = this.props
    const { installments } = this.state
    installments.push({
      id: uuid(),
      currency: currentUser.instance.default_currency,
      amount: null,
      isOpen: true,
      draft: true,
    })
    this.setState({ installments })
  }

  onRemove = (id) => {
    const {
      request: { slug },
    } = this.props
    let installments = this.state.installments.filter((item) => item['id'] !== id)
    const index = this.state.installments.findIndex((item) => item['id'] === id)
    const values = this.state.installments[index]

    return new Promise((resolve, reject) => {
      this.setState({ installments }, () => {
        if (!values['draft']) {
          return APIClient.removeInstallment(slug, id).then(resolve).catch(reject)
        }

        return resolve()
      })
    })
  }

  onChange = (id, ...args) => {
    let name, value
    if (isObject(args[0])) {
      name = args[0].target.name
      value = args[0].target.value
    } else {
      name = args[0]
      value = args[1]
    }

    let errors = this.state.errors
    delete errors[name]

    if (name === 'currency') {
      delete errors['currency_id']
    }

    const index = this.state.installments.findIndex((item) => item['id'] === id)

    const { installments } = this.state
    installments[index][name] = value

    this.setState({ installments, errors })
  }

  onSave = (e, id) => {
    e.preventDefault()

    const {
      request: { slug },
    } = this.props

    const index = this.state.installments.findIndex((item) => item['id'] === id)
    const values = this.state.installments[index]

    if (parseFloat(values['amount']) === 0) {
      return this.onRemove(values['id'])
    } else if (values['draft']) {
      return APIClient.createInstallment(slug, values)
        .then((response) => {
          const errors = []
          const installments = this.state.installments
          installments[index]['id'] = response.data.id
          installments[index]['draft'] = false
          installments[index]['isOpen'] = false
          this.setState({ installments, errors })
        })
        .catch((response) => {
          const errors = get(response, 'alerts.0.errors')
          if (errors) {
            this.setState({ errors: errors })
          }
        })
    } else {
      return APIClient.updateInstallment(slug, values['id'], values)
        .then(() => {
          const installments = this.state.installments
          installments[index]['isOpen'] = false
          const errors = []
          this.setState({ installments, errors })
        })
        .catch((response) => {
          const errors = get(response, 'alerts.0.errors')
          if (errors) {
            this.setState({ errors: errors })
          }
        })
    }
  }

  renderElements() {
    const { installments, currencies, errors } = this.state
    const { request } = this.props

    return installments.map((installment) => {
      return (
        <InstallmentTimelineElement
          key={installment['id']}
          values={installment}
          currencies={currencies}
          onRemove={this.onRemove}
          onSave={this.onSave}
          onChange={this.onChange}
          errors={errors}
          request={request}
        />
      )
    })
  }

  render() {
    const elements = this.renderElements()

    return (
      <section className='section advance-payment' data-test='installments'>
        <header className='section__header'>
          <h2 className='h2 section__header-title'>
            {trans('request.installment')}
            <Ability ability={['edit']}>
              <Button primary icon='plus2' className='advance-icon' onClick={this.onAdd} />
            </Ability>
          </h2>
        </header>
        <div className='advance-payment__elements'>{elements}</div>
      </section>
    )
  }
}

Installments.propTypes = {
  request: PropTypes.object.isRequired,
  currentUser: PropTypes.object.isRequired,
}

export { Installments }
export default { Installments }
