import React from 'react'
import { reduxForm, SubmissionError } from 'redux-form/immutable'
import { connect } from 'react-redux'
import { fromJS } from 'immutable'
import { bindActionCreators } from 'redux'
import { processAPIerrorResponseToFormErrors } from '../../../../services/APIClient'
import { prepareRequestDates } from '../../../../utils/prepareRequestDates'
import { Factory as ElementFactory } from '../../../../models/timeline/index'
import { getFormErrors, getFormValues } from '../../../../utils/forms'
import { DateSuggester } from '../../../../store/app/trip-timeline/services/date-suggester'
import { getCurrency } from '../../../../store/app/instance'
import { selectOffer, toggleSearch } from '../../../../store/app/trains-booking/creators'
import {
  getSearchUuid,
  getSelectedOffer,
  isSearchEnabled,
} from '../../../../store/app/trains-booking/selectors'
import { TrainLocationSuggester } from '../../../../store/app/trip-timeline/services/train-location-suggester'
import { getRequestTravelers } from '../../../../store/app/request-traveler'
import { get, isEmpty } from 'lodash'
import { setEndOfDay } from '../../../../utils/setEndOfDay'
import { Train as Form } from '../Forms/Train'

class Train extends React.Component<any, any> {
  render() {
    if (!this.props.initialized) {
      return null
    }

    return <Form {...this.props} />
  }
}

const saveTrainOffer = (props, element, dispatch) => {
  if (props.searchUuid && props.selectedOffer) {
    const option = props.selectedOffer.option
    if (option) {
      dispatch(
        selectOffer(props.train.key)(props.request, {
          search_uuid: props.searchUuid,
          offer: props.selectedOffer,
          option,
          request_element: element,
          attributes: {
            carriage_type: props.train.carriage_type,
            seat_coach_number: props.train.seat_coach_number,
            seat_location: props.train.seat_location,
            seat_type: props.train.seat_type,
            seat_place_number: props.train.seat_place_number,
          },
        }),
      )
    }
  }
}

export const submit = (values, dispatch, props) => {
  const { onSave, request, change } = props

  values = prepareRequestDates(values, [
    'target_real_arrival_at',
    'target_real_departure_at',
    'return_real_arrival_at',
    'return_real_departure_at',
  ])
  values.return_at = setEndOfDay(values.return_at, true)

  return onSave(request, values, props.element).then(
    (element) => {
      dispatch(change('isOpen', false))
      saveTrainOffer(props, element, dispatch)
    },
    (alerts) => {
      throw new SubmissionError(processAPIerrorResponseToFormErrors(alerts))
    },
  )
}

export const change = (values, dispatch, props) => {
  const { change } = props
  if (values.get('return_at') < values.get('departure_at')) {
    dispatch(change('return_at', values.get('departure_at')))
  }
}

const withForm = reduxForm({
  onSubmit: submit,
  onChange: change,
  destroyOnUnmount: false,
})(Train)

const hasAvailableOption = (option, key) => {
  const availableOption = get(option, 'availableOptions.' + key, [])

  if (availableOption === true) {
    return true
  }

  return availableOption.length > 0
}

const initValue = (option, key, initValue) => {
  if (hasAvailableOption(option, key)) {
    return get(option, 'requestedAttributes.' + key, initValue)
  } else {
    return initValue
  }
}

const mapStateToProps = (state, props) => {
  const { request, element, currencies } = props

  const travelers = getRequestTravelers(state)
  const defaultTravelers = travelers.map((traveler) => traveler.slug)

  const train = ElementFactory.create(element)
  const { key, draft } = train

  const currentValues = getFormValues(key, state)
  const currentTrain = !isEmpty(currentValues) ? ElementFactory.create(currentValues) : train

  const dateSuggester = new DateSuggester(state, currentTrain)
  const locationSuggester = new TrainLocationSuggester(state, currentTrain)

  const selectedOffer = getSelectedOffer(key)(state)
  const instanceCurrency = getCurrency(state)
  const option = get(selectedOffer, 'option', null)

  return {
    initialValues: fromJS({
      uuid: train.uuid,
      departure_at: draft ? dateSuggester.suggestStartDate() : train.getStartDate(),
      return_at: draft ? dateSuggester.suggestEndDate() : train.getEndDate(),
      destination_location: draft ? locationSuggester.suggestEndLocation() : train.getEndLocation(),
      departure_location: draft
        ? locationSuggester.suggestStartLocation()
        : train.getStartLocation(),
      amount: train.amount,
      amount_currency: !draft ? train.amount_currency : instanceCurrency,
      type: train.type,
      id: train.id,
      converted_amount: train.converted_amount,
      calculated_amount_currency: train.calculated_amount_currency
        ? train.calculated_amount_currency
        : instanceCurrency,
      round_trip: train.round_trip,
      draft: draft,
      virtual: train.virtual,
      acceptance_source: train.acceptance_source,
      searcher_disabled: train.searcher_disabled,
      request_travelers: train.request_travelers.length
        ? train.request_travelers
        : defaultTravelers,
      seat_location: initValue(option, 'seat_location', 'WINDOW'),
      seat_type: initValue(option, 'seat_type', 'ANY'),
      seat_place_number: initValue(option, 'seat_place_number', null),
      seat_coach_number: initValue(option, 'seat_coach_number', null),
      carriage_type: initValue(option, 'carriage_type', null),
      offer_uuid: train.offer_uuid,
      target_real_departure_at: train.target_real_departure_at,
      target_real_arrival_at: train.target_real_arrival_at,
      return_real_departure_at: train.return_real_departure_at,
      return_real_arrival_at: train.return_real_arrival_at,
    }),
    form: key,
    errors: getFormErrors(props.form, state),
    request,
    train: currentTrain,
    currencies,
    minDate: dateSuggester.suggestMinDate(),
    maxDate: dateSuggester.suggestMaxDate(),
    maxStartDate: dateSuggester.suggestMaxStartDate(),
    instanceCurrency,
    selectedOffer,
    searchUuid: getSearchUuid(key)(state),
    isSearchEnabled: isSearchEnabled(key)(state),
    isOpen: props.isOpen,
    travelers,
  }
}

const mapDispatchToProps = (dispatch, props) => {
  const { element } = props
  const train = ElementFactory.create(element)

  return bindActionCreators(
    {
      toggleSearch: toggleSearch(train.key),
    },
    dispatch,
  )
}

const connected = connect(mapStateToProps, mapDispatchToProps)(withForm)

Train = connected

export { Train }
export default { Train }
