import React from 'react'
import { connect } from 'formik'
import { FormGroup, Input } from '../../../ui'
import Error from '../../../ui/Input/Error'
import { trans } from '../../../trans'
import { SelectField } from '../../ui/Form'

export default connect((props) => {
  const { label, name, languages, disabled = false } = props

  const options = languages.map((lang) => ({
    value: lang,
    label: trans('languages.' + lang),
  }))

  return (
    <div>
      <FormGroup label={label} labeltop>
        <SelectField
          name={name}
          options={options}
          value={props.formik.values[name]}
          onChange={(value) => props.formik.setFieldValue(name, value)}
          disabled={disabled}
          placeholder={trans('user.select-language')}
        />

        <Error errors={props.formik.errors[name]} />
      </FormGroup>
    </div>
  )
})
