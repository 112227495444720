import PropTypes from 'prop-types'
import React from 'react'
import trans from '../../../trans'
import { FormattedNumberField } from './index'
import { isObject } from 'lodash'
import numeral from 'numeral'
import { AmountFormatter } from '../../AmountFormatter'

export class AmountField extends React.Component<any, any> {
  render() {
    const { suggestedAmount, suggestedAmountCurrency, suggestedAmountLabel, multiplier, ...input } =
      this.props

    let suggestion = suggestedAmount
    let label = suggestedAmountLabel

    if (!label) {
      label = trans('request.suggested-value-of-expense')
    }

    return (
      <div>
        <FormattedNumberField {...input} />
        {suggestion && Number(input.value) !== Number(suggestion * multiplier) ? (
          <div className='input-suggestion'>
            <span>
              {label}: <AmountFormatter amount={suggestion} />
              &nbsp;{suggestedAmountCurrency}
            </span>
            <a
              className=''
              onClick={() => {
                input.onChange(suggestion * multiplier)
              }}
            >
              {trans('ui.accept')}
            </a>
          </div>
        ) : null}
      </div>
    )
  }
}

AmountField.propTypes = {
  onChange: PropTypes.func,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  value: PropTypes.any,
  suggestedAmount: PropTypes.any,
  suggestedAmountCurrency: PropTypes.string,
  multiplier: PropTypes.number,
}

AmountField.defaultProps = {
  suggestedAmount: null,
  suggestedAmountCurrency: 'PLN',
  multiplier: 1,
}
