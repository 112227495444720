import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Icon from '../ui/IconComponent'
import { AmountFormatter } from '../AmountFormatter'
import { InputValid } from '../../ui'
import { InputErrorComponent } from '../InputErrorComponent'
import isTouchDevice from '../../utils/isTouchDevice'

class EditableAmount extends Component<any, any> {
  constructor(props) {
    super(props)

    this.state = {
      isEditing: false,
      amountError: null,
      amount: this.props.amount,
    }
  }

  setEditing = (e) => {
    e.preventDefault()

    if (this.props.canEdit) {
      this.setState({ isEditing: true })
    }
  }

  renderAmount = () => {
    const { canEdit } = this.props

    return (
      <div
        className='table-accordion__installment-date'
        style={{ justifyContent: 'flex-end' }}
        onClick={this.setEditing}
      >
        <span>
          <AmountFormatter amount={this.props.amount} />
        </span>
        {canEdit && (
          <span className='timeline-icon' onClick={this.setEditing}>
            <Icon type='edit' lg gradient />
          </span>
        )}
      </div>
    )
  }

  onChange = (amount) => {
    this.setState({ amount, amountError: null })
  }

  submitChange = () => {
    this.props
      .onChange(this.state.amount, this.props.currency)
      .then(() => this.setState({ isEditing: false }))
      .catch((error) => this.setState({ amountError: this.props.onError(error) }))
  }

  handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault()

      this.submitChange()
    }
  }

  renderInput = () => {
    return (
      <div className='table-accordion__row-edit' onClick={(e) => e.preventDefault()}>
        <InputValid
          name='amount'
          autoFocus
          placeholder={'0,00'}
          value={this.props.amount}
          options={{ numeral: true, numeralThousandsGroupStyle: 'none' }}
          onChange={(value) => this.onChange(value)}
          onBlur={this.submitChange}
          onKeyDown={this.handleKeyDown}
        >
          <InputErrorComponent errors={this.state.amountError} />
        </InputValid>

        {isTouchDevice() && (
          <span className='timeline-icon' onClick={this.submitChange}>
            <Icon type='checked' lg gradient />
          </span>
        )}
      </div>
    )
  }

  render() {
    return this.state.isEditing ? this.renderInput() : this.renderAmount()
  }
}

EditableAmount.propTypes = {
  amount: PropTypes.string,
  currency: PropTypes.string,
  canEdit: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  onError: PropTypes.func.isRequired,
}

export default EditableAmount
export { EditableAmount }
