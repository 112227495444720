import React from 'react'
import { trans } from '../../trans'
import { SectionHeader } from '../ui/SectionHeader'
import { Table, THead, TBody, Row, Cell } from '../ui/AccordionTable'
import { round } from '../../utils/number'
import { AmountFormatter } from '../AmountFormatter'
import SocketProviderService from '../../utils/SocketProvider'

interface RequestAccountingSummaryProps {
  accountingBalance: any
  documents: any
  request: any
  changeRequestValue: Function
}

class RequestAccountingSummary extends React.Component<RequestAccountingSummaryProps, any> {
  private channelName: string
  private eventName: string

  componentDidMount() {
    const {
      request: { slug },
    } = this.props

    this.subscribe(slug)
  }

  componentWillUnmount() {
    SocketProviderService.unsubscribe(this.channelName)(this.eventName)
  }

  subscribe(slug) {
    this.channelName = `App.Request.${slug}.AccountingBalance`
    this.eventName = `.App\\Events\\Request\\RequestAccountingBalanceChangedEvent`

    SocketProviderService.subscribe(this.channelName)(this.eventName)((accountingBalance) => {
      this.props.changeRequestValue(['accountingBalance'], accountingBalance)
    })
  }

  renderAccountingRow(element) {
    const value = element[`${element.type}Amount`]
    return (
      <Row
        key={`${element.name}-${element.type}`}
        thin
        className='basic-summary__element-row decretation'
      >
        <Cell xs={6}>
          <span className='decretation__text-line'>
            {element['number'] + ' ' + trans(element['name'])}
          </span>
        </Cell>
        <Cell xs={3} alignRight>
          <span className='decretation__text-line'>
            {element['type'] === 'db' && <AmountFormatter amount={round(value, 2)} />}
          </span>
        </Cell>
        <Cell xs={3} alignRight>
          <span className='decretation__text-line'>
            {element['type'] === 'ct' && <AmountFormatter amount={round(value, 2)} />}
          </span>
        </Cell>
      </Row>
    )
  }

  renderAccounting({ accountingPart1, accountingPart2, requestDocuments }) {
    const parts = [accountingPart1]
    const reverseTax = {
        hasReverseTax: false,
        CR: {
            account_number: '',
            name: '',
            value: 0
        },
        DR: {
            account_number: '',
            name: '',
            value: 0
        }
    }
    if (requestDocuments){
        requestDocuments.map((document) => {
            if (document.reverse_tax) {
                reverseTax.hasReverseTax = true
                let firstElement = document.elements[0]
                reverseTax.DR.account_number = firstElement.vatNumber.accountingAccount.account_number
                reverseTax.CR.account_number = firstElement.vatNumber.reverse_accounting_account.account_number
                reverseTax.DR.name = firstElement.vatNumber.accountingAccount.name
                reverseTax.CR.name = firstElement.vatNumber.reverse_accounting_account.name
                reverseTax.DR.value += document.reverse_tax
                reverseTax.CR.value += document.reverse_tax
            }
        })
    }

    return (
      <div>
        <SectionHeader caption={trans('request-summary.accounting')} noMargin />

        {parts.map((part, i) => {
          let partOutput = null
          if (part.elements && part.elements.length) {
            const dbSum = reverseTax.hasReverseTax ? Number(part['dbSum']) + Number(reverseTax.DR.value) : Number(part['dbSum'])
            const ctSum = reverseTax.hasReverseTax ? Number(part['ctSum']) + Number(reverseTax.CR.value) : Number(part['ctSum'])
            partOutput = (
              <div key={i}>
                <Table>
                  <THead noBorder>
                    <Row thin>
                      <Cell xs={6}></Cell>
                      <Cell xs={3} alignRight style={{ paddingRight: '8px' }}>
                        <span className='accounting__header'>{trans('request-summary.DB')}</span>
                      </Cell>
                      <Cell xs={3} alignRight style={{ paddingRight: '8px' }}>
                        <span className='accounting__header'>{trans('request-summary.CT')}</span>
                      </Cell>
                    </Row>
                  </THead>
                  <TBody>
                    {part.elements.map((element) => this.renderAccountingRow(element))}
                    {reverseTax.hasReverseTax ? this.renderReverseTax(reverseTax) : ''}
                    <Row className='summary-table' thin>
                      <Cell xs={6}></Cell>
                      <Cell xs={3} alignRight>
                        <strong>
                          <AmountFormatter amount={round(dbSum, 2)} />
                        </strong>
                      </Cell>
                      <Cell xs={3} alignRight>
                        <strong>
                          <AmountFormatter amount={round(ctSum, 2)} />
                        </strong>
                      </Cell>
                    </Row>
                  </TBody>
                </Table>
              </div>
            )
          }

          return partOutput
        })}
      </div>
    )
  }
    private renderReverseTax(reverseTax) {
      return (
        <>
          <Row className='basic-summary__element-row decretation' thin>
            <Cell xs={6}>{reverseTax.DR.account_number + ' ' + reverseTax.DR.name}</Cell>
            <Cell xs={3} alignRight>{reverseTax.DR.value}</Cell>
            <Cell xs={3} alignRight></Cell>
          </Row>
          <Row className='basic-summary__element-row decretation' thin>
            <Cell xs={6}>{reverseTax.CR.account_number + ' ' + reverseTax.CR.name}</Cell>
            <Cell xs={3} alignRight></Cell>
            <Cell xs={3} alignRight>{reverseTax.CR.value}</Cell>
          </Row>
        </>
      )
    }

  render() {
    const {
      accountingBalance: { accountingPart1, accountingPart2 },
      documents: requestDocuments
    } = this.props

    return this.renderAccounting({ accountingPart1, accountingPart2, requestDocuments })
  }
}

export default { RequestAccountingSummary }
export { RequestAccountingSummary }
