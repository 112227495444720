import React, { Component, Fragment } from 'react'
import { connect } from '../../containers/LoginAs/connect'
import Icon from '../ui/IconComponent'
import SimpleLoader from '../ui/SimpleLoader'
import classNames from 'classnames'
import { trans } from '../../trans'
import Avatar from '../Avatar/Avatar'

class CurrentSuperior extends Component<any, any> {
  constructor(props) {
    super(props)
  }

  render() {
    if (!this.props.loginAs.selectors.users || !this.props.loginAs.selectors.users.length) {
      return null
    }

    const {
      loginAs: {
        selectors: { isLoginInProgress, users },
      },
    } = this.props
    const currentSuperior = users.find((user) => user.current)

    const classes = classNames({ 'is-invisible': isLoginInProgress })
    const dropdownHeader = currentSuperior
      ? `${currentSuperior.first_name} ${currentSuperior.last_name}`
      : trans('global.switch-on')

    return (
      <Fragment>
        <Avatar className='profile-dropdown__header-img' user={currentSuperior} />

        <span className='profile-dropdown__header-name'>{dropdownHeader}</span>
        <div className={classes}>
          <Icon type='arrow' />
        </div>
        {isLoginInProgress && (
          <div style={{ position: 'absolute', right: '-18px' }}>
            <SimpleLoader />
          </div>
        )}
      </Fragment>
    )
  }
}
export default connect(CurrentSuperior)
export { CurrentSuperior }
