import { stringify } from 'query-string'
import { FlightsRequest } from './FlightsRequest'

class FlightsSortRequest extends FlightsRequest {
  constructor(searchUUID, sort) {
    super()
    this.searchUUID = searchUUID
    this.sort = sort
  }

  toString() {
    return stringify({
      uuid: this.searchUUID,
      sort: this.sort,
    })
  }
}

export default FlightsSortRequest
export { FlightsSortRequest }
