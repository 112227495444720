import PropTypes from 'prop-types'
import React from 'react'
import { StateTextButton } from '../ui/StateTextButton'
import { SelectField, DatePickerField } from '../ui/Form'
import {
  getStatusesForSelect,
  STATUS_ACCOUNTING,
  STATUS_ACCEPTANCE_OF_SETTLEMENT,
} from '../../constants/request'
import trans from '../../trans'
import Icon from '../../components/ui/IconComponent'
import { AsyncSelectField } from '../ui/Form/AsyncSelectField'
import { loadOptionsForUsers } from '../../store/app/users-for-select'
import { fetchMpkForAsyncSelect } from '../../store/app/mpks'
import classnames from 'classnames'
import { isEmpty } from 'lodash'
import ToggleableFilters from '../ToggleableFilters/ToggleableFilters'
import ToggleableBottomFilters from '../ToggleableFilters/ToggleableBottomFilters'
import ToggleableQuickFilters from '../ToggleableFilters/ToggleableQuickFilters'
import { components } from '../../utils/react-select'
import UserSelectOption from '../../form/UserField/UserFieldOption'
import UserField from '../../form/UserField/UserField'

// For Page Trips.
class TripRequestsListFilters extends React.Component<any, any> {
  constructor(props) {
    super(props)

    this.timer = null

    this.fetchMpkForAsyncSelect = fetchMpkForAsyncSelect()
  }

  render() {
    const { filters, onChangeFilter } = this.props
    const filtersCount = Object.keys(filters).length

    const bottomFilters = (
      <>
        <div className='filters__input-wrapper'>
          <span className='filters__input-title'>{trans('global.filter-status')}</span>
          <SelectField
            options={getStatusesForSelect()}
            value={filters['statuses']}
            onChange={(value) => {
              return onChangeFilter('statuses', value)
            }}
            placeholder={trans('global.filter-placeholder-all')}
            isMulti
            clearable={true}
          />
        </div>

        <div className='filters__input-wrapper'>
          <span className='filters__input-title'>{trans('global.filter-owner')}</span>

          <UserField
            value={filters['users']}
            onChange={(value) => onChangeFilter('users', value)}
            loadItems={loadOptionsForUsers}
            isMulti
            clearable
          />
        </div>

        <div className='filters__input-wrapper'>
          <span className='filters__input-title'>{trans('global.filter-mpk')}</span>
          <AsyncSelectField
            loadOptions={this.fetchMpkForAsyncSelect}
            value={filters['mpks']}
            onChange={(value) => onChangeFilter('mpks', value)}
            placeholder={trans('global.filter-placeholder-all')}
            noOptionsMessage={() => {
              return trans('user.no-results-for-search-message')
            }}
            loadingMessage={() => {
              return trans('user.searching-message')
            }}
            delay={500}
            returnFullItemValueOnChange
            isMulti
            clearable={true}
          />
        </div>

        <div className='filters__input-wrapper'>
          <span className='filters__input-title'>{trans('global.filter-period')}</span>
          <div className='filters__period-container'>
            <DatePickerField
              placeholder={trans('global.datepicker-placeholder')}
              onChange={(value) => onChangeFilter('period_start', value)}
              isClearable={true}
              value={filters['period_start']}
            />
            <span className='filters__separator'>-</span>
            <DatePickerField
              placeholder={trans('global.datepicker-placeholder')}
              onChange={(value) => onChangeFilter('period_end', value)}
              isClearable={true}
              minDate={filters['period_start']}
              value={filters['period_end']}
            />
          </div>
        </div>
      </>
    )

    return (
      <form>
        <ToggleableFilters filtersCount={filtersCount}>
          {({ isOpen }) => (
            <>
              <ToggleableQuickFilters>
                <div className='input-group'>
                  <Icon type='search' lg />
                  <input
                    type='text'
                    placeholder={trans('ui.search')}
                    className='input-group__input'
                    ref={(e) => (this.searchInput = e)}
                    defaultValue={filters['phrase']}
                    onChange={() => {
                      clearTimeout(this.timer)
                      this.timer = setTimeout(() => {
                        onChangeFilter('phrase', this.searchInput.value)
                      }, 250)
                    }}
                  />
                </div>

                <div className='filters__quick'>
                  <span className='filters__quick-title'>{trans('global.quick-filters')}:</span>

                  <StateTextButton
                    onClick={(activate) => {
                      onChangeFilter('only_mine', activate ? true : null)
                    }}
                    active={filters['only_mine'] === true}
                  >
                    {trans('global.filter-my')}
                  </StateTextButton>

                  <StateTextButton
                    onClick={(activate) => {
                      onChangeFilter('statuses', activate ? STATUS_ACCEPTANCE_OF_SETTLEMENT : null)
                    }}
                    active={filters['statuses'] === STATUS_ACCEPTANCE_OF_SETTLEMENT}
                  >
                    {trans('global.filter-waiting-for-settlement')}
                  </StateTextButton>

                  <StateTextButton
                    onClick={(activate) => {
                      onChangeFilter('statuses', activate ? STATUS_ACCOUNTING : null)
                    }}
                    active={filters['statuses'] === STATUS_ACCOUNTING}
                  >
                    {trans('global.filter-to-decretation')}
                  </StateTextButton>
                </div>
              </ToggleableQuickFilters>

              <ToggleableBottomFilters isOpen={isOpen}>{bottomFilters}</ToggleableBottomFilters>
            </>
          )}
        </ToggleableFilters>
      </form>
    )
  }
}

TripRequestsListFilters.propTypes = {
  filters: PropTypes.object.isRequired,
  onChangeFilter: PropTypes.func.isRequired,
  currentUser: PropTypes.object.isRequired,
  MPKs: PropTypes.array.isRequired,
}

export default { TripRequestsListFilters }
export { TripRequestsListFilters }
