import PropTypes from 'prop-types'
import React from 'react'
import { RequestHeader } from '../../RequestPageCommon/RequestHeader'
import { TripRequestSummary } from '../TripRequestSummary'
import { RequestComments } from '../../RequestPageCommon/RequestComments'
import RequestCommentManager from '../../../containers/RequestCommentManager/RequestCommentManager'
import BasicRequestInfoRenderer from '../../RequestBasicInfoRenderer/RequestBasicInfoRenderer'

class TripRequestPageCancelled extends React.Component<any, any> {
  render() {
    const { request, currentUser, isModuleActive } = this.props

    return (
      <div>
        <RequestHeader request={request} />

        <BasicRequestInfoRenderer />

        <TripRequestSummary request={request} />

        <RequestCommentManager request={request}>
          {({ comments }) => {
            return <RequestComments comments={comments} />
          }}
        </RequestCommentManager>
      </div>
    )
  }
}

TripRequestPageCancelled.propTypes = {
  request: PropTypes.object.isRequired,
  currentUser: PropTypes.object.isRequired,
  isModuleActive: PropTypes.func.isRequired,
}

export { TripRequestPageCancelled }
export default { TripRequestPageCancelled }
