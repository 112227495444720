import React, { Component } from 'react'
import Icon from './ui/IconComponent'
import { times, isFunction } from 'lodash'
import PropTypes from 'prop-types'

class StarsRating extends Component<any, any> {
  constructor(props) {
    super(props)
    this.state = {
      hover: isNaN(this.props.rate) ? 0 : this.props.rate,
    }
  }

  componentDidUpdate = (prevProps, prevState) => {
    if (prevProps.rate !== this.props.rate && !(isNaN(prevProps.rate) && isNaN(this.props.rate))) {
      this.setState({ hover: isNaN(this.props.rate) ? 0 : this.props.rate })
    }
  }

  onMouseEnter = (hover) => {
    this.setState({ hover })
  }

  onMouseLeave = () => {
    this.setState({ hover: isNaN(this.props.rate) ? 0 : this.props.rate })
  }

  onClick = (index) => {
    if (isFunction(this.props.onChange)) {
      this.props.onChange(index)
    }
  }

  render() {
    return times(this.props.stars, (index) => {
      const rate = index + 1
      const type = rate > this.state.hover ? 'star' : 'star_fill'

      return (
        <Icon
          className='icon-star_fill--yellow'
          key={index}
          type={type}
          onMouseEnter={() => this.onMouseEnter(rate)}
          onMouseLeave={() => this.onMouseLeave()}
          onClick={() => this.onClick(rate)}
        />
      )
    })
  }
}

StarsRating.propTypes = {
  stars: PropTypes.number.isRequired,
  rate: PropTypes.number.isRequired,
  onChange: PropTypes.func,
}

export default StarsRating
export { StarsRating }
