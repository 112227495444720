import React from 'react'
import { connect as connectFlights } from '../../../containers/FlightsBooking'
import ActionButtons from '../Booking/ActionButtons'
import trans from '../../../trans'
import { Col, Row } from '../../../ui/Grid'
import AmountFields from '../AmountField'
import QueryFields from '../Reservation/QueryFields'

const SearchFormComponent = (props) => {
  const {
    context: { element },
    currencies,
  } = props
  const query = {
    flight_from: element.flight_from,
    flight_to: element.flight_to,
    arrival_at: element.arrival_at,
    return_at: element.return_at,
    round_trip: element.round_trip,
    flight_class: element.flight_class,
    direct_only: element.direct_only,
    restrict_to_fba: element.restrict_to_fba,
    request_travelers: element.request_travelers,
  }

  return (
    <div>
      {props.flights.selectors.searchFailed() && (
        <div className='plane-offer__reservation-status'>
          <span className='reservation-status reservation-status--error'>
            {trans('global.search-failed')}
          </span>
        </div>
      )}

      <div className='plane-trip__dashboard'>
        <QueryFields />

        <Row>
          <AmountFields plane={element} currencies={currencies} />
        </Row>

        <Row className='is-margin-top'>
          <Col sm={12}>
            <ActionButtons query={query} />
          </Col>
        </Row>
      </div>
    </div>
  )
}

const SearchForm = connectFlights(SearchFormComponent)

export { SearchForm }
export default SearchForm
