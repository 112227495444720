import React from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import Icon from '../../components/ui/IconComponent'

export const Alert = ({ className, children, handleAcceptClick, handleRejectClick, ...props }) => {
  const AlertClasses = classNames(className, {
    alert: true,
  })

  return (
    <div className={AlertClasses}>
      <span className='alert__content'>{children}</span>
      <div className='alert__controls'>
        <span className='alert__controls-icon' onClick={handleAcceptClick}>
          <Icon type='checked_fill' className='is-gradient-success' />
        </span>
        <span className='alert__controls-icon' onClick={handleRejectClick}>
          <Icon type='close_fill' className='is-gradient-danger' />
        </span>
      </div>
    </div>
  )
}

Alert.propTypes = {
  className: PropTypes.string,
  children: PropTypes.any.isRequired,
  handleAcceptClick: PropTypes.func.isRequired,
  handleRejectClick: PropTypes.func.isRequired,
}
