import PropTypes from 'prop-types'
import React from 'react'
import _, { isInteger } from 'lodash'
import classnames from 'classnames'
import { components } from '../../../utils/react-select'
import { AsyncPaginate, wrapMenuList } from 'react-select-async-paginate'
import CreatableSelect from 'react-select/creatable'
import { CustomAsyncPaginateComponent } from '../../../utils/react-select-async-paginate'

const MenuList = wrapMenuList(components.MenuList)

export const ASYNC_SELECT_DEFAULT_LIMIT = 20

interface AsyncSelectFieldProps {
  allowCreateNewOption?: boolean
  [key: string]: any
}

export class AsyncSelectField extends React.Component<AsyncSelectFieldProps, any> {
  constructor(props) {
    super(props)
    this.divRef = null
    this.state = {
      isOpen: false,
      value: null,
    }
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside)

    this.setState({
      value: this.props.value,
    })
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside)
  }

  componentDidUpdate(prevProps: Readonly<any>, prevState: Readonly<any>, snapshot?: any) {
      if (this.props.value !== prevProps.value) {
          this.setState({
              value: this.props.value,
          })
      }
  }

  handleClickOutside = (event) => {
    if (this.state.isOpen && this.divRef && !this.divRef.contains(event.target)) {
      this.close()
    }
  }

  open() {
    if (this.props.onOpen) this.props.onOpen()
    this.setState({ isOpen: true })
  }

  close() {
    if (this.props.onClose) this.props.onClose()
    this.setState({ isOpen: false })
  }

  toggleOpen(e) {
    const { isOpen } = this.state
    const { disabled } = this.props

    e.preventDefault()

    if (disabled) {
      return
    }

    if (isOpen) {
      this.close()
    } else {
      this.open()
    }
  }

  onTouchStart(e) {
    const { disabled } = this.props

    if (disabled) {
      return
    }

    if (e.target.classList.contains('react-select__dropdown-indicator')) {
      this.toggleOpen(e)
    }
  }

  render() {
    const {
      loadOptions,
      name,
      className,
      value,
      defaultOptions,
      placeholder,
      disabled,
      onFocus,
      noBorder,
      emptyValue,
      clearable,
      labeltop,
      onBlur,
      onChange,
      nested,
      maxMenuHeight,
      allowCreateNewOption,
      ...rest
    } = this.props

    const classes = classnames({
      'react-select': true,
      'react-select--no-border': noBorder,
      [className]: true,
    })

    const delay = this.props.delay ? this.props.delay : 500
    const displayDelay = isInteger(this.props.displayDelay) ? this.props.displayDelay : delay
    const Component = allowCreateNewOption ? CustomAsyncPaginateComponent : AsyncPaginate

    return (
      <div className='react-select__container--outer' ref={(ref) => (this.divRef = ref)}>
        <Component
          name={name}
          value={this.state.value}
          defaultOptions={defaultOptions}
          loadOptions={loadOptions}
          className={classes}
          debounceTimeout={delay}
          onChange={(item) => {
            this.setState({ value: item })

            if (_.isFunction(onChange)) {
              onChange(item ? (this.props.returnFullItemValueOnChange ? item : item.value) : null)

              this.close()
            }
          }}
          placeholder={emptyValue ? emptyValue.label : placeholder}
          isDisabled={disabled}
          onFocus={onFocus}
          isClearable={clearable && !disabled}
          components={{
            ...components,
            MenuList,
          }}
          onBlur={onBlur}
          maxMenuHeight={maxMenuHeight ? maxMenuHeight : 300}
          noOptionsMessage={this.props.noOptionsMessage}
          loadingMessage={this.props.loadingMessage}
          {...rest}
        />
      </div>
    )
  }
}

AsyncSelectField.propTypes = {
  onChange: PropTypes.func,
  name: PropTypes.string,
  loadOptions: PropTypes.func,
  defaultOptions: PropTypes.array,
  className: PropTypes.string,
  value: PropTypes.any,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  noBorder: PropTypes.bool,
  nested: PropTypes.bool,
  delay: PropTypes.number,
  minChars: PropTypes.number,
  noOptionsMessage: PropTypes.func,
  loadingMessage: PropTypes.func,
  returnFullItemValueOnChange: PropTypes.bool,
}

AsyncSelectField.defaultProps = {}
