import React from 'react'
import { bindActionCreators, compose } from 'redux'
import { connect } from 'react-redux'
import { getRules } from '../../store/app/trip-request-summary'

const summaryRules = () => (Component) => {
  class SummaryRules extends React.Component<any, any> {
    render() {
      return <Component<any, any> {...this.props} />
    }
  }

  const mapStateToProps = (state) => ({
    rules: getRules(state),
  })

  const mapDispatchToProps = (dispatch, props) => {
    return bindActionCreators({}, dispatch)
  }

  const mergedProps = (selectors, actions, own) => {
    return {
      ...own,
      summaryRules: {
        selectors,
        actions,
      },
    }
  }

  const withConnect = connect(mapStateToProps, mapDispatchToProps, mergedProps)

  return compose(withConnect)(SummaryRules)
}

export { summaryRules }
export default summaryRules
