import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators, compose } from 'redux'
import store from '../../store'
import {
  addElement,
  fetchElements,
  fetchTypes,
  getElements,
  getIsLoading,
  getTypes,
  isElementOpen,
  isSaving,
  removeCost,
  removeElement,
  reset,
  toggleElement,
  setElements,
} from '../../store/app/request-other-costs'
import { isUndefined } from 'lodash'
import { ifDeepDiff } from '../../utils/javascript'
import * as constants from '../../constants/request'
import socketProvider from '../SocketProvider'

class OtherCostsManager extends React.Component<any, any> {
  constructor(props) {
    super(props)
  }

  componentWillUnmount() {
    const { reset } = this.props
    reset()

    if (this.props.request.type === 'expense') {
      this.props.socket.unsubscribe(this.channelName)(this.eventName)
    }
  }

  componentDidMount() {
    const { reset, fetchTypes, request, fetchElements } = this.props
    reset()
    fetchElements(request.slug)
    fetchTypes(request)

    if (request.type === 'expense') {
      this.subscribe()
    }
  }

  subscribe() {
    this.channelName = `App.Request.${this.props.request.slug}`
    this.eventName = '.App\\Events\\DocumentOptoked'

    this.props.socket.subscribe(this.channelName)(this.eventName)((data) => {
      // cast object to array and remove "socket" prop by checking if key is a number
      const elements = Object.keys(data)
        .filter((a) => !isNaN(parseInt(a)))
        .map((a) => data[a])

      this.props.reset()
      this.props.setElements(elements)
    })
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const {
      request: { slug },
      fetchElements,
    } = this.props
    if (
      prevProps['request'] &&
      this.props['request'] &&
      this.props['request'].status !== constants.STATUS_DRAFT
    ) {
      if (ifDeepDiff(prevProps['request'], this.props['request'])) {
        fetchElements(slug)
      }
    }
  }

  onAddHandler(index) {
    const { addElement } = this.props
    addElement(index)
  }

  onRemoveHandler(element) {
    const { request, removeCost } = this.props
    removeCost(request, element)
  }

  onEditHandler(element) {
    const { toggleElement } = this.props
    toggleElement(element, true)
  }

  toggleHandler(element, storage = false) {
    const { toggleElement, isElementOpen } = this.props

    let state = element.isOpen
    if (isUndefined(element.isOpen)) {
      state = isElementOpen(element)
    }

    if (state === null) {
      state = false
    } else {
      state = !state
    }

    toggleElement(element, state, storage)
  }

  render() {
    const { children, request, elements, types, isLoading, isElementOpen, isSaving } = this.props
    const renderProps = {
      request,
      elements,
      types,
      isLoading,
      onAdd: this.onAddHandler.bind(this),
      onEdit: this.onEditHandler.bind(this),
      onRemove: this.onRemoveHandler.bind(this),
      toggle: this.toggleHandler.bind(this),
      isElementOpen,
      isSaving,
    }

    return <div data-test='other-costs'>{children(renderProps)}</div>
  }
}

const mapStateToProps = (state) => ({
  elements: getElements(state),
  types: getTypes(state),
  isLoading: getIsLoading(state),
  isElementOpen: (element) => isElementOpen(element),
  isSaving: isSaving(state),
})

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      reset,
      fetchTypes,
      fetchElements,
      removeElement,
      toggleElement,
      addElement,
      removeCost,
      setElements,
    },
    dispatch,
    store.getState,
  )
}

const withConnect = connect(mapStateToProps, mapDispatchToProps)
const withSocketProvider = socketProvider()

OtherCostsManager = compose(withConnect, withSocketProvider)(OtherCostsManager)

OtherCostsManager.propTypes = {
  request: PropTypes.object.isRequired,
}

export { OtherCostsManager }
export default { OtherCostsManager }
