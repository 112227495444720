import React, { Component } from 'react'
import { trans } from '../../../trans'
import { StarsRatingField } from '../../ui/Form/StarsRatingField'
import { CheckboxField } from '../../ui/Form'
import FilterInput from './FilterInput'
import Sort from './Sort'
import { Factory as ElementFactory } from '../../../models/timeline/Factory'
import { connect } from 'react-redux'
import {
  getFacilities,
  getInitialFacilities,
  getSortBy,
} from '../../../store/app/hotels-booking/selectors'
import { isNumber } from 'lodash'
import Button from '../../ui/ButtonComponent'

class PureNavigation extends Component<any, any> {
  state = {
    parking: null,
    breakfast: null,
    others: null,
    standard: null,
    sortBy: null,
    initialized: false,
  }

  componentDidMount() {
    const {
      hotelsBooking: {
        actions: { setFilters },
      },
    } = this.props

    const filters = {
      parking: this.props.parking,
      others: this.props.others,
      standard: this.props.standard,
    }

    setFilters(filters)

    this.setState({
      ...filters,
      breakfast: this.props.breakfast,
      sortBy: this.props.sortBy,
      initialized: true,
    })
  }

  changeStandard = (value) => {
    const current = this.state.standard
    let newValue = value

    if (current === newValue) {
      newValue = null
    }

    this.change('standard', newValue)
  }

  change = (prop, value) => {
    const {
      hotelsBooking: {
        actions: { setFilter, setSortBy },
      },
    } = this.props

    prop === 'sortBy' ? setSortBy(value) : setFilter(prop, value)

    this.setState({
      [prop]: value,
    })
  }

  changeFacility = (prop, value) => {
    const {
      hotelsBooking: {
        actions: { isChanged, setFacility },
      },
    } = this.props

    setFacility(prop, value)

    this.setState({
      [prop]: value,
    })
  }

  search = () => {
    const {
      hotelsBooking: {
        actions: { searchFilters },
      },
    } = this.props

    searchFilters()
  }

  render() {
    const { sortBy, standard, breakfast, parking, others, initialized } = this.state

    if (!initialized) {
      return null
    }

    return (
      <div className='hotels-dialog__navigation'>
        <div className='hotels-dialog__navigation-filters'>
          <div className='accommodation__input-container accommodation__input-container--stars hotels-dialog__navigation-item'>
            <label className='accommodation__input-label'>
              {trans('accommodation.number-of-stars')}
            </label>
            <StarsRatingField
              stars={5}
              input={{
                value: standard,
                onChange: this.changeStandard,
              }}
            />
          </div>

          <div className='hotels-dialog__checkboxes-wrapper'>
            <label className='accommodation__input-label accommodation__input-label--small-margin'>
              {trans('hotels-booking.included-in-price')}
            </label>

            <div className='accommodation__checkbox-container'>
              <CheckboxField
                label={trans('accommodation.breakfast')}
                onChange={(value) => this.changeFacility('breakfast', value)}
                checked={breakfast}
              />

              <CheckboxField
                label={trans('accommodation.parking')}
                onChange={(value) => this.change('parking', value)}
                checked={parking}
              />
            </div>
          </div>

          <div className='hotels-dialog__input-filter-wrapper hotels-dialog__navigation-item'>
            <FilterInput
              onChange={(value) => this.change('others', value)}
              label={trans('accommodation.other-filters')}
              initValue={others}
            />
          </div>
        </div>
        <div className='hotels-dialog__input-filter-wrapper hotels-dialog__navigation-filters'>
          <div className='hotels-dialog__sort hotels-dialog__navigation-item'>
            <Sort onChange={(value) => this.change('sortBy', value)} value={sortBy} />
          </div>

          <div className='hotels-dialog__action'>
            <Button primary xxs onClick={this.search}>
              {trans('global.search')}
            </Button>
          </div>
        </div>
      </div>
    )
  }
}

PureNavigation.propTypes = {}

const mapStateToProps = (state, props) => {
  const {
    hotelsBooking: {
      element,
      selectors: { filters },
    },
  } = props
  const accommodation = ElementFactory.create(element)
  const facilities = getFacilities(accommodation.key)(state)
  const useStoredFilters = isNumber(accommodation.id)
  const sortBy = useStoredFilters ? getSortBy(accommodation.key)(state) : null
  const standard = accommodation.standard
  const breakfast = facilities.breakfast
  const parking = accommodation.parking
  const others = accommodation.others

  return {
    sortBy: sortBy,
    standard: filters.standard || standard,
    breakfast: filters.breakfast || breakfast,
    parking: filters.parking || parking,
    others: filters.others || others,
  }
}

const Navigation = connect(mapStateToProps)(PureNavigation)

export default Navigation
export { Navigation }
