import PropTypes from 'prop-types'
import React from 'react'
import classnames from 'classnames'
import PaginatorDefault from './PaginatorDefault'
import _ from 'lodash'
import trans from '../../../trans'
import Button from '../ButtonComponent'

class Table extends React.Component<any, any> {
  render() {
    const { children, striped, pagination, paginator, stripedVertically, className } = this.props
    const classes = classnames({
      'table-accordion': true,
      'table-accordion--is-striped': striped,
      'table-accordion--is-striped-vertically': stripedVertically,
    })

    const containerClasses = classnames(
      {
        'table-accordion-container': true,
      },
      className,
    )

    const Paginator = paginator || PaginatorDefault

    return (
      <div>
        <div className={containerClasses} style={this.props.styleForTable}>
          <div className={classes}>{children}</div>
        </div>
        {!_.isEmpty(pagination) && (
          <div>
            <Paginator {...pagination} />
          </div>
        )}
      </div>
    )
  }
}

Table.propTypes = {
  children: PropTypes.node,
  striped: PropTypes.bool,
  stripedVertically: PropTypes.bool,
  pagination: PropTypes.object,
  paginator: PropTypes.func,
}

Table.defaultProps = {
  striped: false,
  stripedVertically: false,
}

export { Table }
export default Table
