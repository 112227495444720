import React from 'react'
import { trans } from '../../../../trans'
import { TimelineElement, TimeLineElementContent, TimeLineElementHeader } from '../../../Timeline'
import { Col } from '../../../../ui/Grid'
import { TRAVEL_ACCOMODATION } from '../../../../constants/travel'
import Icon from '../../../ui/IconComponent'

class AccomodationSuggestion extends React.Component<any, any> {
  shouldComponentUpdate(nextProps, nextState) {
    return false
  }

  render() {
    const { onChangeType, onRemove, element } = this.props

    return (
      <TimelineElement type={element.type} activeSimilar>
        <TimeLineElementHeader icon='question_fill' gradient='true'>
          <Col xs={8} style={{ display: 'flex', alignItems: 'center' }}>
            <Icon type='accomodation' className='is-margin-right-small' lg />
            {trans('request.do-you-want-add-accommodation')}
            <div className='accommodation-suggestions__buttons'>
              <span onClick={() => onChangeType(element, TRAVEL_ACCOMODATION)}>
                <Icon type='checked_fill' className='is-gradient-success' lg />
              </span>
              <span onClick={() => onRemove(element)}>
                <Icon type='close_fill' className='is-gradient-danger' lg />
              </span>
            </div>
          </Col>
        </TimeLineElementHeader>
        <TimeLineElementContent isOpened='open' />
      </TimelineElement>
    )
  }
}

export { AccomodationSuggestion }
export default { AccomodationSuggestion }
