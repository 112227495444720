import React from 'react'
import { connect } from 'react-redux'
import { fromJS } from 'immutable'
import { NationalTripForm as Form } from '../../components/BorderCrossings/BorderCrossings/NationalTripForm'
import { compose } from 'redux'
import { getFormValues } from '../../utils/forms'
import { borderCrossings } from '../../store/app/border-crossings'
import { reduxForm } from 'redux-form/immutable'

export const submit = (values, dispatch, props) => {
  const {
    crossings: {
      actions: { setNationalTrip },
      selectors: { nationalTrip },
    },
  } = props
  return setNationalTrip(props.data.national_trip)
}

const withForm = reduxForm({
  onSubmit: submit,
})
const mapStateToProps = (state, props) => {
  const {
    form,
    crossings: {
      selectors: { nationalTrip },
    },
  } = props

  return {
    initialValues: fromJS({
      national_trip: nationalTrip,
    }),
    form,
    data: getFormValues(form, state),
  }
}

const withBorderCrossings = borderCrossings()
const withConnect = connect(mapStateToProps)

const NationalTripForm = compose(withBorderCrossings, withConnect, withForm)(Form)

export default NationalTripForm
export { NationalTripForm }
