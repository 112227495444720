import APIClient from '../../../services/APIClient'
import { config } from '../../../config'
import moment from 'moment'

const initialState = {
  data: [],
  isLoading: true,
}

export const MOUNT_POINT = 'substitutions'

export const RESET = MOUNT_POINT + '::reset'
export const SET = MOUNT_POINT + '::set'
export const ADD = MOUNT_POINT + '::add'
export const DELETE = MOUNT_POINT + '::delete-substitution'

export const reset = () => (dispatch) => {
  dispatch({
    type: RESET,
  })
}

export const setData = (data) => (dispatch) => {
  dispatch({
    type: SET,
    payload: data,
  })
}

export const deleteSubstitution = (id) => (dispatch) => {
  dispatch({
    type: DELETE,
    payload: id,
  })
}

export const getSubstitutionsAction =
  (slug = null) =>
  (dispatch) => {
    return APIClient.getUserDeputies(slug).then((response) => {
      dispatch(setData(response.data))
    })
  }

export const addSubstitutionAction =
  (userId, from, to, slug = null) =>
  (dispatch) => {
    return APIClient.addUserDeputy(
      userId,
      moment(from).format(config.apiDateFormat),
      moment(to).format(config.apiDateFormat),
      slug,
    ).then(() => {
      dispatch(getSubstitutionsAction(slug))
    })
  }

export const deleteSubstitutionAction =
  (substitutionId, slug = null) =>
  (dispatch) => {
    return APIClient.deleteUserDeputy(substitutionId, slug).then(() => {
      dispatch(deleteSubstitution(substitutionId))
      dispatch(getSubstitutionsAction(slug))
    })
  }

export const reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case RESET:
      return initialState
    case SET:
      return {
        ...state,
        data: payload,
        isLoading: false,
        isLoaded: true,
      }
    case DELETE:
      return {
        ...state,
        data: state.data.filter((sub) => sub.id !== payload),
      }
    default:
      return state
  }
}

export const getState = (state) => state.get(MOUNT_POINT)
export const getSubstitutions = (state) => getState(state).data
export const isLoading = (state) => getState(state).isLoading
