import React from 'react'
import { connect } from 'formik'
import { FormGroup } from '../../../ui'
import Error from '../../../ui/Input/Error'
import { trans } from '../../../trans'
import { SelectField } from '../../ui/Form'

export default connect((props) => {
  const { label, name, countries, disabled = false, maxMenuHeight } = props

  const options = countries.map((country) => ({
    value: country.id,
    label: country.name,
  }))

  return (
    <div>
      <FormGroup label={label} labeltop>
        <SelectField
          name={name}
          options={options}
          value={props.formik.values[name]}
          onChange={(value) => props.formik.setFieldValue(name, value)}
          disabled={disabled}
          placeholder={trans('user.select-country')}
          maxMenuHeight={maxMenuHeight}
        />

        <Error errors={props.formik.errors[name]} />
      </FormGroup>
    </div>
  )
})
