import React from 'react'
import PropTypes from 'prop-types'
import { View } from './components/View'
import { Manager } from './Manager'
import { borderCrossings } from '../../store/app/border-crossings'
import { connect as nonDelegation } from '../../containers/NonDelegation'
import { connect as tripDidNotHavePlace } from '../../containers/TripDidNotHavePlace'

class RequestExpenseCommute extends React.Component<any, any> {
  render() {
    const {
      request,
      isReadOnly,
      crossings: {
        selectors: { nationalTrip, step },
      },
    } = this.props
    const {
      nonDelegation: {
        selectors: { isDelegation },
      },
    } = this.props
    const {
      tripDidNotHavePlace: {
        selectors: { isTripDidNotHavePlace },
      },
    } = this.props

    if (nationalTrip) return null

    return (
      <Manager request={request}>
        {({
          accessLumpSums,
          setAccessLumpSumPair,
          deleteAccessLumpSums,
          addAccessLumpSum,
          setStoreForAccessLumpSumsPair,
          isLoading,
        }) => {
          return (
            <View
              accessLumpSums={accessLumpSums}
              setAccessLumpSumPair={setAccessLumpSumPair}
              deleteAccessLumpSums={deleteAccessLumpSums}
              addAccessLumpSum={addAccessLumpSum}
              setStoreForAccessLumpSumsPair={setStoreForAccessLumpSumsPair}
              isLoading={isLoading}
              isReadOnly={isReadOnly}
              showUnsettledOverlay={!step && isDelegation && !isTripDidNotHavePlace}
            />
          )
        }}
      </Manager>
    )
  }
}

RequestExpenseCommute = borderCrossings()(RequestExpenseCommute)
RequestExpenseCommute = nonDelegation(RequestExpenseCommute)
RequestExpenseCommute = tripDidNotHavePlace(RequestExpenseCommute)

RequestExpenseCommute.propTypes = {
  request: PropTypes.object.isRequired,
  isReadOnly: PropTypes.bool.isRequired,
}

export { RequestExpenseCommute }
export default { RequestExpenseCommute }
