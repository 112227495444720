import React, { Component } from 'react'
import { connect } from 'react-redux'
import { logoutUserAction } from './App/actions'
import Session from '../services/Session'
import APIClient from '../services/APIClient'

// TODO: logout action shouldn't be component!
class Logout extends Component<any, any> {
  componentDidMount() {
    Session.clearSessionData()

    localStorage.clear()

    APIClient.logout().then((response) => {
      window.location.href = response.data.logout_url
    })
  }

  render() {
    return null
  }
}

const mapDispatchToProps = (dispatch) => ({
  logoutUser: () => dispatch(logoutUserAction()),
})

export default connect(null, mapDispatchToProps)(Logout)
