import React from 'react'
import PropTypes from 'prop-types'
import { connect } from '../../containers/FlightsBooking'
import { get } from 'lodash'

const SelectedServices = connect((props) => {
  const { offer, name, pax } = props
  const {
    flights: {
      selectors: { getPaxAvailableAttribute, getPaxRequestedAttribute },
    },
  } = props

  const available = getPaxAvailableAttribute(pax.slug, offer, name)
  const requested = getPaxRequestedAttribute(pax.slug, offer, name)

  const items = get(available, 'items', [])
  const selected = items.filter((item) => requested.indexOf(item.id) !== -1)

  if (!items.length || !selected.length) {
    return '-'
  }

  return (
    <ul className='plane-offer__selected-services'>
      {selected.map((item) => (
        <li key={item.id}>
          <div className='plane-offer__selected-services-description'>{item.description}</div>
          <div className='plane-offer__selected-services-amount'>{item.amount.formatted}</div>
        </li>
      ))}
    </ul>
  )
})

SelectedServices.propTypes = {
  offer: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
}

export default SelectedServices
export { SelectedServices }
