import { withFormik } from 'formik'
import PropTypes from 'prop-types'
import SecurityChangePin from './SecurityChangePin'
import APIClient from '../../../services/APIClient'
import { get } from 'lodash'

const initialValues = {
  current_pin: '',
  new_pin: '',
  new_pin_confirmation: '',
}

const SecurityChangePinForm = withFormik({
  mapPropsToValues: () => initialValues,
  handleSubmit: (values, { props, setSubmitting, setErrors, resetForm }) => {
    APIClient.changePinCode({
      ...values,
      slug: _.isObject(props.subjectUser) ? props.subjectUser.slug : null,
    })
      .then(() => {
        resetForm(initialValues)
        setSubmitting(false)
      })
      .catch((response) => {
        setErrors(get(response, 'alerts.0.errors', {}))
        setSubmitting(false)
      })
  },
  enableReinitialize: true,
  displayName: 'SecurityChangePin',
})(SecurityChangePin)

SecurityChangePinForm.propTypes = {
  subjectUser: PropTypes.object,
}

export { SecurityChangePinForm }
export default SecurityChangePinForm
