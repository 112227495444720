import React, { Fragment } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { Cell, Row } from '../../../ui/AccordionTable'
import trans from '../../../../trans'
import { CompanyBaseList } from '../../CompanyBaseList'
import ImportListItem from './ImportListItem'
import { SelectField } from '../../../ui/Form'
import Button from '../../../ui/ButtonComponent'
import {
  fetchCompanies,
  getCompaniesWithAllForSelectOptionsSelector,
} from '../../../../store/app/companies/slice'
import {
  createImport,
  fetchImports,
  getImportsTotalCount,
  getPage,
  getPaginatedImports,
  isAdding,
  isLoading,
  setAdding,
  setPage,
} from '../../../../store/app/imports'
import APIClient from '../../../../services/APIClient'
import Dialog from '@material-ui/core/Dialog'
import { Transition } from 'react-transition-group'
import Paper from '@material-ui/core/Paper'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import { IImportOptions } from '../../../../types/import'
import Icon from '../../../ui/IconComponent'

interface ImportListState {
  importOptions: IImportOptions[]
  subOptions: any[]
  editable: any
  adding: boolean
  type: string
  subtype: string
  shouldHaveSubtype: boolean
  [key: string]: unknown
}

class ImportList extends CompanyBaseList<any, ImportListState> {
  constructor(props) {
    super(props)

    this.state = {
      shouldHaveSubtype: false,
      company: null,
      type: null,
      subtype: null,
      file: null,
      editable: {},
      adding: false,
      importOptions: [],
      subOptions: [],
      modal: false,
    }

    this.selectCompany = this.selectCompany.bind(this)
    this.selectType = this.selectType.bind(this)
    this.selectSubtype = this.selectSubtype.bind(this)
    this.selectFile = this.selectFile.bind(this)
    this.confirmAction = this.confirmAction.bind(this)
    this.onCloseModal = this.onCloseModal.bind(this)
  }
  async componentDidMount() {
    const { fetchCompanies, fetchImports } = this.props

    fetchCompanies()
    fetchImports()

    await this.fetchImportOptions()
  }

  async fetchImportOptions() {
    const response = await APIClient.fetchImportTypes()

    this.setState({
      importOptions: response.data,
      subOptions: [],
    })
  }

  selectFile(event) {
    this.setState({ file: event.target.files[0] })
  }

  selectCompany(value) {
    this.setState({ company: value })
  }

  async selectSubtype(value) {
    this.setState({ subtype: value })
  }

  async selectType(value) {
    const type = this.state.importOptions.find((t) => t.value === value)

    if (type.moreOptions) {
      const response = await APIClient._get(type.moreOptions)

      this.setState({
        subOptions: response.data,
        shouldHaveSubtype: true,
      })
    } else {
      this.setState({
        subOptions: [],
        shouldHaveSubtype: false,
      })
    }

    this.setState({ type: value, subtype: null })
  }

  async export() {}

  async import() {
    if (!this.canCreate) {
      return
    }

    this.props.setAdding(true)

    const data = new FormData()
    data.append('file', this.state.file)
    data.set('type', this.state.type)

    if (this.state.company) {
      data.set('company_id', this.state.company)
    }

    if (this.state.subtype) {
      data.set('subtype', this.state.subtype)
    }

    return this.props.createImport(data).finally(() => {
      this.props.setAdding(false)

      document.querySelector(`input[type=file]`).value = null
    })
  }

  getIdentifierKey() {
    return 'slug'
  }

  getListItemComponent() {
    return ImportListItem
  }

  openModal(type: string) {
    this.setState({ modal: type })
  }

  onCloseModal() {
    this.setState({ modal: false })
  }

  canSelectFile() {
    if (this.state.shouldHaveSubtype) {
      return this.state.subtype
    }

    return this.state.type
  }

  async confirmAction() {
    if (this.state.modal === 'import') {
      await this.import()
    } else if (this.state.modal === 'export') {
      await this.export()
    }

    this.setState({
      modal: false,
      type: null,
      subtype: null,
      file: null,
      subOptions: null,
    })
  }

  renderFooter() {
    const { companies } = this.props
    const { adding } = this.state

    if (!this.state.modal) {
      return null
    }

    return (
      <Dialog
        open={true}
        TransitionComponent={Transition}
        disableBackdropClick
        disableEscapeKeyDown
        onClose={this.onCloseModal}
        className='import-export-modal'
      >
        <Paper square>
          <DialogTitle>{trans(`import.${this.state.modal}-modal-title`)}</DialogTitle>

          <DialogContent className='import-export-modal__content'>
            <div className='import-export-modal__field'>
              <span>{trans('instance-users.company-filter')}</span>
              <SelectField
                options={companies}
                value={this.state.company}
                disabled={adding}
                onChange={this.selectCompany}
                placeholder={trans('global.filter-placeholder-choose')}
                menuPortalTarget={document.body}
              />
            </div>
            <div className='import-export-modal__field'>
              <span>{trans('import.type')}</span>
              <SelectField
                options={[...this.state.importOptions]}
                value={this.state.type}
                disabled={adding}
                onChange={this.selectType}
                placeholder={trans('global.filter-placeholder-choose')}
                menuPortalTarget={document.body}
              />
            </div>

            {!!this.state.subOptions.length && (
              <div className='import-export-modal__field'>
                <span>{trans('import.subtype')}</span>
                <SelectField
                  options={[...this.state.subOptions]}
                  value={this.state.subtype}
                  disabled={adding}
                  onChange={this.selectSubtype}
                  placeholder={trans('global.filter-placeholder-choose')}
                  menuPortalTarget={document.body}
                />
              </div>
            )}

            <div className='company-settings file-select'>
              <Button
                outline
                xs
                className={'btn--tiny import-button'}
                locked={this.props.isAdding}
                onClick={() => {
                  document.querySelector(`input[type=file]`).click()
                }}
                disabled={!this.canSelectFile()}
              >
                {trans('import.select-file')}
              </Button>

              {this.state.file && this.state.file.name && <span>{this.state.file.name}</span>}

              <input
                type='file'
                name='file'
                id='upload'
                style={{ display: 'none' }}
                onChange={this.selectFile}
                accept='.csv,.txt,.xls,.xlsx'
              />
            </div>
          </DialogContent>

          <DialogActions className='import-export-modal__footer'>
            <Button
              primary
              xs
              disabled={!this.state.file || !this.canSelectFile()}
              onClick={this.confirmAction}
            >
              {trans('import.modal-complete')}
            </Button>
          </DialogActions>

          <Icon className='last-requests__dialog-close' type='close' onClick={this.onCloseModal} />
        </Paper>
      </Dialog>
    )
  }

  renderCompanySelect() {
    return <Fragment></Fragment>
  }

  renderTableContent() {
    const { items } = this.props

    return <Fragment>{items.map((item) => this.renderRow(item))}</Fragment>
  }

  renderTableHeader() {
    return (
      <Row>
        <Cell fixedWidth={165}>Data rozpoczęcia</Cell>
        <Cell fixedWidth={165}>Data zakończenia</Cell>
        <Cell fixedWidth='auto'>Rodzaj</Cell>
        <Cell fixedWidth={150}>Firma</Cell>
        <Cell fixedWidth={150}>Dodał</Cell>
        <Cell fixedWidth={150}>Status</Cell>
        <Cell fixedWidth={160} alignCenter></Cell>
      </Row>
    )
  }

  renderHeader() {
    return (
      <div className='table__header'>
        <div className='import-export__actions table__header-actions table__header-actions--small'>
          <Button
            primary
            xs
            className={'btn--tiny import-button'}
            locked={this.props.isAdding}
            onClick={() => this.openModal('import')}
          >
            {trans('import.import-button-label')}
          </Button>
        </div>
      </div>
    )
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      setPage,
      setAdding,
      fetchCompanies,
      fetchImports,
      createImport,
    },
    dispatch,
  )
}

const mapStateToProps = (state) => {
  return {
    companies: getCompaniesWithAllForSelectOptionsSelector(state, 'all'),
    isLoading: isLoading(state),
    isAdding: isAdding(state),
    items: getPaginatedImports(state),
    total: getImportsTotalCount(state),
    page: getPage(state),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ImportList)
