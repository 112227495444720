import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { TRAVEL_ACCOMODATION, TRAVEL_PLANE_TRIP, TRAVEL_TRAIN_TRIP } from '../../constants/travel'
import { Reservation as HotelsReservation } from '../HotelsBooking/Reservation/Reservation'
import { Reservation as TrainsReservation } from '../TrainsBooking/Reservation/Reservation'
import { DEFAULT_SEARCH_RADIUS } from '../../store/app/hotels-booking'
import { Reservation as FlightsReservation } from '../../components/FlightsBooking'

class Booking extends Component<any, any> {
  render() {
    const { element, request } = this.props

    switch (element.type) {
      case TRAVEL_TRAIN_TRIP:
        return (
          <TrainsReservation
            trainsBooking={{
              request,
              element,
              init: {
                name: element.key,
                offers: [],
                query: {
                  departure_location: element.departure_location,
                  destination_location: element.destination_location,
                  departure_at: element.departure_at,
                  request_travelers: element.request_travelers,
                },
              },
            }}
            reservation={true}
          />
        )

      case TRAVEL_ACCOMODATION:
        return (
          <HotelsReservation
            rangeMap={{
              init: {
                radius: DEFAULT_SEARCH_RADIUS,
                coordinates: {
                  lat: element.location.lat,
                  lng: element.location.long,
                },
                name: element.key,
              },
            }}
            hotelsBooking={{
              request,
              element,
              init: {
                offers: [],
                socket: {
                  channel: null,
                  event: null,
                },
                name: element.key,
                filters: {},
                facilities: {
                  breakfast: element.breakfast,
                },
                query: {
                  location: element.getStartLocation(),
                  arrival_at: element.getStartDate(),
                  departure_at: element.getEndDate(),
                },
              },
            }}
          />
        )

      case TRAVEL_PLANE_TRIP:
        return <FlightsReservation request={request} element={element} />
      default:
        return null
    }
  }
}

Booking.propTypes = {
  element: PropTypes.object.isRequired,
  request: PropTypes.object.isRequired,
}

export default Booking
export { Booking }
