import { createSelector, createSlice } from '@reduxjs/toolkit'
import APIClient from '../../../services/APIClient'
import store, { history } from '../../../store'
import { fetchLoggedUser, getCurrentUser, setProfile } from '../user-profile/userProfileSlice'
import { fetch as fetchDashboardCounter } from '../dashboard-counter'

export const LOGGABLE_MOUNT_POINT = 'loggable'
export const LOGGABLE_AS_USERS_LIMIT = 30

/**
 * In @reduxjs/toolkit we can mutate state in reducers,
 * because behind the scene it uses ImmerJS for applying them as non-mutate
 *
 * https://github.com/immerjs/immer
 */
const slice = createSlice({
  name: LOGGABLE_MOUNT_POINT,
  initialState: {
    items: [],
    filters: {
      phrase: '',
      limit: LOGGABLE_AS_USERS_LIMIT,
      offset: 0,
    },
    error: null,
    isLoading: false,
    isLoaded: false,
    isLoginInProgress: false,
    hasMore: true,
  },
  extraReducers: (builder) => {
    // proxy action to existing reducer
    builder.addCase(setProfile, slice.caseReducers.updateLoggableUser)
  },
  reducers: {
    setLoggableUsers(state, action) {
      const { data, filters } = action.payload

      if (
        action.payload.filters &&
        state.filters &&
        state.filters.phrase === action.payload.filters.phrase &&
        state.filters.offset < action.payload.filters.offset
      ) {
        state.items = [...state.items, ...data]
      } else {
        state.items = data
      }

      state.filters = filters
      state.hasMore =
        action.payload.pagination && action.payload.pagination.total > action.payload.filters.offset
    },
    reset(state) {
      state = {
        items: [],
        filters: {
          phrase: '',
          limit: LOGGABLE_AS_USERS_LIMIT,
          offset: 0,
        },
        error: null,
        isLoading: false,
        isLoaded: false,
        isLoginInProgress: false,
        hasMore: true,
      }
    },
    startLoading(state) {
      state.isLoading = true
      state.isLoaded = false
      state.error = null
    },
    setLoadingSuccess(state) {
      state.isLoading = false
      state.isLoaded = true
      state.error = null
    },
    setLoadingFailed(state, action) {
      state.isLoading = false
      state.isLoaded = false
      state.error = action.payload
    },
    updateLoggableUser(state, action) {
      const update = action.payload
      const user = state.items.find((user) => user.slug === update.slug)

      // if user does not have ability to switch profile, list should be empty
      if (!state.items.length) {
        return
      }

      if (user) {
        // we can not just re-asign user, because loggable user has extra properties: current and type
        user.abilities = update.abilities
        user.avatar = update.avatar
        user.email = update.email
        user.first_name = update.first_name
        user.full_name = update.full_name
        user.last_name = update.last_name
      } else {
        state.items.push({
          abilities: update.abilities,
          avatar: update.avatar,
          email: update.email,
          first_name: update.first_name,
          full_name: update.full_name,
          last_name: update.last_name,
          current: false,
          slug: update.slug,
          type: 'admin', // TODO check how to determine type
        })
      }

      let items = [...state.items]
      items.sort((a, b) => {
        const aLastName = a.last_name ? a.last_name.toLowerCase() : a.last_name
        const bLastName = a.last_name ? b.last_name.toLowerCase() : a.last_name

        if (aLastName < bLastName) {
          return -1
        }
        if (aLastName > bLastName) {
          return 1
        }
        return 0
      })

      state.items = items
    },
    setLoginInProgress(state, action) {
      state.isLoginInProgress = action.payload
    },
  },
})

export const {
  setLoggableUsers,
  updateLoggableUser,
  setLoginInProgress,
  startLoading,
  setLoadingSuccess,
  setLoadingFailed,
  reset,
} = slice.actions

export default slice.reducer

// selectors
export const getState = (state) => state.get(LOGGABLE_MOUNT_POINT)
export const getLoggableUsers = (state) => getState(state).items
export const isLoaded = (state) => getState(state).isLoaded || !isLoading(state)
export const isLoading = (state) => getState(state).isLoading
export const isLoginInProgress = (state) => getState(state).isLoginInProgress
// action thunks
export const fetchLoggableUsers =
  (filters = { phrase: '', limit: LOGGABLE_AS_USERS_LIMIT, offset: 0, status: null }) =>
  async (dispatch) => {
    dispatch(startLoading())

    try {
      const { data, pagination } = await APIClient.getLoggableTo(filters)

      dispatch(setLoggableUsers({ filters, data, pagination }))
      dispatch(setLoadingSuccess())
    } catch (err) {
      dispatch(setLoadingFailed(err))
    }
  }

export const loginAs = (user) => async (dispatch) => {
  dispatch(setLoginInProgress(true))

  const { data } = await APIClient.loginAs(user)

  // refresh logged user state
  await dispatch(fetchLoggedUser())

  dispatch(setLoggableUsers({ data }))
  dispatch(setLoginInProgress(false))
  dispatch(fetchDashboardCounter())

  if (user.slug === getCurrentUser(store.getState()).slug) {
    dispatch(
      fetchLoggableUsers({
        phrase: '',
        offset: 0,
        limit: LOGGABLE_AS_USERS_LIMIT,
      }),
    )
  }

  // hack: redirect to dashboard twice to refresh main view
  history.push('/')
}
