import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import OperatorLogo from '../Offer/OperatorLogo'
import TravelTime from '../Offer/TravelTime'
import Relation from '../Offer/Relation'
import Changes from '../Offer/Changes'
import ChangeDetails from '../Offer/ChangeDetails'
import Luggage from '../Offer/Luggage'
import Icon from '../../ui/IconComponent'
import Tariff from '../Offer/Tariff'
import Warning from '../Offer/Warning'
import { trans } from '../../../trans'
import { get } from 'lodash'
import { getOfferOption } from '../../../store/app/flights-booking/selectors'
import { connect } from '../../../containers/FlightsBooking'
import Ellipsis from 'react-ellipsis-pjs'
import { Tooltip } from '../../Tooltip'
import TariffConditions from '../Reservation/TariffConditions'

class ItemDetailsComponent extends Component<any, any> {
  renderAirlinesTooltip = (ellipsisText, isEllipsis) => {
    if (!isEllipsis) return ellipsisText

    return <Tooltip html={this.props.offer.attributes.airlineName}>{ellipsisText}</Tooltip>
  }

  renderDetails = () => {
    const { flights } = this.props

    const round = get(flights, 'selectors.query.round_trip', false)

    if (round) {
      return trans('flights-booking.round-trip-light')
    }

    if (!round) {
      return trans('flights-booking.one-way-trip-light')
    }

    return null
  }

  render() {
    const {
      offer,
      isRoundTrip,
      children,
      isReturn,
      flights: {
        selectors: { query },
      },
    } = this.props

    const option = getOfferOption(offer)

    const airlines = get(offer, 'attributes.airlineName', null)
    const duration = get(offer, 'attributes.travelTime', null)

    const amountFormatted = get(
      option,
      'amount.amount_rounded',
      get(option, 'amount.formatted'),
    ).toLowerCase()
    const hasBrokenRules = get(offer, 'hasBrokenRules')
    const rules = get(offer, 'rules')

    const checkedBaggage = get(offer, 'attributes.checkedBaggage', [])
    const filteredCheckedBaggage = checkedBaggage.filter((baggage) => {
      const pieces = baggage.numberOfItems !== null ? baggage.numberOfItems : baggage.totalAllowance
      return parseInt(pieces) > 0
    })

    const isLowCost = get(offer, 'attributes.tariffSlug', '') === 'LOW'

    const tariffDescription = get(offer, 'attributes.tariffDescription', null)
    const tariffSlug = get(offer, 'attributes.tariffSlug', null)
    const linkText = (
      <Fragment>
        {tariffDescription} ({tariffSlug})
      </Fragment>
    )

    return (
      <div className='plane-trip__offer-details'>
        <div className='plane-trip__offer-details-grid'>
          <div className='plane-trip__offer-detail plane-trip__offer-detail--logo'>
            <OperatorLogo offer={offer} canRenderMultiLogo={true} />
          </div>
          <div className='plane-trip__offer-detail'>
            <span className='plane-offer__detail-heading'>
              <TravelTime offer={offer} />
            </span>
            <span className='plane-offer__detail-content'>
              <Ellipsis lines={1} custom={this.renderAirlinesTooltip}>
                {airlines}
              </Ellipsis>
            </span>
          </div>

          <div className='plane-trip__offer-detail'>
            <span className='plane-offer__detail-heading'>{duration}</span>
            <span className='plane-offer__detail-content'>
              <Relation
                offer={offer}
                flight_from={query.flight_from}
                flight_to={query.flight_to}
                isReturn={isReturn}
              />
            </span>
          </div>

          <div className='plane-trip__offer-detail'>
            <span className='plane-offer__detail-heading'>
              <Changes offer={offer} />
            </span>
            <span className='plane-offer__detail-content'>
              <ChangeDetails offer={offer} />
            </span>
          </div>

          <div className='plane-trip__offer-detail'>
            <Luggage offer={offer} showLowCostMessage={isLowCost}>
              {!!filteredCheckedBaggage.length ? (
                <Icon type='suitcase2' className='plane-offer__luggage-icon' />
              ) : (
                '—'
              )}
            </Luggage>
          </div>

          <div className='plane-trip__offer-detail'>
            <Tariff offer={offer} />
            <TariffConditions linkText={linkText} />
          </div>

          <div className='plane-trip__offer-detail plane-trip__offer-detail--price'>
            <div className='plane-trip__offer-detail-inner-wrapper'>
              <div className='plane-trip__offer-detail-content'>
                <span className='plane-trip__offer-detail-price-value'>{amountFormatted}</span>
                {hasBrokenRules && <Warning rules={rules} />}
                <span className='plane-trip__offer-detail-price-desc'>{this.renderDetails()}</span>
              </div>
            </div>
            {isRoundTrip && (
              <div className='plane-trip__offer-detail-annotation is-font-xsmall'>
                {trans('flights-booking.round-trip-price')}
              </div>
            )}
          </div>
          {children}
        </div>
      </div>
    )
  }
}

ItemDetailsComponent.propTypes = {
  offer: PropTypes.object.isRequired,
  isRoundTrip: PropTypes.bool.isRequired,
  expanded: PropTypes.bool.isRequired,
}

ItemDetailsComponent.defaultProps = {
  isRoundTrip: false,
  expanded: false,
}

const ItemDetails = connect(ItemDetailsComponent)

export default ItemDetails
export { ItemDetails }
