import { CHANGE, RESET, SET } from './index'
import APIClient from '../../../services/APIClient'
import { setRequest, updateAccountingTravelExpense } from '../request-mileage-allowance'
import { isArray } from 'lodash'
import { setRequestData } from '../trip-request'

// actions Creators
export const reset = () => (dispatch) => {
  dispatch({
    type: RESET,
  })
}

export const set = (request) => (dispatch) => {
  dispatch({
    type: SET,
    payload: {
      request,
    },
  })
}

export const change = (path, value) => (dispatch) => {
  dispatch({
    type: CHANGE,
    payload: {
      path,
      value,
    },
  })
}

export const setNonDelegation = (request) => (value) => (dispatch) => {
  dispatch(change('delegation', value))

  return APIClient.updateRequestDelegation(request, value).then((response) => {
    const data = response.data

    dispatch(
      setRequestData({
        ...request,
        delegation: data.delegation,
        delegation_was_switched: data.delegation_was_switched,
      }),
    )

    if (isArray(data.accountingTravelExpenses)) {
      data.accountingTravelExpenses.map((accountingTravelExpense) => {
        dispatch(updateAccountingTravelExpense(accountingTravelExpense))
      })
    }
  })
}
