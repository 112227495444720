import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { get } from 'lodash'
import { trans } from '../../../trans'
import { Tooltip } from '../../Tooltip'
import Icon from '../../ui/IconComponent'

const Tariff = (props) => {
  const { offer, noText } = props

  const tariffDescription = get(offer, 'attributes.tariffDescription', null)
  const tariffSlug = get(offer, 'attributes.tariffSlug', null)
  const extFlightInfo = get(offer, 'attributes.extFlightInfo', '')
  const fareBase = get(offer, 'attributes.fareBase', '')
  const airlineCode = get(offer, 'attributes.airlineCode', '')

  let extFlightInfoTranslated = null
  if (extFlightInfo) {
    const transKey = ('flights-booking.tariff-' + airlineCode + '-' + extFlightInfo).toLowerCase()
    extFlightInfoTranslated = trans(transKey) !== transKey ? trans(transKey) : extFlightInfo
  }

  const tooltipSlug = extFlightInfoTranslated ? extFlightInfoTranslated : fareBase ? fareBase : ''

  return (
    <Fragment>
      <div className='plane-trip__offer-detail-tooltip-wrapper'>
        <span className='plane-offer__detail-heading'>{trans('flights-booking.tariff')}</span>
        {tooltipSlug && (
          <Tooltip html={<span>{tooltipSlug}</span>}>
            <Icon type='info' className='icon-info-grey plane-trip__icon-info' />
          </Tooltip>
        )}
      </div>
      {!noText && (
        <span className='plane-offer__detail-content'>
          {tariffDescription} ({tariffSlug})
        </span>
      )}
    </Fragment>
  )
}

Tariff.propTypes = {
  offer: PropTypes.object.isRequired,
}

export default Tariff
export { Tariff }
