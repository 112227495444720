import { format, Track, useMask } from '@react-input/mask'
import { ModifiedData, TrackingData } from '@react-input/mask/types'

const IBAN_WITHOUT_COUNTRY_MASK = '__ ____ ____ ____ ____ ____ ____';
const IBAN_WITH_COUNTRY_MASK = `AA${IBAN_WITHOUT_COUNTRY_MASK}`;
const IBAN_ANY_MASK = `**${IBAN_WITHOUT_COUNTRY_MASK}`;


const modify = ({ value, data, selectionStart, selectionEnd, inputType }: TrackingData): ModifiedData | undefined => {
  const _value = value.slice(0, selectionStart) + (data ?? '') + value.slice(selectionEnd);

  if (!_value) {
    return {
      mask: IBAN_ANY_MASK,
    }
  }

  if (isNaN(+_value[0]) && !isNaN(+_value[1])) {
    return {
      mask: IBAN_ANY_MASK,
    }
  }

  if (isNaN(+_value[0]) && isNaN(+_value[1])) {
    return { mask: IBAN_WITH_COUNTRY_MASK }
  }

  return { mask: IBAN_WITHOUT_COUNTRY_MASK };
};

const determineMask = (iban: string) => {
  if (!iban) {
    return {
      mask: IBAN_ANY_MASK,
    }
  }

  if (isNaN(+iban[0])) {
    return { mask: IBAN_WITH_COUNTRY_MASK }
  }

  return { mask: IBAN_WITHOUT_COUNTRY_MASK };
}

const track: Track = (input: TrackingData) => {
  return input.data ? input.data.toUpperCase() : input.data
};

const IBAN_MASK_OPTIONS = {
  replacement: { _: /\d/, A: /\D/, '*': /./ },
  modify,
  track,
}

// IBAN = https://jsfiddle.net/rfjegmk9/1/
// https://www.iban.com/structure
export function useIBAN(iban: string) {
  const formatter = determineMask(iban);

  return useMask({
    ...IBAN_MASK_OPTIONS,
    mask: formatter.mask,
  });
}

export function formatIBAN(iban: string) {
  const formatter = determineMask(iban);

  return format(iban || '', {
    mask: formatter.mask,
    replacement: IBAN_MASK_OPTIONS.replacement,
  })
}

export function parseIBAN(iban: string) {
  return iban.replace(/\s/g, '');
}
