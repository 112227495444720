import React from 'react'
import { toast } from 'react-toastify'
import { trans } from '../trans'
import classnames from 'classnames'

const TYPE_SUCCESS = 'success'
const TYPE_INFO = 'info'
const TYPE_ERROR = 'error'

const AVG_WORD_READING_TIME_IN_MILLISECONDS = 279

class AlertsController {
  static instance = null

  static FADE_IN_TIME_IN_MILLISECONDS = 2000
  static EXCLUDED_TYPES = [TYPE_INFO]

  timeouts = []

  constructor() {
    if (AlertsController.instance !== null) {
      throw new Error('Cannot construct other instance of GoogleMapsProvider')
    }
  }

  static getInstance() {
    if (!AlertsController.instance) {
      AlertsController.instance = new AlertsController()
    }

    return AlertsController.instance
  }

  displayAlerts(alerts = []) {
    alerts
      .filter((alert) => AlertsController.EXCLUDED_TYPES.indexOf(alert.type) === -1)
      .forEach((alert) => this.displayAlert(alert))
  }

  displayAlert(alert) {
    const id = this.getAlertID(alert)

    if (toast.isActive(id)) {
      this.resetAlertTimeout(alert)
    } else {
      toast(this.toastRender(alert), {
        toastId: id,
      })

      this.setAlertTimeout(alert)
    }
  }

  toastRender(alert) {
    const classes = classnames('toast-custom', {
      [`toast-custom--type-${alert.type}`]: !!alert.type,
    })

    return (
      <div className={classes}>
        <div className='toast-custom__icon-container'>
          <span className={`${alert.type} toast-custom__icon`} />
        </div>
        <div className='toast-custom__content'>
          <span>{this.getToastMessage(alert.message)}</span>
        </div>
      </div>
    )
  }

  getToastMessage(message) {
    if (typeof message == 'object') {
      return trans(message.name, message.params)
    }

    return message
  }

  calculateDuration(alert) {
    const message = this.getToastMessage(alert.message)
    const wordsCount = message.split(' ').length

    return (
      AlertsController.FADE_IN_TIME_IN_MILLISECONDS +
      wordsCount * AVG_WORD_READING_TIME_IN_MILLISECONDS
    )
  }

  setAlertTimeout(alert) {
    const id = this.getAlertID(alert)

    this.timeouts[id] = setTimeout(() => {
      toast.dismiss(id)
    }, this.calculateDuration(alert))
  }

  clearAlertTimeout() {
    const id = this.getAlertID(alert)
    clearTimeout(this.timeouts[id])
  }

  resetAlertTimeout(alert) {
    const id = this.getAlertID(alert)

    this.clearAlertTimeout(id)
    this.clearAlertTimeout(id)
  }

  getAlertID(alert) {
    return this.getToastMessage(alert.message)
  }
}

const instance = AlertsController.getInstance()

export default instance
export { instance }
