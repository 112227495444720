import React from 'react'
import { Field } from 'redux-form/immutable'
import { FormField } from '../ui/Form'
import { trans } from '../../trans'
import { TimelineElement, TimeLineElementContent, TimeLineElementHeader } from '../Timeline'
import { Col, Row } from '../../ui/Grid'
import Button from '../ui/ButtonComponent'
import Icon, { EXPENSES_TYPES_FILLED_TO_ICONS, EXPENSES_TYPES_TO_ICONS } from '../ui/IconComponent'
import ExchangeSummaryContainer from '../../containers/ExchangeSummaryContainer'
import numeral from 'numeral'
import { Map } from 'immutable'
import { AmountFormatter } from '../AmountFormatter'
import { keepInViewport } from '../TripTimeline/Elements/KeepInViewport'
import { ForeignCurrenciesTooltip } from '../ForeignCurrenciesTooltip'

const KeepingViewportHeader = keepInViewport()(TimeLineElementHeader)

class OtherCostForm extends React.Component<any, any> {
  constructor(props) {
    super(props)
  }

  renderLabel() {
    const { getValue, types } = this.props

    const costType = types
      .map((type) => type.options)
      .reduce((a, b) => [...a, ...b], [])
      .find((option) => option.value === getValue('type_id'))

    if (costType && types) {
      if (getValue('description')) {
        return `${costType.label} - ${getValue('description')}`
      }
      return `${costType.label}`
    }

    return trans('global.fill-data')
  }

  renderEdit() {
    const { element, onEdit } = this.props
    if (element.isOpen) {
      return null
    }

    return (
      <span
        className='timeline-icon'
        onClick={() => {
          onEdit(element)
        }}
      >
        <Icon type='edit' lg gradient />
      </span>
    )
  }

  getExchangeSummaryPartials() {
    const { getValue } = this.props

    const amount = getValue('amount')
    const amount_currency = getValue('amount_currency')

    return Map({
      [amount_currency]: numeral(amount),
    })
  }

  renderForeignCurrenciesTooltip() {
    const { getValue, instanceCurrency } = this.props
    return (
      <ForeignCurrenciesTooltip
        instanceCurrency={instanceCurrency}
        foreignCurrencies={[
          {
            currency: getValue('amount_currency'),
            value: getValue('amount'),
          },
        ]}
      />
    )
  }

  renderCalculatedAmount() {
    const { getValue } = this.props
    const amount = getValue('calculated_amount')
    const currency = getValue('calculated_amount_currency')

    return (
      <div className='flex-end-center'>
        {this.renderForeignCurrenciesTooltip()}{' '}
        <span>
          <AmountFormatter amount={amount} /> {currency}
        </span>
      </div>
    )
  }

  render() {
    const {
      handleSubmit,
      element = {},
      currencies,
      onRemove,
      form,
      types,
      change,
      cost_type_slug,
    } = this.props

    const timelineIcon = EXPENSES_TYPES_TO_ICONS[cost_type_slug]

    return (
      <div data-test='other-cost-form'>
        <TimelineElement className={form} activeSimilar>
          <KeepingViewportHeader icon={timelineIcon} iconFill={true} isOpen={element.isOpen}>
            <Col xs={9} className='timeline__header--title'>
              {this.renderLabel()}
            </Col>
            <Col style={{ paddingRight: '0' }} className='is-allign-end' xs={2}>
              {this.renderCalculatedAmount()}
            </Col>
            <Col xs={1} style={{ paddingLeft: '0' }} className='is-allign-end'>
              {this.renderEdit()}
              <span className='timeline-icon' onClick={() => onRemove(element)}>
                <Icon type='trash' lg gradient />
              </span>
            </Col>
          </KeepingViewportHeader>
          <TimeLineElementContent isOpened={element.isOpen}>
            <div className='tooltip-content'>
              <form onSubmit={handleSubmit}>
                <Field name='draft' type='hidden' component={FormField} inputOnly labeltop />

                <Field name='id' type='hidden' component={FormField} inputOnly labeltop />

                <ExchangeSummaryContainer
                  key={`exchange-${form}`}
                  onSumChange={(sum) => {
                    change('calculated_amount', sum)
                  }}
                  partials={this.getExchangeSummaryPartials()}
                  showSummary={false}
                  request={this.props.request}
                />

                <Row>
                  <Col style={{ width: '500px' }}>
                    <Row>
                      <Col sm={6}>
                        <Field
                          name='type_id'
                          type='grouped-select'
                          component={FormField}
                          options={types}
                          label={trans('cost.type')}
                          labeltop
                        />
                      </Col>
                      <Col sm={6}>
                        <Row>
                          <Col sm={7} style={{ paddingRight: '0' }}>
                            <Field
                              name='amount'
                              type='formatted_number'
                              component={FormField}
                              label={trans('cost.amount')}
                              labeltop
                            />
                          </Col>
                          <Col sm={5}>
                            <Field
                              name='amount_currency'
                              type='currency'
                              component={FormField}
                              currencies={currencies}
                              labeltop
                              label=' '
                            />
                          </Col>
                        </Row>
                      </Col>
                    </Row>

                    <Row>
                      <Col sm={12}>
                        <Field
                          name='description'
                          type='text'
                          component={FormField}
                          label={trans('cost.description')}
                          labeltop
                        />
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Row className='is-margin-top'>
                  <Col sm={12}>
                    <Button primary pull_end type='submit'>
                      {trans('ui.save-cost')}
                    </Button>
                  </Col>
                </Row>
              </form>
            </div>
          </TimeLineElementContent>
        </TimelineElement>
      </div>
    )
  }
}

export { OtherCostForm }
export default { OtherCostForm }
