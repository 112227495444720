import React from 'react'
import { routes } from '../routes'

import MainNav from '../containers/MainNav/index'
import { Wrapper, Main } from '../components/ui/Layout'
import { Header } from './partials/Header'
import { Route, useRouteMatch } from 'react-router-dom'
import classNames from 'classnames'

export const DefaultLayout = ({ component: Component, type, name, ...rest }) => {
  const classes = classNames(['app', name])

  return (
    <div className={classes}>
      <MainNav items={routes.main} />
      <Route
        {...rest}
        children={(matchProps) => (
          <div className='app__main'>
            <Header />
            <Wrapper>
              <Main>
                <Component<any, any> {...matchProps} />
              </Main>
            </Wrapper>
          </div>
        )}
      />
    </div>
  )
}
