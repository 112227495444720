import React from 'react'
import { Link, Redirect } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import { config } from '../../config'
import { getRouteByName } from '../../routes'
import { BreadCrumbs } from '../../containers'
import { Section } from '../ui/Section'
import { SectionHeader } from '../ui/SectionHeader'
import { DocumentsManager } from '../../containers/DocumentsManager'
import { DocumentsListTable } from '../DocumentsListTable'
import { DocumentsListFilters } from '../DocumentsListFilters'
import { RouteManager } from '../../containers/RouteManager'
import trans from '../../trans'
import { Ability } from '../RequestPageCommon/Ability/Ability'
import { userProfile } from '../../store/app/user-profile/providers/userProfile'
import { compose } from 'redux'
import Button from '../ui/ButtonComponent'

let DocumentsListPage = (props) => {
  const {
    userProfile: {
      selectors: { data: user },
    },
  } = props

  return (
    <div>
      <Ability
        ability={['showTransaction']}
        abilities={user.abilities}
        fallback={() => {
          return <Redirect to='/reports/cockpit' />
        }}
      >
        <BreadCrumbs>
          <Link to={getRouteByName('main', 'dashboard')}>{trans('global.dashboard')}</Link>
          <Link to={getRouteByName('main', 'reports')}>{trans('global.reports')}</Link>
          <Link to={getRouteByName('main', 'allRequests')}>{trans('global.transactions')}</Link>
        </BreadCrumbs>

        <Section noBorder>
          <Helmet title={trans(config.titles.documentsList)} />

          <SectionHeader caption={trans('global.transactions')} />

          <RouteManager>
            {({ getRouteByName }) => (
              <DocumentsManager>
                {({
                  documents,
                  documentsFilters,
                  changeDocumentFilter,
                  requestsSorter,
                  changeRequestsSorter,
                  areDocumentsLoaded,
                  areDocumentsLoading,
                  MPKs,
                  providers,
                  companies,
                  currentUser,
                  documentsPagination,
                  changeDocumentsPage,
                  users,
                }) => (
                  <div>
                    <DocumentsListFilters
                      filters={documentsFilters}
                      onChangeFilter={changeDocumentFilter}
                      MPKs={MPKs}
                      currentUser={currentUser}
                      users={users}
                      providers={providers}
                      companies={companies}
                    />
                    <DocumentsListTable
                      documents={documents}
                      isLoading={!areDocumentsLoaded || areDocumentsLoading}
                      getRouteByName={getRouteByName}
                      pagination={documentsPagination}
                      changePage={changeDocumentsPage}
                      sorter={requestsSorter}
                      onChangeSorter={changeRequestsSorter}
                      instance={currentUser.instance}
                    />
                  </div>
                )}
              </DocumentsManager>
            )}
          </RouteManager>
        </Section>
      </Ability>
    </div>
  )
}

const withProfile = userProfile(false, false)

DocumentsListPage = compose(withProfile)(DocumentsListPage)

export { DocumentsListPage }
export default DocumentsListPage
