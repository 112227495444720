import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { Loader } from './ui/LoadingOverlay/Loader'
import classNames from 'classnames'
import Select from 'react-select'
import { components } from '../utils/react-select'
import { get } from 'lodash'
import { trans } from '../trans'

const documentAssign =
  ({ onSelect }) =>
  (Component) => {
    class DocumentAssign extends Component<any, any> {
      constructor(props) {
        super(props)
        this.state = {
          isLoading: false,
          saved: false,
        }
      }

      onSelect = (selected) => {
        const { document } = this.props
        this.setState({ isLoading: true })
        onSelect(document, selected)
          .then(() => {
            this.setState({ isLoading: false, saved: true })
          })
          .catch(() => {
            this.setState({ isLoading: false })
          })
      }

      render() {
        const { options, document } = this.props
        const classes = classNames({
          'document-assign': true,
          'has-loader': true,
          'document-assign--overlay':
            get(document, 'request_elements', []) &&
            !get(document, 'request_elements', []).length &&
            !this.state.saved,
        })

        return (
          <div className={classes}>
            {this.state.isLoading && <Loader />}

            <div className='document-assign__select'>
              <div className='document-assign__select-label'>
                {trans('document.assign-to-request-element')}:
              </div>
              <div className='react-select__select-container'>
                <Select
                  maxMenuHeight={114}
                  components={components}
                  options={options}
                  onChange={this.onSelect}
                  placeholder={trans('dashboard-page.assign-element')}
                />
              </div>
            </div>
            <Component<any, any> {...this.props} />
          </div>
        )
      }
    }

    DocumentAssign.propTypes = {
      document: PropTypes.object.isRequired,
      options: PropTypes.array.isRequired,
    }

    return DocumentAssign
  }

export default documentAssign
export { documentAssign }
