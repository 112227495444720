import React from 'react'
import classnames from 'classnames'
import { Link } from 'react-router-dom'

export function Tab({ title, active, id, linkTo }) {
  const classes = classnames('tabs__title', {
    'tabs__title--active': active,
  })

  return (
    <li className={classes}>
      <Link to={linkTo}>{title}</Link>
    </li>
  )
}
