import React, { Component, Fragment } from 'react'
import ListItem from './ListItem'
import { Loader } from '../ui/LoadingOverlay/Loader'
import { Scrollbars } from 'react-custom-scrollbars-2'
import { trans } from '../../trans'
import { SEARCH_STATUS_PENDING } from '../../store/app/hotels-booking'
import Button from '../ui/ButtonComponent'
import Icon from './OffersDialog'

class OffersList extends Component<any, any> {
  state = {
    window: {
      width: window.innerWidth,
      height: window.innerHeight,
    },
  }

  componentDidMount() {
    window.addEventListener('resize', () => this.updateWindowDimensions())
  }

  componentWillUnmount() {
    window.removeEventListener('resize', () => this.updateWindowDimensions())
  }

  updateWindowDimensions() {
    this.setState({
      window: {
        width: window.innerWidth,
        height: window.innerHeight,
      },
    })
  }

  getListHeight() {
    const { width, height } = this.state.window
    const isScreenSmall = width <= 1366 && height <= 768
    const isScreenTall = height > 900

    return !isScreenSmall && !isScreenTall ? 500 : 'calc(100% - 100px)'
  }

  selectOffer = (offer) => {
    const {
      trainsBooking: {
        actions: { selectOffer },
        selectors: { uuid },
        request,
        element,
      },
    } = this.props
    selectOffer(request, {
      search_uuid: uuid,
      offer: offer,
      request_element: element,
    })
  }

  renderItems = () => {
    const {
      trainsBooking: {
        selectors: { offers },
      },
    } = this.props
    return offers.map((offer) => (
      <ListItem key={offer.uuid} offer={offer} selectOffer={() => this.selectOffer(offer)} />
    ))
  }

  renderNoResults = () => {
    return (
      <div className='trip-dialog__no-results'>
        <span>{trans('hotels-booking.no-results')}</span>
      </div>
    )
  }

  render() {
    const {
      trainsBooking: {
        selectors: { isLoading, hasOffers, offers, isBlocked },
        actions: { closeResultsList },
      },
    } = this.props

    return (
      <div
        className='has-loader trip-dialog__list-wrapper train-trip__dialog-list-wrapper'
        style={{ height: this.getListHeight() }}
      >
        <Scrollbars>
          {hasOffers && (
            <Fragment>
              {!!offers.length && <ul className='trip-dialog__list'>{this.renderItems()}</ul>}

              {!offers.length && this.renderNoResults()}
            </Fragment>
          )}

          {!isLoading && !hasOffers && status !== SEARCH_STATUS_PENDING && this.renderNoResults()}
        </Scrollbars>

        {!hasOffers && status === SEARCH_STATUS_PENDING && <Loader />}

        {(isLoading || isBlocked) && <Loader />}
      </div>
    )
  }
}

OffersList.propTypes = {}

export default OffersList
export { OffersList }
