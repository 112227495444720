import React, { Component } from 'react'
import { Col, Row } from '../../ui'
import Avatar from '../Avatar/Avatar'

class Comments extends Component<any, any> {
  constructor(props) {
    super(props)
  }

  render() {
    const { comments, noPadding } = this.props

    const renderInternalList = (list) => {
      if (list.length == 0) {
        return ''
      }

      return list.map((element) => {
        return (
          <div>
            <b>{element.name}</b>
            <ul>{element.messages && element.messages.map((message) => <li>{message}</li>)}</ul>
          </div>
        )
      })
    }

    const commentList = comments.map((item) => (
      <Row key={item.id}>
        {noPadding ? null : <Col sm={1} />}
        <Col sm={noPadding ? 12 : 11}>
          <div className='comment'>
            <Avatar className='comment__photo' user={item.user} />

            <div className='comment__content'>
              <p
                className='comment__content-desc'
                dangerouslySetInnerHTML={{ __html: item.content }}
              />
              {renderInternalList(item.list)}
              <span className='comment__content-name'>
                {item.user.first_name} {item.user.last_name}
              </span>
              <span className='comment__content-date'> {item.created_at}</span>
            </div>
          </div>
        </Col>
      </Row>
    ))

    return <div>{commentList}</div>
  }
}

export default Comments
