import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { ElementManager, TimelineManager } from '../../containers/TripTimeline/index'
import { trans } from '../../trans'
import { TimelineAdd } from '../Timeline/index'
import SelectType from './SelectType'
import { TripEnd, TripStart } from './Elements/Forms'
import { EdgePointManager } from '../../containers/EdgePointManager'
import { TimelineManagerOverlay } from '../../containers/TripTimeline/TimelineManagerOverlay'
import { Loader } from '../ui/LoadingOverlay/Loader'

class TripTimeline extends Component<any, any> {
  renderElements(elements, onAdd) {
    const { request } = this.props
    const hasStoredTargetPoint =
      elements.filter((element) => element.type === 'target_point' && !element.draft).length !== 0

    return (
      <div className='timeline__element'>
        {elements.map((element, index) => {
          const timelineAddClasses = [
            'timeline--add',
            element.type === 'target_point' && 'timeline--add-target-point',
          ]
            .filter((className) => className)
            .join(' ')

          return (
            <div key={`${element.type}-${element.id}${element.virtual ? '-virtual' : ''}`}>
              {request['abilities']['edit'] && hasStoredTargetPoint && (
                <TimelineAdd
                  className={timelineAddClasses}
                  icon='plus'
                  gradient='true'
                  onClick={() => onAdd(index)}
                >
                  {trans('global.add')}
                </TimelineAdd>
              )}

              <ElementManager request={request} element={element}>
                {({ Component, instanceCurrency, onSave, onRemove, onChangeType }) => {
                  return this.renderElementComponent(
                    element,
                    instanceCurrency,
                    Component,
                    onSave,
                    onRemove,
                    onChangeType,
                  )
                }}
              </ElementManager>
            </div>
          )
        })}
        {request['abilities']['edit'] && hasStoredTargetPoint && (
          <TimelineAdd
            icon='plus'
            gradient='true'
            className='timeline--add'
            onClick={() => onAdd(elements.length)}
          >
            {trans('global.add')}
          </TimelineAdd>
        )}
      </div>
    )
  }

  renderElementComponent(element, instanceCurrency, Component, onSave, onRemove, onChangeType) {
    const { request, currencies } = this.props

    if (Component === SelectType) {
      return (
        <Component
          selected={null}
          onChange={null}
          onSelect={(type) => onChangeType(element, type)}
          onRemove={onRemove}
        />
      )
    }

    return (
      <Component
        request={request}
        element={element}
        instanceCurrency={instanceCurrency}
        onSave={onSave}
        onRemove={onRemove}
        onChangeType={onChangeType}
        currencies={currencies}
      />
    )
  }

  renderEditable(elements, onAdd) {
    const { request } = this.props
    return (
      <EdgePointManager request={request}>
        {({
          onLocationChange,
          start,
          end,
          onDateChange,
          startMaxDate,
          instanceLocation,
          endMinDate,
          disabled,
        }) => {
          return (
            <div>
              <TripStart
                key={'trip-timeline-element-start'}
                start={start}
                onLocationChange={onLocationChange}
                onDateChange={onDateChange}
                startMaxDate={startMaxDate}
                instanceLocation={instanceLocation}
                disabled={disabled}
                request={request}
              />

              {this.renderElements(elements, onAdd)}

              <TripEnd
                key={'trip-timeline-element-end'}
                end={end}
                onLocationChange={onLocationChange}
                onDateChange={onDateChange}
                endMinDate={endMinDate}
                instanceLocation={instanceLocation}
                disabled={disabled}
                request={request}
              />
            </div>
          )
        }}
      </EdgePointManager>
    )
  }

  render() {
    const { request } = this.props

    return (
      <div data-test='trip-timeline'>
        <TimelineManagerOverlay>
          {({ isRecomposition }) => {
            return (
              <div className='has-loader'>
                {isRecomposition && <Loader />}

                <TimelineManager request={request}>
                  {({ elements, onAdd }) => {
                    return (
                      <div>
                        <div className='timeline-container timeline-container--trip-plan trip-plan--draft'>
                          {this.renderEditable(elements, onAdd)}
                        </div>
                      </div>
                    )
                  }}
                </TimelineManager>
              </div>
            )
          }}
        </TimelineManagerOverlay>
      </div>
    )
  }
}

TripTimeline.propTypes = {
  request: PropTypes.object.isRequired,
  currencies: PropTypes.array.isRequired,
}

export { TripTimeline }
export default TripTimeline
