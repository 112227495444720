import React from 'react'
import { isFunction } from 'lodash'
import Icon from '../ui/IconComponent'
import invalidFileIcon from '../../../../assets/img/file_error.svg'
import { get } from 'lodash'
import classNames from 'classnames'
import { Col } from '../../ui/Grid'

class DocumentCellFail extends React.Component<any, any> {
  onDelete = () => {
    const { deleteDocument, document } = this.props
    deleteDocument(document)
  }

  render() {
    const { xs, sm, md, width, className, document } = this.props

    return (
      <Col xs={xs} sm={sm} md={md} style={{ display: 'flex', width }}>
        <div className={classNames('document-cell-outer', className)}>
          <div className='document-cell-inner document-cell-inner--is-deletable'>
            <div className='document-cell-inner__delete-icon' onClick={() => this.onDelete()}>
              <Icon type='trash' lg />
            </div>
            <span className='dropzone__header'>Zły format pliku</span>
            <div
              className='document-cell__img-wrapper document-cell__img-wrapper--failure'
              style={{ backgroundImage: `url(${invalidFileIcon})` }}
            />
            <div className='document-cell__content-data'>
              {get(document, 'errors', []).map((error, i) => {
                return <p key={i}>{error}</p>
              })}
            </div>
          </div>
        </div>
      </Col>
    )
  }
}

export { DocumentCellFail }
export default { DocumentCellFail }
