import React, { DetailedHTMLProps, InputHTMLAttributes } from 'react'
import classNames from 'classnames'
import Icon from '../../components/ui/IconComponent'

interface CheckboxProps
  extends DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {
  label?: string
}

export const Checkbox = ({ className, label, ...props }: CheckboxProps) => {
  const { disabled } = props
  const checkboxClasses = classNames(className, {
    'checkbox-wrapper__input-wrapper': true,
    'checkbox-wrapper__input-wrapper--is-disabled': disabled,
  })

  return (
    <label className='checkbox-wrapper'>
      <div className={checkboxClasses}>
        <input type='checkbox' className='checkbox-wrapper__input' {...props} />
        <Icon type='checked' className='checkbox-wrapper__icon' />
      </div>
      {label && <span className='checkbox-wrapper__label'>{label}</span>}
    </label>
  )
}
