import React from 'react'
import { bindActionCreators } from 'redux'
import { reset, set } from '../../store/app/non-delegation/creators'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { ProviderContext } from './context'

const provide = (Component) => {
  class NonDelegationProvider extends React.Component<any, any> {
    componentDidMount() {
      const { reset, set, request } = this.props
      reset()
      set(request)
    }

    componentWillUnmount() {
      const { reset } = this.props
      reset()
    }

    render() {
      const context = {
        request: this.props.request,
      }

      return (
        <ProviderContext.Provider value={context}>
          <Component<any, any> {...this.props} />
        </ProviderContext.Provider>
      )
    }
  }

  NonDelegationProvider.propTypes = {
    request: PropTypes.object.isRequired,
  }

  const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
      {
        reset,
        set,
      },
      dispatch,
    )
  }

  return connect(null, mapDispatchToProps)(NonDelegationProvider)
}

export default provide
export { provide }
