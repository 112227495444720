import React from 'react'
import { reduxForm, SubmissionError } from 'redux-form/immutable'
import { connect } from 'react-redux'
import { fromJS } from 'immutable'
import { Bus as Form } from '../Forms'
import { bindActionCreators, compose } from 'redux'
import { processAPIerrorResponseToFormErrors } from '../../../../services/APIClient'
import { prepareRequestDates } from '../../../../utils/prepareRequestDates'
import { Factory as ElementFactory } from '../../../../models/timeline/index'
import { getFormValues } from '../../../../utils/forms'
import { DateSuggester } from '../../../../store/app/trip-timeline/services/date-suggester'
import { LocationSuggester } from '../../../../store/app/trip-timeline/services/location-suggester'
import { getCurrency } from '../../../../store/app/instance'
import foreignCurrenciesTooltip from '../../../ForeignCurrenciesTooltip'
import { setEndOfDay } from '../../../../utils/setEndOfDay'

class Bus extends React.Component<any, any> {
  render() {
    const { ...props } = this.props

    if (!this.props.initialized) {
      return null
    }

    return <Form {...props} />
  }
}

export const submit = (values, dispatch, props) => {
  const { onSave, request, change } = props

  values = prepareRequestDates(values)
  values.return_at = setEndOfDay(values.return_at, true)

  return onSave(request, values, props.element).then(
    () => {
      dispatch(change('isOpen', false))
    },
    (alerts) => {
      throw new SubmissionError(processAPIerrorResponseToFormErrors(alerts))
    },
  )
}

export const change = (values, dispatch, props) => {
  const { change } = props
  if (values.get('return_at') < values.get('departure_at')) {
    dispatch(change('return_at', values.get('departure_at')))
  }
}

const withForm = reduxForm({
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
  onSubmit: submit,
  onChange: change,
  destroyOnUnmount: false,
  touchOnBlur: false,
})

const mapStateToProps = (state, props) => {
  const { request, element, currencies } = props
  const bus = ElementFactory.create(element)

  const dateSuggester = new DateSuggester(state, bus)
  const locationSuggester = new LocationSuggester(state, bus)

  const instanceCurrency = getCurrency(state)
  const currentValues = ElementFactory.create(getFormValues(bus.key, state))

  return {
    initialValues: fromJS({
      uuid: bus.uuid,
      departure_at: bus.draft ? dateSuggester.suggestStartDate() : bus.getStartDate(),
      return_at: bus.draft ? dateSuggester.suggestEndDate() : bus.getEndDate(),
      destination_location: bus.draft
        ? locationSuggester.suggestEndLocation()
        : bus.getEndLocation(),
      departure_location: bus.draft
        ? locationSuggester.suggestStartLocation()
        : bus.getStartLocation(),
      amount: bus.amount,
      amount_currency: !bus.draft ? bus.amount_currency : instanceCurrency,
      converted_amount: bus.converted_amount,
      calculated_amount_currency: bus.calculated_amount_currency,
      round_trip: bus.round_trip,
      id: bus.id,
      type: bus.type,
      isOpen: bus.isOpen,
      draft: bus.draft,
      virtual: bus.virtual,
    }),
    form: bus.key,
    request,
    bus: currentValues,
    currencies,
    minDate: dateSuggester.suggestMinDate(),
    maxDate: dateSuggester.suggestMaxDate(),
    maxStartDate: dateSuggester.suggestMaxStartDate(),
    instanceCurrency,
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({}, dispatch)
}

const withConnect = connect(mapStateToProps, mapDispatchToProps)

Bus = compose(withConnect, withForm)(Bus)

export { Bus }
export default { Bus }
