import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { get } from 'lodash'
import APIClient from '../../../services/APIClient'

export const MODULE_MOUNT_POINT = 'train-stations'

const RESET = MODULE_MOUNT_POINT + '::reset'
const SET = MODULE_MOUNT_POINT + '::set'
const SET_LOADING = MODULE_MOUNT_POINT + '::set-loading'

const getInitialState = () => {
  return {
    data: [],
    loading: true,
  }
}

// actions Creators
const reset = () => (dispatch) => {
  dispatch({
    type: RESET,
  })
}

const set = (data) => (dispatch) => {
  dispatch({
    type: SET,
    payload: data,
  })
}

const setLoading = (state) => (dispatch) => {
  dispatch({
    type: SET_LOADING,
    payload: { state },
  })
}

const fetch = () => (dispatch) => {
  return APIClient.getTrainStations().then((response) => {
    dispatch(set(response.data))
    dispatch(setLoading(false))
  })
}

//actions
const resetAction = () => {
  return getInitialState()
}

const setAction = (state, payload) => {
  return {
    ...state,
    data: payload,
    loading: false,
  }
}

const setLoadingAction = (state, payload) => {
  return {
    ...state,
    loading: payload.state,
  }
}

const actions = {
  [RESET]: resetAction,
  [SET]: setAction,
  [SET_LOADING]: setLoadingAction,
}

//selectors
const getState = (state) => {
  return state.get(MODULE_MOUNT_POINT)
}

const getStations = (state) => {
  return get(getState(state), 'data', {})
}

const isLoading = (state) => {
  return get(getState(state), 'loading', true)
}

export const reducer = (state = getInitialState(), action) => {
  if (actions.hasOwnProperty(action.type)) {
    return actions[action.type](state, action.payload)
  }
  return state
}

const trainStations =
  (resetOnMount = false, fetchOnMount = false) =>
  (Component) => {
    class TrainStationsProvider extends React.Component<any, any> {
      componentDidMount() {
        const { trainStations } = this.props

        if (resetOnMount) {
          trainStations.actions.reset()
        }

        if (fetchOnMount) {
          trainStations.actions.fetch()
        }
      }

      render() {
        return <Component<any, any> {...this.props} />
      }
    }

    const mapStateToProps = (state) => ({
      stations: getStations(state),
      isLoading: isLoading(state),
    })

    const mapDispatchToProps = (dispatch) => {
      return bindActionCreators(
        {
          reset,
          set,
          fetch,
        },
        dispatch,
      )
    }

    const mergedProps = (selectors, actions, own) => {
      return {
        ...own,
        trainStations: {
          selectors,
          actions,
          ...own.trainStations,
        },
      }
    }

    return connect(mapStateToProps, mapDispatchToProps, mergedProps)(TrainStationsProvider)
  }

export { trainStations }
export default trainStations
