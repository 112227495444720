import React from 'react'
import {
  bookOfferAction,
  changeAction,
  changeErrorMessageSlugAction,
  changeStatusAction,
  reduxFormChangeAction,
  reduxFormInitializeAction,
  resetAction,
  searchAnotherOneAction,
  selectOfferAction,
  selectOfferOptionAction,
  setAction,
  setAgreementAction,
  setOffersAction,
  toggleSearchAction,
  valuateOfferAction,
} from './actions'
import { get } from 'lodash'

export const MODULE_MOUNT_POINT = 'hotels-booking'

export const RESET = MODULE_MOUNT_POINT + '::reset'
export const SET = MODULE_MOUNT_POINT + '::set'
export const CHANGE = MODULE_MOUNT_POINT + '::change'
export const SET_OFFERS = MODULE_MOUNT_POINT + '::set-offers'
export const SELECT_OFFER = MODULE_MOUNT_POINT + '::select-offer'
export const CHANGE_STATUS = MODULE_MOUNT_POINT + '::change-status'
export const SELECT_OFFER_OPTION = MODULE_MOUNT_POINT + '::select-offer-option'
export const RESET_FILTERS = MODULE_MOUNT_POINT + '::reset-filters'
export const SEARCH_ANOTHER_ONE = MODULE_MOUNT_POINT + '::search-another-one'
export const BOOK_OFFER = MODULE_MOUNT_POINT + '::book-offer'
export const VALUATE_OFFER = MODULE_MOUNT_POINT + '::valuate-offer'
export const TOGGLE_SEARCH = MODULE_MOUNT_POINT + '::toggle-search'
export const SET_AGREEMENT = MODULE_MOUNT_POINT + '::set-agreement'
export const CHANGE_ERROR_MESSAGE_SLUG = MODULE_MOUNT_POINT + '::change-error-message-slug'

const REDUX_FORM_CHANGE = '@@redux-form/CHANGE'
const REDUX_FORM_INITIALIZE = '@@redux-form/INITIALIZE'

export const STEP_MAP = 'map'
export const STEP_SEARCHING = 'searching'
export const STEP_LISTING = 'listing'
export const STEP_OPTIONS = 'options'

export const SEARCH_STATUS_PENDING = 'pending'
export const SEARCH_STATUS_COMPLETE = 'complete'

export const BOOKING_SUCCESS = 'booked'
export const BOOKING_EXPIRED = 'expired'
export const BOOKING_FAILED = 'failed'
export const BOOKING_WAITING_FOR_VERIFY = 'waiting_for_verify'
export const BOOKING_WAITING_FOR_CONFIRM = 'waiting_for_confirm'
export const BOOKING_WAITING_FOR_TICKET = 'waiting_for_ticket'
export const BOOKING_NEW = 'new'
export const BOOKING_OFFER_CHANGED = 'offer_changed'
export const BOOKING_YA_RE_CHECK = 'ya_re_check'
export const BOOKING_PROCESS_TOO_LONG = 'booking_process_too_long'
export const BOOKING_BOOKED_WITHOUT_TICKET = 'booked_without_ticket'
export const BOOKING_WAITING_FOR_ACCEPTATION = 'waiting_for_acceptation'

export const FILTERING_IN_PROGRESS = 'filtering-in-progress'
export const FILTERING_FILTERED = 'filtering-filtered'

export const DEFAULT_SEARCH_RADIUS = 10
export const DEFAULT_COORDINATE_LAT = 52.2297
export const DEFAULT_COORDINATE_LNG = 21.0122

const actions = {
  [RESET]: resetAction,
  [SET]: setAction,
  [CHANGE]: changeAction,
  [SET_OFFERS]: setOffersAction,
  [SELECT_OFFER]: selectOfferAction,
  [CHANGE_STATUS]: changeStatusAction,
  [SELECT_OFFER_OPTION]: selectOfferOptionAction,
  [REDUX_FORM_CHANGE]: reduxFormChangeAction,
  [REDUX_FORM_INITIALIZE]: reduxFormInitializeAction,
  [SEARCH_ANOTHER_ONE]: searchAnotherOneAction,
  [BOOK_OFFER]: bookOfferAction,
  [VALUATE_OFFER]: valuateOfferAction,
  [TOGGLE_SEARCH]: toggleSearchAction,
  [SET_AGREEMENT]: setAgreementAction,
  [CHANGE_ERROR_MESSAGE_SLUG]: changeErrorMessageSlugAction,
}

export const getInitialState = () => {
  return {
    step: STEP_MAP,
    offers: [],
    status: SEARCH_STATUS_PENDING,
    initialized: false,
    selected: null,
    uuid: null,
    roomAllocations: [],
    totalPaxes: 1,
    filters: {},
    facilities: {
      breakfast: false,
    },
    initialFacilities: {
      breakfast: false,
    },
    sortBy: null,
    query: {},
    enabled: true,
    searching: false,
    hasOffers: false,
    isResultsListOpen: false,
    isOfferDialogOpen: false,
    isFiltering: false,
    isChanged: false,
    isFacilityChanged: false,
    statusAfterFiltering: SEARCH_STATUS_PENDING,
    processedOffers: [],
    lastComputed: null,
  }
}

export const reducer = (state = {}, action) => {
  if (actions.hasOwnProperty(action.type)) {
    return actions[action.type](state, action.payload, get(action, 'meta', {}))
  }
  return state
}
