import React from 'react'
import { provide } from '../../../containers/FlightsBooking'
import BookingContent from './BookingContent'

const BookingComponent = (props) => <BookingContent currencies={props.currencies} />

const Booking = provide((props) => {
  return {
    name: props.element.key,
    request: props.request,
    element: props.plane,
  }
}, false)(BookingComponent)

export { Booking }
export default Booking
