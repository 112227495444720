export const getLumpSumFromSubSummary = (subsummary) => {
  let sum = 0

  if (subsummary) {
    Object.keys(subsummary).forEach((key) => {
      const partial_sum = Object.keys(subsummary[key]['elements']).reduce(function (previous, j) {
        return previous + parseFloat(subsummary[key]['elements'][j].convertedAmount)
      }, 0)

      sum += partial_sum
    })
  }

  return sum
}
