import React from 'react'
import trans from '../../trans'
import Icon from '../../components/ui/IconComponent'
import ProfileDropdown from '../../components/ProfileDropdown'
import { NotificationDropdown } from '../../containers/NotificationDropdown'
import AssistantDropdown from '../../components/AssistantDropdown'
import Paper from '@material-ui/core/Paper'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import Dialog from '@material-ui/core/Dialog'
import Slide from '@material-ui/core/Slide'
import { getInstance } from '../../store/app/instance'
import store from '../../store'
import { connect } from 'react-redux'
import { compose } from 'redux'

function Transition(props) {
  return <Slide direction='up' {...props} />
}

const HeaderBase = class extends React.Component<any, any> {
  constructor(props) {
    super(props)
    this.state = {
      isOpen: false,
    }
  }

  openPopup = () => {
    this.setState({ isOpen: true })
  }

  closePopup = () => {
    this.setState({ isOpen: false })
  }

  render() {
    const {
      instance: { trip_agent },
    } = this.props

    const search = (
      <div className='main-header__search header-search'>
        <div className='input-group header-search__input-group'>
          <Icon type='search' lg />
          <input
            type='text'
            placeholder={trans('global.search-for-person-request')}
            className='input-group__input header-search__input-group-input'
          />
          <button className='input-group__btn'>{trans('ui.search')}</button>
        </div>
      </div>
    )

    return (
      <header className='app__main-header main-header'>
        <AssistantDropdown />
        <ProfileDropdown />

        <div className='main-header__icons'>
          <NotificationDropdown />

          <Icon onClick={this.openPopup} type='question_fill' xlg />
        </div>

        <Dialog
          open={this.state.isOpen}
          TransitionComponent={Transition}
          keepMounted
          onClose={this.closePopup}
          aria-labelledby='alert-dialog-slide-title'
          aria-describedby='alert-dialog-slide-description'
          className='last-requests__dialog'
        >
          <Paper square>
            <DialogTitle id='alert-dialog-slide-title'>{trans('global.hints')}</DialogTitle>

            <DialogContent className='last-requests__dialog-content'>
              <div className='help-dialog'>
                <h4>{trans('global.travel-support')}</h4>

                <p dangerouslySetInnerHTML={{ __html: trip_agent }} />

                <br />
                <h4>{trans('global.technical-support')}</h4>
                <p>
                  <a href='mailto:support@mindento.com'>support@mindento.com</a>
                </p>
              </div>
            </DialogContent>

            <DialogActions className='no-margin'>
              <Icon
                className='last-requests__dialog-close'
                type='close'
                onClick={this.closePopup}
              />
            </DialogActions>
          </Paper>
        </Dialog>
      </header>
    )
  }
}

const mapStateToProps = (state, props) => {
  return {
    instance: getInstance(store.getState()),
  }
}

const withConnect = connect(mapStateToProps)
const Header = React.memo(compose(withConnect)(HeaderBase))

export default Header
export { Header }
