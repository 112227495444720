import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import Button from '../../components/ui/ButtonComponent'
import { getRouteByName } from '../../routes'
import trans from '../../trans'
import APIClient from '../../services/APIClient'
import { history } from '../../store'

export default class NewRequestButton extends Component<any, any> {
  renderNewRequestButton = (owner) => {
    if (owner && owner.full_name) {
      return `${trans('request.new-request-for')} ${owner.full_name}`
    }

    return trans('request.new-request')
  }

  onClick = () => {
    const { owner } = this.props

    return APIClient.createRequestFor(owner).then((response) => {
      history.push(getRouteByName('main', 'tripRequestShow', { id: response.data.slug }))
    })
  }

  render() {
    const { owner } = this.props

    return (
      <Button primary disabled={!owner || !owner.slug} onClick={this.onClick}>
        {this.renderNewRequestButton(owner)}
      </Button>
    )
  }
}
