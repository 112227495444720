import React from 'react'
import { BreadCrumbs } from '../../containers'
import { Link } from 'react-router-dom'
import { getRouteByName } from '../../routes'
import trans from '../../trans'
import Advanced from './Advanced'
import { userProfile } from '../../store/app/user-profile/providers/userProfile'
import { countries } from '../../store/app/countries'
import { compose } from 'redux'
import { Helmet } from 'react-helmet'
import { languages } from '../../store/app/languages'

class InstanceSettingsPage extends React.Component<any, any> {
  render() {
    return (
      <div className='settings-page'>
        <Helmet title={trans('user.page-title')} />

        <BreadCrumbs>
          <Link to={getRouteByName('main', 'dashboard')}>{trans('global.dashboard')}</Link>
          <Link to={getRouteByName('main', 'instance-settings')}>
            {trans('instance-settings.instance-settings')}
          </Link>
          <Link to={`${this.props.match.path}/additional`}>
            {trans('instance-settings.advanced')}
          </Link>
        </BreadCrumbs>

        <section className='section section--no-border settings-page__section has-loader'>
          <div className='settings-page__account-container'>
            <Advanced />
          </div>
        </section>
      </div>
    )
  }
}

const withUserProfile = userProfile(false)
const withCountries = countries(false, false)
const withLanguages = languages(false, false)

export default compose(withUserProfile, withCountries, withLanguages)(InstanceSettingsPage)
