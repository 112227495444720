import PropTypes from 'prop-types'
import React from 'react'
import moment from 'moment'
import { formValueSelector } from 'redux-form/immutable'
import { connect } from 'react-redux'
import { change as changeForm, stopSubmit } from 'redux-form'
import { fromJS } from 'immutable'
import { AccountDocumentForm as FormComponent } from '../../components/AccountDocumentForm'
import {
  getDocument,
  getProvider,
  getProviders,
  updateDocument,
  changeProvider,
  getCurrencies,
  startLoading,
} from '../../store/app/document-account-page'
import { bindActionCreators, compose } from 'redux'
import { config } from '../../config'
import { createAutoSaveForm, getFormValues } from '../../utils/forms'
import exchange from '../../store/app/exchange-rates'
import { Map } from 'immutable'
import { withAmountLoader } from '../DocumentForm/withAmountLoader'
import _ from 'lodash'
import { getCache, setCache } from '../../store/app/account-dimensions'
import {
  generateAccountDimensionFormValues,
  isAccountDimensionField,
  submitAccountDimension,
} from '../../store/app/account-dimensions/form'
import {
  applyTransactionSuggestion,
  detachTransactionSuggestion,
  fetchTransactionSuggestions,
  getSelectedTransaction,
  getTransactionSuggestions,
  isTransactionSuggestionsLoading,
  startLoading as setLoadingTransactionSuggestion,
} from '../../store/app/transaction-suggestion'
import APIClient, { processAPIerrorResponseToFormErrors } from '../../services/APIClient'
import { selectInstanceDefaultCurrency } from '../App/selectors'

export const FORM_NAME = 'account-document-form'

const isInvoice = (accountingType) => _.includes(['correcting_invoice', 'invoice'], accountingType)

const AccountDocumentFormBase = createAutoSaveForm(FormComponent, {
  name: FORM_NAME,
  enableReinitialize: false,
  timeout: 100,
  fieldsExcludedFromDebounce: ['provider'],
  save: (name, value, dispatch, props) => {
    const {
      updateDocument,
      document,
      amountLoader,
      accountDimensionItems,
      accountDimensions,
      setCache,
      applyTransactionSuggestion,
      detachTransactionSuggestion,
      setLoadingTransactionSuggestion,
    } = props

    if (['gross', 'currency', 'issue_date'].includes(name)) {
      setLoadingTransactionSuggestion()
    }

    if (name === 'provider' && value.id === 'disposable') {
      return Promise.resolve()
    }

    if (['issue_date', 'received_date', 'vat_date'].includes(name)) {
      value = moment(value).format(config.apiDateFormat)
    }

    if (
      ['gross', 'currency_id', 'payment', 'exchange_rate', 'issue_date', 'received_date'].indexOf(
        name,
      ) !== -1
    ) {
      amountLoader.setLoading(true)
    }

    if (name === 'default_exchange_rate_accepted') {
      value = true
    }

    if (name === 'transaction') {
      if (value) {
        return applyTransactionSuggestion(value)
      }

      return detachTransactionSuggestion()
    }

    if (isAccountDimensionField(name)) {
      return submitAccountDimension({
        name,
        value,
        setCache,
        dimensions: accountDimensions,
        items: accountDimensionItems,
        updateMethod: (dim_id) =>
          APIClient.updateDocumentAccountDimension(document.id, dim_id, value.id),
        deleteMethod: (dim_id) => APIClient.deleteDocumentAccountDimension(document.id, dim_id),
      })
    }

    return updateDocument(document['id'], { [name]: value })
      .then((response) => {
        amountLoader.setLoading(false)
        dispatch(
          changeForm(
            FORM_NAME,
            'default_exchange_rate_accepted',
            response.data.default_exchange_rate_accepted,
          ),
        )
        dispatch(changeForm(FORM_NAME, 'net', response.data.net))

        return Promise.resolve(response)
      })
      .catch((response) => {
        const errors = processAPIerrorResponseToFormErrors(response.alerts, true)
        dispatch(stopSubmit(FORM_NAME, errors))

        return response.alerts
      })
  },

  change: (values, dispatch, props, previousValues) => {
    if (values instanceof Map) {
      values = values.toJS()
    }

    if (previousValues instanceof Map) {
      previousValues = previousValues.toJS()
    }

    if (
      !isInvoice(values['accounting_type']) &&
      values['accounting_type'] !== previousValues['accounting_type']
    ) {
      dispatch(changeForm(FORM_NAME, 'is_margin', 0))
      dispatch(changeForm(FORM_NAME, 'corrected_document_number', null))
    }

    if (
      values['issue_date'] !== previousValues['issue_date'] ||
      values['currency_id'] !== previousValues['currency_id']
    ) {
      dispatch(startLoading('documentElementTypes'))
    }
  },
})

AccountDocumentFormBase.propTypes = {
  document: PropTypes.object.isRequired,
  provider: PropTypes.object.isRequired,
  providers: PropTypes.array.isRequired,
  changeProvider: PropTypes.func.isRequired,
  accountDimensions: PropTypes.array.isRequired,
}

const mapStateToProps = (state, props) => {
  const { accountDimensions = [] } = props
  const document = getDocument(state)
  const provider = getProvider(state)
  const providers = getProviders(state)
  const currencies = getCurrencies(state)
  const { accountDimensionItems = [] } = document
  const transaction = getSelectedTransaction(state)

  const selector = formValueSelector(FORM_NAME, (state) => {
    return state.get('form').toJS()
  })

  return {
    initialValues: fromJS({
      document_number: document['document_number'],
      issue_date: moment(document['issue_date']).format(config.apiDateFormat),
      received_date: moment(document['received_date']).format(config.apiDateFormat),
      vat_date: moment(document['vat_date']).format(config.apiDateFormat),
      accounting_type: document.accounting_type ? document.accounting_type : null,
      is_margin: document.is_margin,
      annotation: document['annotation'],
      gross: document['gross'],
      net: document['net'],
      provider: document['provider'],
      payment: document['payment'],
      exchange_rate: document['exchange_rate'],
      currency_id: document['currency'] ? document['currency']['id'] : null,
      default_exchange_rate_accepted: document['default_exchange_rate_accepted'],
      corrected_document_number: document['corrected_document_number'],
      transaction: transaction?.transaction.id,
      ...generateAccountDimensionFormValues(accountDimensions, accountDimensionItems),
    }),
    provider,
    providers,
    document,
    currencies,
    accountDimensions,
    gross: selector(state, 'gross'),
    net: selector(state, 'net'),
    exchange_rate: selector(state, 'exchange_rate'),
    currency_id: selector(state, 'currency_id'),
    is_margin_shown: isInvoice(selector(state, 'accounting_type')),
    data: getFormValues(FORM_NAME, state),
    getValue: (name) => selector(state, name),
    accountDimensionItems: getCache(state)(FORM_NAME),
    transactionSuggestions: getTransactionSuggestions(state),
    transactionSuggestionsLoading: isTransactionSuggestionsLoading(state),
    defaultCurrency: selectInstanceDefaultCurrency(state),
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      updateDocument,
      changeProvider,
      setCache: (data) => setCache({ name: FORM_NAME, data }),
      fetchTransactionSuggestions,
      applyTransactionSuggestion,
      detachTransactionSuggestion,
      setLoadingTransactionSuggestion,
    },
    dispatch,
  )
}

const withConnect = connect(mapStateToProps, mapDispatchToProps)
const withExchange = exchange({
  exchangeName: 'account-document',
  resetOnMount: true,
  fetchOnMount: true,
  destroyOnUnmount: true,
})

const AccountDocumentForm = compose(
  withExchange,
  withConnect,
  withAmountLoader,
)(AccountDocumentFormBase)

export { AccountDocumentForm }
export default { AccountDocumentForm }
