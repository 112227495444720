import trans from '../../../trans'
import { ASYNC_SELECT_DEFAULT_LIMIT } from '../../../components/ui/Form/AsyncSelectField'
import APIClient from '../../../services/APIClient'
import { getString } from '../../../utils/string'

export const getAccountDimensionKey = (slug) => `account-dimensions_${slug}`

export const getAccountDimensionIdFromKey = (key) => key.replace('account-dimensions_', '')

export const isAccountDimensionField = (key) => key && key.startsWith('account-dimensions_')

export const generateAccountDimensionFormValues = (
  allAccountDimensions,
  entryAccountDimensions = [],
) => {
  if (!allAccountDimensions || !entryAccountDimensions) {
    return []
  }

  if (allAccountDimensions.length > 0) {
    const accountDimensionFormValues = Object.assign(
      ...allAccountDimensions.map((item) => ({
        [getAccountDimensionKey(item.slug)]: null,
      })),
    )

    entryAccountDimensions.forEach((item) => {
      const dimension = allAccountDimensions.find((dim) => dim.id === item.account_dimension_id)

      if (!dimension) {
        return
      }

      const key = getAccountDimensionKey(dimension.slug)

      accountDimensionFormValues[key] = {
        value: item.accountDimensionItem.id,
        label: `${getString(item.accountDimensionItem.code)} ${getString(
          item.accountDimensionItem.name,
        )}`,
      }
    })

    return accountDimensionFormValues
  }
  return {}
}

export const submitAccountDimension = ({
  name,
  value,
  items,
  setCache,
  dimensions,
  updateMethod,
  deleteMethod,
}) => {
  const slug = getAccountDimensionIdFromKey(name)
  const dimension = dimensions.find((dim) => dim.slug === slug)
  const id = dimension.id
  const accountDimensionItem = items.find((dim) => dim.account_dimension_id === id)

  if (!value || value.value === null) {
    if (!accountDimensionItem) {
      return Promise.resolve()
    }

    return deleteMethod(accountDimensionItem.id).then(() => {
      setCache(items.filter((item) => item.id !== accountDimensionItem.id))
    })
  }

  return updateMethod(id).then(({ data }) => {
    setCache([...items.filter((item) => item.account_dimension_id !== id), data])
  })
}

export const transformDimensions = (item) => ({
  id: item.id,
  label: item.name,
  slug: item.slug,
  required: item.is_required,
  disabled: item.disabled,
  fieldName: getAccountDimensionKey(item.slug),
  options: [
    !item.is_required
      ? {
          label: trans('account-dimensions.empty'),
          value: null,
        }
      : null,
    ...(item.items || [])
      .filter((child) => child.is_active)
      .map((child) => ({
        label: `${child.code} ${child.name}`,
        value: child.id,
      })),
  ].filter(Boolean),
})

export const fetchAdditionalDimensionOptions = (slug, required) => async (search, prevOptions) => {
  const hasPlaceholder =
    prevOptions.filter((item) => item.label === trans('trip-request.empty-project-option-label'))
      .length > 0
  const limit = ASYNC_SELECT_DEFAULT_LIMIT
  const offset = hasPlaceholder ? prevOptions.length - 1 : prevOptions.length

  const createLabel = (option) => {
    if (option.name) {
      return `${getString(option.code)} - ${getString(option.name)}`
    }

    return getString(option.code)
  }

  try {
    const response = await APIClient.getAdditionalDimensionItems(slug, offset, limit, search)

    let data = response.data.map((item) => {
      return {
        value: item.id,
        label: createLabel(item),
        ...item,
      }
    })

    if (!hasPlaceholder && !required) {
      data = [
        {
          label: trans('trip-request.empty-project-option-label'),
          value: null,
          onSelectResetsInput: true,
        },
        ...data,
      ]
    }

    const { pagination } = response

    return {
      options: data,
      hasMore: pagination.total > pagination.offset + response.data.length,
    }
  } catch (_) {
    return {
      options: [],
      hasMore: false,
    }
  }
}
