import React, { Component } from 'react'
import { Loader } from '../../ui/LoadingOverlay/Loader'
import { trans } from '../../../trans'
import Button from '../../ui/ButtonComponent'
import Regular from '../../Formik/Fields/Regular'

const Security = (props) => {
  const { handleSubmit, isSubmitting } = props

  return (
    <form onSubmit={handleSubmit}>
      {isSubmitting && <Loader />}

      <div className='user-profile__fields-wrapper'>
        <div className='user-profile__fields'>
          <Regular type='password' label={trans('user.new_password')} name='new_password' />

          <Regular
            type='password'
            label={trans('user.new_password_confirmation')}
            name='new_password_confirmation'
          />
        </div>
        <div />
      </div>
      <div className='is-margin-top'>
        <Button primary pull_end type='submit'>
          {trans('user.change-password')}
        </Button>
      </div>
    </form>
  )
}

Security.propTypes = {}

export default Security
export { Security }
