import React, { Fragment } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { Cell, Row, Table, TBody, THead } from '../../../ui/AccordionTable'
import trans from '../../../../trans'
import {
  changeExpenseGroupOrder,
  fetchExpenseGroups,
  getExpenseGroups,
  isLoading,
} from '../../../../store/app/expense-group'
import ExpenseGroupListFormItem from './ExpenseGroupListFormItem'
import ExpenseGroupListItem from './ExpenseGroupListItem'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'
import { CompanyBaseList } from '../../CompanyBaseList'

class ExpenseGroupList extends CompanyBaseList {
  pagination = false
  canCreate = false

  getIdentifierKey() {
    return 'slug'
  }

  getFormComponent() {
    return ExpenseGroupListFormItem
  }

  getListItemComponent() {
    return ExpenseGroupListItem
  }

  renderTableContent() {
    const { items, changeExpenseGroupOrder } = this.props

    return (
      <DragDropContext onDragEnd={changeExpenseGroupOrder}>
        <Droppable droppableId='droppable'>
          {(provided) => (
            <div ref={provided.innerRef}>
              {items.map((item, index) => (
                <Draggable key={item.slug} draggableId={item.slug} index={index}>
                  {(provided) => (
                    <div
                      className={index % 2 === 1 ? 'even' : null}
                      {...{
                        ...provided.draggableProps,
                        ...provided.dragHandleProps,
                        ref: provided.innerRef,
                      }}
                    >
                      {this.renderRow(item)}
                    </div>
                  )}
                </Draggable>
              ))}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    )
  }

  renderTableHeader() {
    return (
      <Row>
        <Cell fixedWidth='auto'>{trans('accounting.document-element-group-name')}</Cell>
        <Cell fixedWidth={120}>{trans('accounting.is_active')}</Cell>
        <Cell fixedWidth={180} alignRight />
      </Row>
    )
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      fetch: fetchExpenseGroups,
      changeExpenseGroupOrder,
    },
    dispatch,
  )
}

const mapStateToProps = (state) => {
  return {
    items: getExpenseGroups(state),
    isLoading: isLoading(state),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ExpenseGroupList)
