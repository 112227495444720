import PropTypes from 'prop-types'
import React from 'react'
import classNames from 'classnames'
import Icon from '../IconComponent'

class AccordionPanel extends React.Component<any, any> {
  constructor(props) {
    super(props)
    this.state = {
      isOpened: this.props.defaultOpened || false,
      noShadow: false,
    }
  }

  setIsOpened(isOpened) {
    this.setState({ isOpened })
  }

  render() {
    const { children, bar, className, expandOnBarClick, barTheme } = this.props
    const classes = classNames(
      {
        'accordion-panel': true,
        'accordion-panel--theme-primary': !barTheme || barTheme === 'primary',
        'accordion-panel--theme-info': barTheme === 'info',
        'accordion-panel--theme-info--second': barTheme === 'secondary',
      },
      className,
    )
    const { isOpened } = this.state
    const iconClasses = classNames('accordion-panel__icon', {
      'accordion-panel__icon--open': !isOpened,
      'accordion-panel__icon--close': isOpened,
    })

    return (
      <div className={classes}>
        <div
          className={classNames({
            'accordion-panel__bar': true,
            'accordion-panel__bar--is-clickable': expandOnBarClick,
          })}
          onClick={() => {
            if (expandOnBarClick) {
              this.setIsOpened(!isOpened)
            }
          }}
        >
          {bar}
          <span
            className={iconClasses}
            onClick={() => {
              this.setIsOpened(!isOpened)
            }}
          >
            <Icon type='arrow' />
          </span>
        </div>
        {isOpened && (
          <div className='accordion-panel__content'>
            {children({ isOpen: this.state.isOpened })}
          </div>
        )}
      </div>
    )
  }
}

AccordionPanel.propTypes = {
  bar: PropTypes.node,
  defaultOpened: PropTypes.bool,
  name: PropTypes.string,
  expandOnBarClick: PropTypes.bool,
  barTheme: PropTypes.string,
  forceUpdate: PropTypes.bool,
}

AccordionPanel.defaultProps = {
  forceUpdate: null,
}

export { AccordionPanel }
export default { AccordionPanel }
