import React, { Component } from 'react'
import APIClient from '../../services/APIClient'
import trans from '../../trans'
import Button from '../ui/ButtonComponent'
import { Loader } from '../ui/LoadingOverlay/Loader'
import { getInstance } from '../../store/app/instance'
import { connect } from 'react-redux'
import { Ability } from '../RequestPageCommon/Ability/Ability'
import { getCurrentUser } from '../../store/app/user-profile/userProfileSlice'

class Advanced extends Component<any, any> {
  constructor(props) {
    super(props)

    this.state = {
      loading: false,
    }
  }

  reset = (event) => {
    const isConfirmed = confirm(trans('instance-settings.reset-confirm-message'))

    if (isConfirmed) {
      this.setState({ loading: true })
      APIClient.resetInstance().then(() => {
        this.setState({ loading: false })
      })
    }
  }

  sendEmail = (event) => {
    const isConfirmed = confirm(trans('instance-settings.send-welcome-email-confirm-message'))

    if (isConfirmed) {
      this.setState({ loading: true })
      APIClient.sendWelcomeEmailToEveryone().then(() => {
        this.setState({ loading: false })
      })
    }
  }

  render() {
    return (
      <div className='substitutions has-loader'>
        {this.state.loading && <Loader />}
        <div className='substitutions__header'>
          <h2 className='tabs__content-heading substitutions__title'>
            {trans('instance-settings.advanced-header')}
          </h2>
        </div>

        <p className='substitutions__copy'>{trans('instance-settings.reset-instance-label')}</p>
        <Button className='substitutions__set-button' primary onClick={this.reset}>
          {trans('instance-settings.reset-instance')}
        </Button>

        <p className='substitutions__copy'>{trans('instance-settings.send-welcome-email-label')}</p>
        <Button className='substitutions__set-button' primary onClick={this.sendEmail}>
          {trans('instance-settings.send-welcome-email')}
        </Button>
      </div>
    )
  }
}

Advanced.propTypes = {}

const mapStateToProps = (state) => ({
  demoMode: getInstance(state).demo_mode,
  user: getCurrentUser(state),
})

Advanced = connect(mapStateToProps)(Advanced)

export default Advanced
export { Advanced }
