import _ from 'lodash'

// initial state
const getInitialState = () => {
  return {
    data: {},
  }
}

// constants
export const RESET_EXPENSE_REQUEST = 'expense-request::reset'
export const SET_EXPENSE_REQUEST = 'expense-request::set'
export const MOUNT_POINT = 'expense-request'

// reducer
export const reducer = (state = getInitialState(), action) => {
  switch (action.type) {
    case RESET_EXPENSE_REQUEST:
      return getInitialState()
    case SET_EXPENSE_REQUEST:
      return {
        ...state,
        data: action.payload,
      }
    default:
      return state
  }
}

// actions
export const reset = () => (dispatch) => {
  dispatch({
    type: RESET_EXPENSE_REQUEST,
  })
}

export const setExpenseRequest = (data) => (dispatch) => {
  dispatch({
    type: SET_EXPENSE_REQUEST,
    payload: data,
  })
}

// selectors
export const getState = (state) => {
  return state.get(MOUNT_POINT)
}

export const getExpenseRequestData = (state) => {
  return getState(state).data
}

export const getExpenseRequestIsLoaded = (state) => {
  return !_.isEmpty(getExpenseRequestData(state))
}
