import { Cell, Row } from '../../../ui/AccordionTable'
import Button from '../../../ui/ButtonComponent'
import trans from '../../../../trans'
import React from 'react'
import Ellipsis from '../../../ui/Ellipsis/Ellipsis'
import TruncateMarkup from 'react-truncate-markup'
import { AmountFormatter } from '../../../AmountFormatter'

export default function VatListItem({
  accountingAccount,
  reverse_accounting_account,
  name,
  code,
  value,
  deductibility,
  is_active,
  edit,
}) {
  return (
    <Row>
      <Cell fixedWidth={150}>{code}</Cell>
      <Cell fixedWidth='auto'>{name}</Cell>
      <Cell fixedWidth={150} alignRight>
        <AmountFormatter amount={value} format={null} />
      </Cell>
      <Cell fixedWidth={150} alignRight>
        <AmountFormatter amount={deductibility} format={null} />
      </Cell>
      <Cell fixedWidth={150}>
        <TruncateMarkup
          lines={1}
          ellipsis={<Ellipsis text={`${reverse_accounting_account ? accountingAccount.account_number + ' / ' + reverse_accounting_account.account_number : accountingAccount.account_number}`} />}
        >
          <span>{reverse_accounting_account ? accountingAccount.account_number + ' / ' + reverse_accounting_account.account_number : accountingAccount.account_number}</span>
        </TruncateMarkup>
      </Cell>
      <Cell fixedWidth={120}>
        {trans(is_active ? 'accounting.is_active_active' : 'accounting.is_active_not_active')}
      </Cell>
      <Cell fixedWidth={180} alignRight>
        <Button primary xs className={'btn--tiny'} onClick={edit}>
          {trans('instance-users.edit')}
        </Button>
      </Cell>
    </Row>
  )
}
