import React from 'react'
import { Field } from 'redux-form/immutable'
import { FormField } from '../ui/Form'
import trans from '../../trans'
import { Link } from 'react-router-dom'
import { LoadingOverlay } from '../ui/LoadingOverlay'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { getInstance } from '../../store/app/instance'

class LostPasswordForm extends React.Component<any, any> {
  constructor(props) {
    super(props)

    this.state = {
      sending: false,
      success: false,
      error: false,
    }
  }

  render() {
    const { handleSubmit } = this.props
    const { sending, success, error } = this.state

    return (
      <div>
        <form
          className='form-group form-group--label-top form-group--vertical lost-password-form'
          onSubmit={(e) => {
            e.preventDefault()
            this.setState({
              sending: true,
            })
            return handleSubmit(e).then((result) => {
              if (result === true) {
                this.setState({
                  sending: false,
                  success: true,
                  error: false,
                })
              } else {
                this.setState({
                  sending: false,
                  success: false,
                  error: true,
                })
              }
            })
          }}
        >
          <label className='form-group__wrapper'>
            <Field
              name='email'
              type='text'
              label={trans('login-page.e-mail')}
              component={FormField}
              labeltop
            />
          </label>
          <input
            type='submit'
            value={trans('login-page.restore-password-button')}
            className='btn--primary btn input--submit'
          />
          {sending && <LoadingOverlay center />}
          {!sending && !error && success && (
            <div className='lost-password-form__success-message-container'>
              <p>{trans('login-page.password-reset-email-sent-direct')}</p>
            </div>
          )}
        </form>
        <Link to='/login'>{trans('login-page.back-to-login-page')}</Link>
      </div>
    )
  }
}

const connected = connect((state) => ({
  instance: getInstance(state),
}))

LostPasswordForm = compose(connected)(LostPasswordForm)

export { LostPasswordForm }
export default { LostPasswordForm }
