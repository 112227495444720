import React, { createRef } from 'react'
import { Field } from 'redux-form/immutable'
import { FormField } from '../../ui/Form/index'
import { TimelineElement, TimeLineElementHeader } from '../../Timeline/index'
import Icon from '../../ui/IconComponent'
import { trans } from '../../../trans'
import { Tooltip } from '../../Tooltip'
import { config } from '../../../config'

class EdgePointForm extends React.Component<any, any> {
  constructor(props) {
    super(props)
    this.state = { isOpen: false }
    this.parentRef = createRef()
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside)
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside)
  }

  handleClickOutside = (event) => {
    const { current } = this.parentRef
    if (this.state.isOpen && current && !current.contains(event.target)) {
      this.setState({ isOpen: false })
    }
  }

  render() {
    const { label, showTarget, index, disabled } = this.props
    const style = {
      zIndex: !this.state.isOpen ? 1 : 2,
      position: 'relative',
    }

    return (
      <div ref={this.parentRef} onMouseDown={() => this.setState({ isOpen: true })} style={style}>
        <TimelineElement activeSimilar>
          <TimeLineElementHeader icon='pin_2' gradient='true'>
            <div className='border-crossings__timieline-item'>
              <div className='border-crossings__timieline-label-container'>
                <span className='border-crossings__timieline-item-label'>{label}</span>

                <Tooltip
                  html={
                    <div>
                      <p style={{ marginBottom: 10 }}>
                        <strong>{trans('deductions-widget.tooltip-strong-1')}</strong>
                        <span>{trans('deductions-widget.tooltip-content-1')}</span>
                      </p>
                      <p>
                        <strong>{trans('deductions-widget.tooltip-strong-2')}</strong>
                        <span>{trans('deductions-widget.tooltip-content-2')}</span>
                      </p>
                      <p>
                        <strong>{trans('deductions-widget.tooltip-strong-3')}</strong>
                        <span>{trans('deductions-widget.tooltip-content-3')}</span>
                      </p>
                    </div>
                  }
                >
                  <Icon type='question_fill' className='border-crossings__timieline-item-icon' />
                </Tooltip>
              </div>

              <div className='border-crossings__timieline-inputs'>
                <div className='border-crossings__timieline-item-input-wrapper'>
                  <Field name='location' type='location' component={FormField} immutable labeltop />
                </div>

                <div className='border-crossings__timieline-item-input-wrapper'>
                  <Field
                    name='date'
                    type='datepicker'
                    component={FormField}
                    labeltop
                    showTimeSelect
                    timeFormat='HH:mm'
                    dateFormat={config.datePickerFieldFormat}
                    timeIntervals={15}
                  />
                </div>

                {showTarget && (
                  <div className='border-crossings__timieline-item-checkbox-wrapper'>
                    <Field
                      name='target'
                      type='checkbox'
                      component={FormField}
                      inputOnly
                      labeltop
                      disabled={disabled}
                    />
                    <label className='border-crossings__timieline-item-checkbox-label'>
                      {trans('deductions-widget.destination-country')}
                    </label>
                  </div>
                )}
              </div>
            </div>
          </TimeLineElementHeader>
        </TimelineElement>
      </div>
    )
  }
}

export { EdgePointForm }
export default EdgePointForm
