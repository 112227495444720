import PropTypes from 'prop-types'
import React, { Fragment } from 'react'
import { EditableLabel, FormField } from '../ui/Form'
import { Field } from 'redux-form/immutable'
import Icon, { EXPENSES_TYPES_TO_ICONS } from '../ui/IconComponent'
import { LoadingOverlay } from '../ui/LoadingOverlay'
import trans from '../../trans'
import _, { groupBy } from 'lodash'
import { AmountFormatter } from '../AmountFormatter'
import { config } from '../../config'
import { Col, Row } from '../../ui/Grid'
import { Loader } from '../ui/LoadingOverlay/Loader'
import { deductibleOptions } from '../../store/app/deductibilities'
import { fetchMpkForAsyncSelect } from '../../store/app/mpks'

class AccountDocumentElementForm extends React.Component<any, any> {
  constructor(props) {
    super(props)
  }

  componentDidMount() {
    const {
      element: { accountDimensionItems = [] },
      setCache,
    } = this.props

    setCache(accountDimensionItems)
  }

  renderVatNumberOptions() {
    const { vatNumbers } = this.props

    return vatNumbers.map((vat) => {
      return {
        label: vat.name,
        value: vat.id,
      }
    })
  }

  renderHeader() {
    const { data, types } = this.props

    const type = types
      .reduce((prev, next) => [...prev, ...next.options], [])
      .find((item) => item.value === data.type_id)

    if (!type) {
      return null
    }

    const icon = type.slug

    return (
      <Fragment>
        <Icon
          type={EXPENSES_TYPES_TO_ICONS[icon] || 'other'}
          className='account-document-page__section-icon'
          lg
          grey
        />
        {trans(type.label)}
      </Fragment>
    )
  }

  removeSection = (event) => {
    event.preventDefault()
    const { element, document, removeDocumentElement, updateAddFormVisible } = this.props

    if (element['id']) {
      removeDocumentElement(document, element)
    } else {
      updateAddFormVisible(false)
    }
  }

  render() {
    const {
      handleSubmit,
      submitting,
      element,
      document,
      accountingAccounts,
      isDeletingInProgress,
      allowRemove,
      instanceCurrency,
      data,
      types,
      projects,
      accountDimensions,
      isDeductibilityEnabled,
    } = this.props

    const canUserEditElementExchangeRate =
      this.props.document.abilities.canUserEditElementExchangeRate
    const canUserEdit = this.props.document.abilities.edit

    return (
      <div>
        <header className='section__header'>
          <h1 className='h2 section__header-title'>{this.renderHeader()}</h1>
          {allowRemove && (
            <Icon type='close' className='icon-close' onClick={this.removeSection} lg />
          )}
        </header>
        <form onSubmit={handleSubmit}>
          <div>
            <div className='field-on-top'>
              <Field
                name='type_id'
                type='grouped-select'
                label={trans('document-element.expense-type')}
                component={FormField}
                options={types}
                clearable={false}
                disabled={!canUserEdit}
                labeltop
              />
            </div>

            <div className='has-loader'>
              {_.isEmpty(element) && <Loader />}

              <Row>
                <Col xs={6}>
                  <Field
                    name='gross'
                    type='formatted_number'
                    label={trans('document-element.gross')}
                    component={FormField}
                    disabled={!canUserEdit}
                    labeltop
                  />
                </Col>
                <Col xs={6}>
                  <div className='form-group form-group--label-top'>
                    <span className='form-group__label'>{trans('document.exchange-rate')}</span>
                    <div className='account-document-page__rate'>
                      <EditableLabel
                        isOpen={
                          !_.isNil(data.pristine_exchange_rate || !canUserEditElementExchangeRate)
                        }
                        defaultValue={() => (
                          <AmountFormatter
                            amount={data.default_exchange_rate}
                            format={config.exchangeRateFormat}
                          />
                        )}
                        field={
                          <Field
                            name='exchange_rate'
                            type='formatted_number'
                            component={FormField}
                            disabled={submitting || !canUserEditElementExchangeRate || !canUserEdit}
                            inputOnly
                            options={{ decimalScale: 8 }}
                            withError
                            normalize={(value) => (value === '' ? null : value)}
                          />
                        }
                      />
                    </div>
                  </div>
                </Col>
              </Row>

              <Field
                name='vat_number_id'
                type='select'
                label={trans('document-element.vat')}
                component={FormField}
                options={this.renderVatNumberOptions()}
                clearable={false}
                disabled={!canUserEdit}
                emptyValue={{
                  label: trans('global.empty-option'),
                  value: null,
                }}
                labeltop
              />

              <Field
                name='net'
                type='formatted_number'
                label={trans('document-element.expense-element-net')}
                component={FormField}
                disabled={!canUserEdit}
                labeltop
              />

              <Field
                name='accounting_account_id'
                type='select'
                label={trans('document-element.accounting-account')}
                component={FormField}
                options={accountingAccounts}
                clearable={true}
                disabled={!canUserEdit}
                labeltop
              />

              <Field
                name='mpk'
                type='async-select'
                label={trans('document-element.mpk')}
                component={FormField}
                loadOptions={fetchMpkForAsyncSelect(document.request.company.id)}
                labeltop
                disabled={!canUserEdit}
                selectedOption={data.mpk}
                placeholder={trans('instance-users.none')}
                noOptionsMessage={() => {
                  return trans('user.no-results-for-search-message')
                }}
                loadingMessage={() => {
                  return trans('user.searching-message')
                }}
                clearable={true}
                returnFullItemValueOnChange
              />

              {projects.length > 0 && (
                <Field
                  name='project_id'
                  type='select'
                  label={trans('document-element.project')}
                  component={FormField}
                  options={projects}
                  clearable={true}
                  disabled={!canUserEdit}
                  emptyValue={{
                    label: trans('trip-request.empty-project-option-label'),
                    value: null,
                  }}
                  labeltop
                />
              )}

              {isDeductibilityEnabled && (
                <Field
                  name='deductibility'
                  type='select'
                  label={trans('document-element.deductibility')}
                  component={FormField}
                  options={deductibleOptions()}
                  clearable={false}
                  disabled={!canUserEdit}
                  labeltop
                />
              )}

              <div className='form-group--inline-checkbox form-group--inline-checkbox-disabled'>
                <Field
                  name='cost_of_earning'
                  type='checkbox'
                  label={trans('document-element.cost-of-earning')}
                  component={FormField}
                  inputOnly={true}
                  labeltop
                  disabled={true}
                />
              </div>

              {accountDimensions.map((dimension) => (
                <Field
                  name={dimension.fieldName}
                  type='account-dimensions'
                  component={FormField}
                  accountDimension={dimension}
                  selectedDimensions={element.accountDimensionItems}
                  placeholder={trans('account-dimensions.placeholder')}
                  label={dimension.label}
                  labeltop
                  asField
                  disabled={!canUserEdit}
                  key={dimension.id}
                />
              ))}

              <Field
                name='description'
                type='text'
                component={FormField}
                label={trans('document-element.description')}
                disabled={submitting || !canUserEdit}
                labeltop
              />
            </div>
          </div>
        </form>
        {isDeletingInProgress && <LoadingOverlay />}
      </div>
    )
  }
}

AccountDocumentElementForm.propTypes = {
  vatNumbers: PropTypes.array.isRequired,
  types: PropTypes.array.isRequired,
  accountingAccounts: PropTypes.array.isRequired,
  mpks: PropTypes.array.isRequired,
  removeDocumentElement: PropTypes.func.isRequired,
  element: PropTypes.object.isRequired,
  isDeletingInProgress: PropTypes.bool.isRequired,
  document: PropTypes.object.isRequired,
  updateAddFormVisible: PropTypes.func.isRequired,
  values: PropTypes.object.isRequired,
  allowRemove: PropTypes.bool,
}

AccountDocumentElementForm.defaultProps = {
  values: {},
}

export { AccountDocumentElementForm }
export default { AccountDocumentElementForm }
