import PropTypes from 'prop-types'
import React from 'react'
import { RequestHeader } from '../../RequestPageCommon/RequestHeader'
import { ExpenseRequestSummary } from '../ExpenseRequestSummary'
import { RequestComments } from '../../RequestPageCommon/RequestComments'
import { RequestBasicInfo } from '../../RequestPageCommon/BasicInfo/RequestBasicInfo'
import { BasicInfoManager } from '../../../containers/BasicInfoManager'
import { LoadingOverlay } from '../../ui/LoadingOverlay'
import trans from '../../../trans'
import { BasicInfoForm } from '../../../containers/BasicInfoForm'
import { AccountDimensionManager } from '../../../containers/AccountDimensionManager'
import BasicRequestInfoRenderer from '../../RequestBasicInfoRenderer/RequestBasicInfoRenderer'

class ExpenseRequestPageRejected extends React.Component<any, any> {
  render() {
    const { request, currentUser, isModuleActive } = this.props

    return (
      <div>
        <RequestHeader request={request} />

        <BasicRequestInfoRenderer />

        <ExpenseRequestSummary request={request} />

        <RequestComments comments={request['comments']} />
      </div>
    )
  }
}

ExpenseRequestPageRejected.propTypes = {
  request: PropTypes.object.isRequired,
  currentUser: PropTypes.object.isRequired,
  isModuleActive: PropTypes.func.isRequired,
}

export { ExpenseRequestPageRejected }
export default { TripRequestPageRejected: ExpenseRequestPageRejected }
