import React from 'react'
import { Col, Row } from '../../ui'
import { AmountFormatter } from '../AmountFormatter'
import trans from '../../trans'

export default function DocumentAccountDimensionsSummary({
  net,
  gross,
  vat,
}: {
  net: number | string
  gross: number | string
  vat: number | string
}) {
  return (
    <div className='vat-summary read-only'>
      <Row>
        <Col xs={4}>
          <div className='form-group form-group--label-top'>
            <span className='form-group__label'>{trans('document.gross')}</span>
            <div className='form-group__input-wrapper read-only'>
              <div className='input input--text'>
                <AmountFormatter amount={gross} />
              </div>
            </div>
          </div>
        </Col>
        <Col xs={4}>
          <div className='form-group form-group--label-top'>
            <span className='form-group__label'>{trans('document.net')}</span>
            <div className='form-group__input-wrapper read-only'>
              <div className='input input--text'>
                <AmountFormatter amount={net} />
              </div>
            </div>
          </div>
        </Col>
        <Col xs={4}>
          <div className='form-group form-group--label-top'>
            <span className='form-group__label'>{trans('document.vat')}</span>
            <div className='form-group__input-wrapper read-only'>
              <div className='input input--text'>
                <AmountFormatter amount={vat} />
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  )
}
