import React, { PropsWithChildren } from 'react'

interface ToggleableInputWrapperProps {
  label: string
}

function ToggleableInputWrapper({
  label,
  children,
}: PropsWithChildren<ToggleableInputWrapperProps>) {
  return (
    <div className='filters__input-wrapper'>
      <span className='filters__input-title'>{label}</span>

      {children}
    </div>
  )
}

export default ToggleableInputWrapper
