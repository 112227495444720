import React from 'react'

import TabTitle from './TabTitle'
import TabContent from './TabContent'

class Tabs extends React.Component<any, any> {
  constructor(props) {
    super(props)

    const { items } = this.props

    this.state = {
      activeItem: !_.isEmpty(items) ? items[0] : null,
    }
  }

  onTitleClick(item) {
    this.setState({ activeItem: item })
  }

  render() {
    const { items } = this.props
    const { activeItem } = this.state

    const titles = (
      <ul className='tabs__titles'>
        {items.map((item) => (
          <TabTitle
            key={`title-${item.id}`}
            title={item.title}
            handleClick={() => {
              this.onTitleClick(item)
            }}
            active={activeItem.id === item.id}
          />
        ))}
      </ul>
    )

    const content = (
      <div className='tabs__content has-loader'>
        <TabContent content={activeItem.content} />
      </div>
    )

    return (
      <div className='tabs'>
        {titles}
        {content}
      </div>
    )
  }
}

export default Tabs
