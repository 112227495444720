import React from 'react'
import PropTypes from 'prop-types'

const Expense = (props) => {
  const { form, date } = props
  return (
    <div className='declarations__step declarations__grid-row'>
      <div className='declarations__step-date-wrapper declaratas__grid-column'>
        <span className='declarations__step-date'>{date}</span>
      </div>
      {form()}
    </div>
  )
}

Expense.propTypes = {
  date: PropTypes.string.isRequired,
  form: PropTypes.func.isRequired,
}

export default Expense
export { Expense }
