import React, { Component, Fragment } from 'react'
import moment from 'moment'
import { get } from 'lodash'

const OfferDate = (props) => {
  const { offer } = props
  const date = moment(get(offer, 'attributes.departureDate', null))

  return date.format(' - ddd. D MMM.')
}

export default OfferDate
export { OfferDate }
