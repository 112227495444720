import React, { Component, PureComponent } from 'react'
import { Dropdown } from './Dropdown'
import { compose } from 'redux'
import { connect } from 'react-redux'
import {
  fetchNotifications,
  getNotifications,
  getNotificationsUnreadCount,
  readNotifications,
} from '../../store/app/notifications'
import { bindActionCreators } from 'redux'
import _ from 'lodash'
import { withRouter } from 'react-router-dom'

class NotificationDropdown extends PureComponent<any, any> {
  constructor(props) {
    super(props)

    this.dropdownRef = React.createRef()
    this.state = {
      open: false,
    }
  }

  componentDidMount() {
    document.addEventListener('click', this.handleOutsideClick, true)
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.handleOutsideClick, true)
  }

  handleOutsideClick = (e) => {
    if ((this.state.open && !this.dropdownRef) || !this.dropdownRef.current.contains(e.target)) {
      this.setState({
        open: false,
      })
    }
  }

  handleDropdownIconClick = () => {
    const { notifications } = this.props
    if (!_.isEmpty(notifications)) {
      this.setState({ open: !this.state.open })
    }
  }

  handleNotificationClick = (notification_id) => {
    return new Promise((resolve) => {
      const { readNotifications } = this.props
      this.setState({ open: !this.state.open })
      readNotifications([notification_id]).then(() => resolve())
    })
  }

  loadMore = () => {
    return this.props.fetchNotifications()
  }

  render() {
    const {
      notifications,
      unreadCount,
      history: { push, location },
    } = this.props
    const { open } = this.state

    return (
      <Dropdown
        notifications={notifications}
        passRef={this.dropdownRef}
        unreadCounter={unreadCount}
        open={open}
        handleDropdownIconClick={this.handleDropdownIconClick}
        handleNotificationClick={this.handleNotificationClick}
        handleLoadMore={this.loadMore}
        historyPush={push}
        location={location}
      />
    )
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ readNotifications, fetchNotifications }, dispatch)
}

const mapStateToProps = (state) => ({
  notifications: getNotifications(state),
  unreadCount: getNotificationsUnreadCount(state),
})

const withConnectedRouter = withRouter(NotificationDropdown)
const withConnect = connect(mapStateToProps, mapDispatchToProps)

export default compose(withConnect)(withConnectedRouter)
