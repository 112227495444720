import PropTypes from 'prop-types'
import React from 'react'
import { STATUS_SETTLEMENT, STATUS_TRIP, STATUS_UPCOMING_TRIP } from '../../constants/request'
import { UserAnnotationForm } from '../../containers/UserAnnotationForm'
import { Ability } from '../RequestPageCommon/Ability/Ability'
import { TripRequestPath } from './TripRequestPath'
import { TripRequestTitleRight } from './TripRequestTitleRight'

class TripRequestRightPanel extends React.Component<any, any> {
  render() {
    const { request, fetchRequest } = this.props

    const isReservation = () => request['status'] === STATUS_UPCOMING_TRIP
    const isTrip = () => request['status'] === STATUS_TRIP

    return (
      <div>
        <TripRequestTitleRight request={request} />

        {(isReservation() || isTrip()) && (
          <Ability ability={['writeAnnotation']}>
            <UserAnnotationForm request={request} />
          </Ability>
        )}

        <TripRequestPath request={request} fetchRequest={fetchRequest} />
      </div>
    )
  }
}

TripRequestRightPanel.propTypes = {
  request: PropTypes.object.isRequired,
  fetchRequest: PropTypes.func.isRequired,
  disableUploadTravelDocuments: PropTypes.bool,
}

export { TripRequestRightPanel }
export default { TripRequestRightPanel }
