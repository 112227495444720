import React, { Component, Fragment } from 'react'
import { AmountFormatter } from '../AmountFormatter'
import Avatar from '../Avatar/Avatar'
import trans from '../../trans'
import PropTypes from 'prop-types'

const TYPE_EXPENSE_RANGING = 'expenseRanking'
const TYPE_TRIP_RANKING = 'tripRanking'

class CostsRanking extends Component<any, any> {
  constructor(props) {
    super(props)
  }

  render() {
    return (
      <Fragment>
        {this.props.items.length > 0
          ? this.props.items.map((item) => {
              return (
                <div className='costs-ranking-item' key={item.user.id}>
                  <Avatar user={item.user} className='costs-ranking-item--image' asImage={false} />

                  <div className='costs-ranking-item--person'>
                    <div className='costs-ranking-item--person__name'>{item.user.name}</div>
                    <div className='costs-ranking-item--person__position'>{item.user.mpk}</div>
                  </div>
                  <div className='costs-ranking-item--count'>
                    <div className='costs-ranking-item--count__quantity'>
                      {item.quantity} {this.getTranslationForQuantityUnit(item.quantity)}
                    </div>
                    <div className='costs-ranking-item--count__amount'>
                      <AmountFormatter amount={item.value} /> {item.unit}
                    </div>
                  </div>
                </div>
              )
            })
          : ''}
      </Fragment>
    )
  }

  getTranslationForQuantityUnit(quantity) {
    if (this.props.type === TYPE_EXPENSE_RANGING) {
      switch (
        quantity.toString().length > 1
          ? quantity.toString().slice(quantity.toString().length - 1, quantity.toString().length)
          : quantity.toString()
      ) {
        case '1':
          return trans('expense-request.one-expense')
        case '2':
        case '3':
        case '4':
          return trans('expense-request.two-to-four-expenses')
        default:
          return trans('expense-request.multiple-expenses')
      }
    } else if (this.props.type === TYPE_TRIP_RANKING) {
      switch (
        quantity.toString().length > 1
          ? quantity.toString().slice(quantity.toString().length - 1, quantity.toString().length)
          : quantity.toString()
      ) {
        case '1':
          return trans('expense-request.one-trip')
        case '2':
        case '3':
        case '4':
          return trans('expense-request.two-to-four-trips')
        default:
          return trans('expense-request.multiple-trips')
      }
    }
  }
}

CostsRanking.propTypes = {
  type: PropTypes.string.isRequired,
}

export { CostsRanking }
export default CostsRanking
