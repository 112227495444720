import PropTypes from 'prop-types'
import React from 'react'
import { withRouter } from 'react-router-dom'
import { getRouteByName } from '../../routes'

export const parseQuery = (queryString) => {
  const query = {}
  const pairs = (queryString[0] === '?' ? queryString.substr(1) : queryString).split('&')
  for (let i = 0; i < pairs.length; i++) {
    const pair = pairs[i].split('=')
    query[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1] || '')
  }
  return query
}

export class RouteManagerBase extends React.Component<any, any> {
  render() {
    const { children, history, location, match } = this.props

    const renderProps = {
      push: (url) => {
        history.push(url)
      },
      goBack: () => {
        history.goBack()
      },
      replace: (url) => {
        history.replace(url)
      },
      getRouteByName,
      location,
      match,
    }

    return children(renderProps)
  }
}

RouteManagerBase.propTypes = {
  children: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
}

export const RouteManager = withRouter(RouteManagerBase)
