import React from 'react'
import { EXPENSES_TYPES_TO_ICONS } from '../../ui/IconComponent'
import { ExpenseTypeBox } from '../../ExpenseTypeBox'
import Icon from '../../ui/IconComponent'
import { DocumentExpenseTypeForm } from '../../../containers/DocumentExpenseTypeForm'
import { AmountFormatter } from '../../AmountFormatter'

class ExpenseTypesExpense extends React.Component<any, any> {
  constructor(props) {
    super(props)
  }

  _getRemainingSum() {
    const { sumOfExpenseTypes, document } = this.props
    return Number(document.gross) - sumOfExpenseTypes
  }

  renderContent() {
    const { expense, onEdit, suggestedExpense } = this.props
    return (
      <DocumentExpenseTypeForm
        expense={expense}
        formName={`expense-type-${expense['id']}`}
        handleExpenseSave={onEdit}
        remainingSum={this._getRemainingSum()}
        suggestedExpense={suggestedExpense}
        onClick={(e) => e.stopPropagation()}
      />
    )
  }

  renderReadOnly() {
    const { expense } = this.props
    return <AmountFormatter amount={expense.element.gross} />
  }

  render() {
    const { expense, readOnly } = this.props

    if (!EXPENSES_TYPES_TO_ICONS[expense['slug']]) {
      console.log(`Expense Type icon not found for ${expense['slug']}`)
    }

    return (
      <ExpenseTypeBox
        icon={<Icon type={EXPENSES_TYPES_TO_ICONS[expense['slug']]} />}
        title={expense['short_name']}
        key={`type-${expense['id']}`}
        isActive={true}
        itemWidth={'25%'}
      >
        {readOnly || this.props.document['type'] === 'confirmation'
          ? this.renderReadOnly()
          : this.renderContent()}
      </ExpenseTypeBox>
    )
  }
}

export default ExpenseTypesExpense
