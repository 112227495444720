import PropTypes from 'prop-types'
import React from 'react'
import { Cell, Row, Table, TBody, THead } from '../../../components/ui/AccordionTable'
import trans from '../../../trans'
import moment from 'moment'
import { config } from '../../../config'
import Button from '../../ui/ButtonComponent'
import { history } from '../../../store'
import UserName from '../../UserName/UserName'
import Ellipsis from '../../ui/Ellipsis/Ellipsis'
import TruncateMarkup from 'react-truncate-markup'
import SortableCellWrapper from '../../Sortable/SortableCellWrapper'
import { CheckboxField } from '../../ui/Form'
import { Col, Radio } from '../../../ui'
import { ExpenseRequestActions } from '../../ExpenseRequestAccountingPage/ExpenseRequestActions'
import { Ability } from '../../RequestPageCommon/Ability/Ability'
import { noop } from 'lodash/util'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import Dialog from '@material-ui/core/Dialog'
import AddProviderForm from '../../DocumentForm/DocumentAddProviderForm'
import Icon from '../../ui/IconComponent'
import RequestCardForm from './Modal/RequestCardForm'

enum Action {
  REQUEST_CARD = 'REQUEST_CARD',
}

export class List extends React.Component<any, any> {
  state = {
    action: null,
    isIssueCardModalOpen: false,
    actionData: {
      limit: null,
      validity_period: null,
    },
  }

  renderStatus = (user) => {
    if (!user.blocked_at) {
      return trans('instance-users.status-active')
    }

    const date = moment(user.blocked_at)
    return trans('instance-users.status-deactivate', { date: date.format(config.apiDateFormat) })
  }

  renderCardInfo = (user) => {
    if (!user.my_card) {
      return '-'
    }

    if (!user.my_card.status) {
      return user.my_card.cards.join(', ')
    }

    return user.my_card.status
  }

  setAction(action: Action) {
    this.setState({
      action,
      actionData: {
        limit: null,
        validity_period: null,
      },
    })

    if (action === Action.REQUEST_CARD) {
      this.setState({
        isIssueCardModalOpen: true,
        actionData: {
          limit: null,
          validity_period: null,
        },
      })
    }
  }

  closeIssueCardModal = () => {
    this.setState({
      action: null,
      isIssueCardModalOpen: false,
      actionData: {
        limit: null,
        validity_period: null,
      },
    })
  }

  applyAction() {
    const { action, actionData } = this.state
    const { requestCardForUsers } = this.props

    if (action === Action.REQUEST_CARD) {
      return requestCardForUsers(actionData).finally(() => {
        this.setAction(null)
      })
    }
  }

  canApplyAction() {
    const { action } = this.state
    const { actionData } = this.state

    if (action === Action.REQUEST_CARD) {
      return actionData.limit && actionData.validity_period
    }

    return action
  }

  isSelectedAll = (users) => {
    const { isItemSelected } = this.props

    if (users.length === 0) {
      return false
    }

    return users.filter((user) => isItemSelected(user)).length === users.length
  }

  render() {
    const {
      users,
      isLoading,
      getRouteByName,
      pagination,
      changePage,
      sorter,
      onChangeSorter,
      selectedItems,
      selectDeselectOne,
      selectDeselectMany,
      isItemSelected,
      currentUser,
    } = this.props

    return (
      <>
        <Table
          className={'table-stylesheet-theme'}
          striped
          pagination={{
            ...pagination,
            changePage,
            align: 'center',
          }}
        >
          <THead>
            <Row>
              <Cell fixedWidth={50} cellClassName='has-checkbox'>
                <CheckboxField
                  onChange={(value) => selectDeselectMany(users, value)}
                  name='select-deleselect-all'
                  checked={this.isSelectedAll(users)}
                />
              </Cell>

              <SortableCellWrapper
                sorter={sorter}
                onChangeSorter={onChangeSorter}
                name={'full_name'}
              >
                {({ icon }) => (
                  <Cell fixedWidth={200} className={'table-accordion__col__sortable'}>
                    {trans('instance-users.name')}&nbsp;{icon}
                  </Cell>
                )}
              </SortableCellWrapper>
              <SortableCellWrapper
                sorter={sorter}
                onChangeSorter={onChangeSorter}
                name={'email'}
                style={{ width: '100%' }}
              >
                {({ icon }) => (
                  <Cell fixedWidth='auto' className={'table-accordion__col__sortable'}>
                    {trans('instance-users.email')}&nbsp;{icon}
                  </Cell>
                )}
              </SortableCellWrapper>
              <SortableCellWrapper
                sorter={sorter}
                onChangeSorter={onChangeSorter}
                name={'supervisor_full_name'}
              >
                {({ icon }) => (
                  <Cell fixedWidth={200} className={'table-accordion__col__sortable'}>
                    {trans('instance-users.supervisor')}&nbsp;{icon}
                  </Cell>
                )}
              </SortableCellWrapper>

              <SortableCellWrapper sorter={sorter} onChangeSorter={noop}>
                {({ icon }) => (
                  <Cell fixedWidth={200} className={'table-accordion__col__sortable'}>
                    {trans('instance-users.my-card')}&nbsp;{icon}
                  </Cell>
                )}
              </SortableCellWrapper>

              <SortableCellWrapper
                sorter={sorter}
                onChangeSorter={onChangeSorter}
                name={'blocked_at'}
              >
                {({ icon }) => (
                  <Cell fixedWidth={120} className={'table-accordion__col__sortable'}>
                    {trans('instance-users.status')}&nbsp;{icon}
                  </Cell>
                )}
              </SortableCellWrapper>
              <SortableCellWrapper
                sorter={sorter}
                onChangeSorter={onChangeSorter}
                name={'company_code'}
              >
                {({ icon }) => (
                  <Cell fixedWidth={110} className={'table-accordion__col__sortable'}>
                    {trans('global.company_code')}&nbsp;{icon}
                  </Cell>
                )}
              </SortableCellWrapper>
              <div>
                <Cell fixedWidth={110} alignRight />
              </div>
            </Row>
          </THead>
          <TBody isLoading={isLoading}>
            {users.map((user, i) => {
              return (
                <Row
                  className='request-list__table'
                  key={i}
                  href={getRouteByName('main', 'external-user', { slug: user.slug })}
                >
                  <Cell fixedWidth={50} cellClassName='has-checkbox'>
                    <CheckboxField
                      name={`item-${user['slug']}`}
                      onChange={() => selectDeselectOne(user)}
                      checked={isItemSelected(user)}
                    />
                  </Cell>
                  <Cell fixedWidth={200}>
                    <UserName firstName={user.first_name} lastName={user.last_name} />
                  </Cell>
                  <Cell fixedWidth='auto'>
                    <div className='long-text-wrap is-text-lowercase'>
                      <TruncateMarkup lines={2} ellipsis={<Ellipsis text={user.email} />}>
                        <span>{user.email}</span>
                      </TruncateMarkup>
                    </div>
                  </Cell>
                  <Cell fixedWidth={200}>
                    {user.supervisor && (
                      <UserName
                        firstName={user.supervisor.first_name}
                        lastName={user.supervisor.last_name}
                      />
                    )}
                    {!user.supervisor && '-'}
                  </Cell>
                  <Cell fixedWidth={200}>
                    <TruncateMarkup
                      lines={2}
                      ellipsis={<Ellipsis text={<span>{this.renderCardInfo(user)}</span>} />}
                    >
                      <span>{this.renderCardInfo(user)}</span>
                    </TruncateMarkup>
                  </Cell>
                  <Cell fixedWidth={120}>
                    <TruncateMarkup
                      lines={2}
                      ellipsis={<Ellipsis text={<span>{this.renderStatus(user)}</span>} />}
                    >
                      <span>{this.renderStatus(user)}</span>
                    </TruncateMarkup>
                  </Cell>
                  <Cell fixedWidth={110} style={{ alignSelf: 'center' }}>
                    {user.company.code}
                  </Cell>
                  <Cell fixedWidth={110} alignRight>
                    <Button
                      className='charge-card__operation-button charge-card__operation-button--delete'
                      primary
                      xs
                      pull_end
                      onClick={() => history.push(`/settings/user/${user.slug}`)}
                    >
                      {trans('instance-users.edit')}
                    </Button>
                  </Cell>
                </Row>
              )
            })}
          </TBody>

          <Ability abilities={currentUser.abilities} ability={['mycardCardIssuing']}>
            {selectedItems.length > 0 && (
              <Row className='notification-bar'>
                <Col sm={12} is_pull_end>
                  <div className='notification-bar__row'>
                    <ExpenseRequestActions hideNotApplicable>
                      <div className='react-select__option'>
                        <Radio
                          name='action'
                          disabled={!selectedItems.length}
                          label={trans('instance-users.card-issue') + ` (${selectedItems.length})`}
                          onClick={() => this.setAction(Action.REQUEST_CARD)}
                          checked={this.state.action === Action.REQUEST_CARD}
                        />
                      </div>
                    </ExpenseRequestActions>

                    <Button
                      primary
                      xs
                      disabled={!this.canApplyAction()}
                      onClick={() => this.applyAction()}
                    >
                      {trans('instance-users.complete')}
                    </Button>
                  </div>
                </Col>
              </Row>
            )}
          </Ability>
        </Table>

        <Dialog
          open={this.state.isIssueCardModalOpen}
          keepMounted
          onClose={this.closeIssueCardModal}
          aria-labelledby='alert-dialog-slide-title'
          aria-describedby='alert-dialog-slide-description'
          className='last-requests__dialog'
          disablePortal={true}
          PaperProps={{
            square: true,
            className: 'add-provider-dialog__paper',
          }}
        >
          <DialogTitle id='alert-dialog-slide-title'>
            {trans('instance-users.card-details')}
          </DialogTitle>

          <DialogContent className='last-requests__dialog-content'>
            {this.state.isIssueCardModalOpen && (
              <RequestCardForm
                save={(data) => {
                  this.setState({
                    isIssueCardModalOpen: false,
                    actionData: data,
                  })
                }}
              />
            )}
          </DialogContent>

          <DialogActions className='no-margin'>
            <Icon
              className='last-requests__dialog-close'
              type='close'
              onClick={this.closeIssueCardModal}
            />
          </DialogActions>
        </Dialog>
      </>
    )
  }
}

List.propTypes = {
  users: PropTypes.array.isRequired,
  isLoading: PropTypes.bool.isRequired,
  getRouteByName: PropTypes.func.isRequired,
  changePage: PropTypes.func.isRequired,
  selectedItems: PropTypes.func.isRequired,
  selectDeselectOne: PropTypes.func.isRequired,
  selectDeselectAll: PropTypes.func.isRequired,
  isItemSelected: PropTypes.func.isRequired,
  selectDeselectMany: PropTypes.func.isRequired,
}
