import moment from 'moment'
import { config } from '../config'
import { get } from 'lodash'
import { stringify } from 'query-string'
import { FlightsRequest } from './FlightsRequest'

class FlightsSearchRequest extends FlightsRequest {
  constructor(
    arrival_at,
    return_at = null,
    flight_from,
    flight_to,
    round_trip,
    direct_only,
    flight_class,
    target_departure_time,
    return_departure_time,
    sort,
    restrict_to_fba,
    request_travelers,
  ) {
    super()

    this.arrival_at = moment(arrival_at).format(config.apiDateTimeFormat)
    this.return_at = return_at
    this.round_trip = round_trip

    if (this.return_at) {
      this.return_at = moment(this.return_at).format(config.apiDateTimeFormat)
    }

    if (!round_trip) {
      this.return_at = null
    }

    this.flight_from = get(flight_from, 'additional_data.code', null)
    this.flight_to = get(flight_to, 'additional_data.code', null)

    this.target_departure_time = target_departure_time
    this.return_departure_time = return_departure_time
    this.sort = sort
    this.direct_only = direct_only
    this.flight_class = flight_class
    this.restrict_to_fba = restrict_to_fba
    this.request_travelers = request_travelers
  }

  toString() {
    return stringify({
      arrival_at: this.arrival_at,
      ...(this.return_at ? { return_at: this.return_at } : {}),
      flight_from: this.flight_from,
      flight_to: this.flight_to,
      'target_departure_time[from]': FlightsSearchRequest.minutesToHour(
        this.target_departure_time.min,
      ),
      'target_departure_time[to]': FlightsSearchRequest.minutesToHour(
        this.target_departure_time.max,
      ),
      'return_departure_time[from]': FlightsSearchRequest.minutesToHour(
        this.return_departure_time.min,
      ),
      'return_departure_time[to]': FlightsSearchRequest.minutesToHour(
        this.return_departure_time.max,
      ),
      sort: this.sort,
      round_trip: FlightsSearchRequest.stringBoolToNumber(this.round_trip),
      direct_only: FlightsSearchRequest.stringBoolToNumber(this.direct_only),
      restrict_to_fba: FlightsSearchRequest.stringBoolToNumber(this.restrict_to_fba),
      flight_class: this.flight_class,
      'request_travelers[]': this.request_travelers,
    })
  }

  toArray() {
    {
    }
  }
}

export default FlightsSearchRequest
export { FlightsSearchRequest }
