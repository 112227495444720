import PropTypes from 'prop-types'
import React from 'react'
import { LoadingOverlay } from '../ui/LoadingOverlay'
import trans from '../../trans'

function DashboardRequestsContent({ items, isLoading, children }) {
  if (isLoading) {
    return (
      <div>
        <LoadingOverlay />
      </div>
    )
  }

  if (items.length === 0) {
    return (
      <div className='text-center' style={{ lineHeight: '250px' }}>
        <span>{trans('dashboard-page.requests-not-found')}</span>
      </div>
    )
  }

  return <div>{children}</div>
}

DashboardRequestsContent.propTypes = {
  children: PropTypes.node.isRequired,
  items: PropTypes.array.isRequired,
  isLoading: PropTypes.bool.isRequired,
}

export { DashboardRequestsContent }
export default { DashboardRequestsContent }
