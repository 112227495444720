import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import Icon from '../../ui/IconComponent'
import { Field } from 'redux-form/lib/immutable'

class SeatCoachNumberField extends Component<any, any> {
  constructor(props) {
    super(props)
  }

  render() {
    const {
      input,
      meta: { error },
      disabled,
    } = this.props

    if (disabled && !input.value) {
      return null
    }

    return (
      <div className='train-trip__selected-ticket-place-reservation-field'>
        <div className='train-trip__selected-ticket-place-reservation-title'>
          # <Icon type='train' />
        </div>

        <div className='train-trip__selected-ticket-place-reservation-wagon-field'>
          {disabled ? (
            <span>{input.value}</span>
          ) : (
            <input className='input' {...input} maxLength='4' />
          )}
        </div>
      </div>
    )
  }
}

SeatCoachNumberField.propTypes = {}

export default SeatCoachNumberField
export { SeatCoachNumberField }
