import React, { ReactElement } from 'react'
import { components } from 'react-select'
import RenderProvider from './RenderProvider'
import { SingleValueProps } from 'react-select/dist/declarations/src/components/SingleValue'

export interface ProviderSelectSingleValueProps extends SingleValueProps {
  children: ReactElement
  data: {
    data: {
      name: string
      erp_id: string
      registry_number: string
      address: string
      city: string
      postcode: string
    }
  }
}

function ProviderSelectSingleValue({
  children,
  ...props
}: ProviderSelectSingleValueProps): ReactElement {
  const {
    data: { data },
  } = props
  const content = data ? (
    <RenderProvider
      name={data.name}
      erpId={data.erp_id}
      registryNumber={data.registry_number}
      address={data.address}
      city={data.city}
    />
  ) : (
    children
  )

  return <components.SingleValue {...props}>{content}</components.SingleValue>
}

export default ProviderSelectSingleValue
