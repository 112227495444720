import { Col, Radio } from '../../ui'
import { ExpenseRequestActions } from '../ExpenseRequestAccountingPage/ExpenseRequestActions'
import trans from '../../trans'
import Button from '../ui/ButtonComponent'
import { Row } from '../ui/AccordionTable'
import React, { useState } from 'react'
import { LEGACY_REPORT_ID, useReports } from './useReports'

export default function ActionBar() {
  const [selectedReportId, setSelectedReportId] = useState<number>(0)
  const { reports, generateReport } = useReports()

  return (
    <Row className='notification-bar'>
      <Col sm={12} is_pull_end>
        <div className='notification-bar__row'>
          <ExpenseRequestActions hideNotApplicable>
            <div className='notification-bar__title'>
              <strong>{trans('reports.export-title')}</strong>
            </div>

            <div className='react-select__option'>
              <Radio
                name='status'
                label={trans('reports.export-default')}
                checked={selectedReportId === LEGACY_REPORT_ID}
                onClick={() => {
                  setSelectedReportId(LEGACY_REPORT_ID)
                }}
              />
            </div>

            {reports.map((report) => (
              <div className='react-select__option'>
                <Radio
                  name='status'
                  label={report.name}
                  checked={selectedReportId === report.id}
                  onClick={() => {
                    setSelectedReportId(report.id)
                  }}
                />
              </div>
            ))}
          </ExpenseRequestActions>

          <Button
            primary
            xs
            disabled={!selectedReportId}
            onClick={() => generateReport(selectedReportId)}
          >
            {trans('global.perform')}
          </Button>
        </div>
      </Col>
    </Row>
  )
}
