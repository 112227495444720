import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators, compose } from 'redux'
import {
  reset,
  fetchDocument,
  getDocumentData,
  getProvider,
  getProvidersData,
  fetchProviders,
  fetchCurrencies,
  getCurrenciesData,
  fetchDocumentElementTypes,
  getDocumentElementsTypesData,
  updateAddFormVisible,
  isAddFormVisible,
  fetchExchangeRates,
  getExchangeRateData,
  setDocumentElementTypes,
  setDocumentElements,
} from '../../store/app/document-account-page'
import { fetchTransactionSuggestions } from '../../store/app/transaction-suggestion'
import _ from 'lodash'
import { getCurrency as getInstanceCurrency } from '../../store/app/instance'
import store from '../../store'
import socketProvider from '../SocketProvider'
import {
  fetchAccountingAccounts,
  isLoading as accountingAccountsIsLoading,
} from '../../store/app/accounting-account'
import { fetchMpk, isLoaded as isMpkLoaded } from '../../store/app/mpks'
import { fetchVatNumbers, isLoaded as isVatLoaded } from '../../store/app/vat'
import { fetchProjects, isLoaded as isProjectLoaded } from '../../store/app/projects'
import { fetchTypes } from '../../store/app/request-other-costs'
import { Loader } from '../../components/ui/LoadingOverlay/Loader'

class AccountDocumentManagerBase extends React.Component<any, any> {
  constructor(props) {
    super(props)

    this.state = { loaded: false }
  }

  componentDidMount() {
    const {
      reset,
      documentId,
      fetchDocument,
      fetchProviders,
      fetchCurrencies,
      fetchVatNumbers,
      fetchDocumentElementTypes,
      fetchMpk,
      fetchProjects,
      fetchAccountingAccounts,
      fetchExchangeRates,
      fetchTypes,
      fetchTransactionSuggestions,
      socket,
    } = this.props

    reset()

    Promise.all([
      fetchDocument(documentId),
      fetchCurrencies(),
      fetchProviders(),
      fetchVatNumbers(),
      fetchDocumentElementTypes(documentId),
      fetchMpk(),
      fetchProjects(),
      fetchAccountingAccounts(),
    ]).then(([document, currencies]) => {
      if (_.isEmpty(document['exchange_rate'])) {
        const _currencies = currencies.map((currency) => currency['code']).join(',')
        fetchExchangeRates(document.request.slug, _currencies)
      }

      Promise.all([
        fetchTypes(document.request),
        fetchTransactionSuggestions(document._links.transaction_suggestions),
      ]).then(() => {
        this.setState({ loaded: true })
      })

      socket.subscribe(`App.Document.${documentId}`)('.App\\Events\\DocumentExchangeRateChanged')(
        (response) => {
          store.dispatch(setDocumentElements({ data: response.elements }))
        },
      )
    })
  }

  componentWillUnmount() {
    const { socket, documentId, reset } = this.props
    socket.unsubscribe(`App.Document.${documentId}`)('.App\\Events\\DocumentExchangeRateChanged')

    reset()
  }

  getExchangeRate() {
    const renderProps = this.getRenderProps()

    const { document, exchangeRates, instanceCurrency } = renderProps
    const { exchange_rate, currency } = document

    if (_.isEmpty(currency)) {
      return null
    } else {
      if (currency['code'] === instanceCurrency) {
        return 1
      }
    }

    if (exchange_rate) {
      return parseFloat(exchange_rate)
    }

    if (!_.isEmpty(currency) && !_.isEmpty(exchangeRates)) {
      const exchangeRate = exchangeRates.find(
        (exchangeRate) => exchangeRate['currency']['id'] === currency['id'],
      )
      if (exchangeRate) {
        return parseFloat(exchangeRate['rate'])
      }
    }

    return null
  }

  isLoading() {
    const { loaded } = this.state

    return !loaded
  }

  getRenderProps() {
    const {
      documentData,
      provider,
      providersData,
      currenciesData,
      documentElementTypesData,
      updateAddFormVisible,
      isAddFormVisible,
      exchangeRateData,
      instanceCurrency,
    } = this.props

    const isLoading = this.isLoading()

    return {
      document: documentData.data,
      providers: providersData.data,
      currencies: currenciesData.data,
      documentElementTypes: documentElementTypesData.data,
      isLoading,
      documentProvider: provider,
      isAddFormVisible,
      updateAddFormVisible,
      isDocumentLoaded: documentData.isLoaded,
      instanceCurrency,
      exchangeRates: exchangeRateData.data,
      getExchangeRate: this.getExchangeRate.bind(this),
    }
  }

  render() {
    const { children } = this.props
    const isLoading = this.isLoading()
    const renderProps = this.getRenderProps()

    if (isLoading) {
      return <Loader />
    }

    return children(renderProps)
  }
}

AccountDocumentManagerBase.propTypes = {
  documentId: PropTypes.string.isRequired,
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      reset,
      fetchDocument,
      fetchProviders,
      fetchCurrencies,
      fetchVatNumbers,
      fetchDocumentElementTypes,
      fetchMpk,
      fetchProjects,
      fetchTypes,
      fetchAccountingAccounts,
      updateAddFormVisible,
      fetchExchangeRates,
      setDocumentElementTypes,
      fetchTransactionSuggestions,
    },
    dispatch,
  )
}

const mapStateToProps = (state) => ({
  documentData: getDocumentData(state),
  providersData: getProvidersData(state),
  currenciesData: getCurrenciesData(state),
  documentElementTypesData: getDocumentElementsTypesData(state),
  accountingAccountsIsLoading: accountingAccountsIsLoading(state),
  provider: getProvider(state),
  isMpkLoaded: isMpkLoaded(state),
  isProjectLoaded: isProjectLoaded(state),
  isVatLoaded: isVatLoaded(state),
  isAddFormVisible: isAddFormVisible(state),
  exchangeRateData: getExchangeRateData(state),
  instanceCurrency: getInstanceCurrency(state),
})

const withConnect = connect(mapStateToProps, mapDispatchToProps)
const withSocket = socketProvider()

const AccountDocumentManager = compose(withSocket, withConnect)(AccountDocumentManagerBase)

export { AccountDocumentManager }
export default { AccountDocumentManager }
