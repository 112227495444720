import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators, compose } from 'redux'
import { get, isUndefined } from 'lodash'
import { socketProvider } from '../../../containers/SocketProvider'

export const MODULE_MOUNT_POINT = 'compliance'

const RESET = MODULE_MOUNT_POINT + '::reset'
const SET = MODULE_MOUNT_POINT + '::set'
const DESTROY = MODULE_MOUNT_POINT + '::destroy'

// actions Creators
const reset = (complianceName) => (dispatch) => {
  dispatch({
    type: RESET,
    payload: {
      complianceName,
    },
  })
}

const destroy = (complianceName) => (dispatch) => {
  dispatch({
    type: DESTROY,
    payload: {
      complianceName,
    },
  })
}

const set = (complianceName, rules) => (dispatch) => {
  dispatch({
    type: SET,
    payload: {
      complianceName,
      rules,
    },
  })
}

const getInitialState = () => {
  return {}
}

//actions
const resetAction = (state, payload) => {
  return {
    ...state,
    [payload.complianceName]: {
      rules: [],
    },
  }
}

const destroyAction = (state, payload) => {
  let newState = state
  delete newState[payload.complianceName]
  return newState
}

const setAction = (state, payload) => {
  return {
    ...state,
    [payload.complianceName]: {
      rules: payload.rules,
    },
  }
}

const actions = {
  [RESET]: resetAction,
  [SET]: setAction,
  [DESTROY]: destroyAction,
}

//selectors
const getRules = (complianceName) => (state) => {
  return get(state.get(MODULE_MOUNT_POINT), `${complianceName}.rules`, [])
}

export const reducer = (state = getInitialState(), action) => {
  if (actions.hasOwnProperty(action.type)) {
    return actions[action.type](state, action.payload)
  }
  return state
}

const compliance =
  (complianceName = null) =>
  (Component) => {
    class Compliance extends React.Component<any, any> {
      constructor(props) {
        super(props)
      }

      componentDidMount() {
        const { socket, compliance } = this.props
        if (!isUndefined(compliance.init.name)) {
          complianceName = compliance.init.name
        }

        compliance.actions.reset(complianceName)
        compliance.actions.set(complianceName, compliance.init.rules)

        if (
          !isUndefined(compliance.init.socketChannel) &&
          !isUndefined(compliance.init.socketEvent)
        ) {
          socket.subscribe(compliance.init.socketChannel)(compliance.init.socketEvent)(
            (response) => {
              compliance.actions.set(complianceName, response.rules)
            },
          )
        }
      }

      componentWillUnmount() {
        const { socket, compliance } = this.props

        if (!isUndefined(compliance.init.name)) {
          complianceName = compliance.init.name
        }

        compliance.actions.destroy(complianceName)

        if (
          !isUndefined(compliance.init.socketChannel) &&
          !isUndefined(compliance.init.socketEvent)
        ) {
          socket.unsubscribe(compliance.init.socketChannel)(compliance.init.socketEvent)
        }
      }

      render() {
        return <Component<any, any> {...this.props} />
      }
    }

    Compliance.propTypes = {
      compliance: PropTypes.shape({
        init: PropTypes.shape({
          rules: PropTypes.array.isRequired,
          socketChannel: PropTypes.string.isRequired,
          socketEvent: PropTypes.string.isRequired,
        }),
      }),
    }

    const mapStateToProps = (state, props) => {
      if (!isUndefined(props.compliance.init.name)) {
        complianceName = props.compliance.init.name
      }

      return {
        rules: getRules(complianceName)(state),
      }
    }

    const mapDispatchToProps = (dispatch) => {
      return bindActionCreators(
        {
          reset,
          set,
          destroy,
        },
        dispatch,
      )
    }

    const mergedProps = (selectors, actions, own) => {
      return {
        ...own,
        compliance: {
          selectors,
          actions,
          ...own.compliance,
        },
      }
    }

    const withConnect = connect(mapStateToProps, mapDispatchToProps, mergedProps)
    const withSocket = socketProvider()

    return compose(withSocket, withConnect)(Compliance)
  }

export { compliance }
export default compliance
