import React from 'react'
import { Section } from '../ui/Section'
import { Panel } from '../ui/Panel'
import ReportProgressBar from './ReportProgressBar'
import ExpensesChart from './ExpensesChart'
import { Col, Row } from '../../ui/Grid'
import Icon from '../ui/IconComponent'
import KPITimeline from './KPITimeline'
import CostsRanking from './CostsRanking'
import SemicircleGraph from './SemicircleGraph'
import CircleGraph from './CircleGraph'
import withReports from '../../store/app/reports'
import { AmountFormatter } from '../AmountFormatter'
import { trans } from '../../trans'
import { Loader } from '../ui/LoadingOverlay/Loader'
import moment from 'moment'

class Widgets extends React.Component<any, any> {
  render() {
    const { data, isReady } = this.props
    return (
      <Section noBorder className='has-loader'>
        {!isReady && <Loader offset={200} />}
        <Row>
          <Col xs={12} md={6}>
            <Panel className='reports-section'>
              <h6 className='is-text-uppercase'>
                {trans('reports.business-trips')}{' '}
                {trans('reports.currency-in-thou', { currency: 'PLN' })}
              </h6>
              <ExpensesChart
                months={data.monthlyTripsExpenses.months}
                expenses={data.monthlyTripsExpenses.expenses}
                events={data.monthlyTripsExpenses.events}
                onHoverDescription={trans('reports.trip-costs')}
              />
            </Panel>
          </Col>

          <Col xs={12} md={6}>
            <Panel className='reports-section'>
              <h6 className='is-text-uppercase'>
                {trans('reports.employee-expenses')}{' '}
                {trans('reports.currency-in-thou', { currency: 'PLN' })}
              </h6>
              <ExpensesChart
                months={data.monthlyExpensesOnExpenseRequests.months}
                expenses={data.monthlyExpensesOnExpenseRequests.expenses}
                events={data.monthlyExpensesOnExpenseRequests.events}
                onHoverDescription={trans('reports.expenses')}
              />
            </Panel>
          </Col>

          <Col xs={12} md={6}>
            <ReportProgressBar
              countryValue={data.averageExpensesOnNationalAndAbroadTrips.country}
              abroadValue={data.averageExpensesOnNationalAndAbroadTrips.abroad}
              unit={data.averageExpensesOnNationalAndAbroadTrips.unit}
              expenseLabel={trans('reports.average-trip-cost')}
              iconType='suitcase2'
            />
          </Col>

          <Col xs={12} md={6}>
            <ReportProgressBar
              countryValue={data.averageDurationOnNationalAndAbroadTrips.country}
              abroadValue={data.averageDurationOnNationalAndAbroadTrips.abroad}
              unit={data.averageDurationOnNationalAndAbroadTrips.unit}
              format='0.0'
              expenseLabel={trans('reports.average-trip-length')}
              iconType='calendar'
            />
          </Col>

          <Col xs={12} md={6}>
            <ReportProgressBar
              countryValue={data.averageExpenseOnNationalAndAbroadAccommodations.country}
              abroadValue={data.averageExpenseOnNationalAndAbroadAccommodations.abroad}
              unit={data.averageExpenseOnNationalAndAbroadAccommodations.unit}
              expenseLabel={trans('reports.average-accommodation-cost')}
              iconType='accomodation'
            />
          </Col>

          <Col xs={12} md={6}>
            <ReportProgressBar
              countryValue={data.averageExpensesOnNationalAndAbroadPlaneTrips.country}
              abroadValue={data.averageExpensesOnNationalAndAbroadPlaneTrips.abroad}
              unit={data.averageExpensesOnNationalAndAbroadPlaneTrips.unit}
              expenseLabel={trans('reports.average-flight-cost')}
              iconType='plane'
            />
          </Col>

          <Col md={4}>
            <Panel className='reports-section'>
              <h6 className='is-align-center is-text-uppercase'>
                {trans('reports.domestic-foreign-trips')}
              </h6>
              <CircleGraph
                data={[
                  {
                    id: 1,
                    status: trans('reports.domestic'),
                    value: data.percentageValueNationalAndAbroadTrips.domestic,
                    color: '#64e0ba',
                  },
                  {
                    id: 2,
                    status: trans('reports.foreign'),
                    value: data.percentageValueNationalAndAbroadTrips.foreign,
                    color: '#F44336',
                  },
                ]}
              />
            </Panel>
            <Panel className='reports-section'>
              <h6 className='is-align-center is-text-uppercase'>
                {trans('reports.internal-external-meetings')}
              </h6>
              <CircleGraph
                data={[
                  {
                    id: 1,
                    status: trans('reports.internal'),
                    value: data.percentageValueInternalAndExternalMeetings.internal,
                    color: '#64e0ba',
                  },
                  {
                    id: 2,
                    status: trans('reports.external'),
                    value: data.percentageValueInternalAndExternalMeetings.external,
                    color: '#ffc107',
                  },
                ]}
              />
            </Panel>
          </Col>
          <Col md={4}>
            <Panel
              className='reports-section'
              header={
                <h5>
                  <Icon type='suitcase2' lg />
                  {trans('reports.process-kpi')}: {trans('reports.trips')}
                </h5>
              }
            >
              <KPITimeline items={data.tripKPI} />
            </Panel>

            <Panel
              className='reports-section'
              header={
                <h5 className='is-align-center is-text-uppercase'>
                  {trans('reports.trips-costs-ranking')}
                </h5>
              }
            >
              <CostsRanking items={data.tripRanking} type={'tripRanking'} />
            </Panel>
          </Col>
          <Col md={4}>
            <Panel
              className='reports-section reports-section__empty'
              header={
                <h5>
                  {trans('reports.average-expense-cost')}:{' '}
                  <AmountFormatter amount={data.averageExpense.value} /> {data.averageExpense.unit}
                </h5>
              }
            />

            <Panel
              className='reports-section'
              header={
                <h5>
                  <Icon type='wallet' lg />
                  {trans('reports.process-kpi')}: {trans('reports.expenses')}
                </h5>
              }
            >
              <KPITimeline items={data.expenseKPI} />
            </Panel>

            <Panel
              className='reports-section'
              header={
                <h5 className='is-align-center is-text-uppercase'>
                  {trans('reports.representation-costs-ranking')}
                </h5>
              }
            >
              <CostsRanking items={data.expenseRanking} type={'expenseRanking'} />
            </Panel>

            <Panel className='reports-section'>
              <h6 className='is-align-center is-text-uppercase'>
                {trans('reports.rules-violations-applications')}
              </h6>
              <SemicircleGraph value={data.percentageValueRequestsWithBrokenRules.value} />
            </Panel>
          </Col>
        </Row>
      </Section>
    )
  }
}

export default withReports()(Widgets)
