import React from 'react'
import PropTypes from 'prop-types'
import { trans } from '../../../trans'
import { connect } from '../../../containers/FlightsBooking'
import classNames from 'classnames'

const Header = connect((props) => {
  const {
    flights: {
      selectors: { featuredOffers },
    },
    visible,
    mainHeader,
    style,
  } = props

  if (!featuredOffers.length) {
    return null
  }

  let label = trans(`flights-booking.flight-featured`)

  const classes = classNames(
    {
      'trip-dialog__header': true,
      'plane-trip__offers-header': true,
      'plane-trip__offers-header--main': mainHeader,
      'plane-trip__offers-header--not-visible': !visible,
    },
    props.className,
  )

  return (
    <span className={classes} style={style}>
      <strong>{label}</strong>
    </span>
  )
})

Header.propTypes = {
  visible: PropTypes.bool.isRequired,
}

Header.defaultProps = {
  visible: true,
}

export default Header
export { Header }
