import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import { provide as tripDidNotHavePlaceProvider } from '../../containers/TripDidNotHavePlace'

const TripDidNotHavePlaceWrapperComponent = (props) => props.children

const TripDidNotHavePlaceWrapper = tripDidNotHavePlaceProvider(TripDidNotHavePlaceWrapperComponent)

TripDidNotHavePlaceWrapper.propTypes = {
  request: PropTypes.object.isRequired,
}

export default TripDidNotHavePlaceWrapper
export { TripDidNotHavePlaceWrapper }
