import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import { trans } from '../../trans'
import Dialog from '@material-ui/core/Dialog'
import Paper from '@material-ui/core/Paper'
import DialogContent from '@material-ui/core/DialogContent'
import Icon from '../ui/IconComponent'
import Time from './Filters/Time'
import TravelClass from './Filters/TravelClass'
import { get } from 'lodash'
import moment from 'moment'
import OffersList from './OffersList'

class OffersDialog extends Component<any, any> {
  renderTitle = () => {
    const { trainsBooking } = this.props

    const from = get(trainsBooking, 'selectors.query.departure_location.name', '')
    const to = get(trainsBooking, 'selectors.query.destination_location.name', '')

    return trans('trains-booking.offers-list-title', { from, to })
  }

  renderDetails = () => {
    const { trainsBooking } = this.props

    const date = moment(get(trainsBooking, 'selectors.query.departure_at', null))
    const round = get(trainsBooking, 'element.round_trip', false)

    let label = date.format('D MMM.')

    if (round) {
      label += ' ' + trans('trains-booking.round-trip')
    } else {
      label += ' ' + trans('trains-booking.one-way-trip')
    }

    return label
  }

  render() {
    const { trainsBooking } = this.props

    return (
      <Dialog
        open={trainsBooking.selectors.isResultsListOpen}
        onClose={trainsBooking.actions.closeResultsList}
        disableBackdropClick
        aria-labelledby='alert-dialog-slide-title'
        aria-describedby='alert-dialog-slide-description'
        maxWidth={false}
        fullWidth={true}
        PaperProps={{
          className: 'trip-dialog',
          square: true,
        }}
        scroll='paper'
      >
        <DialogContent>
          <div className='trip-dialog__main'>
            <div className='trip-dialog__header-line'>
              <div className='trip-dialog__header-title'>
                <h2 className='trip-dialog__trip-title h2'>{this.renderTitle()}</h2>
                <span className='trip-dialog__trip-details'>{this.renderDetails()}</span>
              </div>

              <Icon
                type='close'
                className='trip-dialog__close'
                onClick={trainsBooking.actions.closeResultsList}
              />
            </div>

            <nav className='trip-dialog__dashboard'>
              <Time trainsBooking={trainsBooking} />
              <TravelClass trainsBooking={trainsBooking} />
            </nav>

            <OffersList trainsBooking={trainsBooking} />
          </div>
        </DialogContent>
      </Dialog>
    )
  }
}

OffersDialog.propTypes = {
  trainsBooking: PropTypes.object.isRequired,
}

export default OffersDialog
export { OffersDialog }
