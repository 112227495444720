import React from 'react'
import { trans } from '../../trans'
import { TimelineElement, TimeLineElementHeader } from '../Timeline'
import { Col } from '../../ui/Grid'
import { EXPENSES_TYPES_FILLED_TO_ICONS, EXPENSES_TYPES_TO_ICONS } from '../ui/IconComponent'
import { AmountFormatter } from '../AmountFormatter'

class Preview extends React.Component<any, any> {
  constructor(props) {
    super(props)
  }

  renderLabel() {
    const { types, element } = this.props
    const costType = types
      .map((type) => type.options)
      .reduce((a, b) => [...a, ...b], [])
      .find((option) => option.slug === element.cost_type.slug)

    if (costType && types) {
      if (element.description) {
        return `${costType.label} - ${element.description}`
      }
      return `${costType.label}`
    }

    return trans('global.fill-data')
  }

  renderAmount() {
    const {
      element: { amount, amount_currency },
    } = this.props

    return (
      <div>
        <AmountFormatter amount={amount} /> {amount_currency}
      </div>
    )
  }

  render() {
    const { form, last, element } = this.props
    const timelineIcon = EXPENSES_TYPES_TO_ICONS[element.icon_type]

    return (
      <div data-test='other-cost-preview'>
        <TimelineElement className={form} activeSimilar last={last}>
          <TimeLineElementHeader icon={timelineIcon} iconFill={true}>
            <Col xs={9} className='timeline__header--title'>
              {this.renderLabel()}
            </Col>
            <Col style={{ paddingRight: '0' }} className='is-allign-end' xs={2}>
              {this.renderAmount()}
            </Col>
          </TimeLineElementHeader>
        </TimelineElement>
      </div>
    )
  }
}

export { Preview }
export default { Preview }
