import { IAccountPayment } from '../../../types/account'
import { useParams } from 'react-router-dom'
import useMyCardDataSource from '../../../hooks/useMyCardDataSource'

export default function useAccountPayments() {
  const params = useParams<{ id: string; statementId: string }>()
  const url = `/mycard/accounts/${params.id}/statements/${params.statementId}/entries`

  return useMyCardDataSource<IAccountPayment>(url)
}
