import PropTypes from 'prop-types'
import React from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import {
  changeElementOfType,
  addElementOfType,
  deleteElementOfType,
  isRemainingProgress,
} from '../../../store/app/document-expense-types'
import { getSumOfExpenseTypes } from '../../../store/app/document-expense-types/index'

class ExpenseTypesExpenseManager extends React.Component<any, any> {
  onEdit = (item, type, values) => {
    const element = type['element']

    const { addElementOfType, deleteElementOfType, changeElementOfType } = this.props
    const floatGross = parseFloat(values['gross'])

    if (element.id === null && !isNaN(floatGross)) {
      return addElementOfType(item, element['document_id'], {
        request_element: {
          id: item['id'],
          type: item['type'],
        },
        gross: floatGross,
        type_id: type['id'],
      })
    } else if (element.id !== null && (floatGross === 0 || isNaN(floatGross))) {
      return deleteElementOfType(item, element['document_id'], element)
    } else if (element.id !== null) {
      return changeElementOfType(item, element['document_id'], element, type, {
        gross: floatGross,
        type_id: type['id'],
      })
    }

    return new Promise((resolve) => resolve())
  }

  render() {
    const { children, group, sumOfExpenseTypes, suggestedExpense, isRemainingInProgress } =
      this.props

    const renderProps = {
      expenses: group.types,
      onEdit: this.onEdit,
      sumOfExpenseTypes: sumOfExpenseTypes,
      suggestedExpense: suggestedExpense,
      isRemainingInProgress: isRemainingInProgress,
    }

    return children(renderProps)
  }
}

ExpenseTypesExpenseManager.propTypes = {
  children: PropTypes.func.isRequired,
  group: PropTypes.object.isRequired,
}

const mapStateToProps = (state) => ({
  sumOfExpenseTypes: getSumOfExpenseTypes(state),
  isRemainingInProgress: isRemainingProgress(state),
})
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      changeElementOfType,
      addElementOfType,
      deleteElementOfType,
    },
    dispatch,
  )
}

ExpenseTypesExpenseManager = connect(
  mapStateToProps,
  mapDispatchToProps,
)(ExpenseTypesExpenseManager)

export { ExpenseTypesExpenseManager }
export default ExpenseTypesExpenseManager
