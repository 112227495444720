import React, { Component } from 'react'
import { Col } from '../../../ui/Grid'
import { TripRequestElementLabel } from '../TripRequestElementLabel'
import { trans } from '../../../trans'
import { STATE_REQUEST, STATE_SETTLEMENT } from '../../../constants/request'
import { AmountFormatter } from '../../AmountFormatter'
import Icon, { TRAVEL_TYPES_TO_FILLED_ICONS } from '../../ui/IconComponent'
import { TimeLineElementHeader } from '../../Timeline'
import { ForeignCurrenciesTooltip } from '../../ForeignCurrenciesTooltip'
import { TRAVEL_PLANE_TRIP } from '../../../constants/travel'

class Header extends Component<any, any> {
  renderWayBack() {
    const { element } = this.props

    let message = trans('request.way-back')

    if (element.isLumpSum()) {
      message = trans('request-element.settled-within-lump-sums')
    }

    if (element.virtual) {
      return (
        <Col xs={6} is_pull_end>
          <span style={{ marginRight: '17px' }}>{message}</span>
        </Col>
      )
    }

    return null
  }

  renderForeignCurrenciesTooltip(accounted = false) {
    const { element, instanceCurrency } = this.props

    let currencies = element.getAllAmounts()
    if (accounted) {
      currencies = Object.values(element.accounted_component_amounts).map((e) => ({
        currency: e.currency,
        value: e.amount,
      }))
    }

    return (
      <ForeignCurrenciesTooltip
        instanceCurrency={instanceCurrency}
        foreignCurrencies={currencies}
      />
    )
  }

  renderAccountedAmount() {
    const { element, request, instanceCurrency } = this.props

    if (element.isLumpSum() && !element.virtual) {
      return (
        <Col xs={6} is_pull_end>
          <span style={{ marginRight: '17px' }}>
            {trans('request-element.settled-within-lump-sums')}
          </span>
        </Col>
      )
    }

    if (!element.virtual && request.state === STATE_SETTLEMENT) {
      return (
        <Col xs={3} is_pull_end align_center style={{ paddingRight: '28px' }}>
          <div className='header-item'>
            <div className='header-label'>{trans('request-summary.settled-amount')}</div>
            {this.renderForeignCurrenciesTooltip(true)}
            <AmountFormatter amount={element['accounted_amount']} />
            &nbsp;
            {instanceCurrency}
          </div>
        </Col>
      )
    }
  }

  renderSettledConvertedAccount() {
    const { element, request, instanceCurrency } = this.props

    if (element.isLumpSum()) {
      return null
    }

    if (!element.virtual) {
      const style =
        request.state === STATE_REQUEST ? { marginLeft: '70px' } : { paddingRight: '32px' }

      return (
        <Col xs={3} is_pull_end align_center style={style}>
          <div className='header-item'>
            <div className='header-label'>{trans('request-summary.requested-amount')}</div>
            {this.renderForeignCurrenciesTooltip()}
            <AmountFormatter amount={element['converted_amount']} />
            &nbsp;
            {instanceCurrency}
          </div>
        </Col>
      )
    }

    return null
  }

  renderToggle() {
    const { element, isOpen, toggle } = this.props

    if (element.isLumpSum()) {
      return null
    }

    if (element.type === TRAVEL_PLANE_TRIP && element.virtual) {
      return false
    }

    return (
      <div
        style={{
          position: 'absolute',
          display: 'flex',
          right: '0',
        }}
      >
        <Icon
          type='arrow'
          onClick={() => {
            toggle(element, true)
          }}
          rotatable
          rotate={isOpen}
        />
      </div>
    )
  }

  render() {
    const { element } = this.props
    let icon = TRAVEL_TYPES_TO_FILLED_ICONS[element.type]

    return (
      <TimeLineElementHeader icon={icon} gradient='true'>
        <Col xs={6}>
          <TripRequestElementLabel element={element} />
        </Col>

        {this.renderWayBack()}
        {this.renderAccountedAmount()}
        {this.renderSettledConvertedAccount()}
        {this.renderToggle()}
      </TimeLineElementHeader>
    )
  }
}

export default Header
export { Header }
