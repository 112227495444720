import PropTypes from 'prop-types'
import React from 'react'
import Button from '../../../components/ui/ButtonComponent'
import Icon from '../../../components/ui/IconComponent'
import trans from '../../../trans'
import { InputValid } from '../../../ui/index'
import { InputErrorComponent } from '../../InputErrorComponent'
import { AmountFormatter } from '../../AmountFormatter/index'
import { CurrencyField } from '../../ui/Form'

class InstallmentTimelineElement extends React.Component<any, any> {
  constructor(props) {
    super(props)
  }

  onEdit() {
    this.props.onChange(this.props.values['id'], 'isOpen', !this.props.values['isOpen'])
  }

  onSave(e) {
    return this.props.onSave(e, this.props.values['id'])
  }

  renderEdit() {
    return (
      <div className='advance-payment__item'>
        <div className='advance-payment__new-item'>
          <div className='advance-payment__input-amount'>
            <span className='advance-payment__item-label'>
              {trans('request.installment-amount')}
            </span>
            <InputValid
              name='amount'
              placeholder={'0,00'}
              value={this.props.values['amount']}
              options={{ numeral: true, numeralThousandsGroupStyle: 'none' }}
              onChange={(e) => this.props.onChange(this.props.values['id'], 'amount', e)}
            >
              <InputErrorComponent errors={this.props.errors['amount']} />
            </InputValid>
          </div>

          <div className='advance-payment__input-currency'>
            <span className='advance-payment__item-label'>{trans('request.currency')}</span>
            <CurrencyField
              value={this.props.values.currency}
              onChange={(value) => this.props.onChange(this.props.values.id, 'currency', value)}
              currencies={this.props.currencies.map(({ id, code }) => ({
                label: code,
                value: code,
              }))}
              disabled={!this.props.request.abilities.editInstalmentCurrency}
            />
            <InputErrorComponent errors={this.props.errors['currency_id']} />
          </div>
        </div>

        <div className='advance-payment__buttons'>
          <Icon type='trash' lg onClick={() => this.props.onRemove(this.props.values['id'])} />
          <Button primary pull_end onClick={(e) => this.onSave(e)}>
            {trans('ui.save-installment')}
          </Button>
        </div>
      </div>
    )
  }

  renderPreview() {
    return (
      <div className='advance-payment__item'>
        <div>
          <span className='advance-payment__item-label'>{trans('request.installment-amount')}</span>
          <div className='advance-payment__item-amount'>
            <AmountFormatter amount={this.props.values['amount']} /> {this.props.values['currency']}
          </div>
        </div>

        <div>
          <span className='timeline-icon'>
            <Icon type='edit' lg onClick={() => this.onEdit()} />
          </span>
          <span className='timeline-icon'>
            <Icon type='trash' lg onClick={() => this.props.onRemove(this.props.values['id'])} />
          </span>
        </div>
      </div>
    )
  }

  render() {
    let content
    if (this.props.values['isOpen']) {
      content = this.renderEdit()
    } else {
      content = this.renderPreview()
    }

    return content
  }
}

InstallmentTimelineElement.propTypes = {
  request: PropTypes.object.isRequired,
}

export { InstallmentTimelineElement }
export default { InstallmentTimelineElement }
