import React, { Component } from 'react'
import { connect } from 'react-redux'
import {
  getAssistantsAction,
  getAssistants,
  deleteAssistantAction,
  reset,
} from '../../../../store/app/assistants'
import moment from 'moment'
import { config } from '../../../../config'
import Button from '../../../ui/ButtonComponent'
import trans from '../../../../trans'
import PropTypes from 'prop-types'
import Avatar from '../../../Avatar/Avatar'

class AssistantsTable extends Component<any, any> {
  constructor(props) {
    super(props)

    this.state = {
      userAvatars: {},
    }
  }

  componentDidMount() {
    this.props.reset()
    if (
      this.props.subjectUser &&
      this.props.subjectUser.slug &&
      this.props.isFromExternal === true
    ) {
      this.props.getAssistantsAction(this.props.subjectUser.slug)
    }

    if (!this.props.isFromExternal) {
      this.props.getAssistantsAction()
    }
  }

  componentWillUnmount() {
    this.props.reset()
  }

  renderUserInfo = (user) => {
    if (!user) return null

    return (
      <div className='substitutions__user'>
        <Avatar user={user} className='substitutions__user-avatar' />

        <div>
          <span className='substitutions__user-detail substitutions__user-detail--bold'>
            {user.first_name} {user.last_name}
          </span>
          <a href={`mailto:${user.email}`} className='substitutions__user-detail'>
            {user.email}
          </a>
        </div>
      </div>
    )
  }

  handleDelete = (assistant) => {
    this.props.deleteAssistantAction(
      assistant.id,
      this.props.subjectUser && this.props.subjectUser.slug ? this.props.subjectUser.slug : null,
    )
  }

  render() {
    if (this.props.data.length === 0) {
      return <strong>{trans('global.no-results')}</strong>
    }

    return (
      <div className='substitutions-table'>
        <div className='substitutions-table__header substitutions-table__row'>
          <div className='substitutions-table__cell'>{trans('global.user')}</div>
          <div className='substitutions-table__cell'>{trans('user.operations')}</div>
        </div>

        {this.props.data.map((assistant) => (
          <div className='substitutions-table__row' key={assistant.id}>
            <div className='substitutions-table__cell'>
              {this.renderUserInfo(assistant.assistant)}
            </div>
            <div className='substitutions-table__cell'>
              <Button xxs outline onClick={() => this.handleDelete(assistant)}>
                {trans('user.delete')}
              </Button>
            </div>
          </div>
        ))}
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  data: getAssistants(state),
  reset: reset,
})

const mapDispatchToProps = {
  getAssistantsAction,
  deleteAssistantAction,
  reset,
}

AssistantsTable.propTypes = {
  subjectUser: PropTypes.object,
}

export default connect(mapStateToProps, mapDispatchToProps)(AssistantsTable)
