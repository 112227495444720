import React from 'react'
import CustomAutocompleteField from './CustomAutocompleteField'
import APIClient from '../../../services/APIClient'

const fetch = (...args) => {
  return APIClient.getAirports(...args)
}

const AirportsAutocompleteField = (props) => {
  return <CustomAutocompleteField fetchMethod={fetch} {...props} />
}

export default AirportsAutocompleteField
export { AirportsAutocompleteField }
