import React from 'react'

function tabTitle({ handleClick, title, active }) {
  const classes = ['tabs__title', active && 'tabs__title--active']
    .filter((className) => className)
    .join(' ')

  return (
    <li className={classes} onClick={handleClick} role='button'>
      {title}
    </li>
  )
}

export default tabTitle
