import PropTypes from 'prop-types'
import React, { Fragment } from 'react'
import classnames from 'classnames'
import _ from 'lodash'

class PaginatorDefault extends React.Component<any, any> {
  getMinPage({ page, pageCount }) {
    const minPage =
      pageCount - page > 3 ? Math.max(page - 5, 1) : Math.max(page - (9 - (pageCount - page)), 1)

    return minPage
  }

  getMaxPage({ page, pageCount }) {
    return Math.min(Math.max(page + 4, 10), pageCount)
  }

  render() {
    const { page, pageCount, changePage, perPage, totalCount, align, className, ...rest } =
      this.props

    if (pageCount < 2) {
      return null
    }

    const minPage = this.getMinPage({ page, pageCount })
    const maxPage = this.getMaxPage({ page, pageCount })
    const hasPrevPage = page > 1
    const hasNextPage = page < pageCount

    const classes = classnames({
      pagination: true,
      [className]: true,
    })

    return (
      <div className={classes}>
        <div className='pagination__wrapper'>
          <a
            onClick={() => {
              if (hasPrevPage) {
                changePage(page - 1)
              }
            }}
            className='pagination__link'
          >
            {'<'}
          </a>

          {_.range(minPage, maxPage + 1).map((pageNb) => {
            return (
              <Fragment key={`page-${pageNb}`}>
                {pageNb >= 100 ? <span style={{ marginLeft: '5px' }} /> : ''}
                <a
                  onClick={() => changePage(pageNb)}
                  className={`pagination__link ${
                    page === pageNb ? 'pagination__link--is-active' : ''
                  }`}
                >
                  {pageNb}
                </a>
              </Fragment>
            )
          })}

          <span style={{ marginLeft: '5px' }} />
          <a
            onClick={() => {
              if (hasNextPage) {
                changePage(page + 1)
              }
            }}
            className='pagination__link'
          >
            {'>'}
          </a>
        </div>
      </div>
    )
  }
}

PaginatorDefault.propTypes = {
  changePage: PropTypes.func.isRequired,
  align: PropTypes.string,
}

export { PaginatorDefault }
export default PaginatorDefault
