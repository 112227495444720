import React, { memo } from 'react'
import { Col, Row } from '../../ui'
import trans from '../../trans'
import { AmountFormatter } from '../AmountFormatter'

function TripRequestHeaderSummary({ accounted_amount, requested_amount, currency }) {
  return (
    <div className='trip-request-header-summary'>
      <Row className='trip-request-header-summary__labels'>
        <Col xs={6} />
        <Col xs={3} is_pull_end>
          {trans('request-summary.settled-amount')}
        </Col>
        <Col xs={3} is_pull_end>
          {trans('request-summary.requested-amount')}
        </Col>
      </Row>
      <Row className='trip-request-header-summary__values'>
        <Col xs={6} />
        <Col xs={3} is_pull_end>
          <AmountFormatter amount={accounted_amount} />
          &nbsp;
          {currency}
        </Col>
        <Col xs={3} is_pull_end>
          <AmountFormatter amount={requested_amount} />
          &nbsp;
          {currency}
        </Col>
      </Row>
    </div>
  )
}

export default memo(TripRequestHeaderSummary)
