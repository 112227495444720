import React, { useEffect, useRef, useState } from 'react'
import { FormGroup } from '../../ui'
import { trans } from '../../trans'
import classNames from 'classnames'
import Icon from '../ui/IconComponent'

export function ExpenseRequestActions(props) {
  const [open, setOpen] = useState(false)
  const ref = useRef(null)
  const hide = () => setOpen(false)
  const show = () => setOpen(true)
  const containerClasses = classNames({
    'react-select__select-container': true,
    'react-select-custom': true,
    'is-invisible': !open,
  })

  const handleClickOutside = (event) => {
    if (!event.composedPath().includes(ref.current)) {
      hide()
    }
  }

  useEffect(() => {
    window.addEventListener('mouseup', handleClickOutside)

    return () => {
      window.removeEventListener('mouseup', handleClickOutside)
    }
  }, [])

  return (
    <div className='request-actions-select'>
      <div className='request-actions-select-label'>
        <Icon type='other' onClick={show} /> {trans('trip-request.trip-options')}
      </div>

      <div className={containerClasses} ref={ref}>
        <div className='react-select__menu'>
          {!props.hideNotApplicable && (
            <div onClick={hide} className='react-select__option'>
              {trans('trip-request.trip-option-not-applicable')}
            </div>
          )}

          {props.children}
        </div>
      </div>
    </div>
  )
}
