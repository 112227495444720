import React, { PropsWithChildren } from 'react'
import classNames from 'classnames'

interface FormGroupProps {
  className?: string
  left?: boolean
  inverse?: boolean
  label?: string
  labeltop?: boolean
}

export const FormGroup = ({
  className,
  left,
  inverse,
  children,
  label,
  labeltop,
  ...props
}: PropsWithChildren<FormGroupProps>) => {
  const FormGroupClasses = classNames(className, {
    'form-group': true,
    'form-group--inline': inverse,
    'form-group--label-top': labeltop,
    'form-group--inverse': inverse,
  })

  const LabelClasses = classNames({
    'form-group__label': true,
    'form-group__label--start': left,
  })

  if (inverse) {
    return (
      <div className={FormGroupClasses} {...props}>
        <span className={LabelClasses}>{children}</span>
        {label}
      </div>
    )
  }

  return (
    <div className={FormGroupClasses} {...props}>
      {label && <span className={LabelClasses}>{label}</span>}
      {children}
    </div>
  )
}
