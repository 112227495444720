import React from 'react'
import {
  getOffers,
  getQuery,
  getSearchUuid,
  getSelectedOffer,
  getStatus,
  initialized,
  isLoading,
  isIntegrity,
  getFilters,
  isOptionBooked,
  selector,
  getTotalPaxes,
} from '../selectors'
import { bindActionCreators } from 'redux'
import {
  search,
  selectOffer,
  setOffers,
  set,
  reset,
  fetchSelectedForElement,
  setFilter,
  setFilters,
  setQuery,
  bookOffer,
  searchAnotherOne,
  setAgreement,
  toggleSearch,
  resignFromSearching,
  closeResultsList,
  unselectOption,
  setInitialized,
  backToSearching,
  subscribeReservationStatus,
  unsubscribeReservationStatus,
} from '../creators'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { isTrainSearchEnabled } from '../../trip-request'

const trainsBooking =
  (resetOnMount = false) =>
  (Component) => {
    class TrainsBookingProvider extends React.Component<any, any> {
      componentDidMount() {
        const { trainsBooking } = this.props

        if (resetOnMount) {
          trainsBooking.actions.reset()

          if (trainsBooking.element.draft) {
            trainsBooking.actions.set({
              offers: trainsBooking.init.offers,
              uuid: trainsBooking.init.uuid,
              query: trainsBooking.init.query,
              enabled: trainsBooking.selectors.isSearchFeatureEnabled,
            })
          } else {
            trainsBooking.actions.setQuery(trainsBooking.init.query)

            if (
              trainsBooking.element.searcher_disabled ||
              !trainsBooking.selectors.isSearchFeatureEnabled
            ) {
              trainsBooking.actions.toggleSearch()
              trainsBooking.actions.setInitialized(true)
            } else {
              trainsBooking.actions
                .fetchSelectedForElement(trainsBooking.request, trainsBooking.element)
                .then(() => {
                  trainsBooking.actions.setInitialized(true)
                })
            }
          }
        }
      }

      render() {
        return <Component<any, any> {...this.props} />
      }
    }

    const mapStateToProps = (state, props) => {
      const {
        trainsBooking: {
          init: { name },
        },
      } = props
      const get = selector(name)(state)

      return {
        offers: getOffers(name)(state),
        status: getStatus(name)(state),
        selectedOffer: getSelectedOffer(name)(state),
        uuid: getSearchUuid(name)(state),
        initialized: initialized(name)(state),
        query: getQuery(name)(state),
        isLoading: isLoading(name)(state),
        isIntegrity: isIntegrity(name)(state),
        filters: getFilters(name)(state),
        isOptionBooked: isOptionBooked(name)(state),
        isSearchEnabled: get('enabled'),
        isResultsListOpen: get('isResultsListOpen'),
        isOfferDialogOpen: get('isOfferDialogOpen'),
        hasOffers: get('hasOffers'),
        isBlocked: get('isBlocked'),
        waitingForAttributes: get('waitingForAttributes'),
        hasAgreement: get('hasAgreement'),
        totalPaxes: getTotalPaxes(name)(state),
        isSearchFeatureEnabled: isTrainSearchEnabled(state),
      }
    }

    const mapDispatchToProps = (dispatch, props) => {
      const {
        trainsBooking: {
          init: { name },
          request,
          element,
        },
      } = props
      return bindActionCreators(
        {
          reset: reset(name),
          set: set(name),
          search: search(name),
          setOffers: setOffers(name),
          selectOffer: selectOffer(name),
          fetchSelectedForElement: fetchSelectedForElement(name),
          setFilter: setFilter(name),
          setFilters: setFilters(name),
          setQuery: setQuery(name),
          bookOffer: bookOffer(name)(request, element),
          searchAnotherOne: searchAnotherOne(name)(request),
          toggleSearch: toggleSearch(name),
          resignFromSearching: resignFromSearching(name)(request, element),
          closeResultsList: closeResultsList(name),
          unselectOption: unselectOption(name),
          setInitialized: setInitialized(name),
          backToSearching: backToSearching(name)(request)(element),
          setAgreement: setAgreement(name),
          subscribeReservationStatus: subscribeReservationStatus(name),
          unsubscribeReservationStatus: unsubscribeReservationStatus(name),
        },
        dispatch,
      )
    }

    const mergedProps = (selectors, actions, own) => {
      return {
        ...own,
        trainsBooking: {
          selectors,
          actions,
          ...own.trainsBooking,
        },
      }
    }

    TrainsBookingProvider.propTypes = {
      trainsBooking: PropTypes.shape({
        request: PropTypes.object.isRequired,
        element: PropTypes.object.isRequired,
        init: PropTypes.shape({
          name: PropTypes.string.isRequired,
          offers: PropTypes.array,
        }),
      }),
    }

    return connect(mapStateToProps, mapDispatchToProps, mergedProps)(TrainsBookingProvider)
  }

export { trainsBooking }
export default trainsBooking
