import React from 'react'
import PropTypes from 'prop-types'

class Loader extends React.Component<any, any> {
  render() {
    let classes = 'component-loader__loader'
    const position = !!this.props.offset ? this.props.offset + 'px' : 'undefined'

    if (this.props.small) {
      classes += ' component-loader__loader--small'
    }

    if (this.props.center) {
      classes += ' component-loader__loader--center'
    }

    return (
      <div className='component-loader__overlay'>
        <div className={classes} style={{ top: position }} />
      </div>
    )
  }
}

Loader.propTypes = {
  small: PropTypes.bool,
  center: PropTypes.bool,
}

export { Loader }
export default { Loader }
