// initial state
import { Location } from '../../../models/Location'
import APIClient from '../../../services/APIClient'
import { config } from '../../../config'
import moment from 'moment'
import { getElements, startRecomposition, stopRecomposition } from '../trip-timeline'
import { get } from 'lodash'
import {
  TRAVEL_ACCOMODATION,
  TRAVEL_ACCOMODATION_SUGGESTION,
  TRAVEL_EMPTY,
} from '../../../constants/travel'
import { Factory as ElementFactory } from '../../../models/timeline/index'

const getInitialState = () => {
  return {
    start: {
      date: {
        value: null,
        min_date: null,
        max_date: null,
        saved: false,
        errors: [],
      },
      location: {
        value: new Location(),
        errors: [],
      },
    },
    end: {
      date: {
        value: null,
        min_date: null,
        max_date: null,
        saved: false,
        errors: [],
      },
      location: {
        value: new Location(),
        errors: [],
      },
    },
    initialized: false,
  }
}

// constants
export const MOUNT_POINT = 'edge-points'
export const RESET = `${MOUNT_POINT}::reset`
export const INIT = `${MOUNT_POINT}::init`
export const SET_DATE = `${MOUNT_POINT}::set-date`
export const SET_LOCATION = `${MOUNT_POINT}::set-location`
export const SET_MIN_DATE = `${MOUNT_POINT}::set-min-date`
export const SET_MAX_DATE = `${MOUNT_POINT}::set-max-date`
export const SET_SAVED = `${MOUNT_POINT}::set-saved`
export const SET_ERRORS = `${MOUNT_POINT}::set-errors`

// reducer
export const reducer = (state = getInitialState(), action) => {
  switch (action.type) {
    case RESET:
      return getInitialState()
    case INIT: {
      const { trip_starts, trip_ends, start_location, end_location } = action.payload

      if (state.initialized) {
        return state
      }

      return {
        ...state,
        start: {
          ...state.start,
          date: {
            ...state.start.date,
            value: trip_starts ? trip_starts : moment().format(config.apiDateFormat),
            saved: trip_starts !== null,
          },
          location: {
            value: start_location,
            errors: [],
          },
        },
        end: {
          ...state.end,
          date: {
            ...state.end.date,
            value: trip_ends ? trip_ends : moment().format(config.apiDateFormat),
            saved: trip_ends !== null,
          },
          location: {
            value: end_location,
            errors: [],
          },
        },
        initialized: true,
      }
    }
    case SET_DATE: {
      const { point, date } = action.payload
      return {
        ...state,
        [point]: {
          ...state[point],
          date: {
            ...state[point].date,
            value: date,
            saved: true,
          },
        },
      }
    }

    case SET_LOCATION: {
      const { point, location } = action.payload
      return {
        ...state,
        [point]: {
          ...state[point],
          location: {
            value: location,
          },
        },
      }
    }

    case SET_MIN_DATE: {
      const { point, date } = action.payload
      return {
        ...state,
        [point]: {
          ...state[point],
          date: {
            ...state[point].date,
            min_date: date,
          },
        },
      }
    }

    case SET_MAX_DATE: {
      const { point, date } = action.payload
      return {
        ...state,
        [point]: {
          ...state[point],
          date: {
            ...state[point].date,
            max_date: date,
          },
        },
      }
    }
    case SET_SAVED: {
      const { point } = action.payload

      return {
        ...state,
        [point]: {
          ...state[point],
          date: {
            ...state[point].date,
            saved: true,
          },
        },
      }
    }
    case SET_ERRORS: {
      const { point, field, errors } = action.payload

      return {
        ...state,
        [point]: {
          ...state[point],
          [field]: {
            ...state[point][field],
            errors,
          },
        },
      }
    }

    default:
      return state
  }
}

// actions
export const reset = () => (dispatch) => {
  dispatch({
    type: RESET,
  })
}

export const initData = (request) => (dispatch) => {
  dispatch({
    type: INIT,
    payload: request,
  })
}

export const saveDate = (request, point, date) => {
  return APIClient.updateEdgePoint(request.slug, {
    [`trip_${point}s`]: date,
  })
}

export const setDate = (point, date) => (dispatch) => {
  if (!moment.isMoment(date)) {
    date = moment(date)
  }

  dispatch({
    type: SET_DATE,
    payload: {
      point,
      date: date.second(0).minutes(0).hours(0).format(config.apiDateTimeFormat),
    },
  })
}

export const setDateAndSave = (request, point, date) => (dispatch) => {
  const formattedDate = moment(date).second(0).minutes(0).hours(0).format(config.apiDateTimeFormat)

  dispatch(setErrors(point, 'date', {}))
  dispatch(startRecomposition())
  dispatch(setDate(point, date))

  saveDate(request, point, formattedDate)
    .then(() => {
      dispatch(stopRecomposition())
    })
    .catch((response) => {
      const errors = get(response, 'alerts.0.errors', {})
      dispatch(setErrors(point, 'date', errors))
      dispatch(stopRecomposition())
    })
}

export const saveLocation = (request, point, location) => {
  return APIClient.updateEdgePoint(request.slug, {
    [`${point}_location`]: location,
  })
}

export const setLocation = (request, point, location) => (dispatch) => {
  dispatch(setErrors(point, 'location', []))

  dispatch({
    type: SET_LOCATION,
    payload: {
      point,
      location,
    },
  })

  return saveLocation(request, point, location).catch((response) => {
    const errors = get(response, 'alerts.0.errors', {})
    dispatch(setErrors(point, 'location', errors))
  })
}

export const setErrors = (point, field, errors) => (dispatch) => {
  dispatch({
    type: SET_ERRORS,
    payload: {
      point,
      field,
      errors,
    },
  })
}

export const setSaved = (point) => (dispatch) => {
  dispatch({
    type: SET_SAVED,
    payload: {
      point,
    },
  })
}

export const actions = {
  reset,
  saveDate,
  setDate,
  setDateAndSave,
  setLocation,
  initData,
}

// selectors
export const getState = (state) => {
  return state.get(MOUNT_POINT)
}

export const getStartPoint = (state) => {
  return getState(state).start
}

export const getEndPoint = (state) => {
  return getState(state).end
}

export const sortElements = (elements) => {
  return elements.sort((a, b) => {
    if (a.type === TRAVEL_ACCOMODATION) {
      a.getDate = a.getEndDate
    }

    if (b.type === TRAVEL_ACCOMODATION) {
      b.getDate = b.getEndDate
    }

    if (a.getDate() < b.getDate()) {
      return -1
    }

    if (a.getDate() > b.getDate()) {
      return 1
    }

    return 0
  })
}

export const getLowestElementDate = (state) => {
  const elements = getElements(state)

  if (!elements.length) {
    return getStartPoint(state).date.value
  }

  let noDrafts = elements.filter(
    (e) => !(e.draft || e.type === TRAVEL_EMPTY || e.type === TRAVEL_ACCOMODATION_SUGGESTION),
  )

  noDrafts = noDrafts.map((element) => {
    return ElementFactory.create(element)
  })

  noDrafts = sortElements(noDrafts)

  if (!noDrafts.length) {
    return getStartPoint(state).date.value
  }

  const element = noDrafts[0]
  return element.getStartDate()
}

export const getHighestElementDate = (state) => {
  const elements = getElements(state).reverse()

  if (!elements.length) {
    return getEndPoint(state).date.value
  }

  let noDrafts = elements.filter(
    (e) => !(e.draft || e.type === TRAVEL_EMPTY || e.type === TRAVEL_ACCOMODATION_SUGGESTION),
  )

  noDrafts = noDrafts.map((element) => {
    return ElementFactory.create(element)
  })

  noDrafts = sortElements(noDrafts).reverse()
  if (!noDrafts.length) {
    return getEndPoint(state).date.value
  }

  const element = ElementFactory.create(noDrafts[0])
  return element.getEndDate()
}

export const selectors = {
  getStartPoint,
  getEndPoint,
  getFirstFormStartDate: getLowestElementDate,
  getLastFormEndDate: getHighestElementDate,
}
