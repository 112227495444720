import trans from './trans'
import { includes, isUndefined, capitalize } from 'lodash'
import pluralize from 'pluralize'
import { Map } from 'immutable'

export const getDocumentPageBreadcrumbs = (match, getRouteByName, document) => {
  if (isUndefined(document.request)) {
    return []
  }

  const isSettlement = includes(
    [
      getRouteByName('main', 'settlementDocument', false),
      getRouteByName('main', 'settlementAccountDocument', false),
    ],
    match.path,
  )

  let breadcrumbs = [
    {
      label: trans('global.dashboard'),
      url: getRouteByName('main', 'dashboard'),
    },
  ]

  if (isSettlement) {
    breadcrumbs.push({
      label: trans('global.settlements'),
      url: getRouteByName('main', 'settlementRequestsList'),
    })
  }

  if (!isUndefined(match.params.type) && !isSettlement) {
    breadcrumbs.push({
      label: trans('global.' + pluralize.plural(match.params.type)),
      url: getRouteByName('main', match.params.type + 'RequestsList'),
    })
  }

  if (!isUndefined(match.params.type) && isSettlement) {
    breadcrumbs.push({
      label: trans('global.' + pluralize.plural(match.params.type)),
      url:
        getRouteByName('main', 'settlementRequestsList') +
        '?filters=' +
        encodeURIComponent('types=' + match.params.type) +
        '&preview',
    })
  }

  if (!isUndefined(match.params.request) && !isSettlement) {
    breadcrumbs.push({
      label: `${trans('request.' + document.request.state)} ${document.request.uid}`,
      url: getRouteByName('main', match.params.type + 'RequestShow', { id: document.request.slug }),
    })
  }

  if (!isUndefined(match.params.request) && isSettlement) {
    breadcrumbs.push({
      label: `${trans('request.' + document.request.state)} ${document.request.uid}`,
      url: getRouteByName('main', `show${capitalize(match.params.type)}Settlement`, {
        id: document.request.slug,
      }),
    })
  }

  if (Object.keys(match.params).length === 1) {
    breadcrumbs.push({
      label: trans('global.documents'),
      url: getRouteByName('main', 'allRequests'),
    })
  }

  if (!isUndefined(match.params.id)) {
    let name
    if (document.document_number) {
      name = document.document_number
      if (document.title) {
        name += ' - ' + document.title
      }
    } else {
      name = document.name
    }

    breadcrumbs.push({
      label: trans('global.document') + ' ' + name,
      url: match.url,
    })
  }

  return breadcrumbs
}

export const getMileageAllowancePageBreadcrumbs = (match, getRouteByName, request) => {
  const isSettlement =
    getRouteByName('main', 'settlementRequestMileageAllowance', false) === match.path
  const breadcrumbs = [
    {
      label: trans('global.dashboard'),
      url: getRouteByName('main', 'dashboard'),
    },
  ]

  if (isSettlement) {
    breadcrumbs.push({
      label: trans('global.settlements'),
      url: getRouteByName('main', 'settlementRequestsList'),
    })
  }

  if (!isUndefined(match.params.type) && !isSettlement) {
    breadcrumbs.push({
      label: trans('global.' + pluralize.plural(match.params.type)),
      url: getRouteByName('main', match.params.type + 'RequestsList'),
    })
  }

  if (!isUndefined(match.params.type) && isSettlement) {
    breadcrumbs.push({
      label: trans('global.' + pluralize.plural(match.params.type)),
      url:
        getRouteByName('main', 'settlementRequestsList') +
        '?filters=' +
        encodeURIComponent('types=' + match.params.type) +
        '&preview',
    })
  }

  if (!isUndefined(match.params.id) && !isSettlement) {
    breadcrumbs.push({
      label: `${trans('request.' + request.state)} ${request.uid}`,
      url: getRouteByName('main', match.params.type + 'RequestShow', { id: request.slug }),
    })
  }

  if (!isUndefined(match.params.id) && isSettlement) {
    breadcrumbs.push({
      label: `${trans('request.' + request.state)} ${request.uid}`,
      url: getRouteByName('main', `show${capitalize(match.params.type)}Settlement`, {
        id: request.slug,
      }),
    })
  }

  breadcrumbs.push({
    label: trans('request.mileage-allowance-header'),
    url: match.url,
  })

  return breadcrumbs
}
