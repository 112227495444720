import React from 'react'
import Button from '../../ui/ButtonComponent'
import Icon from '../../ui/IconComponent'
import { StatementStatus } from '../../../types/statements'
import trans from '../../../trans'
import { ICellRendererParams } from 'ag-grid-community'

export interface StatementStatusRendererProps extends ICellRendererParams {
  value: StatementStatus
}

export default function StatementStatusRenderer({ value, ...rest }: StatementStatusRendererProps) {
  const icon = value ? 'checked_fill-square' : 'warning'
  const label = value ? 'statements.statements_status_closed' : 'statements.statements_status_open'

  return (
    <div className={'statement-status'}>
      <Icon xlg type={icon} />

      {trans(label)}
    </div>
  )
}
