import React from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { fetchTableConfigs, getTableConfigs, isLoading, reset } from './index'

const tableConfigs =
  (resetOnMount = false, fetchOnMount = false) =>
  (Component) => {
    class TableConfigs extends React.Component<any, any> {
      componentDidMount() {
        const { tableConfigs } = this.props

        if (resetOnMount) {
          tableConfigs.actions.reset()
        }

        if (fetchOnMount) {
          tableConfigs.actions.fetch()
        }
      }

      render() {
        return <Component<any, any> {...this.props} />
      }
    }

    const mapStateToProps = (state) => ({
      tableConfigs: getTableConfigs(state),
      isLoading: isLoading(state),
    })

    const mapDispatchToProps = (dispatch) => {
      return bindActionCreators(
        {
          reset,
          fetch: fetchTableConfigs,
        },
        dispatch,
      )
    }

    const mergedProps = (selectors, actions, own) => {
      return {
        ...own,
        tableConfigs: {
          selectors,
          actions,
          ...own.tableConfigs,
        },
      }
    }

    return connect(mapStateToProps, mapDispatchToProps, mergedProps)(TableConfigs)
  }

export default tableConfigs
export { tableConfigs }
