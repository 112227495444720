import APIClient from '../../../services/APIClient'
import { createModule } from '../../modules/list/index'
import moment from 'moment'
import { config } from '../../../config'

const fetchMethod = ({ filters }, source) => {
  return APIClient.getInstallments(filters, source)
}

const module = createModule({
  namespace: 'installments-list',
  mountPoint: 'installments-list',
  paginationByAPI: true,
  fetchMethod,
})

const { reducer, MOUNT_POINT, selectors } = module

const markAsPaid = (installment) => (dispatch) => {
  return APIClient.setInstallmentStatus(installment, 'paid').then((response) => {
    dispatch(module.actions.updateItem(response.data))
    return response
  })
}

const markAsAccounted = (installment) => (dispatch) => {
  return APIClient.setInstallmentAccountingStatus(installment, true).then((response) => {
    dispatch(module.actions.updateItem(response.data))
    return response
  })
}

const changeDate = (installment, newDate) => (dispatch) => {
  const date = moment(newDate).isValid() ? moment(newDate).format(config.apiDateFormat) : null
  return APIClient.setInstallmentPaidDate(installment, date).then((response) => {
    dispatch(module.actions.updateItem(response.data))
    return response
  })
}

const changeAccountingDate = (installment, newDate) => (dispatch) => {
  const date = moment(newDate).isValid() ? moment(newDate).format(config.apiDateFormat) : null
  return APIClient.setInstallmentAccountingDate(installment, date).then((response) => {
    dispatch(module.actions.updateItem(response.data))
    return response
  })
}

const changeExchangeRate = (installment, exchangeRate) => (dispatch) => {
  return APIClient.setInstallmentExchangeRate(installment, exchangeRate).then((response) => {
    dispatch(module.actions.updateItem(response.data))
    return response
  })
}

const changeAmount = (installment, amount, currency) => (dispatch) => {
  return APIClient.setInstallmentCurrency(installment, amount, currency).then((response) => {
    dispatch(module.actions.updateItem(response.data))
    return response
  })
}

const changeErpId = (installment, id) => (dispatch) => {
  return APIClient.setInstallmentErpId(installment, id).then((response) => {
    dispatch(module.actions.updateItem(response.data))
    return response
  })
}

const actions = {
  ...module.actions,
  markAsPaid,
  markAsAccounted,
  changeDate,
  changeAccountingDate,
  changeExchangeRate,
  changeAmount,
  changeErpId,
}

export { reducer, MOUNT_POINT, selectors, actions }
