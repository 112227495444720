import React from 'react'
import PropTypes from 'prop-types'
import { get } from 'lodash'
import { trans } from '../../../trans'

const Changes = (props) => {
  const { offer } = props

  const connectingFlightsNumber = get(offer, 'attributes.connectingFlightsNumber', 0)

  if (!connectingFlightsNumber) {
    return trans('flights-booking.directly')
  }

  const localize = (number) => {
    switch (number) {
      case 1:
        return 'flights-booking.change'
      case 2:
      case 3:
      case 4:
        return 'flights-booking.changes-loc'
      default:
        return 'flights-booking.changes'
    }
  }

  return `${connectingFlightsNumber} ${trans(localize(connectingFlightsNumber))}`
}

Changes.propTypes = {
  offer: PropTypes.object.isRequired,
}

export default Changes
export { Changes }
