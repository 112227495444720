import React from 'react'
import classnames from 'classnames'

class THead extends React.Component<any, any> {
  render() {
    const { children, noBorder } = this.props
    const classes = classnames({
      'table-accordion__head': true,
      'table-accordion__head--noborder': noBorder,
    })

    return <div className={classes}>{children}</div>
  }
}

export { THead }
export default THead
