import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Expense from './Expense'
import TripStateRow from './TripStateRow'
import TripPeriodChange from './TripPeriodChange'
import {
  TYPE_BORDER_CROSSING,
  TYPE_EXPENSE,
  TYPE_TRAVEL_EXPENSE_PERIOD_CHANGE,
  TYPE_TRIP_END,
  TYPE_TRIP_START,
} from '../../constants'
import { get } from 'lodash'

class Rows extends Component<any, any> {
  renderRow = (row, key) => {
    const { form: Form, change, validate } = this.props
    switch (row.type) {
      case TYPE_EXPENSE:
        return (
          <Expense
            key={key}
            date={row.date}
            form={() => (
              <Form
                values={row.values}
                disabled={row.disabled}
                change={(field, value) => change(row.id, field, value)}
                validate={() => validate(row.id)}
                userCanObtainAccommodation={row.userCanObtainAccommodation}
              />
            )}
            period={row.period}
          />
        )
      case TYPE_TRAVEL_EXPENSE_PERIOD_CHANGE:
        return (
          <TripPeriodChange
            key={key}
            localTime={row.localTime}
            timezone={row.timeZoneAbbreviation}
            className={{ first: key === 0 }}
            diff={row.diff}
          />
        )
      case TYPE_TRIP_END:
      case TYPE_TRIP_START:
      case TYPE_BORDER_CROSSING:
        return (
          <TripStateRow
            key={key}
            type={row.type}
            time={row.instanceTime}
            localTime={row.localTime}
            timezone={row.timeZoneAbbreviation}
            diff={row.diff}
            countryCode={row.countryCode}
            countryName={row.countryName}
            period={row.period}
            className={{ first: key === 0 }}
            target={get(row, 'values.target', false)}
          />
        )
      default:
        return null
    }
  }

  render() {
    return this.props.rows.map(this.renderRow)
  }
}

Rows.propTypes = {
  rows: PropTypes.array.isRequired,
  form: PropTypes.func.isRequired,
}

export default Rows
export { Rows }
