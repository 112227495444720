// initial state
import APIClient from '../../../services/APIClient'

const getInitialState = () => {
  return {
    isLoaded: false,
    isLoading: false,
    error: false,
    items: [],
    groupSums: {},
    suggestedElement: {},
  }
}

// constants
export const RESET_DOCUMENT_ELEMENT_GROUPS = 'document-element-groups::reset'
export const SET_DOCUMENT_ELEMENT_GROUPS = 'document-element-groups::set-documents'
export const START_LOADING = 'document-element-groups::start-loading'
export const LOADING_ERROR = 'document-element-groups::loading-error'
export const CHANGE_ELEMENT_OF_TYPE = 'document-element-groups::change-element-of-type'
export const ADD_ELEMENT_OF_TYPE = 'document-element-groups::add-element-of-type'
export const DELETE_ELEMENT_OF_TYPE = 'document-element-groups::delete-element-of-type'
export const CHANGE_AMOUNT_OF_ELEMENT = 'document-element-groups::change-amount-of-element'
export const SET_GROUP_SUMS = 'document-element-groups::set-group-sums'

export const MOUNT_POINT = 'document-element-groups'

// reducer
export const reducer = (state = getInitialState(), action) => {
  switch (action.type) {
    case RESET_DOCUMENT_ELEMENT_GROUPS:
      return getInitialState()
    case SET_DOCUMENT_ELEMENT_GROUPS:
      return {
        ...state,
        items: action.payload,
        isLoading: false,
        isLoaded: true,
        error: false,
      }
    case START_LOADING:
      return {
        ...state,
        isLoading: true,
        error: false,
      }
    case LOADING_ERROR:
      return {
        ...state,
        isLoaded: false,
        isLoading: false,
        error: true,
      }
    case CHANGE_ELEMENT_OF_TYPE: {
      const { element, type, data } = action.payload

      return {
        ...state,
        items: state.items.map((_section) => {
          return {
            ..._section,
            types: _section.types.map((_type) => {
              if (_type['id'] === type['id']) {
                if (_type['element']) {
                  return {
                    ..._type,
                    element: {
                      ..._type['element'],
                      ...data,
                    },
                  }
                }
              }

              return _type
            }),
          }
        }),
      }
    }
    case ADD_ELEMENT_OF_TYPE: {
      const { type, data } = action.payload

      return {
        ...state,
        items: state.items.map((_section) => {
          return {
            ..._section,
            types: _section.types.map((_type) => {
              if (_type['id'] === type['id']) {
                if (!_type['element']) {
                  return {
                    ..._type,
                    element: {
                      ...data,
                    },
                  }
                }
              }

              return _type
            }),
          }
        }),
      }
    }
    case DELETE_ELEMENT_OF_TYPE: {
      const { element } = action.payload

      return {
        ...state,
        items: state.items.map((_section) => {
          return {
            ..._section,
            types: _section.types.map((_type) => {
              if (_type['element'] && _type['element']['id'] === element['id']) {
                return {
                  ..._type,
                  element: null,
                }
              }

              return _type
            }),
          }
        }),
      }
    }
    case SET_GROUP_SUMS:
      return {
        ...state,
        groupSums: action.payload,
      }
    default:
      return state
  }
}

// helpers
const prepareElementData = (data) => {
  if (data['gross'].length === 0) {
    data['gross'] = 0
  }

  return data
}

const calculateSums = (groups) => (dispatch) => {
  const sums = {}
  groups.forEach((group) => {
    group['types'].forEach((type) => {
      if (type['element']) {
        if (!sums[group['id']]) {
          sums[group['id']] = 0
        }
        sums[group['id']] += parseFloat(type['element']['gross'])
      }
    })
  })

  dispatch(setGroupSums(sums))
}

export const calculateSumsWithType = (_element, _type, _gross) => (dispatch, getState) => {
  if (!_element) {
    return false
  }

  const groups = getDocumentElementGroups(getState())
  const sums = {}
  groups.forEach((group) => {
    group['types'].forEach((type) => {
      if (!sums[group['id']]) {
        sums[group['id']] = 0
      }
      if (type['id'] === _type['id']) {
        sums[group['id']] += _gross
        dispatch({
          type: CHANGE_ELEMENT_OF_TYPE,
          payload: {
            element: _element,
            type: _type,
            data: {
              gross: _gross,
            },
          },
        })
      } else {
        if (type['element']) {
          sums[group['id']] += parseFloat(type['element']['gross'])
        }
      }
    })
  })
  dispatch(setGroupSums(sums))
}

// actions
export const reset = () => (dispatch) => {
  dispatch({
    type: RESET_DOCUMENT_ELEMENT_GROUPS,
  })
}

export const setDocumentElementGroups = (groups) => (dispatch) => {
  dispatch({
    type: SET_DOCUMENT_ELEMENT_GROUPS,
    payload: groups,
  })
  dispatch(calculateSums(groups))
}

export const setGroupSums = (sums) => (dispatch) => {
  dispatch({
    type: SET_GROUP_SUMS,
    payload: sums,
  })
}

export const changeElementOfType = (document, element, type, data) => (dispatch, getState) => {
  data = prepareElementData(data)
  return new Promise((resolve) => {
    APIClient.saveDocumentElementType(document, element, type, data).then(() => {
      dispatch({
        type: CHANGE_ELEMENT_OF_TYPE,
        payload: {
          element,
          type,
          data,
        },
      })
      dispatch(calculateSums(getDocumentElementGroups(getState())))
      resolve()
    })
  })
}

export const addElementOfType = (document, element, type, data) => (dispatch, getState) => {
  data = prepareElementData(data)

  return new Promise((resolve) => {
    APIClient.addDocumentElementType(document, element, type, data).then((response) => {
      dispatch({
        type: ADD_ELEMENT_OF_TYPE,
        payload: {
          element,
          type,
          data: response.data,
        },
      })
      dispatch(calculateSums(getDocumentElementGroups(getState())))
      resolve()
    })
  })
}

export const deleteElementOfType = (document, element) => (dispatch, getState) => {
  return new Promise((resolve) => {
    APIClient.deleteDocumentElementType(document, element).then(() => {
      dispatch({
        type: DELETE_ELEMENT_OF_TYPE,
        payload: {
          element,
        },
      })
      dispatch(calculateSums(getDocumentElementGroups(getState())))
      resolve()
    })
  })
}

export const changeAmountOfElementSuggested =
  (gross, suggestedElement, document) => (dispatch, getState) => {
    return new Promise((resolve) => {
      const groups = getDocumentElementGroups(getState())
      let elementExists = null
      let type = null
      let element = null

      groups.forEach((group) => {
        group['types'].forEach((_type) => {
          if (_type['slug'] === suggestedElement['slug']) {
            elementExists = _type['element'] === null ? false : true
            type = _type
            element = _type['element']
          }
        })
      })

      if (elementExists !== null) {
        if (elementExists) {
          if (Number.parseFloat(gross) > 0) {
            dispatch(
              changeElementOfType(document, element, type, {
                gross,
              }),
            ).then(() => resolve())
          } else {
            dispatch(deleteElementOfType(document, element)).then(() => resolve())
          }
        } else {
          dispatch(
            addElementOfType(document, element, type, {
              gross,
              type_id: type['id'],
            }),
          ).then(() => resolve())
        }
      } else {
        resolve()
      }
    })
  }

export const loadingError = () => (dispatch) => dispatch({ type: LOADING_ERROR })

export const fetchDocumentElementGroups = (document) => (dispatch) => {
  dispatch({ type: START_LOADING })

  APIClient.getDocumentElementGroups(document)
    .then((response) => {
      dispatch(setDocumentElementGroups(response.data))
    })
    .catch(() => {
      dispatch(loadingError())
    })
}

// selectors
export const getState = (state) => {
  return state.get(MOUNT_POINT)
}

export const getDocumentElementGroups = (state) => {
  return getState(state).items
}

export const getIsLoading = (state) => getState(state).isLoading

export const getIsLoaded = (state) => getState(state).isLoaded

export const getGroupSums = (state) => getState(state).groupSums

export const getRemainingSum = (state) => {
  const groupSums = Object.values(getGroupSums(state))

  const shouldCalculate = groupSums.filter((value) => {
    return value > 0
  })

  let total = 0
  if (shouldCalculate.length) {
    total = groupSums.reduce((acc, current) => {
      return acc + Number.parseFloat(current)
    }, 0)
  }

  return total
}
