import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Row } from '../../../ui/index'
import { TravelPart } from './TravelPart'
import { FormWrapper } from './FormWrapper'
import trans from '../../../trans'

export class Travel extends Component<any, any> {
  constructor(props) {
    super(props)
    const { from, to, confirmed } = this.props

    this.state = { formToggle: !confirmed || (from['isNew'] && to['isNew']) }
  }

  toggle() {
    const { formToggle } = this.state
    this.setState({ formToggle: !formToggle })
  }

  render() {
    const {
      from,
      to,
      setAccessLumpSumPair,
      deleteAccessLumpSums,
      fromIndex,
      toIndex,
      setStoreForAccessLumpSumsPair,
      isReadOnly,
    } = this.props
    const { formToggle } = this.state

    return (
      <li className='expense-commute__travel-list__travel'>
        {formToggle ? (
          <FormWrapper
            from={from}
            to={to}
            fromIndex={fromIndex}
            toIndex={toIndex}
            setAccessLumpSumPair={setAccessLumpSumPair}
            setStoreForAccessLumpSumsPair={setStoreForAccessLumpSumsPair}
            deleteAccessLumpSums={deleteAccessLumpSums}
            toggle={this.toggle.bind(this)}
          />
        ) : (
          <div className='expense-commute__travel-list__travel__columns'>
            <Row>
              <TravelPart
                accessLumpSum={to}
                direction='to'
                secondAccessLumpSum={from}
                setAccessLumpSumPair={setAccessLumpSumPair}
              />
              <TravelPart
                accessLumpSum={from}
                direction='from'
                secondAccessLumpSum={to}
                toggle={this.toggle.bind(this)}
                setAccessLumpSumPair={setAccessLumpSumPair}
                deleteAccessLumpSums={deleteAccessLumpSums}
                fromIndex={fromIndex}
                toIndex={toIndex}
                isReadOnly={isReadOnly}
              />
            </Row>
          </div>
        )}
      </li>
    )
  }
}

Travel.propTypes = {
  from: PropTypes.object.isRequired,
  to: PropTypes.object.isRequired,
  setAccessLumpSumPair: PropTypes.func.isRequired,
  deleteAccessLumpSums: PropTypes.func.isRequired,
  fromIndex: PropTypes.number.isRequired,
  toIndex: PropTypes.number.isRequired,
  setStoreForAccessLumpSumsPair: PropTypes.func.isRequired,
  isReadOnly: PropTypes.bool,
}
