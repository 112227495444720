import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import { actions, selectors } from '../store/app/instance-users'
import { bindActionCreators } from 'redux'
import APIClient from '../services/APIClient'
import { getCurrentUser } from '../store/app/user-profile/userProfileSlice'
import { Grade } from '../types/grade'

interface InstanceUsersManagerProps {
  grades: Grade[]
  [key: string]: any
}

class InstanceUsersManagerComponent extends React.Component<any, InstanceUsersManagerProps> {
  constructor(props) {
    super(props)

    this.state = {
      companies: [],
      groups: [],
      MPKs: [],
      grades: [],
    }
  }

  componentDidMount() {
    const { reset, fetchItems, listenQueryChange } = this.props
    reset()
    fetchItems()
    listenQueryChange()

    const dependencies = []

    dependencies.push(APIClient.getCompanies())
    dependencies.push(APIClient.getGroups())
    dependencies.push(APIClient.getMPKs())
    dependencies.push(APIClient.getGrades())

    Promise.all(dependencies).then((responses) => {
      this.setState({
        companies: responses[0].data,
        groups: responses[1].data,
        MPKs: responses[2].data,
        grades: responses[3].data,
      })
    })
  }

  componentWillUnmount() {
    const { unlistenQueryChange } = this.props

    unlistenQueryChange()
  }

  render() {
    const {
      children,
      users,
      isLoading,
      isLoaded,
      filters,
      changeFilter,
      sorter,
      changeSorter,
      pagination,
      changePage,
      selectedItems,
      selectDeselectOne,
      selectDeselectAll,
      selectDeselectMany,
      isItemSelected,
      requestCardForSelectedUsers,
      currentUser,
    } = this.props

    const { companies, groups, MPKs, grades } = this.state

    const renderProps = {
      users,
      companies,
      groups,
      MPKs,
      grades,
      selectedItems,
      selectDeselectOne,
      selectDeselectAll,
      selectDeselectMany,
      isItemSelected,
      currentUser,
      requestsFilters: filters,
      changeRequestsFilter: changeFilter,
      sorter: sorter,
      changeSorter: changeSorter,
      areUsersLoading: isLoading,
      areUsersLoaded: isLoaded,
      requestsPagination: pagination,
      changeRequestsPage: changePage,
      requestCardForSelectedUsers,
    }

    return children(renderProps)
  }
}

InstanceUsersManagerComponent.propTypes = {
  children: PropTypes.func.isRequired,
  users: PropTypes.array.isRequired,
  isLoaded: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
  filters: PropTypes.object.isRequired,
  changeFilter: PropTypes.func.isRequired,
  changeSorter: PropTypes.func.isRequired,
  sorter: PropTypes.object.isRequired,
  reset: PropTypes.func.isRequired,
  pagination: PropTypes.object.isRequired,
}

const {
  reset,
  fetchItems,
  changeFilter,
  changeSorter,
  selectDeselectOne,
  selectDeselectAll,
  selectDeselectMany,
  changePage,
  listenQueryChange,
  unlistenQueryChange,
  requestCardForSelectedUsers,
} = actions

const {
  getIsLoading,
  getIsLoaded,
  getFilters,
  getSorter,
  isItemSelected,
  getSelectedItems,
  getPagination,
  getItemsPaginated,
} = selectors

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      reset,
      fetchItems,
      changeFilter,
      changeSorter,
      selectDeselectOne,
      selectDeselectAll,
      selectDeselectMany,
      changePage,
      listenQueryChange,
      unlistenQueryChange,
      requestCardForSelectedUsers,
    },
    dispatch,
  )
}

const mapStateToProps = (state) => ({
  users: getItemsPaginated(state),
  isLoaded: getIsLoaded(state),
  isLoading: getIsLoading(state),
  filters: getFilters(state),
  sorter: getSorter(state),
  isItemSelected: isItemSelected(state),
  selectedItems: getSelectedItems(state),
  pagination: getPagination(state),
  currentUser: getCurrentUser(state),
})

const InstanceUsersManager = connect(
  mapStateToProps,
  mapDispatchToProps,
)(InstanceUsersManagerComponent)

export default InstanceUsersManager
