import PropTypes from 'prop-types'
import React from 'react'
import trans from '../../../trans'
import { TripRequestSummary } from '../TripRequestSummary'
import { Ability } from '../../RequestPageCommon/Ability/Ability'
import { RequestHeader } from '../../RequestPageCommon/RequestHeader'
import { Link, withRouter } from 'react-router-dom'
import { TripRequestLumpSum } from '../TripRequestLumpSum'
import { TripPlanDocuments } from '../TripPlanDocuments/TripPlanDocuments'
import { TripRequestCostsDocuments } from '../TripRequestCostsDocuments'
import { TripRequestUnassignedDocuments } from '../TripRequestUnassignedDocuments'
import Button from '../../ui/ButtonComponent'
import { RouteManager } from '../../../containers/RouteManager'
import { RequestComments } from '../../RequestPageCommon/RequestComments'
import { TimelineElementsCompliance } from '../TimelineElementsCompliance'
import RequestCommentManager from '../../../containers/RequestCommentManager/RequestCommentManager'
import BasicRequestInfoRenderer from '../../RequestBasicInfoRenderer/RequestBasicInfoRenderer'

class TripRequestPageSettlementComponent extends React.Component<any, any> {
  render() {
    const { request, instanceCurrency, fetchRequest, cancelRequest } = this.props

    return (
      <RouteManager>
        {({ push, getRouteByName }) => (
          <div>
            <RequestHeader request={request} />

            <BasicRequestInfoRenderer />

            <TripPlanDocuments request={request} instanceCurrency={instanceCurrency} />

            <TripRequestCostsDocuments
              request={request}
              instanceCurrency={instanceCurrency}
              fetchRequest={fetchRequest}
            />

            <TripRequestUnassignedDocuments request={request} fetchRequest={fetchRequest} />

            <Ability abilities={request.abilities} ability={['showTripRequestLumpSum']}>
              <TripRequestLumpSum request={request} instanceCurrency={instanceCurrency} />
            </Ability>

            <TripRequestSummary request={request} />

            <TimelineElementsCompliance />

            <div className='request-button-group'>
              <div style={{ display: 'flex' }}>
                <Ability ability={['cancel']}>
                  <Button
                    onClick={(e) => {
                      e.preventDefault()
                      cancelRequest().then(() => {
                        this.props.history.goBack()
                      })
                    }}
                    link
                  >
                    {trans('ui.cancel-request')}
                  </Button>
                </Ability>
              </div>

              <Ability ability={['viewSettlementSummary']}>
                <Link
                  className='btn btn--primary'
                  to={`/requests/${request.type}/${request.slug}/summary`}
                >
                  {trans('global.go-to-settlement-summary')}
                </Link>
              </Ability>
            </div>

            <RequestCommentManager request={request}>
              {({ comments }) => {
                return <RequestComments comments={comments} />
              }}
            </RequestCommentManager>
          </div>
        )}
      </RouteManager>
    )
  }
}

TripRequestPageSettlementComponent.propTypes = {
  request: PropTypes.object.isRequired,
  instanceCurrency: PropTypes.string.isRequired,
  sendToAcceptanceOfSettlement: PropTypes.func.isRequired,
  changeRequestValue: PropTypes.func.isRequired,
  cancelRequest: PropTypes.func.isRequired,
}

export const TripRequestPageSettlement = withRouter(TripRequestPageSettlementComponent)
export default { TripRequestPageSettlement }
