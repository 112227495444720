import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators, compose } from 'redux'
import PropTypes from 'prop-types'
import { fetchComments } from '../../store/app/trip-request'
import socketProvider from '../SocketProvider'
import { debounce } from 'lodash'

class RequestCommentManager extends React.Component<any, any> {
  componentDidMount() {
    const {
      request: { slug },
    } = this.props

    this.channelName = `App.Request.${slug}`
    this.eventName = '.App\\Events\\RequestCommentCreated'

    this.subscribe()
  }

  componentWillUnmount() {
    this.unsubscribe()
  }

  subscribe() {
    const { socket, fetchComments } = this.props
    const debouncedFetchComments = debounce(() => fetchComments(), 500)

    socket.subscribe(this.channelName)(this.eventName)(debouncedFetchComments)
  }

  unsubscribe() {
    const { socket } = this.props

    socket.unsubscribe(this.channelName)(this.eventName)
  }

  render() {
    const { children, comments } = this.props

    const renderProps = {
      comments,
    }

    return children(renderProps)
  }
}

RequestCommentManager.propTypes = {
  children: PropTypes.func,
  request: PropTypes.object.isRequired,
}

const mapDispatchToProps = (dispatch, props) => {
  const { request } = props

  return bindActionCreators(
    {
      fetchComments: () => fetchComments(request.slug),
    },
    dispatch,
  )
}

const mapStateToProps = (state, props) => {
  const { request } = props

  return {
    comments: request.comments,
  }
}

const withConnect = connect(mapStateToProps, mapDispatchToProps)
const withSocket = socketProvider()

RequestCommentManager = compose(withConnect, withSocket)(RequestCommentManager)

export { RequestCommentManager }
export default RequestCommentManager
