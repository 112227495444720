import React from 'react'
import MUIDialog from '@material-ui/core/Dialog'
import Paper from '@material-ui/core/Paper'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Slide from '@material-ui/core/Slide'
import Icon from '../ui/IconComponent'
import Button from '../ui/ButtonComponent'

function Transition(props) {
  return <Slide direction='up' {...props} />
}

export const Dialog = (props) => (
  <MUIDialog
    open={props.open}
    TransitionComponent={props.transition ? Transition : undefined}
    keepMounted
    onClose={props.onClose}
    aria-labelledby='alert-dialog-slide-title'
    aria-describedby='alert-dialog-slide-description'
    className='last-requests__dialog'
    PaperProps={{
      className: 'last-requests__dialog-paper',
      square: true,
    }}
  >
    <DialogTitle id='alert-dialog-slide-title'>{props.title}</DialogTitle>

    <DialogContent className='last-requests__dialog-content'>
      <Icon className='last-requests__dialog-close' type='close' onClick={props.onClose} />
      {props.children}
    </DialogContent>

    <DialogActions className='last-requests__dialog-actions'>
      {props.actionButtons &&
        props.actionButtons.map(({ title, ...buttonProps }) => (
          <Button primary xxs color='primary' {...buttonProps}>
            {title}
          </Button>
        ))}
      {props.actions}
    </DialogActions>
  </MUIDialog>
)
