import { get } from 'lodash'

export const filter = (filters, offers) => {
  const breakfast = get(filters, 'breakfast')
  const standard = get(filters, 'standard')
  const wifi = get(filters, 'wifi')
  const parking = get(filters, 'parking')
  const others = get(filters, 'others')

  return offers.filter((offer) => {
    if (breakfast && get(offer, 'hasBreakfast', false) !== breakfast) {
      return false
    }

    if (standard && get(offer, 'attributes.stars', 0) !== standard) {
      return false
    }

    if (parking) {
      const hasParking =
        get(offer, 'attributes.facilities.facilities', []).indexOf('parking') !== -1

      if (!hasParking) {
        return false
      }
    }

    if (wifi) {
      const hasWifi = get(offer, 'attributes.facilities.facilities', []).indexOf('wifi') !== -1

      if (!hasWifi) {
        return false
      }
    }

    if (others && others.length > 2) {
      let match = false
      const value = others
      const { attributes } = offer
      const searchBy = ['address', 'description', 'name']
      const regex = new RegExp(value, 'i')
      searchBy.forEach((field) => {
        if (match) {
          return
        }
        match = regex.test(attributes[field])
      })

      return match
    }

    return true
  })
}
