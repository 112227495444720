import store from './store'
import { template, isUndefined } from 'lodash'
import { getTranslations } from './store/app/instance'
import parse from 'html-react-parser'

const templateOptions = {
  interpolate: new RegExp(':(\\w+)'),
}

export const trans = (key, args = {}) => {
  const translations = getTranslations(store.getState())

  if (isUndefined(translations[key])) {
    return key
  }

  const compiled = template(translations[key], templateOptions)

  try {
    return parse(compiled(args))
  } catch (e) {
    return key
  }
}

export default trans
