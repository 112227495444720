import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import { SelectField } from '../../ui/Form'
import { trans } from '../../../trans'
import { get, clone } from 'lodash'

class SeatTypeField extends Component<any, any> {
  constructor(props) {
    super(props)
    this.state = {
      options: [],
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const { offer } = nextProps
    const option = offer.option
    const options = clone(get(option, 'availableOptions.seat_type', []))

    if (options.length) {
      options.push('ANY')
    }

    return {
      options,
    }
  }

  getOptions = () => {
    return this.state.options.map((option) => {
      return {
        label: trans('trains-booking.seat-type-' + option),
        value: option,
      }
    })
  }

  renderValue = () => {
    const { input } = this.props
    return trans('trains-booking.seat-type-' + input.value)
  }

  renderLabel = () => {
    const { input } = this.props

    let label = trans('trains-booking.seat-type-reservation')

    if (input.value === 'NEXTTO') {
      label = trans('trains-booking.seat-type-nextto-reservation')
    }

    if (input.value === 'SPECIFIED') {
      label = trans('trains-booking.seat-type-nextto-reservation')
    }

    return label
  }

  render() {
    const { input, disabled } = this.props

    const options = this.getOptions()
    if (!options.length) {
      return null
    }

    if (disabled && !input.value) {
      return null
    }

    return (
      <Fragment>
        <span className='train-trip__selected-ticket-travel-detail-title'>
          {trans('trains-booking.seat-type-reservation')}
        </span>

        {disabled && this.renderValue()}

        {!disabled && (
          <div className='train-trip__selected-ticket-place-prefered-select'>
            <SelectField
              options={options}
              value={input.value}
              placeholder={trans('trains-booking.select-seat-type')}
              onChange={(value) => input.onChange(value)}
              disabled={false}
            />
          </div>
        )}
      </Fragment>
    )
  }
}

SeatTypeField.propTypes = {
  offer: PropTypes.object.isRequired,
}

SeatTypeField.defaultProps = {
  disabled: false,
}

export default SeatTypeField
export { SeatTypeField }
