import React from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { Cell, Row } from '../../../ui/AccordionTable'
import trans from '../../../../trans'
import KpiListItem from './KpiListItem'
import KpiListFormItem from './KpiListFormItem'
import {
  fetchKpiList,
  getKpiTotalCount,
  getPage,
  getPaginatedKpi,
  isLoading,
  setPage,
} from '../../../../store/app/kpis'
import { CompanyBaseList } from '../../CompanyBaseList'

class KpiList extends CompanyBaseList {
  canCreate = false

  getIdentifierKey() {
    return 'slug'
  }

  renderTableHeader() {
    return (
      <Row>
        <Cell fixedWidth='auto'>{trans('accounting.kpi-name')}</Cell>
        <Cell fixedWidth={150} alignRight>
          {trans('accounting.kpi-value')}
        </Cell>
        <Cell fixedWidth={100}>{trans('accounting.kpi-unit-label')}</Cell>
        <Cell fixedWidth={180} alignRight />
      </Row>
    )
  }

  getFormComponent() {
    return KpiListFormItem
  }

  getListItemComponent() {
    return KpiListItem
  }
}

const mapDispatchToProps = (dispatch, state) => {
  return bindActionCreators(
    {
      setPage,
      fetch: fetchKpiList,
    },
    dispatch,
  )
}

const mapStateToProps = (state) => {
  return {
    isLoading: isLoading(state),
    items: getPaginatedKpi(state),
    total: getKpiTotalCount(state),
    page: getPage(state),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(KpiList)
