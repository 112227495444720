import { get } from 'lodash'
import { trans } from '../../../trans'
import Button from '../../ui/ButtonComponent'
import { connect } from '../../../containers/FlightsBooking'
import React from 'react'
import { SingleOffer } from '../SingleOffer'
import moment from 'moment/moment'
import Warning from '../Offer/Warning'
import Agreement from './Agreement'
import NoticeLoader from '../../ui/LoadingOverlay/NoticeLoader'
import ReservationStatus from './ReservationStatus'
import {
  BOOKING_CANCELLED,
  BOOKING_EXPIRED,
  BOOKING_FAILED,
  BOOKING_OFFER_CHANGED,
} from '../../../store/app/flights-booking'
import { Tooltip } from '../../Tooltip'
import { Ability } from '../../RequestPageCommon/Ability/Ability'

class SelectedOfferComponent extends React.Component<any, any> {
  componentDidMount() {
    this.listenReservationStatus()
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      this.props.flights.selectors.isBookingProcessWaiting !==
      prevProps.flights.selectors.isBookingProcessWaiting
    ) {
      this.listenReservationStatus()
    }
  }

  componentWillUnmount() {
    const {
      flights: {
        actions: { unsubscribeReservationStatus },
      },
    } = this.props

    unsubscribeReservationStatus()
  }

  renderSearchAnother = () => {
    const {
      flights: {
        actions: { searchAnotherOne },
      },
    } = this.props

    return (
      <Button
        outline
        className='plane-offer__button plane-offer__button--another'
        onClick={searchAnotherOne}
      >
        {trans('flights-booking.select-another-one')}
      </Button>
    )
  }

  onBook() {
    const {
      flights: {
        selectors: { isAgreement },
        actions: { bookOffer },
      },
    } = this.props
  }

  renderBookButton = () => {
    const {
      flights: {
        selectors: { isAgreement },
        actions: { bookOffer },
      },
    } = this.props

    const button = (
      <Button
        type='submit'
        primary
        className='plane-offer__button plane-offer__button--save'
        disabled={!isAgreement}
        onClick={bookOffer}
      >
        {trans('flights-booking.book')}
      </Button>
    )

    if (!isAgreement) {
      return (
        <Tooltip html={<span>{trans('global.booking-agreement-tooltip')}</span>}>{button}</Tooltip>
      )
    }

    return button
  }

  isSearchAnotherAllowed = (offer) => {
    const bookingStatus = get(offer, 'options.0.booking', null)
    return (
      bookingStatus === null ||
      bookingStatus === BOOKING_OFFER_CHANGED ||
      bookingStatus === BOOKING_EXPIRED ||
      bookingStatus === BOOKING_FAILED
    )
  }

  isBookAllowed = (offer) => {
    const bookingStatus = get(offer, 'options.0.booking', null)
    return bookingStatus === null || bookingStatus === BOOKING_OFFER_CHANGED
  }

  isBookingFailed = (offer) => {
    const bookingStatus = get(offer, 'options.0.booking', null)
    return (
      bookingStatus === BOOKING_FAILED ||
      bookingStatus === BOOKING_EXPIRED ||
      bookingStatus === BOOKING_CANCELLED
    )
  }

  renderReservationNotice = () => {
    return (
      <div className='plane-offer__reservation-notice'>
        <NoticeLoader
          loaderText={trans('flights-booking.reservation-waiting-loader')}
          description={trans('flights-booking.reservation-waiting-notice')}
        />
      </div>
    )
  }

  listenReservationStatus() {
    const {
      flights: {
        selectors: { isBookingProcessWaiting },
        actions: { subscribeReservationStatus, unsubscribeReservationStatus },
      },
    } = this.props

    if (isBookingProcessWaiting) {
      subscribeReservationStatus()
    } else {
      unsubscribeReservationStatus()
    }
  }

  render() {
    const {
      flights: {
        selectors: {
          selectedThere,
          selectedBack,
          query,
          rules: flightRules,
          isBlocked,
          isBookingProcessWaiting,
          totalPaxes,
          travelers,
        },
      },
      context: {
        request: { abilities },
      },
      isReturn,
    } = this.props

    const amountOfferReference = selectedBack ? selectedBack : selectedThere

    const thereDate = moment(get(selectedThere, 'attributes.departureDateTime', null)).format(
      'ddd. D MMM.',
    )
    const backDate = moment(get(selectedBack, 'attributes.departureDateTime', null)).format(
      'ddd. D MMM.',
    )
    const flightClass = get(query, 'flight_class', null)
    const amount = get(
      amountOfferReference,
      'options.0.amount.amount_rounded',
      get(amountOfferReference, 'amount.formatted', '0,00 PLN'),
    ).toLowerCase()
    const fee = get(
      amountOfferReference,
      'options.0.reservation_fee.formatted',
      '0,00 PLN',
    ).toLowerCase()
    const hasBrokenRules = get(amountOfferReference, 'hasBrokenRules', false)
    const rules = get(amountOfferReference, 'rules', false)
    const hasTermRules = !!get(flightRules, 'type', null)
    const offer = selectedBack ? selectedBack : selectedThere
    const readOnly = abilities.view && !abilities.edit && !abilities.bookOffers

    return (
      <div className='plane-offer'>
        <div className='plane-offer__reservation-status'>
          <ReservationStatus offer={offer} />
        </div>

        <div className='plane-offer__step'>
          <div className='plane-offer__heading'>
            <strong>{trans('flights-booking.flight-there')}</strong>
            <span> - {thereDate}</span>
          </div>

          <SingleOffer
            offer={selectedThere}
            flightClass={flightClass}
            query={query}
            totalPaxes={totalPaxes}
            isReturn={false}
            abilities={abilities}
            travelers={travelers}
          />
        </div>

        {selectedBack && (
          <div className='plane-offer__step'>
            <div className='plane-offer__heading'>
              <strong>{trans('flights-booking.flight-back-single')}</strong>
              <span> - {backDate}</span>
            </div>

            <SingleOffer
              offer={selectedBack}
              flightClass={flightClass}
              query={query}
              totalPaxes={totalPaxes}
              isReturn={true}
              abilities={abilities}
              travelers={travelers}
            />
          </div>
        )}

        <div className='plane-offer__footer'>
          <div className='plane-offer__price-wrapper'>
            {hasBrokenRules && <Warning rules={rules} />}

            <span className='plane-offer__price'>
              {isBlocked ? trans('flights-booking.pricing') : amount}
            </span>
          </div>

          <div className='plane-offer__messages'>
            <div className='plane-offer__agreement-message'>
              {!readOnly && (
                <Ability ability={['edit', 'bookOffers']} comparator='or'>
                  {!this.isBookingFailed(offer) && hasTermRules && !isBookingProcessWaiting && (
                    <Agreement offer={offer} />
                  )}
                </Ability>
              )}
            </div>

            <div>
              <div className='plane-offer__commission-message'>
                {totalPaxes > 1 && (
                  <p className='is-allign-end'>
                    {trans('flights-booking.reservation-short-info', { paxes: totalPaxes })}
                  </p>
                )}
                <p>
                  {trans('flights-booking.commission-message')} {fee}
                </p>
              </div>

              {!readOnly && (
                <Ability ability={['edit', 'bookOffers']} comparator='or'>
                  <div className='plane-offer__buttons'>
                    {this.isSearchAnotherAllowed(offer) && this.renderSearchAnother()}
                    {this.isBookAllowed(offer) && this.renderBookButton()}
                  </div>
                </Ability>
              )}
            </div>
          </div>

          {isBookingProcessWaiting && this.renderReservationNotice()}
        </div>
      </div>
    )
  }
}

const SelectedOffer = connect(SelectedOfferComponent)

export default SelectedOffer
export { SelectedOffer }
