import PropTypes from 'prop-types'
import React from 'react'
import { Field } from 'redux-form/immutable'
import { FormattedNumberField, FormField, FormFieldRadioGroup } from '../ui/Form'
import trans from '../../trans'
import { debounce, get } from 'lodash'
import { AmountFormatter } from '../AmountFormatter'
import { Loader } from '../ui/LoadingOverlay/Loader'
import Icon from '../ui/IconComponent'
import BaseIcon from '../ui/IconComponent'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import AddProviderForm from '../DocumentForm/DocumentAddProviderForm'
import DialogActions from '@material-ui/core/DialogActions'
import Dialog from '@material-ui/core/Dialog'
import { compose } from 'redux'
import { countries } from '../../store/app/countries'
import { Tooltip } from '../Tooltip'
import DocumentFormRate from '../DocumentForm/DocumentFormRate'
import DocumentFormExchangeRateTooltip from '../DocumentForm/DocumentFormExchangeRateTooltip'
import { getLabelForProvider, loadOptionsForProviders } from '../../store/app/providers-for-select'
import { Alert, Col, Row } from '../../ui'
import { LoadingOverlay } from '../ui/LoadingOverlay'
import Button from '../ui/ButtonComponent'
import localStorage from '../../services/localStorage'
import APIClient from '../../services/APIClient'
import ProviderSelectOption from './ProviderSelectOption'
import ProviderSelectSingleValue from './ProviderSelectSingleValue'
import { Provider } from '../../types/provider'
import { toNumber } from '../../utils/number'

const OCR_HINTS_STORAGE_KEY = 'ocr-hints'

export interface AccountDocumentFormState {
  suggestedProvider: Partial<Provider>
  [key: string]: any
}

class AccountDocumentForm extends React.Component<any, AccountDocumentFormState> {
  debouncedRateChange: (event, rate) => void

  constructor(props) {
    super(props)

    const hintsVisibility = localStorage.get(OCR_HINTS_STORAGE_KEY) || {}

    this.state = {
      disabledHints: [],
      initialOcrStatus: get(props.document, 'ocr_status', null),
      areHintsVisible: get(hintsVisibility, `${props.document.id}.visible`, true),
      hintsDirty: get(hintsVisibility, `${props.document.id}.dirty`, false),
      defaultCurrencyGrossFocus: false,
      showProviderError: false,
      suggestedProvider: {},
    }
    this.debouncedRateChange = debounce(this.onRateChange, 300)
    this.validateProvider = this.validateProvider.bind(this)
  }

  componentDidMount() {
    const {
      document: { accountDimensionItems = [] },
      setCache,
    } = this.props

    setCache(accountDimensionItems)
  }

  validateProvider() {
    return this.props.document.ocr_provider_not_found === true && !this.props.document.provider
      ? trans('document.no-provider-error')
      : undefined
  }

  renderProvider() {
    const provider = this.props.document.provider

    if (!provider) {
      return <p style={{ color: '#848484' }}>{trans('document.no-provider')}</p>
    }
    return ''
  }

  getCurrenciesOptions() {
    const { currencies } = this.props

    return currencies.map((currency) => {
      return {
        label: currency['code'],
        value: currency['id'],
      }
    })
  }

  acceptHint(hint) {
    return new Promise((resolve) => {
      this.props.acceptHint(hint, this.props).then((hint) => {
        const { disabledHints } = this.state
        disabledHints.push(hint['id'])
        this.setState({ disabledHints, initialOcrStatus: 'processed' })
        this.setHintsDirty()
        this.props.fetch()
        resolve()
      })
    })
  }

  acceptProvider(hint) {
    hint.name = hint.label

    APIClient.createProvider(hint).then((createdProvider) => {
      this.acceptHint(hint).then(() => {
        this.updateProviders(createdProvider.data)
      })
    })
  }

  rejectHint(hint) {
    return new Promise((resolve) => {
      this.props.rejectHint(hint, this.props).then((hint) => {
        const { disabledHints } = this.state
        disabledHints.push(hint['id'])
        this.setState({ disabledHints, initialOcrStatus: 'processed' })
        this.setHintsDirty()
        resolve(undefined)
      })
    })
  }

  onTransactionChange = (event, transactionId) => {
    const { transactionSuggestions } = this.props
    const suggestion = transactionSuggestions.find(
      (suggestion) => suggestion.transaction.id === transactionId,
    )

    if (!suggestion) {
      return
    }

    const billingValue = toNumber(suggestion.transaction.billing_value)

    this.setState(
      {
        defaultCurrencyGrossFocus: true,
      },
      () => {
        this.onDefaultCurrencyGrossChange(billingValue)
        this.setState({ defaultCurrencyGrossFocus: false })
      },
    )
  }

  getTransactionOptions() {
    const { transactionSuggestions } = this.props

    return transactionSuggestions.map((transaction) => ({
      label: transaction.transaction.title,
      value: transaction.transaction.id,
    }))
  }

  acceptHintOrProvider(hint) {
    if (hint.column !== 'provider_suggested') {
      this.acceptHint(hint)
    } else {
      this.acceptProvider(hint)
    }
  }

  getAllOcrHints() {
    const {
      document: { ocrHints },
    } = this.props
    const { disabledHints } = this.state
    const allHints = [...ocrHints]

    return allHints.filter(
      ({ id, accepted }) => disabledHints.indexOf(id) === -1 && accepted === null,
    )
  }

  getOcrSuggestedProviderParams(): Partial<Provider> {
    const {
      document: { ocrSuggestedProvider },
    } = this.props

    return ocrSuggestedProvider.length ? ocrSuggestedProvider[0].params : {}
  }

  acceptAllHints() {
    const ocrHints = this.getAllOcrHints()

    ocrHints.forEach((hint) => {
      this.acceptHintOrProvider(hint)
    })
  }

  closeHints = () => {
    const { document } = this.props
    let data = localStorage.get(OCR_HINTS_STORAGE_KEY) || {}

    data = {
      ...data,
      [document.id]: {
        visible: false,
      },
    }

    localStorage.set(OCR_HINTS_STORAGE_KEY, data)
    this.setState({ areHintsVisible: false })
  }

  calculateRateFromGrossChange = (gross, defaultCurrencyGross) => {
    const result = parseFloat(defaultCurrencyGross) / parseFloat(gross)
    return result ? result.toFixed(8).toString() : null
  }

  onDefaultCurrencyGrossChange = (value) => {
    const { data } = this.props
    const result = this.calculateRateFromGrossChange(data.gross, value)

    if (this.state.defaultCurrencyGrossFocus) {
      this.debouncedRateChange(null, result)
    }
  }

  onRateChange = (event, rate) => {
    const { change } = this.props

    change('exchange_rate', rate)
  }

  onRateSuggestionAccept = () => {
    const { change } = this.props

    // it's a hack, because we disable form reinitialization,
    // this field is not updated, when currency is changed
    // that mean, redux-form does not recognize change of field,
    // when previous value is the same as current value,
    // so we weren't able to accept new exchange rate
    change('default_exchange_rate_accepted', new Date().getTime())
  }

  addNewProvider = () => {
    this.setState(
      {
        suggestedProvider: this.getOcrSuggestedProviderParams(),
      },
      () => this.openPopup(),
    )
  }

  openPopup = () => {
    this.setState({ isAddProviderPopupOpen: true })
  }

  closePopup = () => {
    this.setState({
      isAddProviderPopupOpen: false,
      suggestedProvider: {},
    })
  }

  updateProviders = (provider: Provider) => {
    const { dispatch, change } = this.props

    if (!provider) {
      dispatch(change('provider', null))
    } else {
      dispatch(change('provider', provider))
    }
  }

  setHintsDirty() {
    const { document } = this.props
    let data = localStorage.get(OCR_HINTS_STORAGE_KEY) || {}

    data = {
      ...data,
      [document.id]: {
        dirty: true,
      },
    }

    localStorage.set(OCR_HINTS_STORAGE_KEY, data)
    this.setState({ hintsDirty: true })
  }

  renderHints() {
    const {
      document: { abilities, ocr_status },
    } = this.props
    const ocrHints = this.getAllOcrHints()

    const hintsList = []

    if (!abilities.edit || !this.state.areHintsVisible || ocr_status === 'unprocessable') {
      return null
    }

    if (this.state.hintsDirty && !ocrHints.length) {
      return null
    }

    ocrHints.map((hint) => {
      hintsList.push(
        <Alert
          className='alert-warning'
          key={hint['id']}
          handleAcceptClick={() => this.acceptHintOrProvider(hint)}
          handleRejectClick={() => this.rejectHint(hint)}
        >
          {trans(`document.ocr-hint-${hint['column']}`)} <span>{hint['label']}</span>
        </Alert>,
      )
    })

    return (
      <div>
        <div className='is-font-semibold-weight' style={{ marginTop: '30px' }}>
          {trans('document.ocr-hint-automatic-recognition')}
        </div>

        {['pending', 'processing', 'wfm'].indexOf(ocr_status) !== -1 && (
          <div style={{ height: 70, position: 'relative' }}>
            <LoadingOverlay xs />
          </div>
        )}

        {hintsList.length > 0 && hintsList}

        {((ocr_status === 'processed' && hintsList.length === 0) || ocr_status === 'failed') && (
          <Row style={{ marginBottom: '30px' }}>
            <Col xs={12}>
              <small className='no-ocr-hints'>{trans('document.no-ocr-hints')}</small>
            </Col>
          </Row>
        )}

        <Row>
          <Col is_pull_end xs={12}>
            <Button
              className='is-margin-right-small'
              outline
              xs
              onClick={(e) => {
                e.preventDefault()
                this.closeHints()
              }}
            >
              {trans('document.close-hints')}
            </Button>

            {hintsList.length > 0 && (
              <Button
                primary
                xs
                onClick={(e) => {
                  e.preventDefault()
                  this.acceptAllHints()
                }}
              >
                {trans('document.accept-all-hints')}
              </Button>
            )}
          </Col>
        </Row>
      </div>
    )
  }

  changeProvider(provider: Provider) {
    if (!provider.disposable) {
      this.props.changeProvider(provider)
    } else {
      const suggestedProvider = {
        ...this.getOcrSuggestedProviderParams(),
        id: provider.id,
        erp_id: provider.erp_id,
        disposable: provider.disposable,
      }

      this.setState({ suggestedProvider }, () => this.openPopup())
    }
  }

  hasCorrectedDocumentField(): boolean {
    return this.props.data.accounting_type === 'correcting_invoice'
  }

  isCurrencyChanged(): boolean {
    const {
      defaultCurrency,
      getValue,
      currencies,
      document: { exchange_rate },
    } = this.props

    const currency = currencies.find((c) => c['id'] === getValue('currency_id'))

    return currency && currency['code'] !== defaultCurrency
  }

  canRenderNetField(): boolean {
    const {
      document: { exchange_rate },
    } = this.props

    return !(this.isCurrencyChanged() && !exchange_rate)
  }

  render() {
    const {
      submitting,
      defaultCurrency,
      getValue,
      currencies,
      data,
      document: {
        is_margin,
        accounting_type,
        instance_id,
        exchange_rate,
        default_exchange_rate,
        converted_gross_sum_of_elements,
        converted_net_sum_of_elements,
        abilities: { account, add_custom_provider: addProvider },
        can_edit_exchange_rate,
      },
      document,
      amountLoader,
      countries,
      accountDimensions,
      is_margin_shown,
      transactionSuggestionsLoading,
    } = this.props

    const gross = getValue('gross') ? getValue('gross') : '0,00'
    const net = getValue('net') ? getValue('net') : '0,00'
    const currency = currencies.find((c) => c['id'] === getValue('currency_id'))
    const readOnly = !account

    const {
      exchange: {
        selectors: { rate: exchangeRate },
      },
    } = this.props
    return (
      <form style={{ flexWrap: 'wrap' }} className='document-form'>
        <Field
          name='accounting_type'
          component={FormFieldRadioGroup}
          label={trans('document.document-type')}
          items={[
            {
              value: 'invoice',
              label: trans('document.invoice'),
              disabled: readOnly,
            },
            {
              value: 'correcting_invoice',
              label: trans('document.correcting-invoice'),
              disabled: readOnly,
            },
            {
              value: 'receipt',
              label: trans('document.receipt'),
              disabled: readOnly,
            },
          ]}
          disabled={submitting}
          inline
          labeltop
        />
        {is_margin_shown && (
          <Field
            name='is_margin'
            component={FormFieldRadioGroup}
            label={trans('document.is_margin')}
            items={[
              {
                value: 0,
                label: trans('document.is_margin_type_vat'),
                disabled: readOnly,
              },
              {
                value: 1,
                label: trans('document.is_margin_type_margin_scheme'),
                disabled: readOnly,
              },
            ]}
            disabled={submitting}
            inline
            labeltop
          />
        )}
        <div>
          <div className='input-add-provider'>
            <Field
              component={FormField}
              label={trans('document.provider')}
              type='async-select'
              name='provider'
              disabled={!document.abilities.edit}
              loadOptions={(search, prevOptions) =>
                loadOptionsForProviders(search, prevOptions, document.request.company.id)
              }
              noOptionsMessage={() => {
                trans('provider.no-results-for-search-message')
              }}
              loadingMessage={() => {
                return trans('provider.searching-message')
              }}
              selectedOption={document.provider}
              onChange={(event, provider) => {
                this.changeProvider(event, provider)
              }}
              components={{ Option: ProviderSelectOption, SingleValue: ProviderSelectSingleValue }}
              labeltop
              displayDelay={0}
              placeholder={
                document.abilities.edit
                  ? trans('document.provider-search')
                  : trans('document.no-provider')
              }
              validate={this.validateProvider}
            />

            {addProvider && (
              <div className='provider-add-btn-wrapper'>
                <span className='provider-add-btn' onClick={() => this.addNewProvider()}>
                  <Icon className='is-gradient-success' type='plus' />
                </span>
              </div>
            )}
          </div>

          <Dialog
            open={this.state.isAddProviderPopupOpen}
            keepMounted
            onClose={this.closePopup}
            aria-labelledby='alert-dialog-slide-title'
            aria-describedby='alert-dialog-slide-description'
            className='last-requests__dialog'
            disablePortal={true}
            PaperProps={{
              square: true,
              className: 'add-provider-dialog__paper',
            }}
          >
            <DialogTitle id='alert-dialog-slide-title'>
              {trans('document.add-provider-form')}
            </DialogTitle>

            <DialogContent className='last-requests__dialog-content'>
              {this.state.isAddProviderPopupOpen && (
                <AddProviderForm
                  instanceId={instance_id}
                  companySlug={document.request.company.slug}
                  closePopup={this.closePopup}
                  countries={countries.selectors.countries}
                  submitCallback={this.updateProviders}
                  suggestedProvider={this.state.suggestedProvider}
                />
              )}
            </DialogContent>

            <DialogActions className='no-margin'>
              <Icon
                className='last-requests__dialog-close'
                type='close'
                onClick={this.closePopup}
              />
            </DialogActions>
          </Dialog>
        </div>
        <Row>
          <Col xs={6}>
            <Field
              name='document_number'
              type='text'
              component={FormField}
              label={trans('document.document-number')}
              disabled={submitting}
              labeltop
            />
          </Col>

          <Col xs={6}>
            <Field
              name='vat_date'
              type='datepicker'
              component={FormField}
              label={trans('document.vat-date')}
              disabled={submitting}
              labeltop
            />
          </Col>

          {this.hasCorrectedDocumentField() && (
            <Col xs={12}>
              <Field
                name='corrected_document_number'
                type='text'
                component={FormField}
                label={trans('document.document-corrected-number')}
                disabled={submitting || !account}
                labeltop
              />
            </Col>
          )}
        </Row>
        <Row>
          <Col xs={6}>
            <Field
              name='issue_date'
              type='datepicker'
              component={FormField}
              label={trans('document.issue-date')}
              disabled={submitting}
              labeltop
            />
          </Col>
          <Col xs={6}>
            <Field
              name='received_date'
              type='datepicker'
              component={FormField}
              label={trans('document.received-date')}
              disabled={submitting}
              labeltop
            />
          </Col>
        </Row>
        <Field
          name='annotation'
          type='text'
          component={FormField}
          label={trans('document.annotation')}
          disabled={submitting}
          labeltop
        />
        <Row>
          <Col sm={12}>
            <Field
              name='payment'
              component={FormFieldRadioGroup}
              label={
                <>
                  {trans('document.payment')}
                  <Tooltip
                    html={
                      <div>
                        <p>
                          <strong>{trans('document.payment-tooltip-personal-card')}</strong>:{' '}
                          {trans('document.payment-tooltip-personal-card-description')}
                        </p>
                        <br />
                        <p>
                          <strong>{trans('document.payment-tooltip-own-funds')}</strong>:{' '}
                          {trans('document.payment-tooltip-own-funds-description')}
                        </p>
                      </div>
                    }
                  >
                    <BaseIcon grey lg className='icon-info-grey' style={{ marginLeft: 7 }} />
                  </Tooltip>
                </>
              }
              items={[
                {
                  value: 'service_card',
                  label: trans('document.service-card'),
                  disabled: readOnly,
                },
                {
                  value: 'own',
                  label: trans('document.own'),
                  disabled: readOnly,
                },
                {
                  value: 'corporate_card',
                  label: trans('document.corporate-card'),
                  disabled: readOnly,
                },
              ]}
              inline
              labeltop
              disabled={submitting}
            />
          </Col>
        </Row>
        <Row>
          <Col xs={6}>
            <Row>
              <Col xs={7} style={{ paddingRight: '0' }}>
                <div className='form-group form-group--label-top'>
                  <span className='form-group__label'>{trans('document.gross')}</span>
                  <div className='form-group__input-wrapper'>
                    {readOnly ? (
                      <div className='input input--text'>
                        <AmountFormatter amount={gross} /> {currency ? currency.code : ''}
                      </div>
                    ) : (
                      <Field
                        name='gross'
                        type='formatted_number'
                        inputOnly
                        component={FormField}
                        disabled={submitting}
                        withError
                        labeltop
                      />
                    )}
                  </div>
                </div>
              </Col>
              {!readOnly && (
                <Col xs={5} style={{ paddingLeft: '10px' }}>
                  <div className='form-group form-group--label-top'>
                    <span className='form-group__label'>{trans('document.currency')}</span>
                    <div className='form-group__input-wrapper'>
                      <Field
                        name='currency_id'
                        type='currency'
                        component={FormField}
                        inputOnly
                        currencies={this.getCurrenciesOptions()}
                        disabled={submitting || !data.payment}
                        label=' '
                        withError
                        labeltop
                      />
                    </div>
                  </div>
                </Col>
              )}
            </Row>
          </Col>
          <Col xs={6}>
            {data.payment === 'service_card' ? (
              <div className='form-group form-group--label-top'>
                <span className='form-group__label'>
                  {trans('document.gross') + ' ' + defaultCurrency}
                </span>
                <div className='account-document-page__rate'>
                  <FormattedNumberField
                    value={document.converted_gross}
                    onChange={(event) => {
                      this.onDefaultCurrencyGrossChange(event)
                    }}
                    disabled={!data.gross}
                    onBlur={() => {
                      this.setState({
                        defaultCurrencyGrossFocus: false,
                      })
                    }}
                    onFocus={() => {
                      this.setState({
                        defaultCurrencyGrossFocus: true,
                      })
                    }}
                  />
                </div>
              </div>
            ) : (
              <Field
                component={FormField}
                label={<strong>{trans('document.gross') + ' ' + defaultCurrency}</strong>}
                type='html'
                name='dummy2'
                html={() => {
                  return (
                    <div style={{ marginTop: '7px' }}>
                      <strong className='document__amount document__amount--accounting has-loader'>
                        {amountLoader.isLoading ? (
                          <Loader small />
                        ) : (
                          <AmountFormatter amount={document.converted_gross} />
                        )}
                      </strong>
                    </div>
                  )
                }}
                labeltop
              />
            )}
          </Col>
        </Row>
        {this.isCurrencyChanged() && (
          <Row>
            <Col sm={6}>
              <div style={{ position: 'relative' }}>
                <div className='form-group form-group--label-top'>
                  <DocumentFormRate
                    document={document}
                    onRateChange={this.onRateChange}
                    isAccounting={true}
                    onRateSuggestionAccept={this.onRateSuggestionAccept}
                  />
                </div>

                <DocumentFormExchangeRateTooltip type={document.request.type} />
              </div>
            </Col>
          </Row>
        )}
        {this.canRenderNetField() && (
          <Row>
            <Col xs={6}>
              <div className='form-group form-group--label-top'>
                <span className='form-group__label'>{trans('document.net')}</span>
                <div className='form-group__input-wrapper'>
                  {readOnly ? (
                    <div className='input input--text'>
                      <AmountFormatter amount={net} /> {currency ? currency.code : ''}
                    </div>
                  ) : (
                    <Field
                      name='net'
                      type='formatted_number'
                      inputOnly
                      component={FormField}
                      disabled={submitting}
                      withError
                      labeltop
                    />
                  )}
                </div>
              </div>
            </Col>

            <Col xs={6}>
              <div className='form-group form-group--label-top'>
                <span className='form-group__label'>{trans('document.vat')}</span>
                <div className='form-group__input-wrapper read-only'>
                  <div className='input input--text'>
                    <AmountFormatter amount={document.tax_original_converted} />
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        )}

        {document._links.transaction_suggestions && (
          <Row>
            <Col sm={12}>
              <Field
                component={FormField}
                label={trans('document.transaction')}
                type='select'
                name='transaction'
                options={this.getTransactionOptions()}
                onChange={this.onTransactionChange}
                clearable
                disabled={readOnly}
                isLoading={transactionSuggestionsLoading}
                labeltop
              />
            </Col>
          </Row>
        )}

        <Row>
          <Col sm={12}>
            {accountDimensions.map((dimension) => (
              <Field
                name={dimension.fieldName}
                type='account-dimensions'
                component={FormField}
                accountDimension={dimension}
                selectedDimensions={document.accountDimensionItems}
                placeholder={trans('account-dimensions.placeholder')}
                label={dimension.label}
                labeltop
                asField
                disabled={!document.abilities.edit}
                key={dimension.id}
              />
            ))}
          </Col>
        </Row>
      </form>
    )
  }
}

AccountDocumentForm.propTypes = {
  provider: PropTypes.object.isRequired,
  changeProvider: PropTypes.func.isRequired,
  document: PropTypes.object.isRequired,
}

const withCountries = countries(false, false)

AccountDocumentForm = compose(withCountries)(AccountDocumentForm)

export { AccountDocumentForm }
export default { AccountDocumentForm }
