import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import Avatar from '../Avatar/Avatar'

class Employee extends Component<any, any> {
  constructor(props) {
    super(props)
  }

  render() {
    const {
      employee: { name, avatar },
      employee,
      large,
    } = this.props

    const avatarClasses = classNames({
      'settings-page__hierarchy-avatar': true,
      'settings-page__hierarchy-avatar--large': large,
    })

    if (avatar) {
      return (
        <div>
          <Avatar asImage={true} alt={name} user={employee} className={avatarClasses} />

          <span className='settings-page__hierarchy-name'>{name}</span>
        </div>
      )
    } else {
      return ''
    }
  }
}

Employee.propTypes = {
  employee: PropTypes.object.isRequired,
  large: PropTypes.bool.isRequired,
}

Employee.defaultProps = {
  large: false,
}

export default Employee
export { Employee }
