import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import Dialog from '@material-ui/core/Dialog'
import Paper from '@material-ui/core/Paper'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogActions from '@material-ui/core/DialogActions'
import { Transition } from 'react-transition-group'
import Button from '../ui/ButtonComponent'
import trans from '../../trans'
import DialogContent from '@material-ui/core/DialogContent'

function ConfirmationModal({ title, accept, decline, onConfirm, onReject }) {
  if (!title) {
    return null
  }

  useEffect(() => {
    document.activeElement.blur()
  }, [])

  return (
    <Dialog
      open={!!title}
      TransitionComponent={Transition}
      keepMounted
      disableBackdropClick
      disableEscapeKeyDown
      aria-labelledby='alert-dialog-slide-title'
      aria-describedby='alert-dialog-slide-description'
      className='last-requests__dialog'
    >
      <Paper square>
        <DialogTitle id='alert-dialog-slide-title'>{trans('global.confirm-title')}</DialogTitle>

        <DialogContent className='last-requests__dialog-content'>{title}</DialogContent>

        <DialogActions className='accommodation-confirm__dialog-actions'>
          <Button outline xxs onClick={onReject}>
            {decline}
          </Button>
          <Button primary xxs onClick={onConfirm}>
            {accept}
          </Button>
        </DialogActions>
      </Paper>
    </Dialog>
  )
}

ConfirmationModal.propTypes = {
  title: PropTypes.string,
  decline: PropTypes.string,
  accept: PropTypes.string,
  onConfirm: PropTypes.func.isRequired,
  onReject: PropTypes.func.isRequired,
}

export { ConfirmationModal }
export default ConfirmationModal
