import React from 'react'
import { Tooltip } from '../../Tooltip'
import PropTypes from 'prop-types'
import Icon from '../IconComponent'

const Ellipsis = (props) => {
  let { text, ...rest } = props

  return (
    <span>
      <Tooltip hide={false} html={text} {...rest}>
        <span className='request-list__column-name-ellipsis'>...</span>
        <Icon className='request-list__column-name-icon' type='question_fill' />
      </Tooltip>
    </span>
  )
}

Ellipsis.propTypes = {
  text: PropTypes.any.isRequired,
}

export default Ellipsis
export { Ellipsis }
