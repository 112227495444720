import { get, isFunction } from 'lodash'

export const INSIDE_HEADER_OUT = 'out'
export const INSIDE_HEADER_IN = 'in'

export const onScroll = (insideInOutCallback) => {
  try {
    const header = get(document.getElementsByClassName('js-main-header'), '0', null)
    const insideHeader = get(document.getElementsByClassName('js-inside-header'), '0', null)

    if (!insideHeader && !header) {
      return
    }

    const callback = isFunction(insideInOutCallback) ? insideInOutCallback : () => {}

    if (!insideHeader) {
      callback(INSIDE_HEADER_IN)
    }

    const featuredOutOfView = isFeaturedOutOfView(header, insideHeader)

    if (featuredOutOfView) {
      callback(INSIDE_HEADER_OUT)
    } else {
      callback(INSIDE_HEADER_IN)
    }
  } catch (e) {
    // console.log(e);
  }
}

const isFeaturedOutOfView = (header, insideHeader) => {
  return insideHeader.getBoundingClientRect().bottom < header.getBoundingClientRect().bottom + 25
}
