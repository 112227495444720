import PropTypes from 'prop-types'
import React from 'react'
import classNames from 'classnames'

class Panel extends React.Component<any, any> {
  render() {
    const { children, header, closable, onClose, className, iconClass } = this.props
    const classes = classNames({ panel: true }, className)
    const iconClasses = classNames({ icon: true, 'icon-close': true }, iconClass)

    return (
      <div className={classes}>
        {closable && (
          <span className='panel__close-icon' onClick={onClose}>
            <i className={iconClasses} />
          </span>
        )}
        {header && <div className='panel__header'>{header}</div>}
        <div className='panel__content'>{children}</div>
      </div>
    )
  }
}

Panel.propTypes = {
  header: PropTypes.node,
  closable: PropTypes.bool,
  onClose: PropTypes.func,
  iconClass: PropTypes.string,
}

Panel.defaultProps = {
  closable: false,
}

export { Panel }
export default { Panel }
