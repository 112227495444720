export const round = (number, places = 2) => {
  number = parseFloat(String(number).replace(/,/g, '.'))
  if (isNaN(number)) {
    return 0
  }
  return (+(Math.round(number + 'e+' + places) + 'e-' + places)).toFixed(places)
}

export const toNumber = (number: string): Number => {
  if (!number) {
    return null
  }

  return Number(`${number}`.replace(',', '.').replace(' ', ''))
}
