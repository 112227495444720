import PropTypes from 'prop-types'
import React from 'react'
import moment from 'moment'
import { Cell, Row, Table, TBody, THead } from '../../components/ui/AccordionTable'
import trans from '../../trans'
import { get } from 'lodash'
import { EditableDate } from './EditableDate'
import { getRouteByName } from '../../routes'
import { MarkAsPaid } from './MarkAsPaid'
import EditableAmount from './EditableAmount'
import APIClient from '../../services/APIClient'
import EditableErpId from './EditableErpId'
import MarkAsAccounted from './MarkAsAccounted'
import EditableCurrency from './EditableCurrency'
import UserName from '../UserName/UserName'
import Ellipsis from '../ui/Ellipsis/Ellipsis'
import TruncateMarkup from 'react-truncate-markup'
import classnames from 'classnames'
import EditableExchangeRate from './EditableExchangeRate'
import { getInstance } from '../../store/app/instance'
import store from '../../store'
import InstallmentAlreadyAccountedTooltip from './InstallmentAlreadyAccountedTooltip'
import SortableCellWrapper from '../Sortable/SortableCellWrapper'

class InstallmentsList extends React.Component<any, any> {
  constructor(props) {
    super(props)

    this.state = {
      currencies: [],
    }
  }

  componentDidMount() {
    APIClient.getCurrencies().then((response) => {
      this.setState({
        currencies: response.data.map(({ id, code }) => ({
          label: code,
          value: code,
        })),
      })
    })
  }

  renderRequestDate = (installment) => {
    const date = get(installment, 'request.sent_at', null)

    if (!date) {
      return <span>---</span>
    }

    return <span>{moment(date).format('YYYY-MM-DD')}</span>
  }

  renderEmployee = (installment) => {
    const user = { ...get(installment, 'request.user') }

    return <UserName key={installment.id} firstName={user.first_name} lastName={user.last_name} />
  }

  renderPayoutDate = (installment, outerDate) => {
    const date = outerDate ? outerDate : get(installment, 'date', null)

    return (
      <InstallmentAlreadyAccountedTooltip
        ability={get(installment, 'abilities.set_paid_date', false)}
      >
        <EditableDate
          date={date}
          onChange={(date) => this.props.changeDate(installment, date)}
          canEdit={get(installment, 'abilities.set_paid_date', false) && installment.abilities.edit}
          placeholder={trans('global.datepicker-placeholder')}
          triggerOnBlur
        />
      </InstallmentAlreadyAccountedTooltip>
    )
  }

  renderAccountingDate = (installment, outerDate) => {
    const date = outerDate ? outerDate : get(installment, 'accounting_date', null)

    return (
      <InstallmentAlreadyAccountedTooltip
        ability={get(installment, 'abilities.set_accounting_date', false)}
      >
        <EditableDate
          date={date}
          onChange={(date) => this.props.changeAccountingDate(installment, date)}
          canEdit={
            get(installment, 'abilities.set_accounting_date', false) && installment.abilities.edit
          }
          placeholder={trans('global.datepicker-placeholder')}
          triggerOnBlur
        />
      </InstallmentAlreadyAccountedTooltip>
    )
  }

  renderAmount = (installment) => {
    const amount = get(installment, 'amount', null)
    const currency = get(installment, 'currency', null)

    return (
      <EditableAmount
        amount={amount}
        currency={currency}
        onChange={(amount, currency) => this.props.changeAmount(installment, amount, currency)}
        onError={(response) => get(response, 'alerts.0.errors.amount', [])}
        canEdit={get(installment, 'abilities.set_paid_amount', false) && installment.abilities.edit}
      />
    )
  }

  renderCurrency = (installment) => {
    const amount = get(installment, 'amount', null)
    const currency = get(installment, 'currency', null)

    return (
      <EditableCurrency
        amount={amount}
        currency={currency}
        currencies={this.state.currencies}
        onChange={(amount, currency) => this.props.changeAmount(installment, amount, currency)}
        onError={(response) => get(response, 'alerts.0.errors.amount', [])}
        canEdit={get(installment, 'abilities.set_paid_amount', false) && installment.abilities.edit}
      />
    )
  }

  renderExchangeRate = (installment) => {
    const instance = getInstance(store.getState())

    return (
      <EditableExchangeRate
        exchangeRate={
          installment.exchange_rate
            ? parseFloat(installment.exchange_rate).toFixed(4)
            : installment.exchange_rate
        }
        onChange={(exchangeRate) => this.props.changeExchangeRate(installment, exchangeRate)}
        canEdit={
          get(installment, 'abilities.set_exchange_rate', false) &&
          installment.abilities.edit &&
          installment.currency !== instance.currency
        }
      />
    )
  }

  renderErpId = (installment) => {
    const id = get(installment, 'erp_id', null)

    return (
      <InstallmentAlreadyAccountedTooltip ability={get(installment, 'abilities.set_erp_id', false)}>
        <EditableErpId
          id={id}
          onChange={(id) => this.props.changeErpId(installment, id)}
          canEdit={get(installment, 'abilities.set_erp_id', false) && installment.abilities.edit}
        />
      </InstallmentAlreadyAccountedTooltip>
    )
  }

  renderRequestPermalink = (installment) => {
    const request = get(installment, 'request', null)

    if (!request) {
      return '#'
    }

    return getRouteByName('main', `${request.type}RequestShow`, { id: request.slug })
  }

  markAsPaidCallback = (installment, response) => {
    this.renderPayoutDate(installment, response.data.date)
  }

  markAsAccountedCallback = (installment, response) => {
    this.renderAccountingDate(installment, response.data.accounting_date)
  }

  renderStatus = (installment) => {
    const userCanMarkAsPaid = get(installment, 'abilities.set_status', false)

    if (installment.status === 'unpaid' && !userCanMarkAsPaid) {
      return trans('installments.status-unpaid')
    }

    return (
      <MarkAsPaid
        installment={installment}
        markAsPaid={() => this.props.markAsPaid(installment)}
        markAsUnPaid={() => this.props.changeDate(installment, null)}
        canMark={userCanMarkAsPaid}
        status={installment.status}
        callback={(date) => this.markAsPaidCallback(installment, date)}
      />
    )
  }

  renderAccountingStatus = (installment) => {
    const userCanMarkAsAccounted = get(installment, 'abilities.set_accounting_status', false)

    if (installment.accounted === false && !userCanMarkAsAccounted) {
      return trans('installments.status-accounted')
    }

    return (
      <MarkAsAccounted
        installment={installment}
        markAsAccounted={() => this.props.markAsAccounted(installment)}
        markAsUnAccounted={() => this.props.changeAccountingDate(installment, null)}
        canMark={userCanMarkAsAccounted}
        status={installment.accounted}
        callback={(date) => this.markAsAccountedCallback(installment, date)}
      />
    )
  }

  render() {
    const { installments, isLoading, pagination, changePage, sorter, onChangeSorter } = this.props

    return (
      <Table
        className={'table-stylesheet-theme table--installment'}
        striped
        pagination={{
          ...pagination,
          changePage,
          align: 'center',
        }}
      >
        <THead>
          <Row>
            <SortableCellWrapper
              sorter={sorter}
              onChangeSorter={onChangeSorter}
              name={'request_sent_at'}
            >
              {({ icon }) => (
                <Cell fixedWidth={130} className={'table-accordion__col__sortable'}>
                  {trans('installments.request-date')}&nbsp;{icon}
                </Cell>
              )}
            </SortableCellWrapper>
            <SortableCellWrapper
              sorter={sorter}
              onChangeSorter={onChangeSorter}
              name={'request_number'}
            >
              {({ icon }) => (
                <Cell fixedWidth={120} className={'table-accordion__col__sortable'}>
                  {trans('installments.request-id')}&nbsp;{icon}
                </Cell>
              )}
            </SortableCellWrapper>
            <SortableCellWrapper
              sorter={sorter}
              onChangeSorter={onChangeSorter}
              name={'request_status_translated'}
            >
              {({ icon }) => (
                <Cell fixedWidth={125} alignRight className={'table-accordion__col__sortable'}>
                  {trans('installments.status')}&nbsp;{icon}
                </Cell>
              )}
            </SortableCellWrapper>
            <SortableCellWrapper
              sorter={sorter}
              onChangeSorter={onChangeSorter}
              name={'request_user_last_name'}
              style={{ width: '100%' }}
            >
              {({ icon }) => (
                <Cell fixedWidth={'auto'} className={'table-accordion__col__sortable'}>
                  {trans('installments.employee')}&nbsp;{icon}
                </Cell>
              )}
            </SortableCellWrapper>
            <SortableCellWrapper sorter={sorter} onChangeSorter={onChangeSorter} name={'amount'}>
              {({ icon }) => (
                <Cell
                  fixedWidth={120}
                  alignRight
                  className={'table-accordion__col__sortable flex-end-center'}
                >
                  {trans('installments.amount')}&nbsp;{icon}
                </Cell>
              )}
            </SortableCellWrapper>
            <SortableCellWrapper
              sorter={sorter}
              onChangeSorter={onChangeSorter}
              name={'exchange_rate'}
            >
              {({ icon }) => (
                <Cell
                  fixedWidth={120}
                  alignRight
                  className={'table-accordion__col__sortable flex-end-center'}
                >
                  {trans('installments.exchange-rate')}&nbsp;{icon}
                </Cell>
              )}
            </SortableCellWrapper>
            <SortableCellWrapper
              sorter={sorter}
              onChangeSorter={onChangeSorter}
              name={'currency_code'}
            >
              {({ icon }) => (
                <Cell
                  fixedWidth={110}
                  alignRight
                  className={'table-accordion__col__sortable flex-end-center'}
                >
                  {trans('installments.currency')}&nbsp;{icon}
                </Cell>
              )}
            </SortableCellWrapper>
            <SortableCellWrapper
              sorter={sorter}
              onChangeSorter={onChangeSorter}
              name={'payout_date'}
            >
              {({ icon }) => (
                <Cell
                  fixedWidth={160}
                  alignRight
                  className={'table-accordion__col__sortable flex-end-center'}
                >
                  {trans('installments.payout-date')}&nbsp;{icon}
                </Cell>
              )}
            </SortableCellWrapper>
            <SortableCellWrapper
              sorter={sorter}
              onChangeSorter={onChangeSorter}
              name={'accounting_date'}
            >
              {({ icon }) => (
                <Cell
                  fixedWidth={160}
                  alignRight
                  className={'table-accordion__col__sortable flex-end-center'}
                >
                  {trans('installments.accounting-date')}&nbsp;{icon}
                </Cell>
              )}
            </SortableCellWrapper>
            <SortableCellWrapper sorter={sorter} onChangeSorter={onChangeSorter} name={'erp_id'}>
              {({ icon }) => (
                <Cell
                  fixedWidth={160}
                  alignRight
                  className={'table-accordion__col__sortable flex-end-center'}
                >
                  {trans('installments.erp-id')}&nbsp;{icon}
                </Cell>
              )}
            </SortableCellWrapper>
          </Row>
        </THead>
        <TBody isLoading={isLoading}>
          {installments.map((installment, i) => {
            const rowClasses = classnames({
              'request-list__table': true,
              'table-accordion__row--is-link': true,
              // 'table-accordion_row-greyed-out': !installment.abilities.edit
            })
            return (
              <Row className={rowClasses} key={i}>
                <Cell fixedWidth={130} href={this.renderRequestPermalink(installment)}>
                  {this.renderRequestDate(installment)}
                </Cell>
                <Cell fixedWidth={120} href={this.renderRequestPermalink(installment)}>
                  <TruncateMarkup
                    lines={2}
                    ellipsis={
                      <Ellipsis text={<span>{get(installment, 'request.uid', '')}</span>} />
                    }
                  >
                    <span>{get(installment, 'request.uid', '')}</span>
                  </TruncateMarkup>
                </Cell>
                <Cell fixedWidth={125} alignRight href={this.renderRequestPermalink(installment)}>
                  {trans(`global.request-status-${installment.request.status}`)}
                </Cell>
                <Cell fixedWidth={'auto'} href={this.renderRequestPermalink(installment)}>
                  <div>{this.renderEmployee(installment)}</div>
                </Cell>
                <Cell fixedWidth={120} alignRight>
                  {this.renderAmount(installment)}
                </Cell>
                <Cell fixedWidth={120} alignRight>
                  {this.renderExchangeRate(installment)}
                </Cell>
                <Cell fixedWidth={110} alignRight>
                  {this.renderCurrency(installment)}
                </Cell>
                <Cell fixedWidth={160} style={{ justifyContent: 'flex-end' }}>
                  {this.renderPayoutDate(installment)}
                </Cell>
                <Cell fixedWidth={160} style={{ justifyContent: 'flex-end' }}>
                  {this.renderAccountingDate(installment)}
                </Cell>
                <Cell fixedWidth={160} alignRight>
                  {this.renderErpId(installment)}
                </Cell>
              </Row>
            )
          })}
        </TBody>
      </Table>
    )
  }
}

InstallmentsList.propTypes = {
  installments: PropTypes.array.isRequired,
}

export { InstallmentsList }
export default InstallmentsList
