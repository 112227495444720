import React from 'react'
import { trans } from '../../../../trans'
import {
  TimelineElement,
  TimeLineElementContent,
  TimeLineTargetPointHeader,
} from '../../../Timeline'
import { Col, Row } from '../../../../ui/Grid'
import { get } from 'lodash'
import { AutoCompleteGeocoding, FormGroup } from '../../../../ui'
import moment from 'moment/moment'
import { DatePickerField } from '../../../ui/Form'
import { Map } from 'immutable'
import Icon from '../../../ui/IconComponent'
import Button from '../../../ui/ButtonComponent'
import { TravelPointLabel } from '../../../TravelPointLabel'
import { keepInViewport } from '../KeepInViewport'
import { TravelPointDate } from '../../../TravelPointDate'
import className from 'classnames'

const KeepingViewportHeader = keepInViewport()(TimeLineTargetPointHeader)

class TripStart extends React.Component<any, any> {
  constructor(props) {
    super(props)

    this.state = { show: false }
  }

  renderLabel() {
    const { start, instanceLocation, startMaxDate } = this.props
    if (start.location.value instanceof Map) {
      start.location.value = start.location.value.toJS()
    }

    let location = get(start, 'location.value', get(instanceLocation, null))
    if (location) {
      const date = get(start, 'date.value')
      return (
        <div>
          <strong>{trans('request.travel-start-point')}</strong>
          <span> - </span>
          <TravelPointLabel location={location} />
          <TravelPointDate start={date} />
        </div>
      )
    }
  }

  show() {
    this.setState({ show: true })
  }

  hide() {
    this.setState({ show: false })
  }

  render() {
    const { start, startMaxDate, onLocationChange, onDateChange, request, disabled } = this.props
    const { show } = this.state
    const iconClasses = className({
      'timeline-icon': true,
      'timeline-icon--disabled': disabled,
    })

    return (
      <TimelineElement activeSimilar className='open trip-start'>
        <KeepingViewportHeader icon='pin_2' gradient='true' isOpen={show}>
          <Col xs={11} className='timeline__header--title'>
            {this.renderLabel()}
          </Col>
          {request['abilities']['edit'] && (
            <Col xs={1} style={{ paddingLeft: '0' }} className='is-allign-end'>
              {!show && (
                <span
                  className={iconClasses}
                  onClick={() => {
                    if (disabled) {
                      return
                    }

                    show ? this.hide() : this.show()
                  }}
                >
                  <Icon type='edit' lg gradient />
                </span>
              )}
              <span className='timeline-icon icon--empty'></span>
            </Col>
          )}
        </KeepingViewportHeader>
        <TimeLineElementContent isOpened={show}>
          <div className='tooltip-content'>
            <form style={{ width: '100%' }} className='timeline--edge-points'>
              <Row>
                <Col xs={3}>
                  <FormGroup labeltop label={trans('request.start-point-location')}>
                    <div className='form-group__input-wrapper'>
                      <AutoCompleteGeocoding
                        name='start_location'
                        inputProps={{
                          value: start.location.value,
                          onChange: (address) => {
                            onLocationChange('start', address)
                          },
                          noAutoTrigger: true,
                        }}
                      />
                      {start.location.errors && (
                        <div className='form-group__error'>
                          {Object.keys(start.location.errors).map((error) => (
                            <p>{start.location.errors[error]}</p>
                          ))}
                        </div>
                      )}
                    </div>
                  </FormGroup>
                </Col>
                <Col xs={3}>
                  <FormGroup labeltop label={trans('request.start-point-date')}>
                    <div className='form-group__input-wrapper'>
                      <DatePickerField
                        name='trip_starts'
                        // placeholder={label}
                        onChange={(value) => {
                          onDateChange('start', value)
                        }}
                        value={moment(start.date.value)}
                        maxDate={startMaxDate}
                      />

                      {start.date.errors && (
                        <div className='form-group__error'>
                          {Object.keys(start.date.errors).map((error) => (
                            <p>{start.date.errors[error]}</p>
                          ))}
                        </div>
                      )}
                    </div>
                  </FormGroup>
                </Col>
              </Row>
              <Row className='is-margin-top'>
                <Col xs={12}>
                  <Button
                    primary
                    pull_end
                    onClick={(e) => {
                      e.preventDefault()
                      this.hide()
                    }}
                  >
                    {trans('global.save')}
                  </Button>
                </Col>
              </Row>
            </form>
          </div>
        </TimeLineElementContent>
      </TimelineElement>
    )
  }
}

export { TripStart }
export default { TripStart }
