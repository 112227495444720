import React from 'react'
import trans from '../../../trans'
import { AgreementContainer } from '../../AgreementContainer'
import trainsBooking from '../../../store/app/trains-booking/providers/trainsBooking'

export const Agreement = trainsBooking(false)((props) => (
  <AgreementContainer
    setAgreement={props.trainsBooking.actions.setAgreement}
    checked={props.trainsBooking.selectors.hasAgreement}
  >
    {trans('trains-booking.agreement-text-start')}
    <a href={trans('trains-booking.agreement-link-target')} target='_blank'>
      {' '}
      {trans('trains-booking.agreement-link-text')}{' '}
    </a>
    {trans('trains-booking.agreement-text-end')}
  </AgreementContainer>
))
