import { Sort } from './Sort'
import { Time } from './Time'

const Filters = {
  Sort,
  Time,
}

export default Filters
export { Filters }
