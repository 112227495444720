import PropTypes from 'prop-types'
import React from 'react'
import { Col, Row } from '../../ui/Grid'
import { FormGroup, Textarea } from '../../ui'
import Button from '../ui/ButtonComponent'
import trans from '../../trans'

class RequestAction extends React.Component<any, any> {
  render() {
    const { onChange, onAction, comment, decision, children, full = false } = this.props

    return (
      <div>
        <Row>
          <Col sm={1} />
          <Col sm={11} fill={full}>
            <span className='comments__label'>{trans('comment.comment')}</span>
          </Col>
        </Row>
        <Row>
          <Col sm={1} />
          <Col sm={11}>
            <FormGroup labeltop>
              <Textarea
                onChange={(e) => onChange('comment', e.target.value)}
                value={comment}
                placeholder={trans('request.add-comment')}
              />
            </FormGroup>
          </Col>
        </Row>
        <Row style={{ justifyContent: 'flex-end', marginRight: '-10px' }}>{children}</Row>
        <Row>
          <Col xs={12} is_pull_end>
            <div className='is-margin-top'>
              <Button primary onClick={onAction} disabled={!decision}>
                {trans('global.perform')}
              </Button>
            </div>
          </Col>
        </Row>
      </div>
    )
  }
}

RequestAction.propTypes = {
  decision: PropTypes.string,
  comment: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  onAction: PropTypes.func.isRequired,
  printAccountingDocuments: PropTypes.bool,
}

RequestAction.defaultProps = {
  printAccountingDocuments: false,
}

export { RequestAction }
