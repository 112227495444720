import React from 'react'
import { Field } from 'redux-form/immutable'
import { FormField, FormFieldRadioGroup } from '../../../ui/Form'
import { trans } from '../../../../trans'
import { TimelineElement, TimeLineElementContent, TimeLineElementHeader } from '../../../Timeline'
import { Col, Row } from '../../../../ui'
import Icon from '../../../ui/IconComponent'
import Button from '../../../ui/ButtonComponent'
import { get } from 'lodash'
import numeral from 'numeral'
import ExchangeSummaryContainer from '../../../../containers/ExchangeSummaryContainer'
import { Map } from 'immutable'
import { AmountFormatter } from '../../../AmountFormatter'
import { TypesMenu } from '../../index'
import { ifDeepDiff } from '../../../../utils/javascript'
import {
  TRAVEL_COMPANY_CAR_TRIP,
  TRAVEL_PASSENGER_CAR_TRIP,
  TRAVEL_PRIVATE_CAR_TRIP,
  TRAVEL_RENTED_CAR_TRIP,
  TRAVEL_REPLACEMENT_CAR_TRIP,
} from '../../../../constants/travel'
import { keepInViewport } from '../KeepInViewport'
import { ForeignCurrenciesTooltip } from '../../../ForeignCurrenciesTooltip'
import { FormGroup } from '../../../../ui'
import { TravelPointLabel } from '../../../TravelPointLabel'
import { TravelPointDate } from '../../../TravelPointDate'
import RentCarTypeMultiSelect from '../../../ui/Form/RentCarTypeMultiSelect'
import { connect } from 'react-redux'
import { bindActionCreators, compose } from 'redux'
import {
  fetchOrderEnumValuesIfNeeded,
  getOrderCarEnumValues,
} from '../../../../store/app/rent-car-order-car-types'
import { config } from '../../../../config'
import Car from './Car'

const KeepingViewportHeader = keepInViewport()(TimeLineElementHeader)

class RentedCar extends Car {
  constructor(props) {
    super(props)
  }

  componentDidMount() {
    this.props.fetchOrderEnumValuesIfNeeded()
  }

  shouldComponentUpdate(nextProps, nextState) {
    return ifDeepDiff(nextProps.car, this.props.car)
  }

  getExchangeSummaryPartials() {
    const { car } = this.props

    let partials = Map()

    const other_costs_amount = car.other_costs_amount ? car.other_costs_amount : 0
    const other_costs_currency = car.other_costs_currency

    const rent_cost = car.rent_cost ? car.rent_cost : 0
    const rent_cost_currency = car.rent_cost_currency

    const fuel_cost = car.fuel_cost ? car.fuel_cost : 0
    const fuel_cost_currency = car.fuel_cost_currency

    partials = partials.set(other_costs_currency, numeral(other_costs_amount))

    if (partials.has(rent_cost_currency)) {
      partials = partials.set(rent_cost_currency, partials.get(rent_cost_currency).add(rent_cost))
    } else {
      partials = partials.set(rent_cost_currency, numeral(rent_cost))
    }

    if (partials.has(fuel_cost_currency)) {
      partials = partials.set(fuel_cost_currency, partials.get(fuel_cost_currency).add(fuel_cost))
    } else {
      partials = partials.set(fuel_cost_currency, numeral(fuel_cost))
    }

    return partials
  }

  renderForm() {
    const {
      handleSubmit,
      car,
      currencies,
      onChangeType,
      change,
      minDate,
      formErrors,
      maxStartDate,
    } = this.props

    const destination_error = get(formErrors, 'destination_location.formatted_address', null)
    const departure_error = get(formErrors, 'departure_location.formatted_address', null)

    const carOrderTypeItemsMapped = this.props.orderCarTypes
      ? _.values(this.props.orderCarTypes).map((item) => {
          return {
            label: trans(`request-car-element.order-car-type-${item}`),
            value: item,
          }
        })
      : []

    const orderCarAdditionalEquipmentMapped = this.props.orderCarAdditionalEquipment
      ? _.values(this.props.orderCarAdditionalEquipment).map((item) => {
          return {
            name: trans(`request-car-element.order-car-additional-equipment-${item}`),
            value: item,
          }
        })
      : []

    return (
      <form onSubmit={handleSubmit}>
        <Field name='draft' type='hidden' component={FormField} inputOnly labeltop />

        <Field name='id' type='hidden' component={FormField} inputOnly labeltop />

        <Field name='type' type='hidden' component={FormField} inputOnly labeltop />

        <Row>
          <Col sm={6}>{this.renderCarTypeField()}</Col>
          <Col sm={6}>
            <div className={'rent-car-trip__order-check-box'}>
              <Field
                name='order'
                type='checkbox'
                component={FormField}
                label={trans('request-car-element.order')}
                labeltop
                inputOnly
              />
            </div>
          </Col>
        </Row>
        <Row>
          <Col sm={6}>
            <Row>
              <Col sm={6}>
                <FormGroup labeltop label={trans('request-car-element.departure-from')}>
                  <div className='form-group__input-wrapper'>
                    <Field
                      name='departure_location'
                      type='location'
                      component={FormField}
                      inputOnly
                      immutable
                      autoFocus
                      labeltop
                    />

                    {destination_error && (
                      <div className='form-group__error'>
                        <p>{destination_error}</p>
                      </div>
                    )}
                  </div>
                </FormGroup>
              </Col>
              <Col sm={6}>
                <FormGroup labeltop label={trans('request-car-element.arrival-to')}>
                  <div className='form-group__input-wrapper'>
                    <Field
                      name='destination_location'
                      type='location'
                      component={FormField}
                      inputOnly
                      immutable
                      labeltop
                    />
                    {departure_error && (
                      <div className='form-group__error'>
                        <p>{departure_error}</p>
                      </div>
                    )}
                  </div>
                </FormGroup>
              </Col>
            </Row>
            {this.props.data.order === true && (
              <Row>
                <Col sm={6}>
                  <Field
                    name='pickup_at'
                    type='datepicker'
                    component={FormField}
                    label={trans('request-car-element.order-pick-up-label')}
                    minDate={minDate}
                    maxDate={maxStartDate}
                    labeltop
                    showTimeSelect={this.props.data.order}
                    timeFormat='HH:mm'
                    dateFormat={config.datePickerFieldFormat}
                    timeIntervals={15}
                  />
                </Col>

                <Col sm={6}>
                  <Field
                    name='return_at'
                    type='datepicker'
                    component={FormField}
                    label={trans('request-car-element.order-drop-off-label')}
                    minDate={car.pickup_at}
                    labeltop
                    showTimeSelect={this.props.data.order}
                    timeFormat='HH:mm'
                    dateFormat={config.datePickerFieldFormat}
                    timeIntervals={15}
                  />
                </Col>
              </Row>
            )}

            {this.props.data.order === false && (
              <Row>
                <Col sm={6}>
                  <Field
                    name='pickup_at'
                    type='datepicker'
                    component={FormField}
                    label={trans('request-car-element.pickup-date')}
                    minDate={minDate}
                    maxDate={maxStartDate}
                    labeltop
                  />
                </Col>

                <Col sm={6}>
                  <Field
                    name='return_at'
                    type='datepicker'
                    component={FormField}
                    label={trans('request-car-element.rented-car-return-date')}
                    minDate={car.pickup_at}
                    labeltop
                  />
                </Col>
              </Row>
            )}

            <Row>
              <Col sm={6}>
                <Row>
                  <Col sm={7} style={{ paddingRight: '0' }}>
                    <Field
                      name='rent_cost'
                      type='formatted_number'
                      component={FormField}
                      label={trans('request-car-element.rent-cost')}
                      placeholder='0,00'
                      labeltop
                    />
                  </Col>

                  <Col sm={5}>
                    <Field
                      name='rent_cost_currency'
                      type='currency'
                      component={FormField}
                      currencies={currencies}
                      label=' '
                      labeltop
                    />
                  </Col>
                </Row>
              </Col>
              <Col sm={6}>
                <Row>
                  <Col sm={7} style={{ paddingRight: '0' }}>
                    <Field
                      name='fuel_cost'
                      type='formatted_number'
                      component={FormField}
                      label={trans('request-car-element.fuel-costs')}
                      placeholder='0,00'
                      labeltop
                    />
                  </Col>

                  <Col sm={5}>
                    <Field
                      name='fuel_cost_currency'
                      type='currency'
                      component={FormField}
                      currencies={currencies}
                      label=' '
                      labeltop
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row>
              <Col sm={6}>
                <Row>
                  <Col sm={7} style={{ paddingRight: '0' }}>
                    <Field
                      name='other_costs_amount'
                      type='formatted_number'
                      component={FormField}
                      label={trans('request-car-element.other-costs')}
                      placeholder='0,00'
                      labeltop
                    />
                  </Col>

                  <Col sm={5}>
                    <Field
                      name='other_costs_currency'
                      type='currency'
                      component={FormField}
                      currencies={currencies}
                      label=' '
                      labeltop
                    />
                  </Col>
                </Row>
              </Col>
            </Row>

            {this.props.data.order && (
              <Row>
                <Col sm={12}>
                  <Row>
                    <Col sm={6}>
                      <RentCarTypeMultiSelect
                        name={'order_car_type'}
                        options={carOrderTypeItemsMapped}
                        value={this.props.data.order_car_types}
                        placeholder={trans('request-car-element.order-car-type-placeholder')}
                        labeltop
                        label={trans('request-car-element.order-car-type-placeholder')}
                        onChange={(value) => this.changeOrderCarTypes(value)}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col sm={6}>
                      <Field
                        name='order_car_additional_equipment'
                        type='grouped-checkbox'
                        options={orderCarAdditionalEquipmentMapped}
                        component={FormField}
                        withError
                        labeltop
                        label={trans(
                          'request-car-element.order-car-additional-equipment-placeholder',
                        )}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col sm={12}>
                      <Field
                        name='order_car_other_requirement'
                        type='textarea'
                        component={FormField}
                        label={trans(
                          'request-car-element.order-car-other-requirements-placeholder',
                        )}
                        placeholder={trans(
                          'request-car-element.order-car-other-requirements-placeholder',
                        )}
                        labeltop
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>
            )}

            <Row>
              <Col sm={12}>
                <ExchangeSummaryContainer
                  onSumChange={(sum) => {
                    change('converted_amount', sum.format('0.00'))
                  }}
                  partials={this.getExchangeSummaryPartials()}
                  request={this.props.request}
                />
              </Col>
            </Row>
          </Col>
          <Col sm={6}></Col>
        </Row>

        <Row className='is-margin-top'>
          <Col sm={12}>
            <Button primary pull_end xs type='submit'>
              {trans('ui.save-trip-element')}
            </Button>
          </Col>
        </Row>
      </form>
    )
  }

  changeOrderCarTypes = (value) => {
    this.props.change('order_car_types', value)
  }
}

const mapStateToProps = (state) => ({
  orderCarTypes: getOrderCarEnumValues(state).OrderCarTypeEnum,
  orderCarAdditionalEquipment: getOrderCarEnumValues(state).OrderCarAdditionalEquipmentEnum,
})

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      fetchOrderEnumValuesIfNeeded,
    },
    dispatch,
  )
}

RentedCar = connect(mapStateToProps, mapDispatchToProps)(RentedCar)

export { RentedCar }
export default { RentedCar }
