import PropTypes from 'prop-types'
import React from 'react'
import trans from '../../trans'
import Dropzone from 'react-dropzone'
import classNames from 'classnames'
import Icon from '../ui/IconComponent'
import { LoadingOverlay } from '../ui/LoadingOverlay'
import { config } from '../../config'

class UploadCell extends React.Component<any, any> {
  constructor(props) {
    super(props)

    this.state = {
      focus: false,
      uploadingInProgress: false,
    }
  }

  shouldComponentUpdate(nextProps, nextState, nextContext) {
    return false
  }

  onDragEnterHandle = () => {
    this.setState({ focus: true })
  }

  onDragLeaveHandle = () => {
    this.setState({ focus: false })
  }

  render() {
    const { uploadDocument, request, className } = this.props
    const { focus, uploadingInProgress } = this.state

    const classes = classNames(
      {
        'upload-cell': true,
        'upload-cell--is-focused': focus,
      },
      className,
    )

    const dropzoneProgressClasses = classNames({
      dropzone__progress: true,
      start: false,
      end: false,
    })

    return request.abilities.addDocuments === true ? (
      <div className='upload-cell-container'>
        <div className={classes}>
          <Dropzone
            onDragLeave={this.onDragLeaveHandle}
            onDragEnter={this.onDragEnterHandle}
            onDrop={(files) => {
              uploadDocument(request, files)
            }}
          >
            <div className='dropzone__header'>
              {trans('global.dropzone-settlement')} / {trans('global.dropzone-receipt')}
            </div>
            <div className='dropzone__icon'>
              <Icon type='document_add' className='is-gradient-success' />
            </div>
            <div className={dropzoneProgressClasses} />
            <span className='dropzone__title'>{trans('global.dropzone-title')}</span>
            <span className='dropzone__subtitle'>{trans('global.dropzone-subtitle')}</span>
          </Dropzone>
        </div>
        {uploadingInProgress && <LoadingOverlay />}
      </div>
    ) : (
      ''
    )
  }
}

UploadCell.propTypes = {
  uploadDocument: PropTypes.func.isRequired,
  request: PropTypes.object.isRequired,
}

export { UploadCell }
export default { UploadCell }
