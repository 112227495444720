import { Loader } from '../../ui/LoadingOverlay/Loader'
import React from 'react'
import Button from '../../ui/ButtonComponent'
import { trans } from '../../../trans'
import Regular from '../../Formik/Fields/Regular'

const SecurityChangePin = (props) => {
  const { handleSubmit, isSubmitting, subjectUser } = props

  return (
    <form onSubmit={handleSubmit}>
      {isSubmitting && <Loader />}
      <div className='user-profile__fields-wrapper'>
        <div className='user-profile__fields'>
          {_.isUndefined(subjectUser) && (
            <Regular type='password' label={trans('user.current_pin')} name='current_pin' />
          )}

          <Regular type='password' label={trans('user.new_pin')} name='new_pin' />

          <Regular
            type='password'
            label={trans('user.new_pin_confirmation')}
            name='new_pin_confirmation'
          />
        </div>
      </div>
      <div className='is-margin-top'>
        <Button primary pull_end type='submit'>
          {trans('user.change-pin')}
        </Button>
      </div>
    </form>
  )
}

export default SecurityChangePin
export { SecurityChangePin }
