import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Icon from '../ui/IconComponent'
import { InputValid } from '../../ui'
import { InputErrorComponent } from '../InputErrorComponent'
import isTouchDevice from '../../utils/isTouchDevice'
import trans from '../../trans'

class EditableExchangeRate extends Component<any, any> {
  constructor(props) {
    super(props)

    this.state = {
      isEditing: false,
      exchangeRate: this.props.exchangeRate,
    }
  }

  setEditing = (e) => {
    e.preventDefault()

    if (this.props.canEdit) {
      this.setState({ isEditing: true })
    }
  }

  renderId = () => {
    const { canEdit } = this.props

    return (
      <div
        className='table-accordion__installment-date table-accordion__installment-date--flex-end'
        onClick={this.setEditing}
      >
        <span>{this.props.exchangeRate ? this.props.exchangeRate : '---'}</span>
        <span
          className='timeline-icon'
          style={{ opacity: canEdit ? '1' : '0' }}
          onClick={canEdit ? this.setEditing : () => {}}
        >
          <Icon type='edit' lg gradient />
        </span>
      </div>
    )
  }

  onChange = (exchangeRate) => {
    this.setState({
      exchangeRate,
      exchangeRateError: null,
    })
  }

  onBlur = (event) => {
    this.submitChange(event)
  }

  submitChange = (event) => {
    event.preventDefault()

    this.props
      .onChange(this.state.exchangeRate)
      .then(() => this.setState({ isEditing: false }))
      .catch((error) => {
        this.setState({ error: trans('error.server-error') })
      })
  }

  handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      this.submitChange(event)
    }
  }

  renderInput = () => {
    return (
      <div className='table-accordion__row-edit' onClick={(e) => e.preventDefault()}>
        <InputValid
          name='id'
          placeholder={' '}
          value={this.props.exchangeRate}
          autoFocus
          onChange={(value) => this.onChange(value)}
          onBlur={this.submitChange}
          onKeyDown={this.handleKeyDown}
          options={{ decimalScale: 4 }}
        >
          <InputErrorComponent errors={this.state.idError} />
        </InputValid>

        {isTouchDevice() && (
          <span className='timeline-icon' onClick={this.submitChange}>
            <Icon type='checked' lg gradient />
          </span>
        )}
      </div>
    )
  }

  render() {
    return this.state.isEditing ? this.renderInput() : this.renderId()
  }
}

EditableExchangeRate.propTypes = {
  exchangeRate: PropTypes.string,
  canEdit: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
}

export default EditableExchangeRate
export { EditableExchangeRate }
