import React, { memo } from 'react'
import PropTypes from 'prop-types'

function Sticky({ children, offset = 0 }) {
  return (
    <div className='sticky-element' style={{ top: `${offset}px` }}>
      {children}
    </div>
  )
}

Sticky.propTypes = {
  children: PropTypes.element.isRequired,
  offset: PropTypes.number,
}

Sticky.defaultProps = {
  offset: 0,
}

export default memo(Sticky)
