import React from 'react'
import { AccordionPanel } from '../../ui/AccordionPanel/index'
import { Label, TYPE_WARNING } from '../../ui/Label/index'
import { Col, Row } from '../../../ui/Grid/index'
import { RouteManager } from '../../../containers/RouteManager/index'
import { RequestInfo } from './RequestInfo'
import { DashboardRequestsDocuments } from '../DashboardRequestDocuments/index'
import { Link } from 'react-router-dom'
import Button from '../../ui/ButtonComponent'
import { DashboardWaitingToSettlementManager } from '../../../containers/DashboardWaitingToSettlementManager/index'
import { SectionHeader } from '../../ui/SectionHeader/index'
import { Section } from '../../ui/Section/index'
import moment from 'moment'
import Icon from '../../ui/IconComponent'
import { DashboardRequestsContent } from '../DashboardRequestsContent'
import trans from '../../../trans'
import TruncateBreakable from '../../TruncateBreakable/TruncateBreakable'
import { Loader } from '../../ui/LoadingOverlay/Loader'

const renderDays = (date) => {
  const date1 = moment(date)
  const date2 = moment()

  return date2.diff(date1, 'days')
}

export const DashboardWaitingToSettlement = (props) => {
  if (!props.counter) {
    return null
  }

  return (
    <div className='dashboard-current-trips'>
      <RouteManager>
        {({ push, getRouteByName }) => {
          return (
            <AccordionPanel
              bar={
                <h1>
                  <Label level={TYPE_WARNING}>{props.counter}</Label>
                  {trans('dashboard-page.requests-waiting-to-settlement')}
                </h1>
              }
              defaultOpened={false}
              name='waiting-to-settlement'
              expandOnBarClick
              barTheme='secondary'
              noShadow
            >
              {({ isOpen }) => {
                return (
                  <DashboardWaitingToSettlementManager>
                    {({
                      items,
                      isLoading,
                      isLoaded,
                      instanceCurrency,
                      uploadDocument,
                      currentUser,
                    }) => {
                      const _isLoading = isLoading || !isLoaded

                      return isOpen === true && items.length > 0 ? (
                        <DashboardRequestsContent
                          className='dashboard-current-trips__row'
                          items={items}
                          isLoading={_isLoading}
                        >
                          {items.map((request) => {
                            const passedDays = renderDays(request['trip_ends'])

                            return (
                              <div key={request['slug']}>
                                <Row>
                                  <Col xs={8}>
                                    <Section noBorder noMargin>
                                      <SectionHeader
                                        noMargin
                                        caption={
                                          <TruncateBreakable text={request.name} lines={2} />
                                        }
                                      />
                                    </Section>
                                  </Col>
                                  <Col xs={4}>
                                    {request['type'] === 'trip' && passedDays >= 1 && (
                                      <div className='dashboard-current-trips__trip-info'>
                                        <Icon
                                          type='warning'
                                          className='is-gradient-warning dashboard-current-trips__icon'
                                        />
                                        <span>
                                          {trans('dashboard-page.time-left-1')} {passedDays}{' '}
                                          {trans('dashboard-page.time-left-2')}
                                        </span>
                                      </div>
                                    )}
                                  </Col>
                                </Row>
                                <Row key={request['slug']} className='dashboard-current-trips__row'>
                                  <Col xs={12} sm={3} md={2} style={{ paddingRight: '40px' }}>
                                    <RequestInfo request={request} />
                                  </Col>

                                  <Col
                                    xs={12}
                                    sm={9}
                                    md={10}
                                    style={{ position: 'relative', paddingRight: '165px' }}
                                  >
                                    <DashboardRequestsDocuments
                                      getRouteByName={getRouteByName}
                                      push={push}
                                      documents={request['documents']}
                                      instanceCurrency={instanceCurrency}
                                      uploadDocument={uploadDocument}
                                      request={request}
                                      currentUser={currentUser}
                                    />
                                    <Link
                                      to={getRouteByName('main', `${request['type']}RequestShow`, {
                                        id: request['slug'],
                                      })}
                                      className='dashboard-current-trips__show-button'
                                    >
                                      <Button outline xs>
                                        {trans('dashboard-page.see-details')}
                                      </Button>
                                    </Link>
                                  </Col>
                                  <div className='dashboard-current-trips__hr' />
                                </Row>
                              </div>
                            )
                          })}
                        </DashboardRequestsContent>
                      ) : (
                        <div className='has-loader pt-12'>
                          <Loader />
                        </div>
                      )
                    }}
                  </DashboardWaitingToSettlementManager>
                )
              }}
            </AccordionPanel>
          )
        }}
      </RouteManager>
    </div>
  )
}
