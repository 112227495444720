import { stringify } from 'query-string'
import { FlightsRequest } from './FlightsRequest'

class FlightsFilterRequest extends FlightsRequest {
  constructor(
    searchUUID,
    targetOffer = null,
    target_departure_time,
    return_departure_time,
    flight_class,
    direct_only,
    restrict_to_fba,
    sort,
  ) {
    super()
    this.searchUUID = searchUUID
    this.targetOffer = targetOffer
    this.target_departure_time = target_departure_time
    this.return_departure_time = return_departure_time
    this.flight_class = flight_class
    this.direct_only = direct_only
    this.restrict_to_fba = restrict_to_fba
    this.sort = sort
  }

  toString() {
    return stringify({
      'target_departure_time[from]': FlightsRequest.minutesToHour(this.target_departure_time.min),
      'target_departure_time[to]': FlightsRequest.minutesToHour(this.target_departure_time.max),
      'return_departure_time[from]': FlightsRequest.minutesToHour(this.return_departure_time.min),
      'return_departure_time[to]': FlightsRequest.minutesToHour(this.return_departure_time.max),
      flight_class: this.flight_class,
      direct_only: FlightsRequest.stringBoolToNumber(this.direct_only),
      restrict_to_fba: FlightsRequest.stringBoolToNumber(this.restrict_to_fba),
      sort: this.sort,
    })
  }
}

export default FlightsFilterRequest
export { FlightsFilterRequest }
