import React from 'react'
import { connect } from 'react-redux'
import { fromJS } from 'immutable'
import { compose } from 'redux'
import { createAutoSaveForm, getFormValues } from '../../utils/forms'
import { TargetPoint as Form } from '../../components/BorderCrossings/TargetPoint'

export const save = (name, value, dispatch, props) => {
  const {
    targetPoints: {
      actions: { save },
    },
    point,
    data,
  } = props

  if (point.draft) {
    if (data.date && data.target) {
      return save(point, { date: data.date, location: data.target })
    }
  } else {
    return save(point, { date: data.date, location: data.target })
  }

  return new Promise((resolve) => resolve())
}

const AutoSaveForm = createAutoSaveForm(Form, {
  save,
  destroyOnUnmount: false,
  keepDirtyOnReinitialize: true,
  timeout: 0,
})

const mapStateToProps = (state, props) => {
  const { point, form, targetPoints, request } = props

  return {
    initialValues: fromJS({
      target: point.location,
      date: point.date,
    }),
    form,
    data: getFormValues(form, state),
    request,
    targetPoints,
    point,
  }
}

const withConnect = connect(mapStateToProps)

const DestinationForm = compose(withConnect)(AutoSaveForm)

export { DestinationForm }
export default DestinationForm
