export const parseAddress = (data, formatted_address = null) => {
  let components = data.address_components

  const result = {
    country: components.filter((item) => item.types[0] === 'country'),
    city: components.filter((item) => item.types[0] === 'locality'),
    county: components.filter((item) => item.types[0] === 'administrative_area_level_2'),
    province: components.filter((item) => item.types[0] === 'administrative_area_level_1'),
    streetNumber: components.filter((item) => item.types[0] === 'street_number'),
    street: components.filter((item) => item.types[0] === 'route'),
    postal_town: components.filter((item) => item.types[0] === 'postal_town'),
  }

  let country = null
  let country_code = null
  let address = null
  let city = null
  let province = null

  if (result.country.length) {
    country = result.country[0].long_name
    country_code = result.country[0].short_name
  }

  if (result.city.length) {
    city = result.city[0].long_name
  }

  if (!result.city.length && result.country.length) {
    city = result.country[0].long_name
  }

  if (!city && result.postal_town.length) {
    city = result.postal_town[0].long_name
  }

  if (!city && result.county.length) {
    city = result.county[0].long_name
  }

  if (result.province.length) {
    province = result.province[0].long_name
  }

  if (result.street.length) {
    address = result.street[0].long_name
  }

  if (result.streetNumber.length && result.street.length) {
    address += ' ' + result.streetNumber[0].long_name
  }

  return {
    country,
    country_code,
    city,
    province,
    address,
    formatted_address: formatted_address ? formatted_address : data.formatted_address,
  }
}
