import React from 'react'
import { isUndefined } from 'lodash'
import { ModelRulesManger } from '../../containers/ModelRulesManger'
import { Rules } from '../Rules'
import { trans } from '../../trans'

class DocumentAccountRules extends React.Component<any, any> {
  render() {
    const {
      document: { rules: documentRules, id },
    } = this.props
    return (
      <ModelRulesManger
        modelRules={documentRules}
        socketChannel={`App.Document.${id}`}
        socketEvent={`.App\\Events\\DocumentRulesChanged`}
      >
        {({ rules, isLoading }) => {
          if (isLoading) {
            return null
          }

          return (
            <Rules
              rules={rules}
              types={['document_is_valid_for_settlement', 'document_is_valid_for_accounting']}
              message={trans('document-rule.account-message')}
            />
          )
        }}
      </ModelRulesManger>
    )
  }
}

export { DocumentAccountRules }
export default { DocumentAccountRules }
