import React from 'react'
import Button from '../../ui/ButtonComponent'
import { ICellRendererParams } from 'ag-grid-community'

export interface ButtonRendererProps extends ICellRendererParams {
  label: string
  primary?: boolean
  secondary?: boolean
  danger?: boolean
}

export default function ButtonRenderer({ label, primary, secondary, danger }: ButtonRendererProps) {
  return (
    <div>
      <Button
        className={'ag-grid__button'}
        xxs
        primary={primary}
        secondary={secondary}
        danger={danger}
      >
        {label}
      </Button>
    </div>
  )
}
