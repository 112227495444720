import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators, compose } from 'redux'
import {
  createAccountingTravelExpense,
  createMileageAllowance,
  fetchRequest,
  getRequestData,
  getRoutes,
  getSummary,
  removeAccountingTravelExpense,
  reset,
  saveAccountingTravelExpense,
  setRequest,
  setRequestAsCompleted,
  setSubsummary,
} from '../../store/app/request-mileage-allowance'
import { change } from 'redux-form/immutable'
import socketProvider from '../SocketProvider'
import {
  fetchAccountingAccounts,
  isLoading as accountingAccountsIsLoading,
} from '../../store/app/accounting-account'
import { reset as resetAccountDimensions } from '../../store/app/account-dimensions'
import { fetchMpk, isLoading as isMpkLoading } from '../../store/app/mpks'
import { fetchProjects, isLoading as isProjectLoading } from '../../store/app/projects'
import { fetchCurrencies, getCurrenciesData } from '../../store/app/document-account-page'

class RequestMileageAllowancePageManagerBase extends React.Component<any, any> {
  constructor(props) {
    super(props)
  }

  componentDidMount() {
    const {
      reset,
      requestId,
      fetchRequest,
      setSubsummary,
      fetchProjects,
      fetchAccountingAccounts,
      fetchMpk,
      fetchCurrencies,
      socket,
    } = this.props

    reset()

    fetchRequest(requestId).then(() => {
      socket.subscribe(`App.Subsummary.${requestId}`)('.App\\Events\\RequestSubsummaryChanged')(
        (data) => {
          delete data['socket']
          setSubsummary(data)
        },
      )
    })
    fetchProjects()
    fetchAccountingAccounts()
    fetchMpk()
    fetchCurrencies()
  }

  componentWillUnmount() {
    const { requestId, socket, resetAccountDimensions } = this.props
    socket.unsubscribe(`App.Subsummary.${requestId}`)('.App\\Events\\RequestSubsummaryChanged')

    resetAccountDimensions()
  }

  setRequestAsCompletedHandler() {
    const {
      requestData: { data: request },
      setRequestAsCompleted,
    } = this.props
    return setRequestAsCompleted(request)
  }

  render() {
    const {
      children,
      requestData,
      routes,
      summary,
      change,
      setRequest,
      isMpkLoading,
      isProjectLoading,
      accountingAccountsIsLoading,
      isAccountDimensionLoading,
      saveAccountingTravelExpense,
      removeAccountingTravelExpense,
      currentUser,
      currenciesData,
      createAccountingTravelExpense,
      createMileageAllowance,
    } = this.props

    const isLoading =
      requestData.isLoading ||
      !requestData.isLoaded ||
      !currenciesData.isLoaded ||
      isMpkLoading ||
      isProjectLoading ||
      accountingAccountsIsLoading ||
      isAccountDimensionLoading

    const renderProps = {
      request: requestData.data,
      isLoading,
      routes,
      summary,
      change,
      setRequest,
      currencies: currenciesData.data,
      saveAccountingTravelExpense,
      removeAccountingTravelExpense,
      currentUser,
      createAccountingTravelExpense,
      createMileageAllowance,
      setRequestAsCompleted: this.setRequestAsCompletedHandler.bind(this),
    }

    return children(renderProps)
  }
}

RequestMileageAllowancePageManagerBase.propTypes = {
  requestId: PropTypes.string.isRequired,
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      reset,
      fetchRequest,
      change,
      setSubsummary,
      setRequest,
      fetchMpk,
      fetchProjects,
      fetchCurrencies,
      fetchAccountingAccounts,
      saveAccountingTravelExpense,
      removeAccountingTravelExpense,
      createAccountingTravelExpense,
      setRequestAsCompleted,
      resetAccountDimensions,
      createMileageAllowance,
    },
    dispatch,
  )
}

const mapStateToProps = (state) => {
  return {
    requestData: getRequestData(state),
    currenciesData: getCurrenciesData(state),
    summary: getSummary(state),
    routes: getRoutes(state),
    isMpkLoading: isMpkLoading(state),
    isProjectLoading: isProjectLoading(state),
    accountingAccountsIsLoading: accountingAccountsIsLoading(state),
    currentUser: state.get('global').get('currentUser'),
  }
}

const withConnect = connect(mapStateToProps, mapDispatchToProps)
const withSocket = socketProvider()

const RequestMileageAllowancePageManager = compose(
  withConnect,
  withSocket,
)(RequestMileageAllowancePageManagerBase)

export default RequestMileageAllowancePageManager
export { RequestMileageAllowancePageManager }
