import React from 'react'
import { Helmet } from 'react-helmet'
import { config } from '../../config'
import LoginFormContainer from './LoginFormContainer'
import queryString from 'query-string'
import trans from '../../trans'

class LoginPage extends React.Component<any, any> {
  renderMessage() {
    const {
      location: { pathname, search },
    } = window
    const { query } = queryString.parseUrl(`${pathname}${search}`)

    if (query.msg === 'reset') {
      return (
        <div className='text-center'>
          <p>{trans(`login-page.login-msg-${query.msg}`)}</p>
        </div>
      )
    }
    return null
  }

  render() {
    return (
      <div>
        <Helmet title={trans(config.titles.login)} />
        {this.renderMessage()}
        <LoginFormContainer />
      </div>
    )
  }
}

export default LoginPage
export { LoginPage }
