import PropTypes from 'prop-types'
import React from 'react'
import trans from '../../../trans'

export const ProjectInfo = ({ project, mpk, purpose, isModuleActive }) => (
  <span className='dashboard-requests-to-accept__project-info'>
    {mpk['name']} ({mpk['code']})
    <br />
    {isModuleActive('projects') ? (
      <span>
        {project['name']} ({project['code']})
      </span>
    ) : null}
    <p>{purpose}</p>
  </span>
)

ProjectInfo.propTypes = {
  // project: PropTypes.object.isRequired,
  purpose: PropTypes.string,
  mpk: PropTypes.object.isRequired,
}
