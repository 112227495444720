import React from 'react'
import { withFormik } from 'formik'
import { get } from 'lodash'
import APIClient from '../../services/APIClient'
import AddProvider from './DocumentAddProvider'
import { getInstance } from '../../store/app/instance'
import store from '../../store'

const AddProviderForm = withFormik({
  mapPropsToValues: (props) => ({
    name: props.suggestedProvider.name,
    registry_number: props.suggestedProvider.registry_number,
    country_id:
      props.suggestedProvider.country_id || getInstance(store.getState()).location.country_id,
    address: props.suggestedProvider.address,
    city: props.suggestedProvider.city,
    postcode: props.suggestedProvider.postcode,
    instance_id: props.instanceId,
    company_slug: props.companySlug,
    source: props.suggestedProvider.source || 'user',
    erp_id: props.suggestedProvider.erp_id,
  }),
  handleSubmit: (values, { props, setSubmitting, setErrors, resetForm }) => {
    if (props.suggestedProvider.disposable) {
      resetForm()
      setSubmitting(false)
      props.closePopup()
      props.submitCallback(values)

      return
    }

    APIClient.createProvider(values)
      .then((response) => {
        resetForm()
        setSubmitting(false)
        props.closePopup()
        props.submitCallback(response.data)
      })
      .catch((response) => {
        setErrors(get(response, 'alerts.0.errors', {}))
        setSubmitting(false)
        props.submitCallback(null)
      })
  },
  enableReinitialize: true,
  displayName: 'Provider',
})(AddProvider)

export { AddProviderForm }
export default AddProviderForm
