import React from 'react'
import { Tooltip } from '../Tooltip'
import Icon from '../ui/IconComponent'
import { trans } from '../../trans'

class BrokenRules extends React.Component<any, any> {
  render() {
    const messages = this.props.messages.map((item, index) => {
      return <div key={index}>{trans(item.message.name, item.message.params)}</div>
    })

    return (
      <div className='summary-warnings'>
        <Tooltip html={<div>{messages}</div>}>
          <Icon
            type='warning'
            className='summary-warnings__icon is-color-warning is-gradient-warning'
          />
        </Tooltip>
      </div>
    )
  }
}

BrokenRules.propTypes = {}

export { BrokenRules }
export default BrokenRules
