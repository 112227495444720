import React from 'react'
import { trans } from '../../trans'
import { isUndefined } from 'lodash'

class Preview extends React.Component<any, any> {
  constructor(props) {
    super(props)
  }

  _renderMPKLabel() {
    const {
      request: { mpk },
    } = this.props
    return (
      <p>
        {mpk.name} ({mpk.code})
      </p>
    )
  }

  _renderProjectLabel() {
    const {
      request: { project_id },
      projects,
    } = this.props
    const project = projects.find((project) => project.id === project_id)

    if (project) {
      return (
        <p>
          {project.name} ({project.code})
        </p>
      )
    }

    return null
  }

  render() {
    const {
      request: { purpose },
      isModuleActive,
    } = this.props

    return (
      <div>
        <div className='row'>
          <label className='form-group form-group--label-top xs-12'>
            <span className='form-group__label form-group__label'>{trans('global.mpk')}</span>

            <div className='form-group__input-wrapper form-group__input-wrapper--no-margin'>
              {this._renderMPKLabel()}
            </div>
          </label>
        </div>

        {isModuleActive('projects') ? (
          <div className='row'>
            <label className='form-group form-group--label-top xs-12'>
              <span className='form-group__label form-group__label'>{trans('global.project')}</span>

              <div className='form-group__input-wrapper form-group__input-wrapper--no-margin'>
                {this._renderProjectLabel()}
              </div>
            </label>
          </div>
        ) : null}

        {purpose ? (
          <div className='row'>
            <label className='form-group form-group--label-top xs-12'>
              <span className='form-group__label form-group__label'>
                {trans('request.description')}
              </span>

              <div className='form-group__input-wrapper form-group__input-wrapper--no-margin'>
                <p>{purpose}</p>
              </div>
            </label>
          </div>
        ) : null}
      </div>
    )
  }
}

export { Preview }
export default { Preview }
