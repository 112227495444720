import _ from 'lodash'
import { DocumentCell } from '../DocumentCell'
import { TYPE_TRIP } from '../../constants/request'
import { MileageAllowanceCell } from '../MileageAllowanceCell'
import React from 'react'
import { Col, Row } from '../../ui/Grid'
import { Section } from '../ui/Section'
import { SectionHeader } from '../ui/SectionHeader'
import trans from '../../trans'
import PropTypes from 'prop-types'

class ExpenseAccountingDocuments extends React.Component<any, any> {
  renderDocuments() {
    const { expenseRequest, push, getRouteByName, instanceCurrency, currentUser } = this.props
    const { documents } = expenseRequest

    if (_.isEmpty(documents)) {
      return null
    }

    return documents
      .filter(({ type }) => type === 'accounting')
      .map((document) => {
        return (
          <DocumentCell
            width='217px'
            document={document}
            key={`document-cell-${document['id']}`}
            push={push}
            getRouteByName={getRouteByName}
            instanceCurrency={instanceCurrency}
            request={expenseRequest}
            currentUser={currentUser}
          />
        )
      })
  }

  renderMileageAllowance() {
    const { expenseRequest, push, getRouteByName } = this.props

    if (expenseRequest.type !== TYPE_TRIP) {
      return null
    }

    if (!expenseRequest.abilities.showTripRequestLumpSum) {
      return null
    }

    return (
      <MileageAllowanceCell
        width='217px'
        request={expenseRequest}
        push={push}
        getRouteByName={getRouteByName}
      />
    )
  }

  render() {
    return (
      <Row>
        <Col xs={12}>
          <Section>
            <SectionHeader caption={trans('document.accounting-documents')} />

            <Row>
              {this.renderMileageAllowance()}
              {this.renderDocuments()}
            </Row>
          </Section>
        </Col>
      </Row>
    )
  }
}

ExpenseAccountingDocuments.propTypes = {
  expenseRequest: PropTypes.object.isRequired,
  push: PropTypes.func.isRequired,
  getRouteByName: PropTypes.func.isRequired,
  instanceCurrency: PropTypes.object.isRequired,
  currentUser: PropTypes.object.isRequired,
}

export default ExpenseAccountingDocuments
