import PropTypes from 'prop-types'
import React from 'react'
import classnames from 'classnames'
import _ from 'lodash'
import { LoadingOverlay } from '../LoadingOverlay/LoadingOverlay'
import trans from '../../../trans'

class TBody extends React.Component<any, any> {
  render() {
    const { children, isLoading, alternativeLabel, hideNoResultsLabel } = this.props
    const classes = classnames({
      'table-accordion__body': true,
      'table-accordion__body--is-loading': isLoading,
    })
    const renderChildren = !_.isEmpty(children)

    return (
      <div className={classes}>
        {renderChildren && children}
        {!renderChildren && (
          <p className='text-center'>
            {!hideNoResultsLabel ? alternativeLabel || trans('global.no-results') : '\n'}
          </p>
        )}
        {isLoading && <LoadingOverlay />}
      </div>
    )
  }
}

TBody.propTypes = {
  children: PropTypes.node,
  isLoading: PropTypes.bool,
  alternativeLabel: PropTypes.string,
  hideNoResultsLabel: PropTypes.bool,
}

export { TBody }
export default TBody
