import PropTypes from 'prop-types'
import React from 'react'
import { isUndefined } from 'lodash'
import { trans } from '../../trans'
import Icon from '../ui/IconComponent'
import { includes } from 'lodash'

class Rules extends React.Component<any, any> {
  renderRule(rule) {
    return <li key={rule.id}>- {trans(rule.message.name, rule.message.params)}</li>
  }

  render() {
    const { rules, types = [], message } = this.props

    if (!rules.length) {
      return null
    }

    const flags = new Set()

    const filtered = rules.filter((entry) => types.includes(entry.ruleName))
    const uniqueRules = filtered.filter((entry) => {
      if (flags.has(entry.message.name)) {
        return false
      }
      flags.add(entry.message.name)
      return true
    })

    const renderRules = uniqueRules.map((rule, id) => this.renderRule({ id, ...rule }))

    if (!renderRules.length) {
      return null
    }

    return (
      <div className='panel' style={{ width: '100%' }}>
        <div className='panel__content'>
          <div className='request-summary-warnings'>
            <p className='request-summary-warnings__warning-header'>
              <Icon
                type='warning'
                className='request-summary-warnings__warning-icon is-gradient-warning'
              />
              {message}
            </p>
            <ul>{renderRules}</ul>
          </div>
        </div>
      </div>
    )
  }
}

Rules.propTypes = {
  message: PropTypes.string.isRequired,
  rules: PropTypes.array.isRequired,
  types: PropTypes.array.isRequired,
}

export { Rules }
export default { Rules }
