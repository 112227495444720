import React, { Component, Fragment } from 'react'
import { FormField } from '../../ui/Form'
import { trans } from '../../../trans'
import PropTypes from 'prop-types'
import Button from '../../ui/ButtonComponent'
import { Field } from 'redux-form/immutable'
import alwaysOnTop from '../../../utils/alwaysOnTop'

class SearchFields extends Component<any, any> {
  onFocusDate = (e) => {
    alwaysOnTop('.timeline.accordion', e.target)
  }

  render() {
    const { travelers } = this.props
    const {
      hotelsBooking: {
        actions: { setFacility },
      },
    } = this.props

    return (
      <Fragment>
        <div>
          <div className='accommodation__inputs'>
            <div className='accommodation__input-container'>
              <Field
                name='location'
                type='location'
                component={FormField}
                label={trans('accommodation.location')}
                immutable
                autoFocus
                labeltop
                onFocus={this.onFocusDate}
              />
            </div>
          </div>
          <div className='accommodation__inputs'>
            <div className='accommodation__input-container'>
              <Field
                name='arrival_at'
                type='datepicker'
                component={FormField}
                label={trans('accommodation.arrival-date')}
                labeltop
                onFocus={this.onFocusDate}
              />
            </div>

            <div className='accommodation__input-container'>
              <Field
                name='departure_at'
                type='datepicker'
                component={FormField}
                label={trans('accommodation.departure-date')}
                labeltop
                onFocus={this.onFocusDate}
              />
            </div>
          </div>

          {travelers.length > 1 && (
            <div className='accommodation__inputs'>
              <div className='accommodation__input-container'>
                <Field
                  name='request_travelers'
                  type='traveler-select'
                  options={travelers}
                  component={FormField}
                  label={trans('hotels-booking.request-traveler-field-label')}
                  immutable
                  labeltop
                />
              </div>

              <div className='accommodation__input-container'>
                <Field
                  name='rooms'
                  type='rooms'
                  component={FormField}
                  label={trans('hotels-booking.rooms-field-label')}
                  onChange={() => {
                    this.props.roomChanged()
                  }}
                  immutable
                  labeltop
                />
              </div>
            </div>
          )}

          <div className='accommodation__inputs'>
            <div className='accommodation__input-container'>
              <Field
                name='breakfast'
                type='checkbox'
                label={trans('hotels-booking.breakfast-included')}
                onChange={(event, value) => {
                  setFacility('breakfast', value)
                }}
                component={FormField}
                inputOnly={true}
              />
            </div>
          </div>
        </div>

        <div className='accommodation__buttons-wrapper'>
          <div className='accommodation__buttons'>
            <div className='accommodation__buttons-wrapper'>
              <div className='accommodation__buttons'>
                <Button
                  className='accommodation__button-resign is-margin-right-small'
                  secondary
                  outline
                  xs
                  onClick={this.props.onResign}
                >
                  {trans('hotels-booking.resign-from-searching')}
                </Button>
                <Button
                  className='accommodation__button-search'
                  primary
                  type='submit'
                  onClick={this.props.onSearch}
                >
                  {trans('hotels-booking.search')}
                </Button>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    )
  }
}

SearchFields.propTypes = {
  hotelsBooking: PropTypes.object.isRequired,
  onResign: PropTypes.func.isRequired,
  onSearch: PropTypes.func.isRequired,
  travelers: PropTypes.array.isRequired,
  roomChanged: PropTypes.func,
}

export default SearchFields
export { SearchFields }
