import React from 'react'
import { bindActionCreators } from 'redux'
import {
  getCurrentUser,
  isUserProfileLoading,
  fetchUserProfile,
  resetProfile,
  setProfile,
} from '../userProfileSlice'
import { connect } from 'react-redux'

const userProfile =
  (resetOnMount = false) =>
  (Component) => {
    class UserProfileProvider extends React.Component<any, any> {
      componentDidMount() {
        const { userProfile } = this.props

        if (resetOnMount) {
          userProfile.actions.reset()
          userProfile.actions.fetch()
        }
      }

      render() {
        return <Component<any, any> {...this.props} />
      }
    }

    const mapStateToProps = (state, props) => {
      return {
        data: getCurrentUser(state),
        isFetching: isUserProfileLoading(state),
        // countries: selector(state)('countries', []),
      }
    }

    const mapDispatchToProps = (dispatch, props) => {
      return bindActionCreators(
        {
          reset: resetProfile,
          set: setProfile,
          fetch: fetchUserProfile,
        },
        dispatch,
      )
    }

    const mergedProps = (selectors, actions, own) => {
      return {
        ...own,
        userProfile: {
          selectors,
          actions,
          ...own.userProfile,
        },
      }
    }

    UserProfileProvider.propTypes = {}

    return connect(mapStateToProps, mapDispatchToProps, mergedProps)(UserProfileProvider)
  }

export { userProfile }
export default userProfile
