import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import {
  BOOKING_FAILED,
  BOOKING_NEW,
  BOOKING_OFFER_CHANGED,
  BOOKING_SUCCESS,
  BOOKING_WAITING_FOR_CONFIRM,
} from '../../store/app/hotels-booking'
import { BOOKING_EXPIRED } from '../../store/app/trains-booking'
import { trans } from '../../trans'
import classNames from 'classnames'
import { get } from 'lodash'

const ReservationStatus = (props) => {
  let status = get(props, 'selectedOption.booking', null)
  // const callback =  get(props, 'callback', null);

  if (status === BOOKING_WAITING_FOR_CONFIRM || status === BOOKING_NEW) {
    return trans('trains-booking.waiting-for-reservation')
  }

  const statusClass = classNames({
    'selected-offer__status': true,
    'selected-offer__status--success': status === BOOKING_SUCCESS,
    'selected-offer__status--warning': status === BOOKING_OFFER_CHANGED,
    'selected-offer__status--error': status === BOOKING_FAILED || status === BOOKING_EXPIRED,
  })

  let label = null

  if (status === BOOKING_OFFER_CHANGED) {
    label = trans('hotels-booking.offer-changed')
  }

  if (status === BOOKING_FAILED) {
    label = trans('trains-booking.booking-failed')
  }

  if (status === BOOKING_EXPIRED) {
    label = trans('trains-booking.booking-expired')
  }

  if (status === BOOKING_SUCCESS) {
    label = trans('trains-booking.booking-success')
  }

  if (label) {
    return (
      <span className={statusClass}>
        <span className='selected-offer__status-message'>{label}</span>
      </span>
    )
  }

  return null
}

ReservationStatus.propTypes = {
  selectedOption: PropTypes.object.isRequired,
}

export default ReservationStatus
export { ReservationStatus }
