import React, { Component } from 'react'
import { connect } from 'react-redux'
import { LoginFormComponent } from '../../components/LoginFormComponent'
import { Redirect, withRouter } from 'react-router-dom'
import { makeSelectSession } from '../App/selectors'
import { makeSelectErrors } from './selectors'
import { compose } from 'redux'
import { userLoginAction } from './actions'
import { parseQuery } from '../RouteManager/RouteManager'
import { getCurrentLang } from '../../utils/lang'
import { getTranslations } from '../../store/app/instance'

class LoginFormContainer extends Component<any, any> {
  constructor(props) {
    super(props)

    this.state = {
      email: '',
      password: '',
      errors: {},
    }
  }

  submitHandle = (e) => {
    const lang = getCurrentLang()
    this.props.loginUser(this.state.email, this.state.password, lang)
    e.preventDefault()
  }

  changeHandle = (e) => {
    const name = e.target.getAttribute('name')
    this.setState({ [name]: e.target.value })
  }

  render() {
    const {
      location: { search },
    } = this.props
    if (this.props.session.isAuthenticated) {
      const query = parseQuery(search)
      const redirect = query['redirect'] || '/dashboard'
      return <Redirect to={redirect} />
    }
    return (
      <LoginFormComponent
        onLogin={this.submitHandle}
        onChangeInput={this.changeHandle}
        email={this.state.email}
        password={this.state.password}
        errors={this.props.errors}
      />
    )
  }
}

const mapDispatchToProps = (dispatch) => ({
  loginUser: (email, password, lang) => dispatch(userLoginAction(email, password, lang)),
})

const mapStateToProps = (state) => ({
  session: makeSelectSession()(state),
  errors: makeSelectErrors()(state),
  translations: getTranslations(state),
})

const withConnect = connect(mapStateToProps, mapDispatchToProps)
const composed = compose(withRouter, withConnect)(LoginFormContainer)

export default composed
