import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { get } from 'lodash'
import PropTypes from 'prop-types'

export const MODULE_MOUNT_POINT = 'range-map'

const RESET = MODULE_MOUNT_POINT + '::reset'
const SET = MODULE_MOUNT_POINT + '::set'
const SET_RANGE = MODULE_MOUNT_POINT + '::set-range'
const SET_COORDINATES = MODULE_MOUNT_POINT + '::set-coordinates'

const getInitialState = () => {
  return {
    radius: null,
    coordinates: {
      lat: null,
      lng: null,
    },
    initialized: false,
  }
}

// actions Creators
const reset = (name) => () => (dispatch) => {
  dispatch({
    type: RESET,
    payload: {
      name,
    },
  })
}

const set = (name) => (data) => (dispatch) => {
  dispatch({
    type: SET,
    payload: {
      name,
      data,
    },
  })
}

export const setRange = (name) => (radius) => (dispatch) => {
  dispatch({
    type: SET_RANGE,
    payload: {
      name,
      radius,
    },
  })
}

export const setCoordinates = (name) => (coordinates) => (dispatch) => {
  dispatch({
    type: SET_COORDINATES,
    payload: {
      name,
      coordinates,
    },
  })
}

//actions
const resetAction = (state, payload) => {
  return {
    ...state,
    [payload.name]: getInitialState(),
  }
}

const setAction = (state, payload) => {
  return {
    ...state,
    [payload.name]: {
      ...state[payload.name],
      ...payload.data,
      initialized: true,
    },
  }
}

const setRangeAction = (state, payload) => {
  return {
    ...state,
    [payload.name]: {
      ...state[payload.name],
      radius: payload.radius,
    },
  }
}

const setCoordinatesAction = (state, payload) => {
  return {
    ...state,
    [payload.name]: {
      ...state[payload.name],
      coordinates: {
        ...payload.coordinates,
      },
    },
  }
}

const actions = {
  [RESET]: resetAction,
  [SET]: setAction,
  [SET_RANGE]: setRangeAction,
  [SET_COORDINATES]: setCoordinatesAction,
}

//selectors
const getState = (state) => {
  return state.get(MODULE_MOUNT_POINT)
}

export const getRadius = (name) => (state) => {
  return get(getState(state), `${name}.radius`, null)
}

const getCoordinates = (name) => (state) => {
  return get(getState(state), `${name}.coordinates`, {
    lat: null,
    lng: null,
  })
}

const initialized = (name) => (state) => {
  return get(getState(state), `${name}.initialized`, null)
}

export const reducer = (state = {}, action) => {
  if (actions.hasOwnProperty(action.type)) {
    return actions[action.type](state, action.payload)
  }
  return state
}

const rangeMap =
  (resetOnMount = false) =>
  (Component) => {
    class RangeMap extends React.Component<any, any> {
      componentDidMount() {
        const { rangeMap } = this.props
        if (resetOnMount) {
          rangeMap.actions.reset()
          rangeMap.actions.set({
            radius: rangeMap.init.radius,
            coordinates: rangeMap.init.coordinates,
          })
        }
      }

      render() {
        return <Component<any, any> {...this.props} />
      }
    }

    const mapStateToProps = (state, props) => {
      const {
        rangeMap: {
          init: { name },
        },
      } = props
      return {
        radius: getRadius(name)(state),
        coordinates: getCoordinates(name)(state),
        initialized: initialized(name)(state),
      }
    }

    const mapDispatchToProps = (dispatch, props) => {
      const {
        rangeMap: {
          init: { name },
        },
      } = props
      return bindActionCreators(
        {
          reset: reset(name),
          set: set(name),
          setRange: setRange(name),
          setCoordinates: setCoordinates(name),
        },
        dispatch,
      )
    }

    const mergedProps = (selectors, actions, own) => {
      return {
        ...own,
        rangeMap: {
          selectors,
          actions,
          ...own.rangeMap,
        },
      }
    }

    RangeMap.propTypes = {
      rangeMap: PropTypes.shape({
        init: PropTypes.shape({
          radius: PropTypes.number,
          coordinates: PropTypes.shape({
            lat: PropTypes.number,
            lng: PropTypes.number,
          }),
        }),
      }),
    }

    return connect(mapStateToProps, mapDispatchToProps, mergedProps)(RangeMap)
  }

export { rangeMap }
export default rangeMap
