import APIClient from '../../../services/APIClient'

import {
  BOOK_OFFER,
  BOOKING_FAILED,
  BOOKING_NEW,
  CHANGE,
  CHANGE_STATUS,
  RESET,
  SEARCH_ANOTHER_ONE,
  SEARCH_STATUS_COMPLETE,
  SEARCH_STATUS_PENDING,
  SELECT_OFFER,
  SELECT_OFFER_OPTION,
  SET,
  SET_AGREEMENT,
  SET_OFFERS,
  STEP_LISTING,
  STEP_MAP,
  STEP_OPTIONS,
  STEP_SEARCHING,
  TOGGLE_SEARCH,
  VALUATE_OFFER,
  CHANGE_ERROR_MESSAGE_SLUG,
} from './index'
import { get, has, isEqual, mapValues } from 'lodash'
import { Accommodation } from '../../../models/timeline'
import { change as changeForm, stopSubmit } from 'redux-form'
import { STATUS_DRAFT } from '../../../constants/request'
import { Map } from 'immutable'
import {
  getFacilities,
  getFacility,
  getFilters,
  getInitialFacilities,
  getOffers,
  getQuery,
  getSearchUuid,
  getSelectedOffer,
  getSelectedOption,
  getSortBy,
  getStatus,
  isReservationSuccess,
} from './selectors'
import store from '../../../store'
import SocketProviderService from '../../../utils/SocketProvider'
import { getSelectedTravelersOrDefault } from '../request-traveler/helper'
import { deleteElement, saveElement, updateElementSearchUuid } from '../trip-timeline'
import { setSearchUuidForElement } from '../trip-request'
import HotelsFilterRequest from '../../../requests/HotelsFilterRequest'
import CancellableRequest from '../../../requests/CancellableRequest'
import { filter } from './filters'
import { sort } from './sort'
import { HotelsBookingSearchOffersResultsChanged } from './hotels-booking.model'
import { ChooseHotelOfferResponse } from '../../../types/hotel-offers'

const dispatchFiltering = (name, dispatch, getState, status) => {
  const state = getState()
  const offers = getOffers(name)(state)
  const filters = getFilters(name)(state)
  const sortBy = getSortBy(name)(state)

  dispatch(change(name)('isFiltering', true))

  const filtered = filter(filters, offers)
  const sorted = sort(sortBy, filtered, get(filters, 'breakfast', false))

  store.dispatch(change(name)('processedOffers', sorted))
  store.dispatch(change(name)('lastComputed', Date.now()))
  store.dispatch(change(name)('isFiltering', false))

  if (status) {
    store.dispatch(change(name)('statusAfterFiltering', status))
  }
}

// actions Creators
export const reset = (name) => () => (dispatch) => {
  dispatch(change(name)('isChanged', false))
  dispatch({
    type: RESET,
    payload: {
      name,
    },
  })
}

export const set = (name) => (data) => (dispatch) => {
  dispatch({
    type: SET,
    payload: {
      name,
      data,
    },
  })
}

export const change = (name) => (path, value) => (dispatch) => {
  dispatch({
    type: CHANGE,
    payload: {
      name,
      path,
      value,
    },
  })
}

export const setStepMap = (name) => () => (dispatch) => {
  dispatch(change(name)('step', STEP_MAP))
}

export const setStepSearching = (name) => () => (dispatch) => {
  dispatch(change(name)('step', STEP_SEARCHING))
}

export const setStepListing = (name) => () => (dispatch) => {
  dispatch(change(name)('step', STEP_LISTING))
}

export const setStepOptions = (name) => () => (dispatch) => {
  dispatch(change(name)('step', STEP_OPTIONS))
}

export const search =
  (name) =>
  (request, data = {}) =>
  (dispatch, getState) => {
    dispatch(change(name)('searching', true))
    dispatch(unsubscribeSearchResult(name))
    dispatch(change(name)('hasOffers', false))
    dispatch(change(name)('status', SEARCH_STATUS_PENDING))
    dispatch(change(name)('isChanged', false))
    dispatch(setInitialFacilities(name)({ breakfast: data.breakfast }))
    dispatch(
      setQuery(name)({
        location: data.location,
        arrival_at: data.arrival_at,
        departure_at: data.departure_at,
        request_travelers: data.request_travelers,
        rooms: data.rooms,
      }),
    )

    let location = data.location

    if (location instanceof Map) {
      location = location.toJS()
    }

    const request_travelers = getSelectedTravelersOrDefault(
      get(data, 'request_travelers', []),
      getState(),
    )
    const rooms = get(data, 'rooms', [])

    const requestData = {
      date_from: get(data, 'arrival_at', null),
      date_to: get(data, 'departure_at', null),
      rooms: rooms.length ? rooms : [1],
      request_travelers: request_travelers,
      lat: get(location, 'lat', null),
      long: get(location, 'long', null),
      city: get(location, 'city', null),
      country_code: get(location, 'country_code', null),
      formatted_address: get(location, 'formatted_address', null),
      range: get(data, 'range', 5),
    }

    const requestQuery = {
      with_breakfasts: getFacility(name)(getState())('breakfast'),
    }

    return APIClient.searchHotels(request, requestData, requestQuery)
      .then((response) => {
        dispatch(openResultsList(name)(response.data.uuid))
        dispatch(setStepSearching(name)())

        if (response.data.status === 'complete') {
          return APIClient.getOffersByUuid(response.data.uuid).then((response) => {
            dispatch(setOffers(name)(response.data))
            dispatch(setInitialized(name)(true))

            dispatch(change(name)('searching', false))
            return response
          })
        }
      })
      .catch((error) => {
        dispatch(change(name)('searching', false))

        const errors = mapValues(get(error, 'alerts.0.errors', {}), (error) => get(error, '0'))

        if (has(errors, 'location.formatted_address')) {
          errors.location = errors['location.formatted_address']
        }

        dispatch(stopSubmit(name, errors))
        return error
      })
  }

export const backToMap = (name) => () => (dispatch) => {
  dispatch(unsubscribeSearchResult(name))
  dispatch(setStepMap(name)())
}

export const backToListing = (name) => () => (dispatch) => {
  dispatch(setStepListing(name)())
}

export const setOffers =
  (name) =>
  ({ uuid, status, offers, roomAllocations, totalPaxes }) =>
  (dispatch, getState) => {
    if (offers.length) {
      dispatch(change(name)('hasOffers', true))
    }

    dispatch({
      type: SET_OFFERS,
      payload: {
        name,
        uuid,
        offers,
        status,
        roomAllocations,
        totalPaxes,
      },
    })

    dispatchFiltering(name, dispatch, getState, status)
    dispatch(change(name)('isChanged', false))
  }

export const subscribeSocket = (name) => () => (dispatch) => {
  dispatch(change(name)('socket.subscribed', true))
}

export const selectOffer = (name) => (uuid) => (dispatch, getState) => {
  dispatch({
    type: SELECT_OFFER,
    payload: {
      name,
      uuid,
    },
  })
  dispatch(setStepOptions(name)())

  const option = getSelectedOption(name)(getState())
  const reservationSuccess = isReservationSuccess(name)(getState())

  if (option && !reservationSuccess) {
    dispatch(subscribeReservationStatus(name))
  } else {
    dispatch(unsubscribeReservationStatus(name)())
  }
}

export const changeStatus = (name) => (offerUuid, optionUuid, status) => (dispatch) => {
  dispatch({
    type: CHANGE_STATUS,
    payload: {
      name,
      offerUuid,
      optionUuid,
      status,
    },
  })
}

export const changeErrorMessageSlug = (name) => (offerUuid, errorMessageSlug) => (dispatch) => {
  dispatch({
    type: CHANGE_ERROR_MESSAGE_SLUG,
    payload: {
      name,
      offerUuid,
      errorMessageSlug,
    },
  })
}

export const subscribeReservationStatus = (name) => (dispatch, getState) => {
  const searchUuid = getSearchUuid(name)(getState())
  const channelName = `App.Offer.${searchUuid}`
  const eventName = `.App\\Events\\ReservationStatusChangedEvent`

  SocketProviderService.subscribe(channelName)(eventName)((response) => {
    dispatch(
      changeErrorMessageSlug(name)(response.offer.offer_uuid, response.offer.error_message_slug),
    )
    dispatch(
      changeStatus(name)(
        response.offer.offer_uuid,
        response.offer.option_uuid,
        response.offer.status,
      ),
    )
  })
}

export const unsubscribeReservationStatus = (name) => () => (dispatch, getState) => {
  const searchUuid = getSearchUuid(name)(getState())
  const channelName = `App.Offer.${searchUuid}`
  const eventName = `.App\\Events\\ReservationStatusChangedEvent`

  SocketProviderService.unsubscribe(channelName)(eventName)
}

export const subscribeSearchResult = (name) => (dispatch, getState) => {
  const searchUuid = getSearchUuid(name)(getState())
  const channelName = `App.Offer.${searchUuid}`
  const eventName = `.App\\Events\\SearchOffersResultsChanged`
  const completeEventName = `.App\\Events\\SearchQueryCompletedEvent`

  let fetchedChunks = []
  let allChunks = []

  dispatch(change(name)('offers', []))

  const completeIfAllLoaded = () => {
    const isLoaded = allChunks.every((chunk) => fetchedChunks.includes(chunk))

    if (isLoaded) {
      dispatch(change(name)('searching', false))
      dispatch(change(name)('status', SEARCH_STATUS_COMPLETE))

      SocketProviderService.unsubscribe(channelName)(eventName)
      SocketProviderService.unsubscribe(channelName)(completeEventName)

      // clear memory
      fetchedChunks = null
      allChunks = null
    }

    return isLoaded
  }

  SocketProviderService.subscribe(channelName)(eventName)(
    (response: HotelsBookingSearchOffersResultsChanged) => {
      const offers = getOffers(name)(getState())
      const newOffers = Array.isArray(response.offers)
        ? response.offers
        : Object.values(response.offers)

      fetchedChunks.push(response.searchQueryChunkUuid)

      dispatch(
        setOffers(name)({
          ...response,
          offers: [...offers, ...newOffers],
        }),
      )

      if (allChunks.length) {
        completeIfAllLoaded()
      }
    },
  )

  SocketProviderService.subscribe(channelName)(completeEventName)((response) => {
    allChunks = response.chunks

    completeIfAllLoaded()
  })
}

export const unsubscribeSearchResult = (name) => (dispatch, getState) => {
  const searchUuid = getSearchUuid(name)(getState())
  const channelName = `App.Offer.${searchUuid}`
  const eventName = `.App\\Events\\SearchOffersResultsChanged`
  const completeEventName = `.App\\Events\\SearchQueryCompletedEvent`

  SocketProviderService.unsubscribe(channelName)(eventName)
  SocketProviderService.unsubscribe(channelName)(completeEventName)
}

export const toggleParking = (name) => (request_element) => (dispatch) => {
  dispatch(changeForm(request_element.key, 'parking', !request_element.parking))
}

export const toggleExtraServices = (name) => (request_element) => (dispatch) => {
  dispatch(
    changeForm(request_element.key, 'extraServicesChecked', !request_element.extraServicesChecked),
  )
}

export const changeExtraServicesText = (name) => (value, request_element) => (dispatch) => {
  dispatch(changeForm(request_element.key, 'extraServicesText', value))
}

export const selectOfferOption =
  (name) =>
  (request, { search_uuid, offer, option, request_element }) =>
  (dispatch, getState): Promise<ChooseHotelOfferResponse> => {
    if (request_element instanceof Accommodation) {
      const amount = Number.parseFloat(get(option, 'amount.amount', 0))
      const fee = Number.parseFloat(get(option, 'reservation_fee.amount', 0))
      const gross = (amount + fee).toFixed(2)
      const filters = getFilters(name)(getState())
      // properties that are set on hotel page should not be overriden by filters
      const excludedFilters = ['parking']

      dispatch(
        changeForm(request_element.key, 'location', {
          ...request_element.location,
          address: offer.attributes.address,
          long: offer.attributes.location.longitude,
          lat: offer.attributes.location.latitude,
        }),
      )

      dispatch(changeForm(request_element.key, 'amount', gross))
      dispatch(changeForm(request_element.key, 'search_uuid', search_uuid))

      Object.keys(filters)
        .filter((filter) => !excludedFilters.includes(filter))
        .forEach((filter) => {
          dispatch(changeForm(request_element.key, filter, filters[filter]))
        })

      dispatch(updateElementSearchUuid(request_element.key, search_uuid))
    }

    dispatch(setSearchUuidForElement(request_element.id, search_uuid))

    dispatch({
      type: SELECT_OFFER_OPTION,
      payload: {
        name,
        offer_uuid: offer.uuid,
        option_uuid: option.uuid,
      },
    })

    return APIClient.chooseHotelOffer(request, {
      search_uuid,
      offer_uuid: offer.uuid,
      option_uuid: option.uuid,
      request_element: get(request_element, 'draft', null) ? null : request_element,
    })
      .then((response: ChooseHotelOfferResponse) => {
        return response
      })
      .catch(() => null)
  }

export const fetchSelectedForElement = (name) => (request, element) => (dispatch) => {
  return APIClient.getOffers(request, { element_id: element.id, element_type: element.type }).then(
    ({ data }) => {
      if (data.uuid) {
        dispatch(setOffers(name)(data))

        const chosen = data.offers.find((offer) => {
          if (offer.chosen) {
            return offer.options.find((option) => option.chosen === true)
          }
          return false
        })

        if (chosen) {
          dispatch(selectOffer(name)(chosen.uuid))
        }
      }

      if (get(request, 'status', null) === STATUS_DRAFT && !data.uuid && Number(element.amount)) {
        dispatch(setEnable(name)(false))
      }

      dispatch(setInitialized(name)(true))
    },
  )
}

export const setInitialFacilities = (name) => (facilities) => (dispatch) => {
  dispatch(change(name)(`facilities`, facilities))
  dispatch(change(name)(`initialFacilities`, facilities))
}

export const setFacility = (name) => (facilityName, facilityValue) => (dispatch, getState) => {
  const initialFacilities = getInitialFacilities(name)(getState())
  const facilities = {
    ...getFacilities(name)(getState()),
    [facilityName]: facilityValue,
  }

  dispatch(changeForm(name, facilityName, facilityValue))
  dispatch(change(name)(`facilities.${facilityName}`, facilityValue))
  dispatch(change(name)(`isFacilityChanged`, !isEqual(facilities, initialFacilities)))
}

export const setFilter = (name) => (filterName, filterValue) => (dispatch, getState) => {
  const status = getStatus(name)(getState())

  dispatch(change(name)(`filters.${filterName}`, filterValue))
  dispatchFiltering(name, dispatch, getState, status)
}

export const setFilters = (name) => (filters) => (dispatch) => {
  dispatch(change(name)(`filters`, filters))
}

export const setInitialized = (name) => (state) => (dispatch) => {
  dispatch(change(name)('initialized', state))
}

export const setEnable = (name) => (state) => (dispatch) => {
  dispatch(change(name)('enable', state))
}

export const setQuery = (name) => (query) => (dispatch) => {
  dispatch(change(name)('query', query))
}

export const setSearch = (name) => (state) => (dispatch) => {
  dispatch(change(name)('searching', state))
}

export const isChanged = (name) => (value) => (dispatch) => {
  dispatch(change(name)('isChanged', value))
}

export const searchFilters = (name) => () => (dispatch) => {
  dispatch(researchHotels(name)())
}

export const researchHotels = (name) => () => (dispatch, getState) => {
  const state = getState()

  const searchUUID = getSearchUuid(name)(state)
  const facilities = getFacilities(name)(state)

  const filterRequest = new HotelsFilterRequest(searchUUID, facilities.breakfast)
  const cancellableRequest = CancellableRequest.setRequest(filterRequest)

  dispatch(unsubscribeSearchResult(name))
  dispatch(change(name)('isFiltering', true))

  return APIClient.researchHotels(cancellableRequest).then((response) => {
    dispatch(setOffers(name)(response.data))
    dispatch(change(name)(`initialFacilities`, facilities))
    dispatch(change(name)(`isFacilityChanged`, false))
    dispatch(change(name)('isChanged', false))
    dispatch(subscribeSearchResult(name))
  })
}

export const searchAnotherOne = (name) => (request, element, search_uuid) => (dispatch) => {
  dispatch(unsubscribeSearchResult(name))

  dispatch({
    type: SEARCH_ANOTHER_ONE,
    payload: {
      name,
    },
  })

  APIClient.chooseHotelOffer(request, {
    search_uuid: search_uuid,
    request_element: {
      type: null,
      id: null,
    },
  }).catch(() => {})

  dispatch(closeResultsList(name)())
  dispatch(setStepMap(name)())

  if (element.isAdditional()) {
    dispatch(deleteElement(request, element))
  }
}

export const bookOffer = (name) => (request, element) => (uuid) => async (dispatch, getState) => {
  dispatch({
    type: BOOK_OFFER,
    payload: {
      name,
      status: BOOKING_NEW,
    },
  })

  dispatch(unsubscribeSearchResult(name))
  dispatch(closeOfferDialog(name)())

  const facilities = getFacilities(name)(getState())

  if (element.draft) {
    const selectedOffer = getSelectedOffer(name)(getState())
    const selectedOption = getSelectedOption(name)(getState())
    const query = getQuery(name)(getState())

    const savedElement = await dispatch(
      saveElement(
        request,
        {
          ...element,
          ...facilities,
          amount: selectedOption.amount.amount,
          amount_currency: selectedOption.amount.currency,
          arrival_at: query.arrival_at,
          departure_at: query.departure_at,
          location: query.location,
          request_travelers: query.request_travelers,
          rooms: query.rooms,
        },
        element,
      ),
    )

    await dispatch(
      selectOfferOption(name)(request, {
        search_uuid: uuid,
        offer: selectedOffer,
        option: selectedOption,
        request_element: savedElement,
      }),
    )
  }

  return APIClient.bookOffer(request, uuid)
    .then(() => {
      dispatch(subscribeReservationStatus(name))
    })
    .catch((error) => {
      dispatch(unsubscribeReservationStatus(name)())

      dispatch({
        type: BOOK_OFFER,
        payload: {
          name,
          status: get(error, 'data.booking', BOOKING_FAILED),
        },
      })
    })
}

export const setAgreement = (name) => (value) => (dispatch) => {
  dispatch({
    type: SET_AGREEMENT,
    payload: {
      name,
      value,
    },
  })
}

export const valuateOffer = (name) => (request) => (uuid) => (dispatch) => {
  dispatch({
    type: VALUATE_OFFER,
    payload: {
      name,
      status: true,
    },
  })
  APIClient.valuateOffer(request, uuid).catch(() => {
    dispatch({
      type: BOOK_OFFER,
      payload: {
        name,
        status: BOOKING_FAILED,
      },
    })
  })
}

export const toggleSearch = (name) => () => (dispatch) => {
  dispatch({
    type: TOGGLE_SEARCH,
    payload: {
      name,
    },
  })
}

export const openResultsList = (name) => (uuid) => (dispatch) => {
  dispatch(change(name)('uuid', uuid))
  dispatch(change(name)('isResultsListOpen', true))
  dispatch(change(name)('isFacilityChanged', false))
  dispatch(subscribeSearchResult(name))
}

export const closeResultsList = (name) => () => (dispatch) => {
  dispatch(change(name)('searching', false))
  dispatch(unsubscribeSearchResult(name))
  dispatch(change(name)('hasOffers', false))
  dispatch(change(name)('isResultsListOpen', false))
}

export const openOfferDialog = (name) => () => (dispatch) => {
  dispatch(change(name)('isOfferDialogOpen', true))
}

export const closeOfferDialog = (name) => () => (dispatch) => {
  dispatch(change(name)('isOfferDialogOpen', false))
}

export const resignFromSearch = (name) => (request, element) => () => (dispatch) => {
  if (element.isAdditional()) {
    dispatch(deleteElement(request, element))

    return
  }

  dispatch(change(name)('enabled', false))

  if (!element.draft) {
    APIClient.disableSearcher(request, element)
  }
}

export const backToSearching = (name) => (request) => (element) => () => (dispatch) => {
  dispatch(change(name)('enabled', true))

  dispatch(changeForm(name, 'searcher_disabled', false))

  if (!element.draft) {
    APIClient.updateAccommodation(request.slug, element.id, {
      ...element,
      searcher_disabled: false,
    })
  }
}

export const setSortBy = (name) => (value) => (dispatch, getState) => {
  const status = getStatus(name)(getState())

  dispatch(change(name)('sortBy', value))
  dispatchFiltering(name, dispatch, getState, status)
}
