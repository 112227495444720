import React from 'react'
import { withFormik } from 'formik'
import { get } from 'lodash'
import APIClient from '../../../services/APIClient'
import Security from './Security'
import PropTypes from 'prop-types'

const initialValues = {
  new_password: '',
  new_password_confirmation: '',
}

const SecurityForm = withFormik({
  mapPropsToValues: () => initialValues,
  handleSubmit: (values, { props, setSubmitting, setErrors, resetForm }) => {
    APIClient.changePassword({
      ...values,
      slug: _.isObject(props.subjectUser) ? props.subjectUser.slug : null,
    })
      .then(() => {
        resetForm(initialValues)
        setSubmitting(false)
      })
      .catch((response) => {
        setErrors(get(response, 'alerts.0.errors', {}))
        setSubmitting(false)
      })
  },
  enableReinitialize: true,
  displayName: 'Security',
})(Security)

SecurityForm.propTypes = {
  subjectUser: PropTypes.object,
}

export { SecurityForm }
export default SecurityForm
