import moment from 'moment'
import { config } from '../config'

export const setEndOfDay = (value, format = false) => {
  const date = moment.isMoment(value) ? value : moment(value, config.apiDateTimeFormat)
  const newDate = date.hours(23).minutes(59).seconds(59)

  if (format) {
    return newDate.format(config.apiDateTimeFormat)
  }

  return newDate
}
