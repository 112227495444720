import React from 'react'
import Dropdown from './Dropdown'
import { userProfile } from '../../store/app/user-profile/providers/userProfile'
import { isEqual } from 'lodash'

class ProfileDropdown extends React.Component<any, any> {
  constructor(props) {
    super(props)

    this.state = {
      open: false,
    }
  }

  shouldComponentUpdate = (nextProps, nextState) => {
    const {
      userProfile: {
        selectors: { data },
      },
    } = this.props
    return (
      !isEqual(data, nextProps.userProfile.selectors.data) || nextState.open !== this.state.open
    )
  }

  handleDropdownHeaderClick = () => {
    if (this.state.open) {
      this.setState({ open: false })
    } else {
      this.setState({ open: true })
    }
  }

  render() {
    const {
      userProfile: {
        selectors: { data },
      },
    } = this.props

    return (
      <Dropdown
        handleDropdownHeaderClick={this.handleDropdownHeaderClick}
        open={this.state.open}
        currentUser={data}
      />
    )
  }
}

export default userProfile(false)(ProfileDropdown)
