import { findIndex, flatten, get, groupBy, values } from 'lodash'
import { setDisabledExpensesInPeriod } from './utils'

export const createDeductions = (namespace, fetchMethod, changeMethod, validateMethod = null) => {
  const MODULE_MOUNT_POINT = namespace

  const RESET = MODULE_MOUNT_POINT + '::reset'
  const SET = MODULE_MOUNT_POINT + '::set'
  const DESTROY = MODULE_MOUNT_POINT + '::destroy'
  const SET_LOADING = MODULE_MOUNT_POINT + '::set-loading'
  const CHANGE = MODULE_MOUNT_POINT + '::change'

  const getInitialState = () => {
    return {
      deductions: [],
      loading: true,
      initialized: false,
    }
  }

  // actions Creators
  const reset = () => (dispatch) => {
    dispatch({
      type: RESET,
    })
  }

  const destroy = () => (dispatch) => {
    dispatch({
      type: DESTROY,
    })
  }

  const set = (data) => (dispatch) => {
    dispatch({
      type: SET,
      payload: data,
    })
  }

  const setLoading = (state) => (dispatch) => {
    dispatch({
      type: SET_LOADING,
      payload: { state },
    })
  }

  const change = (request) => (expenseId, field, value) => (dispatch) => {
    changeMethod(request.slug, expenseId, { [field]: value })

    dispatch({
      type: CHANGE,
      payload: {
        expenseId,
        field,
        value,
      },
    })
  }

  const validate = (request) => (expenseId) => (dispatch) => {
    return validateMethod(request.slug, expenseId)
  }

  const fetch = (request) => () => (dispatch) => {
    dispatch(setLoading(true))
    return fetchMethod(request.slug)
      .then((response) => {
        const periods = values(groupBy(setDisabledExpensesInPeriod(response.data), 'tripDay'))
        dispatch(set(periods))
        dispatch(setLoading(false))
      })
      .catch((e) => {
        dispatch(setLoading(false))
      })
  }

  //actions
  const resetAction = () => {
    return getInitialState()
  }

  const destroyAction = (state) => {
    delete state[MODULE_MOUNT_POINT]
    return state
  }

  const setAction = (state, payload) => {
    return {
      ...state,
      deductions: payload,
      initialized: true,
    }
  }

  const setLoadingAction = (state, payload) => {
    return {
      ...state,
      loading: payload.state,
    }
  }

  const changeAction = (state, payload) => {
    let deductions = flatten([...state.deductions])
    const expenseIndex = findIndex(deductions, { id: payload.expenseId })

    if (expenseIndex === -1) {
      return state
    }

    deductions[expenseIndex].values[payload.field] = payload.value

    deductions = values(groupBy(setDisabledExpensesInPeriod(deductions), 'tripDay'))

    return {
      ...state,
      deductions,
    }
  }

  const actions = {
    [RESET]: resetAction,
    [SET]: setAction,
    [DESTROY]: destroyAction,
    [SET_LOADING]: setLoadingAction,
    [CHANGE]: changeAction,
  }

  //selectors
  const getState = (state) => {
    return state.get(MODULE_MOUNT_POINT)
  }

  const getDeductions = (state) => {
    return get(getState(state), 'deductions', [])
  }

  const isLoading = (state) => {
    return get(getState(state), 'loading', true)
  }

  const isInitialized = (state) => {
    return get(getState(state), 'initialized', true)
  }

  const reducer = (state = getInitialState(), action) => {
    if (actions.hasOwnProperty(action.type)) {
      return actions[action.type](state, action.payload)
    }
    return state
  }

  return {
    creators: {
      reset,
      destroy,
      set,
      setLoading,
      change,
      fetch,
      validate,
    },
    selectors: {
      getDeductions,
      isLoading,
      isInitialized,
    },
    reducer,
  }
}
