import React, { Component } from 'react'
import isFunction from 'lodash/isFunction'
import throttle from 'lodash/throttle'

import { TextField } from '../../ui/Form'
import { FormGroup } from '../../../ui'
import Icon from '../../ui/IconComponent'

class FilterInput extends Component<any, any> {
  constructor(props) {
    super(props)

    this.state = {
      value: props.initValue || '',
    }

    this.throttledOnChange = throttle(props.onChange, 500)
  }

  updateValue = (value) => {
    const { onChange } = this.props

    this.setState(
      {
        value,
      },
      () => isFunction(onChange) && this.throttledOnChange(this.state.value),
    )
  }

  handleChange = (e) => {
    e.persist()
    const { value } = e.target
    this.updateValue(value)
  }

  handleClearClick = () => {
    this.updateValue('')
  }

  render() {
    const { label } = this.props
    const { value } = this.state

    return (
      <FormGroup label={label} labeltop>
        <div className='hotels-dialog__input-filter-label hotels-dialog__input-filter-label--relative'>
          <TextField
            type='text'
            name='other-filters'
            className='hotels-dialog__input-filter'
            onChange={this.handleChange}
            value={value}
          />
          {value && (
            <Icon
              type='close'
              className='hotels-dialog__input-filter-close'
              onClick={this.handleClearClick}
            />
          )}
        </div>
      </FormGroup>
    )
  }
}

export { FilterInput }
export default FilterInput
