import { createSlice } from '@reduxjs/toolkit'
import APIClient from '../../../services/APIClient'

export const RENT_CAR_ORDER_ENUM_VALUES = 'rent-car-order-enum-values'

const getInitialState = () => ({
  enumValues: {
    OrderCarTypeEnum: [],
    OrderCarAdditionalEquipmentEnum: [],
  },
  error: null,
  isLoading: false,
  isLoaded: false,
})

/**
 * In @reduxjs/toolkit we can mutate state in reducers,
 * because behind the scene it uses ImmerJS for applying them as non-mutate
 *
 * https://github.com/immerjs/immer
 */
const rentCarOrderEnumValuesSlice = createSlice({
  name: RENT_CAR_ORDER_ENUM_VALUES,
  initialState: getInitialState(),
  reducers: {
    resetEnumValues(state) {
      state = getInitialState()
    },
    setEnumValues(state, action) {
      const { data } = action.payload

      state.enumValues = data
    },
    startLoading(state) {
      state.isLoading = true
      state.isLoaded = false
      state.error = null
    },
    setLoadingSuccess(state) {
      state.isLoading = false
      state.isLoaded = true
      state.error = null
    },
    setLoadingFailed(state, action) {
      state.isLoading = false
      state.isLoaded = false
      state.error = action.payload
    },
  },
})

export const { resetEnumValues, setEnumValues, startLoading, setLoadingSuccess, setLoadingFailed } =
  rentCarOrderEnumValuesSlice.actions

export default rentCarOrderEnumValuesSlice.reducer

const getState = (state) => state.get(RENT_CAR_ORDER_ENUM_VALUES)

export const getOrderCarEnumValues = (state) => getState(state).enumValues
export const isLoading = (state) => getState(state).isLoading
export const isLoaded = (state) => getState(state).isLoaded

export const fetchOrderCarEnumValues = () => async (dispatch) => {
  dispatch(startLoading())

  APIClient.getOrderCarEnumValues()
    .then(({ data }) => {
      dispatch(setEnumValues({ data }))
      dispatch(setLoadingSuccess())
    })
    .catch((err) => {
      dispatch(setLoadingFailed(err))
    })
}

export const fetchOrderEnumValuesIfNeeded = () => async (dispatch, state) => {
  const { items, isLoading } = getState(state())

  if ((items && items.length > 0) || isLoading) {
    return
  }

  return dispatch(fetchOrderCarEnumValues())
}
