import React from 'react'
import { connect } from 'react-redux'
import { actions, selectors } from './index'
import { bindActionCreators } from 'redux'
import APIClient from '../../../services/APIClient'

const InstallmentsManager =
  (resetOnMount = false) =>
  (Component) => {
    class InstallmentsProvider extends React.Component<any, any> {
      constructor(props) {
        super(props)

        this.state = {
          MPKs: [],
          companies: [],
        }
      }

      componentDidMount() {
        const { reset, fetchItems, listenQueryChange } = this.props
        const dependencies = []

        dependencies.push(APIClient.getMPKs())
        dependencies.push(APIClient.getCompanies())

        Promise.all(dependencies).then((responses) => {
          this.setState({
            MPKs: responses[0].data,
            companies: responses[1].data,
          })
        })

        if (resetOnMount) {
          reset()
          fetchItems()
        }

        listenQueryChange()
      }

      componentWillUnmount() {
        const { unlistenQueryChange } = this.props

        unlistenQueryChange()
      }

      render() {
        return <Component companies={this.state.companies} {...this.props} />
      }
    }

    const { getIsLoaded, getIsLoading, getItemsPaginated, getPagination, getFilters, getSorter } =
      selectors

    const mapDispatchToProps = (dispatch) => {
      return bindActionCreators(actions, dispatch)
    }

    const mapStateToProps = (state) => ({
      installments: getItemsPaginated(state),
      pagination: getPagination(state),
      isLoaded: getIsLoaded(state),
      isLoading: getIsLoading(state),
      filters: getFilters(state),
      sorter: getSorter(state),
    })

    return connect(mapStateToProps, mapDispatchToProps)(InstallmentsProvider)
  }

export { InstallmentsManager }
export default InstallmentsManager
