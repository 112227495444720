import React, { ReactElement } from 'react'
import { components } from 'react-select'
import RenderProvider from './RenderProvider'
import { OptionProps } from 'react-select/dist/declarations/src/components/Option'

export interface ProviderSelectOptionProps extends OptionProps {
  children: ReactElement
  data: {
    data: {
      name: string
      erp_id: string
      registry_number: string
      address: string
      city: string
      postcode: string
    }
  }
}

function ProviderSelectOption({ children, ...props }: ProviderSelectOptionProps): ReactElement {
  const {
    data: { data },
  } = props
  const content = data ? (
    <RenderProvider
      name={data.name}
      erpId={data.erp_id}
      registryNumber={data.registry_number}
      address={data.address}
      city={data.city}
    />
  ) : (
    children
  )

  return <components.Option {...props}>{content}</components.Option>
}

export default ProviderSelectOption
