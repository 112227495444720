import React from 'react'
import { trans } from '../../../trans'
import PropTypes from 'prop-types'
import classNames from 'classnames'

const NotFeaturedHeader = (props) => {
  const { visible, marginTop } = props

  const classes = classNames(
    {
      'trip-dialog__header': true,
      'plane-trip__offers-header': true,
      'plane-trip__offers-header--not-visible': !visible,
      'plane-trip__offers-header--top-margin': marginTop,
    },
    props.className,
  )

  return (
    <span className={classes}>
      <strong>{trans('flights-booking.flight-not-featured')}</strong>
    </span>
  )
}

NotFeaturedHeader.propTypes = {
  visible: PropTypes.bool.isRequired,
}

NotFeaturedHeader.defaultProps = {
  visible: true,
}

export default NotFeaturedHeader
export { NotFeaturedHeader }
