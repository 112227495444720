import React from 'react'
import { reduxForm, SubmissionError } from 'redux-form/immutable'
import { connect } from 'react-redux'
import { fromJS } from 'immutable'
import { TargetPoint as Form } from '../Forms'
import { bindActionCreators } from 'redux'
import { processAPIerrorResponseToFormErrors } from '../../../../services/APIClient'
import { prepareRequestDates } from '../../../../utils/prepareRequestDates'
import { getFormValues } from '../../../../utils/forms'
import { Factory as ElementFactory } from '../../../../models/timeline/index'
import { DateSuggester } from '../../../../store/app/trip-timeline/services/date-suggester'
import { LocationSuggester } from '../../../../store/app/trip-timeline/services/location-suggester'
import { addFirstElement, addTargetPoint, getElements } from '../../../../store/app/trip-timeline'
import { TRAVEL_TARGET_POINT } from '../../../../constants/travel'

class TargetPoint extends React.PureComponent<any, any> {
  render() {
    const { ...props } = this.props

    if (!this.props.initialized) {
      return null
    }

    return <Form {...props} />
  }
}

export const submit = (values, dispatch, props) => {
  const { onSave, request, change } = props

  values = prepareRequestDates(values, ['date'])

  return onSave(request, values, props.element).then(
    () => {
      dispatch(change('isOpen', false))
    },
    (alerts) => {
      throw new SubmissionError(processAPIerrorResponseToFormErrors(alerts))
    },
  )
}

export const change = (values, dispatch, props) => {
  const { change } = props
  if (values.get('departure_at') < values.get('arrival_at')) {
    dispatch(change('departure_at', values.get('arrival_at')))
  }
}

const withForm = reduxForm({
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
  onSubmit: submit,
  onChange: change,
  destroyOnUnmount: false,
})(TargetPoint)

const mapStateToProps = (state, props) => {
  const { request, element } = props
  const point = ElementFactory.create(element)
  const dateSuggester = new DateSuggester(state, point)
  const alreadyHasTargetPoint = !!getElements(state).find(
    (element) => element.type === TRAVEL_TARGET_POINT && !element.draft,
  )

  return {
    initialValues: fromJS({
      uuid: point.uuid,
      location: point.getStartLocation(),
      date: point.getStartDate(),
      id: point.id,
      type: point.type,
      isOpen: point.isOpen,
      draft: point.draft,
      virtual: point.virtual,
    }),
    form: point.key,
    request,
    point: ElementFactory.create(getFormValues(point.key, state)),
    minDate: dateSuggester.suggestMinDate(),
    maxDate: dateSuggester.suggestMaxDate(),
    maxStartDate: dateSuggester.suggestMaxStartDate(),
    alreadyHasTargetPoint,
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      addTargetPoint,
      addFirstElement,
    },
    dispatch,
  )
}

const connected = connect(mapStateToProps, mapDispatchToProps)(withForm)

TargetPoint = connected

export { TargetPoint }
export default { TargetPoint }
