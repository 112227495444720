import React from 'react'
import { Tab } from './Tab'

export function TabsHeader({ items = [], activeTab, match }) {
  return (
    <ul className='tabs__titles'>
      {items
        .filter((item) => item.enabled !== false)
        .map((item) => (
          <Tab
            key={`title-${item.id}`}
            id={item.id}
            title={item.title}
            linkTo={`${match.url}/${item.id}`}
            active={activeTab === item.id}
          />
        ))}
    </ul>
  )
}
