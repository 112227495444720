import React from 'react'
import moment from 'moment'
import trans from '../../trans'
import { spanStatusfromHttpCode } from '@sentry/react'

interface TravelPointDateProps {
  start: string
  end?: string
  withArrival?: boolean
}

const TravelPointDate = React.memo((props: TravelPointDateProps) => {
  let start = props.start
  let end = props.end
  let arrivalTime = null

  if (!start) {
    return null
  }

  start = moment(props.start).format('ddd. D MMM. YYYY')
  arrivalTime = moment(props.start, 'YYYY-MM-DD HH:mm:ss').format('HH:mm')

  if (end) {
    end = moment(props.end).format('ddd. D MMM. YYYY')
    arrivalTime = moment(props.end, 'YYYY-MM-DD HH:mm:ss').format('HH:mm')
  }

  if (end === start) {
    end = null
  }

  return (
    <>
      <span className='travel-point-date'>
        {start}
        {!!end && ` - ${end}`}{' '}
      </span>

      {props.withArrival && (
        <span className={'travel-point travel-point-start-stop--label'}>
          {trans('request-element.target-point-arrival', { time: arrivalTime })}
        </span>
      )}
    </>
  )
})

export default TravelPointDate
export { TravelPointDate }
