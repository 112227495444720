import { Element } from './Element'
import { Location } from './index'
import { TRAVEL_BUS_TRIP } from '../../constants/travel'

class Bus extends Element {
  constructor(init) {
    super({ ...init, type: TRAVEL_BUS_TRIP })

    const defaults = {
      departure_at: null,
      return_at: null,
      round_trip: true,
      destination_location: {},
      departure_location: {},
      calculated_amount: null,
      calculated_amount_currency: null,
      amount: null,
      amount_currency: null,
    }

    const opts = Object.assign({}, defaults, init)

    this.departure_at = opts.departure_at
    this.return_at = opts.return_at
    this.round_trip = opts.round_trip
    this.departure_location = new Location(opts.departure_location)
    this.destination_location = new Location(opts.destination_location)
    this.calculated_amount = opts.calculated_amount
    this.calculated_amount_currency = opts.calculated_amount_currency
    this.amount = opts.amount
    this.amount_currency = opts.amount_currency
  }

  getStartLocation() {
    return this.departure_location
  }

  getEndLocation() {
    return this.destination_location
  }

  getStartDate() {
    return this.departure_at
  }

  getEndDate() {
    return this.return_at
  }

  getAllAmounts() {
    return [
      {
        currency: this.amount_currency,
        value: this.amount,
      },
    ]
  }

  isRoundTrip() {
    return this.round_trip
  }
}

export { Bus }
export default Bus
