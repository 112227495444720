import PropTypes from 'prop-types'
import React from 'react'
import classnames from 'classnames'
import Icon from '../IconComponent'

export function CounterField({
  className,
  value,
  onChange,
  disabled,
  min = Number.MIN_SAFE_INTEGER,
  max = Number.MAX_SAFE_INTEGER,
}) {
  const change = (val) => !disabled && val >= min && val <= max && onChange(val)
  const decrement = () => change(value - 1)
  const increment = () => change(value + 1)
  const classes = classnames({
    'counter-field': true,
    [className]: true,
  })

  return (
    <div className={classes}>
      <div className='counter-field__icon' onClick={decrement}>
        <Icon type='minus' gradient />
      </div>

      <div className='counter-field__value'>{value}</div>

      <div className='counter-field__icon' onClick={increment}>
        <Icon type='plus' gradient />
      </div>
    </div>
  )
}

CounterField.propTypes = {
  onChange: PropTypes.func,
  className: PropTypes.string,
  value: PropTypes.number,
  disabled: PropTypes.bool,
  min: PropTypes.number,
}

CounterField.defaultProps = {
  min: Number.MIN_SAFE_INTEGER,
  max: Number.MAX_SAFE_INTEGER,
}
