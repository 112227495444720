import React from 'react'
import Icon, { EXPENSES_TYPES_TO_ICONS } from '../../ui/IconComponent'
import { ExpenseTypeBox } from '../../ExpenseTypeBox'
import { AmountFormatter } from '../../AmountFormatter'
import { get } from 'lodash'

class ExpenseTypesGroup extends React.Component<any, any> {
  constructor(props) {
    super(props)
    this.ref = null
  }

  toggle() {
    const { group, onToggleGroup } = this.props
    if (!get(group, 'disabled', false)) {
      onToggleGroup(group, this.ref)
    }
  }

  anyGroupsHasValueOrIsActive() {
    const { groups } = this.props
    return groups.filter((g) => g.sum > 0 || g.isActive).length
  }

  calculateRemaining() {
    const { groups, document } = this.props

    let remaining =
      document.gross -
      groups.reduce((acc, group) => {
        return acc + Number.parseFloat(group.sum)
      }, 0)

    if (remaining < 0 && remaining !== document.gross) {
      remaining = 0
    }

    return remaining
  }

  render() {
    const { group } = this.props

    if (!EXPENSES_TYPES_TO_ICONS[group['slug']]) {
      console.log(`Expense Type icon not found for ${group['slug']}`)
    }

    return (
      <ExpenseTypeBox
        icon={<Icon type={EXPENSES_TYPES_TO_ICONS[group['slug']]} />}
        title={group['name']}
        key={`group-${group['id']}`}
        isActive={group['isActive']}
        itemWidth={group['groupWidth']}
        onClick={this.toggle.bind(this)}
        ref={(ref) => (this.ref = ref)}
        hasChildren={
          (!this.anyGroupsHasValueOrIsActive() && !this.calculateRemaining()) || group['sum'] > 0
        }
      >
        <AmountFormatter amount={group['sum']} />
      </ExpenseTypeBox>
    )
  }
}

export default ExpenseTypesGroup
