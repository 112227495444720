import { Cell, Row } from '../../../ui/AccordionTable'
import Button from '../../../ui/ButtonComponent'
import trans from '../../../../trans'
import React from 'react'
import { change, reduxForm, Field, SubmissionError } from 'redux-form/immutable'
import { connect } from 'react-redux'
import { bindActionCreators, compose } from 'redux'
import { getFormValues } from '../../../../utils/forms'
import { fromJS } from 'immutable'
import { FormField } from '../../../ui/Form'
import { createOrUpdateMPK } from '../../../../store/app/mpks'
import { getValidationErrors } from '../../../../utils'
import { getCompaniesWithAllForSelectOptionsSelector } from '../../../../store/app/companies/slice'

class MpkListFormItem extends React.Component<any, any> {
  render() {
    const { handleSubmit, cancel, item, companies } = this.props

    return (
      <Row>
        <form onSubmit={handleSubmit}>
          <Cell fixedWidth={150}>
            <Field name='code' type='text' component={FormField} inputOnly withErrorTooltip />
          </Cell>
          <Cell fixedWidth='auto'>
            <Field name='name' type='text' component={FormField} inputOnly withErrorTooltip />
          </Cell>
          <Cell fixedWidth={250}>
            <Field
              name='company_id'
              type='select'
              options={companies}
              component={FormField}
              placeholder={trans('global.all')}
              inputOnly
              withErrorTooltip
            />
          </Cell>
          <Cell fixedWidth={120}>
            <Field
              name='is_active'
              type='toggle'
              component={FormField}
              disabled={!item.id}
              inputOnly
              withErrorTooltip
            />
          </Cell>
          <Cell fixedWidth={180} alignRight>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <Button primary xs className={'btn--tiny'}>
                {trans('ui.save-send')}
              </Button>
              <Button outline xs className={'btn--tiny'} type='button' onClick={() => cancel(item)}>
                {trans('ui.save-cancel')}
              </Button>
            </div>
          </Cell>
        </form>
      </Row>
    )
  }
}

const submit = (values, dispatch, props) => {
  const data = values.toJS()
  const { cancel, createOrUpdateMPK } = props

  if (!data.company_id) {
    delete data.company_id
  }

  return createOrUpdateMPK(data)
    .then((item) => cancel(item))
    .catch((res) => {
      throw new SubmissionError(getValidationErrors(res.alerts))
    })
}

const withForm = reduxForm({
  onSubmit: submit,
  enableReinitialize: true,
})

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      change,
      createOrUpdateMPK,
    },
    dispatch,
  )
}

const mapStateToProps = (state, props) => {
  const form = `mpk-form-${props.item.id}`
  const currentValues = getFormValues(form, state)
  const {
    item: { slug, code, name, is_active, company_id },
  } = props

  return {
    initialValues: fromJS({
      slug,
      code,
      name,
      company_id,
      is_active: Boolean(is_active),
    }),
    data: currentValues,
    companies: getCompaniesWithAllForSelectOptionsSelector(state, 'all'),
    form,
  }
}

const withConnect = connect(mapStateToProps, mapDispatchToProps)

export default compose(withConnect, withForm)(MpkListFormItem)
