import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { fetchCompaniesIfNeeded, getCompanies, resetCompanies, isLoading } from './slice'

const companies =
  (resetOnMount = false, fetchOnMount = false) =>
  (Component) => {
    class Companies extends React.Component<any, any> {
      componentDidMount() {
        const {
          companies: {
            actions: { fetch, reset },
          },
        } = this.props

        if (resetOnMount) {
          reset()
        }

        if (fetchOnMount) {
          fetch()
        }
      }

      render() {
        return <Component<any, any> {...this.props} />
      }
    }

    const mapStateToProps = (state) => ({
      companies: getCompanies(state),
      isLoading: isLoading(state),
    })

    const mapDispatchToProps = (dispatch) => {
      return bindActionCreators(
        {
          fetch: fetchCompaniesIfNeeded,
          reset: resetCompanies,
        },
        dispatch,
      )
    }

    const mergedProps = (selectors, actions, own) => {
      return {
        ...own,
        companies: {
          selectors,
          actions,
          ...own.companies,
        },
      }
    }

    return connect(mapStateToProps, mapDispatchToProps, mergedProps)(Companies)
  }

export { companies }
export default companies
