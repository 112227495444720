import { createSelector, createSlice } from '@reduxjs/toolkit'
import APIClient from '../../../services/APIClient'
import { toArray } from '../../../utils/array'

export const EXPENSE_GROUP_MOUNT_POINT = 'expense-group'

/**
 * In @reduxjs/toolkit we can mutate state in reducers,
 * because behind the scene it uses ImmerJS for applying them as non-mutate
 *
 * https://github.com/immerjs/immer
 */
const slice = createSlice({
  name: EXPENSE_GROUP_MOUNT_POINT,
  initialState: {
    items: [],
    error: null,
    isLoading: false,
  },
  reducers: {
    setExpenseGroups(state, action) {
      const { data } = action.payload

      state.items = data
    },
    setExpenseGroup(state, action) {
      const { data } = action.payload
      const index = state.items.findIndex((item) => item.id === data.id)

      if (index === -1) {
        state.items.unshift(data)
      } else {
        state.items[index] = data
      }
    },
    startLoading(state) {
      state.isLoading = true
      state.error = null
    },
    setLoadingSuccess(state) {
      state.isLoading = false
      state.error = null
    },
    setLoadingFailed(state, action) {
      state.isLoading = false
      state.error = action.payload
    },
  },
})

export const {
  setExpenseGroups,
  setExpenseGroup,
  startLoading,
  setLoadingSuccess,
  setLoadingFailed,
} = slice.actions

export default slice.reducer

// selectors
const getState = (state) => state.get(EXPENSE_GROUP_MOUNT_POINT)
export const getExpenseGroups = (state) => getState(state).items
export const isLoading = (state) => getState(state).isLoading
export const getExpenseGroupById = (state, id) => {
  const items = getExpenseGroups(state)

  return items.find((item) => item.id === id)
}
export const getActiveExpenseGroupSelectOptions = createSelector(
  [
    (state, _type, _include) => getExpenseGroups(state),
    (_state, _type, include) => toArray(include).map((item) => item.id),
  ],

  (items, type, includeIds) =>
    items
      .filter((item) => item.is_active || (includeIds || []).includes(item.id))
      .map((item) => ({
        label: item.name,
        value: item.id,
      })),
)

// action thunks
export const fetchExpenseGroups = () => async (dispatch) => {
  dispatch(startLoading())

  try {
    const { data } = await APIClient.getExpenseGroups()

    dispatch(setExpenseGroups({ data }))
    dispatch(setLoadingSuccess())
  } catch (err) {
    dispatch(setLoadingFailed(err))
  }
}

export const updateExpenseGroup = (item) => async (dispatch) => {
  const { data } = await APIClient.updateExpenseGroup(item.slug, item)

  dispatch(setExpenseGroup({ data }))

  return data
}

export const changeExpenseGroupOrder = (event) => async (dispatch, state) => {
  if (!event || !event.destination) {
    return
  }

  const { destination, source } = event

  if (destination.index === source.index) {
    return
  }

  const cloned = [...getExpenseGroups(state())]
  let items = [...getExpenseGroups(state())]
  const item = items.splice(source.index, 1)

  items.splice(destination.index, 0, item[0])

  // temporary set order to store
  dispatch(setExpenseGroups({ data: items }))

  try {
    const { data } = await APIClient.changeOrderOfExpenseGroups({
      groups: items.map((item) => item.slug),
    })

    dispatch(setExpenseGroups({ data }))
  } catch (err) {
    dispatch(setLoadingFailed(err))
    dispatch(setExpenseGroups({ data: cloned }))
  }
}
