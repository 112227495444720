import { config } from '../config'
import Echo from 'laravel-echo'
import { get, isFunction } from 'lodash'
import { getInstance } from '../store/app/instance'

const NOTIFICATION_EVENT = '.Illuminate\\Notifications\\Events\\BroadcastNotificationCreated'

export interface SocketEvent {
  socket: any
  uuid: string
  subscribe: {
    channel: string
    event: string
  }
}

class SocketProvider {
  static subscribed = []
  static queue = []
  static storeUnsubscribe

  static initialize = (store) => {
    SocketProvider.storeUnsubscribe = store.subscribe(() => SocketProvider.storeSubscriber(store))
  }

  static storeSubscriber = (store) => {
    const state = store.getState()
    const instance = getInstance(state)
    const session = SocketProvider.getSession(state)
    if (get(instance, 'env', null) && session) {
      SocketProvider.create()
      SocketProvider.queueProcess()
    }
  }

  static getSession = (state) => {
    state = state.get('global').toJS()
    return get(state, 'session.isAuthenticated', false)
  }

  static isSocketConnected = () => {
    return get(SocketProvider.socket, 'connector.socket.connected', false)
  }

  static queueProcess = () => {
    setInterval(() => {
      if (SocketProvider.queue.length && SocketProvider.isSocketConnected()) {
        const item = SocketProvider.queue.shift()
        SocketProvider.listen({ ...item })
      }
    }, 500)
  }

  static listen = ({ channel, event, callback }) => {
    if (
      SocketProvider.subscribed.find((item) => item.channel === channel && item.event === event)
    ) {
      return
    }

    SocketProvider.subscribed.push({ channel, event })

    if (!channel || !event || !isFunction(callback)) {
      throw 'Invalid subscribe parameters'
    }

    SocketProvider.updateOptions()

    if (event === 'notification') {
      SocketProvider.notification()
    }

    console.warn('Subscribing...', channel, 'listen', event)

    SocketProvider.socket.private(channel).listen(event, (e) => {
      console.warn('Message received...', channel, 'listen', event)
      callback(e)
    })
  }

  static subscribe = (channel) => (event) => (callback) => {
    console.warn('Pushing subscription', channel, event, 'into queue...')
    SocketProvider.queue.push({
      channel,
      event,
      callback,
    })
  }

  // Jak stara baba w przychodni.
  static subscribeOutOfSequence = (channel) => (event) => (callback) => {
    console.warn('Pushing subscription', channel, event, 'into queue...')
    SocketProvider.queue.unshift({
      channel,
      event,
      callback,
    })
  }

  static unsubscribe = (channel) => (event) => {
    if (!channel || !event) {
      throw 'Invalid unsubscribe parameters'
    }

    if (SocketProvider.socket.options.broadcaster !== 'socket.io') {
      throw 'Not supported unsubscribe method'
    }

    const index = SocketProvider.subscribed.findIndex(
      (item) => item.channel === channel && item.event === event,
    )

    if (index === -1) {
      return
    }

    SocketProvider.subscribed.splice(index, 1)

    console.warn('Unsubscribing...', channel, 'listen', event)

    try {
      const connector = SocketProvider.socket.connector
      const socketChannel = connector.channels['private-' + channel]

      const e = event.replace('.', '')

      if (socketChannel && socketChannel.events) {
        if (e in socketChannel.events) {
          if (Array.isArray(socketChannel.events[e])) {
            socketChannel.events[e].forEach((callback) => {
              connector.socket.removeListener(e, callback)
            })
          } else {
            connector.socket.removeListener(e, socketChannel.events[e])
          }

          delete socketChannel.events[e]
        }

        if (!Object.keys(socketChannel.events).length) {
          SocketProvider.leave(channel)
        }
      }
    } catch (e) {
      console.warn('Unsubscribing failed.', channel, 'listen', event)
      console.warn(e)
    }
  }

  static notification = (channel) => (callback) => {
    if (!channel || !isFunction(callback)) {
      throw 'Invalid subscribe parameters'
    }

    SocketProvider.subscribe(channel)(NOTIFICATION_EVENT)((e) => {
      callback(e)
    })
  }

  static stopNotifications = (channel) => {
    SocketProvider.unsubscribe(channel)(NOTIFICATION_EVENT)
  }

  static leave = (channel) => {
    SocketProvider.socket.leave(channel)
  }

  static create = (host, port, authToken) => {
    if (!SocketProvider.socket) {
      console.warn('Creating SocketProvider...')

      const sessionData = JSON.parse(config.storage.getItem('sessionData'))
      const instanceData = JSON.parse(config.storage.getItem('instanceData'))

      SocketProvider.authToken = get(sessionData, 'token', null)
      SocketProvider.url = get(instanceData, 'socket_url', window.location.hostname)

      if (host) {
        SocketProvider.host = host
      }

      if (authToken) {
        SocketProvider.authToken = authToken
      }

      SocketProvider.socket = window.EchoObj = new Echo({
        broadcaster: 'socket.io',
        host: SocketProvider.url,
        key: config.pusherKey,
        cluster: 'eu',
        auth: {
          headers: {
            Authorization: `Bearer ${SocketProvider.authToken}`,
          },
        },
      })

      SocketProvider.socket.connector.socket.on('disconnect', (e) => {
        console.error('WebSocket lost connection', e)
      })

      SocketProvider.storeUnsubscribe()

      console.warn('Socket created...')
    }
  }

  static updateOptions = () => {
    if (SocketProvider.socket) {
      const sessionData = JSON.parse(config.storage.getItem('sessionData'))
      const instanceData = JSON.parse(config.storage.getItem('instanceData'))

      SocketProvider.authToken = get(sessionData, 'token', null)
      SocketProvider.url = get(instanceData, 'socket_url', window.location.hostname)

      SocketProvider.socket.options.host = SocketProvider.url
      SocketProvider.socket.options.auth.headers.Authorization = `Bearer ${SocketProvider.authToken}`
    }
  }
}

export default SocketProvider
