import trans from '../../trans'
import React, { PropsWithChildren } from 'react'

function ToggleableQuickFiltersContainer({ children }: PropsWithChildren) {
  return (
    <div className='filters__quick'>
      <span className='filters__quick-title'>{trans('global.quick-filters')}:</span>

      {children}
    </div>
  )
}

export default ToggleableQuickFiltersContainer
