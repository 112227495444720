import React from 'react'
import { reduxForm, SubmissionError } from 'redux-form/immutable'
import { connect } from 'react-redux'
import { fromJS } from 'immutable'
import { CrossingForm as Form } from '../../components/BorderCrossings/BorderCrossings/CrossingForm'
import { compose } from 'redux'
import { createAutoSaveForm, getFormValues } from '../../utils/forms'
import { borderCrossings } from '../../store/app/border-crossings'

export const save = (name, value, dispatch, props) => {
  const {
    crossings: {
      actions: { save },
    },
    crossing,
    data,
  } = props

  if (name === 'target' && crossing.target === value) {
    return new Promise((resolve) => resolve())
  }

  if (crossing.draft) {
    if (data.date && data.country_id) {
      return save(crossing, { date: data.date, country_id: data.country_id })
    }
  } else {
    return save(crossing, { [name]: value })
  }

  return new Promise((resolve) => resolve())
}

const AutoSaveForm = createAutoSaveForm(Form, {
  save,
  destroyOnUnmount: true,
  timeout: 0,
})

const mapStateToProps = (state, props) => {
  const { crossing, form } = props
  return {
    initialValues: fromJS({
      country_id: crossing.country_id,
      target: crossing.target,
      date: crossing.date,
    }),
    form,
    data: getFormValues(form, state),
    crossing,
  }
}

const withConnect = connect(mapStateToProps)
const withBorderCrossings = borderCrossings()

const CrossingForm = compose(withConnect, withBorderCrossings)(AutoSaveForm)

export { CrossingForm }
export default CrossingForm
