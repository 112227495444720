import PropTypes from 'prop-types'
import React from 'react'
import Icon from '../IconComponent'
import classNames from 'classnames'
import _ from 'lodash'

export class EditableLabel extends React.Component<any, any> {
  constructor(props) {
    super(props)
    this.state = {
      isOpen: props.isOpen || false,
      dirty: false,
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      !_.isUndefined(this.props.isOpen) &&
      this.props.isOpen !== this.state.isOpen &&
      !this.state.dirty
    ) {
      this.setState({ isOpen: this.props.isOpen })
    }
  }

  render() {
    const { field, defaultValue, onClick } = this.props
    const { isOpen } = this.state
    const classes = classNames('editable-label', {
      'editable-label--is-open': isOpen,
    })

    return (
      <div className={classes}>
        {!isOpen && (
          <div className='editable-label__label'>
            <div className='editable-label__value'>{defaultValue()}</div>
            <div
              className='editable-label__icon'
              onClick={() => {
                this.setState({ isOpen: true, dirty: true })
                if (_.isFunction(onClick)) {
                  onClick(defaultValue())
                }
              }}
            >
              <Icon type='edit' />
            </div>
          </div>
        )}
        {isOpen && field}
      </div>
    )
  }
}

EditableLabel.propTypes = {
  defaultValue: PropTypes.func.isRequired,
  field: PropTypes.node.isRequired,
  isOpen: PropTypes.bool,
}
