import { createContext, useMemo, useReducer } from 'react'

const AgGridContext = createContext()

const Actions = {
  add: 'add',
  remove: 'remove',
}

function init(initState) {
  return {
    rows: initState,
  }
}
function reducer(state, action) {
  switch (action.type) {
    case Actions.remove: {
      return {
        rows: state.rows.filter((row) => row !== action.payload),
      }
    }

    case Actions.add: {
      return {
        rows: [...state.rows.filter((row) => row !== action.payload), action.payload],
      }
    }

    default:
      return state
  }
}

function AgGridProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, [], init)
  const markRowAsEditing = (row) => dispatch({ type: Actions.add, payload: row })
  const markRowAsView = (row) => dispatch({ type: Actions.remove, payload: row })
  const isRowInEditingState = (row) => state.rows.includes(row)

  const value = useMemo(
    () => [state, { markRowAsEditing, markRowAsView, isRowInEditingState }],
    [state, dispatch],
  )

  return <AgGridContext.Provider value={value}>{children}</AgGridContext.Provider>
}

export { AgGridProvider, AgGridContext }
