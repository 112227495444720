import React, { Component, Fragment } from 'react'
import { Loader } from '../../ui/LoadingOverlay/Loader'
import { Scrollbars } from 'react-custom-scrollbars-2'
import { trans } from '../../../trans'
import { SEARCH_STATUS_PENDING } from '../../../store/app/hotels-booking'
import { ListItem } from './ListItem'
import { connect } from '../../../containers/FlightsBooking'
import { get } from 'lodash'
import NotFeaturedHeader from './NotFeaturedHeader'
import Header from './Header'
import { INSIDE_HEADER_OUT, onScroll } from '../../../third-party/sticky-header'
import classNames from 'classnames'
import { ComponentOverlay } from '../../ui/ComponentOverlay'
import Icon from '../../ui/IconComponent'

const LIST_HEIGHT = 325

class OffersListComponent extends Component<any, any> {
  constructor(props) {
    super(props)

    this.state = {
      isHeaderHidden: false,
      window: {
        width: window.innerWidth,
        height: window.innerHeight,
      },
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const {
      flights: {
        selectors: { featuredOffers },
      },
    } = nextProps

    if (featuredOffers.length === 0) {
      return {
        ...prevState,
        isHeaderHidden: false,
      }
    }

    return prevState
  }

  componentDidMount() {
    window.addEventListener('resize', () => this.updateWindowDimensions())
  }

  componentWillUnmount() {
    window.removeEventListener('resize', () => this.updateWindowDimensions())
  }

  updateWindowDimensions() {
    this.setState({
      window: {
        width: window.innerWidth,
        height: window.innerHeight,
      },
    })
  }

  getListHeight() {
    const { width, height } = this.state.window
    const isScreenSmall = width <= 1366 && height <= 768
    const isScreenTall = height > 900

    return !isScreenSmall && !isScreenTall ? 500 : 'calc(100% - 100px)'
  }

  selectOffer = (offer) => {
    const {
      flights: {
        actions: { selectOffer },
        selectors: { uuid },
        request,
        element,
      },
    } = this.props
    selectOffer(request, {
      search_uuid: uuid,
      offer: offer,
      request_element: element,
    })
  }

  renderItems = () => {
    const {
      flights: {
        selectors: { offers, selectMode, query, featuredOffers },
        actions: { selectOffer },
      },
    } = this.props
    const isRoundTrip = get(query, 'round_trip', false)

    return offers.map((offer, index) => {
      return (
        <Fragment key={offer.uuid}>
          {index === featuredOffers.length && (
            <NotFeaturedHeader key='not-featured-header' marginTop className='js-inside-header' />
          )}

          <ListItem
            offer={offer}
            selectOffer={() => selectOffer(offer, isRoundTrip, selectMode)}
            isRoundTrip={isRoundTrip}
            featured={index < featuredOffers.length}
          />
        </Fragment>
      )
    })
  }

  renderNoResults = () => {
    return (
      <div style={{ height: LIST_HEIGHT }} className='trip-dialog__no-results'>
        <span>{trans('flights-booking.no-results')}</span>
      </div>
    )
  }

  renderAsyncActionsLoader = () => {
    const {
      flights: {
        selectors: { isLoading, isBlocked, isFetchingReturns, isFiltering },
      },
    } = this.props

    if (isLoading || isBlocked || isFetchingReturns || isFiltering) {
      return <Loader />
    }

    return null
  }

  renderHeader = () => {
    const { isHeaderHidden } = this.state

    return (
      <Fragment>
        <NotFeaturedHeader visible={isHeaderHidden} />
        <Header mainHeader className='js-main-header' visible={!isHeaderHidden} />
      </Fragment>
    )
  }

  onScroll = () => {
    onScroll((being) => {
      let is_hidden = being === INSIDE_HEADER_OUT
      if (this.state.isHeaderHidden !== is_hidden) {
        this.setState({ isHeaderHidden: is_hidden })
      }
    })
  }

  render() {
    const {
      flights: {
        selectors: { isLoading, hasOffers, offers, isFiltering, isBlocked, isChanged },
      },
    } = this.props

    return (
      <div
        className={classNames(
          'has-loader trip-dialog__list-wrapper',
          isChanged ? 'is-changed' : '',
        )}
        style={{ height: this.getListHeight() }}
      >
        {!isLoading && isChanged && (
          <ComponentOverlay>
            <div className='component-overlay__message component-overlay__message--unsettled-lump-sums'>
              <Icon className='icon-info-grey component-overlay__message-icon' type='info' />
              <span>{trans('request.flight-filters-changed-overlay-message')}</span>
            </div>
          </ComponentOverlay>
        )}

        {!isLoading && this.renderHeader()}

        <Scrollbars onScroll={this.onScroll}>
          {hasOffers && (
            <Fragment>
              {!!offers.length && (
                <ul className='trip-dialog__list has-loader'>{this.renderItems()}</ul>
              )}

              {!isBlocked && !isFiltering && !offers.length && this.renderNoResults()}
            </Fragment>
          )}

          {!isBlocked &&
            !isLoading &&
            !isFiltering &&
            !hasOffers &&
            status !== SEARCH_STATUS_PENDING &&
            this.renderNoResults()}
        </Scrollbars>

        {!hasOffers && status === SEARCH_STATUS_PENDING && <Loader />}

        {this.renderAsyncActionsLoader()}
      </div>
    )
  }
}

const OffersList = connect(OffersListComponent)

export default OffersList
export { OffersList }
