import React, { Component, Fragment } from 'react'
import ReactDOM from 'react-dom'
import Select from 'react-select'

class SelectWithOutsideClosing extends Component<any, any> {
  constructor(props) {
    super(props)
    this.ref = React.createRef()
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside)
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside)
  }

  handleClickOutside = (event) => {
    if (!this.ref.current) {
      return null
    }

    const select = ReactDOM.findDOMNode(this.ref.current)

    if (!select.contains(event.target)) {
      this.ref.current.onMenuClose()
    }
  }

  render() {
    return (
      <Fragment>
        <Select {...this.props} ref={this.ref} />
      </Fragment>
    )
  }
}

SelectWithOutsideClosing.propTypes = {}

export default SelectWithOutsideClosing
export { SelectWithOutsideClosing }
