import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import {
  reset,
  fetch,
  getItems,
  getIsLoading,
  getIsLoaded,
  rejectRequest,
  getIsActionInProgress,
  acceptRequest,
} from '../../store/app/dashboard-requests-to-accept'
import { isModuleActive } from '../../store/app/instance'

class DashboardRequestsToAcceptManagerBase extends React.Component<any, any> {
  componentDidMount() {
    const { reset, fetch } = this.props

    reset()
    fetch()
  }

  render() {
    const {
      children,
      items,
      isLoading,
      isLoaded,
      rejectRequest,
      isActionInProgress,
      acceptRequest,
      isModuleActive,
    } = this.props

    const renderProps = {
      items,
      isLoading,
      isLoaded,
      rejectRequest,
      isActionInProgress,
      acceptRequest,
      isModuleActive,
    }

    return children(renderProps)
  }
}

DashboardRequestsToAcceptManagerBase.propTypes = {
  children: PropTypes.func.isRequired,
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      reset,
      fetch,
      rejectRequest,
      acceptRequest,
    },
    dispatch,
  )
}

const mapStateToProps = (state) => ({
  items: getItems(state),
  isLoading: getIsLoading(state),
  isLoaded: getIsLoaded(state),
  isActionInProgress: getIsActionInProgress(state),
  isModuleActive: (module) => isModuleActive()(state, module),
})

const DashboardRequestsToAcceptManager = connect(
  mapStateToProps,
  mapDispatchToProps,
)(DashboardRequestsToAcceptManagerBase)

export { DashboardRequestsToAcceptManager }
export default DashboardRequestsToAcceptManager
