// initial state
import APIClient from '../../../services/APIClient'
import { change } from 'redux-form'

const getInitialState = () => {
  return {
    data: {},
    saving: false,
  }
}

// constants
export const MOUNT_POINT = 'sensitive-data'

export const RESET = `${MOUNT_POINT}::reset`
export const START_LOADING = `${MOUNT_POINT}::start-loading`
export const SET = `${MOUNT_POINT}::set`

// reducer
export const reducer = (state = getInitialState(), action) => {
  switch (action.type) {
    case RESET:
      return getInitialState()
    default:
      return state
  }
}

// actions
export const reset = () => (dispatch) => {
  dispatch({
    type: RESET,
  })
}

export const changeFieldValue = (form, name, value) => (dispatch) => {
  dispatch(change(form, name, value))
}

export const setSensitiveData = (slug, values) => (dispatch) => {
  return new Promise((resolve, reject) => {
    return APIClient.setSensitiveData(slug, values)
      .then((response) => {
        resolve(response)
      })
      .catch((response) => {
        reject(response)
      })
  })
}

// selectors
export const getState = (state) => {
  return state.get(MOUNT_POINT)
}
