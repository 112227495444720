import React, { useState, memo } from 'react'
import PropTypes from 'prop-types'

function RequestCollapsableSectionManager({ children, defaultOpen = true }) {
  const [isOpen, setOpen] = useState(defaultOpen)
  const toggle = () => setOpen(!isOpen)
  const childrenProps = {
    isOpen,
    toggle,
  }

  return children(childrenProps)
}

RequestCollapsableSectionManager.propTypes = {
  children: PropTypes.func.isRequired,
  defaultOpen: PropTypes.bool,
}

export default memo(RequestCollapsableSectionManager)
