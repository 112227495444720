import React from 'react'
import { Link } from 'react-router-dom'
import classnames from 'classnames'
import _ from 'lodash'
import PropTypes from 'prop-types'

function CellGroup({ children, href, className, fixedWidth }) {
  const classes = classnames({
    'table-accordion__cell-group': true,
    'table-accordion__cell-group__link': !!href,
    [className]: !!className,
  })

  const styles = () => {
    const style = {}

    if (_.isNumber(fixedWidth) && fixedWidth > 0) {
      style['width'] = `${fixedWidth}px`
      style['flex'] = `0 0 ${fixedWidth}px`
    } else if (fixedWidth === 'auto') {
      style['flex'] = `1`
    }

    return style
  }

  const renderGroup = () => {
    return (
      <div className={classes} style={styles()}>
        {children}
      </div>
    )
  }

  const renderLinkedGroup = () => {
    return (
      <Link to={href} className={classes} style={styles()}>
        {children}
      </Link>
    )
  }

  return href ? renderLinkedGroup() : renderGroup()
}

CellGroup.propTypes = {
  children: PropTypes.any,
  href: PropTypes.string,
  className: PropTypes.string,
  fixedWidth: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
}

export { CellGroup }
export default CellGroup
