import React from 'react'
import { provide } from '../../../containers/FlightsBooking'
import ReservationContent from './ReservationContent'

const ReservationComponent = () => <ReservationContent />

const Reservation = provide((props) => {
  return {
    name: props.element.key,
    request: props.request,
    element: props.element,
  }
}, true)(ReservationComponent)

export { Reservation }
export default Reservation
