import React from 'react'
class ComponentOverlay extends React.Component<any, any> {
  render() {
    const { children, className = '' } = this.props

    const classes = ['component-overlay']
      .concat(className.split(' '))
      .filter((className) => className)
      .join(' ')

    return (
      <div className={classes}>
        <div className='component-overlay__content'>{children}</div>
      </div>
    )
  }
}

export { ComponentOverlay }
export default { ComponentOverlay }
