import PropTypes from 'prop-types'
import React from 'react'
import { isObject, isFunction } from 'lodash'
import DatePicker from 'react-datepicker'
import moment from 'moment'
import Icon from '../IconComponent'

import { trans } from '../../../trans'
import { connect } from 'react-redux'
import { getCurrentLocale } from '../../../store/app/user-profile/userProfileSlice'

let instance = 1

class DatePickerField extends React.Component<any, any> {
  state = {
    value: null,
  }

  handleOnChange = (value) => {
    const { onChange } = this.props

    if (isFunction(onChange)) {
      onChange(value)
    }
  }

  componentDidMount() {
    this.setState({
      value: this.props.value,
      portalId: `portal-${instance++}`,
    })
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const prevValue = this.state.value
    const value = this.props.value

    if (value !== prevValue) {
      this.setState({
        value,
      })
    }
  }

  componentWillUnmount() {
    if (isFunction(this.props.onBlur)) {
      this.props.onBlur(this.getValue())
    }
  }

  getValue() {
    return this.state.value || this.props.value
  }

  handleClose = () => {
    this.handleOnChange(this.getValue())
  }

  handleOpen = () => {
    // Hack for centering on selected time
    setTimeout(() => {
      const selectedTime = document.querySelector(
        `#${this.state.portalId} .react-datepicker__time-list-item--selected`,
      )

      if (selectedTime) {
        selectedTime.scrollIntoView({ block: 'center' })
      }
    })
  }

  render() {
    const {
      name,
      className,
      placeholder,
      isClearable,
      minDate,
      maxDate,
      onBlur,
      disabled,
      onKeyDown,
      onFocus,
      showTimeSelect,
      shouldCloseOnSelect,
      timeFormat,
      dateFormat,
      timeIntervals,
      locale,
      disabledCalendar,
      usePortal,
    } = this.props

    const { portalId } = this.state
    const value = this.getValue()

    let minDateObject = minDate
    if (minDateObject && !isObject(minDateObject)) {
      minDateObject = moment(minDateObject)
    }

    let maxDateObject = maxDate
    if (maxDateObject && !isObject(maxDateObject)) {
      maxDateObject = moment(maxDateObject)
    }

    return (
      <div className='date-picker-field' data-portal-id={portalId}>
        <DatePicker
          name={name}
          placeholderText={placeholder}
          portalId={showTimeSelect || usePortal ? portalId : undefined}
          onChange={(value) => {
            if (value && !(value instanceof moment)) {
              value = moment(value)
            }

            this.setState({
              value: value,
            })

            this.handleOnChange(value)
          }}
          onFocus={onFocus}
          onBlur={() => {
            if (isFunction(onBlur)) {
              onBlur(this.state.value)
            }
          }}
          onKeyDown={onKeyDown}
          selected={
            value && typeof value.isValid === 'function' && value.isValid()
              ? value.toDate()
              : undefined
          }
          isClearable={isClearable}
          className={className}
          minDate={minDateObject}
          maxDate={maxDateObject}
          disabled={disabled}
          showTimeSelect={showTimeSelect}
          onCalendarClose={this.handleClose}
          onCalendarOpen={this.handleOpen}
          timeCaption={trans('ui.datepicker-time-caption')}
          shouldCloseOnSelect={shouldCloseOnSelect}
          ref={(ref) => (this.ref = ref)}
          onClickOutside={() => {
            this.ref.cancelFocusInput()
            this.ref.setOpen(false)
          }}
          autoComplete='off'
          timeFormat={timeFormat}
          dateFormat={dateFormat || 'dd.MM.yyyy'}
          timeIntervals={timeIntervals}
          popperPlacement='bottom-start'
          locale={locale}
          popperModifiers={{
            offset: {
              enabled: true,
            },
            preventOverflow: {
              enabled: true,
              escapeWithReference: false, // force popper to stay in viewport (even when input is scrolled out of view)
              boundariesElement: 'window',
            },
          }}
        />
        {disabledCalendar !== true ? (
          <Icon
            type='calendar'
            className='date-picker-field__icon'
            onClick={() => {
              if (this.ref) {
                this.ref.setOpen(true)
              }
            }}
          />
        ) : (
          ''
        )}
      </div>
    )
  }
}

DatePickerField.propTypes = {
  onChange: PropTypes.func,
  name: PropTypes.string,
  className: PropTypes.string,
  placeholder: PropTypes.string,
  isClearable: PropTypes.bool,
  minDate: PropTypes.any,
  value: PropTypes.any,
  disabled: PropTypes.bool,
  showTimeSelect: PropTypes.bool,
  shouldCloseOnSelect: PropTypes.bool,
  disabledCalendar: PropTypes.bool,
}

DatePickerField.defaultProps = {
  isClearable: false,
}

const mapStateToProps = (state) => {
  return {
    locale: getCurrentLocale(state),
  }
}

DatePickerField = connect(mapStateToProps)(DatePickerField)

export { DatePickerField }
