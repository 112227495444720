import { useAsyncPaginate, useComponents, wrapMenuList } from 'react-select-async-paginate'
import React from 'react'
import CreatableSelect from 'react-select/creatable'

export const CustomAsyncPaginateComponent = ({
  components: defaultComponents,
  value,
  onChange,

  loadOptions,
  options,
  defaultOptions,
  additional,
  defaultAdditional,
  loadOptionsOnMenuOpen,
  debounceTimeout,
  reduceOptions,
  shouldLoadMore,
  filterOption,
  inputValue,
  menuIsOpen,
  defaultInputValue,
  defaultMenuIsOpen,
  onInputChange,
  onMenuClose,
  onMenuOpen,
  ...rest
}) => {
  const asyncPaginateProps = useAsyncPaginate({
    loadOptions,
    options,
    defaultOptions,
    additional,
    defaultAdditional,
    loadOptionsOnMenuOpen,
    debounceTimeout,
    reduceOptions,
    shouldLoadMore,
    filterOption,
    inputValue,
    menuIsOpen,
    defaultInputValue,
    defaultMenuIsOpen,
    onInputChange,
    onMenuClose,
    onMenuOpen,
  })

  const components = useComponents(defaultComponents)

  return (
    <CreatableSelect
      {...asyncPaginateProps}
      {...rest}
      allowCreateWhileLoading={true}
      components={components}
      value={value}
      onChange={onChange}
      createOptionPosition='first'
      formatCreateLabel={(value) => value}
    />
  )
}
