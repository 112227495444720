import trans from '../../trans'
import { AmountFormatter } from '../AmountFormatter'
import { round } from '../../utils/number'
import React from 'react'
import { StatementSummary as Model } from '../../types/statements'
import className from 'classnames'

export default function StatementSummary({ data }: { data: Model[] }) {
  if (!data || !data.length) {
    return null
  }

  return (
    <>
      <h2 className='h2 section__header-title'>{trans('my-card.statement-balance-header')}</h2>

      <div className='table-accordion-container'>
        <div className='table-accordion'>
          <div className='table-accordion__body'>
            <div className='accordion table-accordion__accordion'>
              <div key='balance-row-title' className='table-accordion__row row'>
                <div className='table-accordion__col xs-9' />
                <div className='table-accordion__col xs-3 is-allign-end'>
                  <strong>{trans('request-summary.amount')}</strong>
                </div>
              </div>
            </div>

            {data.map(({ name, currency, value }, index) => {
              const valueClasses = className({
                'table-accordion__col': true,
                'xs-3': true,
                'is-allign-end': true,
                'is-last': index === data.length - 1,
              })

              return (
                <div className='accordion table-accordion__accordion'>
                  <div className='table-accordion__row row'>
                    <div className='table-accordion__col xs-9'>{trans(name)}</div>
                    <div className={valueClasses}>
                      <div>
                        <strong>
                          <AmountFormatter amount={round(value, 2)} /> {currency}
                        </strong>
                      </div>
                    </div>
                  </div>
                </div>
              )
            })}
          </div>
        </div>
      </div>
    </>
  )
}
