import PropTypes from 'prop-types'
import React from 'react'
import { Field } from 'redux-form/immutable'
import { FormField } from '../ui/Form'
import { round } from '../../utils/number'
import { Loader } from '../ui/LoadingOverlay/Loader'
import { setRemainingSumProgress } from '../../store/app/document-expense-types'

class DocumentExpenseTypeForm extends React.Component<any, any> {
  constructor(props) {
    super(props)
  }

  setRemainingSum() {
    const {
      remainingSum,
      change,
      dispatch,
      data: { gross },
    } = this.props

    if (Number.parseFloat(remainingSum) > 0 && !gross) {
      dispatch(change('gross', round(remainingSum)))
      dispatch(setRemainingSumProgress(true))
    }
  }

  render() {
    const { submitting } = this.props
    return (
      <div>
        {submitting ? <Loader small /> : null}
        <Field
          name='gross'
          type='formatted_number'
          inputOnly
          component={FormField}
          withError
          labeltop
          onFocus={() => this.setRemainingSum()}
        />
      </div>
    )
  }
}

DocumentExpenseTypeForm.propTypes = {
  expense: PropTypes.object.isRequired,
}

export { DocumentExpenseTypeForm }
export default { DocumentExpenseTypeForm }
