import React from 'react'
import { trans } from '../../../../trans'
import { AmountFormatter } from '../../../AmountFormatter'
import { TravelPointLabel } from '../../../TravelPointLabel'
import { ForeignCurrenciesTooltip } from '../../../ForeignCurrenciesTooltip'
import { TravelPointDate } from '../../../TravelPointDate'
import { Accommodation as AccommodationForm } from '../Containers/Accommodation'
import { OfferTimeline } from './OfferTimeline'

class AccommodationTimeline extends OfferTimeline {
  icon() {
    return 'hotel_fill'
  }

  renderLabel() {
    const { element } = this.props
    const arrivalDate = element.getStartDate()
    const departureDate = element.getEndDate()

    if (!element.getStartLocation().city || !element.getStartDate()) {
      return trans('request.fill-travel-data')
    } else {
      return (
        <div>
          <TravelPointLabel location={element.getStartLocation()} showDirections />
          <TravelPointDate start={arrivalDate} end={departureDate} />
        </div>
      )
    }
  }

  renderForeignCurrenciesTooltip() {
    const { element } = this.props

    const instanceCurrency =
      this.props.instanceCurrency && this.props.instanceCurrency.length > 0
        ? this.props.instanceCurrency
        : this.props.request.user.instance.default_currency

    return (
      <ForeignCurrenciesTooltip
        instanceCurrency={instanceCurrency}
        foreignCurrencies={element.getAllAmounts()}
      />
    )
  }

  renderCalculatedAmount() {
    const { element } = this.props

    if (element.virtual) {
      return trans('accommodation.check-out')
    }

    const amount = element.converted_amount
    const currency = element.calculated_amount_currency

    return (
      <div className='flex-end-center'>
        {this.renderForeignCurrenciesTooltip()}{' '}
        <span>
          <AmountFormatter amount={amount} /> {currency}
        </span>
      </div>
    )
  }

  renderElement() {
    const { request, element, onRemove, currencies } = this.props

    const { isOpen } = this.state

    return (
      <AccommodationForm
        request={request}
        element={element}
        currencies={currencies}
        onSave={this.onSave}
        onRemove={onRemove}
        isOpen={isOpen}
      />
    )
  }
}

export { AccommodationTimeline }
export default { AccommodationTimeline }
