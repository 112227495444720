import React, { PureComponent, Fragment } from 'react'
import PropTypes from 'prop-types'
import APIClient from '../services/APIClient'
import trans from '../trans'
import Button from './ui/ButtonComponent'

class PrintRequestDocuments extends PureComponent<any, any> {
  constructor(props) {
    super(props)

    this.printRef = React.createRef()

    this.state = {
      isPrinting: false,
      isPrinted: false,
    }
  }

  printAccountingDocuments = (e) => {
    this.setState({ isPrinting: true })
    e.stopPropagation()
    const { request } = this.props

    APIClient.getRequestExportURI(request)
      .then((response) => {
        this.setState({ isPrinting: false })
      })
      .catch(() => {
        this.setState({ isPrinting: false })
      })
  }

  renderPrintLabel = () => {
    return this.state.isPrinting
      ? trans('global.print-accounting-documents-in-progress')
      : trans('global.print-accounting-documents')
  }

  render() {
    return (
      <Fragment>
        <Button secondary outline pull_end onClick={this.printAccountingDocuments}>
          {this.renderPrintLabel()}
        </Button>
        <a ref={this.printRef} href='#' target='_blank' style={{ display: 'none' }}>
          hidden print link
        </a>
      </Fragment>
    )
  }
}

PrintRequestDocuments.propTypes = {
  request: PropTypes.object.isRequired,
}

export default PrintRequestDocuments
export { PrintRequestDocuments }
