import React from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { setWaitingForMessage } from '../../../store/app/document-expense-types/index'

const withExpensesActions = () => (Component) => {
  class ExpenseActions extends React.Component<any, any> {
    render() {
      return <Component<any, any> {...this.props} />
    }
  }

  const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
      {
        setWaitingForMessage,
      },
      dispatch,
    )
  }

  return connect(null, mapDispatchToProps)(ExpenseActions)
}

export default withExpensesActions
export { withExpensesActions }
