import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import { Field } from 'redux-form'
import { trans } from '../../../trans'
import { SelectField } from '../../ui/Form'
import { get } from 'lodash'

class CarriageTypeField extends Component<any, any> {
  getOptions = () => {
    const { offer } = this.props
    const option = offer.option

    return get(option, 'availableOptions.carriage_type', []).map((option) => {
      return {
        label: trans('trains-booking.carriage_type-' + option),
        value: option,
      }
    })
  }

  renderValue = () => {
    const { input } = this.props
    return trans('trains-booking.carriage_type-' + input.value)
  }

  render() {
    const { input, meta, disabled } = this.props
    const options = this.getOptions()

    if (!options.length) {
      return options
    }

    if (disabled && !input.value) {
      return null
    }

    return (
      <Fragment>
        <span className='train-trip__selected-ticket-travel-detail-title'>
          {trans('trains-booking.seat-type')}
        </span>
        {disabled && this.renderValue()}

        {!disabled && (
          <div className='train-trip__selected-ticket-place-type-select'>
            <SelectField
              options={options}
              value={input.value}
              placeholder={trans('trains-booking.carriage-type')}
              onChange={(value) => input.onChange(value)}
              disabled={meta.warning}
            />
          </div>
        )}
      </Fragment>
    )
  }
}

CarriageTypeField.propTypes = {}

CarriageTypeField.defaultProps = {
  disabled: false,
}

export default CarriageTypeField
export { CarriageTypeField }
