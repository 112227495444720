import {
  ADD_FORM_ERRORS,
  AFTER_SAVE,
  API_ERROR,
  CLEAR_FORM_ERRORS,
  CLEAR_FORM_INPUT_ERRORS,
  SET_CURRENT_USER,
  SET_LOADING,
  USER_AUTHORIZE,
  USER_LOGOUT,
} from './constants'
import { fetchInstanceBeforeLogin, resetInstance } from '../../store/app/instance'
import { getCurrentLang } from '../../utils/lang'

export const addFormErrorsAction = (formName, errors) => {
  return {
    type: ADD_FORM_ERRORS,
    formName,
    errors,
  }
}

export const clearFormErrorsAction = (formName) => {
  return {
    type: CLEAR_FORM_ERRORS,
    formName,
    errors: {},
  }
}

export const clearFormInputErrorsAction = (formName, fieldKey) => {
  return {
    type: CLEAR_FORM_INPUT_ERRORS,
    formName,
    fieldKey,
  }
}

export const setUserToken = (token, user) => {
  return {
    type: USER_AUTHORIZE,
    isAuthenticated: false,
    token,
    user,
  }
}

export const authorizeUserAction = (token, user) => {
  return {
    type: USER_AUTHORIZE,
    isAuthenticated: true,
    token,
    user,
  }
}

export const logoutUser = () => {
  return {
    type: USER_LOGOUT,
    isAuthenticated: false,
    token: '',
    user: {},
  }
}

export const logoutUserAction = () => (dispatch) => {
  dispatch(resetInstance())

  const lang = getCurrentLang()

  dispatch(fetchInstanceBeforeLogin(lang))
  dispatch(logoutUser())
}

export const setCurrentUserAction = (userData) => {
  return {
    type: SET_CURRENT_USER,
    userData: userData,
  }
}

export const setLoadingAction = (value) => {
  return {
    type: SET_LOADING,
    value: value,
  }
}

export const afterSaveAction = () => {
  return {
    type: AFTER_SAVE,
  }
}

export const apiErrorAction = (status) => {
  return {
    type: API_ERROR,
    status,
  }
}
