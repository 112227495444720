import { createSelector, createSlice } from '@reduxjs/toolkit'
import { isEmpty } from 'lodash'
import APIClient from '../../../services/APIClient'
import { toArray } from '../../../utils/array'
import { PER_PAGE } from '../../../constants/pagination'
import { getCurrentUser } from '../user-profile/userProfileSlice'
import { ASYNC_SELECT_DEFAULT_LIMIT } from '../../../components/ui/Form/AsyncSelectField'
import trans from '../../../trans'

export const MPK_MOUNT_POINT = 'mpk'

/**
 * In @reduxjs/toolkit we can mutate state in reducers,
 * because behind the scene it uses ImmerJS for applying them as non-mutate
 *
 * https://github.com/immerjs/immer
 */
const slice = createSlice({
  name: MPK_MOUNT_POINT,
  initialState: {
    items: [],
    error: null,
    isLoading: false,
    isLoaded: false,
    page: 0,
    pagination: {
      total: 0,
      offset: 0,
      limit: PER_PAGE,
    },
  },
  reducers: {
    setMpks(state, action) {
      const { data } = action.payload

      state.items = data
    },
    setMpk(state, action) {
      const { data } = action.payload
      const index = state.items.findIndex((item) => item.id === data.id)

      if (index === -1) {
        state.items.unshift(data)
      } else {
        state.items[index] = data
      }
    },
    setPagination(state, action) {
      const { pagination } = action.payload
      state.pagination = pagination
      state.page = pagination.offset / pagination.limit + 1
    },
    startLoading(state) {
      state.isLoading = true
      state.isLoaded = false
      state.error = null
    },
    setLoadingSuccess(state) {
      state.isLoading = false
      state.isLoaded = true
      state.error = null
    },
    setLoadingFailed(state, action) {
      state.isLoading = false
      state.isLoaded = false
      state.error = action.payload
    },
  },
})

export const { setMpk, setMpks, startLoading, setLoadingSuccess, setLoadingFailed, setPagination } =
  slice.actions

export default slice.reducer

const transformToOptions = (item) => ({
  label: `${item.code} - ${item.name}`,
  value: item.id,
})

const filterByCompany = (company_id) => (item) => !item.company_id || item.company_id === company_id

// selectors
const getState = (state) => state.get(MPK_MOUNT_POINT)
export const getPage = (state) => getState(state).page
export const getMpks = (state) => getState(state).items
export const isLoading = (state) => getState(state).isLoading
export const isLoaded = (state) => getState(state).isLoaded
export const getMpksTotalCount = (state) => getState(state).pagination.total

export const getMpkSelectOptions = createSelector(
  (state) => getCurrentUser(state),
  (state) => getMpks(state),
  (currentUser, items) =>
    items.filter(filterByCompany(currentUser.company_id)).map(transformToOptions),
)

export const getActiveMpkSelectOptions = createSelector(
  (state) => getCurrentUser(state),
  (state, _include) => getMpks(state),
  (_state, include) => toArray(include),
  (currentUser, items, includeIds) =>
    items
      .filter(filterByCompany(currentUser.company_id))
      .filter((item) => item.is_active || includeIds.includes(item.id))
      .map(transformToOptions),
)

// memoized selectors, improve performance
export const makeGetMpkByCompanyIdSelector = () =>
  createSelector(
    [(state, _companyId) => getMpks(state), (state, companyId) => companyId],
    (items, companyId) => {
      if (companyId) {
        return items.filter(filterByCompany(companyId))
      }

      return items
    },
  )

export const makeGetMpkByCompanyIdForSelectOptionsSelector = () => {
  return createSelector(makeGetMpkByCompanyIdSelector(), (items) =>
    items
      .filter((mpk) => mpk.is_active)
      .map((item) => {
        return transformToOptions(item)
      }),
  )
}

export const fetchEditableMpk =
  (page = 0) =>
  async (dispatch, getState) => {
    if (page === getPage(getState())) {
      dispatch(setLoadingSuccess())
      return
    }

    dispatch(startLoading())

    try {
      const offset = page * PER_PAGE - PER_PAGE
      const { data, pagination } = await APIClient.getEditableMpk(offset, PER_PAGE)

      dispatch(setMpks({ data }))
      dispatch(setPagination({ pagination }))
      dispatch(setLoadingSuccess())
    } catch (err) {
      dispatch(setLoadingFailed(err))
    }
  }

// action thunks
export const fetchMpk =
  (page = 0) =>
  async (dispatch, getState) => {
    if (page === getPage(getState())) {
      dispatch(setLoadingSuccess())
      return
    }

    dispatch(startLoading())

    try {
      const offset = page * PER_PAGE - PER_PAGE
      const { data, pagination } = await APIClient.getMPKs(offset, PER_PAGE)

      dispatch(setMpks({ data }))
      dispatch(setPagination({ pagination }))
      dispatch(setLoadingSuccess())
    } catch (err) {
      dispatch(setLoadingFailed(err))
    }
  }

export const createOrUpdateMPK = (mpk) => async (dispatch) => {
  const { data } = mpk.slug
    ? await APIClient.updateMPK(mpk.slug, mpk)
    : await APIClient.createMPK(mpk)

  await dispatch(fetchEditableMpk())

  return data
}

export const fetchMpkForAsyncSelect =
  (companyId, withPlaceholder: boolean = true) =>
  async (search, prevOptions) => {
    const limit = ASYNC_SELECT_DEFAULT_LIMIT
    const offset = !search && prevOptions.length > 0 ? prevOptions.length - 1 : prevOptions.length
    try {
      const response = await APIClient.getMPKs(companyId, offset, limit, search)

      let data = response.data.map((item) => {
        return {
          value: item.id,
          label: item.name,
          ...item,
        }
      })

      const hasPlaceholder =
        prevOptions.filter((item) => item.label === trans('global.all')).length > 0

      if (withPlaceholder && !hasPlaceholder) {
        data = [
          {
            label: trans('global.all'),
            value: null,
            onSelectResetsInput: true,
          },
          ...data,
        ]
      }

      return {
        options: data,
        hasMore: response.pagination.total > response.pagination.offset,
      }
    } catch (_) {
      return {
        options: [],
        hasMore: false,
      }
    }
  }
