import React from 'react'
import Button from './ButtonComponent'
import Tabs from '../Tabs'
import Icon, { ICON_TYPES } from './IconComponent'
import { Col, Row } from '../../ui/Grid'
import { Tooltip } from '../Tooltip'
import { DatePickerField } from './Form'
import { Checkbox } from '../../ui'
import { Scrollbars } from 'react-custom-scrollbars-2'
import InputRange from 'react-input-range'

export const StyleGuide = () => {
  const hotelOffers = []
  for (let i = 0; i < 10; ++i) {
    hotelOffers.push(
      <div key={`hotel-offer-${i}`} className='accommodation__hotel-offer'>
        <aside className='accommodation__hotel-details-bar'>
          <div className='accommodation__hotel-stars'>
            <Icon type='star_fill' />
            <Icon type='star_fill' />
            <Icon type='star_fill' />
            <Icon type='star_fill' />
            <Icon type='star_fill' />
          </div>

          <div className='accommodation__hotel-accessories'>
            <Icon type='restaurant' />
            <Icon type='restaurant' />
            <Icon type='restaurant' />
          </div>
        </aside>

        <div className='accommodation__hotel-info'>
          <div className='accommodation__hotel-gallery'>
            <button
              className='accommodation__hotel-gallery-button accommodation__hotel-gallery-button--prev'
              type='button'
            >
              <Icon type='arrow' />
            </button>

            <img className='accommodation__hotel-image' src='//placehold.it/200x450' />

            <button
              className='accommodation__hotel-gallery-button accommodation__hotel-gallery-button--next'
              type='button'
            >
              <Icon type='arrow' />
            </button>
          </div>

          <div className='accommodation__hotel-info-details'>
            <span className='accommodation__hotel-name'>
              <Tooltip html={<span>Lorem ipsum dolor sit amet.</span>}>
                <span
                  className='icon-info-grey accommodation__hotel-icon-info'
                  aria-hidden='true'
                />
              </Tooltip>
              <span>Hotel Berlin City Nord by Campanile</span>
            </span>
            <span className='accommodation__hotel-address'>
              <Icon className='accommodation__hotel-address-icon' type='pin_1' />
              <span>Wittenberget Str. 87, 04129</span>
            </span>
            <span className='accommodation__hotel-price'>640,00 PLN</span>
            <span className='accommodation__hotel-price-details'>Od 320,00 PLN/noc (4 oferty)</span>
          </div>
        </div>
      </div>,
    )
  }

  const packets = []
  for (let i = 0; i < 10; ++i) {
    packets.push(
      <div key={`packet-${i}`} className='accommodation__hotel-packet'>
        <div className='accommodation__hotel-packet-item'>
          <Icon type='restaurant' />
          <span>Pojedyncze łózko</span>
        </div>

        <div className='accommodation__hotel-packet-item'>
          <Icon type='restaurant' />
          <span>Śniadanie</span>
        </div>

        <div className='accommodation__hotel-packet-prices'>
          <span className='accommodation__hotel-price'>640,00 PLN</span>
          <span className='accommodation__hotel-price-details'>Od 320,00 PLN/noc (4 oferty)</span>
        </div>

        <button type='button' className='btn btn--primary accommodation__hotel-packet-button'>
          Wybierz
        </button>
      </div>,
    )
  }

  return (
    <div className='styleguide'>
      <div className='table-accordion'>
        <div className='table-accordion__head'>
          <div className='row'>
            <div className='table-accordion__col xs-5'>Nazwa</div>
            <div className='table-accordion__col xs-3 is-allign-end'>Wnioskowana kwota</div>
            <div className='table-accordion__col xs-3'></div>
            <div className='xs-1 table-accordion__row-icon'></div>
          </div>
        </div>

        <div className='table-accordion__body'>
          <div className='accordion table-accordion__accordion active'>
            <div className='accordion__bar'>
              <div className='table-accordion__row row'>
                <div className='table-accordion__col xs-5'>Koszty podróży</div>
                <div className='table-accordion__col xs-3 is-allign-end'>
                  <Icon className='is-color-warning' type='warning' /> 2 587,00 PLN
                </div>
                <div className='table-accordion__col xs-3'>430 PLN/noc</div>
                <div className='xs-1 table-accordion__row-icon'>
                  <Icon type='arrow' />
                </div>
              </div>
            </div>

            <div className='accordion__content'>
              <div className='table-accordion__row row'>
                <div className='table-accordion__col xs-5'>
                  <Icon className='is-color-grey' type='plane' /> Koszty podróży
                </div>
                <div className='table-accordion__col xs-3 is-allign-end'>2 587,00 PLN</div>
                <div className='table-accordion__col xs-3'>
                  <Icon type='hotel' /> <Icon type='gift' /> 430 PLN/noc
                </div>
                <div className='xs-1 table-accordion__row-icon'></div>
              </div>

              <div className='table-accordion__row row'>
                <div className='table-accordion__col xs-5'>Koszty podróży</div>
                <div className='table-accordion__col xs-3 is-allign-end'>2 587,00 PLN</div>
                <div className='table-accordion__col xs-3'>430 PLN/noc</div>
                <div className='xs-1 table-accordion__row-icon'></div>
              </div>
            </div>
          </div>

          <div className='accordion table-accordion__accordion'>
            <div className='accordion__bar'>
              <div className='table-accordion__row row'>
                <div className='table-accordion__col xs-5'>Koszty podróży</div>
                <div className='table-accordion__col xs-3 is-allign-end'>
                  <Icon className='is-color-warning' type='warning' /> 2 587,00 PLN
                </div>
                <div className='table-accordion__col xs-3'>430 PLN/noc</div>
                <div className='xs-1 table-accordion__row-icon'>
                  <Icon type='arrow' />
                </div>
              </div>
            </div>

            <div className='accordion__content'>
              <div className='table-accordion__row row'>
                <div className='table-accordion__col xs-5'>
                  <Icon className='is-color-grey' type='plane' /> Koszty podróży
                </div>
                <div className='table-accordion__col xs-3 is-allign-end'>2 587,00 PLN</div>
                <div className='table-accordion__col xs-3'>
                  <Icon type='hotel' /> <Icon type='gift' /> 430 PLN/noc
                </div>
                <div className='xs-1 table-accordion__row-icon'></div>
              </div>

              <div className='table-accordion__row row'>
                <div className='table-accordion__col xs-5'>Koszty podróży</div>
                <div className='table-accordion__col xs-3 is-allign-end'>2 587,00 PLN</div>
                <div className='table-accordion__col xs-3'>430 PLN/noc</div>
                <div className='xs-1 table-accordion__row-icon'></div>
              </div>
            </div>
          </div>
        </div>

        <div className='table-accordion__footer'>
          <div className='row'>
            <div className='table-accordion__col xs-5'>
              <span className='table-accordion__footer-bold'>Wnioskowana kwota</span>W tym zaliczki
            </div>
            <div className='table-accordion__col xs-3 is-allign-end'>
              <span className='table-accordion__footer-bold'>4 500,00 PLN</span>
              637,37 PLN
            </div>
            <div className='table-accordion__col xs-3'>(150,00 EUR)</div>
            <div className='xs-1 table-accordion__row-icon'></div>
          </div>
        </div>
      </div>

      <div>
        <header className='section__header section__header--inline'>
          <h2 className='h2 section__header-title'>Podsumowanie wniosku</h2>
        </header>

        <p>Lorem ipsum</p>
      </div>

      <h2 className='h2'>Typography</h2>
      <p>
        Default paragraph <strong>Bold</strong> <Icon type='check' />
      </p>
      <code>&lt;p&gt;Default paragraph &lt;strong&gt;Bold&lt;/strong&gt;&lt;/p&gt;</code>

      <ul>
        <li>List element</li>
        <li>List element</li>
        <li>List element</li>
      </ul>
      <hr />

      <h2 className='h2'>Headings</h2>
      <h1 className='h1'>Heading h1</h1>
      <code>&lt;h1 className="h1"&gt;Heading h1&lt;/h1&gt;</code>
      <h2 className='h2'>
        Heading h2 <Icon type='check' />
      </h2>
      <h3 className='h3'>Heading h3</h3>
      <h4 className='h4'>Heading h4</h4>
      <h5 className='h5'>Heading h5</h5>
      <hr />

      <h2 className='h2'>Links</h2>
      <a href=''>Link</a>
      <hr />

      <h2 className='h2'>Icons</h2>

      <Row>
        {ICON_TYPES.map((type) => {
          return (
            <Col key={`icon-${type}`} xs={2}>
              <p style={{ textAlign: 'center' }}>
                <span style={{ fontSize: '40px' }}>
                  <Icon type={type} />
                </span>
                <code
                  style={{
                    width: '100%',
                    wordWrap: 'break-word',
                    wordBreak: 'break-all',
                    margin: 0,
                  }}
                >
                  &lt;Icon type="{type}"/&gt;
                </code>
              </p>
            </Col>
          )
        })}
      </Row>

      <hr />

      <h2 className='h2'>
        Buttons <Icon type='check' />
      </h2>
      <Button primary>Button primary</Button>
      <code>&lt;Button primary&gt;Button primary&lt;/Button&gt;</code>
      <Button primary disabled>
        Button primary
      </Button>
      <code>&lt;Button primary disabled&gt;Button primary&lt;/Button&gt;</code>

      <Button secondary>Button secondary</Button>
      <code>&lt;Button secondary&gt;Button secondary&lt;/Button&gt;</code>
      <Button secondary disabled>
        Button secondary
      </Button>
      <code>&lt;Button secondary disabled&gt;Button secondary&lt;/Button&gt;</code>
      <hr />

      <h2 className='h2'>Inputs</h2>

      <section className='row'>
        <div className='form-group form-group--label-top sm-6'>
          <label className='form-group__wrapper'>
            <span className='form-group__wrapper-label'>Label Label Label</span>
            <input
              className='form-group__wrapper-input input input--text'
              placeholder='Placeholder'
              type='text'
            />
          </label>
        </div>

        <div className='form-group form-group--label-top sm-12'>
          <label className='form-group__wrapper'>
            <span className='form-group__wrapper-label'>Label</span>
            <textarea
              placeholder='Placeholder'
              className='form-group__wrapper-input input input--textarea'
            ></textarea>
          </label>
        </div>
      </section>

      <label htmlFor='return' className='input-check'>
        <input
          className='input-check__input'
          type='checkbox'
          id='return'
          name='action'
          value='-1'
        />
        <span className='input-check__icon'></span>
        <span className='input-check__label'> Zwróć do poprawy:</span>
      </label>

      <hr />
      <h2 className='h2'>
        Input group <Icon type='check' />
      </h2>
      <div className='input-group'>
        <Icon type='search' />
        <input type='text' placeholder='Placeholder' className='input-group__input' />
        <button className='input-group__btn'>Button</button>
      </div>

      <code>
        &lt;div className=&quot;input-group&quot;&gt;
        <br />
        &lt;Icon type=&quot;search&quot;/&gt;
        <br />
        &lt;input type=&quot;text&quot; placeholder=&quot;Placeholder&quot;
        className=&quot;input-group__input&quot;/&gt;&lt;button
        className=&quot;input-group__btn&quot;&gt; Button&lt;/button&gt;
        <br />
        &lt;/div&gt;
      </code>

      <hr />
      <h2 className='h2'>Tooltip</h2>

      <div style={{ display: 'inline-block' }}>
        <Tooltip
          useContext
          interactive
          position='top'
          popperOptions={{
            modifiers: {
              preventOverflow: {
                enabled: false,
              },
            },
            data: {
              placement: 'top',
              originalPlacement: 'top',
            },
          }}
          html={
            <div>
              <strong>Nagłówek wyświetlany w tooltipie</strong>
              <div className='row'>
                <div className='xs-3' style={{ alignSelf: 'center' }}>
                  <img
                    className='avatar'
                    src='https://api.adorable.io/avatars/50/c019044291953e626b11918a744f43cd.png'
                  />
                </div>
                <div className='xs-9' style={{ paddingBottom: '15' }}>
                  <p style={{ marginBottom: '0' }}>Superadmin Superadmin</p>
                  <a style={{ color: '#fff' }} href='mailto:superadmin@netkata.com'>
                    superadmin@netkata.com
                  </a>
                </div>
              </div>
            </div>
          }
        >
          <Icon lg greyLight type='question_fill' />
        </Tooltip>
      </div>

      <div></div>

      <code>
        {`<div style={{display: 'inline-block'}}>
                <Tooltip
                    useContext
                    interactive
                    position="top"
                    popperOptions={{
                    modifiers: {
                        preventOverflow: {
                            enabled: false
                        },
                    },
                    data: {
                        placement: "top",
                        originalPlacement: "top",
                    },
                }}
                    html={(
                        <div>
                            <strong>
                                Nagłówek wyświetlany w tooltipie
                            </strong>
                            <div className="row">
                                <div className="xs-3" style={{alignSelf: 'center'}}>
                                    <img className="avatar" src="https://api.adorable.io/avatars/50/c019044291953e626b11918a744f43cd.png" />
                                </div>
                                <div className="xs-9" style={{paddingBottom: '15'}}>
                                    <p style={{marginBottom: '0'}}>Superadmin Superadmin</p>
                                    <a style={{color: '#fff'}} href="mailto:superadmin@netkata.com">superadmin@netkata.com</a>
                                </div>
                            </div>
                        </div>
                    )}
                >
            </div>`}
      </code>

      <hr />

      <div>
        <h2 className='h2'>Tabs</h2>
        <Tabs
          items={[
            {
              id: 'styleguide-tab-1',
              title: 'Styleguide Tab #1',
              content: <p>Any JSX you want</p>,
            },
            {
              id: 'styleguide-tab-2',
              title: 'Styleguide Tab #2',
              content: (
                <span>
                  Lorem ipsum dolor sit amet, consectetur adipisicing elit. Asperiores dolore,
                  impedit laborum magnam numquam recusandae.
                </span>
              ),
            },
            {
              id: 'styleguide-tab-3',
              title: 'Styleguide Tab #3',
              content: (
                <div>
                  <h2 className='h2'>Some content</h2>
                  <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Nobis, quas.</p>
                </div>
              ),
            },
          ]}
        />
        <code>{`<Tabs items={[
                    { id: 'styleguide-tab-1', title: 'Styleguide Tab #1', content: <p>Any JSX you want</p> },
                    { id: 'styleguide-tab-2', title: 'Styleguide Tab #2', content: <span>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Asperiores dolore, impedit laborum magnam numquam recusandae.</span> },
                    { id: 'styleguide-tab-3', title: 'Styleguide Tab #3', content: <div><h2 className="h2">Some content</h2><p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Nobis, quas.</p></div> },
                ]}/>`}</code>
      </div>

      <br />

      <div>
        <div className='accommodation__form-wrapper'>
          <span className='accommodation__heading'>Dodaj nocleg</span>

          <div className='accommodation__form-group'>
            <label className='accommodation__form-group-label'>Miejsce</label>
            <input className='input input--text' type='text' />
          </div>

          <div className='accommodation__form-group'>
            <label className='accommodation__form-group-label'>Data przyjazdu</label>
            <div className='form-group__input-wrapper'>
              <DatePickerField />
            </div>
          </div>

          <div className='accommodation__form-group'>
            <label className='accommodation__form-group-label'>Data wyjazdu</label>
            <div className='form-group__input-wrapper'>
              <DatePickerField />
            </div>
          </div>

          <div className='accommodation__form-group'>
            <label className='accommodation__form-group-label'>Standard do</label>
            <div className='accommodation__stars'>
              <Icon type='star_fill' />
              <Icon type='star_fill' />
              <Icon type='star_fill' />
              <Icon type='star' />
              <Icon type='star' />
            </div>
          </div>

          <div className='accommodation__form-group'>
            <label htmlFor='' className='accommodation__form-group-label'></label>
            <Checkbox label='Śniadania' />
            <Checkbox label='Parking' />
            <Checkbox label='Wi-fi' />
          </div>
        </div>

        <div className='accommodation__offers'>
          <span className='accommodation__heading'>Oferty hoteli</span>

          <div style={{ display: 'none' }}>
            <div className='accommodation__map'></div>
            <div className='accommodation__search-dashboard'>
              <label className='accommodation__search-dashboard-label'>Promień wyszukiwania</label>
              <div className='accommodation__search-range-container'>
                <InputRange minValue={0} maxValue={10} value={1} onChange={() => {}} />
                <div className='accommodation__search-range-icons'>
                  <Icon type='car_flat' />
                  <Icon type='car_flat' />
                </div>
              </div>
              <span className='accommodation__search-dashboard-label'>2km</span>
              <button className='btn btn--primary accommodation__search-dashboard-button'>
                Wyszukaj oferty
              </button>
            </div>
          </div>

          <div className='accommodation__hotels-container'>
            <div className='accommodation__hotels-options'>
              <button type='button' className='accommodation__hotels-option-button'>
                <Icon type='search' />
                <span>Lista</span>
              </button>
              <button type='button' className='accommodation__hotels-option-button'>
                <Icon type='search' />
                <span>Mapa</span>
              </button>
            </div>

            <Scrollbars style={{ height: '285px', display: 'block' }}>
              <div className='accommodation__hotels-list'>{hotelOffers}</div>
            </Scrollbars>

            <div className='accommodation__hotel-details-container'>
              <aside className='accommodation__hotel-details-bar'>
                <div className='accommodation__hotel-stars'>
                  <Icon type='star_fill' />
                  <Icon type='star_fill' />
                  <Icon type='star_fill' />
                  <Icon type='star_fill' />
                  <Icon type='star_fill' />
                </div>

                <div className='accommodation__hotel-accessories'>
                  <Icon type='restaurant' />
                  <Icon type='restaurant' />
                  <Icon type='restaurant' />
                </div>
              </aside>

              <div className='accommodation__hotel-details-main'>
                <div className='accommodation__hotel-details-gallery'>
                  <img
                    src='//placehold.it/300x200'
                    className='accommodation__hotel-gallery-image'
                  />
                  <img
                    src='//placehold.it/300x200'
                    className='accommodation__hotel-gallery-image'
                  />
                  <img
                    src='//placehold.it/300x200'
                    className='accommodation__hotel-gallery-image'
                  />
                  <img
                    src='//placehold.it/300x200'
                    className='accommodation__hotel-gallery-image'
                  />
                  <img
                    src='//placehold.it/300x200'
                    className='accommodation__hotel-gallery-image'
                  />
                </div>

                <div className='accommodation__hotel-details-text'>
                  <span className='accommodation__hotel-name'>
                    Hotel Berlin City Nord by Campanile
                  </span>
                  <p className='accommodation__hotel-details-copy'>
                    Lorem ipsum dolor sit amet, consectetur adipisicing elit. Consequatur cumque
                    doloribus dolorum ducimus eius, enim fugit illum ipsa nulla, placeat quasi,
                    reprehenderit? Atque ea eveniet fugit id, iure maxime similique.
                  </p>
                </div>

                <Scrollbars style={{ height: '210px' }}>
                  <div className='accommodation__hotel-details-packets'>{packets}</div>
                </Scrollbars>
              </div>
            </div>

            <button type='button' className='btn btn--primary'>
              Zmień wyszukiwanie
            </button>
          </div>
        </div>

        <div className='accommodation__save'>
          <label className='accommodation__save-label'>Wnioskowana kwota</label>

          <div className='accommodation__amount-input-wrapper'>
            <Icon
              className='accomodation__amount-warning-icon is-color-warning is-gradient-warning'
              type='warning'
            />
            <input type='number' className='accommodation__amount-input input input--text' />
            <span>PLN</span>
          </div>

          <button className='btn btn--primary'>Zapisz wniosek</button>
        </div>
      </div>
    </div>
  )
}
