import * as Sentry from '@sentry/react'
import { createSelector, createSlice } from '@reduxjs/toolkit'
import APIClient from '../../../services/APIClient'
import Session from '../../../services/Session'
import { history } from '../../../store'

export const AUTH_MOUNT_POINT = 'auth'

/**
 * In @reduxjs/toolkit we can mutate state in reducers,
 * because behind the scene it uses ImmerJS for applying them as non-mutate
 *
 * https://github.com/immerjs/immer
 */
const slice = createSlice({
  name: AUTH_MOUNT_POINT,
  initialState: {
    user: null,
  },
  reducers: {
    setUser(state, action) {
      state.user = action.payload
    },
  },
})

export default slice.reducer

// selectors
const getState = (state) => state.get(AUTH_MOUNT_POINT)

// memoized selectors, improve performance
export const getCurrentUser = createSelector(getState, (state) => state.user)
export const getCurrentLocale = createSelector(getCurrentUser, (user) => (user ? user.lang : 'pl'))

// action thunks
export const setUser = (user) => (dispatch) => {
  Sentry.setUser({ email: user.email, username: user.full_name, id: user.id })

  dispatch(slice.actions.setUser(user))
}

export const relogin = (slug) => async () => {
  const {
    data: { token },
  } = await APIClient.relogin(slug)

  Session.saveToStorage(
    JSON.stringify({
      isAuthenticated: true,
      token,
    }),
  )

  await Session.isAuthenticated()

  history.push('/')
}
