import PropTypes from 'prop-types'
import React from 'react'
import { createStructuredSelector } from 'reselect'
import { isUndefined, isEmpty, isFunction } from 'lodash'
import { Map } from 'immutable'

class AbstractAbility extends React.Component<any, any> {
  getAbilities() {
    const { abilities, storeAbilities } = this.props

    if (abilities) {
      return Map(abilities)
    }

    if (!(storeAbilities instanceof Map)) {
      return Map(storeAbilities)
    }

    return storeAbilities
  }

  or() {
    const passed = this.getAbilities().filter((ability) => ability === true)
    if (!passed.size) {
      return this.deny()
    }

    const matched = this.props.ability.filter((ability) => passed.has(ability))

    if (matched.length > 0) {
      return this.allow()
    }

    return this.deny()
  }

  and() {
    const passed = this.getAbilities().filter((ability) => ability === true)

    if (!passed.size) {
      return this.deny()
    }

    const matched = this.props.ability.filter((ability) => passed.has(ability))

    if (matched.length === this.props.ability.length) {
      return this.allow()
    }

    return this.deny()
  }

  nor() {
    const passed = this.getAbilities().filter((ability) => ability === true)

    if (!passed.size) {
      return this.deny()
    }

    const matched = this.props.ability.filter((ability) => passed.has(ability))
    if (matched.length > 0) {
      return this.deny()
    }

    return this.allow()
  }

  allow() {
    const { children } = this.props
    if (children.length > 1) {
      return <div>{children}</div>
    } else {
      return children
    }
  }

  deny() {
    const { fallback } = this.props

    if (!isUndefined(fallback) && isFunction(fallback)) {
      return fallback()
    }

    return null
  }

  render() {
    if (!this.props.children) {
      return this.deny()
    }

    if (isUndefined(this.props.ability) || isEmpty(this.props.ability)) {
      return this.allow()
    }

    if (!this.props.comparator || this.props.comparator.toLowerCase() === 'and') {
      return this.and()
    }

    if (this.props.comparator && this.props.comparator.toLowerCase() === 'or') {
      return this.or()
    }

    if (this.props.comparator && this.props.comparator.toLowerCase() === 'nor') {
      return this.nor()
    }

    return this.deny()
  }
}

AbstractAbility.propTypes = {
  abilities: PropTypes.object,
  storeAbilities: PropTypes.object,
  ability: PropTypes.array,
  comparator: PropTypes.string,
  fallback: PropTypes.func,
}

export { AbstractAbility }
export default { AbstractAbility }
