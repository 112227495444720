import React, { ReactElement } from 'react'
import { TravelPointLabel } from '../../../TravelPointLabel'
import trans from '../../../../trans'
import { Element } from '../../../../models/timeline/Element'
import TravelPointDate from '../../../TravelPointDate'
import Icon from '../../../ui/IconComponent'
import ForeignCurrenciesTooltip from '../../../ForeignCurrenciesTooltip'
import { AmountFormatter } from '../../../AmountFormatter'
import {
  TRAVEL_COMPANY_CAR_TRIP,
  TRAVEL_PASSENGER_CAR_TRIP,
  TRAVEL_PRIVATE_CAR_TRIP,
  TRAVEL_RENTED_CAR_TRIP,
  TRAVEL_REPLACEMENT_CAR_TRIP,
} from '../../../../constants/travel'
import { TimelineElement, TimeLineElementContent, TimeLineElementHeader } from '../../../Timeline'
import { Col } from '../../../../ui'
import keepInViewport from '../KeepInViewport'
import { TypesMenu } from '../../TypesMenu'
import { Field } from 'redux-form/immutable'
import { FormField } from '../../../ui/Form'

interface CarProps {
  car: Element
  change: (prop: string, value: any) => void
  onRemove: (car: Element) => void
  instanceCurrency: string
}

const KeepingViewportHeader = keepInViewport()(TimeLineElementHeader)

export default class Car extends React.Component<any, any> {
  renderLabel() {
    const { car } = this.props

    const from = car.virtual ? car.getEndLocation() : car.getStartLocation()
    const to = car.virtual ? car.getStartLocation() : car.getEndLocation()
    const date = car.virtual ? car.getEndDate() : car.getStartDate()

    if (!from.city || !to.city || !date) {
      return trans('request.fill-travel-data')
    } else {
      return (
        <div>
          <TravelPointLabel location={from} /> → <TravelPointLabel location={to} showDirections />
          <TravelPointDate start={date} />
        </div>
      )
    }
  }

  renderCarTypeField() {
    const { onChangeType, car } = this.props

    return (
      <Field
        name='type'
        type={'select'}
        component={FormField}
        options={this.getChangeTypeOptions()}
        label={trans('request-car-element.car-type')}
        onChange={(f, value) => onChangeType(car, value)}
        disabled={!car.draft}
        inline
        labeltop
      />
    )
  }

  renderTrash() {
    const { car, onRemove } = this.props
    if (car.virtual) {
      return null
    }

    return (
      <span className='timeline-icon' onClick={() => onRemove(car)}>
        <Icon type='trash' lg gradient />
      </span>
    )
  }

  renderEdit() {
    const { car, change } = this.props
    if (car.virtual || car.isOpen) {
      return null
    }

    return (
      <span
        className='timeline-icon'
        onClick={() => {
          change('isOpen', true)
        }}
      >
        <Icon type='edit' lg gradient />
      </span>
    )
  }

  renderForeignCurrenciesTooltip() {
    const { car, instanceCurrency } = this.props
    return (
      <ForeignCurrenciesTooltip
        instanceCurrency={instanceCurrency}
        foreignCurrencies={car.getAllAmounts()}
      />
    )
  }

  renderCalculatedAmount() {
    const { car } = this.props

    if (car.virtual) {
      return trans('request.return-travel')
    } else {
      const amount = car.converted_amount
      const currency = car.calculated_amount_currency

      return (
        <div className='flex-end-center'>
          {this.renderForeignCurrenciesTooltip()}{' '}
          <span>
            <AmountFormatter amount={amount} /> {currency}
          </span>
        </div>
      )
    }
  }

  renderContent() {
    const { onChangeType, car } = this.props

    return (
      <TimeLineElementContent isOpened={car.isOpen}>
        <TypesMenu
          selected={car.type}
          onChange={() => onChangeType(car, null)}
          showChangeButton={car.draft}
        />

        <div className='tooltip-content'>{this.renderForm()}</div>
      </TimeLineElementContent>
    )
  }

  getChangeTypeOptions() {
    const { car } = this.props

    const options = {
      [TRAVEL_COMPANY_CAR_TRIP]: {
        value: TRAVEL_COMPANY_CAR_TRIP,
        label: trans('request-car-element.company-car'),
      },
      [TRAVEL_RENTED_CAR_TRIP]: {
        value: TRAVEL_RENTED_CAR_TRIP,
        label: trans('request-car-element.rented-car'),
      },
      [TRAVEL_PRIVATE_CAR_TRIP]: {
        value: TRAVEL_PRIVATE_CAR_TRIP,
        label: trans('request-car-element.private-car'),
      },
      [TRAVEL_REPLACEMENT_CAR_TRIP]: {
        value: TRAVEL_REPLACEMENT_CAR_TRIP,
        label: trans('request-car-element.replacement-car'),
      },
      [TRAVEL_PASSENGER_CAR_TRIP]: {
        value: TRAVEL_PASSENGER_CAR_TRIP,
        label: trans('request-car-element.passenger-car'),
      },
    }

    if (car.draft) {
      return Object.values(options)
    }

    return [options[car.type]]
  }

  render() {
    const { car, request } = this.props

    return (
      <TimelineElement className={car.className} type={car.type} activeSimilar>
        <KeepingViewportHeader icon='car_fill' gradient='true' isOpen={car.isOpen}>
          <Col xs={9} className='timeline__header--title'>
            {this.renderLabel()}
          </Col>
          <Col style={{ paddingRight: '0' }} className='is-allign-end' xs={2}>
            {this.renderCalculatedAmount()}
          </Col>

          {request['abilities']['edit'] && (
            <Col xs={1} style={{ paddingLeft: '0' }} className='is-allign-end'>
              {this.renderEdit()}
              {this.renderTrash()}
            </Col>
          )}
        </KeepingViewportHeader>

        {car.isOpen && this.renderContent()}
      </TimelineElement>
    )
  }

  renderForm(): JSX.Element {
    throw new Error('Not implemented')
  }
}
